import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export * as Operations from './base-type-documents';

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  calendar_event_material_view_scalar: any;
  calendar_meeting_call_scalar: any;
  citext: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  organization_user_leave_data_scalar: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
  uuid: any;
};

export type ActionInput = {
  action: Scalars['String'];
  id: Scalars['uuid'];
};

export type ActionOutput = {
  __typename?: 'ActionOutput';
  id: Scalars['uuid'];
};

export type ActivateInput = {
  ticket: Scalars['uuid'];
};

export type ActivateOutput = {
  __typename?: 'ActivateOutput';
  refreshToken?: Maybe<Scalars['String']>;
  resetPassword?: Maybe<Scalars['String']>;
};

export type AddAssignUserToCareerPlanInput = {
  career_development_plan_id?: InputMaybe<Scalars['uuid']>;
  user_list: Array<Selected_User>;
};

export type AddAssignUserToCareerPlanOutput = {
  __typename?: 'AddAssignUserToCareerPlanOutput';
  response: Scalars['String'];
};

export type AddChatEmoteReactionInput = {
  messageId: Scalars['uuid'];
  reactionType: Scalars['String'];
};

export type AddChatEmoteReactionOutput = {
  __typename?: 'AddChatEmoteReactionOutput';
  reactionId: Scalars['String'];
};

export type AddCompetencyInput = {
  competencyskills?: InputMaybe<Array<InputMaybe<AddCompetencySkills>>>;
  name: Scalars['String'];
  orgId: Scalars['uuid'];
};

export type AddCompetencyOutput = {
  __typename?: 'AddCompetencyOutput';
  id: Scalars['uuid'];
};

export type AddCompetencySkills = {
  id?: InputMaybe<Scalars['uuid']>;
};

export type AddMemberDetailsInput = {
  memberId: Scalars['uuid'];
  projectId: Scalars['uuid'];
  releaseDate: Scalars['date'];
};

export type AddMemberDetailsOutput = {
  __typename?: 'AddMemberDetailsOutput';
  id: Scalars['uuid'];
};

export type AddNewLeaveTypeInput = {
  annual_carry_forward_amount?: InputMaybe<Scalars['Int']>;
  annual_eligibility?: InputMaybe<Scalars['Int']>;
  leave_type?: InputMaybe<Scalars['String']>;
  monthly_earning?: InputMaybe<Scalars['Float']>;
  org_id?: InputMaybe<Scalars['String']>;
  per_leave_limit?: InputMaybe<Scalars['Int']>;
  will_be_earned: Scalars['Boolean'];
  will_carry_forward?: InputMaybe<Scalars['Boolean']>;
};

export type AddNewLeaveTypeOutput = {
  __typename?: 'AddNewLeaveTypeOutput';
  new_leave_type_id?: Maybe<Scalars['String']>;
};

export type AddSkillDetailsInput = {
  comptId?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  orgId: Scalars['uuid'];
  skills: Array<InputMaybe<AddSkills>>;
};

export type AddSkillDetailsOutput = {
  __typename?: 'AddSkillDetailsOutput';
  id: Scalars['uuid'];
};

export type AddSkills = {
  description?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
};

export type AddUsersSkillsAndComptInput = {
  comptId: Scalars['uuid'];
  userId: Scalars['uuid'];
  userSkills: Array<InputMaybe<SkillsUnderCompt>>;
};

export type AddUsersSkillsAndComptOutput = {
  __typename?: 'AddUsersSkillsAndComptOutput';
  id: Scalars['uuid'];
};

export type AddUsersToCallInput = {
  call_id: Scalars['String'];
  users_id: Array<Scalars['String']>;
};

export type AddUsersToCallOutput = {
  __typename?: 'AddUsersToCallOutput';
  response: Scalars['Boolean'];
};

export type AddUsersToChatInput = {
  chatRoomId: Scalars['String'];
  users: Array<Scalars['String']>;
};

export type AddUsersToChatOutput = {
  __typename?: 'AddUsersToChatOutput';
  success: Scalars['Boolean'];
};

export type AggregateInfo = {
  __typename?: 'AggregateInfo';
  count?: Maybe<Scalars['Int']>;
  sum?: Maybe<AggregateSum>;
};

export type AggregateSum = {
  __typename?: 'AggregateSum';
  feedback?: Maybe<Scalars['Int']>;
};

export type AllQuestions = {
  __typename?: 'AllQuestions';
  id: Scalars['uuid'];
  name: Scalars['String'];
  question_options: AllQuestionsOptions;
  storage_file?: Maybe<QuestionStorageFile>;
  type: Scalars['String'];
};

export type AllQuestionsOptions = {
  __typename?: 'AllQuestionsOptions';
  name: Scalars['String'];
};

export type AllUserRatingDetail = {
  __typename?: 'AllUserRatingDetail';
  activeStatus?: Maybe<Scalars['String']>;
  comptPercentage?: Maybe<Scalars['Int']>;
  profileImage?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  skillDetail?: Maybe<Array<Maybe<UserSkillsDetail>>>;
  userId?: Maybe<Scalars['uuid']>;
  userName?: Maybe<Scalars['uuid']>;
};

export type AssessmentData = {
  __typename?: 'AssessmentData';
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

export type AssessmentInput = {
  active_till: Scalars['Int'];
  assessment_role_details: Array<InputMaybe<CreateAssessmentRoleInput>>;
  assessment_skills?: InputMaybe<Array<InputMaybe<AssessmentSkillInput>>>;
  assessment_type_id: Scalars['uuid'];
  department_id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  passing_score: Scalars['Int'];
  questions: Array<QuestionInput>;
  recommended_training: Scalars['String'];
  role_id?: InputMaybe<Scalars['uuid']>;
  status: Scalars['Boolean'];
  tags?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type AssessmentOutput = {
  __typename?: 'AssessmentOutput';
  id: Scalars['uuid'];
};

export type AssessmentScoreForAssessmentTypeInput = {
  managerId?: InputMaybe<Scalars['uuid']>;
  organizationId: Scalars['uuid'];
  roleId?: InputMaybe<Scalars['uuid']>;
  roleTitle?: InputMaybe<Scalars['String']>;
  systemRole?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AssessmentScoreForAssessmentTypeOuput = {
  __typename?: 'AssessmentScoreForAssessmentTypeOuput';
  response?: Maybe<Array<Maybe<AssessmentTypeWiseData>>>;
};

export type AssessmentSkillInput = {
  skillId?: InputMaybe<Scalars['uuid']>;
};

export type AssessmentTrainingSkills = {
  __typename?: 'AssessmentTrainingSkills';
  skill_name?: Maybe<Scalars['String']>;
};

export type Attendee = {
  attendee_id?: InputMaybe<Scalars['uuid']>;
  attendee_name: Scalars['String'];
  attendee_type: Scalars['String'];
  calendar_id: Scalars['uuid'];
  email: Scalars['String'];
  id?: InputMaybe<Scalars['uuid']>;
};

export type Attenders = {
  attendee_id?: InputMaybe<Scalars['uuid']>;
  attendee_name: Scalars['String'];
  attendee_type: Scalars['String'];
  calendar_id: Scalars['uuid'];
  email: Scalars['String'];
  id?: InputMaybe<Scalars['uuid']>;
};

export type AvatarFileInfo = {
  __typename?: 'AvatarFileInfo';
  file_url?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CalendarEventOneToOneInput = {
  additional_fields: Scalars['jsonb'];
  all_day?: InputMaybe<Scalars['Boolean']>;
  attendees: Array<Attenders>;
  calendar_id: Scalars['String'];
  end_date_time: Scalars['String'];
  event_name: Scalars['String'];
  event_type: Scalars['String'];
  performance_timePeriod_Id: Scalars['uuid'];
  rrule?: InputMaybe<Scalars['String']>;
  start_date_time: Scalars['String'];
  timeperiod: Scalars['String'];
  userId: Scalars['uuid'];
};

export type CalendarEventOneToOneOutput = {
  __typename?: 'CalendarEventOneToOneOutput';
  id: Scalars['uuid'];
};

export enum CallRequestResponseType {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
}

export type CallTokenInput = {
  agoraId: Scalars['String'];
  channel: Scalars['String'];
};

export type CallTokenOutput = {
  __typename?: 'CallTokenOutput';
  appId: Scalars['String'];
  token: Scalars['String'];
};

export type CareerDevelopmentDashboardInput = {
  user_id: Scalars['uuid'];
};

export type CareerDevelopmentDashboardOutput = {
  __typename?: 'CareerDevelopmentDashboardOutput';
  response?: Maybe<Array<Maybe<User_Career_Development_Dashboard>>>;
};

export type CareerDevelopmentInput = {
  career_stage: Array<List_Of_Career_Stage>;
  cover_image_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_certification?: InputMaybe<
    Array<InputMaybe<List_Of_External_Certification>>
  >;
  org_id?: InputMaybe<Scalars['uuid']>;
  role: Array<Career_Selected_Roles>;
  role_title?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CareerDevelopmentOutput = {
  __typename?: 'CareerDevelopmentOutput';
  id: Scalars['uuid'];
};

export type CareerViewStatsInput = {
  filter?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  org_id: Scalars['uuid'];
  page?: InputMaybe<Scalars['Int']>;
};

export type CareerViewStatsOutput = {
  __typename?: 'CareerViewStatsOutput';
  response?: Maybe<ViewStatsResponse>;
};

export type Career_Selected_Roles = {
  role_id?: InputMaybe<Scalars['uuid']>;
};

export type Certification_Details = {
  __typename?: 'Certification_Details';
  file_url?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ChatNotificationStates {
  Muted = 'MUTED',
  Unmuted = 'UNMUTED',
}

export type ChecklistInput = {
  isApproved?: InputMaybe<Scalars['Boolean']>;
  itemName: Scalars['String'];
  taskId: Scalars['uuid'];
};

export type ChecklistOutput = {
  __typename?: 'ChecklistOutput';
  approvedBy?: Maybe<Scalars['uuid']>;
  checklistId: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type ClientDetails = {
  __typename?: 'ClientDetails';
  name: Scalars['String'];
  workHours: Scalars['Int'];
};

export type ClientInput = {
  clientId?: InputMaybe<Scalars['uuid']>;
  clientName?: InputMaybe<Scalars['String']>;
};

export type Competency = {
  __typename?: 'Competency';
  competencyId?: Maybe<Scalars['uuid']>;
  competencyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  listOfSkills?: Maybe<Array<Maybe<Skills>>>;
};

export type ContactUsInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type ContactUsOutput = {
  __typename?: 'ContactUsOutput';
  success: Scalars['String'];
};

export type CoverImageDetail = {
  __typename?: 'CoverImageDetail';
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CreateCalendarEventInput = {
  additional_fields: Scalars['jsonb'];
  all_day?: InputMaybe<Scalars['Boolean']>;
  attendees: Array<Attendee>;
  calendar_id: Scalars['String'];
  end_date_time: Scalars['String'];
  event_name: Scalars['String'];
  event_type: Scalars['String'];
  live_training?: InputMaybe<Scalars['Boolean']>;
  rrule?: InputMaybe<Scalars['String']>;
  start_date_time: Scalars['String'];
};

export type CreateCalendarEventOutput = {
  __typename?: 'CreateCalendarEventOutput';
  id: Scalars['uuid'];
};

export type CreateMeetCallOutput = {
  __typename?: 'CreateMeetCallOutput';
  slug: Scalars['String'];
};

export type CreateMeetingRoomInput = {
  organization_id: Scalars['uuid'];
  room_type: Scalars['String'];
};

export type CreateMeetingRoomOutput = {
  __typename?: 'CreateMeetingRoomOutput';
  room_id: Scalars['uuid'];
};

export type CreateMilestoneInput = {
  date: Scalars['timestamp'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['uuid'];
  status?: InputMaybe<Scalars['String']>;
};

export type CreateMilestoneOutput = {
  __typename?: 'CreateMilestoneOutput';
  date?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

export type CreateProjectOutput = {
  __typename?: 'CreateProjectOutput';
  projectId: Scalars['uuid'];
};

export type CreateSprintInput = {
  category: SprintCategory;
  daily_scrum?: InputMaybe<DailyScrum>;
  duration_in_weeks: Scalars['Int'];
  end_date: Scalars['date'];
  name: Scalars['String'];
  project_id: Scalars['uuid'];
  start_date: Scalars['date'];
};

export type CreateSprintOutput = {
  __typename?: 'CreateSprintOutput';
  id?: Maybe<Scalars['uuid']>;
};

export type CreateTaskOutput = {
  __typename?: 'CreateTaskOutput';
  taskId: Scalars['uuid'];
};

export type DailyScrum = {
  end_time: Scalars['timestamp'];
  start_time: Scalars['timestamp'];
  timezone: Scalars['String'];
};

export type DeleteEmployeeInput = {
  userId: Scalars['String'];
};

export type DeleteEmployeeOutput = {
  __typename?: 'DeleteEmployeeOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteGoalInput = {
  createdId: Scalars['uuid'];
  defaultRole: Scalars['String'];
  id: Scalars['uuid'];
  manager_id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  performanceTimePeriodId?: InputMaybe<
    Array<InputMaybe<PerformanceTimePeriodIdInput>>
  >;
};

export type DeleteGoalOutput = {
  __typename?: 'DeleteGoalOutput';
  id: Scalars['uuid'];
};

export type DeleteOrgAdminInput = {
  org_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type DeleteOrgAdminOutput = {
  __typename?: 'DeleteOrgAdminOutput';
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteOrgLocationInput = {
  locationId: Scalars['String'];
  orgId: Scalars['String'];
};

export type DeleteOrgLocationOutput = {
  __typename?: 'DeleteOrgLocationOutput';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteOrgRoleInput = {
  role_id: Scalars['String'];
};

export type DeleteOrgRoleOutput = {
  __typename?: 'DeleteOrgRoleOutput';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteSprintInput = {
  deleteAssociatedTasks?: InputMaybe<Scalars['Boolean']>;
  sprint_id: Scalars['uuid'];
};

export type DeleteSprintOutput = {
  __typename?: 'DeleteSprintOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DepartmentKpiDetails = {
  __typename?: 'DepartmentKpiDetails';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

export type DepartmentListId = {
  id: Scalars['uuid'];
};

export type DepartmentListsId = {
  id: Scalars['uuid'];
};

export type DepartmentUuidInput = {
  department_Id?: InputMaybe<Scalars['uuid']>;
};

export type DepartmentUuidInputs = {
  department_Id: Scalars['uuid'];
};

export type Deviated_Emp = {
  __typename?: 'Deviated_Emp';
  deviated_count?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Int']>;
  user_list?: Maybe<Array<Maybe<Skill_User_List>>>;
};

export type Deviated_Skills = {
  __typename?: 'Deviated_Skills';
  deviated_percentage?: Maybe<Scalars['Int']>;
  skill_name?: Maybe<Scalars['String']>;
};

export type DropboxAuthenticationInput = {
  code: Scalars['String'];
  userId: Scalars['String'];
};

export type DropboxAuthenticationOutput = {
  __typename?: 'DropboxAuthenticationOutput';
  accessToken: Scalars['String'];
};

export type DropboxLogoutInput = {
  user_id: Scalars['uuid'];
};

export type DropboxLogoutOutput = {
  __typename?: 'DropboxLogoutOutput';
  status: Scalars['String'];
};

export type EditCompetencyInput = {
  editCompetencySkill?: InputMaybe<Array<InputMaybe<EditCompetencySkills>>>;
  id: Scalars['uuid'];
  name: Scalars['String'];
};

export type EditCompetencyOutput = {
  __typename?: 'EditCompetencyOutput';
  response: Scalars['String'];
};

export type EditCompetencySkills = {
  id?: InputMaybe<Scalars['uuid']>;
};

export type EditLeaveTypeInput = {
  annual_carry_forward_amount?: InputMaybe<Scalars['Int']>;
  annual_eligibility?: InputMaybe<Scalars['Int']>;
  delete?: InputMaybe<Scalars['Boolean']>;
  leave_type?: InputMaybe<Scalars['String']>;
  leave_type_id: Scalars['String'];
  monthly_earning?: InputMaybe<Scalars['Float']>;
  per_leave_limit?: InputMaybe<Scalars['Int']>;
  will_be_earned: Scalars['Boolean'];
  will_carry_forward?: InputMaybe<Scalars['Boolean']>;
};

export type EditLeaveTypeOutput = {
  __typename?: 'EditLeaveTypeOutput';
  leave_type_id: Scalars['String'];
};

export type EditSkillDetailsInput = {
  main_Skill_Id?: InputMaybe<Scalars['uuid']>;
  orgId: Scalars['uuid'];
  skillName: Scalars['String'];
  skills_Id: Scalars['uuid'];
  subSkills: Array<InputMaybe<EditSkillsInput>>;
};

export type EditSkillDetailsOutput = {
  __typename?: 'EditSkillDetailsOutput';
  response: Scalars['String'];
};

export type EditSkillsInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
};

export type ExtendDayAlertInput = {
  employeeId: Scalars['uuid'];
  managerId: Scalars['uuid'];
  message: Scalars['String'];
};

export type ExtendDayAlertOutput = {
  __typename?: 'ExtendDayAlertOutput';
  success: Scalars['Boolean'];
};

export type External_Certification_Details = {
  __typename?: 'External_Certification_Details';
  certificate?: Maybe<Certification_Details>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ExtraFields = {
  __typename?: 'ExtraFields';
  category?: Maybe<Scalars['String']>;
  completedTasks?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  totalTasks?: Maybe<Scalars['Int']>;
};

export type GetAllUsersBySkillInput = {
  comptId: Scalars['uuid'];
  orgId: Scalars['uuid'];
};

export type GetAllUsersBySkillOutput = {
  __typename?: 'GetAllUsersBySkillOutput';
  response?: Maybe<Array<Maybe<AllUserRatingDetail>>>;
};

export type GetGoalListPerRoleInput = {
  filterDeptartment: Array<DepartmentListId>;
  filterRole: Array<RoleListId>;
  filterTimePeriod: Array<TimePeriodName>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orgId: Scalars['uuid'];
  year: Scalars['String'];
};

export type GetGoalListPerRoleOutput = {
  __typename?: 'GetGoalListPerRoleOutput';
  response: Array<Maybe<ListOfGoalsPerRole>>;
};

export type GetKpiListPerRoleInput = {
  filterDeptartment?: InputMaybe<Array<InputMaybe<DepartmentListsId>>>;
  filterRole?: InputMaybe<Array<InputMaybe<RoleListsId>>>;
  goalId?: InputMaybe<Scalars['uuid']>;
  orgId: Scalars['uuid'];
  seachKpi?: InputMaybe<Scalars['String']>;
};

export type GetKpiListPerRoleOutput = {
  __typename?: 'GetKpiListPerRoleOutput';
  response?: Maybe<KpiResponses>;
};

export type GetLatestAssessmentRecordMarksInput = {
  assessmentId: Scalars['uuid'];
  careerAssessment: Scalars['Boolean'];
  skillAssessment: Scalars['Boolean'];
  userId: Scalars['uuid'];
};

export type GetLatestAssessmentRecordMarksOutput = {
  __typename?: 'GetLatestAssessmentRecordMarksOutput';
  correct: Scalars['Int'];
  pass: Scalars['String'];
  question: Scalars['Int'];
  response: Array<Maybe<AllAnswers>>;
};

export type GetScoreSheetInput = {
  orgId?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

export type GetScoreSheetOutput = {
  __typename?: 'GetScoreSheetOutput';
  response?: Maybe<UserScoreSheetResponse>;
};

export type GetUSerListForSkillApprovalInput = {
  orgId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type GetUSerListForSkillApprovalOutput = {
  __typename?: 'GetUSerListForSkillApprovalOutput';
  response?: Maybe<UserListResp>;
};

export type GetUserSkillDashboardInput = {
  orgId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type GetUserSkillDashboardOutput = {
  __typename?: 'GetUserSkillDashboardOutput';
  response?: Maybe<Resp>;
};

export type GetUserSkillInfoInput = {
  orgId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type GetUserSkillInfoOutput = {
  __typename?: 'GetUserSkillInfoOutput';
  response?: Maybe<Array<Maybe<UserSkillResponse>>>;
};

export type Goal = {
  __typename?: 'Goal';
  description: Scalars['String'];
  goalWithKpis: Array<KpiObject>;
  goalsWithRoles?: Maybe<Array<Maybe<RoleWeightageObject>>>;
  id: Scalars['uuid'];
  timePeriodic: Scalars['String'];
  title: Scalars['String'];
  weightage_s: Scalars['String'];
};

export type GoalCountPerRolesInput = {
  orgId: Scalars['uuid'];
  timePeriod: Scalars['String'];
};

export type GoalCountPerRolesOutput = {
  __typename?: 'GoalCountPerRolesOutput';
  response: Array<Maybe<GoalDetailPerRoles>>;
};

export type GoalDetailPerRoles = {
  __typename?: 'GoalDetailPerRoles';
  count?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  roleId?: Maybe<Scalars['uuid']>;
  roleTitle?: Maybe<Scalars['String']>;
};

export type GoalDetailWithDepts = {
  __typename?: 'GoalDetailWithDepts';
  department?: Maybe<OrgDeptDetails>;
  id?: Maybe<Scalars['uuid']>;
};

export type GoalDetailWithFreq = {
  __typename?: 'GoalDetailWithFreq';
  frequency?: Maybe<OrgFreqDetails>;
  id?: Maybe<Scalars['uuid']>;
};

export type GoalDetailWithKpis = {
  __typename?: 'GoalDetailWithKpis';
  kpi_ID?: Maybe<Scalars['uuid']>;
};

export type GoalDetailWithRoleWeightage = {
  __typename?: 'GoalDetailWithRoleWeightage';
  role_id?: Maybe<Scalars['uuid']>;
  role_name?: Maybe<Scalars['String']>;
  time_period?: Maybe<Array<Maybe<GoalDetailWithWeightage>>>;
};

export type GoalDetailWithRoles = {
  __typename?: 'GoalDetailWithRoles';
  id?: Maybe<Scalars['uuid']>;
  organization_role?: Maybe<OrgRoleDetails>;
};

export type GoalDetailWithTimePeriod = {
  __typename?: 'GoalDetailWithTimePeriod';
  id?: Maybe<Scalars['uuid']>;
  timePeriod?: Maybe<Scalars['String']>;
};

export type GoalDetailWithWeightage = {
  __typename?: 'GoalDetailWithWeightage';
  available_weightage?: Maybe<Scalars['Int']>;
  timePeriod?: Maybe<Scalars['String']>;
  weightage?: Maybe<Scalars['Int']>;
};

export type GoalDetailsByIdResponse = {
  __typename?: 'GoalDetailsByIdResponse';
  description?: Maybe<Scalars['String']>;
  goalWithKpis?: Maybe<Array<Maybe<GoalDetailWithKpis>>>;
  goalWithRoleWeightage?: Maybe<Array<Maybe<GoalDetailWithRoleWeightage>>>;
  goalWithTimePeriods?: Maybe<Array<Maybe<GoalDetailWithTimePeriod>>>;
  goal_Id?: Maybe<Scalars['uuid']>;
  goalsWithDepts?: Maybe<Array<Maybe<GoalDetailWithDepts>>>;
  goalsWithFreqs?: Maybe<Array<Maybe<GoalDetailWithFreq>>>;
  goalsWithRoles?: Maybe<Array<Maybe<GoalDetailWithRoles>>>;
  id?: Maybe<Scalars['uuid']>;
  manager_Id?: Maybe<Scalars['uuid']>;
  timePeriodic?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  weightage_s?: Maybe<Scalars['String']>;
};

export type GoalDetailsInput = {
  goal_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type GoalDetailsOutput = {
  __typename?: 'GoalDetailsOutput';
  response?: Maybe<GoalDetailsByIdResponse>;
};

export type GoalKpiInfoList = {
  __typename?: 'GoalKpiInfoList';
  createdBy?: Maybe<Scalars['uuid']>;
  kpi_ID?: Maybe<Scalars['uuid']>;
};

export type GoalKpiInput = {
  goal_Id?: InputMaybe<Scalars['uuid']>;
  kpi: Array<KpiUuid>;
};

export type GoalKpiOuput = {
  __typename?: 'GoalKpiOuput';
  id: Scalars['uuid'];
};

export type GoalObject = {
  __typename?: 'GoalObject';
  goal: Goal;
  performance_Period_Id: Scalars['uuid'];
};

export type GoalRoalWeightageDifferentInput = {
  role_id?: InputMaybe<Scalars['uuid']>;
  role_weightage?: InputMaybe<Scalars['String']>;
};

export type GoalRoleWeightageInput = {
  role_id?: InputMaybe<Scalars['uuid']>;
  time_period?: InputMaybe<Array<InputMaybe<SelectedGoalTimePeriodOption>>>;
};

export type GoalsInput = {
  department?: InputMaybe<Array<DepartmentUuidInputs>>;
  description: Scalars['String'];
  different_weightage_role?: InputMaybe<
    Array<InputMaybe<GoalRoalWeightageDifferentInput>>
  >;
  manager_id: Scalars['uuid'];
  performance_Id: Scalars['uuid'];
  performance_timePeriod_Id: Array<PerforamanceReviewtimePeriodInputId>;
  role?: InputMaybe<Array<RoleUuidInputs>>;
  role_weightage?: InputMaybe<Array<InputMaybe<GoalRoleWeightageInput>>>;
  selected_Assign_Weightage_Opt?: InputMaybe<Scalars['String']>;
  single_weightage_all_role?: InputMaybe<Scalars['String']>;
  timePeriods?: InputMaybe<Array<TimePeriodInputs>>;
  title: Scalars['String'];
};

export type GoalsOutput = {
  __typename?: 'GoalsOutput';
  id: Scalars['uuid'];
};

export type GoogledriveAuthenticationInput = {
  code: Scalars['String'];
  userId: Scalars['String'];
};

export type GoogledriveAuthenticationOutput = {
  __typename?: 'GoogledriveAuthenticationOutput';
  accessToken: Scalars['String'];
};

export type GoogledriveLogoutInput = {
  user_id: Scalars['uuid'];
};

export type GoogledriveLogoutOutput = {
  __typename?: 'GoogledriveLogoutOutput';
  status: Scalars['String'];
};

export type GraphData = {
  __typename?: 'GraphData';
  biblableWorkHours: Scalars['Int'];
  clientName: Array<Maybe<ClientDetails>>;
  leaveData: Scalars['Int'];
  name?: Maybe<Scalars['Int']>;
  nonBillableClientName?: Maybe<Array<Maybe<ClientDetails>>>;
  nonBillableWorkHours: Scalars['String'];
};

export type GroupByFilterInput = {
  assignedBy?: InputMaybe<Scalars['uuid']>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']>>;
  category?: InputMaybe<Array<Scalars['String']>>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']>>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  groupByType?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  priority?: InputMaybe<Array<Scalars['String']>>;
  projectId?: InputMaybe<Scalars['uuid']>;
  recurringFrequency?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  taskName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

export type GroupByFilterOutput = {
  __typename?: 'GroupByFilterOutput';
  aggregateCount?: Maybe<Scalars['Int']>;
  groupByDetails?: Maybe<Array<GroupByOutput>>;
  tasks?: Maybe<Array<TaskDetailsOutput>>;
};

export type GroupByOutput = {
  __typename?: 'GroupByOutput';
  count?: Maybe<Scalars['Int']>;
  data?: Maybe<ExtraFields>;
  key?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startIndex?: Maybe<Scalars['Int']>;
};

export type IndividualAssessmentScoreInput = {
  userId: Array<InputMaybe<Scalars['uuid']>>;
};

export type IndividualAssessmentScoreOuput = {
  __typename?: 'IndividualAssessmentScoreOuput';
  response?: Maybe<Array<Maybe<ScoreAssessmentData>>>;
};

export type IndividualAssessmentTypeGraphInput = {
  assessmentType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

export type IndividualAssessmentTypeGraphOuput = {
  __typename?: 'IndividualAssessmentTypeGraphOuput';
  response?: Maybe<Array<Maybe<IndividualScoreAssessmentData>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type InviteGuestToProjectInput = {
  emailIds?: InputMaybe<Array<Scalars['String']>>;
  projectId: Scalars['uuid'];
};

export type InviteGuestToProjectOutput = {
  __typename?: 'InviteGuestToProjectOutput';
  users?: Maybe<Array<Scalars['uuid']>>;
};

export type InviteMeetingRoomInput = {
  meeting_room_id: Scalars['uuid'];
  users: Array<Scalars['uuid']>;
};

export type InviteMeetingRoomOutput = {
  __typename?: 'InviteMeetingRoomOutput';
  affected_rows: Scalars['String'];
};

export type JoinLiveCallInput = {
  liveCallId: Scalars['uuid'];
};

export type JoinLiveCallOutput = {
  __typename?: 'JoinLiveCallOutput';
  success: Scalars['Boolean'];
};

export type JoinMeetOutput = {
  __typename?: 'JoinMeetOutput';
  calendarMeetingCallUserId: Scalars['uuid'];
};

export type KpiBank = {
  __typename?: 'KpiBank';
  description: Scalars['String'];
  id: Scalars['uuid'];
  open_ended_kpi?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type KpiDeptInfo = {
  __typename?: 'KpiDeptInfo';
  department?: Maybe<DepartmentKpiDetails>;
};

export type KpiInfo = {
  __typename?: 'KpiInfo';
  description: Scalars['String'];
  id: Scalars['uuid'];
  title: Scalars['String'];
};

export type KpiInput = {
  department?: InputMaybe<Array<DepartmentUuidInput>>;
  description: Scalars['String'];
  goal_Id?: InputMaybe<Scalars['uuid']>;
  manager_id: Scalars['uuid'];
  openEndedKpi: Scalars['Boolean'];
  role?: InputMaybe<Array<RoleUuidInput>>;
  title: Scalars['String'];
};

export type KpiObject = {
  __typename?: 'KpiObject';
  kpi_bank: KpiBank;
};

export type KpiOutput = {
  __typename?: 'KpiOutput';
  id: Scalars['uuid'];
};

export type KpiRoleInfo = {
  __typename?: 'KpiRoleInfo';
  organization_role?: Maybe<RoleKpiDetails>;
};

export type LeaveCallInput = {
  activity_status?: InputMaybe<Scalars['String']>;
  call_id: Scalars['String'];
  user_id?: InputMaybe<Scalars['String']>;
};

export type LeaveCallOutput = {
  __typename?: 'LeaveCallOutput';
  response: Scalars['Boolean'];
};

export type LeaveChatInput = {
  chatRoomId: Scalars['uuid'];
};

export type LeaveChatOutput = {
  __typename?: 'LeaveChatOutput';
  success: Scalars['Boolean'];
};

export type LeaveCycleInput = {
  org_id: Scalars['uuid'];
  starting_date: Scalars['String'];
};

export type LeaveCycleOutput = {
  __typename?: 'LeaveCycleOutput';
  leave_cycle_id: Scalars['String'];
};

export type LeaveLiveCallInput = {
  liveCallId: Scalars['uuid'];
};

export type LeaveLiveCallOutput = {
  __typename?: 'LeaveLiveCallOutput';
  success: Scalars['Boolean'];
};

export type LeaveMeetCallOutput = {
  __typename?: 'LeaveMeetCallOutput';
  status: Scalars['Boolean'];
};

export type LeaveMeetingRoomInput = {
  meetingRoomId: Scalars['uuid'];
};

export type LeaveMeetingRoomOutput = {
  __typename?: 'LeaveMeetingRoomOutput';
  success: Scalars['Boolean'];
};

export type ListOfComptSkills = {
  __typename?: 'ListOfComptSkills';
  id?: Maybe<Scalars['uuid']>;
  skillDetail?: Maybe<Array<Maybe<SkillDescription>>>;
  skillId?: Maybe<Scalars['uuid']>;
  skillName?: Maybe<Scalars['String']>;
  userRating?: Maybe<Scalars['Int']>;
};

export type ListOfGoalsPerRole = {
  __typename?: 'ListOfGoalsPerRole';
  createdId: Scalars['uuid'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  kpi: Scalars['Int'];
  kpiList: Array<Maybe<KpiInfo>>;
  timePeriod: Array<Maybe<TimePeriodList>>;
  title: Scalars['String'];
};

export type ListOfKpiPerRole = {
  __typename?: 'ListOfKpiPerRole';
  description: Scalars['String'];
  goalWithKpis?: Maybe<Array<Maybe<GoalKpiInfoList>>>;
  id: Scalars['uuid'];
  kpiDeptDetails?: Maybe<Array<Maybe<KpiDeptInfo>>>;
  kpiRoleDetails?: Maybe<Array<Maybe<KpiRoleInfo>>>;
  kpiWithRoles?: Maybe<Array<Maybe<KpiRoleInfo>>>;
  kpiWithRolesAndDepts?: Maybe<Array<Maybe<KpiDeptInfo>>>;
  managerId: Scalars['uuid'];
  open_ended_kpi?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type ListOfSkillId = {
  skillId: Scalars['uuid'];
};

export type List_Of_Performance_Review = {
  __typename?: 'List_Of_Performance_Review';
  duration?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  period?: Maybe<Array<Maybe<List_Of_TimePeriod>>>;
  status?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type List_Of_TimePeriod = {
  __typename?: 'List_Of_TimePeriod';
  action?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endingDate?: Maybe<Scalars['String']>;
  goalDetails?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['String']>;
  startingDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['uuid']>;
};

export type List_Of_Career_Stage = {
  assessment_stage?: InputMaybe<Array<InputMaybe<Stage_Selected_Assessment>>>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_title?: InputMaybe<Scalars['String']>;
  training_stage?: InputMaybe<Array<InputMaybe<Stage_Selected_Training>>>;
};

export type List_Of_External_Certification = {
  certification_link?: InputMaybe<Scalars['String']>;
};

export type ListofSkillsUnderComptInput = {
  comptId: Scalars['uuid'];
  orgId: Scalars['uuid'];
};

export type ListofSkillsUnderComptOutput = {
  __typename?: 'ListofSkillsUnderComptOutput';
  response?: Maybe<Array<Maybe<ListOfComptSkills>>>;
};

export enum LiveCallResponseType {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
}

export type LoadPerformanceReviewInput = {
  org_id: Scalars['uuid'];
};

export type LoadPerformanceReviewOutput = {
  __typename?: 'LoadPerformanceReviewOutput';
  response?: Maybe<Array<Maybe<List_Of_Performance_Review>>>;
};

export type LoadUserSelectedCareerDetailInput = {
  career_development_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type LoadUserSelectedCareerDetailOutput = {
  __typename?: 'LoadUserSelectedCareerDetailOutput';
  response?: Maybe<UserCareerFlowDetail>;
};

export type Load_Role_Weightage_Input = {
  goal_id?: InputMaybe<Scalars['uuid']>;
  org_id: Scalars['uuid'];
  selectedAssignWeightageOption: Scalars['String'];
  selectedRoles: Array<Selected_Roles_Option>;
  selectedTimePeriod: Array<Selected_TimePeriod_Option>;
};

export type Load_Role_Weightage_Output = {
  __typename?: 'Load_Role_Weightage_Output';
  response?: Maybe<Array<Maybe<Role_Weightage_Response>>>;
};

export type LoginInput = {
  email: Scalars['String'];
  login_type: Scalars['String'];
  metadata?: InputMaybe<Scalars['jsonb']>;
  password: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  display_name?: Maybe<Scalars['String']>;
  jwt_expires_in: Scalars['Int'];
  jwt_token: Scalars['String'];
  refresh_token: Scalars['String'];
  setup_complete?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type LogoutInput = {
  logoutAllSessions: Scalars['Boolean'];
  refreshToken: Scalars['uuid'];
};

export type LogoutOutput = {
  __typename?: 'LogoutOutput';
  success: Scalars['Boolean'];
};

export enum MeetingRoomResponseType {
  Accept = 'ACCEPT',
  Disconnect = 'DISCONNECT',
  Reject = 'REJECT',
}

export type OnedriveAuthenticationInput = {
  code: Scalars['String'];
  userId: Scalars['String'];
};

export type OnedriveAuthenticationOutPut = {
  __typename?: 'OnedriveAuthenticationOutPut';
  accessToken: Scalars['String'];
};

export type OnedriveLogoutInput = {
  user_id: Scalars['uuid'];
};

export type OnedriveLogoutOutput = {
  __typename?: 'OnedriveLogoutOutput';
  status: Scalars['String'];
};

export type OrderBy = {
  column: Scalars['String'];
  order: Scalars['String'];
};

export type OrgDeptDetails = {
  __typename?: 'OrgDeptDetails';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

export type OrgFreqDetails = {
  __typename?: 'OrgFreqDetails';
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

export type OrgRoleDetails = {
  __typename?: 'OrgRoleDetails';
  id?: Maybe<Scalars['uuid']>;
  role_title?: Maybe<Scalars['String']>;
};

export type PeerInput = {
  peerId: Scalars['uuid'];
  timePeriod: Scalars['String'];
  userId: Scalars['uuid'];
};

export type PeerOutput = {
  __typename?: 'PeerOutput';
  id: Scalars['uuid'];
};

export type PerfReviewInput = {
  organizationId: Scalars['uuid'];
  roleId: Scalars['uuid'];
  timePeriod: Scalars['String'];
  year: Scalars['String'];
};

export type PerfReviewOutput = {
  __typename?: 'PerfReviewOutput';
  response: Array<Maybe<PerformanceReviewInfo>>;
};

export type PerformanceReviewDetailPerUser = {
  __typename?: 'PerformanceReviewDetailPerUser';
  managerReview?: Maybe<Scalars['Int']>;
  manager_user_review?: Maybe<Scalars['Int']>;
  overAllReview?: Maybe<Scalars['Int']>;
  peerReview?: Maybe<Scalars['Int']>;
  peer_user_marks?: Maybe<Scalars['Int']>;
  selfReview?: Maybe<Scalars['Int']>;
  self_user_review?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  totalMark?: Maybe<Scalars['Int']>;
};

export type PerformanceReviewGraphInput = {
  role_id: Scalars['String'];
  timePeriod: Scalars['String'];
  userId: Scalars['uuid'];
};

export type PerformanceReviewGraphOutput = {
  __typename?: 'PerformanceReviewGraphOutput';
  overReviewMarks?: Maybe<Scalars['Int']>;
  response: Array<Maybe<PerformanceReviewUserMarks>>;
  totalMark?: Maybe<Scalars['Int']>;
};

export type PerformanceReviewInfo = {
  __typename?: 'PerformanceReviewInfo';
  frequency: Scalars['String'];
  id: Scalars['uuid'];
  instruction: Scalars['String'];
  organizationId: Scalars['uuid'];
  performance_review_setting_With_Goals: PerformanceReviewWithGoals;
  rating?: Maybe<Scalars['Int']>;
  rating_detail?: Maybe<Array<Maybe<Rating_Scale_Info>>>;
  year: Scalars['String'];
};

export type PerformanceReviewInput = {
  copy_previous_year_goals?: InputMaybe<Scalars['Boolean']>;
  end: Scalars['String'];
  frequency: Scalars['String'];
  instruction: Scalars['String'];
  manager_review?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  org_goal_share?: InputMaybe<Scalars['String']>;
  organization_Id: Scalars['uuid'];
  peer_review?: InputMaybe<Scalars['String']>;
  previous_year_tp?: InputMaybe<Scalars['String']>;
  rating_scale?: InputMaybe<Array<InputMaybe<Rating_Scale_Detail>>>;
  self_review?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
  timePeriod: Array<PeriodInput>;
  view_option?: InputMaybe<Scalars['Boolean']>;
  year: Scalars['String'];
};

export type PerformanceReviewNotifyInput = {
  message?: InputMaybe<Scalars['String']>;
  peerId?: InputMaybe<Scalars['uuid']>;
  roleTitle?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

export type PerformanceReviewNotifyOutput = {
  __typename?: 'PerformanceReviewNotifyOutput';
  response: Scalars['String'];
};

export type PerformanceReviewOutput = {
  __typename?: 'PerformanceReviewOutput';
  id: Scalars['uuid'];
};

export type PerformanceReviewUserInput = {
  peerId?: InputMaybe<Scalars['uuid']>;
  role_id: Scalars['String'];
  selfReview?: InputMaybe<Scalars['String']>;
  timePeriod: Scalars['String'];
  userId: Scalars['uuid'];
};

export type PerformanceReviewUserMarks = {
  __typename?: 'PerformanceReviewUserMarks';
  goalId?: Maybe<Scalars['uuid']>;
  goalInfo?: Maybe<Scalars['String']>;
  goalMarks?: Maybe<Scalars['Int']>;
  peerMarks?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  total_peer_marks?: Maybe<Scalars['Int']>;
  weightage?: Maybe<Scalars['String']>;
};

export type PerformanceReviewUserOutput = {
  __typename?: 'PerformanceReviewUserOutput';
  response: Array<Maybe<PerformanceReviewDetailPerUser>>;
};

export type PerformanceReviewUsersInput = {
  goal: Array<GoalDetailUuid>;
  peerId?: InputMaybe<Scalars['uuid']>;
  peerUserId?: InputMaybe<Scalars['uuid']>;
  performance_Id: Scalars['uuid'];
  performance_TimePeriod_Id: Scalars['uuid'];
  reviewUserStatus?: InputMaybe<Scalars['String']>;
  roleId: Scalars['uuid'];
  timePeriod: Scalars['String'];
  usersId: Scalars['uuid'];
};

export type PerformanceReviewUsersOutput = {
  __typename?: 'PerformanceReviewUsersOutput';
  id: Scalars['uuid'];
};

export type PerformanceReviewWithGoals = {
  __typename?: 'PerformanceReviewWithGoals';
  action: Scalars['String'];
  dueDate: Scalars['String'];
  id: Scalars['uuid'];
  performance_review_setting_GoalsLists: Array<GoalObject>;
  startDate: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
  year: Scalars['String'];
};

export type PerformanceTimePeriodIdInput = {
  performance_time_period_id: Scalars['uuid'];
};

export type PeriodInput = {
  endDate: Scalars['date'];
  endingDate: Scalars['date'];
  kpiOptions?: InputMaybe<Scalars['Int']>;
  period: Scalars['String'];
  startDate: Scalars['date'];
  startingDate: Scalars['date'];
  title: Scalars['String'];
  viewOptions?: InputMaybe<Scalars['String']>;
};

export type ProfileInput = {
  avatarId?: InputMaybe<Scalars['uuid']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  dateOfJoining?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<Scalars['String']>;
  marriageAnniversary?: InputMaybe<Scalars['String']>;
};

export type ProjectInput = {
  createChatGroup?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['date']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  projectIcon?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['date']>;
  title: Scalars['String'];
};

export type ProjectMembersInsertInput = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type ProjectOutput = {
  __typename?: 'ProjectOutput';
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

export type QuestionInput = {
  image_id?: InputMaybe<Scalars['uuid']>;
  questionName: Scalars['String'];
  questionOptions: Array<QuestionOptionInput>;
  questionType?: InputMaybe<Scalars['String']>;
};

export type QuestionOptionInput = {
  correct_answer: Scalars['Boolean'];
  name: Scalars['String'];
};

export type QuestionStorageFile = {
  __typename?: 'QuestionStorageFile';
  file_url?: Maybe<Scalars['String']>;
};

export type Rating_Scale_Detail = {
  description?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
};

export type Rating_Scale_Info = {
  __typename?: 'Rating_scale_Info';
  name?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['Int']>;
};

export type RemainderInput = {
  userId: Scalars['uuid'];
};

export type RemainderOutput = {
  __typename?: 'RemainderOutput';
  id: Scalars['uuid'];
};

export type RemoveChatUserInput = {
  chat_room_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type RemoveChatUserOutput = {
  __typename?: 'RemoveChatUserOutput';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestCalendarMeetCallInput = {
  meeting_call_id: Scalars['uuid'];
  user_ids: Array<Scalars['uuid']>;
};

export type RequestCalendarMeetCallOutput = {
  __typename?: 'RequestCalendarMeetCallOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type ResendActivateEmailInput = {
  email: Scalars['String'];
};

export type ResendActivateEmailOutput = {
  __typename?: 'ResendActivateEmailOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type Resp = {
  __typename?: 'Resp';
  approvedSkills?: Maybe<Array<Maybe<Competency>>>;
  assessmentAttended?: Maybe<Scalars['Int']>;
  assessmentCount?: Maybe<Scalars['Int']>;
  project?: Maybe<Array<Maybe<ProjList>>>;
  trainingAttended?: Maybe<Scalars['Int']>;
  trainingCount?: Maybe<Scalars['Int']>;
  underManagerApproval?: Maybe<Array<Maybe<Competency>>>;
};

export type RespondMeetingCallInput = {
  meeting_call_id: Scalars['uuid'];
  response_type: CallRequestResponseType;
};

export type RespondMeetingCallOutput = {
  __typename?: 'RespondMeetingCallOutput';
  event_id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RespondMeetingRoomInput = {
  meeting_room_id: Scalars['uuid'];
  response: MeetingRoomResponseType;
};

export type RespondMeetingRoomOutput = {
  __typename?: 'RespondMeetingRoomOutput';
  success: Scalars['Boolean'];
};

export type RespondToCallInput = {
  call_id: Scalars['String'];
  response_type: CallRequestResponseType;
};

export type RespondToCallOutput = {
  __typename?: 'RespondToCallOutput';
  call_id?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['String']>;
  response: Scalars['Boolean'];
};

export type RespondToLiveCallInput = {
  liveCallRequestId: Scalars['uuid'];
  responseType: LiveCallResponseType;
};

export type RespondToLiveCallOutput = {
  __typename?: 'RespondToLiveCallOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type RoleInput = {
  roleId?: InputMaybe<Scalars['uuid']>;
};

export type RoleKpiDetails = {
  __typename?: 'RoleKpiDetails';
  id?: Maybe<Scalars['uuid']>;
  role_title?: Maybe<Scalars['String']>;
};

export type RoleListId = {
  id: Scalars['uuid'];
};

export type RoleListsId = {
  id: Scalars['uuid'];
};

export type RoleUuidInput = {
  role_Id?: InputMaybe<Scalars['uuid']>;
};

export type RoleUuidInputs = {
  role_Id: Scalars['uuid'];
};

export type RoleWeightageInput = {
  org_id: Scalars['uuid'];
  selectedTimePeriod: Array<SelectedWeightageTimePeriod>;
};

export type RoleWeightageObject = {
  __typename?: 'RoleWeightageObject';
  role_weightage?: Maybe<Scalars['Int']>;
};

export type RoleWeightageOutput = {
  __typename?: 'RoleWeightageOutput';
  response?: Maybe<Array<Maybe<RoleWeightageResponse>>>;
};

export type RoleWeightagePerTp = {
  __typename?: 'RoleWeightagePerTP';
  available_weightage?: Maybe<Scalars['Int']>;
  timePeriod?: Maybe<Scalars['String']>;
  weightage?: Maybe<Scalars['Int']>;
};

export type RoleWeightageResponse = {
  __typename?: 'RoleWeightageResponse';
  role_id?: Maybe<Scalars['uuid']>;
  role_name?: Maybe<Scalars['String']>;
  time_period?: Maybe<Array<Maybe<RoleWeightagePerTp>>>;
};

export type Role_Weightage_Response = {
  __typename?: 'Role_Weightage_Response';
  role_id?: Maybe<Scalars['uuid']>;
  role_name?: Maybe<Scalars['String']>;
  time_period?: Maybe<Array<Maybe<RoleWeightagePerTp>>>;
};

export type SaveEmployeeInput = {
  departmentId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['String']>;
  organizationRoleId?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SaveEmployeeOutput = {
  __typename?: 'SaveEmployeeOutput';
  userId: Scalars['String'];
};

export type SaveOrganizationAdminInput = {
  departmentId: Scalars['uuid'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['uuid'];
  password?: InputMaybe<Scalars['String']>;
};

export type SaveOrganizationAdminOutput = {
  __typename?: 'SaveOrganizationAdminOutput';
  userId: Scalars['uuid'];
};

export type SaveTimeSheetOutput = {
  __typename?: 'SaveTimeSheetOutput';
  timeSheetIds: Array<Scalars['uuid']>;
};

export type SaveTimeSheetWeekOutput = {
  __typename?: 'SaveTimeSheetWeekOutput';
  timeSheetIds: Array<Scalars['uuid']>;
};

export type Section = {
  __typename?: 'Section';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

export type SelectedGoalTimePeriodOption = {
  time_period?: InputMaybe<Scalars['String']>;
  weightage?: InputMaybe<Scalars['String']>;
};

export type SelectedWeightageTimePeriod = {
  time_period: Scalars['String'];
};

export type Selected_Roles_Option = {
  role_id?: InputMaybe<Scalars['uuid']>;
};

export type Selected_TimePeriod_Option = {
  timePeriodInfo?: InputMaybe<Scalars['String']>;
};

export type Selected_User = {
  user_id: Scalars['uuid'];
};

export type SendTaskReminderInput = {
  message?: InputMaybe<Scalars['String']>;
  taskId: Scalars['uuid'];
};

export type SendTaskReminderOutput = {
  __typename?: 'SendTaskReminderOutput';
  success?: Maybe<Scalars['String']>;
};

export type SkillDescription = {
  __typename?: 'SkillDescription';
  description?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};

export type SkillResourceFilterInput = {
  availableFrom?: InputMaybe<Scalars['String']>;
  comptId: Scalars['uuid'];
  expLevel?: InputMaybe<Scalars['Int']>;
  goalRating?: InputMaybe<Scalars['Int']>;
  orgId: Scalars['uuid'];
  skill: Array<InputMaybe<ListOfSkillId>>;
  skillRating?: InputMaybe<Scalars['Int']>;
};

export type SkillResourceFilterOutput = {
  __typename?: 'SkillResourceFilterOutput';
  response?: Maybe<Array<Maybe<UserListSkill>>>;
};

export type Skill_Assessment_List = {
  __typename?: 'Skill_Assessment_List';
  assessment_details?: Maybe<Array<Maybe<User_Assessment_Details>>>;
  assessment_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Skill_Forecasting = {
  __typename?: 'Skill_Forecasting';
  assessment?: Maybe<Skill_Assessment_List>;
  deviated_emp?: Maybe<Deviated_Emp>;
  skill_name?: Maybe<Scalars['String']>;
  skilled_emp?: Maybe<Skilled_Emp>;
  total_emp?: Maybe<Scalars['Int']>;
  training?: Maybe<Skill_Training_List>;
};

export type Skill_Reporting = {
  __typename?: 'Skill_Reporting';
  assessment?: Maybe<User_Skill_Reporting_Assessment>;
  deviated_skill?: Maybe<User_Skill_Deviated>;
  ldp_journey?: Maybe<User_Skill_Reporting_Journey>;
  profile_pic?: Maybe<Scalars['String']>;
  training?: Maybe<User_Skill_Reporting_Training>;
  user_name?: Maybe<Scalars['String']>;
};

export type Skill_Training_List = {
  __typename?: 'Skill_Training_List';
  count?: Maybe<Scalars['Int']>;
  training_details?: Maybe<Array<Maybe<User_Training_Details>>>;
  training_names?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Skill_User_List = {
  __typename?: 'Skill_User_List';
  profile_pic?: Maybe<Scalars['String']>;
  role_title?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

export type Skilled_Emp = {
  __typename?: 'Skilled_Emp';
  percentage?: Maybe<Scalars['Int']>;
  skilled_count?: Maybe<Scalars['Int']>;
  user_list?: Maybe<Array<Maybe<Skill_User_List>>>;
};

export type Skilled_Skills = {
  __typename?: 'Skilled_Skills';
  skill_name?: Maybe<Scalars['String']>;
  skilled_percentage?: Maybe<Scalars['Int']>;
};

export type Skills = {
  __typename?: 'Skills';
  assessmentDetail: Array<Maybe<AssessmentDetailInfo>>;
  id?: Maybe<Scalars['uuid']>;
  skillDetail?: Maybe<Array<Maybe<SkillDescription>>>;
  skillId?: Maybe<Scalars['uuid']>;
  skillName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  trainingDetail: Array<Maybe<TrainingDetailInfo>>;
  userRating?: Maybe<Scalars['Int']>;
};

export type SkillsDescription = {
  __typename?: 'SkillsDescription';
  description?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};

export type SkillsUnderCompt = {
  rating?: InputMaybe<Scalars['Int']>;
  skillId: Scalars['uuid'];
};

export type Sprint = {
  __typename?: 'Sprint';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

export enum SprintCategory {
  Active = 'ACTIVE',
  Backlog = 'BACKLOG',
}

export type Stage_Selected_Assessment = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
};

export type Stage_Selected_Training = {
  order_line?: InputMaybe<Scalars['Int']>;
  training_id?: InputMaybe<Scalars['uuid']>;
};

export type Stages_Journey_Status = {
  __typename?: 'Stages_Journey_Status';
  stage_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type StartCallInput = {
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  media_type: StartCallMediaType;
  metadata?: InputMaybe<Scalars['jsonb']>;
  organization_id: Scalars['uuid'];
  users: Array<Scalars['String']>;
};

export enum StartCallMediaType {
  AudioOnly = 'AUDIO_ONLY',
  AudioVideo = 'AUDIO_VIDEO',
}

export type StartCallOutput = {
  __typename?: 'StartCallOutput';
  callId: Scalars['String'];
};

export type StartChatInput = {
  resource_id?: InputMaybe<Scalars['String']>;
  users: Array<Scalars['uuid']>;
};

export type StartChatOutput = {
  __typename?: 'StartChatOutput';
  chatRoomId: Scalars['String'];
};

export type StartLiveCallInput = {
  userId: Scalars['uuid'];
};

export type StartLiveCallOutput = {
  __typename?: 'StartLiveCallOutput';
  liveCallId: Scalars['uuid'];
};

export type StartMeetingChatInput = {
  meeting_id: Scalars['uuid'];
  meeting_name: Scalars['String'];
  meeting_type: Scalars['String'];
  users?: InputMaybe<Array<Scalars['uuid']>>;
};

export type StartMeetingChatOutput = {
  __typename?: 'StartMeetingChatOutput';
  chatRoomId: Scalars['String'];
};

export type StorageFile = {
  __typename?: 'StorageFile';
  enum_storage_file_type?: Maybe<StorageFileType>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

export type StorageFileDetail = {
  __typename?: 'StorageFileDetail';
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type StorageFileType = {
  __typename?: 'StorageFileType';
  value?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type SubTaskAggregateCount = {
  __typename?: 'SubTaskAggregateCount';
  count?: Maybe<Scalars['Int']>;
};

export type SubTasksAggregate = {
  __typename?: 'SubTasksAggregate';
  aggregate?: Maybe<SubTaskAggregateCount>;
};

export type SubmitLeaveInput = {
  leave_dates: Array<InputMaybe<Scalars['String']>>;
  leave_end_date?: InputMaybe<Scalars['String']>;
  leave_files_id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leave_reason?: InputMaybe<Scalars['String']>;
  leave_start_date?: InputMaybe<Scalars['String']>;
  leave_type_id: Scalars['String'];
  org_id: Scalars['String'];
};

export type SubmitLeaveOutput = {
  __typename?: 'SubmitLeaveOutput';
  new_employee_leave_id: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  name?: Maybe<Scalars['String']>;
};

export type TagsInsertInput = {
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
};

export type TagsObjRelInsertInput = {
  data?: InputMaybe<TagsInsertInput>;
};

export type TaskCollaboratorInsertInput = {
  task_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type TaskDependencies = {
  __typename?: 'TaskDependencies';
  dependent_on_task_id?: Maybe<Scalars['uuid']>;
};

export type TaskDetailsOutput = {
  __typename?: 'TaskDetailsOutput';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User>;
  due_date_time?: Maybe<Scalars['timestamptz']>;
  estimated_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  is_draft?: Maybe<Scalars['Boolean']>;
  is_recurring?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectOutput>;
  project_section?: Maybe<Section>;
  project_sprint?: Maybe<Sprint>;
  recurring_frequency?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  sub_tasks_aggregate?: Maybe<SubTasksAggregate>;
  taskDependenciesByTaskId?: Maybe<Array<Maybe<TaskDependencies>>>;
  task_files?: Maybe<Array<Maybe<TaskFile>>>;
  task_tags?: Maybe<Array<Maybe<TaskTag>>>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type TaskFile = {
  __typename?: 'TaskFile';
  storage_file?: Maybe<StorageFile>;
};

export type TaskFileInsertInput = {
  storage_file_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type TaskInput = {
  assignedTo?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  estimatedTime?: InputMaybe<Scalars['numeric']>;
  priority?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['uuid']>;
  recurringFrequency?: InputMaybe<Scalars['String']>;
  sectionId?: InputMaybe<Scalars['String']>;
  sprintId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subTaskOf?: InputMaybe<Scalars['uuid']>;
  title: Scalars['String'];
};

export type TaskMilestoneInsertInput = {
  milestone_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type TaskSetInput = {
  assigned_to?: InputMaybe<Scalars['uuid']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  due_date_time?: InputMaybe<Scalars['String']>;
  end_date_time?: InputMaybe<Scalars['String']>;
  estimated_time?: InputMaybe<Scalars['numeric']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  is_recurring?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  recurring_frequency?: InputMaybe<Scalars['String']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  sprint_id?: InputMaybe<Scalars['uuid']>;
  start_date_time?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['uuid']>;
};

export type TaskStorageFileDetails = {
  __typename?: 'TaskStorageFileDetails';
  file_url?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TaskTag = {
  __typename?: 'TaskTag';
  tag?: Maybe<Tag>;
  tag_id?: Maybe<Scalars['uuid']>;
};

export type TaskTagsInsertInput = {
  tag?: InputMaybe<TagsObjRelInsertInput>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  task_id: Scalars['uuid'];
};

export type TimePeriodInputs = {
  timePeriodInfo: Scalars['String'];
};

export type TimePeriodList = {
  __typename?: 'TimePeriodList';
  id: Scalars['uuid'];
  timePeriod: Scalars['String'];
};

export type TimePeriodListInput = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

export type TimePeriodListOutput = {
  __typename?: 'TimePeriodListOutput';
  response: Array<Maybe<TimePeriodRespList>>;
};

export type TimePeriodName = {
  id: Scalars['String'];
};

export type TimeSheet = {
  projectId: Scalars['uuid'];
  status?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  taskUnscheduledId?: InputMaybe<Scalars['uuid']>;
  timeSheetId?: InputMaybe<Scalars['uuid']>;
  workDate: Scalars['String'];
  workHours: Scalars['Float'];
  workMinutes?: InputMaybe<Scalars['Float']>;
};

export type TimeSheetTask = {
  projectId: Scalars['uuid'];
  taskId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  taskUnscheduledId?: InputMaybe<Scalars['uuid']>;
};

export type TimeSheetWeekDays = {
  status?: InputMaybe<Scalars['String']>;
  timeSheetId?: InputMaybe<Scalars['uuid']>;
  workDate: Scalars['String'];
  workHours: Scalars['Float'];
  workMinutes: Scalars['Float'];
};

export type TimeSheetWeekObject = {
  task: TimeSheetTask;
  weekdays: Array<TimeSheetWeekDays>;
};

export type ToggleChatNotificationInput = {
  chat_room_id: Scalars['String'];
  toggle_type: ChatNotificationStates;
};

export type ToggleChatNotificationOutput = {
  __typename?: 'ToggleChatNotificationOutput';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type TrainingData = {
  __typename?: 'TrainingData';
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type TrainingFeedbacksAggregate = {
  __typename?: 'TrainingFeedbacksAggregate';
  aggregate?: Maybe<AggregateInfo>;
  nodes?: Maybe<Array<Maybe<UserInfoNodes>>>;
};

export type TrainingInput = {
  duration?: InputMaybe<Scalars['Int']>;
  fileFormat: Scalars['String'];
  imageId: Scalars['uuid'];
  organizationId: Scalars['uuid'];
  rewards: Scalars['Int'];
  tags?: InputMaybe<Scalars['String']>;
  trainingName: Scalars['String'];
  trainingType: Scalars['String'];
  training_role_details: Array<InputMaybe<RoleInput>>;
  training_skills?: InputMaybe<Array<InputMaybe<TrainingSkillInput>>>;
  training_table_of_contents?: InputMaybe<Array<InputMaybe<TableContents>>>;
  userId: Scalars['uuid'];
};

export type TrainingProgressDetails = {
  __typename?: 'TrainingProgressDetails';
  docData?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TrainingProgressInput = {
  careerTrainingCheck?: InputMaybe<Scalars['Boolean']>;
  docData: Scalars['String'];
  progress: Scalars['String'];
  skillTrainingCheck?: InputMaybe<Scalars['Boolean']>;
  trainingId: Scalars['uuid'];
  type: Scalars['String'];
  userId: Scalars['uuid'];
  userSkillTrainingId?: InputMaybe<Scalars['uuid']>;
};

export type TrainingProgressOutput = {
  __typename?: 'TrainingProgressOutput';
  id: Scalars['String'];
};

export type TrainingSkillInput = {
  skillId?: InputMaybe<Scalars['uuid']>;
};

export type TransferOwnershipInput = {
  org_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type TransferOwnershipOutput = {
  __typename?: 'TransferOwnershipOutput';
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type TwoXTwoMatrixInput = {
  competencyId?: InputMaybe<Scalars['uuid']>;
  orgId?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

export type TwoXTwoMatrixOutput = {
  __typename?: 'TwoXTwoMatrixOutput';
  response?: Maybe<Array<Maybe<UserMatrixDetail>>>;
};

export type UpdateAssesmentRoleInput = {
  roleId: Scalars['uuid'];
};

export type UpdateAssessmentInput = {
  active_till: Scalars['Int'];
  assessment_role_details: Array<InputMaybe<UpdateAssesmentRoleInput>>;
  assessment_skills?: InputMaybe<Array<InputMaybe<UpdateAssessmentSkill>>>;
  assessment_type_id: Scalars['uuid'];
  department_id?: InputMaybe<Scalars['uuid']>;
  id: Scalars['String'];
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  passing_score: Scalars['Int'];
  questions: Array<UpdateQuestionInput>;
  recommended_training: Scalars['String'];
  recordData: Array<InputMaybe<Assessmentrecord>>;
  role_id?: InputMaybe<Scalars['uuid']>;
  status: Scalars['Boolean'];
  tags?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type UpdateAssessmentOutput = {
  __typename?: 'UpdateAssessmentOutput';
  id: Scalars['uuid'];
};

export type UpdateAssessmentSkill = {
  skillId?: InputMaybe<Scalars['uuid']>;
};

export type UpdateCalendarEventInput = {
  add_attendees?: InputMaybe<Array<InputMaybe<Attendee>>>;
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  all_day?: InputMaybe<Scalars['Boolean']>;
  calendar_id?: InputMaybe<Scalars['String']>;
  delete_attendees?: InputMaybe<Array<InputMaybe<Attendee>>>;
  end_date_time?: InputMaybe<Scalars['String']>;
  event_name?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  rrule?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['String']>;
};

export type UpdateCalendarEventOutput = {
  __typename?: 'UpdateCalendarEventOutput';
  id: Scalars['uuid'];
};

export type UpdateCareerDevelopmentPlanInput = {
  career_stage: Array<Update_List_Of_Career_Stage>;
  cover_image_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_certification?: InputMaybe<
    Array<InputMaybe<Update_List_Of_External_Certification>>
  >;
  id: Scalars['uuid'];
  org_id?: InputMaybe<Scalars['uuid']>;
  role: Array<Update_Career_Selected_Roles>;
  title: Scalars['String'];
};

export type UpdateCareerDevelopmentPlanOutput = {
  __typename?: 'UpdateCareerDevelopmentPlanOutput';
  id: Scalars['uuid'];
};

export type UpdateChecklistInput = {
  id: Scalars['uuid'];
  isApproved?: InputMaybe<Scalars['Boolean']>;
  itemName?: InputMaybe<Scalars['String']>;
  operationName: Scalars['String'];
};

export type UpdateChecklistOutput = {
  __typename?: 'UpdateChecklistOutput';
  id: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

export type UpdateDepartmentUuidInput = {
  department_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type UpdateDepartmentUuidInputs = {
  department_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type UpdateDependentTaskInput = {
  dependent_on_task_ids?: InputMaybe<Array<Scalars['uuid']>>;
  due_date_time?: InputMaybe<Scalars['String']>;
  removed_dependent_on_task_ids?: InputMaybe<Array<Scalars['uuid']>>;
  start_date_time?: InputMaybe<Scalars['String']>;
  task_id: Scalars['uuid'];
};

export type UpdateDependentTaskOutput = {
  __typename?: 'UpdateDependentTaskOutput';
  dependentTaskIds?: Maybe<Array<Scalars['uuid']>>;
  success: Scalars['Boolean'];
};

export type UpdateGoalInput = {
  createdId: Scalars['uuid'];
  department?: InputMaybe<Array<UpdateDepartmentUuidInputs>>;
  description: Scalars['String'];
  different_weightage_role?: InputMaybe<
    Array<InputMaybe<UpdateGoalRoalWeightageDifferentInput>>
  >;
  id: Scalars['uuid'];
  manager_id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  performance_Id: Scalars['uuid'];
  performance_timePeriod_Id: Array<UpdatePerforamanceReviewtimePeriodInputId>;
  role?: InputMaybe<Array<UpdateRoleUuidInputs>>;
  role_weightage?: InputMaybe<Array<InputMaybe<UpdateGoalRoleWeightageInput>>>;
  selected_Assign_Weightage_Opt?: InputMaybe<Scalars['String']>;
  single_weightage_all_role?: InputMaybe<Scalars['String']>;
  timePeriods?: InputMaybe<Array<UpdateTimePeriodInputs>>;
  title: Scalars['String'];
};

export type UpdateGoalOutput = {
  __typename?: 'UpdateGoalOutput';
  id: Scalars['uuid'];
};

export type UpdateGoalRoalWeightageDifferentInput = {
  role_id?: InputMaybe<Scalars['uuid']>;
  role_weightage?: InputMaybe<Scalars['String']>;
};

export type UpdateGoalRoleWeightageInput = {
  role_id?: InputMaybe<Scalars['uuid']>;
  time_period?: InputMaybe<
    Array<InputMaybe<UpdateSelectedGoalTimePeriodOption>>
  >;
};

export type UpdateKpiInput = {
  department?: InputMaybe<Array<UpdateDepartmentUuidInput>>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  manager_id: Scalars['uuid'];
  openEndedKpi: Scalars['Boolean'];
  role?: InputMaybe<Array<UpdateRoleUuidInput>>;
  title: Scalars['String'];
};

export type UpdateKpiOutput = {
  __typename?: 'UpdateKpiOutput';
  id: Scalars['uuid'];
};

export type UpdateMilestoneInput = {
  date?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: InputMaybe<Scalars['String']>;
  operationName: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  taskIds?: InputMaybe<Array<Scalars['uuid']>>;
};

export type UpdateMilestoneOutput = {
  __typename?: 'UpdateMilestoneOutput';
  date?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskIds?: Maybe<Array<Scalars['uuid']>>;
};

export type UpdateMultipleTasksInput = {
  assigned_to?: InputMaybe<Scalars['uuid']>;
  due_date_time?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['String']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  sprint_id?: InputMaybe<Scalars['uuid']>;
};

export type UpdateMultipleTasksOutput = {
  __typename?: 'UpdateMultipleTasksOutput';
  message: Scalars['String'];
  updated_ids: Array<Maybe<Scalars['uuid']>>;
};

export type UpdatePerforamanceReviewtimePeriodInputId = {
  id?: InputMaybe<Scalars['uuid']>;
  performaceReview_Timeperiod_Id: Scalars['uuid'];
};

export type UpdatePeriodInput = {
  endDate: Scalars['date'];
  endingDate: Scalars['date'];
  period: Scalars['String'];
  startDate: Scalars['date'];
  startingDate: Scalars['date'];
  title: Scalars['String'];
};

export type UpdatePerofrmanceReviewInput = {
  copy_previous_year_goals?: InputMaybe<Scalars['Boolean']>;
  end: Scalars['String'];
  frequency: Scalars['String'];
  id: Scalars['uuid'];
  instruction: Scalars['String'];
  manager_review?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  org_goal_share?: InputMaybe<Scalars['String']>;
  organization_Id: Scalars['uuid'];
  peer_review?: InputMaybe<Scalars['String']>;
  rating_scale?: InputMaybe<Array<InputMaybe<Update_Rating_Scale_Info>>>;
  self_review?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
  timePeriod: Array<UpdatePeriodInput>;
  view_option?: InputMaybe<Scalars['Boolean']>;
  year: Scalars['String'];
};

export type UpdatePerofrmanceReviewOutput = {
  __typename?: 'UpdatePerofrmanceReviewOutput';
  id: Scalars['uuid'];
};

export type UpdateQuestionInput = {
  id: Scalars['uuid'];
  image_id?: InputMaybe<Scalars['uuid']>;
  questionName: Scalars['String'];
  questionOptions: Array<UpdateQuestionOptionInput>;
  questionType?: InputMaybe<Scalars['String']>;
};

export type UpdateQuestionOptionInput = {
  correct_answer: Scalars['Boolean'];
  name: Scalars['String'];
};

export type UpdateRoleUuidInput = {
  id?: InputMaybe<Scalars['uuid']>;
  role_Id?: InputMaybe<Scalars['uuid']>;
};

export type UpdateRoleUuidInputs = {
  id?: InputMaybe<Scalars['uuid']>;
  role_Id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  weightage?: InputMaybe<Scalars['Int']>;
};

export type UpdateSelectedGoalTimePeriodOption = {
  time_period?: InputMaybe<Scalars['String']>;
  weightage?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskApproversInput = {
  approvers: Array<Scalars['uuid']>;
  operationName: Scalars['String'];
  taskId: Scalars['uuid'];
};

export type UpdateTaskApproversOutput = {
  __typename?: 'UpdateTaskApproversOutput';
  approverIds: Array<Maybe<Scalars['uuid']>>;
};

export type UpdateTaskOutput = {
  __typename?: 'UpdateTaskOutput';
  success: Scalars['Boolean'];
};

export type UpdateTaskRankInput = {
  draggedTaskId: Scalars['uuid'];
  insertPosition: Scalars['String'];
  priority?: InputMaybe<Scalars['String']>;
  projectId: Scalars['uuid'];
  refTaskId?: InputMaybe<Scalars['uuid']>;
  sectionId?: InputMaybe<Scalars['uuid']>;
  sprintId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskRankOutput = {
  __typename?: 'UpdateTaskRankOutput';
  success: Scalars['Boolean'];
};

export type UpdateTimePeriodInputs = {
  id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

export type UpdateTrainingSkillsInput = {
  skillId?: InputMaybe<Scalars['uuid']>;
};

export type Update_Career_Selected_Roles = {
  role_id?: InputMaybe<Scalars['uuid']>;
};

export type Update_List_Of_Career_Stage = {
  assessment_stage?: InputMaybe<
    Array<InputMaybe<Update_Stage_Selected_Assessment>>
  >;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_title?: InputMaybe<Scalars['String']>;
  training_stage?: InputMaybe<
    Array<InputMaybe<Update_Stage_Selected_Training>>
  >;
};

export type Update_List_Of_External_Certification = {
  certification_link?: InputMaybe<Scalars['String']>;
};

export type Update_Rating_Scale_Info = {
  description?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
};

export type Update_Stage_Selected_Assessment = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
};

export type Update_Stage_Selected_Training = {
  order_line?: InputMaybe<Scalars['Int']>;
  training_id?: InputMaybe<Scalars['uuid']>;
};

export type User = {
  __typename?: 'User';
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

export type UserCareerFlowDetail = {
  __typename?: 'UserCareerFlowDetail';
  assessmentCount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  external_certification?: Maybe<Array<Maybe<External_Certification_Details>>>;
  id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['Int']>;
  stage_details?: Maybe<Array<Maybe<UserCareerStageDetails>>>;
  storage_file?: Maybe<CoverImageDetail>;
  title?: Maybe<Scalars['String']>;
  trainingCount?: Maybe<Scalars['Int']>;
};

export type UserCareerStageDetails = {
  __typename?: 'UserCareerStageDetails';
  stage_name?: Maybe<Scalars['String']>;
  stage_status?: Maybe<Scalars['String']>;
  taskDetail?: Maybe<Array<Maybe<UserCareerStageTasksDetails>>>;
  totalCount?: Maybe<Scalars['String']>;
};

export type UserCareerStageTasksDetails = {
  __typename?: 'UserCareerStageTasksDetails';
  assessment_id?: Maybe<Scalars['uuid']>;
  assessment_type?: Maybe<Scalars['String']>;
  docData?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  question_list?: Maybe<Scalars['Int']>;
  skill_detail?: Maybe<Array<Maybe<AssessmentTrainingSkills>>>;
  status?: Maybe<Scalars['String']>;
  storage_file?: Maybe<TaskStorageFileDetails>;
  training_id?: Maybe<Scalars['uuid']>;
  training_progress_records?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type UserDetailScoreInfo = {
  __typename?: 'UserDetailScoreInfo';
  comments?: Maybe<Scalars['String']>;
  marks?: Maybe<Scalars['Int']>;
  profileImage?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type UserDetailedInfo = {
  __typename?: 'UserDetailedInfo';
  currentProject?: Maybe<Scalars['String']>;
  lastTaskDate?: Maybe<Scalars['String']>;
  projectAge?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['String']>;
};

export type UserGoalScoreSheet = {
  __typename?: 'UserGoalScoreSheet';
  goal_Id?: Maybe<Scalars['uuid']>;
  goal_weightage_marks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  kpiDetail?: Maybe<UserKpiScoreSheet>;
  marks?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type UserInfoData = {
  __typename?: 'UserInfoData';
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  profile?: Maybe<UserProfileInfo>;
};

export type UserInfoNodes = {
  __typename?: 'UserInfoNodes';
  user?: Maybe<UserInfoData>;
};

export type UserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type UserKpiScoreSheet = {
  __typename?: 'UserKpiScoreSheet';
  id?: Maybe<Scalars['uuid']>;
  kpi_id?: Maybe<Scalars['uuid']>;
  kpi_title?: Maybe<Scalars['String']>;
  open_ended_kpi?: Maybe<Scalars['Boolean']>;
  userDetails?: Maybe<Array<Maybe<UserDetailScoreInfo>>>;
};

export type UserList = {
  __typename?: 'UserList';
  profileImage?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  skillCount?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
  userName?: Maybe<Scalars['String']>;
};

export type UserListResp = {
  __typename?: 'UserListResp';
  approvedList?: Maybe<Array<Maybe<UserList>>>;
  pendingList?: Maybe<Array<Maybe<UserList>>>;
};

export type UserListSkill = {
  __typename?: 'UserListSkill';
  allocation?: Maybe<Scalars['Int']>;
  detail?: Maybe<Array<Maybe<UserDetailedInfo>>>;
  matchScore?: Maybe<Scalars['Int']>;
  profileImage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
  userName?: Maybe<Scalars['String']>;
};

export type UserMatrixDetail = {
  __typename?: 'UserMatrixDetail';
  id?: Maybe<Scalars['uuid']>;
  performaceMarks?: Maybe<Scalars['Int']>;
  potentialMarks?: Maybe<Scalars['Int']>;
  profileImage?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type UserProfileInfo = {
  __typename?: 'UserProfileInfo';
  activity_status?: Maybe<Scalars['String']>;
  avatar_file?: Maybe<AvatarFileInfo>;
};

export type UserScoreSheetResponse = {
  __typename?: 'UserScoreSheetResponse';
  goalDetail?: Maybe<Array<Maybe<UserGoalScoreSheet>>>;
  id?: Maybe<Scalars['uuid']>;
  rating_scale?: Maybe<Scalars['Int']>;
  timePeriod?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  viewOption?: Maybe<Scalars['String']>;
};

export type UserSkillResponse = {
  __typename?: 'UserSkillResponse';
  action?: Maybe<Scalars['String']>;
  assessmentCount?: Maybe<Scalars['Int']>;
  assessmentData?: Maybe<Array<Maybe<AssessmentData>>>;
  competencyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  reviseSkill?: Maybe<Array<Maybe<SkillsDescription>>>;
  skillName?: Maybe<Scalars['String']>;
  trainingCount?: Maybe<Scalars['Int']>;
  trainingData?: Maybe<Array<Maybe<TrainingData>>>;
  userRating?: Maybe<Scalars['Int']>;
  userSkillId?: Maybe<Scalars['uuid']>;
};

export type UserSkillsDetail = {
  __typename?: 'UserSkillsDetail';
  skillId?: Maybe<Scalars['uuid']>;
  skillName?: Maybe<Scalars['String']>;
  userRating?: Maybe<Scalars['Int']>;
};

export type User_Assessment_Details = {
  __typename?: 'User_Assessment_Details';
  assessment_name?: Maybe<Scalars['String']>;
  completedBy?: Maybe<Array<Maybe<User_Data_Info>>>;
  failed_CompletedBy?: Maybe<Array<Maybe<User_Data_Info>>>;
  not_CompletedBy?: Maybe<Array<Maybe<User_Data_Info>>>;
  pass_percentage?: Maybe<Scalars['Int']>;
};

export type User_Assessment_Details_Info = {
  __typename?: 'User_Assessment_Details_Info';
  assessment_name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type User_Career_Development_Dashboard = {
  __typename?: 'User_Career_Development_Dashboard';
  assessmentCount?: Maybe<Scalars['Int']>;
  completedBy?: Maybe<Array<Maybe<User_Completed_Career>>>;
  createdBy?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['Int']>;
  stages?: Maybe<Array<Maybe<User_Stage_Detail>>>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trainingCount?: Maybe<Scalars['Int']>;
};

export type User_Completed_Career = {
  __typename?: 'User_Completed_Career';
  display_name?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
};

export type User_Data_Info = {
  __typename?: 'User_Data_Info';
  name?: Maybe<Scalars['String']>;
  profile_pic?: Maybe<Scalars['String']>;
};

export type User_Joureny_Details_Info = {
  __typename?: 'User_Joureny_Details_Info';
  career_name?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Stages_Journey_Status>>>;
};

/** columns and relationships of "User_Peer_Review" */
export type User_Peer_Review = {
  __typename?: 'User_Peer_Review';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  peer_Id: Scalars['uuid'];
  performanceReviewByUser_Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  performance_review_byUser?: Maybe<Performance_Review_ByUser>;
  status?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByUserId: Users;
  user_Id: Scalars['uuid'];
};

/** aggregated selection of "User_Peer_Review" */
export type User_Peer_Review_Aggregate = {
  __typename?: 'User_Peer_Review_aggregate';
  aggregate?: Maybe<User_Peer_Review_Aggregate_Fields>;
  nodes: Array<User_Peer_Review>;
};

export type User_Peer_Review_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Peer_Review_Aggregate_Bool_Exp_Count>;
};

export type User_Peer_Review_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Peer_Review_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "User_Peer_Review" */
export type User_Peer_Review_Aggregate_Fields = {
  __typename?: 'User_Peer_Review_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Peer_Review_Max_Fields>;
  min?: Maybe<User_Peer_Review_Min_Fields>;
};

/** aggregate fields of "User_Peer_Review" */
export type User_Peer_Review_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "User_Peer_Review" */
export type User_Peer_Review_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Peer_Review_Max_Order_By>;
  min?: InputMaybe<User_Peer_Review_Min_Order_By>;
};

/** input type for inserting array relation for remote table "User_Peer_Review" */
export type User_Peer_Review_Arr_Rel_Insert_Input = {
  data: Array<User_Peer_Review_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Peer_Review_On_Conflict>;
};

/** Boolean expression to filter rows from the table "User_Peer_Review". All fields are combined with a logical 'AND'. */
export type User_Peer_Review_Bool_Exp = {
  _and?: InputMaybe<Array<User_Peer_Review_Bool_Exp>>;
  _not?: InputMaybe<User_Peer_Review_Bool_Exp>;
  _or?: InputMaybe<Array<User_Peer_Review_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  peer_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performanceReviewByUser_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  timePeriod?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByUserId?: InputMaybe<Users_Bool_Exp>;
  user_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "User_Peer_Review" */
export enum User_Peer_Review_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPeerReviewPkey = 'User_Peer_Review_pkey',
}

/** input type for inserting data into table "User_Peer_Review" */
export type User_Peer_Review_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  peer_Id?: InputMaybe<Scalars['uuid']>;
  performanceReviewByUser_Id?: InputMaybe<Scalars['uuid']>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Peer_Review_Max_Fields = {
  __typename?: 'User_Peer_Review_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  peer_Id?: Maybe<Scalars['uuid']>;
  performanceReviewByUser_Id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  user_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "User_Peer_Review" */
export type User_Peer_Review_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  peer_Id?: InputMaybe<Order_By>;
  performanceReviewByUser_Id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Peer_Review_Min_Fields = {
  __typename?: 'User_Peer_Review_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  peer_Id?: Maybe<Scalars['uuid']>;
  performanceReviewByUser_Id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  user_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "User_Peer_Review" */
export type User_Peer_Review_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  peer_Id?: InputMaybe<Order_By>;
  performanceReviewByUser_Id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "User_Peer_Review" */
export type User_Peer_Review_Mutation_Response = {
  __typename?: 'User_Peer_Review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Peer_Review>;
};

/** on_conflict condition type for table "User_Peer_Review" */
export type User_Peer_Review_On_Conflict = {
  constraint: User_Peer_Review_Constraint;
  update_columns?: Array<User_Peer_Review_Update_Column>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "User_Peer_Review". */
export type User_Peer_Review_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  peer_Id?: InputMaybe<Order_By>;
  performanceReviewByUser_Id?: InputMaybe<Order_By>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Order_By>;
  status?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByUserId?: InputMaybe<Users_Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: User_Peer_Review */
export type User_Peer_Review_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "User_Peer_Review" */
export enum User_Peer_Review_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PeerId = 'peer_Id',
  /** column name */
  PerformanceReviewByUserId = 'performanceReviewByUser_Id',
  /** column name */
  Status = 'status',
  /** column name */
  TimePeriod = 'timePeriod',
  /** column name */
  UserId = 'user_Id',
}

/** input type for updating data in table "User_Peer_Review" */
export type User_Peer_Review_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  peer_Id?: InputMaybe<Scalars['uuid']>;
  performanceReviewByUser_Id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "User_Peer_Review" */
export type User_Peer_Review_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Peer_Review_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Peer_Review_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  peer_Id?: InputMaybe<Scalars['uuid']>;
  performanceReviewByUser_Id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "User_Peer_Review" */
export enum User_Peer_Review_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PeerId = 'peer_Id',
  /** column name */
  PerformanceReviewByUserId = 'performanceReviewByUser_Id',
  /** column name */
  Status = 'status',
  /** column name */
  TimePeriod = 'timePeriod',
  /** column name */
  UserId = 'user_Id',
}

export type User_Peer_Review_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Peer_Review_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Peer_Review_Bool_Exp;
};

export type User_Skill_Deviated = {
  __typename?: 'User_Skill_Deviated';
  count?: Maybe<Scalars['String']>;
  skill_details?: Maybe<Array<Maybe<User_Skill_Deviated_Info>>>;
  skill_names?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type User_Skill_Deviated_Info = {
  __typename?: 'User_Skill_Deviated_Info';
  deviation?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  skill_name?: Maybe<Scalars['String']>;
};

export type User_Skill_Reporting_Assessment = {
  __typename?: 'User_Skill_Reporting_Assessment';
  assessment_details?: Maybe<Array<Maybe<User_Assessment_Details_Info>>>;
  assessment_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['String']>;
};

export type User_Skill_Reporting_Journey = {
  __typename?: 'User_Skill_Reporting_Journey';
  career_details?: Maybe<Array<Maybe<User_Joureny_Details_Info>>>;
  career_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Int']>;
};

export type User_Skill_Reporting_Training = {
  __typename?: 'User_Skill_Reporting_Training';
  count?: Maybe<Scalars['String']>;
  training_details?: Maybe<Array<Maybe<User_Training_Details_Info>>>;
  training_names?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type User_Stage_Detail = {
  __typename?: 'User_Stage_Detail';
  stageName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type User_Training_Details = {
  __typename?: 'User_Training_Details';
  completedBy?: Maybe<Array<Maybe<User_Data_Info>>>;
  not_CompletedBy?: Maybe<Array<Maybe<User_Data_Info>>>;
  training_name?: Maybe<Scalars['String']>;
};

export type User_Training_Details_Info = {
  __typename?: 'User_Training_Details_Info';
  status?: Maybe<Scalars['String']>;
  training_name?: Maybe<Scalars['String']>;
};

export type ViewStatsResponse = {
  __typename?: 'ViewStatsResponse';
  count?: Maybe<Scalars['Int']>;
  deviated_skills?: Maybe<Array<Maybe<Deviated_Skills>>>;
  skill_forecasting?: Maybe<Array<Maybe<Skill_Forecasting>>>;
  skill_reporting?: Maybe<Array<Maybe<Skill_Reporting>>>;
  skilled_skills?: Maybe<Array<Maybe<Skilled_Skills>>>;
};

export type AddCommentInput = {
  comment: Scalars['String'];
  comment_id?: InputMaybe<Scalars['String']>;
  file_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  is_reply?: InputMaybe<Scalars['Boolean']>;
  mention_users?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  post_id: Scalars['String'];
};

export type AddCommentOutput = {
  __typename?: 'addCommentOutput';
  comment_id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  post_id?: Maybe<Scalars['String']>;
};

/** Table represent a residential/business address */
export type Address = {
  __typename?: 'address';
  additional_fields?: Maybe<Scalars['jsonb']>;
  address_line: Scalars['String'];
  country: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  landmark?: Maybe<Scalars['String']>;
  /** An array relationship */
  organization_locations: Array<Organization_Locations>;
  /** An aggregate relationship */
  organization_locations_aggregate: Organization_Locations_Aggregate;
  /** An array relationship */
  organizations: Array<Organization>;
  /** An aggregate relationship */
  organizations_aggregate: Organization_Aggregate;
  pin_code: Scalars['String'];
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregate relationship */
  profiles_aggregate: Profile_Aggregate;
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Table represent a residential/business address */
export type AddressAdditional_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Table represent a residential/business address */
export type AddressOrganization_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

/** Table represent a residential/business address */
export type AddressOrganization_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

/** Table represent a residential/business address */
export type AddressOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Table represent a residential/business address */
export type AddressOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Table represent a residential/business address */
export type AddressProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Table represent a residential/business address */
export type AddressProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Address_Append_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  additional_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  address_line?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  landmark?: InputMaybe<String_Comparison_Exp>;
  organization_locations?: InputMaybe<Organization_Locations_Bool_Exp>;
  organization_locations_aggregate?: InputMaybe<Organization_Locations_Aggregate_Bool_Exp>;
  organizations?: InputMaybe<Organization_Bool_Exp>;
  organizations_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
  pin_code?: InputMaybe<String_Comparison_Exp>;
  profiles?: InputMaybe<Profile_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Address_Delete_At_Path_Input = {
  additional_fields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Address_Delete_Elem_Input = {
  additional_fields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Address_Delete_Key_Input = {
  additional_fields?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address_line?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  landmark?: InputMaybe<Scalars['String']>;
  organization_locations?: InputMaybe<Organization_Locations_Arr_Rel_Insert_Input>;
  organizations?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
  pin_code?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  address_line?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  landmark?: Maybe<Scalars['String']>;
  pin_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  address_line?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  landmark?: Maybe<Scalars['String']>;
  pin_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  additional_fields?: InputMaybe<Order_By>;
  address_line?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landmark?: InputMaybe<Order_By>;
  organization_locations_aggregate?: InputMaybe<Organization_Locations_Aggregate_Order_By>;
  organizations_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
  pin_code?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Address_Prepend_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AddressLine = 'address_line',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Landmark = 'landmark',
  /** column name */
  PinCode = 'pin_code',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address_line?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  landmark?: InputMaybe<Scalars['String']>;
  pin_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address_line?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  landmark?: InputMaybe<Scalars['String']>;
  pin_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AddressLine = 'address_line',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Landmark = 'landmark',
  /** column name */
  PinCode = 'pin_code',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Address_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Address_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Address_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Address_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Address_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Address_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

export type AllAnswers = {
  __typename?: 'allAnswers';
  correct_answer: Scalars['String'];
  given_answer: Scalars['String'];
  question: AllQuestions;
};

/** assessment  */
export type Assessment = {
  __typename?: 'assessment';
  active_till: Scalars['Int'];
  /** An array relationship */
  assessment_Skills: Array<Assessment_Skills>;
  /** An aggregate relationship */
  assessment_Skills_aggregate: Assessment_Skills_Aggregate;
  /** An array relationship */
  assessment_feedbacks: Array<Assessment_Feedback>;
  /** An aggregate relationship */
  assessment_feedbacks_aggregate: Assessment_Feedback_Aggregate;
  /** An array relationship */
  assessment_records: Array<Assessment_Record>;
  /** An aggregate relationship */
  assessment_records_aggregate: Assessment_Record_Aggregate;
  /** An array relationship */
  assessment_role_details: Array<Assessment_Role_Details>;
  /** An aggregate relationship */
  assessment_role_details_aggregate: Assessment_Role_Details_Aggregate;
  /** An array relationship */
  assessment_stage_careers: Array<Assessment_Stage_Career>;
  /** An aggregate relationship */
  assessment_stage_careers_aggregate: Assessment_Stage_Career_Aggregate;
  /** An object relationship */
  assessment_type: Assessment_Type;
  assessment_type_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  department?: Maybe<Department>;
  department_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  organization_role?: Maybe<Organization_Roles>;
  passing_score: Scalars['float8'];
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  recommended_training?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['uuid']>;
  status: Scalars['Boolean'];
  tags?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An array relationship */
  user_Skills_Assessments: Array<User_Skills_Assessment>;
  /** An aggregate relationship */
  user_Skills_Assessments_aggregate: User_Skills_Assessment_Aggregate;
  user_id: Scalars['uuid'];
};

/** assessment  */
export type AssessmentAssessment_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Skills_Order_By>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Skills_Order_By>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_Stage_CareersArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Stage_Career_Order_By>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

/** assessment  */
export type AssessmentAssessment_Stage_Careers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Stage_Career_Order_By>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

/** assessment  */
export type AssessmentQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** assessment  */
export type AssessmentQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** assessment  */
export type AssessmentUser_Skills_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Assessment_Order_By>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

/** assessment  */
export type AssessmentUser_Skills_Assessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Assessment_Order_By>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

export type AssessmentDetailInfo = {
  __typename?: 'assessmentDetailInfo';
  assessmentId?: Maybe<Scalars['uuid']>;
  assessmentName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

export type AssessmentTypeWiseData = {
  __typename?: 'assessmentTypeWiseData';
  assessmentType?: Maybe<Scalars['String']>;
  assessmentTypeId?: Maybe<Scalars['String']>;
  deviatedData?: Maybe<Scalars['Int']>;
  skilledData?: Maybe<Scalars['Int']>;
  totalDeviated?: Maybe<Scalars['Int']>;
  totalSkilled?: Maybe<Scalars['Int']>;
  userCount?: Maybe<Scalars['Int']>;
  userId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** columns and relationships of "assessment_Skills" */
export type Assessment_Skills = {
  __typename?: 'assessment_Skills';
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  assessment_Id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  list_Of_Skill?: Maybe<List_Of_Skills>;
  list_of_Skill_Id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "assessment_Skills" */
export type Assessment_Skills_Aggregate = {
  __typename?: 'assessment_Skills_aggregate';
  aggregate?: Maybe<Assessment_Skills_Aggregate_Fields>;
  nodes: Array<Assessment_Skills>;
};

export type Assessment_Skills_Aggregate_Bool_Exp = {
  count?: InputMaybe<Assessment_Skills_Aggregate_Bool_Exp_Count>;
};

export type Assessment_Skills_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Skills_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assessment_Skills" */
export type Assessment_Skills_Aggregate_Fields = {
  __typename?: 'assessment_Skills_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Assessment_Skills_Max_Fields>;
  min?: Maybe<Assessment_Skills_Min_Fields>;
};

/** aggregate fields of "assessment_Skills" */
export type Assessment_Skills_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment_Skills" */
export type Assessment_Skills_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Skills_Max_Order_By>;
  min?: InputMaybe<Assessment_Skills_Min_Order_By>;
};

/** input type for inserting array relation for remote table "assessment_Skills" */
export type Assessment_Skills_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Skills_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Skills_On_Conflict>;
};

/** Boolean expression to filter rows from the table "assessment_Skills". All fields are combined with a logical 'AND'. */
export type Assessment_Skills_Bool_Exp = {
  _and?: InputMaybe<Array<Assessment_Skills_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Skills_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Skills_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  assessment_Id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Bool_Exp>;
  list_of_Skill_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "assessment_Skills" */
export enum Assessment_Skills_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssessmentSkillsPkey = 'assessment_Skills_pkey',
}

/** input type for inserting data into table "assessment_Skills" */
export type Assessment_Skills_Insert_Input = {
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  assessment_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Obj_Rel_Insert_Input>;
  list_of_Skill_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Assessment_Skills_Max_Fields = {
  __typename?: 'assessment_Skills_max_fields';
  assessment_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  list_of_Skill_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assessment_Skills" */
export type Assessment_Skills_Max_Order_By = {
  assessment_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list_of_Skill_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Skills_Min_Fields = {
  __typename?: 'assessment_Skills_min_fields';
  assessment_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  list_of_Skill_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assessment_Skills" */
export type Assessment_Skills_Min_Order_By = {
  assessment_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list_of_Skill_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assessment_Skills" */
export type Assessment_Skills_Mutation_Response = {
  __typename?: 'assessment_Skills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment_Skills>;
};

/** on_conflict condition type for table "assessment_Skills" */
export type Assessment_Skills_On_Conflict = {
  constraint: Assessment_Skills_Constraint;
  update_columns?: Array<Assessment_Skills_Update_Column>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment_Skills". */
export type Assessment_Skills_Order_By = {
  assessment?: InputMaybe<Assessment_Order_By>;
  assessment_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Order_By>;
  list_of_Skill_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assessment_Skills */
export type Assessment_Skills_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assessment_Skills" */
export enum Assessment_Skills_Select_Column {
  /** column name */
  AssessmentId = 'assessment_Id',
  /** column name */
  Id = 'id',
  /** column name */
  ListOfSkillId = 'list_of_Skill_Id',
}

/** input type for updating data in table "assessment_Skills" */
export type Assessment_Skills_Set_Input = {
  assessment_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  list_of_Skill_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "assessment_Skills" */
export type Assessment_Skills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Skills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Skills_Stream_Cursor_Value_Input = {
  assessment_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  list_of_Skill_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "assessment_Skills" */
export enum Assessment_Skills_Update_Column {
  /** column name */
  AssessmentId = 'assessment_Id',
  /** column name */
  Id = 'id',
  /** column name */
  ListOfSkillId = 'list_of_Skill_Id',
}

export type Assessment_Skills_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Skills_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Skills_Bool_Exp;
};

/** aggregated selection of "assessment" */
export type Assessment_Aggregate = {
  __typename?: 'assessment_aggregate';
  aggregate?: Maybe<Assessment_Aggregate_Fields>;
  nodes: Array<Assessment>;
};

export type Assessment_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Assessment_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Assessment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Assessment_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Assessment_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Assessment_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Assessment_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Assessment_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Assessment_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Assessment_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Assessment_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Assessment_Aggregate_Bool_Exp_Var_Samp>;
};

export type Assessment_Aggregate_Bool_Exp_Avg = {
  arguments: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Corr = {
  arguments: Assessment_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Assessment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Assessment_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Assessment_Aggregate_Bool_Exp_Max = {
  arguments: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Min = {
  arguments: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Sum = {
  arguments: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "assessment" */
export type Assessment_Aggregate_Fields = {
  __typename?: 'assessment_aggregate_fields';
  avg?: Maybe<Assessment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assessment_Max_Fields>;
  min?: Maybe<Assessment_Min_Fields>;
  stddev?: Maybe<Assessment_Stddev_Fields>;
  stddev_pop?: Maybe<Assessment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assessment_Stddev_Samp_Fields>;
  sum?: Maybe<Assessment_Sum_Fields>;
  var_pop?: Maybe<Assessment_Var_Pop_Fields>;
  var_samp?: Maybe<Assessment_Var_Samp_Fields>;
  variance?: Maybe<Assessment_Variance_Fields>;
};

/** aggregate fields of "assessment" */
export type Assessment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment" */
export type Assessment_Aggregate_Order_By = {
  avg?: InputMaybe<Assessment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Max_Order_By>;
  min?: InputMaybe<Assessment_Min_Order_By>;
  stddev?: InputMaybe<Assessment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Assessment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Assessment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Assessment_Sum_Order_By>;
  var_pop?: InputMaybe<Assessment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Assessment_Var_Samp_Order_By>;
  variance?: InputMaybe<Assessment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assessment" */
export type Assessment_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_On_Conflict>;
};

/** aggregate avg on columns */
export type Assessment_Avg_Fields = {
  __typename?: 'assessment_avg_fields';
  active_till?: Maybe<Scalars['Float']>;
  passing_score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assessment" */
export type Assessment_Avg_Order_By = {
  active_till?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assessment". All fields are combined with a logical 'AND'. */
export type Assessment_Bool_Exp = {
  _and?: InputMaybe<Array<Assessment_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Bool_Exp>>;
  active_till?: InputMaybe<Int_Comparison_Exp>;
  assessment_Skills?: InputMaybe<Assessment_Skills_Bool_Exp>;
  assessment_Skills_aggregate?: InputMaybe<Assessment_Skills_Aggregate_Bool_Exp>;
  assessment_feedbacks?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  assessment_feedbacks_aggregate?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp>;
  assessment_records?: InputMaybe<Assessment_Record_Bool_Exp>;
  assessment_records_aggregate?: InputMaybe<Assessment_Record_Aggregate_Bool_Exp>;
  assessment_role_details?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
  assessment_role_details_aggregate?: InputMaybe<Assessment_Role_Details_Aggregate_Bool_Exp>;
  assessment_stage_careers?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
  assessment_stage_careers_aggregate?: InputMaybe<Assessment_Stage_Career_Aggregate_Bool_Exp>;
  assessment_type?: InputMaybe<Assessment_Type_Bool_Exp>;
  assessment_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  department?: InputMaybe<Department_Bool_Exp>;
  department_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  passing_score?: InputMaybe<Float8_Comparison_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
  recommended_training?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
  tags?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_Skills_Assessments?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
  user_Skills_Assessments_aggregate?: InputMaybe<User_Skills_Assessment_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "assessment" */
export enum Assessment_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssessmentPkey = 'assessment_pkey',
}

/** each assessment feedback  */
export type Assessment_Feedback = {
  __typename?: 'assessment_feedback';
  /** An object relationship */
  assessment: Assessment;
  assessment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  feedback: Scalars['float8'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  update_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "assessment_feedback" */
export type Assessment_Feedback_Aggregate = {
  __typename?: 'assessment_feedback_aggregate';
  aggregate?: Maybe<Assessment_Feedback_Aggregate_Fields>;
  nodes: Array<Assessment_Feedback>;
};

export type Assessment_Feedback_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp_Var_Samp>;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Avg = {
  arguments: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Corr = {
  arguments: Assessment_Feedback_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Assessment_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Max = {
  arguments: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Min = {
  arguments: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Sum = {
  arguments: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Assessment_Feedback_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "assessment_feedback" */
export type Assessment_Feedback_Aggregate_Fields = {
  __typename?: 'assessment_feedback_aggregate_fields';
  avg?: Maybe<Assessment_Feedback_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assessment_Feedback_Max_Fields>;
  min?: Maybe<Assessment_Feedback_Min_Fields>;
  stddev?: Maybe<Assessment_Feedback_Stddev_Fields>;
  stddev_pop?: Maybe<Assessment_Feedback_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assessment_Feedback_Stddev_Samp_Fields>;
  sum?: Maybe<Assessment_Feedback_Sum_Fields>;
  var_pop?: Maybe<Assessment_Feedback_Var_Pop_Fields>;
  var_samp?: Maybe<Assessment_Feedback_Var_Samp_Fields>;
  variance?: Maybe<Assessment_Feedback_Variance_Fields>;
};

/** aggregate fields of "assessment_feedback" */
export type Assessment_Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment_feedback" */
export type Assessment_Feedback_Aggregate_Order_By = {
  avg?: InputMaybe<Assessment_Feedback_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Feedback_Max_Order_By>;
  min?: InputMaybe<Assessment_Feedback_Min_Order_By>;
  stddev?: InputMaybe<Assessment_Feedback_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Assessment_Feedback_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Assessment_Feedback_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Assessment_Feedback_Sum_Order_By>;
  var_pop?: InputMaybe<Assessment_Feedback_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Assessment_Feedback_Var_Samp_Order_By>;
  variance?: InputMaybe<Assessment_Feedback_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assessment_feedback" */
export type Assessment_Feedback_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Feedback_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Feedback_On_Conflict>;
};

/** aggregate avg on columns */
export type Assessment_Feedback_Avg_Fields = {
  __typename?: 'assessment_feedback_avg_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Avg_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assessment_feedback". All fields are combined with a logical 'AND'. */
export type Assessment_Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<Assessment_Feedback_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Feedback_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feedback?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  update_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "assessment_feedback" */
export enum Assessment_Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssessmentFeedbackPkey = 'assessment_feedback_pkey',
}

/** input type for incrementing numeric columns in table "assessment_feedback" */
export type Assessment_Feedback_Inc_Input = {
  feedback?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "assessment_feedback" */
export type Assessment_Feedback_Insert_Input = {
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  assessment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  update_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Assessment_Feedback_Max_Fields = {
  __typename?: 'assessment_feedback_max_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  feedback?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Max_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Feedback_Min_Fields = {
  __typename?: 'assessment_feedback_min_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  feedback?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Min_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assessment_feedback" */
export type Assessment_Feedback_Mutation_Response = {
  __typename?: 'assessment_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment_Feedback>;
};

/** on_conflict condition type for table "assessment_feedback" */
export type Assessment_Feedback_On_Conflict = {
  constraint: Assessment_Feedback_Constraint;
  update_columns?: Array<Assessment_Feedback_Update_Column>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment_feedback". */
export type Assessment_Feedback_Order_By = {
  assessment?: InputMaybe<Assessment_Order_By>;
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assessment_feedback */
export type Assessment_Feedback_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdateAt = 'update_at',
  /** column name */
  UserId = 'user_id',
}

/** select "assessment_feedback_aggregate_bool_exp_avg_arguments_columns" columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "assessment_feedback_aggregate_bool_exp_corr_arguments_columns" columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "assessment_feedback_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "assessment_feedback_aggregate_bool_exp_max_arguments_columns" columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "assessment_feedback_aggregate_bool_exp_min_arguments_columns" columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "assessment_feedback_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "assessment_feedback_aggregate_bool_exp_sum_arguments_columns" columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "assessment_feedback_aggregate_bool_exp_var_samp_arguments_columns" columns of table "assessment_feedback" */
export enum Assessment_Feedback_Select_Column_Assessment_Feedback_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** input type for updating data in table "assessment_feedback" */
export type Assessment_Feedback_Set_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  update_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Assessment_Feedback_Stddev_Fields = {
  __typename?: 'assessment_feedback_stddev_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Stddev_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assessment_Feedback_Stddev_Pop_Fields = {
  __typename?: 'assessment_feedback_stddev_pop_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Stddev_Pop_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assessment_Feedback_Stddev_Samp_Fields = {
  __typename?: 'assessment_feedback_stddev_samp_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Stddev_Samp_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "assessment_feedback" */
export type Assessment_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Feedback_Stream_Cursor_Value_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  update_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Assessment_Feedback_Sum_Fields = {
  __typename?: 'assessment_feedback_sum_fields';
  feedback?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Sum_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** update columns of table "assessment_feedback" */
export enum Assessment_Feedback_Update_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdateAt = 'update_at',
  /** column name */
  UserId = 'user_id',
}

export type Assessment_Feedback_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Assessment_Feedback_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Feedback_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assessment_Feedback_Var_Pop_Fields = {
  __typename?: 'assessment_feedback_var_pop_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Var_Pop_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assessment_Feedback_Var_Samp_Fields = {
  __typename?: 'assessment_feedback_var_samp_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Var_Samp_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Assessment_Feedback_Variance_Fields = {
  __typename?: 'assessment_feedback_variance_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assessment_feedback" */
export type Assessment_Feedback_Variance_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "assessment" */
export type Assessment_Inc_Input = {
  active_till?: InputMaybe<Scalars['Int']>;
  passing_score?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "assessment" */
export type Assessment_Insert_Input = {
  active_till?: InputMaybe<Scalars['Int']>;
  assessment_Skills?: InputMaybe<Assessment_Skills_Arr_Rel_Insert_Input>;
  assessment_feedbacks?: InputMaybe<Assessment_Feedback_Arr_Rel_Insert_Input>;
  assessment_records?: InputMaybe<Assessment_Record_Arr_Rel_Insert_Input>;
  assessment_role_details?: InputMaybe<Assessment_Role_Details_Arr_Rel_Insert_Input>;
  assessment_stage_careers?: InputMaybe<Assessment_Stage_Career_Arr_Rel_Insert_Input>;
  assessment_type?: InputMaybe<Assessment_Type_Obj_Rel_Insert_Input>;
  assessment_type_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  department_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  passing_score?: InputMaybe<Scalars['float8']>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
  recommended_training?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_Skills_Assessments?: InputMaybe<User_Skills_Assessment_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Assessment_Max_Fields = {
  __typename?: 'assessment_max_fields';
  active_till?: Maybe<Scalars['Int']>;
  assessment_type_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  passing_score?: Maybe<Scalars['float8']>;
  recommended_training?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assessment" */
export type Assessment_Max_Order_By = {
  active_till?: InputMaybe<Order_By>;
  assessment_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
  recommended_training?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Min_Fields = {
  __typename?: 'assessment_min_fields';
  active_till?: Maybe<Scalars['Int']>;
  assessment_type_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  passing_score?: Maybe<Scalars['float8']>;
  recommended_training?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assessment" */
export type Assessment_Min_Order_By = {
  active_till?: InputMaybe<Order_By>;
  assessment_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
  recommended_training?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assessment" */
export type Assessment_Mutation_Response = {
  __typename?: 'assessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment>;
};

/** input type for inserting object relation for remote table "assessment" */
export type Assessment_Obj_Rel_Insert_Input = {
  data: Assessment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_On_Conflict>;
};

/** on_conflict condition type for table "assessment" */
export type Assessment_On_Conflict = {
  constraint: Assessment_Constraint;
  update_columns?: Array<Assessment_Update_Column>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment". */
export type Assessment_Order_By = {
  active_till?: InputMaybe<Order_By>;
  assessment_Skills_aggregate?: InputMaybe<Assessment_Skills_Aggregate_Order_By>;
  assessment_feedbacks_aggregate?: InputMaybe<Assessment_Feedback_Aggregate_Order_By>;
  assessment_records_aggregate?: InputMaybe<Assessment_Record_Aggregate_Order_By>;
  assessment_role_details_aggregate?: InputMaybe<Assessment_Role_Details_Aggregate_Order_By>;
  assessment_stage_careers_aggregate?: InputMaybe<Assessment_Stage_Career_Aggregate_Order_By>;
  assessment_type?: InputMaybe<Assessment_Type_Order_By>;
  assessment_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  department?: InputMaybe<Department_Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  passing_score?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
  recommended_training?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_Skills_Assessments_aggregate?: InputMaybe<User_Skills_Assessment_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assessment */
export type Assessment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** assessment given answer and mark will be store here */
export type Assessment_Record = {
  __typename?: 'assessment_record';
  /** An object relationship */
  assessment: Assessment;
  assessment_id: Scalars['uuid'];
  career_Assessment_record?: Maybe<Scalars['Boolean']>;
  correct_answer: Scalars['String'];
  created_at: Scalars['timestamptz'];
  given_answer?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  latest_record: Scalars['Boolean'];
  marks: Scalars['Int'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid'];
  skill_Assessment_record?: Maybe<Scalars['Boolean']>;
  total_marks?: Maybe<Scalars['Int']>;
  update_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "assessment_record" */
export type Assessment_Record_Aggregate = {
  __typename?: 'assessment_record_aggregate';
  aggregate?: Maybe<Assessment_Record_Aggregate_Fields>;
  nodes: Array<Assessment_Record>;
};

export type Assessment_Record_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Assessment_Record_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Assessment_Record_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Assessment_Record_Aggregate_Bool_Exp_Count>;
};

export type Assessment_Record_Aggregate_Bool_Exp_Bool_And = {
  arguments: Assessment_Record_Select_Column_Assessment_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Record_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assessment_Record_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Assessment_Record_Select_Column_Assessment_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Record_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assessment_Record_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Record_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assessment_record" */
export type Assessment_Record_Aggregate_Fields = {
  __typename?: 'assessment_record_aggregate_fields';
  avg?: Maybe<Assessment_Record_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assessment_Record_Max_Fields>;
  min?: Maybe<Assessment_Record_Min_Fields>;
  stddev?: Maybe<Assessment_Record_Stddev_Fields>;
  stddev_pop?: Maybe<Assessment_Record_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assessment_Record_Stddev_Samp_Fields>;
  sum?: Maybe<Assessment_Record_Sum_Fields>;
  var_pop?: Maybe<Assessment_Record_Var_Pop_Fields>;
  var_samp?: Maybe<Assessment_Record_Var_Samp_Fields>;
  variance?: Maybe<Assessment_Record_Variance_Fields>;
};

/** aggregate fields of "assessment_record" */
export type Assessment_Record_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment_record" */
export type Assessment_Record_Aggregate_Order_By = {
  avg?: InputMaybe<Assessment_Record_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Record_Max_Order_By>;
  min?: InputMaybe<Assessment_Record_Min_Order_By>;
  stddev?: InputMaybe<Assessment_Record_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Assessment_Record_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Assessment_Record_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Assessment_Record_Sum_Order_By>;
  var_pop?: InputMaybe<Assessment_Record_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Assessment_Record_Var_Samp_Order_By>;
  variance?: InputMaybe<Assessment_Record_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assessment_record" */
export type Assessment_Record_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Record_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Record_On_Conflict>;
};

/** aggregate avg on columns */
export type Assessment_Record_Avg_Fields = {
  __typename?: 'assessment_record_avg_fields';
  marks?: Maybe<Scalars['Float']>;
  total_marks?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assessment_record" */
export type Assessment_Record_Avg_Order_By = {
  marks?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assessment_record". All fields are combined with a logical 'AND'. */
export type Assessment_Record_Bool_Exp = {
  _and?: InputMaybe<Array<Assessment_Record_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Record_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Record_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  career_Assessment_record?: InputMaybe<Boolean_Comparison_Exp>;
  correct_answer?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  given_answer?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latest_record?: InputMaybe<Boolean_Comparison_Exp>;
  marks?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  skill_Assessment_record?: InputMaybe<Boolean_Comparison_Exp>;
  total_marks?: InputMaybe<Int_Comparison_Exp>;
  update_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "assessment_record" */
export enum Assessment_Record_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssessmentRecordPkey = 'assessment_record_pkey',
}

/** input type for incrementing numeric columns in table "assessment_record" */
export type Assessment_Record_Inc_Input = {
  marks?: InputMaybe<Scalars['Int']>;
  total_marks?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "assessment_record" */
export type Assessment_Record_Insert_Input = {
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  assessment_id?: InputMaybe<Scalars['uuid']>;
  career_Assessment_record?: InputMaybe<Scalars['Boolean']>;
  correct_answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  given_answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  latest_record?: InputMaybe<Scalars['Boolean']>;
  marks?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']>;
  skill_Assessment_record?: InputMaybe<Scalars['Boolean']>;
  total_marks?: InputMaybe<Scalars['Int']>;
  update_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Assessment_Record_Max_Fields = {
  __typename?: 'assessment_record_max_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  correct_answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  given_answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marks?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  total_marks?: Maybe<Scalars['Int']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assessment_record" */
export type Assessment_Record_Max_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  correct_answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  given_answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Record_Min_Fields = {
  __typename?: 'assessment_record_min_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  correct_answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  given_answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marks?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  total_marks?: Maybe<Scalars['Int']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assessment_record" */
export type Assessment_Record_Min_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  correct_answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  given_answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assessment_record" */
export type Assessment_Record_Mutation_Response = {
  __typename?: 'assessment_record_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment_Record>;
};

/** on_conflict condition type for table "assessment_record" */
export type Assessment_Record_On_Conflict = {
  constraint: Assessment_Record_Constraint;
  update_columns?: Array<Assessment_Record_Update_Column>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment_record". */
export type Assessment_Record_Order_By = {
  assessment?: InputMaybe<Assessment_Order_By>;
  assessment_id?: InputMaybe<Order_By>;
  career_Assessment_record?: InputMaybe<Order_By>;
  correct_answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  given_answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latest_record?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  skill_Assessment_record?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assessment_record */
export type Assessment_Record_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assessment_record" */
export enum Assessment_Record_Select_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CareerAssessmentRecord = 'career_Assessment_record',
  /** column name */
  CorrectAnswer = 'correct_answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GivenAnswer = 'given_answer',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRecord = 'latest_record',
  /** column name */
  Marks = 'marks',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SkillAssessmentRecord = 'skill_Assessment_record',
  /** column name */
  TotalMarks = 'total_marks',
  /** column name */
  UpdateAt = 'update_at',
  /** column name */
  UserId = 'user_id',
}

/** select "assessment_record_aggregate_bool_exp_bool_and_arguments_columns" columns of table "assessment_record" */
export enum Assessment_Record_Select_Column_Assessment_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CareerAssessmentRecord = 'career_Assessment_record',
  /** column name */
  LatestRecord = 'latest_record',
  /** column name */
  SkillAssessmentRecord = 'skill_Assessment_record',
}

/** select "assessment_record_aggregate_bool_exp_bool_or_arguments_columns" columns of table "assessment_record" */
export enum Assessment_Record_Select_Column_Assessment_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CareerAssessmentRecord = 'career_Assessment_record',
  /** column name */
  LatestRecord = 'latest_record',
  /** column name */
  SkillAssessmentRecord = 'skill_Assessment_record',
}

/** input type for updating data in table "assessment_record" */
export type Assessment_Record_Set_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  career_Assessment_record?: InputMaybe<Scalars['Boolean']>;
  correct_answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  given_answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  latest_record?: InputMaybe<Scalars['Boolean']>;
  marks?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  skill_Assessment_record?: InputMaybe<Scalars['Boolean']>;
  total_marks?: InputMaybe<Scalars['Int']>;
  update_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Assessment_Record_Stddev_Fields = {
  __typename?: 'assessment_record_stddev_fields';
  marks?: Maybe<Scalars['Float']>;
  total_marks?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assessment_record" */
export type Assessment_Record_Stddev_Order_By = {
  marks?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assessment_Record_Stddev_Pop_Fields = {
  __typename?: 'assessment_record_stddev_pop_fields';
  marks?: Maybe<Scalars['Float']>;
  total_marks?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assessment_record" */
export type Assessment_Record_Stddev_Pop_Order_By = {
  marks?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assessment_Record_Stddev_Samp_Fields = {
  __typename?: 'assessment_record_stddev_samp_fields';
  marks?: Maybe<Scalars['Float']>;
  total_marks?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assessment_record" */
export type Assessment_Record_Stddev_Samp_Order_By = {
  marks?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "assessment_record" */
export type Assessment_Record_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Record_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Record_Stream_Cursor_Value_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  career_Assessment_record?: InputMaybe<Scalars['Boolean']>;
  correct_answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  given_answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  latest_record?: InputMaybe<Scalars['Boolean']>;
  marks?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  skill_Assessment_record?: InputMaybe<Scalars['Boolean']>;
  total_marks?: InputMaybe<Scalars['Int']>;
  update_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Assessment_Record_Sum_Fields = {
  __typename?: 'assessment_record_sum_fields';
  marks?: Maybe<Scalars['Int']>;
  total_marks?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assessment_record" */
export type Assessment_Record_Sum_Order_By = {
  marks?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
};

/** update columns of table "assessment_record" */
export enum Assessment_Record_Update_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CareerAssessmentRecord = 'career_Assessment_record',
  /** column name */
  CorrectAnswer = 'correct_answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GivenAnswer = 'given_answer',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRecord = 'latest_record',
  /** column name */
  Marks = 'marks',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SkillAssessmentRecord = 'skill_Assessment_record',
  /** column name */
  TotalMarks = 'total_marks',
  /** column name */
  UpdateAt = 'update_at',
  /** column name */
  UserId = 'user_id',
}

export type Assessment_Record_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Assessment_Record_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Record_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Record_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assessment_Record_Var_Pop_Fields = {
  __typename?: 'assessment_record_var_pop_fields';
  marks?: Maybe<Scalars['Float']>;
  total_marks?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assessment_record" */
export type Assessment_Record_Var_Pop_Order_By = {
  marks?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assessment_Record_Var_Samp_Fields = {
  __typename?: 'assessment_record_var_samp_fields';
  marks?: Maybe<Scalars['Float']>;
  total_marks?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assessment_record" */
export type Assessment_Record_Var_Samp_Order_By = {
  marks?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Assessment_Record_Variance_Fields = {
  __typename?: 'assessment_record_variance_fields';
  marks?: Maybe<Scalars['Float']>;
  total_marks?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assessment_record" */
export type Assessment_Record_Variance_Order_By = {
  marks?: InputMaybe<Order_By>;
  total_marks?: InputMaybe<Order_By>;
};

/** columns and relationships of "assessment_role_details" */
export type Assessment_Role_Details = {
  __typename?: 'assessment_role_details';
  /** An object relationship */
  assessment: Assessment;
  assessment_id: Scalars['uuid'];
  assessment_type_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  organization_role: Organization_Roles;
  role_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "assessment_role_details" */
export type Assessment_Role_Details_Aggregate = {
  __typename?: 'assessment_role_details_aggregate';
  aggregate?: Maybe<Assessment_Role_Details_Aggregate_Fields>;
  nodes: Array<Assessment_Role_Details>;
};

export type Assessment_Role_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Assessment_Role_Details_Aggregate_Bool_Exp_Count>;
};

export type Assessment_Role_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assessment_role_details" */
export type Assessment_Role_Details_Aggregate_Fields = {
  __typename?: 'assessment_role_details_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Assessment_Role_Details_Max_Fields>;
  min?: Maybe<Assessment_Role_Details_Min_Fields>;
};

/** aggregate fields of "assessment_role_details" */
export type Assessment_Role_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment_role_details" */
export type Assessment_Role_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Role_Details_Max_Order_By>;
  min?: InputMaybe<Assessment_Role_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "assessment_role_details" */
export type Assessment_Role_Details_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Role_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Role_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "assessment_role_details". All fields are combined with a logical 'AND'. */
export type Assessment_Role_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Assessment_Role_Details_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Role_Details_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  assessment_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "assessment_role_details" */
export enum Assessment_Role_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssessmentRoleDetailsPkey = 'assessment_role_details_pkey',
}

/** input type for inserting data into table "assessment_role_details" */
export type Assessment_Role_Details_Insert_Input = {
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  assessment_id?: InputMaybe<Scalars['uuid']>;
  assessment_type_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Assessment_Role_Details_Max_Fields = {
  __typename?: 'assessment_role_details_max_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  assessment_type_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "assessment_role_details" */
export type Assessment_Role_Details_Max_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  assessment_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Role_Details_Min_Fields = {
  __typename?: 'assessment_role_details_min_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  assessment_type_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "assessment_role_details" */
export type Assessment_Role_Details_Min_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  assessment_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assessment_role_details" */
export type Assessment_Role_Details_Mutation_Response = {
  __typename?: 'assessment_role_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment_Role_Details>;
};

/** on_conflict condition type for table "assessment_role_details" */
export type Assessment_Role_Details_On_Conflict = {
  constraint: Assessment_Role_Details_Constraint;
  update_columns?: Array<Assessment_Role_Details_Update_Column>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment_role_details". */
export type Assessment_Role_Details_Order_By = {
  assessment?: InputMaybe<Assessment_Order_By>;
  assessment_id?: InputMaybe<Order_By>;
  assessment_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assessment_role_details */
export type Assessment_Role_Details_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assessment_role_details" */
export enum Assessment_Role_Details_Select_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  AssessmentTypeId = 'assessment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "assessment_role_details" */
export type Assessment_Role_Details_Set_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  assessment_type_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "assessment_role_details" */
export type Assessment_Role_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Role_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Role_Details_Stream_Cursor_Value_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  assessment_type_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "assessment_role_details" */
export enum Assessment_Role_Details_Update_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  AssessmentTypeId = 'assessment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Assessment_Role_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Role_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Role_Details_Bool_Exp;
};

/** select columns of table "assessment" */
export enum Assessment_Select_Column {
  /** column name */
  ActiveTill = 'active_till',
  /** column name */
  AssessmentTypeId = 'assessment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PassingScore = 'passing_score',
  /** column name */
  RecommendedTraining = 'recommended_training',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "assessment_aggregate_bool_exp_avg_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  PassingScore = 'passing_score',
}

/** select "assessment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Status = 'status',
}

/** select "assessment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Status = 'status',
}

/** select "assessment_aggregate_bool_exp_corr_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  PassingScore = 'passing_score',
}

/** select "assessment_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  PassingScore = 'passing_score',
}

/** select "assessment_aggregate_bool_exp_max_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  PassingScore = 'passing_score',
}

/** select "assessment_aggregate_bool_exp_min_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  PassingScore = 'passing_score',
}

/** select "assessment_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  PassingScore = 'passing_score',
}

/** select "assessment_aggregate_bool_exp_sum_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  PassingScore = 'passing_score',
}

/** select "assessment_aggregate_bool_exp_var_samp_arguments_columns" columns of table "assessment" */
export enum Assessment_Select_Column_Assessment_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  PassingScore = 'passing_score',
}

/** input type for updating data in table "assessment" */
export type Assessment_Set_Input = {
  active_till?: InputMaybe<Scalars['Int']>;
  assessment_type_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  department_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  passing_score?: InputMaybe<Scalars['float8']>;
  recommended_training?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "assessment_stage_career" */
export type Assessment_Stage_Career = {
  __typename?: 'assessment_stage_career';
  /** An object relationship */
  assessment: Assessment;
  assessment_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  order_line?: Maybe<Scalars['Int']>;
  /** An object relationship */
  stage_career_detail: Stage_Career_Detail;
  stage_career_detail_id: Scalars['uuid'];
};

/** aggregated selection of "assessment_stage_career" */
export type Assessment_Stage_Career_Aggregate = {
  __typename?: 'assessment_stage_career_aggregate';
  aggregate?: Maybe<Assessment_Stage_Career_Aggregate_Fields>;
  nodes: Array<Assessment_Stage_Career>;
};

export type Assessment_Stage_Career_Aggregate_Bool_Exp = {
  count?: InputMaybe<Assessment_Stage_Career_Aggregate_Bool_Exp_Count>;
};

export type Assessment_Stage_Career_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assessment_stage_career" */
export type Assessment_Stage_Career_Aggregate_Fields = {
  __typename?: 'assessment_stage_career_aggregate_fields';
  avg?: Maybe<Assessment_Stage_Career_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assessment_Stage_Career_Max_Fields>;
  min?: Maybe<Assessment_Stage_Career_Min_Fields>;
  stddev?: Maybe<Assessment_Stage_Career_Stddev_Fields>;
  stddev_pop?: Maybe<Assessment_Stage_Career_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assessment_Stage_Career_Stddev_Samp_Fields>;
  sum?: Maybe<Assessment_Stage_Career_Sum_Fields>;
  var_pop?: Maybe<Assessment_Stage_Career_Var_Pop_Fields>;
  var_samp?: Maybe<Assessment_Stage_Career_Var_Samp_Fields>;
  variance?: Maybe<Assessment_Stage_Career_Variance_Fields>;
};

/** aggregate fields of "assessment_stage_career" */
export type Assessment_Stage_Career_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment_stage_career" */
export type Assessment_Stage_Career_Aggregate_Order_By = {
  avg?: InputMaybe<Assessment_Stage_Career_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Stage_Career_Max_Order_By>;
  min?: InputMaybe<Assessment_Stage_Career_Min_Order_By>;
  stddev?: InputMaybe<Assessment_Stage_Career_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Assessment_Stage_Career_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Assessment_Stage_Career_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Assessment_Stage_Career_Sum_Order_By>;
  var_pop?: InputMaybe<Assessment_Stage_Career_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Assessment_Stage_Career_Var_Samp_Order_By>;
  variance?: InputMaybe<Assessment_Stage_Career_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assessment_stage_career" */
export type Assessment_Stage_Career_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Stage_Career_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Stage_Career_On_Conflict>;
};

/** aggregate avg on columns */
export type Assessment_Stage_Career_Avg_Fields = {
  __typename?: 'assessment_stage_career_avg_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Avg_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assessment_stage_career". All fields are combined with a logical 'AND'. */
export type Assessment_Stage_Career_Bool_Exp = {
  _and?: InputMaybe<Array<Assessment_Stage_Career_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Stage_Career_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_line?: InputMaybe<Int_Comparison_Exp>;
  stage_career_detail?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
  stage_career_detail_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "assessment_stage_career" */
export enum Assessment_Stage_Career_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssessmentStageCareerPkey = 'assessment_stage_career_pkey',
}

/** input type for incrementing numeric columns in table "assessment_stage_career" */
export type Assessment_Stage_Career_Inc_Input = {
  order_line?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "assessment_stage_career" */
export type Assessment_Stage_Career_Insert_Input = {
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  assessment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_career_detail?: InputMaybe<Stage_Career_Detail_Obj_Rel_Insert_Input>;
  stage_career_detail_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Assessment_Stage_Career_Max_Fields = {
  __typename?: 'assessment_stage_career_max_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order_line?: Maybe<Scalars['Int']>;
  stage_career_detail_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Max_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_career_detail_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Stage_Career_Min_Fields = {
  __typename?: 'assessment_stage_career_min_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order_line?: Maybe<Scalars['Int']>;
  stage_career_detail_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Min_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_career_detail_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assessment_stage_career" */
export type Assessment_Stage_Career_Mutation_Response = {
  __typename?: 'assessment_stage_career_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment_Stage_Career>;
};

/** on_conflict condition type for table "assessment_stage_career" */
export type Assessment_Stage_Career_On_Conflict = {
  constraint: Assessment_Stage_Career_Constraint;
  update_columns?: Array<Assessment_Stage_Career_Update_Column>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment_stage_career". */
export type Assessment_Stage_Career_Order_By = {
  assessment?: InputMaybe<Assessment_Order_By>;
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_career_detail?: InputMaybe<Stage_Career_Detail_Order_By>;
  stage_career_detail_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assessment_stage_career */
export type Assessment_Stage_Career_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assessment_stage_career" */
export enum Assessment_Stage_Career_Select_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderLine = 'order_line',
  /** column name */
  StageCareerDetailId = 'stage_career_detail_id',
}

/** input type for updating data in table "assessment_stage_career" */
export type Assessment_Stage_Career_Set_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_career_detail_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Assessment_Stage_Career_Stddev_Fields = {
  __typename?: 'assessment_stage_career_stddev_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Stddev_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assessment_Stage_Career_Stddev_Pop_Fields = {
  __typename?: 'assessment_stage_career_stddev_pop_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Stddev_Pop_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assessment_Stage_Career_Stddev_Samp_Fields = {
  __typename?: 'assessment_stage_career_stddev_samp_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Stddev_Samp_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "assessment_stage_career" */
export type Assessment_Stage_Career_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Stage_Career_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Stage_Career_Stream_Cursor_Value_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_career_detail_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Assessment_Stage_Career_Sum_Fields = {
  __typename?: 'assessment_stage_career_sum_fields';
  order_line?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Sum_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** update columns of table "assessment_stage_career" */
export enum Assessment_Stage_Career_Update_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderLine = 'order_line',
  /** column name */
  StageCareerDetailId = 'stage_career_detail_id',
}

export type Assessment_Stage_Career_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Assessment_Stage_Career_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Stage_Career_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Stage_Career_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assessment_Stage_Career_Var_Pop_Fields = {
  __typename?: 'assessment_stage_career_var_pop_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Var_Pop_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assessment_Stage_Career_Var_Samp_Fields = {
  __typename?: 'assessment_stage_career_var_samp_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Var_Samp_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Assessment_Stage_Career_Variance_Fields = {
  __typename?: 'assessment_stage_career_variance_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assessment_stage_career" */
export type Assessment_Stage_Career_Variance_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Assessment_Stddev_Fields = {
  __typename?: 'assessment_stddev_fields';
  active_till?: Maybe<Scalars['Float']>;
  passing_score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assessment" */
export type Assessment_Stddev_Order_By = {
  active_till?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assessment_Stddev_Pop_Fields = {
  __typename?: 'assessment_stddev_pop_fields';
  active_till?: Maybe<Scalars['Float']>;
  passing_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assessment" */
export type Assessment_Stddev_Pop_Order_By = {
  active_till?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assessment_Stddev_Samp_Fields = {
  __typename?: 'assessment_stddev_samp_fields';
  active_till?: Maybe<Scalars['Float']>;
  passing_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assessment" */
export type Assessment_Stddev_Samp_Order_By = {
  active_till?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "assessment" */
export type Assessment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Stream_Cursor_Value_Input = {
  active_till?: InputMaybe<Scalars['Int']>;
  assessment_type_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  department_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  passing_score?: InputMaybe<Scalars['float8']>;
  recommended_training?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Assessment_Sum_Fields = {
  __typename?: 'assessment_sum_fields';
  active_till?: Maybe<Scalars['Int']>;
  passing_score?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "assessment" */
export type Assessment_Sum_Order_By = {
  active_till?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
};

/** columns and relationships of "assessment_type" */
export type Assessment_Type = {
  __typename?: 'assessment_type';
  /** An array relationship */
  assessments: Array<Assessment>;
  /** An aggregate relationship */
  assessments_aggregate: Assessment_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  meta_tags: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  status: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** columns and relationships of "assessment_type" */
export type Assessment_TypeAssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** columns and relationships of "assessment_type" */
export type Assessment_TypeAssessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** aggregated selection of "assessment_type" */
export type Assessment_Type_Aggregate = {
  __typename?: 'assessment_type_aggregate';
  aggregate?: Maybe<Assessment_Type_Aggregate_Fields>;
  nodes: Array<Assessment_Type>;
};

export type Assessment_Type_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Assessment_Type_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Assessment_Type_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Assessment_Type_Aggregate_Bool_Exp_Count>;
};

export type Assessment_Type_Aggregate_Bool_Exp_Bool_And = {
  arguments: Assessment_Type_Select_Column_Assessment_Type_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Type_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assessment_Type_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Assessment_Type_Select_Column_Assessment_Type_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Type_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assessment_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assessment_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assessment_type" */
export type Assessment_Type_Aggregate_Fields = {
  __typename?: 'assessment_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Assessment_Type_Max_Fields>;
  min?: Maybe<Assessment_Type_Min_Fields>;
};

/** aggregate fields of "assessment_type" */
export type Assessment_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment_type" */
export type Assessment_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Type_Max_Order_By>;
  min?: InputMaybe<Assessment_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "assessment_type" */
export type Assessment_Type_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "assessment_type". All fields are combined with a logical 'AND'. */
export type Assessment_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Assessment_Type_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Type_Bool_Exp>>;
  assessments?: InputMaybe<Assessment_Bool_Exp>;
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meta_tags?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "assessment_type" */
export enum Assessment_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssessmentTypePkey = 'assessment_type_pkey',
}

/** input type for inserting data into table "assessment_type" */
export type Assessment_Type_Insert_Input = {
  assessments?: InputMaybe<Assessment_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta_tags?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Assessment_Type_Max_Fields = {
  __typename?: 'assessment_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meta_tags?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assessment_type" */
export type Assessment_Type_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_tags?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Type_Min_Fields = {
  __typename?: 'assessment_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meta_tags?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assessment_type" */
export type Assessment_Type_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_tags?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assessment_type" */
export type Assessment_Type_Mutation_Response = {
  __typename?: 'assessment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment_Type>;
};

/** input type for inserting object relation for remote table "assessment_type" */
export type Assessment_Type_Obj_Rel_Insert_Input = {
  data: Assessment_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Type_On_Conflict>;
};

/** on_conflict condition type for table "assessment_type" */
export type Assessment_Type_On_Conflict = {
  constraint: Assessment_Type_Constraint;
  update_columns?: Array<Assessment_Type_Update_Column>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment_type". */
export type Assessment_Type_Order_By = {
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_tags?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assessment_type */
export type Assessment_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assessment_type" */
export enum Assessment_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetaTags = 'meta_tags',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "assessment_type_aggregate_bool_exp_bool_and_arguments_columns" columns of table "assessment_type" */
export enum Assessment_Type_Select_Column_Assessment_Type_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Status = 'status',
}

/** select "assessment_type_aggregate_bool_exp_bool_or_arguments_columns" columns of table "assessment_type" */
export enum Assessment_Type_Select_Column_Assessment_Type_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "assessment_type" */
export type Assessment_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta_tags?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "assessment_type" */
export type Assessment_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta_tags?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "assessment_type" */
export enum Assessment_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetaTags = 'meta_tags',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Assessment_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Type_Bool_Exp;
};

/** update columns of table "assessment" */
export enum Assessment_Update_Column {
  /** column name */
  ActiveTill = 'active_till',
  /** column name */
  AssessmentTypeId = 'assessment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PassingScore = 'passing_score',
  /** column name */
  RecommendedTraining = 'recommended_training',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Assessment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Assessment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assessment_Var_Pop_Fields = {
  __typename?: 'assessment_var_pop_fields';
  active_till?: Maybe<Scalars['Float']>;
  passing_score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assessment" */
export type Assessment_Var_Pop_Order_By = {
  active_till?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assessment_Var_Samp_Fields = {
  __typename?: 'assessment_var_samp_fields';
  active_till?: Maybe<Scalars['Float']>;
  passing_score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assessment" */
export type Assessment_Var_Samp_Order_By = {
  active_till?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Assessment_Variance_Fields = {
  __typename?: 'assessment_variance_fields';
  active_till?: Maybe<Scalars['Float']>;
  passing_score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assessment" */
export type Assessment_Variance_Order_By = {
  active_till?: InputMaybe<Order_By>;
  passing_score?: InputMaybe<Order_By>;
};

export type Assessmentrecord = {
  assessment_id: Scalars['uuid'];
  correct_answer?: InputMaybe<Scalars['String']>;
  given_answer?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  marks?: InputMaybe<Scalars['Int']>;
  organization_id: Scalars['uuid'];
  question_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type AssignAwardInput = {
  awardTypeId: Scalars['String'];
  cycle?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
  postId: Scalars['String'];
  userId: Scalars['String'];
};

export type AssignAwardOutput = {
  __typename?: 'assignAwardOutput';
  id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "assign_career_development_plan" */
export type Assign_Career_Development_Plan = {
  __typename?: 'assign_career_development_plan';
  /** An object relationship */
  career_development_detail: Career_Development_Detail;
  career_development_detail_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Aggregate = {
  __typename?: 'assign_career_development_plan_aggregate';
  aggregate?: Maybe<Assign_Career_Development_Plan_Aggregate_Fields>;
  nodes: Array<Assign_Career_Development_Plan>;
};

export type Assign_Career_Development_Plan_Aggregate_Bool_Exp = {
  count?: InputMaybe<Assign_Career_Development_Plan_Aggregate_Bool_Exp_Count>;
};

export type Assign_Career_Development_Plan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Aggregate_Fields = {
  __typename?: 'assign_career_development_plan_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Assign_Career_Development_Plan_Max_Fields>;
  min?: Maybe<Assign_Career_Development_Plan_Min_Fields>;
};

/** aggregate fields of "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assign_Career_Development_Plan_Max_Order_By>;
  min?: InputMaybe<Assign_Career_Development_Plan_Min_Order_By>;
};

/** input type for inserting array relation for remote table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Arr_Rel_Insert_Input = {
  data: Array<Assign_Career_Development_Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assign_Career_Development_Plan_On_Conflict>;
};

/** Boolean expression to filter rows from the table "assign_career_development_plan". All fields are combined with a logical 'AND'. */
export type Assign_Career_Development_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Assign_Career_Development_Plan_Bool_Exp>>;
  _not?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Assign_Career_Development_Plan_Bool_Exp>>;
  career_development_detail?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  career_development_detail_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "assign_career_development_plan" */
export enum Assign_Career_Development_Plan_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssignCareerDevelopmentPlanPkey = 'assign_career_development_plan_pkey',
}

/** input type for inserting data into table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Insert_Input = {
  career_development_detail?: InputMaybe<Career_Development_Detail_Obj_Rel_Insert_Input>;
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Assign_Career_Development_Plan_Max_Fields = {
  __typename?: 'assign_career_development_plan_max_fields';
  career_development_detail_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Max_Order_By = {
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assign_Career_Development_Plan_Min_Fields = {
  __typename?: 'assign_career_development_plan_min_fields';
  career_development_detail_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Min_Order_By = {
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Mutation_Response = {
  __typename?: 'assign_career_development_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assign_Career_Development_Plan>;
};

/** on_conflict condition type for table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_On_Conflict = {
  constraint: Assign_Career_Development_Plan_Constraint;
  update_columns?: Array<Assign_Career_Development_Plan_Update_Column>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "assign_career_development_plan". */
export type Assign_Career_Development_Plan_Order_By = {
  career_development_detail?: InputMaybe<Career_Development_Detail_Order_By>;
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assign_career_development_plan */
export type Assign_Career_Development_Plan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assign_career_development_plan" */
export enum Assign_Career_Development_Plan_Select_Column {
  /** column name */
  CareerDevelopmentDetailId = 'career_development_detail_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Set_Input = {
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "assign_career_development_plan" */
export type Assign_Career_Development_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assign_Career_Development_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assign_Career_Development_Plan_Stream_Cursor_Value_Input = {
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "assign_career_development_plan" */
export enum Assign_Career_Development_Plan_Update_Column {
  /** column name */
  CareerDevelopmentDetailId = 'career_development_detail_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id',
}

export type Assign_Career_Development_Plan_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assign_Career_Development_Plan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assign_Career_Development_Plan_Bool_Exp;
};

export type AttendAssessmentData = {
  assessment_id: Scalars['uuid'];
  careerCheck?: InputMaybe<Scalars['Boolean']>;
  given_answer?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  organization_id: Scalars['uuid'];
  question_id?: InputMaybe<Scalars['String']>;
  skillCheck?: InputMaybe<Scalars['Boolean']>;
  userSkillAssessmentId?: InputMaybe<Scalars['uuid']>;
  user_id: Scalars['uuid'];
};

export type AttendAssessmentInput = {
  data: Array<AttendAssessmentData>;
};

export type AttendAssessmentOutput = {
  __typename?: 'attendAssessmentOutput';
  id: Scalars['uuid'];
};

/** columns and relationships of "audit.user_state" */
export type Audit_User_State = {
  __typename?: 'audit_user_state';
  activity_state: Scalars['String'];
  activity_state_last_updated: Scalars['timestamptz'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  manual_state: Scalars['String'];
  previous_user_state: Scalars['jsonb'];
  system_state: Scalars['String'];
  system_state_owner?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
};

/** columns and relationships of "audit.user_state" */
export type Audit_User_StatePrevious_User_StateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "audit.user_state" */
export type Audit_User_State_Aggregate = {
  __typename?: 'audit_user_state_aggregate';
  aggregate?: Maybe<Audit_User_State_Aggregate_Fields>;
  nodes: Array<Audit_User_State>;
};

/** aggregate fields of "audit.user_state" */
export type Audit_User_State_Aggregate_Fields = {
  __typename?: 'audit_user_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Audit_User_State_Max_Fields>;
  min?: Maybe<Audit_User_State_Min_Fields>;
};

/** aggregate fields of "audit.user_state" */
export type Audit_User_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_User_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_User_State_Append_Input = {
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "audit.user_state". All fields are combined with a logical 'AND'. */
export type Audit_User_State_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_User_State_Bool_Exp>>;
  _not?: InputMaybe<Audit_User_State_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_User_State_Bool_Exp>>;
  activity_state?: InputMaybe<String_Comparison_Exp>;
  activity_state_last_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  manual_state?: InputMaybe<String_Comparison_Exp>;
  previous_user_state?: InputMaybe<Jsonb_Comparison_Exp>;
  system_state?: InputMaybe<String_Comparison_Exp>;
  system_state_owner?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit.user_state" */
export enum Audit_User_State_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserStatePkey = 'user_state_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_User_State_Delete_At_Path_Input = {
  previous_user_state?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_User_State_Delete_Elem_Input = {
  previous_user_state?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_User_State_Delete_Key_Input = {
  previous_user_state?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "audit.user_state" */
export type Audit_User_State_Insert_Input = {
  activity_state?: InputMaybe<Scalars['String']>;
  activity_state_last_updated?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  manual_state?: InputMaybe<Scalars['String']>;
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
  system_state?: InputMaybe<Scalars['String']>;
  system_state_owner?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Audit_User_State_Max_Fields = {
  __typename?: 'audit_user_state_max_fields';
  activity_state?: Maybe<Scalars['String']>;
  activity_state_last_updated?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  manual_state?: Maybe<Scalars['String']>;
  system_state?: Maybe<Scalars['String']>;
  system_state_owner?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Audit_User_State_Min_Fields = {
  __typename?: 'audit_user_state_min_fields';
  activity_state?: Maybe<Scalars['String']>;
  activity_state_last_updated?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  manual_state?: Maybe<Scalars['String']>;
  system_state?: Maybe<Scalars['String']>;
  system_state_owner?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "audit.user_state" */
export type Audit_User_State_Mutation_Response = {
  __typename?: 'audit_user_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_User_State>;
};

/** on_conflict condition type for table "audit.user_state" */
export type Audit_User_State_On_Conflict = {
  constraint: Audit_User_State_Constraint;
  update_columns?: Array<Audit_User_State_Update_Column>;
  where?: InputMaybe<Audit_User_State_Bool_Exp>;
};

/** Ordering options when selecting data from "audit.user_state". */
export type Audit_User_State_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  activity_state_last_updated?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manual_state?: InputMaybe<Order_By>;
  previous_user_state?: InputMaybe<Order_By>;
  system_state?: InputMaybe<Order_By>;
  system_state_owner?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit.user_state */
export type Audit_User_State_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_User_State_Prepend_Input = {
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "audit.user_state" */
export enum Audit_User_State_Select_Column {
  /** column name */
  ActivityState = 'activity_state',
  /** column name */
  ActivityStateLastUpdated = 'activity_state_last_updated',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ManualState = 'manual_state',
  /** column name */
  PreviousUserState = 'previous_user_state',
  /** column name */
  SystemState = 'system_state',
  /** column name */
  SystemStateOwner = 'system_state_owner',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "audit.user_state" */
export type Audit_User_State_Set_Input = {
  activity_state?: InputMaybe<Scalars['String']>;
  activity_state_last_updated?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  manual_state?: InputMaybe<Scalars['String']>;
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
  system_state?: InputMaybe<Scalars['String']>;
  system_state_owner?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "audit_user_state" */
export type Audit_User_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_User_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_User_State_Stream_Cursor_Value_Input = {
  activity_state?: InputMaybe<Scalars['String']>;
  activity_state_last_updated?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  manual_state?: InputMaybe<Scalars['String']>;
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
  system_state?: InputMaybe<Scalars['String']>;
  system_state_owner?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "audit.user_state" */
export enum Audit_User_State_Update_Column {
  /** column name */
  ActivityState = 'activity_state',
  /** column name */
  ActivityStateLastUpdated = 'activity_state_last_updated',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ManualState = 'manual_state',
  /** column name */
  PreviousUserState = 'previous_user_state',
  /** column name */
  SystemState = 'system_state',
  /** column name */
  SystemStateOwner = 'system_state_owner',
  /** column name */
  UserId = 'user_id',
}

export type Audit_User_State_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_User_State_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_User_State_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_User_State_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_User_State_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_User_State_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_User_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_User_State_Bool_Exp;
};

/** columns and relationships of "auth.account_providers" */
export type Auth_Account_Providers = {
  __typename?: 'auth_account_providers';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  auth_provider: Scalars['String'];
  auth_provider_unique_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Auth_Providers;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate = {
  __typename?: 'auth_account_providers_aggregate';
  aggregate?: Maybe<Auth_Account_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Account_Providers>;
};

export type Auth_Account_Providers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Account_Providers_Aggregate_Bool_Exp_Count>;
};

export type Auth_Account_Providers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Fields = {
  __typename?: 'auth_account_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Account_Providers_Max_Fields>;
  min?: Maybe<Auth_Account_Providers_Min_Fields>;
};

/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Account_Providers_Max_Order_By>;
  min?: InputMaybe<Auth_Account_Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_providers" */
export type Auth_Account_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Providers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Account_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_providers". All fields are combined with a logical 'AND'. */
export type Auth_Account_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Account_Providers_Bool_Exp>>;
  _not?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Account_Providers_Bool_Exp>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  auth_provider?: InputMaybe<String_Comparison_Exp>;
  auth_provider_unique_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Auth_Providers_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.account_providers" */
export enum Auth_Account_Providers_Constraint {
  /** unique or primary key constraint on columns "auth_provider", "account_id" */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint on columns "auth_provider", "auth_provider_unique_id" */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint on columns "id" */
  AccountProvidersPkey = 'account_providers_pkey',
}

/** input type for inserting data into table "auth.account_providers" */
export type Auth_Account_Providers_Insert_Input = {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  auth_provider?: InputMaybe<Scalars['String']>;
  auth_provider_unique_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Auth_Providers_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Account_Providers_Max_Fields = {
  __typename?: 'auth_account_providers_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  auth_provider?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Providers_Min_Fields = {
  __typename?: 'auth_account_providers_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  auth_provider?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.account_providers" */
export type Auth_Account_Providers_Mutation_Response = {
  __typename?: 'auth_account_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Account_Providers>;
};

/** on_conflict condition type for table "auth.account_providers" */
export type Auth_Account_Providers_On_Conflict = {
  constraint: Auth_Account_Providers_Constraint;
  update_columns?: Array<Auth_Account_Providers_Update_Column>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.account_providers". */
export type Auth_Account_Providers_Order_By = {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  auth_provider?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Auth_Providers_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.account_providers */
export type Auth_Account_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "auth.account_providers" */
export type Auth_Account_Providers_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  auth_provider?: InputMaybe<Scalars['String']>;
  auth_provider_unique_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "auth_account_providers" */
export type Auth_Account_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Account_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Account_Providers_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  auth_provider?: InputMaybe<Scalars['String']>;
  auth_provider_unique_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Auth_Account_Providers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Account_Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Account_Providers_Bool_Exp;
};

/** columns and relationships of "auth.account_roles" */
export type Auth_Account_Roles = {
  __typename?: 'auth_account_roles';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  role: Scalars['String'];
  /** An object relationship */
  roleByRole: Auth_Roles;
};

/** aggregated selection of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate = {
  __typename?: 'auth_account_roles_aggregate';
  aggregate?: Maybe<Auth_Account_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Account_Roles>;
};

export type Auth_Account_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Account_Roles_Aggregate_Bool_Exp_Count>;
};

export type Auth_Account_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Fields = {
  __typename?: 'auth_account_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Account_Roles_Max_Fields>;
  min?: Maybe<Auth_Account_Roles_Min_Fields>;
};

/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Account_Roles_Max_Order_By>;
  min?: InputMaybe<Auth_Account_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_roles" */
export type Auth_Account_Roles_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Account_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_roles". All fields are combined with a logical 'AND'. */
export type Auth_Account_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Account_Roles_Bool_Exp>>;
  _not?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Account_Roles_Bool_Exp>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  roleByRole?: InputMaybe<Auth_Roles_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.account_roles" */
export enum Auth_Account_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint on columns "role", "account_id" */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key',
}

/** input type for inserting data into table "auth.account_roles" */
export type Auth_Account_Roles_Insert_Input = {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  roleByRole?: InputMaybe<Auth_Roles_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Auth_Account_Roles_Max_Fields = {
  __typename?: 'auth_account_roles_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Roles_Min_Fields = {
  __typename?: 'auth_account_roles_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.account_roles" */
export type Auth_Account_Roles_Mutation_Response = {
  __typename?: 'auth_account_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Account_Roles>;
};

/** on_conflict condition type for table "auth.account_roles" */
export type Auth_Account_Roles_On_Conflict = {
  constraint: Auth_Account_Roles_Constraint;
  update_columns?: Array<Auth_Account_Roles_Update_Column>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.account_roles". */
export type Auth_Account_Roles_Order_By = {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<Auth_Roles_Order_By>;
};

/** primary key columns input for table: auth.account_roles */
export type Auth_Account_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
}

/** input type for updating data in table "auth.account_roles" */
export type Auth_Account_Roles_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "auth_account_roles" */
export type Auth_Account_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Account_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Account_Roles_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
}

export type Auth_Account_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Account_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Account_Roles_Bool_Exp;
};

/** columns and relationships of "auth.accounts" */
export type Auth_Accounts = {
  __typename?: 'auth_accounts';
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregate relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregate relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role: Scalars['String'];
  email?: Maybe<Scalars['citext']>;
  id: Scalars['uuid'];
  is_anonymous: Scalars['Boolean'];
  mfa_enabled: Scalars['Boolean'];
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** An aggregate relationship */
  refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** An object relationship */
  role: Auth_Roles;
  ticket: Scalars['uuid'];
  ticket_expires_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

/** columns and relationships of "auth.accounts" */
export type Auth_AccountsCustom_Register_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** aggregated selection of "auth.accounts" */
export type Auth_Accounts_Aggregate = {
  __typename?: 'auth_accounts_aggregate';
  aggregate?: Maybe<Auth_Accounts_Aggregate_Fields>;
  nodes: Array<Auth_Accounts>;
};

export type Auth_Accounts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Auth_Accounts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Auth_Accounts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Auth_Accounts_Aggregate_Bool_Exp_Count>;
};

export type Auth_Accounts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Auth_Accounts_Select_Column_Auth_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Auth_Accounts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Auth_Accounts_Select_Column_Auth_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Auth_Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_Fields = {
  __typename?: 'auth_accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Accounts_Max_Fields>;
  min?: Maybe<Auth_Accounts_Min_Fields>;
};

/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.accounts" */
export type Auth_Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Accounts_Max_Order_By>;
  min?: InputMaybe<Auth_Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Append_Input = {
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.accounts" */
export type Auth_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Auth_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export type Auth_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Auth_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Accounts_Bool_Exp>>;
  account_providers?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
  account_providers_aggregate?: InputMaybe<Auth_Account_Providers_Aggregate_Bool_Exp>;
  account_roles?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Bool_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_register_data?: InputMaybe<Jsonb_Comparison_Exp>;
  default_role?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_anonymous?: InputMaybe<Boolean_Comparison_Exp>;
  mfa_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  new_email?: InputMaybe<Citext_Comparison_Exp>;
  otp_secret?: InputMaybe<String_Comparison_Exp>;
  password_hash?: InputMaybe<String_Comparison_Exp>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp>;
  role?: InputMaybe<Auth_Roles_Bool_Exp>;
  ticket?: InputMaybe<Uuid_Comparison_Exp>;
  ticket_expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.accounts" */
export enum Auth_Accounts_Constraint {
  /** unique or primary key constraint on columns "email" */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint on columns "new_email" */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint on columns "user_id" */
  AccountsUserIdKey = 'accounts_user_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Auth_Accounts_Delete_At_Path_Input = {
  custom_register_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Auth_Accounts_Delete_Elem_Input = {
  custom_register_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Auth_Accounts_Delete_Key_Input = {
  custom_register_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.accounts" */
export type Auth_Accounts_Insert_Input = {
  account_providers?: InputMaybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  account_roles?: InputMaybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
  default_role?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_anonymous?: InputMaybe<Scalars['Boolean']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']>;
  new_email?: InputMaybe<Scalars['citext']>;
  otp_secret?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Auth_Roles_Obj_Rel_Insert_Input>;
  ticket?: InputMaybe<Scalars['uuid']>;
  ticket_expires_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Accounts_Max_Fields = {
  __typename?: 'auth_accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.accounts" */
export type Auth_Accounts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Accounts_Min_Fields = {
  __typename?: 'auth_accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.accounts" */
export type Auth_Accounts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.accounts" */
export type Auth_Accounts_Mutation_Response = {
  __typename?: 'auth_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Accounts>;
};

/** input type for inserting object relation for remote table "auth.accounts" */
export type Auth_Accounts_Obj_Rel_Insert_Input = {
  data: Auth_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "auth.accounts" */
export type Auth_Accounts_On_Conflict = {
  constraint: Auth_Accounts_Constraint;
  update_columns?: Array<Auth_Accounts_Update_Column>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.accounts". */
export type Auth_Accounts_Order_By = {
  account_providers_aggregate?: InputMaybe<Auth_Account_Providers_Aggregate_Order_By>;
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_register_data?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_anonymous?: InputMaybe<Order_By>;
  mfa_enabled?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Order_By>;
  role?: InputMaybe<Auth_Roles_Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.accounts */
export type Auth_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Prepend_Input = {
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "auth_accounts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column_Auth_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
}

/** select "auth_accounts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column_Auth_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
}

/** input type for updating data in table "auth.accounts" */
export type Auth_Accounts_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
  default_role?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_anonymous?: InputMaybe<Scalars['Boolean']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']>;
  new_email?: InputMaybe<Scalars['citext']>;
  otp_secret?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  ticket?: InputMaybe<Scalars['uuid']>;
  ticket_expires_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_accounts" */
export type Auth_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Accounts_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
  default_role?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_anonymous?: InputMaybe<Scalars['Boolean']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']>;
  new_email?: InputMaybe<Scalars['citext']>;
  otp_secret?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  ticket?: InputMaybe<Scalars['uuid']>;
  ticket_expires_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.accounts" */
export enum Auth_Accounts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Auth_Accounts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Auth_Accounts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Auth_Accounts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Auth_Accounts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Auth_Accounts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Auth_Accounts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Accounts_Bool_Exp;
};

/** columns and relationships of "auth.providers" */
export type Auth_Providers = {
  __typename?: 'auth_providers';
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregate relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  provider: Scalars['String'];
};

/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

/** aggregated selection of "auth.providers" */
export type Auth_Providers_Aggregate = {
  __typename?: 'auth_providers_aggregate';
  aggregate?: Maybe<Auth_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Providers>;
};

/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_Fields = {
  __typename?: 'auth_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Providers_Max_Fields>;
  min?: Maybe<Auth_Providers_Min_Fields>;
};

/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type Auth_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Providers_Bool_Exp>>;
  _not?: InputMaybe<Auth_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Providers_Bool_Exp>>;
  account_providers?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
  account_providers_aggregate?: InputMaybe<Auth_Account_Providers_Aggregate_Bool_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum Auth_Providers_Constraint {
  /** unique or primary key constraint on columns "provider" */
  ProvidersPkey = 'providers_pkey',
}

/** input type for inserting data into table "auth.providers" */
export type Auth_Providers_Insert_Input = {
  account_providers?: InputMaybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  provider?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Providers_Max_Fields = {
  __typename?: 'auth_providers_max_fields';
  provider?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Providers_Min_Fields = {
  __typename?: 'auth_providers_min_fields';
  provider?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.providers" */
export type Auth_Providers_Mutation_Response = {
  __typename?: 'auth_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Providers>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type Auth_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};

/** on_conflict condition type for table "auth.providers" */
export type Auth_Providers_On_Conflict = {
  constraint: Auth_Providers_Constraint;
  update_columns?: Array<Auth_Providers_Update_Column>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.providers". */
export type Auth_Providers_Order_By = {
  account_providers_aggregate?: InputMaybe<Auth_Account_Providers_Aggregate_Order_By>;
  provider?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.providers */
export type Auth_Providers_Pk_Columns_Input = {
  provider: Scalars['String'];
};

/** select columns of table "auth.providers" */
export enum Auth_Providers_Select_Column {
  /** column name */
  Provider = 'provider',
}

/** input type for updating data in table "auth.providers" */
export type Auth_Providers_Set_Input = {
  provider?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "auth_providers" */
export type Auth_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Providers_Stream_Cursor_Value_Input = {
  provider?: InputMaybe<Scalars['String']>;
};

/** update columns of table "auth.providers" */
export enum Auth_Providers_Update_Column {
  /** column name */
  Provider = 'provider',
}

export type Auth_Providers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Providers_Bool_Exp;
};

/** columns and relationships of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens = {
  __typename?: 'auth_refresh_tokens';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  refresh_token: Scalars['uuid'];
};

/** aggregated selection of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate = {
  __typename?: 'auth_refresh_tokens_aggregate';
  aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Fields>;
  nodes: Array<Auth_Refresh_Tokens>;
};

export type Auth_Refresh_Tokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp_Count>;
};

export type Auth_Refresh_Tokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Fields = {
  __typename?: 'auth_refresh_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Refresh_Tokens_Max_Fields>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Fields>;
};

/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Refresh_Tokens_Max_Order_By>;
  min?: InputMaybe<Auth_Refresh_Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Auth_Refresh_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type Auth_Refresh_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Refresh_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Refresh_Tokens_Bool_Exp>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  refresh_token?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Constraint {
  /** unique or primary key constraint on columns "refresh_token" */
  RefreshTokensPkey = 'refresh_tokens_pkey',
}

/** input type for inserting data into table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Insert_Input = {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  refresh_token?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Refresh_Tokens_Max_Fields = {
  __typename?: 'auth_refresh_tokens_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Refresh_Tokens_Min_Fields = {
  __typename?: 'auth_refresh_tokens_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Mutation_Response = {
  __typename?: 'auth_refresh_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Refresh_Tokens>;
};

/** on_conflict condition type for table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_On_Conflict = {
  constraint: Auth_Refresh_Tokens_Constraint;
  update_columns?: Array<Auth_Refresh_Tokens_Update_Column>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type Auth_Refresh_Tokens_Order_By = {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.refresh_tokens */
export type Auth_Refresh_Tokens_Pk_Columns_Input = {
  refresh_token: Scalars['uuid'];
};

/** select columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token',
}

/** input type for updating data in table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  refresh_token?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_refresh_tokens" */
export type Auth_Refresh_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Refresh_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Refresh_Tokens_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  refresh_token?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token',
}

export type Auth_Refresh_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Refresh_Tokens_Bool_Exp;
};

/** columns and relationships of "auth.roles" */
export type Auth_Roles = {
  __typename?: 'auth_roles';
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregate relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** An array relationship */
  accounts: Array<Auth_Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Auth_Accounts_Aggregate;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  organization_roles: Array<Organization_Roles>;
  /** An aggregate relationship */
  organization_roles_aggregate: Organization_Roles_Aggregate;
  role: Scalars['String'];
};

/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

/** columns and relationships of "auth.roles" */
export type Auth_RolesAccountsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

/** columns and relationships of "auth.roles" */
export type Auth_RolesAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

/** columns and relationships of "auth.roles" */
export type Auth_RolesOrganization_RolesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Roles_Order_By>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

/** columns and relationships of "auth.roles" */
export type Auth_RolesOrganization_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Roles_Order_By>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

/** aggregated selection of "auth.roles" */
export type Auth_Roles_Aggregate = {
  __typename?: 'auth_roles_aggregate';
  aggregate?: Maybe<Auth_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Roles>;
};

/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_Fields = {
  __typename?: 'auth_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Roles_Max_Fields>;
  min?: Maybe<Auth_Roles_Min_Fields>;
};

/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type Auth_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Roles_Bool_Exp>>;
  _not?: InputMaybe<Auth_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Roles_Bool_Exp>>;
  account_roles?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Bool_Exp>;
  accounts?: InputMaybe<Auth_Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Auth_Accounts_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  organization_roles?: InputMaybe<Organization_Roles_Bool_Exp>;
  organization_roles_aggregate?: InputMaybe<Organization_Roles_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum Auth_Roles_Constraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey',
}

/** input type for inserting data into table "auth.roles" */
export type Auth_Roles_Insert_Input = {
  account_roles?: InputMaybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  accounts?: InputMaybe<Auth_Accounts_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  organization_roles?: InputMaybe<Organization_Roles_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Roles_Max_Fields = {
  __typename?: 'auth_roles_max_fields';
  description?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Roles_Min_Fields = {
  __typename?: 'auth_roles_min_fields';
  description?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.roles" */
export type Auth_Roles_Mutation_Response = {
  __typename?: 'auth_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Roles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type Auth_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Roles_On_Conflict>;
};

/** on_conflict condition type for table "auth.roles" */
export type Auth_Roles_On_Conflict = {
  constraint: Auth_Roles_Constraint;
  update_columns?: Array<Auth_Roles_Update_Column>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.roles". */
export type Auth_Roles_Order_By = {
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Order_By>;
  accounts_aggregate?: InputMaybe<Auth_Accounts_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  organization_roles_aggregate?: InputMaybe<Organization_Roles_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.roles */
export type Auth_Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "auth.roles" */
export enum Auth_Roles_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Role = 'role',
}

/** input type for updating data in table "auth.roles" */
export type Auth_Roles_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "auth_roles" */
export type Auth_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Roles_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "auth.roles" */
export enum Auth_Roles_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Role = 'role',
}

export type Auth_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Roles_Bool_Exp;
};

/** columns and relationships of "award_posts" */
export type Award_Posts = {
  __typename?: 'award_posts';
  award_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  period_description?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "award_posts" */
export type Award_Posts_Aggregate = {
  __typename?: 'award_posts_aggregate';
  aggregate?: Maybe<Award_Posts_Aggregate_Fields>;
  nodes: Array<Award_Posts>;
};

export type Award_Posts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Award_Posts_Aggregate_Bool_Exp_Count>;
};

export type Award_Posts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Award_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Award_Posts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "award_posts" */
export type Award_Posts_Aggregate_Fields = {
  __typename?: 'award_posts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Award_Posts_Max_Fields>;
  min?: Maybe<Award_Posts_Min_Fields>;
};

/** aggregate fields of "award_posts" */
export type Award_Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Award_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "award_posts" */
export type Award_Posts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Award_Posts_Max_Order_By>;
  min?: InputMaybe<Award_Posts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "award_posts" */
export type Award_Posts_Arr_Rel_Insert_Input = {
  data: Array<Award_Posts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Award_Posts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "award_posts". All fields are combined with a logical 'AND'. */
export type Award_Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Award_Posts_Bool_Exp>>;
  _not?: InputMaybe<Award_Posts_Bool_Exp>;
  _or?: InputMaybe<Array<Award_Posts_Bool_Exp>>;
  award_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  period_description?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "award_posts" */
export enum Award_Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  AwardPostsPkey = 'award_posts_pkey',
}

/** input type for inserting data into table "award_posts" */
export type Award_Posts_Insert_Input = {
  award_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  period_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Award_Posts_Max_Fields = {
  __typename?: 'award_posts_max_fields';
  award_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  period_description?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "award_posts" */
export type Award_Posts_Max_Order_By = {
  award_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  period_description?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Award_Posts_Min_Fields = {
  __typename?: 'award_posts_min_fields';
  award_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  period_description?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "award_posts" */
export type Award_Posts_Min_Order_By = {
  award_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  period_description?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "award_posts" */
export type Award_Posts_Mutation_Response = {
  __typename?: 'award_posts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Award_Posts>;
};

/** on_conflict condition type for table "award_posts" */
export type Award_Posts_On_Conflict = {
  constraint: Award_Posts_Constraint;
  update_columns?: Array<Award_Posts_Update_Column>;
  where?: InputMaybe<Award_Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "award_posts". */
export type Award_Posts_Order_By = {
  award_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  period_description?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: award_posts */
export type Award_Posts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "award_posts" */
export enum Award_Posts_Select_Column {
  /** column name */
  AwardId = 'award_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PeriodDescription = 'period_description',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "award_posts" */
export type Award_Posts_Set_Input = {
  award_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  period_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "award_posts" */
export type Award_Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Award_Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Award_Posts_Stream_Cursor_Value_Input = {
  award_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  period_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "award_posts" */
export enum Award_Posts_Update_Column {
  /** column name */
  AwardId = 'award_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PeriodDescription = 'period_description',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Award_Posts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Award_Posts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Award_Posts_Bool_Exp;
};

/** columns and relationships of "awards" */
export type Awards = {
  __typename?: 'awards';
  /** An array relationship */
  award_posts: Array<Award_Posts>;
  /** An aggregate relationship */
  award_posts_aggregate: Award_Posts_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  cycle: Enum_Award_Cycle_Type_Enum;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  enum_award_cycle_type?: Maybe<Enum_Award_Cycle_Type>;
  id: Scalars['uuid'];
  is_active?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  organization_award_id?: Maybe<Scalars['uuid']>;
  organization_id: Scalars['uuid'];
  permissions?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "awards" */
export type AwardsAward_PostsArgs = {
  distinct_on?: InputMaybe<Array<Award_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Award_Posts_Order_By>>;
  where?: InputMaybe<Award_Posts_Bool_Exp>;
};

/** columns and relationships of "awards" */
export type AwardsAward_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Award_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Award_Posts_Order_By>>;
  where?: InputMaybe<Award_Posts_Bool_Exp>;
};

/** aggregated selection of "awards" */
export type Awards_Aggregate = {
  __typename?: 'awards_aggregate';
  aggregate?: Maybe<Awards_Aggregate_Fields>;
  nodes: Array<Awards>;
};

export type Awards_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Awards_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Awards_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Awards_Aggregate_Bool_Exp_Count>;
};

export type Awards_Aggregate_Bool_Exp_Bool_And = {
  arguments: Awards_Select_Column_Awards_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Awards_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Awards_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Awards_Select_Column_Awards_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Awards_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Awards_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Awards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Awards_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "awards" */
export type Awards_Aggregate_Fields = {
  __typename?: 'awards_aggregate_fields';
  avg?: Maybe<Awards_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Awards_Max_Fields>;
  min?: Maybe<Awards_Min_Fields>;
  stddev?: Maybe<Awards_Stddev_Fields>;
  stddev_pop?: Maybe<Awards_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Awards_Stddev_Samp_Fields>;
  sum?: Maybe<Awards_Sum_Fields>;
  var_pop?: Maybe<Awards_Var_Pop_Fields>;
  var_samp?: Maybe<Awards_Var_Samp_Fields>;
  variance?: Maybe<Awards_Variance_Fields>;
};

/** aggregate fields of "awards" */
export type Awards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Awards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "awards" */
export type Awards_Aggregate_Order_By = {
  avg?: InputMaybe<Awards_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Awards_Max_Order_By>;
  min?: InputMaybe<Awards_Min_Order_By>;
  stddev?: InputMaybe<Awards_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Awards_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Awards_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Awards_Sum_Order_By>;
  var_pop?: InputMaybe<Awards_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Awards_Var_Samp_Order_By>;
  variance?: InputMaybe<Awards_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "awards" */
export type Awards_Arr_Rel_Insert_Input = {
  data: Array<Awards_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Awards_On_Conflict>;
};

/** aggregate avg on columns */
export type Awards_Avg_Fields = {
  __typename?: 'awards_avg_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "awards" */
export type Awards_Avg_Order_By = {
  limit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "awards". All fields are combined with a logical 'AND'. */
export type Awards_Bool_Exp = {
  _and?: InputMaybe<Array<Awards_Bool_Exp>>;
  _not?: InputMaybe<Awards_Bool_Exp>;
  _or?: InputMaybe<Array<Awards_Bool_Exp>>;
  award_posts?: InputMaybe<Award_Posts_Bool_Exp>;
  award_posts_aggregate?: InputMaybe<Award_Posts_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  cycle?: InputMaybe<Enum_Award_Cycle_Type_Enum_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  enum_award_cycle_type?: InputMaybe<Enum_Award_Cycle_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  limit?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_award_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  permissions?: InputMaybe<String_Comparison_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "awards" */
export enum Awards_Constraint {
  /** unique or primary key constraint on columns "id" */
  AwardsPkey = 'awards_pkey',
}

/** input type for incrementing numeric columns in table "awards" */
export type Awards_Inc_Input = {
  limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "awards" */
export type Awards_Insert_Input = {
  award_posts?: InputMaybe<Award_Posts_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  cycle?: InputMaybe<Enum_Award_Cycle_Type_Enum>;
  description?: InputMaybe<Scalars['String']>;
  enum_award_cycle_type?: InputMaybe<Enum_Award_Cycle_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organization_award_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  permissions?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Awards_Max_Fields = {
  __typename?: 'awards_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_award_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  permissions?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "awards" */
export type Awards_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_award_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  permissions?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Awards_Min_Fields = {
  __typename?: 'awards_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_award_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  permissions?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "awards" */
export type Awards_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_award_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  permissions?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "awards" */
export type Awards_Mutation_Response = {
  __typename?: 'awards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Awards>;
};

/** on_conflict condition type for table "awards" */
export type Awards_On_Conflict = {
  constraint: Awards_Constraint;
  update_columns?: Array<Awards_Update_Column>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

/** Ordering options when selecting data from "awards". */
export type Awards_Order_By = {
  award_posts_aggregate?: InputMaybe<Award_Posts_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  cycle?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  enum_award_cycle_type?: InputMaybe<Enum_Award_Cycle_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_award_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  permissions?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: awards */
export type Awards_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "awards" */
export enum Awards_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Cycle = 'cycle',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Limit = 'limit',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationAwardId = 'organization_award_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "awards_aggregate_bool_exp_bool_and_arguments_columns" columns of table "awards" */
export enum Awards_Select_Column_Awards_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "awards_aggregate_bool_exp_bool_or_arguments_columns" columns of table "awards" */
export enum Awards_Select_Column_Awards_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "awards" */
export type Awards_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  cycle?: InputMaybe<Enum_Award_Cycle_Type_Enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organization_award_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  permissions?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Awards_Stddev_Fields = {
  __typename?: 'awards_stddev_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "awards" */
export type Awards_Stddev_Order_By = {
  limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Awards_Stddev_Pop_Fields = {
  __typename?: 'awards_stddev_pop_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "awards" */
export type Awards_Stddev_Pop_Order_By = {
  limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Awards_Stddev_Samp_Fields = {
  __typename?: 'awards_stddev_samp_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "awards" */
export type Awards_Stddev_Samp_Order_By = {
  limit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "awards" */
export type Awards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Awards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Awards_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  cycle?: InputMaybe<Enum_Award_Cycle_Type_Enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organization_award_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  permissions?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Awards_Sum_Fields = {
  __typename?: 'awards_sum_fields';
  limit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "awards" */
export type Awards_Sum_Order_By = {
  limit?: InputMaybe<Order_By>;
};

/** update columns of table "awards" */
export enum Awards_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Cycle = 'cycle',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Limit = 'limit',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationAwardId = 'organization_award_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Awards_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Awards_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Awards_Set_Input>;
  /** filter the rows which have to be updated */
  where: Awards_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Awards_Var_Pop_Fields = {
  __typename?: 'awards_var_pop_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "awards" */
export type Awards_Var_Pop_Order_By = {
  limit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Awards_Var_Samp_Fields = {
  __typename?: 'awards_var_samp_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "awards" */
export type Awards_Var_Samp_Order_By = {
  limit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Awards_Variance_Fields = {
  __typename?: 'awards_variance_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "awards" */
export type Awards_Variance_Order_By = {
  limit?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

export type Booked_Seats_Organization_Locations_Args = {
  booking_date?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "calendar" */
export type Calendar = {
  __typename?: 'calendar';
  /** An array relationship */
  calendar_attendees: Array<Calendar_Attendee>;
  /** An aggregate relationship */
  calendar_attendees_aggregate: Calendar_Attendee_Aggregate;
  /** An array relationship */
  calendar_events: Array<Calendar_Event>;
  /** An aggregate relationship */
  calendar_events_aggregate: Calendar_Event_Aggregate;
  calendar_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  default_timezone: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** columns and relationships of "calendar" */
export type CalendarCalendar_AttendeesArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "calendar" */
export type CalendarCalendar_Attendees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "calendar" */
export type CalendarCalendar_EventsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

/** columns and relationships of "calendar" */
export type CalendarCalendar_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

/** aggregated selection of "calendar" */
export type Calendar_Aggregate = {
  __typename?: 'calendar_aggregate';
  aggregate?: Maybe<Calendar_Aggregate_Fields>;
  nodes: Array<Calendar>;
};

export type Calendar_Aggregate_Bool_Exp = {
  count?: InputMaybe<Calendar_Aggregate_Bool_Exp_Count>;
};

export type Calendar_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendar" */
export type Calendar_Aggregate_Fields = {
  __typename?: 'calendar_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Max_Fields>;
  min?: Maybe<Calendar_Min_Fields>;
};

/** aggregate fields of "calendar" */
export type Calendar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendar" */
export type Calendar_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendar_Max_Order_By>;
  min?: InputMaybe<Calendar_Min_Order_By>;
};

/** input type for inserting array relation for remote table "calendar" */
export type Calendar_Arr_Rel_Insert_Input = {
  data: Array<Calendar_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_On_Conflict>;
};

/** columns and relationships of "calendar_attendee" */
export type Calendar_Attendee = {
  __typename?: 'calendar_attendee';
  attendee_id?: Maybe<Scalars['uuid']>;
  attendee_name?: Maybe<Scalars['String']>;
  attendee_status: Enum_Attendee_Status_Type_Enum;
  attendee_type: Enum_Attendee_Type_Enum;
  /** An object relationship */
  calendar?: Maybe<Calendar>;
  /** An object relationship */
  calendar_event: Calendar_Event;
  calendar_event_id: Scalars['uuid'];
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** An object relationship */
  enum_attendee_status_type: Enum_Attendee_Status_Type;
  /** An object relationship */
  enum_attendee_type: Enum_Attendee_Type;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByCreatedBy: Users;
};

/** aggregated selection of "calendar_attendee" */
export type Calendar_Attendee_Aggregate = {
  __typename?: 'calendar_attendee_aggregate';
  aggregate?: Maybe<Calendar_Attendee_Aggregate_Fields>;
  nodes: Array<Calendar_Attendee>;
};

export type Calendar_Attendee_Aggregate_Bool_Exp = {
  count?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp_Count>;
};

export type Calendar_Attendee_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendar_attendee" */
export type Calendar_Attendee_Aggregate_Fields = {
  __typename?: 'calendar_attendee_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Attendee_Max_Fields>;
  min?: Maybe<Calendar_Attendee_Min_Fields>;
};

/** aggregate fields of "calendar_attendee" */
export type Calendar_Attendee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendar_attendee" */
export type Calendar_Attendee_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendar_Attendee_Max_Order_By>;
  min?: InputMaybe<Calendar_Attendee_Min_Order_By>;
};

/** input type for inserting array relation for remote table "calendar_attendee" */
export type Calendar_Attendee_Arr_Rel_Insert_Input = {
  data: Array<Calendar_Attendee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_Attendee_On_Conflict>;
};

/** Boolean expression to filter rows from the table "calendar_attendee". All fields are combined with a logical 'AND'. */
export type Calendar_Attendee_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Attendee_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Attendee_Bool_Exp>>;
  attendee_id?: InputMaybe<Uuid_Comparison_Exp>;
  attendee_name?: InputMaybe<String_Comparison_Exp>;
  attendee_status?: InputMaybe<Enum_Attendee_Status_Type_Enum_Comparison_Exp>;
  attendee_type?: InputMaybe<Enum_Attendee_Type_Enum_Comparison_Exp>;
  calendar?: InputMaybe<Calendar_Bool_Exp>;
  calendar_event?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  calendar_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  enum_attendee_status_type?: InputMaybe<Enum_Attendee_Status_Type_Bool_Exp>;
  enum_attendee_type?: InputMaybe<Enum_Attendee_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByCreatedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "calendar_attendee" */
export enum Calendar_Attendee_Constraint {
  /** unique or primary key constraint on columns "calendar_event_id", "attendee_id" */
  CalendarAttendeesCalendarEventIdAttendeeIdKey = 'calendar_attendees_calendar_event_id_attendee_id_key',
  /** unique or primary key constraint on columns "email", "calendar_event_id" */
  CalendarAttendeesCalendarEventIdEmailKey = 'calendar_attendees_calendar_event_id_email_key',
  /** unique or primary key constraint on columns "id" */
  CalendarAttendeesPkey = 'calendar_attendees_pkey',
}

/** input type for inserting data into table "calendar_attendee" */
export type Calendar_Attendee_Insert_Input = {
  attendee_id?: InputMaybe<Scalars['uuid']>;
  attendee_name?: InputMaybe<Scalars['String']>;
  attendee_status?: InputMaybe<Enum_Attendee_Status_Type_Enum>;
  attendee_type?: InputMaybe<Enum_Attendee_Type_Enum>;
  calendar?: InputMaybe<Calendar_Obj_Rel_Insert_Input>;
  calendar_event?: InputMaybe<Calendar_Event_Obj_Rel_Insert_Input>;
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  calendar_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  enum_attendee_status_type?: InputMaybe<Enum_Attendee_Status_Type_Obj_Rel_Insert_Input>;
  enum_attendee_type?: InputMaybe<Enum_Attendee_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByCreatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Calendar_Attendee_Max_Fields = {
  __typename?: 'calendar_attendee_max_fields';
  attendee_id?: Maybe<Scalars['uuid']>;
  attendee_name?: Maybe<Scalars['String']>;
  calendar_event_id?: Maybe<Scalars['uuid']>;
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "calendar_attendee" */
export type Calendar_Attendee_Max_Order_By = {
  attendee_id?: InputMaybe<Order_By>;
  attendee_name?: InputMaybe<Order_By>;
  calendar_event_id?: InputMaybe<Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calendar_Attendee_Min_Fields = {
  __typename?: 'calendar_attendee_min_fields';
  attendee_id?: Maybe<Scalars['uuid']>;
  attendee_name?: Maybe<Scalars['String']>;
  calendar_event_id?: Maybe<Scalars['uuid']>;
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "calendar_attendee" */
export type Calendar_Attendee_Min_Order_By = {
  attendee_id?: InputMaybe<Order_By>;
  attendee_name?: InputMaybe<Order_By>;
  calendar_event_id?: InputMaybe<Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendar_attendee" */
export type Calendar_Attendee_Mutation_Response = {
  __typename?: 'calendar_attendee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendar_Attendee>;
};

/** on_conflict condition type for table "calendar_attendee" */
export type Calendar_Attendee_On_Conflict = {
  constraint: Calendar_Attendee_Constraint;
  update_columns?: Array<Calendar_Attendee_Update_Column>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** Ordering options when selecting data from "calendar_attendee". */
export type Calendar_Attendee_Order_By = {
  attendee_id?: InputMaybe<Order_By>;
  attendee_name?: InputMaybe<Order_By>;
  attendee_status?: InputMaybe<Order_By>;
  attendee_type?: InputMaybe<Order_By>;
  calendar?: InputMaybe<Calendar_Order_By>;
  calendar_event?: InputMaybe<Calendar_Event_Order_By>;
  calendar_event_id?: InputMaybe<Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  enum_attendee_status_type?: InputMaybe<Enum_Attendee_Status_Type_Order_By>;
  enum_attendee_type?: InputMaybe<Enum_Attendee_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByCreatedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: calendar_attendee */
export type Calendar_Attendee_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "calendar_attendee" */
export enum Calendar_Attendee_Select_Column {
  /** column name */
  AttendeeId = 'attendee_id',
  /** column name */
  AttendeeName = 'attendee_name',
  /** column name */
  AttendeeStatus = 'attendee_status',
  /** column name */
  AttendeeType = 'attendee_type',
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "calendar_attendee" */
export type Calendar_Attendee_Set_Input = {
  attendee_id?: InputMaybe<Scalars['uuid']>;
  attendee_name?: InputMaybe<Scalars['String']>;
  attendee_status?: InputMaybe<Enum_Attendee_Status_Type_Enum>;
  attendee_type?: InputMaybe<Enum_Attendee_Type_Enum>;
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  calendar_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "calendar_attendee" */
export type Calendar_Attendee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Attendee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Attendee_Stream_Cursor_Value_Input = {
  attendee_id?: InputMaybe<Scalars['uuid']>;
  attendee_name?: InputMaybe<Scalars['String']>;
  attendee_status?: InputMaybe<Enum_Attendee_Status_Type_Enum>;
  attendee_type?: InputMaybe<Enum_Attendee_Type_Enum>;
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  calendar_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "calendar_attendee" */
export enum Calendar_Attendee_Update_Column {
  /** column name */
  AttendeeId = 'attendee_id',
  /** column name */
  AttendeeName = 'attendee_name',
  /** column name */
  AttendeeStatus = 'attendee_status',
  /** column name */
  AttendeeType = 'attendee_type',
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Calendar_Attendee_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendar_Attendee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendar_Attendee_Bool_Exp;
};

/** Boolean expression to filter rows from the table "calendar". All fields are combined with a logical 'AND'. */
export type Calendar_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Bool_Exp>>;
  calendar_attendees?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp>;
  calendar_events?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_events_aggregate?: InputMaybe<Calendar_Event_Aggregate_Bool_Exp>;
  calendar_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  default_timezone?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "calendar" */
export enum Calendar_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalendarsPkey = 'calendars_pkey',
}

/** columns and relationships of "calendar_delete_events" */
export type Calendar_Delete_Events = {
  __typename?: 'calendar_delete_events';
  /** An object relationship */
  calendar_event: Calendar_Event;
  calendar_event_id: Scalars['uuid'];
  excluded_date: Scalars['timestamptz'];
  id: Scalars['uuid'];
};

/** aggregated selection of "calendar_delete_events" */
export type Calendar_Delete_Events_Aggregate = {
  __typename?: 'calendar_delete_events_aggregate';
  aggregate?: Maybe<Calendar_Delete_Events_Aggregate_Fields>;
  nodes: Array<Calendar_Delete_Events>;
};

export type Calendar_Delete_Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Calendar_Delete_Events_Aggregate_Bool_Exp_Count>;
};

export type Calendar_Delete_Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendar_Delete_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendar_delete_events" */
export type Calendar_Delete_Events_Aggregate_Fields = {
  __typename?: 'calendar_delete_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Delete_Events_Max_Fields>;
  min?: Maybe<Calendar_Delete_Events_Min_Fields>;
};

/** aggregate fields of "calendar_delete_events" */
export type Calendar_Delete_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Delete_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendar_delete_events" */
export type Calendar_Delete_Events_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendar_Delete_Events_Max_Order_By>;
  min?: InputMaybe<Calendar_Delete_Events_Min_Order_By>;
};

/** input type for inserting array relation for remote table "calendar_delete_events" */
export type Calendar_Delete_Events_Arr_Rel_Insert_Input = {
  data: Array<Calendar_Delete_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_Delete_Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "calendar_delete_events". All fields are combined with a logical 'AND'. */
export type Calendar_Delete_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Delete_Events_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Delete_Events_Bool_Exp>>;
  calendar_event?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  excluded_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendar_delete_events" */
export enum Calendar_Delete_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalendarDeleteEventsPkey = 'calendar_delete_events_pkey',
}

/** input type for inserting data into table "calendar_delete_events" */
export type Calendar_Delete_Events_Insert_Input = {
  calendar_event?: InputMaybe<Calendar_Event_Obj_Rel_Insert_Input>;
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  excluded_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Calendar_Delete_Events_Max_Fields = {
  __typename?: 'calendar_delete_events_max_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  excluded_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "calendar_delete_events" */
export type Calendar_Delete_Events_Max_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  excluded_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calendar_Delete_Events_Min_Fields = {
  __typename?: 'calendar_delete_events_min_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  excluded_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "calendar_delete_events" */
export type Calendar_Delete_Events_Min_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  excluded_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendar_delete_events" */
export type Calendar_Delete_Events_Mutation_Response = {
  __typename?: 'calendar_delete_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendar_Delete_Events>;
};

/** on_conflict condition type for table "calendar_delete_events" */
export type Calendar_Delete_Events_On_Conflict = {
  constraint: Calendar_Delete_Events_Constraint;
  update_columns?: Array<Calendar_Delete_Events_Update_Column>;
  where?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "calendar_delete_events". */
export type Calendar_Delete_Events_Order_By = {
  calendar_event?: InputMaybe<Calendar_Event_Order_By>;
  calendar_event_id?: InputMaybe<Order_By>;
  excluded_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendar_delete_events */
export type Calendar_Delete_Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "calendar_delete_events" */
export enum Calendar_Delete_Events_Select_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  ExcludedDate = 'excluded_date',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "calendar_delete_events" */
export type Calendar_Delete_Events_Set_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  excluded_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "calendar_delete_events" */
export type Calendar_Delete_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Delete_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Delete_Events_Stream_Cursor_Value_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  excluded_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "calendar_delete_events" */
export enum Calendar_Delete_Events_Update_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  ExcludedDate = 'excluded_date',
  /** column name */
  Id = 'id',
}

export type Calendar_Delete_Events_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendar_Delete_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendar_Delete_Events_Bool_Exp;
};

/** columns and relationships of "calendar_event" */
export type Calendar_Event = {
  __typename?: 'calendar_event';
  additional_fields: Scalars['jsonb'];
  all_day: Scalars['Boolean'];
  /** An object relationship */
  calendar: Calendar;
  /** An array relationship */
  calendar_attendees: Array<Calendar_Attendee>;
  /** An aggregate relationship */
  calendar_attendees_aggregate: Calendar_Attendee_Aggregate;
  /** An array relationship */
  calendar_delete_events: Array<Calendar_Delete_Events>;
  /** An aggregate relationship */
  calendar_delete_events_aggregate: Calendar_Delete_Events_Aggregate;
  calendar_id: Scalars['uuid'];
  /** An array relationship */
  calendar_meeting_calls: Array<Calendar_Meeting_Call>;
  /** An aggregate relationship */
  calendar_meeting_calls_aggregate: Calendar_Meeting_Call_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  end_date_time: Scalars['timestamptz'];
  /** An object relationship */
  enum_event_type: Enum_Event_Type;
  event_name: Scalars['String'];
  event_type: Enum_Event_Type_Enum;
  id: Scalars['uuid'];
  live_training?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  performance_review_byUsers: Array<Performance_Review_ByUser>;
  /** An aggregate relationship */
  performance_review_byUsers_aggregate: Performance_Review_ByUser_Aggregate;
  /** An array relationship */
  project_sprints: Array<Project_Sprints>;
  /** An aggregate relationship */
  project_sprints_aggregate: Project_Sprints_Aggregate;
  rrule?: Maybe<Scalars['String']>;
  scheduler_rrule?: Maybe<Scalars['String']>;
  start_date_time: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventAdditional_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventCalendar_AttendeesArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventCalendar_Attendees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventCalendar_Delete_EventsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Delete_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Delete_Events_Order_By>>;
  where?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventCalendar_Delete_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Delete_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Delete_Events_Order_By>>;
  where?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventCalendar_Meeting_CallsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventCalendar_Meeting_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventPerformance_Review_ByUsersArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventPerformance_Review_ByUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventProject_SprintsArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** columns and relationships of "calendar_event" */
export type Calendar_EventProject_Sprints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** aggregated selection of "calendar_event" */
export type Calendar_Event_Aggregate = {
  __typename?: 'calendar_event_aggregate';
  aggregate?: Maybe<Calendar_Event_Aggregate_Fields>;
  nodes: Array<Calendar_Event>;
};

export type Calendar_Event_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Calendar_Event_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Calendar_Event_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Calendar_Event_Aggregate_Bool_Exp_Count>;
};

export type Calendar_Event_Aggregate_Bool_Exp_Bool_And = {
  arguments: Calendar_Event_Select_Column_Calendar_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Calendar_Event_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Calendar_Event_Select_Column_Calendar_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Calendar_Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendar_event" */
export type Calendar_Event_Aggregate_Fields = {
  __typename?: 'calendar_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Event_Max_Fields>;
  min?: Maybe<Calendar_Event_Min_Fields>;
};

/** aggregate fields of "calendar_event" */
export type Calendar_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendar_event" */
export type Calendar_Event_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendar_Event_Max_Order_By>;
  min?: InputMaybe<Calendar_Event_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Calendar_Event_Append_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "calendar_event" */
export type Calendar_Event_Arr_Rel_Insert_Input = {
  data: Array<Calendar_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "calendar_event". All fields are combined with a logical 'AND'. */
export type Calendar_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Event_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Event_Bool_Exp>>;
  additional_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  all_day?: InputMaybe<Boolean_Comparison_Exp>;
  calendar?: InputMaybe<Calendar_Bool_Exp>;
  calendar_attendees?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp>;
  calendar_delete_events?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
  calendar_delete_events_aggregate?: InputMaybe<Calendar_Delete_Events_Aggregate_Bool_Exp>;
  calendar_id?: InputMaybe<Uuid_Comparison_Exp>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  end_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_event_type?: InputMaybe<Enum_Event_Type_Bool_Exp>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  event_type?: InputMaybe<Enum_Event_Type_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  live_training?: InputMaybe<Boolean_Comparison_Exp>;
  performance_review_byUsers?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  performance_review_byUsers_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Bool_Exp>;
  project_sprints?: InputMaybe<Project_Sprints_Bool_Exp>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Bool_Exp>;
  rrule?: InputMaybe<String_Comparison_Exp>;
  scheduler_rrule?: InputMaybe<String_Comparison_Exp>;
  start_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "calendar_event" */
export enum Calendar_Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalendarEventPkey = 'calendar_event_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Calendar_Event_Delete_At_Path_Input = {
  additional_fields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Calendar_Event_Delete_Elem_Input = {
  additional_fields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Calendar_Event_Delete_Key_Input = {
  additional_fields?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "calendar_event" */
export type Calendar_Event_Insert_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  all_day?: InputMaybe<Scalars['Boolean']>;
  calendar?: InputMaybe<Calendar_Obj_Rel_Insert_Input>;
  calendar_attendees?: InputMaybe<Calendar_Attendee_Arr_Rel_Insert_Input>;
  calendar_delete_events?: InputMaybe<Calendar_Delete_Events_Arr_Rel_Insert_Input>;
  calendar_id?: InputMaybe<Scalars['uuid']>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  enum_event_type?: InputMaybe<Enum_Event_Type_Obj_Rel_Insert_Input>;
  event_name?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Enum_Event_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  live_training?: InputMaybe<Scalars['Boolean']>;
  performance_review_byUsers?: InputMaybe<Performance_Review_ByUser_Arr_Rel_Insert_Input>;
  project_sprints?: InputMaybe<Project_Sprints_Arr_Rel_Insert_Input>;
  rrule?: InputMaybe<Scalars['String']>;
  scheduler_rrule?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** columns and relationships of "calendar_event_material_view" */
export type Calendar_Event_Material_View = {
  __typename?: 'calendar_event_material_view';
  additional_fields?: Maybe<Scalars['jsonb']>;
  all_day?: Maybe<Scalars['Boolean']>;
  /** fetch data from the table: "calendar_attendee" */
  calendar_attendee: Array<Calendar_Attendee>;
  /** fetch aggregated fields from the table: "calendar_attendee" */
  calendar_attendee_aggregate: Calendar_Attendee_Aggregate;
  /** An object relationship */
  calendar_events?: Maybe<Calendar_Event>;
  calendar_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  calendar_meeting_calls: Array<Calendar_Meeting_Call>;
  /** An aggregate relationship */
  calendar_meeting_calls_aggregate: Calendar_Meeting_Call_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "joined_call_usrs" */
  joinedMeetingUsers?: Maybe<Array<Calendar_Meeting_Call_User>>;
  /** An object relationship */
  meetingCallUser?: Maybe<Calendar_Meeting_Call_User>;
  rrule?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "calendar_event_material_view" */
export type Calendar_Event_Material_ViewAdditional_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "calendar_event_material_view" */
export type Calendar_Event_Material_ViewCalendar_AttendeeArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "calendar_event_material_view" */
export type Calendar_Event_Material_ViewCalendar_Attendee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "calendar_event_material_view" */
export type Calendar_Event_Material_ViewCalendar_Meeting_CallsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "calendar_event_material_view" */
export type Calendar_Event_Material_ViewCalendar_Meeting_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "calendar_event_material_view" */
export type Calendar_Event_Material_ViewJoinedMeetingUsersArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

/** aggregated selection of "calendar_event_material_view" */
export type Calendar_Event_Material_View_Aggregate = {
  __typename?: 'calendar_event_material_view_aggregate';
  aggregate?: Maybe<Calendar_Event_Material_View_Aggregate_Fields>;
  nodes: Array<Calendar_Event_Material_View>;
};

/** aggregate fields of "calendar_event_material_view" */
export type Calendar_Event_Material_View_Aggregate_Fields = {
  __typename?: 'calendar_event_material_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Event_Material_View_Max_Fields>;
  min?: Maybe<Calendar_Event_Material_View_Min_Fields>;
};

/** aggregate fields of "calendar_event_material_view" */
export type Calendar_Event_Material_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Event_Material_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "calendar_event_material_view". All fields are combined with a logical 'AND'. */
export type Calendar_Event_Material_View_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Event_Material_View_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Event_Material_View_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Event_Material_View_Bool_Exp>>;
  additional_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  all_day?: InputMaybe<Boolean_Comparison_Exp>;
  calendar_attendee?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  calendar_attendee_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp>;
  calendar_events?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_id?: InputMaybe<Uuid_Comparison_Exp>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  end_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  joinedMeetingUsers?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
  meetingCallUser?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
  rrule?: InputMaybe<String_Comparison_Exp>;
  start_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Calendar_Event_Material_View_Max_Fields = {
  __typename?: 'calendar_event_material_view_max_fields';
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Calendar_Event_Material_View_Min_Fields = {
  __typename?: 'calendar_event_material_view_min_fields';
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "calendar_event_material_view". */
export type Calendar_Event_Material_View_Order_By = {
  additional_fields?: InputMaybe<Order_By>;
  all_day?: InputMaybe<Order_By>;
  calendar_attendee_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Order_By>;
  calendar_events?: InputMaybe<Calendar_Event_Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  joinedMeetingUsers_aggregate?: InputMaybe<Calendar_Meeting_Call_User_Aggregate_Order_By>;
  meetingCallUser?: InputMaybe<Calendar_Meeting_Call_User_Order_By>;
  rrule?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "calendar_event_material_view" */
export enum Calendar_Event_Material_View_Select_Column {
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AllDay = 'all_day',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  EventName = 'event_name',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Rrule = 'rrule',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "calendar_event_material_view" */
export type Calendar_Event_Material_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Event_Material_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Event_Material_View_Stream_Cursor_Value_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  all_day?: InputMaybe<Scalars['Boolean']>;
  calendar_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  event_name?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  rrule?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Calendar_Event_Max_Fields = {
  __typename?: 'calendar_event_max_fields';
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  scheduler_rrule?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "calendar_event" */
export type Calendar_Event_Max_Order_By = {
  calendar_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  scheduler_rrule?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calendar_Event_Min_Fields = {
  __typename?: 'calendar_event_min_fields';
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  scheduler_rrule?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "calendar_event" */
export type Calendar_Event_Min_Order_By = {
  calendar_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  scheduler_rrule?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendar_event" */
export type Calendar_Event_Mutation_Response = {
  __typename?: 'calendar_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendar_Event>;
};

/** input type for inserting object relation for remote table "calendar_event" */
export type Calendar_Event_Obj_Rel_Insert_Input = {
  data: Calendar_Event_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_Event_On_Conflict>;
};

/** on_conflict condition type for table "calendar_event" */
export type Calendar_Event_On_Conflict = {
  constraint: Calendar_Event_Constraint;
  update_columns?: Array<Calendar_Event_Update_Column>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "calendar_event". */
export type Calendar_Event_Order_By = {
  additional_fields?: InputMaybe<Order_By>;
  all_day?: InputMaybe<Order_By>;
  calendar?: InputMaybe<Calendar_Order_By>;
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Order_By>;
  calendar_delete_events_aggregate?: InputMaybe<Calendar_Delete_Events_Aggregate_Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  enum_event_type?: InputMaybe<Enum_Event_Type_Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_training?: InputMaybe<Order_By>;
  performance_review_byUsers_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Order_By>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Order_By>;
  rrule?: InputMaybe<Order_By>;
  scheduler_rrule?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: calendar_event */
export type Calendar_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Calendar_Event_Prepend_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "calendar_event" */
export enum Calendar_Event_Select_Column {
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AllDay = 'all_day',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  EventName = 'event_name',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  LiveTraining = 'live_training',
  /** column name */
  Rrule = 'rrule',
  /** column name */
  SchedulerRrule = 'scheduler_rrule',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "calendar_event_aggregate_bool_exp_bool_and_arguments_columns" columns of table "calendar_event" */
export enum Calendar_Event_Select_Column_Calendar_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllDay = 'all_day',
  /** column name */
  LiveTraining = 'live_training',
}

/** select "calendar_event_aggregate_bool_exp_bool_or_arguments_columns" columns of table "calendar_event" */
export enum Calendar_Event_Select_Column_Calendar_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllDay = 'all_day',
  /** column name */
  LiveTraining = 'live_training',
}

/** input type for updating data in table "calendar_event" */
export type Calendar_Event_Set_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  all_day?: InputMaybe<Scalars['Boolean']>;
  calendar_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  event_name?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Enum_Event_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  live_training?: InputMaybe<Scalars['Boolean']>;
  rrule?: InputMaybe<Scalars['String']>;
  scheduler_rrule?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "calendar_event" */
export type Calendar_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Event_Stream_Cursor_Value_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  all_day?: InputMaybe<Scalars['Boolean']>;
  calendar_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  event_name?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Enum_Event_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  live_training?: InputMaybe<Scalars['Boolean']>;
  rrule?: InputMaybe<Scalars['String']>;
  scheduler_rrule?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "calendar_event" */
export enum Calendar_Event_Update_Column {
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AllDay = 'all_day',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  EventName = 'event_name',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  LiveTraining = 'live_training',
  /** column name */
  Rrule = 'rrule',
  /** column name */
  SchedulerRrule = 'scheduler_rrule',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Calendar_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Calendar_Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Calendar_Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Calendar_Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Calendar_Event_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Calendar_Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendar_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendar_Event_Bool_Exp;
};

/** input type for inserting data into table "calendar" */
export type Calendar_Insert_Input = {
  calendar_attendees?: InputMaybe<Calendar_Attendee_Arr_Rel_Insert_Input>;
  calendar_events?: InputMaybe<Calendar_Event_Arr_Rel_Insert_Input>;
  calendar_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  default_timezone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Calendar_Max_Fields = {
  __typename?: 'calendar_max_fields';
  calendar_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  default_timezone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "calendar" */
export type Calendar_Max_Order_By = {
  calendar_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_timezone?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Call created for calendar event meetings */
export type Calendar_Meeting_Call = {
  __typename?: 'calendar_meeting_call';
  /** An object relationship */
  calendar_event: Calendar_Event;
  /** An array relationship */
  calendar_meeting_call_requests: Array<Calendar_Meeting_Call_Request>;
  /** An aggregate relationship */
  calendar_meeting_call_requests_aggregate: Calendar_Meeting_Call_Request_Aggregate;
  channel_id: Scalars['String'];
  chat_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_chat_room_id_from_event_id_for_meeting_call" */
  chat_room?: Maybe<Array<Chat_Room>>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_by_user: Users;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_call_recording_status?: Maybe<Enum_Call_Recording_Status>;
  /** An object relationship */
  enum_call_status_type?: Maybe<Enum_Call_Status_Type>;
  event_id: Scalars['uuid'];
  id: Scalars['uuid'];
  meet_status?: Maybe<Enum_Call_Status_Type_Enum>;
  /** An array relationship */
  meetingSchedulars: Array<Meeting_Call_Schedular>;
  /** An aggregate relationship */
  meetingSchedulars_aggregate: Meeting_Call_Schedular_Aggregate;
  /** An array relationship */
  meeting_call_users: Array<Calendar_Meeting_Call_User>;
  /** An aggregate relationship */
  meeting_call_users_aggregate: Calendar_Meeting_Call_User_Aggregate;
  metadata?: Maybe<Scalars['jsonb']>;
  org_id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  reacording_started_by?: Maybe<Scalars['uuid']>;
  recording_link?: Maybe<Scalars['String']>;
  recording_status?: Maybe<Enum_Call_Recording_Status_Enum>;
  resource_id?: Maybe<Scalars['String']>;
  sid?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  uid?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** Call created for calendar event meetings */
export type Calendar_Meeting_CallCalendar_Meeting_Call_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

/** Call created for calendar event meetings */
export type Calendar_Meeting_CallCalendar_Meeting_Call_Requests_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Calendar_Meeting_Call_Request_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
    where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
  };

/** Call created for calendar event meetings */
export type Calendar_Meeting_CallChat_RoomArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** Call created for calendar event meetings */
export type Calendar_Meeting_CallMeetingSchedularsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Call_Schedular_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Call_Schedular_Order_By>>;
  where?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
};

/** Call created for calendar event meetings */
export type Calendar_Meeting_CallMeetingSchedulars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Call_Schedular_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Call_Schedular_Order_By>>;
  where?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
};

/** Call created for calendar event meetings */
export type Calendar_Meeting_CallMeeting_Call_UsersArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

/** Call created for calendar event meetings */
export type Calendar_Meeting_CallMeeting_Call_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

/** Call created for calendar event meetings */
export type Calendar_Meeting_CallMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "calendar_meeting_call" */
export type Calendar_Meeting_Call_Aggregate = {
  __typename?: 'calendar_meeting_call_aggregate';
  aggregate?: Maybe<Calendar_Meeting_Call_Aggregate_Fields>;
  nodes: Array<Calendar_Meeting_Call>;
};

export type Calendar_Meeting_Call_Aggregate_Bool_Exp = {
  count?: InputMaybe<Calendar_Meeting_Call_Aggregate_Bool_Exp_Count>;
};

export type Calendar_Meeting_Call_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendar_meeting_call" */
export type Calendar_Meeting_Call_Aggregate_Fields = {
  __typename?: 'calendar_meeting_call_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Meeting_Call_Max_Fields>;
  min?: Maybe<Calendar_Meeting_Call_Min_Fields>;
};

/** aggregate fields of "calendar_meeting_call" */
export type Calendar_Meeting_Call_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendar_Meeting_Call_Max_Order_By>;
  min?: InputMaybe<Calendar_Meeting_Call_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Calendar_Meeting_Call_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Arr_Rel_Insert_Input = {
  data: Array<Calendar_Meeting_Call_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_Meeting_Call_On_Conflict>;
};

/** Boolean expression to filter rows from the table "calendar_meeting_call". All fields are combined with a logical 'AND'. */
export type Calendar_Meeting_Call_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Meeting_Call_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Meeting_Call_Bool_Exp>>;
  calendar_event?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_meeting_call_requests?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
  calendar_meeting_call_requests_aggregate?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Bool_Exp>;
  channel_id?: InputMaybe<String_Comparison_Exp>;
  chat_id?: InputMaybe<Uuid_Comparison_Exp>;
  chat_room?: InputMaybe<Chat_Room_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user?: InputMaybe<Users_Bool_Exp>;
  end_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
  enum_call_status_type?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meet_status?: InputMaybe<Enum_Call_Status_Type_Enum_Comparison_Exp>;
  meetingSchedulars?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
  meetingSchedulars_aggregate?: InputMaybe<Meeting_Call_Schedular_Aggregate_Bool_Exp>;
  meeting_call_users?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
  meeting_call_users_aggregate?: InputMaybe<Calendar_Meeting_Call_User_Aggregate_Bool_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  reacording_started_by?: InputMaybe<Uuid_Comparison_Exp>;
  recording_link?: InputMaybe<String_Comparison_Exp>;
  recording_status?: InputMaybe<Enum_Call_Recording_Status_Enum_Comparison_Exp>;
  resource_id?: InputMaybe<String_Comparison_Exp>;
  sid?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  start_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendar_meeting_call" */
export enum Calendar_Meeting_Call_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalendarMeetingCallPkey = 'calendar_meeting_call_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Calendar_Meeting_Call_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Calendar_Meeting_Call_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Calendar_Meeting_Call_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Insert_Input = {
  calendar_event?: InputMaybe<Calendar_Event_Obj_Rel_Insert_Input>;
  calendar_meeting_call_requests?: InputMaybe<Calendar_Meeting_Call_Request_Arr_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars['String']>;
  chat_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  enum_call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Obj_Rel_Insert_Input>;
  enum_call_status_type?: InputMaybe<Enum_Call_Status_Type_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  meet_status?: InputMaybe<Enum_Call_Status_Type_Enum>;
  meetingSchedulars?: InputMaybe<Meeting_Call_Schedular_Arr_Rel_Insert_Input>;
  meeting_call_users?: InputMaybe<Calendar_Meeting_Call_User_Arr_Rel_Insert_Input>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  reacording_started_by?: InputMaybe<Scalars['uuid']>;
  recording_link?: InputMaybe<Scalars['String']>;
  recording_status?: InputMaybe<Enum_Call_Recording_Status_Enum>;
  resource_id?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Calendar_Meeting_Call_Max_Fields = {
  __typename?: 'calendar_meeting_call_max_fields';
  channel_id?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  reacording_started_by?: Maybe<Scalars['uuid']>;
  recording_link?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['String']>;
  sid?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  reacording_started_by?: InputMaybe<Order_By>;
  recording_link?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  sid?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calendar_Meeting_Call_Min_Fields = {
  __typename?: 'calendar_meeting_call_min_fields';
  channel_id?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  reacording_started_by?: Maybe<Scalars['uuid']>;
  recording_link?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['String']>;
  sid?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  reacording_started_by?: InputMaybe<Order_By>;
  recording_link?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  sid?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Mutation_Response = {
  __typename?: 'calendar_meeting_call_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendar_Meeting_Call>;
};

/** input type for inserting object relation for remote table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Obj_Rel_Insert_Input = {
  data: Calendar_Meeting_Call_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_Meeting_Call_On_Conflict>;
};

/** on_conflict condition type for table "calendar_meeting_call" */
export type Calendar_Meeting_Call_On_Conflict = {
  constraint: Calendar_Meeting_Call_Constraint;
  update_columns?: Array<Calendar_Meeting_Call_Update_Column>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** Ordering options when selecting data from "calendar_meeting_call". */
export type Calendar_Meeting_Call_Order_By = {
  calendar_event?: InputMaybe<Calendar_Event_Order_By>;
  calendar_meeting_call_requests_aggregate?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_room_aggregate?: InputMaybe<Chat_Room_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<Users_Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  enum_call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Order_By>;
  enum_call_status_type?: InputMaybe<Enum_Call_Status_Type_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meet_status?: InputMaybe<Order_By>;
  meetingSchedulars_aggregate?: InputMaybe<Meeting_Call_Schedular_Aggregate_Order_By>;
  meeting_call_users_aggregate?: InputMaybe<Calendar_Meeting_Call_User_Aggregate_Order_By>;
  metadata?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  reacording_started_by?: InputMaybe<Order_By>;
  recording_link?: InputMaybe<Order_By>;
  recording_status?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  sid?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendar_meeting_call */
export type Calendar_Meeting_Call_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Calendar_Meeting_Call_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Table to request users to an existing scheduled meeting */
export type Calendar_Meeting_Call_Request = {
  __typename?: 'calendar_meeting_call_request';
  /** An object relationship */
  calendar_meeting_call: Calendar_Meeting_Call;
  calendar_meeting_call_id: Scalars['uuid'];
  call_request_status?: Maybe<Enum_Call_Request_Status_Type_Enum>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  enum_call_request_status_type?: Maybe<Enum_Call_Request_Status_Type>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByUserId: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Aggregate = {
  __typename?: 'calendar_meeting_call_request_aggregate';
  aggregate?: Maybe<Calendar_Meeting_Call_Request_Aggregate_Fields>;
  nodes: Array<Calendar_Meeting_Call_Request>;
};

export type Calendar_Meeting_Call_Request_Aggregate_Bool_Exp = {
  count?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Bool_Exp_Count>;
};

export type Calendar_Meeting_Call_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Aggregate_Fields = {
  __typename?: 'calendar_meeting_call_request_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Meeting_Call_Request_Max_Fields>;
  min?: Maybe<Calendar_Meeting_Call_Request_Min_Fields>;
};

/** aggregate fields of "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendar_Meeting_Call_Request_Max_Order_By>;
  min?: InputMaybe<Calendar_Meeting_Call_Request_Min_Order_By>;
};

/** input type for inserting array relation for remote table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Arr_Rel_Insert_Input = {
  data: Array<Calendar_Meeting_Call_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_Meeting_Call_Request_On_Conflict>;
};

/** Boolean expression to filter rows from the table "calendar_meeting_call_request". All fields are combined with a logical 'AND'. */
export type Calendar_Meeting_Call_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Meeting_Call_Request_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Meeting_Call_Request_Bool_Exp>>;
  calendar_meeting_call?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  calendar_meeting_call_id?: InputMaybe<Uuid_Comparison_Exp>;
  call_request_status?: InputMaybe<Enum_Call_Request_Status_Type_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  enum_call_request_status_type?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByUserId?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendar_meeting_call_request" */
export enum Calendar_Meeting_Call_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalendarMeetingCallRequestPkey = 'calendar_meeting_call_request_pkey',
}

/** input type for inserting data into table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Insert_Input = {
  calendar_meeting_call?: InputMaybe<Calendar_Meeting_Call_Obj_Rel_Insert_Input>;
  calendar_meeting_call_id?: InputMaybe<Scalars['uuid']>;
  call_request_status?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  enum_call_request_status_type?: InputMaybe<Enum_Call_Request_Status_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Calendar_Meeting_Call_Request_Max_Fields = {
  __typename?: 'calendar_meeting_call_request_max_fields';
  calendar_meeting_call_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Max_Order_By = {
  calendar_meeting_call_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calendar_Meeting_Call_Request_Min_Fields = {
  __typename?: 'calendar_meeting_call_request_min_fields';
  calendar_meeting_call_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Min_Order_By = {
  calendar_meeting_call_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Mutation_Response = {
  __typename?: 'calendar_meeting_call_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendar_Meeting_Call_Request>;
};

/** on_conflict condition type for table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_On_Conflict = {
  constraint: Calendar_Meeting_Call_Request_Constraint;
  update_columns?: Array<Calendar_Meeting_Call_Request_Update_Column>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "calendar_meeting_call_request". */
export type Calendar_Meeting_Call_Request_Order_By = {
  calendar_meeting_call?: InputMaybe<Calendar_Meeting_Call_Order_By>;
  calendar_meeting_call_id?: InputMaybe<Order_By>;
  call_request_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enum_call_request_status_type?: InputMaybe<Enum_Call_Request_Status_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByUserId?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendar_meeting_call_request */
export type Calendar_Meeting_Call_Request_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "calendar_meeting_call_request" */
export enum Calendar_Meeting_Call_Request_Select_Column {
  /** column name */
  CalendarMeetingCallId = 'calendar_meeting_call_id',
  /** column name */
  CallRequestStatus = 'call_request_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Set_Input = {
  calendar_meeting_call_id?: InputMaybe<Scalars['uuid']>;
  call_request_status?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "calendar_meeting_call_request" */
export type Calendar_Meeting_Call_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Meeting_Call_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Meeting_Call_Request_Stream_Cursor_Value_Input = {
  calendar_meeting_call_id?: InputMaybe<Scalars['uuid']>;
  call_request_status?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "calendar_meeting_call_request" */
export enum Calendar_Meeting_Call_Request_Update_Column {
  /** column name */
  CalendarMeetingCallId = 'calendar_meeting_call_id',
  /** column name */
  CallRequestStatus = 'call_request_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Calendar_Meeting_Call_Request_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendar_Meeting_Call_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendar_Meeting_Call_Request_Bool_Exp;
};

/** select columns of table "calendar_meeting_call" */
export enum Calendar_Meeting_Call_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetStatus = 'meet_status',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  ReacordingStartedBy = 'reacording_started_by',
  /** column name */
  RecordingLink = 'recording_link',
  /** column name */
  RecordingStatus = 'recording_status',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  Sid = 'sid',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Set_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  chat_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  meet_status?: InputMaybe<Enum_Call_Status_Type_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  reacording_started_by?: InputMaybe<Scalars['uuid']>;
  recording_link?: InputMaybe<Scalars['String']>;
  recording_status?: InputMaybe<Enum_Call_Recording_Status_Enum>;
  resource_id?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "calendar_meeting_call" */
export type Calendar_Meeting_Call_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Meeting_Call_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Meeting_Call_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  chat_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  meet_status?: InputMaybe<Enum_Call_Status_Type_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  reacording_started_by?: InputMaybe<Scalars['uuid']>;
  recording_link?: InputMaybe<Scalars['String']>;
  recording_status?: InputMaybe<Enum_Call_Recording_Status_Enum>;
  resource_id?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "calendar_meeting_call" */
export enum Calendar_Meeting_Call_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetStatus = 'meet_status',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  ReacordingStartedBy = 'reacording_started_by',
  /** column name */
  RecordingLink = 'recording_link',
  /** column name */
  RecordingStatus = 'recording_status',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  Sid = 'sid',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Calendar_Meeting_Call_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Calendar_Meeting_Call_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Calendar_Meeting_Call_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Calendar_Meeting_Call_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Calendar_Meeting_Call_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Calendar_Meeting_Call_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendar_Meeting_Call_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendar_Meeting_Call_Bool_Exp;
};

/** Users who joined the calendar meeting call */
export type Calendar_Meeting_Call_User = {
  __typename?: 'calendar_meeting_call_user';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  call_joined_date?: Maybe<Scalars['date']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_call_user_status: Enum_Call_User_Status;
  id: Scalars['uuid'];
  /** An object relationship */
  meeting_call: Calendar_Meeting_Call;
  meeting_call_id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  status: Enum_Call_User_Status_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** Users who joined the calendar meeting call */
export type Calendar_Meeting_Call_UserMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Aggregate = {
  __typename?: 'calendar_meeting_call_user_aggregate';
  aggregate?: Maybe<Calendar_Meeting_Call_User_Aggregate_Fields>;
  nodes: Array<Calendar_Meeting_Call_User>;
};

export type Calendar_Meeting_Call_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Calendar_Meeting_Call_User_Aggregate_Bool_Exp_Count>;
};

export type Calendar_Meeting_Call_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Aggregate_Fields = {
  __typename?: 'calendar_meeting_call_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Meeting_Call_User_Max_Fields>;
  min?: Maybe<Calendar_Meeting_Call_User_Min_Fields>;
};

/** aggregate fields of "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendar_Meeting_Call_User_Max_Order_By>;
  min?: InputMaybe<Calendar_Meeting_Call_User_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Calendar_Meeting_Call_User_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Arr_Rel_Insert_Input = {
  data: Array<Calendar_Meeting_Call_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_Meeting_Call_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "calendar_meeting_call_user". All fields are combined with a logical 'AND'. */
export type Calendar_Meeting_Call_User_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Meeting_Call_User_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Meeting_Call_User_Bool_Exp>>;
  calendar_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  call_joined_date?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_call_user_status?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meeting_call?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  meeting_call_id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<Enum_Call_User_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendar_meeting_call_user" */
export enum Calendar_Meeting_Call_User_Constraint {
  /** unique or primary key constraint on columns "user_id", "meeting_call_id" */
  CalendarMeetingCallUserMeetingCallIdUserIdKey = 'calendar_meeting_call_user_meeting_call_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  CalendarMeetingCallUserPkey = 'calendar_meeting_call_user_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Calendar_Meeting_Call_User_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Calendar_Meeting_Call_User_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Calendar_Meeting_Call_User_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Insert_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  call_joined_date?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_call_user_status?: InputMaybe<Enum_Call_User_Status_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_call?: InputMaybe<Calendar_Meeting_Call_Obj_Rel_Insert_Input>;
  meeting_call_id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Enum_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Calendar_Meeting_Call_User_Max_Fields = {
  __typename?: 'calendar_meeting_call_user_max_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  call_joined_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_call_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Max_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  call_joined_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_call_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calendar_Meeting_Call_User_Min_Fields = {
  __typename?: 'calendar_meeting_call_user_min_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  call_joined_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_call_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Min_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  call_joined_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_call_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Mutation_Response = {
  __typename?: 'calendar_meeting_call_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendar_Meeting_Call_User>;
};

/** on_conflict condition type for table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_On_Conflict = {
  constraint: Calendar_Meeting_Call_User_Constraint;
  update_columns?: Array<Calendar_Meeting_Call_User_Update_Column>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

/** Ordering options when selecting data from "calendar_meeting_call_user". */
export type Calendar_Meeting_Call_User_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  call_joined_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_call_user_status?: InputMaybe<Enum_Call_User_Status_Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_call?: InputMaybe<Calendar_Meeting_Call_Order_By>;
  meeting_call_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendar_meeting_call_user */
export type Calendar_Meeting_Call_User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Calendar_Meeting_Call_User_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "calendar_meeting_call_user" */
export enum Calendar_Meeting_Call_User_Select_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  CallJoinedDate = 'call_joined_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingCallId = 'meeting_call_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Set_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  call_joined_date?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_call_id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Enum_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "calendar_meeting_call_user" */
export type Calendar_Meeting_Call_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Meeting_Call_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Meeting_Call_User_Stream_Cursor_Value_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  call_joined_date?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_call_id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Enum_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "calendar_meeting_call_user" */
export enum Calendar_Meeting_Call_User_Update_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  CallJoinedDate = 'call_joined_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingCallId = 'meeting_call_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Calendar_Meeting_Call_User_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Calendar_Meeting_Call_User_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Calendar_Meeting_Call_User_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Calendar_Meeting_Call_User_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Calendar_Meeting_Call_User_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Calendar_Meeting_Call_User_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendar_Meeting_Call_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendar_Meeting_Call_User_Bool_Exp;
};

/** columns and relationships of "calendar_meeting_view" */
export type Calendar_Meeting_View = {
  __typename?: 'calendar_meeting_view';
  /** An object relationship */
  CreatedByUser?: Maybe<Users>;
  additional_fields?: Maybe<Scalars['jsonb']>;
  all_day?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  calendar?: Maybe<Calendar>;
  /** fetch data from the table: "calendar_attendee" */
  calendar_attendee: Array<Calendar_Attendee>;
  /** fetch aggregated fields from the table: "calendar_attendee" */
  calendar_attendee_aggregate: Calendar_Attendee_Aggregate;
  /** An object relationship */
  calendar_event?: Maybe<Calendar_Event>;
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  uid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "calendar_meeting_view" */
export type Calendar_Meeting_ViewAdditional_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "calendar_meeting_view" */
export type Calendar_Meeting_ViewCalendar_AttendeeArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "calendar_meeting_view" */
export type Calendar_Meeting_ViewCalendar_Attendee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** aggregated selection of "calendar_meeting_view" */
export type Calendar_Meeting_View_Aggregate = {
  __typename?: 'calendar_meeting_view_aggregate';
  aggregate?: Maybe<Calendar_Meeting_View_Aggregate_Fields>;
  nodes: Array<Calendar_Meeting_View>;
};

/** aggregate fields of "calendar_meeting_view" */
export type Calendar_Meeting_View_Aggregate_Fields = {
  __typename?: 'calendar_meeting_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Calendar_Meeting_View_Max_Fields>;
  min?: Maybe<Calendar_Meeting_View_Min_Fields>;
};

/** aggregate fields of "calendar_meeting_view" */
export type Calendar_Meeting_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Meeting_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "calendar_meeting_view". All fields are combined with a logical 'AND'. */
export type Calendar_Meeting_View_Bool_Exp = {
  CreatedByUser?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<Calendar_Meeting_View_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Meeting_View_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Meeting_View_Bool_Exp>>;
  additional_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  all_day?: InputMaybe<Boolean_Comparison_Exp>;
  calendar?: InputMaybe<Calendar_Bool_Exp>;
  calendar_attendee?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  calendar_attendee_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp>;
  calendar_event?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  end_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  rrule?: InputMaybe<String_Comparison_Exp>;
  start_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  uid?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Calendar_Meeting_View_Max_Fields = {
  __typename?: 'calendar_meeting_view_max_fields';
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  uid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Calendar_Meeting_View_Min_Fields = {
  __typename?: 'calendar_meeting_view_min_fields';
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  uid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "calendar_meeting_view". */
export type Calendar_Meeting_View_Order_By = {
  CreatedByUser?: InputMaybe<Users_Order_By>;
  additional_fields?: InputMaybe<Order_By>;
  all_day?: InputMaybe<Order_By>;
  calendar?: InputMaybe<Calendar_Order_By>;
  calendar_attendee_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Order_By>;
  calendar_event?: InputMaybe<Calendar_Event_Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "calendar_meeting_view" */
export enum Calendar_Meeting_View_Select_Column {
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AllDay = 'all_day',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  EventName = 'event_name',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Rrule = 'rrule',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "calendar_meeting_view" */
export type Calendar_Meeting_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Meeting_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Meeting_View_Stream_Cursor_Value_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  all_day?: InputMaybe<Scalars['Boolean']>;
  calendar_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  event_name?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  rrule?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  uid?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Calendar_Min_Fields = {
  __typename?: 'calendar_min_fields';
  calendar_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  default_timezone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "calendar" */
export type Calendar_Min_Order_By = {
  calendar_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_timezone?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendar" */
export type Calendar_Mutation_Response = {
  __typename?: 'calendar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendar>;
};

/** input type for inserting object relation for remote table "calendar" */
export type Calendar_Obj_Rel_Insert_Input = {
  data: Calendar_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendar_On_Conflict>;
};

/** on_conflict condition type for table "calendar" */
export type Calendar_On_Conflict = {
  constraint: Calendar_Constraint;
  update_columns?: Array<Calendar_Update_Column>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

/** Ordering options when selecting data from "calendar". */
export type Calendar_Order_By = {
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Order_By>;
  calendar_events_aggregate?: InputMaybe<Calendar_Event_Aggregate_Order_By>;
  calendar_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_timezone?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: calendar */
export type Calendar_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "calendar" */
export enum Calendar_Select_Column {
  /** column name */
  CalendarName = 'calendar_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DefaultTimezone = 'default_timezone',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "calendar" */
export type Calendar_Set_Input = {
  calendar_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  default_timezone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "calendar" */
export type Calendar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Stream_Cursor_Value_Input = {
  calendar_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  default_timezone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "calendar" */
export enum Calendar_Update_Column {
  /** column name */
  CalendarName = 'calendar_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DefaultTimezone = 'default_timezone',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Calendar_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendar_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendar_Bool_Exp;
};

/** columns and relationships of "call" */
export type Call = {
  __typename?: 'call';
  access_policy?: Maybe<Enum_Call_Access_Policy_Enum>;
  call_broadcasting_type?: Maybe<Enum_Call_Broadcasting_Type_Enum>;
  call_org_id: Scalars['uuid'];
  call_recording_link?: Maybe<Scalars['String']>;
  call_recording_status?: Maybe<Enum_Call_Recording_Status_Enum>;
  /** An array relationship */
  call_requests: Array<Call_Request>;
  /** An aggregate relationship */
  call_requests_aggregate: Call_Request_Aggregate;
  call_resource_id?: Maybe<Scalars['String']>;
  call_sid?: Maybe<Scalars['String']>;
  call_status?: Maybe<Enum_Call_Status_Type_Enum>;
  call_type: Enum_Call_Type_Enum;
  call_uid?: Maybe<Scalars['String']>;
  /** An array relationship */
  call_users: Array<Call_User>;
  /** An aggregate relationship */
  call_users_aggregate: Call_User_Aggregate;
  channel_id: Scalars['String'];
  chat_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_by_user: Users;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_call_access_policy?: Maybe<Enum_Call_Access_Policy>;
  /** An object relationship */
  enum_call_broadcasting_type?: Maybe<Enum_Call_Broadcasting_Type>;
  /** An object relationship */
  enum_call_call_type: Enum_Call_Type;
  /** An object relationship */
  enum_call_media_type: Enum_Call_Media_Type;
  /** An object relationship */
  enum_call_recording_status?: Maybe<Enum_Call_Recording_Status>;
  /** An object relationship */
  enum_call_status_type?: Maybe<Enum_Call_Status_Type>;
  id: Scalars['uuid'];
  media_type: Enum_Call_Media_Type_Enum;
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  organization: Organization;
  recording_started_by?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "total_talk_time" */
  total_talk_time?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "call" */
export type CallCall_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** columns and relationships of "call" */
export type CallCall_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** columns and relationships of "call" */
export type CallCall_UsersArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

/** columns and relationships of "call" */
export type CallCall_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

/** columns and relationships of "call" */
export type CallMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "call" */
export type Call_Aggregate = {
  __typename?: 'call_aggregate';
  aggregate?: Maybe<Call_Aggregate_Fields>;
  nodes: Array<Call>;
};

export type Call_Aggregate_Bool_Exp = {
  count?: InputMaybe<Call_Aggregate_Bool_Exp_Count>;
};

export type Call_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Call_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Call_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "call" */
export type Call_Aggregate_Fields = {
  __typename?: 'call_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Call_Max_Fields>;
  min?: Maybe<Call_Min_Fields>;
};

/** aggregate fields of "call" */
export type Call_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Call_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "call" */
export type Call_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Call_Max_Order_By>;
  min?: InputMaybe<Call_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Call_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "call" */
export type Call_Arr_Rel_Insert_Input = {
  data: Array<Call_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Call_On_Conflict>;
};

/** Boolean expression to filter rows from the table "call". All fields are combined with a logical 'AND'. */
export type Call_Bool_Exp = {
  _and?: InputMaybe<Array<Call_Bool_Exp>>;
  _not?: InputMaybe<Call_Bool_Exp>;
  _or?: InputMaybe<Array<Call_Bool_Exp>>;
  access_policy?: InputMaybe<Enum_Call_Access_Policy_Enum_Comparison_Exp>;
  call_broadcasting_type?: InputMaybe<Enum_Call_Broadcasting_Type_Enum_Comparison_Exp>;
  call_org_id?: InputMaybe<Uuid_Comparison_Exp>;
  call_recording_link?: InputMaybe<String_Comparison_Exp>;
  call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Enum_Comparison_Exp>;
  call_requests?: InputMaybe<Call_Request_Bool_Exp>;
  call_requests_aggregate?: InputMaybe<Call_Request_Aggregate_Bool_Exp>;
  call_resource_id?: InputMaybe<String_Comparison_Exp>;
  call_sid?: InputMaybe<String_Comparison_Exp>;
  call_status?: InputMaybe<Enum_Call_Status_Type_Enum_Comparison_Exp>;
  call_type?: InputMaybe<Enum_Call_Type_Enum_Comparison_Exp>;
  call_uid?: InputMaybe<String_Comparison_Exp>;
  call_users?: InputMaybe<Call_User_Bool_Exp>;
  call_users_aggregate?: InputMaybe<Call_User_Aggregate_Bool_Exp>;
  channel_id?: InputMaybe<String_Comparison_Exp>;
  chat_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user?: InputMaybe<Users_Bool_Exp>;
  end_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_call_access_policy?: InputMaybe<Enum_Call_Access_Policy_Bool_Exp>;
  enum_call_broadcasting_type?: InputMaybe<Enum_Call_Broadcasting_Type_Bool_Exp>;
  enum_call_call_type?: InputMaybe<Enum_Call_Type_Bool_Exp>;
  enum_call_media_type?: InputMaybe<Enum_Call_Media_Type_Bool_Exp>;
  enum_call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
  enum_call_status_type?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  media_type?: InputMaybe<Enum_Call_Media_Type_Enum_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  recording_started_by?: InputMaybe<Uuid_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  start_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  total_talk_time?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "call" */
export enum Call_Constraint {
  /** unique or primary key constraint on columns "id" */
  CallPkey = 'call_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Call_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Call_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Call_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "call" */
export type Call_Insert_Input = {
  access_policy?: InputMaybe<Enum_Call_Access_Policy_Enum>;
  call_broadcasting_type?: InputMaybe<Enum_Call_Broadcasting_Type_Enum>;
  call_org_id?: InputMaybe<Scalars['uuid']>;
  call_recording_link?: InputMaybe<Scalars['String']>;
  call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Enum>;
  call_requests?: InputMaybe<Call_Request_Arr_Rel_Insert_Input>;
  call_resource_id?: InputMaybe<Scalars['String']>;
  call_sid?: InputMaybe<Scalars['String']>;
  call_status?: InputMaybe<Enum_Call_Status_Type_Enum>;
  call_type?: InputMaybe<Enum_Call_Type_Enum>;
  call_uid?: InputMaybe<Scalars['String']>;
  call_users?: InputMaybe<Call_User_Arr_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars['String']>;
  chat_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  enum_call_access_policy?: InputMaybe<Enum_Call_Access_Policy_Obj_Rel_Insert_Input>;
  enum_call_broadcasting_type?: InputMaybe<Enum_Call_Broadcasting_Type_Obj_Rel_Insert_Input>;
  enum_call_call_type?: InputMaybe<Enum_Call_Type_Obj_Rel_Insert_Input>;
  enum_call_media_type?: InputMaybe<Enum_Call_Media_Type_Obj_Rel_Insert_Input>;
  enum_call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Obj_Rel_Insert_Input>;
  enum_call_status_type?: InputMaybe<Enum_Call_Status_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  media_type?: InputMaybe<Enum_Call_Media_Type_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  recording_started_by?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Call_Max_Fields = {
  __typename?: 'call_max_fields';
  call_org_id?: Maybe<Scalars['uuid']>;
  call_recording_link?: Maybe<Scalars['String']>;
  call_resource_id?: Maybe<Scalars['String']>;
  call_sid?: Maybe<Scalars['String']>;
  call_uid?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  recording_started_by?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "call" */
export type Call_Max_Order_By = {
  call_org_id?: InputMaybe<Order_By>;
  call_recording_link?: InputMaybe<Order_By>;
  call_resource_id?: InputMaybe<Order_By>;
  call_sid?: InputMaybe<Order_By>;
  call_uid?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recording_started_by?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Call_Min_Fields = {
  __typename?: 'call_min_fields';
  call_org_id?: Maybe<Scalars['uuid']>;
  call_recording_link?: Maybe<Scalars['String']>;
  call_resource_id?: Maybe<Scalars['String']>;
  call_sid?: Maybe<Scalars['String']>;
  call_uid?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  recording_started_by?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "call" */
export type Call_Min_Order_By = {
  call_org_id?: InputMaybe<Order_By>;
  call_recording_link?: InputMaybe<Order_By>;
  call_resource_id?: InputMaybe<Order_By>;
  call_sid?: InputMaybe<Order_By>;
  call_uid?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recording_started_by?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "call" */
export type Call_Mutation_Response = {
  __typename?: 'call_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Call>;
};

/** input type for inserting object relation for remote table "call" */
export type Call_Obj_Rel_Insert_Input = {
  data: Call_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Call_On_Conflict>;
};

/** on_conflict condition type for table "call" */
export type Call_On_Conflict = {
  constraint: Call_Constraint;
  update_columns?: Array<Call_Update_Column>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** Ordering options when selecting data from "call". */
export type Call_Order_By = {
  access_policy?: InputMaybe<Order_By>;
  call_broadcasting_type?: InputMaybe<Order_By>;
  call_org_id?: InputMaybe<Order_By>;
  call_recording_link?: InputMaybe<Order_By>;
  call_recording_status?: InputMaybe<Order_By>;
  call_requests_aggregate?: InputMaybe<Call_Request_Aggregate_Order_By>;
  call_resource_id?: InputMaybe<Order_By>;
  call_sid?: InputMaybe<Order_By>;
  call_status?: InputMaybe<Order_By>;
  call_type?: InputMaybe<Order_By>;
  call_uid?: InputMaybe<Order_By>;
  call_users_aggregate?: InputMaybe<Call_User_Aggregate_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<Users_Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  enum_call_access_policy?: InputMaybe<Enum_Call_Access_Policy_Order_By>;
  enum_call_broadcasting_type?: InputMaybe<Enum_Call_Broadcasting_Type_Order_By>;
  enum_call_call_type?: InputMaybe<Enum_Call_Type_Order_By>;
  enum_call_media_type?: InputMaybe<Enum_Call_Media_Type_Order_By>;
  enum_call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Order_By>;
  enum_call_status_type?: InputMaybe<Enum_Call_Status_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  media_type?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  recording_started_by?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  total_talk_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: call */
export type Call_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Call_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** The request made for call is mapped */
export type Call_Request = {
  __typename?: 'call_request';
  /** An object relationship */
  call: Call;
  call_id: Scalars['uuid'];
  call_request_status?: Maybe<Enum_Call_Request_Status_Type_Enum>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_by_user: Users;
  /** An object relationship */
  created_for_user: Users;
  /** An object relationship */
  enum_call_request_status_type?: Maybe<Enum_Call_Request_Status_Type>;
  /** An object relationship */
  enum_call_request_type: Enum_Call_Request_Type;
  id: Scalars['uuid'];
  request_type: Enum_Call_Request_Type_Enum;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "call_request" */
export type Call_Request_Aggregate = {
  __typename?: 'call_request_aggregate';
  aggregate?: Maybe<Call_Request_Aggregate_Fields>;
  nodes: Array<Call_Request>;
};

export type Call_Request_Aggregate_Bool_Exp = {
  count?: InputMaybe<Call_Request_Aggregate_Bool_Exp_Count>;
};

export type Call_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Call_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Call_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "call_request" */
export type Call_Request_Aggregate_Fields = {
  __typename?: 'call_request_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Call_Request_Max_Fields>;
  min?: Maybe<Call_Request_Min_Fields>;
};

/** aggregate fields of "call_request" */
export type Call_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Call_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "call_request" */
export type Call_Request_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Call_Request_Max_Order_By>;
  min?: InputMaybe<Call_Request_Min_Order_By>;
};

/** input type for inserting array relation for remote table "call_request" */
export type Call_Request_Arr_Rel_Insert_Input = {
  data: Array<Call_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Call_Request_On_Conflict>;
};

/** Boolean expression to filter rows from the table "call_request". All fields are combined with a logical 'AND'. */
export type Call_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Call_Request_Bool_Exp>>;
  _not?: InputMaybe<Call_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Call_Request_Bool_Exp>>;
  call?: InputMaybe<Call_Bool_Exp>;
  call_id?: InputMaybe<Uuid_Comparison_Exp>;
  call_request_status?: InputMaybe<Enum_Call_Request_Status_Type_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user?: InputMaybe<Users_Bool_Exp>;
  created_for_user?: InputMaybe<Users_Bool_Exp>;
  enum_call_request_status_type?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
  enum_call_request_type?: InputMaybe<Enum_Call_Request_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  request_type?: InputMaybe<Enum_Call_Request_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "call_request" */
export enum Call_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  CallRequestPkey = 'call_request_pkey',
}

/** input type for inserting data into table "call_request" */
export type Call_Request_Insert_Input = {
  call?: InputMaybe<Call_Obj_Rel_Insert_Input>;
  call_id?: InputMaybe<Scalars['uuid']>;
  call_request_status?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  created_for_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  enum_call_request_status_type?: InputMaybe<Enum_Call_Request_Status_Type_Obj_Rel_Insert_Input>;
  enum_call_request_type?: InputMaybe<Enum_Call_Request_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Enum_Call_Request_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Call_Request_Max_Fields = {
  __typename?: 'call_request_max_fields';
  call_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "call_request" */
export type Call_Request_Max_Order_By = {
  call_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Call_Request_Min_Fields = {
  __typename?: 'call_request_min_fields';
  call_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "call_request" */
export type Call_Request_Min_Order_By = {
  call_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "call_request" */
export type Call_Request_Mutation_Response = {
  __typename?: 'call_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Call_Request>;
};

/** on_conflict condition type for table "call_request" */
export type Call_Request_On_Conflict = {
  constraint: Call_Request_Constraint;
  update_columns?: Array<Call_Request_Update_Column>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "call_request". */
export type Call_Request_Order_By = {
  call?: InputMaybe<Call_Order_By>;
  call_id?: InputMaybe<Order_By>;
  call_request_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<Users_Order_By>;
  created_for_user?: InputMaybe<Users_Order_By>;
  enum_call_request_status_type?: InputMaybe<Enum_Call_Request_Status_Type_Order_By>;
  enum_call_request_type?: InputMaybe<Enum_Call_Request_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  request_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: call_request */
export type Call_Request_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "call_request" */
export enum Call_Request_Select_Column {
  /** column name */
  CallId = 'call_id',
  /** column name */
  CallRequestStatus = 'call_request_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  RequestType = 'request_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "call_request" */
export type Call_Request_Set_Input = {
  call_id?: InputMaybe<Scalars['uuid']>;
  call_request_status?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Enum_Call_Request_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "call_request" */
export type Call_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Call_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Call_Request_Stream_Cursor_Value_Input = {
  call_id?: InputMaybe<Scalars['uuid']>;
  call_request_status?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Enum_Call_Request_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "call_request" */
export enum Call_Request_Update_Column {
  /** column name */
  CallId = 'call_id',
  /** column name */
  CallRequestStatus = 'call_request_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  RequestType = 'request_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Call_Request_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Call_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Call_Request_Bool_Exp;
};

/** select columns of table "call" */
export enum Call_Select_Column {
  /** column name */
  AccessPolicy = 'access_policy',
  /** column name */
  CallBroadcastingType = 'call_broadcasting_type',
  /** column name */
  CallOrgId = 'call_org_id',
  /** column name */
  CallRecordingLink = 'call_recording_link',
  /** column name */
  CallRecordingStatus = 'call_recording_status',
  /** column name */
  CallResourceId = 'call_resource_id',
  /** column name */
  CallSid = 'call_sid',
  /** column name */
  CallStatus = 'call_status',
  /** column name */
  CallType = 'call_type',
  /** column name */
  CallUid = 'call_uid',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  Id = 'id',
  /** column name */
  MediaType = 'media_type',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RecordingStartedBy = 'recording_started_by',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "call" */
export type Call_Set_Input = {
  access_policy?: InputMaybe<Enum_Call_Access_Policy_Enum>;
  call_broadcasting_type?: InputMaybe<Enum_Call_Broadcasting_Type_Enum>;
  call_org_id?: InputMaybe<Scalars['uuid']>;
  call_recording_link?: InputMaybe<Scalars['String']>;
  call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Enum>;
  call_resource_id?: InputMaybe<Scalars['String']>;
  call_sid?: InputMaybe<Scalars['String']>;
  call_status?: InputMaybe<Enum_Call_Status_Type_Enum>;
  call_type?: InputMaybe<Enum_Call_Type_Enum>;
  call_uid?: InputMaybe<Scalars['String']>;
  channel_id?: InputMaybe<Scalars['String']>;
  chat_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  media_type?: InputMaybe<Enum_Call_Media_Type_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  recording_started_by?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "call" */
export type Call_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Call_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Call_Stream_Cursor_Value_Input = {
  access_policy?: InputMaybe<Enum_Call_Access_Policy_Enum>;
  call_broadcasting_type?: InputMaybe<Enum_Call_Broadcasting_Type_Enum>;
  call_org_id?: InputMaybe<Scalars['uuid']>;
  call_recording_link?: InputMaybe<Scalars['String']>;
  call_recording_status?: InputMaybe<Enum_Call_Recording_Status_Enum>;
  call_resource_id?: InputMaybe<Scalars['String']>;
  call_sid?: InputMaybe<Scalars['String']>;
  call_status?: InputMaybe<Enum_Call_Status_Type_Enum>;
  call_type?: InputMaybe<Enum_Call_Type_Enum>;
  call_uid?: InputMaybe<Scalars['String']>;
  channel_id?: InputMaybe<Scalars['String']>;
  chat_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  media_type?: InputMaybe<Enum_Call_Media_Type_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  recording_started_by?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "call" */
export enum Call_Update_Column {
  /** column name */
  AccessPolicy = 'access_policy',
  /** column name */
  CallBroadcastingType = 'call_broadcasting_type',
  /** column name */
  CallOrgId = 'call_org_id',
  /** column name */
  CallRecordingLink = 'call_recording_link',
  /** column name */
  CallRecordingStatus = 'call_recording_status',
  /** column name */
  CallResourceId = 'call_resource_id',
  /** column name */
  CallSid = 'call_sid',
  /** column name */
  CallStatus = 'call_status',
  /** column name */
  CallType = 'call_type',
  /** column name */
  CallUid = 'call_uid',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  Id = 'id',
  /** column name */
  MediaType = 'media_type',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RecordingStartedBy = 'recording_started_by',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Call_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Call_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Call_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Call_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Call_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Call_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Call_Set_Input>;
  /** filter the rows which have to be updated */
  where: Call_Bool_Exp;
};

/** Users which participant on call */
export type Call_User = {
  __typename?: 'call_user';
  /** An object relationship */
  call: Call;
  call_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_call_user_status: Enum_Call_User_Status;
  id: Scalars['uuid'];
  status: Enum_Call_User_Status_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "call_user" */
export type Call_User_Aggregate = {
  __typename?: 'call_user_aggregate';
  aggregate?: Maybe<Call_User_Aggregate_Fields>;
  nodes: Array<Call_User>;
};

export type Call_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Call_User_Aggregate_Bool_Exp_Count>;
};

export type Call_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Call_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Call_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "call_user" */
export type Call_User_Aggregate_Fields = {
  __typename?: 'call_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Call_User_Max_Fields>;
  min?: Maybe<Call_User_Min_Fields>;
};

/** aggregate fields of "call_user" */
export type Call_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Call_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "call_user" */
export type Call_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Call_User_Max_Order_By>;
  min?: InputMaybe<Call_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "call_user" */
export type Call_User_Arr_Rel_Insert_Input = {
  data: Array<Call_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Call_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "call_user". All fields are combined with a logical 'AND'. */
export type Call_User_Bool_Exp = {
  _and?: InputMaybe<Array<Call_User_Bool_Exp>>;
  _not?: InputMaybe<Call_User_Bool_Exp>;
  _or?: InputMaybe<Array<Call_User_Bool_Exp>>;
  call?: InputMaybe<Call_Bool_Exp>;
  call_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_call_user_status?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Enum_Call_User_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "call_user" */
export enum Call_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  CallUserPkey = 'call_user_pkey',
}

/** input type for inserting data into table "call_user" */
export type Call_User_Insert_Input = {
  call?: InputMaybe<Call_Obj_Rel_Insert_Input>;
  call_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_call_user_status?: InputMaybe<Enum_Call_User_Status_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Call_User_Max_Fields = {
  __typename?: 'call_user_max_fields';
  call_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "call_user" */
export type Call_User_Max_Order_By = {
  call_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Call_User_Min_Fields = {
  __typename?: 'call_user_min_fields';
  call_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "call_user" */
export type Call_User_Min_Order_By = {
  call_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "call_user" */
export type Call_User_Mutation_Response = {
  __typename?: 'call_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Call_User>;
};

/** on_conflict condition type for table "call_user" */
export type Call_User_On_Conflict = {
  constraint: Call_User_Constraint;
  update_columns?: Array<Call_User_Update_Column>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

/** Ordering options when selecting data from "call_user". */
export type Call_User_Order_By = {
  call?: InputMaybe<Call_Order_By>;
  call_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_call_user_status?: InputMaybe<Enum_Call_User_Status_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: call_user */
export type Call_User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "call_user" */
export enum Call_User_Select_Column {
  /** column name */
  CallId = 'call_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "call_user" */
export type Call_User_Set_Input = {
  call_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "call_user" */
export type Call_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Call_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Call_User_Stream_Cursor_Value_Input = {
  call_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "call_user" */
export enum Call_User_Update_Column {
  /** column name */
  CallId = 'call_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Call_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Call_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Call_User_Bool_Exp;
};

export type CapacityData = {
  __typename?: 'capacityData';
  estimatedHours?: Maybe<Scalars['Int']>;
  meetingHours?: Maybe<Scalars['Int']>;
  task?: Maybe<TaskInfo>;
  userId?: Maybe<Scalars['String']>;
  work_day?: Maybe<Scalars['String']>;
};

/** columns and relationships of "career_development_detail" */
export type Career_Development_Detail = {
  __typename?: 'career_development_detail';
  /** An array relationship */
  assign_career_development_plans: Array<Assign_Career_Development_Plan>;
  /** An aggregate relationship */
  assign_career_development_plans_aggregate: Assign_Career_Development_Plan_Aggregate;
  /** An array relationship */
  career_development_role_details: Array<Career_Development_Role_Details>;
  /** An aggregate relationship */
  career_development_role_details_aggregate: Career_Development_Role_Details_Aggregate;
  /** An array relationship */
  career_external_certifications: Array<Career_External_Certification>;
  /** An aggregate relationship */
  career_external_certifications_aggregate: Career_External_Certification_Aggregate;
  cover_image_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An array relationship */
  stage_career_details: Array<Stage_Career_Detail>;
  /** An aggregate relationship */
  stage_career_details_aggregate: Stage_Career_Detail_Aggregate;
  /** An object relationship */
  storage_file: Storage_Files;
  title: Scalars['String'];
  /** An object relationship */
  user: Users;
  /** An array relationship */
  user_career_development_records: Array<User_Career_Development_Record>;
  /** An aggregate relationship */
  user_career_development_records_aggregate: User_Career_Development_Record_Aggregate;
};

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailAssign_Career_Development_PlansArgs = {
  distinct_on?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assign_Career_Development_Plan_Order_By>>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailAssign_Career_Development_Plans_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Assign_Career_Development_Plan_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Assign_Career_Development_Plan_Order_By>>;
    where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
  };

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailCareer_Development_Role_DetailsArgs = {
  distinct_on?: InputMaybe<
    Array<Career_Development_Role_Details_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Role_Details_Order_By>>;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailCareer_Development_Role_Details_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Career_Development_Role_Details_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Career_Development_Role_Details_Order_By>>;
    where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
  };

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailCareer_External_CertificationsArgs = {
  distinct_on?: InputMaybe<Array<Career_External_Certification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_External_Certification_Order_By>>;
  where?: InputMaybe<Career_External_Certification_Bool_Exp>;
};

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailCareer_External_Certifications_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Career_External_Certification_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Career_External_Certification_Order_By>>;
    where?: InputMaybe<Career_External_Certification_Bool_Exp>;
  };

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailStage_Career_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Stage_Career_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stage_Career_Detail_Order_By>>;
  where?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
};

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailStage_Career_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stage_Career_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stage_Career_Detail_Order_By>>;
  where?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
};

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailUser_Career_Development_RecordsArgs = {
  distinct_on?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_Development_Record_Order_By>>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

/** columns and relationships of "career_development_detail" */
export type Career_Development_DetailUser_Career_Development_Records_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<User_Career_Development_Record_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<User_Career_Development_Record_Order_By>>;
    where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  };

/** aggregated selection of "career_development_detail" */
export type Career_Development_Detail_Aggregate = {
  __typename?: 'career_development_detail_aggregate';
  aggregate?: Maybe<Career_Development_Detail_Aggregate_Fields>;
  nodes: Array<Career_Development_Detail>;
};

export type Career_Development_Detail_Aggregate_Bool_Exp = {
  count?: InputMaybe<Career_Development_Detail_Aggregate_Bool_Exp_Count>;
};

export type Career_Development_Detail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "career_development_detail" */
export type Career_Development_Detail_Aggregate_Fields = {
  __typename?: 'career_development_detail_aggregate_fields';
  avg?: Maybe<Career_Development_Detail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Career_Development_Detail_Max_Fields>;
  min?: Maybe<Career_Development_Detail_Min_Fields>;
  stddev?: Maybe<Career_Development_Detail_Stddev_Fields>;
  stddev_pop?: Maybe<Career_Development_Detail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Career_Development_Detail_Stddev_Samp_Fields>;
  sum?: Maybe<Career_Development_Detail_Sum_Fields>;
  var_pop?: Maybe<Career_Development_Detail_Var_Pop_Fields>;
  var_samp?: Maybe<Career_Development_Detail_Var_Samp_Fields>;
  variance?: Maybe<Career_Development_Detail_Variance_Fields>;
};

/** aggregate fields of "career_development_detail" */
export type Career_Development_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "career_development_detail" */
export type Career_Development_Detail_Aggregate_Order_By = {
  avg?: InputMaybe<Career_Development_Detail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Career_Development_Detail_Max_Order_By>;
  min?: InputMaybe<Career_Development_Detail_Min_Order_By>;
  stddev?: InputMaybe<Career_Development_Detail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Career_Development_Detail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Career_Development_Detail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Career_Development_Detail_Sum_Order_By>;
  var_pop?: InputMaybe<Career_Development_Detail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Career_Development_Detail_Var_Samp_Order_By>;
  variance?: InputMaybe<Career_Development_Detail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "career_development_detail" */
export type Career_Development_Detail_Arr_Rel_Insert_Input = {
  data: Array<Career_Development_Detail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Career_Development_Detail_On_Conflict>;
};

/** aggregate avg on columns */
export type Career_Development_Detail_Avg_Fields = {
  __typename?: 'career_development_detail_avg_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "career_development_detail" */
export type Career_Development_Detail_Avg_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "career_development_detail". All fields are combined with a logical 'AND'. */
export type Career_Development_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Career_Development_Detail_Bool_Exp>>;
  _not?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Career_Development_Detail_Bool_Exp>>;
  assign_career_development_plans?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
  assign_career_development_plans_aggregate?: InputMaybe<Assign_Career_Development_Plan_Aggregate_Bool_Exp>;
  career_development_role_details?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
  career_development_role_details_aggregate?: InputMaybe<Career_Development_Role_Details_Aggregate_Bool_Exp>;
  career_external_certifications?: InputMaybe<Career_External_Certification_Bool_Exp>;
  career_external_certifications_aggregate?: InputMaybe<Career_External_Certification_Aggregate_Bool_Exp>;
  cover_image_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  stage_career_details?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
  stage_career_details_aggregate?: InputMaybe<Stage_Career_Detail_Aggregate_Bool_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_career_development_records?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  user_career_development_records_aggregate?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "career_development_detail" */
export enum Career_Development_Detail_Constraint {
  /** unique or primary key constraint on columns "id" */
  CareerDevelopmentDetailPkey = 'career_development_detail_pkey',
}

/** input type for incrementing numeric columns in table "career_development_detail" */
export type Career_Development_Detail_Inc_Input = {
  duration?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "career_development_detail" */
export type Career_Development_Detail_Insert_Input = {
  assign_career_development_plans?: InputMaybe<Assign_Career_Development_Plan_Arr_Rel_Insert_Input>;
  career_development_role_details?: InputMaybe<Career_Development_Role_Details_Arr_Rel_Insert_Input>;
  career_external_certifications?: InputMaybe<Career_External_Certification_Arr_Rel_Insert_Input>;
  cover_image_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  stage_career_details?: InputMaybe<Stage_Career_Detail_Arr_Rel_Insert_Input>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_career_development_records?: InputMaybe<User_Career_Development_Record_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Career_Development_Detail_Max_Fields = {
  __typename?: 'career_development_detail_max_fields';
  cover_image_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "career_development_detail" */
export type Career_Development_Detail_Max_Order_By = {
  cover_image_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Career_Development_Detail_Min_Fields = {
  __typename?: 'career_development_detail_min_fields';
  cover_image_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "career_development_detail" */
export type Career_Development_Detail_Min_Order_By = {
  cover_image_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "career_development_detail" */
export type Career_Development_Detail_Mutation_Response = {
  __typename?: 'career_development_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Career_Development_Detail>;
};

/** input type for inserting object relation for remote table "career_development_detail" */
export type Career_Development_Detail_Obj_Rel_Insert_Input = {
  data: Career_Development_Detail_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Career_Development_Detail_On_Conflict>;
};

/** on_conflict condition type for table "career_development_detail" */
export type Career_Development_Detail_On_Conflict = {
  constraint: Career_Development_Detail_Constraint;
  update_columns?: Array<Career_Development_Detail_Update_Column>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

/** Ordering options when selecting data from "career_development_detail". */
export type Career_Development_Detail_Order_By = {
  assign_career_development_plans_aggregate?: InputMaybe<Assign_Career_Development_Plan_Aggregate_Order_By>;
  career_development_role_details_aggregate?: InputMaybe<Career_Development_Role_Details_Aggregate_Order_By>;
  career_external_certifications_aggregate?: InputMaybe<Career_External_Certification_Aggregate_Order_By>;
  cover_image_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  stage_career_details_aggregate?: InputMaybe<Stage_Career_Detail_Aggregate_Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  title?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_career_development_records_aggregate?: InputMaybe<User_Career_Development_Record_Aggregate_Order_By>;
};

/** primary key columns input for table: career_development_detail */
export type Career_Development_Detail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "career_development_detail" */
export enum Career_Development_Detail_Select_Column {
  /** column name */
  CoverImageId = 'cover_image_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Title = 'title',
}

/** input type for updating data in table "career_development_detail" */
export type Career_Development_Detail_Set_Input = {
  cover_image_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Career_Development_Detail_Stddev_Fields = {
  __typename?: 'career_development_detail_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "career_development_detail" */
export type Career_Development_Detail_Stddev_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Career_Development_Detail_Stddev_Pop_Fields = {
  __typename?: 'career_development_detail_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "career_development_detail" */
export type Career_Development_Detail_Stddev_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Career_Development_Detail_Stddev_Samp_Fields = {
  __typename?: 'career_development_detail_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "career_development_detail" */
export type Career_Development_Detail_Stddev_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "career_development_detail" */
export type Career_Development_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Career_Development_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Career_Development_Detail_Stream_Cursor_Value_Input = {
  cover_image_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Career_Development_Detail_Sum_Fields = {
  __typename?: 'career_development_detail_sum_fields';
  duration?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "career_development_detail" */
export type Career_Development_Detail_Sum_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** update columns of table "career_development_detail" */
export enum Career_Development_Detail_Update_Column {
  /** column name */
  CoverImageId = 'cover_image_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Title = 'title',
}

export type Career_Development_Detail_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Career_Development_Detail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Career_Development_Detail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Career_Development_Detail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Career_Development_Detail_Var_Pop_Fields = {
  __typename?: 'career_development_detail_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "career_development_detail" */
export type Career_Development_Detail_Var_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Career_Development_Detail_Var_Samp_Fields = {
  __typename?: 'career_development_detail_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "career_development_detail" */
export type Career_Development_Detail_Var_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Career_Development_Detail_Variance_Fields = {
  __typename?: 'career_development_detail_variance_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "career_development_detail" */
export type Career_Development_Detail_Variance_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** columns and relationships of "career_development_role_details" */
export type Career_Development_Role_Details = {
  __typename?: 'career_development_role_details';
  /** An object relationship */
  career_development_detail: Career_Development_Detail;
  career_development_plan_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization_role: Organization_Roles;
  role_id: Scalars['uuid'];
};

/** aggregated selection of "career_development_role_details" */
export type Career_Development_Role_Details_Aggregate = {
  __typename?: 'career_development_role_details_aggregate';
  aggregate?: Maybe<Career_Development_Role_Details_Aggregate_Fields>;
  nodes: Array<Career_Development_Role_Details>;
};

export type Career_Development_Role_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Career_Development_Role_Details_Aggregate_Bool_Exp_Count>;
};

export type Career_Development_Role_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Career_Development_Role_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "career_development_role_details" */
export type Career_Development_Role_Details_Aggregate_Fields = {
  __typename?: 'career_development_role_details_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Career_Development_Role_Details_Max_Fields>;
  min?: Maybe<Career_Development_Role_Details_Min_Fields>;
};

/** aggregate fields of "career_development_role_details" */
export type Career_Development_Role_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Career_Development_Role_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "career_development_role_details" */
export type Career_Development_Role_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Career_Development_Role_Details_Max_Order_By>;
  min?: InputMaybe<Career_Development_Role_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "career_development_role_details" */
export type Career_Development_Role_Details_Arr_Rel_Insert_Input = {
  data: Array<Career_Development_Role_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Career_Development_Role_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "career_development_role_details". All fields are combined with a logical 'AND'. */
export type Career_Development_Role_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Career_Development_Role_Details_Bool_Exp>>;
  _not?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Career_Development_Role_Details_Bool_Exp>>;
  career_development_detail?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  career_development_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "career_development_role_details" */
export enum Career_Development_Role_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  CareerDevelopmentRoleDetailsPkey = 'career_development_role_details_pkey',
}

/** input type for inserting data into table "career_development_role_details" */
export type Career_Development_Role_Details_Insert_Input = {
  career_development_detail?: InputMaybe<Career_Development_Detail_Obj_Rel_Insert_Input>;
  career_development_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Career_Development_Role_Details_Max_Fields = {
  __typename?: 'career_development_role_details_max_fields';
  career_development_plan_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "career_development_role_details" */
export type Career_Development_Role_Details_Max_Order_By = {
  career_development_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Career_Development_Role_Details_Min_Fields = {
  __typename?: 'career_development_role_details_min_fields';
  career_development_plan_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "career_development_role_details" */
export type Career_Development_Role_Details_Min_Order_By = {
  career_development_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "career_development_role_details" */
export type Career_Development_Role_Details_Mutation_Response = {
  __typename?: 'career_development_role_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Career_Development_Role_Details>;
};

/** on_conflict condition type for table "career_development_role_details" */
export type Career_Development_Role_Details_On_Conflict = {
  constraint: Career_Development_Role_Details_Constraint;
  update_columns?: Array<Career_Development_Role_Details_Update_Column>;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "career_development_role_details". */
export type Career_Development_Role_Details_Order_By = {
  career_development_detail?: InputMaybe<Career_Development_Detail_Order_By>;
  career_development_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: career_development_role_details */
export type Career_Development_Role_Details_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "career_development_role_details" */
export enum Career_Development_Role_Details_Select_Column {
  /** column name */
  CareerDevelopmentPlanId = 'career_development_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
}

/** input type for updating data in table "career_development_role_details" */
export type Career_Development_Role_Details_Set_Input = {
  career_development_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "career_development_role_details" */
export type Career_Development_Role_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Career_Development_Role_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Career_Development_Role_Details_Stream_Cursor_Value_Input = {
  career_development_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "career_development_role_details" */
export enum Career_Development_Role_Details_Update_Column {
  /** column name */
  CareerDevelopmentPlanId = 'career_development_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
}

export type Career_Development_Role_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Career_Development_Role_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Career_Development_Role_Details_Bool_Exp;
};

/** columns and relationships of "career_external_certification" */
export type Career_External_Certification = {
  __typename?: 'career_external_certification';
  /** An object relationship */
  career_development_detail?: Maybe<Career_Development_Detail>;
  career_development_detail_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_certification_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  user_career_external_certifications: Array<User_Career_External_Certification>;
  /** An aggregate relationship */
  user_career_external_certifications_aggregate: User_Career_External_Certification_Aggregate;
};

/** columns and relationships of "career_external_certification" */
export type Career_External_CertificationUser_Career_External_CertificationsArgs =
  {
    distinct_on?: InputMaybe<
      Array<User_Career_External_Certification_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
    where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
  };

/** columns and relationships of "career_external_certification" */
export type Career_External_CertificationUser_Career_External_Certifications_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<User_Career_External_Certification_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
    where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
  };

/** aggregated selection of "career_external_certification" */
export type Career_External_Certification_Aggregate = {
  __typename?: 'career_external_certification_aggregate';
  aggregate?: Maybe<Career_External_Certification_Aggregate_Fields>;
  nodes: Array<Career_External_Certification>;
};

export type Career_External_Certification_Aggregate_Bool_Exp = {
  count?: InputMaybe<Career_External_Certification_Aggregate_Bool_Exp_Count>;
};

export type Career_External_Certification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Career_External_Certification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Career_External_Certification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "career_external_certification" */
export type Career_External_Certification_Aggregate_Fields = {
  __typename?: 'career_external_certification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Career_External_Certification_Max_Fields>;
  min?: Maybe<Career_External_Certification_Min_Fields>;
};

/** aggregate fields of "career_external_certification" */
export type Career_External_Certification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Career_External_Certification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "career_external_certification" */
export type Career_External_Certification_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Career_External_Certification_Max_Order_By>;
  min?: InputMaybe<Career_External_Certification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "career_external_certification" */
export type Career_External_Certification_Arr_Rel_Insert_Input = {
  data: Array<Career_External_Certification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Career_External_Certification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "career_external_certification". All fields are combined with a logical 'AND'. */
export type Career_External_Certification_Bool_Exp = {
  _and?: InputMaybe<Array<Career_External_Certification_Bool_Exp>>;
  _not?: InputMaybe<Career_External_Certification_Bool_Exp>;
  _or?: InputMaybe<Array<Career_External_Certification_Bool_Exp>>;
  career_development_detail?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  career_development_detail_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_certification_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_career_external_certifications?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
  user_career_external_certifications_aggregate?: InputMaybe<User_Career_External_Certification_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "career_external_certification" */
export enum Career_External_Certification_Constraint {
  /** unique or primary key constraint on columns "id" */
  CareerExternalCertificationPkey = 'career_external_certification_pkey',
}

/** input type for inserting data into table "career_external_certification" */
export type Career_External_Certification_Insert_Input = {
  career_development_detail?: InputMaybe<Career_Development_Detail_Obj_Rel_Insert_Input>;
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_certification_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_career_external_certifications?: InputMaybe<User_Career_External_Certification_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Career_External_Certification_Max_Fields = {
  __typename?: 'career_external_certification_max_fields';
  career_development_detail_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_certification_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "career_external_certification" */
export type Career_External_Certification_Max_Order_By = {
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_certification_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Career_External_Certification_Min_Fields = {
  __typename?: 'career_external_certification_min_fields';
  career_development_detail_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_certification_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "career_external_certification" */
export type Career_External_Certification_Min_Order_By = {
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_certification_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "career_external_certification" */
export type Career_External_Certification_Mutation_Response = {
  __typename?: 'career_external_certification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Career_External_Certification>;
};

/** input type for inserting object relation for remote table "career_external_certification" */
export type Career_External_Certification_Obj_Rel_Insert_Input = {
  data: Career_External_Certification_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Career_External_Certification_On_Conflict>;
};

/** on_conflict condition type for table "career_external_certification" */
export type Career_External_Certification_On_Conflict = {
  constraint: Career_External_Certification_Constraint;
  update_columns?: Array<Career_External_Certification_Update_Column>;
  where?: InputMaybe<Career_External_Certification_Bool_Exp>;
};

/** Ordering options when selecting data from "career_external_certification". */
export type Career_External_Certification_Order_By = {
  career_development_detail?: InputMaybe<Career_Development_Detail_Order_By>;
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_certification_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_career_external_certifications_aggregate?: InputMaybe<User_Career_External_Certification_Aggregate_Order_By>;
};

/** primary key columns input for table: career_external_certification */
export type Career_External_Certification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "career_external_certification" */
export enum Career_External_Certification_Select_Column {
  /** column name */
  CareerDevelopmentDetailId = 'career_development_detail_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalCertificationName = 'external_certification_name',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "career_external_certification" */
export type Career_External_Certification_Set_Input = {
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_certification_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "career_external_certification" */
export type Career_External_Certification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Career_External_Certification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Career_External_Certification_Stream_Cursor_Value_Input = {
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_certification_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "career_external_certification" */
export enum Career_External_Certification_Update_Column {
  /** column name */
  CareerDevelopmentDetailId = 'career_development_detail_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalCertificationName = 'external_certification_name',
  /** column name */
  Id = 'id',
}

export type Career_External_Certification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Career_External_Certification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Career_External_Certification_Bool_Exp;
};

/** Represent a chat message */
export type Chat_Message = {
  __typename?: 'chat_message';
  /** An array relationship */
  chat_message_actions: Array<Chat_Message_Action>;
  /** An aggregate relationship */
  chat_message_actions_aggregate: Chat_Message_Action_Aggregate;
  /** An array relationship */
  chat_message_files: Array<Chat_Message_File>;
  /** An aggregate relationship */
  chat_message_files_aggregate: Chat_Message_File_Aggregate;
  /** An object relationship */
  chat_room: Chat_Room;
  chat_room_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  emote_reactions?: Maybe<Enum_Live_Emote_Type_Enum>;
  /** An object relationship */
  enum_chat_message_type: Enum_Chat_Message_Type;
  /** An object relationship */
  enum_live_emote_type?: Maybe<Enum_Live_Emote_Type>;
  id: Scalars['uuid'];
  message_text: Scalars['String'];
  message_type: Enum_Chat_Message_Type_Enum;
  metadata?: Maybe<Scalars['jsonb']>;
  replied_to?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  replied_to_message?: Maybe<Chat_Message>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** Represent a chat message */
export type Chat_MessageChat_Message_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Action_Order_By>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

/** Represent a chat message */
export type Chat_MessageChat_Message_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Action_Order_By>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

/** Represent a chat message */
export type Chat_MessageChat_Message_FilesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_File_Order_By>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

/** Represent a chat message */
export type Chat_MessageChat_Message_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_File_Order_By>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

/** Represent a chat message */
export type Chat_MessageMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** The actions performed by users on a message */
export type Chat_Message_Action = {
  __typename?: 'chat_message_action';
  /** An object relationship */
  chat_message: Chat_Message;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  is_read: Scalars['Boolean'];
  is_shown: Scalars['Boolean'];
  message_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "chat_message_action" */
export type Chat_Message_Action_Aggregate = {
  __typename?: 'chat_message_action_aggregate';
  aggregate?: Maybe<Chat_Message_Action_Aggregate_Fields>;
  nodes: Array<Chat_Message_Action>;
};

export type Chat_Message_Action_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Chat_Message_Action_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Chat_Message_Action_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Chat_Message_Action_Aggregate_Bool_Exp_Count>;
};

export type Chat_Message_Action_Aggregate_Bool_Exp_Bool_And = {
  arguments: Chat_Message_Action_Select_Column_Chat_Message_Action_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_Action_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Message_Action_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Chat_Message_Action_Select_Column_Chat_Message_Action_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_Action_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Message_Action_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_Action_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_message_action" */
export type Chat_Message_Action_Aggregate_Fields = {
  __typename?: 'chat_message_action_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Message_Action_Max_Fields>;
  min?: Maybe<Chat_Message_Action_Min_Fields>;
};

/** aggregate fields of "chat_message_action" */
export type Chat_Message_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_action" */
export type Chat_Message_Action_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Message_Action_Max_Order_By>;
  min?: InputMaybe<Chat_Message_Action_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_message_action" */
export type Chat_Message_Action_Arr_Rel_Insert_Input = {
  data: Array<Chat_Message_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Message_Action_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_message_action". All fields are combined with a logical 'AND'. */
export type Chat_Message_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Message_Action_Bool_Exp>>;
  _not?: InputMaybe<Chat_Message_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Message_Action_Bool_Exp>>;
  chat_message?: InputMaybe<Chat_Message_Bool_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_read?: InputMaybe<Boolean_Comparison_Exp>;
  is_shown?: InputMaybe<Boolean_Comparison_Exp>;
  message_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_message_action" */
export enum Chat_Message_Action_Constraint {
  /** unique or primary key constraint on columns "user_id", "message_id" */
  ChatMessageActionPkey = 'chat_message_action_pkey',
}

/** input type for inserting data into table "chat_message_action" */
export type Chat_Message_Action_Insert_Input = {
  chat_message?: InputMaybe<Chat_Message_Obj_Rel_Insert_Input>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  is_shown?: InputMaybe<Scalars['Boolean']>;
  message_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Chat_Message_Action_Max_Fields = {
  __typename?: 'chat_message_action_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  message_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chat_message_action" */
export type Chat_Message_Action_Max_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Message_Action_Min_Fields = {
  __typename?: 'chat_message_action_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  message_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chat_message_action" */
export type Chat_Message_Action_Min_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_message_action" */
export type Chat_Message_Action_Mutation_Response = {
  __typename?: 'chat_message_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Message_Action>;
};

/** on_conflict condition type for table "chat_message_action" */
export type Chat_Message_Action_On_Conflict = {
  constraint: Chat_Message_Action_Constraint;
  update_columns?: Array<Chat_Message_Action_Update_Column>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message_action". */
export type Chat_Message_Action_Order_By = {
  chat_message?: InputMaybe<Chat_Message_Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  is_read?: InputMaybe<Order_By>;
  is_shown?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_message_action */
export type Chat_Message_Action_Pk_Columns_Input = {
  message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "chat_message_action" */
export enum Chat_Message_Action_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  UserId = 'user_id',
}

/** select "chat_message_action_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_message_action" */
export enum Chat_Message_Action_Select_Column_Chat_Message_Action_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRead = 'is_read',
  /** column name */
  IsShown = 'is_shown',
}

/** select "chat_message_action_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_message_action" */
export enum Chat_Message_Action_Select_Column_Chat_Message_Action_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRead = 'is_read',
  /** column name */
  IsShown = 'is_shown',
}

/** input type for updating data in table "chat_message_action" */
export type Chat_Message_Action_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  is_shown?: InputMaybe<Scalars['Boolean']>;
  message_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "chat_message_action" */
export type Chat_Message_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Message_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Message_Action_Stream_Cursor_Value_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  is_shown?: InputMaybe<Scalars['Boolean']>;
  message_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "chat_message_action" */
export enum Chat_Message_Action_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  UserId = 'user_id',
}

export type Chat_Message_Action_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Message_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Message_Action_Bool_Exp;
};

/** aggregated selection of "chat_message" */
export type Chat_Message_Aggregate = {
  __typename?: 'chat_message_aggregate';
  aggregate?: Maybe<Chat_Message_Aggregate_Fields>;
  nodes: Array<Chat_Message>;
};

export type Chat_Message_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chat_Message_Aggregate_Bool_Exp_Count>;
};

export type Chat_Message_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Message_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_message" */
export type Chat_Message_Aggregate_Fields = {
  __typename?: 'chat_message_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Message_Max_Fields>;
  min?: Maybe<Chat_Message_Min_Fields>;
};

/** aggregate fields of "chat_message" */
export type Chat_Message_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Message_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message" */
export type Chat_Message_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Message_Max_Order_By>;
  min?: InputMaybe<Chat_Message_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Chat_Message_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "chat_message" */
export type Chat_Message_Arr_Rel_Insert_Input = {
  data: Array<Chat_Message_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Message_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_message". All fields are combined with a logical 'AND'. */
export type Chat_Message_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Message_Bool_Exp>>;
  _not?: InputMaybe<Chat_Message_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Message_Bool_Exp>>;
  chat_message_actions?: InputMaybe<Chat_Message_Action_Bool_Exp>;
  chat_message_actions_aggregate?: InputMaybe<Chat_Message_Action_Aggregate_Bool_Exp>;
  chat_message_files?: InputMaybe<Chat_Message_File_Bool_Exp>;
  chat_message_files_aggregate?: InputMaybe<Chat_Message_File_Aggregate_Bool_Exp>;
  chat_room?: InputMaybe<Chat_Room_Bool_Exp>;
  chat_room_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  emote_reactions?: InputMaybe<Enum_Live_Emote_Type_Enum_Comparison_Exp>;
  enum_chat_message_type?: InputMaybe<Enum_Chat_Message_Type_Bool_Exp>;
  enum_live_emote_type?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message_text?: InputMaybe<String_Comparison_Exp>;
  message_type?: InputMaybe<Enum_Chat_Message_Type_Enum_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  replied_to?: InputMaybe<Uuid_Comparison_Exp>;
  replied_to_message?: InputMaybe<Chat_Message_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "chat_message" */
export enum Chat_Message_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessagePkey = 'chat_message_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Chat_Message_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Chat_Message_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Chat_Message_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** Files added to a chat message */
export type Chat_Message_File = {
  __typename?: 'chat_message_file';
  /** An object relationship */
  chat_message: Chat_Message;
  chat_message_id: Scalars['uuid'];
  /** An object relationship */
  storage_file: Storage_Files;
  storage_file_id: Scalars['uuid'];
};

/** aggregated selection of "chat_message_file" */
export type Chat_Message_File_Aggregate = {
  __typename?: 'chat_message_file_aggregate';
  aggregate?: Maybe<Chat_Message_File_Aggregate_Fields>;
  nodes: Array<Chat_Message_File>;
};

export type Chat_Message_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chat_Message_File_Aggregate_Bool_Exp_Count>;
};

export type Chat_Message_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_message_file" */
export type Chat_Message_File_Aggregate_Fields = {
  __typename?: 'chat_message_file_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Message_File_Max_Fields>;
  min?: Maybe<Chat_Message_File_Min_Fields>;
};

/** aggregate fields of "chat_message_file" */
export type Chat_Message_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_file" */
export type Chat_Message_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Message_File_Max_Order_By>;
  min?: InputMaybe<Chat_Message_File_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_message_file" */
export type Chat_Message_File_Arr_Rel_Insert_Input = {
  data: Array<Chat_Message_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Message_File_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_message_file". All fields are combined with a logical 'AND'. */
export type Chat_Message_File_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Message_File_Bool_Exp>>;
  _not?: InputMaybe<Chat_Message_File_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Message_File_Bool_Exp>>;
  chat_message?: InputMaybe<Chat_Message_Bool_Exp>;
  chat_message_id?: InputMaybe<Uuid_Comparison_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  storage_file_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_message_file" */
export enum Chat_Message_File_Constraint {
  /** unique or primary key constraint on columns "chat_message_id", "storage_file_id" */
  ChatMessageFilePkey = 'chat_message_file_pkey',
}

/** input type for inserting data into table "chat_message_file" */
export type Chat_Message_File_Insert_Input = {
  chat_message?: InputMaybe<Chat_Message_Obj_Rel_Insert_Input>;
  chat_message_id?: InputMaybe<Scalars['uuid']>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Chat_Message_File_Max_Fields = {
  __typename?: 'chat_message_file_max_fields';
  chat_message_id?: Maybe<Scalars['uuid']>;
  storage_file_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chat_message_file" */
export type Chat_Message_File_Max_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Message_File_Min_Fields = {
  __typename?: 'chat_message_file_min_fields';
  chat_message_id?: Maybe<Scalars['uuid']>;
  storage_file_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chat_message_file" */
export type Chat_Message_File_Min_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_message_file" */
export type Chat_Message_File_Mutation_Response = {
  __typename?: 'chat_message_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Message_File>;
};

/** on_conflict condition type for table "chat_message_file" */
export type Chat_Message_File_On_Conflict = {
  constraint: Chat_Message_File_Constraint;
  update_columns?: Array<Chat_Message_File_Update_Column>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message_file". */
export type Chat_Message_File_Order_By = {
  chat_message?: InputMaybe<Chat_Message_Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_message_file */
export type Chat_Message_File_Pk_Columns_Input = {
  chat_message_id: Scalars['uuid'];
  storage_file_id: Scalars['uuid'];
};

/** select columns of table "chat_message_file" */
export enum Chat_Message_File_Select_Column {
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  StorageFileId = 'storage_file_id',
}

/** input type for updating data in table "chat_message_file" */
export type Chat_Message_File_Set_Input = {
  chat_message_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "chat_message_file" */
export type Chat_Message_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Message_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Message_File_Stream_Cursor_Value_Input = {
  chat_message_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "chat_message_file" */
export enum Chat_Message_File_Update_Column {
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  StorageFileId = 'storage_file_id',
}

export type Chat_Message_File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Message_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Message_File_Bool_Exp;
};

/** input type for inserting data into table "chat_message" */
export type Chat_Message_Insert_Input = {
  chat_message_actions?: InputMaybe<Chat_Message_Action_Arr_Rel_Insert_Input>;
  chat_message_files?: InputMaybe<Chat_Message_File_Arr_Rel_Insert_Input>;
  chat_room?: InputMaybe<Chat_Room_Obj_Rel_Insert_Input>;
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  emote_reactions?: InputMaybe<Enum_Live_Emote_Type_Enum>;
  enum_chat_message_type?: InputMaybe<Enum_Chat_Message_Type_Obj_Rel_Insert_Input>;
  enum_live_emote_type?: InputMaybe<Enum_Live_Emote_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  message_text?: InputMaybe<Scalars['String']>;
  message_type?: InputMaybe<Enum_Chat_Message_Type_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  replied_to?: InputMaybe<Scalars['uuid']>;
  replied_to_message?: InputMaybe<Chat_Message_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Chat_Message_Max_Fields = {
  __typename?: 'chat_message_max_fields';
  chat_room_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message_text?: Maybe<Scalars['String']>;
  replied_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_message" */
export type Chat_Message_Max_Order_By = {
  chat_room_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_text?: InputMaybe<Order_By>;
  replied_to?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Message_Min_Fields = {
  __typename?: 'chat_message_min_fields';
  chat_room_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message_text?: Maybe<Scalars['String']>;
  replied_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_message" */
export type Chat_Message_Min_Order_By = {
  chat_room_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_text?: InputMaybe<Order_By>;
  replied_to?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_message" */
export type Chat_Message_Mutation_Response = {
  __typename?: 'chat_message_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Message>;
};

/** input type for inserting object relation for remote table "chat_message" */
export type Chat_Message_Obj_Rel_Insert_Input = {
  data: Chat_Message_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Message_On_Conflict>;
};

/** on_conflict condition type for table "chat_message" */
export type Chat_Message_On_Conflict = {
  constraint: Chat_Message_Constraint;
  update_columns?: Array<Chat_Message_Update_Column>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message". */
export type Chat_Message_Order_By = {
  chat_message_actions_aggregate?: InputMaybe<Chat_Message_Action_Aggregate_Order_By>;
  chat_message_files_aggregate?: InputMaybe<Chat_Message_File_Aggregate_Order_By>;
  chat_room?: InputMaybe<Chat_Room_Order_By>;
  chat_room_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  emote_reactions?: InputMaybe<Order_By>;
  enum_chat_message_type?: InputMaybe<Enum_Chat_Message_Type_Order_By>;
  enum_live_emote_type?: InputMaybe<Enum_Live_Emote_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  message_text?: InputMaybe<Order_By>;
  message_type?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  replied_to?: InputMaybe<Order_By>;
  replied_to_message?: InputMaybe<Chat_Message_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: chat_message */
export type Chat_Message_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Chat_Message_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "chat_message_reactions" */
export type Chat_Message_Reactions = {
  __typename?: 'chat_message_reactions';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_live_emote_type: Enum_Live_Emote_Type;
  id: Scalars['uuid'];
  /** An object relationship */
  message?: Maybe<Chat_Message>;
  message_id: Scalars['uuid'];
  reacted_by: Scalars['uuid'];
  reaction_type: Enum_Live_Emote_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "chat_message_reactions" */
export type Chat_Message_Reactions_Aggregate = {
  __typename?: 'chat_message_reactions_aggregate';
  aggregate?: Maybe<Chat_Message_Reactions_Aggregate_Fields>;
  nodes: Array<Chat_Message_Reactions>;
};

export type Chat_Message_Reactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chat_Message_Reactions_Aggregate_Bool_Exp_Count>;
};

export type Chat_Message_Reactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Message_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_message_reactions" */
export type Chat_Message_Reactions_Aggregate_Fields = {
  __typename?: 'chat_message_reactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Message_Reactions_Max_Fields>;
  min?: Maybe<Chat_Message_Reactions_Min_Fields>;
};

/** aggregate fields of "chat_message_reactions" */
export type Chat_Message_Reactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Message_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_reactions" */
export type Chat_Message_Reactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Message_Reactions_Max_Order_By>;
  min?: InputMaybe<Chat_Message_Reactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_message_reactions" */
export type Chat_Message_Reactions_Arr_Rel_Insert_Input = {
  data: Array<Chat_Message_Reactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Message_Reactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_message_reactions". All fields are combined with a logical 'AND'. */
export type Chat_Message_Reactions_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Message_Reactions_Bool_Exp>>;
  _not?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Message_Reactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_live_emote_type?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<Chat_Message_Bool_Exp>;
  message_id?: InputMaybe<Uuid_Comparison_Exp>;
  reacted_by?: InputMaybe<Uuid_Comparison_Exp>;
  reaction_type?: InputMaybe<Enum_Live_Emote_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "chat_message_reactions" */
export enum Chat_Message_Reactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessageReactionsPkey = 'chat_message_reactions_pkey',
}

/** input type for inserting data into table "chat_message_reactions" */
export type Chat_Message_Reactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_live_emote_type?: InputMaybe<Enum_Live_Emote_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Chat_Message_Obj_Rel_Insert_Input>;
  message_id?: InputMaybe<Scalars['uuid']>;
  reacted_by?: InputMaybe<Scalars['uuid']>;
  reaction_type?: InputMaybe<Enum_Live_Emote_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Chat_Message_Reactions_Max_Fields = {
  __typename?: 'chat_message_reactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['uuid']>;
  reacted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_message_reactions" */
export type Chat_Message_Reactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  reacted_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Message_Reactions_Min_Fields = {
  __typename?: 'chat_message_reactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['uuid']>;
  reacted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_message_reactions" */
export type Chat_Message_Reactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  reacted_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_message_reactions" */
export type Chat_Message_Reactions_Mutation_Response = {
  __typename?: 'chat_message_reactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Message_Reactions>;
};

/** on_conflict condition type for table "chat_message_reactions" */
export type Chat_Message_Reactions_On_Conflict = {
  constraint: Chat_Message_Reactions_Constraint;
  update_columns?: Array<Chat_Message_Reactions_Update_Column>;
  where?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message_reactions". */
export type Chat_Message_Reactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  enum_live_emote_type?: InputMaybe<Enum_Live_Emote_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Chat_Message_Order_By>;
  message_id?: InputMaybe<Order_By>;
  reacted_by?: InputMaybe<Order_By>;
  reaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: chat_message_reactions */
export type Chat_Message_Reactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_message_reactions" */
export enum Chat_Message_Reactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  ReactedBy = 'reacted_by',
  /** column name */
  ReactionType = 'reaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "chat_message_reactions" */
export type Chat_Message_Reactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message_id?: InputMaybe<Scalars['uuid']>;
  reacted_by?: InputMaybe<Scalars['uuid']>;
  reaction_type?: InputMaybe<Enum_Live_Emote_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_message_reactions" */
export type Chat_Message_Reactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Message_Reactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Message_Reactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message_id?: InputMaybe<Scalars['uuid']>;
  reacted_by?: InputMaybe<Scalars['uuid']>;
  reaction_type?: InputMaybe<Enum_Live_Emote_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_message_reactions" */
export enum Chat_Message_Reactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  ReactedBy = 'reacted_by',
  /** column name */
  ReactionType = 'reaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Chat_Message_Reactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Message_Reactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Message_Reactions_Bool_Exp;
};

/** select columns of table "chat_message" */
export enum Chat_Message_Select_Column {
  /** column name */
  ChatRoomId = 'chat_room_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EmoteReactions = 'emote_reactions',
  /** column name */
  Id = 'id',
  /** column name */
  MessageText = 'message_text',
  /** column name */
  MessageType = 'message_type',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RepliedTo = 'replied_to',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "chat_message" */
export type Chat_Message_Set_Input = {
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  emote_reactions?: InputMaybe<Enum_Live_Emote_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  message_text?: InputMaybe<Scalars['String']>;
  message_type?: InputMaybe<Enum_Chat_Message_Type_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  replied_to?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_message" */
export type Chat_Message_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Message_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Message_Stream_Cursor_Value_Input = {
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  emote_reactions?: InputMaybe<Enum_Live_Emote_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  message_text?: InputMaybe<Scalars['String']>;
  message_type?: InputMaybe<Enum_Chat_Message_Type_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  replied_to?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_message" */
export enum Chat_Message_Update_Column {
  /** column name */
  ChatRoomId = 'chat_room_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EmoteReactions = 'emote_reactions',
  /** column name */
  Id = 'id',
  /** column name */
  MessageText = 'message_text',
  /** column name */
  MessageType = 'message_type',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RepliedTo = 'replied_to',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Chat_Message_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Chat_Message_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Chat_Message_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Chat_Message_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Chat_Message_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Chat_Message_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Message_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Message_Bool_Exp;
};

/** columns and relationships of "chat_pinned" */
export type Chat_Pinned = {
  __typename?: 'chat_pinned';
  chat_room_id: Scalars['uuid'];
  id: Scalars['uuid'];
  is_pinned: Scalars['Boolean'];
  /** An object relationship */
  room?: Maybe<Chat_Room>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "chat_pinned" */
export type Chat_Pinned_Aggregate = {
  __typename?: 'chat_pinned_aggregate';
  aggregate?: Maybe<Chat_Pinned_Aggregate_Fields>;
  nodes: Array<Chat_Pinned>;
};

/** aggregate fields of "chat_pinned" */
export type Chat_Pinned_Aggregate_Fields = {
  __typename?: 'chat_pinned_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Pinned_Max_Fields>;
  min?: Maybe<Chat_Pinned_Min_Fields>;
};

/** aggregate fields of "chat_pinned" */
export type Chat_Pinned_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Pinned_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_pinned". All fields are combined with a logical 'AND'. */
export type Chat_Pinned_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Pinned_Bool_Exp>>;
  _not?: InputMaybe<Chat_Pinned_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Pinned_Bool_Exp>>;
  chat_room_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_pinned?: InputMaybe<Boolean_Comparison_Exp>;
  room?: InputMaybe<Chat_Room_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_pinned" */
export enum Chat_Pinned_Constraint {
  /** unique or primary key constraint on columns "chat_room_id" */
  ChatPinnedChatRoomIdKey = 'chat_pinned_chat_room_id_key',
  /** unique or primary key constraint on columns "id" */
  ChatPinnedPkey = 'chat_pinned_pkey',
}

/** input type for inserting data into table "chat_pinned" */
export type Chat_Pinned_Insert_Input = {
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_pinned?: InputMaybe<Scalars['Boolean']>;
  room?: InputMaybe<Chat_Room_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Chat_Pinned_Max_Fields = {
  __typename?: 'chat_pinned_max_fields';
  chat_room_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Chat_Pinned_Min_Fields = {
  __typename?: 'chat_pinned_min_fields';
  chat_room_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "chat_pinned" */
export type Chat_Pinned_Mutation_Response = {
  __typename?: 'chat_pinned_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Pinned>;
};

/** on_conflict condition type for table "chat_pinned" */
export type Chat_Pinned_On_Conflict = {
  constraint: Chat_Pinned_Constraint;
  update_columns?: Array<Chat_Pinned_Update_Column>;
  where?: InputMaybe<Chat_Pinned_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_pinned". */
export type Chat_Pinned_Order_By = {
  chat_room_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_pinned?: InputMaybe<Order_By>;
  room?: InputMaybe<Chat_Room_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_pinned */
export type Chat_Pinned_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_pinned" */
export enum Chat_Pinned_Select_Column {
  /** column name */
  ChatRoomId = 'chat_room_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPinned = 'is_pinned',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "chat_pinned" */
export type Chat_Pinned_Set_Input = {
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_pinned?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "chat_pinned" */
export type Chat_Pinned_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Pinned_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Pinned_Stream_Cursor_Value_Input = {
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_pinned?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "chat_pinned" */
export enum Chat_Pinned_Update_Column {
  /** column name */
  ChatRoomId = 'chat_room_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPinned = 'is_pinned',
  /** column name */
  UserId = 'user_id',
}

export type Chat_Pinned_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Pinned_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Pinned_Bool_Exp;
};

/** Represent a chat room  */
export type Chat_Room = {
  __typename?: 'chat_room';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  chat_messages: Array<Chat_Message>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Message_Aggregate;
  /** An object relationship */
  chat_room_managed_by?: Maybe<Users>;
  chat_room_type: Enum_Chat_Room_Type_Enum;
  /** An array relationship */
  chat_room_users: Array<Chat_Room_User>;
  /** An aggregate relationship */
  chat_room_users_aggregate: Chat_Room_User_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_chat_room_type: Enum_Chat_Room_Type;
  id: Scalars['uuid'];
  last_message_id?: Maybe<Scalars['uuid']>;
  managed_by?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  resource_id?: Maybe<Scalars['uuid']>;
  room_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** Represent a chat room  */
export type Chat_RoomChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** Represent a chat room  */
export type Chat_RoomChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** Represent a chat room  */
export type Chat_RoomChat_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** Represent a chat room  */
export type Chat_RoomChat_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** Represent a chat room  */
export type Chat_RoomMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Represent a chat room  */
export type Chat_RoomProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Represent a chat room  */
export type Chat_RoomProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** aggregated selection of "chat_room" */
export type Chat_Room_Aggregate = {
  __typename?: 'chat_room_aggregate';
  aggregate?: Maybe<Chat_Room_Aggregate_Fields>;
  nodes: Array<Chat_Room>;
};

export type Chat_Room_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chat_Room_Aggregate_Bool_Exp_Count>;
};

export type Chat_Room_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Room_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_room" */
export type Chat_Room_Aggregate_Fields = {
  __typename?: 'chat_room_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Room_Max_Fields>;
  min?: Maybe<Chat_Room_Min_Fields>;
};

/** aggregate fields of "chat_room" */
export type Chat_Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_room" */
export type Chat_Room_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Room_Max_Order_By>;
  min?: InputMaybe<Chat_Room_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Chat_Room_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "chat_room" */
export type Chat_Room_Arr_Rel_Insert_Input = {
  data: Array<Chat_Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Room_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_room". All fields are combined with a logical 'AND'. */
export type Chat_Room_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Room_Bool_Exp>>;
  _not?: InputMaybe<Chat_Room_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Room_Bool_Exp>>;
  calendar_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  chat_messages?: InputMaybe<Chat_Message_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Bool_Exp>;
  chat_room_managed_by?: InputMaybe<Users_Bool_Exp>;
  chat_room_type?: InputMaybe<Enum_Chat_Room_Type_Enum_Comparison_Exp>;
  chat_room_users?: InputMaybe<Chat_Room_User_Bool_Exp>;
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_chat_room_type?: InputMaybe<Enum_Chat_Room_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_message_id?: InputMaybe<Uuid_Comparison_Exp>;
  managed_by?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  resource_id?: InputMaybe<Uuid_Comparison_Exp>;
  room_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "chat_room" */
export enum Chat_Room_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatRoomPkey = 'chat_room_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Chat_Room_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Chat_Room_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Chat_Room_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "chat_room" */
export type Chat_Room_Insert_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  chat_messages?: InputMaybe<Chat_Message_Arr_Rel_Insert_Input>;
  chat_room_managed_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  chat_room_type?: InputMaybe<Enum_Chat_Room_Type_Enum>;
  chat_room_users?: InputMaybe<Chat_Room_User_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  enum_chat_room_type?: InputMaybe<Enum_Chat_Room_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  last_message_id?: InputMaybe<Scalars['uuid']>;
  managed_by?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  resource_id?: InputMaybe<Scalars['uuid']>;
  room_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Chat_Room_Max_Fields = {
  __typename?: 'chat_room_max_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_message_id?: Maybe<Scalars['uuid']>;
  managed_by?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  room_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_room" */
export type Chat_Room_Max_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_message_id?: InputMaybe<Order_By>;
  managed_by?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  room_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Room_Min_Fields = {
  __typename?: 'chat_room_min_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_message_id?: Maybe<Scalars['uuid']>;
  managed_by?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  room_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_room" */
export type Chat_Room_Min_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_message_id?: InputMaybe<Order_By>;
  managed_by?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  room_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_room" */
export type Chat_Room_Mutation_Response = {
  __typename?: 'chat_room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Room>;
};

/** input type for inserting object relation for remote table "chat_room" */
export type Chat_Room_Obj_Rel_Insert_Input = {
  data: Chat_Room_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Room_On_Conflict>;
};

/** on_conflict condition type for table "chat_room" */
export type Chat_Room_On_Conflict = {
  constraint: Chat_Room_Constraint;
  update_columns?: Array<Chat_Room_Update_Column>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_room". */
export type Chat_Room_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Order_By>;
  chat_room_managed_by?: InputMaybe<Users_Order_By>;
  chat_room_type?: InputMaybe<Order_By>;
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  enum_chat_room_type?: InputMaybe<Enum_Chat_Room_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  last_message_id?: InputMaybe<Order_By>;
  managed_by?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  resource_id?: InputMaybe<Order_By>;
  room_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: chat_room */
export type Chat_Room_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Chat_Room_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "chat_room" */
export enum Chat_Room_Select_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  ChatRoomType = 'chat_room_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastMessageId = 'last_message_id',
  /** column name */
  ManagedBy = 'managed_by',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  RoomName = 'room_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "chat_room" */
export type Chat_Room_Set_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  chat_room_type?: InputMaybe<Enum_Chat_Room_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_message_id?: InputMaybe<Scalars['uuid']>;
  managed_by?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  resource_id?: InputMaybe<Scalars['uuid']>;
  room_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_room" */
export type Chat_Room_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Room_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Room_Stream_Cursor_Value_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  chat_room_type?: InputMaybe<Enum_Chat_Room_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_message_id?: InputMaybe<Scalars['uuid']>;
  managed_by?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  resource_id?: InputMaybe<Scalars['uuid']>;
  room_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_room" */
export enum Chat_Room_Update_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  ChatRoomType = 'chat_room_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastMessageId = 'last_message_id',
  /** column name */
  ManagedBy = 'managed_by',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  RoomName = 'room_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Chat_Room_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Chat_Room_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Chat_Room_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Chat_Room_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Chat_Room_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Chat_Room_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Room_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Room_Bool_Exp;
};

/** Users belonging in chat rooms */
export type Chat_Room_User = {
  __typename?: 'chat_room_user';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  chat_room: Chat_Room;
  chat_room_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_chat_notification: Enum_Chat_Notification;
  /** An object relationship */
  enum_chat_room_notification_status?: Maybe<Enum_Chat_Room_Notification_Status>;
  /** An object relationship */
  enum_chat_room_user_status: Enum_Chat_Room_User_Status;
  inactive_from?: Maybe<Scalars['timestamptz']>;
  is_active_is_this_room: Scalars['Boolean'];
  notification_settings: Enum_Chat_Notification_Enum;
  notification_status?: Maybe<Enum_Chat_Room_Notification_Status_Enum>;
  status: Enum_Chat_Room_User_Status_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "chat_room_user" */
export type Chat_Room_User_Aggregate = {
  __typename?: 'chat_room_user_aggregate';
  aggregate?: Maybe<Chat_Room_User_Aggregate_Fields>;
  nodes: Array<Chat_Room_User>;
};

export type Chat_Room_User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Chat_Room_User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Chat_Room_User_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Chat_Room_User_Aggregate_Bool_Exp_Count>;
};

export type Chat_Room_User_Aggregate_Bool_Exp_Bool_And = {
  arguments: Chat_Room_User_Select_Column_Chat_Room_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Room_User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Room_User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Chat_Room_User_Select_Column_Chat_Room_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Room_User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Room_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Room_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_room_user" */
export type Chat_Room_User_Aggregate_Fields = {
  __typename?: 'chat_room_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Room_User_Max_Fields>;
  min?: Maybe<Chat_Room_User_Min_Fields>;
};

/** aggregate fields of "chat_room_user" */
export type Chat_Room_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_room_user" */
export type Chat_Room_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Room_User_Max_Order_By>;
  min?: InputMaybe<Chat_Room_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_room_user" */
export type Chat_Room_User_Arr_Rel_Insert_Input = {
  data: Array<Chat_Room_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Room_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_room_user". All fields are combined with a logical 'AND'. */
export type Chat_Room_User_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Room_User_Bool_Exp>>;
  _not?: InputMaybe<Chat_Room_User_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Room_User_Bool_Exp>>;
  calendar_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  chat_room?: InputMaybe<Chat_Room_Bool_Exp>;
  chat_room_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_chat_notification?: InputMaybe<Enum_Chat_Notification_Bool_Exp>;
  enum_chat_room_notification_status?: InputMaybe<Enum_Chat_Room_Notification_Status_Bool_Exp>;
  enum_chat_room_user_status?: InputMaybe<Enum_Chat_Room_User_Status_Bool_Exp>;
  inactive_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_active_is_this_room?: InputMaybe<Boolean_Comparison_Exp>;
  notification_settings?: InputMaybe<Enum_Chat_Notification_Enum_Comparison_Exp>;
  notification_status?: InputMaybe<Enum_Chat_Room_Notification_Status_Enum_Comparison_Exp>;
  status?: InputMaybe<Enum_Chat_Room_User_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_room_user" */
export enum Chat_Room_User_Constraint {
  /** unique or primary key constraint on columns "chat_room_id", "user_id" */
  ChatRoomUserPkey = 'chat_room_user_pkey',
}

/** input type for inserting data into table "chat_room_user" */
export type Chat_Room_User_Insert_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  chat_room?: InputMaybe<Chat_Room_Obj_Rel_Insert_Input>;
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_chat_notification?: InputMaybe<Enum_Chat_Notification_Obj_Rel_Insert_Input>;
  enum_chat_room_notification_status?: InputMaybe<Enum_Chat_Room_Notification_Status_Obj_Rel_Insert_Input>;
  enum_chat_room_user_status?: InputMaybe<Enum_Chat_Room_User_Status_Obj_Rel_Insert_Input>;
  inactive_from?: InputMaybe<Scalars['timestamptz']>;
  is_active_is_this_room?: InputMaybe<Scalars['Boolean']>;
  notification_settings?: InputMaybe<Enum_Chat_Notification_Enum>;
  notification_status?: InputMaybe<Enum_Chat_Room_Notification_Status_Enum>;
  status?: InputMaybe<Enum_Chat_Room_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Chat_Room_User_Max_Fields = {
  __typename?: 'chat_room_user_max_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  chat_room_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  inactive_from?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chat_room_user" */
export type Chat_Room_User_Max_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  chat_room_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  inactive_from?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Room_User_Min_Fields = {
  __typename?: 'chat_room_user_min_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  chat_room_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  inactive_from?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chat_room_user" */
export type Chat_Room_User_Min_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  chat_room_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  inactive_from?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_room_user" */
export type Chat_Room_User_Mutation_Response = {
  __typename?: 'chat_room_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Room_User>;
};

/** on_conflict condition type for table "chat_room_user" */
export type Chat_Room_User_On_Conflict = {
  constraint: Chat_Room_User_Constraint;
  update_columns?: Array<Chat_Room_User_Update_Column>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_room_user". */
export type Chat_Room_User_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  chat_room?: InputMaybe<Chat_Room_Order_By>;
  chat_room_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_chat_notification?: InputMaybe<Enum_Chat_Notification_Order_By>;
  enum_chat_room_notification_status?: InputMaybe<Enum_Chat_Room_Notification_Status_Order_By>;
  enum_chat_room_user_status?: InputMaybe<Enum_Chat_Room_User_Status_Order_By>;
  inactive_from?: InputMaybe<Order_By>;
  is_active_is_this_room?: InputMaybe<Order_By>;
  notification_settings?: InputMaybe<Order_By>;
  notification_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_room_user */
export type Chat_Room_User_Pk_Columns_Input = {
  chat_room_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "chat_room_user" */
export enum Chat_Room_User_Select_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  ChatRoomId = 'chat_room_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InactiveFrom = 'inactive_from',
  /** column name */
  IsActiveIsThisRoom = 'is_active_is_this_room',
  /** column name */
  NotificationSettings = 'notification_settings',
  /** column name */
  NotificationStatus = 'notification_status',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "chat_room_user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_room_user" */
export enum Chat_Room_User_Select_Column_Chat_Room_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActiveIsThisRoom = 'is_active_is_this_room',
}

/** select "chat_room_user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_room_user" */
export enum Chat_Room_User_Select_Column_Chat_Room_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActiveIsThisRoom = 'is_active_is_this_room',
}

/** input type for updating data in table "chat_room_user" */
export type Chat_Room_User_Set_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  inactive_from?: InputMaybe<Scalars['timestamptz']>;
  is_active_is_this_room?: InputMaybe<Scalars['Boolean']>;
  notification_settings?: InputMaybe<Enum_Chat_Notification_Enum>;
  notification_status?: InputMaybe<Enum_Chat_Room_Notification_Status_Enum>;
  status?: InputMaybe<Enum_Chat_Room_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "chat_room_user" */
export type Chat_Room_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Room_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Room_User_Stream_Cursor_Value_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  chat_room_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  inactive_from?: InputMaybe<Scalars['timestamptz']>;
  is_active_is_this_room?: InputMaybe<Scalars['Boolean']>;
  notification_settings?: InputMaybe<Enum_Chat_Notification_Enum>;
  notification_status?: InputMaybe<Enum_Chat_Room_Notification_Status_Enum>;
  status?: InputMaybe<Enum_Chat_Room_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "chat_room_user" */
export enum Chat_Room_User_Update_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  ChatRoomId = 'chat_room_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InactiveFrom = 'inactive_from',
  /** column name */
  IsActiveIsThisRoom = 'is_active_is_this_room',
  /** column name */
  NotificationSettings = 'notification_settings',
  /** column name */
  NotificationStatus = 'notification_status',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Chat_Room_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Room_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Room_User_Bool_Exp;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']>;
};

export type Completed = {
  __typename?: 'completed';
  displayName: Scalars['String'];
  profile: Scalars['String'];
  status: Scalars['String'];
};

export type CompletedBy = {
  __typename?: 'completedBy';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateAnnouncementInput = {
  message: Scalars['String'];
};

export type CreateAnnouncementOutput = {
  __typename?: 'createAnnouncementOutput';
  post_id: Scalars['String'];
};

export type CreateAssessmentRoleInput = {
  roleId: Scalars['uuid'];
};

export type CreateAwardTypeInput = {
  created_by?: InputMaybe<Scalars['uuid']>;
  cycle: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  permissions?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['uuid']>;
};

export type CreateAwardTypeOutput = {
  __typename?: 'createAwardTypeOutput';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  addressId: Scalars['String'];
  anniversaryDate: Scalars['String'];
  employeeNo: Scalars['String'];
  endTime: Scalars['String'];
  holidayFileId: Scalars['uuid'];
  logoFileId?: InputMaybe<Scalars['uuid']>;
  organizationName: Scalars['String'];
  seatNo: Scalars['String'];
  startTime: Scalars['String'];
  timezoneName: Scalars['String'];
  workingDays: Scalars['String'];
  workingHours: Scalars['uuid'];
};

export type CreateOrganizationOutput = {
  __typename?: 'createOrganizationOutput';
  organizationId: Scalars['uuid'];
};

export type CreateOrganizationOwnerOutput = {
  __typename?: 'createOrganizationOwnerOutput';
  userId: Scalars['uuid'];
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "dashboard_billable" */
export type Dashboard_Billable = {
  __typename?: 'dashboard_billable';
  client_id?: Maybe<Scalars['uuid']>;
  client_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  work_date?: Maybe<Scalars['date']>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "dashboard_billable" */
export type Dashboard_Billable_Aggregate = {
  __typename?: 'dashboard_billable_aggregate';
  aggregate?: Maybe<Dashboard_Billable_Aggregate_Fields>;
  nodes: Array<Dashboard_Billable>;
};

/** aggregate fields of "dashboard_billable" */
export type Dashboard_Billable_Aggregate_Fields = {
  __typename?: 'dashboard_billable_aggregate_fields';
  avg?: Maybe<Dashboard_Billable_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Billable_Max_Fields>;
  min?: Maybe<Dashboard_Billable_Min_Fields>;
  stddev?: Maybe<Dashboard_Billable_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Billable_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Billable_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Billable_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Billable_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Billable_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Billable_Variance_Fields>;
};

/** aggregate fields of "dashboard_billable" */
export type Dashboard_Billable_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Billable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Billable_Avg_Fields = {
  __typename?: 'dashboard_billable_avg_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_billable". All fields are combined with a logical 'AND'. */
export type Dashboard_Billable_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Billable_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Billable_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Billable_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  work_date?: InputMaybe<Date_Comparison_Exp>;
  work_hours?: InputMaybe<Numeric_Comparison_Exp>;
};

/** A view for get_employee_billable_hours output permissions */
export type Dashboard_Billable_Employee = {
  __typename?: 'dashboard_billable_employee';
  client_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project_client?: Maybe<Project_Client>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "dashboard_billable_employee" */
export type Dashboard_Billable_Employee_Aggregate = {
  __typename?: 'dashboard_billable_employee_aggregate';
  aggregate?: Maybe<Dashboard_Billable_Employee_Aggregate_Fields>;
  nodes: Array<Dashboard_Billable_Employee>;
};

/** aggregate fields of "dashboard_billable_employee" */
export type Dashboard_Billable_Employee_Aggregate_Fields = {
  __typename?: 'dashboard_billable_employee_aggregate_fields';
  avg?: Maybe<Dashboard_Billable_Employee_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Billable_Employee_Max_Fields>;
  min?: Maybe<Dashboard_Billable_Employee_Min_Fields>;
  stddev?: Maybe<Dashboard_Billable_Employee_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Billable_Employee_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Billable_Employee_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Billable_Employee_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Billable_Employee_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Billable_Employee_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Billable_Employee_Variance_Fields>;
};

/** aggregate fields of "dashboard_billable_employee" */
export type Dashboard_Billable_Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Billable_Employee_Avg_Fields = {
  __typename?: 'dashboard_billable_employee_avg_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_billable_employee". All fields are combined with a logical 'AND'. */
export type Dashboard_Billable_Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Billable_Employee_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Billable_Employee_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_client?: InputMaybe<Project_Client_Bool_Exp>;
  work_hours?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Billable_Employee_Max_Fields = {
  __typename?: 'dashboard_billable_employee_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Dashboard_Billable_Employee_Min_Fields = {
  __typename?: 'dashboard_billable_employee_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "dashboard_billable_employee". */
export type Dashboard_Billable_Employee_Order_By = {
  client_id?: InputMaybe<Order_By>;
  project_client?: InputMaybe<Project_Client_Order_By>;
  work_hours?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_billable_employee" */
export enum Dashboard_Billable_Employee_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  WorkHours = 'work_hours',
}

/** aggregate stddev on columns */
export type Dashboard_Billable_Employee_Stddev_Fields = {
  __typename?: 'dashboard_billable_employee_stddev_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Billable_Employee_Stddev_Pop_Fields = {
  __typename?: 'dashboard_billable_employee_stddev_pop_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Billable_Employee_Stddev_Samp_Fields = {
  __typename?: 'dashboard_billable_employee_stddev_samp_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_billable_employee" */
export type Dashboard_Billable_Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Billable_Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Billable_Employee_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  work_hours?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Dashboard_Billable_Employee_Sum_Fields = {
  __typename?: 'dashboard_billable_employee_sum_fields';
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Billable_Employee_Var_Pop_Fields = {
  __typename?: 'dashboard_billable_employee_var_pop_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Billable_Employee_Var_Samp_Fields = {
  __typename?: 'dashboard_billable_employee_var_samp_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Billable_Employee_Variance_Fields = {
  __typename?: 'dashboard_billable_employee_variance_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** A view for get_employee_billable_hours_as_manager output permissions */
export type Dashboard_Billable_Manager = {
  __typename?: 'dashboard_billable_manager';
  client_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project_client?: Maybe<Project_Client>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "dashboard_billable_manager" */
export type Dashboard_Billable_Manager_Aggregate = {
  __typename?: 'dashboard_billable_manager_aggregate';
  aggregate?: Maybe<Dashboard_Billable_Manager_Aggregate_Fields>;
  nodes: Array<Dashboard_Billable_Manager>;
};

/** aggregate fields of "dashboard_billable_manager" */
export type Dashboard_Billable_Manager_Aggregate_Fields = {
  __typename?: 'dashboard_billable_manager_aggregate_fields';
  avg?: Maybe<Dashboard_Billable_Manager_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Billable_Manager_Max_Fields>;
  min?: Maybe<Dashboard_Billable_Manager_Min_Fields>;
  stddev?: Maybe<Dashboard_Billable_Manager_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Billable_Manager_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Billable_Manager_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Billable_Manager_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Billable_Manager_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Billable_Manager_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Billable_Manager_Variance_Fields>;
};

/** aggregate fields of "dashboard_billable_manager" */
export type Dashboard_Billable_Manager_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Billable_Manager_Avg_Fields = {
  __typename?: 'dashboard_billable_manager_avg_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_billable_manager". All fields are combined with a logical 'AND'. */
export type Dashboard_Billable_Manager_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Billable_Manager_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Billable_Manager_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_client?: InputMaybe<Project_Client_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  work_hours?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Billable_Manager_Max_Fields = {
  __typename?: 'dashboard_billable_manager_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Dashboard_Billable_Manager_Min_Fields = {
  __typename?: 'dashboard_billable_manager_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "dashboard_billable_manager". */
export type Dashboard_Billable_Manager_Order_By = {
  client_id?: InputMaybe<Order_By>;
  project_client?: InputMaybe<Project_Client_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_billable_manager" */
export enum Dashboard_Billable_Manager_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkHours = 'work_hours',
}

/** aggregate stddev on columns */
export type Dashboard_Billable_Manager_Stddev_Fields = {
  __typename?: 'dashboard_billable_manager_stddev_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Billable_Manager_Stddev_Pop_Fields = {
  __typename?: 'dashboard_billable_manager_stddev_pop_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Billable_Manager_Stddev_Samp_Fields = {
  __typename?: 'dashboard_billable_manager_stddev_samp_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_billable_manager" */
export type Dashboard_Billable_Manager_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Billable_Manager_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Billable_Manager_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  work_hours?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Dashboard_Billable_Manager_Sum_Fields = {
  __typename?: 'dashboard_billable_manager_sum_fields';
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Billable_Manager_Var_Pop_Fields = {
  __typename?: 'dashboard_billable_manager_var_pop_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Billable_Manager_Var_Samp_Fields = {
  __typename?: 'dashboard_billable_manager_var_samp_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Billable_Manager_Variance_Fields = {
  __typename?: 'dashboard_billable_manager_variance_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Dashboard_Billable_Max_Fields = {
  __typename?: 'dashboard_billable_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  client_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  work_date?: Maybe<Scalars['date']>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Dashboard_Billable_Min_Fields = {
  __typename?: 'dashboard_billable_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  client_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  work_date?: Maybe<Scalars['date']>;
  work_hours?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "dashboard_billable". */
export type Dashboard_Billable_Order_By = {
  client_id?: InputMaybe<Order_By>;
  client_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  work_date?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_billable" */
export enum Dashboard_Billable_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ClientName = 'client_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Title = 'title',
  /** column name */
  WorkDate = 'work_date',
  /** column name */
  WorkHours = 'work_hours',
}

/** aggregate stddev on columns */
export type Dashboard_Billable_Stddev_Fields = {
  __typename?: 'dashboard_billable_stddev_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Billable_Stddev_Pop_Fields = {
  __typename?: 'dashboard_billable_stddev_pop_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Billable_Stddev_Samp_Fields = {
  __typename?: 'dashboard_billable_stddev_samp_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_billable" */
export type Dashboard_Billable_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Billable_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Billable_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  client_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  work_date?: InputMaybe<Scalars['date']>;
  work_hours?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Dashboard_Billable_Sum_Fields = {
  __typename?: 'dashboard_billable_sum_fields';
  work_hours?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Billable_Var_Pop_Fields = {
  __typename?: 'dashboard_billable_var_pop_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Billable_Var_Samp_Fields = {
  __typename?: 'dashboard_billable_var_samp_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Billable_Variance_Fields = {
  __typename?: 'dashboard_billable_variance_fields';
  work_hours?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_meeting" */
export type Dashboard_Meeting = {
  __typename?: 'dashboard_meeting';
  event_date?: Maybe<Scalars['date']>;
  event_duration?: Maybe<Scalars['float8']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "dashboard_meeting" */
export type Dashboard_Meeting_Aggregate = {
  __typename?: 'dashboard_meeting_aggregate';
  aggregate?: Maybe<Dashboard_Meeting_Aggregate_Fields>;
  nodes: Array<Dashboard_Meeting>;
};

/** aggregate fields of "dashboard_meeting" */
export type Dashboard_Meeting_Aggregate_Fields = {
  __typename?: 'dashboard_meeting_aggregate_fields';
  avg?: Maybe<Dashboard_Meeting_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Meeting_Max_Fields>;
  min?: Maybe<Dashboard_Meeting_Min_Fields>;
  stddev?: Maybe<Dashboard_Meeting_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Meeting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Meeting_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Meeting_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Meeting_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Meeting_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Meeting_Variance_Fields>;
};

/** aggregate fields of "dashboard_meeting" */
export type Dashboard_Meeting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Meeting_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Meeting_Avg_Fields = {
  __typename?: 'dashboard_meeting_avg_fields';
  event_duration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_meeting". All fields are combined with a logical 'AND'. */
export type Dashboard_Meeting_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Meeting_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Meeting_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Meeting_Bool_Exp>>;
  event_date?: InputMaybe<Date_Comparison_Exp>;
  event_duration?: InputMaybe<Float8_Comparison_Exp>;
  start_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Meeting_Max_Fields = {
  __typename?: 'dashboard_meeting_max_fields';
  event_date?: Maybe<Scalars['date']>;
  event_duration?: Maybe<Scalars['float8']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Dashboard_Meeting_Min_Fields = {
  __typename?: 'dashboard_meeting_min_fields';
  event_date?: Maybe<Scalars['date']>;
  event_duration?: Maybe<Scalars['float8']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "dashboard_meeting". */
export type Dashboard_Meeting_Order_By = {
  event_date?: InputMaybe<Order_By>;
  event_duration?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_meeting" */
export enum Dashboard_Meeting_Select_Column {
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventDuration = 'event_duration',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Dashboard_Meeting_Stddev_Fields = {
  __typename?: 'dashboard_meeting_stddev_fields';
  event_duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Meeting_Stddev_Pop_Fields = {
  __typename?: 'dashboard_meeting_stddev_pop_fields';
  event_duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Meeting_Stddev_Samp_Fields = {
  __typename?: 'dashboard_meeting_stddev_samp_fields';
  event_duration?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_meeting" */
export type Dashboard_Meeting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Meeting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Meeting_Stream_Cursor_Value_Input = {
  event_date?: InputMaybe<Scalars['date']>;
  event_duration?: InputMaybe<Scalars['float8']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Dashboard_Meeting_Sum_Fields = {
  __typename?: 'dashboard_meeting_sum_fields';
  event_duration?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Meeting_Var_Pop_Fields = {
  __typename?: 'dashboard_meeting_var_pop_fields';
  event_duration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Meeting_Var_Samp_Fields = {
  __typename?: 'dashboard_meeting_var_samp_fields';
  event_duration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Meeting_Variance_Fields = {
  __typename?: 'dashboard_meeting_variance_fields';
  event_duration?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_remainders" */
export type Dashboard_Remainders = {
  __typename?: 'dashboard_remainders';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_dashboard_remainder_type?: Maybe<Enum_Dashboard_Remainder_Type>;
  id: Scalars['uuid'];
  is_read: Scalars['Boolean'];
  name: Scalars['String'];
  remainder_type?: Maybe<Enum_Dashboard_Remainder_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "dashboard_remainders" */
export type Dashboard_Remainders_Aggregate = {
  __typename?: 'dashboard_remainders_aggregate';
  aggregate?: Maybe<Dashboard_Remainders_Aggregate_Fields>;
  nodes: Array<Dashboard_Remainders>;
};

export type Dashboard_Remainders_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Dashboard_Remainders_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Dashboard_Remainders_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Dashboard_Remainders_Aggregate_Bool_Exp_Count>;
};

export type Dashboard_Remainders_Aggregate_Bool_Exp_Bool_And = {
  arguments: Dashboard_Remainders_Select_Column_Dashboard_Remainders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dashboard_Remainders_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Dashboard_Remainders_Select_Column_Dashboard_Remainders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dashboard_Remainders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dashboard_Remainders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dashboard_remainders" */
export type Dashboard_Remainders_Aggregate_Fields = {
  __typename?: 'dashboard_remainders_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Remainders_Max_Fields>;
  min?: Maybe<Dashboard_Remainders_Min_Fields>;
};

/** aggregate fields of "dashboard_remainders" */
export type Dashboard_Remainders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Remainders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dashboard_remainders" */
export type Dashboard_Remainders_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dashboard_Remainders_Max_Order_By>;
  min?: InputMaybe<Dashboard_Remainders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "dashboard_remainders" */
export type Dashboard_Remainders_Arr_Rel_Insert_Input = {
  data: Array<Dashboard_Remainders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dashboard_Remainders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "dashboard_remainders". All fields are combined with a logical 'AND'. */
export type Dashboard_Remainders_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Remainders_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Remainders_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_dashboard_remainder_type?: InputMaybe<Enum_Dashboard_Remainder_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_read?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  remainder_type?: InputMaybe<Enum_Dashboard_Remainder_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "dashboard_remainders" */
export enum Dashboard_Remainders_Constraint {
  /** unique or primary key constraint on columns "id" */
  DashboardRemaindersPkey = 'dashboard_remainders_pkey',
}

/** input type for inserting data into table "dashboard_remainders" */
export type Dashboard_Remainders_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_dashboard_remainder_type?: InputMaybe<Enum_Dashboard_Remainder_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  remainder_type?: InputMaybe<Enum_Dashboard_Remainder_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Dashboard_Remainders_Max_Fields = {
  __typename?: 'dashboard_remainders_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "dashboard_remainders" */
export type Dashboard_Remainders_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dashboard_Remainders_Min_Fields = {
  __typename?: 'dashboard_remainders_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "dashboard_remainders" */
export type Dashboard_Remainders_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dashboard_remainders" */
export type Dashboard_Remainders_Mutation_Response = {
  __typename?: 'dashboard_remainders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dashboard_Remainders>;
};

/** on_conflict condition type for table "dashboard_remainders" */
export type Dashboard_Remainders_On_Conflict = {
  constraint: Dashboard_Remainders_Constraint;
  update_columns?: Array<Dashboard_Remainders_Update_Column>;
  where?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
};

/** Ordering options when selecting data from "dashboard_remainders". */
export type Dashboard_Remainders_Order_By = {
  created_at?: InputMaybe<Order_By>;
  enum_dashboard_remainder_type?: InputMaybe<Enum_Dashboard_Remainder_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  is_read?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remainder_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dashboard_remainders */
export type Dashboard_Remainders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "dashboard_remainders" */
export enum Dashboard_Remainders_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  Name = 'name',
  /** column name */
  RemainderType = 'remainder_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "dashboard_remainders_aggregate_bool_exp_bool_and_arguments_columns" columns of table "dashboard_remainders" */
export enum Dashboard_Remainders_Select_Column_Dashboard_Remainders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRead = 'is_read',
}

/** select "dashboard_remainders_aggregate_bool_exp_bool_or_arguments_columns" columns of table "dashboard_remainders" */
export enum Dashboard_Remainders_Select_Column_Dashboard_Remainders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRead = 'is_read',
}

/** input type for updating data in table "dashboard_remainders" */
export type Dashboard_Remainders_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  remainder_type?: InputMaybe<Enum_Dashboard_Remainder_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "dashboard_remainders" */
export type Dashboard_Remainders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Remainders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Remainders_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  remainder_type?: InputMaybe<Enum_Dashboard_Remainder_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "dashboard_remainders" */
export enum Dashboard_Remainders_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  Name = 'name',
  /** column name */
  RemainderType = 'remainder_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Dashboard_Remainders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dashboard_Remainders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dashboard_Remainders_Bool_Exp;
};

export type DateData = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export type DeleteCalendarEventInput = {
  calendar_event_id: Scalars['uuid'];
  event_delete_date?: InputMaybe<Scalars['String']>;
  mode_type: Scalars['String'];
};

export type DeleteCalendarEventOutput = {
  __typename?: 'deleteCalendarEventOutput';
  status: Scalars['String'];
};

export type DeleteProjectOutput = {
  __typename?: 'deleteProjectOutput';
  status: Scalars['String'];
};

/** Departments in an organization  */
export type Department = {
  __typename?: 'department';
  /** An array relationship */
  assessments: Array<Assessment>;
  /** An aggregate relationship */
  assessments_aggregate: Assessment_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An array relationship */
  department_employees: Array<Department_Employee>;
  /** An aggregate relationship */
  department_employees_aggregate: Department_Employee_Aggregate;
  /** An array relationship */
  goalsWithDepts: Array<GoalsWithDepts>;
  /** An aggregate relationship */
  goalsWithDepts_aggregate: GoalsWithDepts_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  kpiWithRolesAndDepts: Array<KpiWithDept>;
  /** An aggregate relationship */
  kpiWithRolesAndDepts_aggregate: KpiWithDept_Aggregate;
  manager_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  manager_user?: Maybe<Users>;
  name: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An array relationship */
  performanceReviewStatuses: Array<PerformanceReviewStatus>;
  /** An aggregate relationship */
  performanceReviewStatuses_aggregate: PerformanceReviewStatus_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** Departments in an organization  */
export type DepartmentAssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentAssessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentDepartment_EmployeesArgs = {
  distinct_on?: InputMaybe<Array<Department_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Employee_Order_By>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentDepartment_Employees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Employee_Order_By>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentGoalsWithDeptsArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithDepts_Order_By>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentGoalsWithDepts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithDepts_Order_By>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentKpiWithRolesAndDeptsArgs = {
  distinct_on?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithDept_Order_By>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentKpiWithRolesAndDepts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithDept_Order_By>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentPerformanceReviewStatusesArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** Departments in an organization  */
export type DepartmentPerformanceReviewStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** aggregated selection of "department" */
export type Department_Aggregate = {
  __typename?: 'department_aggregate';
  aggregate?: Maybe<Department_Aggregate_Fields>;
  nodes: Array<Department>;
};

export type Department_Aggregate_Bool_Exp = {
  count?: InputMaybe<Department_Aggregate_Bool_Exp_Count>;
};

export type Department_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Department_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Department_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "department" */
export type Department_Aggregate_Fields = {
  __typename?: 'department_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Department_Max_Fields>;
  min?: Maybe<Department_Min_Fields>;
};

/** aggregate fields of "department" */
export type Department_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Department_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "department" */
export type Department_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Department_Max_Order_By>;
  min?: InputMaybe<Department_Min_Order_By>;
};

/** input type for inserting array relation for remote table "department" */
export type Department_Arr_Rel_Insert_Input = {
  data: Array<Department_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** Boolean expression to filter rows from the table "department". All fields are combined with a logical 'AND'. */
export type Department_Bool_Exp = {
  _and?: InputMaybe<Array<Department_Bool_Exp>>;
  _not?: InputMaybe<Department_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Bool_Exp>>;
  assessments?: InputMaybe<Assessment_Bool_Exp>;
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  department_employees?: InputMaybe<Department_Employee_Bool_Exp>;
  department_employees_aggregate?: InputMaybe<Department_Employee_Aggregate_Bool_Exp>;
  goalsWithDepts?: InputMaybe<GoalsWithDepts_Bool_Exp>;
  goalsWithDepts_aggregate?: InputMaybe<GoalsWithDepts_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpiWithRolesAndDepts?: InputMaybe<KpiWithDept_Bool_Exp>;
  kpiWithRolesAndDepts_aggregate?: InputMaybe<KpiWithDept_Aggregate_Bool_Exp>;
  manager_id?: InputMaybe<Uuid_Comparison_Exp>;
  manager_user?: InputMaybe<Users_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "department" */
export enum Department_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  DepartmentNameOrganizationIdKey = 'department_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  DepartmentPkey = 'department_pkey',
}

/** Employees (user) in a department */
export type Department_Employee = {
  __typename?: 'department_employee';
  /** An object relationship */
  department: Department;
  department_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "department_employee" */
export type Department_Employee_Aggregate = {
  __typename?: 'department_employee_aggregate';
  aggregate?: Maybe<Department_Employee_Aggregate_Fields>;
  nodes: Array<Department_Employee>;
};

export type Department_Employee_Aggregate_Bool_Exp = {
  count?: InputMaybe<Department_Employee_Aggregate_Bool_Exp_Count>;
};

export type Department_Employee_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Department_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Department_Employee_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "department_employee" */
export type Department_Employee_Aggregate_Fields = {
  __typename?: 'department_employee_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Department_Employee_Max_Fields>;
  min?: Maybe<Department_Employee_Min_Fields>;
};

/** aggregate fields of "department_employee" */
export type Department_Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Department_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "department_employee" */
export type Department_Employee_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Department_Employee_Max_Order_By>;
  min?: InputMaybe<Department_Employee_Min_Order_By>;
};

/** input type for inserting array relation for remote table "department_employee" */
export type Department_Employee_Arr_Rel_Insert_Input = {
  data: Array<Department_Employee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_Employee_On_Conflict>;
};

/** Boolean expression to filter rows from the table "department_employee". All fields are combined with a logical 'AND'. */
export type Department_Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Department_Employee_Bool_Exp>>;
  _not?: InputMaybe<Department_Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Employee_Bool_Exp>>;
  department?: InputMaybe<Department_Bool_Exp>;
  department_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "department_employee" */
export enum Department_Employee_Constraint {
  /** unique or primary key constraint on columns "department_id", "user_id" */
  DepartmentEmployeePkey = 'department_employee_pkey',
}

/** input type for inserting data into table "department_employee" */
export type Department_Employee_Insert_Input = {
  department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  department_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Department_Employee_Max_Fields = {
  __typename?: 'department_employee_max_fields';
  department_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "department_employee" */
export type Department_Employee_Max_Order_By = {
  department_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Department_Employee_Min_Fields = {
  __typename?: 'department_employee_min_fields';
  department_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "department_employee" */
export type Department_Employee_Min_Order_By = {
  department_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "department_employee" */
export type Department_Employee_Mutation_Response = {
  __typename?: 'department_employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Department_Employee>;
};

/** on_conflict condition type for table "department_employee" */
export type Department_Employee_On_Conflict = {
  constraint: Department_Employee_Constraint;
  update_columns?: Array<Department_Employee_Update_Column>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "department_employee". */
export type Department_Employee_Order_By = {
  department?: InputMaybe<Department_Order_By>;
  department_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: department_employee */
export type Department_Employee_Pk_Columns_Input = {
  department_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "department_employee" */
export enum Department_Employee_Select_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "department_employee" */
export type Department_Employee_Set_Input = {
  department_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "department_employee" */
export type Department_Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Department_Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Department_Employee_Stream_Cursor_Value_Input = {
  department_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "department_employee" */
export enum Department_Employee_Update_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  UserId = 'user_id',
}

export type Department_Employee_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Department_Employee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Department_Employee_Bool_Exp;
};

/** input type for inserting data into table "department" */
export type Department_Insert_Input = {
  assessments?: InputMaybe<Assessment_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  department_employees?: InputMaybe<Department_Employee_Arr_Rel_Insert_Input>;
  goalsWithDepts?: InputMaybe<GoalsWithDepts_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  kpiWithRolesAndDepts?: InputMaybe<KpiWithDept_Arr_Rel_Insert_Input>;
  manager_id?: InputMaybe<Scalars['uuid']>;
  manager_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Department_Max_Fields = {
  __typename?: 'department_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  manager_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "department" */
export type Department_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Department_Min_Fields = {
  __typename?: 'department_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  manager_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "department" */
export type Department_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "department" */
export type Department_Mutation_Response = {
  __typename?: 'department_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Department>;
};

/** input type for inserting object relation for remote table "department" */
export type Department_Obj_Rel_Insert_Input = {
  data: Department_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** on_conflict condition type for table "department" */
export type Department_On_Conflict = {
  constraint: Department_Constraint;
  update_columns?: Array<Department_Update_Column>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** Ordering options when selecting data from "department". */
export type Department_Order_By = {
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  department_employees_aggregate?: InputMaybe<Department_Employee_Aggregate_Order_By>;
  goalsWithDepts_aggregate?: InputMaybe<GoalsWithDepts_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  kpiWithRolesAndDepts_aggregate?: InputMaybe<KpiWithDept_Aggregate_Order_By>;
  manager_id?: InputMaybe<Order_By>;
  manager_user?: InputMaybe<Users_Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: department */
export type Department_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "department" */
export enum Department_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'manager_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "department" */
export type Department_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  manager_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "department" */
export type Department_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Department_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Department_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  manager_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "department" */
export enum Department_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'manager_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Department_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Department_Set_Input>;
  /** filter the rows which have to be updated */
  where: Department_Bool_Exp;
};

/** Documents oauth tokens */
export type Document_Token = {
  __typename?: 'document_token';
  access_token: Scalars['String'];
  /** An object relationship */
  enum_document_token_type: Enum_Document_Token_Type;
  refresh_token?: Maybe<Scalars['String']>;
  token_type: Enum_Document_Token_Type_Enum;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "document_token" */
export type Document_Token_Aggregate = {
  __typename?: 'document_token_aggregate';
  aggregate?: Maybe<Document_Token_Aggregate_Fields>;
  nodes: Array<Document_Token>;
};

export type Document_Token_Aggregate_Bool_Exp = {
  count?: InputMaybe<Document_Token_Aggregate_Bool_Exp_Count>;
};

export type Document_Token_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Token_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "document_token" */
export type Document_Token_Aggregate_Fields = {
  __typename?: 'document_token_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Document_Token_Max_Fields>;
  min?: Maybe<Document_Token_Min_Fields>;
};

/** aggregate fields of "document_token" */
export type Document_Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "document_token" */
export type Document_Token_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Token_Max_Order_By>;
  min?: InputMaybe<Document_Token_Min_Order_By>;
};

/** input type for inserting array relation for remote table "document_token" */
export type Document_Token_Arr_Rel_Insert_Input = {
  data: Array<Document_Token_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Token_On_Conflict>;
};

/** Boolean expression to filter rows from the table "document_token". All fields are combined with a logical 'AND'. */
export type Document_Token_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Token_Bool_Exp>>;
  _not?: InputMaybe<Document_Token_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Token_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  enum_document_token_type?: InputMaybe<Enum_Document_Token_Type_Bool_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  token_type?: InputMaybe<Enum_Document_Token_Type_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "document_token" */
export enum Document_Token_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  DocumentTokenPkey = 'document_token_pkey',
}

/** input type for inserting data into table "document_token" */
export type Document_Token_Insert_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  enum_document_token_type?: InputMaybe<Enum_Document_Token_Type_Obj_Rel_Insert_Input>;
  refresh_token?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Enum_Document_Token_Type_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Document_Token_Max_Fields = {
  __typename?: 'document_token_max_fields';
  access_token?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "document_token" */
export type Document_Token_Max_Order_By = {
  access_token?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Document_Token_Min_Fields = {
  __typename?: 'document_token_min_fields';
  access_token?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "document_token" */
export type Document_Token_Min_Order_By = {
  access_token?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "document_token" */
export type Document_Token_Mutation_Response = {
  __typename?: 'document_token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Token>;
};

/** on_conflict condition type for table "document_token" */
export type Document_Token_On_Conflict = {
  constraint: Document_Token_Constraint;
  update_columns?: Array<Document_Token_Update_Column>;
  where?: InputMaybe<Document_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "document_token". */
export type Document_Token_Order_By = {
  access_token?: InputMaybe<Order_By>;
  enum_document_token_type?: InputMaybe<Enum_Document_Token_Type_Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  token_type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_token */
export type Document_Token_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "document_token" */
export enum Document_Token_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  TokenType = 'token_type',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "document_token" */
export type Document_Token_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Enum_Document_Token_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "document_token" */
export type Document_Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Token_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Token_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Enum_Document_Token_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "document_token" */
export enum Document_Token_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  TokenType = 'token_type',
  /** column name */
  UserId = 'user_id',
}

export type Document_Token_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Token_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Token_Bool_Exp;
};

export type EditCommentInput = {
  comment_id?: InputMaybe<Scalars['String']>;
  delete?: InputMaybe<Scalars['Boolean']>;
  reply_id?: InputMaybe<Scalars['String']>;
};

export type EditCommentOutput = {
  __typename?: 'editCommentOutput';
  comment_id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  post_id?: Maybe<Scalars['String']>;
};

export type EditPostInput = {
  delete?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Scalars['String']>;
};

export type EditPostOutput = {
  __typename?: 'editPostOutput';
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  post_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "email_templates" */
export type Email_Templates = {
  __typename?: 'email_templates';
  body_content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  sample_data?: Maybe<Scalars['jsonb']>;
  subject_content: Scalars['String'];
  template_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "email_templates" */
export type Email_TemplatesSample_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "email_templates" */
export type Email_Templates_Aggregate = {
  __typename?: 'email_templates_aggregate';
  aggregate?: Maybe<Email_Templates_Aggregate_Fields>;
  nodes: Array<Email_Templates>;
};

/** aggregate fields of "email_templates" */
export type Email_Templates_Aggregate_Fields = {
  __typename?: 'email_templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Email_Templates_Max_Fields>;
  min?: Maybe<Email_Templates_Min_Fields>;
};

/** aggregate fields of "email_templates" */
export type Email_Templates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Templates_Append_Input = {
  sample_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "email_templates". All fields are combined with a logical 'AND'. */
export type Email_Templates_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Templates_Bool_Exp>>;
  _not?: InputMaybe<Email_Templates_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Templates_Bool_Exp>>;
  body_content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sample_data?: InputMaybe<Jsonb_Comparison_Exp>;
  subject_content?: InputMaybe<String_Comparison_Exp>;
  template_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_templates" */
export enum Email_Templates_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailTemplatesPkey = 'email_templates_pkey',
  /** unique or primary key constraint on columns "template_name" */
  EmailTemplatesTemplateNameKey = 'email_templates_template_name_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Templates_Delete_At_Path_Input = {
  sample_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Templates_Delete_Elem_Input = {
  sample_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Templates_Delete_Key_Input = {
  sample_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "email_templates" */
export type Email_Templates_Insert_Input = {
  body_content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sample_data?: InputMaybe<Scalars['jsonb']>;
  subject_content?: InputMaybe<Scalars['String']>;
  template_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Templates_Max_Fields = {
  __typename?: 'email_templates_max_fields';
  body_content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  subject_content?: Maybe<Scalars['String']>;
  template_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Templates_Min_Fields = {
  __typename?: 'email_templates_min_fields';
  body_content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  subject_content?: Maybe<Scalars['String']>;
  template_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email_templates" */
export type Email_Templates_Mutation_Response = {
  __typename?: 'email_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Templates>;
};

/** on_conflict condition type for table "email_templates" */
export type Email_Templates_On_Conflict = {
  constraint: Email_Templates_Constraint;
  update_columns?: Array<Email_Templates_Update_Column>;
  where?: InputMaybe<Email_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "email_templates". */
export type Email_Templates_Order_By = {
  body_content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sample_data?: InputMaybe<Order_By>;
  subject_content?: InputMaybe<Order_By>;
  template_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_templates */
export type Email_Templates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Templates_Prepend_Input = {
  sample_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "email_templates" */
export enum Email_Templates_Select_Column {
  /** column name */
  BodyContent = 'body_content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SampleData = 'sample_data',
  /** column name */
  SubjectContent = 'subject_content',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "email_templates" */
export type Email_Templates_Set_Input = {
  body_content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sample_data?: InputMaybe<Scalars['jsonb']>;
  subject_content?: InputMaybe<Scalars['String']>;
  template_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "email_templates" */
export type Email_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Templates_Stream_Cursor_Value_Input = {
  body_content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sample_data?: InputMaybe<Scalars['jsonb']>;
  subject_content?: InputMaybe<Scalars['String']>;
  template_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "email_templates" */
export enum Email_Templates_Update_Column {
  /** column name */
  BodyContent = 'body_content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SampleData = 'sample_data',
  /** column name */
  SubjectContent = 'subject_content',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Email_Templates_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Email_Templates_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Email_Templates_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Email_Templates_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Email_Templates_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Email_Templates_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Templates_Bool_Exp;
};

export type EmpScoreAssessmentData = {
  __typename?: 'empScoreAssessmentData';
  displayName?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  userId?: Maybe<Scalars['uuid']>;
  userProfile?: Maybe<Scalars['String']>;
};

export type EmployeeAssessmentScoreInput = {
  assessmentType?: InputMaybe<Scalars['String']>;
  assessmentTypeId?: InputMaybe<Scalars['uuid']>;
  userId: Array<InputMaybe<Scalars['uuid']>>;
};

export type EmployeeAssessmentScoreOuput = {
  __typename?: 'employeeAssessmentScoreOuput';
  response: Array<Maybe<EmpScoreAssessmentData>>;
};

export type EmployeeBandwidthData = {
  organization_id: Scalars['uuid'];
  taskId: Scalars['uuid'];
};

export type EmployeeBandwidthInput = {
  data?: InputMaybe<EmployeeBandwidthData>;
};

export type EmployeeBandwidthOutput = {
  __typename?: 'employeeBandwidthOutput';
  id: Scalars['uuid'];
};

export type EmployeeCapacityData = {
  __typename?: 'employeeCapacityData';
  displayName?: Maybe<Scalars['String']>;
  employeeCapacity?: Maybe<Array<Maybe<CapacityData>>>;
};

/** columns and relationships of "employee_bandwidth_data" */
export type Employee_Bandwidth_Data = {
  __typename?: 'employee_bandwidth_data';
  estimated_hours: Scalars['float8'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  work_day: Scalars['date'];
};

/** aggregated selection of "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Aggregate = {
  __typename?: 'employee_bandwidth_data_aggregate';
  aggregate?: Maybe<Employee_Bandwidth_Data_Aggregate_Fields>;
  nodes: Array<Employee_Bandwidth_Data>;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp_Var_Samp>;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Avg = {
  arguments: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Corr = {
  arguments: Employee_Bandwidth_Data_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Employee_Bandwidth_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Max = {
  arguments: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Min = {
  arguments: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Sum = {
  arguments: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Bandwidth_Data_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Aggregate_Fields = {
  __typename?: 'employee_bandwidth_data_aggregate_fields';
  avg?: Maybe<Employee_Bandwidth_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Bandwidth_Data_Max_Fields>;
  min?: Maybe<Employee_Bandwidth_Data_Min_Fields>;
  stddev?: Maybe<Employee_Bandwidth_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Bandwidth_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Bandwidth_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Bandwidth_Data_Sum_Fields>;
  var_pop?: Maybe<Employee_Bandwidth_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Bandwidth_Data_Var_Samp_Fields>;
  variance?: Maybe<Employee_Bandwidth_Data_Variance_Fields>;
};

/** aggregate fields of "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Bandwidth_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Bandwidth_Data_Max_Order_By>;
  min?: InputMaybe<Employee_Bandwidth_Data_Min_Order_By>;
  stddev?: InputMaybe<Employee_Bandwidth_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Bandwidth_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Bandwidth_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Bandwidth_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Bandwidth_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Bandwidth_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Bandwidth_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Arr_Rel_Insert_Input = {
  data: Array<Employee_Bandwidth_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employee_Bandwidth_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Employee_Bandwidth_Data_Avg_Fields = {
  __typename?: 'employee_bandwidth_data_avg_fields';
  estimated_hours?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Avg_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employee_bandwidth_data". All fields are combined with a logical 'AND'. */
export type Employee_Bandwidth_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Bandwidth_Data_Bool_Exp>>;
  _not?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Bandwidth_Data_Bool_Exp>>;
  estimated_hours?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  work_day?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmployeeBandwidthDataPkey = 'employee_bandwidth_data_pkey',
}

/** input type for incrementing numeric columns in table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Inc_Input = {
  estimated_hours?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Insert_Input = {
  estimated_hours?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  work_day?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Employee_Bandwidth_Data_Max_Fields = {
  __typename?: 'employee_bandwidth_data_max_fields';
  estimated_hours?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  work_day?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Max_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  work_day?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employee_Bandwidth_Data_Min_Fields = {
  __typename?: 'employee_bandwidth_data_min_fields';
  estimated_hours?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  work_day?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Min_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  work_day?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Mutation_Response = {
  __typename?: 'employee_bandwidth_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employee_Bandwidth_Data>;
};

/** on_conflict condition type for table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_On_Conflict = {
  constraint: Employee_Bandwidth_Data_Constraint;
  update_columns?: Array<Employee_Bandwidth_Data_Update_Column>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "employee_bandwidth_data". */
export type Employee_Bandwidth_Data_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  work_day?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employee_bandwidth_data */
export type Employee_Bandwidth_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column {
  /** column name */
  EstimatedHours = 'estimated_hours',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkDay = 'work_day',
}

/** select "employee_bandwidth_data_aggregate_bool_exp_avg_arguments_columns" columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  EstimatedHours = 'estimated_hours',
}

/** select "employee_bandwidth_data_aggregate_bool_exp_corr_arguments_columns" columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  EstimatedHours = 'estimated_hours',
}

/** select "employee_bandwidth_data_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  EstimatedHours = 'estimated_hours',
}

/** select "employee_bandwidth_data_aggregate_bool_exp_max_arguments_columns" columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  EstimatedHours = 'estimated_hours',
}

/** select "employee_bandwidth_data_aggregate_bool_exp_min_arguments_columns" columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  EstimatedHours = 'estimated_hours',
}

/** select "employee_bandwidth_data_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  EstimatedHours = 'estimated_hours',
}

/** select "employee_bandwidth_data_aggregate_bool_exp_sum_arguments_columns" columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  EstimatedHours = 'estimated_hours',
}

/** select "employee_bandwidth_data_aggregate_bool_exp_var_samp_arguments_columns" columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Select_Column_Employee_Bandwidth_Data_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  EstimatedHours = 'estimated_hours',
}

/** input type for updating data in table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Set_Input = {
  estimated_hours?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  work_day?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type Employee_Bandwidth_Data_Stddev_Fields = {
  __typename?: 'employee_bandwidth_data_stddev_fields';
  estimated_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Stddev_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employee_Bandwidth_Data_Stddev_Pop_Fields = {
  __typename?: 'employee_bandwidth_data_stddev_pop_fields';
  estimated_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Stddev_Pop_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employee_Bandwidth_Data_Stddev_Samp_Fields = {
  __typename?: 'employee_bandwidth_data_stddev_samp_fields';
  estimated_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Stddev_Samp_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Bandwidth_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Bandwidth_Data_Stream_Cursor_Value_Input = {
  estimated_hours?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  work_day?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Employee_Bandwidth_Data_Sum_Fields = {
  __typename?: 'employee_bandwidth_data_sum_fields';
  estimated_hours?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Sum_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
};

/** update columns of table "employee_bandwidth_data" */
export enum Employee_Bandwidth_Data_Update_Column {
  /** column name */
  EstimatedHours = 'estimated_hours',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkDay = 'work_day',
}

export type Employee_Bandwidth_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employee_Bandwidth_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employee_Bandwidth_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employee_Bandwidth_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employee_Bandwidth_Data_Var_Pop_Fields = {
  __typename?: 'employee_bandwidth_data_var_pop_fields';
  estimated_hours?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Var_Pop_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employee_Bandwidth_Data_Var_Samp_Fields = {
  __typename?: 'employee_bandwidth_data_var_samp_fields';
  estimated_hours?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Var_Samp_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employee_Bandwidth_Data_Variance_Fields = {
  __typename?: 'employee_bandwidth_data_variance_fields';
  estimated_hours?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employee_bandwidth_data" */
export type Employee_Bandwidth_Data_Variance_Order_By = {
  estimated_hours?: InputMaybe<Order_By>;
};

/** This table store task load on every employee for current month and upcoming month */
export type Employee_Capacity = {
  __typename?: 'employee_capacity';
  date: Scalars['date'];
  display_name: Scalars['String'];
  employee_id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  workload_hours: Scalars['float8'];
};

/** aggregated selection of "employee_capacity" */
export type Employee_Capacity_Aggregate = {
  __typename?: 'employee_capacity_aggregate';
  aggregate?: Maybe<Employee_Capacity_Aggregate_Fields>;
  nodes: Array<Employee_Capacity>;
};

export type Employee_Capacity_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp_Var_Samp>;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Avg = {
  arguments: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Corr = {
  arguments: Employee_Capacity_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Employee_Capacity_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Max = {
  arguments: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Min = {
  arguments: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Sum = {
  arguments: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Employee_Capacity_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Capacity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "employee_capacity" */
export type Employee_Capacity_Aggregate_Fields = {
  __typename?: 'employee_capacity_aggregate_fields';
  avg?: Maybe<Employee_Capacity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Capacity_Max_Fields>;
  min?: Maybe<Employee_Capacity_Min_Fields>;
  stddev?: Maybe<Employee_Capacity_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Capacity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Capacity_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Capacity_Sum_Fields>;
  var_pop?: Maybe<Employee_Capacity_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Capacity_Var_Samp_Fields>;
  variance?: Maybe<Employee_Capacity_Variance_Fields>;
};

/** aggregate fields of "employee_capacity" */
export type Employee_Capacity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_capacity" */
export type Employee_Capacity_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Capacity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Capacity_Max_Order_By>;
  min?: InputMaybe<Employee_Capacity_Min_Order_By>;
  stddev?: InputMaybe<Employee_Capacity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Capacity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Capacity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Capacity_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Capacity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Capacity_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Capacity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employee_capacity" */
export type Employee_Capacity_Arr_Rel_Insert_Input = {
  data: Array<Employee_Capacity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employee_Capacity_On_Conflict>;
};

/** aggregate avg on columns */
export type Employee_Capacity_Avg_Fields = {
  __typename?: 'employee_capacity_avg_fields';
  workload_hours?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employee_capacity" */
export type Employee_Capacity_Avg_Order_By = {
  workload_hours?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employee_capacity". All fields are combined with a logical 'AND'. */
export type Employee_Capacity_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Capacity_Bool_Exp>>;
  _not?: InputMaybe<Employee_Capacity_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Capacity_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  workload_hours?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "employee_capacity" */
export enum Employee_Capacity_Constraint {
  /** unique or primary key constraint on columns "employee_id", "date" */
  EmployeeCapacityPkey = 'employee_capacity_pkey',
}

/** input type for incrementing numeric columns in table "employee_capacity" */
export type Employee_Capacity_Inc_Input = {
  workload_hours?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "employee_capacity" */
export type Employee_Capacity_Insert_Input = {
  date?: InputMaybe<Scalars['date']>;
  display_name?: InputMaybe<Scalars['String']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  workload_hours?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Employee_Capacity_Max_Fields = {
  __typename?: 'employee_capacity_max_fields';
  date?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  workload_hours?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "employee_capacity" */
export type Employee_Capacity_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  workload_hours?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employee_Capacity_Min_Fields = {
  __typename?: 'employee_capacity_min_fields';
  date?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  workload_hours?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "employee_capacity" */
export type Employee_Capacity_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  workload_hours?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employee_capacity" */
export type Employee_Capacity_Mutation_Response = {
  __typename?: 'employee_capacity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employee_Capacity>;
};

/** on_conflict condition type for table "employee_capacity" */
export type Employee_Capacity_On_Conflict = {
  constraint: Employee_Capacity_Constraint;
  update_columns?: Array<Employee_Capacity_Update_Column>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

/** Ordering options when selecting data from "employee_capacity". */
export type Employee_Capacity_Order_By = {
  date?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  workload_hours?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employee_capacity */
export type Employee_Capacity_Pk_Columns_Input = {
  date: Scalars['date'];
  employee_id: Scalars['uuid'];
};

/** select columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** select "employee_capacity_aggregate_bool_exp_avg_arguments_columns" columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** select "employee_capacity_aggregate_bool_exp_corr_arguments_columns" columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** select "employee_capacity_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** select "employee_capacity_aggregate_bool_exp_max_arguments_columns" columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** select "employee_capacity_aggregate_bool_exp_min_arguments_columns" columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** select "employee_capacity_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** select "employee_capacity_aggregate_bool_exp_sum_arguments_columns" columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** select "employee_capacity_aggregate_bool_exp_var_samp_arguments_columns" columns of table "employee_capacity" */
export enum Employee_Capacity_Select_Column_Employee_Capacity_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  WorkloadHours = 'workload_hours',
}

/** input type for updating data in table "employee_capacity" */
export type Employee_Capacity_Set_Input = {
  date?: InputMaybe<Scalars['date']>;
  display_name?: InputMaybe<Scalars['String']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  workload_hours?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Employee_Capacity_Stddev_Fields = {
  __typename?: 'employee_capacity_stddev_fields';
  workload_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employee_capacity" */
export type Employee_Capacity_Stddev_Order_By = {
  workload_hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employee_Capacity_Stddev_Pop_Fields = {
  __typename?: 'employee_capacity_stddev_pop_fields';
  workload_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employee_capacity" */
export type Employee_Capacity_Stddev_Pop_Order_By = {
  workload_hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employee_Capacity_Stddev_Samp_Fields = {
  __typename?: 'employee_capacity_stddev_samp_fields';
  workload_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employee_capacity" */
export type Employee_Capacity_Stddev_Samp_Order_By = {
  workload_hours?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employee_capacity" */
export type Employee_Capacity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Capacity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Capacity_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  display_name?: InputMaybe<Scalars['String']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  workload_hours?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Employee_Capacity_Sum_Fields = {
  __typename?: 'employee_capacity_sum_fields';
  workload_hours?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "employee_capacity" */
export type Employee_Capacity_Sum_Order_By = {
  workload_hours?: InputMaybe<Order_By>;
};

/** update columns of table "employee_capacity" */
export enum Employee_Capacity_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  WorkloadHours = 'workload_hours',
}

export type Employee_Capacity_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employee_Capacity_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employee_Capacity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employee_Capacity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employee_Capacity_Var_Pop_Fields = {
  __typename?: 'employee_capacity_var_pop_fields';
  workload_hours?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employee_capacity" */
export type Employee_Capacity_Var_Pop_Order_By = {
  workload_hours?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employee_Capacity_Var_Samp_Fields = {
  __typename?: 'employee_capacity_var_samp_fields';
  workload_hours?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employee_capacity" */
export type Employee_Capacity_Var_Samp_Order_By = {
  workload_hours?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employee_Capacity_Variance_Fields = {
  __typename?: 'employee_capacity_variance_fields';
  workload_hours?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employee_capacity" */
export type Employee_Capacity_Variance_Order_By = {
  workload_hours?: InputMaybe<Order_By>;
};

/** columns and relationships of "employee_capacity_view" */
export type Employee_Capacity_View = {
  __typename?: 'employee_capacity_view';
  display_name?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['uuid']>;
  estimate_day?: Maybe<Scalars['timestamptz']>;
  estimated_time?: Maybe<Scalars['float8']>;
  /** An object relationship */
  organization_user?: Maybe<Organization_User>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "employee_capacity_view" */
export type Employee_Capacity_View_Aggregate = {
  __typename?: 'employee_capacity_view_aggregate';
  aggregate?: Maybe<Employee_Capacity_View_Aggregate_Fields>;
  nodes: Array<Employee_Capacity_View>;
};

/** aggregate fields of "employee_capacity_view" */
export type Employee_Capacity_View_Aggregate_Fields = {
  __typename?: 'employee_capacity_view_aggregate_fields';
  avg?: Maybe<Employee_Capacity_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Capacity_View_Max_Fields>;
  min?: Maybe<Employee_Capacity_View_Min_Fields>;
  stddev?: Maybe<Employee_Capacity_View_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Capacity_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Capacity_View_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Capacity_View_Sum_Fields>;
  var_pop?: Maybe<Employee_Capacity_View_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Capacity_View_Var_Samp_Fields>;
  variance?: Maybe<Employee_Capacity_View_Variance_Fields>;
};

/** aggregate fields of "employee_capacity_view" */
export type Employee_Capacity_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Capacity_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Employee_Capacity_View_Avg_Fields = {
  __typename?: 'employee_capacity_view_avg_fields';
  estimated_time?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "employee_capacity_view". All fields are combined with a logical 'AND'. */
export type Employee_Capacity_View_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Capacity_View_Bool_Exp>>;
  _not?: InputMaybe<Employee_Capacity_View_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Capacity_View_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  estimate_day?: InputMaybe<Timestamptz_Comparison_Exp>;
  estimated_time?: InputMaybe<Float8_Comparison_Exp>;
  organization_user?: InputMaybe<Organization_User_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** aggregate max on columns */
export type Employee_Capacity_View_Max_Fields = {
  __typename?: 'employee_capacity_view_max_fields';
  display_name?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['uuid']>;
  estimate_day?: Maybe<Scalars['timestamptz']>;
  estimated_time?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Employee_Capacity_View_Min_Fields = {
  __typename?: 'employee_capacity_view_min_fields';
  display_name?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['uuid']>;
  estimate_day?: Maybe<Scalars['timestamptz']>;
  estimated_time?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "employee_capacity_view". */
export type Employee_Capacity_View_Order_By = {
  display_name?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  estimate_day?: InputMaybe<Order_By>;
  estimated_time?: InputMaybe<Order_By>;
  organization_user?: InputMaybe<Organization_User_Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** select columns of table "employee_capacity_view" */
export enum Employee_Capacity_View_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EstimateDay = 'estimate_day',
  /** column name */
  EstimatedTime = 'estimated_time',
}

/** aggregate stddev on columns */
export type Employee_Capacity_View_Stddev_Fields = {
  __typename?: 'employee_capacity_view_stddev_fields';
  estimated_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Employee_Capacity_View_Stddev_Pop_Fields = {
  __typename?: 'employee_capacity_view_stddev_pop_fields';
  estimated_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Employee_Capacity_View_Stddev_Samp_Fields = {
  __typename?: 'employee_capacity_view_stddev_samp_fields';
  estimated_time?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "employee_capacity_view" */
export type Employee_Capacity_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Capacity_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Capacity_View_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  estimate_day?: InputMaybe<Scalars['timestamptz']>;
  estimated_time?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Employee_Capacity_View_Sum_Fields = {
  __typename?: 'employee_capacity_view_sum_fields';
  estimated_time?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Employee_Capacity_View_Var_Pop_Fields = {
  __typename?: 'employee_capacity_view_var_pop_fields';
  estimated_time?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Employee_Capacity_View_Var_Samp_Fields = {
  __typename?: 'employee_capacity_view_var_samp_fields';
  estimated_time?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Employee_Capacity_View_Variance_Fields = {
  __typename?: 'employee_capacity_view_variance_fields';
  estimated_time?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "enum_activity_state" */
export type Enum_Activity_State = {
  __typename?: 'enum_activity_state';
  comment: Scalars['String'];
  /** An array relationship */
  user_states: Array<User_State>;
  /** An aggregate relationship */
  user_states_aggregate: User_State_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_activity_state" */
export type Enum_Activity_StateUser_StatesArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** columns and relationships of "enum_activity_state" */
export type Enum_Activity_StateUser_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** aggregated selection of "enum_activity_state" */
export type Enum_Activity_State_Aggregate = {
  __typename?: 'enum_activity_state_aggregate';
  aggregate?: Maybe<Enum_Activity_State_Aggregate_Fields>;
  nodes: Array<Enum_Activity_State>;
};

/** aggregate fields of "enum_activity_state" */
export type Enum_Activity_State_Aggregate_Fields = {
  __typename?: 'enum_activity_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Activity_State_Max_Fields>;
  min?: Maybe<Enum_Activity_State_Min_Fields>;
};

/** aggregate fields of "enum_activity_state" */
export type Enum_Activity_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Activity_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_activity_state". All fields are combined with a logical 'AND'. */
export type Enum_Activity_State_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Activity_State_Bool_Exp>>;
  _not?: InputMaybe<Enum_Activity_State_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Activity_State_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_states?: InputMaybe<User_State_Bool_Exp>;
  user_states_aggregate?: InputMaybe<User_State_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_activity_state" */
export enum Enum_Activity_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumActivityStatePkey = 'enum_activity_state_pkey',
}

export enum Enum_Activity_State_Enum {
  /** The user is not active on desktop */
  Away = 'AWAY',
  /** The user is not using the system */
  Offline = 'OFFLINE',
  /** The user is active on desktop */
  Online = 'ONLINE',
}

/** Boolean expression to compare columns of type "enum_activity_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Activity_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Activity_State_Enum>;
  _in?: InputMaybe<Array<Enum_Activity_State_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Activity_State_Enum>;
  _nin?: InputMaybe<Array<Enum_Activity_State_Enum>>;
};

/** input type for inserting data into table "enum_activity_state" */
export type Enum_Activity_State_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_states?: InputMaybe<User_State_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Activity_State_Max_Fields = {
  __typename?: 'enum_activity_state_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Activity_State_Min_Fields = {
  __typename?: 'enum_activity_state_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_activity_state" */
export type Enum_Activity_State_Mutation_Response = {
  __typename?: 'enum_activity_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Activity_State>;
};

/** input type for inserting object relation for remote table "enum_activity_state" */
export type Enum_Activity_State_Obj_Rel_Insert_Input = {
  data: Enum_Activity_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Activity_State_On_Conflict>;
};

/** on_conflict condition type for table "enum_activity_state" */
export type Enum_Activity_State_On_Conflict = {
  constraint: Enum_Activity_State_Constraint;
  update_columns?: Array<Enum_Activity_State_Update_Column>;
  where?: InputMaybe<Enum_Activity_State_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_activity_state". */
export type Enum_Activity_State_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_states_aggregate?: InputMaybe<User_State_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_activity_state */
export type Enum_Activity_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_activity_state" */
export enum Enum_Activity_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_activity_state" */
export type Enum_Activity_State_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_activity_state" */
export type Enum_Activity_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Activity_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Activity_State_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_activity_state" */
export enum Enum_Activity_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Activity_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Activity_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Activity_State_Bool_Exp;
};

/** columns and relationships of "enum_activity_type" */
export type Enum_Activity_Type = {
  __typename?: 'enum_activity_type';
  comment: Scalars['String'];
  /** An array relationship */
  task_comments: Array<Task_Comment>;
  /** An aggregate relationship */
  task_comments_aggregate: Task_Comment_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_activity_type" */
export type Enum_Activity_TypeTask_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

/** columns and relationships of "enum_activity_type" */
export type Enum_Activity_TypeTask_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

/** aggregated selection of "enum_activity_type" */
export type Enum_Activity_Type_Aggregate = {
  __typename?: 'enum_activity_type_aggregate';
  aggregate?: Maybe<Enum_Activity_Type_Aggregate_Fields>;
  nodes: Array<Enum_Activity_Type>;
};

/** aggregate fields of "enum_activity_type" */
export type Enum_Activity_Type_Aggregate_Fields = {
  __typename?: 'enum_activity_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Activity_Type_Max_Fields>;
  min?: Maybe<Enum_Activity_Type_Min_Fields>;
};

/** aggregate fields of "enum_activity_type" */
export type Enum_Activity_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Activity_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_activity_type". All fields are combined with a logical 'AND'. */
export type Enum_Activity_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Activity_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Activity_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Activity_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  task_comments?: InputMaybe<Task_Comment_Bool_Exp>;
  task_comments_aggregate?: InputMaybe<Task_Comment_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_activity_type" */
export enum Enum_Activity_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumActivityTypePkey = 'enum_activity_type_pkey',
}

export enum Enum_Activity_Type_Enum {
  /** task activity */
  Activity = 'ACTIVITY',
  /** comment activity */
  Comment = 'COMMENT',
}

/** Boolean expression to compare columns of type "enum_activity_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Activity_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Activity_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Activity_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Activity_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Activity_Type_Enum>>;
};

/** input type for inserting data into table "enum_activity_type" */
export type Enum_Activity_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  task_comments?: InputMaybe<Task_Comment_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Activity_Type_Max_Fields = {
  __typename?: 'enum_activity_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Activity_Type_Min_Fields = {
  __typename?: 'enum_activity_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_activity_type" */
export type Enum_Activity_Type_Mutation_Response = {
  __typename?: 'enum_activity_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Activity_Type>;
};

/** input type for inserting object relation for remote table "enum_activity_type" */
export type Enum_Activity_Type_Obj_Rel_Insert_Input = {
  data: Enum_Activity_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Activity_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_activity_type" */
export type Enum_Activity_Type_On_Conflict = {
  constraint: Enum_Activity_Type_Constraint;
  update_columns?: Array<Enum_Activity_Type_Update_Column>;
  where?: InputMaybe<Enum_Activity_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_activity_type". */
export type Enum_Activity_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  task_comments_aggregate?: InputMaybe<Task_Comment_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_activity_type */
export type Enum_Activity_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_activity_type" */
export enum Enum_Activity_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_activity_type" */
export type Enum_Activity_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_activity_type" */
export type Enum_Activity_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Activity_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Activity_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_activity_type" */
export enum Enum_Activity_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Activity_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Activity_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Activity_Type_Bool_Exp;
};

/** columns and relationships of "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type = {
  __typename?: 'enum_attendee_status_type';
  /** An array relationship */
  calendar_attendees: Array<Calendar_Attendee>;
  /** An aggregate relationship */
  calendar_attendees_aggregate: Calendar_Attendee_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_attendee_status_type" */
export type Enum_Attendee_Status_TypeCalendar_AttendeesArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "enum_attendee_status_type" */
export type Enum_Attendee_Status_TypeCalendar_Attendees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** aggregated selection of "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_Aggregate = {
  __typename?: 'enum_attendee_status_type_aggregate';
  aggregate?: Maybe<Enum_Attendee_Status_Type_Aggregate_Fields>;
  nodes: Array<Enum_Attendee_Status_Type>;
};

/** aggregate fields of "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_Aggregate_Fields = {
  __typename?: 'enum_attendee_status_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Attendee_Status_Type_Max_Fields>;
  min?: Maybe<Enum_Attendee_Status_Type_Min_Fields>;
};

/** aggregate fields of "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Attendee_Status_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_attendee_status_type". All fields are combined with a logical 'AND'. */
export type Enum_Attendee_Status_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Attendee_Status_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Attendee_Status_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Attendee_Status_Type_Bool_Exp>>;
  calendar_attendees?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_attendee_status_type" */
export enum Enum_Attendee_Status_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAttendeeStatusTypePkey = 'enum_attendee_status_type_pkey',
}

export enum Enum_Attendee_Status_Type_Enum {
  /** A user is attending a calendar event. */
  Attending = 'ATTENDING',
  /** A user is not attending a calendar event. */
  NotAttending = 'NOT_ATTENDING',
  /** When user don't add any status for an event. */
  NotSelected = 'NOT_SELECTED',
}

/** Boolean expression to compare columns of type "enum_attendee_status_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Attendee_Status_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Attendee_Status_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Attendee_Status_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Attendee_Status_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Attendee_Status_Type_Enum>>;
};

/** input type for inserting data into table "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_Insert_Input = {
  calendar_attendees?: InputMaybe<Calendar_Attendee_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Attendee_Status_Type_Max_Fields = {
  __typename?: 'enum_attendee_status_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Attendee_Status_Type_Min_Fields = {
  __typename?: 'enum_attendee_status_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_Mutation_Response = {
  __typename?: 'enum_attendee_status_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Attendee_Status_Type>;
};

/** input type for inserting object relation for remote table "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_Obj_Rel_Insert_Input = {
  data: Enum_Attendee_Status_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Attendee_Status_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_On_Conflict = {
  constraint: Enum_Attendee_Status_Type_Constraint;
  update_columns?: Array<Enum_Attendee_Status_Type_Update_Column>;
  where?: InputMaybe<Enum_Attendee_Status_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_attendee_status_type". */
export type Enum_Attendee_Status_Type_Order_By = {
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_attendee_status_type */
export type Enum_Attendee_Status_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_attendee_status_type" */
export enum Enum_Attendee_Status_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_attendee_status_type" */
export type Enum_Attendee_Status_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Attendee_Status_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Attendee_Status_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_attendee_status_type" */
export enum Enum_Attendee_Status_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Attendee_Status_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Attendee_Status_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Attendee_Status_Type_Bool_Exp;
};

/** columns and relationships of "enum_attendee_type" */
export type Enum_Attendee_Type = {
  __typename?: 'enum_attendee_type';
  /** An array relationship */
  calendar_attendees: Array<Calendar_Attendee>;
  /** An aggregate relationship */
  calendar_attendees_aggregate: Calendar_Attendee_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_attendee_type" */
export type Enum_Attendee_TypeCalendar_AttendeesArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "enum_attendee_type" */
export type Enum_Attendee_TypeCalendar_Attendees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** aggregated selection of "enum_attendee_type" */
export type Enum_Attendee_Type_Aggregate = {
  __typename?: 'enum_attendee_type_aggregate';
  aggregate?: Maybe<Enum_Attendee_Type_Aggregate_Fields>;
  nodes: Array<Enum_Attendee_Type>;
};

/** aggregate fields of "enum_attendee_type" */
export type Enum_Attendee_Type_Aggregate_Fields = {
  __typename?: 'enum_attendee_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Attendee_Type_Max_Fields>;
  min?: Maybe<Enum_Attendee_Type_Min_Fields>;
};

/** aggregate fields of "enum_attendee_type" */
export type Enum_Attendee_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Attendee_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_attendee_type". All fields are combined with a logical 'AND'. */
export type Enum_Attendee_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Attendee_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Attendee_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Attendee_Type_Bool_Exp>>;
  calendar_attendees?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_attendee_type" */
export enum Enum_Attendee_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAttendeeTypePkey = 'enum_attendee_type_pkey',
}

export enum Enum_Attendee_Type_Enum {
  /** Represent an external attendee */
  External = 'EXTERNAL',
  /** Represent an internal attendee */
  Internal = 'INTERNAL',
}

/** Boolean expression to compare columns of type "enum_attendee_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Attendee_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Attendee_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Attendee_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Attendee_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Attendee_Type_Enum>>;
};

/** input type for inserting data into table "enum_attendee_type" */
export type Enum_Attendee_Type_Insert_Input = {
  calendar_attendees?: InputMaybe<Calendar_Attendee_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Attendee_Type_Max_Fields = {
  __typename?: 'enum_attendee_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Attendee_Type_Min_Fields = {
  __typename?: 'enum_attendee_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_attendee_type" */
export type Enum_Attendee_Type_Mutation_Response = {
  __typename?: 'enum_attendee_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Attendee_Type>;
};

/** input type for inserting object relation for remote table "enum_attendee_type" */
export type Enum_Attendee_Type_Obj_Rel_Insert_Input = {
  data: Enum_Attendee_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Attendee_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_attendee_type" */
export type Enum_Attendee_Type_On_Conflict = {
  constraint: Enum_Attendee_Type_Constraint;
  update_columns?: Array<Enum_Attendee_Type_Update_Column>;
  where?: InputMaybe<Enum_Attendee_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_attendee_type". */
export type Enum_Attendee_Type_Order_By = {
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_attendee_type */
export type Enum_Attendee_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_attendee_type" */
export enum Enum_Attendee_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_attendee_type" */
export type Enum_Attendee_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_attendee_type" */
export type Enum_Attendee_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Attendee_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Attendee_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_attendee_type" */
export enum Enum_Attendee_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Attendee_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Attendee_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Attendee_Type_Bool_Exp;
};

/** columns and relationships of "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type = {
  __typename?: 'enum_award_cycle_type';
  /** An array relationship */
  awards: Array<Awards>;
  /** An aggregate relationship */
  awards_aggregate: Awards_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "enum_award_cycle_type" */
export type Enum_Award_Cycle_TypeAwardsArgs = {
  distinct_on?: InputMaybe<Array<Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Awards_Order_By>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

/** columns and relationships of "enum_award_cycle_type" */
export type Enum_Award_Cycle_TypeAwards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Awards_Order_By>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

/** aggregated selection of "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_Aggregate = {
  __typename?: 'enum_award_cycle_type_aggregate';
  aggregate?: Maybe<Enum_Award_Cycle_Type_Aggregate_Fields>;
  nodes: Array<Enum_Award_Cycle_Type>;
};

/** aggregate fields of "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_Aggregate_Fields = {
  __typename?: 'enum_award_cycle_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Award_Cycle_Type_Max_Fields>;
  min?: Maybe<Enum_Award_Cycle_Type_Min_Fields>;
};

/** aggregate fields of "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Award_Cycle_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_award_cycle_type". All fields are combined with a logical 'AND'. */
export type Enum_Award_Cycle_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Award_Cycle_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Award_Cycle_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Award_Cycle_Type_Bool_Exp>>;
  awards?: InputMaybe<Awards_Bool_Exp>;
  awards_aggregate?: InputMaybe<Awards_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_award_cycle_type" */
export enum Enum_Award_Cycle_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAwardCycleTypePkey = 'enum_award_cycle_type_pkey',
}

export enum Enum_Award_Cycle_Type_Enum {
  Annual = 'ANNUAL',
  HalfYearly = 'HALF_YEARLY',
  Quarterly = 'QUARTERLY',
}

/** Boolean expression to compare columns of type "enum_award_cycle_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Award_Cycle_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Award_Cycle_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Award_Cycle_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Award_Cycle_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Award_Cycle_Type_Enum>>;
};

/** input type for inserting data into table "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_Insert_Input = {
  awards?: InputMaybe<Awards_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Award_Cycle_Type_Max_Fields = {
  __typename?: 'enum_award_cycle_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Award_Cycle_Type_Min_Fields = {
  __typename?: 'enum_award_cycle_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_Mutation_Response = {
  __typename?: 'enum_award_cycle_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Award_Cycle_Type>;
};

/** input type for inserting object relation for remote table "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_Obj_Rel_Insert_Input = {
  data: Enum_Award_Cycle_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Award_Cycle_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_On_Conflict = {
  constraint: Enum_Award_Cycle_Type_Constraint;
  update_columns?: Array<Enum_Award_Cycle_Type_Update_Column>;
  where?: InputMaybe<Enum_Award_Cycle_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_award_cycle_type". */
export type Enum_Award_Cycle_Type_Order_By = {
  awards_aggregate?: InputMaybe<Awards_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_award_cycle_type */
export type Enum_Award_Cycle_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_award_cycle_type" */
export enum Enum_Award_Cycle_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_award_cycle_type" */
export type Enum_Award_Cycle_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Award_Cycle_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Award_Cycle_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_award_cycle_type" */
export enum Enum_Award_Cycle_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Award_Cycle_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Award_Cycle_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Award_Cycle_Type_Bool_Exp;
};

/** columns and relationships of "enum_call_access_policy" */
export type Enum_Call_Access_Policy = {
  __typename?: 'enum_call_access_policy';
  /** An array relationship */
  calls: Array<Call>;
  /** An aggregate relationship */
  calls_aggregate: Call_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_access_policy" */
export type Enum_Call_Access_PolicyCallsArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_access_policy" */
export type Enum_Call_Access_PolicyCalls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** aggregated selection of "enum_call_access_policy" */
export type Enum_Call_Access_Policy_Aggregate = {
  __typename?: 'enum_call_access_policy_aggregate';
  aggregate?: Maybe<Enum_Call_Access_Policy_Aggregate_Fields>;
  nodes: Array<Enum_Call_Access_Policy>;
};

/** aggregate fields of "enum_call_access_policy" */
export type Enum_Call_Access_Policy_Aggregate_Fields = {
  __typename?: 'enum_call_access_policy_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Access_Policy_Max_Fields>;
  min?: Maybe<Enum_Call_Access_Policy_Min_Fields>;
};

/** aggregate fields of "enum_call_access_policy" */
export type Enum_Call_Access_Policy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Access_Policy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_access_policy". All fields are combined with a logical 'AND'. */
export type Enum_Call_Access_Policy_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Access_Policy_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Access_Policy_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Access_Policy_Bool_Exp>>;
  calls?: InputMaybe<Call_Bool_Exp>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_access_policy" */
export enum Enum_Call_Access_Policy_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallAccessPolicyPkey = 'enum_call_access_policy_pkey',
}

export enum Enum_Call_Access_Policy_Enum {
  /** To join need to get accepted by creator */
  Explicit = 'EXPLICIT',
  /** Any one with the link can join */
  WithLink = 'WITH_LINK',
}

/** Boolean expression to compare columns of type "enum_call_access_policy_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_Access_Policy_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_Access_Policy_Enum>;
  _in?: InputMaybe<Array<Enum_Call_Access_Policy_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_Access_Policy_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_Access_Policy_Enum>>;
};

/** input type for inserting data into table "enum_call_access_policy" */
export type Enum_Call_Access_Policy_Insert_Input = {
  calls?: InputMaybe<Call_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Access_Policy_Max_Fields = {
  __typename?: 'enum_call_access_policy_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Access_Policy_Min_Fields = {
  __typename?: 'enum_call_access_policy_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_access_policy" */
export type Enum_Call_Access_Policy_Mutation_Response = {
  __typename?: 'enum_call_access_policy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Access_Policy>;
};

/** input type for inserting object relation for remote table "enum_call_access_policy" */
export type Enum_Call_Access_Policy_Obj_Rel_Insert_Input = {
  data: Enum_Call_Access_Policy_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_Access_Policy_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_access_policy" */
export type Enum_Call_Access_Policy_On_Conflict = {
  constraint: Enum_Call_Access_Policy_Constraint;
  update_columns?: Array<Enum_Call_Access_Policy_Update_Column>;
  where?: InputMaybe<Enum_Call_Access_Policy_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_access_policy". */
export type Enum_Call_Access_Policy_Order_By = {
  calls_aggregate?: InputMaybe<Call_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_access_policy */
export type Enum_Call_Access_Policy_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_access_policy" */
export enum Enum_Call_Access_Policy_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_access_policy" */
export type Enum_Call_Access_Policy_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_access_policy" */
export type Enum_Call_Access_Policy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Access_Policy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Access_Policy_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_access_policy" */
export enum Enum_Call_Access_Policy_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Access_Policy_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Access_Policy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Access_Policy_Bool_Exp;
};

/** columns and relationships of "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type = {
  __typename?: 'enum_call_broadcasting_type';
  /** An array relationship */
  calls: Array<Call>;
  /** An aggregate relationship */
  calls_aggregate: Call_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_TypeCallsArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_TypeCalls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** aggregated selection of "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_Aggregate = {
  __typename?: 'enum_call_broadcasting_type_aggregate';
  aggregate?: Maybe<Enum_Call_Broadcasting_Type_Aggregate_Fields>;
  nodes: Array<Enum_Call_Broadcasting_Type>;
};

/** aggregate fields of "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_Aggregate_Fields = {
  __typename?: 'enum_call_broadcasting_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Broadcasting_Type_Max_Fields>;
  min?: Maybe<Enum_Call_Broadcasting_Type_Min_Fields>;
};

/** aggregate fields of "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_broadcasting_type". All fields are combined with a logical 'AND'. */
export type Enum_Call_Broadcasting_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Broadcasting_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Bool_Exp>>;
  calls?: InputMaybe<Call_Bool_Exp>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_broadcasting_type" */
export enum Enum_Call_Broadcasting_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallBroadcastingTypePkey = 'enum_call_broadcasting_type_pkey',
}

export enum Enum_Call_Broadcasting_Type_Enum {
  Group = 'GROUP',
  OneToOne = 'ONE_TO_ONE',
}

/** Boolean expression to compare columns of type "enum_call_broadcasting_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_Broadcasting_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_Broadcasting_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_Broadcasting_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Enum>>;
};

/** input type for inserting data into table "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_Insert_Input = {
  calls?: InputMaybe<Call_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Broadcasting_Type_Max_Fields = {
  __typename?: 'enum_call_broadcasting_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Broadcasting_Type_Min_Fields = {
  __typename?: 'enum_call_broadcasting_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_Mutation_Response = {
  __typename?: 'enum_call_broadcasting_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Broadcasting_Type>;
};

/** input type for inserting object relation for remote table "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_Obj_Rel_Insert_Input = {
  data: Enum_Call_Broadcasting_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_Broadcasting_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_On_Conflict = {
  constraint: Enum_Call_Broadcasting_Type_Constraint;
  update_columns?: Array<Enum_Call_Broadcasting_Type_Update_Column>;
  where?: InputMaybe<Enum_Call_Broadcasting_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_broadcasting_type". */
export type Enum_Call_Broadcasting_Type_Order_By = {
  calls_aggregate?: InputMaybe<Call_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_broadcasting_type */
export type Enum_Call_Broadcasting_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_broadcasting_type" */
export enum Enum_Call_Broadcasting_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_broadcasting_type" */
export type Enum_Call_Broadcasting_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Broadcasting_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Broadcasting_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_broadcasting_type" */
export enum Enum_Call_Broadcasting_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Broadcasting_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Broadcasting_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Broadcasting_Type_Bool_Exp;
};

/** columns and relationships of "enum_call_media_type" */
export type Enum_Call_Media_Type = {
  __typename?: 'enum_call_media_type';
  /** An array relationship */
  calls: Array<Call>;
  /** An aggregate relationship */
  calls_aggregate: Call_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_media_type" */
export type Enum_Call_Media_TypeCallsArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_media_type" */
export type Enum_Call_Media_TypeCalls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** aggregated selection of "enum_call_media_type" */
export type Enum_Call_Media_Type_Aggregate = {
  __typename?: 'enum_call_media_type_aggregate';
  aggregate?: Maybe<Enum_Call_Media_Type_Aggregate_Fields>;
  nodes: Array<Enum_Call_Media_Type>;
};

/** aggregate fields of "enum_call_media_type" */
export type Enum_Call_Media_Type_Aggregate_Fields = {
  __typename?: 'enum_call_media_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Media_Type_Max_Fields>;
  min?: Maybe<Enum_Call_Media_Type_Min_Fields>;
};

/** aggregate fields of "enum_call_media_type" */
export type Enum_Call_Media_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Media_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_media_type". All fields are combined with a logical 'AND'. */
export type Enum_Call_Media_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Media_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Media_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Media_Type_Bool_Exp>>;
  calls?: InputMaybe<Call_Bool_Exp>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_media_type" */
export enum Enum_Call_Media_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallMediaTypePkey = 'enum_call_media_type_pkey',
}

export enum Enum_Call_Media_Type_Enum {
  /** Represent an audio only call */
  AudioOnly = 'AUDIO_ONLY',
  /** Represent an video & audio only call */
  AudioVideo = 'AUDIO_VIDEO',
}

/** Boolean expression to compare columns of type "enum_call_media_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_Media_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_Media_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Call_Media_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_Media_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_Media_Type_Enum>>;
};

/** input type for inserting data into table "enum_call_media_type" */
export type Enum_Call_Media_Type_Insert_Input = {
  calls?: InputMaybe<Call_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Media_Type_Max_Fields = {
  __typename?: 'enum_call_media_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Media_Type_Min_Fields = {
  __typename?: 'enum_call_media_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_media_type" */
export type Enum_Call_Media_Type_Mutation_Response = {
  __typename?: 'enum_call_media_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Media_Type>;
};

/** input type for inserting object relation for remote table "enum_call_media_type" */
export type Enum_Call_Media_Type_Obj_Rel_Insert_Input = {
  data: Enum_Call_Media_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_Media_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_media_type" */
export type Enum_Call_Media_Type_On_Conflict = {
  constraint: Enum_Call_Media_Type_Constraint;
  update_columns?: Array<Enum_Call_Media_Type_Update_Column>;
  where?: InputMaybe<Enum_Call_Media_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_media_type". */
export type Enum_Call_Media_Type_Order_By = {
  calls_aggregate?: InputMaybe<Call_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_media_type */
export type Enum_Call_Media_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_media_type" */
export enum Enum_Call_Media_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_media_type" */
export type Enum_Call_Media_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_media_type" */
export type Enum_Call_Media_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Media_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Media_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_media_type" */
export enum Enum_Call_Media_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Media_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Media_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Media_Type_Bool_Exp;
};

/** columns and relationships of "enum_call_recording_status" */
export type Enum_Call_Recording_Status = {
  __typename?: 'enum_call_recording_status';
  /** An array relationship */
  calendar_meeting_calls: Array<Calendar_Meeting_Call>;
  /** An aggregate relationship */
  calendar_meeting_calls_aggregate: Calendar_Meeting_Call_Aggregate;
  /** An array relationship */
  calls: Array<Call>;
  /** An aggregate relationship */
  calls_aggregate: Call_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_recording_status" */
export type Enum_Call_Recording_StatusCalendar_Meeting_CallsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_recording_status" */
export type Enum_Call_Recording_StatusCalendar_Meeting_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_recording_status" */
export type Enum_Call_Recording_StatusCallsArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_recording_status" */
export type Enum_Call_Recording_StatusCalls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** aggregated selection of "enum_call_recording_status" */
export type Enum_Call_Recording_Status_Aggregate = {
  __typename?: 'enum_call_recording_status_aggregate';
  aggregate?: Maybe<Enum_Call_Recording_Status_Aggregate_Fields>;
  nodes: Array<Enum_Call_Recording_Status>;
};

/** aggregate fields of "enum_call_recording_status" */
export type Enum_Call_Recording_Status_Aggregate_Fields = {
  __typename?: 'enum_call_recording_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Recording_Status_Max_Fields>;
  min?: Maybe<Enum_Call_Recording_Status_Min_Fields>;
};

/** aggregate fields of "enum_call_recording_status" */
export type Enum_Call_Recording_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Recording_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_recording_status". All fields are combined with a logical 'AND'. */
export type Enum_Call_Recording_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Recording_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Recording_Status_Bool_Exp>>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Bool_Exp>;
  calls?: InputMaybe<Call_Bool_Exp>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_recording_status" */
export enum Enum_Call_Recording_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallRecordingStatusPkey = 'enum_call_recording_status_pkey',
}

export enum Enum_Call_Recording_Status_Enum {
  Awaiting = 'AWAITING',
  NotRecording = 'NOT_RECORDING',
  Recording = 'RECORDING',
  StandingBy = 'STANDING_BY',
  Stopped = 'STOPPED',
}

/** Boolean expression to compare columns of type "enum_call_recording_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_Recording_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_Recording_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Call_Recording_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_Recording_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_Recording_Status_Enum>>;
};

/** input type for inserting data into table "enum_call_recording_status" */
export type Enum_Call_Recording_Status_Insert_Input = {
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Arr_Rel_Insert_Input>;
  calls?: InputMaybe<Call_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Recording_Status_Max_Fields = {
  __typename?: 'enum_call_recording_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Recording_Status_Min_Fields = {
  __typename?: 'enum_call_recording_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_recording_status" */
export type Enum_Call_Recording_Status_Mutation_Response = {
  __typename?: 'enum_call_recording_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Recording_Status>;
};

/** input type for inserting object relation for remote table "enum_call_recording_status" */
export type Enum_Call_Recording_Status_Obj_Rel_Insert_Input = {
  data: Enum_Call_Recording_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_Recording_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_recording_status" */
export type Enum_Call_Recording_Status_On_Conflict = {
  constraint: Enum_Call_Recording_Status_Constraint;
  update_columns?: Array<Enum_Call_Recording_Status_Update_Column>;
  where?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_recording_status". */
export type Enum_Call_Recording_Status_Order_By = {
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Order_By>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_recording_status */
export type Enum_Call_Recording_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_recording_status" */
export enum Enum_Call_Recording_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_recording_status" */
export type Enum_Call_Recording_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_recording_status" */
export type Enum_Call_Recording_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Recording_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Recording_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_recording_status" */
export enum Enum_Call_Recording_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Recording_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Recording_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Recording_Status_Bool_Exp;
};

/** columns and relationships of "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type = {
  __typename?: 'enum_call_request_response_type';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type_Aggregate = {
  __typename?: 'enum_call_request_response_type_aggregate';
  aggregate?: Maybe<Enum_Call_Request_Response_Type_Aggregate_Fields>;
  nodes: Array<Enum_Call_Request_Response_Type>;
};

/** aggregate fields of "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type_Aggregate_Fields = {
  __typename?: 'enum_call_request_response_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Request_Response_Type_Max_Fields>;
  min?: Maybe<Enum_Call_Request_Response_Type_Min_Fields>;
};

/** aggregate fields of "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Request_Response_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_request_response_type". All fields are combined with a logical 'AND'. */
export type Enum_Call_Request_Response_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Request_Response_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Request_Response_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Request_Response_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_request_response_type" */
export enum Enum_Call_Request_Response_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallRequestResponseTypePkey = 'enum_call_request_response_type_pkey',
}

/** input type for inserting data into table "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Request_Response_Type_Max_Fields = {
  __typename?: 'enum_call_request_response_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Request_Response_Type_Min_Fields = {
  __typename?: 'enum_call_request_response_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type_Mutation_Response = {
  __typename?: 'enum_call_request_response_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Request_Response_Type>;
};

/** on_conflict condition type for table "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type_On_Conflict = {
  constraint: Enum_Call_Request_Response_Type_Constraint;
  update_columns?: Array<Enum_Call_Request_Response_Type_Update_Column>;
  where?: InputMaybe<Enum_Call_Request_Response_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_request_response_type". */
export type Enum_Call_Request_Response_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_request_response_type */
export type Enum_Call_Request_Response_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_request_response_type" */
export enum Enum_Call_Request_Response_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_request_response_type" */
export type Enum_Call_Request_Response_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Request_Response_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Request_Response_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_request_response_type" */
export enum Enum_Call_Request_Response_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Request_Response_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Request_Response_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Request_Response_Type_Bool_Exp;
};

/** columns and relationships of "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type = {
  __typename?: 'enum_call_request_status_type';
  /** An array relationship */
  calendar_meeting_call_requests: Array<Calendar_Meeting_Call_Request>;
  /** An aggregate relationship */
  calendar_meeting_call_requests_aggregate: Calendar_Meeting_Call_Request_Aggregate;
  /** An array relationship */
  call_requests: Array<Call_Request>;
  /** An aggregate relationship */
  call_requests_aggregate: Call_Request_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_request_status_type" */
export type Enum_Call_Request_Status_TypeCalendar_Meeting_Call_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

/** columns and relationships of "enum_call_request_status_type" */
export type Enum_Call_Request_Status_TypeCalendar_Meeting_Call_Requests_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Calendar_Meeting_Call_Request_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
    where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
  };

/** columns and relationships of "enum_call_request_status_type" */
export type Enum_Call_Request_Status_TypeCall_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** columns and relationships of "enum_call_request_status_type" */
export type Enum_Call_Request_Status_TypeCall_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** aggregated selection of "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_Aggregate = {
  __typename?: 'enum_call_request_status_type_aggregate';
  aggregate?: Maybe<Enum_Call_Request_Status_Type_Aggregate_Fields>;
  nodes: Array<Enum_Call_Request_Status_Type>;
};

/** aggregate fields of "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_Aggregate_Fields = {
  __typename?: 'enum_call_request_status_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Request_Status_Type_Max_Fields>;
  min?: Maybe<Enum_Call_Request_Status_Type_Min_Fields>;
};

/** aggregate fields of "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Request_Status_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_request_status_type". All fields are combined with a logical 'AND'. */
export type Enum_Call_Request_Status_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Request_Status_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Request_Status_Type_Bool_Exp>>;
  calendar_meeting_call_requests?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
  calendar_meeting_call_requests_aggregate?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Bool_Exp>;
  call_requests?: InputMaybe<Call_Request_Bool_Exp>;
  call_requests_aggregate?: InputMaybe<Call_Request_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_request_status_type" */
export enum Enum_Call_Request_Status_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallRequestStatusTypePkey = 'enum_call_request_status_type_pkey',
}

export enum Enum_Call_Request_Status_Type_Enum {
  /** To represent the a call status as accepted  */
  Accepted = 'ACCEPTED',
  /** To represent that the call request got cancelled for some reason */
  Cancelled = 'CANCELLED',
  /** To represent the a call status as pending */
  Pending = 'PENDING',
  /** To represent the a call status as rejected */
  Rejected = 'REJECTED',
}

/** Boolean expression to compare columns of type "enum_call_request_status_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_Request_Status_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Call_Request_Status_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_Request_Status_Type_Enum>>;
};

/** input type for inserting data into table "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_Insert_Input = {
  calendar_meeting_call_requests?: InputMaybe<Calendar_Meeting_Call_Request_Arr_Rel_Insert_Input>;
  call_requests?: InputMaybe<Call_Request_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Request_Status_Type_Max_Fields = {
  __typename?: 'enum_call_request_status_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Request_Status_Type_Min_Fields = {
  __typename?: 'enum_call_request_status_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_Mutation_Response = {
  __typename?: 'enum_call_request_status_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Request_Status_Type>;
};

/** input type for inserting object relation for remote table "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_Obj_Rel_Insert_Input = {
  data: Enum_Call_Request_Status_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_Request_Status_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_On_Conflict = {
  constraint: Enum_Call_Request_Status_Type_Constraint;
  update_columns?: Array<Enum_Call_Request_Status_Type_Update_Column>;
  where?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_request_status_type". */
export type Enum_Call_Request_Status_Type_Order_By = {
  calendar_meeting_call_requests_aggregate?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Order_By>;
  call_requests_aggregate?: InputMaybe<Call_Request_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_request_status_type */
export type Enum_Call_Request_Status_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_request_status_type" */
export enum Enum_Call_Request_Status_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_request_status_type" */
export type Enum_Call_Request_Status_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Request_Status_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Request_Status_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_request_status_type" */
export enum Enum_Call_Request_Status_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Request_Status_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Request_Status_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Request_Status_Type_Bool_Exp;
};

/** columns and relationships of "enum_call_request_type" */
export type Enum_Call_Request_Type = {
  __typename?: 'enum_call_request_type';
  /** An array relationship */
  call_requests: Array<Call_Request>;
  /** An aggregate relationship */
  call_requests_aggregate: Call_Request_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_request_type" */
export type Enum_Call_Request_TypeCall_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** columns and relationships of "enum_call_request_type" */
export type Enum_Call_Request_TypeCall_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** aggregated selection of "enum_call_request_type" */
export type Enum_Call_Request_Type_Aggregate = {
  __typename?: 'enum_call_request_type_aggregate';
  aggregate?: Maybe<Enum_Call_Request_Type_Aggregate_Fields>;
  nodes: Array<Enum_Call_Request_Type>;
};

/** aggregate fields of "enum_call_request_type" */
export type Enum_Call_Request_Type_Aggregate_Fields = {
  __typename?: 'enum_call_request_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Request_Type_Max_Fields>;
  min?: Maybe<Enum_Call_Request_Type_Min_Fields>;
};

/** aggregate fields of "enum_call_request_type" */
export type Enum_Call_Request_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Request_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_request_type". All fields are combined with a logical 'AND'. */
export type Enum_Call_Request_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Request_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Request_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Request_Type_Bool_Exp>>;
  call_requests?: InputMaybe<Call_Request_Bool_Exp>;
  call_requests_aggregate?: InputMaybe<Call_Request_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_request_type" */
export enum Enum_Call_Request_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallRequestTypePkey = 'enum_call_request_type_pkey',
}

export enum Enum_Call_Request_Type_Enum {
  Meeting = 'MEETING',
  Normal = 'NORMAL',
}

/** Boolean expression to compare columns of type "enum_call_request_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_Request_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_Request_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Call_Request_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_Request_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_Request_Type_Enum>>;
};

/** input type for inserting data into table "enum_call_request_type" */
export type Enum_Call_Request_Type_Insert_Input = {
  call_requests?: InputMaybe<Call_Request_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Request_Type_Max_Fields = {
  __typename?: 'enum_call_request_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Request_Type_Min_Fields = {
  __typename?: 'enum_call_request_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_request_type" */
export type Enum_Call_Request_Type_Mutation_Response = {
  __typename?: 'enum_call_request_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Request_Type>;
};

/** input type for inserting object relation for remote table "enum_call_request_type" */
export type Enum_Call_Request_Type_Obj_Rel_Insert_Input = {
  data: Enum_Call_Request_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_Request_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_request_type" */
export type Enum_Call_Request_Type_On_Conflict = {
  constraint: Enum_Call_Request_Type_Constraint;
  update_columns?: Array<Enum_Call_Request_Type_Update_Column>;
  where?: InputMaybe<Enum_Call_Request_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_request_type". */
export type Enum_Call_Request_Type_Order_By = {
  call_requests_aggregate?: InputMaybe<Call_Request_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_request_type */
export type Enum_Call_Request_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_request_type" */
export enum Enum_Call_Request_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_request_type" */
export type Enum_Call_Request_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_request_type" */
export type Enum_Call_Request_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Request_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Request_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_request_type" */
export enum Enum_Call_Request_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Request_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Request_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Request_Type_Bool_Exp;
};

/** columns and relationships of "enum_call_status_type" */
export type Enum_Call_Status_Type = {
  __typename?: 'enum_call_status_type';
  /** An array relationship */
  calendar_meeting_calls: Array<Calendar_Meeting_Call>;
  /** An aggregate relationship */
  calendar_meeting_calls_aggregate: Calendar_Meeting_Call_Aggregate;
  /** An array relationship */
  calls: Array<Call>;
  /** An aggregate relationship */
  calls_aggregate: Call_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_status_type" */
export type Enum_Call_Status_TypeCalendar_Meeting_CallsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_status_type" */
export type Enum_Call_Status_TypeCalendar_Meeting_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_status_type" */
export type Enum_Call_Status_TypeCallsArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_status_type" */
export type Enum_Call_Status_TypeCalls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** aggregated selection of "enum_call_status_type" */
export type Enum_Call_Status_Type_Aggregate = {
  __typename?: 'enum_call_status_type_aggregate';
  aggregate?: Maybe<Enum_Call_Status_Type_Aggregate_Fields>;
  nodes: Array<Enum_Call_Status_Type>;
};

/** aggregate fields of "enum_call_status_type" */
export type Enum_Call_Status_Type_Aggregate_Fields = {
  __typename?: 'enum_call_status_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Status_Type_Max_Fields>;
  min?: Maybe<Enum_Call_Status_Type_Min_Fields>;
};

/** aggregate fields of "enum_call_status_type" */
export type Enum_Call_Status_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Status_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_status_type". All fields are combined with a logical 'AND'. */
export type Enum_Call_Status_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Status_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Status_Type_Bool_Exp>>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Bool_Exp>;
  calls?: InputMaybe<Call_Bool_Exp>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_status_type" */
export enum Enum_Call_Status_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallStatusTypePkey = 'enum_call_status_type_pkey',
}

export enum Enum_Call_Status_Type_Enum {
  Cancelled = 'CANCELLED',
  Ended = 'ENDED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

/** Boolean expression to compare columns of type "enum_call_status_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_Status_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_Status_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Call_Status_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_Status_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_Status_Type_Enum>>;
};

/** input type for inserting data into table "enum_call_status_type" */
export type Enum_Call_Status_Type_Insert_Input = {
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Arr_Rel_Insert_Input>;
  calls?: InputMaybe<Call_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Status_Type_Max_Fields = {
  __typename?: 'enum_call_status_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Status_Type_Min_Fields = {
  __typename?: 'enum_call_status_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_status_type" */
export type Enum_Call_Status_Type_Mutation_Response = {
  __typename?: 'enum_call_status_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Status_Type>;
};

/** input type for inserting object relation for remote table "enum_call_status_type" */
export type Enum_Call_Status_Type_Obj_Rel_Insert_Input = {
  data: Enum_Call_Status_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_Status_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_status_type" */
export type Enum_Call_Status_Type_On_Conflict = {
  constraint: Enum_Call_Status_Type_Constraint;
  update_columns?: Array<Enum_Call_Status_Type_Update_Column>;
  where?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_status_type". */
export type Enum_Call_Status_Type_Order_By = {
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Order_By>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_status_type */
export type Enum_Call_Status_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_status_type" */
export enum Enum_Call_Status_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_status_type" */
export type Enum_Call_Status_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_status_type" */
export type Enum_Call_Status_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Status_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Status_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_status_type" */
export enum Enum_Call_Status_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Status_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Status_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Status_Type_Bool_Exp;
};

/** columns and relationships of "enum_call_type" */
export type Enum_Call_Type = {
  __typename?: 'enum_call_type';
  /** An array relationship */
  calls: Array<Call>;
  /** An aggregate relationship */
  calls_aggregate: Call_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_type" */
export type Enum_Call_TypeCallsArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** columns and relationships of "enum_call_type" */
export type Enum_Call_TypeCalls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** aggregated selection of "enum_call_type" */
export type Enum_Call_Type_Aggregate = {
  __typename?: 'enum_call_type_aggregate';
  aggregate?: Maybe<Enum_Call_Type_Aggregate_Fields>;
  nodes: Array<Enum_Call_Type>;
};

/** aggregate fields of "enum_call_type" */
export type Enum_Call_Type_Aggregate_Fields = {
  __typename?: 'enum_call_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_Type_Max_Fields>;
  min?: Maybe<Enum_Call_Type_Min_Fields>;
};

/** aggregate fields of "enum_call_type" */
export type Enum_Call_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_type". All fields are combined with a logical 'AND'. */
export type Enum_Call_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_Type_Bool_Exp>>;
  calls?: InputMaybe<Call_Bool_Exp>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_type" */
export enum Enum_Call_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallCallTypePkey = 'enum_call_call_type_pkey',
}

export enum Enum_Call_Type_Enum {
  /** Call created for a scheduled meeting */
  Meeting = 'MEETING',
  /** Call triggered by a person */
  Normal = 'NORMAL',
}

/** Boolean expression to compare columns of type "enum_call_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Call_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_Type_Enum>>;
};

/** input type for inserting data into table "enum_call_type" */
export type Enum_Call_Type_Insert_Input = {
  calls?: InputMaybe<Call_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_Type_Max_Fields = {
  __typename?: 'enum_call_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_Type_Min_Fields = {
  __typename?: 'enum_call_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_type" */
export type Enum_Call_Type_Mutation_Response = {
  __typename?: 'enum_call_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_Type>;
};

/** input type for inserting object relation for remote table "enum_call_type" */
export type Enum_Call_Type_Obj_Rel_Insert_Input = {
  data: Enum_Call_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_type" */
export type Enum_Call_Type_On_Conflict = {
  constraint: Enum_Call_Type_Constraint;
  update_columns?: Array<Enum_Call_Type_Update_Column>;
  where?: InputMaybe<Enum_Call_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_type". */
export type Enum_Call_Type_Order_By = {
  calls_aggregate?: InputMaybe<Call_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_type */
export type Enum_Call_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_type" */
export enum Enum_Call_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_type" */
export type Enum_Call_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_type" */
export type Enum_Call_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_type" */
export enum Enum_Call_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_Type_Bool_Exp;
};

/** columns and relationships of "enum_call_user_status" */
export type Enum_Call_User_Status = {
  __typename?: 'enum_call_user_status';
  /** An array relationship */
  calendar_meeting_call_users: Array<Calendar_Meeting_Call_User>;
  /** An aggregate relationship */
  calendar_meeting_call_users_aggregate: Calendar_Meeting_Call_User_Aggregate;
  /** An array relationship */
  call_users: Array<Call_User>;
  /** An aggregate relationship */
  call_users_aggregate: Call_User_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_call_user_status" */
export type Enum_Call_User_StatusCalendar_Meeting_Call_UsersArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

/** columns and relationships of "enum_call_user_status" */
export type Enum_Call_User_StatusCalendar_Meeting_Call_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

/** columns and relationships of "enum_call_user_status" */
export type Enum_Call_User_StatusCall_UsersArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

/** columns and relationships of "enum_call_user_status" */
export type Enum_Call_User_StatusCall_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

/** aggregated selection of "enum_call_user_status" */
export type Enum_Call_User_Status_Aggregate = {
  __typename?: 'enum_call_user_status_aggregate';
  aggregate?: Maybe<Enum_Call_User_Status_Aggregate_Fields>;
  nodes: Array<Enum_Call_User_Status>;
};

/** aggregate fields of "enum_call_user_status" */
export type Enum_Call_User_Status_Aggregate_Fields = {
  __typename?: 'enum_call_user_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Call_User_Status_Max_Fields>;
  min?: Maybe<Enum_Call_User_Status_Min_Fields>;
};

/** aggregate fields of "enum_call_user_status" */
export type Enum_Call_User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Call_User_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_call_user_status". All fields are combined with a logical 'AND'. */
export type Enum_Call_User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Call_User_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Call_User_Status_Bool_Exp>>;
  calendar_meeting_call_users?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
  calendar_meeting_call_users_aggregate?: InputMaybe<Calendar_Meeting_Call_User_Aggregate_Bool_Exp>;
  call_users?: InputMaybe<Call_User_Bool_Exp>;
  call_users_aggregate?: InputMaybe<Call_User_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_call_user_status" */
export enum Enum_Call_User_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCallUserStatusPkey = 'enum_call_user_status_pkey',
}

export enum Enum_Call_User_Status_Enum {
  /** user is active in the call */
  Active = 'ACTIVE',
  /** user is inactive in the call */
  Inactive = 'INACTIVE',
}

/** Boolean expression to compare columns of type "enum_call_user_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Call_User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Call_User_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Call_User_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Call_User_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Call_User_Status_Enum>>;
};

/** input type for inserting data into table "enum_call_user_status" */
export type Enum_Call_User_Status_Insert_Input = {
  calendar_meeting_call_users?: InputMaybe<Calendar_Meeting_Call_User_Arr_Rel_Insert_Input>;
  call_users?: InputMaybe<Call_User_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Call_User_Status_Max_Fields = {
  __typename?: 'enum_call_user_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Call_User_Status_Min_Fields = {
  __typename?: 'enum_call_user_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_call_user_status" */
export type Enum_Call_User_Status_Mutation_Response = {
  __typename?: 'enum_call_user_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Call_User_Status>;
};

/** input type for inserting object relation for remote table "enum_call_user_status" */
export type Enum_Call_User_Status_Obj_Rel_Insert_Input = {
  data: Enum_Call_User_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Call_User_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_call_user_status" */
export type Enum_Call_User_Status_On_Conflict = {
  constraint: Enum_Call_User_Status_Constraint;
  update_columns?: Array<Enum_Call_User_Status_Update_Column>;
  where?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_call_user_status". */
export type Enum_Call_User_Status_Order_By = {
  calendar_meeting_call_users_aggregate?: InputMaybe<Calendar_Meeting_Call_User_Aggregate_Order_By>;
  call_users_aggregate?: InputMaybe<Call_User_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_call_user_status */
export type Enum_Call_User_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_call_user_status" */
export enum Enum_Call_User_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_call_user_status" */
export type Enum_Call_User_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_call_user_status" */
export type Enum_Call_User_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Call_User_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Call_User_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_call_user_status" */
export enum Enum_Call_User_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Call_User_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Call_User_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Call_User_Status_Bool_Exp;
};

/** columns and relationships of "enum_chat_message_type" */
export type Enum_Chat_Message_Type = {
  __typename?: 'enum_chat_message_type';
  /** An array relationship */
  chat_messages: Array<Chat_Message>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Message_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_chat_message_type" */
export type Enum_Chat_Message_TypeChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** columns and relationships of "enum_chat_message_type" */
export type Enum_Chat_Message_TypeChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** aggregated selection of "enum_chat_message_type" */
export type Enum_Chat_Message_Type_Aggregate = {
  __typename?: 'enum_chat_message_type_aggregate';
  aggregate?: Maybe<Enum_Chat_Message_Type_Aggregate_Fields>;
  nodes: Array<Enum_Chat_Message_Type>;
};

/** aggregate fields of "enum_chat_message_type" */
export type Enum_Chat_Message_Type_Aggregate_Fields = {
  __typename?: 'enum_chat_message_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Chat_Message_Type_Max_Fields>;
  min?: Maybe<Enum_Chat_Message_Type_Min_Fields>;
};

/** aggregate fields of "enum_chat_message_type" */
export type Enum_Chat_Message_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Chat_Message_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_chat_message_type". All fields are combined with a logical 'AND'. */
export type Enum_Chat_Message_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Chat_Message_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Chat_Message_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Chat_Message_Type_Bool_Exp>>;
  chat_messages?: InputMaybe<Chat_Message_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_chat_message_type" */
export enum Enum_Chat_Message_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumChatMessageTypePkey = 'enum_chat_message_type_pkey',
}

export enum Enum_Chat_Message_Type_Enum {
  /** Represent a message created by system */
  ActivityMessage = 'ACTIVITY_MESSAGE',
  /** Represent a call activity */
  CallMessage = 'CALL_MESSAGE',
  /** Attach a document to the chat */
  DocumentMessage = 'DOCUMENT_MESSAGE',
  /** Represent a message send by user */
  NormalMessage = 'NORMAL_MESSAGE',
  /** A live emote reaction  */
  ReactionMessage = 'REACTION_MESSAGE',
  RecordingUpdates = 'RECORDING_UPDATES',
  /** these messages are generated by the system itlsef */
  SystemGenerated = 'SYSTEM_GENERATED',
}

/** Boolean expression to compare columns of type "enum_chat_message_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Chat_Message_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Chat_Message_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Chat_Message_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Chat_Message_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Chat_Message_Type_Enum>>;
};

/** input type for inserting data into table "enum_chat_message_type" */
export type Enum_Chat_Message_Type_Insert_Input = {
  chat_messages?: InputMaybe<Chat_Message_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Chat_Message_Type_Max_Fields = {
  __typename?: 'enum_chat_message_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Chat_Message_Type_Min_Fields = {
  __typename?: 'enum_chat_message_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_chat_message_type" */
export type Enum_Chat_Message_Type_Mutation_Response = {
  __typename?: 'enum_chat_message_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Chat_Message_Type>;
};

/** input type for inserting object relation for remote table "enum_chat_message_type" */
export type Enum_Chat_Message_Type_Obj_Rel_Insert_Input = {
  data: Enum_Chat_Message_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Chat_Message_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_chat_message_type" */
export type Enum_Chat_Message_Type_On_Conflict = {
  constraint: Enum_Chat_Message_Type_Constraint;
  update_columns?: Array<Enum_Chat_Message_Type_Update_Column>;
  where?: InputMaybe<Enum_Chat_Message_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_chat_message_type". */
export type Enum_Chat_Message_Type_Order_By = {
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_chat_message_type */
export type Enum_Chat_Message_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_chat_message_type" */
export enum Enum_Chat_Message_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_chat_message_type" */
export type Enum_Chat_Message_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_chat_message_type" */
export type Enum_Chat_Message_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Chat_Message_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Chat_Message_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_chat_message_type" */
export enum Enum_Chat_Message_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Chat_Message_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Chat_Message_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Chat_Message_Type_Bool_Exp;
};

/** columns and relationships of "enum_chat_notification" */
export type Enum_Chat_Notification = {
  __typename?: 'enum_chat_notification';
  /** An array relationship */
  chat_room_users: Array<Chat_Room_User>;
  /** An aggregate relationship */
  chat_room_users_aggregate: Chat_Room_User_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_chat_notification" */
export type Enum_Chat_NotificationChat_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** columns and relationships of "enum_chat_notification" */
export type Enum_Chat_NotificationChat_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** aggregated selection of "enum_chat_notification" */
export type Enum_Chat_Notification_Aggregate = {
  __typename?: 'enum_chat_notification_aggregate';
  aggregate?: Maybe<Enum_Chat_Notification_Aggregate_Fields>;
  nodes: Array<Enum_Chat_Notification>;
};

/** aggregate fields of "enum_chat_notification" */
export type Enum_Chat_Notification_Aggregate_Fields = {
  __typename?: 'enum_chat_notification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Chat_Notification_Max_Fields>;
  min?: Maybe<Enum_Chat_Notification_Min_Fields>;
};

/** aggregate fields of "enum_chat_notification" */
export type Enum_Chat_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Chat_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_chat_notification". All fields are combined with a logical 'AND'. */
export type Enum_Chat_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Chat_Notification_Bool_Exp>>;
  _not?: InputMaybe<Enum_Chat_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Chat_Notification_Bool_Exp>>;
  chat_room_users?: InputMaybe<Chat_Room_User_Bool_Exp>;
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_chat_notification" */
export enum Enum_Chat_Notification_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumChatNotificationPkey = 'enum_chat_notification_pkey',
}

export enum Enum_Chat_Notification_Enum {
  /** No notifications */
  Disabled = 'DISABLED',
  /** Receives all notifications */
  Enabled = 'ENABLED',
  /** To represent when the chat notifications are muted */
  Muted = 'MUTED',
}

/** Boolean expression to compare columns of type "enum_chat_notification_enum". All fields are combined with logical 'AND'. */
export type Enum_Chat_Notification_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Chat_Notification_Enum>;
  _in?: InputMaybe<Array<Enum_Chat_Notification_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Chat_Notification_Enum>;
  _nin?: InputMaybe<Array<Enum_Chat_Notification_Enum>>;
};

/** input type for inserting data into table "enum_chat_notification" */
export type Enum_Chat_Notification_Insert_Input = {
  chat_room_users?: InputMaybe<Chat_Room_User_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Chat_Notification_Max_Fields = {
  __typename?: 'enum_chat_notification_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Chat_Notification_Min_Fields = {
  __typename?: 'enum_chat_notification_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_chat_notification" */
export type Enum_Chat_Notification_Mutation_Response = {
  __typename?: 'enum_chat_notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Chat_Notification>;
};

/** input type for inserting object relation for remote table "enum_chat_notification" */
export type Enum_Chat_Notification_Obj_Rel_Insert_Input = {
  data: Enum_Chat_Notification_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Chat_Notification_On_Conflict>;
};

/** on_conflict condition type for table "enum_chat_notification" */
export type Enum_Chat_Notification_On_Conflict = {
  constraint: Enum_Chat_Notification_Constraint;
  update_columns?: Array<Enum_Chat_Notification_Update_Column>;
  where?: InputMaybe<Enum_Chat_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_chat_notification". */
export type Enum_Chat_Notification_Order_By = {
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_chat_notification */
export type Enum_Chat_Notification_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_chat_notification" */
export enum Enum_Chat_Notification_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_chat_notification" */
export type Enum_Chat_Notification_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_chat_notification" */
export type Enum_Chat_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Chat_Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Chat_Notification_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_chat_notification" */
export enum Enum_Chat_Notification_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Chat_Notification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Chat_Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Chat_Notification_Bool_Exp;
};

/** columns and relationships of "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status = {
  __typename?: 'enum_chat_room_notification_status';
  /** An array relationship */
  chat_room_users: Array<Chat_Room_User>;
  /** An aggregate relationship */
  chat_room_users_aggregate: Chat_Room_User_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_StatusChat_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** columns and relationships of "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_StatusChat_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** aggregated selection of "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_Aggregate = {
  __typename?: 'enum_chat_room_notification_status_aggregate';
  aggregate?: Maybe<Enum_Chat_Room_Notification_Status_Aggregate_Fields>;
  nodes: Array<Enum_Chat_Room_Notification_Status>;
};

/** aggregate fields of "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_Aggregate_Fields = {
  __typename?: 'enum_chat_room_notification_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Chat_Room_Notification_Status_Max_Fields>;
  min?: Maybe<Enum_Chat_Room_Notification_Status_Min_Fields>;
};

/** aggregate fields of "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_chat_room_notification_status". All fields are combined with a logical 'AND'. */
export type Enum_Chat_Room_Notification_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Chat_Room_Notification_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Bool_Exp>>;
  chat_room_users?: InputMaybe<Chat_Room_User_Bool_Exp>;
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_chat_room_notification_status" */
export enum Enum_Chat_Room_Notification_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumChatRoomNotificationStatusPkey = 'enum_chat_room_notification_status_pkey',
}

export enum Enum_Chat_Room_Notification_Status_Enum {
  Muted = 'MUTED',
  UnMuted = 'UN_MUTED',
}

/** Boolean expression to compare columns of type "enum_chat_room_notification_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Chat_Room_Notification_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Chat_Room_Notification_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Chat_Room_Notification_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Enum>>;
};

/** input type for inserting data into table "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_Insert_Input = {
  chat_room_users?: InputMaybe<Chat_Room_User_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Chat_Room_Notification_Status_Max_Fields = {
  __typename?: 'enum_chat_room_notification_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Chat_Room_Notification_Status_Min_Fields = {
  __typename?: 'enum_chat_room_notification_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_Mutation_Response = {
  __typename?: 'enum_chat_room_notification_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Chat_Room_Notification_Status>;
};

/** input type for inserting object relation for remote table "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_Obj_Rel_Insert_Input = {
  data: Enum_Chat_Room_Notification_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Chat_Room_Notification_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_On_Conflict = {
  constraint: Enum_Chat_Room_Notification_Status_Constraint;
  update_columns?: Array<Enum_Chat_Room_Notification_Status_Update_Column>;
  where?: InputMaybe<Enum_Chat_Room_Notification_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_chat_room_notification_status". */
export type Enum_Chat_Room_Notification_Status_Order_By = {
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_chat_room_notification_status */
export type Enum_Chat_Room_Notification_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_chat_room_notification_status" */
export enum Enum_Chat_Room_Notification_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_chat_room_notification_status" */
export type Enum_Chat_Room_Notification_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Chat_Room_Notification_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Chat_Room_Notification_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_chat_room_notification_status" */
export enum Enum_Chat_Room_Notification_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Chat_Room_Notification_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Chat_Room_Notification_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Chat_Room_Notification_Status_Bool_Exp;
};

/** Chat room types */
export type Enum_Chat_Room_Type = {
  __typename?: 'enum_chat_room_type';
  /** An array relationship */
  chat_rooms: Array<Chat_Room>;
  /** An aggregate relationship */
  chat_rooms_aggregate: Chat_Room_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** Chat room types */
export type Enum_Chat_Room_TypeChat_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** Chat room types */
export type Enum_Chat_Room_TypeChat_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** aggregated selection of "enum_chat_room_type" */
export type Enum_Chat_Room_Type_Aggregate = {
  __typename?: 'enum_chat_room_type_aggregate';
  aggregate?: Maybe<Enum_Chat_Room_Type_Aggregate_Fields>;
  nodes: Array<Enum_Chat_Room_Type>;
};

/** aggregate fields of "enum_chat_room_type" */
export type Enum_Chat_Room_Type_Aggregate_Fields = {
  __typename?: 'enum_chat_room_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Chat_Room_Type_Max_Fields>;
  min?: Maybe<Enum_Chat_Room_Type_Min_Fields>;
};

/** aggregate fields of "enum_chat_room_type" */
export type Enum_Chat_Room_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Chat_Room_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_chat_room_type". All fields are combined with a logical 'AND'. */
export type Enum_Chat_Room_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Chat_Room_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Chat_Room_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Chat_Room_Type_Bool_Exp>>;
  chat_rooms?: InputMaybe<Chat_Room_Bool_Exp>;
  chat_rooms_aggregate?: InputMaybe<Chat_Room_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_chat_room_type" */
export enum Enum_Chat_Room_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumChatRoomTypePkey = 'enum_chat_room_type_pkey',
}

export enum Enum_Chat_Room_Type_Enum {
  /** To represent a group room */
  Group = 'GROUP',
  /** To represent a direct message room */
  OneToOne = 'ONE_TO_ONE',
}

/** Boolean expression to compare columns of type "enum_chat_room_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Chat_Room_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Chat_Room_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Chat_Room_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Chat_Room_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Chat_Room_Type_Enum>>;
};

/** input type for inserting data into table "enum_chat_room_type" */
export type Enum_Chat_Room_Type_Insert_Input = {
  chat_rooms?: InputMaybe<Chat_Room_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Chat_Room_Type_Max_Fields = {
  __typename?: 'enum_chat_room_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Chat_Room_Type_Min_Fields = {
  __typename?: 'enum_chat_room_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_chat_room_type" */
export type Enum_Chat_Room_Type_Mutation_Response = {
  __typename?: 'enum_chat_room_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Chat_Room_Type>;
};

/** input type for inserting object relation for remote table "enum_chat_room_type" */
export type Enum_Chat_Room_Type_Obj_Rel_Insert_Input = {
  data: Enum_Chat_Room_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Chat_Room_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_chat_room_type" */
export type Enum_Chat_Room_Type_On_Conflict = {
  constraint: Enum_Chat_Room_Type_Constraint;
  update_columns?: Array<Enum_Chat_Room_Type_Update_Column>;
  where?: InputMaybe<Enum_Chat_Room_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_chat_room_type". */
export type Enum_Chat_Room_Type_Order_By = {
  chat_rooms_aggregate?: InputMaybe<Chat_Room_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_chat_room_type */
export type Enum_Chat_Room_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_chat_room_type" */
export enum Enum_Chat_Room_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_chat_room_type" */
export type Enum_Chat_Room_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_chat_room_type" */
export type Enum_Chat_Room_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Chat_Room_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Chat_Room_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_chat_room_type" */
export enum Enum_Chat_Room_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Chat_Room_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Chat_Room_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Chat_Room_Type_Bool_Exp;
};

/** columns and relationships of "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status = {
  __typename?: 'enum_chat_room_user_status';
  /** An array relationship */
  chat_room_users: Array<Chat_Room_User>;
  /** An aggregate relationship */
  chat_room_users_aggregate: Chat_Room_User_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_StatusChat_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** columns and relationships of "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_StatusChat_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** aggregated selection of "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_Aggregate = {
  __typename?: 'enum_chat_room_user_status_aggregate';
  aggregate?: Maybe<Enum_Chat_Room_User_Status_Aggregate_Fields>;
  nodes: Array<Enum_Chat_Room_User_Status>;
};

/** aggregate fields of "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_Aggregate_Fields = {
  __typename?: 'enum_chat_room_user_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Chat_Room_User_Status_Max_Fields>;
  min?: Maybe<Enum_Chat_Room_User_Status_Min_Fields>;
};

/** aggregate fields of "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Chat_Room_User_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_chat_room_user_status". All fields are combined with a logical 'AND'. */
export type Enum_Chat_Room_User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Chat_Room_User_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Chat_Room_User_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Chat_Room_User_Status_Bool_Exp>>;
  chat_room_users?: InputMaybe<Chat_Room_User_Bool_Exp>;
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_chat_room_user_status" */
export enum Enum_Chat_Room_User_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumChatRoomUserStatusPkey = 'enum_chat_room_user_status_pkey',
}

export enum Enum_Chat_Room_User_Status_Enum {
  /** User left the chat room */
  Closed = 'CLOSED',
  /** User is part of the chat room */
  Joined = 'JOINED',
  /** User is actively looking at chat room */
  Viewing = 'VIEWING',
}

/** Boolean expression to compare columns of type "enum_chat_room_user_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Chat_Room_User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Chat_Room_User_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Chat_Room_User_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Chat_Room_User_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Chat_Room_User_Status_Enum>>;
};

/** input type for inserting data into table "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_Insert_Input = {
  chat_room_users?: InputMaybe<Chat_Room_User_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Chat_Room_User_Status_Max_Fields = {
  __typename?: 'enum_chat_room_user_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Chat_Room_User_Status_Min_Fields = {
  __typename?: 'enum_chat_room_user_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_Mutation_Response = {
  __typename?: 'enum_chat_room_user_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Chat_Room_User_Status>;
};

/** input type for inserting object relation for remote table "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_Obj_Rel_Insert_Input = {
  data: Enum_Chat_Room_User_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Chat_Room_User_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_On_Conflict = {
  constraint: Enum_Chat_Room_User_Status_Constraint;
  update_columns?: Array<Enum_Chat_Room_User_Status_Update_Column>;
  where?: InputMaybe<Enum_Chat_Room_User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_chat_room_user_status". */
export type Enum_Chat_Room_User_Status_Order_By = {
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_chat_room_user_status */
export type Enum_Chat_Room_User_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_chat_room_user_status" */
export enum Enum_Chat_Room_User_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_chat_room_user_status" */
export type Enum_Chat_Room_User_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Chat_Room_User_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Chat_Room_User_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_chat_room_user_status" */
export enum Enum_Chat_Room_User_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Chat_Room_User_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Chat_Room_User_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Chat_Room_User_Status_Bool_Exp;
};

/** columns and relationships of "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type = {
  __typename?: 'enum_dashboard_remainder_type';
  comment?: Maybe<Scalars['String']>;
  /** An array relationship */
  remainders: Array<Dashboard_Remainders>;
  /** An aggregate relationship */
  remainders_aggregate: Dashboard_Remainders_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_TypeRemaindersArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Remainders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Remainders_Order_By>>;
  where?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
};

/** columns and relationships of "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_TypeRemainders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Remainders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Remainders_Order_By>>;
  where?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
};

/** aggregated selection of "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_Aggregate = {
  __typename?: 'enum_dashboard_remainder_type_aggregate';
  aggregate?: Maybe<Enum_Dashboard_Remainder_Type_Aggregate_Fields>;
  nodes: Array<Enum_Dashboard_Remainder_Type>;
};

/** aggregate fields of "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_Aggregate_Fields = {
  __typename?: 'enum_dashboard_remainder_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Dashboard_Remainder_Type_Max_Fields>;
  min?: Maybe<Enum_Dashboard_Remainder_Type_Min_Fields>;
};

/** aggregate fields of "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_dashboard_remainder_type". All fields are combined with a logical 'AND'. */
export type Enum_Dashboard_Remainder_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Dashboard_Remainder_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  remainders?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
  remainders_aggregate?: InputMaybe<Dashboard_Remainders_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_dashboard_remainder_type" */
export enum Enum_Dashboard_Remainder_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDashboardRemainderTypePkey = 'enum_dashboard_remainder_type_pkey',
}

export enum Enum_Dashboard_Remainder_Type_Enum {
  /** Random generated goals remainder */
  Goals = 'GOALS',
  /** Mandatory assessment due remainder */
  MandatoryAssessment = 'MANDATORY_ASSESSMENT',
  /** Perfomance review started remainder */
  PerfomanceReview = 'PERFOMANCE_REVIEW',
  /** Weekly missed timesheet remainder */
  WeeklyTimesheet = 'WEEKLY_TIMESHEET',
}

/** Boolean expression to compare columns of type "enum_dashboard_remainder_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Dashboard_Remainder_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Dashboard_Remainder_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Dashboard_Remainder_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Enum>>;
};

/** input type for inserting data into table "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  remainders?: InputMaybe<Dashboard_Remainders_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Dashboard_Remainder_Type_Max_Fields = {
  __typename?: 'enum_dashboard_remainder_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Dashboard_Remainder_Type_Min_Fields = {
  __typename?: 'enum_dashboard_remainder_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_Mutation_Response = {
  __typename?: 'enum_dashboard_remainder_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Dashboard_Remainder_Type>;
};

/** input type for inserting object relation for remote table "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_Obj_Rel_Insert_Input = {
  data: Enum_Dashboard_Remainder_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Dashboard_Remainder_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_On_Conflict = {
  constraint: Enum_Dashboard_Remainder_Type_Constraint;
  update_columns?: Array<Enum_Dashboard_Remainder_Type_Update_Column>;
  where?: InputMaybe<Enum_Dashboard_Remainder_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_dashboard_remainder_type". */
export type Enum_Dashboard_Remainder_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  remainders_aggregate?: InputMaybe<Dashboard_Remainders_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_dashboard_remainder_type */
export type Enum_Dashboard_Remainder_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_dashboard_remainder_type" */
export enum Enum_Dashboard_Remainder_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_dashboard_remainder_type" */
export type Enum_Dashboard_Remainder_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Dashboard_Remainder_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Dashboard_Remainder_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_dashboard_remainder_type" */
export enum Enum_Dashboard_Remainder_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Dashboard_Remainder_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Dashboard_Remainder_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Dashboard_Remainder_Type_Bool_Exp;
};

/** Type of token that will be saved per user */
export type Enum_Document_Token_Type = {
  __typename?: 'enum_document_token_type';
  comment?: Maybe<Scalars['String']>;
  /** An array relationship */
  document_tokens: Array<Document_Token>;
  /** An aggregate relationship */
  document_tokens_aggregate: Document_Token_Aggregate;
  value: Scalars['String'];
};

/** Type of token that will be saved per user */
export type Enum_Document_Token_TypeDocument_TokensArgs = {
  distinct_on?: InputMaybe<Array<Document_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Token_Order_By>>;
  where?: InputMaybe<Document_Token_Bool_Exp>;
};

/** Type of token that will be saved per user */
export type Enum_Document_Token_TypeDocument_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Token_Order_By>>;
  where?: InputMaybe<Document_Token_Bool_Exp>;
};

/** aggregated selection of "enum_document_token_type" */
export type Enum_Document_Token_Type_Aggregate = {
  __typename?: 'enum_document_token_type_aggregate';
  aggregate?: Maybe<Enum_Document_Token_Type_Aggregate_Fields>;
  nodes: Array<Enum_Document_Token_Type>;
};

/** aggregate fields of "enum_document_token_type" */
export type Enum_Document_Token_Type_Aggregate_Fields = {
  __typename?: 'enum_document_token_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Document_Token_Type_Max_Fields>;
  min?: Maybe<Enum_Document_Token_Type_Min_Fields>;
};

/** aggregate fields of "enum_document_token_type" */
export type Enum_Document_Token_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Document_Token_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_document_token_type". All fields are combined with a logical 'AND'. */
export type Enum_Document_Token_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Document_Token_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Document_Token_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Document_Token_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  document_tokens?: InputMaybe<Document_Token_Bool_Exp>;
  document_tokens_aggregate?: InputMaybe<Document_Token_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_document_token_type" */
export enum Enum_Document_Token_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDocumentTokenTypePkey = 'enum_document_token_type_pkey',
}

export enum Enum_Document_Token_Type_Enum {
  /** Dropbox oauth token */
  Dropbox = 'DROPBOX',
  /** Google Drive oauth token */
  Googledrive = 'GOOGLEDRIVE',
  /** Onedrive oauth token */
  Onedrive = 'ONEDRIVE',
}

/** Boolean expression to compare columns of type "enum_document_token_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Document_Token_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Document_Token_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Document_Token_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Document_Token_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Document_Token_Type_Enum>>;
};

/** input type for inserting data into table "enum_document_token_type" */
export type Enum_Document_Token_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  document_tokens?: InputMaybe<Document_Token_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Document_Token_Type_Max_Fields = {
  __typename?: 'enum_document_token_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Document_Token_Type_Min_Fields = {
  __typename?: 'enum_document_token_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_document_token_type" */
export type Enum_Document_Token_Type_Mutation_Response = {
  __typename?: 'enum_document_token_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Document_Token_Type>;
};

/** input type for inserting object relation for remote table "enum_document_token_type" */
export type Enum_Document_Token_Type_Obj_Rel_Insert_Input = {
  data: Enum_Document_Token_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Document_Token_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_document_token_type" */
export type Enum_Document_Token_Type_On_Conflict = {
  constraint: Enum_Document_Token_Type_Constraint;
  update_columns?: Array<Enum_Document_Token_Type_Update_Column>;
  where?: InputMaybe<Enum_Document_Token_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_document_token_type". */
export type Enum_Document_Token_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  document_tokens_aggregate?: InputMaybe<Document_Token_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_document_token_type */
export type Enum_Document_Token_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_document_token_type" */
export enum Enum_Document_Token_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_document_token_type" */
export type Enum_Document_Token_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_document_token_type" */
export type Enum_Document_Token_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Document_Token_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Document_Token_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_document_token_type" */
export enum Enum_Document_Token_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Document_Token_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Document_Token_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Document_Token_Type_Bool_Exp;
};

/** columns and relationships of "enum_event_type" */
export type Enum_Event_Type = {
  __typename?: 'enum_event_type';
  /** An array relationship */
  calendar_events: Array<Calendar_Event>;
  /** An aggregate relationship */
  calendar_events_aggregate: Calendar_Event_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_event_type" */
export type Enum_Event_TypeCalendar_EventsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

/** columns and relationships of "enum_event_type" */
export type Enum_Event_TypeCalendar_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

/** aggregated selection of "enum_event_type" */
export type Enum_Event_Type_Aggregate = {
  __typename?: 'enum_event_type_aggregate';
  aggregate?: Maybe<Enum_Event_Type_Aggregate_Fields>;
  nodes: Array<Enum_Event_Type>;
};

/** aggregate fields of "enum_event_type" */
export type Enum_Event_Type_Aggregate_Fields = {
  __typename?: 'enum_event_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Event_Type_Max_Fields>;
  min?: Maybe<Enum_Event_Type_Min_Fields>;
};

/** aggregate fields of "enum_event_type" */
export type Enum_Event_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Event_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_event_type". All fields are combined with a logical 'AND'. */
export type Enum_Event_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Event_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Event_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Event_Type_Bool_Exp>>;
  calendar_events?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_events_aggregate?: InputMaybe<Calendar_Event_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_event_type" */
export enum Enum_Event_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumEventTypePkey = 'enum_event_type_pkey',
}

export enum Enum_Event_Type_Enum {
  /** Event type meeting */
  Meeting = 'MEETING',
}

/** Boolean expression to compare columns of type "enum_event_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Event_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Event_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Event_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Event_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Event_Type_Enum>>;
};

/** input type for inserting data into table "enum_event_type" */
export type Enum_Event_Type_Insert_Input = {
  calendar_events?: InputMaybe<Calendar_Event_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Event_Type_Max_Fields = {
  __typename?: 'enum_event_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Event_Type_Min_Fields = {
  __typename?: 'enum_event_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_event_type" */
export type Enum_Event_Type_Mutation_Response = {
  __typename?: 'enum_event_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Event_Type>;
};

/** input type for inserting object relation for remote table "enum_event_type" */
export type Enum_Event_Type_Obj_Rel_Insert_Input = {
  data: Enum_Event_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Event_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_event_type" */
export type Enum_Event_Type_On_Conflict = {
  constraint: Enum_Event_Type_Constraint;
  update_columns?: Array<Enum_Event_Type_Update_Column>;
  where?: InputMaybe<Enum_Event_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_event_type". */
export type Enum_Event_Type_Order_By = {
  calendar_events_aggregate?: InputMaybe<Calendar_Event_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_event_type */
export type Enum_Event_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_event_type" */
export enum Enum_Event_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_event_type" */
export type Enum_Event_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_event_type" */
export type Enum_Event_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Event_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Event_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_event_type" */
export enum Enum_Event_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Event_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Event_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Event_Type_Bool_Exp;
};

/** columns and relationships of "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type = {
  __typename?: 'enum_leave_donate_type';
  comment: Scalars['String'];
  /** An array relationship */
  leave_donates: Array<Leave_Donate>;
  /** An aggregate relationship */
  leave_donates_aggregate: Leave_Donate_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_leave_donate_type" */
export type Enum_Leave_Donate_TypeLeave_DonatesArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

/** columns and relationships of "enum_leave_donate_type" */
export type Enum_Leave_Donate_TypeLeave_Donates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

/** aggregated selection of "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_Aggregate = {
  __typename?: 'enum_leave_donate_type_aggregate';
  aggregate?: Maybe<Enum_Leave_Donate_Type_Aggregate_Fields>;
  nodes: Array<Enum_Leave_Donate_Type>;
};

/** aggregate fields of "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_Aggregate_Fields = {
  __typename?: 'enum_leave_donate_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Leave_Donate_Type_Max_Fields>;
  min?: Maybe<Enum_Leave_Donate_Type_Min_Fields>;
};

/** aggregate fields of "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Leave_Donate_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_leave_donate_type". All fields are combined with a logical 'AND'. */
export type Enum_Leave_Donate_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Leave_Donate_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Leave_Donate_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Leave_Donate_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  leave_donates?: InputMaybe<Leave_Donate_Bool_Exp>;
  leave_donates_aggregate?: InputMaybe<Leave_Donate_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_leave_donate_type" */
export enum Enum_Leave_Donate_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumLeaveDonateTypePkey = 'enum_leave_donate_type_pkey',
}

export enum Enum_Leave_Donate_Type_Enum {
  /** Leave donated by a person. */
  Person = 'PERSON',
  /** Leave donated by previous year. */
  PrevYear = 'PREV_YEAR',
}

/** Boolean expression to compare columns of type "enum_leave_donate_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Leave_Donate_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Leave_Donate_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Leave_Donate_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Leave_Donate_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Leave_Donate_Type_Enum>>;
};

/** input type for inserting data into table "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  leave_donates?: InputMaybe<Leave_Donate_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Leave_Donate_Type_Max_Fields = {
  __typename?: 'enum_leave_donate_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Leave_Donate_Type_Min_Fields = {
  __typename?: 'enum_leave_donate_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_Mutation_Response = {
  __typename?: 'enum_leave_donate_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Leave_Donate_Type>;
};

/** input type for inserting object relation for remote table "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_Obj_Rel_Insert_Input = {
  data: Enum_Leave_Donate_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Leave_Donate_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_On_Conflict = {
  constraint: Enum_Leave_Donate_Type_Constraint;
  update_columns?: Array<Enum_Leave_Donate_Type_Update_Column>;
  where?: InputMaybe<Enum_Leave_Donate_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_leave_donate_type". */
export type Enum_Leave_Donate_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  leave_donates_aggregate?: InputMaybe<Leave_Donate_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_leave_donate_type */
export type Enum_Leave_Donate_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_leave_donate_type" */
export enum Enum_Leave_Donate_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_leave_donate_type" */
export type Enum_Leave_Donate_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Leave_Donate_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Leave_Donate_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_leave_donate_type" */
export enum Enum_Leave_Donate_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Leave_Donate_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Leave_Donate_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Leave_Donate_Type_Bool_Exp;
};

/** columns and relationships of "enum_leave_status_type" */
export type Enum_Leave_Status_Type = {
  __typename?: 'enum_leave_status_type';
  comment: Scalars['String'];
  /** An array relationship */
  leaves: Array<Leave>;
  /** An aggregate relationship */
  leaves_aggregate: Leave_Aggregate;
  /** An array relationship */
  organization_user_leave_applications: Array<Organization_User_Leave_Application>;
  /** An aggregate relationship */
  organization_user_leave_applications_aggregate: Organization_User_Leave_Application_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_leave_status_type" */
export type Enum_Leave_Status_TypeLeavesArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

/** columns and relationships of "enum_leave_status_type" */
export type Enum_Leave_Status_TypeLeaves_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

/** columns and relationships of "enum_leave_status_type" */
export type Enum_Leave_Status_TypeOrganization_User_Leave_ApplicationsArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** columns and relationships of "enum_leave_status_type" */
export type Enum_Leave_Status_TypeOrganization_User_Leave_Applications_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Organization_User_Leave_Application_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
    where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  };

/** aggregated selection of "enum_leave_status_type" */
export type Enum_Leave_Status_Type_Aggregate = {
  __typename?: 'enum_leave_status_type_aggregate';
  aggregate?: Maybe<Enum_Leave_Status_Type_Aggregate_Fields>;
  nodes: Array<Enum_Leave_Status_Type>;
};

/** aggregate fields of "enum_leave_status_type" */
export type Enum_Leave_Status_Type_Aggregate_Fields = {
  __typename?: 'enum_leave_status_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Leave_Status_Type_Max_Fields>;
  min?: Maybe<Enum_Leave_Status_Type_Min_Fields>;
};

/** aggregate fields of "enum_leave_status_type" */
export type Enum_Leave_Status_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Leave_Status_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_leave_status_type". All fields are combined with a logical 'AND'. */
export type Enum_Leave_Status_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Leave_Status_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Leave_Status_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  leaves?: InputMaybe<Leave_Bool_Exp>;
  leaves_aggregate?: InputMaybe<Leave_Aggregate_Bool_Exp>;
  organization_user_leave_applications?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  organization_user_leave_applications_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_leave_status_type" */
export enum Enum_Leave_Status_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumLeaveStatusTypePkey = 'enum_leave_status_type_pkey',
}

export enum Enum_Leave_Status_Type_Enum {
  /** The leave is accepted by manager. */
  Accepted = 'ACCEPTED',
  /** Accepted a delete request for a leave */
  DeleteAccepted = 'DELETE_ACCEPTED',
  /** The leave is requested to be deleted */
  DeletePending = 'DELETE_PENDING',
  /** Rejected a delete request for a leave */
  DeleteRejected = 'DELETE_REJECTED',
  /** The leave is waiting for approval. */
  Pending = 'PENDING',
  /** The leave is rejected by manager. */
  Rejected = 'REJECTED',
}

/** Boolean expression to compare columns of type "enum_leave_status_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Leave_Status_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Leave_Status_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Leave_Status_Type_Enum>>;
};

/** input type for inserting data into table "enum_leave_status_type" */
export type Enum_Leave_Status_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  leaves?: InputMaybe<Leave_Arr_Rel_Insert_Input>;
  organization_user_leave_applications?: InputMaybe<Organization_User_Leave_Application_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Leave_Status_Type_Max_Fields = {
  __typename?: 'enum_leave_status_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Leave_Status_Type_Min_Fields = {
  __typename?: 'enum_leave_status_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_leave_status_type" */
export type Enum_Leave_Status_Type_Mutation_Response = {
  __typename?: 'enum_leave_status_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Leave_Status_Type>;
};

/** input type for inserting object relation for remote table "enum_leave_status_type" */
export type Enum_Leave_Status_Type_Obj_Rel_Insert_Input = {
  data: Enum_Leave_Status_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Leave_Status_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_leave_status_type" */
export type Enum_Leave_Status_Type_On_Conflict = {
  constraint: Enum_Leave_Status_Type_Constraint;
  update_columns?: Array<Enum_Leave_Status_Type_Update_Column>;
  where?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_leave_status_type". */
export type Enum_Leave_Status_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  leaves_aggregate?: InputMaybe<Leave_Aggregate_Order_By>;
  organization_user_leave_applications_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_leave_status_type */
export type Enum_Leave_Status_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_leave_status_type" */
export enum Enum_Leave_Status_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_leave_status_type" */
export type Enum_Leave_Status_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_leave_status_type" */
export type Enum_Leave_Status_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Leave_Status_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Leave_Status_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_leave_status_type" */
export enum Enum_Leave_Status_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Leave_Status_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Leave_Status_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Leave_Status_Type_Bool_Exp;
};

/** columns and relationships of "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status = {
  __typename?: 'enum_live_call_request_status';
  comment: Scalars['String'];
  /** An array relationship */
  live_call_requests: Array<Live_Call_Request>;
  /** An aggregate relationship */
  live_call_requests_aggregate: Live_Call_Request_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_live_call_request_status" */
export type Enum_Live_Call_Request_StatusLive_Call_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** columns and relationships of "enum_live_call_request_status" */
export type Enum_Live_Call_Request_StatusLive_Call_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** aggregated selection of "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_Aggregate = {
  __typename?: 'enum_live_call_request_status_aggregate';
  aggregate?: Maybe<Enum_Live_Call_Request_Status_Aggregate_Fields>;
  nodes: Array<Enum_Live_Call_Request_Status>;
};

/** aggregate fields of "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_Aggregate_Fields = {
  __typename?: 'enum_live_call_request_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Live_Call_Request_Status_Max_Fields>;
  min?: Maybe<Enum_Live_Call_Request_Status_Min_Fields>;
};

/** aggregate fields of "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Live_Call_Request_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_live_call_request_status". All fields are combined with a logical 'AND'. */
export type Enum_Live_Call_Request_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Live_Call_Request_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Live_Call_Request_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Live_Call_Request_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  live_call_requests?: InputMaybe<Live_Call_Request_Bool_Exp>;
  live_call_requests_aggregate?: InputMaybe<Live_Call_Request_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_live_call_request_status" */
export enum Enum_Live_Call_Request_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  LiveCallRequestStatusPkey = 'live_call_request_status_pkey',
}

export enum Enum_Live_Call_Request_Status_Enum {
  /** Request accepted */
  Accepted = 'ACCEPTED',
  /** Request auto canceled by system */
  Cancelled = 'CANCELLED',
  /** Request pending */
  Pending = 'PENDING',
  /** Request rejected */
  Rejected = 'REJECTED',
}

/** Boolean expression to compare columns of type "enum_live_call_request_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Live_Call_Request_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Live_Call_Request_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Live_Call_Request_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Live_Call_Request_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Live_Call_Request_Status_Enum>>;
};

/** input type for inserting data into table "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  live_call_requests?: InputMaybe<Live_Call_Request_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Live_Call_Request_Status_Max_Fields = {
  __typename?: 'enum_live_call_request_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Live_Call_Request_Status_Min_Fields = {
  __typename?: 'enum_live_call_request_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_Mutation_Response = {
  __typename?: 'enum_live_call_request_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Live_Call_Request_Status>;
};

/** input type for inserting object relation for remote table "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_Obj_Rel_Insert_Input = {
  data: Enum_Live_Call_Request_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Live_Call_Request_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_On_Conflict = {
  constraint: Enum_Live_Call_Request_Status_Constraint;
  update_columns?: Array<Enum_Live_Call_Request_Status_Update_Column>;
  where?: InputMaybe<Enum_Live_Call_Request_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_live_call_request_status". */
export type Enum_Live_Call_Request_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  live_call_requests_aggregate?: InputMaybe<Live_Call_Request_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_live_call_request_status */
export type Enum_Live_Call_Request_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_live_call_request_status" */
export enum Enum_Live_Call_Request_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_live_call_request_status" */
export type Enum_Live_Call_Request_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Live_Call_Request_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Live_Call_Request_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_live_call_request_status" */
export enum Enum_Live_Call_Request_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Live_Call_Request_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Live_Call_Request_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Live_Call_Request_Status_Bool_Exp;
};

/** columns and relationships of "enum_live_call_status" */
export type Enum_Live_Call_Status = {
  __typename?: 'enum_live_call_status';
  comment: Scalars['String'];
  /** An array relationship */
  live_calls: Array<Live_Call>;
  /** An aggregate relationship */
  live_calls_aggregate: Live_Call_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_live_call_status" */
export type Enum_Live_Call_StatusLive_CallsArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Order_By>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

/** columns and relationships of "enum_live_call_status" */
export type Enum_Live_Call_StatusLive_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Order_By>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

/** aggregated selection of "enum_live_call_status" */
export type Enum_Live_Call_Status_Aggregate = {
  __typename?: 'enum_live_call_status_aggregate';
  aggregate?: Maybe<Enum_Live_Call_Status_Aggregate_Fields>;
  nodes: Array<Enum_Live_Call_Status>;
};

/** aggregate fields of "enum_live_call_status" */
export type Enum_Live_Call_Status_Aggregate_Fields = {
  __typename?: 'enum_live_call_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Live_Call_Status_Max_Fields>;
  min?: Maybe<Enum_Live_Call_Status_Min_Fields>;
};

/** aggregate fields of "enum_live_call_status" */
export type Enum_Live_Call_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Live_Call_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_live_call_status". All fields are combined with a logical 'AND'. */
export type Enum_Live_Call_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Live_Call_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Live_Call_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Live_Call_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  live_calls?: InputMaybe<Live_Call_Bool_Exp>;
  live_calls_aggregate?: InputMaybe<Live_Call_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_live_call_status" */
export enum Enum_Live_Call_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumLiveCallStatusPkey = 'enum_live_call_status_pkey',
}

export enum Enum_Live_Call_Status_Enum {
  /** Live call auto cancelled by system */
  Cancelled = 'CANCELLED',
  /** Live call closed by user */
  Ended = 'ENDED',
  /** Live call is in progress */
  InProgress = 'IN_PROGRESS',
  /** Live call is in pending state */
  Pending = 'PENDING',
}

/** Boolean expression to compare columns of type "enum_live_call_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Live_Call_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Live_Call_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Live_Call_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Live_Call_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Live_Call_Status_Enum>>;
};

/** input type for inserting data into table "enum_live_call_status" */
export type Enum_Live_Call_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  live_calls?: InputMaybe<Live_Call_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Live_Call_Status_Max_Fields = {
  __typename?: 'enum_live_call_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Live_Call_Status_Min_Fields = {
  __typename?: 'enum_live_call_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_live_call_status" */
export type Enum_Live_Call_Status_Mutation_Response = {
  __typename?: 'enum_live_call_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Live_Call_Status>;
};

/** input type for inserting object relation for remote table "enum_live_call_status" */
export type Enum_Live_Call_Status_Obj_Rel_Insert_Input = {
  data: Enum_Live_Call_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Live_Call_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_live_call_status" */
export type Enum_Live_Call_Status_On_Conflict = {
  constraint: Enum_Live_Call_Status_Constraint;
  update_columns?: Array<Enum_Live_Call_Status_Update_Column>;
  where?: InputMaybe<Enum_Live_Call_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_live_call_status". */
export type Enum_Live_Call_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  live_calls_aggregate?: InputMaybe<Live_Call_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_live_call_status */
export type Enum_Live_Call_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_live_call_status" */
export enum Enum_Live_Call_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_live_call_status" */
export type Enum_Live_Call_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_live_call_status" */
export type Enum_Live_Call_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Live_Call_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Live_Call_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_live_call_status" */
export enum Enum_Live_Call_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Live_Call_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Live_Call_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Live_Call_Status_Bool_Exp;
};

/** columns and relationships of "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status = {
  __typename?: 'enum_live_call_user_status';
  comment: Scalars['String'];
  /** An array relationship */
  live_call_users: Array<Live_Call_User>;
  /** An aggregate relationship */
  live_call_users_aggregate: Live_Call_User_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_live_call_user_status" */
export type Enum_Live_Call_User_StatusLive_Call_UsersArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** columns and relationships of "enum_live_call_user_status" */
export type Enum_Live_Call_User_StatusLive_Call_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** aggregated selection of "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_Aggregate = {
  __typename?: 'enum_live_call_user_status_aggregate';
  aggregate?: Maybe<Enum_Live_Call_User_Status_Aggregate_Fields>;
  nodes: Array<Enum_Live_Call_User_Status>;
};

/** aggregate fields of "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_Aggregate_Fields = {
  __typename?: 'enum_live_call_user_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Live_Call_User_Status_Max_Fields>;
  min?: Maybe<Enum_Live_Call_User_Status_Min_Fields>;
};

/** aggregate fields of "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Live_Call_User_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_live_call_user_status". All fields are combined with a logical 'AND'. */
export type Enum_Live_Call_User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Live_Call_User_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Live_Call_User_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Live_Call_User_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  live_call_users?: InputMaybe<Live_Call_User_Bool_Exp>;
  live_call_users_aggregate?: InputMaybe<Live_Call_User_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_live_call_user_status" */
export enum Enum_Live_Call_User_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumLiveCallUserStatusPkey = 'enum_live_call_user_status_pkey',
}

export enum Enum_Live_Call_User_Status_Enum {
  /** user is active in the call */
  Active = 'ACTIVE',
  /** user is only viewing in the call */
  ActiveConsumer = 'ACTIVE_CONSUMER',
  /** user is sharing but not viewing in the call */
  ActiveProvider = 'ACTIVE_PROVIDER',
  /** user is inactive in the call */
  Inactive = 'INACTIVE',
}

/** Boolean expression to compare columns of type "enum_live_call_user_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Live_Call_User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Live_Call_User_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Live_Call_User_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Live_Call_User_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Live_Call_User_Status_Enum>>;
};

/** input type for inserting data into table "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  live_call_users?: InputMaybe<Live_Call_User_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Live_Call_User_Status_Max_Fields = {
  __typename?: 'enum_live_call_user_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Live_Call_User_Status_Min_Fields = {
  __typename?: 'enum_live_call_user_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_Mutation_Response = {
  __typename?: 'enum_live_call_user_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Live_Call_User_Status>;
};

/** input type for inserting object relation for remote table "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_Obj_Rel_Insert_Input = {
  data: Enum_Live_Call_User_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Live_Call_User_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_On_Conflict = {
  constraint: Enum_Live_Call_User_Status_Constraint;
  update_columns?: Array<Enum_Live_Call_User_Status_Update_Column>;
  where?: InputMaybe<Enum_Live_Call_User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_live_call_user_status". */
export type Enum_Live_Call_User_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  live_call_users_aggregate?: InputMaybe<Live_Call_User_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_live_call_user_status */
export type Enum_Live_Call_User_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_live_call_user_status" */
export enum Enum_Live_Call_User_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_live_call_user_status" */
export type Enum_Live_Call_User_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Live_Call_User_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Live_Call_User_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_live_call_user_status" */
export enum Enum_Live_Call_User_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Live_Call_User_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Live_Call_User_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Live_Call_User_Status_Bool_Exp;
};

/** columns and relationships of "enum_live_emote_type" */
export type Enum_Live_Emote_Type = {
  __typename?: 'enum_live_emote_type';
  /** An array relationship */
  chat_message_reactions: Array<Chat_Message_Reactions>;
  /** An aggregate relationship */
  chat_message_reactions_aggregate: Chat_Message_Reactions_Aggregate;
  /** An array relationship */
  chat_messages: Array<Chat_Message>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Message_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "enum_live_emote_type" */
export type Enum_Live_Emote_TypeChat_Message_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Reactions_Order_By>>;
  where?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
};

/** columns and relationships of "enum_live_emote_type" */
export type Enum_Live_Emote_TypeChat_Message_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Reactions_Order_By>>;
  where?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
};

/** columns and relationships of "enum_live_emote_type" */
export type Enum_Live_Emote_TypeChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** columns and relationships of "enum_live_emote_type" */
export type Enum_Live_Emote_TypeChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** aggregated selection of "enum_live_emote_type" */
export type Enum_Live_Emote_Type_Aggregate = {
  __typename?: 'enum_live_emote_type_aggregate';
  aggregate?: Maybe<Enum_Live_Emote_Type_Aggregate_Fields>;
  nodes: Array<Enum_Live_Emote_Type>;
};

/** aggregate fields of "enum_live_emote_type" */
export type Enum_Live_Emote_Type_Aggregate_Fields = {
  __typename?: 'enum_live_emote_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Live_Emote_Type_Max_Fields>;
  min?: Maybe<Enum_Live_Emote_Type_Min_Fields>;
};

/** aggregate fields of "enum_live_emote_type" */
export type Enum_Live_Emote_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Live_Emote_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_live_emote_type". All fields are combined with a logical 'AND'. */
export type Enum_Live_Emote_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Live_Emote_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Live_Emote_Type_Bool_Exp>>;
  chat_message_reactions?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
  chat_message_reactions_aggregate?: InputMaybe<Chat_Message_Reactions_Aggregate_Bool_Exp>;
  chat_messages?: InputMaybe<Chat_Message_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_live_emote_type" */
export enum Enum_Live_Emote_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumLiveEmoteTypePkey = 'enum_live_emote_type_pkey',
}

export enum Enum_Live_Emote_Type_Enum {
  /** emote of clap */
  Clap = 'CLAP',
  /** emote of partying face */
  PartyingFace = 'PARTYING_FACE',
}

/** Boolean expression to compare columns of type "enum_live_emote_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Live_Emote_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Live_Emote_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Live_Emote_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Live_Emote_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Live_Emote_Type_Enum>>;
};

/** input type for inserting data into table "enum_live_emote_type" */
export type Enum_Live_Emote_Type_Insert_Input = {
  chat_message_reactions?: InputMaybe<Chat_Message_Reactions_Arr_Rel_Insert_Input>;
  chat_messages?: InputMaybe<Chat_Message_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Live_Emote_Type_Max_Fields = {
  __typename?: 'enum_live_emote_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Live_Emote_Type_Min_Fields = {
  __typename?: 'enum_live_emote_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_live_emote_type" */
export type Enum_Live_Emote_Type_Mutation_Response = {
  __typename?: 'enum_live_emote_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Live_Emote_Type>;
};

/** input type for inserting object relation for remote table "enum_live_emote_type" */
export type Enum_Live_Emote_Type_Obj_Rel_Insert_Input = {
  data: Enum_Live_Emote_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Live_Emote_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_live_emote_type" */
export type Enum_Live_Emote_Type_On_Conflict = {
  constraint: Enum_Live_Emote_Type_Constraint;
  update_columns?: Array<Enum_Live_Emote_Type_Update_Column>;
  where?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_live_emote_type". */
export type Enum_Live_Emote_Type_Order_By = {
  chat_message_reactions_aggregate?: InputMaybe<Chat_Message_Reactions_Aggregate_Order_By>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_live_emote_type */
export type Enum_Live_Emote_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_live_emote_type" */
export enum Enum_Live_Emote_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_live_emote_type" */
export type Enum_Live_Emote_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_live_emote_type" */
export type Enum_Live_Emote_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Live_Emote_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Live_Emote_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_live_emote_type" */
export enum Enum_Live_Emote_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Live_Emote_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Live_Emote_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Live_Emote_Type_Bool_Exp;
};

/** columns and relationships of "enum_manual_state" */
export type Enum_Manual_State = {
  __typename?: 'enum_manual_state';
  comment: Scalars['String'];
  /** An array relationship */
  user_states: Array<User_State>;
  /** An aggregate relationship */
  user_states_aggregate: User_State_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_manual_state" */
export type Enum_Manual_StateUser_StatesArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** columns and relationships of "enum_manual_state" */
export type Enum_Manual_StateUser_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** aggregated selection of "enum_manual_state" */
export type Enum_Manual_State_Aggregate = {
  __typename?: 'enum_manual_state_aggregate';
  aggregate?: Maybe<Enum_Manual_State_Aggregate_Fields>;
  nodes: Array<Enum_Manual_State>;
};

/** aggregate fields of "enum_manual_state" */
export type Enum_Manual_State_Aggregate_Fields = {
  __typename?: 'enum_manual_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Manual_State_Max_Fields>;
  min?: Maybe<Enum_Manual_State_Min_Fields>;
};

/** aggregate fields of "enum_manual_state" */
export type Enum_Manual_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Manual_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_manual_state". All fields are combined with a logical 'AND'. */
export type Enum_Manual_State_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Manual_State_Bool_Exp>>;
  _not?: InputMaybe<Enum_Manual_State_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Manual_State_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_states?: InputMaybe<User_State_Bool_Exp>;
  user_states_aggregate?: InputMaybe<User_State_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_manual_state" */
export enum Enum_Manual_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  ManualStatePkey = 'manual_state_pkey',
}

export enum Enum_Manual_State_Enum {
  /** The user is DND mode */
  Dnd = 'DND',
  /** The user is available  */
  Online = 'ONLINE',
  /** The user is relax mode */
  Relax = 'RELAX',
}

/** Boolean expression to compare columns of type "enum_manual_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Manual_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Manual_State_Enum>;
  _in?: InputMaybe<Array<Enum_Manual_State_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Manual_State_Enum>;
  _nin?: InputMaybe<Array<Enum_Manual_State_Enum>>;
};

/** input type for inserting data into table "enum_manual_state" */
export type Enum_Manual_State_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_states?: InputMaybe<User_State_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Manual_State_Max_Fields = {
  __typename?: 'enum_manual_state_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Manual_State_Min_Fields = {
  __typename?: 'enum_manual_state_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_manual_state" */
export type Enum_Manual_State_Mutation_Response = {
  __typename?: 'enum_manual_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Manual_State>;
};

/** input type for inserting object relation for remote table "enum_manual_state" */
export type Enum_Manual_State_Obj_Rel_Insert_Input = {
  data: Enum_Manual_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Manual_State_On_Conflict>;
};

/** on_conflict condition type for table "enum_manual_state" */
export type Enum_Manual_State_On_Conflict = {
  constraint: Enum_Manual_State_Constraint;
  update_columns?: Array<Enum_Manual_State_Update_Column>;
  where?: InputMaybe<Enum_Manual_State_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_manual_state". */
export type Enum_Manual_State_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_states_aggregate?: InputMaybe<User_State_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_manual_state */
export type Enum_Manual_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_manual_state" */
export enum Enum_Manual_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_manual_state" */
export type Enum_Manual_State_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_manual_state" */
export type Enum_Manual_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Manual_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Manual_State_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_manual_state" */
export enum Enum_Manual_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Manual_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Manual_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Manual_State_Bool_Exp;
};

/** Status type for a meeting room */
export type Enum_Meeting_Room_Status = {
  __typename?: 'enum_meeting_room_status';
  comment: Scalars['String'];
  /** An array relationship */
  meeting_rooms: Array<Meeting_Room>;
  /** An aggregate relationship */
  meeting_rooms_aggregate: Meeting_Room_Aggregate;
  value: Scalars['String'];
};

/** Status type for a meeting room */
export type Enum_Meeting_Room_StatusMeeting_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** Status type for a meeting room */
export type Enum_Meeting_Room_StatusMeeting_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** aggregated selection of "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_Aggregate = {
  __typename?: 'enum_meeting_room_status_aggregate';
  aggregate?: Maybe<Enum_Meeting_Room_Status_Aggregate_Fields>;
  nodes: Array<Enum_Meeting_Room_Status>;
};

/** aggregate fields of "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_Aggregate_Fields = {
  __typename?: 'enum_meeting_room_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Meeting_Room_Status_Max_Fields>;
  min?: Maybe<Enum_Meeting_Room_Status_Min_Fields>;
};

/** aggregate fields of "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Meeting_Room_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_meeting_room_status". All fields are combined with a logical 'AND'. */
export type Enum_Meeting_Room_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Meeting_Room_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Meeting_Room_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Meeting_Room_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  meeting_rooms?: InputMaybe<Meeting_Room_Bool_Exp>;
  meeting_rooms_aggregate?: InputMaybe<Meeting_Room_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_meeting_room_status" */
export enum Enum_Meeting_Room_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMeetingRoomStatusPkey = 'enum_meeting_room_status_pkey',
}

export enum Enum_Meeting_Room_Status_Enum {
  /** The meeting room is available for meetings */
  Available = 'AVAILABLE',
  /** The meeting room is engaged and not available */
  Busy = 'BUSY',
}

/** Boolean expression to compare columns of type "enum_meeting_room_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Meeting_Room_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Meeting_Room_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Meeting_Room_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Meeting_Room_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Meeting_Room_Status_Enum>>;
};

/** input type for inserting data into table "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  meeting_rooms?: InputMaybe<Meeting_Room_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Meeting_Room_Status_Max_Fields = {
  __typename?: 'enum_meeting_room_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Meeting_Room_Status_Min_Fields = {
  __typename?: 'enum_meeting_room_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_Mutation_Response = {
  __typename?: 'enum_meeting_room_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Meeting_Room_Status>;
};

/** input type for inserting object relation for remote table "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_Obj_Rel_Insert_Input = {
  data: Enum_Meeting_Room_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Meeting_Room_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_On_Conflict = {
  constraint: Enum_Meeting_Room_Status_Constraint;
  update_columns?: Array<Enum_Meeting_Room_Status_Update_Column>;
  where?: InputMaybe<Enum_Meeting_Room_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_meeting_room_status". */
export type Enum_Meeting_Room_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  meeting_rooms_aggregate?: InputMaybe<Meeting_Room_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_meeting_room_status */
export type Enum_Meeting_Room_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_meeting_room_status" */
export enum Enum_Meeting_Room_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_meeting_room_status" */
export type Enum_Meeting_Room_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Meeting_Room_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Meeting_Room_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_meeting_room_status" */
export enum Enum_Meeting_Room_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Meeting_Room_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Meeting_Room_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Meeting_Room_Status_Bool_Exp;
};

/** Types of meeting room an organization can take */
export type Enum_Meeting_Room_Type = {
  __typename?: 'enum_meeting_room_type';
  comment: Scalars['String'];
  /** An array relationship */
  meeting_rooms: Array<Meeting_Room>;
  /** An aggregate relationship */
  meeting_rooms_aggregate: Meeting_Room_Aggregate;
  value: Scalars['String'];
};

/** Types of meeting room an organization can take */
export type Enum_Meeting_Room_TypeMeeting_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** Types of meeting room an organization can take */
export type Enum_Meeting_Room_TypeMeeting_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** aggregated selection of "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_Aggregate = {
  __typename?: 'enum_meeting_room_type_aggregate';
  aggregate?: Maybe<Enum_Meeting_Room_Type_Aggregate_Fields>;
  nodes: Array<Enum_Meeting_Room_Type>;
};

/** aggregate fields of "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_Aggregate_Fields = {
  __typename?: 'enum_meeting_room_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Meeting_Room_Type_Max_Fields>;
  min?: Maybe<Enum_Meeting_Room_Type_Min_Fields>;
};

/** aggregate fields of "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Meeting_Room_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_meeting_room_type". All fields are combined with a logical 'AND'. */
export type Enum_Meeting_Room_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Meeting_Room_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Meeting_Room_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Meeting_Room_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  meeting_rooms?: InputMaybe<Meeting_Room_Bool_Exp>;
  meeting_rooms_aggregate?: InputMaybe<Meeting_Room_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_meeting_room_type" */
export enum Enum_Meeting_Room_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMeetingRoomTypePkey = 'enum_meeting_room_type_pkey',
}

export enum Enum_Meeting_Room_Type_Enum {
  /** Agra Fort meeting room */
  AgraFort = 'AGRA_FORT',
  /** Big Ben meeting room */
  BigBen = 'BIG_BEN',
  /** Capitol Hill meeting room */
  CapitolHill = 'CAPITOL_HILL',
  /** Golden Gate meeting room */
  GoldenGate = 'GOLDEN_GATE',
  /** London Eye meeting room */
  LondonEye = 'LONDON_EYE',
  /** Taj Mahal meeting room */
  TajMahal = 'TAJ_MAHAL',
}

/** Boolean expression to compare columns of type "enum_meeting_room_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Meeting_Room_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Meeting_Room_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Meeting_Room_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Meeting_Room_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Meeting_Room_Type_Enum>>;
};

/** input type for inserting data into table "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  meeting_rooms?: InputMaybe<Meeting_Room_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Meeting_Room_Type_Max_Fields = {
  __typename?: 'enum_meeting_room_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Meeting_Room_Type_Min_Fields = {
  __typename?: 'enum_meeting_room_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_Mutation_Response = {
  __typename?: 'enum_meeting_room_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Meeting_Room_Type>;
};

/** input type for inserting object relation for remote table "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_Obj_Rel_Insert_Input = {
  data: Enum_Meeting_Room_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Meeting_Room_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_On_Conflict = {
  constraint: Enum_Meeting_Room_Type_Constraint;
  update_columns?: Array<Enum_Meeting_Room_Type_Update_Column>;
  where?: InputMaybe<Enum_Meeting_Room_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_meeting_room_type". */
export type Enum_Meeting_Room_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  meeting_rooms_aggregate?: InputMaybe<Meeting_Room_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_meeting_room_type */
export type Enum_Meeting_Room_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_meeting_room_type" */
export enum Enum_Meeting_Room_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_meeting_room_type" */
export type Enum_Meeting_Room_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Meeting_Room_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Meeting_Room_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_meeting_room_type" */
export enum Enum_Meeting_Room_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Meeting_Room_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Meeting_Room_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Meeting_Room_Type_Bool_Exp;
};

/** Meeting room users state according to their current status. */
export type Enum_Meeting_Room_User_State = {
  __typename?: 'enum_meeting_room_user_state';
  comment: Scalars['String'];
  /** An array relationship */
  meeting_room_users: Array<Meeting_Room_Users>;
  /** An aggregate relationship */
  meeting_room_users_aggregate: Meeting_Room_Users_Aggregate;
  value: Scalars['String'];
};

/** Meeting room users state according to their current status. */
export type Enum_Meeting_Room_User_StateMeeting_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** Meeting room users state according to their current status. */
export type Enum_Meeting_Room_User_StateMeeting_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** aggregated selection of "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_Aggregate = {
  __typename?: 'enum_meeting_room_user_state_aggregate';
  aggregate?: Maybe<Enum_Meeting_Room_User_State_Aggregate_Fields>;
  nodes: Array<Enum_Meeting_Room_User_State>;
};

/** aggregate fields of "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_Aggregate_Fields = {
  __typename?: 'enum_meeting_room_user_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Meeting_Room_User_State_Max_Fields>;
  min?: Maybe<Enum_Meeting_Room_User_State_Min_Fields>;
};

/** aggregate fields of "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Meeting_Room_User_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_meeting_room_user_state". All fields are combined with a logical 'AND'. */
export type Enum_Meeting_Room_User_State_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Meeting_Room_User_State_Bool_Exp>>;
  _not?: InputMaybe<Enum_Meeting_Room_User_State_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Meeting_Room_User_State_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  meeting_room_users?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
  meeting_room_users_aggregate?: InputMaybe<Meeting_Room_Users_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_meeting_room_user_state" */
export enum Enum_Meeting_Room_User_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMeetingRoomUserStatePkey = 'enum_meeting_room_user_state_pkey',
}

export enum Enum_Meeting_Room_User_State_Enum {
  /** The invitation is declined */
  Declined = 'DECLINED',
  /** The user is disconnected somehow */
  Disconnected = 'DISCONNECTED',
  /** A user is invited to the meeting room but not joined */
  Invited = 'INVITED',
  /** The user has accepted the meeting room invitation and is in the meeting room */
  Joined = 'JOINED',
  /** The invite is sent but the user has not accepted the invitation */
  NotAccepted = 'NOT_ACCEPTED',
}

/** Boolean expression to compare columns of type "enum_meeting_room_user_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Meeting_Room_User_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Meeting_Room_User_State_Enum>;
  _in?: InputMaybe<Array<Enum_Meeting_Room_User_State_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Meeting_Room_User_State_Enum>;
  _nin?: InputMaybe<Array<Enum_Meeting_Room_User_State_Enum>>;
};

/** input type for inserting data into table "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  meeting_room_users?: InputMaybe<Meeting_Room_Users_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Meeting_Room_User_State_Max_Fields = {
  __typename?: 'enum_meeting_room_user_state_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Meeting_Room_User_State_Min_Fields = {
  __typename?: 'enum_meeting_room_user_state_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_Mutation_Response = {
  __typename?: 'enum_meeting_room_user_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Meeting_Room_User_State>;
};

/** input type for inserting object relation for remote table "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_Obj_Rel_Insert_Input = {
  data: Enum_Meeting_Room_User_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Meeting_Room_User_State_On_Conflict>;
};

/** on_conflict condition type for table "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_On_Conflict = {
  constraint: Enum_Meeting_Room_User_State_Constraint;
  update_columns?: Array<Enum_Meeting_Room_User_State_Update_Column>;
  where?: InputMaybe<Enum_Meeting_Room_User_State_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_meeting_room_user_state". */
export type Enum_Meeting_Room_User_State_Order_By = {
  comment?: InputMaybe<Order_By>;
  meeting_room_users_aggregate?: InputMaybe<Meeting_Room_Users_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_meeting_room_user_state */
export type Enum_Meeting_Room_User_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_meeting_room_user_state" */
export enum Enum_Meeting_Room_User_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_meeting_room_user_state" */
export type Enum_Meeting_Room_User_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Meeting_Room_User_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Meeting_Room_User_State_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_meeting_room_user_state" */
export enum Enum_Meeting_Room_User_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Meeting_Room_User_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Meeting_Room_User_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Meeting_Room_User_State_Bool_Exp;
};

/** columns and relationships of "enum_notification_type" */
export type Enum_Notification_Type = {
  __typename?: 'enum_notification_type';
  comment: Scalars['String'];
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_notification_type" */
export type Enum_Notification_TypeNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "enum_notification_type" */
export type Enum_Notification_TypeNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** aggregated selection of "enum_notification_type" */
export type Enum_Notification_Type_Aggregate = {
  __typename?: 'enum_notification_type_aggregate';
  aggregate?: Maybe<Enum_Notification_Type_Aggregate_Fields>;
  nodes: Array<Enum_Notification_Type>;
};

/** aggregate fields of "enum_notification_type" */
export type Enum_Notification_Type_Aggregate_Fields = {
  __typename?: 'enum_notification_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Notification_Type_Max_Fields>;
  min?: Maybe<Enum_Notification_Type_Min_Fields>;
};

/** aggregate fields of "enum_notification_type" */
export type Enum_Notification_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Notification_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_notification_type". All fields are combined with a logical 'AND'. */
export type Enum_Notification_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Notification_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Notification_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_notification_type" */
export enum Enum_Notification_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumNotificationTypePkey = 'enum_notification_type_pkey',
}

export enum Enum_Notification_Type_Enum {
  /** Notification to add goals */
  AddGoals = 'ADD_GOALS',
  /** Notification when a call is missed */
  CallMissed = 'CALL_MISSED',
  /** The notification for new donated leaves */
  DonatedLeaveAdded = 'DONATED_LEAVE_ADDED',
  /** this enum type represents a notification of which informs the user about something */
  Information = 'INFORMATION',
  /** A notification trigger for leave status change. */
  LeaveStatusChanged = 'LEAVE_STATUS_CHANGED',
  /** An invitation to a meeting room */
  MeetingroomInvite = 'MEETINGROOM_INVITE',
  /** Invited a person to a meeting */
  MeetingInvited = 'MEETING_INVITED',
  /** Remind of a meeting happening soon */
  MeetingReminder = 'MEETING_REMINDER',
  /** You have been added as a Peer. */
  PeerAdded = 'PEER_ADDED',
  /** user/users got mentioned on a post comment */
  PostCommentMention = 'POST_COMMENT_MENTION',
  /** user/users got mentioned on a post */
  PostMention = 'POST_MENTION',
  /** New project member has been added */
  ProjectMemberAdded = 'PROJECT_MEMBER_ADDED',
  /** When task assigned changed notification */
  TaskAssignedChanged = 'TASK_ASSIGNED_CHANGED',
  /** Added or removed as a collaborator */
  TaskCollaborator = 'TASK_COLLABORATOR',
  /** Task commenting notification */
  TaskComment = 'TASK_COMMENT',
  /** To send task reminder notification */
  TaskReminder = 'TASK_REMINDER',
  /** Task status update notification */
  TaskStatusChanged = 'TASK_STATUS_CHANGED',
  /** A notification type for time sheet status change. */
  TimeSheetStatusChanged = 'TIME_SHEET_STATUS_CHANGED',
}

/** Boolean expression to compare columns of type "enum_notification_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Notification_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Notification_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Notification_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Notification_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Notification_Type_Enum>>;
};

/** input type for inserting data into table "enum_notification_type" */
export type Enum_Notification_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Notification_Type_Max_Fields = {
  __typename?: 'enum_notification_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Notification_Type_Min_Fields = {
  __typename?: 'enum_notification_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_notification_type" */
export type Enum_Notification_Type_Mutation_Response = {
  __typename?: 'enum_notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Notification_Type>;
};

/** input type for inserting object relation for remote table "enum_notification_type" */
export type Enum_Notification_Type_Obj_Rel_Insert_Input = {
  data: Enum_Notification_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Notification_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_notification_type" */
export type Enum_Notification_Type_On_Conflict = {
  constraint: Enum_Notification_Type_Constraint;
  update_columns?: Array<Enum_Notification_Type_Update_Column>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_notification_type". */
export type Enum_Notification_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_notification_type */
export type Enum_Notification_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_notification_type" */
export enum Enum_Notification_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_notification_type" */
export type Enum_Notification_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_notification_type" */
export type Enum_Notification_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Notification_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Notification_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_notification_type" */
export enum Enum_Notification_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Notification_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Notification_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Notification_Type_Bool_Exp;
};

/** columns and relationships of "enum_personalised_access" */
export type Enum_Personalised_Access = {
  __typename?: 'enum_personalised_access';
  comment: Scalars['String'];
  /** An array relationship */
  personaliseds: Array<Personalised>;
  /** An aggregate relationship */
  personaliseds_aggregate: Personalised_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_personalised_access" */
export type Enum_Personalised_AccessPersonalisedsArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** columns and relationships of "enum_personalised_access" */
export type Enum_Personalised_AccessPersonaliseds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** aggregated selection of "enum_personalised_access" */
export type Enum_Personalised_Access_Aggregate = {
  __typename?: 'enum_personalised_access_aggregate';
  aggregate?: Maybe<Enum_Personalised_Access_Aggregate_Fields>;
  nodes: Array<Enum_Personalised_Access>;
};

/** aggregate fields of "enum_personalised_access" */
export type Enum_Personalised_Access_Aggregate_Fields = {
  __typename?: 'enum_personalised_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Personalised_Access_Max_Fields>;
  min?: Maybe<Enum_Personalised_Access_Min_Fields>;
};

/** aggregate fields of "enum_personalised_access" */
export type Enum_Personalised_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Personalised_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_personalised_access". All fields are combined with a logical 'AND'. */
export type Enum_Personalised_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Personalised_Access_Bool_Exp>>;
  _not?: InputMaybe<Enum_Personalised_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Personalised_Access_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  personaliseds?: InputMaybe<Personalised_Bool_Exp>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_personalised_access" */
export enum Enum_Personalised_Access_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPersonalisedAccessPkey = 'enum_personalised_access_pkey',
}

export enum Enum_Personalised_Access_Enum {
  /** any one in the organization can access */
  OrganizationWide = 'ORGANIZATION_WIDE',
  /** who created can access */
  UserOnly = 'USER_ONLY',
}

/** Boolean expression to compare columns of type "enum_personalised_access_enum". All fields are combined with logical 'AND'. */
export type Enum_Personalised_Access_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Personalised_Access_Enum>;
  _in?: InputMaybe<Array<Enum_Personalised_Access_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Personalised_Access_Enum>;
  _nin?: InputMaybe<Array<Enum_Personalised_Access_Enum>>;
};

/** input type for inserting data into table "enum_personalised_access" */
export type Enum_Personalised_Access_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  personaliseds?: InputMaybe<Personalised_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Personalised_Access_Max_Fields = {
  __typename?: 'enum_personalised_access_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Personalised_Access_Min_Fields = {
  __typename?: 'enum_personalised_access_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_personalised_access" */
export type Enum_Personalised_Access_Mutation_Response = {
  __typename?: 'enum_personalised_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Personalised_Access>;
};

/** input type for inserting object relation for remote table "enum_personalised_access" */
export type Enum_Personalised_Access_Obj_Rel_Insert_Input = {
  data: Enum_Personalised_Access_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Personalised_Access_On_Conflict>;
};

/** on_conflict condition type for table "enum_personalised_access" */
export type Enum_Personalised_Access_On_Conflict = {
  constraint: Enum_Personalised_Access_Constraint;
  update_columns?: Array<Enum_Personalised_Access_Update_Column>;
  where?: InputMaybe<Enum_Personalised_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_personalised_access". */
export type Enum_Personalised_Access_Order_By = {
  comment?: InputMaybe<Order_By>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_personalised_access */
export type Enum_Personalised_Access_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_personalised_access" */
export enum Enum_Personalised_Access_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_personalised_access" */
export type Enum_Personalised_Access_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_personalised_access" */
export type Enum_Personalised_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Personalised_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Personalised_Access_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_personalised_access" */
export enum Enum_Personalised_Access_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Personalised_Access_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Personalised_Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Personalised_Access_Bool_Exp;
};

/** Categories used in personalised */
export type Enum_Personalised_Categories = {
  __typename?: 'enum_personalised_categories';
  comment: Scalars['String'];
  /** An array relationship */
  personaliseds: Array<Personalised>;
  /** An aggregate relationship */
  personaliseds_aggregate: Personalised_Aggregate;
  value: Scalars['String'];
};

/** Categories used in personalised */
export type Enum_Personalised_CategoriesPersonalisedsArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** Categories used in personalised */
export type Enum_Personalised_CategoriesPersonaliseds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** aggregated selection of "enum_personalised_categories" */
export type Enum_Personalised_Categories_Aggregate = {
  __typename?: 'enum_personalised_categories_aggregate';
  aggregate?: Maybe<Enum_Personalised_Categories_Aggregate_Fields>;
  nodes: Array<Enum_Personalised_Categories>;
};

/** aggregate fields of "enum_personalised_categories" */
export type Enum_Personalised_Categories_Aggregate_Fields = {
  __typename?: 'enum_personalised_categories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Personalised_Categories_Max_Fields>;
  min?: Maybe<Enum_Personalised_Categories_Min_Fields>;
};

/** aggregate fields of "enum_personalised_categories" */
export type Enum_Personalised_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Personalised_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_personalised_categories". All fields are combined with a logical 'AND'. */
export type Enum_Personalised_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Personalised_Categories_Bool_Exp>>;
  _not?: InputMaybe<Enum_Personalised_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Personalised_Categories_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  personaliseds?: InputMaybe<Personalised_Bool_Exp>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_personalised_categories" */
export enum Enum_Personalised_Categories_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPersonalisedCategoryPkey = 'enum_personalised_category_pkey',
}

export enum Enum_Personalised_Categories_Enum {
  /** A personal or public photo */
  Image = 'image',
  /** A joke audio */
  Joke = 'joke',
  /** A mediation audio */
  Meditate = 'meditate',
  /** A music audio */
  Music = 'music',
}

/** Boolean expression to compare columns of type "enum_personalised_categories_enum". All fields are combined with logical 'AND'. */
export type Enum_Personalised_Categories_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Personalised_Categories_Enum>;
  _in?: InputMaybe<Array<Enum_Personalised_Categories_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Personalised_Categories_Enum>;
  _nin?: InputMaybe<Array<Enum_Personalised_Categories_Enum>>;
};

/** input type for inserting data into table "enum_personalised_categories" */
export type Enum_Personalised_Categories_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  personaliseds?: InputMaybe<Personalised_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Personalised_Categories_Max_Fields = {
  __typename?: 'enum_personalised_categories_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Personalised_Categories_Min_Fields = {
  __typename?: 'enum_personalised_categories_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_personalised_categories" */
export type Enum_Personalised_Categories_Mutation_Response = {
  __typename?: 'enum_personalised_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Personalised_Categories>;
};

/** input type for inserting object relation for remote table "enum_personalised_categories" */
export type Enum_Personalised_Categories_Obj_Rel_Insert_Input = {
  data: Enum_Personalised_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Personalised_Categories_On_Conflict>;
};

/** on_conflict condition type for table "enum_personalised_categories" */
export type Enum_Personalised_Categories_On_Conflict = {
  constraint: Enum_Personalised_Categories_Constraint;
  update_columns?: Array<Enum_Personalised_Categories_Update_Column>;
  where?: InputMaybe<Enum_Personalised_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_personalised_categories". */
export type Enum_Personalised_Categories_Order_By = {
  comment?: InputMaybe<Order_By>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_personalised_categories */
export type Enum_Personalised_Categories_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_personalised_categories" */
export enum Enum_Personalised_Categories_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_personalised_categories" */
export type Enum_Personalised_Categories_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_personalised_categories" */
export type Enum_Personalised_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Personalised_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Personalised_Categories_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_personalised_categories" */
export enum Enum_Personalised_Categories_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Personalised_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Personalised_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Personalised_Categories_Bool_Exp;
};

/** columns and relationships of "enum_post_type" */
export type Enum_Post_Type = {
  __typename?: 'enum_post_type';
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_post_type" */
export type Enum_Post_TypePostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

/** columns and relationships of "enum_post_type" */
export type Enum_Post_TypePosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

/** aggregated selection of "enum_post_type" */
export type Enum_Post_Type_Aggregate = {
  __typename?: 'enum_post_type_aggregate';
  aggregate?: Maybe<Enum_Post_Type_Aggregate_Fields>;
  nodes: Array<Enum_Post_Type>;
};

/** aggregate fields of "enum_post_type" */
export type Enum_Post_Type_Aggregate_Fields = {
  __typename?: 'enum_post_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Post_Type_Max_Fields>;
  min?: Maybe<Enum_Post_Type_Min_Fields>;
};

/** aggregate fields of "enum_post_type" */
export type Enum_Post_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Post_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_post_type". All fields are combined with a logical 'AND'. */
export type Enum_Post_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Post_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Post_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Post_Type_Bool_Exp>>;
  posts?: InputMaybe<Posts_Bool_Exp>;
  posts_aggregate?: InputMaybe<Posts_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_post_type" */
export enum Enum_Post_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPostTypePkey = 'enum_post_type_pkey',
}

export enum Enum_Post_Type_Enum {
  Anniversaries = 'ANNIVERSARIES',
  Announcement = 'ANNOUNCEMENT',
  Birthdays = 'BIRTHDAYS',
  CompletedProject = 'COMPLETED_PROJECT',
  General = 'GENERAL',
  Holidays = 'HOLIDAYS',
  NewProject = 'NEW_PROJECT',
  Polls = 'POLLS',
  Quote = 'QUOTE',
  Welcome = 'WELCOME',
}

/** Boolean expression to compare columns of type "enum_post_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Post_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Post_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Post_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Post_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Post_Type_Enum>>;
};

/** input type for inserting data into table "enum_post_type" */
export type Enum_Post_Type_Insert_Input = {
  posts?: InputMaybe<Posts_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Post_Type_Max_Fields = {
  __typename?: 'enum_post_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Post_Type_Min_Fields = {
  __typename?: 'enum_post_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_post_type" */
export type Enum_Post_Type_Mutation_Response = {
  __typename?: 'enum_post_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Post_Type>;
};

/** input type for inserting object relation for remote table "enum_post_type" */
export type Enum_Post_Type_Obj_Rel_Insert_Input = {
  data: Enum_Post_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Post_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_post_type" */
export type Enum_Post_Type_On_Conflict = {
  constraint: Enum_Post_Type_Constraint;
  update_columns?: Array<Enum_Post_Type_Update_Column>;
  where?: InputMaybe<Enum_Post_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_post_type". */
export type Enum_Post_Type_Order_By = {
  posts_aggregate?: InputMaybe<Posts_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_post_type */
export type Enum_Post_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_post_type" */
export enum Enum_Post_Type_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_post_type" */
export type Enum_Post_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_post_type" */
export type Enum_Post_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Post_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Post_Type_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_post_type" */
export enum Enum_Post_Type_Update_Column {
  /** column name */
  Value = 'value',
}

export type Enum_Post_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Post_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Post_Type_Bool_Exp;
};

/** Presence connection status types */
export type Enum_Presence_Connection_Status = {
  __typename?: 'enum_presence_connection_status';
  comment: Scalars['String'];
  /** An array relationship */
  user_presences: Array<User_Presence>;
  /** An aggregate relationship */
  user_presences_aggregate: User_Presence_Aggregate;
  value: Scalars['String'];
};

/** Presence connection status types */
export type Enum_Presence_Connection_StatusUser_PresencesArgs = {
  distinct_on?: InputMaybe<Array<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

/** Presence connection status types */
export type Enum_Presence_Connection_StatusUser_Presences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

/** aggregated selection of "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_Aggregate = {
  __typename?: 'enum_presence_connection_status_aggregate';
  aggregate?: Maybe<Enum_Presence_Connection_Status_Aggregate_Fields>;
  nodes: Array<Enum_Presence_Connection_Status>;
};

/** aggregate fields of "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_Aggregate_Fields = {
  __typename?: 'enum_presence_connection_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Presence_Connection_Status_Max_Fields>;
  min?: Maybe<Enum_Presence_Connection_Status_Min_Fields>;
};

/** aggregate fields of "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Presence_Connection_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_presence_connection_status". All fields are combined with a logical 'AND'. */
export type Enum_Presence_Connection_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Presence_Connection_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Presence_Connection_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Presence_Connection_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_presences?: InputMaybe<User_Presence_Bool_Exp>;
  user_presences_aggregate?: InputMaybe<User_Presence_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_presence_connection_status" */
export enum Enum_Presence_Connection_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPresenceConnectionStatusPkey = 'enum_presence_connection_status_pkey',
}

export enum Enum_Presence_Connection_Status_Enum {
  /** Connected to the presence websocket */
  Connected = 'CONNECTED',
  /** Disconnected to the presence websocket */
  Disconnected = 'DISCONNECTED',
}

/** Boolean expression to compare columns of type "enum_presence_connection_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Presence_Connection_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Presence_Connection_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Presence_Connection_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Presence_Connection_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Presence_Connection_Status_Enum>>;
};

/** input type for inserting data into table "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_presences?: InputMaybe<User_Presence_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Presence_Connection_Status_Max_Fields = {
  __typename?: 'enum_presence_connection_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Presence_Connection_Status_Min_Fields = {
  __typename?: 'enum_presence_connection_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_Mutation_Response = {
  __typename?: 'enum_presence_connection_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Presence_Connection_Status>;
};

/** input type for inserting object relation for remote table "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_Obj_Rel_Insert_Input = {
  data: Enum_Presence_Connection_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Presence_Connection_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_On_Conflict = {
  constraint: Enum_Presence_Connection_Status_Constraint;
  update_columns?: Array<Enum_Presence_Connection_Status_Update_Column>;
  where?: InputMaybe<Enum_Presence_Connection_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_presence_connection_status". */
export type Enum_Presence_Connection_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_presences_aggregate?: InputMaybe<User_Presence_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_presence_connection_status */
export type Enum_Presence_Connection_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_presence_connection_status" */
export enum Enum_Presence_Connection_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_presence_connection_status" */
export type Enum_Presence_Connection_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Presence_Connection_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Presence_Connection_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_presence_connection_status" */
export enum Enum_Presence_Connection_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Presence_Connection_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Presence_Connection_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Presence_Connection_Status_Bool_Exp;
};

/** Project Milestone Status */
export type Enum_Project_Milestone_Status = {
  __typename?: 'enum_project_milestone_status';
  comment: Scalars['String'];
  /** An array relationship */
  project_milestones: Array<Project_Milestones>;
  /** An aggregate relationship */
  project_milestones_aggregate: Project_Milestones_Aggregate;
  value: Scalars['String'];
};

/** Project Milestone Status */
export type Enum_Project_Milestone_StatusProject_MilestonesArgs = {
  distinct_on?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Milestones_Order_By>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

/** Project Milestone Status */
export type Enum_Project_Milestone_StatusProject_Milestones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Milestones_Order_By>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

/** aggregated selection of "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_Aggregate = {
  __typename?: 'enum_project_milestone_status_aggregate';
  aggregate?: Maybe<Enum_Project_Milestone_Status_Aggregate_Fields>;
  nodes: Array<Enum_Project_Milestone_Status>;
};

/** aggregate fields of "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_Aggregate_Fields = {
  __typename?: 'enum_project_milestone_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Project_Milestone_Status_Max_Fields>;
  min?: Maybe<Enum_Project_Milestone_Status_Min_Fields>;
};

/** aggregate fields of "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Project_Milestone_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_project_milestone_status". All fields are combined with a logical 'AND'. */
export type Enum_Project_Milestone_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Project_Milestone_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Project_Milestone_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Project_Milestone_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  project_milestones?: InputMaybe<Project_Milestones_Bool_Exp>;
  project_milestones_aggregate?: InputMaybe<Project_Milestones_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_project_milestone_status" */
export enum Enum_Project_Milestone_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProjectMilestoneStatusPkey = 'enum_project_milestone_status_pkey',
}

export enum Enum_Project_Milestone_Status_Enum {
  /** Milestone completed */
  Completed = 'COMPLETED',
  /** Milestone is in progress */
  InProgress = 'IN_PROGRESS',
  /** Milestone Open */
  Open = 'OPEN',
  /** Milestone once completed, but the completion deemed incorrect */
  Reopened = 'REOPENED',
  /** Milestone is yet to start */
  YetToStart = 'YET_TO_START',
}

/** Boolean expression to compare columns of type "enum_project_milestone_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Project_Milestone_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Project_Milestone_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Project_Milestone_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Project_Milestone_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Project_Milestone_Status_Enum>>;
};

/** input type for inserting data into table "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  project_milestones?: InputMaybe<Project_Milestones_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Project_Milestone_Status_Max_Fields = {
  __typename?: 'enum_project_milestone_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Project_Milestone_Status_Min_Fields = {
  __typename?: 'enum_project_milestone_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_Mutation_Response = {
  __typename?: 'enum_project_milestone_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Project_Milestone_Status>;
};

/** input type for inserting object relation for remote table "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_Obj_Rel_Insert_Input = {
  data: Enum_Project_Milestone_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Project_Milestone_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_On_Conflict = {
  constraint: Enum_Project_Milestone_Status_Constraint;
  update_columns?: Array<Enum_Project_Milestone_Status_Update_Column>;
  where?: InputMaybe<Enum_Project_Milestone_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_project_milestone_status". */
export type Enum_Project_Milestone_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  project_milestones_aggregate?: InputMaybe<Project_Milestones_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_project_milestone_status */
export type Enum_Project_Milestone_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_project_milestone_status" */
export enum Enum_Project_Milestone_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_project_milestone_status" */
export type Enum_Project_Milestone_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Project_Milestone_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Project_Milestone_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_project_milestone_status" */
export enum Enum_Project_Milestone_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Project_Milestone_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Project_Milestone_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Project_Milestone_Status_Bool_Exp;
};

/** Category types for sprints */
export type Enum_Project_Sprint_Category = {
  __typename?: 'enum_project_sprint_category';
  comment?: Maybe<Scalars['String']>;
  /** An array relationship */
  project_sprints: Array<Project_Sprints>;
  /** An aggregate relationship */
  project_sprints_aggregate: Project_Sprints_Aggregate;
  value: Scalars['String'];
};

/** Category types for sprints */
export type Enum_Project_Sprint_CategoryProject_SprintsArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** Category types for sprints */
export type Enum_Project_Sprint_CategoryProject_Sprints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** aggregated selection of "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_Aggregate = {
  __typename?: 'enum_project_sprint_category_aggregate';
  aggregate?: Maybe<Enum_Project_Sprint_Category_Aggregate_Fields>;
  nodes: Array<Enum_Project_Sprint_Category>;
};

/** aggregate fields of "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_Aggregate_Fields = {
  __typename?: 'enum_project_sprint_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Project_Sprint_Category_Max_Fields>;
  min?: Maybe<Enum_Project_Sprint_Category_Min_Fields>;
};

/** aggregate fields of "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Project_Sprint_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_project_sprint_category". All fields are combined with a logical 'AND'. */
export type Enum_Project_Sprint_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Project_Sprint_Category_Bool_Exp>>;
  _not?: InputMaybe<Enum_Project_Sprint_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Project_Sprint_Category_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  project_sprints?: InputMaybe<Project_Sprints_Bool_Exp>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_project_sprint_category" */
export enum Enum_Project_Sprint_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProjectSprintCategoryPkey = 'enum_project_sprint_category_pkey',
}

export enum Enum_Project_Sprint_Category_Enum {
  /** The sprint is active */
  Active = 'ACTIVE',
  /** The sprint is in backlog state */
  Backlog = 'BACKLOG',
}

/** Boolean expression to compare columns of type "enum_project_sprint_category_enum". All fields are combined with logical 'AND'. */
export type Enum_Project_Sprint_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Project_Sprint_Category_Enum>;
  _in?: InputMaybe<Array<Enum_Project_Sprint_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Project_Sprint_Category_Enum>;
  _nin?: InputMaybe<Array<Enum_Project_Sprint_Category_Enum>>;
};

/** input type for inserting data into table "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  project_sprints?: InputMaybe<Project_Sprints_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Project_Sprint_Category_Max_Fields = {
  __typename?: 'enum_project_sprint_category_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Project_Sprint_Category_Min_Fields = {
  __typename?: 'enum_project_sprint_category_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_Mutation_Response = {
  __typename?: 'enum_project_sprint_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Project_Sprint_Category>;
};

/** input type for inserting object relation for remote table "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_Obj_Rel_Insert_Input = {
  data: Enum_Project_Sprint_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Project_Sprint_Category_On_Conflict>;
};

/** on_conflict condition type for table "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_On_Conflict = {
  constraint: Enum_Project_Sprint_Category_Constraint;
  update_columns?: Array<Enum_Project_Sprint_Category_Update_Column>;
  where?: InputMaybe<Enum_Project_Sprint_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_project_sprint_category". */
export type Enum_Project_Sprint_Category_Order_By = {
  comment?: InputMaybe<Order_By>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_project_sprint_category */
export type Enum_Project_Sprint_Category_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_project_sprint_category" */
export enum Enum_Project_Sprint_Category_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_project_sprint_category" */
export type Enum_Project_Sprint_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Project_Sprint_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Project_Sprint_Category_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_project_sprint_category" */
export enum Enum_Project_Sprint_Category_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Project_Sprint_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Project_Sprint_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Project_Sprint_Category_Bool_Exp;
};

/** columns and relationships of "enum_project_status" */
export type Enum_Project_Status = {
  __typename?: 'enum_project_status';
  comment: Scalars['String'];
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_project_status" */
export type Enum_Project_StatusProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** columns and relationships of "enum_project_status" */
export type Enum_Project_StatusProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** aggregated selection of "enum_project_status" */
export type Enum_Project_Status_Aggregate = {
  __typename?: 'enum_project_status_aggregate';
  aggregate?: Maybe<Enum_Project_Status_Aggregate_Fields>;
  nodes: Array<Enum_Project_Status>;
};

/** aggregate fields of "enum_project_status" */
export type Enum_Project_Status_Aggregate_Fields = {
  __typename?: 'enum_project_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Project_Status_Max_Fields>;
  min?: Maybe<Enum_Project_Status_Min_Fields>;
};

/** aggregate fields of "enum_project_status" */
export type Enum_Project_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Project_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_project_status". All fields are combined with a logical 'AND'. */
export type Enum_Project_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Project_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Project_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Project_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_project_status" */
export enum Enum_Project_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProjectStatusPkey = 'enum_project_status_pkey',
}

export enum Enum_Project_Status_Enum {
  /** Project is completed */
  Completed = 'COMPLETED',
  /** Project is ready to work */
  Open = 'OPEN',
  /** Task once completed, but the completion deemed incorrect. */
  Reopened = 'REOPENED',
}

/** Boolean expression to compare columns of type "enum_project_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Project_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Project_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Project_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Project_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Project_Status_Enum>>;
};

/** input type for inserting data into table "enum_project_status" */
export type Enum_Project_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Project_Status_Max_Fields = {
  __typename?: 'enum_project_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Project_Status_Min_Fields = {
  __typename?: 'enum_project_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_project_status" */
export type Enum_Project_Status_Mutation_Response = {
  __typename?: 'enum_project_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Project_Status>;
};

/** input type for inserting object relation for remote table "enum_project_status" */
export type Enum_Project_Status_Obj_Rel_Insert_Input = {
  data: Enum_Project_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Project_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_project_status" */
export type Enum_Project_Status_On_Conflict = {
  constraint: Enum_Project_Status_Constraint;
  update_columns?: Array<Enum_Project_Status_Update_Column>;
  where?: InputMaybe<Enum_Project_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_project_status". */
export type Enum_Project_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_project_status */
export type Enum_Project_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_project_status" */
export enum Enum_Project_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_project_status" */
export type Enum_Project_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_project_status" */
export type Enum_Project_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Project_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Project_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_project_status" */
export enum Enum_Project_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Project_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Project_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Project_Status_Bool_Exp;
};

/** The category a task can take  */
export type Enum_Project_Task_Category = {
  __typename?: 'enum_project_task_category';
  column: Scalars['String'];
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  value: Scalars['String'];
};

/** The category a task can take  */
export type Enum_Project_Task_CategoryTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** The category a task can take  */
export type Enum_Project_Task_CategoryTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "enum_project_task_category" */
export type Enum_Project_Task_Category_Aggregate = {
  __typename?: 'enum_project_task_category_aggregate';
  aggregate?: Maybe<Enum_Project_Task_Category_Aggregate_Fields>;
  nodes: Array<Enum_Project_Task_Category>;
};

/** aggregate fields of "enum_project_task_category" */
export type Enum_Project_Task_Category_Aggregate_Fields = {
  __typename?: 'enum_project_task_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Project_Task_Category_Max_Fields>;
  min?: Maybe<Enum_Project_Task_Category_Min_Fields>;
};

/** aggregate fields of "enum_project_task_category" */
export type Enum_Project_Task_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Project_Task_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_project_task_category". All fields are combined with a logical 'AND'. */
export type Enum_Project_Task_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Project_Task_Category_Bool_Exp>>;
  _not?: InputMaybe<Enum_Project_Task_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Project_Task_Category_Bool_Exp>>;
  column?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_project_task_category" */
export enum Enum_Project_Task_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProjectTaskCategoryPkey = 'enum_project_task_category_pkey',
}

export enum Enum_Project_Task_Category_Enum {
  /** The task is active */
  Active = 'ACTIVE',
  /** The task is in backlog */
  Backlog = 'BACKLOG',
}

/** Boolean expression to compare columns of type "enum_project_task_category_enum". All fields are combined with logical 'AND'. */
export type Enum_Project_Task_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Project_Task_Category_Enum>;
  _in?: InputMaybe<Array<Enum_Project_Task_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Project_Task_Category_Enum>;
  _nin?: InputMaybe<Array<Enum_Project_Task_Category_Enum>>;
};

/** input type for inserting data into table "enum_project_task_category" */
export type Enum_Project_Task_Category_Insert_Input = {
  column?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Project_Task_Category_Max_Fields = {
  __typename?: 'enum_project_task_category_max_fields';
  column?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Project_Task_Category_Min_Fields = {
  __typename?: 'enum_project_task_category_min_fields';
  column?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_project_task_category" */
export type Enum_Project_Task_Category_Mutation_Response = {
  __typename?: 'enum_project_task_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Project_Task_Category>;
};

/** input type for inserting object relation for remote table "enum_project_task_category" */
export type Enum_Project_Task_Category_Obj_Rel_Insert_Input = {
  data: Enum_Project_Task_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Project_Task_Category_On_Conflict>;
};

/** on_conflict condition type for table "enum_project_task_category" */
export type Enum_Project_Task_Category_On_Conflict = {
  constraint: Enum_Project_Task_Category_Constraint;
  update_columns?: Array<Enum_Project_Task_Category_Update_Column>;
  where?: InputMaybe<Enum_Project_Task_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_project_task_category". */
export type Enum_Project_Task_Category_Order_By = {
  column?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_project_task_category */
export type Enum_Project_Task_Category_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_project_task_category" */
export enum Enum_Project_Task_Category_Select_Column {
  /** column name */
  Column = 'column',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_project_task_category" */
export type Enum_Project_Task_Category_Set_Input = {
  column?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_project_task_category" */
export type Enum_Project_Task_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Project_Task_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Project_Task_Category_Stream_Cursor_Value_Input = {
  column?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_project_task_category" */
export enum Enum_Project_Task_Category_Update_Column {
  /** column name */
  Column = 'column',
  /** column name */
  Value = 'value',
}

export type Enum_Project_Task_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Project_Task_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Project_Task_Category_Bool_Exp;
};

/** Settings type for user settings */
export type Enum_Setting_Type = {
  __typename?: 'enum_setting_type';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "enum_setting_type" */
export type Enum_Setting_Type_Aggregate = {
  __typename?: 'enum_setting_type_aggregate';
  aggregate?: Maybe<Enum_Setting_Type_Aggregate_Fields>;
  nodes: Array<Enum_Setting_Type>;
};

/** aggregate fields of "enum_setting_type" */
export type Enum_Setting_Type_Aggregate_Fields = {
  __typename?: 'enum_setting_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Setting_Type_Max_Fields>;
  min?: Maybe<Enum_Setting_Type_Min_Fields>;
};

/** aggregate fields of "enum_setting_type" */
export type Enum_Setting_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Setting_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_setting_type". All fields are combined with a logical 'AND'. */
export type Enum_Setting_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Setting_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Setting_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Setting_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_setting_type" */
export enum Enum_Setting_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSettingTypePkey = 'enum_setting_type_pkey',
}

/** input type for inserting data into table "enum_setting_type" */
export type Enum_Setting_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Setting_Type_Max_Fields = {
  __typename?: 'enum_setting_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Setting_Type_Min_Fields = {
  __typename?: 'enum_setting_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_setting_type" */
export type Enum_Setting_Type_Mutation_Response = {
  __typename?: 'enum_setting_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Setting_Type>;
};

/** on_conflict condition type for table "enum_setting_type" */
export type Enum_Setting_Type_On_Conflict = {
  constraint: Enum_Setting_Type_Constraint;
  update_columns?: Array<Enum_Setting_Type_Update_Column>;
  where?: InputMaybe<Enum_Setting_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_setting_type". */
export type Enum_Setting_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_setting_type */
export type Enum_Setting_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_setting_type" */
export enum Enum_Setting_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_setting_type" */
export type Enum_Setting_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_setting_type" */
export type Enum_Setting_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Setting_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Setting_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_setting_type" */
export enum Enum_Setting_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Setting_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Setting_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Setting_Type_Bool_Exp;
};

/** columns and relationships of "enum_storage_file_type" */
export type Enum_Storage_File_Type = {
  __typename?: 'enum_storage_file_type';
  comment: Scalars['String'];
  /** An array relationship */
  storage_files: Array<Storage_Files>;
  /** An aggregate relationship */
  storage_files_aggregate: Storage_Files_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_storage_file_type" */
export type Enum_Storage_File_TypeStorage_FilesArgs = {
  distinct_on?: InputMaybe<Array<Storage_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Files_Order_By>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

/** columns and relationships of "enum_storage_file_type" */
export type Enum_Storage_File_TypeStorage_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Files_Order_By>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

/** aggregated selection of "enum_storage_file_type" */
export type Enum_Storage_File_Type_Aggregate = {
  __typename?: 'enum_storage_file_type_aggregate';
  aggregate?: Maybe<Enum_Storage_File_Type_Aggregate_Fields>;
  nodes: Array<Enum_Storage_File_Type>;
};

/** aggregate fields of "enum_storage_file_type" */
export type Enum_Storage_File_Type_Aggregate_Fields = {
  __typename?: 'enum_storage_file_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Storage_File_Type_Max_Fields>;
  min?: Maybe<Enum_Storage_File_Type_Min_Fields>;
};

/** aggregate fields of "enum_storage_file_type" */
export type Enum_Storage_File_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Storage_File_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_storage_file_type". All fields are combined with a logical 'AND'. */
export type Enum_Storage_File_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Storage_File_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Storage_File_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Storage_File_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  storage_files?: InputMaybe<Storage_Files_Bool_Exp>;
  storage_files_aggregate?: InputMaybe<Storage_Files_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_storage_file_type" */
export enum Enum_Storage_File_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumStorageFileTypePkey = 'enum_storage_file_type_pkey',
}

export enum Enum_Storage_File_Type_Enum {
  /** The audio files like mp3 etc */
  Audio = 'AUDIO',
  /** Any document files */
  Document = 'DOCUMENT',
  /** The images like png, jpeg, gif etc */
  Image = 'IMAGE',
  /** Recorder files associated with task */
  Recorder = 'RECORDER',
}

/** Boolean expression to compare columns of type "enum_storage_file_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Storage_File_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Storage_File_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Storage_File_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Storage_File_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Storage_File_Type_Enum>>;
};

/** input type for inserting data into table "enum_storage_file_type" */
export type Enum_Storage_File_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  storage_files?: InputMaybe<Storage_Files_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Storage_File_Type_Max_Fields = {
  __typename?: 'enum_storage_file_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Storage_File_Type_Min_Fields = {
  __typename?: 'enum_storage_file_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_storage_file_type" */
export type Enum_Storage_File_Type_Mutation_Response = {
  __typename?: 'enum_storage_file_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Storage_File_Type>;
};

/** input type for inserting object relation for remote table "enum_storage_file_type" */
export type Enum_Storage_File_Type_Obj_Rel_Insert_Input = {
  data: Enum_Storage_File_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Storage_File_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_storage_file_type" */
export type Enum_Storage_File_Type_On_Conflict = {
  constraint: Enum_Storage_File_Type_Constraint;
  update_columns?: Array<Enum_Storage_File_Type_Update_Column>;
  where?: InputMaybe<Enum_Storage_File_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_storage_file_type". */
export type Enum_Storage_File_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  storage_files_aggregate?: InputMaybe<Storage_Files_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_storage_file_type */
export type Enum_Storage_File_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_storage_file_type" */
export enum Enum_Storage_File_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_storage_file_type" */
export type Enum_Storage_File_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_storage_file_type" */
export type Enum_Storage_File_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Storage_File_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Storage_File_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_storage_file_type" */
export enum Enum_Storage_File_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Storage_File_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Storage_File_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Storage_File_Type_Bool_Exp;
};

/** columns and relationships of "enum_system_state" */
export type Enum_System_State = {
  __typename?: 'enum_system_state';
  comment: Scalars['String'];
  /** An array relationship */
  user_states: Array<User_State>;
  /** An aggregate relationship */
  user_states_aggregate: User_State_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_system_state" */
export type Enum_System_StateUser_StatesArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** columns and relationships of "enum_system_state" */
export type Enum_System_StateUser_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** aggregated selection of "enum_system_state" */
export type Enum_System_State_Aggregate = {
  __typename?: 'enum_system_state_aggregate';
  aggregate?: Maybe<Enum_System_State_Aggregate_Fields>;
  nodes: Array<Enum_System_State>;
};

/** aggregate fields of "enum_system_state" */
export type Enum_System_State_Aggregate_Fields = {
  __typename?: 'enum_system_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_System_State_Max_Fields>;
  min?: Maybe<Enum_System_State_Min_Fields>;
};

/** aggregate fields of "enum_system_state" */
export type Enum_System_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_System_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_system_state". All fields are combined with a logical 'AND'. */
export type Enum_System_State_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_System_State_Bool_Exp>>;
  _not?: InputMaybe<Enum_System_State_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_System_State_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_states?: InputMaybe<User_State_Bool_Exp>;
  user_states_aggregate?: InputMaybe<User_State_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_system_state" */
export enum Enum_System_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSystemStatePkey = 'enum_system_state_pkey',
}

export enum Enum_System_State_Enum {
  /** One of the system module is using state */
  Busy = 'BUSY',
  /** There is system is in idle */
  Online = 'ONLINE',
}

/** Boolean expression to compare columns of type "enum_system_state_enum". All fields are combined with logical 'AND'. */
export type Enum_System_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_System_State_Enum>;
  _in?: InputMaybe<Array<Enum_System_State_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_System_State_Enum>;
  _nin?: InputMaybe<Array<Enum_System_State_Enum>>;
};

/** input type for inserting data into table "enum_system_state" */
export type Enum_System_State_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_states?: InputMaybe<User_State_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_System_State_Max_Fields = {
  __typename?: 'enum_system_state_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_System_State_Min_Fields = {
  __typename?: 'enum_system_state_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_system_state" */
export type Enum_System_State_Mutation_Response = {
  __typename?: 'enum_system_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_System_State>;
};

/** input type for inserting object relation for remote table "enum_system_state" */
export type Enum_System_State_Obj_Rel_Insert_Input = {
  data: Enum_System_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_System_State_On_Conflict>;
};

/** on_conflict condition type for table "enum_system_state" */
export type Enum_System_State_On_Conflict = {
  constraint: Enum_System_State_Constraint;
  update_columns?: Array<Enum_System_State_Update_Column>;
  where?: InputMaybe<Enum_System_State_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_system_state". */
export type Enum_System_State_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_states_aggregate?: InputMaybe<User_State_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "enum_system_state_owner" */
export type Enum_System_State_Owner = {
  __typename?: 'enum_system_state_owner';
  comment: Scalars['String'];
  /** An array relationship */
  user_states: Array<User_State>;
  /** An aggregate relationship */
  user_states_aggregate: User_State_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_system_state_owner" */
export type Enum_System_State_OwnerUser_StatesArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** columns and relationships of "enum_system_state_owner" */
export type Enum_System_State_OwnerUser_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** aggregated selection of "enum_system_state_owner" */
export type Enum_System_State_Owner_Aggregate = {
  __typename?: 'enum_system_state_owner_aggregate';
  aggregate?: Maybe<Enum_System_State_Owner_Aggregate_Fields>;
  nodes: Array<Enum_System_State_Owner>;
};

/** aggregate fields of "enum_system_state_owner" */
export type Enum_System_State_Owner_Aggregate_Fields = {
  __typename?: 'enum_system_state_owner_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_System_State_Owner_Max_Fields>;
  min?: Maybe<Enum_System_State_Owner_Min_Fields>;
};

/** aggregate fields of "enum_system_state_owner" */
export type Enum_System_State_Owner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_System_State_Owner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_system_state_owner". All fields are combined with a logical 'AND'. */
export type Enum_System_State_Owner_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_System_State_Owner_Bool_Exp>>;
  _not?: InputMaybe<Enum_System_State_Owner_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_System_State_Owner_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_states?: InputMaybe<User_State_Bool_Exp>;
  user_states_aggregate?: InputMaybe<User_State_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_system_state_owner" */
export enum Enum_System_State_Owner_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSystemStateOwnerPkey = 'enum_system_state_owner_pkey',
}

export enum Enum_System_State_Owner_Enum {
  /** Call as the state owner */
  Call = 'CALL',
  /** Live office as the state owner */
  LiveOffice = 'LIVE_OFFICE',
  /** Status changed by being in a meeting room */
  MeetingRoom = 'MEETING_ROOM',
  /** Joined in a scheduled meeting */
  ScheduledCall = 'SCHEDULED_CALL',
}

/** Boolean expression to compare columns of type "enum_system_state_owner_enum". All fields are combined with logical 'AND'. */
export type Enum_System_State_Owner_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_System_State_Owner_Enum>;
  _in?: InputMaybe<Array<Enum_System_State_Owner_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_System_State_Owner_Enum>;
  _nin?: InputMaybe<Array<Enum_System_State_Owner_Enum>>;
};

/** input type for inserting data into table "enum_system_state_owner" */
export type Enum_System_State_Owner_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_states?: InputMaybe<User_State_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_System_State_Owner_Max_Fields = {
  __typename?: 'enum_system_state_owner_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_System_State_Owner_Min_Fields = {
  __typename?: 'enum_system_state_owner_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_system_state_owner" */
export type Enum_System_State_Owner_Mutation_Response = {
  __typename?: 'enum_system_state_owner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_System_State_Owner>;
};

/** input type for inserting object relation for remote table "enum_system_state_owner" */
export type Enum_System_State_Owner_Obj_Rel_Insert_Input = {
  data: Enum_System_State_Owner_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_System_State_Owner_On_Conflict>;
};

/** on_conflict condition type for table "enum_system_state_owner" */
export type Enum_System_State_Owner_On_Conflict = {
  constraint: Enum_System_State_Owner_Constraint;
  update_columns?: Array<Enum_System_State_Owner_Update_Column>;
  where?: InputMaybe<Enum_System_State_Owner_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_system_state_owner". */
export type Enum_System_State_Owner_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_states_aggregate?: InputMaybe<User_State_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_system_state_owner */
export type Enum_System_State_Owner_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_system_state_owner" */
export enum Enum_System_State_Owner_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_system_state_owner" */
export type Enum_System_State_Owner_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_system_state_owner" */
export type Enum_System_State_Owner_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_System_State_Owner_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_System_State_Owner_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_system_state_owner" */
export enum Enum_System_State_Owner_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_System_State_Owner_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_System_State_Owner_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_System_State_Owner_Bool_Exp;
};

/** primary key columns input for table: enum_system_state */
export type Enum_System_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_system_state" */
export enum Enum_System_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_system_state" */
export type Enum_System_State_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_system_state" */
export type Enum_System_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_System_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_System_State_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_system_state" */
export enum Enum_System_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_System_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_System_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_System_State_Bool_Exp;
};

/** columns and relationships of "enum_task_priority" */
export type Enum_Task_Priority = {
  __typename?: 'enum_task_priority';
  comment?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_task_priority" */
export type Enum_Task_PriorityTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "enum_task_priority" */
export type Enum_Task_PriorityTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "enum_task_priority" */
export type Enum_Task_Priority_Aggregate = {
  __typename?: 'enum_task_priority_aggregate';
  aggregate?: Maybe<Enum_Task_Priority_Aggregate_Fields>;
  nodes: Array<Enum_Task_Priority>;
};

/** aggregate fields of "enum_task_priority" */
export type Enum_Task_Priority_Aggregate_Fields = {
  __typename?: 'enum_task_priority_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Task_Priority_Max_Fields>;
  min?: Maybe<Enum_Task_Priority_Min_Fields>;
};

/** aggregate fields of "enum_task_priority" */
export type Enum_Task_Priority_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Task_Priority_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_task_priority". All fields are combined with a logical 'AND'. */
export type Enum_Task_Priority_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Task_Priority_Bool_Exp>>;
  _not?: InputMaybe<Enum_Task_Priority_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Task_Priority_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_task_priority" */
export enum Enum_Task_Priority_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTaskPriorityPkey = 'enum_task_priority_pkey',
}

export enum Enum_Task_Priority_Enum {
  /** high priority */
  High = 'HIGH',
  /** low priority */
  Low = 'LOW',
  /** medium priority */
  Medium = 'MEDIUM',
  /** urgent priority */
  Urgent = 'URGENT',
}

/** Boolean expression to compare columns of type "enum_task_priority_enum". All fields are combined with logical 'AND'. */
export type Enum_Task_Priority_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Task_Priority_Enum>;
  _in?: InputMaybe<Array<Enum_Task_Priority_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Task_Priority_Enum>;
  _nin?: InputMaybe<Array<Enum_Task_Priority_Enum>>;
};

/** input type for inserting data into table "enum_task_priority" */
export type Enum_Task_Priority_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Task_Priority_Max_Fields = {
  __typename?: 'enum_task_priority_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Task_Priority_Min_Fields = {
  __typename?: 'enum_task_priority_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_task_priority" */
export type Enum_Task_Priority_Mutation_Response = {
  __typename?: 'enum_task_priority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Task_Priority>;
};

/** input type for inserting object relation for remote table "enum_task_priority" */
export type Enum_Task_Priority_Obj_Rel_Insert_Input = {
  data: Enum_Task_Priority_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Task_Priority_On_Conflict>;
};

/** on_conflict condition type for table "enum_task_priority" */
export type Enum_Task_Priority_On_Conflict = {
  constraint: Enum_Task_Priority_Constraint;
  update_columns?: Array<Enum_Task_Priority_Update_Column>;
  where?: InputMaybe<Enum_Task_Priority_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_task_priority". */
export type Enum_Task_Priority_Order_By = {
  comment?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_task_priority */
export type Enum_Task_Priority_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_task_priority" */
export enum Enum_Task_Priority_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_task_priority" */
export type Enum_Task_Priority_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_task_priority" */
export type Enum_Task_Priority_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Task_Priority_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Task_Priority_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_task_priority" */
export enum Enum_Task_Priority_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Task_Priority_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Task_Priority_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Task_Priority_Bool_Exp;
};

/** After what time period task needs to be recurred */
export type Enum_Task_Recurring_Frequency = {
  __typename?: 'enum_task_recurring_frequency';
  comment: Scalars['String'];
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  value: Scalars['String'];
};

/** After what time period task needs to be recurred */
export type Enum_Task_Recurring_FrequencyTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** After what time period task needs to be recurred */
export type Enum_Task_Recurring_FrequencyTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_Aggregate = {
  __typename?: 'enum_task_recurring_frequency_aggregate';
  aggregate?: Maybe<Enum_Task_Recurring_Frequency_Aggregate_Fields>;
  nodes: Array<Enum_Task_Recurring_Frequency>;
};

/** aggregate fields of "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_Aggregate_Fields = {
  __typename?: 'enum_task_recurring_frequency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Task_Recurring_Frequency_Max_Fields>;
  min?: Maybe<Enum_Task_Recurring_Frequency_Min_Fields>;
};

/** aggregate fields of "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_task_recurring_frequency". All fields are combined with a logical 'AND'. */
export type Enum_Task_Recurring_Frequency_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Bool_Exp>>;
  _not?: InputMaybe<Enum_Task_Recurring_Frequency_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_task_recurring_frequency" */
export enum Enum_Task_Recurring_Frequency_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTaskRecurringFrequencyPkey = 'enum_task_recurring_frequency_pkey',
}

export enum Enum_Task_Recurring_Frequency_Enum {
  /** Task created BIWEEKLY */
  Biweekly = 'BIWEEKLY',
  /** Task created daily */
  Daily = 'DAILY',
  /** Task created Monthly */
  Monthly = 'MONTHLY',
  /** Task created daily */
  Weekly = 'WEEKLY',
}

/** Boolean expression to compare columns of type "enum_task_recurring_frequency_enum". All fields are combined with logical 'AND'. */
export type Enum_Task_Recurring_Frequency_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Task_Recurring_Frequency_Enum>;
  _in?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Task_Recurring_Frequency_Enum>;
  _nin?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Enum>>;
};

/** input type for inserting data into table "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Task_Recurring_Frequency_Max_Fields = {
  __typename?: 'enum_task_recurring_frequency_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Task_Recurring_Frequency_Min_Fields = {
  __typename?: 'enum_task_recurring_frequency_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_Mutation_Response = {
  __typename?: 'enum_task_recurring_frequency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Task_Recurring_Frequency>;
};

/** input type for inserting object relation for remote table "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_Obj_Rel_Insert_Input = {
  data: Enum_Task_Recurring_Frequency_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Task_Recurring_Frequency_On_Conflict>;
};

/** on_conflict condition type for table "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_On_Conflict = {
  constraint: Enum_Task_Recurring_Frequency_Constraint;
  update_columns?: Array<Enum_Task_Recurring_Frequency_Update_Column>;
  where?: InputMaybe<Enum_Task_Recurring_Frequency_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_task_recurring_frequency". */
export type Enum_Task_Recurring_Frequency_Order_By = {
  comment?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_task_recurring_frequency */
export type Enum_Task_Recurring_Frequency_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_task_recurring_frequency" */
export enum Enum_Task_Recurring_Frequency_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_task_recurring_frequency" */
export type Enum_Task_Recurring_Frequency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Task_Recurring_Frequency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Task_Recurring_Frequency_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_task_recurring_frequency" */
export enum Enum_Task_Recurring_Frequency_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Task_Recurring_Frequency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Task_Recurring_Frequency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Task_Recurring_Frequency_Bool_Exp;
};

/** Task status */
export type Enum_Task_Status = {
  __typename?: 'enum_task_status';
  comment: Scalars['String'];
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  value: Scalars['String'];
};

/** Task status */
export type Enum_Task_StatusTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Task status */
export type Enum_Task_StatusTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "enum_task_status" */
export type Enum_Task_Status_Aggregate = {
  __typename?: 'enum_task_status_aggregate';
  aggregate?: Maybe<Enum_Task_Status_Aggregate_Fields>;
  nodes: Array<Enum_Task_Status>;
};

/** aggregate fields of "enum_task_status" */
export type Enum_Task_Status_Aggregate_Fields = {
  __typename?: 'enum_task_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Task_Status_Max_Fields>;
  min?: Maybe<Enum_Task_Status_Min_Fields>;
};

/** aggregate fields of "enum_task_status" */
export type Enum_Task_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Task_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_task_status". All fields are combined with a logical 'AND'. */
export type Enum_Task_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Task_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Task_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Task_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_task_status" */
export enum Enum_Task_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTaskStatusPkey = 'enum_task_status_pkey',
}

export enum Enum_Task_Status_Enum {
  /** Task completed */
  Completed = 'COMPLETED',
  /** Task in the draft stage of writing. */
  Draft = 'DRAFT',
  /** Task is in progress */
  InProgress = 'IN_PROGRESS',
  /** Task open and ready for the assignee to start work on it. */
  Open = 'OPEN',
  /** Task once completed, but the completion deemed incorrect. */
  Reopened = 'REOPENED',
}

/** Boolean expression to compare columns of type "enum_task_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Task_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Task_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Task_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Task_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Task_Status_Enum>>;
};

/** input type for inserting data into table "enum_task_status" */
export type Enum_Task_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Task_Status_Max_Fields = {
  __typename?: 'enum_task_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Task_Status_Min_Fields = {
  __typename?: 'enum_task_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_task_status" */
export type Enum_Task_Status_Mutation_Response = {
  __typename?: 'enum_task_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Task_Status>;
};

/** input type for inserting object relation for remote table "enum_task_status" */
export type Enum_Task_Status_Obj_Rel_Insert_Input = {
  data: Enum_Task_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Task_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_task_status" */
export type Enum_Task_Status_On_Conflict = {
  constraint: Enum_Task_Status_Constraint;
  update_columns?: Array<Enum_Task_Status_Update_Column>;
  where?: InputMaybe<Enum_Task_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_task_status". */
export type Enum_Task_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_task_status */
export type Enum_Task_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_task_status" */
export enum Enum_Task_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_task_status" */
export type Enum_Task_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_task_status" */
export type Enum_Task_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Task_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Task_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_task_status" */
export enum Enum_Task_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Task_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Task_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Task_Status_Bool_Exp;
};

/** columns and relationships of "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status = {
  __typename?: 'enum_task_time_sheet_status';
  comment: Scalars['String'];
  /** An array relationship */
  task_time_sheets: Array<Task_Time_Sheet>;
  /** An aggregate relationship */
  task_time_sheets_aggregate: Task_Time_Sheet_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_StatusTask_Time_SheetsArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** columns and relationships of "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_StatusTask_Time_Sheets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** aggregated selection of "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_Aggregate = {
  __typename?: 'enum_task_time_sheet_status_aggregate';
  aggregate?: Maybe<Enum_Task_Time_Sheet_Status_Aggregate_Fields>;
  nodes: Array<Enum_Task_Time_Sheet_Status>;
};

/** aggregate fields of "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_Aggregate_Fields = {
  __typename?: 'enum_task_time_sheet_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Task_Time_Sheet_Status_Max_Fields>;
  min?: Maybe<Enum_Task_Time_Sheet_Status_Min_Fields>;
};

/** aggregate fields of "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_task_time_sheet_status". All fields are combined with a logical 'AND'. */
export type Enum_Task_Time_Sheet_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Task_Time_Sheet_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  task_time_sheets?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
  task_time_sheets_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_task_time_sheet_status" */
export enum Enum_Task_Time_Sheet_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTaskTimeSheetStatusPkey = 'enum_task_time_sheet_status_pkey',
}

export enum Enum_Task_Time_Sheet_Status_Enum {
  /** Auto approve the time sheet if it comes from assigned task. */
  AutoApproved = 'AUTO_APPROVED',
  /** The timesheet is updated and in draft status */
  Draft = 'DRAFT',
  /** Manual approve the time sheet by the manager. */
  ManualApproved = 'MANUAL_APPROVED',
  /** Manual reject the time sheet by the manager. */
  ManualRejected = 'MANUAL_REJECTED',
  /** The time sheet is on pending state once its created. */
  Pending = 'PENDING',
}

/** Boolean expression to compare columns of type "enum_task_time_sheet_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Task_Time_Sheet_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Task_Time_Sheet_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Task_Time_Sheet_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Enum>>;
};

/** input type for inserting data into table "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  task_time_sheets?: InputMaybe<Task_Time_Sheet_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Task_Time_Sheet_Status_Max_Fields = {
  __typename?: 'enum_task_time_sheet_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Task_Time_Sheet_Status_Min_Fields = {
  __typename?: 'enum_task_time_sheet_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_Mutation_Response = {
  __typename?: 'enum_task_time_sheet_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Task_Time_Sheet_Status>;
};

/** input type for inserting object relation for remote table "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_Obj_Rel_Insert_Input = {
  data: Enum_Task_Time_Sheet_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Task_Time_Sheet_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_On_Conflict = {
  constraint: Enum_Task_Time_Sheet_Status_Constraint;
  update_columns?: Array<Enum_Task_Time_Sheet_Status_Update_Column>;
  where?: InputMaybe<Enum_Task_Time_Sheet_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_task_time_sheet_status". */
export type Enum_Task_Time_Sheet_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  task_time_sheets_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_task_time_sheet_status */
export type Enum_Task_Time_Sheet_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_task_time_sheet_status" */
export enum Enum_Task_Time_Sheet_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_task_time_sheet_status" */
export type Enum_Task_Time_Sheet_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Task_Time_Sheet_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Task_Time_Sheet_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_task_time_sheet_status" */
export enum Enum_Task_Time_Sheet_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_Task_Time_Sheet_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Task_Time_Sheet_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Task_Time_Sheet_Status_Bool_Exp;
};

/** columns and relationships of "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type = {
  __typename?: 'enum_user_activity_tracker_status_type';
  comment: Scalars['String'];
  /** An array relationship */
  user_activity_trackers: Array<User_Activity_Tracker>;
  /** An aggregate relationship */
  user_activity_trackers_aggregate: User_Activity_Tracker_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_TypeUser_Activity_TrackersArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Tracker_Order_By>>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

/** columns and relationships of "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_TypeUser_Activity_Trackers_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<User_Activity_Tracker_Order_By>>;
    where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
  };

/** aggregated selection of "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_Aggregate = {
  __typename?: 'enum_user_activity_tracker_status_type_aggregate';
  aggregate?: Maybe<Enum_User_Activity_Tracker_Status_Type_Aggregate_Fields>;
  nodes: Array<Enum_User_Activity_Tracker_Status_Type>;
};

/** aggregate fields of "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_Aggregate_Fields = {
  __typename?: 'enum_user_activity_tracker_status_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_User_Activity_Tracker_Status_Type_Max_Fields>;
  min?: Maybe<Enum_User_Activity_Tracker_Status_Type_Min_Fields>;
};

/** aggregate fields of "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Enum_User_Activity_Tracker_Status_Type_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_activity_tracker_status_type". All fields are combined with a logical 'AND'. */
export type Enum_User_Activity_Tracker_Status_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_activity_trackers?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
  user_activity_trackers_aggregate?: InputMaybe<User_Activity_Tracker_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_activity_tracker_status_type" */
export enum Enum_User_Activity_Tracker_Status_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumUserActivityTrackerStatusTypePkey = 'enum_user_activity_tracker_status_type_pkey',
}

export enum Enum_User_Activity_Tracker_Status_Type_Enum {
  /** Make the user's active state. */
  Active = 'ACTIVE',
  /** This helps to make the manual check-in of a user. */
  CheckedIn = 'CHECKED_IN',
  /** This helps to make the manual check-out of a user. */
  CheckedOut = 'CHECKED_OUT',
  /** The status that trigger when internet is restored */
  InternetRecovered = 'INTERNET_RECOVERED',
  /** Make the user's inactive state. */
  InActive = 'IN_ACTIVE',
  /** Make the user's system active. this happens when the system is resumed or the internet is recovered. */
  SystemActive = 'SYSTEM_ACTIVE',
  /** Make the user's system inactive. this happens due to the system being suspended, the internet down, and the system going into hibernation. */
  SystemInActive = 'SYSTEM_IN_ACTIVE',
}

/** Boolean expression to compare columns of type "enum_user_activity_tracker_status_type_enum". All fields are combined with logical 'AND'. */
export type Enum_User_Activity_Tracker_Status_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Enum>;
  _in?: InputMaybe<Array<Enum_User_Activity_Tracker_Status_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_User_Activity_Tracker_Status_Type_Enum>>;
};

/** input type for inserting data into table "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_activity_trackers?: InputMaybe<User_Activity_Tracker_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Activity_Tracker_Status_Type_Max_Fields = {
  __typename?: 'enum_user_activity_tracker_status_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_User_Activity_Tracker_Status_Type_Min_Fields = {
  __typename?: 'enum_user_activity_tracker_status_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_Mutation_Response = {
  __typename?: 'enum_user_activity_tracker_status_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_User_Activity_Tracker_Status_Type>;
};

/** input type for inserting object relation for remote table "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_Obj_Rel_Insert_Input = {
  data: Enum_User_Activity_Tracker_Status_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_On_Conflict = {
  constraint: Enum_User_Activity_Tracker_Status_Type_Constraint;
  update_columns?: Array<Enum_User_Activity_Tracker_Status_Type_Update_Column>;
  where?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_user_activity_tracker_status_type". */
export type Enum_User_Activity_Tracker_Status_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_activity_trackers_aggregate?: InputMaybe<User_Activity_Tracker_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_user_activity_tracker_status_type */
export type Enum_User_Activity_Tracker_Status_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_activity_tracker_status_type" */
export enum Enum_User_Activity_Tracker_Status_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_user_activity_tracker_status_type" */
export type Enum_User_Activity_Tracker_Status_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_User_Activity_Tracker_Status_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_User_Activity_Tracker_Status_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_user_activity_tracker_status_type" */
export enum Enum_User_Activity_Tracker_Status_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_User_Activity_Tracker_Status_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_User_Activity_Tracker_Status_Type_Bool_Exp;
};

/** Avatar types for a user */
export type Enum_User_Avatar_Types = {
  __typename?: 'enum_user_avatar_types';
  comment: Scalars['String'];
  /** An array relationship */
  user_avatar_files: Array<User_Avatar_Files>;
  /** An aggregate relationship */
  user_avatar_files_aggregate: User_Avatar_Files_Aggregate;
  value: Scalars['String'];
};

/** Avatar types for a user */
export type Enum_User_Avatar_TypesUser_Avatar_FilesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

/** Avatar types for a user */
export type Enum_User_Avatar_TypesUser_Avatar_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

/** aggregated selection of "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_Aggregate = {
  __typename?: 'enum_user_avatar_types_aggregate';
  aggregate?: Maybe<Enum_User_Avatar_Types_Aggregate_Fields>;
  nodes: Array<Enum_User_Avatar_Types>;
};

/** aggregate fields of "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_Aggregate_Fields = {
  __typename?: 'enum_user_avatar_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_User_Avatar_Types_Max_Fields>;
  min?: Maybe<Enum_User_Avatar_Types_Min_Fields>;
};

/** aggregate fields of "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_User_Avatar_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_avatar_types". All fields are combined with a logical 'AND'. */
export type Enum_User_Avatar_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_User_Avatar_Types_Bool_Exp>>;
  _not?: InputMaybe<Enum_User_Avatar_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_User_Avatar_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_avatar_files?: InputMaybe<User_Avatar_Files_Bool_Exp>;
  user_avatar_files_aggregate?: InputMaybe<User_Avatar_Files_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_avatar_types" */
export enum Enum_User_Avatar_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumUserAvatarTypesPkey = 'enum_user_avatar_types_pkey',
}

export enum Enum_User_Avatar_Types_Enum {
  /** A full body avatar */
  FullBody = 'FULL_BODY',
  /** A half body avatar */
  HalfBody = 'HALF_BODY',
  /** Half body avatar thumbnail */
  HalfBodyImage = 'HALF_BODY_IMAGE',
  /** A head only avatar */
  HeadOnly = 'HEAD_ONLY',
}

/** Boolean expression to compare columns of type "enum_user_avatar_types_enum". All fields are combined with logical 'AND'. */
export type Enum_User_Avatar_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_User_Avatar_Types_Enum>;
  _in?: InputMaybe<Array<Enum_User_Avatar_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_User_Avatar_Types_Enum>;
  _nin?: InputMaybe<Array<Enum_User_Avatar_Types_Enum>>;
};

/** input type for inserting data into table "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_avatar_files?: InputMaybe<User_Avatar_Files_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Avatar_Types_Max_Fields = {
  __typename?: 'enum_user_avatar_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_User_Avatar_Types_Min_Fields = {
  __typename?: 'enum_user_avatar_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_Mutation_Response = {
  __typename?: 'enum_user_avatar_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_User_Avatar_Types>;
};

/** input type for inserting object relation for remote table "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_Obj_Rel_Insert_Input = {
  data: Enum_User_Avatar_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_User_Avatar_Types_On_Conflict>;
};

/** on_conflict condition type for table "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_On_Conflict = {
  constraint: Enum_User_Avatar_Types_Constraint;
  update_columns?: Array<Enum_User_Avatar_Types_Update_Column>;
  where?: InputMaybe<Enum_User_Avatar_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_user_avatar_types". */
export type Enum_User_Avatar_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_avatar_files_aggregate?: InputMaybe<User_Avatar_Files_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_user_avatar_types */
export type Enum_User_Avatar_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_avatar_types" */
export enum Enum_User_Avatar_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_user_avatar_types" */
export type Enum_User_Avatar_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_User_Avatar_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_User_Avatar_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_user_avatar_types" */
export enum Enum_User_Avatar_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_User_Avatar_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_User_Avatar_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_User_Avatar_Types_Bool_Exp;
};

/** This enum has the types of gender */
export type Enum_User_Gender = {
  __typename?: 'enum_user_gender';
  comment: Scalars['String'];
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregate relationship */
  profiles_aggregate: Profile_Aggregate;
  value: Scalars['String'];
};

/** This enum has the types of gender */
export type Enum_User_GenderProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** This enum has the types of gender */
export type Enum_User_GenderProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** aggregated selection of "enum_user_gender" */
export type Enum_User_Gender_Aggregate = {
  __typename?: 'enum_user_gender_aggregate';
  aggregate?: Maybe<Enum_User_Gender_Aggregate_Fields>;
  nodes: Array<Enum_User_Gender>;
};

/** aggregate fields of "enum_user_gender" */
export type Enum_User_Gender_Aggregate_Fields = {
  __typename?: 'enum_user_gender_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_User_Gender_Max_Fields>;
  min?: Maybe<Enum_User_Gender_Min_Fields>;
};

/** aggregate fields of "enum_user_gender" */
export type Enum_User_Gender_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_User_Gender_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_gender". All fields are combined with a logical 'AND'. */
export type Enum_User_Gender_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_User_Gender_Bool_Exp>>;
  _not?: InputMaybe<Enum_User_Gender_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_User_Gender_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  profiles?: InputMaybe<Profile_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_gender" */
export enum Enum_User_Gender_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumUserGenderPkey = 'enum_user_gender_pkey',
}

export enum Enum_User_Gender_Enum {
  /** User is female */
  Female = 'FEMALE',
  /** User is male */
  Male = 'MALE',
}

/** Boolean expression to compare columns of type "enum_user_gender_enum". All fields are combined with logical 'AND'. */
export type Enum_User_Gender_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_User_Gender_Enum>;
  _in?: InputMaybe<Array<Enum_User_Gender_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_User_Gender_Enum>;
  _nin?: InputMaybe<Array<Enum_User_Gender_Enum>>;
};

/** input type for inserting data into table "enum_user_gender" */
export type Enum_User_Gender_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Gender_Max_Fields = {
  __typename?: 'enum_user_gender_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_User_Gender_Min_Fields = {
  __typename?: 'enum_user_gender_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_user_gender" */
export type Enum_User_Gender_Mutation_Response = {
  __typename?: 'enum_user_gender_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_User_Gender>;
};

/** input type for inserting object relation for remote table "enum_user_gender" */
export type Enum_User_Gender_Obj_Rel_Insert_Input = {
  data: Enum_User_Gender_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_User_Gender_On_Conflict>;
};

/** on_conflict condition type for table "enum_user_gender" */
export type Enum_User_Gender_On_Conflict = {
  constraint: Enum_User_Gender_Constraint;
  update_columns?: Array<Enum_User_Gender_Update_Column>;
  where?: InputMaybe<Enum_User_Gender_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_user_gender". */
export type Enum_User_Gender_Order_By = {
  comment?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_user_gender */
export type Enum_User_Gender_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_gender" */
export enum Enum_User_Gender_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_user_gender" */
export type Enum_User_Gender_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_user_gender" */
export type Enum_User_Gender_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_User_Gender_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_User_Gender_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_user_gender" */
export enum Enum_User_Gender_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_User_Gender_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_User_Gender_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_User_Gender_Bool_Exp;
};

/** User presense provider type */
export type Enum_User_Presence_Provider_Type = {
  __typename?: 'enum_user_presence_provider_type';
  comment: Scalars['String'];
  /** An array relationship */
  user_presences: Array<User_Presence>;
  /** An aggregate relationship */
  user_presences_aggregate: User_Presence_Aggregate;
  value: Scalars['String'];
};

/** User presense provider type */
export type Enum_User_Presence_Provider_TypeUser_PresencesArgs = {
  distinct_on?: InputMaybe<Array<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

/** User presense provider type */
export type Enum_User_Presence_Provider_TypeUser_Presences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

/** aggregated selection of "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_Aggregate = {
  __typename?: 'enum_user_presence_provider_type_aggregate';
  aggregate?: Maybe<Enum_User_Presence_Provider_Type_Aggregate_Fields>;
  nodes: Array<Enum_User_Presence_Provider_Type>;
};

/** aggregate fields of "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_Aggregate_Fields = {
  __typename?: 'enum_user_presence_provider_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_User_Presence_Provider_Type_Max_Fields>;
  min?: Maybe<Enum_User_Presence_Provider_Type_Min_Fields>;
};

/** aggregate fields of "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_presence_provider_type". All fields are combined with a logical 'AND'. */
export type Enum_User_Presence_Provider_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_User_Presence_Provider_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_presences?: InputMaybe<User_Presence_Bool_Exp>;
  user_presences_aggregate?: InputMaybe<User_Presence_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_presence_provider_type" */
export enum Enum_User_Presence_Provider_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumUserPresenseProviderTypePkey = 'enum_user_presense_provider_type_pkey',
}

export enum Enum_User_Presence_Provider_Type_Enum {
  /** Presence created using meeting room  */
  MeetingRoom = 'MEETING_ROOM',
  /** Presence created using normal chat call */
  NormalCall = 'NORMAL_CALL',
  /** Presence state set by a scheduled meeting */
  ScheduledMeet = 'SCHEDULED_MEET',
}

/** Boolean expression to compare columns of type "enum_user_presence_provider_type_enum". All fields are combined with logical 'AND'. */
export type Enum_User_Presence_Provider_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_User_Presence_Provider_Type_Enum>;
  _in?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_User_Presence_Provider_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Enum>>;
};

/** input type for inserting data into table "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_presences?: InputMaybe<User_Presence_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Presence_Provider_Type_Max_Fields = {
  __typename?: 'enum_user_presence_provider_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_User_Presence_Provider_Type_Min_Fields = {
  __typename?: 'enum_user_presence_provider_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_Mutation_Response = {
  __typename?: 'enum_user_presence_provider_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_User_Presence_Provider_Type>;
};

/** input type for inserting object relation for remote table "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_Obj_Rel_Insert_Input = {
  data: Enum_User_Presence_Provider_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_User_Presence_Provider_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_On_Conflict = {
  constraint: Enum_User_Presence_Provider_Type_Constraint;
  update_columns?: Array<Enum_User_Presence_Provider_Type_Update_Column>;
  where?: InputMaybe<Enum_User_Presence_Provider_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_user_presence_provider_type". */
export type Enum_User_Presence_Provider_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_presences_aggregate?: InputMaybe<User_Presence_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_user_presence_provider_type */
export type Enum_User_Presence_Provider_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_presence_provider_type" */
export enum Enum_User_Presence_Provider_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_user_presence_provider_type" */
export type Enum_User_Presence_Provider_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_User_Presence_Provider_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_User_Presence_Provider_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_user_presence_provider_type" */
export enum Enum_User_Presence_Provider_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_User_Presence_Provider_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_User_Presence_Provider_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_User_Presence_Provider_Type_Bool_Exp;
};

/** columns and relationships of "enum_user_settings_type" */
export type Enum_User_Settings_Type = {
  __typename?: 'enum_user_settings_type';
  comment?: Maybe<Scalars['String']>;
  /** An array relationship */
  user_settings: Array<User_Settings>;
  /** An aggregate relationship */
  user_settings_aggregate: User_Settings_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_user_settings_type" */
export type Enum_User_Settings_TypeUser_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

/** columns and relationships of "enum_user_settings_type" */
export type Enum_User_Settings_TypeUser_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

/** aggregated selection of "enum_user_settings_type" */
export type Enum_User_Settings_Type_Aggregate = {
  __typename?: 'enum_user_settings_type_aggregate';
  aggregate?: Maybe<Enum_User_Settings_Type_Aggregate_Fields>;
  nodes: Array<Enum_User_Settings_Type>;
};

/** aggregate fields of "enum_user_settings_type" */
export type Enum_User_Settings_Type_Aggregate_Fields = {
  __typename?: 'enum_user_settings_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_User_Settings_Type_Max_Fields>;
  min?: Maybe<Enum_User_Settings_Type_Min_Fields>;
};

/** aggregate fields of "enum_user_settings_type" */
export type Enum_User_Settings_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_User_Settings_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_settings_type". All fields are combined with a logical 'AND'. */
export type Enum_User_Settings_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_User_Settings_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_User_Settings_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_User_Settings_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_settings?: InputMaybe<User_Settings_Bool_Exp>;
  user_settings_aggregate?: InputMaybe<User_Settings_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_settings_type" */
export enum Enum_User_Settings_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumUserSettingsTypePkey = 'enum_user_settings_type_pkey',
}

export enum Enum_User_Settings_Type_Enum {
  /** Sets the where the user is working from */
  WorkingFrom = 'WORKING_FROM',
  /** A setting type for preference notifications */
  NotificationPreferences = 'notification_preferences',
  /** A setting type for personalizing */
  Personalised = 'personalised',
}

/** Boolean expression to compare columns of type "enum_user_settings_type_enum". All fields are combined with logical 'AND'. */
export type Enum_User_Settings_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_User_Settings_Type_Enum>;
  _in?: InputMaybe<Array<Enum_User_Settings_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_User_Settings_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_User_Settings_Type_Enum>>;
};

/** input type for inserting data into table "enum_user_settings_type" */
export type Enum_User_Settings_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_settings?: InputMaybe<User_Settings_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Settings_Type_Max_Fields = {
  __typename?: 'enum_user_settings_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_User_Settings_Type_Min_Fields = {
  __typename?: 'enum_user_settings_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_user_settings_type" */
export type Enum_User_Settings_Type_Mutation_Response = {
  __typename?: 'enum_user_settings_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_User_Settings_Type>;
};

/** input type for inserting object relation for remote table "enum_user_settings_type" */
export type Enum_User_Settings_Type_Obj_Rel_Insert_Input = {
  data: Enum_User_Settings_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_User_Settings_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_user_settings_type" */
export type Enum_User_Settings_Type_On_Conflict = {
  constraint: Enum_User_Settings_Type_Constraint;
  update_columns?: Array<Enum_User_Settings_Type_Update_Column>;
  where?: InputMaybe<Enum_User_Settings_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_user_settings_type". */
export type Enum_User_Settings_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_settings_aggregate?: InputMaybe<User_Settings_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_user_settings_type */
export type Enum_User_Settings_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_settings_type" */
export enum Enum_User_Settings_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_user_settings_type" */
export type Enum_User_Settings_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_user_settings_type" */
export type Enum_User_Settings_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_User_Settings_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_User_Settings_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_user_settings_type" */
export enum Enum_User_Settings_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Enum_User_Settings_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_User_Settings_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_User_Settings_Type_Bool_Exp;
};

export type FeedbackInput = {
  id?: InputMaybe<Scalars['uuid']>;
};

export type FeedbackOutput = {
  __typename?: 'feedbackOutput';
  count: Scalars['Int'];
  feedback: Scalars['Int'];
};

/** All Filterable Columns of project module along with their data type */
export type Filter_Definition = {
  __typename?: 'filter_definition';
  column_name: Scalars['String'];
  column_type: Scalars['String'];
  display_name: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['uuid']>;
  section_name: Scalars['String'];
  values?: Maybe<Scalars['String']>;
};

/** aggregated selection of "filter_definition" */
export type Filter_Definition_Aggregate = {
  __typename?: 'filter_definition_aggregate';
  aggregate?: Maybe<Filter_Definition_Aggregate_Fields>;
  nodes: Array<Filter_Definition>;
};

export type Filter_Definition_Aggregate_Bool_Exp = {
  count?: InputMaybe<Filter_Definition_Aggregate_Bool_Exp_Count>;
};

export type Filter_Definition_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Filter_Definition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Filter_Definition_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "filter_definition" */
export type Filter_Definition_Aggregate_Fields = {
  __typename?: 'filter_definition_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Filter_Definition_Max_Fields>;
  min?: Maybe<Filter_Definition_Min_Fields>;
};

/** aggregate fields of "filter_definition" */
export type Filter_Definition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Filter_Definition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "filter_definition" */
export type Filter_Definition_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Filter_Definition_Max_Order_By>;
  min?: InputMaybe<Filter_Definition_Min_Order_By>;
};

/** input type for inserting array relation for remote table "filter_definition" */
export type Filter_Definition_Arr_Rel_Insert_Input = {
  data: Array<Filter_Definition_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Filter_Definition_On_Conflict>;
};

/** Boolean expression to filter rows from the table "filter_definition". All fields are combined with a logical 'AND'. */
export type Filter_Definition_Bool_Exp = {
  _and?: InputMaybe<Array<Filter_Definition_Bool_Exp>>;
  _not?: InputMaybe<Filter_Definition_Bool_Exp>;
  _or?: InputMaybe<Array<Filter_Definition_Bool_Exp>>;
  column_name?: InputMaybe<String_Comparison_Exp>;
  column_type?: InputMaybe<String_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  section_name?: InputMaybe<String_Comparison_Exp>;
  values?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "filter_definition" */
export enum Filter_Definition_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilterDefinitionPkey = 'filter_definition_pkey',
}

/** input type for inserting data into table "filter_definition" */
export type Filter_Definition_Insert_Input = {
  column_name?: InputMaybe<Scalars['String']>;
  column_type?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  section_name?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Filter_Definition_Max_Fields = {
  __typename?: 'filter_definition_max_fields';
  column_name?: Maybe<Scalars['String']>;
  column_type?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  section_name?: Maybe<Scalars['String']>;
  values?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "filter_definition" */
export type Filter_Definition_Max_Order_By = {
  column_name?: InputMaybe<Order_By>;
  column_type?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  section_name?: InputMaybe<Order_By>;
  values?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Filter_Definition_Min_Fields = {
  __typename?: 'filter_definition_min_fields';
  column_name?: Maybe<Scalars['String']>;
  column_type?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  section_name?: Maybe<Scalars['String']>;
  values?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "filter_definition" */
export type Filter_Definition_Min_Order_By = {
  column_name?: InputMaybe<Order_By>;
  column_type?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  section_name?: InputMaybe<Order_By>;
  values?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "filter_definition" */
export type Filter_Definition_Mutation_Response = {
  __typename?: 'filter_definition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Definition>;
};

/** on_conflict condition type for table "filter_definition" */
export type Filter_Definition_On_Conflict = {
  constraint: Filter_Definition_Constraint;
  update_columns?: Array<Filter_Definition_Update_Column>;
  where?: InputMaybe<Filter_Definition_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_definition". */
export type Filter_Definition_Order_By = {
  column_name?: InputMaybe<Order_By>;
  column_type?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  section_name?: InputMaybe<Order_By>;
  values?: InputMaybe<Order_By>;
};

/** primary key columns input for table: filter_definition */
export type Filter_Definition_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "filter_definition" */
export enum Filter_Definition_Select_Column {
  /** column name */
  ColumnName = 'column_name',
  /** column name */
  ColumnType = 'column_type',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SectionName = 'section_name',
  /** column name */
  Values = 'values',
}

/** input type for updating data in table "filter_definition" */
export type Filter_Definition_Set_Input = {
  column_name?: InputMaybe<Scalars['String']>;
  column_type?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  section_name?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "filter_definition" */
export type Filter_Definition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Filter_Definition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filter_Definition_Stream_Cursor_Value_Input = {
  column_name?: InputMaybe<Scalars['String']>;
  column_type?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  section_name?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Scalars['String']>;
};

/** update columns of table "filter_definition" */
export enum Filter_Definition_Update_Column {
  /** column name */
  ColumnName = 'column_name',
  /** column name */
  ColumnType = 'column_type',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SectionName = 'section_name',
  /** column name */
  Values = 'values',
}

export type Filter_Definition_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Filter_Definition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Filter_Definition_Bool_Exp;
};

/** columns and relationships of "financialYear" */
export type FinancialYear = {
  __typename?: 'financialYear';
  fromDate: Scalars['date'];
  id: Scalars['uuid'];
  toDate: Scalars['date'];
};

/** aggregated selection of "financialYear" */
export type FinancialYear_Aggregate = {
  __typename?: 'financialYear_aggregate';
  aggregate?: Maybe<FinancialYear_Aggregate_Fields>;
  nodes: Array<FinancialYear>;
};

/** aggregate fields of "financialYear" */
export type FinancialYear_Aggregate_Fields = {
  __typename?: 'financialYear_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FinancialYear_Max_Fields>;
  min?: Maybe<FinancialYear_Min_Fields>;
};

/** aggregate fields of "financialYear" */
export type FinancialYear_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FinancialYear_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "financialYear". All fields are combined with a logical 'AND'. */
export type FinancialYear_Bool_Exp = {
  _and?: InputMaybe<Array<FinancialYear_Bool_Exp>>;
  _not?: InputMaybe<FinancialYear_Bool_Exp>;
  _or?: InputMaybe<Array<FinancialYear_Bool_Exp>>;
  fromDate?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  toDate?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "financialYear" */
export enum FinancialYear_Constraint {
  /** unique or primary key constraint on columns "id" */
  FinancialYearPkey = 'financialYear_pkey',
}

/** input type for inserting data into table "financialYear" */
export type FinancialYear_Insert_Input = {
  fromDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  toDate?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type FinancialYear_Max_Fields = {
  __typename?: 'financialYear_max_fields';
  fromDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  toDate?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type FinancialYear_Min_Fields = {
  __typename?: 'financialYear_min_fields';
  fromDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  toDate?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "financialYear" */
export type FinancialYear_Mutation_Response = {
  __typename?: 'financialYear_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FinancialYear>;
};

/** on_conflict condition type for table "financialYear" */
export type FinancialYear_On_Conflict = {
  constraint: FinancialYear_Constraint;
  update_columns?: Array<FinancialYear_Update_Column>;
  where?: InputMaybe<FinancialYear_Bool_Exp>;
};

/** Ordering options when selecting data from "financialYear". */
export type FinancialYear_Order_By = {
  fromDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  toDate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: financialYear */
export type FinancialYear_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "financialYear" */
export enum FinancialYear_Select_Column {
  /** column name */
  FromDate = 'fromDate',
  /** column name */
  Id = 'id',
  /** column name */
  ToDate = 'toDate',
}

/** input type for updating data in table "financialYear" */
export type FinancialYear_Set_Input = {
  fromDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  toDate?: InputMaybe<Scalars['date']>;
};

/** Streaming cursor of the table "financialYear" */
export type FinancialYear_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FinancialYear_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FinancialYear_Stream_Cursor_Value_Input = {
  fromDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  toDate?: InputMaybe<Scalars['date']>;
};

/** update columns of table "financialYear" */
export enum FinancialYear_Update_Column {
  /** column name */
  FromDate = 'fromDate',
  /** column name */
  Id = 'id',
  /** column name */
  ToDate = 'toDate',
}

export type FinancialYear_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FinancialYear_Set_Input>;
  /** filter the rows which have to be updated */
  where: FinancialYear_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "frequency" */
export type Frequency = {
  __typename?: 'frequency';
  /** An array relationship */
  goalsWithFreqs: Array<GoalsWithFreqs>;
  /** An aggregate relationship */
  goalsWithFreqs_aggregate: GoalsWithFreqs_Aggregate;
  id: Scalars['uuid'];
  value: Scalars['String'];
};

/** columns and relationships of "frequency" */
export type FrequencyGoalsWithFreqsArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithFreqs_Order_By>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

/** columns and relationships of "frequency" */
export type FrequencyGoalsWithFreqs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithFreqs_Order_By>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

/** aggregated selection of "frequency" */
export type Frequency_Aggregate = {
  __typename?: 'frequency_aggregate';
  aggregate?: Maybe<Frequency_Aggregate_Fields>;
  nodes: Array<Frequency>;
};

/** aggregate fields of "frequency" */
export type Frequency_Aggregate_Fields = {
  __typename?: 'frequency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Frequency_Max_Fields>;
  min?: Maybe<Frequency_Min_Fields>;
};

/** aggregate fields of "frequency" */
export type Frequency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Frequency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "frequency". All fields are combined with a logical 'AND'. */
export type Frequency_Bool_Exp = {
  _and?: InputMaybe<Array<Frequency_Bool_Exp>>;
  _not?: InputMaybe<Frequency_Bool_Exp>;
  _or?: InputMaybe<Array<Frequency_Bool_Exp>>;
  goalsWithFreqs?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
  goalsWithFreqs_aggregate?: InputMaybe<GoalsWithFreqs_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "frequency" */
export enum Frequency_Constraint {
  /** unique or primary key constraint on columns "id" */
  FrequencyPkey = 'frequency_pkey',
}

/** input type for inserting data into table "frequency" */
export type Frequency_Insert_Input = {
  goalsWithFreqs?: InputMaybe<GoalsWithFreqs_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Frequency_Max_Fields = {
  __typename?: 'frequency_max_fields';
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Frequency_Min_Fields = {
  __typename?: 'frequency_min_fields';
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "frequency" */
export type Frequency_Mutation_Response = {
  __typename?: 'frequency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Frequency>;
};

/** input type for inserting object relation for remote table "frequency" */
export type Frequency_Obj_Rel_Insert_Input = {
  data: Frequency_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Frequency_On_Conflict>;
};

/** on_conflict condition type for table "frequency" */
export type Frequency_On_Conflict = {
  constraint: Frequency_Constraint;
  update_columns?: Array<Frequency_Update_Column>;
  where?: InputMaybe<Frequency_Bool_Exp>;
};

/** Ordering options when selecting data from "frequency". */
export type Frequency_Order_By = {
  goalsWithFreqs_aggregate?: InputMaybe<GoalsWithFreqs_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: frequency */
export type Frequency_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "frequency" */
export enum Frequency_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "frequency" */
export type Frequency_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "frequency" */
export type Frequency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frequency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frequency_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "frequency" */
export enum Frequency_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
}

export type Frequency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Frequency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Frequency_Bool_Exp;
};

export type Get_All_Dependent_Tasks_New_Args = {
  parent_task_id?: InputMaybe<Scalars['uuid']>;
};

export type Get_Chat_Room_Id_From_Event_Id_For_Meeting_Call_Args = {
  mc?: InputMaybe<Scalars['calendar_meeting_call_scalar']>;
};

export type Get_Employee_Billable_Hours_Args = {
  track_end_date?: InputMaybe<Scalars['date']>;
  track_start_date?: InputMaybe<Scalars['date']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Get_Employee_Billable_Hours_As_Manager_Args = {
  current_manager_id?: InputMaybe<Scalars['uuid']>;
  track_end_date?: InputMaybe<Scalars['date']>;
  track_start_date?: InputMaybe<Scalars['date']>;
};

export type GoalCountPerTimeperiodInput = {
  action: Scalars['String'];
  orgId: Scalars['uuid'];
  timePeriod: Scalars['String'];
  timePeriodId: Scalars['uuid'];
};

export type GoalCountPerTimeperiodOutput = {
  __typename?: 'goalCountPerTimeperiodOutput';
  response: Scalars['String'];
};

export type GoalDetailUuid = {
  goalId: Scalars['uuid'];
  kpi: Array<KpiDetailUuid>;
  role_weightage?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "goalWithKpi" */
export type GoalWithKpi = {
  __typename?: 'goalWithKpi';
  createdBy?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  goal: Goals;
  goal_ID: Scalars['uuid'];
  id: Scalars['uuid'];
  kpi_ID: Scalars['uuid'];
  /** An object relationship */
  kpi_bank: Kpi_Bank;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "goalWithKpi" */
export type GoalWithKpi_Aggregate = {
  __typename?: 'goalWithKpi_aggregate';
  aggregate?: Maybe<GoalWithKpi_Aggregate_Fields>;
  nodes: Array<GoalWithKpi>;
};

export type GoalWithKpi_Aggregate_Bool_Exp = {
  count?: InputMaybe<GoalWithKpi_Aggregate_Bool_Exp_Count>;
};

export type GoalWithKpi_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalWithKpi_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "goalWithKpi" */
export type GoalWithKpi_Aggregate_Fields = {
  __typename?: 'goalWithKpi_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GoalWithKpi_Max_Fields>;
  min?: Maybe<GoalWithKpi_Min_Fields>;
};

/** aggregate fields of "goalWithKpi" */
export type GoalWithKpi_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goalWithKpi" */
export type GoalWithKpi_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GoalWithKpi_Max_Order_By>;
  min?: InputMaybe<GoalWithKpi_Min_Order_By>;
};

/** input type for inserting array relation for remote table "goalWithKpi" */
export type GoalWithKpi_Arr_Rel_Insert_Input = {
  data: Array<GoalWithKpi_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GoalWithKpi_On_Conflict>;
};

/** Boolean expression to filter rows from the table "goalWithKpi". All fields are combined with a logical 'AND'. */
export type GoalWithKpi_Bool_Exp = {
  _and?: InputMaybe<Array<GoalWithKpi_Bool_Exp>>;
  _not?: InputMaybe<GoalWithKpi_Bool_Exp>;
  _or?: InputMaybe<Array<GoalWithKpi_Bool_Exp>>;
  createdBy?: InputMaybe<Uuid_Comparison_Exp>;
  goal?: InputMaybe<Goals_Bool_Exp>;
  goal_ID?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_ID?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_bank?: InputMaybe<Kpi_Bank_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "goalWithKpi" */
export enum GoalWithKpi_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoalWithKpiPkey = 'goalWithKpi_pkey',
}

/** input type for inserting data into table "goalWithKpi" */
export type GoalWithKpi_Insert_Input = {
  createdBy?: InputMaybe<Scalars['uuid']>;
  goal?: InputMaybe<Goals_Obj_Rel_Insert_Input>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_ID?: InputMaybe<Scalars['uuid']>;
  kpi_bank?: InputMaybe<Kpi_Bank_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type GoalWithKpi_Max_Fields = {
  __typename?: 'goalWithKpi_max_fields';
  createdBy?: Maybe<Scalars['uuid']>;
  goal_ID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  kpi_ID?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "goalWithKpi" */
export type GoalWithKpi_Max_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_ID?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GoalWithKpi_Min_Fields = {
  __typename?: 'goalWithKpi_min_fields';
  createdBy?: Maybe<Scalars['uuid']>;
  goal_ID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  kpi_ID?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "goalWithKpi" */
export type GoalWithKpi_Min_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_ID?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "goalWithKpi" */
export type GoalWithKpi_Mutation_Response = {
  __typename?: 'goalWithKpi_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoalWithKpi>;
};

/** on_conflict condition type for table "goalWithKpi" */
export type GoalWithKpi_On_Conflict = {
  constraint: GoalWithKpi_Constraint;
  update_columns?: Array<GoalWithKpi_Update_Column>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

/** Ordering options when selecting data from "goalWithKpi". */
export type GoalWithKpi_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  goal?: InputMaybe<Goals_Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_ID?: InputMaybe<Order_By>;
  kpi_bank?: InputMaybe<Kpi_Bank_Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: goalWithKpi */
export type GoalWithKpi_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "goalWithKpi" */
export enum GoalWithKpi_Select_Column {
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  GoalId = 'goal_ID',
  /** column name */
  Id = 'id',
  /** column name */
  KpiId = 'kpi_ID',
}

/** input type for updating data in table "goalWithKpi" */
export type GoalWithKpi_Set_Input = {
  createdBy?: InputMaybe<Scalars['uuid']>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_ID?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "goalWithKpi" */
export type GoalWithKpi_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoalWithKpi_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoalWithKpi_Stream_Cursor_Value_Input = {
  createdBy?: InputMaybe<Scalars['uuid']>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_ID?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "goalWithKpi" */
export enum GoalWithKpi_Update_Column {
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  GoalId = 'goal_ID',
  /** column name */
  Id = 'id',
  /** column name */
  KpiId = 'kpi_ID',
}

export type GoalWithKpi_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoalWithKpi_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoalWithKpi_Bool_Exp;
};

/** columns and relationships of "goalWithTimePeriod" */
export type GoalWithTimePeriod = {
  __typename?: 'goalWithTimePeriod';
  /** An object relationship */
  goal: Goals;
  goalId: Scalars['uuid'];
  id: Scalars['uuid'];
  timePeriod: Scalars['String'];
};

/** aggregated selection of "goalWithTimePeriod" */
export type GoalWithTimePeriod_Aggregate = {
  __typename?: 'goalWithTimePeriod_aggregate';
  aggregate?: Maybe<GoalWithTimePeriod_Aggregate_Fields>;
  nodes: Array<GoalWithTimePeriod>;
};

export type GoalWithTimePeriod_Aggregate_Bool_Exp = {
  count?: InputMaybe<GoalWithTimePeriod_Aggregate_Bool_Exp_Count>;
};

export type GoalWithTimePeriod_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GoalWithTimePeriod_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "goalWithTimePeriod" */
export type GoalWithTimePeriod_Aggregate_Fields = {
  __typename?: 'goalWithTimePeriod_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GoalWithTimePeriod_Max_Fields>;
  min?: Maybe<GoalWithTimePeriod_Min_Fields>;
};

/** aggregate fields of "goalWithTimePeriod" */
export type GoalWithTimePeriod_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoalWithTimePeriod_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goalWithTimePeriod" */
export type GoalWithTimePeriod_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GoalWithTimePeriod_Max_Order_By>;
  min?: InputMaybe<GoalWithTimePeriod_Min_Order_By>;
};

/** input type for inserting array relation for remote table "goalWithTimePeriod" */
export type GoalWithTimePeriod_Arr_Rel_Insert_Input = {
  data: Array<GoalWithTimePeriod_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GoalWithTimePeriod_On_Conflict>;
};

/** Boolean expression to filter rows from the table "goalWithTimePeriod". All fields are combined with a logical 'AND'. */
export type GoalWithTimePeriod_Bool_Exp = {
  _and?: InputMaybe<Array<GoalWithTimePeriod_Bool_Exp>>;
  _not?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
  _or?: InputMaybe<Array<GoalWithTimePeriod_Bool_Exp>>;
  goal?: InputMaybe<Goals_Bool_Exp>;
  goalId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  timePeriod?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "goalWithTimePeriod" */
export enum GoalWithTimePeriod_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoalWithTimePeriodPkey = 'goalWithTimePeriod_pkey',
}

/** input type for inserting data into table "goalWithTimePeriod" */
export type GoalWithTimePeriod_Insert_Input = {
  goal?: InputMaybe<Goals_Obj_Rel_Insert_Input>;
  goalId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GoalWithTimePeriod_Max_Fields = {
  __typename?: 'goalWithTimePeriod_max_fields';
  goalId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  timePeriod?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "goalWithTimePeriod" */
export type GoalWithTimePeriod_Max_Order_By = {
  goalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GoalWithTimePeriod_Min_Fields = {
  __typename?: 'goalWithTimePeriod_min_fields';
  goalId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  timePeriod?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "goalWithTimePeriod" */
export type GoalWithTimePeriod_Min_Order_By = {
  goalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "goalWithTimePeriod" */
export type GoalWithTimePeriod_Mutation_Response = {
  __typename?: 'goalWithTimePeriod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoalWithTimePeriod>;
};

/** on_conflict condition type for table "goalWithTimePeriod" */
export type GoalWithTimePeriod_On_Conflict = {
  constraint: GoalWithTimePeriod_Constraint;
  update_columns?: Array<GoalWithTimePeriod_Update_Column>;
  where?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
};

/** Ordering options when selecting data from "goalWithTimePeriod". */
export type GoalWithTimePeriod_Order_By = {
  goal?: InputMaybe<Goals_Order_By>;
  goalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
};

/** primary key columns input for table: goalWithTimePeriod */
export type GoalWithTimePeriod_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "goalWithTimePeriod" */
export enum GoalWithTimePeriod_Select_Column {
  /** column name */
  GoalId = 'goalId',
  /** column name */
  Id = 'id',
  /** column name */
  TimePeriod = 'timePeriod',
}

/** input type for updating data in table "goalWithTimePeriod" */
export type GoalWithTimePeriod_Set_Input = {
  goalId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "goalWithTimePeriod" */
export type GoalWithTimePeriod_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoalWithTimePeriod_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoalWithTimePeriod_Stream_Cursor_Value_Input = {
  goalId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

/** update columns of table "goalWithTimePeriod" */
export enum GoalWithTimePeriod_Update_Column {
  /** column name */
  GoalId = 'goalId',
  /** column name */
  Id = 'id',
  /** column name */
  TimePeriod = 'timePeriod',
}

export type GoalWithTimePeriod_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoalWithTimePeriod_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoalWithTimePeriod_Bool_Exp;
};

/** columns and relationships of "goals" */
export type Goals = {
  __typename?: 'goals';
  created_At: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An array relationship */
  goalWithKpis: Array<GoalWithKpi>;
  /** An aggregate relationship */
  goalWithKpis_aggregate: GoalWithKpi_Aggregate;
  /** An array relationship */
  goalWithTimePeriods: Array<GoalWithTimePeriod>;
  /** An aggregate relationship */
  goalWithTimePeriods_aggregate: GoalWithTimePeriod_Aggregate;
  /** An array relationship */
  goalsWithDepts: Array<GoalsWithDepts>;
  /** An aggregate relationship */
  goalsWithDepts_aggregate: GoalsWithDepts_Aggregate;
  /** An array relationship */
  goalsWithFreqs: Array<GoalsWithFreqs>;
  /** An aggregate relationship */
  goalsWithFreqs_aggregate: GoalsWithFreqs_Aggregate;
  /** An array relationship */
  goalsWithRoles: Array<GoalsWithRoles>;
  /** An aggregate relationship */
  goalsWithRoles_aggregate: GoalsWithRoles_Aggregate;
  id: Scalars['uuid'];
  manager_Id: Scalars['uuid'];
  /** An array relationship */
  performance_review_byUser_WithGoals: Array<Performance_Review_ByUser_WithGoal>;
  /** An aggregate relationship */
  performance_review_byUser_WithGoals_aggregate: Performance_Review_ByUser_WithGoal_Aggregate;
  /** An array relationship */
  performance_review_setting_GoalsLists: Array<Performance_Review_Setting_GoalsList>;
  /** An aggregate relationship */
  performance_review_setting_GoalsLists_aggregate: Performance_Review_Setting_GoalsList_Aggregate;
  timePeriodic?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_At: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  weightage_s?: Maybe<Scalars['String']>;
};

/** columns and relationships of "goals" */
export type GoalsGoalWithKpisArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalWithKpis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalWithTimePeriodsArgs = {
  distinct_on?: InputMaybe<Array<GoalWithTimePeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithTimePeriod_Order_By>>;
  where?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalWithTimePeriods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalWithTimePeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithTimePeriod_Order_By>>;
  where?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalsWithDeptsArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithDepts_Order_By>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalsWithDepts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithDepts_Order_By>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalsWithFreqsArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithFreqs_Order_By>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalsWithFreqs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithFreqs_Order_By>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalsWithRolesArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithRoles_Order_By>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsGoalsWithRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithRoles_Order_By>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsPerformance_Review_ByUser_WithGoalsArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_ByUser_WithGoal_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsPerformance_Review_ByUser_WithGoals_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_ByUser_WithGoal_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsPerformance_Review_Setting_GoalsListsArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_Setting_GoalsList_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
  where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
};

/** columns and relationships of "goals" */
export type GoalsPerformance_Review_Setting_GoalsLists_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_Setting_GoalsList_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
  where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
};

/** columns and relationships of "goalsWithDepts" */
export type GoalsWithDepts = {
  __typename?: 'goalsWithDepts';
  /** An object relationship */
  department?: Maybe<Department>;
  department_ID?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  goal: Goals;
  goal_ID: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "goalsWithDepts" */
export type GoalsWithDepts_Aggregate = {
  __typename?: 'goalsWithDepts_aggregate';
  aggregate?: Maybe<GoalsWithDepts_Aggregate_Fields>;
  nodes: Array<GoalsWithDepts>;
};

export type GoalsWithDepts_Aggregate_Bool_Exp = {
  count?: InputMaybe<GoalsWithDepts_Aggregate_Bool_Exp_Count>;
};

export type GoalsWithDepts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithDepts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "goalsWithDepts" */
export type GoalsWithDepts_Aggregate_Fields = {
  __typename?: 'goalsWithDepts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GoalsWithDepts_Max_Fields>;
  min?: Maybe<GoalsWithDepts_Min_Fields>;
};

/** aggregate fields of "goalsWithDepts" */
export type GoalsWithDepts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goalsWithDepts" */
export type GoalsWithDepts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GoalsWithDepts_Max_Order_By>;
  min?: InputMaybe<GoalsWithDepts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "goalsWithDepts" */
export type GoalsWithDepts_Arr_Rel_Insert_Input = {
  data: Array<GoalsWithDepts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GoalsWithDepts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "goalsWithDepts". All fields are combined with a logical 'AND'. */
export type GoalsWithDepts_Bool_Exp = {
  _and?: InputMaybe<Array<GoalsWithDepts_Bool_Exp>>;
  _not?: InputMaybe<GoalsWithDepts_Bool_Exp>;
  _or?: InputMaybe<Array<GoalsWithDepts_Bool_Exp>>;
  department?: InputMaybe<Department_Bool_Exp>;
  department_ID?: InputMaybe<Uuid_Comparison_Exp>;
  goal?: InputMaybe<Goals_Bool_Exp>;
  goal_ID?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "goalsWithDepts" */
export enum GoalsWithDepts_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoalsWithDeptsPkey = 'goalsWithDepts_pkey',
}

/** input type for inserting data into table "goalsWithDepts" */
export type GoalsWithDepts_Insert_Input = {
  department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  department_ID?: InputMaybe<Scalars['uuid']>;
  goal?: InputMaybe<Goals_Obj_Rel_Insert_Input>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GoalsWithDepts_Max_Fields = {
  __typename?: 'goalsWithDepts_max_fields';
  department_ID?: Maybe<Scalars['uuid']>;
  goal_ID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "goalsWithDepts" */
export type GoalsWithDepts_Max_Order_By = {
  department_ID?: InputMaybe<Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GoalsWithDepts_Min_Fields = {
  __typename?: 'goalsWithDepts_min_fields';
  department_ID?: Maybe<Scalars['uuid']>;
  goal_ID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "goalsWithDepts" */
export type GoalsWithDepts_Min_Order_By = {
  department_ID?: InputMaybe<Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "goalsWithDepts" */
export type GoalsWithDepts_Mutation_Response = {
  __typename?: 'goalsWithDepts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoalsWithDepts>;
};

/** on_conflict condition type for table "goalsWithDepts" */
export type GoalsWithDepts_On_Conflict = {
  constraint: GoalsWithDepts_Constraint;
  update_columns?: Array<GoalsWithDepts_Update_Column>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

/** Ordering options when selecting data from "goalsWithDepts". */
export type GoalsWithDepts_Order_By = {
  department?: InputMaybe<Department_Order_By>;
  department_ID?: InputMaybe<Order_By>;
  goal?: InputMaybe<Goals_Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: goalsWithDepts */
export type GoalsWithDepts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "goalsWithDepts" */
export enum GoalsWithDepts_Select_Column {
  /** column name */
  DepartmentId = 'department_ID',
  /** column name */
  GoalId = 'goal_ID',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "goalsWithDepts" */
export type GoalsWithDepts_Set_Input = {
  department_ID?: InputMaybe<Scalars['uuid']>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "goalsWithDepts" */
export type GoalsWithDepts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoalsWithDepts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoalsWithDepts_Stream_Cursor_Value_Input = {
  department_ID?: InputMaybe<Scalars['uuid']>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "goalsWithDepts" */
export enum GoalsWithDepts_Update_Column {
  /** column name */
  DepartmentId = 'department_ID',
  /** column name */
  GoalId = 'goal_ID',
  /** column name */
  Id = 'id',
}

export type GoalsWithDepts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoalsWithDepts_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoalsWithDepts_Bool_Exp;
};

/** columns and relationships of "goalsWithFreqs" */
export type GoalsWithFreqs = {
  __typename?: 'goalsWithFreqs';
  freq_Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  frequency?: Maybe<Frequency>;
  /** An object relationship */
  goal: Goals;
  goal_ID: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "goalsWithFreqs" */
export type GoalsWithFreqs_Aggregate = {
  __typename?: 'goalsWithFreqs_aggregate';
  aggregate?: Maybe<GoalsWithFreqs_Aggregate_Fields>;
  nodes: Array<GoalsWithFreqs>;
};

export type GoalsWithFreqs_Aggregate_Bool_Exp = {
  count?: InputMaybe<GoalsWithFreqs_Aggregate_Bool_Exp_Count>;
};

export type GoalsWithFreqs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "goalsWithFreqs" */
export type GoalsWithFreqs_Aggregate_Fields = {
  __typename?: 'goalsWithFreqs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GoalsWithFreqs_Max_Fields>;
  min?: Maybe<GoalsWithFreqs_Min_Fields>;
};

/** aggregate fields of "goalsWithFreqs" */
export type GoalsWithFreqs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goalsWithFreqs" */
export type GoalsWithFreqs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GoalsWithFreqs_Max_Order_By>;
  min?: InputMaybe<GoalsWithFreqs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "goalsWithFreqs" */
export type GoalsWithFreqs_Arr_Rel_Insert_Input = {
  data: Array<GoalsWithFreqs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GoalsWithFreqs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "goalsWithFreqs". All fields are combined with a logical 'AND'. */
export type GoalsWithFreqs_Bool_Exp = {
  _and?: InputMaybe<Array<GoalsWithFreqs_Bool_Exp>>;
  _not?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
  _or?: InputMaybe<Array<GoalsWithFreqs_Bool_Exp>>;
  freq_Id?: InputMaybe<Uuid_Comparison_Exp>;
  frequency?: InputMaybe<Frequency_Bool_Exp>;
  goal?: InputMaybe<Goals_Bool_Exp>;
  goal_ID?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "goalsWithFreqs" */
export enum GoalsWithFreqs_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoalsWithFreqsPkey = 'goalsWithFreqs_pkey',
}

/** input type for inserting data into table "goalsWithFreqs" */
export type GoalsWithFreqs_Insert_Input = {
  freq_Id?: InputMaybe<Scalars['uuid']>;
  frequency?: InputMaybe<Frequency_Obj_Rel_Insert_Input>;
  goal?: InputMaybe<Goals_Obj_Rel_Insert_Input>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GoalsWithFreqs_Max_Fields = {
  __typename?: 'goalsWithFreqs_max_fields';
  freq_Id?: Maybe<Scalars['uuid']>;
  goal_ID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "goalsWithFreqs" */
export type GoalsWithFreqs_Max_Order_By = {
  freq_Id?: InputMaybe<Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GoalsWithFreqs_Min_Fields = {
  __typename?: 'goalsWithFreqs_min_fields';
  freq_Id?: Maybe<Scalars['uuid']>;
  goal_ID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "goalsWithFreqs" */
export type GoalsWithFreqs_Min_Order_By = {
  freq_Id?: InputMaybe<Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "goalsWithFreqs" */
export type GoalsWithFreqs_Mutation_Response = {
  __typename?: 'goalsWithFreqs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoalsWithFreqs>;
};

/** on_conflict condition type for table "goalsWithFreqs" */
export type GoalsWithFreqs_On_Conflict = {
  constraint: GoalsWithFreqs_Constraint;
  update_columns?: Array<GoalsWithFreqs_Update_Column>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

/** Ordering options when selecting data from "goalsWithFreqs". */
export type GoalsWithFreqs_Order_By = {
  freq_Id?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Frequency_Order_By>;
  goal?: InputMaybe<Goals_Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: goalsWithFreqs */
export type GoalsWithFreqs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "goalsWithFreqs" */
export enum GoalsWithFreqs_Select_Column {
  /** column name */
  FreqId = 'freq_Id',
  /** column name */
  GoalId = 'goal_ID',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "goalsWithFreqs" */
export type GoalsWithFreqs_Set_Input = {
  freq_Id?: InputMaybe<Scalars['uuid']>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "goalsWithFreqs" */
export type GoalsWithFreqs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoalsWithFreqs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoalsWithFreqs_Stream_Cursor_Value_Input = {
  freq_Id?: InputMaybe<Scalars['uuid']>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "goalsWithFreqs" */
export enum GoalsWithFreqs_Update_Column {
  /** column name */
  FreqId = 'freq_Id',
  /** column name */
  GoalId = 'goal_ID',
  /** column name */
  Id = 'id',
}

export type GoalsWithFreqs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoalsWithFreqs_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoalsWithFreqs_Bool_Exp;
};

/** columns and relationships of "goalsWithRoles" */
export type GoalsWithRoles = {
  __typename?: 'goalsWithRoles';
  /** An object relationship */
  goal: Goals;
  goal_ID: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization_role?: Maybe<Organization_Roles>;
  role_ID?: Maybe<Scalars['uuid']>;
  role_weightage?: Maybe<Scalars['float8']>;
  time_period?: Maybe<Scalars['String']>;
};

/** aggregated selection of "goalsWithRoles" */
export type GoalsWithRoles_Aggregate = {
  __typename?: 'goalsWithRoles_aggregate';
  aggregate?: Maybe<GoalsWithRoles_Aggregate_Fields>;
  nodes: Array<GoalsWithRoles>;
};

export type GoalsWithRoles_Aggregate_Bool_Exp = {
  avg?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp_Var_Samp>;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Avg = {
  arguments: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Corr = {
  arguments: GoalsWithRoles_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Corr_Arguments = {
  X: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: GoalsWithRoles_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Max = {
  arguments: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Min = {
  arguments: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Sum = {
  arguments: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GoalsWithRoles_Aggregate_Bool_Exp_Var_Samp = {
  arguments: GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "goalsWithRoles" */
export type GoalsWithRoles_Aggregate_Fields = {
  __typename?: 'goalsWithRoles_aggregate_fields';
  avg?: Maybe<GoalsWithRoles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GoalsWithRoles_Max_Fields>;
  min?: Maybe<GoalsWithRoles_Min_Fields>;
  stddev?: Maybe<GoalsWithRoles_Stddev_Fields>;
  stddev_pop?: Maybe<GoalsWithRoles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GoalsWithRoles_Stddev_Samp_Fields>;
  sum?: Maybe<GoalsWithRoles_Sum_Fields>;
  var_pop?: Maybe<GoalsWithRoles_Var_Pop_Fields>;
  var_samp?: Maybe<GoalsWithRoles_Var_Samp_Fields>;
  variance?: Maybe<GoalsWithRoles_Variance_Fields>;
};

/** aggregate fields of "goalsWithRoles" */
export type GoalsWithRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goalsWithRoles" */
export type GoalsWithRoles_Aggregate_Order_By = {
  avg?: InputMaybe<GoalsWithRoles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GoalsWithRoles_Max_Order_By>;
  min?: InputMaybe<GoalsWithRoles_Min_Order_By>;
  stddev?: InputMaybe<GoalsWithRoles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GoalsWithRoles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GoalsWithRoles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GoalsWithRoles_Sum_Order_By>;
  var_pop?: InputMaybe<GoalsWithRoles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GoalsWithRoles_Var_Samp_Order_By>;
  variance?: InputMaybe<GoalsWithRoles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "goalsWithRoles" */
export type GoalsWithRoles_Arr_Rel_Insert_Input = {
  data: Array<GoalsWithRoles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GoalsWithRoles_On_Conflict>;
};

/** aggregate avg on columns */
export type GoalsWithRoles_Avg_Fields = {
  __typename?: 'goalsWithRoles_avg_fields';
  role_weightage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Avg_Order_By = {
  role_weightage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "goalsWithRoles". All fields are combined with a logical 'AND'. */
export type GoalsWithRoles_Bool_Exp = {
  _and?: InputMaybe<Array<GoalsWithRoles_Bool_Exp>>;
  _not?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  _or?: InputMaybe<Array<GoalsWithRoles_Bool_Exp>>;
  goal?: InputMaybe<Goals_Bool_Exp>;
  goal_ID?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  role_ID?: InputMaybe<Uuid_Comparison_Exp>;
  role_weightage?: InputMaybe<Float8_Comparison_Exp>;
  time_period?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "goalsWithRoles" */
export enum GoalsWithRoles_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoalsWithRolesPkey = 'goalsWithRoles_pkey',
}

/** input type for incrementing numeric columns in table "goalsWithRoles" */
export type GoalsWithRoles_Inc_Input = {
  role_weightage?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "goalsWithRoles" */
export type GoalsWithRoles_Insert_Input = {
  goal?: InputMaybe<Goals_Obj_Rel_Insert_Input>;
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  role_ID?: InputMaybe<Scalars['uuid']>;
  role_weightage?: InputMaybe<Scalars['float8']>;
  time_period?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GoalsWithRoles_Max_Fields = {
  __typename?: 'goalsWithRoles_max_fields';
  goal_ID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role_ID?: Maybe<Scalars['uuid']>;
  role_weightage?: Maybe<Scalars['float8']>;
  time_period?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Max_Order_By = {
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_ID?: InputMaybe<Order_By>;
  role_weightage?: InputMaybe<Order_By>;
  time_period?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GoalsWithRoles_Min_Fields = {
  __typename?: 'goalsWithRoles_min_fields';
  goal_ID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role_ID?: Maybe<Scalars['uuid']>;
  role_weightage?: Maybe<Scalars['float8']>;
  time_period?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Min_Order_By = {
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_ID?: InputMaybe<Order_By>;
  role_weightage?: InputMaybe<Order_By>;
  time_period?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "goalsWithRoles" */
export type GoalsWithRoles_Mutation_Response = {
  __typename?: 'goalsWithRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoalsWithRoles>;
};

/** on_conflict condition type for table "goalsWithRoles" */
export type GoalsWithRoles_On_Conflict = {
  constraint: GoalsWithRoles_Constraint;
  update_columns?: Array<GoalsWithRoles_Update_Column>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "goalsWithRoles". */
export type GoalsWithRoles_Order_By = {
  goal?: InputMaybe<Goals_Order_By>;
  goal_ID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  role_ID?: InputMaybe<Order_By>;
  role_weightage?: InputMaybe<Order_By>;
  time_period?: InputMaybe<Order_By>;
};

/** primary key columns input for table: goalsWithRoles */
export type GoalsWithRoles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column {
  /** column name */
  GoalId = 'goal_ID',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_ID',
  /** column name */
  RoleWeightage = 'role_weightage',
  /** column name */
  TimePeriod = 'time_period',
}

/** select "goalsWithRoles_aggregate_bool_exp_avg_arguments_columns" columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  RoleWeightage = 'role_weightage',
}

/** select "goalsWithRoles_aggregate_bool_exp_corr_arguments_columns" columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  RoleWeightage = 'role_weightage',
}

/** select "goalsWithRoles_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  RoleWeightage = 'role_weightage',
}

/** select "goalsWithRoles_aggregate_bool_exp_max_arguments_columns" columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  RoleWeightage = 'role_weightage',
}

/** select "goalsWithRoles_aggregate_bool_exp_min_arguments_columns" columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  RoleWeightage = 'role_weightage',
}

/** select "goalsWithRoles_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  RoleWeightage = 'role_weightage',
}

/** select "goalsWithRoles_aggregate_bool_exp_sum_arguments_columns" columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  RoleWeightage = 'role_weightage',
}

/** select "goalsWithRoles_aggregate_bool_exp_var_samp_arguments_columns" columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Select_Column_GoalsWithRoles_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  RoleWeightage = 'role_weightage',
}

/** input type for updating data in table "goalsWithRoles" */
export type GoalsWithRoles_Set_Input = {
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_ID?: InputMaybe<Scalars['uuid']>;
  role_weightage?: InputMaybe<Scalars['float8']>;
  time_period?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type GoalsWithRoles_Stddev_Fields = {
  __typename?: 'goalsWithRoles_stddev_fields';
  role_weightage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Stddev_Order_By = {
  role_weightage?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GoalsWithRoles_Stddev_Pop_Fields = {
  __typename?: 'goalsWithRoles_stddev_pop_fields';
  role_weightage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Stddev_Pop_Order_By = {
  role_weightage?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GoalsWithRoles_Stddev_Samp_Fields = {
  __typename?: 'goalsWithRoles_stddev_samp_fields';
  role_weightage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Stddev_Samp_Order_By = {
  role_weightage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "goalsWithRoles" */
export type GoalsWithRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoalsWithRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoalsWithRoles_Stream_Cursor_Value_Input = {
  goal_ID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_ID?: InputMaybe<Scalars['uuid']>;
  role_weightage?: InputMaybe<Scalars['float8']>;
  time_period?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type GoalsWithRoles_Sum_Fields = {
  __typename?: 'goalsWithRoles_sum_fields';
  role_weightage?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Sum_Order_By = {
  role_weightage?: InputMaybe<Order_By>;
};

/** update columns of table "goalsWithRoles" */
export enum GoalsWithRoles_Update_Column {
  /** column name */
  GoalId = 'goal_ID',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_ID',
  /** column name */
  RoleWeightage = 'role_weightage',
  /** column name */
  TimePeriod = 'time_period',
}

export type GoalsWithRoles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GoalsWithRoles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoalsWithRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoalsWithRoles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GoalsWithRoles_Var_Pop_Fields = {
  __typename?: 'goalsWithRoles_var_pop_fields';
  role_weightage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Var_Pop_Order_By = {
  role_weightage?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GoalsWithRoles_Var_Samp_Fields = {
  __typename?: 'goalsWithRoles_var_samp_fields';
  role_weightage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Var_Samp_Order_By = {
  role_weightage?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GoalsWithRoles_Variance_Fields = {
  __typename?: 'goalsWithRoles_variance_fields';
  role_weightage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "goalsWithRoles" */
export type GoalsWithRoles_Variance_Order_By = {
  role_weightage?: InputMaybe<Order_By>;
};

/** aggregated selection of "goals" */
export type Goals_Aggregate = {
  __typename?: 'goals_aggregate';
  aggregate?: Maybe<Goals_Aggregate_Fields>;
  nodes: Array<Goals>;
};

export type Goals_Aggregate_Bool_Exp = {
  count?: InputMaybe<Goals_Aggregate_Bool_Exp_Count>;
};

export type Goals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Goals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "goals" */
export type Goals_Aggregate_Fields = {
  __typename?: 'goals_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Goals_Max_Fields>;
  min?: Maybe<Goals_Min_Fields>;
};

/** aggregate fields of "goals" */
export type Goals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goals" */
export type Goals_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Goals_Max_Order_By>;
  min?: InputMaybe<Goals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "goals" */
export type Goals_Arr_Rel_Insert_Input = {
  data: Array<Goals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};

/** Boolean expression to filter rows from the table "goals". All fields are combined with a logical 'AND'. */
export type Goals_Bool_Exp = {
  _and?: InputMaybe<Array<Goals_Bool_Exp>>;
  _not?: InputMaybe<Goals_Bool_Exp>;
  _or?: InputMaybe<Array<Goals_Bool_Exp>>;
  created_At?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  goalWithKpis?: InputMaybe<GoalWithKpi_Bool_Exp>;
  goalWithKpis_aggregate?: InputMaybe<GoalWithKpi_Aggregate_Bool_Exp>;
  goalWithTimePeriods?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
  goalWithTimePeriods_aggregate?: InputMaybe<GoalWithTimePeriod_Aggregate_Bool_Exp>;
  goalsWithDepts?: InputMaybe<GoalsWithDepts_Bool_Exp>;
  goalsWithDepts_aggregate?: InputMaybe<GoalsWithDepts_Aggregate_Bool_Exp>;
  goalsWithFreqs?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
  goalsWithFreqs_aggregate?: InputMaybe<GoalsWithFreqs_Aggregate_Bool_Exp>;
  goalsWithRoles?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  goalsWithRoles_aggregate?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  manager_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_review_byUser_WithGoals?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  performance_review_byUser_WithGoals_aggregate?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp>;
  performance_review_setting_GoalsLists?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  performance_review_setting_GoalsLists_aggregate?: InputMaybe<Performance_Review_Setting_GoalsList_Aggregate_Bool_Exp>;
  timePeriodic?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_At?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  weightage_s?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "goals" */
export enum Goals_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoalsPkey = 'goals_pkey',
}

/** input type for inserting data into table "goals" */
export type Goals_Insert_Input = {
  created_At?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  goalWithKpis?: InputMaybe<GoalWithKpi_Arr_Rel_Insert_Input>;
  goalWithTimePeriods?: InputMaybe<GoalWithTimePeriod_Arr_Rel_Insert_Input>;
  goalsWithDepts?: InputMaybe<GoalsWithDepts_Arr_Rel_Insert_Input>;
  goalsWithFreqs?: InputMaybe<GoalsWithFreqs_Arr_Rel_Insert_Input>;
  goalsWithRoles?: InputMaybe<GoalsWithRoles_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  manager_Id?: InputMaybe<Scalars['uuid']>;
  performance_review_byUser_WithGoals?: InputMaybe<Performance_Review_ByUser_WithGoal_Arr_Rel_Insert_Input>;
  performance_review_setting_GoalsLists?: InputMaybe<Performance_Review_Setting_GoalsList_Arr_Rel_Insert_Input>;
  timePeriodic?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_At?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  weightage_s?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Goals_Max_Fields = {
  __typename?: 'goals_max_fields';
  created_At?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  manager_Id?: Maybe<Scalars['uuid']>;
  timePeriodic?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_At?: Maybe<Scalars['timestamptz']>;
  weightage_s?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "goals" */
export type Goals_Max_Order_By = {
  created_At?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_Id?: InputMaybe<Order_By>;
  timePeriodic?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_At?: InputMaybe<Order_By>;
  weightage_s?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Goals_Min_Fields = {
  __typename?: 'goals_min_fields';
  created_At?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  manager_Id?: Maybe<Scalars['uuid']>;
  timePeriodic?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_At?: Maybe<Scalars['timestamptz']>;
  weightage_s?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "goals" */
export type Goals_Min_Order_By = {
  created_At?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_Id?: InputMaybe<Order_By>;
  timePeriodic?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_At?: InputMaybe<Order_By>;
  weightage_s?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "goals" */
export type Goals_Mutation_Response = {
  __typename?: 'goals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Goals>;
};

/** input type for inserting object relation for remote table "goals" */
export type Goals_Obj_Rel_Insert_Input = {
  data: Goals_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};

/** on_conflict condition type for table "goals" */
export type Goals_On_Conflict = {
  constraint: Goals_Constraint;
  update_columns?: Array<Goals_Update_Column>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

/** Ordering options when selecting data from "goals". */
export type Goals_Order_By = {
  created_At?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  goalWithKpis_aggregate?: InputMaybe<GoalWithKpi_Aggregate_Order_By>;
  goalWithTimePeriods_aggregate?: InputMaybe<GoalWithTimePeriod_Aggregate_Order_By>;
  goalsWithDepts_aggregate?: InputMaybe<GoalsWithDepts_Aggregate_Order_By>;
  goalsWithFreqs_aggregate?: InputMaybe<GoalsWithFreqs_Aggregate_Order_By>;
  goalsWithRoles_aggregate?: InputMaybe<GoalsWithRoles_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  manager_Id?: InputMaybe<Order_By>;
  performance_review_byUser_WithGoals_aggregate?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Order_By>;
  performance_review_setting_GoalsLists_aggregate?: InputMaybe<Performance_Review_Setting_GoalsList_Aggregate_Order_By>;
  timePeriodic?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_At?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  weightage_s?: InputMaybe<Order_By>;
};

/** primary key columns input for table: goals */
export type Goals_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "goals" */
export enum Goals_Select_Column {
  /** column name */
  CreatedAt = 'created_At',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'manager_Id',
  /** column name */
  TimePeriodic = 'timePeriodic',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_At',
  /** column name */
  WeightageS = 'weightage_s',
}

/** input type for updating data in table "goals" */
export type Goals_Set_Input = {
  created_At?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  manager_Id?: InputMaybe<Scalars['uuid']>;
  timePeriodic?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_At?: InputMaybe<Scalars['timestamptz']>;
  weightage_s?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "goals" */
export type Goals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Goals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Goals_Stream_Cursor_Value_Input = {
  created_At?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  manager_Id?: InputMaybe<Scalars['uuid']>;
  timePeriodic?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_At?: InputMaybe<Scalars['timestamptz']>;
  weightage_s?: InputMaybe<Scalars['String']>;
};

/** update columns of table "goals" */
export enum Goals_Update_Column {
  /** column name */
  CreatedAt = 'created_At',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'manager_Id',
  /** column name */
  TimePeriodic = 'timePeriodic',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_At',
  /** column name */
  WeightageS = 'weightage_s',
}

export type Goals_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Goals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Goals_Bool_Exp;
};

/** columns and relationships of "hotdesk_bookings" */
export type Hotdesk_Bookings = {
  __typename?: 'hotdesk_bookings';
  created_at: Scalars['timestamptz'];
  date: Scalars['date'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  location_id: Scalars['uuid'];
  /** An object relationship */
  organization_location: Organization_Locations;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "hotdesk_bookings" */
export type Hotdesk_Bookings_Aggregate = {
  __typename?: 'hotdesk_bookings_aggregate';
  aggregate?: Maybe<Hotdesk_Bookings_Aggregate_Fields>;
  nodes: Array<Hotdesk_Bookings>;
};

export type Hotdesk_Bookings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hotdesk_Bookings_Aggregate_Bool_Exp_Count>;
};

export type Hotdesk_Bookings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hotdesk_bookings" */
export type Hotdesk_Bookings_Aggregate_Fields = {
  __typename?: 'hotdesk_bookings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hotdesk_Bookings_Max_Fields>;
  min?: Maybe<Hotdesk_Bookings_Min_Fields>;
};

/** aggregate fields of "hotdesk_bookings" */
export type Hotdesk_Bookings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hotdesk_bookings" */
export type Hotdesk_Bookings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hotdesk_Bookings_Max_Order_By>;
  min?: InputMaybe<Hotdesk_Bookings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hotdesk_bookings" */
export type Hotdesk_Bookings_Arr_Rel_Insert_Input = {
  data: Array<Hotdesk_Bookings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hotdesk_Bookings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hotdesk_bookings". All fields are combined with a logical 'AND'. */
export type Hotdesk_Bookings_Bool_Exp = {
  _and?: InputMaybe<Array<Hotdesk_Bookings_Bool_Exp>>;
  _not?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
  _or?: InputMaybe<Array<Hotdesk_Bookings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_location?: InputMaybe<Organization_Locations_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "hotdesk_bookings" */
export enum Hotdesk_Bookings_Constraint {
  /** unique or primary key constraint on columns "id" */
  HotdeskBookingsPkey = 'hotdesk_bookings_pkey',
}

/** input type for inserting data into table "hotdesk_bookings" */
export type Hotdesk_Bookings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  organization_location?: InputMaybe<Organization_Locations_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Hotdesk_Bookings_Max_Fields = {
  __typename?: 'hotdesk_bookings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hotdesk_bookings" */
export type Hotdesk_Bookings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hotdesk_Bookings_Min_Fields = {
  __typename?: 'hotdesk_bookings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hotdesk_bookings" */
export type Hotdesk_Bookings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hotdesk_bookings" */
export type Hotdesk_Bookings_Mutation_Response = {
  __typename?: 'hotdesk_bookings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hotdesk_Bookings>;
};

/** on_conflict condition type for table "hotdesk_bookings" */
export type Hotdesk_Bookings_On_Conflict = {
  constraint: Hotdesk_Bookings_Constraint;
  update_columns?: Array<Hotdesk_Bookings_Update_Column>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

/** Ordering options when selecting data from "hotdesk_bookings". */
export type Hotdesk_Bookings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_location?: InputMaybe<Organization_Locations_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hotdesk_bookings */
export type Hotdesk_Bookings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hotdesk_bookings" */
export enum Hotdesk_Bookings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "hotdesk_bookings" */
export type Hotdesk_Bookings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "hotdesk_bookings" */
export type Hotdesk_Bookings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hotdesk_Bookings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hotdesk_Bookings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "hotdesk_bookings" */
export enum Hotdesk_Bookings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Hotdesk_Bookings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hotdesk_Bookings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hotdesk_Bookings_Bool_Exp;
};

export type IndividualScoreAssessmentData = {
  __typename?: 'individualScoreAssessmentData';
  assessmentName?: Maybe<Scalars['String']>;
  attempted?: Maybe<Scalars['Int']>;
  passed?: Maybe<Scalars['Int']>;
  pendingAssessment?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  totalAssessment?: Maybe<Scalars['Int']>;
};

export type IndividualTimeSpentGraphInput = {
  endDate: Scalars['String'];
  orgId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  userId: Array<InputMaybe<Scalars['String']>>;
};

export type IndividualTimeSpentGraphOutput = {
  __typename?: 'individualTimeSpentGraphOutput';
  response: Array<Maybe<TimeGraphData>>;
};

export type Joined_Call_Usrs_Args = {
  cemv?: InputMaybe<Scalars['calendar_event_material_view_scalar']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type KpiBankCount = {
  __typename?: 'kpiBankCount';
  aggregate?: Maybe<KpiCounts>;
};

export type KpiCounts = {
  __typename?: 'kpiCounts';
  count?: Maybe<Scalars['Int']>;
};

export type KpiDetailUuid = {
  comment?: InputMaybe<Scalars['String']>;
  kpiId: Scalars['uuid'];
  marks?: InputMaybe<Scalars['Int']>;
};

export type KpiResponses = {
  __typename?: 'kpiResponses';
  kpi_bank: Array<Maybe<ListOfKpiPerRole>>;
  kpi_bank_aggregate?: Maybe<KpiBankCount>;
  listOfKpi: Array<Maybe<ListOfKpiPerRole>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type KpiUuid = {
  createdBy: Scalars['uuid'];
  kpi_Id: Scalars['uuid'];
};

/** columns and relationships of "kpiWithDept" */
export type KpiWithDept = {
  __typename?: 'kpiWithDept';
  /** An object relationship */
  department?: Maybe<Department>;
  department_Id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  kpi_Id: Scalars['uuid'];
  /** An object relationship */
  kpi_bank: Kpi_Bank;
};

/** aggregated selection of "kpiWithDept" */
export type KpiWithDept_Aggregate = {
  __typename?: 'kpiWithDept_aggregate';
  aggregate?: Maybe<KpiWithDept_Aggregate_Fields>;
  nodes: Array<KpiWithDept>;
};

export type KpiWithDept_Aggregate_Bool_Exp = {
  count?: InputMaybe<KpiWithDept_Aggregate_Bool_Exp_Count>;
};

export type KpiWithDept_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<KpiWithDept_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kpiWithDept" */
export type KpiWithDept_Aggregate_Fields = {
  __typename?: 'kpiWithDept_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<KpiWithDept_Max_Fields>;
  min?: Maybe<KpiWithDept_Min_Fields>;
};

/** aggregate fields of "kpiWithDept" */
export type KpiWithDept_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kpiWithDept" */
export type KpiWithDept_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<KpiWithDept_Max_Order_By>;
  min?: InputMaybe<KpiWithDept_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kpiWithDept" */
export type KpiWithDept_Arr_Rel_Insert_Input = {
  data: Array<KpiWithDept_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<KpiWithDept_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kpiWithDept". All fields are combined with a logical 'AND'. */
export type KpiWithDept_Bool_Exp = {
  _and?: InputMaybe<Array<KpiWithDept_Bool_Exp>>;
  _not?: InputMaybe<KpiWithDept_Bool_Exp>;
  _or?: InputMaybe<Array<KpiWithDept_Bool_Exp>>;
  department?: InputMaybe<Department_Bool_Exp>;
  department_Id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_Id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_bank?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

/** unique or primary key constraints on table "kpiWithDept" */
export enum KpiWithDept_Constraint {
  /** unique or primary key constraint on columns "id" */
  KpiWithRolesAndDeptPkey = 'kpiWithRolesAndDept_pkey',
}

/** input type for inserting data into table "kpiWithDept" */
export type KpiWithDept_Insert_Input = {
  department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  department_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
  kpi_bank?: InputMaybe<Kpi_Bank_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type KpiWithDept_Max_Fields = {
  __typename?: 'kpiWithDept_max_fields';
  department_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  kpi_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "kpiWithDept" */
export type KpiWithDept_Max_Order_By = {
  department_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type KpiWithDept_Min_Fields = {
  __typename?: 'kpiWithDept_min_fields';
  department_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  kpi_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "kpiWithDept" */
export type KpiWithDept_Min_Order_By = {
  department_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kpiWithDept" */
export type KpiWithDept_Mutation_Response = {
  __typename?: 'kpiWithDept_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<KpiWithDept>;
};

/** on_conflict condition type for table "kpiWithDept" */
export type KpiWithDept_On_Conflict = {
  constraint: KpiWithDept_Constraint;
  update_columns?: Array<KpiWithDept_Update_Column>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

/** Ordering options when selecting data from "kpiWithDept". */
export type KpiWithDept_Order_By = {
  department?: InputMaybe<Department_Order_By>;
  department_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
  kpi_bank?: InputMaybe<Kpi_Bank_Order_By>;
};

/** primary key columns input for table: kpiWithDept */
export type KpiWithDept_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kpiWithDept" */
export enum KpiWithDept_Select_Column {
  /** column name */
  DepartmentId = 'department_Id',
  /** column name */
  Id = 'id',
  /** column name */
  KpiId = 'kpi_Id',
}

/** input type for updating data in table "kpiWithDept" */
export type KpiWithDept_Set_Input = {
  department_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "kpiWithDept" */
export type KpiWithDept_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: KpiWithDept_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type KpiWithDept_Stream_Cursor_Value_Input = {
  department_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "kpiWithDept" */
export enum KpiWithDept_Update_Column {
  /** column name */
  DepartmentId = 'department_Id',
  /** column name */
  Id = 'id',
  /** column name */
  KpiId = 'kpi_Id',
}

export type KpiWithDept_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<KpiWithDept_Set_Input>;
  /** filter the rows which have to be updated */
  where: KpiWithDept_Bool_Exp;
};

/** columns and relationships of "kpiWithRole" */
export type KpiWithRole = {
  __typename?: 'kpiWithRole';
  id: Scalars['uuid'];
  kpi_Id: Scalars['uuid'];
  /** An object relationship */
  kpi_bank: Kpi_Bank;
  /** An object relationship */
  organization_role?: Maybe<Organization_Roles>;
  role_Id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "kpiWithRole" */
export type KpiWithRole_Aggregate = {
  __typename?: 'kpiWithRole_aggregate';
  aggregate?: Maybe<KpiWithRole_Aggregate_Fields>;
  nodes: Array<KpiWithRole>;
};

export type KpiWithRole_Aggregate_Bool_Exp = {
  count?: InputMaybe<KpiWithRole_Aggregate_Bool_Exp_Count>;
};

export type KpiWithRole_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<KpiWithRole_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kpiWithRole" */
export type KpiWithRole_Aggregate_Fields = {
  __typename?: 'kpiWithRole_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<KpiWithRole_Max_Fields>;
  min?: Maybe<KpiWithRole_Min_Fields>;
};

/** aggregate fields of "kpiWithRole" */
export type KpiWithRole_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kpiWithRole" */
export type KpiWithRole_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<KpiWithRole_Max_Order_By>;
  min?: InputMaybe<KpiWithRole_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kpiWithRole" */
export type KpiWithRole_Arr_Rel_Insert_Input = {
  data: Array<KpiWithRole_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<KpiWithRole_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kpiWithRole". All fields are combined with a logical 'AND'. */
export type KpiWithRole_Bool_Exp = {
  _and?: InputMaybe<Array<KpiWithRole_Bool_Exp>>;
  _not?: InputMaybe<KpiWithRole_Bool_Exp>;
  _or?: InputMaybe<Array<KpiWithRole_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_Id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_bank?: InputMaybe<Kpi_Bank_Bool_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  role_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "kpiWithRole" */
export enum KpiWithRole_Constraint {
  /** unique or primary key constraint on columns "id" */
  KpiWithRolePkey = 'kpiWithRole_pkey',
}

/** input type for inserting data into table "kpiWithRole" */
export type KpiWithRole_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
  kpi_bank?: InputMaybe<Kpi_Bank_Obj_Rel_Insert_Input>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  role_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type KpiWithRole_Max_Fields = {
  __typename?: 'kpiWithRole_max_fields';
  id?: Maybe<Scalars['uuid']>;
  kpi_Id?: Maybe<Scalars['uuid']>;
  role_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "kpiWithRole" */
export type KpiWithRole_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
  role_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type KpiWithRole_Min_Fields = {
  __typename?: 'kpiWithRole_min_fields';
  id?: Maybe<Scalars['uuid']>;
  kpi_Id?: Maybe<Scalars['uuid']>;
  role_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "kpiWithRole" */
export type KpiWithRole_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
  role_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kpiWithRole" */
export type KpiWithRole_Mutation_Response = {
  __typename?: 'kpiWithRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<KpiWithRole>;
};

/** on_conflict condition type for table "kpiWithRole" */
export type KpiWithRole_On_Conflict = {
  constraint: KpiWithRole_Constraint;
  update_columns?: Array<KpiWithRole_Update_Column>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

/** Ordering options when selecting data from "kpiWithRole". */
export type KpiWithRole_Order_By = {
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
  kpi_bank?: InputMaybe<Kpi_Bank_Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  role_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kpiWithRole */
export type KpiWithRole_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kpiWithRole" */
export enum KpiWithRole_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  KpiId = 'kpi_Id',
  /** column name */
  RoleId = 'role_Id',
}

/** input type for updating data in table "kpiWithRole" */
export type KpiWithRole_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
  role_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "kpiWithRole" */
export type KpiWithRole_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: KpiWithRole_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type KpiWithRole_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
  role_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "kpiWithRole" */
export enum KpiWithRole_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  KpiId = 'kpi_Id',
  /** column name */
  RoleId = 'role_Id',
}

export type KpiWithRole_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<KpiWithRole_Set_Input>;
  /** filter the rows which have to be updated */
  where: KpiWithRole_Bool_Exp;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_Bank = {
  __typename?: 'kpi_bank';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An array relationship */
  goalWithKpis: Array<GoalWithKpi>;
  /** An aggregate relationship */
  goalWithKpis_aggregate: GoalWithKpi_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  kpiWithRoles: Array<KpiWithRole>;
  /** An array relationship */
  kpiWithRolesAndDepts: Array<KpiWithDept>;
  /** An aggregate relationship */
  kpiWithRolesAndDepts_aggregate: KpiWithDept_Aggregate;
  /** An aggregate relationship */
  kpiWithRoles_aggregate: KpiWithRole_Aggregate;
  managerId: Scalars['uuid'];
  open_ended_kpi?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  performance_review_byUser_Kpis: Array<Performance_Review_ByUser_Kpi>;
  /** An aggregate relationship */
  performance_review_byUser_Kpis_aggregate: Performance_Review_ByUser_Kpi_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_BankGoalWithKpisArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_BankGoalWithKpis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_BankKpiWithRolesArgs = {
  distinct_on?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithRole_Order_By>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_BankKpiWithRolesAndDeptsArgs = {
  distinct_on?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithDept_Order_By>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_BankKpiWithRolesAndDepts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithDept_Order_By>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_BankKpiWithRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithRole_Order_By>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_BankPerformance_Review_ByUser_KpisArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
};

/** columns and relationships of "kpi_bank" */
export type Kpi_BankPerformance_Review_ByUser_Kpis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
};

/** aggregated selection of "kpi_bank" */
export type Kpi_Bank_Aggregate = {
  __typename?: 'kpi_bank_aggregate';
  aggregate?: Maybe<Kpi_Bank_Aggregate_Fields>;
  nodes: Array<Kpi_Bank>;
};

export type Kpi_Bank_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Kpi_Bank_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Kpi_Bank_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Kpi_Bank_Aggregate_Bool_Exp_Count>;
};

export type Kpi_Bank_Aggregate_Bool_Exp_Bool_And = {
  arguments: Kpi_Bank_Select_Column_Kpi_Bank_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Kpi_Bank_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Kpi_Bank_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Kpi_Bank_Select_Column_Kpi_Bank_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Kpi_Bank_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Kpi_Bank_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kpi_Bank_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Kpi_Bank_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kpi_bank" */
export type Kpi_Bank_Aggregate_Fields = {
  __typename?: 'kpi_bank_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kpi_Bank_Max_Fields>;
  min?: Maybe<Kpi_Bank_Min_Fields>;
};

/** aggregate fields of "kpi_bank" */
export type Kpi_Bank_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kpi_Bank_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kpi_bank" */
export type Kpi_Bank_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kpi_Bank_Max_Order_By>;
  min?: InputMaybe<Kpi_Bank_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kpi_bank" */
export type Kpi_Bank_Arr_Rel_Insert_Input = {
  data: Array<Kpi_Bank_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kpi_Bank_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kpi_bank". All fields are combined with a logical 'AND'. */
export type Kpi_Bank_Bool_Exp = {
  _and?: InputMaybe<Array<Kpi_Bank_Bool_Exp>>;
  _not?: InputMaybe<Kpi_Bank_Bool_Exp>;
  _or?: InputMaybe<Array<Kpi_Bank_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  goalWithKpis?: InputMaybe<GoalWithKpi_Bool_Exp>;
  goalWithKpis_aggregate?: InputMaybe<GoalWithKpi_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpiWithRoles?: InputMaybe<KpiWithRole_Bool_Exp>;
  kpiWithRolesAndDepts?: InputMaybe<KpiWithDept_Bool_Exp>;
  kpiWithRolesAndDepts_aggregate?: InputMaybe<KpiWithDept_Aggregate_Bool_Exp>;
  kpiWithRoles_aggregate?: InputMaybe<KpiWithRole_Aggregate_Bool_Exp>;
  managerId?: InputMaybe<Uuid_Comparison_Exp>;
  open_ended_kpi?: InputMaybe<Boolean_Comparison_Exp>;
  performance_review_byUser_Kpis?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
  performance_review_byUser_Kpis_aggregate?: InputMaybe<Performance_Review_ByUser_Kpi_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "kpi_bank" */
export enum Kpi_Bank_Constraint {
  /** unique or primary key constraint on columns "id" */
  KpiBankPkey = 'kpi_bank_pkey',
}

/** input type for inserting data into table "kpi_bank" */
export type Kpi_Bank_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  goalWithKpis?: InputMaybe<GoalWithKpi_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  kpiWithRoles?: InputMaybe<KpiWithRole_Arr_Rel_Insert_Input>;
  kpiWithRolesAndDepts?: InputMaybe<KpiWithDept_Arr_Rel_Insert_Input>;
  managerId?: InputMaybe<Scalars['uuid']>;
  open_ended_kpi?: InputMaybe<Scalars['Boolean']>;
  performance_review_byUser_Kpis?: InputMaybe<Performance_Review_ByUser_Kpi_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Kpi_Bank_Max_Fields = {
  __typename?: 'kpi_bank_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  managerId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "kpi_bank" */
export type Kpi_Bank_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  managerId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kpi_Bank_Min_Fields = {
  __typename?: 'kpi_bank_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  managerId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "kpi_bank" */
export type Kpi_Bank_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  managerId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kpi_bank" */
export type Kpi_Bank_Mutation_Response = {
  __typename?: 'kpi_bank_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kpi_Bank>;
};

/** input type for inserting object relation for remote table "kpi_bank" */
export type Kpi_Bank_Obj_Rel_Insert_Input = {
  data: Kpi_Bank_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Kpi_Bank_On_Conflict>;
};

/** on_conflict condition type for table "kpi_bank" */
export type Kpi_Bank_On_Conflict = {
  constraint: Kpi_Bank_Constraint;
  update_columns?: Array<Kpi_Bank_Update_Column>;
  where?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

/** Ordering options when selecting data from "kpi_bank". */
export type Kpi_Bank_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  goalWithKpis_aggregate?: InputMaybe<GoalWithKpi_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  kpiWithRolesAndDepts_aggregate?: InputMaybe<KpiWithDept_Aggregate_Order_By>;
  kpiWithRoles_aggregate?: InputMaybe<KpiWithRole_Aggregate_Order_By>;
  managerId?: InputMaybe<Order_By>;
  open_ended_kpi?: InputMaybe<Order_By>;
  performance_review_byUser_Kpis_aggregate?: InputMaybe<Performance_Review_ByUser_Kpi_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: kpi_bank */
export type Kpi_Bank_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kpi_bank" */
export enum Kpi_Bank_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  OpenEndedKpi = 'open_ended_kpi',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "kpi_bank_aggregate_bool_exp_bool_and_arguments_columns" columns of table "kpi_bank" */
export enum Kpi_Bank_Select_Column_Kpi_Bank_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  OpenEndedKpi = 'open_ended_kpi',
}

/** select "kpi_bank_aggregate_bool_exp_bool_or_arguments_columns" columns of table "kpi_bank" */
export enum Kpi_Bank_Select_Column_Kpi_Bank_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  OpenEndedKpi = 'open_ended_kpi',
}

/** input type for updating data in table "kpi_bank" */
export type Kpi_Bank_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  open_ended_kpi?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "kpi_bank" */
export type Kpi_Bank_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kpi_Bank_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kpi_Bank_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  open_ended_kpi?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "kpi_bank" */
export enum Kpi_Bank_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  OpenEndedKpi = 'open_ended_kpi',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Kpi_Bank_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kpi_Bank_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kpi_Bank_Bool_Exp;
};

/** columns and relationships of "leave" */
export type Leave = {
  __typename?: 'leave';
  additional_data?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  end_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_leave_status_type: Enum_Leave_Status_Type;
  id: Scalars['uuid'];
  leave_days: Scalars['numeric'];
  leave_type: Scalars['String'];
  managed_by: Scalars['uuid'];
  start_at: Scalars['timestamptz'];
  status: Enum_Leave_Status_Type_Enum;
  submitted_by: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userBySubmittedBy: Users;
};

/** columns and relationships of "leave" */
export type LeaveAdditional_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "leave" */
export type Leave_Aggregate = {
  __typename?: 'leave_aggregate';
  aggregate?: Maybe<Leave_Aggregate_Fields>;
  nodes: Array<Leave>;
};

export type Leave_Aggregate_Bool_Exp = {
  count?: InputMaybe<Leave_Aggregate_Bool_Exp_Count>;
};

export type Leave_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Leave_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Leave_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leave" */
export type Leave_Aggregate_Fields = {
  __typename?: 'leave_aggregate_fields';
  avg?: Maybe<Leave_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leave_Max_Fields>;
  min?: Maybe<Leave_Min_Fields>;
  stddev?: Maybe<Leave_Stddev_Fields>;
  stddev_pop?: Maybe<Leave_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leave_Stddev_Samp_Fields>;
  sum?: Maybe<Leave_Sum_Fields>;
  var_pop?: Maybe<Leave_Var_Pop_Fields>;
  var_samp?: Maybe<Leave_Var_Samp_Fields>;
  variance?: Maybe<Leave_Variance_Fields>;
};

/** aggregate fields of "leave" */
export type Leave_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leave_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leave" */
export type Leave_Aggregate_Order_By = {
  avg?: InputMaybe<Leave_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Leave_Max_Order_By>;
  min?: InputMaybe<Leave_Min_Order_By>;
  stddev?: InputMaybe<Leave_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Leave_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Leave_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Leave_Sum_Order_By>;
  var_pop?: InputMaybe<Leave_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Leave_Var_Samp_Order_By>;
  variance?: InputMaybe<Leave_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Leave_Append_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "leave" */
export type Leave_Arr_Rel_Insert_Input = {
  data: Array<Leave_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Leave_On_Conflict>;
};

/** aggregate avg on columns */
export type Leave_Avg_Fields = {
  __typename?: 'leave_avg_fields';
  leave_days?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "leave" */
export type Leave_Avg_Order_By = {
  leave_days?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "leave". All fields are combined with a logical 'AND'. */
export type Leave_Bool_Exp = {
  _and?: InputMaybe<Array<Leave_Bool_Exp>>;
  _not?: InputMaybe<Leave_Bool_Exp>;
  _or?: InputMaybe<Array<Leave_Bool_Exp>>;
  additional_data?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_leave_status_type?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leave_days?: InputMaybe<Numeric_Comparison_Exp>;
  leave_type?: InputMaybe<String_Comparison_Exp>;
  managed_by?: InputMaybe<Uuid_Comparison_Exp>;
  start_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Enum_Leave_Status_Type_Enum_Comparison_Exp>;
  submitted_by?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userBySubmittedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "leave" */
export enum Leave_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeavePkey = 'leave_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Leave_Delete_At_Path_Input = {
  additional_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Leave_Delete_Elem_Input = {
  additional_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Leave_Delete_Key_Input = {
  additional_data?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "leave_donate" */
export type Leave_Donate = {
  __typename?: 'leave_donate';
  created_at: Scalars['timestamptz'];
  donate_type: Enum_Leave_Donate_Type_Enum;
  donated_by: Scalars['uuid'];
  donated_days: Scalars['numeric'];
  donated_to: Scalars['uuid'];
  /** An object relationship */
  enum_leave_donate_type: Enum_Leave_Donate_Type;
  id: Scalars['uuid'];
  leave_type: Scalars['String'];
  remaining_days: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByDonatedTo: Users;
};

/** aggregated selection of "leave_donate" */
export type Leave_Donate_Aggregate = {
  __typename?: 'leave_donate_aggregate';
  aggregate?: Maybe<Leave_Donate_Aggregate_Fields>;
  nodes: Array<Leave_Donate>;
};

export type Leave_Donate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Leave_Donate_Aggregate_Bool_Exp_Count>;
};

export type Leave_Donate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Leave_Donate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leave_donate" */
export type Leave_Donate_Aggregate_Fields = {
  __typename?: 'leave_donate_aggregate_fields';
  avg?: Maybe<Leave_Donate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leave_Donate_Max_Fields>;
  min?: Maybe<Leave_Donate_Min_Fields>;
  stddev?: Maybe<Leave_Donate_Stddev_Fields>;
  stddev_pop?: Maybe<Leave_Donate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leave_Donate_Stddev_Samp_Fields>;
  sum?: Maybe<Leave_Donate_Sum_Fields>;
  var_pop?: Maybe<Leave_Donate_Var_Pop_Fields>;
  var_samp?: Maybe<Leave_Donate_Var_Samp_Fields>;
  variance?: Maybe<Leave_Donate_Variance_Fields>;
};

/** aggregate fields of "leave_donate" */
export type Leave_Donate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leave_donate" */
export type Leave_Donate_Aggregate_Order_By = {
  avg?: InputMaybe<Leave_Donate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Leave_Donate_Max_Order_By>;
  min?: InputMaybe<Leave_Donate_Min_Order_By>;
  stddev?: InputMaybe<Leave_Donate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Leave_Donate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Leave_Donate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Leave_Donate_Sum_Order_By>;
  var_pop?: InputMaybe<Leave_Donate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Leave_Donate_Var_Samp_Order_By>;
  variance?: InputMaybe<Leave_Donate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "leave_donate" */
export type Leave_Donate_Arr_Rel_Insert_Input = {
  data: Array<Leave_Donate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Leave_Donate_On_Conflict>;
};

/** aggregate avg on columns */
export type Leave_Donate_Avg_Fields = {
  __typename?: 'leave_donate_avg_fields';
  donated_days?: Maybe<Scalars['Float']>;
  remaining_days?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "leave_donate" */
export type Leave_Donate_Avg_Order_By = {
  donated_days?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "leave_donate". All fields are combined with a logical 'AND'. */
export type Leave_Donate_Bool_Exp = {
  _and?: InputMaybe<Array<Leave_Donate_Bool_Exp>>;
  _not?: InputMaybe<Leave_Donate_Bool_Exp>;
  _or?: InputMaybe<Array<Leave_Donate_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  donate_type?: InputMaybe<Enum_Leave_Donate_Type_Enum_Comparison_Exp>;
  donated_by?: InputMaybe<Uuid_Comparison_Exp>;
  donated_days?: InputMaybe<Numeric_Comparison_Exp>;
  donated_to?: InputMaybe<Uuid_Comparison_Exp>;
  enum_leave_donate_type?: InputMaybe<Enum_Leave_Donate_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leave_type?: InputMaybe<String_Comparison_Exp>;
  remaining_days?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByDonatedTo?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "leave_donate" */
export enum Leave_Donate_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeaveDonatePkey = 'leave_donate_pkey',
}

/** input type for incrementing numeric columns in table "leave_donate" */
export type Leave_Donate_Inc_Input = {
  donated_days?: InputMaybe<Scalars['numeric']>;
  remaining_days?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "leave_donate" */
export type Leave_Donate_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  donate_type?: InputMaybe<Enum_Leave_Donate_Type_Enum>;
  donated_by?: InputMaybe<Scalars['uuid']>;
  donated_days?: InputMaybe<Scalars['numeric']>;
  donated_to?: InputMaybe<Scalars['uuid']>;
  enum_leave_donate_type?: InputMaybe<Enum_Leave_Donate_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  remaining_days?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByDonatedTo?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Leave_Donate_Max_Fields = {
  __typename?: 'leave_donate_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  donated_by?: Maybe<Scalars['uuid']>;
  donated_days?: Maybe<Scalars['numeric']>;
  donated_to?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  remaining_days?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "leave_donate" */
export type Leave_Donate_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  donated_by?: InputMaybe<Order_By>;
  donated_days?: InputMaybe<Order_By>;
  donated_to?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Leave_Donate_Min_Fields = {
  __typename?: 'leave_donate_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  donated_by?: Maybe<Scalars['uuid']>;
  donated_days?: Maybe<Scalars['numeric']>;
  donated_to?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  remaining_days?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "leave_donate" */
export type Leave_Donate_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  donated_by?: InputMaybe<Order_By>;
  donated_days?: InputMaybe<Order_By>;
  donated_to?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leave_donate" */
export type Leave_Donate_Mutation_Response = {
  __typename?: 'leave_donate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Leave_Donate>;
};

/** on_conflict condition type for table "leave_donate" */
export type Leave_Donate_On_Conflict = {
  constraint: Leave_Donate_Constraint;
  update_columns?: Array<Leave_Donate_Update_Column>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

/** Ordering options when selecting data from "leave_donate". */
export type Leave_Donate_Order_By = {
  created_at?: InputMaybe<Order_By>;
  donate_type?: InputMaybe<Order_By>;
  donated_by?: InputMaybe<Order_By>;
  donated_days?: InputMaybe<Order_By>;
  donated_to?: InputMaybe<Order_By>;
  enum_leave_donate_type?: InputMaybe<Enum_Leave_Donate_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByDonatedTo?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: leave_donate */
export type Leave_Donate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "leave_donate" */
export enum Leave_Donate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DonateType = 'donate_type',
  /** column name */
  DonatedBy = 'donated_by',
  /** column name */
  DonatedDays = 'donated_days',
  /** column name */
  DonatedTo = 'donated_to',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  RemainingDays = 'remaining_days',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "leave_donate" */
export type Leave_Donate_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  donate_type?: InputMaybe<Enum_Leave_Donate_Type_Enum>;
  donated_by?: InputMaybe<Scalars['uuid']>;
  donated_days?: InputMaybe<Scalars['numeric']>;
  donated_to?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  remaining_days?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Leave_Donate_Stddev_Fields = {
  __typename?: 'leave_donate_stddev_fields';
  donated_days?: Maybe<Scalars['Float']>;
  remaining_days?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "leave_donate" */
export type Leave_Donate_Stddev_Order_By = {
  donated_days?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Leave_Donate_Stddev_Pop_Fields = {
  __typename?: 'leave_donate_stddev_pop_fields';
  donated_days?: Maybe<Scalars['Float']>;
  remaining_days?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "leave_donate" */
export type Leave_Donate_Stddev_Pop_Order_By = {
  donated_days?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Leave_Donate_Stddev_Samp_Fields = {
  __typename?: 'leave_donate_stddev_samp_fields';
  donated_days?: Maybe<Scalars['Float']>;
  remaining_days?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "leave_donate" */
export type Leave_Donate_Stddev_Samp_Order_By = {
  donated_days?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "leave_donate" */
export type Leave_Donate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leave_Donate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leave_Donate_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  donate_type?: InputMaybe<Enum_Leave_Donate_Type_Enum>;
  donated_by?: InputMaybe<Scalars['uuid']>;
  donated_days?: InputMaybe<Scalars['numeric']>;
  donated_to?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  remaining_days?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Leave_Donate_Sum_Fields = {
  __typename?: 'leave_donate_sum_fields';
  donated_days?: Maybe<Scalars['numeric']>;
  remaining_days?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "leave_donate" */
export type Leave_Donate_Sum_Order_By = {
  donated_days?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
};

/** update columns of table "leave_donate" */
export enum Leave_Donate_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DonateType = 'donate_type',
  /** column name */
  DonatedBy = 'donated_by',
  /** column name */
  DonatedDays = 'donated_days',
  /** column name */
  DonatedTo = 'donated_to',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  RemainingDays = 'remaining_days',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Leave_Donate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Leave_Donate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Leave_Donate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Leave_Donate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Leave_Donate_Var_Pop_Fields = {
  __typename?: 'leave_donate_var_pop_fields';
  donated_days?: Maybe<Scalars['Float']>;
  remaining_days?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "leave_donate" */
export type Leave_Donate_Var_Pop_Order_By = {
  donated_days?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Leave_Donate_Var_Samp_Fields = {
  __typename?: 'leave_donate_var_samp_fields';
  donated_days?: Maybe<Scalars['Float']>;
  remaining_days?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "leave_donate" */
export type Leave_Donate_Var_Samp_Order_By = {
  donated_days?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Leave_Donate_Variance_Fields = {
  __typename?: 'leave_donate_variance_fields';
  donated_days?: Maybe<Scalars['Float']>;
  remaining_days?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "leave_donate" */
export type Leave_Donate_Variance_Order_By = {
  donated_days?: InputMaybe<Order_By>;
  remaining_days?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "leave" */
export type Leave_Inc_Input = {
  leave_days?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "leave" */
export type Leave_Insert_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_at?: InputMaybe<Scalars['timestamptz']>;
  enum_leave_status_type?: InputMaybe<Enum_Leave_Status_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_days?: InputMaybe<Scalars['numeric']>;
  leave_type?: InputMaybe<Scalars['String']>;
  managed_by?: InputMaybe<Scalars['uuid']>;
  start_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  submitted_by?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userBySubmittedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Leave_Max_Fields = {
  __typename?: 'leave_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leave_days?: Maybe<Scalars['numeric']>;
  leave_type?: Maybe<Scalars['String']>;
  managed_by?: Maybe<Scalars['uuid']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "leave" */
export type Leave_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_days?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  managed_by?: InputMaybe<Order_By>;
  start_at?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Leave_Min_Fields = {
  __typename?: 'leave_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leave_days?: Maybe<Scalars['numeric']>;
  leave_type?: Maybe<Scalars['String']>;
  managed_by?: Maybe<Scalars['uuid']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "leave" */
export type Leave_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_days?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  managed_by?: InputMaybe<Order_By>;
  start_at?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leave" */
export type Leave_Mutation_Response = {
  __typename?: 'leave_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Leave>;
};

/** on_conflict condition type for table "leave" */
export type Leave_On_Conflict = {
  constraint: Leave_Constraint;
  update_columns?: Array<Leave_Update_Column>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

/** Ordering options when selecting data from "leave". */
export type Leave_Order_By = {
  additional_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_at?: InputMaybe<Order_By>;
  enum_leave_status_type?: InputMaybe<Enum_Leave_Status_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  leave_days?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  managed_by?: InputMaybe<Order_By>;
  start_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userBySubmittedBy?: InputMaybe<Users_Order_By>;
};

/** columns and relationships of "leave_organization" */
export type Leave_Organization = {
  __typename?: 'leave_organization';
  allocated_days: Scalars['numeric'];
  annual_forward_days: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  id: Scalars['uuid'];
  leave_type: Scalars['String'];
  monthly_limit: Scalars['numeric'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "leave_organization" */
export type Leave_Organization_Aggregate = {
  __typename?: 'leave_organization_aggregate';
  aggregate?: Maybe<Leave_Organization_Aggregate_Fields>;
  nodes: Array<Leave_Organization>;
};

export type Leave_Organization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Leave_Organization_Aggregate_Bool_Exp_Count>;
};

export type Leave_Organization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Leave_Organization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leave_organization" */
export type Leave_Organization_Aggregate_Fields = {
  __typename?: 'leave_organization_aggregate_fields';
  avg?: Maybe<Leave_Organization_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leave_Organization_Max_Fields>;
  min?: Maybe<Leave_Organization_Min_Fields>;
  stddev?: Maybe<Leave_Organization_Stddev_Fields>;
  stddev_pop?: Maybe<Leave_Organization_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leave_Organization_Stddev_Samp_Fields>;
  sum?: Maybe<Leave_Organization_Sum_Fields>;
  var_pop?: Maybe<Leave_Organization_Var_Pop_Fields>;
  var_samp?: Maybe<Leave_Organization_Var_Samp_Fields>;
  variance?: Maybe<Leave_Organization_Variance_Fields>;
};

/** aggregate fields of "leave_organization" */
export type Leave_Organization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leave_organization" */
export type Leave_Organization_Aggregate_Order_By = {
  avg?: InputMaybe<Leave_Organization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Leave_Organization_Max_Order_By>;
  min?: InputMaybe<Leave_Organization_Min_Order_By>;
  stddev?: InputMaybe<Leave_Organization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Leave_Organization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Leave_Organization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Leave_Organization_Sum_Order_By>;
  var_pop?: InputMaybe<Leave_Organization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Leave_Organization_Var_Samp_Order_By>;
  variance?: InputMaybe<Leave_Organization_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "leave_organization" */
export type Leave_Organization_Arr_Rel_Insert_Input = {
  data: Array<Leave_Organization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Leave_Organization_On_Conflict>;
};

/** aggregate avg on columns */
export type Leave_Organization_Avg_Fields = {
  __typename?: 'leave_organization_avg_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  annual_forward_days?: Maybe<Scalars['Float']>;
  monthly_limit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "leave_organization" */
export type Leave_Organization_Avg_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "leave_organization". All fields are combined with a logical 'AND'. */
export type Leave_Organization_Bool_Exp = {
  _and?: InputMaybe<Array<Leave_Organization_Bool_Exp>>;
  _not?: InputMaybe<Leave_Organization_Bool_Exp>;
  _or?: InputMaybe<Array<Leave_Organization_Bool_Exp>>;
  allocated_days?: InputMaybe<Numeric_Comparison_Exp>;
  annual_forward_days?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leave_type?: InputMaybe<String_Comparison_Exp>;
  monthly_limit?: InputMaybe<Numeric_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "leave_organization" */
export enum Leave_Organization_Constraint {
  /** unique or primary key constraint on columns "leave_type", "organization_id" */
  LeaveOrganizationOrganizationIdLeaveTypeKey = 'leave_organization_organization_id_leave_type_key',
  /** unique or primary key constraint on columns "id" */
  LeaveOrganizationPkey = 'leave_organization_pkey',
}

/** input type for incrementing numeric columns in table "leave_organization" */
export type Leave_Organization_Inc_Input = {
  allocated_days?: InputMaybe<Scalars['numeric']>;
  annual_forward_days?: InputMaybe<Scalars['numeric']>;
  monthly_limit?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "leave_organization" */
export type Leave_Organization_Insert_Input = {
  allocated_days?: InputMaybe<Scalars['numeric']>;
  annual_forward_days?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  monthly_limit?: InputMaybe<Scalars['numeric']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Leave_Organization_Max_Fields = {
  __typename?: 'leave_organization_max_fields';
  allocated_days?: Maybe<Scalars['numeric']>;
  annual_forward_days?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  monthly_limit?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "leave_organization" */
export type Leave_Organization_Max_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Leave_Organization_Min_Fields = {
  __typename?: 'leave_organization_min_fields';
  allocated_days?: Maybe<Scalars['numeric']>;
  annual_forward_days?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  monthly_limit?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "leave_organization" */
export type Leave_Organization_Min_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leave_organization" */
export type Leave_Organization_Mutation_Response = {
  __typename?: 'leave_organization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Leave_Organization>;
};

/** on_conflict condition type for table "leave_organization" */
export type Leave_Organization_On_Conflict = {
  constraint: Leave_Organization_Constraint;
  update_columns?: Array<Leave_Organization_Update_Column>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

/** Ordering options when selecting data from "leave_organization". */
export type Leave_Organization_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: leave_organization */
export type Leave_Organization_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "leave_organization" */
export enum Leave_Organization_Select_Column {
  /** column name */
  AllocatedDays = 'allocated_days',
  /** column name */
  AnnualForwardDays = 'annual_forward_days',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  MonthlyLimit = 'monthly_limit',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "leave_organization" */
export type Leave_Organization_Set_Input = {
  allocated_days?: InputMaybe<Scalars['numeric']>;
  annual_forward_days?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  monthly_limit?: InputMaybe<Scalars['numeric']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Leave_Organization_Stddev_Fields = {
  __typename?: 'leave_organization_stddev_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  annual_forward_days?: Maybe<Scalars['Float']>;
  monthly_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "leave_organization" */
export type Leave_Organization_Stddev_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Leave_Organization_Stddev_Pop_Fields = {
  __typename?: 'leave_organization_stddev_pop_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  annual_forward_days?: Maybe<Scalars['Float']>;
  monthly_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "leave_organization" */
export type Leave_Organization_Stddev_Pop_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Leave_Organization_Stddev_Samp_Fields = {
  __typename?: 'leave_organization_stddev_samp_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  annual_forward_days?: Maybe<Scalars['Float']>;
  monthly_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "leave_organization" */
export type Leave_Organization_Stddev_Samp_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "leave_organization" */
export type Leave_Organization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leave_Organization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leave_Organization_Stream_Cursor_Value_Input = {
  allocated_days?: InputMaybe<Scalars['numeric']>;
  annual_forward_days?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  monthly_limit?: InputMaybe<Scalars['numeric']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Leave_Organization_Sum_Fields = {
  __typename?: 'leave_organization_sum_fields';
  allocated_days?: Maybe<Scalars['numeric']>;
  annual_forward_days?: Maybe<Scalars['numeric']>;
  monthly_limit?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "leave_organization" */
export type Leave_Organization_Sum_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
};

/** update columns of table "leave_organization" */
export enum Leave_Organization_Update_Column {
  /** column name */
  AllocatedDays = 'allocated_days',
  /** column name */
  AnnualForwardDays = 'annual_forward_days',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  MonthlyLimit = 'monthly_limit',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Leave_Organization_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Leave_Organization_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Leave_Organization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Leave_Organization_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Leave_Organization_Var_Pop_Fields = {
  __typename?: 'leave_organization_var_pop_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  annual_forward_days?: Maybe<Scalars['Float']>;
  monthly_limit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "leave_organization" */
export type Leave_Organization_Var_Pop_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Leave_Organization_Var_Samp_Fields = {
  __typename?: 'leave_organization_var_samp_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  annual_forward_days?: Maybe<Scalars['Float']>;
  monthly_limit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "leave_organization" */
export type Leave_Organization_Var_Samp_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Leave_Organization_Variance_Fields = {
  __typename?: 'leave_organization_variance_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  annual_forward_days?: Maybe<Scalars['Float']>;
  monthly_limit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "leave_organization" */
export type Leave_Organization_Variance_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  annual_forward_days?: InputMaybe<Order_By>;
  monthly_limit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leave */
export type Leave_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Leave_Prepend_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "leave" */
export enum Leave_Select_Column {
  /** column name */
  AdditionalData = 'additional_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveDays = 'leave_days',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  ManagedBy = 'managed_by',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Status = 'status',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "leave" */
export type Leave_Set_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_days?: InputMaybe<Scalars['numeric']>;
  leave_type?: InputMaybe<Scalars['String']>;
  managed_by?: InputMaybe<Scalars['uuid']>;
  start_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  submitted_by?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Leave_Stddev_Fields = {
  __typename?: 'leave_stddev_fields';
  leave_days?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "leave" */
export type Leave_Stddev_Order_By = {
  leave_days?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Leave_Stddev_Pop_Fields = {
  __typename?: 'leave_stddev_pop_fields';
  leave_days?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "leave" */
export type Leave_Stddev_Pop_Order_By = {
  leave_days?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Leave_Stddev_Samp_Fields = {
  __typename?: 'leave_stddev_samp_fields';
  leave_days?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "leave" */
export type Leave_Stddev_Samp_Order_By = {
  leave_days?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "leave" */
export type Leave_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leave_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leave_Stream_Cursor_Value_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_days?: InputMaybe<Scalars['numeric']>;
  leave_type?: InputMaybe<Scalars['String']>;
  managed_by?: InputMaybe<Scalars['uuid']>;
  start_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  submitted_by?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Leave_Sum_Fields = {
  __typename?: 'leave_sum_fields';
  leave_days?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "leave" */
export type Leave_Sum_Order_By = {
  leave_days?: InputMaybe<Order_By>;
};

/** update columns of table "leave" */
export enum Leave_Update_Column {
  /** column name */
  AdditionalData = 'additional_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveDays = 'leave_days',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  ManagedBy = 'managed_by',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Status = 'status',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Leave_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Leave_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Leave_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Leave_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Leave_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Leave_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Leave_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Leave_Set_Input>;
  /** filter the rows which have to be updated */
  where: Leave_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Leave_Var_Pop_Fields = {
  __typename?: 'leave_var_pop_fields';
  leave_days?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "leave" */
export type Leave_Var_Pop_Order_By = {
  leave_days?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Leave_Var_Samp_Fields = {
  __typename?: 'leave_var_samp_fields';
  leave_days?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "leave" */
export type Leave_Var_Samp_Order_By = {
  leave_days?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Leave_Variance_Fields = {
  __typename?: 'leave_variance_fields';
  leave_days?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "leave" */
export type Leave_Variance_Order_By = {
  leave_days?: InputMaybe<Order_By>;
};

export type LikesInput = {
  comment_id?: InputMaybe<Scalars['String']>;
  is_comment_like?: InputMaybe<Scalars['Boolean']>;
  is_post_like?: InputMaybe<Scalars['Boolean']>;
  is_reply_like?: InputMaybe<Scalars['Boolean']>;
  post_id?: InputMaybe<Scalars['String']>;
  reply_id?: InputMaybe<Scalars['String']>;
};

export type LikesOutput = {
  __typename?: 'likesOutput';
  like_id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  post_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_Skills = {
  __typename?: 'list_Of_Skills';
  /** An array relationship */
  assessment_Skills: Array<Assessment_Skills>;
  /** An aggregate relationship */
  assessment_Skills_aggregate: Assessment_Skills_Aggregate;
  created_At?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  org_Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** An array relationship */
  skills_details: Array<Skills_Detail>;
  /** An aggregate relationship */
  skills_details_aggregate: Skills_Detail_Aggregate;
  /** An array relationship */
  sub_skills: Array<Sub_Skills>;
  /** An aggregate relationship */
  sub_skills_aggregate: Sub_Skills_Aggregate;
  /** An array relationship */
  training_Skills: Array<Training_Skill>;
  /** An aggregate relationship */
  training_Skills_aggregate: Training_Skill_Aggregate;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_SkillsAssessment_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Skills_Order_By>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_SkillsAssessment_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Skills_Order_By>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_SkillsSkills_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Skills_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skills_Detail_Order_By>>;
  where?: InputMaybe<Skills_Detail_Bool_Exp>;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_SkillsSkills_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skills_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skills_Detail_Order_By>>;
  where?: InputMaybe<Skills_Detail_Bool_Exp>;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_SkillsSub_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sub_Skills_Order_By>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_SkillsSub_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sub_Skills_Order_By>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_SkillsTraining_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Training_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Skill_Order_By>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

/** columns and relationships of "list_Of_Skills" */
export type List_Of_SkillsTraining_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Skill_Order_By>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

/** aggregated selection of "list_Of_Skills" */
export type List_Of_Skills_Aggregate = {
  __typename?: 'list_Of_Skills_aggregate';
  aggregate?: Maybe<List_Of_Skills_Aggregate_Fields>;
  nodes: Array<List_Of_Skills>;
};

export type List_Of_Skills_Aggregate_Bool_Exp = {
  count?: InputMaybe<List_Of_Skills_Aggregate_Bool_Exp_Count>;
};

export type List_Of_Skills_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<List_Of_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<List_Of_Skills_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "list_Of_Skills" */
export type List_Of_Skills_Aggregate_Fields = {
  __typename?: 'list_Of_Skills_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<List_Of_Skills_Max_Fields>;
  min?: Maybe<List_Of_Skills_Min_Fields>;
};

/** aggregate fields of "list_Of_Skills" */
export type List_Of_Skills_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<List_Of_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "list_Of_Skills" */
export type List_Of_Skills_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<List_Of_Skills_Max_Order_By>;
  min?: InputMaybe<List_Of_Skills_Min_Order_By>;
};

/** input type for inserting array relation for remote table "list_Of_Skills" */
export type List_Of_Skills_Arr_Rel_Insert_Input = {
  data: Array<List_Of_Skills_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<List_Of_Skills_On_Conflict>;
};

/** Boolean expression to filter rows from the table "list_Of_Skills". All fields are combined with a logical 'AND'. */
export type List_Of_Skills_Bool_Exp = {
  _and?: InputMaybe<Array<List_Of_Skills_Bool_Exp>>;
  _not?: InputMaybe<List_Of_Skills_Bool_Exp>;
  _or?: InputMaybe<Array<List_Of_Skills_Bool_Exp>>;
  assessment_Skills?: InputMaybe<Assessment_Skills_Bool_Exp>;
  assessment_Skills_aggregate?: InputMaybe<Assessment_Skills_Aggregate_Bool_Exp>;
  created_At?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_Id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  skills_details?: InputMaybe<Skills_Detail_Bool_Exp>;
  skills_details_aggregate?: InputMaybe<Skills_Detail_Aggregate_Bool_Exp>;
  sub_skills?: InputMaybe<Sub_Skills_Bool_Exp>;
  sub_skills_aggregate?: InputMaybe<Sub_Skills_Aggregate_Bool_Exp>;
  training_Skills?: InputMaybe<Training_Skill_Bool_Exp>;
  training_Skills_aggregate?: InputMaybe<Training_Skill_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "list_Of_Skills" */
export enum List_Of_Skills_Constraint {
  /** unique or primary key constraint on columns "id" */
  ListOfSkillsPkey = 'list_Of_Skills_pkey',
}

/** input type for inserting data into table "list_Of_Skills" */
export type List_Of_Skills_Insert_Input = {
  assessment_Skills?: InputMaybe<Assessment_Skills_Arr_Rel_Insert_Input>;
  created_At?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  org_Id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  skills_details?: InputMaybe<Skills_Detail_Arr_Rel_Insert_Input>;
  sub_skills?: InputMaybe<Sub_Skills_Arr_Rel_Insert_Input>;
  training_Skills?: InputMaybe<Training_Skill_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type List_Of_Skills_Max_Fields = {
  __typename?: 'list_Of_Skills_max_fields';
  created_At?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  org_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "list_Of_Skills" */
export type List_Of_Skills_Max_Order_By = {
  created_At?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type List_Of_Skills_Min_Fields = {
  __typename?: 'list_Of_Skills_min_fields';
  created_At?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  org_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "list_Of_Skills" */
export type List_Of_Skills_Min_Order_By = {
  created_At?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "list_Of_Skills" */
export type List_Of_Skills_Mutation_Response = {
  __typename?: 'list_Of_Skills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<List_Of_Skills>;
};

/** input type for inserting object relation for remote table "list_Of_Skills" */
export type List_Of_Skills_Obj_Rel_Insert_Input = {
  data: List_Of_Skills_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<List_Of_Skills_On_Conflict>;
};

/** on_conflict condition type for table "list_Of_Skills" */
export type List_Of_Skills_On_Conflict = {
  constraint: List_Of_Skills_Constraint;
  update_columns?: Array<List_Of_Skills_Update_Column>;
  where?: InputMaybe<List_Of_Skills_Bool_Exp>;
};

/** Ordering options when selecting data from "list_Of_Skills". */
export type List_Of_Skills_Order_By = {
  assessment_Skills_aggregate?: InputMaybe<Assessment_Skills_Aggregate_Order_By>;
  created_At?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_Id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  skills_details_aggregate?: InputMaybe<Skills_Detail_Aggregate_Order_By>;
  sub_skills_aggregate?: InputMaybe<Sub_Skills_Aggregate_Order_By>;
  training_Skills_aggregate?: InputMaybe<Training_Skill_Aggregate_Order_By>;
};

/** primary key columns input for table: list_Of_Skills */
export type List_Of_Skills_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "list_Of_Skills" */
export enum List_Of_Skills_Select_Column {
  /** column name */
  CreatedAt = 'created_At',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_Id',
}

/** input type for updating data in table "list_Of_Skills" */
export type List_Of_Skills_Set_Input = {
  created_At?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  org_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "list_Of_Skills" */
export type List_Of_Skills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: List_Of_Skills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type List_Of_Skills_Stream_Cursor_Value_Input = {
  created_At?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  org_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "list_Of_Skills" */
export enum List_Of_Skills_Update_Column {
  /** column name */
  CreatedAt = 'created_At',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_Id',
}

export type List_Of_Skills_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<List_Of_Skills_Set_Input>;
  /** filter the rows which have to be updated */
  where: List_Of_Skills_Bool_Exp;
};

/** Live office watch request are represented */
export type Live_Call = {
  __typename?: 'live_call';
  channel_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  end_date_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_live_call_status: Enum_Live_Call_Status;
  id: Scalars['uuid'];
  /** An array relationship */
  live_call_requests: Array<Live_Call_Request>;
  /** An aggregate relationship */
  live_call_requests_aggregate: Live_Call_Request_Aggregate;
  live_call_status: Enum_Live_Call_Status_Enum;
  /** An array relationship */
  live_call_users: Array<Live_Call_User>;
  /** An aggregate relationship */
  live_call_users_aggregate: Live_Call_User_Aggregate;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** Live office watch request are represented */
export type Live_CallLive_Call_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** Live office watch request are represented */
export type Live_CallLive_Call_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** Live office watch request are represented */
export type Live_CallLive_Call_UsersArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** Live office watch request are represented */
export type Live_CallLive_Call_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** aggregated selection of "live_call" */
export type Live_Call_Aggregate = {
  __typename?: 'live_call_aggregate';
  aggregate?: Maybe<Live_Call_Aggregate_Fields>;
  nodes: Array<Live_Call>;
};

export type Live_Call_Aggregate_Bool_Exp = {
  count?: InputMaybe<Live_Call_Aggregate_Bool_Exp_Count>;
};

export type Live_Call_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Live_Call_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Live_Call_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "live_call" */
export type Live_Call_Aggregate_Fields = {
  __typename?: 'live_call_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Live_Call_Max_Fields>;
  min?: Maybe<Live_Call_Min_Fields>;
};

/** aggregate fields of "live_call" */
export type Live_Call_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Live_Call_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "live_call" */
export type Live_Call_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Live_Call_Max_Order_By>;
  min?: InputMaybe<Live_Call_Min_Order_By>;
};

/** input type for inserting array relation for remote table "live_call" */
export type Live_Call_Arr_Rel_Insert_Input = {
  data: Array<Live_Call_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Live_Call_On_Conflict>;
};

/** Boolean expression to filter rows from the table "live_call". All fields are combined with a logical 'AND'. */
export type Live_Call_Bool_Exp = {
  _and?: InputMaybe<Array<Live_Call_Bool_Exp>>;
  _not?: InputMaybe<Live_Call_Bool_Exp>;
  _or?: InputMaybe<Array<Live_Call_Bool_Exp>>;
  channel_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  end_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_live_call_status?: InputMaybe<Enum_Live_Call_Status_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  live_call_requests?: InputMaybe<Live_Call_Request_Bool_Exp>;
  live_call_requests_aggregate?: InputMaybe<Live_Call_Request_Aggregate_Bool_Exp>;
  live_call_status?: InputMaybe<Enum_Live_Call_Status_Enum_Comparison_Exp>;
  live_call_users?: InputMaybe<Live_Call_User_Bool_Exp>;
  live_call_users_aggregate?: InputMaybe<Live_Call_User_Aggregate_Bool_Exp>;
  start_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "live_call" */
export enum Live_Call_Constraint {
  /** unique or primary key constraint on columns "id" */
  LiveCallPkey = 'live_call_pkey',
}

/** input type for inserting data into table "live_call" */
export type Live_Call_Insert_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  enum_live_call_status?: InputMaybe<Enum_Live_Call_Status_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call_requests?: InputMaybe<Live_Call_Request_Arr_Rel_Insert_Input>;
  live_call_status?: InputMaybe<Enum_Live_Call_Status_Enum>;
  live_call_users?: InputMaybe<Live_Call_User_Arr_Rel_Insert_Input>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Live_Call_Max_Fields = {
  __typename?: 'live_call_max_fields';
  channel_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "live_call" */
export type Live_Call_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Live_Call_Min_Fields = {
  __typename?: 'live_call_min_fields';
  channel_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "live_call" */
export type Live_Call_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "live_call" */
export type Live_Call_Mutation_Response = {
  __typename?: 'live_call_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Live_Call>;
};

/** input type for inserting object relation for remote table "live_call" */
export type Live_Call_Obj_Rel_Insert_Input = {
  data: Live_Call_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Live_Call_On_Conflict>;
};

/** on_conflict condition type for table "live_call" */
export type Live_Call_On_Conflict = {
  constraint: Live_Call_Constraint;
  update_columns?: Array<Live_Call_Update_Column>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

/** Ordering options when selecting data from "live_call". */
export type Live_Call_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  enum_live_call_status?: InputMaybe<Enum_Live_Call_Status_Order_By>;
  id?: InputMaybe<Order_By>;
  live_call_requests_aggregate?: InputMaybe<Live_Call_Request_Aggregate_Order_By>;
  live_call_status?: InputMaybe<Order_By>;
  live_call_users_aggregate?: InputMaybe<Live_Call_User_Aggregate_Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: live_call */
export type Live_Call_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "live_call_request" */
export type Live_Call_Request = {
  __typename?: 'live_call_request';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_user: Users;
  /** An object relationship */
  enum_live_call_request_status: Enum_Live_Call_Request_Status;
  id: Scalars['uuid'];
  /** An object relationship */
  live_call: Live_Call;
  live_call_id: Scalars['uuid'];
  request_status: Enum_Live_Call_Request_Status_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "live_call_request" */
export type Live_Call_Request_Aggregate = {
  __typename?: 'live_call_request_aggregate';
  aggregate?: Maybe<Live_Call_Request_Aggregate_Fields>;
  nodes: Array<Live_Call_Request>;
};

export type Live_Call_Request_Aggregate_Bool_Exp = {
  count?: InputMaybe<Live_Call_Request_Aggregate_Bool_Exp_Count>;
};

export type Live_Call_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Live_Call_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "live_call_request" */
export type Live_Call_Request_Aggregate_Fields = {
  __typename?: 'live_call_request_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Live_Call_Request_Max_Fields>;
  min?: Maybe<Live_Call_Request_Min_Fields>;
};

/** aggregate fields of "live_call_request" */
export type Live_Call_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "live_call_request" */
export type Live_Call_Request_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Live_Call_Request_Max_Order_By>;
  min?: InputMaybe<Live_Call_Request_Min_Order_By>;
};

/** input type for inserting array relation for remote table "live_call_request" */
export type Live_Call_Request_Arr_Rel_Insert_Input = {
  data: Array<Live_Call_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Live_Call_Request_On_Conflict>;
};

/** Boolean expression to filter rows from the table "live_call_request". All fields are combined with a logical 'AND'. */
export type Live_Call_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Live_Call_Request_Bool_Exp>>;
  _not?: InputMaybe<Live_Call_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Live_Call_Request_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_user?: InputMaybe<Users_Bool_Exp>;
  enum_live_call_request_status?: InputMaybe<Enum_Live_Call_Request_Status_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  live_call?: InputMaybe<Live_Call_Bool_Exp>;
  live_call_id?: InputMaybe<Uuid_Comparison_Exp>;
  request_status?: InputMaybe<Enum_Live_Call_Request_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "live_call_request" */
export enum Live_Call_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  LiveCallRequestPkey = 'live_call_request_pkey',
}

/** input type for inserting data into table "live_call_request" */
export type Live_Call_Request_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  enum_live_call_request_status?: InputMaybe<Enum_Live_Call_Request_Status_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call?: InputMaybe<Live_Call_Obj_Rel_Insert_Input>;
  live_call_id?: InputMaybe<Scalars['uuid']>;
  request_status?: InputMaybe<Enum_Live_Call_Request_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Live_Call_Request_Max_Fields = {
  __typename?: 'live_call_request_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  live_call_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "live_call_request" */
export type Live_Call_Request_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_call_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Live_Call_Request_Min_Fields = {
  __typename?: 'live_call_request_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  live_call_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "live_call_request" */
export type Live_Call_Request_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_call_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "live_call_request" */
export type Live_Call_Request_Mutation_Response = {
  __typename?: 'live_call_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Live_Call_Request>;
};

/** on_conflict condition type for table "live_call_request" */
export type Live_Call_Request_On_Conflict = {
  constraint: Live_Call_Request_Constraint;
  update_columns?: Array<Live_Call_Request_Update_Column>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "live_call_request". */
export type Live_Call_Request_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_user?: InputMaybe<Users_Order_By>;
  enum_live_call_request_status?: InputMaybe<Enum_Live_Call_Request_Status_Order_By>;
  id?: InputMaybe<Order_By>;
  live_call?: InputMaybe<Live_Call_Order_By>;
  live_call_id?: InputMaybe<Order_By>;
  request_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: live_call_request */
export type Live_Call_Request_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "live_call_request" */
export enum Live_Call_Request_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LiveCallId = 'live_call_id',
  /** column name */
  RequestStatus = 'request_status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "live_call_request" */
export type Live_Call_Request_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call_id?: InputMaybe<Scalars['uuid']>;
  request_status?: InputMaybe<Enum_Live_Call_Request_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "live_call_request" */
export type Live_Call_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Live_Call_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Live_Call_Request_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call_id?: InputMaybe<Scalars['uuid']>;
  request_status?: InputMaybe<Enum_Live_Call_Request_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "live_call_request" */
export enum Live_Call_Request_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LiveCallId = 'live_call_id',
  /** column name */
  RequestStatus = 'request_status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Live_Call_Request_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Live_Call_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Live_Call_Request_Bool_Exp;
};

/** select columns of table "live_call" */
export enum Live_Call_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  Id = 'id',
  /** column name */
  LiveCallStatus = 'live_call_status',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "live_call" */
export type Live_Call_Set_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call_status?: InputMaybe<Enum_Live_Call_Status_Enum>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "live_call" */
export type Live_Call_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Live_Call_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Live_Call_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call_status?: InputMaybe<Enum_Live_Call_Status_Enum>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "live_call" */
export enum Live_Call_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  Id = 'id',
  /** column name */
  LiveCallStatus = 'live_call_status',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Live_Call_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Live_Call_Set_Input>;
  /** filter the rows which have to be updated */
  where: Live_Call_Bool_Exp;
};

/** Live office watch participants are represented */
export type Live_Call_User = {
  __typename?: 'live_call_user';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_user: Users;
  /** An object relationship */
  enum_live_call_user_status: Enum_Live_Call_User_Status;
  id: Scalars['uuid'];
  /** An object relationship */
  live_call: Live_Call;
  live_call_id: Scalars['uuid'];
  status: Enum_Live_Call_User_Status_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "live_call_user" */
export type Live_Call_User_Aggregate = {
  __typename?: 'live_call_user_aggregate';
  aggregate?: Maybe<Live_Call_User_Aggregate_Fields>;
  nodes: Array<Live_Call_User>;
};

export type Live_Call_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Live_Call_User_Aggregate_Bool_Exp_Count>;
};

export type Live_Call_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Live_Call_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "live_call_user" */
export type Live_Call_User_Aggregate_Fields = {
  __typename?: 'live_call_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Live_Call_User_Max_Fields>;
  min?: Maybe<Live_Call_User_Min_Fields>;
};

/** aggregate fields of "live_call_user" */
export type Live_Call_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "live_call_user" */
export type Live_Call_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Live_Call_User_Max_Order_By>;
  min?: InputMaybe<Live_Call_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "live_call_user" */
export type Live_Call_User_Arr_Rel_Insert_Input = {
  data: Array<Live_Call_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Live_Call_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "live_call_user". All fields are combined with a logical 'AND'. */
export type Live_Call_User_Bool_Exp = {
  _and?: InputMaybe<Array<Live_Call_User_Bool_Exp>>;
  _not?: InputMaybe<Live_Call_User_Bool_Exp>;
  _or?: InputMaybe<Array<Live_Call_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_user?: InputMaybe<Users_Bool_Exp>;
  enum_live_call_user_status?: InputMaybe<Enum_Live_Call_User_Status_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  live_call?: InputMaybe<Live_Call_Bool_Exp>;
  live_call_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Enum_Live_Call_User_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "live_call_user" */
export enum Live_Call_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  LiveCallUserPkey = 'live_call_user_pkey',
}

/** input type for inserting data into table "live_call_user" */
export type Live_Call_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  enum_live_call_user_status?: InputMaybe<Enum_Live_Call_User_Status_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call?: InputMaybe<Live_Call_Obj_Rel_Insert_Input>;
  live_call_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Live_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Live_Call_User_Max_Fields = {
  __typename?: 'live_call_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  live_call_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "live_call_user" */
export type Live_Call_User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_call_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Live_Call_User_Min_Fields = {
  __typename?: 'live_call_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  live_call_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "live_call_user" */
export type Live_Call_User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_call_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "live_call_user" */
export type Live_Call_User_Mutation_Response = {
  __typename?: 'live_call_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Live_Call_User>;
};

/** on_conflict condition type for table "live_call_user" */
export type Live_Call_User_On_Conflict = {
  constraint: Live_Call_User_Constraint;
  update_columns?: Array<Live_Call_User_Update_Column>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** Ordering options when selecting data from "live_call_user". */
export type Live_Call_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_user?: InputMaybe<Users_Order_By>;
  enum_live_call_user_status?: InputMaybe<Enum_Live_Call_User_Status_Order_By>;
  id?: InputMaybe<Order_By>;
  live_call?: InputMaybe<Live_Call_Order_By>;
  live_call_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: live_call_user */
export type Live_Call_User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "live_call_user" */
export enum Live_Call_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LiveCallId = 'live_call_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "live_call_user" */
export type Live_Call_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Live_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "live_call_user" */
export type Live_Call_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Live_Call_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Live_Call_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Live_Call_User_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "live_call_user" */
export enum Live_Call_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LiveCallId = 'live_call_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Live_Call_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Live_Call_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Live_Call_User_Bool_Exp;
};

export type LoadEmployeeCapactiyGraphInput = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LoadEmployeeCapactiyGraphOutput = {
  __typename?: 'loadEmployeeCapactiyGraphOutput';
  response?: Maybe<Array<Maybe<EmployeeCapacityData>>>;
};

export type LoadMandatoryAssessmentInput = {
  department_id: Scalars['uuid'];
  role_id: Scalars['uuid'];
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id: Scalars['uuid'];
};

export type LoadMandatoryAssessmentOutput = {
  __typename?: 'loadMandatoryAssessmentOutput';
  response?: Maybe<Array<Maybe<ResponseData>>>;
};

export type LoadStressDataForGraphInput = {
  date?: InputMaybe<Array<InputMaybe<DateData>>>;
  orgId: Scalars['uuid'];
};

export type LoadStressDataForGraphOutput = {
  __typename?: 'loadStressDataForGraphOutput';
  response?: Maybe<Array<Maybe<StressData>>>;
};

/** columns and relationships of "main_skills" */
export type Main_Skills = {
  __typename?: 'main_skills';
  created_At: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  org_Id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  /** An array relationship */
  sub_skills: Array<Sub_Skills>;
  /** An aggregate relationship */
  sub_skills_aggregate: Sub_Skills_Aggregate;
  /** An array relationship */
  user_Competencies: Array<User_Competency>;
  /** An aggregate relationship */
  user_Competencies_aggregate: User_Competency_Aggregate;
};

/** columns and relationships of "main_skills" */
export type Main_SkillsSub_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sub_Skills_Order_By>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

/** columns and relationships of "main_skills" */
export type Main_SkillsSub_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sub_Skills_Order_By>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

/** columns and relationships of "main_skills" */
export type Main_SkillsUser_CompetenciesArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

/** columns and relationships of "main_skills" */
export type Main_SkillsUser_Competencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

/** aggregated selection of "main_skills" */
export type Main_Skills_Aggregate = {
  __typename?: 'main_skills_aggregate';
  aggregate?: Maybe<Main_Skills_Aggregate_Fields>;
  nodes: Array<Main_Skills>;
};

export type Main_Skills_Aggregate_Bool_Exp = {
  count?: InputMaybe<Main_Skills_Aggregate_Bool_Exp_Count>;
};

export type Main_Skills_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Main_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Main_Skills_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main_skills" */
export type Main_Skills_Aggregate_Fields = {
  __typename?: 'main_skills_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Main_Skills_Max_Fields>;
  min?: Maybe<Main_Skills_Min_Fields>;
};

/** aggregate fields of "main_skills" */
export type Main_Skills_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Main_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main_skills" */
export type Main_Skills_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Main_Skills_Max_Order_By>;
  min?: InputMaybe<Main_Skills_Min_Order_By>;
};

/** input type for inserting array relation for remote table "main_skills" */
export type Main_Skills_Arr_Rel_Insert_Input = {
  data: Array<Main_Skills_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Main_Skills_On_Conflict>;
};

/** Boolean expression to filter rows from the table "main_skills". All fields are combined with a logical 'AND'. */
export type Main_Skills_Bool_Exp = {
  _and?: InputMaybe<Array<Main_Skills_Bool_Exp>>;
  _not?: InputMaybe<Main_Skills_Bool_Exp>;
  _or?: InputMaybe<Array<Main_Skills_Bool_Exp>>;
  created_At?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_Id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  sub_skills?: InputMaybe<Sub_Skills_Bool_Exp>;
  sub_skills_aggregate?: InputMaybe<Sub_Skills_Aggregate_Bool_Exp>;
  user_Competencies?: InputMaybe<User_Competency_Bool_Exp>;
  user_Competencies_aggregate?: InputMaybe<User_Competency_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "main_skills" */
export enum Main_Skills_Constraint {
  /** unique or primary key constraint on columns "id" */
  MainSkillsPkey = 'main_skills_pkey',
}

/** input type for inserting data into table "main_skills" */
export type Main_Skills_Insert_Input = {
  created_At?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  org_Id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  sub_skills?: InputMaybe<Sub_Skills_Arr_Rel_Insert_Input>;
  user_Competencies?: InputMaybe<User_Competency_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Main_Skills_Max_Fields = {
  __typename?: 'main_skills_max_fields';
  created_At?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  org_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "main_skills" */
export type Main_Skills_Max_Order_By = {
  created_At?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Main_Skills_Min_Fields = {
  __typename?: 'main_skills_min_fields';
  created_At?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  org_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "main_skills" */
export type Main_Skills_Min_Order_By = {
  created_At?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main_skills" */
export type Main_Skills_Mutation_Response = {
  __typename?: 'main_skills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Main_Skills>;
};

/** input type for inserting object relation for remote table "main_skills" */
export type Main_Skills_Obj_Rel_Insert_Input = {
  data: Main_Skills_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Main_Skills_On_Conflict>;
};

/** on_conflict condition type for table "main_skills" */
export type Main_Skills_On_Conflict = {
  constraint: Main_Skills_Constraint;
  update_columns?: Array<Main_Skills_Update_Column>;
  where?: InputMaybe<Main_Skills_Bool_Exp>;
};

/** Ordering options when selecting data from "main_skills". */
export type Main_Skills_Order_By = {
  created_At?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_Id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  sub_skills_aggregate?: InputMaybe<Sub_Skills_Aggregate_Order_By>;
  user_Competencies_aggregate?: InputMaybe<User_Competency_Aggregate_Order_By>;
};

/** primary key columns input for table: main_skills */
export type Main_Skills_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "main_skills" */
export enum Main_Skills_Select_Column {
  /** column name */
  CreatedAt = 'created_At',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_Id',
}

/** input type for updating data in table "main_skills" */
export type Main_Skills_Set_Input = {
  created_At?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  org_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "main_skills" */
export type Main_Skills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Main_Skills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Main_Skills_Stream_Cursor_Value_Input = {
  created_At?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  org_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "main_skills" */
export enum Main_Skills_Update_Column {
  /** column name */
  CreatedAt = 'created_At',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_Id',
}

export type Main_Skills_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Main_Skills_Set_Input>;
  /** filter the rows which have to be updated */
  where: Main_Skills_Bool_Exp;
};

/** this table represents the list of all the scheduled events in set for a meeting event */
export type Meeting_Call_Schedular = {
  __typename?: 'meeting_call_schedular';
  /** An object relationship */
  calendarEvent?: Maybe<Calendar_Event>;
  calendar_event_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  for_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  /** An object relationship */
  meetingEvent?: Maybe<Calendar_Meeting_Call>;
  meeting_event_id: Scalars['uuid'];
  schedular_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "meeting_call_schedular" */
export type Meeting_Call_Schedular_Aggregate = {
  __typename?: 'meeting_call_schedular_aggregate';
  aggregate?: Maybe<Meeting_Call_Schedular_Aggregate_Fields>;
  nodes: Array<Meeting_Call_Schedular>;
};

export type Meeting_Call_Schedular_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Call_Schedular_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Call_Schedular_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Call_Schedular_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_call_schedular" */
export type Meeting_Call_Schedular_Aggregate_Fields = {
  __typename?: 'meeting_call_schedular_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Call_Schedular_Max_Fields>;
  min?: Maybe<Meeting_Call_Schedular_Min_Fields>;
};

/** aggregate fields of "meeting_call_schedular" */
export type Meeting_Call_Schedular_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Call_Schedular_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_call_schedular" */
export type Meeting_Call_Schedular_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Call_Schedular_Max_Order_By>;
  min?: InputMaybe<Meeting_Call_Schedular_Min_Order_By>;
};

/** input type for inserting array relation for remote table "meeting_call_schedular" */
export type Meeting_Call_Schedular_Arr_Rel_Insert_Input = {
  data: Array<Meeting_Call_Schedular_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Call_Schedular_On_Conflict>;
};

/** Boolean expression to filter rows from the table "meeting_call_schedular". All fields are combined with a logical 'AND'. */
export type Meeting_Call_Schedular_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Call_Schedular_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Call_Schedular_Bool_Exp>>;
  calendarEvent?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  for_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meetingEvent?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  meeting_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  schedular_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "meeting_call_schedular" */
export enum Meeting_Call_Schedular_Constraint {
  /** unique or primary key constraint on columns "id" */
  MeetingCallSchedularPkey = 'meeting_call_schedular_pkey',
}

/** input type for inserting data into table "meeting_call_schedular" */
export type Meeting_Call_Schedular_Insert_Input = {
  calendarEvent?: InputMaybe<Calendar_Event_Obj_Rel_Insert_Input>;
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  for_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  meetingEvent?: InputMaybe<Calendar_Meeting_Call_Obj_Rel_Insert_Input>;
  meeting_event_id?: InputMaybe<Scalars['uuid']>;
  schedular_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Meeting_Call_Schedular_Max_Fields = {
  __typename?: 'meeting_call_schedular_max_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  for_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_event_id?: Maybe<Scalars['uuid']>;
  schedular_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "meeting_call_schedular" */
export type Meeting_Call_Schedular_Max_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  for_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_event_id?: InputMaybe<Order_By>;
  schedular_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Call_Schedular_Min_Fields = {
  __typename?: 'meeting_call_schedular_min_fields';
  calendar_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  for_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_event_id?: Maybe<Scalars['uuid']>;
  schedular_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "meeting_call_schedular" */
export type Meeting_Call_Schedular_Min_Order_By = {
  calendar_event_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  for_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_event_id?: InputMaybe<Order_By>;
  schedular_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "meeting_call_schedular" */
export type Meeting_Call_Schedular_Mutation_Response = {
  __typename?: 'meeting_call_schedular_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Meeting_Call_Schedular>;
};

/** on_conflict condition type for table "meeting_call_schedular" */
export type Meeting_Call_Schedular_On_Conflict = {
  constraint: Meeting_Call_Schedular_Constraint;
  update_columns?: Array<Meeting_Call_Schedular_Update_Column>;
  where?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
};

/** Ordering options when selecting data from "meeting_call_schedular". */
export type Meeting_Call_Schedular_Order_By = {
  calendarEvent?: InputMaybe<Calendar_Event_Order_By>;
  calendar_event_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  for_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingEvent?: InputMaybe<Calendar_Meeting_Call_Order_By>;
  meeting_event_id?: InputMaybe<Order_By>;
  schedular_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: meeting_call_schedular */
export type Meeting_Call_Schedular_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "meeting_call_schedular" */
export enum Meeting_Call_Schedular_Select_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForDate = 'for_date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingEventId = 'meeting_event_id',
  /** column name */
  SchedularId = 'schedular_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "meeting_call_schedular" */
export type Meeting_Call_Schedular_Set_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  for_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_event_id?: InputMaybe<Scalars['uuid']>;
  schedular_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "meeting_call_schedular" */
export type Meeting_Call_Schedular_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Call_Schedular_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Call_Schedular_Stream_Cursor_Value_Input = {
  calendar_event_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  for_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_event_id?: InputMaybe<Scalars['uuid']>;
  schedular_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "meeting_call_schedular" */
export enum Meeting_Call_Schedular_Update_Column {
  /** column name */
  CalendarEventId = 'calendar_event_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForDate = 'for_date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingEventId = 'meeting_event_id',
  /** column name */
  SchedularId = 'schedular_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Meeting_Call_Schedular_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Meeting_Call_Schedular_Set_Input>;
  /** filter the rows which have to be updated */
  where: Meeting_Call_Schedular_Bool_Exp;
};

/** Meeting rooms under an organization and type */
export type Meeting_Room = {
  __typename?: 'meeting_room';
  channel_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  enum_meeting_room_status: Enum_Meeting_Room_Status;
  /** An object relationship */
  enum_meeting_room_type: Enum_Meeting_Room_Type;
  id: Scalars['uuid'];
  /** An array relationship */
  meeting_room_users: Array<Meeting_Room_Users>;
  /** An aggregate relationship */
  meeting_room_users_aggregate: Meeting_Room_Users_Aggregate;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  status: Enum_Meeting_Room_Status_Enum;
  type: Enum_Meeting_Room_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** Meeting rooms under an organization and type */
export type Meeting_RoomMeeting_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** Meeting rooms under an organization and type */
export type Meeting_RoomMeeting_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** aggregated selection of "meeting_room" */
export type Meeting_Room_Aggregate = {
  __typename?: 'meeting_room_aggregate';
  aggregate?: Maybe<Meeting_Room_Aggregate_Fields>;
  nodes: Array<Meeting_Room>;
};

export type Meeting_Room_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Room_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Room_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Room_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_room" */
export type Meeting_Room_Aggregate_Fields = {
  __typename?: 'meeting_room_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Room_Max_Fields>;
  min?: Maybe<Meeting_Room_Min_Fields>;
};

/** aggregate fields of "meeting_room" */
export type Meeting_Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_room" */
export type Meeting_Room_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Room_Max_Order_By>;
  min?: InputMaybe<Meeting_Room_Min_Order_By>;
};

/** input type for inserting array relation for remote table "meeting_room" */
export type Meeting_Room_Arr_Rel_Insert_Input = {
  data: Array<Meeting_Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Room_On_Conflict>;
};

/** Boolean expression to filter rows from the table "meeting_room". All fields are combined with a logical 'AND'. */
export type Meeting_Room_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Room_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Room_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Room_Bool_Exp>>;
  channel_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  enum_meeting_room_status?: InputMaybe<Enum_Meeting_Room_Status_Bool_Exp>;
  enum_meeting_room_type?: InputMaybe<Enum_Meeting_Room_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meeting_room_users?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
  meeting_room_users_aggregate?: InputMaybe<Meeting_Room_Users_Aggregate_Bool_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Enum_Meeting_Room_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Enum_Meeting_Room_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "meeting_room" */
export enum Meeting_Room_Constraint {
  /** unique or primary key constraint on columns "channel_id" */
  MeetingRoomChannelIdKey = 'meeting_room_channel_id_key',
  /** unique or primary key constraint on columns "id" */
  MeetingRoomPkey = 'meeting_room_pkey',
  /** unique or primary key constraint on columns "type", "organization_id" */
  MeetingRoomTypeOrganizationIdKey = 'meeting_room_type_organization_id_key',
}

/** input type for inserting data into table "meeting_room" */
export type Meeting_Room_Insert_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  enum_meeting_room_status?: InputMaybe<Enum_Meeting_Room_Status_Obj_Rel_Insert_Input>;
  enum_meeting_room_type?: InputMaybe<Enum_Meeting_Room_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_room_users?: InputMaybe<Meeting_Room_Users_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Meeting_Room_Status_Enum>;
  type?: InputMaybe<Enum_Meeting_Room_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Meeting_Room_Max_Fields = {
  __typename?: 'meeting_room_max_fields';
  channel_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "meeting_room" */
export type Meeting_Room_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Room_Min_Fields = {
  __typename?: 'meeting_room_min_fields';
  channel_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "meeting_room" */
export type Meeting_Room_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "meeting_room" */
export type Meeting_Room_Mutation_Response = {
  __typename?: 'meeting_room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Meeting_Room>;
};

/** input type for inserting object relation for remote table "meeting_room" */
export type Meeting_Room_Obj_Rel_Insert_Input = {
  data: Meeting_Room_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Room_On_Conflict>;
};

/** on_conflict condition type for table "meeting_room" */
export type Meeting_Room_On_Conflict = {
  constraint: Meeting_Room_Constraint;
  update_columns?: Array<Meeting_Room_Update_Column>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** Ordering options when selecting data from "meeting_room". */
export type Meeting_Room_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enum_meeting_room_status?: InputMaybe<Enum_Meeting_Room_Status_Order_By>;
  enum_meeting_room_type?: InputMaybe<Enum_Meeting_Room_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_room_users_aggregate?: InputMaybe<Meeting_Room_Users_Aggregate_Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: meeting_room */
export type Meeting_Room_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "meeting_room" */
export enum Meeting_Room_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "meeting_room" */
export type Meeting_Room_Set_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Meeting_Room_Status_Enum>;
  type?: InputMaybe<Enum_Meeting_Room_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "meeting_room" */
export type Meeting_Room_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Room_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Room_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Meeting_Room_Status_Enum>;
  type?: InputMaybe<Enum_Meeting_Room_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "meeting_room" */
export enum Meeting_Room_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Meeting_Room_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Meeting_Room_Set_Input>;
  /** filter the rows which have to be updated */
  where: Meeting_Room_Bool_Exp;
};

/** Users in a meeting room */
export type Meeting_Room_Users = {
  __typename?: 'meeting_room_users';
  added_by: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_meeting_room_user_state?: Maybe<Enum_Meeting_Room_User_State>;
  /** An object relationship */
  meeting_room: Meeting_Room;
  meeting_room_id: Scalars['uuid'];
  status?: Maybe<Enum_Meeting_Room_User_State_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByUserId: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "meeting_room_users" */
export type Meeting_Room_Users_Aggregate = {
  __typename?: 'meeting_room_users_aggregate';
  aggregate?: Maybe<Meeting_Room_Users_Aggregate_Fields>;
  nodes: Array<Meeting_Room_Users>;
};

export type Meeting_Room_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Room_Users_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Room_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_room_users" */
export type Meeting_Room_Users_Aggregate_Fields = {
  __typename?: 'meeting_room_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Room_Users_Max_Fields>;
  min?: Maybe<Meeting_Room_Users_Min_Fields>;
};

/** aggregate fields of "meeting_room_users" */
export type Meeting_Room_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_room_users" */
export type Meeting_Room_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Room_Users_Max_Order_By>;
  min?: InputMaybe<Meeting_Room_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "meeting_room_users" */
export type Meeting_Room_Users_Arr_Rel_Insert_Input = {
  data: Array<Meeting_Room_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Room_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "meeting_room_users". All fields are combined with a logical 'AND'. */
export type Meeting_Room_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Room_Users_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Room_Users_Bool_Exp>>;
  added_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_meeting_room_user_state?: InputMaybe<Enum_Meeting_Room_User_State_Bool_Exp>;
  meeting_room?: InputMaybe<Meeting_Room_Bool_Exp>;
  meeting_room_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Enum_Meeting_Room_User_State_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByUserId?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "meeting_room_users" */
export enum Meeting_Room_Users_Constraint {
  /** unique or primary key constraint on columns "user_id", "meeting_room_id" */
  MeetingRoomUsersPkey = 'meeting_room_users_pkey',
}

/** input type for inserting data into table "meeting_room_users" */
export type Meeting_Room_Users_Insert_Input = {
  added_by?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_meeting_room_user_state?: InputMaybe<Enum_Meeting_Room_User_State_Obj_Rel_Insert_Input>;
  meeting_room?: InputMaybe<Meeting_Room_Obj_Rel_Insert_Input>;
  meeting_room_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Meeting_Room_User_State_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Meeting_Room_Users_Max_Fields = {
  __typename?: 'meeting_room_users_max_fields';
  added_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  meeting_room_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "meeting_room_users" */
export type Meeting_Room_Users_Max_Order_By = {
  added_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  meeting_room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Room_Users_Min_Fields = {
  __typename?: 'meeting_room_users_min_fields';
  added_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  meeting_room_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "meeting_room_users" */
export type Meeting_Room_Users_Min_Order_By = {
  added_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  meeting_room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "meeting_room_users" */
export type Meeting_Room_Users_Mutation_Response = {
  __typename?: 'meeting_room_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Meeting_Room_Users>;
};

/** on_conflict condition type for table "meeting_room_users" */
export type Meeting_Room_Users_On_Conflict = {
  constraint: Meeting_Room_Users_Constraint;
  update_columns?: Array<Meeting_Room_Users_Update_Column>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "meeting_room_users". */
export type Meeting_Room_Users_Order_By = {
  added_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_meeting_room_user_state?: InputMaybe<Enum_Meeting_Room_User_State_Order_By>;
  meeting_room?: InputMaybe<Meeting_Room_Order_By>;
  meeting_room_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByUserId?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: meeting_room_users */
export type Meeting_Room_Users_Pk_Columns_Input = {
  meeting_room_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "meeting_room_users" */
export enum Meeting_Room_Users_Select_Column {
  /** column name */
  AddedBy = 'added_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MeetingRoomId = 'meeting_room_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "meeting_room_users" */
export type Meeting_Room_Users_Set_Input = {
  added_by?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  meeting_room_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Meeting_Room_User_State_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "meeting_room_users" */
export type Meeting_Room_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Room_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Room_Users_Stream_Cursor_Value_Input = {
  added_by?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  meeting_room_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Meeting_Room_User_State_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "meeting_room_users" */
export enum Meeting_Room_Users_Update_Column {
  /** column name */
  AddedBy = 'added_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MeetingRoomId = 'meeting_room_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Meeting_Room_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Meeting_Room_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Meeting_Room_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  activateAccount?: Maybe<ActivateOutput>;
  addChatEmoteReaction?: Maybe<AddChatEmoteReactionOutput>;
  addComment?: Maybe<AddCommentOutput>;
  /** Add Competency */
  addCompetency?: Maybe<AddCompetencyOutput>;
  /** Add Member to Project */
  addMemberToProject?: Maybe<AddMemberDetailsOutput>;
  addNewLeaveType?: Maybe<AddNewLeaveTypeOutput>;
  /** Add Peer */
  addPeer?: Maybe<PeerOutput>;
  /** Add Competency */
  addSkillInfo?: Maybe<AddSkillDetailsOutput>;
  /** Add User Skills */
  addUserSkills?: Maybe<AddUsersSkillsAndComptOutput>;
  /** Assign User to Career Development Plan */
  addUserToCareerDevelopmentPlan?: Maybe<AddAssignUserToCareerPlanOutput>;
  addUsersToCall: AddUsersToCallOutput;
  addUsersToChat?: Maybe<AddUsersToChatOutput>;
  assignAward?: Maybe<AssignAwardOutput>;
  /** attendAssessment */
  attendAssessment?: Maybe<AttendAssessmentOutput>;
  /** Load Career View Stats */
  careerViewStats?: Maybe<CareerViewStatsOutput>;
  /** Check Goal Count To Start Performance-Review */
  checkGoalCount?: Maybe<GoalCountPerTimeperiodOutput>;
  contactUs: ContactUsOutput;
  createAnAnnouncement: CreateAnnouncementOutput;
  createAssessment?: Maybe<AssessmentOutput>;
  createAwardType?: Maybe<CreateAwardTypeOutput>;
  createCalendarEvent?: Maybe<CreateCalendarEventOutput>;
  /** Create Calendar for One to One Discussion */
  createCalendarOneToOne?: Maybe<CalendarEventOneToOneOutput>;
  createCallToken?: Maybe<CallTokenOutput>;
  /** Create Career Development Plan */
  createCareerDevelopmentPlan?: Maybe<CareerDevelopmentOutput>;
  /** Creates checklist for task */
  createChecklist?: Maybe<ChecklistOutput>;
  /** Create Goal Kpi */
  createGoalKpi?: Maybe<GoalKpiOuput>;
  /** Create Goalss */
  createGoals?: Maybe<GoalsOutput>;
  /** Create KPI */
  createKpi?: Maybe<KpiOutput>;
  createLeaveCycle?: Maybe<LeaveCycleOutput>;
  createMeetCall?: Maybe<CreateMeetCallOutput>;
  createMeetingRoom?: Maybe<CreateMeetingRoomOutput>;
  /** This action creates milestone related to project */
  createMilestone?: Maybe<CreateMilestoneOutput>;
  createOrganization?: Maybe<CreateOrganizationOutput>;
  createOrganizationOwner?: Maybe<CreateOrganizationOwnerOutput>;
  /** Create Performance Review */
  createPerformanceReview?: Maybe<PerformanceReviewOutput>;
  /** Creates a project, and client or chat group if required */
  createProject?: Maybe<CreateProjectOutput>;
  /** Create Review by Users */
  createReviewUsers?: Maybe<PerformanceReviewUsersOutput>;
  /** The action that creates a sprint */
  createSprint?: Maybe<CreateSprintOutput>;
  createTask?: Maybe<CreateTaskOutput>;
  /** createTraining */
  createTraining?: Maybe<AssessmentOutput>;
  /** createTrainingProgress */
  createTrainingProgress?: Maybe<TrainingProgressOutput>;
  deleteCalendarEvent?: Maybe<DeleteCalendarEventOutput>;
  deleteEmployee?: Maybe<DeleteEmployeeOutput>;
  /** Delete Goal */
  deleteGoal?: Maybe<DeleteGoalOutput>;
  deleteOrgAdmin?: Maybe<DeleteOrgAdminOutput>;
  deleteOrgLocation?: Maybe<DeleteOrgLocationOutput>;
  deleteOrganizationRole?: Maybe<DeleteOrgRoleOutput>;
  deleteProject?: Maybe<DeleteProjectOutput>;
  /** The action that deletes a sprint */
  deleteSprint?: Maybe<DeleteSprintOutput>;
  /** delete data from the table: "User_Peer_Review" */
  delete_User_Peer_Review?: Maybe<User_Peer_Review_Mutation_Response>;
  /** delete single row from the table: "User_Peer_Review" */
  delete_User_Peer_Review_by_pk?: Maybe<User_Peer_Review>;
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "assessment" */
  delete_assessment?: Maybe<Assessment_Mutation_Response>;
  /** delete data from the table: "assessment_Skills" */
  delete_assessment_Skills?: Maybe<Assessment_Skills_Mutation_Response>;
  /** delete single row from the table: "assessment_Skills" */
  delete_assessment_Skills_by_pk?: Maybe<Assessment_Skills>;
  /** delete single row from the table: "assessment" */
  delete_assessment_by_pk?: Maybe<Assessment>;
  /** delete data from the table: "assessment_feedback" */
  delete_assessment_feedback?: Maybe<Assessment_Feedback_Mutation_Response>;
  /** delete single row from the table: "assessment_feedback" */
  delete_assessment_feedback_by_pk?: Maybe<Assessment_Feedback>;
  /** delete data from the table: "assessment_record" */
  delete_assessment_record?: Maybe<Assessment_Record_Mutation_Response>;
  /** delete single row from the table: "assessment_record" */
  delete_assessment_record_by_pk?: Maybe<Assessment_Record>;
  /** delete data from the table: "assessment_role_details" */
  delete_assessment_role_details?: Maybe<Assessment_Role_Details_Mutation_Response>;
  /** delete single row from the table: "assessment_role_details" */
  delete_assessment_role_details_by_pk?: Maybe<Assessment_Role_Details>;
  /** delete data from the table: "assessment_stage_career" */
  delete_assessment_stage_career?: Maybe<Assessment_Stage_Career_Mutation_Response>;
  /** delete single row from the table: "assessment_stage_career" */
  delete_assessment_stage_career_by_pk?: Maybe<Assessment_Stage_Career>;
  /** delete data from the table: "assessment_type" */
  delete_assessment_type?: Maybe<Assessment_Type_Mutation_Response>;
  /** delete single row from the table: "assessment_type" */
  delete_assessment_type_by_pk?: Maybe<Assessment_Type>;
  /** delete data from the table: "assign_career_development_plan" */
  delete_assign_career_development_plan?: Maybe<Assign_Career_Development_Plan_Mutation_Response>;
  /** delete single row from the table: "assign_career_development_plan" */
  delete_assign_career_development_plan_by_pk?: Maybe<Assign_Career_Development_Plan>;
  /** delete data from the table: "audit.user_state" */
  delete_audit_user_state?: Maybe<Audit_User_State_Mutation_Response>;
  /** delete single row from the table: "audit.user_state" */
  delete_audit_user_state_by_pk?: Maybe<Audit_User_State>;
  /** delete data from the table: "auth.account_providers" */
  delete_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.account_providers" */
  delete_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** delete data from the table: "auth.account_roles" */
  delete_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.account_roles" */
  delete_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** delete data from the table: "auth.accounts" */
  delete_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** delete single row from the table: "auth.accounts" */
  delete_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** delete data from the table: "auth.providers" */
  delete_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.providers" */
  delete_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** delete data from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** delete data from the table: "auth.roles" */
  delete_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.roles" */
  delete_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** delete data from the table: "award_posts" */
  delete_award_posts?: Maybe<Award_Posts_Mutation_Response>;
  /** delete single row from the table: "award_posts" */
  delete_award_posts_by_pk?: Maybe<Award_Posts>;
  /** delete data from the table: "awards" */
  delete_awards?: Maybe<Awards_Mutation_Response>;
  /** delete single row from the table: "awards" */
  delete_awards_by_pk?: Maybe<Awards>;
  /** delete data from the table: "calendar" */
  delete_calendar?: Maybe<Calendar_Mutation_Response>;
  /** delete data from the table: "calendar_attendee" */
  delete_calendar_attendee?: Maybe<Calendar_Attendee_Mutation_Response>;
  /** delete single row from the table: "calendar_attendee" */
  delete_calendar_attendee_by_pk?: Maybe<Calendar_Attendee>;
  /** delete single row from the table: "calendar" */
  delete_calendar_by_pk?: Maybe<Calendar>;
  /** delete data from the table: "calendar_delete_events" */
  delete_calendar_delete_events?: Maybe<Calendar_Delete_Events_Mutation_Response>;
  /** delete single row from the table: "calendar_delete_events" */
  delete_calendar_delete_events_by_pk?: Maybe<Calendar_Delete_Events>;
  /** delete data from the table: "calendar_event" */
  delete_calendar_event?: Maybe<Calendar_Event_Mutation_Response>;
  /** delete single row from the table: "calendar_event" */
  delete_calendar_event_by_pk?: Maybe<Calendar_Event>;
  /** delete data from the table: "calendar_meeting_call" */
  delete_calendar_meeting_call?: Maybe<Calendar_Meeting_Call_Mutation_Response>;
  /** delete single row from the table: "calendar_meeting_call" */
  delete_calendar_meeting_call_by_pk?: Maybe<Calendar_Meeting_Call>;
  /** delete data from the table: "calendar_meeting_call_request" */
  delete_calendar_meeting_call_request?: Maybe<Calendar_Meeting_Call_Request_Mutation_Response>;
  /** delete single row from the table: "calendar_meeting_call_request" */
  delete_calendar_meeting_call_request_by_pk?: Maybe<Calendar_Meeting_Call_Request>;
  /** delete data from the table: "calendar_meeting_call_user" */
  delete_calendar_meeting_call_user?: Maybe<Calendar_Meeting_Call_User_Mutation_Response>;
  /** delete single row from the table: "calendar_meeting_call_user" */
  delete_calendar_meeting_call_user_by_pk?: Maybe<Calendar_Meeting_Call_User>;
  /** delete data from the table: "call" */
  delete_call?: Maybe<Call_Mutation_Response>;
  /** delete single row from the table: "call" */
  delete_call_by_pk?: Maybe<Call>;
  /** delete data from the table: "call_request" */
  delete_call_request?: Maybe<Call_Request_Mutation_Response>;
  /** delete single row from the table: "call_request" */
  delete_call_request_by_pk?: Maybe<Call_Request>;
  /** delete data from the table: "call_user" */
  delete_call_user?: Maybe<Call_User_Mutation_Response>;
  /** delete single row from the table: "call_user" */
  delete_call_user_by_pk?: Maybe<Call_User>;
  /** delete data from the table: "career_development_detail" */
  delete_career_development_detail?: Maybe<Career_Development_Detail_Mutation_Response>;
  /** delete single row from the table: "career_development_detail" */
  delete_career_development_detail_by_pk?: Maybe<Career_Development_Detail>;
  /** delete data from the table: "career_development_role_details" */
  delete_career_development_role_details?: Maybe<Career_Development_Role_Details_Mutation_Response>;
  /** delete single row from the table: "career_development_role_details" */
  delete_career_development_role_details_by_pk?: Maybe<Career_Development_Role_Details>;
  /** delete data from the table: "career_external_certification" */
  delete_career_external_certification?: Maybe<Career_External_Certification_Mutation_Response>;
  /** delete single row from the table: "career_external_certification" */
  delete_career_external_certification_by_pk?: Maybe<Career_External_Certification>;
  /** delete data from the table: "chat_message" */
  delete_chat_message?: Maybe<Chat_Message_Mutation_Response>;
  /** delete data from the table: "chat_message_action" */
  delete_chat_message_action?: Maybe<Chat_Message_Action_Mutation_Response>;
  /** delete single row from the table: "chat_message_action" */
  delete_chat_message_action_by_pk?: Maybe<Chat_Message_Action>;
  /** delete single row from the table: "chat_message" */
  delete_chat_message_by_pk?: Maybe<Chat_Message>;
  /** delete data from the table: "chat_message_file" */
  delete_chat_message_file?: Maybe<Chat_Message_File_Mutation_Response>;
  /** delete single row from the table: "chat_message_file" */
  delete_chat_message_file_by_pk?: Maybe<Chat_Message_File>;
  /** delete data from the table: "chat_message_reactions" */
  delete_chat_message_reactions?: Maybe<Chat_Message_Reactions_Mutation_Response>;
  /** delete single row from the table: "chat_message_reactions" */
  delete_chat_message_reactions_by_pk?: Maybe<Chat_Message_Reactions>;
  /** delete data from the table: "chat_pinned" */
  delete_chat_pinned?: Maybe<Chat_Pinned_Mutation_Response>;
  /** delete single row from the table: "chat_pinned" */
  delete_chat_pinned_by_pk?: Maybe<Chat_Pinned>;
  /** delete data from the table: "chat_room" */
  delete_chat_room?: Maybe<Chat_Room_Mutation_Response>;
  /** delete single row from the table: "chat_room" */
  delete_chat_room_by_pk?: Maybe<Chat_Room>;
  /** delete data from the table: "chat_room_user" */
  delete_chat_room_user?: Maybe<Chat_Room_User_Mutation_Response>;
  /** delete single row from the table: "chat_room_user" */
  delete_chat_room_user_by_pk?: Maybe<Chat_Room_User>;
  /** delete data from the table: "dashboard_remainders" */
  delete_dashboard_remainders?: Maybe<Dashboard_Remainders_Mutation_Response>;
  /** delete single row from the table: "dashboard_remainders" */
  delete_dashboard_remainders_by_pk?: Maybe<Dashboard_Remainders>;
  /** delete data from the table: "department" */
  delete_department?: Maybe<Department_Mutation_Response>;
  /** delete single row from the table: "department" */
  delete_department_by_pk?: Maybe<Department>;
  /** delete data from the table: "department_employee" */
  delete_department_employee?: Maybe<Department_Employee_Mutation_Response>;
  /** delete single row from the table: "department_employee" */
  delete_department_employee_by_pk?: Maybe<Department_Employee>;
  /** delete data from the table: "document_token" */
  delete_document_token?: Maybe<Document_Token_Mutation_Response>;
  /** delete single row from the table: "document_token" */
  delete_document_token_by_pk?: Maybe<Document_Token>;
  /** delete data from the table: "email_templates" */
  delete_email_templates?: Maybe<Email_Templates_Mutation_Response>;
  /** delete single row from the table: "email_templates" */
  delete_email_templates_by_pk?: Maybe<Email_Templates>;
  /** delete data from the table: "employee_bandwidth_data" */
  delete_employee_bandwidth_data?: Maybe<Employee_Bandwidth_Data_Mutation_Response>;
  /** delete single row from the table: "employee_bandwidth_data" */
  delete_employee_bandwidth_data_by_pk?: Maybe<Employee_Bandwidth_Data>;
  /** delete data from the table: "employee_capacity" */
  delete_employee_capacity?: Maybe<Employee_Capacity_Mutation_Response>;
  /** delete single row from the table: "employee_capacity" */
  delete_employee_capacity_by_pk?: Maybe<Employee_Capacity>;
  /** delete data from the table: "enum_activity_state" */
  delete_enum_activity_state?: Maybe<Enum_Activity_State_Mutation_Response>;
  /** delete single row from the table: "enum_activity_state" */
  delete_enum_activity_state_by_pk?: Maybe<Enum_Activity_State>;
  /** delete data from the table: "enum_activity_type" */
  delete_enum_activity_type?: Maybe<Enum_Activity_Type_Mutation_Response>;
  /** delete single row from the table: "enum_activity_type" */
  delete_enum_activity_type_by_pk?: Maybe<Enum_Activity_Type>;
  /** delete data from the table: "enum_attendee_status_type" */
  delete_enum_attendee_status_type?: Maybe<Enum_Attendee_Status_Type_Mutation_Response>;
  /** delete single row from the table: "enum_attendee_status_type" */
  delete_enum_attendee_status_type_by_pk?: Maybe<Enum_Attendee_Status_Type>;
  /** delete data from the table: "enum_attendee_type" */
  delete_enum_attendee_type?: Maybe<Enum_Attendee_Type_Mutation_Response>;
  /** delete single row from the table: "enum_attendee_type" */
  delete_enum_attendee_type_by_pk?: Maybe<Enum_Attendee_Type>;
  /** delete data from the table: "enum_award_cycle_type" */
  delete_enum_award_cycle_type?: Maybe<Enum_Award_Cycle_Type_Mutation_Response>;
  /** delete single row from the table: "enum_award_cycle_type" */
  delete_enum_award_cycle_type_by_pk?: Maybe<Enum_Award_Cycle_Type>;
  /** delete data from the table: "enum_call_access_policy" */
  delete_enum_call_access_policy?: Maybe<Enum_Call_Access_Policy_Mutation_Response>;
  /** delete single row from the table: "enum_call_access_policy" */
  delete_enum_call_access_policy_by_pk?: Maybe<Enum_Call_Access_Policy>;
  /** delete data from the table: "enum_call_broadcasting_type" */
  delete_enum_call_broadcasting_type?: Maybe<Enum_Call_Broadcasting_Type_Mutation_Response>;
  /** delete single row from the table: "enum_call_broadcasting_type" */
  delete_enum_call_broadcasting_type_by_pk?: Maybe<Enum_Call_Broadcasting_Type>;
  /** delete data from the table: "enum_call_media_type" */
  delete_enum_call_media_type?: Maybe<Enum_Call_Media_Type_Mutation_Response>;
  /** delete single row from the table: "enum_call_media_type" */
  delete_enum_call_media_type_by_pk?: Maybe<Enum_Call_Media_Type>;
  /** delete data from the table: "enum_call_recording_status" */
  delete_enum_call_recording_status?: Maybe<Enum_Call_Recording_Status_Mutation_Response>;
  /** delete single row from the table: "enum_call_recording_status" */
  delete_enum_call_recording_status_by_pk?: Maybe<Enum_Call_Recording_Status>;
  /** delete data from the table: "enum_call_request_response_type" */
  delete_enum_call_request_response_type?: Maybe<Enum_Call_Request_Response_Type_Mutation_Response>;
  /** delete single row from the table: "enum_call_request_response_type" */
  delete_enum_call_request_response_type_by_pk?: Maybe<Enum_Call_Request_Response_Type>;
  /** delete data from the table: "enum_call_request_status_type" */
  delete_enum_call_request_status_type?: Maybe<Enum_Call_Request_Status_Type_Mutation_Response>;
  /** delete single row from the table: "enum_call_request_status_type" */
  delete_enum_call_request_status_type_by_pk?: Maybe<Enum_Call_Request_Status_Type>;
  /** delete data from the table: "enum_call_request_type" */
  delete_enum_call_request_type?: Maybe<Enum_Call_Request_Type_Mutation_Response>;
  /** delete single row from the table: "enum_call_request_type" */
  delete_enum_call_request_type_by_pk?: Maybe<Enum_Call_Request_Type>;
  /** delete data from the table: "enum_call_status_type" */
  delete_enum_call_status_type?: Maybe<Enum_Call_Status_Type_Mutation_Response>;
  /** delete single row from the table: "enum_call_status_type" */
  delete_enum_call_status_type_by_pk?: Maybe<Enum_Call_Status_Type>;
  /** delete data from the table: "enum_call_type" */
  delete_enum_call_type?: Maybe<Enum_Call_Type_Mutation_Response>;
  /** delete single row from the table: "enum_call_type" */
  delete_enum_call_type_by_pk?: Maybe<Enum_Call_Type>;
  /** delete data from the table: "enum_call_user_status" */
  delete_enum_call_user_status?: Maybe<Enum_Call_User_Status_Mutation_Response>;
  /** delete single row from the table: "enum_call_user_status" */
  delete_enum_call_user_status_by_pk?: Maybe<Enum_Call_User_Status>;
  /** delete data from the table: "enum_chat_message_type" */
  delete_enum_chat_message_type?: Maybe<Enum_Chat_Message_Type_Mutation_Response>;
  /** delete single row from the table: "enum_chat_message_type" */
  delete_enum_chat_message_type_by_pk?: Maybe<Enum_Chat_Message_Type>;
  /** delete data from the table: "enum_chat_notification" */
  delete_enum_chat_notification?: Maybe<Enum_Chat_Notification_Mutation_Response>;
  /** delete single row from the table: "enum_chat_notification" */
  delete_enum_chat_notification_by_pk?: Maybe<Enum_Chat_Notification>;
  /** delete data from the table: "enum_chat_room_notification_status" */
  delete_enum_chat_room_notification_status?: Maybe<Enum_Chat_Room_Notification_Status_Mutation_Response>;
  /** delete single row from the table: "enum_chat_room_notification_status" */
  delete_enum_chat_room_notification_status_by_pk?: Maybe<Enum_Chat_Room_Notification_Status>;
  /** delete data from the table: "enum_chat_room_type" */
  delete_enum_chat_room_type?: Maybe<Enum_Chat_Room_Type_Mutation_Response>;
  /** delete single row from the table: "enum_chat_room_type" */
  delete_enum_chat_room_type_by_pk?: Maybe<Enum_Chat_Room_Type>;
  /** delete data from the table: "enum_chat_room_user_status" */
  delete_enum_chat_room_user_status?: Maybe<Enum_Chat_Room_User_Status_Mutation_Response>;
  /** delete single row from the table: "enum_chat_room_user_status" */
  delete_enum_chat_room_user_status_by_pk?: Maybe<Enum_Chat_Room_User_Status>;
  /** delete data from the table: "enum_dashboard_remainder_type" */
  delete_enum_dashboard_remainder_type?: Maybe<Enum_Dashboard_Remainder_Type_Mutation_Response>;
  /** delete single row from the table: "enum_dashboard_remainder_type" */
  delete_enum_dashboard_remainder_type_by_pk?: Maybe<Enum_Dashboard_Remainder_Type>;
  /** delete data from the table: "enum_document_token_type" */
  delete_enum_document_token_type?: Maybe<Enum_Document_Token_Type_Mutation_Response>;
  /** delete single row from the table: "enum_document_token_type" */
  delete_enum_document_token_type_by_pk?: Maybe<Enum_Document_Token_Type>;
  /** delete data from the table: "enum_event_type" */
  delete_enum_event_type?: Maybe<Enum_Event_Type_Mutation_Response>;
  /** delete single row from the table: "enum_event_type" */
  delete_enum_event_type_by_pk?: Maybe<Enum_Event_Type>;
  /** delete data from the table: "enum_leave_donate_type" */
  delete_enum_leave_donate_type?: Maybe<Enum_Leave_Donate_Type_Mutation_Response>;
  /** delete single row from the table: "enum_leave_donate_type" */
  delete_enum_leave_donate_type_by_pk?: Maybe<Enum_Leave_Donate_Type>;
  /** delete data from the table: "enum_leave_status_type" */
  delete_enum_leave_status_type?: Maybe<Enum_Leave_Status_Type_Mutation_Response>;
  /** delete single row from the table: "enum_leave_status_type" */
  delete_enum_leave_status_type_by_pk?: Maybe<Enum_Leave_Status_Type>;
  /** delete data from the table: "enum_live_call_request_status" */
  delete_enum_live_call_request_status?: Maybe<Enum_Live_Call_Request_Status_Mutation_Response>;
  /** delete single row from the table: "enum_live_call_request_status" */
  delete_enum_live_call_request_status_by_pk?: Maybe<Enum_Live_Call_Request_Status>;
  /** delete data from the table: "enum_live_call_status" */
  delete_enum_live_call_status?: Maybe<Enum_Live_Call_Status_Mutation_Response>;
  /** delete single row from the table: "enum_live_call_status" */
  delete_enum_live_call_status_by_pk?: Maybe<Enum_Live_Call_Status>;
  /** delete data from the table: "enum_live_call_user_status" */
  delete_enum_live_call_user_status?: Maybe<Enum_Live_Call_User_Status_Mutation_Response>;
  /** delete single row from the table: "enum_live_call_user_status" */
  delete_enum_live_call_user_status_by_pk?: Maybe<Enum_Live_Call_User_Status>;
  /** delete data from the table: "enum_live_emote_type" */
  delete_enum_live_emote_type?: Maybe<Enum_Live_Emote_Type_Mutation_Response>;
  /** delete single row from the table: "enum_live_emote_type" */
  delete_enum_live_emote_type_by_pk?: Maybe<Enum_Live_Emote_Type>;
  /** delete data from the table: "enum_manual_state" */
  delete_enum_manual_state?: Maybe<Enum_Manual_State_Mutation_Response>;
  /** delete single row from the table: "enum_manual_state" */
  delete_enum_manual_state_by_pk?: Maybe<Enum_Manual_State>;
  /** delete data from the table: "enum_meeting_room_status" */
  delete_enum_meeting_room_status?: Maybe<Enum_Meeting_Room_Status_Mutation_Response>;
  /** delete single row from the table: "enum_meeting_room_status" */
  delete_enum_meeting_room_status_by_pk?: Maybe<Enum_Meeting_Room_Status>;
  /** delete data from the table: "enum_meeting_room_type" */
  delete_enum_meeting_room_type?: Maybe<Enum_Meeting_Room_Type_Mutation_Response>;
  /** delete single row from the table: "enum_meeting_room_type" */
  delete_enum_meeting_room_type_by_pk?: Maybe<Enum_Meeting_Room_Type>;
  /** delete data from the table: "enum_meeting_room_user_state" */
  delete_enum_meeting_room_user_state?: Maybe<Enum_Meeting_Room_User_State_Mutation_Response>;
  /** delete single row from the table: "enum_meeting_room_user_state" */
  delete_enum_meeting_room_user_state_by_pk?: Maybe<Enum_Meeting_Room_User_State>;
  /** delete data from the table: "enum_notification_type" */
  delete_enum_notification_type?: Maybe<Enum_Notification_Type_Mutation_Response>;
  /** delete single row from the table: "enum_notification_type" */
  delete_enum_notification_type_by_pk?: Maybe<Enum_Notification_Type>;
  /** delete data from the table: "enum_personalised_access" */
  delete_enum_personalised_access?: Maybe<Enum_Personalised_Access_Mutation_Response>;
  /** delete single row from the table: "enum_personalised_access" */
  delete_enum_personalised_access_by_pk?: Maybe<Enum_Personalised_Access>;
  /** delete data from the table: "enum_personalised_categories" */
  delete_enum_personalised_categories?: Maybe<Enum_Personalised_Categories_Mutation_Response>;
  /** delete single row from the table: "enum_personalised_categories" */
  delete_enum_personalised_categories_by_pk?: Maybe<Enum_Personalised_Categories>;
  /** delete data from the table: "enum_post_type" */
  delete_enum_post_type?: Maybe<Enum_Post_Type_Mutation_Response>;
  /** delete single row from the table: "enum_post_type" */
  delete_enum_post_type_by_pk?: Maybe<Enum_Post_Type>;
  /** delete data from the table: "enum_presence_connection_status" */
  delete_enum_presence_connection_status?: Maybe<Enum_Presence_Connection_Status_Mutation_Response>;
  /** delete single row from the table: "enum_presence_connection_status" */
  delete_enum_presence_connection_status_by_pk?: Maybe<Enum_Presence_Connection_Status>;
  /** delete data from the table: "enum_project_milestone_status" */
  delete_enum_project_milestone_status?: Maybe<Enum_Project_Milestone_Status_Mutation_Response>;
  /** delete single row from the table: "enum_project_milestone_status" */
  delete_enum_project_milestone_status_by_pk?: Maybe<Enum_Project_Milestone_Status>;
  /** delete data from the table: "enum_project_sprint_category" */
  delete_enum_project_sprint_category?: Maybe<Enum_Project_Sprint_Category_Mutation_Response>;
  /** delete single row from the table: "enum_project_sprint_category" */
  delete_enum_project_sprint_category_by_pk?: Maybe<Enum_Project_Sprint_Category>;
  /** delete data from the table: "enum_project_status" */
  delete_enum_project_status?: Maybe<Enum_Project_Status_Mutation_Response>;
  /** delete single row from the table: "enum_project_status" */
  delete_enum_project_status_by_pk?: Maybe<Enum_Project_Status>;
  /** delete data from the table: "enum_project_task_category" */
  delete_enum_project_task_category?: Maybe<Enum_Project_Task_Category_Mutation_Response>;
  /** delete single row from the table: "enum_project_task_category" */
  delete_enum_project_task_category_by_pk?: Maybe<Enum_Project_Task_Category>;
  /** delete data from the table: "enum_setting_type" */
  delete_enum_setting_type?: Maybe<Enum_Setting_Type_Mutation_Response>;
  /** delete single row from the table: "enum_setting_type" */
  delete_enum_setting_type_by_pk?: Maybe<Enum_Setting_Type>;
  /** delete data from the table: "enum_storage_file_type" */
  delete_enum_storage_file_type?: Maybe<Enum_Storage_File_Type_Mutation_Response>;
  /** delete single row from the table: "enum_storage_file_type" */
  delete_enum_storage_file_type_by_pk?: Maybe<Enum_Storage_File_Type>;
  /** delete data from the table: "enum_system_state" */
  delete_enum_system_state?: Maybe<Enum_System_State_Mutation_Response>;
  /** delete single row from the table: "enum_system_state" */
  delete_enum_system_state_by_pk?: Maybe<Enum_System_State>;
  /** delete data from the table: "enum_system_state_owner" */
  delete_enum_system_state_owner?: Maybe<Enum_System_State_Owner_Mutation_Response>;
  /** delete single row from the table: "enum_system_state_owner" */
  delete_enum_system_state_owner_by_pk?: Maybe<Enum_System_State_Owner>;
  /** delete data from the table: "enum_task_priority" */
  delete_enum_task_priority?: Maybe<Enum_Task_Priority_Mutation_Response>;
  /** delete single row from the table: "enum_task_priority" */
  delete_enum_task_priority_by_pk?: Maybe<Enum_Task_Priority>;
  /** delete data from the table: "enum_task_recurring_frequency" */
  delete_enum_task_recurring_frequency?: Maybe<Enum_Task_Recurring_Frequency_Mutation_Response>;
  /** delete single row from the table: "enum_task_recurring_frequency" */
  delete_enum_task_recurring_frequency_by_pk?: Maybe<Enum_Task_Recurring_Frequency>;
  /** delete data from the table: "enum_task_status" */
  delete_enum_task_status?: Maybe<Enum_Task_Status_Mutation_Response>;
  /** delete single row from the table: "enum_task_status" */
  delete_enum_task_status_by_pk?: Maybe<Enum_Task_Status>;
  /** delete data from the table: "enum_task_time_sheet_status" */
  delete_enum_task_time_sheet_status?: Maybe<Enum_Task_Time_Sheet_Status_Mutation_Response>;
  /** delete single row from the table: "enum_task_time_sheet_status" */
  delete_enum_task_time_sheet_status_by_pk?: Maybe<Enum_Task_Time_Sheet_Status>;
  /** delete data from the table: "enum_user_activity_tracker_status_type" */
  delete_enum_user_activity_tracker_status_type?: Maybe<Enum_User_Activity_Tracker_Status_Type_Mutation_Response>;
  /** delete single row from the table: "enum_user_activity_tracker_status_type" */
  delete_enum_user_activity_tracker_status_type_by_pk?: Maybe<Enum_User_Activity_Tracker_Status_Type>;
  /** delete data from the table: "enum_user_avatar_types" */
  delete_enum_user_avatar_types?: Maybe<Enum_User_Avatar_Types_Mutation_Response>;
  /** delete single row from the table: "enum_user_avatar_types" */
  delete_enum_user_avatar_types_by_pk?: Maybe<Enum_User_Avatar_Types>;
  /** delete data from the table: "enum_user_gender" */
  delete_enum_user_gender?: Maybe<Enum_User_Gender_Mutation_Response>;
  /** delete single row from the table: "enum_user_gender" */
  delete_enum_user_gender_by_pk?: Maybe<Enum_User_Gender>;
  /** delete data from the table: "enum_user_presence_provider_type" */
  delete_enum_user_presence_provider_type?: Maybe<Enum_User_Presence_Provider_Type_Mutation_Response>;
  /** delete single row from the table: "enum_user_presence_provider_type" */
  delete_enum_user_presence_provider_type_by_pk?: Maybe<Enum_User_Presence_Provider_Type>;
  /** delete data from the table: "enum_user_settings_type" */
  delete_enum_user_settings_type?: Maybe<Enum_User_Settings_Type_Mutation_Response>;
  /** delete single row from the table: "enum_user_settings_type" */
  delete_enum_user_settings_type_by_pk?: Maybe<Enum_User_Settings_Type>;
  /** delete data from the table: "filter_definition" */
  delete_filter_definition?: Maybe<Filter_Definition_Mutation_Response>;
  /** delete single row from the table: "filter_definition" */
  delete_filter_definition_by_pk?: Maybe<Filter_Definition>;
  /** delete data from the table: "financialYear" */
  delete_financialYear?: Maybe<FinancialYear_Mutation_Response>;
  /** delete single row from the table: "financialYear" */
  delete_financialYear_by_pk?: Maybe<FinancialYear>;
  /** delete data from the table: "frequency" */
  delete_frequency?: Maybe<Frequency_Mutation_Response>;
  /** delete single row from the table: "frequency" */
  delete_frequency_by_pk?: Maybe<Frequency>;
  /** delete data from the table: "goalWithKpi" */
  delete_goalWithKpi?: Maybe<GoalWithKpi_Mutation_Response>;
  /** delete single row from the table: "goalWithKpi" */
  delete_goalWithKpi_by_pk?: Maybe<GoalWithKpi>;
  /** delete data from the table: "goalWithTimePeriod" */
  delete_goalWithTimePeriod?: Maybe<GoalWithTimePeriod_Mutation_Response>;
  /** delete single row from the table: "goalWithTimePeriod" */
  delete_goalWithTimePeriod_by_pk?: Maybe<GoalWithTimePeriod>;
  /** delete data from the table: "goals" */
  delete_goals?: Maybe<Goals_Mutation_Response>;
  /** delete data from the table: "goalsWithDepts" */
  delete_goalsWithDepts?: Maybe<GoalsWithDepts_Mutation_Response>;
  /** delete single row from the table: "goalsWithDepts" */
  delete_goalsWithDepts_by_pk?: Maybe<GoalsWithDepts>;
  /** delete data from the table: "goalsWithFreqs" */
  delete_goalsWithFreqs?: Maybe<GoalsWithFreqs_Mutation_Response>;
  /** delete single row from the table: "goalsWithFreqs" */
  delete_goalsWithFreqs_by_pk?: Maybe<GoalsWithFreqs>;
  /** delete data from the table: "goalsWithRoles" */
  delete_goalsWithRoles?: Maybe<GoalsWithRoles_Mutation_Response>;
  /** delete single row from the table: "goalsWithRoles" */
  delete_goalsWithRoles_by_pk?: Maybe<GoalsWithRoles>;
  /** delete single row from the table: "goals" */
  delete_goals_by_pk?: Maybe<Goals>;
  /** delete data from the table: "hotdesk_bookings" */
  delete_hotdesk_bookings?: Maybe<Hotdesk_Bookings_Mutation_Response>;
  /** delete single row from the table: "hotdesk_bookings" */
  delete_hotdesk_bookings_by_pk?: Maybe<Hotdesk_Bookings>;
  /** delete data from the table: "kpiWithDept" */
  delete_kpiWithDept?: Maybe<KpiWithDept_Mutation_Response>;
  /** delete single row from the table: "kpiWithDept" */
  delete_kpiWithDept_by_pk?: Maybe<KpiWithDept>;
  /** delete data from the table: "kpiWithRole" */
  delete_kpiWithRole?: Maybe<KpiWithRole_Mutation_Response>;
  /** delete single row from the table: "kpiWithRole" */
  delete_kpiWithRole_by_pk?: Maybe<KpiWithRole>;
  /** delete data from the table: "kpi_bank" */
  delete_kpi_bank?: Maybe<Kpi_Bank_Mutation_Response>;
  /** delete single row from the table: "kpi_bank" */
  delete_kpi_bank_by_pk?: Maybe<Kpi_Bank>;
  /** delete data from the table: "leave" */
  delete_leave?: Maybe<Leave_Mutation_Response>;
  /** delete single row from the table: "leave" */
  delete_leave_by_pk?: Maybe<Leave>;
  /** delete data from the table: "leave_donate" */
  delete_leave_donate?: Maybe<Leave_Donate_Mutation_Response>;
  /** delete single row from the table: "leave_donate" */
  delete_leave_donate_by_pk?: Maybe<Leave_Donate>;
  /** delete data from the table: "leave_organization" */
  delete_leave_organization?: Maybe<Leave_Organization_Mutation_Response>;
  /** delete single row from the table: "leave_organization" */
  delete_leave_organization_by_pk?: Maybe<Leave_Organization>;
  /** delete data from the table: "list_Of_Skills" */
  delete_list_Of_Skills?: Maybe<List_Of_Skills_Mutation_Response>;
  /** delete single row from the table: "list_Of_Skills" */
  delete_list_Of_Skills_by_pk?: Maybe<List_Of_Skills>;
  /** delete data from the table: "live_call" */
  delete_live_call?: Maybe<Live_Call_Mutation_Response>;
  /** delete single row from the table: "live_call" */
  delete_live_call_by_pk?: Maybe<Live_Call>;
  /** delete data from the table: "live_call_request" */
  delete_live_call_request?: Maybe<Live_Call_Request_Mutation_Response>;
  /** delete single row from the table: "live_call_request" */
  delete_live_call_request_by_pk?: Maybe<Live_Call_Request>;
  /** delete data from the table: "live_call_user" */
  delete_live_call_user?: Maybe<Live_Call_User_Mutation_Response>;
  /** delete single row from the table: "live_call_user" */
  delete_live_call_user_by_pk?: Maybe<Live_Call_User>;
  /** delete data from the table: "main_skills" */
  delete_main_skills?: Maybe<Main_Skills_Mutation_Response>;
  /** delete single row from the table: "main_skills" */
  delete_main_skills_by_pk?: Maybe<Main_Skills>;
  /** delete data from the table: "meeting_call_schedular" */
  delete_meeting_call_schedular?: Maybe<Meeting_Call_Schedular_Mutation_Response>;
  /** delete single row from the table: "meeting_call_schedular" */
  delete_meeting_call_schedular_by_pk?: Maybe<Meeting_Call_Schedular>;
  /** delete data from the table: "meeting_room" */
  delete_meeting_room?: Maybe<Meeting_Room_Mutation_Response>;
  /** delete single row from the table: "meeting_room" */
  delete_meeting_room_by_pk?: Maybe<Meeting_Room>;
  /** delete data from the table: "meeting_room_users" */
  delete_meeting_room_users?: Maybe<Meeting_Room_Users_Mutation_Response>;
  /** delete single row from the table: "meeting_room_users" */
  delete_meeting_room_users_by_pk?: Maybe<Meeting_Room_Users>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "organization" */
  delete_organization?: Maybe<Organization_Mutation_Response>;
  /** delete data from the table: "organization_active_leave_types" */
  delete_organization_active_leave_types?: Maybe<Organization_Active_Leave_Types_Mutation_Response>;
  /** delete single row from the table: "organization_active_leave_types" */
  delete_organization_active_leave_types_by_pk?: Maybe<Organization_Active_Leave_Types>;
  /** delete data from the table: "organization_admin" */
  delete_organization_admin?: Maybe<Organization_Admin_Mutation_Response>;
  /** delete single row from the table: "organization_admin" */
  delete_organization_admin_by_pk?: Maybe<Organization_Admin>;
  /** delete data from the table: "organization_awards" */
  delete_organization_awards?: Maybe<Organization_Awards_Mutation_Response>;
  /** delete single row from the table: "organization_awards" */
  delete_organization_awards_by_pk?: Maybe<Organization_Awards>;
  /** delete single row from the table: "organization" */
  delete_organization_by_pk?: Maybe<Organization>;
  /** delete data from the table: "organization_employee" */
  delete_organization_employee?: Maybe<Organization_Employee_Mutation_Response>;
  /** delete single row from the table: "organization_employee" */
  delete_organization_employee_by_pk?: Maybe<Organization_Employee>;
  /** delete data from the table: "organization_holidays" */
  delete_organization_holidays?: Maybe<Organization_Holidays_Mutation_Response>;
  /** delete single row from the table: "organization_holidays" */
  delete_organization_holidays_by_pk?: Maybe<Organization_Holidays>;
  /** delete data from the table: "organization_leave_cycles" */
  delete_organization_leave_cycles?: Maybe<Organization_Leave_Cycles_Mutation_Response>;
  /** delete single row from the table: "organization_leave_cycles" */
  delete_organization_leave_cycles_by_pk?: Maybe<Organization_Leave_Cycles>;
  /** delete data from the table: "organization_locations" */
  delete_organization_locations?: Maybe<Organization_Locations_Mutation_Response>;
  /** delete single row from the table: "organization_locations" */
  delete_organization_locations_by_pk?: Maybe<Organization_Locations>;
  /** delete data from the table: "organization_roles" */
  delete_organization_roles?: Maybe<Organization_Roles_Mutation_Response>;
  /** delete single row from the table: "organization_roles" */
  delete_organization_roles_by_pk?: Maybe<Organization_Roles>;
  /** delete data from the table: "organization_user_leave_application" */
  delete_organization_user_leave_application?: Maybe<Organization_User_Leave_Application_Mutation_Response>;
  /** delete single row from the table: "organization_user_leave_application" */
  delete_organization_user_leave_application_by_pk?: Maybe<Organization_User_Leave_Application>;
  /** delete data from the table: "organization_user_leave_data" */
  delete_organization_user_leave_data?: Maybe<Organization_User_Leave_Data_Mutation_Response>;
  /** delete single row from the table: "organization_user_leave_data" */
  delete_organization_user_leave_data_by_pk?: Maybe<Organization_User_Leave_Data>;
  /** delete data from the table: "organization_user_leave_dates" */
  delete_organization_user_leave_dates?: Maybe<Organization_User_Leave_Dates_Mutation_Response>;
  /** delete single row from the table: "organization_user_leave_dates" */
  delete_organization_user_leave_dates_by_pk?: Maybe<Organization_User_Leave_Dates>;
  /** delete data from the table: "organization_user_leave_files" */
  delete_organization_user_leave_files?: Maybe<Organization_User_Leave_Files_Mutation_Response>;
  /** delete single row from the table: "organization_user_leave_files" */
  delete_organization_user_leave_files_by_pk?: Maybe<Organization_User_Leave_Files>;
  /** delete data from the table: "performanceReviewStatus" */
  delete_performanceReviewStatus?: Maybe<PerformanceReviewStatus_Mutation_Response>;
  /** delete single row from the table: "performanceReviewStatus" */
  delete_performanceReviewStatus_by_pk?: Maybe<PerformanceReviewStatus>;
  /** delete data from the table: "performance_rating_scale" */
  delete_performance_rating_scale?: Maybe<Performance_Rating_Scale_Mutation_Response>;
  /** delete single row from the table: "performance_rating_scale" */
  delete_performance_rating_scale_by_pk?: Maybe<Performance_Rating_Scale>;
  /** delete data from the table: "performance_review_byUser" */
  delete_performance_review_byUser?: Maybe<Performance_Review_ByUser_Mutation_Response>;
  /** delete data from the table: "performance_review_byUser_Kpi" */
  delete_performance_review_byUser_Kpi?: Maybe<Performance_Review_ByUser_Kpi_Mutation_Response>;
  /** delete single row from the table: "performance_review_byUser_Kpi" */
  delete_performance_review_byUser_Kpi_by_pk?: Maybe<Performance_Review_ByUser_Kpi>;
  /** delete data from the table: "performance_review_byUser_WithGoal" */
  delete_performance_review_byUser_WithGoal?: Maybe<Performance_Review_ByUser_WithGoal_Mutation_Response>;
  /** delete single row from the table: "performance_review_byUser_WithGoal" */
  delete_performance_review_byUser_WithGoal_by_pk?: Maybe<Performance_Review_ByUser_WithGoal>;
  /** delete single row from the table: "performance_review_byUser" */
  delete_performance_review_byUser_by_pk?: Maybe<Performance_Review_ByUser>;
  /** delete data from the table: "performance_review_setting_GoalsList" */
  delete_performance_review_setting_GoalsList?: Maybe<Performance_Review_Setting_GoalsList_Mutation_Response>;
  /** delete single row from the table: "performance_review_setting_GoalsList" */
  delete_performance_review_setting_GoalsList_by_pk?: Maybe<Performance_Review_Setting_GoalsList>;
  /** delete data from the table: "performance_review_setting_With_Goals" */
  delete_performance_review_setting_With_Goals?: Maybe<Performance_Review_Setting_With_Goals_Mutation_Response>;
  /** delete single row from the table: "performance_review_setting_With_Goals" */
  delete_performance_review_setting_With_Goals_by_pk?: Maybe<Performance_Review_Setting_With_Goals>;
  /** delete data from the table: "performance_review_settings" */
  delete_performance_review_settings?: Maybe<Performance_Review_Settings_Mutation_Response>;
  /** delete single row from the table: "performance_review_settings" */
  delete_performance_review_settings_by_pk?: Maybe<Performance_Review_Settings>;
  /** delete data from the table: "personalised" */
  delete_personalised?: Maybe<Personalised_Mutation_Response>;
  /** delete single row from the table: "personalised" */
  delete_personalised_by_pk?: Maybe<Personalised>;
  /** delete data from the table: "post_comment_files" */
  delete_post_comment_files?: Maybe<Post_Comment_Files_Mutation_Response>;
  /** delete single row from the table: "post_comment_files" */
  delete_post_comment_files_by_pk?: Maybe<Post_Comment_Files>;
  /** delete data from the table: "post_comment_mentioned_users" */
  delete_post_comment_mentioned_users?: Maybe<Post_Comment_Mentioned_Users_Mutation_Response>;
  /** delete single row from the table: "post_comment_mentioned_users" */
  delete_post_comment_mentioned_users_by_pk?: Maybe<Post_Comment_Mentioned_Users>;
  /** delete data from the table: "post_comment_replies" */
  delete_post_comment_replies?: Maybe<Post_Comment_Replies_Mutation_Response>;
  /** delete single row from the table: "post_comment_replies" */
  delete_post_comment_replies_by_pk?: Maybe<Post_Comment_Replies>;
  /** delete data from the table: "post_comment_replies_mentioned_users" */
  delete_post_comment_replies_mentioned_users?: Maybe<Post_Comment_Replies_Mentioned_Users_Mutation_Response>;
  /** delete single row from the table: "post_comment_replies_mentioned_users" */
  delete_post_comment_replies_mentioned_users_by_pk?: Maybe<Post_Comment_Replies_Mentioned_Users>;
  /** delete data from the table: "post_comments_likes" */
  delete_post_comments_likes?: Maybe<Post_Comments_Likes_Mutation_Response>;
  /** delete single row from the table: "post_comments_likes" */
  delete_post_comments_likes_by_pk?: Maybe<Post_Comments_Likes>;
  /** delete data from the table: "post_likes" */
  delete_post_likes?: Maybe<Post_Likes_Mutation_Response>;
  /** delete single row from the table: "post_likes" */
  delete_post_likes_by_pk?: Maybe<Post_Likes>;
  /** delete data from the table: "post_mentioned_users" */
  delete_post_mentioned_users?: Maybe<Post_Mentioned_Users_Mutation_Response>;
  /** delete single row from the table: "post_mentioned_users" */
  delete_post_mentioned_users_by_pk?: Maybe<Post_Mentioned_Users>;
  /** delete data from the table: "post_replies_likes" */
  delete_post_replies_likes?: Maybe<Post_Replies_Likes_Mutation_Response>;
  /** delete single row from the table: "post_replies_likes" */
  delete_post_replies_likes_by_pk?: Maybe<Post_Replies_Likes>;
  /** delete data from the table: "posts" */
  delete_posts?: Maybe<Posts_Mutation_Response>;
  /** delete single row from the table: "posts" */
  delete_posts_by_pk?: Maybe<Posts>;
  /** delete data from the table: "posts_comments" */
  delete_posts_comments?: Maybe<Posts_Comments_Mutation_Response>;
  /** delete single row from the table: "posts_comments" */
  delete_posts_comments_by_pk?: Maybe<Posts_Comments>;
  /** delete data from the table: "posts_files" */
  delete_posts_files?: Maybe<Posts_Files_Mutation_Response>;
  /** delete single row from the table: "posts_files" */
  delete_posts_files_by_pk?: Maybe<Posts_Files>;
  /** delete data from the table: "posts_poll_choices" */
  delete_posts_poll_choices?: Maybe<Posts_Poll_Choices_Mutation_Response>;
  /** delete single row from the table: "posts_poll_choices" */
  delete_posts_poll_choices_by_pk?: Maybe<Posts_Poll_Choices>;
  /** delete data from the table: "posts_poll_choices_votes" */
  delete_posts_poll_choices_votes?: Maybe<Posts_Poll_Choices_Votes_Mutation_Response>;
  /** delete single row from the table: "posts_poll_choices_votes" */
  delete_posts_poll_choices_votes_by_pk?: Maybe<Posts_Poll_Choices_Votes>;
  /** delete data from the table: "profile" */
  delete_profile?: Maybe<Profile_Mutation_Response>;
  /** delete single row from the table: "profile" */
  delete_profile_by_pk?: Maybe<Profile>;
  /** delete data from the table: "project" */
  delete_project?: Maybe<Project_Mutation_Response>;
  /** delete single row from the table: "project" */
  delete_project_by_pk?: Maybe<Project>;
  /** delete data from the table: "project_client" */
  delete_project_client?: Maybe<Project_Client_Mutation_Response>;
  /** delete single row from the table: "project_client" */
  delete_project_client_by_pk?: Maybe<Project_Client>;
  /** delete data from the table: "project_links" */
  delete_project_links?: Maybe<Project_Links_Mutation_Response>;
  /** delete single row from the table: "project_links" */
  delete_project_links_by_pk?: Maybe<Project_Links>;
  /** delete data from the table: "project_members" */
  delete_project_members?: Maybe<Project_Members_Mutation_Response>;
  /** delete single row from the table: "project_members" */
  delete_project_members_by_pk?: Maybe<Project_Members>;
  /** delete data from the table: "project_milestones" */
  delete_project_milestones?: Maybe<Project_Milestones_Mutation_Response>;
  /** delete single row from the table: "project_milestones" */
  delete_project_milestones_by_pk?: Maybe<Project_Milestones>;
  /** delete data from the table: "project_notification_settings" */
  delete_project_notification_settings?: Maybe<Project_Notification_Settings_Mutation_Response>;
  /** delete single row from the table: "project_notification_settings" */
  delete_project_notification_settings_by_pk?: Maybe<Project_Notification_Settings>;
  /** delete data from the table: "project_section" */
  delete_project_section?: Maybe<Project_Section_Mutation_Response>;
  /** delete single row from the table: "project_section" */
  delete_project_section_by_pk?: Maybe<Project_Section>;
  /** delete data from the table: "project_sprints" */
  delete_project_sprints?: Maybe<Project_Sprints_Mutation_Response>;
  /** delete single row from the table: "project_sprints" */
  delete_project_sprints_by_pk?: Maybe<Project_Sprints>;
  /** delete data from the table: "question_options" */
  delete_question_options?: Maybe<Question_Options_Mutation_Response>;
  /** delete single row from the table: "question_options" */
  delete_question_options_by_pk?: Maybe<Question_Options>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<Questions_Mutation_Response>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "skills_detail" */
  delete_skills_detail?: Maybe<Skills_Detail_Mutation_Response>;
  /** delete single row from the table: "skills_detail" */
  delete_skills_detail_by_pk?: Maybe<Skills_Detail>;
  /** delete data from the table: "stage_career_detail" */
  delete_stage_career_detail?: Maybe<Stage_Career_Detail_Mutation_Response>;
  /** delete single row from the table: "stage_career_detail" */
  delete_stage_career_detail_by_pk?: Maybe<Stage_Career_Detail>;
  /** delete data from the table: "storage_files" */
  delete_storage_files?: Maybe<Storage_Files_Mutation_Response>;
  /** delete single row from the table: "storage_files" */
  delete_storage_files_by_pk?: Maybe<Storage_Files>;
  /** delete data from the table: "stress_data" */
  delete_stress_data?: Maybe<Stress_Data_Mutation_Response>;
  /** delete single row from the table: "stress_data" */
  delete_stress_data_by_pk?: Maybe<Stress_Data>;
  /** delete data from the table: "sub_skills" */
  delete_sub_skills?: Maybe<Sub_Skills_Mutation_Response>;
  /** delete single row from the table: "sub_skills" */
  delete_sub_skills_by_pk?: Maybe<Sub_Skills>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "task" */
  delete_task?: Maybe<Task_Mutation_Response>;
  /** delete data from the table: "task_approvers" */
  delete_task_approvers?: Maybe<Task_Approvers_Mutation_Response>;
  /** delete single row from the table: "task_approvers" */
  delete_task_approvers_by_pk?: Maybe<Task_Approvers>;
  /** delete single row from the table: "task" */
  delete_task_by_pk?: Maybe<Task>;
  /** delete data from the table: "task_checklist" */
  delete_task_checklist?: Maybe<Task_Checklist_Mutation_Response>;
  /** delete single row from the table: "task_checklist" */
  delete_task_checklist_by_pk?: Maybe<Task_Checklist>;
  /** delete data from the table: "task_collaborator" */
  delete_task_collaborator?: Maybe<Task_Collaborator_Mutation_Response>;
  /** delete single row from the table: "task_collaborator" */
  delete_task_collaborator_by_pk?: Maybe<Task_Collaborator>;
  /** delete data from the table: "task_comment" */
  delete_task_comment?: Maybe<Task_Comment_Mutation_Response>;
  /** delete single row from the table: "task_comment" */
  delete_task_comment_by_pk?: Maybe<Task_Comment>;
  /** delete data from the table: "task_comment_like" */
  delete_task_comment_like?: Maybe<Task_Comment_Like_Mutation_Response>;
  /** delete single row from the table: "task_comment_like" */
  delete_task_comment_like_by_pk?: Maybe<Task_Comment_Like>;
  /** delete data from the table: "task_dependencies" */
  delete_task_dependencies?: Maybe<Task_Dependencies_Mutation_Response>;
  /** delete single row from the table: "task_dependencies" */
  delete_task_dependencies_by_pk?: Maybe<Task_Dependencies>;
  /** delete data from the table: "task_file" */
  delete_task_file?: Maybe<Task_File_Mutation_Response>;
  /** delete single row from the table: "task_file" */
  delete_task_file_by_pk?: Maybe<Task_File>;
  /** delete data from the table: "task_milestones" */
  delete_task_milestones?: Maybe<Task_Milestones_Mutation_Response>;
  /** delete single row from the table: "task_milestones" */
  delete_task_milestones_by_pk?: Maybe<Task_Milestones>;
  /** delete data from the table: "task_tags" */
  delete_task_tags?: Maybe<Task_Tags_Mutation_Response>;
  /** delete single row from the table: "task_tags" */
  delete_task_tags_by_pk?: Maybe<Task_Tags>;
  /** delete data from the table: "task_time_sheet" */
  delete_task_time_sheet?: Maybe<Task_Time_Sheet_Mutation_Response>;
  /** delete single row from the table: "task_time_sheet" */
  delete_task_time_sheet_by_pk?: Maybe<Task_Time_Sheet>;
  /** delete data from the table: "task_unscheduled" */
  delete_task_unscheduled?: Maybe<Task_Unscheduled_Mutation_Response>;
  /** delete single row from the table: "task_unscheduled" */
  delete_task_unscheduled_by_pk?: Maybe<Task_Unscheduled>;
  /** delete data from the table: "time_period" */
  delete_time_period?: Maybe<Time_Period_Mutation_Response>;
  /** delete single row from the table: "time_period" */
  delete_time_period_by_pk?: Maybe<Time_Period>;
  /** delete data from the table: "training" */
  delete_training?: Maybe<Training_Mutation_Response>;
  /** delete data from the table: "training_Skill" */
  delete_training_Skill?: Maybe<Training_Skill_Mutation_Response>;
  /** delete single row from the table: "training_Skill" */
  delete_training_Skill_by_pk?: Maybe<Training_Skill>;
  /** delete single row from the table: "training" */
  delete_training_by_pk?: Maybe<Training>;
  /** delete data from the table: "training_feedback" */
  delete_training_feedback?: Maybe<Training_Feedback_Mutation_Response>;
  /** delete single row from the table: "training_feedback" */
  delete_training_feedback_by_pk?: Maybe<Training_Feedback>;
  /** delete data from the table: "training_progress_record" */
  delete_training_progress_record?: Maybe<Training_Progress_Record_Mutation_Response>;
  /** delete single row from the table: "training_progress_record" */
  delete_training_progress_record_by_pk?: Maybe<Training_Progress_Record>;
  /** delete data from the table: "training_role_details" */
  delete_training_role_details?: Maybe<Training_Role_Details_Mutation_Response>;
  /** delete single row from the table: "training_role_details" */
  delete_training_role_details_by_pk?: Maybe<Training_Role_Details>;
  /** delete data from the table: "training_stage_career" */
  delete_training_stage_career?: Maybe<Training_Stage_Career_Mutation_Response>;
  /** delete single row from the table: "training_stage_career" */
  delete_training_stage_career_by_pk?: Maybe<Training_Stage_Career>;
  /** delete data from the table: "training_table_of_contents" */
  delete_training_table_of_contents?: Maybe<Training_Table_Of_Contents_Mutation_Response>;
  /** delete single row from the table: "training_table_of_contents" */
  delete_training_table_of_contents_by_pk?: Maybe<Training_Table_Of_Contents>;
  /** delete data from the table: "training_type_for_training" */
  delete_training_type_for_training?: Maybe<Training_Type_For_Training_Mutation_Response>;
  /** delete single row from the table: "training_type_for_training" */
  delete_training_type_for_training_by_pk?: Maybe<Training_Type_For_Training>;
  /** delete data from the table: "user_Competency" */
  delete_user_Competency?: Maybe<User_Competency_Mutation_Response>;
  /** delete single row from the table: "user_Competency" */
  delete_user_Competency_by_pk?: Maybe<User_Competency>;
  /** delete data from the table: "user_Skills" */
  delete_user_Skills?: Maybe<User_Skills_Mutation_Response>;
  /** delete data from the table: "user_Skills_Assessment" */
  delete_user_Skills_Assessment?: Maybe<User_Skills_Assessment_Mutation_Response>;
  /** delete single row from the table: "user_Skills_Assessment" */
  delete_user_Skills_Assessment_by_pk?: Maybe<User_Skills_Assessment>;
  /** delete data from the table: "user_Skills_Training" */
  delete_user_Skills_Training?: Maybe<User_Skills_Training_Mutation_Response>;
  /** delete single row from the table: "user_Skills_Training" */
  delete_user_Skills_Training_by_pk?: Maybe<User_Skills_Training>;
  /** delete single row from the table: "user_Skills" */
  delete_user_Skills_by_pk?: Maybe<User_Skills>;
  /** delete data from the table: "user_activity_tracker" */
  delete_user_activity_tracker?: Maybe<User_Activity_Tracker_Mutation_Response>;
  /** delete single row from the table: "user_activity_tracker" */
  delete_user_activity_tracker_by_pk?: Maybe<User_Activity_Tracker>;
  /** delete data from the table: "user_avatar" */
  delete_user_avatar?: Maybe<User_Avatar_Mutation_Response>;
  /** delete single row from the table: "user_avatar" */
  delete_user_avatar_by_pk?: Maybe<User_Avatar>;
  /** delete data from the table: "user_avatar_files" */
  delete_user_avatar_files?: Maybe<User_Avatar_Files_Mutation_Response>;
  /** delete single row from the table: "user_avatar_files" */
  delete_user_avatar_files_by_pk?: Maybe<User_Avatar_Files>;
  /** delete data from the table: "user_career_development_record" */
  delete_user_career_development_record?: Maybe<User_Career_Development_Record_Mutation_Response>;
  /** delete single row from the table: "user_career_development_record" */
  delete_user_career_development_record_by_pk?: Maybe<User_Career_Development_Record>;
  /** delete data from the table: "user_career_external_certification" */
  delete_user_career_external_certification?: Maybe<User_Career_External_Certification_Mutation_Response>;
  /** delete single row from the table: "user_career_external_certification" */
  delete_user_career_external_certification_by_pk?: Maybe<User_Career_External_Certification>;
  /** delete data from the table: "user_presence" */
  delete_user_presence?: Maybe<User_Presence_Mutation_Response>;
  /** delete single row from the table: "user_presence" */
  delete_user_presence_by_pk?: Maybe<User_Presence>;
  /** delete data from the table: "user_settings" */
  delete_user_settings?: Maybe<User_Settings_Mutation_Response>;
  /** delete single row from the table: "user_settings" */
  delete_user_settings_by_pk?: Maybe<User_Settings>;
  /** delete data from the table: "user_state" */
  delete_user_state?: Maybe<User_State_Mutation_Response>;
  /** delete single row from the table: "user_state" */
  delete_user_state_by_pk?: Maybe<User_State>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  dropboxAuthentication?: Maybe<DropboxAuthenticationOutput>;
  dropboxLogOut?: Maybe<DropboxLogoutOutput>;
  editComment: EditCommentOutput;
  /** Edit Competency */
  editCompetency?: Maybe<EditCompetencyOutput>;
  editLeaveType?: Maybe<EditLeaveTypeOutput>;
  editPost?: Maybe<EditPostOutput>;
  /** Edit Skill Details */
  editSkillDetails?: Maybe<EditSkillDetailsOutput>;
  /** addEmployeeBandwidthData */
  employeeBandwidthData?: Maybe<EmployeeBandwidthOutput>;
  extendDayAlert?: Maybe<ExtendDayAlertOutput>;
  /** Get All User Skill Detail */
  getAllUserSkillDetails?: Maybe<GetAllUsersBySkillOutput>;
  getDashboardReminders: RemainderOutput;
  /** Get Goal List Per Roles */
  getGoalList?: Maybe<GetGoalListPerRoleOutput>;
  /** Get No of Goals as per Roles */
  getGoalNoPerRoles?: Maybe<GoalCountPerRolesOutput>;
  /** Get List Of Kpis */
  getKpiList?: Maybe<GetKpiListPerRoleOutput>;
  /** Get Performance Review Detail */
  getPerformanceReviewDetailInfo?: Maybe<PerfReviewOutput>;
  /** Get Score Sheet */
  getScoreSheet?: Maybe<GetScoreSheetOutput>;
  /** Get Skill Resource Filter */
  getSkillResourceFilter?: Maybe<SkillResourceFilterOutput>;
  /** Get TimePeriod List */
  getTimePeriodList?: Maybe<TimePeriodListOutput>;
  /** two x two matrix */
  getTwoXTwoMatrix?: Maybe<TwoXTwoMatrixOutput>;
  /** Get User List For Approval */
  getUserListForSkillApproval?: Maybe<GetUSerListForSkillApprovalOutput>;
  /** Get User Skills Dashboard */
  getUserSkillInDashboard?: Maybe<GetUserSkillDashboardOutput>;
  /** Get Users Skill Detail */
  getUsersSkillDetail?: Maybe<GetUserSkillInfoOutput>;
  /** Get Latest Assessment Record Marks */
  get_LatestAssessmentMarks?: Maybe<GetLatestAssessmentRecordMarksOutput>;
  goalDetailById?: Maybe<GoalDetailsOutput>;
  googledriveAuthentication?: Maybe<GoogledriveAuthenticationOutput>;
  googledriveLogOut?: Maybe<GoogledriveLogoutOutput>;
  groupByFilterTasks?: Maybe<GroupByFilterOutput>;
  /** loadTimeSpentIndividual */
  individualTimeSpentGraph?: Maybe<IndividualTimeSpentGraphOutput>;
  /** insert data into the table: "User_Peer_Review" */
  insert_User_Peer_Review?: Maybe<User_Peer_Review_Mutation_Response>;
  /** insert a single row into the table: "User_Peer_Review" */
  insert_User_Peer_Review_one?: Maybe<User_Peer_Review>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "assessment" */
  insert_assessment?: Maybe<Assessment_Mutation_Response>;
  /** insert data into the table: "assessment_Skills" */
  insert_assessment_Skills?: Maybe<Assessment_Skills_Mutation_Response>;
  /** insert a single row into the table: "assessment_Skills" */
  insert_assessment_Skills_one?: Maybe<Assessment_Skills>;
  /** insert data into the table: "assessment_feedback" */
  insert_assessment_feedback?: Maybe<Assessment_Feedback_Mutation_Response>;
  /** insert a single row into the table: "assessment_feedback" */
  insert_assessment_feedback_one?: Maybe<Assessment_Feedback>;
  /** insert a single row into the table: "assessment" */
  insert_assessment_one?: Maybe<Assessment>;
  /** insert data into the table: "assessment_record" */
  insert_assessment_record?: Maybe<Assessment_Record_Mutation_Response>;
  /** insert a single row into the table: "assessment_record" */
  insert_assessment_record_one?: Maybe<Assessment_Record>;
  /** insert data into the table: "assessment_role_details" */
  insert_assessment_role_details?: Maybe<Assessment_Role_Details_Mutation_Response>;
  /** insert a single row into the table: "assessment_role_details" */
  insert_assessment_role_details_one?: Maybe<Assessment_Role_Details>;
  /** insert data into the table: "assessment_stage_career" */
  insert_assessment_stage_career?: Maybe<Assessment_Stage_Career_Mutation_Response>;
  /** insert a single row into the table: "assessment_stage_career" */
  insert_assessment_stage_career_one?: Maybe<Assessment_Stage_Career>;
  /** insert data into the table: "assessment_type" */
  insert_assessment_type?: Maybe<Assessment_Type_Mutation_Response>;
  /** insert a single row into the table: "assessment_type" */
  insert_assessment_type_one?: Maybe<Assessment_Type>;
  /** insert data into the table: "assign_career_development_plan" */
  insert_assign_career_development_plan?: Maybe<Assign_Career_Development_Plan_Mutation_Response>;
  /** insert a single row into the table: "assign_career_development_plan" */
  insert_assign_career_development_plan_one?: Maybe<Assign_Career_Development_Plan>;
  /** insert data into the table: "audit.user_state" */
  insert_audit_user_state?: Maybe<Audit_User_State_Mutation_Response>;
  /** insert a single row into the table: "audit.user_state" */
  insert_audit_user_state_one?: Maybe<Audit_User_State>;
  /** insert data into the table: "auth.account_providers" */
  insert_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.account_providers" */
  insert_auth_account_providers_one?: Maybe<Auth_Account_Providers>;
  /** insert data into the table: "auth.account_roles" */
  insert_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.account_roles" */
  insert_auth_account_roles_one?: Maybe<Auth_Account_Roles>;
  /** insert data into the table: "auth.accounts" */
  insert_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** insert a single row into the table: "auth.accounts" */
  insert_auth_accounts_one?: Maybe<Auth_Accounts>;
  /** insert data into the table: "auth.providers" */
  insert_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.providers" */
  insert_auth_providers_one?: Maybe<Auth_Providers>;
  /** insert data into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens_one?: Maybe<Auth_Refresh_Tokens>;
  /** insert data into the table: "auth.roles" */
  insert_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.roles" */
  insert_auth_roles_one?: Maybe<Auth_Roles>;
  /** insert data into the table: "award_posts" */
  insert_award_posts?: Maybe<Award_Posts_Mutation_Response>;
  /** insert a single row into the table: "award_posts" */
  insert_award_posts_one?: Maybe<Award_Posts>;
  /** insert data into the table: "awards" */
  insert_awards?: Maybe<Awards_Mutation_Response>;
  /** insert a single row into the table: "awards" */
  insert_awards_one?: Maybe<Awards>;
  /** insert data into the table: "calendar" */
  insert_calendar?: Maybe<Calendar_Mutation_Response>;
  /** insert data into the table: "calendar_attendee" */
  insert_calendar_attendee?: Maybe<Calendar_Attendee_Mutation_Response>;
  /** insert a single row into the table: "calendar_attendee" */
  insert_calendar_attendee_one?: Maybe<Calendar_Attendee>;
  /** insert data into the table: "calendar_delete_events" */
  insert_calendar_delete_events?: Maybe<Calendar_Delete_Events_Mutation_Response>;
  /** insert a single row into the table: "calendar_delete_events" */
  insert_calendar_delete_events_one?: Maybe<Calendar_Delete_Events>;
  /** insert data into the table: "calendar_event" */
  insert_calendar_event?: Maybe<Calendar_Event_Mutation_Response>;
  /** insert a single row into the table: "calendar_event" */
  insert_calendar_event_one?: Maybe<Calendar_Event>;
  /** insert data into the table: "calendar_meeting_call" */
  insert_calendar_meeting_call?: Maybe<Calendar_Meeting_Call_Mutation_Response>;
  /** insert a single row into the table: "calendar_meeting_call" */
  insert_calendar_meeting_call_one?: Maybe<Calendar_Meeting_Call>;
  /** insert data into the table: "calendar_meeting_call_request" */
  insert_calendar_meeting_call_request?: Maybe<Calendar_Meeting_Call_Request_Mutation_Response>;
  /** insert a single row into the table: "calendar_meeting_call_request" */
  insert_calendar_meeting_call_request_one?: Maybe<Calendar_Meeting_Call_Request>;
  /** insert data into the table: "calendar_meeting_call_user" */
  insert_calendar_meeting_call_user?: Maybe<Calendar_Meeting_Call_User_Mutation_Response>;
  /** insert a single row into the table: "calendar_meeting_call_user" */
  insert_calendar_meeting_call_user_one?: Maybe<Calendar_Meeting_Call_User>;
  /** insert a single row into the table: "calendar" */
  insert_calendar_one?: Maybe<Calendar>;
  /** insert data into the table: "call" */
  insert_call?: Maybe<Call_Mutation_Response>;
  /** insert a single row into the table: "call" */
  insert_call_one?: Maybe<Call>;
  /** insert data into the table: "call_request" */
  insert_call_request?: Maybe<Call_Request_Mutation_Response>;
  /** insert a single row into the table: "call_request" */
  insert_call_request_one?: Maybe<Call_Request>;
  /** insert data into the table: "call_user" */
  insert_call_user?: Maybe<Call_User_Mutation_Response>;
  /** insert a single row into the table: "call_user" */
  insert_call_user_one?: Maybe<Call_User>;
  /** insert data into the table: "career_development_detail" */
  insert_career_development_detail?: Maybe<Career_Development_Detail_Mutation_Response>;
  /** insert a single row into the table: "career_development_detail" */
  insert_career_development_detail_one?: Maybe<Career_Development_Detail>;
  /** insert data into the table: "career_development_role_details" */
  insert_career_development_role_details?: Maybe<Career_Development_Role_Details_Mutation_Response>;
  /** insert a single row into the table: "career_development_role_details" */
  insert_career_development_role_details_one?: Maybe<Career_Development_Role_Details>;
  /** insert data into the table: "career_external_certification" */
  insert_career_external_certification?: Maybe<Career_External_Certification_Mutation_Response>;
  /** insert a single row into the table: "career_external_certification" */
  insert_career_external_certification_one?: Maybe<Career_External_Certification>;
  /** insert data into the table: "chat_message" */
  insert_chat_message?: Maybe<Chat_Message_Mutation_Response>;
  /** insert data into the table: "chat_message_action" */
  insert_chat_message_action?: Maybe<Chat_Message_Action_Mutation_Response>;
  /** insert a single row into the table: "chat_message_action" */
  insert_chat_message_action_one?: Maybe<Chat_Message_Action>;
  /** insert data into the table: "chat_message_file" */
  insert_chat_message_file?: Maybe<Chat_Message_File_Mutation_Response>;
  /** insert a single row into the table: "chat_message_file" */
  insert_chat_message_file_one?: Maybe<Chat_Message_File>;
  /** insert a single row into the table: "chat_message" */
  insert_chat_message_one?: Maybe<Chat_Message>;
  /** insert data into the table: "chat_message_reactions" */
  insert_chat_message_reactions?: Maybe<Chat_Message_Reactions_Mutation_Response>;
  /** insert a single row into the table: "chat_message_reactions" */
  insert_chat_message_reactions_one?: Maybe<Chat_Message_Reactions>;
  /** insert data into the table: "chat_pinned" */
  insert_chat_pinned?: Maybe<Chat_Pinned_Mutation_Response>;
  /** insert a single row into the table: "chat_pinned" */
  insert_chat_pinned_one?: Maybe<Chat_Pinned>;
  /** insert data into the table: "chat_room" */
  insert_chat_room?: Maybe<Chat_Room_Mutation_Response>;
  /** insert a single row into the table: "chat_room" */
  insert_chat_room_one?: Maybe<Chat_Room>;
  /** insert data into the table: "chat_room_user" */
  insert_chat_room_user?: Maybe<Chat_Room_User_Mutation_Response>;
  /** insert a single row into the table: "chat_room_user" */
  insert_chat_room_user_one?: Maybe<Chat_Room_User>;
  /** insert data into the table: "dashboard_remainders" */
  insert_dashboard_remainders?: Maybe<Dashboard_Remainders_Mutation_Response>;
  /** insert a single row into the table: "dashboard_remainders" */
  insert_dashboard_remainders_one?: Maybe<Dashboard_Remainders>;
  /** insert data into the table: "department" */
  insert_department?: Maybe<Department_Mutation_Response>;
  /** insert data into the table: "department_employee" */
  insert_department_employee?: Maybe<Department_Employee_Mutation_Response>;
  /** insert a single row into the table: "department_employee" */
  insert_department_employee_one?: Maybe<Department_Employee>;
  /** insert a single row into the table: "department" */
  insert_department_one?: Maybe<Department>;
  /** insert data into the table: "document_token" */
  insert_document_token?: Maybe<Document_Token_Mutation_Response>;
  /** insert a single row into the table: "document_token" */
  insert_document_token_one?: Maybe<Document_Token>;
  /** insert data into the table: "email_templates" */
  insert_email_templates?: Maybe<Email_Templates_Mutation_Response>;
  /** insert a single row into the table: "email_templates" */
  insert_email_templates_one?: Maybe<Email_Templates>;
  /** insert data into the table: "employee_bandwidth_data" */
  insert_employee_bandwidth_data?: Maybe<Employee_Bandwidth_Data_Mutation_Response>;
  /** insert a single row into the table: "employee_bandwidth_data" */
  insert_employee_bandwidth_data_one?: Maybe<Employee_Bandwidth_Data>;
  /** insert data into the table: "employee_capacity" */
  insert_employee_capacity?: Maybe<Employee_Capacity_Mutation_Response>;
  /** insert a single row into the table: "employee_capacity" */
  insert_employee_capacity_one?: Maybe<Employee_Capacity>;
  /** insert data into the table: "enum_activity_state" */
  insert_enum_activity_state?: Maybe<Enum_Activity_State_Mutation_Response>;
  /** insert a single row into the table: "enum_activity_state" */
  insert_enum_activity_state_one?: Maybe<Enum_Activity_State>;
  /** insert data into the table: "enum_activity_type" */
  insert_enum_activity_type?: Maybe<Enum_Activity_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_activity_type" */
  insert_enum_activity_type_one?: Maybe<Enum_Activity_Type>;
  /** insert data into the table: "enum_attendee_status_type" */
  insert_enum_attendee_status_type?: Maybe<Enum_Attendee_Status_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_attendee_status_type" */
  insert_enum_attendee_status_type_one?: Maybe<Enum_Attendee_Status_Type>;
  /** insert data into the table: "enum_attendee_type" */
  insert_enum_attendee_type?: Maybe<Enum_Attendee_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_attendee_type" */
  insert_enum_attendee_type_one?: Maybe<Enum_Attendee_Type>;
  /** insert data into the table: "enum_award_cycle_type" */
  insert_enum_award_cycle_type?: Maybe<Enum_Award_Cycle_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_award_cycle_type" */
  insert_enum_award_cycle_type_one?: Maybe<Enum_Award_Cycle_Type>;
  /** insert data into the table: "enum_call_access_policy" */
  insert_enum_call_access_policy?: Maybe<Enum_Call_Access_Policy_Mutation_Response>;
  /** insert a single row into the table: "enum_call_access_policy" */
  insert_enum_call_access_policy_one?: Maybe<Enum_Call_Access_Policy>;
  /** insert data into the table: "enum_call_broadcasting_type" */
  insert_enum_call_broadcasting_type?: Maybe<Enum_Call_Broadcasting_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_call_broadcasting_type" */
  insert_enum_call_broadcasting_type_one?: Maybe<Enum_Call_Broadcasting_Type>;
  /** insert data into the table: "enum_call_media_type" */
  insert_enum_call_media_type?: Maybe<Enum_Call_Media_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_call_media_type" */
  insert_enum_call_media_type_one?: Maybe<Enum_Call_Media_Type>;
  /** insert data into the table: "enum_call_recording_status" */
  insert_enum_call_recording_status?: Maybe<Enum_Call_Recording_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_call_recording_status" */
  insert_enum_call_recording_status_one?: Maybe<Enum_Call_Recording_Status>;
  /** insert data into the table: "enum_call_request_response_type" */
  insert_enum_call_request_response_type?: Maybe<Enum_Call_Request_Response_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_call_request_response_type" */
  insert_enum_call_request_response_type_one?: Maybe<Enum_Call_Request_Response_Type>;
  /** insert data into the table: "enum_call_request_status_type" */
  insert_enum_call_request_status_type?: Maybe<Enum_Call_Request_Status_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_call_request_status_type" */
  insert_enum_call_request_status_type_one?: Maybe<Enum_Call_Request_Status_Type>;
  /** insert data into the table: "enum_call_request_type" */
  insert_enum_call_request_type?: Maybe<Enum_Call_Request_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_call_request_type" */
  insert_enum_call_request_type_one?: Maybe<Enum_Call_Request_Type>;
  /** insert data into the table: "enum_call_status_type" */
  insert_enum_call_status_type?: Maybe<Enum_Call_Status_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_call_status_type" */
  insert_enum_call_status_type_one?: Maybe<Enum_Call_Status_Type>;
  /** insert data into the table: "enum_call_type" */
  insert_enum_call_type?: Maybe<Enum_Call_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_call_type" */
  insert_enum_call_type_one?: Maybe<Enum_Call_Type>;
  /** insert data into the table: "enum_call_user_status" */
  insert_enum_call_user_status?: Maybe<Enum_Call_User_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_call_user_status" */
  insert_enum_call_user_status_one?: Maybe<Enum_Call_User_Status>;
  /** insert data into the table: "enum_chat_message_type" */
  insert_enum_chat_message_type?: Maybe<Enum_Chat_Message_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_chat_message_type" */
  insert_enum_chat_message_type_one?: Maybe<Enum_Chat_Message_Type>;
  /** insert data into the table: "enum_chat_notification" */
  insert_enum_chat_notification?: Maybe<Enum_Chat_Notification_Mutation_Response>;
  /** insert a single row into the table: "enum_chat_notification" */
  insert_enum_chat_notification_one?: Maybe<Enum_Chat_Notification>;
  /** insert data into the table: "enum_chat_room_notification_status" */
  insert_enum_chat_room_notification_status?: Maybe<Enum_Chat_Room_Notification_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_chat_room_notification_status" */
  insert_enum_chat_room_notification_status_one?: Maybe<Enum_Chat_Room_Notification_Status>;
  /** insert data into the table: "enum_chat_room_type" */
  insert_enum_chat_room_type?: Maybe<Enum_Chat_Room_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_chat_room_type" */
  insert_enum_chat_room_type_one?: Maybe<Enum_Chat_Room_Type>;
  /** insert data into the table: "enum_chat_room_user_status" */
  insert_enum_chat_room_user_status?: Maybe<Enum_Chat_Room_User_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_chat_room_user_status" */
  insert_enum_chat_room_user_status_one?: Maybe<Enum_Chat_Room_User_Status>;
  /** insert data into the table: "enum_dashboard_remainder_type" */
  insert_enum_dashboard_remainder_type?: Maybe<Enum_Dashboard_Remainder_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_dashboard_remainder_type" */
  insert_enum_dashboard_remainder_type_one?: Maybe<Enum_Dashboard_Remainder_Type>;
  /** insert data into the table: "enum_document_token_type" */
  insert_enum_document_token_type?: Maybe<Enum_Document_Token_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_document_token_type" */
  insert_enum_document_token_type_one?: Maybe<Enum_Document_Token_Type>;
  /** insert data into the table: "enum_event_type" */
  insert_enum_event_type?: Maybe<Enum_Event_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_event_type" */
  insert_enum_event_type_one?: Maybe<Enum_Event_Type>;
  /** insert data into the table: "enum_leave_donate_type" */
  insert_enum_leave_donate_type?: Maybe<Enum_Leave_Donate_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_leave_donate_type" */
  insert_enum_leave_donate_type_one?: Maybe<Enum_Leave_Donate_Type>;
  /** insert data into the table: "enum_leave_status_type" */
  insert_enum_leave_status_type?: Maybe<Enum_Leave_Status_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_leave_status_type" */
  insert_enum_leave_status_type_one?: Maybe<Enum_Leave_Status_Type>;
  /** insert data into the table: "enum_live_call_request_status" */
  insert_enum_live_call_request_status?: Maybe<Enum_Live_Call_Request_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_live_call_request_status" */
  insert_enum_live_call_request_status_one?: Maybe<Enum_Live_Call_Request_Status>;
  /** insert data into the table: "enum_live_call_status" */
  insert_enum_live_call_status?: Maybe<Enum_Live_Call_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_live_call_status" */
  insert_enum_live_call_status_one?: Maybe<Enum_Live_Call_Status>;
  /** insert data into the table: "enum_live_call_user_status" */
  insert_enum_live_call_user_status?: Maybe<Enum_Live_Call_User_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_live_call_user_status" */
  insert_enum_live_call_user_status_one?: Maybe<Enum_Live_Call_User_Status>;
  /** insert data into the table: "enum_live_emote_type" */
  insert_enum_live_emote_type?: Maybe<Enum_Live_Emote_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_live_emote_type" */
  insert_enum_live_emote_type_one?: Maybe<Enum_Live_Emote_Type>;
  /** insert data into the table: "enum_manual_state" */
  insert_enum_manual_state?: Maybe<Enum_Manual_State_Mutation_Response>;
  /** insert a single row into the table: "enum_manual_state" */
  insert_enum_manual_state_one?: Maybe<Enum_Manual_State>;
  /** insert data into the table: "enum_meeting_room_status" */
  insert_enum_meeting_room_status?: Maybe<Enum_Meeting_Room_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_meeting_room_status" */
  insert_enum_meeting_room_status_one?: Maybe<Enum_Meeting_Room_Status>;
  /** insert data into the table: "enum_meeting_room_type" */
  insert_enum_meeting_room_type?: Maybe<Enum_Meeting_Room_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_meeting_room_type" */
  insert_enum_meeting_room_type_one?: Maybe<Enum_Meeting_Room_Type>;
  /** insert data into the table: "enum_meeting_room_user_state" */
  insert_enum_meeting_room_user_state?: Maybe<Enum_Meeting_Room_User_State_Mutation_Response>;
  /** insert a single row into the table: "enum_meeting_room_user_state" */
  insert_enum_meeting_room_user_state_one?: Maybe<Enum_Meeting_Room_User_State>;
  /** insert data into the table: "enum_notification_type" */
  insert_enum_notification_type?: Maybe<Enum_Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_notification_type" */
  insert_enum_notification_type_one?: Maybe<Enum_Notification_Type>;
  /** insert data into the table: "enum_personalised_access" */
  insert_enum_personalised_access?: Maybe<Enum_Personalised_Access_Mutation_Response>;
  /** insert a single row into the table: "enum_personalised_access" */
  insert_enum_personalised_access_one?: Maybe<Enum_Personalised_Access>;
  /** insert data into the table: "enum_personalised_categories" */
  insert_enum_personalised_categories?: Maybe<Enum_Personalised_Categories_Mutation_Response>;
  /** insert a single row into the table: "enum_personalised_categories" */
  insert_enum_personalised_categories_one?: Maybe<Enum_Personalised_Categories>;
  /** insert data into the table: "enum_post_type" */
  insert_enum_post_type?: Maybe<Enum_Post_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_post_type" */
  insert_enum_post_type_one?: Maybe<Enum_Post_Type>;
  /** insert data into the table: "enum_presence_connection_status" */
  insert_enum_presence_connection_status?: Maybe<Enum_Presence_Connection_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_presence_connection_status" */
  insert_enum_presence_connection_status_one?: Maybe<Enum_Presence_Connection_Status>;
  /** insert data into the table: "enum_project_milestone_status" */
  insert_enum_project_milestone_status?: Maybe<Enum_Project_Milestone_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_project_milestone_status" */
  insert_enum_project_milestone_status_one?: Maybe<Enum_Project_Milestone_Status>;
  /** insert data into the table: "enum_project_sprint_category" */
  insert_enum_project_sprint_category?: Maybe<Enum_Project_Sprint_Category_Mutation_Response>;
  /** insert a single row into the table: "enum_project_sprint_category" */
  insert_enum_project_sprint_category_one?: Maybe<Enum_Project_Sprint_Category>;
  /** insert data into the table: "enum_project_status" */
  insert_enum_project_status?: Maybe<Enum_Project_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_project_status" */
  insert_enum_project_status_one?: Maybe<Enum_Project_Status>;
  /** insert data into the table: "enum_project_task_category" */
  insert_enum_project_task_category?: Maybe<Enum_Project_Task_Category_Mutation_Response>;
  /** insert a single row into the table: "enum_project_task_category" */
  insert_enum_project_task_category_one?: Maybe<Enum_Project_Task_Category>;
  /** insert data into the table: "enum_setting_type" */
  insert_enum_setting_type?: Maybe<Enum_Setting_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_setting_type" */
  insert_enum_setting_type_one?: Maybe<Enum_Setting_Type>;
  /** insert data into the table: "enum_storage_file_type" */
  insert_enum_storage_file_type?: Maybe<Enum_Storage_File_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_storage_file_type" */
  insert_enum_storage_file_type_one?: Maybe<Enum_Storage_File_Type>;
  /** insert data into the table: "enum_system_state" */
  insert_enum_system_state?: Maybe<Enum_System_State_Mutation_Response>;
  /** insert a single row into the table: "enum_system_state" */
  insert_enum_system_state_one?: Maybe<Enum_System_State>;
  /** insert data into the table: "enum_system_state_owner" */
  insert_enum_system_state_owner?: Maybe<Enum_System_State_Owner_Mutation_Response>;
  /** insert a single row into the table: "enum_system_state_owner" */
  insert_enum_system_state_owner_one?: Maybe<Enum_System_State_Owner>;
  /** insert data into the table: "enum_task_priority" */
  insert_enum_task_priority?: Maybe<Enum_Task_Priority_Mutation_Response>;
  /** insert a single row into the table: "enum_task_priority" */
  insert_enum_task_priority_one?: Maybe<Enum_Task_Priority>;
  /** insert data into the table: "enum_task_recurring_frequency" */
  insert_enum_task_recurring_frequency?: Maybe<Enum_Task_Recurring_Frequency_Mutation_Response>;
  /** insert a single row into the table: "enum_task_recurring_frequency" */
  insert_enum_task_recurring_frequency_one?: Maybe<Enum_Task_Recurring_Frequency>;
  /** insert data into the table: "enum_task_status" */
  insert_enum_task_status?: Maybe<Enum_Task_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_task_status" */
  insert_enum_task_status_one?: Maybe<Enum_Task_Status>;
  /** insert data into the table: "enum_task_time_sheet_status" */
  insert_enum_task_time_sheet_status?: Maybe<Enum_Task_Time_Sheet_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_task_time_sheet_status" */
  insert_enum_task_time_sheet_status_one?: Maybe<Enum_Task_Time_Sheet_Status>;
  /** insert data into the table: "enum_user_activity_tracker_status_type" */
  insert_enum_user_activity_tracker_status_type?: Maybe<Enum_User_Activity_Tracker_Status_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_user_activity_tracker_status_type" */
  insert_enum_user_activity_tracker_status_type_one?: Maybe<Enum_User_Activity_Tracker_Status_Type>;
  /** insert data into the table: "enum_user_avatar_types" */
  insert_enum_user_avatar_types?: Maybe<Enum_User_Avatar_Types_Mutation_Response>;
  /** insert a single row into the table: "enum_user_avatar_types" */
  insert_enum_user_avatar_types_one?: Maybe<Enum_User_Avatar_Types>;
  /** insert data into the table: "enum_user_gender" */
  insert_enum_user_gender?: Maybe<Enum_User_Gender_Mutation_Response>;
  /** insert a single row into the table: "enum_user_gender" */
  insert_enum_user_gender_one?: Maybe<Enum_User_Gender>;
  /** insert data into the table: "enum_user_presence_provider_type" */
  insert_enum_user_presence_provider_type?: Maybe<Enum_User_Presence_Provider_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_user_presence_provider_type" */
  insert_enum_user_presence_provider_type_one?: Maybe<Enum_User_Presence_Provider_Type>;
  /** insert data into the table: "enum_user_settings_type" */
  insert_enum_user_settings_type?: Maybe<Enum_User_Settings_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_user_settings_type" */
  insert_enum_user_settings_type_one?: Maybe<Enum_User_Settings_Type>;
  /** insert data into the table: "filter_definition" */
  insert_filter_definition?: Maybe<Filter_Definition_Mutation_Response>;
  /** insert a single row into the table: "filter_definition" */
  insert_filter_definition_one?: Maybe<Filter_Definition>;
  /** insert data into the table: "financialYear" */
  insert_financialYear?: Maybe<FinancialYear_Mutation_Response>;
  /** insert a single row into the table: "financialYear" */
  insert_financialYear_one?: Maybe<FinancialYear>;
  /** insert data into the table: "frequency" */
  insert_frequency?: Maybe<Frequency_Mutation_Response>;
  /** insert a single row into the table: "frequency" */
  insert_frequency_one?: Maybe<Frequency>;
  /** insert data into the table: "goalWithKpi" */
  insert_goalWithKpi?: Maybe<GoalWithKpi_Mutation_Response>;
  /** insert a single row into the table: "goalWithKpi" */
  insert_goalWithKpi_one?: Maybe<GoalWithKpi>;
  /** insert data into the table: "goalWithTimePeriod" */
  insert_goalWithTimePeriod?: Maybe<GoalWithTimePeriod_Mutation_Response>;
  /** insert a single row into the table: "goalWithTimePeriod" */
  insert_goalWithTimePeriod_one?: Maybe<GoalWithTimePeriod>;
  /** insert data into the table: "goals" */
  insert_goals?: Maybe<Goals_Mutation_Response>;
  /** insert data into the table: "goalsWithDepts" */
  insert_goalsWithDepts?: Maybe<GoalsWithDepts_Mutation_Response>;
  /** insert a single row into the table: "goalsWithDepts" */
  insert_goalsWithDepts_one?: Maybe<GoalsWithDepts>;
  /** insert data into the table: "goalsWithFreqs" */
  insert_goalsWithFreqs?: Maybe<GoalsWithFreqs_Mutation_Response>;
  /** insert a single row into the table: "goalsWithFreqs" */
  insert_goalsWithFreqs_one?: Maybe<GoalsWithFreqs>;
  /** insert data into the table: "goalsWithRoles" */
  insert_goalsWithRoles?: Maybe<GoalsWithRoles_Mutation_Response>;
  /** insert a single row into the table: "goalsWithRoles" */
  insert_goalsWithRoles_one?: Maybe<GoalsWithRoles>;
  /** insert a single row into the table: "goals" */
  insert_goals_one?: Maybe<Goals>;
  /** insert data into the table: "hotdesk_bookings" */
  insert_hotdesk_bookings?: Maybe<Hotdesk_Bookings_Mutation_Response>;
  /** insert a single row into the table: "hotdesk_bookings" */
  insert_hotdesk_bookings_one?: Maybe<Hotdesk_Bookings>;
  /** insert data into the table: "kpiWithDept" */
  insert_kpiWithDept?: Maybe<KpiWithDept_Mutation_Response>;
  /** insert a single row into the table: "kpiWithDept" */
  insert_kpiWithDept_one?: Maybe<KpiWithDept>;
  /** insert data into the table: "kpiWithRole" */
  insert_kpiWithRole?: Maybe<KpiWithRole_Mutation_Response>;
  /** insert a single row into the table: "kpiWithRole" */
  insert_kpiWithRole_one?: Maybe<KpiWithRole>;
  /** insert data into the table: "kpi_bank" */
  insert_kpi_bank?: Maybe<Kpi_Bank_Mutation_Response>;
  /** insert a single row into the table: "kpi_bank" */
  insert_kpi_bank_one?: Maybe<Kpi_Bank>;
  /** insert data into the table: "leave" */
  insert_leave?: Maybe<Leave_Mutation_Response>;
  /** insert data into the table: "leave_donate" */
  insert_leave_donate?: Maybe<Leave_Donate_Mutation_Response>;
  /** insert a single row into the table: "leave_donate" */
  insert_leave_donate_one?: Maybe<Leave_Donate>;
  /** insert a single row into the table: "leave" */
  insert_leave_one?: Maybe<Leave>;
  /** insert data into the table: "leave_organization" */
  insert_leave_organization?: Maybe<Leave_Organization_Mutation_Response>;
  /** insert a single row into the table: "leave_organization" */
  insert_leave_organization_one?: Maybe<Leave_Organization>;
  /** insert data into the table: "list_Of_Skills" */
  insert_list_Of_Skills?: Maybe<List_Of_Skills_Mutation_Response>;
  /** insert a single row into the table: "list_Of_Skills" */
  insert_list_Of_Skills_one?: Maybe<List_Of_Skills>;
  /** insert data into the table: "live_call" */
  insert_live_call?: Maybe<Live_Call_Mutation_Response>;
  /** insert a single row into the table: "live_call" */
  insert_live_call_one?: Maybe<Live_Call>;
  /** insert data into the table: "live_call_request" */
  insert_live_call_request?: Maybe<Live_Call_Request_Mutation_Response>;
  /** insert a single row into the table: "live_call_request" */
  insert_live_call_request_one?: Maybe<Live_Call_Request>;
  /** insert data into the table: "live_call_user" */
  insert_live_call_user?: Maybe<Live_Call_User_Mutation_Response>;
  /** insert a single row into the table: "live_call_user" */
  insert_live_call_user_one?: Maybe<Live_Call_User>;
  /** insert data into the table: "main_skills" */
  insert_main_skills?: Maybe<Main_Skills_Mutation_Response>;
  /** insert a single row into the table: "main_skills" */
  insert_main_skills_one?: Maybe<Main_Skills>;
  /** insert data into the table: "meeting_call_schedular" */
  insert_meeting_call_schedular?: Maybe<Meeting_Call_Schedular_Mutation_Response>;
  /** insert a single row into the table: "meeting_call_schedular" */
  insert_meeting_call_schedular_one?: Maybe<Meeting_Call_Schedular>;
  /** insert data into the table: "meeting_room" */
  insert_meeting_room?: Maybe<Meeting_Room_Mutation_Response>;
  /** insert a single row into the table: "meeting_room" */
  insert_meeting_room_one?: Maybe<Meeting_Room>;
  /** insert data into the table: "meeting_room_users" */
  insert_meeting_room_users?: Maybe<Meeting_Room_Users_Mutation_Response>;
  /** insert a single row into the table: "meeting_room_users" */
  insert_meeting_room_users_one?: Maybe<Meeting_Room_Users>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "organization" */
  insert_organization?: Maybe<Organization_Mutation_Response>;
  /** insert data into the table: "organization_active_leave_types" */
  insert_organization_active_leave_types?: Maybe<Organization_Active_Leave_Types_Mutation_Response>;
  /** insert a single row into the table: "organization_active_leave_types" */
  insert_organization_active_leave_types_one?: Maybe<Organization_Active_Leave_Types>;
  /** insert data into the table: "organization_admin" */
  insert_organization_admin?: Maybe<Organization_Admin_Mutation_Response>;
  /** insert a single row into the table: "organization_admin" */
  insert_organization_admin_one?: Maybe<Organization_Admin>;
  /** insert data into the table: "organization_awards" */
  insert_organization_awards?: Maybe<Organization_Awards_Mutation_Response>;
  /** insert a single row into the table: "organization_awards" */
  insert_organization_awards_one?: Maybe<Organization_Awards>;
  /** insert data into the table: "organization_employee" */
  insert_organization_employee?: Maybe<Organization_Employee_Mutation_Response>;
  /** insert a single row into the table: "organization_employee" */
  insert_organization_employee_one?: Maybe<Organization_Employee>;
  /** insert data into the table: "organization_holidays" */
  insert_organization_holidays?: Maybe<Organization_Holidays_Mutation_Response>;
  /** insert a single row into the table: "organization_holidays" */
  insert_organization_holidays_one?: Maybe<Organization_Holidays>;
  /** insert data into the table: "organization_leave_cycles" */
  insert_organization_leave_cycles?: Maybe<Organization_Leave_Cycles_Mutation_Response>;
  /** insert a single row into the table: "organization_leave_cycles" */
  insert_organization_leave_cycles_one?: Maybe<Organization_Leave_Cycles>;
  /** insert data into the table: "organization_locations" */
  insert_organization_locations?: Maybe<Organization_Locations_Mutation_Response>;
  /** insert a single row into the table: "organization_locations" */
  insert_organization_locations_one?: Maybe<Organization_Locations>;
  /** insert a single row into the table: "organization" */
  insert_organization_one?: Maybe<Organization>;
  /** insert data into the table: "organization_roles" */
  insert_organization_roles?: Maybe<Organization_Roles_Mutation_Response>;
  /** insert a single row into the table: "organization_roles" */
  insert_organization_roles_one?: Maybe<Organization_Roles>;
  /** insert data into the table: "organization_user_leave_application" */
  insert_organization_user_leave_application?: Maybe<Organization_User_Leave_Application_Mutation_Response>;
  /** insert a single row into the table: "organization_user_leave_application" */
  insert_organization_user_leave_application_one?: Maybe<Organization_User_Leave_Application>;
  /** insert data into the table: "organization_user_leave_data" */
  insert_organization_user_leave_data?: Maybe<Organization_User_Leave_Data_Mutation_Response>;
  /** insert a single row into the table: "organization_user_leave_data" */
  insert_organization_user_leave_data_one?: Maybe<Organization_User_Leave_Data>;
  /** insert data into the table: "organization_user_leave_dates" */
  insert_organization_user_leave_dates?: Maybe<Organization_User_Leave_Dates_Mutation_Response>;
  /** insert a single row into the table: "organization_user_leave_dates" */
  insert_organization_user_leave_dates_one?: Maybe<Organization_User_Leave_Dates>;
  /** insert data into the table: "organization_user_leave_files" */
  insert_organization_user_leave_files?: Maybe<Organization_User_Leave_Files_Mutation_Response>;
  /** insert a single row into the table: "organization_user_leave_files" */
  insert_organization_user_leave_files_one?: Maybe<Organization_User_Leave_Files>;
  /** insert data into the table: "performanceReviewStatus" */
  insert_performanceReviewStatus?: Maybe<PerformanceReviewStatus_Mutation_Response>;
  /** insert a single row into the table: "performanceReviewStatus" */
  insert_performanceReviewStatus_one?: Maybe<PerformanceReviewStatus>;
  /** insert data into the table: "performance_rating_scale" */
  insert_performance_rating_scale?: Maybe<Performance_Rating_Scale_Mutation_Response>;
  /** insert a single row into the table: "performance_rating_scale" */
  insert_performance_rating_scale_one?: Maybe<Performance_Rating_Scale>;
  /** insert data into the table: "performance_review_byUser" */
  insert_performance_review_byUser?: Maybe<Performance_Review_ByUser_Mutation_Response>;
  /** insert data into the table: "performance_review_byUser_Kpi" */
  insert_performance_review_byUser_Kpi?: Maybe<Performance_Review_ByUser_Kpi_Mutation_Response>;
  /** insert a single row into the table: "performance_review_byUser_Kpi" */
  insert_performance_review_byUser_Kpi_one?: Maybe<Performance_Review_ByUser_Kpi>;
  /** insert data into the table: "performance_review_byUser_WithGoal" */
  insert_performance_review_byUser_WithGoal?: Maybe<Performance_Review_ByUser_WithGoal_Mutation_Response>;
  /** insert a single row into the table: "performance_review_byUser_WithGoal" */
  insert_performance_review_byUser_WithGoal_one?: Maybe<Performance_Review_ByUser_WithGoal>;
  /** insert a single row into the table: "performance_review_byUser" */
  insert_performance_review_byUser_one?: Maybe<Performance_Review_ByUser>;
  /** insert data into the table: "performance_review_setting_GoalsList" */
  insert_performance_review_setting_GoalsList?: Maybe<Performance_Review_Setting_GoalsList_Mutation_Response>;
  /** insert a single row into the table: "performance_review_setting_GoalsList" */
  insert_performance_review_setting_GoalsList_one?: Maybe<Performance_Review_Setting_GoalsList>;
  /** insert data into the table: "performance_review_setting_With_Goals" */
  insert_performance_review_setting_With_Goals?: Maybe<Performance_Review_Setting_With_Goals_Mutation_Response>;
  /** insert a single row into the table: "performance_review_setting_With_Goals" */
  insert_performance_review_setting_With_Goals_one?: Maybe<Performance_Review_Setting_With_Goals>;
  /** insert data into the table: "performance_review_settings" */
  insert_performance_review_settings?: Maybe<Performance_Review_Settings_Mutation_Response>;
  /** insert a single row into the table: "performance_review_settings" */
  insert_performance_review_settings_one?: Maybe<Performance_Review_Settings>;
  /** insert data into the table: "personalised" */
  insert_personalised?: Maybe<Personalised_Mutation_Response>;
  /** insert a single row into the table: "personalised" */
  insert_personalised_one?: Maybe<Personalised>;
  /** insert data into the table: "post_comment_files" */
  insert_post_comment_files?: Maybe<Post_Comment_Files_Mutation_Response>;
  /** insert a single row into the table: "post_comment_files" */
  insert_post_comment_files_one?: Maybe<Post_Comment_Files>;
  /** insert data into the table: "post_comment_mentioned_users" */
  insert_post_comment_mentioned_users?: Maybe<Post_Comment_Mentioned_Users_Mutation_Response>;
  /** insert a single row into the table: "post_comment_mentioned_users" */
  insert_post_comment_mentioned_users_one?: Maybe<Post_Comment_Mentioned_Users>;
  /** insert data into the table: "post_comment_replies" */
  insert_post_comment_replies?: Maybe<Post_Comment_Replies_Mutation_Response>;
  /** insert data into the table: "post_comment_replies_mentioned_users" */
  insert_post_comment_replies_mentioned_users?: Maybe<Post_Comment_Replies_Mentioned_Users_Mutation_Response>;
  /** insert a single row into the table: "post_comment_replies_mentioned_users" */
  insert_post_comment_replies_mentioned_users_one?: Maybe<Post_Comment_Replies_Mentioned_Users>;
  /** insert a single row into the table: "post_comment_replies" */
  insert_post_comment_replies_one?: Maybe<Post_Comment_Replies>;
  /** insert data into the table: "post_comments_likes" */
  insert_post_comments_likes?: Maybe<Post_Comments_Likes_Mutation_Response>;
  /** insert a single row into the table: "post_comments_likes" */
  insert_post_comments_likes_one?: Maybe<Post_Comments_Likes>;
  /** insert data into the table: "post_likes" */
  insert_post_likes?: Maybe<Post_Likes_Mutation_Response>;
  /** insert a single row into the table: "post_likes" */
  insert_post_likes_one?: Maybe<Post_Likes>;
  /** insert data into the table: "post_mentioned_users" */
  insert_post_mentioned_users?: Maybe<Post_Mentioned_Users_Mutation_Response>;
  /** insert a single row into the table: "post_mentioned_users" */
  insert_post_mentioned_users_one?: Maybe<Post_Mentioned_Users>;
  /** insert data into the table: "post_replies_likes" */
  insert_post_replies_likes?: Maybe<Post_Replies_Likes_Mutation_Response>;
  /** insert a single row into the table: "post_replies_likes" */
  insert_post_replies_likes_one?: Maybe<Post_Replies_Likes>;
  /** insert data into the table: "posts" */
  insert_posts?: Maybe<Posts_Mutation_Response>;
  /** insert data into the table: "posts_comments" */
  insert_posts_comments?: Maybe<Posts_Comments_Mutation_Response>;
  /** insert a single row into the table: "posts_comments" */
  insert_posts_comments_one?: Maybe<Posts_Comments>;
  /** insert data into the table: "posts_files" */
  insert_posts_files?: Maybe<Posts_Files_Mutation_Response>;
  /** insert a single row into the table: "posts_files" */
  insert_posts_files_one?: Maybe<Posts_Files>;
  /** insert a single row into the table: "posts" */
  insert_posts_one?: Maybe<Posts>;
  /** insert data into the table: "posts_poll_choices" */
  insert_posts_poll_choices?: Maybe<Posts_Poll_Choices_Mutation_Response>;
  /** insert a single row into the table: "posts_poll_choices" */
  insert_posts_poll_choices_one?: Maybe<Posts_Poll_Choices>;
  /** insert data into the table: "posts_poll_choices_votes" */
  insert_posts_poll_choices_votes?: Maybe<Posts_Poll_Choices_Votes_Mutation_Response>;
  /** insert a single row into the table: "posts_poll_choices_votes" */
  insert_posts_poll_choices_votes_one?: Maybe<Posts_Poll_Choices_Votes>;
  /** insert data into the table: "profile" */
  insert_profile?: Maybe<Profile_Mutation_Response>;
  /** insert a single row into the table: "profile" */
  insert_profile_one?: Maybe<Profile>;
  /** insert data into the table: "project" */
  insert_project?: Maybe<Project_Mutation_Response>;
  /** insert data into the table: "project_client" */
  insert_project_client?: Maybe<Project_Client_Mutation_Response>;
  /** insert a single row into the table: "project_client" */
  insert_project_client_one?: Maybe<Project_Client>;
  /** insert data into the table: "project_links" */
  insert_project_links?: Maybe<Project_Links_Mutation_Response>;
  /** insert a single row into the table: "project_links" */
  insert_project_links_one?: Maybe<Project_Links>;
  /** insert data into the table: "project_members" */
  insert_project_members?: Maybe<Project_Members_Mutation_Response>;
  /** insert a single row into the table: "project_members" */
  insert_project_members_one?: Maybe<Project_Members>;
  /** insert data into the table: "project_milestones" */
  insert_project_milestones?: Maybe<Project_Milestones_Mutation_Response>;
  /** insert a single row into the table: "project_milestones" */
  insert_project_milestones_one?: Maybe<Project_Milestones>;
  /** insert data into the table: "project_notification_settings" */
  insert_project_notification_settings?: Maybe<Project_Notification_Settings_Mutation_Response>;
  /** insert a single row into the table: "project_notification_settings" */
  insert_project_notification_settings_one?: Maybe<Project_Notification_Settings>;
  /** insert a single row into the table: "project" */
  insert_project_one?: Maybe<Project>;
  /** insert data into the table: "project_section" */
  insert_project_section?: Maybe<Project_Section_Mutation_Response>;
  /** insert a single row into the table: "project_section" */
  insert_project_section_one?: Maybe<Project_Section>;
  /** insert data into the table: "project_sprints" */
  insert_project_sprints?: Maybe<Project_Sprints_Mutation_Response>;
  /** insert a single row into the table: "project_sprints" */
  insert_project_sprints_one?: Maybe<Project_Sprints>;
  /** insert data into the table: "question_options" */
  insert_question_options?: Maybe<Question_Options_Mutation_Response>;
  /** insert a single row into the table: "question_options" */
  insert_question_options_one?: Maybe<Question_Options>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<Questions_Mutation_Response>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "skills_detail" */
  insert_skills_detail?: Maybe<Skills_Detail_Mutation_Response>;
  /** insert a single row into the table: "skills_detail" */
  insert_skills_detail_one?: Maybe<Skills_Detail>;
  /** insert data into the table: "stage_career_detail" */
  insert_stage_career_detail?: Maybe<Stage_Career_Detail_Mutation_Response>;
  /** insert a single row into the table: "stage_career_detail" */
  insert_stage_career_detail_one?: Maybe<Stage_Career_Detail>;
  /** insert data into the table: "storage_files" */
  insert_storage_files?: Maybe<Storage_Files_Mutation_Response>;
  /** insert a single row into the table: "storage_files" */
  insert_storage_files_one?: Maybe<Storage_Files>;
  /** insert data into the table: "stress_data" */
  insert_stress_data?: Maybe<Stress_Data_Mutation_Response>;
  /** insert a single row into the table: "stress_data" */
  insert_stress_data_one?: Maybe<Stress_Data>;
  /** insert data into the table: "sub_skills" */
  insert_sub_skills?: Maybe<Sub_Skills_Mutation_Response>;
  /** insert a single row into the table: "sub_skills" */
  insert_sub_skills_one?: Maybe<Sub_Skills>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "task" */
  insert_task?: Maybe<Task_Mutation_Response>;
  /** insert data into the table: "task_approvers" */
  insert_task_approvers?: Maybe<Task_Approvers_Mutation_Response>;
  /** insert a single row into the table: "task_approvers" */
  insert_task_approvers_one?: Maybe<Task_Approvers>;
  /** insert data into the table: "task_checklist" */
  insert_task_checklist?: Maybe<Task_Checklist_Mutation_Response>;
  /** insert a single row into the table: "task_checklist" */
  insert_task_checklist_one?: Maybe<Task_Checklist>;
  /** insert data into the table: "task_collaborator" */
  insert_task_collaborator?: Maybe<Task_Collaborator_Mutation_Response>;
  /** insert a single row into the table: "task_collaborator" */
  insert_task_collaborator_one?: Maybe<Task_Collaborator>;
  /** insert data into the table: "task_comment" */
  insert_task_comment?: Maybe<Task_Comment_Mutation_Response>;
  /** insert data into the table: "task_comment_like" */
  insert_task_comment_like?: Maybe<Task_Comment_Like_Mutation_Response>;
  /** insert a single row into the table: "task_comment_like" */
  insert_task_comment_like_one?: Maybe<Task_Comment_Like>;
  /** insert a single row into the table: "task_comment" */
  insert_task_comment_one?: Maybe<Task_Comment>;
  /** insert data into the table: "task_dependencies" */
  insert_task_dependencies?: Maybe<Task_Dependencies_Mutation_Response>;
  /** insert a single row into the table: "task_dependencies" */
  insert_task_dependencies_one?: Maybe<Task_Dependencies>;
  /** insert data into the table: "task_file" */
  insert_task_file?: Maybe<Task_File_Mutation_Response>;
  /** insert a single row into the table: "task_file" */
  insert_task_file_one?: Maybe<Task_File>;
  /** insert data into the table: "task_milestones" */
  insert_task_milestones?: Maybe<Task_Milestones_Mutation_Response>;
  /** insert a single row into the table: "task_milestones" */
  insert_task_milestones_one?: Maybe<Task_Milestones>;
  /** insert a single row into the table: "task" */
  insert_task_one?: Maybe<Task>;
  /** insert data into the table: "task_tags" */
  insert_task_tags?: Maybe<Task_Tags_Mutation_Response>;
  /** insert a single row into the table: "task_tags" */
  insert_task_tags_one?: Maybe<Task_Tags>;
  /** insert data into the table: "task_time_sheet" */
  insert_task_time_sheet?: Maybe<Task_Time_Sheet_Mutation_Response>;
  /** insert a single row into the table: "task_time_sheet" */
  insert_task_time_sheet_one?: Maybe<Task_Time_Sheet>;
  /** insert data into the table: "task_unscheduled" */
  insert_task_unscheduled?: Maybe<Task_Unscheduled_Mutation_Response>;
  /** insert a single row into the table: "task_unscheduled" */
  insert_task_unscheduled_one?: Maybe<Task_Unscheduled>;
  /** insert data into the table: "time_period" */
  insert_time_period?: Maybe<Time_Period_Mutation_Response>;
  /** insert a single row into the table: "time_period" */
  insert_time_period_one?: Maybe<Time_Period>;
  /** insert data into the table: "training" */
  insert_training?: Maybe<Training_Mutation_Response>;
  /** insert data into the table: "training_Skill" */
  insert_training_Skill?: Maybe<Training_Skill_Mutation_Response>;
  /** insert a single row into the table: "training_Skill" */
  insert_training_Skill_one?: Maybe<Training_Skill>;
  /** insert data into the table: "training_feedback" */
  insert_training_feedback?: Maybe<Training_Feedback_Mutation_Response>;
  /** insert a single row into the table: "training_feedback" */
  insert_training_feedback_one?: Maybe<Training_Feedback>;
  /** insert a single row into the table: "training" */
  insert_training_one?: Maybe<Training>;
  /** insert data into the table: "training_progress_record" */
  insert_training_progress_record?: Maybe<Training_Progress_Record_Mutation_Response>;
  /** insert a single row into the table: "training_progress_record" */
  insert_training_progress_record_one?: Maybe<Training_Progress_Record>;
  /** insert data into the table: "training_role_details" */
  insert_training_role_details?: Maybe<Training_Role_Details_Mutation_Response>;
  /** insert a single row into the table: "training_role_details" */
  insert_training_role_details_one?: Maybe<Training_Role_Details>;
  /** insert data into the table: "training_stage_career" */
  insert_training_stage_career?: Maybe<Training_Stage_Career_Mutation_Response>;
  /** insert a single row into the table: "training_stage_career" */
  insert_training_stage_career_one?: Maybe<Training_Stage_Career>;
  /** insert data into the table: "training_table_of_contents" */
  insert_training_table_of_contents?: Maybe<Training_Table_Of_Contents_Mutation_Response>;
  /** insert a single row into the table: "training_table_of_contents" */
  insert_training_table_of_contents_one?: Maybe<Training_Table_Of_Contents>;
  /** insert data into the table: "training_type_for_training" */
  insert_training_type_for_training?: Maybe<Training_Type_For_Training_Mutation_Response>;
  /** insert a single row into the table: "training_type_for_training" */
  insert_training_type_for_training_one?: Maybe<Training_Type_For_Training>;
  /** insert data into the table: "user_Competency" */
  insert_user_Competency?: Maybe<User_Competency_Mutation_Response>;
  /** insert a single row into the table: "user_Competency" */
  insert_user_Competency_one?: Maybe<User_Competency>;
  /** insert data into the table: "user_Skills" */
  insert_user_Skills?: Maybe<User_Skills_Mutation_Response>;
  /** insert data into the table: "user_Skills_Assessment" */
  insert_user_Skills_Assessment?: Maybe<User_Skills_Assessment_Mutation_Response>;
  /** insert a single row into the table: "user_Skills_Assessment" */
  insert_user_Skills_Assessment_one?: Maybe<User_Skills_Assessment>;
  /** insert data into the table: "user_Skills_Training" */
  insert_user_Skills_Training?: Maybe<User_Skills_Training_Mutation_Response>;
  /** insert a single row into the table: "user_Skills_Training" */
  insert_user_Skills_Training_one?: Maybe<User_Skills_Training>;
  /** insert a single row into the table: "user_Skills" */
  insert_user_Skills_one?: Maybe<User_Skills>;
  /** insert data into the table: "user_activity_tracker" */
  insert_user_activity_tracker?: Maybe<User_Activity_Tracker_Mutation_Response>;
  /** insert a single row into the table: "user_activity_tracker" */
  insert_user_activity_tracker_one?: Maybe<User_Activity_Tracker>;
  /** insert data into the table: "user_avatar" */
  insert_user_avatar?: Maybe<User_Avatar_Mutation_Response>;
  /** insert data into the table: "user_avatar_files" */
  insert_user_avatar_files?: Maybe<User_Avatar_Files_Mutation_Response>;
  /** insert a single row into the table: "user_avatar_files" */
  insert_user_avatar_files_one?: Maybe<User_Avatar_Files>;
  /** insert a single row into the table: "user_avatar" */
  insert_user_avatar_one?: Maybe<User_Avatar>;
  /** insert data into the table: "user_career_development_record" */
  insert_user_career_development_record?: Maybe<User_Career_Development_Record_Mutation_Response>;
  /** insert a single row into the table: "user_career_development_record" */
  insert_user_career_development_record_one?: Maybe<User_Career_Development_Record>;
  /** insert data into the table: "user_career_external_certification" */
  insert_user_career_external_certification?: Maybe<User_Career_External_Certification_Mutation_Response>;
  /** insert a single row into the table: "user_career_external_certification" */
  insert_user_career_external_certification_one?: Maybe<User_Career_External_Certification>;
  /** insert data into the table: "user_presence" */
  insert_user_presence?: Maybe<User_Presence_Mutation_Response>;
  /** insert a single row into the table: "user_presence" */
  insert_user_presence_one?: Maybe<User_Presence>;
  /** insert data into the table: "user_settings" */
  insert_user_settings?: Maybe<User_Settings_Mutation_Response>;
  /** insert a single row into the table: "user_settings" */
  insert_user_settings_one?: Maybe<User_Settings>;
  /** insert data into the table: "user_state" */
  insert_user_state?: Maybe<User_State_Mutation_Response>;
  /** insert a single row into the table: "user_state" */
  insert_user_state_one?: Maybe<User_State>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** This action invites guest to a project for collaboration */
  inviteGuestToProject?: Maybe<InviteGuestToProjectOutput>;
  inviteMeetingRoom?: Maybe<InviteMeetingRoomOutput>;
  joinLiveCall?: Maybe<JoinLiveCallOutput>;
  joinMeetCall?: Maybe<JoinMeetOutput>;
  leaveCall?: Maybe<LeaveCallOutput>;
  leaveChat?: Maybe<LeaveChatOutput>;
  leaveLiveCall?: Maybe<LeaveLiveCallOutput>;
  leaveMeetCall?: Maybe<LeaveMeetCallOutput>;
  leaveMeetingRoom?: Maybe<LeaveMeetingRoomOutput>;
  /** loadAssessmentScoreForAssessmentType */
  loadAssessmentScoreForAssessmentType?: Maybe<AssessmentScoreForAssessmentTypeOuput>;
  /** Load Career Development Plan Dashboard */
  loadCareerDevelopmentDashboard?: Maybe<CareerDevelopmentDashboardOutput>;
  /** loadEmployeeAssessmentScore */
  loadEmployeeAssessmentScore?: Maybe<EmployeeAssessmentScoreOuput>;
  /** loadEmployeeCapactiyGraph */
  loadEmployeeCapactiyGraph?: Maybe<LoadEmployeeCapactiyGraphOutput>;
  /** loadIndividualAssessmentGraph */
  loadIndividualAssessmentScore?: Maybe<IndividualAssessmentScoreOuput>;
  /** loadIndividualAssessmentTypeGraph */
  loadIndividualAssessmentTypeGraph?: Maybe<IndividualAssessmentTypeGraphOuput>;
  /** loadMandatoryAssessment */
  loadMandatoryAssessment?: Maybe<LoadMandatoryAssessmentOutput>;
  /** loadOrganizationScores */
  loadOrganizationScores?: Maybe<OrganizationscoreOuput>;
  /** Load Performance Review Setting */
  loadPerformanceReview?: Maybe<LoadPerformanceReviewOutput>;
  /** Load Weight-age Available as per Roles */
  loadRoleWeightages?: Maybe<RoleWeightageOutput>;
  /** loadStressDataForGraph */
  loadStressDataForGraph?: Maybe<LoadStressDataForGraphOutput>;
  /** Load Users Career Development Detail */
  loadUsersCareerPlanDetails?: Maybe<LoadUserSelectedCareerDetailOutput>;
  /** Load Weight-age Available as per Selected Roles */
  loadWeightageForGoals?: Maybe<Load_Role_Weightage_Output>;
  /** feedbackAggregate */
  loadfeedback?: Maybe<FeedbackOutput>;
  /** loadindividualScore */
  loadindividualScore?: Maybe<ScoreOuput>;
  /** loadScoreCard */
  loadscorecard?: Maybe<ScoreCardOutput>;
  login?: Maybe<LoginOutput>;
  logout?: Maybe<LogoutOutput>;
  newPollPost?: Maybe<NewPollPostOutput>;
  newPost?: Maybe<NewPostOutput>;
  onedriveAuthentication?: Maybe<OnedriveAuthenticationOutPut>;
  onedriveLogOut?: Maybe<OnedriveLogoutOutput>;
  /** Performance Review Download PDF */
  performanceReviewDownloadPdf?: Maybe<PerformanceReviewPdfOutput>;
  /** Graph Detail per User */
  performanceReviewGraph?: Maybe<PerformanceReviewGraphOutput>;
  /** Marks User */
  performanceReviewMarksPerUsers?: Maybe<PerformanceReviewUserOutput>;
  /** Performance Review notification */
  performanceReviewNotification?: Maybe<PerformanceReviewNotifyOutput>;
  postLikes?: Maybe<LikesOutput>;
  removeUserFromChat?: Maybe<RemoveChatUserOutput>;
  requestCalendarMeetCall?: Maybe<RequestCalendarMeetCallOutput>;
  resendActivateEmail?: Maybe<ResendActivateEmailOutput>;
  respondMeetingCall?: Maybe<RespondMeetingCallOutput>;
  respondMeetingRoom?: Maybe<RespondMeetingRoomOutput>;
  respondToCall: RespondToCallOutput;
  respondToLiveCall: RespondToLiveCallOutput;
  saveEmployee?: Maybe<SaveEmployeeOutput>;
  saveOrganizationAdmin: SaveOrganizationAdminOutput;
  saveTimeSheetDay?: Maybe<SaveTimeSheetOutput>;
  saveTimeSheetWeek?: Maybe<SaveTimeSheetWeekOutput>;
  sendTaskReminder?: Maybe<SendTaskReminderOutput>;
  /** Get Skills Under Competency */
  skillsUnderComepetency?: Maybe<ListofSkillsUnderComptOutput>;
  startCall: StartCallOutput;
  startChat?: Maybe<StartChatOutput>;
  startLiveCall?: Maybe<StartLiveCallOutput>;
  startMeetingChat?: Maybe<StartMeetingChatOutput>;
  startRecording?: Maybe<StartRecordingOutput>;
  stopRecording?: Maybe<StopRecordingOutput>;
  submitLeave: SubmitLeaveOutput;
  taskproductivityGraph?: Maybe<TaskproductivityGraphOutput>;
  /** graphForTimeSpent */
  timeSpentGraph?: Maybe<TimeSpentGraphOutput>;
  toggleChatNotification?: Maybe<ToggleChatNotificationOutput>;
  transferOwnership?: Maybe<TransferOwnershipOutput>;
  /** Update - Action */
  updateAction?: Maybe<ActionOutput>;
  /** update assessment */
  updateAssessment?: Maybe<UpdateAssessmentOutput>;
  updateCalendarEvent?: Maybe<UpdateCalendarEventOutput>;
  /** Update Career Development Plan */
  updateCareerDevelopmentPlan?: Maybe<UpdateCareerDevelopmentPlanOutput>;
  /** For updating Item name, approved by and for also deleting checklist related to Task */
  updateChecklist?: Maybe<UpdateChecklistOutput>;
  /** updates dependent tasks based on change in parent task */
  updateDependentTasks?: Maybe<UpdateDependentTaskOutput>;
  /** Update Goal */
  updateGoals?: Maybe<UpdateGoalOutput>;
  /** Update-Kpi */
  updateKpi?: Maybe<UpdateKpiOutput>;
  /** For updating and removing milestone */
  updateMilestone?: Maybe<UpdateMilestoneOutput>;
  /** For Updating Multiple tasks in single request */
  updateMultipleTasks?: Maybe<UpdateMultipleTasksOutput>;
  /** Update Performance Review */
  updatePerformanceReview?: Maybe<UpdatePerofrmanceReviewOutput>;
  updateTask?: Maybe<UpdateTaskOutput>;
  /** For adding and removing approvers in task */
  updateTaskApprovers?: Maybe<UpdateTaskApproversOutput>;
  /** For updating rank of task after drag and drop */
  updateTaskRank: Scalars['uuid'];
  /** updateTraining */
  updateTraining?: Maybe<UpdateTrainingOutput>;
  /** update data of the table: "User_Peer_Review" */
  update_User_Peer_Review?: Maybe<User_Peer_Review_Mutation_Response>;
  /** update single row of the table: "User_Peer_Review" */
  update_User_Peer_Review_by_pk?: Maybe<User_Peer_Review>;
  /** update multiples rows of table: "User_Peer_Review" */
  update_User_Peer_Review_many?: Maybe<
    Array<Maybe<User_Peer_Review_Mutation_Response>>
  >;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "assessment" */
  update_assessment?: Maybe<Assessment_Mutation_Response>;
  /** update data of the table: "assessment_Skills" */
  update_assessment_Skills?: Maybe<Assessment_Skills_Mutation_Response>;
  /** update single row of the table: "assessment_Skills" */
  update_assessment_Skills_by_pk?: Maybe<Assessment_Skills>;
  /** update multiples rows of table: "assessment_Skills" */
  update_assessment_Skills_many?: Maybe<
    Array<Maybe<Assessment_Skills_Mutation_Response>>
  >;
  /** update single row of the table: "assessment" */
  update_assessment_by_pk?: Maybe<Assessment>;
  /** update data of the table: "assessment_feedback" */
  update_assessment_feedback?: Maybe<Assessment_Feedback_Mutation_Response>;
  /** update single row of the table: "assessment_feedback" */
  update_assessment_feedback_by_pk?: Maybe<Assessment_Feedback>;
  /** update multiples rows of table: "assessment_feedback" */
  update_assessment_feedback_many?: Maybe<
    Array<Maybe<Assessment_Feedback_Mutation_Response>>
  >;
  /** update multiples rows of table: "assessment" */
  update_assessment_many?: Maybe<Array<Maybe<Assessment_Mutation_Response>>>;
  /** update data of the table: "assessment_record" */
  update_assessment_record?: Maybe<Assessment_Record_Mutation_Response>;
  /** update single row of the table: "assessment_record" */
  update_assessment_record_by_pk?: Maybe<Assessment_Record>;
  /** update multiples rows of table: "assessment_record" */
  update_assessment_record_many?: Maybe<
    Array<Maybe<Assessment_Record_Mutation_Response>>
  >;
  /** update data of the table: "assessment_role_details" */
  update_assessment_role_details?: Maybe<Assessment_Role_Details_Mutation_Response>;
  /** update single row of the table: "assessment_role_details" */
  update_assessment_role_details_by_pk?: Maybe<Assessment_Role_Details>;
  /** update multiples rows of table: "assessment_role_details" */
  update_assessment_role_details_many?: Maybe<
    Array<Maybe<Assessment_Role_Details_Mutation_Response>>
  >;
  /** update data of the table: "assessment_stage_career" */
  update_assessment_stage_career?: Maybe<Assessment_Stage_Career_Mutation_Response>;
  /** update single row of the table: "assessment_stage_career" */
  update_assessment_stage_career_by_pk?: Maybe<Assessment_Stage_Career>;
  /** update multiples rows of table: "assessment_stage_career" */
  update_assessment_stage_career_many?: Maybe<
    Array<Maybe<Assessment_Stage_Career_Mutation_Response>>
  >;
  /** update data of the table: "assessment_type" */
  update_assessment_type?: Maybe<Assessment_Type_Mutation_Response>;
  /** update single row of the table: "assessment_type" */
  update_assessment_type_by_pk?: Maybe<Assessment_Type>;
  /** update multiples rows of table: "assessment_type" */
  update_assessment_type_many?: Maybe<
    Array<Maybe<Assessment_Type_Mutation_Response>>
  >;
  /** update data of the table: "assign_career_development_plan" */
  update_assign_career_development_plan?: Maybe<Assign_Career_Development_Plan_Mutation_Response>;
  /** update single row of the table: "assign_career_development_plan" */
  update_assign_career_development_plan_by_pk?: Maybe<Assign_Career_Development_Plan>;
  /** update multiples rows of table: "assign_career_development_plan" */
  update_assign_career_development_plan_many?: Maybe<
    Array<Maybe<Assign_Career_Development_Plan_Mutation_Response>>
  >;
  /** update data of the table: "audit.user_state" */
  update_audit_user_state?: Maybe<Audit_User_State_Mutation_Response>;
  /** update single row of the table: "audit.user_state" */
  update_audit_user_state_by_pk?: Maybe<Audit_User_State>;
  /** update multiples rows of table: "audit.user_state" */
  update_audit_user_state_many?: Maybe<
    Array<Maybe<Audit_User_State_Mutation_Response>>
  >;
  /** update data of the table: "auth.account_providers" */
  update_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** update single row of the table: "auth.account_providers" */
  update_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** update multiples rows of table: "auth.account_providers" */
  update_auth_account_providers_many?: Maybe<
    Array<Maybe<Auth_Account_Providers_Mutation_Response>>
  >;
  /** update data of the table: "auth.account_roles" */
  update_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** update single row of the table: "auth.account_roles" */
  update_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** update multiples rows of table: "auth.account_roles" */
  update_auth_account_roles_many?: Maybe<
    Array<Maybe<Auth_Account_Roles_Mutation_Response>>
  >;
  /** update data of the table: "auth.accounts" */
  update_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** update single row of the table: "auth.accounts" */
  update_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** update multiples rows of table: "auth.accounts" */
  update_auth_accounts_many?: Maybe<
    Array<Maybe<Auth_Accounts_Mutation_Response>>
  >;
  /** update data of the table: "auth.providers" */
  update_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** update single row of the table: "auth.providers" */
  update_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** update multiples rows of table: "auth.providers" */
  update_auth_providers_many?: Maybe<
    Array<Maybe<Auth_Providers_Mutation_Response>>
  >;
  /** update data of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** update multiples rows of table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_many?: Maybe<
    Array<Maybe<Auth_Refresh_Tokens_Mutation_Response>>
  >;
  /** update data of the table: "auth.roles" */
  update_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** update single row of the table: "auth.roles" */
  update_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** update multiples rows of table: "auth.roles" */
  update_auth_roles_many?: Maybe<Array<Maybe<Auth_Roles_Mutation_Response>>>;
  /** update data of the table: "award_posts" */
  update_award_posts?: Maybe<Award_Posts_Mutation_Response>;
  /** update single row of the table: "award_posts" */
  update_award_posts_by_pk?: Maybe<Award_Posts>;
  /** update multiples rows of table: "award_posts" */
  update_award_posts_many?: Maybe<Array<Maybe<Award_Posts_Mutation_Response>>>;
  /** update data of the table: "awards" */
  update_awards?: Maybe<Awards_Mutation_Response>;
  /** update single row of the table: "awards" */
  update_awards_by_pk?: Maybe<Awards>;
  /** update multiples rows of table: "awards" */
  update_awards_many?: Maybe<Array<Maybe<Awards_Mutation_Response>>>;
  /** update data of the table: "calendar" */
  update_calendar?: Maybe<Calendar_Mutation_Response>;
  /** update data of the table: "calendar_attendee" */
  update_calendar_attendee?: Maybe<Calendar_Attendee_Mutation_Response>;
  /** update single row of the table: "calendar_attendee" */
  update_calendar_attendee_by_pk?: Maybe<Calendar_Attendee>;
  /** update multiples rows of table: "calendar_attendee" */
  update_calendar_attendee_many?: Maybe<
    Array<Maybe<Calendar_Attendee_Mutation_Response>>
  >;
  /** update single row of the table: "calendar" */
  update_calendar_by_pk?: Maybe<Calendar>;
  /** update data of the table: "calendar_delete_events" */
  update_calendar_delete_events?: Maybe<Calendar_Delete_Events_Mutation_Response>;
  /** update single row of the table: "calendar_delete_events" */
  update_calendar_delete_events_by_pk?: Maybe<Calendar_Delete_Events>;
  /** update multiples rows of table: "calendar_delete_events" */
  update_calendar_delete_events_many?: Maybe<
    Array<Maybe<Calendar_Delete_Events_Mutation_Response>>
  >;
  /** update data of the table: "calendar_event" */
  update_calendar_event?: Maybe<Calendar_Event_Mutation_Response>;
  /** update single row of the table: "calendar_event" */
  update_calendar_event_by_pk?: Maybe<Calendar_Event>;
  /** update multiples rows of table: "calendar_event" */
  update_calendar_event_many?: Maybe<
    Array<Maybe<Calendar_Event_Mutation_Response>>
  >;
  /** update multiples rows of table: "calendar" */
  update_calendar_many?: Maybe<Array<Maybe<Calendar_Mutation_Response>>>;
  /** update data of the table: "calendar_meeting_call" */
  update_calendar_meeting_call?: Maybe<Calendar_Meeting_Call_Mutation_Response>;
  /** update single row of the table: "calendar_meeting_call" */
  update_calendar_meeting_call_by_pk?: Maybe<Calendar_Meeting_Call>;
  /** update multiples rows of table: "calendar_meeting_call" */
  update_calendar_meeting_call_many?: Maybe<
    Array<Maybe<Calendar_Meeting_Call_Mutation_Response>>
  >;
  /** update data of the table: "calendar_meeting_call_request" */
  update_calendar_meeting_call_request?: Maybe<Calendar_Meeting_Call_Request_Mutation_Response>;
  /** update single row of the table: "calendar_meeting_call_request" */
  update_calendar_meeting_call_request_by_pk?: Maybe<Calendar_Meeting_Call_Request>;
  /** update multiples rows of table: "calendar_meeting_call_request" */
  update_calendar_meeting_call_request_many?: Maybe<
    Array<Maybe<Calendar_Meeting_Call_Request_Mutation_Response>>
  >;
  /** update data of the table: "calendar_meeting_call_user" */
  update_calendar_meeting_call_user?: Maybe<Calendar_Meeting_Call_User_Mutation_Response>;
  /** update single row of the table: "calendar_meeting_call_user" */
  update_calendar_meeting_call_user_by_pk?: Maybe<Calendar_Meeting_Call_User>;
  /** update multiples rows of table: "calendar_meeting_call_user" */
  update_calendar_meeting_call_user_many?: Maybe<
    Array<Maybe<Calendar_Meeting_Call_User_Mutation_Response>>
  >;
  /** update data of the table: "call" */
  update_call?: Maybe<Call_Mutation_Response>;
  /** update single row of the table: "call" */
  update_call_by_pk?: Maybe<Call>;
  /** update multiples rows of table: "call" */
  update_call_many?: Maybe<Array<Maybe<Call_Mutation_Response>>>;
  /** update data of the table: "call_request" */
  update_call_request?: Maybe<Call_Request_Mutation_Response>;
  /** update single row of the table: "call_request" */
  update_call_request_by_pk?: Maybe<Call_Request>;
  /** update multiples rows of table: "call_request" */
  update_call_request_many?: Maybe<
    Array<Maybe<Call_Request_Mutation_Response>>
  >;
  /** update data of the table: "call_user" */
  update_call_user?: Maybe<Call_User_Mutation_Response>;
  /** update single row of the table: "call_user" */
  update_call_user_by_pk?: Maybe<Call_User>;
  /** update multiples rows of table: "call_user" */
  update_call_user_many?: Maybe<Array<Maybe<Call_User_Mutation_Response>>>;
  /** update data of the table: "career_development_detail" */
  update_career_development_detail?: Maybe<Career_Development_Detail_Mutation_Response>;
  /** update single row of the table: "career_development_detail" */
  update_career_development_detail_by_pk?: Maybe<Career_Development_Detail>;
  /** update multiples rows of table: "career_development_detail" */
  update_career_development_detail_many?: Maybe<
    Array<Maybe<Career_Development_Detail_Mutation_Response>>
  >;
  /** update data of the table: "career_development_role_details" */
  update_career_development_role_details?: Maybe<Career_Development_Role_Details_Mutation_Response>;
  /** update single row of the table: "career_development_role_details" */
  update_career_development_role_details_by_pk?: Maybe<Career_Development_Role_Details>;
  /** update multiples rows of table: "career_development_role_details" */
  update_career_development_role_details_many?: Maybe<
    Array<Maybe<Career_Development_Role_Details_Mutation_Response>>
  >;
  /** update data of the table: "career_external_certification" */
  update_career_external_certification?: Maybe<Career_External_Certification_Mutation_Response>;
  /** update single row of the table: "career_external_certification" */
  update_career_external_certification_by_pk?: Maybe<Career_External_Certification>;
  /** update multiples rows of table: "career_external_certification" */
  update_career_external_certification_many?: Maybe<
    Array<Maybe<Career_External_Certification_Mutation_Response>>
  >;
  /** update data of the table: "chat_message" */
  update_chat_message?: Maybe<Chat_Message_Mutation_Response>;
  /** update data of the table: "chat_message_action" */
  update_chat_message_action?: Maybe<Chat_Message_Action_Mutation_Response>;
  /** update single row of the table: "chat_message_action" */
  update_chat_message_action_by_pk?: Maybe<Chat_Message_Action>;
  /** update multiples rows of table: "chat_message_action" */
  update_chat_message_action_many?: Maybe<
    Array<Maybe<Chat_Message_Action_Mutation_Response>>
  >;
  /** update single row of the table: "chat_message" */
  update_chat_message_by_pk?: Maybe<Chat_Message>;
  /** update data of the table: "chat_message_file" */
  update_chat_message_file?: Maybe<Chat_Message_File_Mutation_Response>;
  /** update single row of the table: "chat_message_file" */
  update_chat_message_file_by_pk?: Maybe<Chat_Message_File>;
  /** update multiples rows of table: "chat_message_file" */
  update_chat_message_file_many?: Maybe<
    Array<Maybe<Chat_Message_File_Mutation_Response>>
  >;
  /** update multiples rows of table: "chat_message" */
  update_chat_message_many?: Maybe<
    Array<Maybe<Chat_Message_Mutation_Response>>
  >;
  /** update data of the table: "chat_message_reactions" */
  update_chat_message_reactions?: Maybe<Chat_Message_Reactions_Mutation_Response>;
  /** update single row of the table: "chat_message_reactions" */
  update_chat_message_reactions_by_pk?: Maybe<Chat_Message_Reactions>;
  /** update multiples rows of table: "chat_message_reactions" */
  update_chat_message_reactions_many?: Maybe<
    Array<Maybe<Chat_Message_Reactions_Mutation_Response>>
  >;
  /** update data of the table: "chat_pinned" */
  update_chat_pinned?: Maybe<Chat_Pinned_Mutation_Response>;
  /** update single row of the table: "chat_pinned" */
  update_chat_pinned_by_pk?: Maybe<Chat_Pinned>;
  /** update multiples rows of table: "chat_pinned" */
  update_chat_pinned_many?: Maybe<Array<Maybe<Chat_Pinned_Mutation_Response>>>;
  /** update data of the table: "chat_room" */
  update_chat_room?: Maybe<Chat_Room_Mutation_Response>;
  /** update single row of the table: "chat_room" */
  update_chat_room_by_pk?: Maybe<Chat_Room>;
  /** update multiples rows of table: "chat_room" */
  update_chat_room_many?: Maybe<Array<Maybe<Chat_Room_Mutation_Response>>>;
  /** update data of the table: "chat_room_user" */
  update_chat_room_user?: Maybe<Chat_Room_User_Mutation_Response>;
  /** update single row of the table: "chat_room_user" */
  update_chat_room_user_by_pk?: Maybe<Chat_Room_User>;
  /** update multiples rows of table: "chat_room_user" */
  update_chat_room_user_many?: Maybe<
    Array<Maybe<Chat_Room_User_Mutation_Response>>
  >;
  /** update data of the table: "dashboard_remainders" */
  update_dashboard_remainders?: Maybe<Dashboard_Remainders_Mutation_Response>;
  /** update single row of the table: "dashboard_remainders" */
  update_dashboard_remainders_by_pk?: Maybe<Dashboard_Remainders>;
  /** update multiples rows of table: "dashboard_remainders" */
  update_dashboard_remainders_many?: Maybe<
    Array<Maybe<Dashboard_Remainders_Mutation_Response>>
  >;
  /** update data of the table: "department" */
  update_department?: Maybe<Department_Mutation_Response>;
  /** update single row of the table: "department" */
  update_department_by_pk?: Maybe<Department>;
  /** update data of the table: "department_employee" */
  update_department_employee?: Maybe<Department_Employee_Mutation_Response>;
  /** update single row of the table: "department_employee" */
  update_department_employee_by_pk?: Maybe<Department_Employee>;
  /** update multiples rows of table: "department_employee" */
  update_department_employee_many?: Maybe<
    Array<Maybe<Department_Employee_Mutation_Response>>
  >;
  /** update multiples rows of table: "department" */
  update_department_many?: Maybe<Array<Maybe<Department_Mutation_Response>>>;
  /** update data of the table: "document_token" */
  update_document_token?: Maybe<Document_Token_Mutation_Response>;
  /** update single row of the table: "document_token" */
  update_document_token_by_pk?: Maybe<Document_Token>;
  /** update multiples rows of table: "document_token" */
  update_document_token_many?: Maybe<
    Array<Maybe<Document_Token_Mutation_Response>>
  >;
  /** update data of the table: "email_templates" */
  update_email_templates?: Maybe<Email_Templates_Mutation_Response>;
  /** update single row of the table: "email_templates" */
  update_email_templates_by_pk?: Maybe<Email_Templates>;
  /** update multiples rows of table: "email_templates" */
  update_email_templates_many?: Maybe<
    Array<Maybe<Email_Templates_Mutation_Response>>
  >;
  /** update data of the table: "employee_bandwidth_data" */
  update_employee_bandwidth_data?: Maybe<Employee_Bandwidth_Data_Mutation_Response>;
  /** update single row of the table: "employee_bandwidth_data" */
  update_employee_bandwidth_data_by_pk?: Maybe<Employee_Bandwidth_Data>;
  /** update multiples rows of table: "employee_bandwidth_data" */
  update_employee_bandwidth_data_many?: Maybe<
    Array<Maybe<Employee_Bandwidth_Data_Mutation_Response>>
  >;
  /** update data of the table: "employee_capacity" */
  update_employee_capacity?: Maybe<Employee_Capacity_Mutation_Response>;
  /** update single row of the table: "employee_capacity" */
  update_employee_capacity_by_pk?: Maybe<Employee_Capacity>;
  /** update multiples rows of table: "employee_capacity" */
  update_employee_capacity_many?: Maybe<
    Array<Maybe<Employee_Capacity_Mutation_Response>>
  >;
  /** update data of the table: "enum_activity_state" */
  update_enum_activity_state?: Maybe<Enum_Activity_State_Mutation_Response>;
  /** update single row of the table: "enum_activity_state" */
  update_enum_activity_state_by_pk?: Maybe<Enum_Activity_State>;
  /** update multiples rows of table: "enum_activity_state" */
  update_enum_activity_state_many?: Maybe<
    Array<Maybe<Enum_Activity_State_Mutation_Response>>
  >;
  /** update data of the table: "enum_activity_type" */
  update_enum_activity_type?: Maybe<Enum_Activity_Type_Mutation_Response>;
  /** update single row of the table: "enum_activity_type" */
  update_enum_activity_type_by_pk?: Maybe<Enum_Activity_Type>;
  /** update multiples rows of table: "enum_activity_type" */
  update_enum_activity_type_many?: Maybe<
    Array<Maybe<Enum_Activity_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_attendee_status_type" */
  update_enum_attendee_status_type?: Maybe<Enum_Attendee_Status_Type_Mutation_Response>;
  /** update single row of the table: "enum_attendee_status_type" */
  update_enum_attendee_status_type_by_pk?: Maybe<Enum_Attendee_Status_Type>;
  /** update multiples rows of table: "enum_attendee_status_type" */
  update_enum_attendee_status_type_many?: Maybe<
    Array<Maybe<Enum_Attendee_Status_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_attendee_type" */
  update_enum_attendee_type?: Maybe<Enum_Attendee_Type_Mutation_Response>;
  /** update single row of the table: "enum_attendee_type" */
  update_enum_attendee_type_by_pk?: Maybe<Enum_Attendee_Type>;
  /** update multiples rows of table: "enum_attendee_type" */
  update_enum_attendee_type_many?: Maybe<
    Array<Maybe<Enum_Attendee_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_award_cycle_type" */
  update_enum_award_cycle_type?: Maybe<Enum_Award_Cycle_Type_Mutation_Response>;
  /** update single row of the table: "enum_award_cycle_type" */
  update_enum_award_cycle_type_by_pk?: Maybe<Enum_Award_Cycle_Type>;
  /** update multiples rows of table: "enum_award_cycle_type" */
  update_enum_award_cycle_type_many?: Maybe<
    Array<Maybe<Enum_Award_Cycle_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_access_policy" */
  update_enum_call_access_policy?: Maybe<Enum_Call_Access_Policy_Mutation_Response>;
  /** update single row of the table: "enum_call_access_policy" */
  update_enum_call_access_policy_by_pk?: Maybe<Enum_Call_Access_Policy>;
  /** update multiples rows of table: "enum_call_access_policy" */
  update_enum_call_access_policy_many?: Maybe<
    Array<Maybe<Enum_Call_Access_Policy_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_broadcasting_type" */
  update_enum_call_broadcasting_type?: Maybe<Enum_Call_Broadcasting_Type_Mutation_Response>;
  /** update single row of the table: "enum_call_broadcasting_type" */
  update_enum_call_broadcasting_type_by_pk?: Maybe<Enum_Call_Broadcasting_Type>;
  /** update multiples rows of table: "enum_call_broadcasting_type" */
  update_enum_call_broadcasting_type_many?: Maybe<
    Array<Maybe<Enum_Call_Broadcasting_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_media_type" */
  update_enum_call_media_type?: Maybe<Enum_Call_Media_Type_Mutation_Response>;
  /** update single row of the table: "enum_call_media_type" */
  update_enum_call_media_type_by_pk?: Maybe<Enum_Call_Media_Type>;
  /** update multiples rows of table: "enum_call_media_type" */
  update_enum_call_media_type_many?: Maybe<
    Array<Maybe<Enum_Call_Media_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_recording_status" */
  update_enum_call_recording_status?: Maybe<Enum_Call_Recording_Status_Mutation_Response>;
  /** update single row of the table: "enum_call_recording_status" */
  update_enum_call_recording_status_by_pk?: Maybe<Enum_Call_Recording_Status>;
  /** update multiples rows of table: "enum_call_recording_status" */
  update_enum_call_recording_status_many?: Maybe<
    Array<Maybe<Enum_Call_Recording_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_request_response_type" */
  update_enum_call_request_response_type?: Maybe<Enum_Call_Request_Response_Type_Mutation_Response>;
  /** update single row of the table: "enum_call_request_response_type" */
  update_enum_call_request_response_type_by_pk?: Maybe<Enum_Call_Request_Response_Type>;
  /** update multiples rows of table: "enum_call_request_response_type" */
  update_enum_call_request_response_type_many?: Maybe<
    Array<Maybe<Enum_Call_Request_Response_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_request_status_type" */
  update_enum_call_request_status_type?: Maybe<Enum_Call_Request_Status_Type_Mutation_Response>;
  /** update single row of the table: "enum_call_request_status_type" */
  update_enum_call_request_status_type_by_pk?: Maybe<Enum_Call_Request_Status_Type>;
  /** update multiples rows of table: "enum_call_request_status_type" */
  update_enum_call_request_status_type_many?: Maybe<
    Array<Maybe<Enum_Call_Request_Status_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_request_type" */
  update_enum_call_request_type?: Maybe<Enum_Call_Request_Type_Mutation_Response>;
  /** update single row of the table: "enum_call_request_type" */
  update_enum_call_request_type_by_pk?: Maybe<Enum_Call_Request_Type>;
  /** update multiples rows of table: "enum_call_request_type" */
  update_enum_call_request_type_many?: Maybe<
    Array<Maybe<Enum_Call_Request_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_status_type" */
  update_enum_call_status_type?: Maybe<Enum_Call_Status_Type_Mutation_Response>;
  /** update single row of the table: "enum_call_status_type" */
  update_enum_call_status_type_by_pk?: Maybe<Enum_Call_Status_Type>;
  /** update multiples rows of table: "enum_call_status_type" */
  update_enum_call_status_type_many?: Maybe<
    Array<Maybe<Enum_Call_Status_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_type" */
  update_enum_call_type?: Maybe<Enum_Call_Type_Mutation_Response>;
  /** update single row of the table: "enum_call_type" */
  update_enum_call_type_by_pk?: Maybe<Enum_Call_Type>;
  /** update multiples rows of table: "enum_call_type" */
  update_enum_call_type_many?: Maybe<
    Array<Maybe<Enum_Call_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_call_user_status" */
  update_enum_call_user_status?: Maybe<Enum_Call_User_Status_Mutation_Response>;
  /** update single row of the table: "enum_call_user_status" */
  update_enum_call_user_status_by_pk?: Maybe<Enum_Call_User_Status>;
  /** update multiples rows of table: "enum_call_user_status" */
  update_enum_call_user_status_many?: Maybe<
    Array<Maybe<Enum_Call_User_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_chat_message_type" */
  update_enum_chat_message_type?: Maybe<Enum_Chat_Message_Type_Mutation_Response>;
  /** update single row of the table: "enum_chat_message_type" */
  update_enum_chat_message_type_by_pk?: Maybe<Enum_Chat_Message_Type>;
  /** update multiples rows of table: "enum_chat_message_type" */
  update_enum_chat_message_type_many?: Maybe<
    Array<Maybe<Enum_Chat_Message_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_chat_notification" */
  update_enum_chat_notification?: Maybe<Enum_Chat_Notification_Mutation_Response>;
  /** update single row of the table: "enum_chat_notification" */
  update_enum_chat_notification_by_pk?: Maybe<Enum_Chat_Notification>;
  /** update multiples rows of table: "enum_chat_notification" */
  update_enum_chat_notification_many?: Maybe<
    Array<Maybe<Enum_Chat_Notification_Mutation_Response>>
  >;
  /** update data of the table: "enum_chat_room_notification_status" */
  update_enum_chat_room_notification_status?: Maybe<Enum_Chat_Room_Notification_Status_Mutation_Response>;
  /** update single row of the table: "enum_chat_room_notification_status" */
  update_enum_chat_room_notification_status_by_pk?: Maybe<Enum_Chat_Room_Notification_Status>;
  /** update multiples rows of table: "enum_chat_room_notification_status" */
  update_enum_chat_room_notification_status_many?: Maybe<
    Array<Maybe<Enum_Chat_Room_Notification_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_chat_room_type" */
  update_enum_chat_room_type?: Maybe<Enum_Chat_Room_Type_Mutation_Response>;
  /** update single row of the table: "enum_chat_room_type" */
  update_enum_chat_room_type_by_pk?: Maybe<Enum_Chat_Room_Type>;
  /** update multiples rows of table: "enum_chat_room_type" */
  update_enum_chat_room_type_many?: Maybe<
    Array<Maybe<Enum_Chat_Room_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_chat_room_user_status" */
  update_enum_chat_room_user_status?: Maybe<Enum_Chat_Room_User_Status_Mutation_Response>;
  /** update single row of the table: "enum_chat_room_user_status" */
  update_enum_chat_room_user_status_by_pk?: Maybe<Enum_Chat_Room_User_Status>;
  /** update multiples rows of table: "enum_chat_room_user_status" */
  update_enum_chat_room_user_status_many?: Maybe<
    Array<Maybe<Enum_Chat_Room_User_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_dashboard_remainder_type" */
  update_enum_dashboard_remainder_type?: Maybe<Enum_Dashboard_Remainder_Type_Mutation_Response>;
  /** update single row of the table: "enum_dashboard_remainder_type" */
  update_enum_dashboard_remainder_type_by_pk?: Maybe<Enum_Dashboard_Remainder_Type>;
  /** update multiples rows of table: "enum_dashboard_remainder_type" */
  update_enum_dashboard_remainder_type_many?: Maybe<
    Array<Maybe<Enum_Dashboard_Remainder_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_document_token_type" */
  update_enum_document_token_type?: Maybe<Enum_Document_Token_Type_Mutation_Response>;
  /** update single row of the table: "enum_document_token_type" */
  update_enum_document_token_type_by_pk?: Maybe<Enum_Document_Token_Type>;
  /** update multiples rows of table: "enum_document_token_type" */
  update_enum_document_token_type_many?: Maybe<
    Array<Maybe<Enum_Document_Token_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_event_type" */
  update_enum_event_type?: Maybe<Enum_Event_Type_Mutation_Response>;
  /** update single row of the table: "enum_event_type" */
  update_enum_event_type_by_pk?: Maybe<Enum_Event_Type>;
  /** update multiples rows of table: "enum_event_type" */
  update_enum_event_type_many?: Maybe<
    Array<Maybe<Enum_Event_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_leave_donate_type" */
  update_enum_leave_donate_type?: Maybe<Enum_Leave_Donate_Type_Mutation_Response>;
  /** update single row of the table: "enum_leave_donate_type" */
  update_enum_leave_donate_type_by_pk?: Maybe<Enum_Leave_Donate_Type>;
  /** update multiples rows of table: "enum_leave_donate_type" */
  update_enum_leave_donate_type_many?: Maybe<
    Array<Maybe<Enum_Leave_Donate_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_leave_status_type" */
  update_enum_leave_status_type?: Maybe<Enum_Leave_Status_Type_Mutation_Response>;
  /** update single row of the table: "enum_leave_status_type" */
  update_enum_leave_status_type_by_pk?: Maybe<Enum_Leave_Status_Type>;
  /** update multiples rows of table: "enum_leave_status_type" */
  update_enum_leave_status_type_many?: Maybe<
    Array<Maybe<Enum_Leave_Status_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_live_call_request_status" */
  update_enum_live_call_request_status?: Maybe<Enum_Live_Call_Request_Status_Mutation_Response>;
  /** update single row of the table: "enum_live_call_request_status" */
  update_enum_live_call_request_status_by_pk?: Maybe<Enum_Live_Call_Request_Status>;
  /** update multiples rows of table: "enum_live_call_request_status" */
  update_enum_live_call_request_status_many?: Maybe<
    Array<Maybe<Enum_Live_Call_Request_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_live_call_status" */
  update_enum_live_call_status?: Maybe<Enum_Live_Call_Status_Mutation_Response>;
  /** update single row of the table: "enum_live_call_status" */
  update_enum_live_call_status_by_pk?: Maybe<Enum_Live_Call_Status>;
  /** update multiples rows of table: "enum_live_call_status" */
  update_enum_live_call_status_many?: Maybe<
    Array<Maybe<Enum_Live_Call_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_live_call_user_status" */
  update_enum_live_call_user_status?: Maybe<Enum_Live_Call_User_Status_Mutation_Response>;
  /** update single row of the table: "enum_live_call_user_status" */
  update_enum_live_call_user_status_by_pk?: Maybe<Enum_Live_Call_User_Status>;
  /** update multiples rows of table: "enum_live_call_user_status" */
  update_enum_live_call_user_status_many?: Maybe<
    Array<Maybe<Enum_Live_Call_User_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_live_emote_type" */
  update_enum_live_emote_type?: Maybe<Enum_Live_Emote_Type_Mutation_Response>;
  /** update single row of the table: "enum_live_emote_type" */
  update_enum_live_emote_type_by_pk?: Maybe<Enum_Live_Emote_Type>;
  /** update multiples rows of table: "enum_live_emote_type" */
  update_enum_live_emote_type_many?: Maybe<
    Array<Maybe<Enum_Live_Emote_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_manual_state" */
  update_enum_manual_state?: Maybe<Enum_Manual_State_Mutation_Response>;
  /** update single row of the table: "enum_manual_state" */
  update_enum_manual_state_by_pk?: Maybe<Enum_Manual_State>;
  /** update multiples rows of table: "enum_manual_state" */
  update_enum_manual_state_many?: Maybe<
    Array<Maybe<Enum_Manual_State_Mutation_Response>>
  >;
  /** update data of the table: "enum_meeting_room_status" */
  update_enum_meeting_room_status?: Maybe<Enum_Meeting_Room_Status_Mutation_Response>;
  /** update single row of the table: "enum_meeting_room_status" */
  update_enum_meeting_room_status_by_pk?: Maybe<Enum_Meeting_Room_Status>;
  /** update multiples rows of table: "enum_meeting_room_status" */
  update_enum_meeting_room_status_many?: Maybe<
    Array<Maybe<Enum_Meeting_Room_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_meeting_room_type" */
  update_enum_meeting_room_type?: Maybe<Enum_Meeting_Room_Type_Mutation_Response>;
  /** update single row of the table: "enum_meeting_room_type" */
  update_enum_meeting_room_type_by_pk?: Maybe<Enum_Meeting_Room_Type>;
  /** update multiples rows of table: "enum_meeting_room_type" */
  update_enum_meeting_room_type_many?: Maybe<
    Array<Maybe<Enum_Meeting_Room_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_meeting_room_user_state" */
  update_enum_meeting_room_user_state?: Maybe<Enum_Meeting_Room_User_State_Mutation_Response>;
  /** update single row of the table: "enum_meeting_room_user_state" */
  update_enum_meeting_room_user_state_by_pk?: Maybe<Enum_Meeting_Room_User_State>;
  /** update multiples rows of table: "enum_meeting_room_user_state" */
  update_enum_meeting_room_user_state_many?: Maybe<
    Array<Maybe<Enum_Meeting_Room_User_State_Mutation_Response>>
  >;
  /** update data of the table: "enum_notification_type" */
  update_enum_notification_type?: Maybe<Enum_Notification_Type_Mutation_Response>;
  /** update single row of the table: "enum_notification_type" */
  update_enum_notification_type_by_pk?: Maybe<Enum_Notification_Type>;
  /** update multiples rows of table: "enum_notification_type" */
  update_enum_notification_type_many?: Maybe<
    Array<Maybe<Enum_Notification_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_personalised_access" */
  update_enum_personalised_access?: Maybe<Enum_Personalised_Access_Mutation_Response>;
  /** update single row of the table: "enum_personalised_access" */
  update_enum_personalised_access_by_pk?: Maybe<Enum_Personalised_Access>;
  /** update multiples rows of table: "enum_personalised_access" */
  update_enum_personalised_access_many?: Maybe<
    Array<Maybe<Enum_Personalised_Access_Mutation_Response>>
  >;
  /** update data of the table: "enum_personalised_categories" */
  update_enum_personalised_categories?: Maybe<Enum_Personalised_Categories_Mutation_Response>;
  /** update single row of the table: "enum_personalised_categories" */
  update_enum_personalised_categories_by_pk?: Maybe<Enum_Personalised_Categories>;
  /** update multiples rows of table: "enum_personalised_categories" */
  update_enum_personalised_categories_many?: Maybe<
    Array<Maybe<Enum_Personalised_Categories_Mutation_Response>>
  >;
  /** update data of the table: "enum_post_type" */
  update_enum_post_type?: Maybe<Enum_Post_Type_Mutation_Response>;
  /** update single row of the table: "enum_post_type" */
  update_enum_post_type_by_pk?: Maybe<Enum_Post_Type>;
  /** update multiples rows of table: "enum_post_type" */
  update_enum_post_type_many?: Maybe<
    Array<Maybe<Enum_Post_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_presence_connection_status" */
  update_enum_presence_connection_status?: Maybe<Enum_Presence_Connection_Status_Mutation_Response>;
  /** update single row of the table: "enum_presence_connection_status" */
  update_enum_presence_connection_status_by_pk?: Maybe<Enum_Presence_Connection_Status>;
  /** update multiples rows of table: "enum_presence_connection_status" */
  update_enum_presence_connection_status_many?: Maybe<
    Array<Maybe<Enum_Presence_Connection_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_project_milestone_status" */
  update_enum_project_milestone_status?: Maybe<Enum_Project_Milestone_Status_Mutation_Response>;
  /** update single row of the table: "enum_project_milestone_status" */
  update_enum_project_milestone_status_by_pk?: Maybe<Enum_Project_Milestone_Status>;
  /** update multiples rows of table: "enum_project_milestone_status" */
  update_enum_project_milestone_status_many?: Maybe<
    Array<Maybe<Enum_Project_Milestone_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_project_sprint_category" */
  update_enum_project_sprint_category?: Maybe<Enum_Project_Sprint_Category_Mutation_Response>;
  /** update single row of the table: "enum_project_sprint_category" */
  update_enum_project_sprint_category_by_pk?: Maybe<Enum_Project_Sprint_Category>;
  /** update multiples rows of table: "enum_project_sprint_category" */
  update_enum_project_sprint_category_many?: Maybe<
    Array<Maybe<Enum_Project_Sprint_Category_Mutation_Response>>
  >;
  /** update data of the table: "enum_project_status" */
  update_enum_project_status?: Maybe<Enum_Project_Status_Mutation_Response>;
  /** update single row of the table: "enum_project_status" */
  update_enum_project_status_by_pk?: Maybe<Enum_Project_Status>;
  /** update multiples rows of table: "enum_project_status" */
  update_enum_project_status_many?: Maybe<
    Array<Maybe<Enum_Project_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_project_task_category" */
  update_enum_project_task_category?: Maybe<Enum_Project_Task_Category_Mutation_Response>;
  /** update single row of the table: "enum_project_task_category" */
  update_enum_project_task_category_by_pk?: Maybe<Enum_Project_Task_Category>;
  /** update multiples rows of table: "enum_project_task_category" */
  update_enum_project_task_category_many?: Maybe<
    Array<Maybe<Enum_Project_Task_Category_Mutation_Response>>
  >;
  /** update data of the table: "enum_setting_type" */
  update_enum_setting_type?: Maybe<Enum_Setting_Type_Mutation_Response>;
  /** update single row of the table: "enum_setting_type" */
  update_enum_setting_type_by_pk?: Maybe<Enum_Setting_Type>;
  /** update multiples rows of table: "enum_setting_type" */
  update_enum_setting_type_many?: Maybe<
    Array<Maybe<Enum_Setting_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_storage_file_type" */
  update_enum_storage_file_type?: Maybe<Enum_Storage_File_Type_Mutation_Response>;
  /** update single row of the table: "enum_storage_file_type" */
  update_enum_storage_file_type_by_pk?: Maybe<Enum_Storage_File_Type>;
  /** update multiples rows of table: "enum_storage_file_type" */
  update_enum_storage_file_type_many?: Maybe<
    Array<Maybe<Enum_Storage_File_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_system_state" */
  update_enum_system_state?: Maybe<Enum_System_State_Mutation_Response>;
  /** update single row of the table: "enum_system_state" */
  update_enum_system_state_by_pk?: Maybe<Enum_System_State>;
  /** update multiples rows of table: "enum_system_state" */
  update_enum_system_state_many?: Maybe<
    Array<Maybe<Enum_System_State_Mutation_Response>>
  >;
  /** update data of the table: "enum_system_state_owner" */
  update_enum_system_state_owner?: Maybe<Enum_System_State_Owner_Mutation_Response>;
  /** update single row of the table: "enum_system_state_owner" */
  update_enum_system_state_owner_by_pk?: Maybe<Enum_System_State_Owner>;
  /** update multiples rows of table: "enum_system_state_owner" */
  update_enum_system_state_owner_many?: Maybe<
    Array<Maybe<Enum_System_State_Owner_Mutation_Response>>
  >;
  /** update data of the table: "enum_task_priority" */
  update_enum_task_priority?: Maybe<Enum_Task_Priority_Mutation_Response>;
  /** update single row of the table: "enum_task_priority" */
  update_enum_task_priority_by_pk?: Maybe<Enum_Task_Priority>;
  /** update multiples rows of table: "enum_task_priority" */
  update_enum_task_priority_many?: Maybe<
    Array<Maybe<Enum_Task_Priority_Mutation_Response>>
  >;
  /** update data of the table: "enum_task_recurring_frequency" */
  update_enum_task_recurring_frequency?: Maybe<Enum_Task_Recurring_Frequency_Mutation_Response>;
  /** update single row of the table: "enum_task_recurring_frequency" */
  update_enum_task_recurring_frequency_by_pk?: Maybe<Enum_Task_Recurring_Frequency>;
  /** update multiples rows of table: "enum_task_recurring_frequency" */
  update_enum_task_recurring_frequency_many?: Maybe<
    Array<Maybe<Enum_Task_Recurring_Frequency_Mutation_Response>>
  >;
  /** update data of the table: "enum_task_status" */
  update_enum_task_status?: Maybe<Enum_Task_Status_Mutation_Response>;
  /** update single row of the table: "enum_task_status" */
  update_enum_task_status_by_pk?: Maybe<Enum_Task_Status>;
  /** update multiples rows of table: "enum_task_status" */
  update_enum_task_status_many?: Maybe<
    Array<Maybe<Enum_Task_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_task_time_sheet_status" */
  update_enum_task_time_sheet_status?: Maybe<Enum_Task_Time_Sheet_Status_Mutation_Response>;
  /** update single row of the table: "enum_task_time_sheet_status" */
  update_enum_task_time_sheet_status_by_pk?: Maybe<Enum_Task_Time_Sheet_Status>;
  /** update multiples rows of table: "enum_task_time_sheet_status" */
  update_enum_task_time_sheet_status_many?: Maybe<
    Array<Maybe<Enum_Task_Time_Sheet_Status_Mutation_Response>>
  >;
  /** update data of the table: "enum_user_activity_tracker_status_type" */
  update_enum_user_activity_tracker_status_type?: Maybe<Enum_User_Activity_Tracker_Status_Type_Mutation_Response>;
  /** update single row of the table: "enum_user_activity_tracker_status_type" */
  update_enum_user_activity_tracker_status_type_by_pk?: Maybe<Enum_User_Activity_Tracker_Status_Type>;
  /** update multiples rows of table: "enum_user_activity_tracker_status_type" */
  update_enum_user_activity_tracker_status_type_many?: Maybe<
    Array<Maybe<Enum_User_Activity_Tracker_Status_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_user_avatar_types" */
  update_enum_user_avatar_types?: Maybe<Enum_User_Avatar_Types_Mutation_Response>;
  /** update single row of the table: "enum_user_avatar_types" */
  update_enum_user_avatar_types_by_pk?: Maybe<Enum_User_Avatar_Types>;
  /** update multiples rows of table: "enum_user_avatar_types" */
  update_enum_user_avatar_types_many?: Maybe<
    Array<Maybe<Enum_User_Avatar_Types_Mutation_Response>>
  >;
  /** update data of the table: "enum_user_gender" */
  update_enum_user_gender?: Maybe<Enum_User_Gender_Mutation_Response>;
  /** update single row of the table: "enum_user_gender" */
  update_enum_user_gender_by_pk?: Maybe<Enum_User_Gender>;
  /** update multiples rows of table: "enum_user_gender" */
  update_enum_user_gender_many?: Maybe<
    Array<Maybe<Enum_User_Gender_Mutation_Response>>
  >;
  /** update data of the table: "enum_user_presence_provider_type" */
  update_enum_user_presence_provider_type?: Maybe<Enum_User_Presence_Provider_Type_Mutation_Response>;
  /** update single row of the table: "enum_user_presence_provider_type" */
  update_enum_user_presence_provider_type_by_pk?: Maybe<Enum_User_Presence_Provider_Type>;
  /** update multiples rows of table: "enum_user_presence_provider_type" */
  update_enum_user_presence_provider_type_many?: Maybe<
    Array<Maybe<Enum_User_Presence_Provider_Type_Mutation_Response>>
  >;
  /** update data of the table: "enum_user_settings_type" */
  update_enum_user_settings_type?: Maybe<Enum_User_Settings_Type_Mutation_Response>;
  /** update single row of the table: "enum_user_settings_type" */
  update_enum_user_settings_type_by_pk?: Maybe<Enum_User_Settings_Type>;
  /** update multiples rows of table: "enum_user_settings_type" */
  update_enum_user_settings_type_many?: Maybe<
    Array<Maybe<Enum_User_Settings_Type_Mutation_Response>>
  >;
  /** update data of the table: "filter_definition" */
  update_filter_definition?: Maybe<Filter_Definition_Mutation_Response>;
  /** update single row of the table: "filter_definition" */
  update_filter_definition_by_pk?: Maybe<Filter_Definition>;
  /** update multiples rows of table: "filter_definition" */
  update_filter_definition_many?: Maybe<
    Array<Maybe<Filter_Definition_Mutation_Response>>
  >;
  /** update data of the table: "financialYear" */
  update_financialYear?: Maybe<FinancialYear_Mutation_Response>;
  /** update single row of the table: "financialYear" */
  update_financialYear_by_pk?: Maybe<FinancialYear>;
  /** update multiples rows of table: "financialYear" */
  update_financialYear_many?: Maybe<
    Array<Maybe<FinancialYear_Mutation_Response>>
  >;
  /** update data of the table: "frequency" */
  update_frequency?: Maybe<Frequency_Mutation_Response>;
  /** update single row of the table: "frequency" */
  update_frequency_by_pk?: Maybe<Frequency>;
  /** update multiples rows of table: "frequency" */
  update_frequency_many?: Maybe<Array<Maybe<Frequency_Mutation_Response>>>;
  /** update data of the table: "goalWithKpi" */
  update_goalWithKpi?: Maybe<GoalWithKpi_Mutation_Response>;
  /** update single row of the table: "goalWithKpi" */
  update_goalWithKpi_by_pk?: Maybe<GoalWithKpi>;
  /** update multiples rows of table: "goalWithKpi" */
  update_goalWithKpi_many?: Maybe<Array<Maybe<GoalWithKpi_Mutation_Response>>>;
  /** update data of the table: "goalWithTimePeriod" */
  update_goalWithTimePeriod?: Maybe<GoalWithTimePeriod_Mutation_Response>;
  /** update single row of the table: "goalWithTimePeriod" */
  update_goalWithTimePeriod_by_pk?: Maybe<GoalWithTimePeriod>;
  /** update multiples rows of table: "goalWithTimePeriod" */
  update_goalWithTimePeriod_many?: Maybe<
    Array<Maybe<GoalWithTimePeriod_Mutation_Response>>
  >;
  /** update data of the table: "goals" */
  update_goals?: Maybe<Goals_Mutation_Response>;
  /** update data of the table: "goalsWithDepts" */
  update_goalsWithDepts?: Maybe<GoalsWithDepts_Mutation_Response>;
  /** update single row of the table: "goalsWithDepts" */
  update_goalsWithDepts_by_pk?: Maybe<GoalsWithDepts>;
  /** update multiples rows of table: "goalsWithDepts" */
  update_goalsWithDepts_many?: Maybe<
    Array<Maybe<GoalsWithDepts_Mutation_Response>>
  >;
  /** update data of the table: "goalsWithFreqs" */
  update_goalsWithFreqs?: Maybe<GoalsWithFreqs_Mutation_Response>;
  /** update single row of the table: "goalsWithFreqs" */
  update_goalsWithFreqs_by_pk?: Maybe<GoalsWithFreqs>;
  /** update multiples rows of table: "goalsWithFreqs" */
  update_goalsWithFreqs_many?: Maybe<
    Array<Maybe<GoalsWithFreqs_Mutation_Response>>
  >;
  /** update data of the table: "goalsWithRoles" */
  update_goalsWithRoles?: Maybe<GoalsWithRoles_Mutation_Response>;
  /** update single row of the table: "goalsWithRoles" */
  update_goalsWithRoles_by_pk?: Maybe<GoalsWithRoles>;
  /** update multiples rows of table: "goalsWithRoles" */
  update_goalsWithRoles_many?: Maybe<
    Array<Maybe<GoalsWithRoles_Mutation_Response>>
  >;
  /** update single row of the table: "goals" */
  update_goals_by_pk?: Maybe<Goals>;
  /** update multiples rows of table: "goals" */
  update_goals_many?: Maybe<Array<Maybe<Goals_Mutation_Response>>>;
  /** update data of the table: "hotdesk_bookings" */
  update_hotdesk_bookings?: Maybe<Hotdesk_Bookings_Mutation_Response>;
  /** update single row of the table: "hotdesk_bookings" */
  update_hotdesk_bookings_by_pk?: Maybe<Hotdesk_Bookings>;
  /** update multiples rows of table: "hotdesk_bookings" */
  update_hotdesk_bookings_many?: Maybe<
    Array<Maybe<Hotdesk_Bookings_Mutation_Response>>
  >;
  /** update data of the table: "kpiWithDept" */
  update_kpiWithDept?: Maybe<KpiWithDept_Mutation_Response>;
  /** update single row of the table: "kpiWithDept" */
  update_kpiWithDept_by_pk?: Maybe<KpiWithDept>;
  /** update multiples rows of table: "kpiWithDept" */
  update_kpiWithDept_many?: Maybe<Array<Maybe<KpiWithDept_Mutation_Response>>>;
  /** update data of the table: "kpiWithRole" */
  update_kpiWithRole?: Maybe<KpiWithRole_Mutation_Response>;
  /** update single row of the table: "kpiWithRole" */
  update_kpiWithRole_by_pk?: Maybe<KpiWithRole>;
  /** update multiples rows of table: "kpiWithRole" */
  update_kpiWithRole_many?: Maybe<Array<Maybe<KpiWithRole_Mutation_Response>>>;
  /** update data of the table: "kpi_bank" */
  update_kpi_bank?: Maybe<Kpi_Bank_Mutation_Response>;
  /** update single row of the table: "kpi_bank" */
  update_kpi_bank_by_pk?: Maybe<Kpi_Bank>;
  /** update multiples rows of table: "kpi_bank" */
  update_kpi_bank_many?: Maybe<Array<Maybe<Kpi_Bank_Mutation_Response>>>;
  /** update data of the table: "leave" */
  update_leave?: Maybe<Leave_Mutation_Response>;
  /** update single row of the table: "leave" */
  update_leave_by_pk?: Maybe<Leave>;
  /** update data of the table: "leave_donate" */
  update_leave_donate?: Maybe<Leave_Donate_Mutation_Response>;
  /** update single row of the table: "leave_donate" */
  update_leave_donate_by_pk?: Maybe<Leave_Donate>;
  /** update multiples rows of table: "leave_donate" */
  update_leave_donate_many?: Maybe<
    Array<Maybe<Leave_Donate_Mutation_Response>>
  >;
  /** update multiples rows of table: "leave" */
  update_leave_many?: Maybe<Array<Maybe<Leave_Mutation_Response>>>;
  /** update data of the table: "leave_organization" */
  update_leave_organization?: Maybe<Leave_Organization_Mutation_Response>;
  /** update single row of the table: "leave_organization" */
  update_leave_organization_by_pk?: Maybe<Leave_Organization>;
  /** update multiples rows of table: "leave_organization" */
  update_leave_organization_many?: Maybe<
    Array<Maybe<Leave_Organization_Mutation_Response>>
  >;
  /** update data of the table: "list_Of_Skills" */
  update_list_Of_Skills?: Maybe<List_Of_Skills_Mutation_Response>;
  /** update single row of the table: "list_Of_Skills" */
  update_list_Of_Skills_by_pk?: Maybe<List_Of_Skills>;
  /** update multiples rows of table: "list_Of_Skills" */
  update_list_Of_Skills_many?: Maybe<
    Array<Maybe<List_Of_Skills_Mutation_Response>>
  >;
  /** update data of the table: "live_call" */
  update_live_call?: Maybe<Live_Call_Mutation_Response>;
  /** update single row of the table: "live_call" */
  update_live_call_by_pk?: Maybe<Live_Call>;
  /** update multiples rows of table: "live_call" */
  update_live_call_many?: Maybe<Array<Maybe<Live_Call_Mutation_Response>>>;
  /** update data of the table: "live_call_request" */
  update_live_call_request?: Maybe<Live_Call_Request_Mutation_Response>;
  /** update single row of the table: "live_call_request" */
  update_live_call_request_by_pk?: Maybe<Live_Call_Request>;
  /** update multiples rows of table: "live_call_request" */
  update_live_call_request_many?: Maybe<
    Array<Maybe<Live_Call_Request_Mutation_Response>>
  >;
  /** update data of the table: "live_call_user" */
  update_live_call_user?: Maybe<Live_Call_User_Mutation_Response>;
  /** update single row of the table: "live_call_user" */
  update_live_call_user_by_pk?: Maybe<Live_Call_User>;
  /** update multiples rows of table: "live_call_user" */
  update_live_call_user_many?: Maybe<
    Array<Maybe<Live_Call_User_Mutation_Response>>
  >;
  /** update data of the table: "main_skills" */
  update_main_skills?: Maybe<Main_Skills_Mutation_Response>;
  /** update single row of the table: "main_skills" */
  update_main_skills_by_pk?: Maybe<Main_Skills>;
  /** update multiples rows of table: "main_skills" */
  update_main_skills_many?: Maybe<Array<Maybe<Main_Skills_Mutation_Response>>>;
  /** update data of the table: "meeting_call_schedular" */
  update_meeting_call_schedular?: Maybe<Meeting_Call_Schedular_Mutation_Response>;
  /** update single row of the table: "meeting_call_schedular" */
  update_meeting_call_schedular_by_pk?: Maybe<Meeting_Call_Schedular>;
  /** update multiples rows of table: "meeting_call_schedular" */
  update_meeting_call_schedular_many?: Maybe<
    Array<Maybe<Meeting_Call_Schedular_Mutation_Response>>
  >;
  /** update data of the table: "meeting_room" */
  update_meeting_room?: Maybe<Meeting_Room_Mutation_Response>;
  /** update single row of the table: "meeting_room" */
  update_meeting_room_by_pk?: Maybe<Meeting_Room>;
  /** update multiples rows of table: "meeting_room" */
  update_meeting_room_many?: Maybe<
    Array<Maybe<Meeting_Room_Mutation_Response>>
  >;
  /** update data of the table: "meeting_room_users" */
  update_meeting_room_users?: Maybe<Meeting_Room_Users_Mutation_Response>;
  /** update single row of the table: "meeting_room_users" */
  update_meeting_room_users_by_pk?: Maybe<Meeting_Room_Users>;
  /** update multiples rows of table: "meeting_room_users" */
  update_meeting_room_users_many?: Maybe<
    Array<Maybe<Meeting_Room_Users_Mutation_Response>>
  >;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<
    Array<Maybe<Notifications_Mutation_Response>>
  >;
  /** update data of the table: "organization" */
  update_organization?: Maybe<Organization_Mutation_Response>;
  /** update data of the table: "organization_active_leave_types" */
  update_organization_active_leave_types?: Maybe<Organization_Active_Leave_Types_Mutation_Response>;
  /** update single row of the table: "organization_active_leave_types" */
  update_organization_active_leave_types_by_pk?: Maybe<Organization_Active_Leave_Types>;
  /** update multiples rows of table: "organization_active_leave_types" */
  update_organization_active_leave_types_many?: Maybe<
    Array<Maybe<Organization_Active_Leave_Types_Mutation_Response>>
  >;
  /** update data of the table: "organization_admin" */
  update_organization_admin?: Maybe<Organization_Admin_Mutation_Response>;
  /** update single row of the table: "organization_admin" */
  update_organization_admin_by_pk?: Maybe<Organization_Admin>;
  /** update multiples rows of table: "organization_admin" */
  update_organization_admin_many?: Maybe<
    Array<Maybe<Organization_Admin_Mutation_Response>>
  >;
  /** update data of the table: "organization_awards" */
  update_organization_awards?: Maybe<Organization_Awards_Mutation_Response>;
  /** update single row of the table: "organization_awards" */
  update_organization_awards_by_pk?: Maybe<Organization_Awards>;
  /** update multiples rows of table: "organization_awards" */
  update_organization_awards_many?: Maybe<
    Array<Maybe<Organization_Awards_Mutation_Response>>
  >;
  /** update single row of the table: "organization" */
  update_organization_by_pk?: Maybe<Organization>;
  /** update data of the table: "organization_employee" */
  update_organization_employee?: Maybe<Organization_Employee_Mutation_Response>;
  /** update single row of the table: "organization_employee" */
  update_organization_employee_by_pk?: Maybe<Organization_Employee>;
  /** update multiples rows of table: "organization_employee" */
  update_organization_employee_many?: Maybe<
    Array<Maybe<Organization_Employee_Mutation_Response>>
  >;
  /** update data of the table: "organization_holidays" */
  update_organization_holidays?: Maybe<Organization_Holidays_Mutation_Response>;
  /** update single row of the table: "organization_holidays" */
  update_organization_holidays_by_pk?: Maybe<Organization_Holidays>;
  /** update multiples rows of table: "organization_holidays" */
  update_organization_holidays_many?: Maybe<
    Array<Maybe<Organization_Holidays_Mutation_Response>>
  >;
  /** update data of the table: "organization_leave_cycles" */
  update_organization_leave_cycles?: Maybe<Organization_Leave_Cycles_Mutation_Response>;
  /** update single row of the table: "organization_leave_cycles" */
  update_organization_leave_cycles_by_pk?: Maybe<Organization_Leave_Cycles>;
  /** update multiples rows of table: "organization_leave_cycles" */
  update_organization_leave_cycles_many?: Maybe<
    Array<Maybe<Organization_Leave_Cycles_Mutation_Response>>
  >;
  /** update data of the table: "organization_locations" */
  update_organization_locations?: Maybe<Organization_Locations_Mutation_Response>;
  /** update single row of the table: "organization_locations" */
  update_organization_locations_by_pk?: Maybe<Organization_Locations>;
  /** update multiples rows of table: "organization_locations" */
  update_organization_locations_many?: Maybe<
    Array<Maybe<Organization_Locations_Mutation_Response>>
  >;
  /** update multiples rows of table: "organization" */
  update_organization_many?: Maybe<
    Array<Maybe<Organization_Mutation_Response>>
  >;
  /** update data of the table: "organization_roles" */
  update_organization_roles?: Maybe<Organization_Roles_Mutation_Response>;
  /** update single row of the table: "organization_roles" */
  update_organization_roles_by_pk?: Maybe<Organization_Roles>;
  /** update multiples rows of table: "organization_roles" */
  update_organization_roles_many?: Maybe<
    Array<Maybe<Organization_Roles_Mutation_Response>>
  >;
  /** update data of the table: "organization_user_leave_application" */
  update_organization_user_leave_application?: Maybe<Organization_User_Leave_Application_Mutation_Response>;
  /** update single row of the table: "organization_user_leave_application" */
  update_organization_user_leave_application_by_pk?: Maybe<Organization_User_Leave_Application>;
  /** update multiples rows of table: "organization_user_leave_application" */
  update_organization_user_leave_application_many?: Maybe<
    Array<Maybe<Organization_User_Leave_Application_Mutation_Response>>
  >;
  /** update data of the table: "organization_user_leave_data" */
  update_organization_user_leave_data?: Maybe<Organization_User_Leave_Data_Mutation_Response>;
  /** update single row of the table: "organization_user_leave_data" */
  update_organization_user_leave_data_by_pk?: Maybe<Organization_User_Leave_Data>;
  /** update multiples rows of table: "organization_user_leave_data" */
  update_organization_user_leave_data_many?: Maybe<
    Array<Maybe<Organization_User_Leave_Data_Mutation_Response>>
  >;
  /** update data of the table: "organization_user_leave_dates" */
  update_organization_user_leave_dates?: Maybe<Organization_User_Leave_Dates_Mutation_Response>;
  /** update single row of the table: "organization_user_leave_dates" */
  update_organization_user_leave_dates_by_pk?: Maybe<Organization_User_Leave_Dates>;
  /** update multiples rows of table: "organization_user_leave_dates" */
  update_organization_user_leave_dates_many?: Maybe<
    Array<Maybe<Organization_User_Leave_Dates_Mutation_Response>>
  >;
  /** update data of the table: "organization_user_leave_files" */
  update_organization_user_leave_files?: Maybe<Organization_User_Leave_Files_Mutation_Response>;
  /** update single row of the table: "organization_user_leave_files" */
  update_organization_user_leave_files_by_pk?: Maybe<Organization_User_Leave_Files>;
  /** update multiples rows of table: "organization_user_leave_files" */
  update_organization_user_leave_files_many?: Maybe<
    Array<Maybe<Organization_User_Leave_Files_Mutation_Response>>
  >;
  /** update data of the table: "performanceReviewStatus" */
  update_performanceReviewStatus?: Maybe<PerformanceReviewStatus_Mutation_Response>;
  /** update single row of the table: "performanceReviewStatus" */
  update_performanceReviewStatus_by_pk?: Maybe<PerformanceReviewStatus>;
  /** update multiples rows of table: "performanceReviewStatus" */
  update_performanceReviewStatus_many?: Maybe<
    Array<Maybe<PerformanceReviewStatus_Mutation_Response>>
  >;
  /** update data of the table: "performance_rating_scale" */
  update_performance_rating_scale?: Maybe<Performance_Rating_Scale_Mutation_Response>;
  /** update single row of the table: "performance_rating_scale" */
  update_performance_rating_scale_by_pk?: Maybe<Performance_Rating_Scale>;
  /** update multiples rows of table: "performance_rating_scale" */
  update_performance_rating_scale_many?: Maybe<
    Array<Maybe<Performance_Rating_Scale_Mutation_Response>>
  >;
  /** update data of the table: "performance_review_byUser" */
  update_performance_review_byUser?: Maybe<Performance_Review_ByUser_Mutation_Response>;
  /** update data of the table: "performance_review_byUser_Kpi" */
  update_performance_review_byUser_Kpi?: Maybe<Performance_Review_ByUser_Kpi_Mutation_Response>;
  /** update single row of the table: "performance_review_byUser_Kpi" */
  update_performance_review_byUser_Kpi_by_pk?: Maybe<Performance_Review_ByUser_Kpi>;
  /** update multiples rows of table: "performance_review_byUser_Kpi" */
  update_performance_review_byUser_Kpi_many?: Maybe<
    Array<Maybe<Performance_Review_ByUser_Kpi_Mutation_Response>>
  >;
  /** update data of the table: "performance_review_byUser_WithGoal" */
  update_performance_review_byUser_WithGoal?: Maybe<Performance_Review_ByUser_WithGoal_Mutation_Response>;
  /** update single row of the table: "performance_review_byUser_WithGoal" */
  update_performance_review_byUser_WithGoal_by_pk?: Maybe<Performance_Review_ByUser_WithGoal>;
  /** update multiples rows of table: "performance_review_byUser_WithGoal" */
  update_performance_review_byUser_WithGoal_many?: Maybe<
    Array<Maybe<Performance_Review_ByUser_WithGoal_Mutation_Response>>
  >;
  /** update single row of the table: "performance_review_byUser" */
  update_performance_review_byUser_by_pk?: Maybe<Performance_Review_ByUser>;
  /** update multiples rows of table: "performance_review_byUser" */
  update_performance_review_byUser_many?: Maybe<
    Array<Maybe<Performance_Review_ByUser_Mutation_Response>>
  >;
  /** update data of the table: "performance_review_setting_GoalsList" */
  update_performance_review_setting_GoalsList?: Maybe<Performance_Review_Setting_GoalsList_Mutation_Response>;
  /** update single row of the table: "performance_review_setting_GoalsList" */
  update_performance_review_setting_GoalsList_by_pk?: Maybe<Performance_Review_Setting_GoalsList>;
  /** update multiples rows of table: "performance_review_setting_GoalsList" */
  update_performance_review_setting_GoalsList_many?: Maybe<
    Array<Maybe<Performance_Review_Setting_GoalsList_Mutation_Response>>
  >;
  /** update data of the table: "performance_review_setting_With_Goals" */
  update_performance_review_setting_With_Goals?: Maybe<Performance_Review_Setting_With_Goals_Mutation_Response>;
  /** update single row of the table: "performance_review_setting_With_Goals" */
  update_performance_review_setting_With_Goals_by_pk?: Maybe<Performance_Review_Setting_With_Goals>;
  /** update multiples rows of table: "performance_review_setting_With_Goals" */
  update_performance_review_setting_With_Goals_many?: Maybe<
    Array<Maybe<Performance_Review_Setting_With_Goals_Mutation_Response>>
  >;
  /** update data of the table: "performance_review_settings" */
  update_performance_review_settings?: Maybe<Performance_Review_Settings_Mutation_Response>;
  /** update single row of the table: "performance_review_settings" */
  update_performance_review_settings_by_pk?: Maybe<Performance_Review_Settings>;
  /** update multiples rows of table: "performance_review_settings" */
  update_performance_review_settings_many?: Maybe<
    Array<Maybe<Performance_Review_Settings_Mutation_Response>>
  >;
  /** update data of the table: "personalised" */
  update_personalised?: Maybe<Personalised_Mutation_Response>;
  /** update single row of the table: "personalised" */
  update_personalised_by_pk?: Maybe<Personalised>;
  /** update multiples rows of table: "personalised" */
  update_personalised_many?: Maybe<
    Array<Maybe<Personalised_Mutation_Response>>
  >;
  /** update data of the table: "post_comment_files" */
  update_post_comment_files?: Maybe<Post_Comment_Files_Mutation_Response>;
  /** update single row of the table: "post_comment_files" */
  update_post_comment_files_by_pk?: Maybe<Post_Comment_Files>;
  /** update multiples rows of table: "post_comment_files" */
  update_post_comment_files_many?: Maybe<
    Array<Maybe<Post_Comment_Files_Mutation_Response>>
  >;
  /** update data of the table: "post_comment_mentioned_users" */
  update_post_comment_mentioned_users?: Maybe<Post_Comment_Mentioned_Users_Mutation_Response>;
  /** update single row of the table: "post_comment_mentioned_users" */
  update_post_comment_mentioned_users_by_pk?: Maybe<Post_Comment_Mentioned_Users>;
  /** update multiples rows of table: "post_comment_mentioned_users" */
  update_post_comment_mentioned_users_many?: Maybe<
    Array<Maybe<Post_Comment_Mentioned_Users_Mutation_Response>>
  >;
  /** update data of the table: "post_comment_replies" */
  update_post_comment_replies?: Maybe<Post_Comment_Replies_Mutation_Response>;
  /** update single row of the table: "post_comment_replies" */
  update_post_comment_replies_by_pk?: Maybe<Post_Comment_Replies>;
  /** update multiples rows of table: "post_comment_replies" */
  update_post_comment_replies_many?: Maybe<
    Array<Maybe<Post_Comment_Replies_Mutation_Response>>
  >;
  /** update data of the table: "post_comment_replies_mentioned_users" */
  update_post_comment_replies_mentioned_users?: Maybe<Post_Comment_Replies_Mentioned_Users_Mutation_Response>;
  /** update single row of the table: "post_comment_replies_mentioned_users" */
  update_post_comment_replies_mentioned_users_by_pk?: Maybe<Post_Comment_Replies_Mentioned_Users>;
  /** update multiples rows of table: "post_comment_replies_mentioned_users" */
  update_post_comment_replies_mentioned_users_many?: Maybe<
    Array<Maybe<Post_Comment_Replies_Mentioned_Users_Mutation_Response>>
  >;
  /** update data of the table: "post_comments_likes" */
  update_post_comments_likes?: Maybe<Post_Comments_Likes_Mutation_Response>;
  /** update single row of the table: "post_comments_likes" */
  update_post_comments_likes_by_pk?: Maybe<Post_Comments_Likes>;
  /** update multiples rows of table: "post_comments_likes" */
  update_post_comments_likes_many?: Maybe<
    Array<Maybe<Post_Comments_Likes_Mutation_Response>>
  >;
  /** update data of the table: "post_likes" */
  update_post_likes?: Maybe<Post_Likes_Mutation_Response>;
  /** update single row of the table: "post_likes" */
  update_post_likes_by_pk?: Maybe<Post_Likes>;
  /** update multiples rows of table: "post_likes" */
  update_post_likes_many?: Maybe<Array<Maybe<Post_Likes_Mutation_Response>>>;
  /** update data of the table: "post_mentioned_users" */
  update_post_mentioned_users?: Maybe<Post_Mentioned_Users_Mutation_Response>;
  /** update single row of the table: "post_mentioned_users" */
  update_post_mentioned_users_by_pk?: Maybe<Post_Mentioned_Users>;
  /** update multiples rows of table: "post_mentioned_users" */
  update_post_mentioned_users_many?: Maybe<
    Array<Maybe<Post_Mentioned_Users_Mutation_Response>>
  >;
  /** update data of the table: "post_replies_likes" */
  update_post_replies_likes?: Maybe<Post_Replies_Likes_Mutation_Response>;
  /** update single row of the table: "post_replies_likes" */
  update_post_replies_likes_by_pk?: Maybe<Post_Replies_Likes>;
  /** update multiples rows of table: "post_replies_likes" */
  update_post_replies_likes_many?: Maybe<
    Array<Maybe<Post_Replies_Likes_Mutation_Response>>
  >;
  /** update data of the table: "posts" */
  update_posts?: Maybe<Posts_Mutation_Response>;
  /** update single row of the table: "posts" */
  update_posts_by_pk?: Maybe<Posts>;
  /** update data of the table: "posts_comments" */
  update_posts_comments?: Maybe<Posts_Comments_Mutation_Response>;
  /** update single row of the table: "posts_comments" */
  update_posts_comments_by_pk?: Maybe<Posts_Comments>;
  /** update multiples rows of table: "posts_comments" */
  update_posts_comments_many?: Maybe<
    Array<Maybe<Posts_Comments_Mutation_Response>>
  >;
  /** update data of the table: "posts_files" */
  update_posts_files?: Maybe<Posts_Files_Mutation_Response>;
  /** update single row of the table: "posts_files" */
  update_posts_files_by_pk?: Maybe<Posts_Files>;
  /** update multiples rows of table: "posts_files" */
  update_posts_files_many?: Maybe<Array<Maybe<Posts_Files_Mutation_Response>>>;
  /** update multiples rows of table: "posts" */
  update_posts_many?: Maybe<Array<Maybe<Posts_Mutation_Response>>>;
  /** update data of the table: "posts_poll_choices" */
  update_posts_poll_choices?: Maybe<Posts_Poll_Choices_Mutation_Response>;
  /** update single row of the table: "posts_poll_choices" */
  update_posts_poll_choices_by_pk?: Maybe<Posts_Poll_Choices>;
  /** update multiples rows of table: "posts_poll_choices" */
  update_posts_poll_choices_many?: Maybe<
    Array<Maybe<Posts_Poll_Choices_Mutation_Response>>
  >;
  /** update data of the table: "posts_poll_choices_votes" */
  update_posts_poll_choices_votes?: Maybe<Posts_Poll_Choices_Votes_Mutation_Response>;
  /** update single row of the table: "posts_poll_choices_votes" */
  update_posts_poll_choices_votes_by_pk?: Maybe<Posts_Poll_Choices_Votes>;
  /** update multiples rows of table: "posts_poll_choices_votes" */
  update_posts_poll_choices_votes_many?: Maybe<
    Array<Maybe<Posts_Poll_Choices_Votes_Mutation_Response>>
  >;
  /** update data of the table: "profile" */
  update_profile?: Maybe<Profile_Mutation_Response>;
  /** update single row of the table: "profile" */
  update_profile_by_pk?: Maybe<Profile>;
  /** update multiples rows of table: "profile" */
  update_profile_many?: Maybe<Array<Maybe<Profile_Mutation_Response>>>;
  /** update data of the table: "project" */
  update_project?: Maybe<Project_Mutation_Response>;
  /** update single row of the table: "project" */
  update_project_by_pk?: Maybe<Project>;
  /** update data of the table: "project_client" */
  update_project_client?: Maybe<Project_Client_Mutation_Response>;
  /** update single row of the table: "project_client" */
  update_project_client_by_pk?: Maybe<Project_Client>;
  /** update multiples rows of table: "project_client" */
  update_project_client_many?: Maybe<
    Array<Maybe<Project_Client_Mutation_Response>>
  >;
  /** update data of the table: "project_links" */
  update_project_links?: Maybe<Project_Links_Mutation_Response>;
  /** update single row of the table: "project_links" */
  update_project_links_by_pk?: Maybe<Project_Links>;
  /** update multiples rows of table: "project_links" */
  update_project_links_many?: Maybe<
    Array<Maybe<Project_Links_Mutation_Response>>
  >;
  /** update multiples rows of table: "project" */
  update_project_many?: Maybe<Array<Maybe<Project_Mutation_Response>>>;
  /** update data of the table: "project_members" */
  update_project_members?: Maybe<Project_Members_Mutation_Response>;
  /** update single row of the table: "project_members" */
  update_project_members_by_pk?: Maybe<Project_Members>;
  /** update multiples rows of table: "project_members" */
  update_project_members_many?: Maybe<
    Array<Maybe<Project_Members_Mutation_Response>>
  >;
  /** update data of the table: "project_milestones" */
  update_project_milestones?: Maybe<Project_Milestones_Mutation_Response>;
  /** update single row of the table: "project_milestones" */
  update_project_milestones_by_pk?: Maybe<Project_Milestones>;
  /** update multiples rows of table: "project_milestones" */
  update_project_milestones_many?: Maybe<
    Array<Maybe<Project_Milestones_Mutation_Response>>
  >;
  /** update data of the table: "project_notification_settings" */
  update_project_notification_settings?: Maybe<Project_Notification_Settings_Mutation_Response>;
  /** update single row of the table: "project_notification_settings" */
  update_project_notification_settings_by_pk?: Maybe<Project_Notification_Settings>;
  /** update multiples rows of table: "project_notification_settings" */
  update_project_notification_settings_many?: Maybe<
    Array<Maybe<Project_Notification_Settings_Mutation_Response>>
  >;
  /** update data of the table: "project_section" */
  update_project_section?: Maybe<Project_Section_Mutation_Response>;
  /** update single row of the table: "project_section" */
  update_project_section_by_pk?: Maybe<Project_Section>;
  /** update multiples rows of table: "project_section" */
  update_project_section_many?: Maybe<
    Array<Maybe<Project_Section_Mutation_Response>>
  >;
  /** update data of the table: "project_sprints" */
  update_project_sprints?: Maybe<Project_Sprints_Mutation_Response>;
  /** update single row of the table: "project_sprints" */
  update_project_sprints_by_pk?: Maybe<Project_Sprints>;
  /** update multiples rows of table: "project_sprints" */
  update_project_sprints_many?: Maybe<
    Array<Maybe<Project_Sprints_Mutation_Response>>
  >;
  /** update data of the table: "question_options" */
  update_question_options?: Maybe<Question_Options_Mutation_Response>;
  /** update single row of the table: "question_options" */
  update_question_options_by_pk?: Maybe<Question_Options>;
  /** update multiples rows of table: "question_options" */
  update_question_options_many?: Maybe<
    Array<Maybe<Question_Options_Mutation_Response>>
  >;
  /** update data of the table: "questions" */
  update_questions?: Maybe<Questions_Mutation_Response>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update multiples rows of table: "questions" */
  update_questions_many?: Maybe<Array<Maybe<Questions_Mutation_Response>>>;
  /** update data of the table: "skills_detail" */
  update_skills_detail?: Maybe<Skills_Detail_Mutation_Response>;
  /** update single row of the table: "skills_detail" */
  update_skills_detail_by_pk?: Maybe<Skills_Detail>;
  /** update multiples rows of table: "skills_detail" */
  update_skills_detail_many?: Maybe<
    Array<Maybe<Skills_Detail_Mutation_Response>>
  >;
  /** update data of the table: "stage_career_detail" */
  update_stage_career_detail?: Maybe<Stage_Career_Detail_Mutation_Response>;
  /** update single row of the table: "stage_career_detail" */
  update_stage_career_detail_by_pk?: Maybe<Stage_Career_Detail>;
  /** update multiples rows of table: "stage_career_detail" */
  update_stage_career_detail_many?: Maybe<
    Array<Maybe<Stage_Career_Detail_Mutation_Response>>
  >;
  /** update data of the table: "storage_files" */
  update_storage_files?: Maybe<Storage_Files_Mutation_Response>;
  /** update single row of the table: "storage_files" */
  update_storage_files_by_pk?: Maybe<Storage_Files>;
  /** update multiples rows of table: "storage_files" */
  update_storage_files_many?: Maybe<
    Array<Maybe<Storage_Files_Mutation_Response>>
  >;
  /** update data of the table: "stress_data" */
  update_stress_data?: Maybe<Stress_Data_Mutation_Response>;
  /** update single row of the table: "stress_data" */
  update_stress_data_by_pk?: Maybe<Stress_Data>;
  /** update multiples rows of table: "stress_data" */
  update_stress_data_many?: Maybe<Array<Maybe<Stress_Data_Mutation_Response>>>;
  /** update data of the table: "sub_skills" */
  update_sub_skills?: Maybe<Sub_Skills_Mutation_Response>;
  /** update single row of the table: "sub_skills" */
  update_sub_skills_by_pk?: Maybe<Sub_Skills>;
  /** update multiples rows of table: "sub_skills" */
  update_sub_skills_many?: Maybe<Array<Maybe<Sub_Skills_Mutation_Response>>>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  update_tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "task" */
  update_task?: Maybe<Task_Mutation_Response>;
  /** update data of the table: "task_approvers" */
  update_task_approvers?: Maybe<Task_Approvers_Mutation_Response>;
  /** update single row of the table: "task_approvers" */
  update_task_approvers_by_pk?: Maybe<Task_Approvers>;
  /** update multiples rows of table: "task_approvers" */
  update_task_approvers_many?: Maybe<
    Array<Maybe<Task_Approvers_Mutation_Response>>
  >;
  /** update single row of the table: "task" */
  update_task_by_pk?: Maybe<Task>;
  /** update data of the table: "task_checklist" */
  update_task_checklist?: Maybe<Task_Checklist_Mutation_Response>;
  /** update single row of the table: "task_checklist" */
  update_task_checklist_by_pk?: Maybe<Task_Checklist>;
  /** update multiples rows of table: "task_checklist" */
  update_task_checklist_many?: Maybe<
    Array<Maybe<Task_Checklist_Mutation_Response>>
  >;
  /** update data of the table: "task_collaborator" */
  update_task_collaborator?: Maybe<Task_Collaborator_Mutation_Response>;
  /** update single row of the table: "task_collaborator" */
  update_task_collaborator_by_pk?: Maybe<Task_Collaborator>;
  /** update multiples rows of table: "task_collaborator" */
  update_task_collaborator_many?: Maybe<
    Array<Maybe<Task_Collaborator_Mutation_Response>>
  >;
  /** update data of the table: "task_comment" */
  update_task_comment?: Maybe<Task_Comment_Mutation_Response>;
  /** update single row of the table: "task_comment" */
  update_task_comment_by_pk?: Maybe<Task_Comment>;
  /** update data of the table: "task_comment_like" */
  update_task_comment_like?: Maybe<Task_Comment_Like_Mutation_Response>;
  /** update single row of the table: "task_comment_like" */
  update_task_comment_like_by_pk?: Maybe<Task_Comment_Like>;
  /** update multiples rows of table: "task_comment_like" */
  update_task_comment_like_many?: Maybe<
    Array<Maybe<Task_Comment_Like_Mutation_Response>>
  >;
  /** update multiples rows of table: "task_comment" */
  update_task_comment_many?: Maybe<
    Array<Maybe<Task_Comment_Mutation_Response>>
  >;
  /** update data of the table: "task_dependencies" */
  update_task_dependencies?: Maybe<Task_Dependencies_Mutation_Response>;
  /** update single row of the table: "task_dependencies" */
  update_task_dependencies_by_pk?: Maybe<Task_Dependencies>;
  /** update multiples rows of table: "task_dependencies" */
  update_task_dependencies_many?: Maybe<
    Array<Maybe<Task_Dependencies_Mutation_Response>>
  >;
  /** update data of the table: "task_file" */
  update_task_file?: Maybe<Task_File_Mutation_Response>;
  /** update single row of the table: "task_file" */
  update_task_file_by_pk?: Maybe<Task_File>;
  /** update multiples rows of table: "task_file" */
  update_task_file_many?: Maybe<Array<Maybe<Task_File_Mutation_Response>>>;
  /** update multiples rows of table: "task" */
  update_task_many?: Maybe<Array<Maybe<Task_Mutation_Response>>>;
  /** update data of the table: "task_milestones" */
  update_task_milestones?: Maybe<Task_Milestones_Mutation_Response>;
  /** update single row of the table: "task_milestones" */
  update_task_milestones_by_pk?: Maybe<Task_Milestones>;
  /** update multiples rows of table: "task_milestones" */
  update_task_milestones_many?: Maybe<
    Array<Maybe<Task_Milestones_Mutation_Response>>
  >;
  /** update data of the table: "task_tags" */
  update_task_tags?: Maybe<Task_Tags_Mutation_Response>;
  /** update single row of the table: "task_tags" */
  update_task_tags_by_pk?: Maybe<Task_Tags>;
  /** update multiples rows of table: "task_tags" */
  update_task_tags_many?: Maybe<Array<Maybe<Task_Tags_Mutation_Response>>>;
  /** update data of the table: "task_time_sheet" */
  update_task_time_sheet?: Maybe<Task_Time_Sheet_Mutation_Response>;
  /** update single row of the table: "task_time_sheet" */
  update_task_time_sheet_by_pk?: Maybe<Task_Time_Sheet>;
  /** update multiples rows of table: "task_time_sheet" */
  update_task_time_sheet_many?: Maybe<
    Array<Maybe<Task_Time_Sheet_Mutation_Response>>
  >;
  /** update data of the table: "task_unscheduled" */
  update_task_unscheduled?: Maybe<Task_Unscheduled_Mutation_Response>;
  /** update single row of the table: "task_unscheduled" */
  update_task_unscheduled_by_pk?: Maybe<Task_Unscheduled>;
  /** update multiples rows of table: "task_unscheduled" */
  update_task_unscheduled_many?: Maybe<
    Array<Maybe<Task_Unscheduled_Mutation_Response>>
  >;
  /** update data of the table: "time_period" */
  update_time_period?: Maybe<Time_Period_Mutation_Response>;
  /** update single row of the table: "time_period" */
  update_time_period_by_pk?: Maybe<Time_Period>;
  /** update multiples rows of table: "time_period" */
  update_time_period_many?: Maybe<Array<Maybe<Time_Period_Mutation_Response>>>;
  /** update data of the table: "training" */
  update_training?: Maybe<Training_Mutation_Response>;
  /** update data of the table: "training_Skill" */
  update_training_Skill?: Maybe<Training_Skill_Mutation_Response>;
  /** update single row of the table: "training_Skill" */
  update_training_Skill_by_pk?: Maybe<Training_Skill>;
  /** update multiples rows of table: "training_Skill" */
  update_training_Skill_many?: Maybe<
    Array<Maybe<Training_Skill_Mutation_Response>>
  >;
  /** update single row of the table: "training" */
  update_training_by_pk?: Maybe<Training>;
  /** update data of the table: "training_feedback" */
  update_training_feedback?: Maybe<Training_Feedback_Mutation_Response>;
  /** update single row of the table: "training_feedback" */
  update_training_feedback_by_pk?: Maybe<Training_Feedback>;
  /** update multiples rows of table: "training_feedback" */
  update_training_feedback_many?: Maybe<
    Array<Maybe<Training_Feedback_Mutation_Response>>
  >;
  /** update multiples rows of table: "training" */
  update_training_many?: Maybe<Array<Maybe<Training_Mutation_Response>>>;
  /** update data of the table: "training_progress_record" */
  update_training_progress_record?: Maybe<Training_Progress_Record_Mutation_Response>;
  /** update single row of the table: "training_progress_record" */
  update_training_progress_record_by_pk?: Maybe<Training_Progress_Record>;
  /** update multiples rows of table: "training_progress_record" */
  update_training_progress_record_many?: Maybe<
    Array<Maybe<Training_Progress_Record_Mutation_Response>>
  >;
  /** update data of the table: "training_role_details" */
  update_training_role_details?: Maybe<Training_Role_Details_Mutation_Response>;
  /** update single row of the table: "training_role_details" */
  update_training_role_details_by_pk?: Maybe<Training_Role_Details>;
  /** update multiples rows of table: "training_role_details" */
  update_training_role_details_many?: Maybe<
    Array<Maybe<Training_Role_Details_Mutation_Response>>
  >;
  /** update data of the table: "training_stage_career" */
  update_training_stage_career?: Maybe<Training_Stage_Career_Mutation_Response>;
  /** update single row of the table: "training_stage_career" */
  update_training_stage_career_by_pk?: Maybe<Training_Stage_Career>;
  /** update multiples rows of table: "training_stage_career" */
  update_training_stage_career_many?: Maybe<
    Array<Maybe<Training_Stage_Career_Mutation_Response>>
  >;
  /** update data of the table: "training_table_of_contents" */
  update_training_table_of_contents?: Maybe<Training_Table_Of_Contents_Mutation_Response>;
  /** update single row of the table: "training_table_of_contents" */
  update_training_table_of_contents_by_pk?: Maybe<Training_Table_Of_Contents>;
  /** update multiples rows of table: "training_table_of_contents" */
  update_training_table_of_contents_many?: Maybe<
    Array<Maybe<Training_Table_Of_Contents_Mutation_Response>>
  >;
  /** update data of the table: "training_type_for_training" */
  update_training_type_for_training?: Maybe<Training_Type_For_Training_Mutation_Response>;
  /** update single row of the table: "training_type_for_training" */
  update_training_type_for_training_by_pk?: Maybe<Training_Type_For_Training>;
  /** update multiples rows of table: "training_type_for_training" */
  update_training_type_for_training_many?: Maybe<
    Array<Maybe<Training_Type_For_Training_Mutation_Response>>
  >;
  /** update data of the table: "user_Competency" */
  update_user_Competency?: Maybe<User_Competency_Mutation_Response>;
  /** update single row of the table: "user_Competency" */
  update_user_Competency_by_pk?: Maybe<User_Competency>;
  /** update multiples rows of table: "user_Competency" */
  update_user_Competency_many?: Maybe<
    Array<Maybe<User_Competency_Mutation_Response>>
  >;
  /** update data of the table: "user_Skills" */
  update_user_Skills?: Maybe<User_Skills_Mutation_Response>;
  /** update data of the table: "user_Skills_Assessment" */
  update_user_Skills_Assessment?: Maybe<User_Skills_Assessment_Mutation_Response>;
  /** update single row of the table: "user_Skills_Assessment" */
  update_user_Skills_Assessment_by_pk?: Maybe<User_Skills_Assessment>;
  /** update multiples rows of table: "user_Skills_Assessment" */
  update_user_Skills_Assessment_many?: Maybe<
    Array<Maybe<User_Skills_Assessment_Mutation_Response>>
  >;
  /** update data of the table: "user_Skills_Training" */
  update_user_Skills_Training?: Maybe<User_Skills_Training_Mutation_Response>;
  /** update single row of the table: "user_Skills_Training" */
  update_user_Skills_Training_by_pk?: Maybe<User_Skills_Training>;
  /** update multiples rows of table: "user_Skills_Training" */
  update_user_Skills_Training_many?: Maybe<
    Array<Maybe<User_Skills_Training_Mutation_Response>>
  >;
  /** update single row of the table: "user_Skills" */
  update_user_Skills_by_pk?: Maybe<User_Skills>;
  /** update multiples rows of table: "user_Skills" */
  update_user_Skills_many?: Maybe<Array<Maybe<User_Skills_Mutation_Response>>>;
  /** update data of the table: "user_activity_tracker" */
  update_user_activity_tracker?: Maybe<User_Activity_Tracker_Mutation_Response>;
  /** update single row of the table: "user_activity_tracker" */
  update_user_activity_tracker_by_pk?: Maybe<User_Activity_Tracker>;
  /** update multiples rows of table: "user_activity_tracker" */
  update_user_activity_tracker_many?: Maybe<
    Array<Maybe<User_Activity_Tracker_Mutation_Response>>
  >;
  /** update data of the table: "user_avatar" */
  update_user_avatar?: Maybe<User_Avatar_Mutation_Response>;
  /** update single row of the table: "user_avatar" */
  update_user_avatar_by_pk?: Maybe<User_Avatar>;
  /** update data of the table: "user_avatar_files" */
  update_user_avatar_files?: Maybe<User_Avatar_Files_Mutation_Response>;
  /** update single row of the table: "user_avatar_files" */
  update_user_avatar_files_by_pk?: Maybe<User_Avatar_Files>;
  /** update multiples rows of table: "user_avatar_files" */
  update_user_avatar_files_many?: Maybe<
    Array<Maybe<User_Avatar_Files_Mutation_Response>>
  >;
  /** update multiples rows of table: "user_avatar" */
  update_user_avatar_many?: Maybe<Array<Maybe<User_Avatar_Mutation_Response>>>;
  /** update data of the table: "user_career_development_record" */
  update_user_career_development_record?: Maybe<User_Career_Development_Record_Mutation_Response>;
  /** update single row of the table: "user_career_development_record" */
  update_user_career_development_record_by_pk?: Maybe<User_Career_Development_Record>;
  /** update multiples rows of table: "user_career_development_record" */
  update_user_career_development_record_many?: Maybe<
    Array<Maybe<User_Career_Development_Record_Mutation_Response>>
  >;
  /** update data of the table: "user_career_external_certification" */
  update_user_career_external_certification?: Maybe<User_Career_External_Certification_Mutation_Response>;
  /** update single row of the table: "user_career_external_certification" */
  update_user_career_external_certification_by_pk?: Maybe<User_Career_External_Certification>;
  /** update multiples rows of table: "user_career_external_certification" */
  update_user_career_external_certification_many?: Maybe<
    Array<Maybe<User_Career_External_Certification_Mutation_Response>>
  >;
  /** update data of the table: "user_presence" */
  update_user_presence?: Maybe<User_Presence_Mutation_Response>;
  /** update single row of the table: "user_presence" */
  update_user_presence_by_pk?: Maybe<User_Presence>;
  /** update multiples rows of table: "user_presence" */
  update_user_presence_many?: Maybe<
    Array<Maybe<User_Presence_Mutation_Response>>
  >;
  /** update data of the table: "user_settings" */
  update_user_settings?: Maybe<User_Settings_Mutation_Response>;
  /** update single row of the table: "user_settings" */
  update_user_settings_by_pk?: Maybe<User_Settings>;
  /** update multiples rows of table: "user_settings" */
  update_user_settings_many?: Maybe<
    Array<Maybe<User_Settings_Mutation_Response>>
  >;
  /** update data of the table: "user_state" */
  update_user_state?: Maybe<User_State_Mutation_Response>;
  /** update single row of the table: "user_state" */
  update_user_state_by_pk?: Maybe<User_State>;
  /** update multiples rows of table: "user_state" */
  update_user_state_many?: Maybe<Array<Maybe<User_State_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootActivateAccountArgs = {
  object: ActivateInput;
};

/** mutation root */
export type Mutation_RootAddChatEmoteReactionArgs = {
  object: AddChatEmoteReactionInput;
};

/** mutation root */
export type Mutation_RootAddCommentArgs = {
  object: AddCommentInput;
};

/** mutation root */
export type Mutation_RootAddCompetencyArgs = {
  object: AddCompetencyInput;
};

/** mutation root */
export type Mutation_RootAddMemberToProjectArgs = {
  object: AddMemberDetailsInput;
};

/** mutation root */
export type Mutation_RootAddNewLeaveTypeArgs = {
  object: AddNewLeaveTypeInput;
};

/** mutation root */
export type Mutation_RootAddPeerArgs = {
  object: PeerInput;
};

/** mutation root */
export type Mutation_RootAddSkillInfoArgs = {
  Object: AddSkillDetailsInput;
};

/** mutation root */
export type Mutation_RootAddUserSkillsArgs = {
  object: AddUsersSkillsAndComptInput;
};

/** mutation root */
export type Mutation_RootAddUserToCareerDevelopmentPlanArgs = {
  object: AddAssignUserToCareerPlanInput;
};

/** mutation root */
export type Mutation_RootAddUsersToCallArgs = {
  object: AddUsersToCallInput;
};

/** mutation root */
export type Mutation_RootAddUsersToChatArgs = {
  object: AddUsersToChatInput;
};

/** mutation root */
export type Mutation_RootAssignAwardArgs = {
  object: AssignAwardInput;
};

/** mutation root */
export type Mutation_RootAttendAssessmentArgs = {
  object: AttendAssessmentInput;
};

/** mutation root */
export type Mutation_RootCareerViewStatsArgs = {
  object: CareerViewStatsInput;
};

/** mutation root */
export type Mutation_RootCheckGoalCountArgs = {
  object: GoalCountPerTimeperiodInput;
};

/** mutation root */
export type Mutation_RootContactUsArgs = {
  object: ContactUsInput;
};

/** mutation root */
export type Mutation_RootCreateAnAnnouncementArgs = {
  object: CreateAnnouncementInput;
};

/** mutation root */
export type Mutation_RootCreateAssessmentArgs = {
  object: AssessmentInput;
};

/** mutation root */
export type Mutation_RootCreateAwardTypeArgs = {
  object: CreateAwardTypeInput;
};

/** mutation root */
export type Mutation_RootCreateCalendarEventArgs = {
  object: CreateCalendarEventInput;
};

/** mutation root */
export type Mutation_RootCreateCalendarOneToOneArgs = {
  object: CalendarEventOneToOneInput;
};

/** mutation root */
export type Mutation_RootCreateCallTokenArgs = {
  object: CallTokenInput;
};

/** mutation root */
export type Mutation_RootCreateCareerDevelopmentPlanArgs = {
  object: CareerDevelopmentInput;
};

/** mutation root */
export type Mutation_RootCreateChecklistArgs = {
  checklist: ChecklistInput;
};

/** mutation root */
export type Mutation_RootCreateGoalKpiArgs = {
  object: GoalKpiInput;
};

/** mutation root */
export type Mutation_RootCreateGoalsArgs = {
  object: GoalsInput;
};

/** mutation root */
export type Mutation_RootCreateKpiArgs = {
  object: KpiInput;
};

/** mutation root */
export type Mutation_RootCreateLeaveCycleArgs = {
  object: LeaveCycleInput;
};

/** mutation root */
export type Mutation_RootCreateMeetCallArgs = {
  eventId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootCreateMeetingRoomArgs = {
  object: CreateMeetingRoomInput;
};

/** mutation root */
export type Mutation_RootCreateMilestoneArgs = {
  milestone: CreateMilestoneInput;
};

/** mutation root */
export type Mutation_RootCreateOrganizationArgs = {
  object: CreateOrganizationInput;
};

/** mutation root */
export type Mutation_RootCreateOrganizationOwnerArgs = {
  profile: ProfileInput;
  user: UserInput;
};

/** mutation root */
export type Mutation_RootCreatePerformanceReviewArgs = {
  object: PerformanceReviewInput;
};

/** mutation root */
export type Mutation_RootCreateProjectArgs = {
  client: ClientInput;
  project: ProjectInput;
};

/** mutation root */
export type Mutation_RootCreateReviewUsersArgs = {
  object: PerformanceReviewUsersInput;
};

/** mutation root */
export type Mutation_RootCreateSprintArgs = {
  data: CreateSprintInput;
};

/** mutation root */
export type Mutation_RootCreateTaskArgs = {
  attachedFiles?: InputMaybe<Array<Scalars['uuid']>>;
  collaborators?: InputMaybe<Array<Scalars['uuid']>>;
  milestones?: InputMaybe<Array<Scalars['uuid']>>;
  tags?: InputMaybe<Array<Scalars['uuid']>>;
  task: TaskInput;
};

/** mutation root */
export type Mutation_RootCreateTrainingArgs = {
  object: TrainingInput;
};

/** mutation root */
export type Mutation_RootCreateTrainingProgressArgs = {
  object: TrainingProgressInput;
};

/** mutation root */
export type Mutation_RootDeleteCalendarEventArgs = {
  object: DeleteCalendarEventInput;
};

/** mutation root */
export type Mutation_RootDeleteEmployeeArgs = {
  object: DeleteEmployeeInput;
};

/** mutation root */
export type Mutation_RootDeleteGoalArgs = {
  object: DeleteGoalInput;
};

/** mutation root */
export type Mutation_RootDeleteOrgAdminArgs = {
  object: DeleteOrgAdminInput;
};

/** mutation root */
export type Mutation_RootDeleteOrgLocationArgs = {
  object: DeleteOrgLocationInput;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationRoleArgs = {
  object: DeleteOrgRoleInput;
};

/** mutation root */
export type Mutation_RootDeleteProjectArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSprintArgs = {
  data: DeleteSprintInput;
};

/** mutation root */
export type Mutation_RootDelete_User_Peer_ReviewArgs = {
  where: User_Peer_Review_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Peer_Review_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_AssessmentArgs = {
  where: Assessment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_SkillsArgs = {
  where: Assessment_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assessment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assessment_FeedbackArgs = {
  where: Assessment_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assessment_RecordArgs = {
  where: Assessment_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Role_DetailsArgs = {
  where: Assessment_Role_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Stage_CareerArgs = {
  where: Assessment_Stage_Career_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Stage_Career_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assessment_TypeArgs = {
  where: Assessment_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assign_Career_Development_PlanArgs = {
  where: Assign_Career_Development_Plan_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assign_Career_Development_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Audit_User_StateArgs = {
  where: Audit_User_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Audit_User_State_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_Account_ProvidersArgs = {
  where: Auth_Account_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_Account_RolesArgs = {
  where: Auth_Account_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_AccountsArgs = {
  where: Auth_Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_ProvidersArgs = {
  where: Auth_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_TokensArgs = {
  where: Auth_Refresh_Tokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_RolesArgs = {
  where: Auth_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Roles_By_PkArgs = {
  role: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Award_PostsArgs = {
  where: Award_Posts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Award_Posts_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_AwardsArgs = {
  where: Awards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Awards_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CalendarArgs = {
  where: Calendar_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendar_AttendeeArgs = {
  where: Calendar_Attendee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Attendee_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Calendar_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Delete_EventsArgs = {
  where: Calendar_Delete_Events_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Delete_Events_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Calendar_EventArgs = {
  where: Calendar_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Meeting_CallArgs = {
  where: Calendar_Meeting_Call_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Meeting_Call_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Meeting_Call_RequestArgs = {
  where: Calendar_Meeting_Call_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Meeting_Call_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Meeting_Call_UserArgs = {
  where: Calendar_Meeting_Call_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendar_Meeting_Call_User_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CallArgs = {
  where: Call_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Call_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Call_RequestArgs = {
  where: Call_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Call_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Call_UserArgs = {
  where: Call_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Call_User_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Career_Development_DetailArgs = {
  where: Career_Development_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Career_Development_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Career_Development_Role_DetailsArgs = {
  where: Career_Development_Role_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Career_Development_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Career_External_CertificationArgs = {
  where: Career_External_Certification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Career_External_Certification_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_MessageArgs = {
  where: Chat_Message_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Message_ActionArgs = {
  where: Chat_Message_Action_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Message_Action_By_PkArgs = {
  message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Message_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Message_FileArgs = {
  where: Chat_Message_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Message_File_By_PkArgs = {
  chat_message_id: Scalars['uuid'];
  storage_file_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Message_ReactionsArgs = {
  where: Chat_Message_Reactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Message_Reactions_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_PinnedArgs = {
  where: Chat_Pinned_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Pinned_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_RoomArgs = {
  where: Chat_Room_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Room_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Room_UserArgs = {
  where: Chat_Room_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Room_User_By_PkArgs = {
  chat_room_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Dashboard_RemaindersArgs = {
  where: Dashboard_Remainders_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dashboard_Remainders_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_DepartmentArgs = {
  where: Department_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Department_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Department_EmployeeArgs = {
  where: Department_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Department_Employee_By_PkArgs = {
  department_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Document_TokenArgs = {
  where: Document_Token_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Document_Token_By_PkArgs = {
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Email_TemplatesArgs = {
  where: Email_Templates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Email_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Employee_Bandwidth_DataArgs = {
  where: Employee_Bandwidth_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Employee_Bandwidth_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Employee_CapacityArgs = {
  where: Employee_Capacity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Employee_Capacity_By_PkArgs = {
  date: Scalars['date'];
  employee_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Activity_StateArgs = {
  where: Enum_Activity_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Activity_State_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Activity_TypeArgs = {
  where: Enum_Activity_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Activity_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Attendee_Status_TypeArgs = {
  where: Enum_Attendee_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Attendee_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Attendee_TypeArgs = {
  where: Enum_Attendee_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Attendee_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Award_Cycle_TypeArgs = {
  where: Enum_Award_Cycle_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Award_Cycle_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Access_PolicyArgs = {
  where: Enum_Call_Access_Policy_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Access_Policy_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Broadcasting_TypeArgs = {
  where: Enum_Call_Broadcasting_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Broadcasting_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Media_TypeArgs = {
  where: Enum_Call_Media_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Media_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Recording_StatusArgs = {
  where: Enum_Call_Recording_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Recording_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Request_Response_TypeArgs = {
  where: Enum_Call_Request_Response_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Request_Response_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Request_Status_TypeArgs = {
  where: Enum_Call_Request_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Request_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Request_TypeArgs = {
  where: Enum_Call_Request_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Request_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Status_TypeArgs = {
  where: Enum_Call_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_TypeArgs = {
  where: Enum_Call_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_User_StatusArgs = {
  where: Enum_Call_User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Call_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Message_TypeArgs = {
  where: Enum_Chat_Message_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Message_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_NotificationArgs = {
  where: Enum_Chat_Notification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Notification_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Room_Notification_StatusArgs = {
  where: Enum_Chat_Room_Notification_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Room_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Room_TypeArgs = {
  where: Enum_Chat_Room_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Room_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Room_User_StatusArgs = {
  where: Enum_Chat_Room_User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Chat_Room_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Dashboard_Remainder_TypeArgs = {
  where: Enum_Dashboard_Remainder_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Dashboard_Remainder_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Document_Token_TypeArgs = {
  where: Enum_Document_Token_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Document_Token_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Event_TypeArgs = {
  where: Enum_Event_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Event_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Leave_Donate_TypeArgs = {
  where: Enum_Leave_Donate_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Leave_Donate_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Leave_Status_TypeArgs = {
  where: Enum_Leave_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Leave_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Live_Call_Request_StatusArgs = {
  where: Enum_Live_Call_Request_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Live_Call_Request_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Live_Call_StatusArgs = {
  where: Enum_Live_Call_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Live_Call_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Live_Call_User_StatusArgs = {
  where: Enum_Live_Call_User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Live_Call_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Live_Emote_TypeArgs = {
  where: Enum_Live_Emote_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Live_Emote_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Manual_StateArgs = {
  where: Enum_Manual_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Manual_State_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Meeting_Room_StatusArgs = {
  where: Enum_Meeting_Room_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Meeting_Room_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Meeting_Room_TypeArgs = {
  where: Enum_Meeting_Room_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Meeting_Room_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Meeting_Room_User_StateArgs = {
  where: Enum_Meeting_Room_User_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Meeting_Room_User_State_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Notification_TypeArgs = {
  where: Enum_Notification_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Personalised_AccessArgs = {
  where: Enum_Personalised_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Personalised_Access_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Personalised_CategoriesArgs = {
  where: Enum_Personalised_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Personalised_Categories_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Post_TypeArgs = {
  where: Enum_Post_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Post_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Presence_Connection_StatusArgs = {
  where: Enum_Presence_Connection_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Presence_Connection_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Project_Milestone_StatusArgs = {
  where: Enum_Project_Milestone_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Project_Milestone_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Project_Sprint_CategoryArgs = {
  where: Enum_Project_Sprint_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Project_Sprint_Category_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Project_StatusArgs = {
  where: Enum_Project_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Project_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Project_Task_CategoryArgs = {
  where: Enum_Project_Task_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Project_Task_Category_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Setting_TypeArgs = {
  where: Enum_Setting_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Setting_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Storage_File_TypeArgs = {
  where: Enum_Storage_File_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Storage_File_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_System_StateArgs = {
  where: Enum_System_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_System_State_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_System_State_OwnerArgs = {
  where: Enum_System_State_Owner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_System_State_Owner_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Task_PriorityArgs = {
  where: Enum_Task_Priority_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Task_Priority_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Task_Recurring_FrequencyArgs = {
  where: Enum_Task_Recurring_Frequency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Task_Recurring_Frequency_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Task_StatusArgs = {
  where: Enum_Task_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Task_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Task_Time_Sheet_StatusArgs = {
  where: Enum_Task_Time_Sheet_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Task_Time_Sheet_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_Activity_Tracker_Status_TypeArgs = {
  where: Enum_User_Activity_Tracker_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_Activity_Tracker_Status_Type_By_PkArgs =
  {
    value: Scalars['String'];
  };

/** mutation root */
export type Mutation_RootDelete_Enum_User_Avatar_TypesArgs = {
  where: Enum_User_Avatar_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_Avatar_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_GenderArgs = {
  where: Enum_User_Gender_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_Gender_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_Presence_Provider_TypeArgs = {
  where: Enum_User_Presence_Provider_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_Presence_Provider_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_Settings_TypeArgs = {
  where: Enum_User_Settings_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_User_Settings_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Filter_DefinitionArgs = {
  where: Filter_Definition_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Filter_Definition_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_FinancialYearArgs = {
  where: FinancialYear_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_FinancialYear_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_FrequencyArgs = {
  where: Frequency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Frequency_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_GoalWithKpiArgs = {
  where: GoalWithKpi_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GoalWithKpi_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_GoalWithTimePeriodArgs = {
  where: GoalWithTimePeriod_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GoalWithTimePeriod_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_GoalsArgs = {
  where: Goals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GoalsWithDeptsArgs = {
  where: GoalsWithDepts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GoalsWithDepts_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_GoalsWithFreqsArgs = {
  where: GoalsWithFreqs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GoalsWithFreqs_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_GoalsWithRolesArgs = {
  where: GoalsWithRoles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GoalsWithRoles_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Goals_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hotdesk_BookingsArgs = {
  where: Hotdesk_Bookings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hotdesk_Bookings_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_KpiWithDeptArgs = {
  where: KpiWithDept_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_KpiWithDept_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_KpiWithRoleArgs = {
  where: KpiWithRole_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_KpiWithRole_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Kpi_BankArgs = {
  where: Kpi_Bank_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kpi_Bank_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_LeaveArgs = {
  where: Leave_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Leave_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Leave_DonateArgs = {
  where: Leave_Donate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Leave_Donate_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Leave_OrganizationArgs = {
  where: Leave_Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Leave_Organization_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_List_Of_SkillsArgs = {
  where: List_Of_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_List_Of_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Live_CallArgs = {
  where: Live_Call_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Live_Call_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Live_Call_RequestArgs = {
  where: Live_Call_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Live_Call_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Live_Call_UserArgs = {
  where: Live_Call_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Live_Call_User_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Main_SkillsArgs = {
  where: Main_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Main_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Meeting_Call_SchedularArgs = {
  where: Meeting_Call_Schedular_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Meeting_Call_Schedular_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Meeting_RoomArgs = {
  where: Meeting_Room_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Meeting_Room_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Meeting_Room_UsersArgs = {
  where: Meeting_Room_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Meeting_Room_Users_By_PkArgs = {
  meeting_room_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_OrganizationArgs = {
  where: Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Active_Leave_TypesArgs = {
  where: Organization_Active_Leave_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Active_Leave_Types_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_AdminArgs = {
  where: Organization_Admin_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Admin_By_PkArgs = {
  organization_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_AwardsArgs = {
  where: Organization_Awards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Awards_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_EmployeeArgs = {
  where: Organization_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Employee_By_PkArgs = {
  organization_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_HolidaysArgs = {
  where: Organization_Holidays_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Holidays_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Leave_CyclesArgs = {
  where: Organization_Leave_Cycles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Leave_Cycles_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_LocationsArgs = {
  where: Organization_Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_RolesArgs = {
  where: Organization_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_User_Leave_ApplicationArgs = {
  where: Organization_User_Leave_Application_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_User_Leave_Application_By_PkArgs =
  {
    id: Scalars['uuid'];
  };

/** mutation root */
export type Mutation_RootDelete_Organization_User_Leave_DataArgs = {
  where: Organization_User_Leave_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_User_Leave_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_User_Leave_DatesArgs = {
  where: Organization_User_Leave_Dates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_User_Leave_Dates_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_User_Leave_FilesArgs = {
  where: Organization_User_Leave_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_User_Leave_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_PerformanceReviewStatusArgs = {
  where: PerformanceReviewStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PerformanceReviewStatus_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Performance_Rating_ScaleArgs = {
  where: Performance_Rating_Scale_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Rating_Scale_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_ByUserArgs = {
  where: Performance_Review_ByUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_ByUser_KpiArgs = {
  where: Performance_Review_ByUser_Kpi_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_ByUser_Kpi_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_ByUser_WithGoalArgs = {
  where: Performance_Review_ByUser_WithGoal_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_ByUser_WithGoal_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_ByUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_Setting_GoalsListArgs = {
  where: Performance_Review_Setting_GoalsList_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_Setting_GoalsList_By_PkArgs =
  {
    id: Scalars['uuid'];
  };

/** mutation root */
export type Mutation_RootDelete_Performance_Review_Setting_With_GoalsArgs = {
  where: Performance_Review_Setting_With_Goals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_Setting_With_Goals_By_PkArgs =
  {
    id: Scalars['uuid'];
  };

/** mutation root */
export type Mutation_RootDelete_Performance_Review_SettingsArgs = {
  where: Performance_Review_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Review_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_PersonalisedArgs = {
  where: Personalised_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Personalised_By_PkArgs = {
  created_at: Scalars['timestamp'];
};

/** mutation root */
export type Mutation_RootDelete_Post_Comment_FilesArgs = {
  where: Post_Comment_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Post_Comment_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Post_Comment_Mentioned_UsersArgs = {
  where: Post_Comment_Mentioned_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Post_Comment_Mentioned_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Post_Comment_RepliesArgs = {
  where: Post_Comment_Replies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Post_Comment_Replies_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Post_Comment_Replies_Mentioned_UsersArgs = {
  where: Post_Comment_Replies_Mentioned_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Post_Comment_Replies_Mentioned_Users_By_PkArgs =
  {
    id: Scalars['uuid'];
  };

/** mutation root */
export type Mutation_RootDelete_Post_Comments_LikesArgs = {
  where: Post_Comments_Likes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Post_Comments_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Post_LikesArgs = {
  where: Post_Likes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Post_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Post_Mentioned_UsersArgs = {
  where: Post_Mentioned_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Post_Mentioned_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Post_Replies_LikesArgs = {
  where: Post_Replies_Likes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Post_Replies_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_PostsArgs = {
  where: Posts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Posts_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Posts_CommentsArgs = {
  where: Posts_Comments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Posts_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Posts_FilesArgs = {
  where: Posts_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Posts_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Posts_Poll_ChoicesArgs = {
  where: Posts_Poll_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Posts_Poll_Choices_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Posts_Poll_Choices_VotesArgs = {
  where: Posts_Poll_Choices_Votes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Posts_Poll_Choices_Votes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProfileArgs = {
  where: Profile_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProjectArgs = {
  where: Project_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_ClientArgs = {
  where: Project_Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Client_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_LinksArgs = {
  where: Project_Links_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Links_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_MembersArgs = {
  where: Project_Members_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Members_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_MilestonesArgs = {
  where: Project_Milestones_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Milestones_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Notification_SettingsArgs = {
  where: Project_Notification_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Notification_Settings_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_SectionArgs = {
  where: Project_Section_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Section_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_SprintsArgs = {
  where: Project_Sprints_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Sprints_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Question_OptionsArgs = {
  where: Question_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Question_Options_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Skills_DetailArgs = {
  where: Skills_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Skills_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Stage_Career_DetailArgs = {
  where: Stage_Career_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stage_Career_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Storage_FilesArgs = {
  where: Storage_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Storage_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Stress_DataArgs = {
  where: Stress_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stress_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Sub_SkillsArgs = {
  where: Sub_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sub_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TaskArgs = {
  where: Task_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_ApproversArgs = {
  where: Task_Approvers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Approvers_By_PkArgs = {
  approver_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_ChecklistArgs = {
  where: Task_Checklist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Checklist_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_CollaboratorArgs = {
  where: Task_Collaborator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Collaborator_By_PkArgs = {
  task_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_CommentArgs = {
  where: Task_Comment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_Comment_LikeArgs = {
  where: Task_Comment_Like_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Comment_Like_By_PkArgs = {
  task_comment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_DependenciesArgs = {
  where: Task_Dependencies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Dependencies_By_PkArgs = {
  dependent_on_task_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_FileArgs = {
  where: Task_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_File_By_PkArgs = {
  storage_file_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_MilestonesArgs = {
  where: Task_Milestones_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Milestones_By_PkArgs = {
  milestone_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_TagsArgs = {
  where: Task_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Tags_By_PkArgs = {
  tag_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_Time_SheetArgs = {
  where: Task_Time_Sheet_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Time_Sheet_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_UnscheduledArgs = {
  where: Task_Unscheduled_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Unscheduled_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Time_PeriodArgs = {
  where: Time_Period_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Time_Period_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TrainingArgs = {
  where: Training_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_SkillArgs = {
  where: Training_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Skill_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Training_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Training_FeedbackArgs = {
  where: Training_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Training_Progress_RecordArgs = {
  where: Training_Progress_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Progress_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Training_Role_DetailsArgs = {
  where: Training_Role_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Training_Stage_CareerArgs = {
  where: Training_Stage_Career_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Stage_Career_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Training_Table_Of_ContentsArgs = {
  where: Training_Table_Of_Contents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Table_Of_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Training_Type_For_TrainingArgs = {
  where: Training_Type_For_Training_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Type_For_Training_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_CompetencyArgs = {
  where: User_Competency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Competency_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_SkillsArgs = {
  where: User_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Skills_AssessmentArgs = {
  where: User_Skills_Assessment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Skills_Assessment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Skills_TrainingArgs = {
  where: User_Skills_Training_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Skills_Training_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Activity_TrackerArgs = {
  where: User_Activity_Tracker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Activity_Tracker_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_AvatarArgs = {
  where: User_Avatar_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Avatar_By_PkArgs = {
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Avatar_FilesArgs = {
  where: User_Avatar_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Avatar_Files_By_PkArgs = {
  avatar_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Career_Development_RecordArgs = {
  where: User_Career_Development_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Career_Development_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Career_External_CertificationArgs = {
  where: User_Career_External_Certification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Career_External_Certification_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_PresenceArgs = {
  where: User_Presence_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Presence_By_PkArgs = {
  connection_id: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_SettingsArgs = {
  where: User_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_StateArgs = {
  where: User_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_State_By_PkArgs = {
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDropboxAuthenticationArgs = {
  object: DropboxAuthenticationInput;
};

/** mutation root */
export type Mutation_RootDropboxLogOutArgs = {
  object: DropboxLogoutInput;
};

/** mutation root */
export type Mutation_RootEditCommentArgs = {
  object: EditCommentInput;
};

/** mutation root */
export type Mutation_RootEditCompetencyArgs = {
  object: EditCompetencyInput;
};

/** mutation root */
export type Mutation_RootEditLeaveTypeArgs = {
  object: EditLeaveTypeInput;
};

/** mutation root */
export type Mutation_RootEditPostArgs = {
  object: EditPostInput;
};

/** mutation root */
export type Mutation_RootEditSkillDetailsArgs = {
  Object: EditSkillDetailsInput;
};

/** mutation root */
export type Mutation_RootEmployeeBandwidthDataArgs = {
  object: EmployeeBandwidthInput;
};

/** mutation root */
export type Mutation_RootExtendDayAlertArgs = {
  object: ExtendDayAlertInput;
};

/** mutation root */
export type Mutation_RootGetAllUserSkillDetailsArgs = {
  object: GetAllUsersBySkillInput;
};

/** mutation root */
export type Mutation_RootGetDashboardRemindersArgs = {
  object: RemainderInput;
};

/** mutation root */
export type Mutation_RootGetGoalListArgs = {
  object: GetGoalListPerRoleInput;
};

/** mutation root */
export type Mutation_RootGetGoalNoPerRolesArgs = {
  object: GoalCountPerRolesInput;
};

/** mutation root */
export type Mutation_RootGetKpiListArgs = {
  object: GetKpiListPerRoleInput;
};

/** mutation root */
export type Mutation_RootGetPerformanceReviewDetailInfoArgs = {
  object: PerfReviewInput;
};

/** mutation root */
export type Mutation_RootGetScoreSheetArgs = {
  object: GetScoreSheetInput;
};

/** mutation root */
export type Mutation_RootGetSkillResourceFilterArgs = {
  object: SkillResourceFilterInput;
};

/** mutation root */
export type Mutation_RootGetTimePeriodListArgs = {
  object: TimePeriodListInput;
};

/** mutation root */
export type Mutation_RootGetTwoXTwoMatrixArgs = {
  object: TwoXTwoMatrixInput;
};

/** mutation root */
export type Mutation_RootGetUserListForSkillApprovalArgs = {
  object: GetUSerListForSkillApprovalInput;
};

/** mutation root */
export type Mutation_RootGetUserSkillInDashboardArgs = {
  object: GetUserSkillDashboardInput;
};

/** mutation root */
export type Mutation_RootGetUsersSkillDetailArgs = {
  object: GetUserSkillInfoInput;
};

/** mutation root */
export type Mutation_RootGet_LatestAssessmentMarksArgs = {
  object: GetLatestAssessmentRecordMarksInput;
};

/** mutation root */
export type Mutation_RootGoalDetailByIdArgs = {
  object: GoalDetailsInput;
};

/** mutation root */
export type Mutation_RootGoogledriveAuthenticationArgs = {
  object: GoogledriveAuthenticationInput;
};

/** mutation root */
export type Mutation_RootGoogledriveLogOutArgs = {
  object: GoogledriveLogoutInput;
};

/** mutation root */
export type Mutation_RootGroupByFilterTasksArgs = {
  task: GroupByFilterInput;
};

/** mutation root */
export type Mutation_RootIndividualTimeSpentGraphArgs = {
  object: IndividualTimeSpentGraphInput;
};

/** mutation root */
export type Mutation_RootInsert_User_Peer_ReviewArgs = {
  objects: Array<User_Peer_Review_Insert_Input>;
  on_conflict?: InputMaybe<User_Peer_Review_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Peer_Review_OneArgs = {
  object: User_Peer_Review_Insert_Input;
  on_conflict?: InputMaybe<User_Peer_Review_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AssessmentArgs = {
  objects: Array<Assessment_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_SkillsArgs = {
  objects: Array<Assessment_Skills_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Skills_OneArgs = {
  object: Assessment_Skills_Insert_Input;
  on_conflict?: InputMaybe<Assessment_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_FeedbackArgs = {
  objects: Array<Assessment_Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Feedback_OneArgs = {
  object: Assessment_Feedback_Insert_Input;
  on_conflict?: InputMaybe<Assessment_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_OneArgs = {
  object: Assessment_Insert_Input;
  on_conflict?: InputMaybe<Assessment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_RecordArgs = {
  objects: Array<Assessment_Record_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Record_OneArgs = {
  object: Assessment_Record_Insert_Input;
  on_conflict?: InputMaybe<Assessment_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Role_DetailsArgs = {
  objects: Array<Assessment_Role_Details_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_Role_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Role_Details_OneArgs = {
  object: Assessment_Role_Details_Insert_Input;
  on_conflict?: InputMaybe<Assessment_Role_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Stage_CareerArgs = {
  objects: Array<Assessment_Stage_Career_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_Stage_Career_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Stage_Career_OneArgs = {
  object: Assessment_Stage_Career_Insert_Input;
  on_conflict?: InputMaybe<Assessment_Stage_Career_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_TypeArgs = {
  objects: Array<Assessment_Type_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Type_OneArgs = {
  object: Assessment_Type_Insert_Input;
  on_conflict?: InputMaybe<Assessment_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assign_Career_Development_PlanArgs = {
  objects: Array<Assign_Career_Development_Plan_Insert_Input>;
  on_conflict?: InputMaybe<Assign_Career_Development_Plan_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assign_Career_Development_Plan_OneArgs = {
  object: Assign_Career_Development_Plan_Insert_Input;
  on_conflict?: InputMaybe<Assign_Career_Development_Plan_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_User_StateArgs = {
  objects: Array<Audit_User_State_Insert_Input>;
  on_conflict?: InputMaybe<Audit_User_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_User_State_OneArgs = {
  object: Audit_User_State_Insert_Input;
  on_conflict?: InputMaybe<Audit_User_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Account_ProvidersArgs = {
  objects: Array<Auth_Account_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Account_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Account_Providers_OneArgs = {
  object: Auth_Account_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Account_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Account_RolesArgs = {
  objects: Array<Auth_Account_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Account_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Account_Roles_OneArgs = {
  object: Auth_Account_Roles_Insert_Input;
  on_conflict?: InputMaybe<Auth_Account_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_AccountsArgs = {
  objects: Array<Auth_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Accounts_OneArgs = {
  object: Auth_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_ProvidersArgs = {
  objects: Array<Auth_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Providers_OneArgs = {
  object: Auth_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_TokensArgs = {
  objects: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_Tokens_OneArgs = {
  object: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_RolesArgs = {
  objects: Array<Auth_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Roles_OneArgs = {
  object: Auth_Roles_Insert_Input;
  on_conflict?: InputMaybe<Auth_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Award_PostsArgs = {
  objects: Array<Award_Posts_Insert_Input>;
  on_conflict?: InputMaybe<Award_Posts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Award_Posts_OneArgs = {
  object: Award_Posts_Insert_Input;
  on_conflict?: InputMaybe<Award_Posts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AwardsArgs = {
  objects: Array<Awards_Insert_Input>;
  on_conflict?: InputMaybe<Awards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Awards_OneArgs = {
  object: Awards_Insert_Input;
  on_conflict?: InputMaybe<Awards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CalendarArgs = {
  objects: Array<Calendar_Insert_Input>;
  on_conflict?: InputMaybe<Calendar_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_AttendeeArgs = {
  objects: Array<Calendar_Attendee_Insert_Input>;
  on_conflict?: InputMaybe<Calendar_Attendee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Attendee_OneArgs = {
  object: Calendar_Attendee_Insert_Input;
  on_conflict?: InputMaybe<Calendar_Attendee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Delete_EventsArgs = {
  objects: Array<Calendar_Delete_Events_Insert_Input>;
  on_conflict?: InputMaybe<Calendar_Delete_Events_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Delete_Events_OneArgs = {
  object: Calendar_Delete_Events_Insert_Input;
  on_conflict?: InputMaybe<Calendar_Delete_Events_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_EventArgs = {
  objects: Array<Calendar_Event_Insert_Input>;
  on_conflict?: InputMaybe<Calendar_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Event_OneArgs = {
  object: Calendar_Event_Insert_Input;
  on_conflict?: InputMaybe<Calendar_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Meeting_CallArgs = {
  objects: Array<Calendar_Meeting_Call_Insert_Input>;
  on_conflict?: InputMaybe<Calendar_Meeting_Call_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Meeting_Call_OneArgs = {
  object: Calendar_Meeting_Call_Insert_Input;
  on_conflict?: InputMaybe<Calendar_Meeting_Call_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Meeting_Call_RequestArgs = {
  objects: Array<Calendar_Meeting_Call_Request_Insert_Input>;
  on_conflict?: InputMaybe<Calendar_Meeting_Call_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Meeting_Call_Request_OneArgs = {
  object: Calendar_Meeting_Call_Request_Insert_Input;
  on_conflict?: InputMaybe<Calendar_Meeting_Call_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Meeting_Call_UserArgs = {
  objects: Array<Calendar_Meeting_Call_User_Insert_Input>;
  on_conflict?: InputMaybe<Calendar_Meeting_Call_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_Meeting_Call_User_OneArgs = {
  object: Calendar_Meeting_Call_User_Insert_Input;
  on_conflict?: InputMaybe<Calendar_Meeting_Call_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendar_OneArgs = {
  object: Calendar_Insert_Input;
  on_conflict?: InputMaybe<Calendar_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CallArgs = {
  objects: Array<Call_Insert_Input>;
  on_conflict?: InputMaybe<Call_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Call_OneArgs = {
  object: Call_Insert_Input;
  on_conflict?: InputMaybe<Call_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Call_RequestArgs = {
  objects: Array<Call_Request_Insert_Input>;
  on_conflict?: InputMaybe<Call_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Call_Request_OneArgs = {
  object: Call_Request_Insert_Input;
  on_conflict?: InputMaybe<Call_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Call_UserArgs = {
  objects: Array<Call_User_Insert_Input>;
  on_conflict?: InputMaybe<Call_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Call_User_OneArgs = {
  object: Call_User_Insert_Input;
  on_conflict?: InputMaybe<Call_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Career_Development_DetailArgs = {
  objects: Array<Career_Development_Detail_Insert_Input>;
  on_conflict?: InputMaybe<Career_Development_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Career_Development_Detail_OneArgs = {
  object: Career_Development_Detail_Insert_Input;
  on_conflict?: InputMaybe<Career_Development_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Career_Development_Role_DetailsArgs = {
  objects: Array<Career_Development_Role_Details_Insert_Input>;
  on_conflict?: InputMaybe<Career_Development_Role_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Career_Development_Role_Details_OneArgs = {
  object: Career_Development_Role_Details_Insert_Input;
  on_conflict?: InputMaybe<Career_Development_Role_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Career_External_CertificationArgs = {
  objects: Array<Career_External_Certification_Insert_Input>;
  on_conflict?: InputMaybe<Career_External_Certification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Career_External_Certification_OneArgs = {
  object: Career_External_Certification_Insert_Input;
  on_conflict?: InputMaybe<Career_External_Certification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_MessageArgs = {
  objects: Array<Chat_Message_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Message_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Message_ActionArgs = {
  objects: Array<Chat_Message_Action_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Message_Action_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Message_Action_OneArgs = {
  object: Chat_Message_Action_Insert_Input;
  on_conflict?: InputMaybe<Chat_Message_Action_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Message_FileArgs = {
  objects: Array<Chat_Message_File_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Message_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Message_File_OneArgs = {
  object: Chat_Message_File_Insert_Input;
  on_conflict?: InputMaybe<Chat_Message_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Message_OneArgs = {
  object: Chat_Message_Insert_Input;
  on_conflict?: InputMaybe<Chat_Message_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Message_ReactionsArgs = {
  objects: Array<Chat_Message_Reactions_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Message_Reactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Message_Reactions_OneArgs = {
  object: Chat_Message_Reactions_Insert_Input;
  on_conflict?: InputMaybe<Chat_Message_Reactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_PinnedArgs = {
  objects: Array<Chat_Pinned_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Pinned_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Pinned_OneArgs = {
  object: Chat_Pinned_Insert_Input;
  on_conflict?: InputMaybe<Chat_Pinned_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_RoomArgs = {
  objects: Array<Chat_Room_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Room_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Room_OneArgs = {
  object: Chat_Room_Insert_Input;
  on_conflict?: InputMaybe<Chat_Room_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Room_UserArgs = {
  objects: Array<Chat_Room_User_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Room_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Room_User_OneArgs = {
  object: Chat_Room_User_Insert_Input;
  on_conflict?: InputMaybe<Chat_Room_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dashboard_RemaindersArgs = {
  objects: Array<Dashboard_Remainders_Insert_Input>;
  on_conflict?: InputMaybe<Dashboard_Remainders_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dashboard_Remainders_OneArgs = {
  object: Dashboard_Remainders_Insert_Input;
  on_conflict?: InputMaybe<Dashboard_Remainders_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DepartmentArgs = {
  objects: Array<Department_Insert_Input>;
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Department_EmployeeArgs = {
  objects: Array<Department_Employee_Insert_Input>;
  on_conflict?: InputMaybe<Department_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Department_Employee_OneArgs = {
  object: Department_Employee_Insert_Input;
  on_conflict?: InputMaybe<Department_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Department_OneArgs = {
  object: Department_Insert_Input;
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Document_TokenArgs = {
  objects: Array<Document_Token_Insert_Input>;
  on_conflict?: InputMaybe<Document_Token_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Document_Token_OneArgs = {
  object: Document_Token_Insert_Input;
  on_conflict?: InputMaybe<Document_Token_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_TemplatesArgs = {
  objects: Array<Email_Templates_Insert_Input>;
  on_conflict?: InputMaybe<Email_Templates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Templates_OneArgs = {
  object: Email_Templates_Insert_Input;
  on_conflict?: InputMaybe<Email_Templates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Employee_Bandwidth_DataArgs = {
  objects: Array<Employee_Bandwidth_Data_Insert_Input>;
  on_conflict?: InputMaybe<Employee_Bandwidth_Data_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Employee_Bandwidth_Data_OneArgs = {
  object: Employee_Bandwidth_Data_Insert_Input;
  on_conflict?: InputMaybe<Employee_Bandwidth_Data_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Employee_CapacityArgs = {
  objects: Array<Employee_Capacity_Insert_Input>;
  on_conflict?: InputMaybe<Employee_Capacity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Employee_Capacity_OneArgs = {
  object: Employee_Capacity_Insert_Input;
  on_conflict?: InputMaybe<Employee_Capacity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Activity_StateArgs = {
  objects: Array<Enum_Activity_State_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Activity_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Activity_State_OneArgs = {
  object: Enum_Activity_State_Insert_Input;
  on_conflict?: InputMaybe<Enum_Activity_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Activity_TypeArgs = {
  objects: Array<Enum_Activity_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Activity_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Activity_Type_OneArgs = {
  object: Enum_Activity_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Activity_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Attendee_Status_TypeArgs = {
  objects: Array<Enum_Attendee_Status_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Attendee_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Attendee_Status_Type_OneArgs = {
  object: Enum_Attendee_Status_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Attendee_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Attendee_TypeArgs = {
  objects: Array<Enum_Attendee_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Attendee_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Attendee_Type_OneArgs = {
  object: Enum_Attendee_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Attendee_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Award_Cycle_TypeArgs = {
  objects: Array<Enum_Award_Cycle_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Award_Cycle_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Award_Cycle_Type_OneArgs = {
  object: Enum_Award_Cycle_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Award_Cycle_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Access_PolicyArgs = {
  objects: Array<Enum_Call_Access_Policy_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Access_Policy_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Access_Policy_OneArgs = {
  object: Enum_Call_Access_Policy_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Access_Policy_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Broadcasting_TypeArgs = {
  objects: Array<Enum_Call_Broadcasting_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Broadcasting_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Broadcasting_Type_OneArgs = {
  object: Enum_Call_Broadcasting_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Broadcasting_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Media_TypeArgs = {
  objects: Array<Enum_Call_Media_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Media_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Media_Type_OneArgs = {
  object: Enum_Call_Media_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Media_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Recording_StatusArgs = {
  objects: Array<Enum_Call_Recording_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Recording_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Recording_Status_OneArgs = {
  object: Enum_Call_Recording_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Recording_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Request_Response_TypeArgs = {
  objects: Array<Enum_Call_Request_Response_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Request_Response_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Request_Response_Type_OneArgs = {
  object: Enum_Call_Request_Response_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Request_Response_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Request_Status_TypeArgs = {
  objects: Array<Enum_Call_Request_Status_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Request_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Request_Status_Type_OneArgs = {
  object: Enum_Call_Request_Status_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Request_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Request_TypeArgs = {
  objects: Array<Enum_Call_Request_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Request_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Request_Type_OneArgs = {
  object: Enum_Call_Request_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Request_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Status_TypeArgs = {
  objects: Array<Enum_Call_Status_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Status_Type_OneArgs = {
  object: Enum_Call_Status_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_TypeArgs = {
  objects: Array<Enum_Call_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_Type_OneArgs = {
  object: Enum_Call_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_User_StatusArgs = {
  objects: Array<Enum_Call_User_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Call_User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Call_User_Status_OneArgs = {
  object: Enum_Call_User_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Call_User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Message_TypeArgs = {
  objects: Array<Enum_Chat_Message_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Chat_Message_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Message_Type_OneArgs = {
  object: Enum_Chat_Message_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Chat_Message_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_NotificationArgs = {
  objects: Array<Enum_Chat_Notification_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Chat_Notification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Notification_OneArgs = {
  object: Enum_Chat_Notification_Insert_Input;
  on_conflict?: InputMaybe<Enum_Chat_Notification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Room_Notification_StatusArgs = {
  objects: Array<Enum_Chat_Room_Notification_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Chat_Room_Notification_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Room_Notification_Status_OneArgs = {
  object: Enum_Chat_Room_Notification_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Chat_Room_Notification_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Room_TypeArgs = {
  objects: Array<Enum_Chat_Room_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Chat_Room_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Room_Type_OneArgs = {
  object: Enum_Chat_Room_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Chat_Room_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Room_User_StatusArgs = {
  objects: Array<Enum_Chat_Room_User_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Chat_Room_User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Chat_Room_User_Status_OneArgs = {
  object: Enum_Chat_Room_User_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Chat_Room_User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Dashboard_Remainder_TypeArgs = {
  objects: Array<Enum_Dashboard_Remainder_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Dashboard_Remainder_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Dashboard_Remainder_Type_OneArgs = {
  object: Enum_Dashboard_Remainder_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Dashboard_Remainder_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Document_Token_TypeArgs = {
  objects: Array<Enum_Document_Token_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Document_Token_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Document_Token_Type_OneArgs = {
  object: Enum_Document_Token_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Document_Token_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Event_TypeArgs = {
  objects: Array<Enum_Event_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Event_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Event_Type_OneArgs = {
  object: Enum_Event_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Event_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Leave_Donate_TypeArgs = {
  objects: Array<Enum_Leave_Donate_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Leave_Donate_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Leave_Donate_Type_OneArgs = {
  object: Enum_Leave_Donate_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Leave_Donate_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Leave_Status_TypeArgs = {
  objects: Array<Enum_Leave_Status_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Leave_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Leave_Status_Type_OneArgs = {
  object: Enum_Leave_Status_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Leave_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Live_Call_Request_StatusArgs = {
  objects: Array<Enum_Live_Call_Request_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Live_Call_Request_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Live_Call_Request_Status_OneArgs = {
  object: Enum_Live_Call_Request_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Live_Call_Request_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Live_Call_StatusArgs = {
  objects: Array<Enum_Live_Call_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Live_Call_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Live_Call_Status_OneArgs = {
  object: Enum_Live_Call_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Live_Call_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Live_Call_User_StatusArgs = {
  objects: Array<Enum_Live_Call_User_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Live_Call_User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Live_Call_User_Status_OneArgs = {
  object: Enum_Live_Call_User_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Live_Call_User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Live_Emote_TypeArgs = {
  objects: Array<Enum_Live_Emote_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Live_Emote_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Live_Emote_Type_OneArgs = {
  object: Enum_Live_Emote_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Live_Emote_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Manual_StateArgs = {
  objects: Array<Enum_Manual_State_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Manual_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Manual_State_OneArgs = {
  object: Enum_Manual_State_Insert_Input;
  on_conflict?: InputMaybe<Enum_Manual_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Meeting_Room_StatusArgs = {
  objects: Array<Enum_Meeting_Room_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Meeting_Room_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Meeting_Room_Status_OneArgs = {
  object: Enum_Meeting_Room_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Meeting_Room_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Meeting_Room_TypeArgs = {
  objects: Array<Enum_Meeting_Room_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Meeting_Room_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Meeting_Room_Type_OneArgs = {
  object: Enum_Meeting_Room_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Meeting_Room_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Meeting_Room_User_StateArgs = {
  objects: Array<Enum_Meeting_Room_User_State_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Meeting_Room_User_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Meeting_Room_User_State_OneArgs = {
  object: Enum_Meeting_Room_User_State_Insert_Input;
  on_conflict?: InputMaybe<Enum_Meeting_Room_User_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Notification_TypeArgs = {
  objects: Array<Enum_Notification_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Notification_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Notification_Type_OneArgs = {
  object: Enum_Notification_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Notification_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Personalised_AccessArgs = {
  objects: Array<Enum_Personalised_Access_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Personalised_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Personalised_Access_OneArgs = {
  object: Enum_Personalised_Access_Insert_Input;
  on_conflict?: InputMaybe<Enum_Personalised_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Personalised_CategoriesArgs = {
  objects: Array<Enum_Personalised_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Personalised_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Personalised_Categories_OneArgs = {
  object: Enum_Personalised_Categories_Insert_Input;
  on_conflict?: InputMaybe<Enum_Personalised_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Post_TypeArgs = {
  objects: Array<Enum_Post_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Post_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Post_Type_OneArgs = {
  object: Enum_Post_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Post_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Presence_Connection_StatusArgs = {
  objects: Array<Enum_Presence_Connection_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Presence_Connection_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Presence_Connection_Status_OneArgs = {
  object: Enum_Presence_Connection_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Presence_Connection_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Project_Milestone_StatusArgs = {
  objects: Array<Enum_Project_Milestone_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Project_Milestone_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Project_Milestone_Status_OneArgs = {
  object: Enum_Project_Milestone_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Project_Milestone_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Project_Sprint_CategoryArgs = {
  objects: Array<Enum_Project_Sprint_Category_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Project_Sprint_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Project_Sprint_Category_OneArgs = {
  object: Enum_Project_Sprint_Category_Insert_Input;
  on_conflict?: InputMaybe<Enum_Project_Sprint_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Project_StatusArgs = {
  objects: Array<Enum_Project_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Project_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Project_Status_OneArgs = {
  object: Enum_Project_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Project_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Project_Task_CategoryArgs = {
  objects: Array<Enum_Project_Task_Category_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Project_Task_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Project_Task_Category_OneArgs = {
  object: Enum_Project_Task_Category_Insert_Input;
  on_conflict?: InputMaybe<Enum_Project_Task_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Setting_TypeArgs = {
  objects: Array<Enum_Setting_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Setting_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Setting_Type_OneArgs = {
  object: Enum_Setting_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Setting_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Storage_File_TypeArgs = {
  objects: Array<Enum_Storage_File_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Storage_File_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Storage_File_Type_OneArgs = {
  object: Enum_Storage_File_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Storage_File_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_System_StateArgs = {
  objects: Array<Enum_System_State_Insert_Input>;
  on_conflict?: InputMaybe<Enum_System_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_System_State_OneArgs = {
  object: Enum_System_State_Insert_Input;
  on_conflict?: InputMaybe<Enum_System_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_System_State_OwnerArgs = {
  objects: Array<Enum_System_State_Owner_Insert_Input>;
  on_conflict?: InputMaybe<Enum_System_State_Owner_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_System_State_Owner_OneArgs = {
  object: Enum_System_State_Owner_Insert_Input;
  on_conflict?: InputMaybe<Enum_System_State_Owner_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Task_PriorityArgs = {
  objects: Array<Enum_Task_Priority_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Task_Priority_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Task_Priority_OneArgs = {
  object: Enum_Task_Priority_Insert_Input;
  on_conflict?: InputMaybe<Enum_Task_Priority_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Task_Recurring_FrequencyArgs = {
  objects: Array<Enum_Task_Recurring_Frequency_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Task_Recurring_Frequency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Task_Recurring_Frequency_OneArgs = {
  object: Enum_Task_Recurring_Frequency_Insert_Input;
  on_conflict?: InputMaybe<Enum_Task_Recurring_Frequency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Task_StatusArgs = {
  objects: Array<Enum_Task_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Task_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Task_Status_OneArgs = {
  object: Enum_Task_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Task_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Task_Time_Sheet_StatusArgs = {
  objects: Array<Enum_Task_Time_Sheet_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Task_Time_Sheet_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Task_Time_Sheet_Status_OneArgs = {
  object: Enum_Task_Time_Sheet_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Task_Time_Sheet_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_Activity_Tracker_Status_TypeArgs = {
  objects: Array<Enum_User_Activity_Tracker_Status_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_Activity_Tracker_Status_Type_OneArgs =
  {
    object: Enum_User_Activity_Tracker_Status_Type_Insert_Input;
    on_conflict?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Enum_User_Avatar_TypesArgs = {
  objects: Array<Enum_User_Avatar_Types_Insert_Input>;
  on_conflict?: InputMaybe<Enum_User_Avatar_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_Avatar_Types_OneArgs = {
  object: Enum_User_Avatar_Types_Insert_Input;
  on_conflict?: InputMaybe<Enum_User_Avatar_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_GenderArgs = {
  objects: Array<Enum_User_Gender_Insert_Input>;
  on_conflict?: InputMaybe<Enum_User_Gender_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_Gender_OneArgs = {
  object: Enum_User_Gender_Insert_Input;
  on_conflict?: InputMaybe<Enum_User_Gender_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_Presence_Provider_TypeArgs = {
  objects: Array<Enum_User_Presence_Provider_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_User_Presence_Provider_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_Presence_Provider_Type_OneArgs = {
  object: Enum_User_Presence_Provider_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_User_Presence_Provider_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_Settings_TypeArgs = {
  objects: Array<Enum_User_Settings_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_User_Settings_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_User_Settings_Type_OneArgs = {
  object: Enum_User_Settings_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_User_Settings_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Filter_DefinitionArgs = {
  objects: Array<Filter_Definition_Insert_Input>;
  on_conflict?: InputMaybe<Filter_Definition_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Filter_Definition_OneArgs = {
  object: Filter_Definition_Insert_Input;
  on_conflict?: InputMaybe<Filter_Definition_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FinancialYearArgs = {
  objects: Array<FinancialYear_Insert_Input>;
  on_conflict?: InputMaybe<FinancialYear_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FinancialYear_OneArgs = {
  object: FinancialYear_Insert_Input;
  on_conflict?: InputMaybe<FinancialYear_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FrequencyArgs = {
  objects: Array<Frequency_Insert_Input>;
  on_conflict?: InputMaybe<Frequency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Frequency_OneArgs = {
  object: Frequency_Insert_Input;
  on_conflict?: InputMaybe<Frequency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalWithKpiArgs = {
  objects: Array<GoalWithKpi_Insert_Input>;
  on_conflict?: InputMaybe<GoalWithKpi_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalWithKpi_OneArgs = {
  object: GoalWithKpi_Insert_Input;
  on_conflict?: InputMaybe<GoalWithKpi_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalWithTimePeriodArgs = {
  objects: Array<GoalWithTimePeriod_Insert_Input>;
  on_conflict?: InputMaybe<GoalWithTimePeriod_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalWithTimePeriod_OneArgs = {
  object: GoalWithTimePeriod_Insert_Input;
  on_conflict?: InputMaybe<GoalWithTimePeriod_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalsArgs = {
  objects: Array<Goals_Insert_Input>;
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalsWithDeptsArgs = {
  objects: Array<GoalsWithDepts_Insert_Input>;
  on_conflict?: InputMaybe<GoalsWithDepts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalsWithDepts_OneArgs = {
  object: GoalsWithDepts_Insert_Input;
  on_conflict?: InputMaybe<GoalsWithDepts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalsWithFreqsArgs = {
  objects: Array<GoalsWithFreqs_Insert_Input>;
  on_conflict?: InputMaybe<GoalsWithFreqs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalsWithFreqs_OneArgs = {
  object: GoalsWithFreqs_Insert_Input;
  on_conflict?: InputMaybe<GoalsWithFreqs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalsWithRolesArgs = {
  objects: Array<GoalsWithRoles_Insert_Input>;
  on_conflict?: InputMaybe<GoalsWithRoles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoalsWithRoles_OneArgs = {
  object: GoalsWithRoles_Insert_Input;
  on_conflict?: InputMaybe<GoalsWithRoles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Goals_OneArgs = {
  object: Goals_Insert_Input;
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hotdesk_BookingsArgs = {
  objects: Array<Hotdesk_Bookings_Insert_Input>;
  on_conflict?: InputMaybe<Hotdesk_Bookings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hotdesk_Bookings_OneArgs = {
  object: Hotdesk_Bookings_Insert_Input;
  on_conflict?: InputMaybe<Hotdesk_Bookings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_KpiWithDeptArgs = {
  objects: Array<KpiWithDept_Insert_Input>;
  on_conflict?: InputMaybe<KpiWithDept_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_KpiWithDept_OneArgs = {
  object: KpiWithDept_Insert_Input;
  on_conflict?: InputMaybe<KpiWithDept_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_KpiWithRoleArgs = {
  objects: Array<KpiWithRole_Insert_Input>;
  on_conflict?: InputMaybe<KpiWithRole_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_KpiWithRole_OneArgs = {
  object: KpiWithRole_Insert_Input;
  on_conflict?: InputMaybe<KpiWithRole_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Kpi_BankArgs = {
  objects: Array<Kpi_Bank_Insert_Input>;
  on_conflict?: InputMaybe<Kpi_Bank_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Kpi_Bank_OneArgs = {
  object: Kpi_Bank_Insert_Input;
  on_conflict?: InputMaybe<Kpi_Bank_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LeaveArgs = {
  objects: Array<Leave_Insert_Input>;
  on_conflict?: InputMaybe<Leave_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leave_DonateArgs = {
  objects: Array<Leave_Donate_Insert_Input>;
  on_conflict?: InputMaybe<Leave_Donate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leave_Donate_OneArgs = {
  object: Leave_Donate_Insert_Input;
  on_conflict?: InputMaybe<Leave_Donate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leave_OneArgs = {
  object: Leave_Insert_Input;
  on_conflict?: InputMaybe<Leave_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leave_OrganizationArgs = {
  objects: Array<Leave_Organization_Insert_Input>;
  on_conflict?: InputMaybe<Leave_Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leave_Organization_OneArgs = {
  object: Leave_Organization_Insert_Input;
  on_conflict?: InputMaybe<Leave_Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_List_Of_SkillsArgs = {
  objects: Array<List_Of_Skills_Insert_Input>;
  on_conflict?: InputMaybe<List_Of_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_List_Of_Skills_OneArgs = {
  object: List_Of_Skills_Insert_Input;
  on_conflict?: InputMaybe<List_Of_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Live_CallArgs = {
  objects: Array<Live_Call_Insert_Input>;
  on_conflict?: InputMaybe<Live_Call_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Live_Call_OneArgs = {
  object: Live_Call_Insert_Input;
  on_conflict?: InputMaybe<Live_Call_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Live_Call_RequestArgs = {
  objects: Array<Live_Call_Request_Insert_Input>;
  on_conflict?: InputMaybe<Live_Call_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Live_Call_Request_OneArgs = {
  object: Live_Call_Request_Insert_Input;
  on_conflict?: InputMaybe<Live_Call_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Live_Call_UserArgs = {
  objects: Array<Live_Call_User_Insert_Input>;
  on_conflict?: InputMaybe<Live_Call_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Live_Call_User_OneArgs = {
  object: Live_Call_User_Insert_Input;
  on_conflict?: InputMaybe<Live_Call_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Main_SkillsArgs = {
  objects: Array<Main_Skills_Insert_Input>;
  on_conflict?: InputMaybe<Main_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Main_Skills_OneArgs = {
  object: Main_Skills_Insert_Input;
  on_conflict?: InputMaybe<Main_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Meeting_Call_SchedularArgs = {
  objects: Array<Meeting_Call_Schedular_Insert_Input>;
  on_conflict?: InputMaybe<Meeting_Call_Schedular_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Meeting_Call_Schedular_OneArgs = {
  object: Meeting_Call_Schedular_Insert_Input;
  on_conflict?: InputMaybe<Meeting_Call_Schedular_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Meeting_RoomArgs = {
  objects: Array<Meeting_Room_Insert_Input>;
  on_conflict?: InputMaybe<Meeting_Room_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Meeting_Room_OneArgs = {
  object: Meeting_Room_Insert_Input;
  on_conflict?: InputMaybe<Meeting_Room_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Meeting_Room_UsersArgs = {
  objects: Array<Meeting_Room_Users_Insert_Input>;
  on_conflict?: InputMaybe<Meeting_Room_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Meeting_Room_Users_OneArgs = {
  object: Meeting_Room_Users_Insert_Input;
  on_conflict?: InputMaybe<Meeting_Room_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OrganizationArgs = {
  objects: Array<Organization_Insert_Input>;
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Active_Leave_TypesArgs = {
  objects: Array<Organization_Active_Leave_Types_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Active_Leave_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Active_Leave_Types_OneArgs = {
  object: Organization_Active_Leave_Types_Insert_Input;
  on_conflict?: InputMaybe<Organization_Active_Leave_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_AdminArgs = {
  objects: Array<Organization_Admin_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Admin_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Admin_OneArgs = {
  object: Organization_Admin_Insert_Input;
  on_conflict?: InputMaybe<Organization_Admin_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_AwardsArgs = {
  objects: Array<Organization_Awards_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Awards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Awards_OneArgs = {
  object: Organization_Awards_Insert_Input;
  on_conflict?: InputMaybe<Organization_Awards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_EmployeeArgs = {
  objects: Array<Organization_Employee_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Employee_OneArgs = {
  object: Organization_Employee_Insert_Input;
  on_conflict?: InputMaybe<Organization_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_HolidaysArgs = {
  objects: Array<Organization_Holidays_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Holidays_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Holidays_OneArgs = {
  object: Organization_Holidays_Insert_Input;
  on_conflict?: InputMaybe<Organization_Holidays_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Leave_CyclesArgs = {
  objects: Array<Organization_Leave_Cycles_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Leave_Cycles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Leave_Cycles_OneArgs = {
  object: Organization_Leave_Cycles_Insert_Input;
  on_conflict?: InputMaybe<Organization_Leave_Cycles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_LocationsArgs = {
  objects: Array<Organization_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Locations_OneArgs = {
  object: Organization_Locations_Insert_Input;
  on_conflict?: InputMaybe<Organization_Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_OneArgs = {
  object: Organization_Insert_Input;
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_RolesArgs = {
  objects: Array<Organization_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Roles_OneArgs = {
  object: Organization_Roles_Insert_Input;
  on_conflict?: InputMaybe<Organization_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_User_Leave_ApplicationArgs = {
  objects: Array<Organization_User_Leave_Application_Insert_Input>;
  on_conflict?: InputMaybe<Organization_User_Leave_Application_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_User_Leave_Application_OneArgs = {
  object: Organization_User_Leave_Application_Insert_Input;
  on_conflict?: InputMaybe<Organization_User_Leave_Application_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_User_Leave_DataArgs = {
  objects: Array<Organization_User_Leave_Data_Insert_Input>;
  on_conflict?: InputMaybe<Organization_User_Leave_Data_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_User_Leave_Data_OneArgs = {
  object: Organization_User_Leave_Data_Insert_Input;
  on_conflict?: InputMaybe<Organization_User_Leave_Data_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_User_Leave_DatesArgs = {
  objects: Array<Organization_User_Leave_Dates_Insert_Input>;
  on_conflict?: InputMaybe<Organization_User_Leave_Dates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_User_Leave_Dates_OneArgs = {
  object: Organization_User_Leave_Dates_Insert_Input;
  on_conflict?: InputMaybe<Organization_User_Leave_Dates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_User_Leave_FilesArgs = {
  objects: Array<Organization_User_Leave_Files_Insert_Input>;
  on_conflict?: InputMaybe<Organization_User_Leave_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_User_Leave_Files_OneArgs = {
  object: Organization_User_Leave_Files_Insert_Input;
  on_conflict?: InputMaybe<Organization_User_Leave_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PerformanceReviewStatusArgs = {
  objects: Array<PerformanceReviewStatus_Insert_Input>;
  on_conflict?: InputMaybe<PerformanceReviewStatus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PerformanceReviewStatus_OneArgs = {
  object: PerformanceReviewStatus_Insert_Input;
  on_conflict?: InputMaybe<PerformanceReviewStatus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Rating_ScaleArgs = {
  objects: Array<Performance_Rating_Scale_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Rating_Scale_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Rating_Scale_OneArgs = {
  object: Performance_Rating_Scale_Insert_Input;
  on_conflict?: InputMaybe<Performance_Rating_Scale_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_ByUserArgs = {
  objects: Array<Performance_Review_ByUser_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Review_ByUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_ByUser_KpiArgs = {
  objects: Array<Performance_Review_ByUser_Kpi_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Review_ByUser_Kpi_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_ByUser_Kpi_OneArgs = {
  object: Performance_Review_ByUser_Kpi_Insert_Input;
  on_conflict?: InputMaybe<Performance_Review_ByUser_Kpi_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_ByUser_WithGoalArgs = {
  objects: Array<Performance_Review_ByUser_WithGoal_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Review_ByUser_WithGoal_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_ByUser_WithGoal_OneArgs = {
  object: Performance_Review_ByUser_WithGoal_Insert_Input;
  on_conflict?: InputMaybe<Performance_Review_ByUser_WithGoal_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_ByUser_OneArgs = {
  object: Performance_Review_ByUser_Insert_Input;
  on_conflict?: InputMaybe<Performance_Review_ByUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_Setting_GoalsListArgs = {
  objects: Array<Performance_Review_Setting_GoalsList_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Review_Setting_GoalsList_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_Setting_GoalsList_OneArgs = {
  object: Performance_Review_Setting_GoalsList_Insert_Input;
  on_conflict?: InputMaybe<Performance_Review_Setting_GoalsList_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_Setting_With_GoalsArgs = {
  objects: Array<Performance_Review_Setting_With_Goals_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Review_Setting_With_Goals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_Setting_With_Goals_OneArgs =
  {
    object: Performance_Review_Setting_With_Goals_Insert_Input;
    on_conflict?: InputMaybe<Performance_Review_Setting_With_Goals_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Performance_Review_SettingsArgs = {
  objects: Array<Performance_Review_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Review_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Review_Settings_OneArgs = {
  object: Performance_Review_Settings_Insert_Input;
  on_conflict?: InputMaybe<Performance_Review_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PersonalisedArgs = {
  objects: Array<Personalised_Insert_Input>;
  on_conflict?: InputMaybe<Personalised_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Personalised_OneArgs = {
  object: Personalised_Insert_Input;
  on_conflict?: InputMaybe<Personalised_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comment_FilesArgs = {
  objects: Array<Post_Comment_Files_Insert_Input>;
  on_conflict?: InputMaybe<Post_Comment_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comment_Files_OneArgs = {
  object: Post_Comment_Files_Insert_Input;
  on_conflict?: InputMaybe<Post_Comment_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comment_Mentioned_UsersArgs = {
  objects: Array<Post_Comment_Mentioned_Users_Insert_Input>;
  on_conflict?: InputMaybe<Post_Comment_Mentioned_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comment_Mentioned_Users_OneArgs = {
  object: Post_Comment_Mentioned_Users_Insert_Input;
  on_conflict?: InputMaybe<Post_Comment_Mentioned_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comment_RepliesArgs = {
  objects: Array<Post_Comment_Replies_Insert_Input>;
  on_conflict?: InputMaybe<Post_Comment_Replies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comment_Replies_Mentioned_UsersArgs = {
  objects: Array<Post_Comment_Replies_Mentioned_Users_Insert_Input>;
  on_conflict?: InputMaybe<Post_Comment_Replies_Mentioned_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comment_Replies_Mentioned_Users_OneArgs = {
  object: Post_Comment_Replies_Mentioned_Users_Insert_Input;
  on_conflict?: InputMaybe<Post_Comment_Replies_Mentioned_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comment_Replies_OneArgs = {
  object: Post_Comment_Replies_Insert_Input;
  on_conflict?: InputMaybe<Post_Comment_Replies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comments_LikesArgs = {
  objects: Array<Post_Comments_Likes_Insert_Input>;
  on_conflict?: InputMaybe<Post_Comments_Likes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Comments_Likes_OneArgs = {
  object: Post_Comments_Likes_Insert_Input;
  on_conflict?: InputMaybe<Post_Comments_Likes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_LikesArgs = {
  objects: Array<Post_Likes_Insert_Input>;
  on_conflict?: InputMaybe<Post_Likes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Likes_OneArgs = {
  object: Post_Likes_Insert_Input;
  on_conflict?: InputMaybe<Post_Likes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Mentioned_UsersArgs = {
  objects: Array<Post_Mentioned_Users_Insert_Input>;
  on_conflict?: InputMaybe<Post_Mentioned_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Mentioned_Users_OneArgs = {
  object: Post_Mentioned_Users_Insert_Input;
  on_conflict?: InputMaybe<Post_Mentioned_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Replies_LikesArgs = {
  objects: Array<Post_Replies_Likes_Insert_Input>;
  on_conflict?: InputMaybe<Post_Replies_Likes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Post_Replies_Likes_OneArgs = {
  object: Post_Replies_Likes_Insert_Input;
  on_conflict?: InputMaybe<Post_Replies_Likes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PostsArgs = {
  objects: Array<Posts_Insert_Input>;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_CommentsArgs = {
  objects: Array<Posts_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Posts_Comments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_Comments_OneArgs = {
  object: Posts_Comments_Insert_Input;
  on_conflict?: InputMaybe<Posts_Comments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_FilesArgs = {
  objects: Array<Posts_Files_Insert_Input>;
  on_conflict?: InputMaybe<Posts_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_Files_OneArgs = {
  object: Posts_Files_Insert_Input;
  on_conflict?: InputMaybe<Posts_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_OneArgs = {
  object: Posts_Insert_Input;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_Poll_ChoicesArgs = {
  objects: Array<Posts_Poll_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Posts_Poll_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_Poll_Choices_OneArgs = {
  object: Posts_Poll_Choices_Insert_Input;
  on_conflict?: InputMaybe<Posts_Poll_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_Poll_Choices_VotesArgs = {
  objects: Array<Posts_Poll_Choices_Votes_Insert_Input>;
  on_conflict?: InputMaybe<Posts_Poll_Choices_Votes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Posts_Poll_Choices_Votes_OneArgs = {
  object: Posts_Poll_Choices_Votes_Insert_Input;
  on_conflict?: InputMaybe<Posts_Poll_Choices_Votes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProfileArgs = {
  objects: Array<Profile_Insert_Input>;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Profile_OneArgs = {
  object: Profile_Insert_Input;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProjectArgs = {
  objects: Array<Project_Insert_Input>;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_ClientArgs = {
  objects: Array<Project_Client_Insert_Input>;
  on_conflict?: InputMaybe<Project_Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Client_OneArgs = {
  object: Project_Client_Insert_Input;
  on_conflict?: InputMaybe<Project_Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_LinksArgs = {
  objects: Array<Project_Links_Insert_Input>;
  on_conflict?: InputMaybe<Project_Links_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Links_OneArgs = {
  object: Project_Links_Insert_Input;
  on_conflict?: InputMaybe<Project_Links_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_MembersArgs = {
  objects: Array<Project_Members_Insert_Input>;
  on_conflict?: InputMaybe<Project_Members_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Members_OneArgs = {
  object: Project_Members_Insert_Input;
  on_conflict?: InputMaybe<Project_Members_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_MilestonesArgs = {
  objects: Array<Project_Milestones_Insert_Input>;
  on_conflict?: InputMaybe<Project_Milestones_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Milestones_OneArgs = {
  object: Project_Milestones_Insert_Input;
  on_conflict?: InputMaybe<Project_Milestones_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Notification_SettingsArgs = {
  objects: Array<Project_Notification_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Project_Notification_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Notification_Settings_OneArgs = {
  object: Project_Notification_Settings_Insert_Input;
  on_conflict?: InputMaybe<Project_Notification_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_OneArgs = {
  object: Project_Insert_Input;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_SectionArgs = {
  objects: Array<Project_Section_Insert_Input>;
  on_conflict?: InputMaybe<Project_Section_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Section_OneArgs = {
  object: Project_Section_Insert_Input;
  on_conflict?: InputMaybe<Project_Section_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_SprintsArgs = {
  objects: Array<Project_Sprints_Insert_Input>;
  on_conflict?: InputMaybe<Project_Sprints_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Sprints_OneArgs = {
  object: Project_Sprints_Insert_Input;
  on_conflict?: InputMaybe<Project_Sprints_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Question_OptionsArgs = {
  objects: Array<Question_Options_Insert_Input>;
  on_conflict?: InputMaybe<Question_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Question_Options_OneArgs = {
  object: Question_Options_Insert_Input;
  on_conflict?: InputMaybe<Question_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skills_DetailArgs = {
  objects: Array<Skills_Detail_Insert_Input>;
  on_conflict?: InputMaybe<Skills_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skills_Detail_OneArgs = {
  object: Skills_Detail_Insert_Input;
  on_conflict?: InputMaybe<Skills_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stage_Career_DetailArgs = {
  objects: Array<Stage_Career_Detail_Insert_Input>;
  on_conflict?: InputMaybe<Stage_Career_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stage_Career_Detail_OneArgs = {
  object: Stage_Career_Detail_Insert_Input;
  on_conflict?: InputMaybe<Stage_Career_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Storage_FilesArgs = {
  objects: Array<Storage_Files_Insert_Input>;
  on_conflict?: InputMaybe<Storage_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Storage_Files_OneArgs = {
  object: Storage_Files_Insert_Input;
  on_conflict?: InputMaybe<Storage_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stress_DataArgs = {
  objects: Array<Stress_Data_Insert_Input>;
  on_conflict?: InputMaybe<Stress_Data_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stress_Data_OneArgs = {
  object: Stress_Data_Insert_Input;
  on_conflict?: InputMaybe<Stress_Data_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sub_SkillsArgs = {
  objects: Array<Sub_Skills_Insert_Input>;
  on_conflict?: InputMaybe<Sub_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sub_Skills_OneArgs = {
  object: Sub_Skills_Insert_Input;
  on_conflict?: InputMaybe<Sub_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TaskArgs = {
  objects: Array<Task_Insert_Input>;
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_ApproversArgs = {
  objects: Array<Task_Approvers_Insert_Input>;
  on_conflict?: InputMaybe<Task_Approvers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Approvers_OneArgs = {
  object: Task_Approvers_Insert_Input;
  on_conflict?: InputMaybe<Task_Approvers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_ChecklistArgs = {
  objects: Array<Task_Checklist_Insert_Input>;
  on_conflict?: InputMaybe<Task_Checklist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Checklist_OneArgs = {
  object: Task_Checklist_Insert_Input;
  on_conflict?: InputMaybe<Task_Checklist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_CollaboratorArgs = {
  objects: Array<Task_Collaborator_Insert_Input>;
  on_conflict?: InputMaybe<Task_Collaborator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Collaborator_OneArgs = {
  object: Task_Collaborator_Insert_Input;
  on_conflict?: InputMaybe<Task_Collaborator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_CommentArgs = {
  objects: Array<Task_Comment_Insert_Input>;
  on_conflict?: InputMaybe<Task_Comment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Comment_LikeArgs = {
  objects: Array<Task_Comment_Like_Insert_Input>;
  on_conflict?: InputMaybe<Task_Comment_Like_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Comment_Like_OneArgs = {
  object: Task_Comment_Like_Insert_Input;
  on_conflict?: InputMaybe<Task_Comment_Like_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Comment_OneArgs = {
  object: Task_Comment_Insert_Input;
  on_conflict?: InputMaybe<Task_Comment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_DependenciesArgs = {
  objects: Array<Task_Dependencies_Insert_Input>;
  on_conflict?: InputMaybe<Task_Dependencies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Dependencies_OneArgs = {
  object: Task_Dependencies_Insert_Input;
  on_conflict?: InputMaybe<Task_Dependencies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_FileArgs = {
  objects: Array<Task_File_Insert_Input>;
  on_conflict?: InputMaybe<Task_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_File_OneArgs = {
  object: Task_File_Insert_Input;
  on_conflict?: InputMaybe<Task_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_MilestonesArgs = {
  objects: Array<Task_Milestones_Insert_Input>;
  on_conflict?: InputMaybe<Task_Milestones_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Milestones_OneArgs = {
  object: Task_Milestones_Insert_Input;
  on_conflict?: InputMaybe<Task_Milestones_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_OneArgs = {
  object: Task_Insert_Input;
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_TagsArgs = {
  objects: Array<Task_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Task_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Tags_OneArgs = {
  object: Task_Tags_Insert_Input;
  on_conflict?: InputMaybe<Task_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Time_SheetArgs = {
  objects: Array<Task_Time_Sheet_Insert_Input>;
  on_conflict?: InputMaybe<Task_Time_Sheet_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Time_Sheet_OneArgs = {
  object: Task_Time_Sheet_Insert_Input;
  on_conflict?: InputMaybe<Task_Time_Sheet_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_UnscheduledArgs = {
  objects: Array<Task_Unscheduled_Insert_Input>;
  on_conflict?: InputMaybe<Task_Unscheduled_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Unscheduled_OneArgs = {
  object: Task_Unscheduled_Insert_Input;
  on_conflict?: InputMaybe<Task_Unscheduled_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Time_PeriodArgs = {
  objects: Array<Time_Period_Insert_Input>;
  on_conflict?: InputMaybe<Time_Period_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Time_Period_OneArgs = {
  object: Time_Period_Insert_Input;
  on_conflict?: InputMaybe<Time_Period_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TrainingArgs = {
  objects: Array<Training_Insert_Input>;
  on_conflict?: InputMaybe<Training_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_SkillArgs = {
  objects: Array<Training_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Training_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Skill_OneArgs = {
  object: Training_Skill_Insert_Input;
  on_conflict?: InputMaybe<Training_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_FeedbackArgs = {
  objects: Array<Training_Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Training_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Feedback_OneArgs = {
  object: Training_Feedback_Insert_Input;
  on_conflict?: InputMaybe<Training_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_OneArgs = {
  object: Training_Insert_Input;
  on_conflict?: InputMaybe<Training_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Progress_RecordArgs = {
  objects: Array<Training_Progress_Record_Insert_Input>;
  on_conflict?: InputMaybe<Training_Progress_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Progress_Record_OneArgs = {
  object: Training_Progress_Record_Insert_Input;
  on_conflict?: InputMaybe<Training_Progress_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Role_DetailsArgs = {
  objects: Array<Training_Role_Details_Insert_Input>;
  on_conflict?: InputMaybe<Training_Role_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Role_Details_OneArgs = {
  object: Training_Role_Details_Insert_Input;
  on_conflict?: InputMaybe<Training_Role_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Stage_CareerArgs = {
  objects: Array<Training_Stage_Career_Insert_Input>;
  on_conflict?: InputMaybe<Training_Stage_Career_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Stage_Career_OneArgs = {
  object: Training_Stage_Career_Insert_Input;
  on_conflict?: InputMaybe<Training_Stage_Career_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Table_Of_ContentsArgs = {
  objects: Array<Training_Table_Of_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Training_Table_Of_Contents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Table_Of_Contents_OneArgs = {
  object: Training_Table_Of_Contents_Insert_Input;
  on_conflict?: InputMaybe<Training_Table_Of_Contents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Type_For_TrainingArgs = {
  objects: Array<Training_Type_For_Training_Insert_Input>;
  on_conflict?: InputMaybe<Training_Type_For_Training_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Type_For_Training_OneArgs = {
  object: Training_Type_For_Training_Insert_Input;
  on_conflict?: InputMaybe<Training_Type_For_Training_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_CompetencyArgs = {
  objects: Array<User_Competency_Insert_Input>;
  on_conflict?: InputMaybe<User_Competency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Competency_OneArgs = {
  object: User_Competency_Insert_Input;
  on_conflict?: InputMaybe<User_Competency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_SkillsArgs = {
  objects: Array<User_Skills_Insert_Input>;
  on_conflict?: InputMaybe<User_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Skills_AssessmentArgs = {
  objects: Array<User_Skills_Assessment_Insert_Input>;
  on_conflict?: InputMaybe<User_Skills_Assessment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Skills_Assessment_OneArgs = {
  object: User_Skills_Assessment_Insert_Input;
  on_conflict?: InputMaybe<User_Skills_Assessment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Skills_TrainingArgs = {
  objects: Array<User_Skills_Training_Insert_Input>;
  on_conflict?: InputMaybe<User_Skills_Training_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Skills_Training_OneArgs = {
  object: User_Skills_Training_Insert_Input;
  on_conflict?: InputMaybe<User_Skills_Training_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Skills_OneArgs = {
  object: User_Skills_Insert_Input;
  on_conflict?: InputMaybe<User_Skills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Activity_TrackerArgs = {
  objects: Array<User_Activity_Tracker_Insert_Input>;
  on_conflict?: InputMaybe<User_Activity_Tracker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Activity_Tracker_OneArgs = {
  object: User_Activity_Tracker_Insert_Input;
  on_conflict?: InputMaybe<User_Activity_Tracker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_AvatarArgs = {
  objects: Array<User_Avatar_Insert_Input>;
  on_conflict?: InputMaybe<User_Avatar_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Avatar_FilesArgs = {
  objects: Array<User_Avatar_Files_Insert_Input>;
  on_conflict?: InputMaybe<User_Avatar_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Avatar_Files_OneArgs = {
  object: User_Avatar_Files_Insert_Input;
  on_conflict?: InputMaybe<User_Avatar_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Avatar_OneArgs = {
  object: User_Avatar_Insert_Input;
  on_conflict?: InputMaybe<User_Avatar_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Career_Development_RecordArgs = {
  objects: Array<User_Career_Development_Record_Insert_Input>;
  on_conflict?: InputMaybe<User_Career_Development_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Career_Development_Record_OneArgs = {
  object: User_Career_Development_Record_Insert_Input;
  on_conflict?: InputMaybe<User_Career_Development_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Career_External_CertificationArgs = {
  objects: Array<User_Career_External_Certification_Insert_Input>;
  on_conflict?: InputMaybe<User_Career_External_Certification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Career_External_Certification_OneArgs = {
  object: User_Career_External_Certification_Insert_Input;
  on_conflict?: InputMaybe<User_Career_External_Certification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_PresenceArgs = {
  objects: Array<User_Presence_Insert_Input>;
  on_conflict?: InputMaybe<User_Presence_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Presence_OneArgs = {
  object: User_Presence_Insert_Input;
  on_conflict?: InputMaybe<User_Presence_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_SettingsArgs = {
  objects: Array<User_Settings_Insert_Input>;
  on_conflict?: InputMaybe<User_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Settings_OneArgs = {
  object: User_Settings_Insert_Input;
  on_conflict?: InputMaybe<User_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_StateArgs = {
  objects: Array<User_State_Insert_Input>;
  on_conflict?: InputMaybe<User_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_State_OneArgs = {
  object: User_State_Insert_Input;
  on_conflict?: InputMaybe<User_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInviteGuestToProjectArgs = {
  data: InviteGuestToProjectInput;
};

/** mutation root */
export type Mutation_RootInviteMeetingRoomArgs = {
  object: InviteMeetingRoomInput;
};

/** mutation root */
export type Mutation_RootJoinLiveCallArgs = {
  object: JoinLiveCallInput;
};

/** mutation root */
export type Mutation_RootJoinMeetCallArgs = {
  meetingCallId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootLeaveCallArgs = {
  object: LeaveCallInput;
};

/** mutation root */
export type Mutation_RootLeaveChatArgs = {
  object: LeaveChatInput;
};

/** mutation root */
export type Mutation_RootLeaveLiveCallArgs = {
  object: LeaveLiveCallInput;
};

/** mutation root */
export type Mutation_RootLeaveMeetCallArgs = {
  meeting_call_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootLeaveMeetingRoomArgs = {
  object: LeaveMeetingRoomInput;
};

/** mutation root */
export type Mutation_RootLoadAssessmentScoreForAssessmentTypeArgs = {
  object: AssessmentScoreForAssessmentTypeInput;
};

/** mutation root */
export type Mutation_RootLoadCareerDevelopmentDashboardArgs = {
  object: CareerDevelopmentDashboardInput;
};

/** mutation root */
export type Mutation_RootLoadEmployeeAssessmentScoreArgs = {
  object: EmployeeAssessmentScoreInput;
};

/** mutation root */
export type Mutation_RootLoadEmployeeCapactiyGraphArgs = {
  object: LoadEmployeeCapactiyGraphInput;
};

/** mutation root */
export type Mutation_RootLoadIndividualAssessmentScoreArgs = {
  object: IndividualAssessmentScoreInput;
};

/** mutation root */
export type Mutation_RootLoadIndividualAssessmentTypeGraphArgs = {
  object: IndividualAssessmentTypeGraphInput;
};

/** mutation root */
export type Mutation_RootLoadMandatoryAssessmentArgs = {
  object: LoadMandatoryAssessmentInput;
};

/** mutation root */
export type Mutation_RootLoadOrganizationScoresArgs = {
  object: OrganizationscoreInput;
};

/** mutation root */
export type Mutation_RootLoadPerformanceReviewArgs = {
  object: LoadPerformanceReviewInput;
};

/** mutation root */
export type Mutation_RootLoadRoleWeightagesArgs = {
  object: RoleWeightageInput;
};

/** mutation root */
export type Mutation_RootLoadStressDataForGraphArgs = {
  object: LoadStressDataForGraphInput;
};

/** mutation root */
export type Mutation_RootLoadUsersCareerPlanDetailsArgs = {
  object: LoadUserSelectedCareerDetailInput;
};

/** mutation root */
export type Mutation_RootLoadWeightageForGoalsArgs = {
  object: Load_Role_Weightage_Input;
};

/** mutation root */
export type Mutation_RootLoadfeedbackArgs = {
  object: FeedbackInput;
};

/** mutation root */
export type Mutation_RootLoadindividualScoreArgs = {
  object: ScoreInput;
};

/** mutation root */
export type Mutation_RootLoadscorecardArgs = {
  object: ScoredCardInput;
};

/** mutation root */
export type Mutation_RootLoginArgs = {
  object: LoginInput;
};

/** mutation root */
export type Mutation_RootLogoutArgs = {
  object: LogoutInput;
};

/** mutation root */
export type Mutation_RootNewPollPostArgs = {
  object: NewPollPostInput;
};

/** mutation root */
export type Mutation_RootNewPostArgs = {
  object: NewPostInput;
};

/** mutation root */
export type Mutation_RootOnedriveAuthenticationArgs = {
  object: OnedriveAuthenticationInput;
};

/** mutation root */
export type Mutation_RootOnedriveLogOutArgs = {
  object: OnedriveLogoutInput;
};

/** mutation root */
export type Mutation_RootPerformanceReviewDownloadPdfArgs = {
  object: PerformanceReviewPdfInput;
};

/** mutation root */
export type Mutation_RootPerformanceReviewGraphArgs = {
  object: PerformanceReviewGraphInput;
};

/** mutation root */
export type Mutation_RootPerformanceReviewMarksPerUsersArgs = {
  object: PerformanceReviewUserInput;
};

/** mutation root */
export type Mutation_RootPerformanceReviewNotificationArgs = {
  object: PerformanceReviewNotifyInput;
};

/** mutation root */
export type Mutation_RootPostLikesArgs = {
  object: LikesInput;
};

/** mutation root */
export type Mutation_RootRemoveUserFromChatArgs = {
  object: RemoveChatUserInput;
};

/** mutation root */
export type Mutation_RootRequestCalendarMeetCallArgs = {
  data: RequestCalendarMeetCallInput;
};

/** mutation root */
export type Mutation_RootResendActivateEmailArgs = {
  object: ResendActivateEmailInput;
};

/** mutation root */
export type Mutation_RootRespondMeetingCallArgs = {
  data: RespondMeetingCallInput;
};

/** mutation root */
export type Mutation_RootRespondMeetingRoomArgs = {
  object: RespondMeetingRoomInput;
};

/** mutation root */
export type Mutation_RootRespondToCallArgs = {
  object: RespondToCallInput;
};

/** mutation root */
export type Mutation_RootRespondToLiveCallArgs = {
  object: RespondToLiveCallInput;
};

/** mutation root */
export type Mutation_RootSaveEmployeeArgs = {
  object: SaveEmployeeInput;
};

/** mutation root */
export type Mutation_RootSaveOrganizationAdminArgs = {
  object?: InputMaybe<SaveOrganizationAdminInput>;
};

/** mutation root */
export type Mutation_RootSaveTimeSheetDayArgs = {
  objects: Array<TimeSheet>;
  saveType: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSaveTimeSheetWeekArgs = {
  objects: Array<TimeSheetWeekObject>;
  saveType: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSendTaskReminderArgs = {
  object: SendTaskReminderInput;
};

/** mutation root */
export type Mutation_RootSkillsUnderComepetencyArgs = {
  object: ListofSkillsUnderComptInput;
};

/** mutation root */
export type Mutation_RootStartCallArgs = {
  object: StartCallInput;
};

/** mutation root */
export type Mutation_RootStartChatArgs = {
  object: StartChatInput;
};

/** mutation root */
export type Mutation_RootStartLiveCallArgs = {
  object: StartLiveCallInput;
};

/** mutation root */
export type Mutation_RootStartMeetingChatArgs = {
  object: StartMeetingChatInput;
};

/** mutation root */
export type Mutation_RootStartRecordingArgs = {
  object: StartRecordingInput;
};

/** mutation root */
export type Mutation_RootStopRecordingArgs = {
  object: StopRecordingInput;
};

/** mutation root */
export type Mutation_RootSubmitLeaveArgs = {
  object: SubmitLeaveInput;
};

/** mutation root */
export type Mutation_RootTaskproductivityGraphArgs = {
  object: TaskproductivityGraphInput;
};

/** mutation root */
export type Mutation_RootTimeSpentGraphArgs = {
  object: TimeSpentGraphInput;
};

/** mutation root */
export type Mutation_RootToggleChatNotificationArgs = {
  object: ToggleChatNotificationInput;
};

/** mutation root */
export type Mutation_RootTransferOwnershipArgs = {
  object: TransferOwnershipInput;
};

/** mutation root */
export type Mutation_RootUpdateActionArgs = {
  object: ActionInput;
};

/** mutation root */
export type Mutation_RootUpdateAssessmentArgs = {
  data: UpdateAssessmentInput;
};

/** mutation root */
export type Mutation_RootUpdateCalendarEventArgs = {
  object: UpdateCalendarEventInput;
};

/** mutation root */
export type Mutation_RootUpdateCareerDevelopmentPlanArgs = {
  object: UpdateCareerDevelopmentPlanInput;
};

/** mutation root */
export type Mutation_RootUpdateChecklistArgs = {
  object: UpdateChecklistInput;
};

/** mutation root */
export type Mutation_RootUpdateDependentTasksArgs = {
  taskInput: UpdateDependentTaskInput;
};

/** mutation root */
export type Mutation_RootUpdateGoalsArgs = {
  object: UpdateGoalInput;
};

/** mutation root */
export type Mutation_RootUpdateKpiArgs = {
  object: UpdateKpiInput;
};

/** mutation root */
export type Mutation_RootUpdateMilestoneArgs = {
  object: UpdateMilestoneInput;
};

/** mutation root */
export type Mutation_RootUpdateMultipleTasksArgs = {
  collaborator_ids?: InputMaybe<Array<Scalars['uuid']>>;
  operation_name: Scalars['String'];
  tag_ids?: InputMaybe<Array<Scalars['uuid']>>;
  task_ids: Array<Scalars['uuid']>;
  updateTasks?: InputMaybe<UpdateMultipleTasksInput>;
};

/** mutation root */
export type Mutation_RootUpdatePerformanceReviewArgs = {
  object: UpdatePerofrmanceReviewInput;
};

/** mutation root */
export type Mutation_RootUpdateTaskArgs = {
  filesInput?: InputMaybe<Array<TaskFileInsertInput>>;
  newCollaborators?: InputMaybe<Array<TaskCollaboratorInsertInput>>;
  newTags?: InputMaybe<Array<TaskTagsInsertInput>>;
  newTaskMilestones?: InputMaybe<Array<TaskMilestoneInsertInput>>;
  projectMembers?: InputMaybe<Array<ProjectMembersInsertInput>>;
  removedCollaboratorsUserIds?: InputMaybe<Array<Scalars['uuid']>>;
  removedTagIds?: InputMaybe<Array<Scalars['uuid']>>;
  removedTaskMilestones?: InputMaybe<Array<Scalars['uuid']>>;
  taskId: Scalars['uuid'];
  taskInput?: InputMaybe<TaskSetInput>;
};

/** mutation root */
export type Mutation_RootUpdateTaskApproversArgs = {
  object: UpdateTaskApproversInput;
};

/** mutation root */
export type Mutation_RootUpdateTaskRankArgs = {
  object: UpdateTaskRankInput;
};

/** mutation root */
export type Mutation_RootUpdateTrainingArgs = {
  object: UpdateTrainingInput;
};

/** mutation root */
export type Mutation_RootUpdate_User_Peer_ReviewArgs = {
  _set?: InputMaybe<User_Peer_Review_Set_Input>;
  where: User_Peer_Review_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Peer_Review_By_PkArgs = {
  _set?: InputMaybe<User_Peer_Review_Set_Input>;
  pk_columns: User_Peer_Review_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Peer_Review_ManyArgs = {
  updates: Array<User_Peer_Review_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _append?: InputMaybe<Address_Append_Input>;
  _delete_at_path?: InputMaybe<Address_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Address_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Address_Delete_Key_Input>;
  _prepend?: InputMaybe<Address_Prepend_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _append?: InputMaybe<Address_Append_Input>;
  _delete_at_path?: InputMaybe<Address_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Address_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Address_Delete_Key_Input>;
  _prepend?: InputMaybe<Address_Prepend_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AssessmentArgs = {
  _inc?: InputMaybe<Assessment_Inc_Input>;
  _set?: InputMaybe<Assessment_Set_Input>;
  where: Assessment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_SkillsArgs = {
  _set?: InputMaybe<Assessment_Skills_Set_Input>;
  where: Assessment_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Skills_By_PkArgs = {
  _set?: InputMaybe<Assessment_Skills_Set_Input>;
  pk_columns: Assessment_Skills_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Skills_ManyArgs = {
  updates: Array<Assessment_Skills_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_By_PkArgs = {
  _inc?: InputMaybe<Assessment_Inc_Input>;
  _set?: InputMaybe<Assessment_Set_Input>;
  pk_columns: Assessment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_FeedbackArgs = {
  _inc?: InputMaybe<Assessment_Feedback_Inc_Input>;
  _set?: InputMaybe<Assessment_Feedback_Set_Input>;
  where: Assessment_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Feedback_By_PkArgs = {
  _inc?: InputMaybe<Assessment_Feedback_Inc_Input>;
  _set?: InputMaybe<Assessment_Feedback_Set_Input>;
  pk_columns: Assessment_Feedback_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Feedback_ManyArgs = {
  updates: Array<Assessment_Feedback_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_ManyArgs = {
  updates: Array<Assessment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_RecordArgs = {
  _inc?: InputMaybe<Assessment_Record_Inc_Input>;
  _set?: InputMaybe<Assessment_Record_Set_Input>;
  where: Assessment_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Record_By_PkArgs = {
  _inc?: InputMaybe<Assessment_Record_Inc_Input>;
  _set?: InputMaybe<Assessment_Record_Set_Input>;
  pk_columns: Assessment_Record_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Record_ManyArgs = {
  updates: Array<Assessment_Record_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Role_DetailsArgs = {
  _set?: InputMaybe<Assessment_Role_Details_Set_Input>;
  where: Assessment_Role_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Role_Details_By_PkArgs = {
  _set?: InputMaybe<Assessment_Role_Details_Set_Input>;
  pk_columns: Assessment_Role_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Role_Details_ManyArgs = {
  updates: Array<Assessment_Role_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Stage_CareerArgs = {
  _inc?: InputMaybe<Assessment_Stage_Career_Inc_Input>;
  _set?: InputMaybe<Assessment_Stage_Career_Set_Input>;
  where: Assessment_Stage_Career_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Stage_Career_By_PkArgs = {
  _inc?: InputMaybe<Assessment_Stage_Career_Inc_Input>;
  _set?: InputMaybe<Assessment_Stage_Career_Set_Input>;
  pk_columns: Assessment_Stage_Career_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Stage_Career_ManyArgs = {
  updates: Array<Assessment_Stage_Career_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_TypeArgs = {
  _set?: InputMaybe<Assessment_Type_Set_Input>;
  where: Assessment_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Type_By_PkArgs = {
  _set?: InputMaybe<Assessment_Type_Set_Input>;
  pk_columns: Assessment_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Type_ManyArgs = {
  updates: Array<Assessment_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assign_Career_Development_PlanArgs = {
  _set?: InputMaybe<Assign_Career_Development_Plan_Set_Input>;
  where: Assign_Career_Development_Plan_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assign_Career_Development_Plan_By_PkArgs = {
  _set?: InputMaybe<Assign_Career_Development_Plan_Set_Input>;
  pk_columns: Assign_Career_Development_Plan_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assign_Career_Development_Plan_ManyArgs = {
  updates: Array<Assign_Career_Development_Plan_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_User_StateArgs = {
  _append?: InputMaybe<Audit_User_State_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_User_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_User_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_User_State_Delete_Key_Input>;
  _prepend?: InputMaybe<Audit_User_State_Prepend_Input>;
  _set?: InputMaybe<Audit_User_State_Set_Input>;
  where: Audit_User_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_User_State_By_PkArgs = {
  _append?: InputMaybe<Audit_User_State_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_User_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_User_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_User_State_Delete_Key_Input>;
  _prepend?: InputMaybe<Audit_User_State_Prepend_Input>;
  _set?: InputMaybe<Audit_User_State_Set_Input>;
  pk_columns: Audit_User_State_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_User_State_ManyArgs = {
  updates: Array<Audit_User_State_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Account_ProvidersArgs = {
  _set?: InputMaybe<Auth_Account_Providers_Set_Input>;
  where: Auth_Account_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Providers_By_PkArgs = {
  _set?: InputMaybe<Auth_Account_Providers_Set_Input>;
  pk_columns: Auth_Account_Providers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Providers_ManyArgs = {
  updates: Array<Auth_Account_Providers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Account_RolesArgs = {
  _set?: InputMaybe<Auth_Account_Roles_Set_Input>;
  where: Auth_Account_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Roles_By_PkArgs = {
  _set?: InputMaybe<Auth_Account_Roles_Set_Input>;
  pk_columns: Auth_Account_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Roles_ManyArgs = {
  updates: Array<Auth_Account_Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_AccountsArgs = {
  _append?: InputMaybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Auth_Accounts_Prepend_Input>;
  _set?: InputMaybe<Auth_Accounts_Set_Input>;
  where: Auth_Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Accounts_By_PkArgs = {
  _append?: InputMaybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Auth_Accounts_Prepend_Input>;
  _set?: InputMaybe<Auth_Accounts_Set_Input>;
  pk_columns: Auth_Accounts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Accounts_ManyArgs = {
  updates: Array<Auth_Accounts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_ProvidersArgs = {
  _set?: InputMaybe<Auth_Providers_Set_Input>;
  where: Auth_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Providers_By_PkArgs = {
  _set?: InputMaybe<Auth_Providers_Set_Input>;
  pk_columns: Auth_Providers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Providers_ManyArgs = {
  updates: Array<Auth_Providers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_TokensArgs = {
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  where: Auth_Refresh_Tokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_Tokens_By_PkArgs = {
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  pk_columns: Auth_Refresh_Tokens_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_Tokens_ManyArgs = {
  updates: Array<Auth_Refresh_Tokens_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_RolesArgs = {
  _set?: InputMaybe<Auth_Roles_Set_Input>;
  where: Auth_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Roles_By_PkArgs = {
  _set?: InputMaybe<Auth_Roles_Set_Input>;
  pk_columns: Auth_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Roles_ManyArgs = {
  updates: Array<Auth_Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Award_PostsArgs = {
  _set?: InputMaybe<Award_Posts_Set_Input>;
  where: Award_Posts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Award_Posts_By_PkArgs = {
  _set?: InputMaybe<Award_Posts_Set_Input>;
  pk_columns: Award_Posts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Award_Posts_ManyArgs = {
  updates: Array<Award_Posts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AwardsArgs = {
  _inc?: InputMaybe<Awards_Inc_Input>;
  _set?: InputMaybe<Awards_Set_Input>;
  where: Awards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Awards_By_PkArgs = {
  _inc?: InputMaybe<Awards_Inc_Input>;
  _set?: InputMaybe<Awards_Set_Input>;
  pk_columns: Awards_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Awards_ManyArgs = {
  updates: Array<Awards_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CalendarArgs = {
  _set?: InputMaybe<Calendar_Set_Input>;
  where: Calendar_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_AttendeeArgs = {
  _set?: InputMaybe<Calendar_Attendee_Set_Input>;
  where: Calendar_Attendee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Attendee_By_PkArgs = {
  _set?: InputMaybe<Calendar_Attendee_Set_Input>;
  pk_columns: Calendar_Attendee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Attendee_ManyArgs = {
  updates: Array<Calendar_Attendee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_By_PkArgs = {
  _set?: InputMaybe<Calendar_Set_Input>;
  pk_columns: Calendar_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Delete_EventsArgs = {
  _set?: InputMaybe<Calendar_Delete_Events_Set_Input>;
  where: Calendar_Delete_Events_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Delete_Events_By_PkArgs = {
  _set?: InputMaybe<Calendar_Delete_Events_Set_Input>;
  pk_columns: Calendar_Delete_Events_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Delete_Events_ManyArgs = {
  updates: Array<Calendar_Delete_Events_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_EventArgs = {
  _append?: InputMaybe<Calendar_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Calendar_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendar_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendar_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Calendar_Event_Prepend_Input>;
  _set?: InputMaybe<Calendar_Event_Set_Input>;
  where: Calendar_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Event_By_PkArgs = {
  _append?: InputMaybe<Calendar_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Calendar_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendar_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendar_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Calendar_Event_Prepend_Input>;
  _set?: InputMaybe<Calendar_Event_Set_Input>;
  pk_columns: Calendar_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Event_ManyArgs = {
  updates: Array<Calendar_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_ManyArgs = {
  updates: Array<Calendar_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_CallArgs = {
  _append?: InputMaybe<Calendar_Meeting_Call_Append_Input>;
  _delete_at_path?: InputMaybe<Calendar_Meeting_Call_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendar_Meeting_Call_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendar_Meeting_Call_Delete_Key_Input>;
  _prepend?: InputMaybe<Calendar_Meeting_Call_Prepend_Input>;
  _set?: InputMaybe<Calendar_Meeting_Call_Set_Input>;
  where: Calendar_Meeting_Call_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_Call_By_PkArgs = {
  _append?: InputMaybe<Calendar_Meeting_Call_Append_Input>;
  _delete_at_path?: InputMaybe<Calendar_Meeting_Call_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendar_Meeting_Call_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendar_Meeting_Call_Delete_Key_Input>;
  _prepend?: InputMaybe<Calendar_Meeting_Call_Prepend_Input>;
  _set?: InputMaybe<Calendar_Meeting_Call_Set_Input>;
  pk_columns: Calendar_Meeting_Call_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_Call_ManyArgs = {
  updates: Array<Calendar_Meeting_Call_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_Call_RequestArgs = {
  _set?: InputMaybe<Calendar_Meeting_Call_Request_Set_Input>;
  where: Calendar_Meeting_Call_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_Call_Request_By_PkArgs = {
  _set?: InputMaybe<Calendar_Meeting_Call_Request_Set_Input>;
  pk_columns: Calendar_Meeting_Call_Request_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_Call_Request_ManyArgs = {
  updates: Array<Calendar_Meeting_Call_Request_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_Call_UserArgs = {
  _append?: InputMaybe<Calendar_Meeting_Call_User_Append_Input>;
  _delete_at_path?: InputMaybe<Calendar_Meeting_Call_User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendar_Meeting_Call_User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendar_Meeting_Call_User_Delete_Key_Input>;
  _prepend?: InputMaybe<Calendar_Meeting_Call_User_Prepend_Input>;
  _set?: InputMaybe<Calendar_Meeting_Call_User_Set_Input>;
  where: Calendar_Meeting_Call_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_Call_User_By_PkArgs = {
  _append?: InputMaybe<Calendar_Meeting_Call_User_Append_Input>;
  _delete_at_path?: InputMaybe<Calendar_Meeting_Call_User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendar_Meeting_Call_User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendar_Meeting_Call_User_Delete_Key_Input>;
  _prepend?: InputMaybe<Calendar_Meeting_Call_User_Prepend_Input>;
  _set?: InputMaybe<Calendar_Meeting_Call_User_Set_Input>;
  pk_columns: Calendar_Meeting_Call_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendar_Meeting_Call_User_ManyArgs = {
  updates: Array<Calendar_Meeting_Call_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CallArgs = {
  _append?: InputMaybe<Call_Append_Input>;
  _delete_at_path?: InputMaybe<Call_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Call_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Call_Delete_Key_Input>;
  _prepend?: InputMaybe<Call_Prepend_Input>;
  _set?: InputMaybe<Call_Set_Input>;
  where: Call_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Call_By_PkArgs = {
  _append?: InputMaybe<Call_Append_Input>;
  _delete_at_path?: InputMaybe<Call_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Call_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Call_Delete_Key_Input>;
  _prepend?: InputMaybe<Call_Prepend_Input>;
  _set?: InputMaybe<Call_Set_Input>;
  pk_columns: Call_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Call_ManyArgs = {
  updates: Array<Call_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Call_RequestArgs = {
  _set?: InputMaybe<Call_Request_Set_Input>;
  where: Call_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Call_Request_By_PkArgs = {
  _set?: InputMaybe<Call_Request_Set_Input>;
  pk_columns: Call_Request_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Call_Request_ManyArgs = {
  updates: Array<Call_Request_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Call_UserArgs = {
  _set?: InputMaybe<Call_User_Set_Input>;
  where: Call_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Call_User_By_PkArgs = {
  _set?: InputMaybe<Call_User_Set_Input>;
  pk_columns: Call_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Call_User_ManyArgs = {
  updates: Array<Call_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Career_Development_DetailArgs = {
  _inc?: InputMaybe<Career_Development_Detail_Inc_Input>;
  _set?: InputMaybe<Career_Development_Detail_Set_Input>;
  where: Career_Development_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Career_Development_Detail_By_PkArgs = {
  _inc?: InputMaybe<Career_Development_Detail_Inc_Input>;
  _set?: InputMaybe<Career_Development_Detail_Set_Input>;
  pk_columns: Career_Development_Detail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Career_Development_Detail_ManyArgs = {
  updates: Array<Career_Development_Detail_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Career_Development_Role_DetailsArgs = {
  _set?: InputMaybe<Career_Development_Role_Details_Set_Input>;
  where: Career_Development_Role_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Career_Development_Role_Details_By_PkArgs = {
  _set?: InputMaybe<Career_Development_Role_Details_Set_Input>;
  pk_columns: Career_Development_Role_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Career_Development_Role_Details_ManyArgs = {
  updates: Array<Career_Development_Role_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Career_External_CertificationArgs = {
  _set?: InputMaybe<Career_External_Certification_Set_Input>;
  where: Career_External_Certification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Career_External_Certification_By_PkArgs = {
  _set?: InputMaybe<Career_External_Certification_Set_Input>;
  pk_columns: Career_External_Certification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Career_External_Certification_ManyArgs = {
  updates: Array<Career_External_Certification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_MessageArgs = {
  _append?: InputMaybe<Chat_Message_Append_Input>;
  _delete_at_path?: InputMaybe<Chat_Message_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chat_Message_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chat_Message_Delete_Key_Input>;
  _prepend?: InputMaybe<Chat_Message_Prepend_Input>;
  _set?: InputMaybe<Chat_Message_Set_Input>;
  where: Chat_Message_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_ActionArgs = {
  _set?: InputMaybe<Chat_Message_Action_Set_Input>;
  where: Chat_Message_Action_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_Action_By_PkArgs = {
  _set?: InputMaybe<Chat_Message_Action_Set_Input>;
  pk_columns: Chat_Message_Action_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_Action_ManyArgs = {
  updates: Array<Chat_Message_Action_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_By_PkArgs = {
  _append?: InputMaybe<Chat_Message_Append_Input>;
  _delete_at_path?: InputMaybe<Chat_Message_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chat_Message_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chat_Message_Delete_Key_Input>;
  _prepend?: InputMaybe<Chat_Message_Prepend_Input>;
  _set?: InputMaybe<Chat_Message_Set_Input>;
  pk_columns: Chat_Message_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_FileArgs = {
  _set?: InputMaybe<Chat_Message_File_Set_Input>;
  where: Chat_Message_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_File_By_PkArgs = {
  _set?: InputMaybe<Chat_Message_File_Set_Input>;
  pk_columns: Chat_Message_File_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_File_ManyArgs = {
  updates: Array<Chat_Message_File_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_ManyArgs = {
  updates: Array<Chat_Message_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_ReactionsArgs = {
  _set?: InputMaybe<Chat_Message_Reactions_Set_Input>;
  where: Chat_Message_Reactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_Reactions_By_PkArgs = {
  _set?: InputMaybe<Chat_Message_Reactions_Set_Input>;
  pk_columns: Chat_Message_Reactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Message_Reactions_ManyArgs = {
  updates: Array<Chat_Message_Reactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_PinnedArgs = {
  _set?: InputMaybe<Chat_Pinned_Set_Input>;
  where: Chat_Pinned_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Pinned_By_PkArgs = {
  _set?: InputMaybe<Chat_Pinned_Set_Input>;
  pk_columns: Chat_Pinned_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Pinned_ManyArgs = {
  updates: Array<Chat_Pinned_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_RoomArgs = {
  _append?: InputMaybe<Chat_Room_Append_Input>;
  _delete_at_path?: InputMaybe<Chat_Room_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chat_Room_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chat_Room_Delete_Key_Input>;
  _prepend?: InputMaybe<Chat_Room_Prepend_Input>;
  _set?: InputMaybe<Chat_Room_Set_Input>;
  where: Chat_Room_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Room_By_PkArgs = {
  _append?: InputMaybe<Chat_Room_Append_Input>;
  _delete_at_path?: InputMaybe<Chat_Room_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chat_Room_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chat_Room_Delete_Key_Input>;
  _prepend?: InputMaybe<Chat_Room_Prepend_Input>;
  _set?: InputMaybe<Chat_Room_Set_Input>;
  pk_columns: Chat_Room_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Room_ManyArgs = {
  updates: Array<Chat_Room_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Room_UserArgs = {
  _set?: InputMaybe<Chat_Room_User_Set_Input>;
  where: Chat_Room_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Room_User_By_PkArgs = {
  _set?: InputMaybe<Chat_Room_User_Set_Input>;
  pk_columns: Chat_Room_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Room_User_ManyArgs = {
  updates: Array<Chat_Room_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dashboard_RemaindersArgs = {
  _set?: InputMaybe<Dashboard_Remainders_Set_Input>;
  where: Dashboard_Remainders_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dashboard_Remainders_By_PkArgs = {
  _set?: InputMaybe<Dashboard_Remainders_Set_Input>;
  pk_columns: Dashboard_Remainders_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dashboard_Remainders_ManyArgs = {
  updates: Array<Dashboard_Remainders_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DepartmentArgs = {
  _set?: InputMaybe<Department_Set_Input>;
  where: Department_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Department_By_PkArgs = {
  _set?: InputMaybe<Department_Set_Input>;
  pk_columns: Department_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Department_EmployeeArgs = {
  _set?: InputMaybe<Department_Employee_Set_Input>;
  where: Department_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Department_Employee_By_PkArgs = {
  _set?: InputMaybe<Department_Employee_Set_Input>;
  pk_columns: Department_Employee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Department_Employee_ManyArgs = {
  updates: Array<Department_Employee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Department_ManyArgs = {
  updates: Array<Department_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Document_TokenArgs = {
  _set?: InputMaybe<Document_Token_Set_Input>;
  where: Document_Token_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Token_By_PkArgs = {
  _set?: InputMaybe<Document_Token_Set_Input>;
  pk_columns: Document_Token_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Token_ManyArgs = {
  updates: Array<Document_Token_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Email_TemplatesArgs = {
  _append?: InputMaybe<Email_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Email_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Email_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Email_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Email_Templates_Prepend_Input>;
  _set?: InputMaybe<Email_Templates_Set_Input>;
  where: Email_Templates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Templates_By_PkArgs = {
  _append?: InputMaybe<Email_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Email_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Email_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Email_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Email_Templates_Prepend_Input>;
  _set?: InputMaybe<Email_Templates_Set_Input>;
  pk_columns: Email_Templates_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Templates_ManyArgs = {
  updates: Array<Email_Templates_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Employee_Bandwidth_DataArgs = {
  _inc?: InputMaybe<Employee_Bandwidth_Data_Inc_Input>;
  _set?: InputMaybe<Employee_Bandwidth_Data_Set_Input>;
  where: Employee_Bandwidth_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Employee_Bandwidth_Data_By_PkArgs = {
  _inc?: InputMaybe<Employee_Bandwidth_Data_Inc_Input>;
  _set?: InputMaybe<Employee_Bandwidth_Data_Set_Input>;
  pk_columns: Employee_Bandwidth_Data_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Employee_Bandwidth_Data_ManyArgs = {
  updates: Array<Employee_Bandwidth_Data_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Employee_CapacityArgs = {
  _inc?: InputMaybe<Employee_Capacity_Inc_Input>;
  _set?: InputMaybe<Employee_Capacity_Set_Input>;
  where: Employee_Capacity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Employee_Capacity_By_PkArgs = {
  _inc?: InputMaybe<Employee_Capacity_Inc_Input>;
  _set?: InputMaybe<Employee_Capacity_Set_Input>;
  pk_columns: Employee_Capacity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Employee_Capacity_ManyArgs = {
  updates: Array<Employee_Capacity_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Activity_StateArgs = {
  _set?: InputMaybe<Enum_Activity_State_Set_Input>;
  where: Enum_Activity_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Activity_State_By_PkArgs = {
  _set?: InputMaybe<Enum_Activity_State_Set_Input>;
  pk_columns: Enum_Activity_State_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Activity_State_ManyArgs = {
  updates: Array<Enum_Activity_State_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Activity_TypeArgs = {
  _set?: InputMaybe<Enum_Activity_Type_Set_Input>;
  where: Enum_Activity_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Activity_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Activity_Type_Set_Input>;
  pk_columns: Enum_Activity_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Activity_Type_ManyArgs = {
  updates: Array<Enum_Activity_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Attendee_Status_TypeArgs = {
  _set?: InputMaybe<Enum_Attendee_Status_Type_Set_Input>;
  where: Enum_Attendee_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Attendee_Status_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Attendee_Status_Type_Set_Input>;
  pk_columns: Enum_Attendee_Status_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Attendee_Status_Type_ManyArgs = {
  updates: Array<Enum_Attendee_Status_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Attendee_TypeArgs = {
  _set?: InputMaybe<Enum_Attendee_Type_Set_Input>;
  where: Enum_Attendee_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Attendee_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Attendee_Type_Set_Input>;
  pk_columns: Enum_Attendee_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Attendee_Type_ManyArgs = {
  updates: Array<Enum_Attendee_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Award_Cycle_TypeArgs = {
  _set?: InputMaybe<Enum_Award_Cycle_Type_Set_Input>;
  where: Enum_Award_Cycle_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Award_Cycle_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Award_Cycle_Type_Set_Input>;
  pk_columns: Enum_Award_Cycle_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Award_Cycle_Type_ManyArgs = {
  updates: Array<Enum_Award_Cycle_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Access_PolicyArgs = {
  _set?: InputMaybe<Enum_Call_Access_Policy_Set_Input>;
  where: Enum_Call_Access_Policy_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Access_Policy_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Access_Policy_Set_Input>;
  pk_columns: Enum_Call_Access_Policy_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Access_Policy_ManyArgs = {
  updates: Array<Enum_Call_Access_Policy_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Broadcasting_TypeArgs = {
  _set?: InputMaybe<Enum_Call_Broadcasting_Type_Set_Input>;
  where: Enum_Call_Broadcasting_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Broadcasting_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Broadcasting_Type_Set_Input>;
  pk_columns: Enum_Call_Broadcasting_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Broadcasting_Type_ManyArgs = {
  updates: Array<Enum_Call_Broadcasting_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Media_TypeArgs = {
  _set?: InputMaybe<Enum_Call_Media_Type_Set_Input>;
  where: Enum_Call_Media_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Media_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Media_Type_Set_Input>;
  pk_columns: Enum_Call_Media_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Media_Type_ManyArgs = {
  updates: Array<Enum_Call_Media_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Recording_StatusArgs = {
  _set?: InputMaybe<Enum_Call_Recording_Status_Set_Input>;
  where: Enum_Call_Recording_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Recording_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Recording_Status_Set_Input>;
  pk_columns: Enum_Call_Recording_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Recording_Status_ManyArgs = {
  updates: Array<Enum_Call_Recording_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_Response_TypeArgs = {
  _set?: InputMaybe<Enum_Call_Request_Response_Type_Set_Input>;
  where: Enum_Call_Request_Response_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_Response_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Request_Response_Type_Set_Input>;
  pk_columns: Enum_Call_Request_Response_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_Response_Type_ManyArgs = {
  updates: Array<Enum_Call_Request_Response_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_Status_TypeArgs = {
  _set?: InputMaybe<Enum_Call_Request_Status_Type_Set_Input>;
  where: Enum_Call_Request_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_Status_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Request_Status_Type_Set_Input>;
  pk_columns: Enum_Call_Request_Status_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_Status_Type_ManyArgs = {
  updates: Array<Enum_Call_Request_Status_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_TypeArgs = {
  _set?: InputMaybe<Enum_Call_Request_Type_Set_Input>;
  where: Enum_Call_Request_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Request_Type_Set_Input>;
  pk_columns: Enum_Call_Request_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Request_Type_ManyArgs = {
  updates: Array<Enum_Call_Request_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Status_TypeArgs = {
  _set?: InputMaybe<Enum_Call_Status_Type_Set_Input>;
  where: Enum_Call_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Status_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Status_Type_Set_Input>;
  pk_columns: Enum_Call_Status_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Status_Type_ManyArgs = {
  updates: Array<Enum_Call_Status_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_TypeArgs = {
  _set?: InputMaybe<Enum_Call_Type_Set_Input>;
  where: Enum_Call_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_Type_Set_Input>;
  pk_columns: Enum_Call_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_Type_ManyArgs = {
  updates: Array<Enum_Call_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_User_StatusArgs = {
  _set?: InputMaybe<Enum_Call_User_Status_Set_Input>;
  where: Enum_Call_User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_User_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Call_User_Status_Set_Input>;
  pk_columns: Enum_Call_User_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Call_User_Status_ManyArgs = {
  updates: Array<Enum_Call_User_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Message_TypeArgs = {
  _set?: InputMaybe<Enum_Chat_Message_Type_Set_Input>;
  where: Enum_Chat_Message_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Message_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Chat_Message_Type_Set_Input>;
  pk_columns: Enum_Chat_Message_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Message_Type_ManyArgs = {
  updates: Array<Enum_Chat_Message_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_NotificationArgs = {
  _set?: InputMaybe<Enum_Chat_Notification_Set_Input>;
  where: Enum_Chat_Notification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Notification_By_PkArgs = {
  _set?: InputMaybe<Enum_Chat_Notification_Set_Input>;
  pk_columns: Enum_Chat_Notification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Notification_ManyArgs = {
  updates: Array<Enum_Chat_Notification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_Notification_StatusArgs = {
  _set?: InputMaybe<Enum_Chat_Room_Notification_Status_Set_Input>;
  where: Enum_Chat_Room_Notification_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_Notification_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Chat_Room_Notification_Status_Set_Input>;
  pk_columns: Enum_Chat_Room_Notification_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_Notification_Status_ManyArgs = {
  updates: Array<Enum_Chat_Room_Notification_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_TypeArgs = {
  _set?: InputMaybe<Enum_Chat_Room_Type_Set_Input>;
  where: Enum_Chat_Room_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Chat_Room_Type_Set_Input>;
  pk_columns: Enum_Chat_Room_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_Type_ManyArgs = {
  updates: Array<Enum_Chat_Room_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_User_StatusArgs = {
  _set?: InputMaybe<Enum_Chat_Room_User_Status_Set_Input>;
  where: Enum_Chat_Room_User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_User_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Chat_Room_User_Status_Set_Input>;
  pk_columns: Enum_Chat_Room_User_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Chat_Room_User_Status_ManyArgs = {
  updates: Array<Enum_Chat_Room_User_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Dashboard_Remainder_TypeArgs = {
  _set?: InputMaybe<Enum_Dashboard_Remainder_Type_Set_Input>;
  where: Enum_Dashboard_Remainder_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Dashboard_Remainder_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Dashboard_Remainder_Type_Set_Input>;
  pk_columns: Enum_Dashboard_Remainder_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Dashboard_Remainder_Type_ManyArgs = {
  updates: Array<Enum_Dashboard_Remainder_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Document_Token_TypeArgs = {
  _set?: InputMaybe<Enum_Document_Token_Type_Set_Input>;
  where: Enum_Document_Token_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Document_Token_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Document_Token_Type_Set_Input>;
  pk_columns: Enum_Document_Token_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Document_Token_Type_ManyArgs = {
  updates: Array<Enum_Document_Token_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Event_TypeArgs = {
  _set?: InputMaybe<Enum_Event_Type_Set_Input>;
  where: Enum_Event_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Event_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Event_Type_Set_Input>;
  pk_columns: Enum_Event_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Event_Type_ManyArgs = {
  updates: Array<Enum_Event_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Leave_Donate_TypeArgs = {
  _set?: InputMaybe<Enum_Leave_Donate_Type_Set_Input>;
  where: Enum_Leave_Donate_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Leave_Donate_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Leave_Donate_Type_Set_Input>;
  pk_columns: Enum_Leave_Donate_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Leave_Donate_Type_ManyArgs = {
  updates: Array<Enum_Leave_Donate_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Leave_Status_TypeArgs = {
  _set?: InputMaybe<Enum_Leave_Status_Type_Set_Input>;
  where: Enum_Leave_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Leave_Status_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Leave_Status_Type_Set_Input>;
  pk_columns: Enum_Leave_Status_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Leave_Status_Type_ManyArgs = {
  updates: Array<Enum_Leave_Status_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_Request_StatusArgs = {
  _set?: InputMaybe<Enum_Live_Call_Request_Status_Set_Input>;
  where: Enum_Live_Call_Request_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_Request_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Live_Call_Request_Status_Set_Input>;
  pk_columns: Enum_Live_Call_Request_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_Request_Status_ManyArgs = {
  updates: Array<Enum_Live_Call_Request_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_StatusArgs = {
  _set?: InputMaybe<Enum_Live_Call_Status_Set_Input>;
  where: Enum_Live_Call_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Live_Call_Status_Set_Input>;
  pk_columns: Enum_Live_Call_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_Status_ManyArgs = {
  updates: Array<Enum_Live_Call_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_User_StatusArgs = {
  _set?: InputMaybe<Enum_Live_Call_User_Status_Set_Input>;
  where: Enum_Live_Call_User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_User_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Live_Call_User_Status_Set_Input>;
  pk_columns: Enum_Live_Call_User_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Call_User_Status_ManyArgs = {
  updates: Array<Enum_Live_Call_User_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Emote_TypeArgs = {
  _set?: InputMaybe<Enum_Live_Emote_Type_Set_Input>;
  where: Enum_Live_Emote_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Emote_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Live_Emote_Type_Set_Input>;
  pk_columns: Enum_Live_Emote_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Live_Emote_Type_ManyArgs = {
  updates: Array<Enum_Live_Emote_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Manual_StateArgs = {
  _set?: InputMaybe<Enum_Manual_State_Set_Input>;
  where: Enum_Manual_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Manual_State_By_PkArgs = {
  _set?: InputMaybe<Enum_Manual_State_Set_Input>;
  pk_columns: Enum_Manual_State_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Manual_State_ManyArgs = {
  updates: Array<Enum_Manual_State_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_StatusArgs = {
  _set?: InputMaybe<Enum_Meeting_Room_Status_Set_Input>;
  where: Enum_Meeting_Room_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Meeting_Room_Status_Set_Input>;
  pk_columns: Enum_Meeting_Room_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_Status_ManyArgs = {
  updates: Array<Enum_Meeting_Room_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_TypeArgs = {
  _set?: InputMaybe<Enum_Meeting_Room_Type_Set_Input>;
  where: Enum_Meeting_Room_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Meeting_Room_Type_Set_Input>;
  pk_columns: Enum_Meeting_Room_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_Type_ManyArgs = {
  updates: Array<Enum_Meeting_Room_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_User_StateArgs = {
  _set?: InputMaybe<Enum_Meeting_Room_User_State_Set_Input>;
  where: Enum_Meeting_Room_User_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_User_State_By_PkArgs = {
  _set?: InputMaybe<Enum_Meeting_Room_User_State_Set_Input>;
  pk_columns: Enum_Meeting_Room_User_State_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Meeting_Room_User_State_ManyArgs = {
  updates: Array<Enum_Meeting_Room_User_State_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_TypeArgs = {
  _set?: InputMaybe<Enum_Notification_Type_Set_Input>;
  where: Enum_Notification_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Notification_Type_Set_Input>;
  pk_columns: Enum_Notification_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_Type_ManyArgs = {
  updates: Array<Enum_Notification_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Personalised_AccessArgs = {
  _set?: InputMaybe<Enum_Personalised_Access_Set_Input>;
  where: Enum_Personalised_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Personalised_Access_By_PkArgs = {
  _set?: InputMaybe<Enum_Personalised_Access_Set_Input>;
  pk_columns: Enum_Personalised_Access_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Personalised_Access_ManyArgs = {
  updates: Array<Enum_Personalised_Access_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Personalised_CategoriesArgs = {
  _set?: InputMaybe<Enum_Personalised_Categories_Set_Input>;
  where: Enum_Personalised_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Personalised_Categories_By_PkArgs = {
  _set?: InputMaybe<Enum_Personalised_Categories_Set_Input>;
  pk_columns: Enum_Personalised_Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Personalised_Categories_ManyArgs = {
  updates: Array<Enum_Personalised_Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Post_TypeArgs = {
  _set?: InputMaybe<Enum_Post_Type_Set_Input>;
  where: Enum_Post_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Post_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Post_Type_Set_Input>;
  pk_columns: Enum_Post_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Post_Type_ManyArgs = {
  updates: Array<Enum_Post_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Presence_Connection_StatusArgs = {
  _set?: InputMaybe<Enum_Presence_Connection_Status_Set_Input>;
  where: Enum_Presence_Connection_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Presence_Connection_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Presence_Connection_Status_Set_Input>;
  pk_columns: Enum_Presence_Connection_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Presence_Connection_Status_ManyArgs = {
  updates: Array<Enum_Presence_Connection_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Milestone_StatusArgs = {
  _set?: InputMaybe<Enum_Project_Milestone_Status_Set_Input>;
  where: Enum_Project_Milestone_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Milestone_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Project_Milestone_Status_Set_Input>;
  pk_columns: Enum_Project_Milestone_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Milestone_Status_ManyArgs = {
  updates: Array<Enum_Project_Milestone_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Sprint_CategoryArgs = {
  _set?: InputMaybe<Enum_Project_Sprint_Category_Set_Input>;
  where: Enum_Project_Sprint_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Sprint_Category_By_PkArgs = {
  _set?: InputMaybe<Enum_Project_Sprint_Category_Set_Input>;
  pk_columns: Enum_Project_Sprint_Category_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Sprint_Category_ManyArgs = {
  updates: Array<Enum_Project_Sprint_Category_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_StatusArgs = {
  _set?: InputMaybe<Enum_Project_Status_Set_Input>;
  where: Enum_Project_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Project_Status_Set_Input>;
  pk_columns: Enum_Project_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Status_ManyArgs = {
  updates: Array<Enum_Project_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Task_CategoryArgs = {
  _set?: InputMaybe<Enum_Project_Task_Category_Set_Input>;
  where: Enum_Project_Task_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Task_Category_By_PkArgs = {
  _set?: InputMaybe<Enum_Project_Task_Category_Set_Input>;
  pk_columns: Enum_Project_Task_Category_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Project_Task_Category_ManyArgs = {
  updates: Array<Enum_Project_Task_Category_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Setting_TypeArgs = {
  _set?: InputMaybe<Enum_Setting_Type_Set_Input>;
  where: Enum_Setting_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Setting_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Setting_Type_Set_Input>;
  pk_columns: Enum_Setting_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Setting_Type_ManyArgs = {
  updates: Array<Enum_Setting_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Storage_File_TypeArgs = {
  _set?: InputMaybe<Enum_Storage_File_Type_Set_Input>;
  where: Enum_Storage_File_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Storage_File_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Storage_File_Type_Set_Input>;
  pk_columns: Enum_Storage_File_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Storage_File_Type_ManyArgs = {
  updates: Array<Enum_Storage_File_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_System_StateArgs = {
  _set?: InputMaybe<Enum_System_State_Set_Input>;
  where: Enum_System_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_System_State_By_PkArgs = {
  _set?: InputMaybe<Enum_System_State_Set_Input>;
  pk_columns: Enum_System_State_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_System_State_ManyArgs = {
  updates: Array<Enum_System_State_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_System_State_OwnerArgs = {
  _set?: InputMaybe<Enum_System_State_Owner_Set_Input>;
  where: Enum_System_State_Owner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_System_State_Owner_By_PkArgs = {
  _set?: InputMaybe<Enum_System_State_Owner_Set_Input>;
  pk_columns: Enum_System_State_Owner_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_System_State_Owner_ManyArgs = {
  updates: Array<Enum_System_State_Owner_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_PriorityArgs = {
  _set?: InputMaybe<Enum_Task_Priority_Set_Input>;
  where: Enum_Task_Priority_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Priority_By_PkArgs = {
  _set?: InputMaybe<Enum_Task_Priority_Set_Input>;
  pk_columns: Enum_Task_Priority_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Priority_ManyArgs = {
  updates: Array<Enum_Task_Priority_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Recurring_FrequencyArgs = {
  _set?: InputMaybe<Enum_Task_Recurring_Frequency_Set_Input>;
  where: Enum_Task_Recurring_Frequency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Recurring_Frequency_By_PkArgs = {
  _set?: InputMaybe<Enum_Task_Recurring_Frequency_Set_Input>;
  pk_columns: Enum_Task_Recurring_Frequency_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Recurring_Frequency_ManyArgs = {
  updates: Array<Enum_Task_Recurring_Frequency_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_StatusArgs = {
  _set?: InputMaybe<Enum_Task_Status_Set_Input>;
  where: Enum_Task_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Task_Status_Set_Input>;
  pk_columns: Enum_Task_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Status_ManyArgs = {
  updates: Array<Enum_Task_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Time_Sheet_StatusArgs = {
  _set?: InputMaybe<Enum_Task_Time_Sheet_Status_Set_Input>;
  where: Enum_Task_Time_Sheet_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Time_Sheet_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Task_Time_Sheet_Status_Set_Input>;
  pk_columns: Enum_Task_Time_Sheet_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Task_Time_Sheet_Status_ManyArgs = {
  updates: Array<Enum_Task_Time_Sheet_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Activity_Tracker_Status_TypeArgs = {
  _set?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Set_Input>;
  where: Enum_User_Activity_Tracker_Status_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Activity_Tracker_Status_Type_By_PkArgs =
  {
    _set?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Set_Input>;
    pk_columns: Enum_User_Activity_Tracker_Status_Type_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Activity_Tracker_Status_Type_ManyArgs =
  {
    updates: Array<Enum_User_Activity_Tracker_Status_Type_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Avatar_TypesArgs = {
  _set?: InputMaybe<Enum_User_Avatar_Types_Set_Input>;
  where: Enum_User_Avatar_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Avatar_Types_By_PkArgs = {
  _set?: InputMaybe<Enum_User_Avatar_Types_Set_Input>;
  pk_columns: Enum_User_Avatar_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Avatar_Types_ManyArgs = {
  updates: Array<Enum_User_Avatar_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_GenderArgs = {
  _set?: InputMaybe<Enum_User_Gender_Set_Input>;
  where: Enum_User_Gender_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Gender_By_PkArgs = {
  _set?: InputMaybe<Enum_User_Gender_Set_Input>;
  pk_columns: Enum_User_Gender_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Gender_ManyArgs = {
  updates: Array<Enum_User_Gender_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Presence_Provider_TypeArgs = {
  _set?: InputMaybe<Enum_User_Presence_Provider_Type_Set_Input>;
  where: Enum_User_Presence_Provider_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Presence_Provider_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_User_Presence_Provider_Type_Set_Input>;
  pk_columns: Enum_User_Presence_Provider_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Presence_Provider_Type_ManyArgs = {
  updates: Array<Enum_User_Presence_Provider_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Settings_TypeArgs = {
  _set?: InputMaybe<Enum_User_Settings_Type_Set_Input>;
  where: Enum_User_Settings_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Settings_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_User_Settings_Type_Set_Input>;
  pk_columns: Enum_User_Settings_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_User_Settings_Type_ManyArgs = {
  updates: Array<Enum_User_Settings_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Filter_DefinitionArgs = {
  _set?: InputMaybe<Filter_Definition_Set_Input>;
  where: Filter_Definition_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Filter_Definition_By_PkArgs = {
  _set?: InputMaybe<Filter_Definition_Set_Input>;
  pk_columns: Filter_Definition_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Filter_Definition_ManyArgs = {
  updates: Array<Filter_Definition_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FinancialYearArgs = {
  _set?: InputMaybe<FinancialYear_Set_Input>;
  where: FinancialYear_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_FinancialYear_By_PkArgs = {
  _set?: InputMaybe<FinancialYear_Set_Input>;
  pk_columns: FinancialYear_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_FinancialYear_ManyArgs = {
  updates: Array<FinancialYear_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FrequencyArgs = {
  _set?: InputMaybe<Frequency_Set_Input>;
  where: Frequency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Frequency_By_PkArgs = {
  _set?: InputMaybe<Frequency_Set_Input>;
  pk_columns: Frequency_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Frequency_ManyArgs = {
  updates: Array<Frequency_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GoalWithKpiArgs = {
  _set?: InputMaybe<GoalWithKpi_Set_Input>;
  where: GoalWithKpi_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GoalWithKpi_By_PkArgs = {
  _set?: InputMaybe<GoalWithKpi_Set_Input>;
  pk_columns: GoalWithKpi_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GoalWithKpi_ManyArgs = {
  updates: Array<GoalWithKpi_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GoalWithTimePeriodArgs = {
  _set?: InputMaybe<GoalWithTimePeriod_Set_Input>;
  where: GoalWithTimePeriod_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GoalWithTimePeriod_By_PkArgs = {
  _set?: InputMaybe<GoalWithTimePeriod_Set_Input>;
  pk_columns: GoalWithTimePeriod_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GoalWithTimePeriod_ManyArgs = {
  updates: Array<GoalWithTimePeriod_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsArgs = {
  _set?: InputMaybe<Goals_Set_Input>;
  where: Goals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithDeptsArgs = {
  _set?: InputMaybe<GoalsWithDepts_Set_Input>;
  where: GoalsWithDepts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithDepts_By_PkArgs = {
  _set?: InputMaybe<GoalsWithDepts_Set_Input>;
  pk_columns: GoalsWithDepts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithDepts_ManyArgs = {
  updates: Array<GoalsWithDepts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithFreqsArgs = {
  _set?: InputMaybe<GoalsWithFreqs_Set_Input>;
  where: GoalsWithFreqs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithFreqs_By_PkArgs = {
  _set?: InputMaybe<GoalsWithFreqs_Set_Input>;
  pk_columns: GoalsWithFreqs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithFreqs_ManyArgs = {
  updates: Array<GoalsWithFreqs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithRolesArgs = {
  _inc?: InputMaybe<GoalsWithRoles_Inc_Input>;
  _set?: InputMaybe<GoalsWithRoles_Set_Input>;
  where: GoalsWithRoles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithRoles_By_PkArgs = {
  _inc?: InputMaybe<GoalsWithRoles_Inc_Input>;
  _set?: InputMaybe<GoalsWithRoles_Set_Input>;
  pk_columns: GoalsWithRoles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GoalsWithRoles_ManyArgs = {
  updates: Array<GoalsWithRoles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Goals_By_PkArgs = {
  _set?: InputMaybe<Goals_Set_Input>;
  pk_columns: Goals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Goals_ManyArgs = {
  updates: Array<Goals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hotdesk_BookingsArgs = {
  _set?: InputMaybe<Hotdesk_Bookings_Set_Input>;
  where: Hotdesk_Bookings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hotdesk_Bookings_By_PkArgs = {
  _set?: InputMaybe<Hotdesk_Bookings_Set_Input>;
  pk_columns: Hotdesk_Bookings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hotdesk_Bookings_ManyArgs = {
  updates: Array<Hotdesk_Bookings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_KpiWithDeptArgs = {
  _set?: InputMaybe<KpiWithDept_Set_Input>;
  where: KpiWithDept_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_KpiWithDept_By_PkArgs = {
  _set?: InputMaybe<KpiWithDept_Set_Input>;
  pk_columns: KpiWithDept_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_KpiWithDept_ManyArgs = {
  updates: Array<KpiWithDept_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_KpiWithRoleArgs = {
  _set?: InputMaybe<KpiWithRole_Set_Input>;
  where: KpiWithRole_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_KpiWithRole_By_PkArgs = {
  _set?: InputMaybe<KpiWithRole_Set_Input>;
  pk_columns: KpiWithRole_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_KpiWithRole_ManyArgs = {
  updates: Array<KpiWithRole_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Kpi_BankArgs = {
  _set?: InputMaybe<Kpi_Bank_Set_Input>;
  where: Kpi_Bank_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kpi_Bank_By_PkArgs = {
  _set?: InputMaybe<Kpi_Bank_Set_Input>;
  pk_columns: Kpi_Bank_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Kpi_Bank_ManyArgs = {
  updates: Array<Kpi_Bank_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LeaveArgs = {
  _append?: InputMaybe<Leave_Append_Input>;
  _delete_at_path?: InputMaybe<Leave_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Leave_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Leave_Delete_Key_Input>;
  _inc?: InputMaybe<Leave_Inc_Input>;
  _prepend?: InputMaybe<Leave_Prepend_Input>;
  _set?: InputMaybe<Leave_Set_Input>;
  where: Leave_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Leave_By_PkArgs = {
  _append?: InputMaybe<Leave_Append_Input>;
  _delete_at_path?: InputMaybe<Leave_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Leave_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Leave_Delete_Key_Input>;
  _inc?: InputMaybe<Leave_Inc_Input>;
  _prepend?: InputMaybe<Leave_Prepend_Input>;
  _set?: InputMaybe<Leave_Set_Input>;
  pk_columns: Leave_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Leave_DonateArgs = {
  _inc?: InputMaybe<Leave_Donate_Inc_Input>;
  _set?: InputMaybe<Leave_Donate_Set_Input>;
  where: Leave_Donate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Leave_Donate_By_PkArgs = {
  _inc?: InputMaybe<Leave_Donate_Inc_Input>;
  _set?: InputMaybe<Leave_Donate_Set_Input>;
  pk_columns: Leave_Donate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Leave_Donate_ManyArgs = {
  updates: Array<Leave_Donate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Leave_ManyArgs = {
  updates: Array<Leave_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Leave_OrganizationArgs = {
  _inc?: InputMaybe<Leave_Organization_Inc_Input>;
  _set?: InputMaybe<Leave_Organization_Set_Input>;
  where: Leave_Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Leave_Organization_By_PkArgs = {
  _inc?: InputMaybe<Leave_Organization_Inc_Input>;
  _set?: InputMaybe<Leave_Organization_Set_Input>;
  pk_columns: Leave_Organization_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Leave_Organization_ManyArgs = {
  updates: Array<Leave_Organization_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_List_Of_SkillsArgs = {
  _set?: InputMaybe<List_Of_Skills_Set_Input>;
  where: List_Of_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_List_Of_Skills_By_PkArgs = {
  _set?: InputMaybe<List_Of_Skills_Set_Input>;
  pk_columns: List_Of_Skills_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_List_Of_Skills_ManyArgs = {
  updates: Array<List_Of_Skills_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Live_CallArgs = {
  _set?: InputMaybe<Live_Call_Set_Input>;
  where: Live_Call_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Live_Call_By_PkArgs = {
  _set?: InputMaybe<Live_Call_Set_Input>;
  pk_columns: Live_Call_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Live_Call_ManyArgs = {
  updates: Array<Live_Call_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Live_Call_RequestArgs = {
  _set?: InputMaybe<Live_Call_Request_Set_Input>;
  where: Live_Call_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Live_Call_Request_By_PkArgs = {
  _set?: InputMaybe<Live_Call_Request_Set_Input>;
  pk_columns: Live_Call_Request_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Live_Call_Request_ManyArgs = {
  updates: Array<Live_Call_Request_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Live_Call_UserArgs = {
  _set?: InputMaybe<Live_Call_User_Set_Input>;
  where: Live_Call_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Live_Call_User_By_PkArgs = {
  _set?: InputMaybe<Live_Call_User_Set_Input>;
  pk_columns: Live_Call_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Live_Call_User_ManyArgs = {
  updates: Array<Live_Call_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Main_SkillsArgs = {
  _set?: InputMaybe<Main_Skills_Set_Input>;
  where: Main_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Main_Skills_By_PkArgs = {
  _set?: InputMaybe<Main_Skills_Set_Input>;
  pk_columns: Main_Skills_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Main_Skills_ManyArgs = {
  updates: Array<Main_Skills_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_Call_SchedularArgs = {
  _set?: InputMaybe<Meeting_Call_Schedular_Set_Input>;
  where: Meeting_Call_Schedular_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_Call_Schedular_By_PkArgs = {
  _set?: InputMaybe<Meeting_Call_Schedular_Set_Input>;
  pk_columns: Meeting_Call_Schedular_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_Call_Schedular_ManyArgs = {
  updates: Array<Meeting_Call_Schedular_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_RoomArgs = {
  _set?: InputMaybe<Meeting_Room_Set_Input>;
  where: Meeting_Room_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_Room_By_PkArgs = {
  _set?: InputMaybe<Meeting_Room_Set_Input>;
  pk_columns: Meeting_Room_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_Room_ManyArgs = {
  updates: Array<Meeting_Room_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_Room_UsersArgs = {
  _set?: InputMaybe<Meeting_Room_Users_Set_Input>;
  where: Meeting_Room_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_Room_Users_By_PkArgs = {
  _set?: InputMaybe<Meeting_Room_Users_Set_Input>;
  pk_columns: Meeting_Room_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Meeting_Room_Users_ManyArgs = {
  updates: Array<Meeting_Room_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganizationArgs = {
  _append?: InputMaybe<Organization_Append_Input>;
  _delete_at_path?: InputMaybe<Organization_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organization_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organization_Delete_Key_Input>;
  _inc?: InputMaybe<Organization_Inc_Input>;
  _prepend?: InputMaybe<Organization_Prepend_Input>;
  _set?: InputMaybe<Organization_Set_Input>;
  where: Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Active_Leave_TypesArgs = {
  _inc?: InputMaybe<Organization_Active_Leave_Types_Inc_Input>;
  _set?: InputMaybe<Organization_Active_Leave_Types_Set_Input>;
  where: Organization_Active_Leave_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Active_Leave_Types_By_PkArgs = {
  _inc?: InputMaybe<Organization_Active_Leave_Types_Inc_Input>;
  _set?: InputMaybe<Organization_Active_Leave_Types_Set_Input>;
  pk_columns: Organization_Active_Leave_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Active_Leave_Types_ManyArgs = {
  updates: Array<Organization_Active_Leave_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_AdminArgs = {
  _set?: InputMaybe<Organization_Admin_Set_Input>;
  where: Organization_Admin_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Admin_By_PkArgs = {
  _set?: InputMaybe<Organization_Admin_Set_Input>;
  pk_columns: Organization_Admin_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Admin_ManyArgs = {
  updates: Array<Organization_Admin_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_AwardsArgs = {
  _set?: InputMaybe<Organization_Awards_Set_Input>;
  where: Organization_Awards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Awards_By_PkArgs = {
  _set?: InputMaybe<Organization_Awards_Set_Input>;
  pk_columns: Organization_Awards_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Awards_ManyArgs = {
  updates: Array<Organization_Awards_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_By_PkArgs = {
  _append?: InputMaybe<Organization_Append_Input>;
  _delete_at_path?: InputMaybe<Organization_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organization_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organization_Delete_Key_Input>;
  _inc?: InputMaybe<Organization_Inc_Input>;
  _prepend?: InputMaybe<Organization_Prepend_Input>;
  _set?: InputMaybe<Organization_Set_Input>;
  pk_columns: Organization_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_EmployeeArgs = {
  _set?: InputMaybe<Organization_Employee_Set_Input>;
  where: Organization_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Employee_By_PkArgs = {
  _set?: InputMaybe<Organization_Employee_Set_Input>;
  pk_columns: Organization_Employee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Employee_ManyArgs = {
  updates: Array<Organization_Employee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_HolidaysArgs = {
  _set?: InputMaybe<Organization_Holidays_Set_Input>;
  where: Organization_Holidays_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Holidays_By_PkArgs = {
  _set?: InputMaybe<Organization_Holidays_Set_Input>;
  pk_columns: Organization_Holidays_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Holidays_ManyArgs = {
  updates: Array<Organization_Holidays_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Leave_CyclesArgs = {
  _set?: InputMaybe<Organization_Leave_Cycles_Set_Input>;
  where: Organization_Leave_Cycles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Leave_Cycles_By_PkArgs = {
  _set?: InputMaybe<Organization_Leave_Cycles_Set_Input>;
  pk_columns: Organization_Leave_Cycles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Leave_Cycles_ManyArgs = {
  updates: Array<Organization_Leave_Cycles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_LocationsArgs = {
  _inc?: InputMaybe<Organization_Locations_Inc_Input>;
  _set?: InputMaybe<Organization_Locations_Set_Input>;
  where: Organization_Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Locations_By_PkArgs = {
  _inc?: InputMaybe<Organization_Locations_Inc_Input>;
  _set?: InputMaybe<Organization_Locations_Set_Input>;
  pk_columns: Organization_Locations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Locations_ManyArgs = {
  updates: Array<Organization_Locations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_ManyArgs = {
  updates: Array<Organization_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_RolesArgs = {
  _set?: InputMaybe<Organization_Roles_Set_Input>;
  where: Organization_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Roles_By_PkArgs = {
  _set?: InputMaybe<Organization_Roles_Set_Input>;
  pk_columns: Organization_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Roles_ManyArgs = {
  updates: Array<Organization_Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_ApplicationArgs = {
  _inc?: InputMaybe<Organization_User_Leave_Application_Inc_Input>;
  _set?: InputMaybe<Organization_User_Leave_Application_Set_Input>;
  where: Organization_User_Leave_Application_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_Application_By_PkArgs =
  {
    _inc?: InputMaybe<Organization_User_Leave_Application_Inc_Input>;
    _set?: InputMaybe<Organization_User_Leave_Application_Set_Input>;
    pk_columns: Organization_User_Leave_Application_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_Application_ManyArgs = {
  updates: Array<Organization_User_Leave_Application_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_DataArgs = {
  _inc?: InputMaybe<Organization_User_Leave_Data_Inc_Input>;
  _set?: InputMaybe<Organization_User_Leave_Data_Set_Input>;
  where: Organization_User_Leave_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_Data_By_PkArgs = {
  _inc?: InputMaybe<Organization_User_Leave_Data_Inc_Input>;
  _set?: InputMaybe<Organization_User_Leave_Data_Set_Input>;
  pk_columns: Organization_User_Leave_Data_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_Data_ManyArgs = {
  updates: Array<Organization_User_Leave_Data_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_DatesArgs = {
  _set?: InputMaybe<Organization_User_Leave_Dates_Set_Input>;
  where: Organization_User_Leave_Dates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_Dates_By_PkArgs = {
  _set?: InputMaybe<Organization_User_Leave_Dates_Set_Input>;
  pk_columns: Organization_User_Leave_Dates_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_Dates_ManyArgs = {
  updates: Array<Organization_User_Leave_Dates_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_FilesArgs = {
  _set?: InputMaybe<Organization_User_Leave_Files_Set_Input>;
  where: Organization_User_Leave_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_Files_By_PkArgs = {
  _set?: InputMaybe<Organization_User_Leave_Files_Set_Input>;
  pk_columns: Organization_User_Leave_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_User_Leave_Files_ManyArgs = {
  updates: Array<Organization_User_Leave_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PerformanceReviewStatusArgs = {
  _set?: InputMaybe<PerformanceReviewStatus_Set_Input>;
  where: PerformanceReviewStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PerformanceReviewStatus_By_PkArgs = {
  _set?: InputMaybe<PerformanceReviewStatus_Set_Input>;
  pk_columns: PerformanceReviewStatus_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_PerformanceReviewStatus_ManyArgs = {
  updates: Array<PerformanceReviewStatus_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Rating_ScaleArgs = {
  _inc?: InputMaybe<Performance_Rating_Scale_Inc_Input>;
  _set?: InputMaybe<Performance_Rating_Scale_Set_Input>;
  where: Performance_Rating_Scale_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Rating_Scale_By_PkArgs = {
  _inc?: InputMaybe<Performance_Rating_Scale_Inc_Input>;
  _set?: InputMaybe<Performance_Rating_Scale_Set_Input>;
  pk_columns: Performance_Rating_Scale_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Rating_Scale_ManyArgs = {
  updates: Array<Performance_Rating_Scale_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUserArgs = {
  _inc?: InputMaybe<Performance_Review_ByUser_Inc_Input>;
  _set?: InputMaybe<Performance_Review_ByUser_Set_Input>;
  where: Performance_Review_ByUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUser_KpiArgs = {
  _inc?: InputMaybe<Performance_Review_ByUser_Kpi_Inc_Input>;
  _set?: InputMaybe<Performance_Review_ByUser_Kpi_Set_Input>;
  where: Performance_Review_ByUser_Kpi_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUser_Kpi_By_PkArgs = {
  _inc?: InputMaybe<Performance_Review_ByUser_Kpi_Inc_Input>;
  _set?: InputMaybe<Performance_Review_ByUser_Kpi_Set_Input>;
  pk_columns: Performance_Review_ByUser_Kpi_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUser_Kpi_ManyArgs = {
  updates: Array<Performance_Review_ByUser_Kpi_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUser_WithGoalArgs = {
  _inc?: InputMaybe<Performance_Review_ByUser_WithGoal_Inc_Input>;
  _set?: InputMaybe<Performance_Review_ByUser_WithGoal_Set_Input>;
  where: Performance_Review_ByUser_WithGoal_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUser_WithGoal_By_PkArgs = {
  _inc?: InputMaybe<Performance_Review_ByUser_WithGoal_Inc_Input>;
  _set?: InputMaybe<Performance_Review_ByUser_WithGoal_Set_Input>;
  pk_columns: Performance_Review_ByUser_WithGoal_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUser_WithGoal_ManyArgs = {
  updates: Array<Performance_Review_ByUser_WithGoal_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUser_By_PkArgs = {
  _inc?: InputMaybe<Performance_Review_ByUser_Inc_Input>;
  _set?: InputMaybe<Performance_Review_ByUser_Set_Input>;
  pk_columns: Performance_Review_ByUser_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_ByUser_ManyArgs = {
  updates: Array<Performance_Review_ByUser_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_Setting_GoalsListArgs = {
  _set?: InputMaybe<Performance_Review_Setting_GoalsList_Set_Input>;
  where: Performance_Review_Setting_GoalsList_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_Setting_GoalsList_By_PkArgs =
  {
    _set?: InputMaybe<Performance_Review_Setting_GoalsList_Set_Input>;
    pk_columns: Performance_Review_Setting_GoalsList_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_Setting_GoalsList_ManyArgs =
  {
    updates: Array<Performance_Review_Setting_GoalsList_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_Setting_With_GoalsArgs = {
  _inc?: InputMaybe<Performance_Review_Setting_With_Goals_Inc_Input>;
  _set?: InputMaybe<Performance_Review_Setting_With_Goals_Set_Input>;
  where: Performance_Review_Setting_With_Goals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_Setting_With_Goals_By_PkArgs =
  {
    _inc?: InputMaybe<Performance_Review_Setting_With_Goals_Inc_Input>;
    _set?: InputMaybe<Performance_Review_Setting_With_Goals_Set_Input>;
    pk_columns: Performance_Review_Setting_With_Goals_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_Setting_With_Goals_ManyArgs =
  {
    updates: Array<Performance_Review_Setting_With_Goals_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_SettingsArgs = {
  _set?: InputMaybe<Performance_Review_Settings_Set_Input>;
  where: Performance_Review_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_Settings_By_PkArgs = {
  _set?: InputMaybe<Performance_Review_Settings_Set_Input>;
  pk_columns: Performance_Review_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Review_Settings_ManyArgs = {
  updates: Array<Performance_Review_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PersonalisedArgs = {
  _set?: InputMaybe<Personalised_Set_Input>;
  where: Personalised_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Personalised_By_PkArgs = {
  _set?: InputMaybe<Personalised_Set_Input>;
  pk_columns: Personalised_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Personalised_ManyArgs = {
  updates: Array<Personalised_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_FilesArgs = {
  _set?: InputMaybe<Post_Comment_Files_Set_Input>;
  where: Post_Comment_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Files_By_PkArgs = {
  _set?: InputMaybe<Post_Comment_Files_Set_Input>;
  pk_columns: Post_Comment_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Files_ManyArgs = {
  updates: Array<Post_Comment_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Mentioned_UsersArgs = {
  _set?: InputMaybe<Post_Comment_Mentioned_Users_Set_Input>;
  where: Post_Comment_Mentioned_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Mentioned_Users_By_PkArgs = {
  _set?: InputMaybe<Post_Comment_Mentioned_Users_Set_Input>;
  pk_columns: Post_Comment_Mentioned_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Mentioned_Users_ManyArgs = {
  updates: Array<Post_Comment_Mentioned_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_RepliesArgs = {
  _set?: InputMaybe<Post_Comment_Replies_Set_Input>;
  where: Post_Comment_Replies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Replies_By_PkArgs = {
  _set?: InputMaybe<Post_Comment_Replies_Set_Input>;
  pk_columns: Post_Comment_Replies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Replies_ManyArgs = {
  updates: Array<Post_Comment_Replies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Replies_Mentioned_UsersArgs = {
  _set?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Set_Input>;
  where: Post_Comment_Replies_Mentioned_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Replies_Mentioned_Users_By_PkArgs =
  {
    _set?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Set_Input>;
    pk_columns: Post_Comment_Replies_Mentioned_Users_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Post_Comment_Replies_Mentioned_Users_ManyArgs =
  {
    updates: Array<Post_Comment_Replies_Mentioned_Users_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Post_Comments_LikesArgs = {
  _set?: InputMaybe<Post_Comments_Likes_Set_Input>;
  where: Post_Comments_Likes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comments_Likes_By_PkArgs = {
  _set?: InputMaybe<Post_Comments_Likes_Set_Input>;
  pk_columns: Post_Comments_Likes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Comments_Likes_ManyArgs = {
  updates: Array<Post_Comments_Likes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Post_LikesArgs = {
  _set?: InputMaybe<Post_Likes_Set_Input>;
  where: Post_Likes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Likes_By_PkArgs = {
  _set?: InputMaybe<Post_Likes_Set_Input>;
  pk_columns: Post_Likes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Likes_ManyArgs = {
  updates: Array<Post_Likes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Mentioned_UsersArgs = {
  _set?: InputMaybe<Post_Mentioned_Users_Set_Input>;
  where: Post_Mentioned_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Mentioned_Users_By_PkArgs = {
  _set?: InputMaybe<Post_Mentioned_Users_Set_Input>;
  pk_columns: Post_Mentioned_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Mentioned_Users_ManyArgs = {
  updates: Array<Post_Mentioned_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Replies_LikesArgs = {
  _set?: InputMaybe<Post_Replies_Likes_Set_Input>;
  where: Post_Replies_Likes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Replies_Likes_By_PkArgs = {
  _set?: InputMaybe<Post_Replies_Likes_Set_Input>;
  pk_columns: Post_Replies_Likes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Post_Replies_Likes_ManyArgs = {
  updates: Array<Post_Replies_Likes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PostsArgs = {
  _set?: InputMaybe<Posts_Set_Input>;
  where: Posts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_By_PkArgs = {
  _set?: InputMaybe<Posts_Set_Input>;
  pk_columns: Posts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_CommentsArgs = {
  _set?: InputMaybe<Posts_Comments_Set_Input>;
  where: Posts_Comments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Comments_By_PkArgs = {
  _set?: InputMaybe<Posts_Comments_Set_Input>;
  pk_columns: Posts_Comments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Comments_ManyArgs = {
  updates: Array<Posts_Comments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_FilesArgs = {
  _set?: InputMaybe<Posts_Files_Set_Input>;
  where: Posts_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Files_By_PkArgs = {
  _set?: InputMaybe<Posts_Files_Set_Input>;
  pk_columns: Posts_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Files_ManyArgs = {
  updates: Array<Posts_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_ManyArgs = {
  updates: Array<Posts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Poll_ChoicesArgs = {
  _set?: InputMaybe<Posts_Poll_Choices_Set_Input>;
  where: Posts_Poll_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Poll_Choices_By_PkArgs = {
  _set?: InputMaybe<Posts_Poll_Choices_Set_Input>;
  pk_columns: Posts_Poll_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Poll_Choices_ManyArgs = {
  updates: Array<Posts_Poll_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Poll_Choices_VotesArgs = {
  _set?: InputMaybe<Posts_Poll_Choices_Votes_Set_Input>;
  where: Posts_Poll_Choices_Votes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Poll_Choices_Votes_By_PkArgs = {
  _set?: InputMaybe<Posts_Poll_Choices_Votes_Set_Input>;
  pk_columns: Posts_Poll_Choices_Votes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Posts_Poll_Choices_Votes_ManyArgs = {
  updates: Array<Posts_Poll_Choices_Votes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProfileArgs = {
  _append?: InputMaybe<Profile_Append_Input>;
  _delete_at_path?: InputMaybe<Profile_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Profile_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Profile_Delete_Key_Input>;
  _inc?: InputMaybe<Profile_Inc_Input>;
  _prepend?: InputMaybe<Profile_Prepend_Input>;
  _set?: InputMaybe<Profile_Set_Input>;
  where: Profile_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Profile_By_PkArgs = {
  _append?: InputMaybe<Profile_Append_Input>;
  _delete_at_path?: InputMaybe<Profile_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Profile_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Profile_Delete_Key_Input>;
  _inc?: InputMaybe<Profile_Inc_Input>;
  _prepend?: InputMaybe<Profile_Prepend_Input>;
  _set?: InputMaybe<Profile_Set_Input>;
  pk_columns: Profile_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Profile_ManyArgs = {
  updates: Array<Profile_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProjectArgs = {
  _set?: InputMaybe<Project_Set_Input>;
  where: Project_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_By_PkArgs = {
  _set?: InputMaybe<Project_Set_Input>;
  pk_columns: Project_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_ClientArgs = {
  _set?: InputMaybe<Project_Client_Set_Input>;
  where: Project_Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Client_By_PkArgs = {
  _set?: InputMaybe<Project_Client_Set_Input>;
  pk_columns: Project_Client_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Client_ManyArgs = {
  updates: Array<Project_Client_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_LinksArgs = {
  _set?: InputMaybe<Project_Links_Set_Input>;
  where: Project_Links_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Links_By_PkArgs = {
  _set?: InputMaybe<Project_Links_Set_Input>;
  pk_columns: Project_Links_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Links_ManyArgs = {
  updates: Array<Project_Links_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_ManyArgs = {
  updates: Array<Project_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_MembersArgs = {
  _set?: InputMaybe<Project_Members_Set_Input>;
  where: Project_Members_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Members_By_PkArgs = {
  _set?: InputMaybe<Project_Members_Set_Input>;
  pk_columns: Project_Members_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Members_ManyArgs = {
  updates: Array<Project_Members_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_MilestonesArgs = {
  _set?: InputMaybe<Project_Milestones_Set_Input>;
  where: Project_Milestones_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Milestones_By_PkArgs = {
  _set?: InputMaybe<Project_Milestones_Set_Input>;
  pk_columns: Project_Milestones_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Milestones_ManyArgs = {
  updates: Array<Project_Milestones_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Notification_SettingsArgs = {
  _set?: InputMaybe<Project_Notification_Settings_Set_Input>;
  where: Project_Notification_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Notification_Settings_By_PkArgs = {
  _set?: InputMaybe<Project_Notification_Settings_Set_Input>;
  pk_columns: Project_Notification_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Notification_Settings_ManyArgs = {
  updates: Array<Project_Notification_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_SectionArgs = {
  _set?: InputMaybe<Project_Section_Set_Input>;
  where: Project_Section_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Section_By_PkArgs = {
  _set?: InputMaybe<Project_Section_Set_Input>;
  pk_columns: Project_Section_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Section_ManyArgs = {
  updates: Array<Project_Section_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_SprintsArgs = {
  _inc?: InputMaybe<Project_Sprints_Inc_Input>;
  _set?: InputMaybe<Project_Sprints_Set_Input>;
  where: Project_Sprints_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Sprints_By_PkArgs = {
  _inc?: InputMaybe<Project_Sprints_Inc_Input>;
  _set?: InputMaybe<Project_Sprints_Set_Input>;
  pk_columns: Project_Sprints_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Sprints_ManyArgs = {
  updates: Array<Project_Sprints_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Question_OptionsArgs = {
  _inc?: InputMaybe<Question_Options_Inc_Input>;
  _set?: InputMaybe<Question_Options_Set_Input>;
  where: Question_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Question_Options_By_PkArgs = {
  _inc?: InputMaybe<Question_Options_Inc_Input>;
  _set?: InputMaybe<Question_Options_Set_Input>;
  pk_columns: Question_Options_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Question_Options_ManyArgs = {
  updates: Array<Question_Options_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_QuestionsArgs = {
  _set?: InputMaybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _set?: InputMaybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questions_ManyArgs = {
  updates: Array<Questions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Skills_DetailArgs = {
  _inc?: InputMaybe<Skills_Detail_Inc_Input>;
  _set?: InputMaybe<Skills_Detail_Set_Input>;
  where: Skills_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Skills_Detail_By_PkArgs = {
  _inc?: InputMaybe<Skills_Detail_Inc_Input>;
  _set?: InputMaybe<Skills_Detail_Set_Input>;
  pk_columns: Skills_Detail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Skills_Detail_ManyArgs = {
  updates: Array<Skills_Detail_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stage_Career_DetailArgs = {
  _inc?: InputMaybe<Stage_Career_Detail_Inc_Input>;
  _set?: InputMaybe<Stage_Career_Detail_Set_Input>;
  where: Stage_Career_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stage_Career_Detail_By_PkArgs = {
  _inc?: InputMaybe<Stage_Career_Detail_Inc_Input>;
  _set?: InputMaybe<Stage_Career_Detail_Set_Input>;
  pk_columns: Stage_Career_Detail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stage_Career_Detail_ManyArgs = {
  updates: Array<Stage_Career_Detail_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Storage_FilesArgs = {
  _append?: InputMaybe<Storage_Files_Append_Input>;
  _delete_at_path?: InputMaybe<Storage_Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Storage_Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Storage_Files_Delete_Key_Input>;
  _prepend?: InputMaybe<Storage_Files_Prepend_Input>;
  _set?: InputMaybe<Storage_Files_Set_Input>;
  where: Storage_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Storage_Files_By_PkArgs = {
  _append?: InputMaybe<Storage_Files_Append_Input>;
  _delete_at_path?: InputMaybe<Storage_Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Storage_Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Storage_Files_Delete_Key_Input>;
  _prepend?: InputMaybe<Storage_Files_Prepend_Input>;
  _set?: InputMaybe<Storage_Files_Set_Input>;
  pk_columns: Storage_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Storage_Files_ManyArgs = {
  updates: Array<Storage_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stress_DataArgs = {
  _inc?: InputMaybe<Stress_Data_Inc_Input>;
  _set?: InputMaybe<Stress_Data_Set_Input>;
  where: Stress_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stress_Data_By_PkArgs = {
  _inc?: InputMaybe<Stress_Data_Inc_Input>;
  _set?: InputMaybe<Stress_Data_Set_Input>;
  pk_columns: Stress_Data_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stress_Data_ManyArgs = {
  updates: Array<Stress_Data_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Sub_SkillsArgs = {
  _inc?: InputMaybe<Sub_Skills_Inc_Input>;
  _set?: InputMaybe<Sub_Skills_Set_Input>;
  where: Sub_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sub_Skills_By_PkArgs = {
  _inc?: InputMaybe<Sub_Skills_Inc_Input>;
  _set?: InputMaybe<Sub_Skills_Set_Input>;
  pk_columns: Sub_Skills_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sub_Skills_ManyArgs = {
  updates: Array<Sub_Skills_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TaskArgs = {
  _inc?: InputMaybe<Task_Inc_Input>;
  _set?: InputMaybe<Task_Set_Input>;
  where: Task_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_ApproversArgs = {
  _set?: InputMaybe<Task_Approvers_Set_Input>;
  where: Task_Approvers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Approvers_By_PkArgs = {
  _set?: InputMaybe<Task_Approvers_Set_Input>;
  pk_columns: Task_Approvers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Approvers_ManyArgs = {
  updates: Array<Task_Approvers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_By_PkArgs = {
  _inc?: InputMaybe<Task_Inc_Input>;
  _set?: InputMaybe<Task_Set_Input>;
  pk_columns: Task_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_ChecklistArgs = {
  _set?: InputMaybe<Task_Checklist_Set_Input>;
  where: Task_Checklist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Checklist_By_PkArgs = {
  _set?: InputMaybe<Task_Checklist_Set_Input>;
  pk_columns: Task_Checklist_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Checklist_ManyArgs = {
  updates: Array<Task_Checklist_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_CollaboratorArgs = {
  _set?: InputMaybe<Task_Collaborator_Set_Input>;
  where: Task_Collaborator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Collaborator_By_PkArgs = {
  _set?: InputMaybe<Task_Collaborator_Set_Input>;
  pk_columns: Task_Collaborator_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Collaborator_ManyArgs = {
  updates: Array<Task_Collaborator_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_CommentArgs = {
  _set?: InputMaybe<Task_Comment_Set_Input>;
  where: Task_Comment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Comment_By_PkArgs = {
  _set?: InputMaybe<Task_Comment_Set_Input>;
  pk_columns: Task_Comment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Comment_LikeArgs = {
  _set?: InputMaybe<Task_Comment_Like_Set_Input>;
  where: Task_Comment_Like_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Comment_Like_By_PkArgs = {
  _set?: InputMaybe<Task_Comment_Like_Set_Input>;
  pk_columns: Task_Comment_Like_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Comment_Like_ManyArgs = {
  updates: Array<Task_Comment_Like_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Comment_ManyArgs = {
  updates: Array<Task_Comment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_DependenciesArgs = {
  _set?: InputMaybe<Task_Dependencies_Set_Input>;
  where: Task_Dependencies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Dependencies_By_PkArgs = {
  _set?: InputMaybe<Task_Dependencies_Set_Input>;
  pk_columns: Task_Dependencies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Dependencies_ManyArgs = {
  updates: Array<Task_Dependencies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_FileArgs = {
  _set?: InputMaybe<Task_File_Set_Input>;
  where: Task_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_File_By_PkArgs = {
  _set?: InputMaybe<Task_File_Set_Input>;
  pk_columns: Task_File_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_File_ManyArgs = {
  updates: Array<Task_File_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_ManyArgs = {
  updates: Array<Task_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_MilestonesArgs = {
  _set?: InputMaybe<Task_Milestones_Set_Input>;
  where: Task_Milestones_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Milestones_By_PkArgs = {
  _set?: InputMaybe<Task_Milestones_Set_Input>;
  pk_columns: Task_Milestones_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Milestones_ManyArgs = {
  updates: Array<Task_Milestones_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_TagsArgs = {
  _set?: InputMaybe<Task_Tags_Set_Input>;
  where: Task_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Tags_By_PkArgs = {
  _set?: InputMaybe<Task_Tags_Set_Input>;
  pk_columns: Task_Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Tags_ManyArgs = {
  updates: Array<Task_Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Time_SheetArgs = {
  _inc?: InputMaybe<Task_Time_Sheet_Inc_Input>;
  _set?: InputMaybe<Task_Time_Sheet_Set_Input>;
  where: Task_Time_Sheet_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Time_Sheet_By_PkArgs = {
  _inc?: InputMaybe<Task_Time_Sheet_Inc_Input>;
  _set?: InputMaybe<Task_Time_Sheet_Set_Input>;
  pk_columns: Task_Time_Sheet_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Time_Sheet_ManyArgs = {
  updates: Array<Task_Time_Sheet_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_UnscheduledArgs = {
  _set?: InputMaybe<Task_Unscheduled_Set_Input>;
  where: Task_Unscheduled_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Unscheduled_By_PkArgs = {
  _set?: InputMaybe<Task_Unscheduled_Set_Input>;
  pk_columns: Task_Unscheduled_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Unscheduled_ManyArgs = {
  updates: Array<Task_Unscheduled_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Time_PeriodArgs = {
  _set?: InputMaybe<Time_Period_Set_Input>;
  where: Time_Period_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Time_Period_By_PkArgs = {
  _set?: InputMaybe<Time_Period_Set_Input>;
  pk_columns: Time_Period_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Time_Period_ManyArgs = {
  updates: Array<Time_Period_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TrainingArgs = {
  _inc?: InputMaybe<Training_Inc_Input>;
  _set?: InputMaybe<Training_Set_Input>;
  where: Training_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_SkillArgs = {
  _set?: InputMaybe<Training_Skill_Set_Input>;
  where: Training_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Skill_By_PkArgs = {
  _set?: InputMaybe<Training_Skill_Set_Input>;
  pk_columns: Training_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Skill_ManyArgs = {
  updates: Array<Training_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_By_PkArgs = {
  _inc?: InputMaybe<Training_Inc_Input>;
  _set?: InputMaybe<Training_Set_Input>;
  pk_columns: Training_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_FeedbackArgs = {
  _inc?: InputMaybe<Training_Feedback_Inc_Input>;
  _set?: InputMaybe<Training_Feedback_Set_Input>;
  where: Training_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Feedback_By_PkArgs = {
  _inc?: InputMaybe<Training_Feedback_Inc_Input>;
  _set?: InputMaybe<Training_Feedback_Set_Input>;
  pk_columns: Training_Feedback_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Feedback_ManyArgs = {
  updates: Array<Training_Feedback_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_ManyArgs = {
  updates: Array<Training_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Progress_RecordArgs = {
  _set?: InputMaybe<Training_Progress_Record_Set_Input>;
  where: Training_Progress_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Progress_Record_By_PkArgs = {
  _set?: InputMaybe<Training_Progress_Record_Set_Input>;
  pk_columns: Training_Progress_Record_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Progress_Record_ManyArgs = {
  updates: Array<Training_Progress_Record_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Role_DetailsArgs = {
  _set?: InputMaybe<Training_Role_Details_Set_Input>;
  where: Training_Role_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Role_Details_By_PkArgs = {
  _set?: InputMaybe<Training_Role_Details_Set_Input>;
  pk_columns: Training_Role_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Role_Details_ManyArgs = {
  updates: Array<Training_Role_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Stage_CareerArgs = {
  _inc?: InputMaybe<Training_Stage_Career_Inc_Input>;
  _set?: InputMaybe<Training_Stage_Career_Set_Input>;
  where: Training_Stage_Career_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Stage_Career_By_PkArgs = {
  _inc?: InputMaybe<Training_Stage_Career_Inc_Input>;
  _set?: InputMaybe<Training_Stage_Career_Set_Input>;
  pk_columns: Training_Stage_Career_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Stage_Career_ManyArgs = {
  updates: Array<Training_Stage_Career_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Table_Of_ContentsArgs = {
  _inc?: InputMaybe<Training_Table_Of_Contents_Inc_Input>;
  _set?: InputMaybe<Training_Table_Of_Contents_Set_Input>;
  where: Training_Table_Of_Contents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Table_Of_Contents_By_PkArgs = {
  _inc?: InputMaybe<Training_Table_Of_Contents_Inc_Input>;
  _set?: InputMaybe<Training_Table_Of_Contents_Set_Input>;
  pk_columns: Training_Table_Of_Contents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Table_Of_Contents_ManyArgs = {
  updates: Array<Training_Table_Of_Contents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Type_For_TrainingArgs = {
  _set?: InputMaybe<Training_Type_For_Training_Set_Input>;
  where: Training_Type_For_Training_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Type_For_Training_By_PkArgs = {
  _set?: InputMaybe<Training_Type_For_Training_Set_Input>;
  pk_columns: Training_Type_For_Training_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Type_For_Training_ManyArgs = {
  updates: Array<Training_Type_For_Training_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_CompetencyArgs = {
  _set?: InputMaybe<User_Competency_Set_Input>;
  where: User_Competency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Competency_By_PkArgs = {
  _set?: InputMaybe<User_Competency_Set_Input>;
  pk_columns: User_Competency_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Competency_ManyArgs = {
  updates: Array<User_Competency_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_SkillsArgs = {
  _inc?: InputMaybe<User_Skills_Inc_Input>;
  _set?: InputMaybe<User_Skills_Set_Input>;
  where: User_Skills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Skills_AssessmentArgs = {
  _set?: InputMaybe<User_Skills_Assessment_Set_Input>;
  where: User_Skills_Assessment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Skills_Assessment_By_PkArgs = {
  _set?: InputMaybe<User_Skills_Assessment_Set_Input>;
  pk_columns: User_Skills_Assessment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Skills_Assessment_ManyArgs = {
  updates: Array<User_Skills_Assessment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Skills_TrainingArgs = {
  _set?: InputMaybe<User_Skills_Training_Set_Input>;
  where: User_Skills_Training_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Skills_Training_By_PkArgs = {
  _set?: InputMaybe<User_Skills_Training_Set_Input>;
  pk_columns: User_Skills_Training_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Skills_Training_ManyArgs = {
  updates: Array<User_Skills_Training_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Skills_By_PkArgs = {
  _inc?: InputMaybe<User_Skills_Inc_Input>;
  _set?: InputMaybe<User_Skills_Set_Input>;
  pk_columns: User_Skills_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Skills_ManyArgs = {
  updates: Array<User_Skills_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Activity_TrackerArgs = {
  _inc?: InputMaybe<User_Activity_Tracker_Inc_Input>;
  _set?: InputMaybe<User_Activity_Tracker_Set_Input>;
  where: User_Activity_Tracker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Activity_Tracker_By_PkArgs = {
  _inc?: InputMaybe<User_Activity_Tracker_Inc_Input>;
  _set?: InputMaybe<User_Activity_Tracker_Set_Input>;
  pk_columns: User_Activity_Tracker_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Activity_Tracker_ManyArgs = {
  updates: Array<User_Activity_Tracker_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_AvatarArgs = {
  _set?: InputMaybe<User_Avatar_Set_Input>;
  where: User_Avatar_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Avatar_By_PkArgs = {
  _set?: InputMaybe<User_Avatar_Set_Input>;
  pk_columns: User_Avatar_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Avatar_FilesArgs = {
  _set?: InputMaybe<User_Avatar_Files_Set_Input>;
  where: User_Avatar_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Avatar_Files_By_PkArgs = {
  _set?: InputMaybe<User_Avatar_Files_Set_Input>;
  pk_columns: User_Avatar_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Avatar_Files_ManyArgs = {
  updates: Array<User_Avatar_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Avatar_ManyArgs = {
  updates: Array<User_Avatar_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Career_Development_RecordArgs = {
  _inc?: InputMaybe<User_Career_Development_Record_Inc_Input>;
  _set?: InputMaybe<User_Career_Development_Record_Set_Input>;
  where: User_Career_Development_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Career_Development_Record_By_PkArgs = {
  _inc?: InputMaybe<User_Career_Development_Record_Inc_Input>;
  _set?: InputMaybe<User_Career_Development_Record_Set_Input>;
  pk_columns: User_Career_Development_Record_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Career_Development_Record_ManyArgs = {
  updates: Array<User_Career_Development_Record_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Career_External_CertificationArgs = {
  _set?: InputMaybe<User_Career_External_Certification_Set_Input>;
  where: User_Career_External_Certification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Career_External_Certification_By_PkArgs = {
  _set?: InputMaybe<User_Career_External_Certification_Set_Input>;
  pk_columns: User_Career_External_Certification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Career_External_Certification_ManyArgs = {
  updates: Array<User_Career_External_Certification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_PresenceArgs = {
  _append?: InputMaybe<User_Presence_Append_Input>;
  _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  _set?: InputMaybe<User_Presence_Set_Input>;
  where: User_Presence_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Presence_By_PkArgs = {
  _append?: InputMaybe<User_Presence_Append_Input>;
  _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  _set?: InputMaybe<User_Presence_Set_Input>;
  pk_columns: User_Presence_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Presence_ManyArgs = {
  updates: Array<User_Presence_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_SettingsArgs = {
  _append?: InputMaybe<User_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<User_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Settings_Prepend_Input>;
  _set?: InputMaybe<User_Settings_Set_Input>;
  where: User_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Settings_By_PkArgs = {
  _append?: InputMaybe<User_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<User_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Settings_Prepend_Input>;
  _set?: InputMaybe<User_Settings_Set_Input>;
  pk_columns: User_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Settings_ManyArgs = {
  updates: Array<User_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_StateArgs = {
  _append?: InputMaybe<User_State_Append_Input>;
  _delete_at_path?: InputMaybe<User_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_State_Delete_Key_Input>;
  _prepend?: InputMaybe<User_State_Prepend_Input>;
  _set?: InputMaybe<User_State_Set_Input>;
  where: User_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_State_By_PkArgs = {
  _append?: InputMaybe<User_State_Append_Input>;
  _delete_at_path?: InputMaybe<User_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_State_Delete_Key_Input>;
  _prepend?: InputMaybe<User_State_Prepend_Input>;
  _set?: InputMaybe<User_State_Set_Input>;
  pk_columns: User_State_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_State_ManyArgs = {
  updates: Array<User_State_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

export type NewPollPostInput = {
  choices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  disappeared_by?: InputMaybe<Scalars['Int']>;
  file_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mention_users?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  message: Scalars['String'];
  org_id: Scalars['String'];
  visibility?: InputMaybe<Scalars['String']>;
};

export type NewPollPostOutput = {
  __typename?: 'newPollPostOutput';
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  post_id?: Maybe<Scalars['String']>;
};

export type NewPostInput = {
  file_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mention_users?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  message: Scalars['String'];
  org_id: Scalars['String'];
  visibility?: InputMaybe<Scalars['String']>;
};

export type NewPostOutput = {
  __typename?: 'newPostOutput';
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  post_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  /** An object relationship */
  Resource_Calendar_Event?: Maybe<Calendar_Event>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_notification_type: Enum_Notification_Type;
  id: Scalars['uuid'];
  is_read: Scalars['Boolean'];
  is_shown: Scalars['Boolean'];
  message: Scalars['String'];
  meta_data?: Maybe<Scalars['jsonb']>;
  owned_by: Scalars['uuid'];
  resource_id?: Maybe<Scalars['uuid']>;
  type: Enum_Notification_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByOwnedBy: Users;
};

/** columns and relationships of "notifications" */
export type NotificationsMeta_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

export type Notifications_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Notifications_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Notifications_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Notifications_Aggregate_Bool_Exp_Count>;
};

export type Notifications_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notifications_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notifications_Max_Order_By>;
  min?: InputMaybe<Notifications_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Append_Input = {
  meta_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  Resource_Calendar_Event?: InputMaybe<Calendar_Event_Bool_Exp>;
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_notification_type?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_read?: InputMaybe<Boolean_Comparison_Exp>;
  is_shown?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  resource_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Enum_Notification_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByOwnedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notifications_Delete_At_Path_Input = {
  meta_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notifications_Delete_Elem_Input = {
  meta_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notifications_Delete_Key_Input = {
  meta_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  Resource_Calendar_Event?: InputMaybe<Calendar_Event_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  enum_notification_type?: InputMaybe<Enum_Notification_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  is_shown?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  meta_data?: InputMaybe<Scalars['jsonb']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  resource_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Enum_Notification_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByOwnedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  Resource_Calendar_Event?: InputMaybe<Calendar_Event_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  enum_notification_type?: InputMaybe<Enum_Notification_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  is_read?: InputMaybe<Order_By>;
  is_shown?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  meta_data?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByOwnedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Prepend_Input = {
  meta_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  Message = 'message',
  /** column name */
  MetaData = 'meta_data',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "notifications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notifications" */
export enum Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRead = 'is_read',
  /** column name */
  IsShown = 'is_shown',
}

/** select "notifications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notifications" */
export enum Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRead = 'is_read',
  /** column name */
  IsShown = 'is_shown',
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  is_shown?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  meta_data?: InputMaybe<Scalars['jsonb']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  resource_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Enum_Notification_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_read?: InputMaybe<Scalars['Boolean']>;
  is_shown?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  meta_data?: InputMaybe<Scalars['jsonb']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  resource_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Enum_Notification_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  Message = 'message',
  /** column name */
  MetaData = 'meta_data',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** An organization */
export type Organization = {
  __typename?: 'organization';
  additional_fields: Scalars['jsonb'];
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  anniversary_date?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  assessment_feedbacks: Array<Assessment_Feedback>;
  /** An aggregate relationship */
  assessment_feedbacks_aggregate: Assessment_Feedback_Aggregate;
  /** An array relationship */
  assessment_records: Array<Assessment_Record>;
  /** An aggregate relationship */
  assessment_records_aggregate: Assessment_Record_Aggregate;
  /** An array relationship */
  assessment_role_details: Array<Assessment_Role_Details>;
  /** An aggregate relationship */
  assessment_role_details_aggregate: Assessment_Role_Details_Aggregate;
  /** An array relationship */
  assessment_types: Array<Assessment_Type>;
  /** An aggregate relationship */
  assessment_types_aggregate: Assessment_Type_Aggregate;
  /** An array relationship */
  assessments: Array<Assessment>;
  /** An aggregate relationship */
  assessments_aggregate: Assessment_Aggregate;
  /** An array relationship */
  calendar_meeting_calls: Array<Calendar_Meeting_Call>;
  /** An aggregate relationship */
  calendar_meeting_calls_aggregate: Calendar_Meeting_Call_Aggregate;
  /** An array relationship */
  calendars: Array<Calendar>;
  /** An aggregate relationship */
  calendars_aggregate: Calendar_Aggregate;
  /** An array relationship */
  calls: Array<Call>;
  /** An aggregate relationship */
  calls_aggregate: Call_Aggregate;
  /** An array relationship */
  career_development_details: Array<Career_Development_Detail>;
  /** An aggregate relationship */
  career_development_details_aggregate: Career_Development_Detail_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: Department_Aggregate;
  /** An array relationship */
  employee_bandwidth_data: Array<Employee_Bandwidth_Data>;
  /** An aggregate relationship */
  employee_bandwidth_data_aggregate: Employee_Bandwidth_Data_Aggregate;
  /** An array relationship */
  employee_capacities: Array<Employee_Capacity>;
  /** An aggregate relationship */
  employee_capacities_aggregate: Employee_Capacity_Aggregate;
  holiday_file_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  last_modified_by: Scalars['uuid'];
  /** An array relationship */
  leave_organizations: Array<Leave_Organization>;
  /** An aggregate relationship */
  leave_organizations_aggregate: Leave_Organization_Aggregate;
  /** An array relationship */
  list_Of_Skills: Array<List_Of_Skills>;
  /** An aggregate relationship */
  list_Of_Skills_aggregate: List_Of_Skills_Aggregate;
  logo_file_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  main_skills: Array<Main_Skills>;
  /** An aggregate relationship */
  main_skills_aggregate: Main_Skills_Aggregate;
  /** An array relationship */
  meeting_rooms: Array<Meeting_Room>;
  /** An aggregate relationship */
  meeting_rooms_aggregate: Meeting_Room_Aggregate;
  org_end_time: Scalars['timetz'];
  org_start_time: Scalars['timetz'];
  /** An array relationship */
  organization_admins: Array<Organization_Admin>;
  /** An aggregate relationship */
  organization_admins_aggregate: Organization_Admin_Aggregate;
  /** An array relationship */
  organization_employees: Array<Organization_Employee>;
  /** An aggregate relationship */
  organization_employees_aggregate: Organization_Employee_Aggregate;
  /** An array relationship */
  organization_holidays: Array<Organization_Holidays>;
  /** An aggregate relationship */
  organization_holidays_aggregate: Organization_Holidays_Aggregate;
  /** An array relationship */
  organization_leave_cycles: Array<Organization_Leave_Cycles>;
  /** An aggregate relationship */
  organization_leave_cycles_aggregate: Organization_Leave_Cycles_Aggregate;
  /** An array relationship */
  organization_locations: Array<Organization_Locations>;
  /** An aggregate relationship */
  organization_locations_aggregate: Organization_Locations_Aggregate;
  organization_name: Scalars['String'];
  /** An array relationship */
  organization_roles: Array<Organization_Roles>;
  /** An aggregate relationship */
  organization_roles_aggregate: Organization_Roles_Aggregate;
  /** An array relationship */
  organization_users: Array<Organization_User>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_User_Aggregate;
  /** An array relationship */
  organizations_active_leave_types: Array<Organization_Active_Leave_Types>;
  /** An aggregate relationship */
  organizations_active_leave_types_aggregate: Organization_Active_Leave_Types_Aggregate;
  owned_by: Scalars['uuid'];
  /** An array relationship */
  performance_review_settings: Array<Performance_Review_Settings>;
  /** An aggregate relationship */
  performance_review_settings_aggregate: Performance_Review_Settings_Aggregate;
  /** An array relationship */
  personaliseds: Array<Personalised>;
  /** An aggregate relationship */
  personaliseds_aggregate: Personalised_Aggregate;
  privacy_policies?: Maybe<Scalars['String']>;
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregate relationship */
  profiles_aggregate: Profile_Aggregate;
  /** An array relationship */
  project_clients: Array<Project_Client>;
  /** An aggregate relationship */
  project_clients_aggregate: Project_Client_Aggregate;
  /** An array relationship */
  project_sprints: Array<Project_Sprints>;
  /** An aggregate relationship */
  project_sprints_aggregate: Project_Sprints_Aggregate;
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  slug: Scalars['String'];
  /** An object relationship */
  storageFileByLogoFileId?: Maybe<Storage_Files>;
  /** An object relationship */
  storage_file?: Maybe<Storage_Files>;
  /** An array relationship */
  stress_data: Array<Stress_Data>;
  /** An aggregate relationship */
  stress_data_aggregate: Stress_Data_Aggregate;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  terms_and_conditions?: Maybe<Scalars['String']>;
  timezone_name: Scalars['String'];
  /** An array relationship */
  training_feedbacks: Array<Training_Feedback>;
  /** An aggregate relationship */
  training_feedbacks_aggregate: Training_Feedback_Aggregate;
  /** An array relationship */
  training_role_details: Array<Training_Role_Details>;
  /** An aggregate relationship */
  training_role_details_aggregate: Training_Role_Details_Aggregate;
  /** An array relationship */
  training_type_for_trainings: Array<Training_Type_For_Training>;
  /** An aggregate relationship */
  training_type_for_trainings_aggregate: Training_Type_For_Training_Aggregate;
  /** An array relationship */
  trainings: Array<Training>;
  /** An aggregate relationship */
  trainings_aggregate: Training_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByOwnedBy: Users;
  working_days: Scalars['Int'];
  working_hours: Scalars['Int'];
};

/** An organization */
export type OrganizationAdditional_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** An organization */
export type OrganizationAssessment_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessment_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessment_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessment_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessment_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessment_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessment_TypesArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessment_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** An organization */
export type OrganizationAssessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** An organization */
export type OrganizationCalendar_Meeting_CallsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** An organization */
export type OrganizationCalendar_Meeting_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** An organization */
export type OrganizationCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Order_By>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

/** An organization */
export type OrganizationCalendars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Order_By>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

/** An organization */
export type OrganizationCallsArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** An organization */
export type OrganizationCalls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** An organization */
export type OrganizationCareer_Development_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

/** An organization */
export type OrganizationCareer_Development_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

/** An organization */
export type OrganizationDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** An organization */
export type OrganizationDepartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** An organization */
export type OrganizationEmployee_Bandwidth_DataArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

/** An organization */
export type OrganizationEmployee_Bandwidth_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

/** An organization */
export type OrganizationEmployee_CapacitiesArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_Order_By>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

/** An organization */
export type OrganizationEmployee_Capacities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_Order_By>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

/** An organization */
export type OrganizationLeave_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Organization_Order_By>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

/** An organization */
export type OrganizationLeave_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Organization_Order_By>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

/** An organization */
export type OrganizationList_Of_SkillsArgs = {
  distinct_on?: InputMaybe<Array<List_Of_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Of_Skills_Order_By>>;
  where?: InputMaybe<List_Of_Skills_Bool_Exp>;
};

/** An organization */
export type OrganizationList_Of_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Of_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Of_Skills_Order_By>>;
  where?: InputMaybe<List_Of_Skills_Bool_Exp>;
};

/** An organization */
export type OrganizationMain_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Main_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Main_Skills_Order_By>>;
  where?: InputMaybe<Main_Skills_Bool_Exp>;
};

/** An organization */
export type OrganizationMain_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Main_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Main_Skills_Order_By>>;
  where?: InputMaybe<Main_Skills_Bool_Exp>;
};

/** An organization */
export type OrganizationMeeting_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** An organization */
export type OrganizationMeeting_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_Admins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_EmployeesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_Employees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_HolidaysArgs = {
  distinct_on?: InputMaybe<Array<Organization_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Holidays_Order_By>>;
  where?: InputMaybe<Organization_Holidays_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_Holidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Holidays_Order_By>>;
  where?: InputMaybe<Organization_Holidays_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_Leave_CyclesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leave_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leave_Cycles_Order_By>>;
  where?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_Leave_Cycles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leave_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leave_Cycles_Order_By>>;
  where?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_RolesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Roles_Order_By>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Roles_Order_By>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Order_By>>;
  where?: InputMaybe<Organization_User_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganization_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Order_By>>;
  where?: InputMaybe<Organization_User_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganizations_Active_Leave_TypesArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_Active_Leave_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Active_Leave_Types_Order_By>>;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

/** An organization */
export type OrganizationOrganizations_Active_Leave_Types_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_Active_Leave_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Active_Leave_Types_Order_By>>;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

/** An organization */
export type OrganizationPerformance_Review_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Settings_Order_By>>;
  where?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
};

/** An organization */
export type OrganizationPerformance_Review_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Settings_Order_By>>;
  where?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
};

/** An organization */
export type OrganizationPersonalisedsArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** An organization */
export type OrganizationPersonaliseds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** An organization */
export type OrganizationProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** An organization */
export type OrganizationProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** An organization */
export type OrganizationProject_ClientsArgs = {
  distinct_on?: InputMaybe<Array<Project_Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Client_Order_By>>;
  where?: InputMaybe<Project_Client_Bool_Exp>;
};

/** An organization */
export type OrganizationProject_Clients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Client_Order_By>>;
  where?: InputMaybe<Project_Client_Bool_Exp>;
};

/** An organization */
export type OrganizationProject_SprintsArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** An organization */
export type OrganizationProject_Sprints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** An organization */
export type OrganizationProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** An organization */
export type OrganizationProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** An organization */
export type OrganizationStress_DataArgs = {
  distinct_on?: InputMaybe<Array<Stress_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stress_Data_Order_By>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

/** An organization */
export type OrganizationStress_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stress_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stress_Data_Order_By>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

/** An organization */
export type OrganizationTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** An organization */
export type OrganizationTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** An organization */
export type OrganizationTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** An organization */
export type OrganizationTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** An organization */
export type OrganizationTraining_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

/** An organization */
export type OrganizationTraining_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

/** An organization */
export type OrganizationTraining_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

/** An organization */
export type OrganizationTraining_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

/** An organization */
export type OrganizationTraining_Type_For_TrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Type_For_Training_Order_By>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

/** An organization */
export type OrganizationTraining_Type_For_Trainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Type_For_Training_Order_By>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

/** An organization */
export type OrganizationTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** An organization */
export type OrganizationTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** this table represents what leave types are active for an organization */
export type Organization_Active_Leave_Types = {
  __typename?: 'organization_active_leave_types';
  annual_carry_forward_amount?: Maybe<Scalars['Int']>;
  annual_eligibility: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  followed_leave_cycle?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An array relationship */
  leave_applications_for_this_leave_type: Array<Organization_User_Leave_Application>;
  /** An aggregate relationship */
  leave_applications_for_this_leave_type_aggregate: Organization_User_Leave_Application_Aggregate;
  leave_type: Scalars['String'];
  limit_of_days_at_once?: Maybe<Scalars['Int']>;
  monthly_earning_amount?: Maybe<Scalars['float8']>;
  org_id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  /** An object relationship */
  organization_leave_cycle?: Maybe<Organization_Leave_Cycles>;
  updated_at: Scalars['timestamptz'];
  will_be_earned_monthly: Scalars['Boolean'];
  will_carry_forward: Scalars['Boolean'];
};

/** this table represents what leave types are active for an organization */
export type Organization_Active_Leave_TypesLeave_Applications_For_This_Leave_TypeArgs =
  {
    distinct_on?: InputMaybe<
      Array<Organization_User_Leave_Application_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
    where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  };

/** this table represents what leave types are active for an organization */
export type Organization_Active_Leave_TypesLeave_Applications_For_This_Leave_Type_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Organization_User_Leave_Application_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
    where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  };

/** aggregated selection of "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Aggregate = {
  __typename?: 'organization_active_leave_types_aggregate';
  aggregate?: Maybe<Organization_Active_Leave_Types_Aggregate_Fields>;
  nodes: Array<Organization_Active_Leave_Types>;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp_Var_Samp>;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Avg = {
  arguments: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Corr = {
  arguments: Organization_Active_Leave_Types_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Corr_Arguments =
  {
    X: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Corr_Arguments_Columns;
    Y: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  };

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Active_Leave_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Organization_Active_Leave_Types_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Covar_Samp_Arguments =
  {
    X: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
    Y: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  };

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Max = {
  arguments: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Min = {
  arguments: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Sum = {
  arguments: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_Active_Leave_Types_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Aggregate_Fields = {
  __typename?: 'organization_active_leave_types_aggregate_fields';
  avg?: Maybe<Organization_Active_Leave_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organization_Active_Leave_Types_Max_Fields>;
  min?: Maybe<Organization_Active_Leave_Types_Min_Fields>;
  stddev?: Maybe<Organization_Active_Leave_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_Active_Leave_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_Active_Leave_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_Active_Leave_Types_Sum_Fields>;
  var_pop?: Maybe<Organization_Active_Leave_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_Active_Leave_Types_Var_Samp_Fields>;
  variance?: Maybe<Organization_Active_Leave_Types_Variance_Fields>;
};

/** aggregate fields of "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Active_Leave_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Active_Leave_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Active_Leave_Types_Max_Order_By>;
  min?: InputMaybe<Organization_Active_Leave_Types_Min_Order_By>;
  stddev?: InputMaybe<Organization_Active_Leave_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Active_Leave_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Active_Leave_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Active_Leave_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Active_Leave_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Active_Leave_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Active_Leave_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Arr_Rel_Insert_Input = {
  data: Array<Organization_Active_Leave_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Active_Leave_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Active_Leave_Types_Avg_Fields = {
  __typename?: 'organization_active_leave_types_avg_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Float']>;
  annual_eligibility?: Maybe<Scalars['Float']>;
  limit_of_days_at_once?: Maybe<Scalars['Float']>;
  monthly_earning_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Avg_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_active_leave_types". All fields are combined with a logical 'AND'. */
export type Organization_Active_Leave_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Active_Leave_Types_Bool_Exp>>;
  _not?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Active_Leave_Types_Bool_Exp>>;
  annual_carry_forward_amount?: InputMaybe<Int_Comparison_Exp>;
  annual_eligibility?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  followed_leave_cycle?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leave_applications_for_this_leave_type?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  leave_applications_for_this_leave_type_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Bool_Exp>;
  leave_type?: InputMaybe<String_Comparison_Exp>;
  limit_of_days_at_once?: InputMaybe<Int_Comparison_Exp>;
  monthly_earning_amount?: InputMaybe<Float8_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_leave_cycle?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  will_be_earned_monthly?: InputMaybe<Boolean_Comparison_Exp>;
  will_carry_forward?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationsActiveLeavesPkey = 'organizations_active_leaves_pkey',
}

/** input type for incrementing numeric columns in table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Inc_Input = {
  annual_carry_forward_amount?: InputMaybe<Scalars['Int']>;
  annual_eligibility?: InputMaybe<Scalars['Int']>;
  limit_of_days_at_once?: InputMaybe<Scalars['Int']>;
  monthly_earning_amount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Insert_Input = {
  annual_carry_forward_amount?: InputMaybe<Scalars['Int']>;
  annual_eligibility?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  followed_leave_cycle?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_applications_for_this_leave_type?: InputMaybe<Organization_User_Leave_Application_Arr_Rel_Insert_Input>;
  leave_type?: InputMaybe<Scalars['String']>;
  limit_of_days_at_once?: InputMaybe<Scalars['Int']>;
  monthly_earning_amount?: InputMaybe<Scalars['float8']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_leave_cycle?: InputMaybe<Organization_Leave_Cycles_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  will_be_earned_monthly?: InputMaybe<Scalars['Boolean']>;
  will_carry_forward?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Organization_Active_Leave_Types_Max_Fields = {
  __typename?: 'organization_active_leave_types_max_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Int']>;
  annual_eligibility?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  followed_leave_cycle?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  limit_of_days_at_once?: Maybe<Scalars['Int']>;
  monthly_earning_amount?: Maybe<Scalars['float8']>;
  org_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Max_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  followed_leave_cycle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Active_Leave_Types_Min_Fields = {
  __typename?: 'organization_active_leave_types_min_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Int']>;
  annual_eligibility?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  followed_leave_cycle?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  limit_of_days_at_once?: Maybe<Scalars['Int']>;
  monthly_earning_amount?: Maybe<Scalars['float8']>;
  org_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Min_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  followed_leave_cycle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Mutation_Response = {
  __typename?: 'organization_active_leave_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Active_Leave_Types>;
};

/** input type for inserting object relation for remote table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Obj_Rel_Insert_Input = {
  data: Organization_Active_Leave_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Active_Leave_Types_On_Conflict>;
};

/** on_conflict condition type for table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_On_Conflict = {
  constraint: Organization_Active_Leave_Types_Constraint;
  update_columns?: Array<Organization_Active_Leave_Types_Update_Column>;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_active_leave_types". */
export type Organization_Active_Leave_Types_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  followed_leave_cycle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_applications_for_this_leave_type_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Order_By>;
  leave_type?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_leave_cycle?: InputMaybe<Organization_Leave_Cycles_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  will_be_earned_monthly?: InputMaybe<Order_By>;
  will_carry_forward?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_active_leave_types */
export type Organization_Active_Leave_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column {
  /** column name */
  AnnualCarryForwardAmount = 'annual_carry_forward_amount',
  /** column name */
  AnnualEligibility = 'annual_eligibility',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FollowedLeaveCycle = 'followed_leave_cycle',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  LimitOfDaysAtOnce = 'limit_of_days_at_once',
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WillBeEarnedMonthly = 'will_be_earned_monthly',
  /** column name */
  WillCarryForward = 'will_carry_forward',
}

/** select "organization_active_leave_types_aggregate_bool_exp_avg_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
}

/** select "organization_active_leave_types_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  WillBeEarnedMonthly = 'will_be_earned_monthly',
  /** column name */
  WillCarryForward = 'will_carry_forward',
}

/** select "organization_active_leave_types_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  WillBeEarnedMonthly = 'will_be_earned_monthly',
  /** column name */
  WillCarryForward = 'will_carry_forward',
}

/** select "organization_active_leave_types_aggregate_bool_exp_corr_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
}

/** select "organization_active_leave_types_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
}

/** select "organization_active_leave_types_aggregate_bool_exp_max_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
}

/** select "organization_active_leave_types_aggregate_bool_exp_min_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
}

/** select "organization_active_leave_types_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
}

/** select "organization_active_leave_types_aggregate_bool_exp_sum_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
}

/** select "organization_active_leave_types_aggregate_bool_exp_var_samp_arguments_columns" columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Select_Column_Organization_Active_Leave_Types_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
}

/** input type for updating data in table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Set_Input = {
  annual_carry_forward_amount?: InputMaybe<Scalars['Int']>;
  annual_eligibility?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  followed_leave_cycle?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  limit_of_days_at_once?: InputMaybe<Scalars['Int']>;
  monthly_earning_amount?: InputMaybe<Scalars['float8']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  will_be_earned_monthly?: InputMaybe<Scalars['Boolean']>;
  will_carry_forward?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Organization_Active_Leave_Types_Stddev_Fields = {
  __typename?: 'organization_active_leave_types_stddev_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Float']>;
  annual_eligibility?: Maybe<Scalars['Float']>;
  limit_of_days_at_once?: Maybe<Scalars['Float']>;
  monthly_earning_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Stddev_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Active_Leave_Types_Stddev_Pop_Fields = {
  __typename?: 'organization_active_leave_types_stddev_pop_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Float']>;
  annual_eligibility?: Maybe<Scalars['Float']>;
  limit_of_days_at_once?: Maybe<Scalars['Float']>;
  monthly_earning_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Stddev_Pop_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Active_Leave_Types_Stddev_Samp_Fields = {
  __typename?: 'organization_active_leave_types_stddev_samp_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Float']>;
  annual_eligibility?: Maybe<Scalars['Float']>;
  limit_of_days_at_once?: Maybe<Scalars['Float']>;
  monthly_earning_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Stddev_Samp_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Active_Leave_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Active_Leave_Types_Stream_Cursor_Value_Input = {
  annual_carry_forward_amount?: InputMaybe<Scalars['Int']>;
  annual_eligibility?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  followed_leave_cycle?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  limit_of_days_at_once?: InputMaybe<Scalars['Int']>;
  monthly_earning_amount?: InputMaybe<Scalars['float8']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  will_be_earned_monthly?: InputMaybe<Scalars['Boolean']>;
  will_carry_forward?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Organization_Active_Leave_Types_Sum_Fields = {
  __typename?: 'organization_active_leave_types_sum_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Int']>;
  annual_eligibility?: Maybe<Scalars['Int']>;
  limit_of_days_at_once?: Maybe<Scalars['Int']>;
  monthly_earning_amount?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Sum_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
};

/** update columns of table "organization_active_leave_types" */
export enum Organization_Active_Leave_Types_Update_Column {
  /** column name */
  AnnualCarryForwardAmount = 'annual_carry_forward_amount',
  /** column name */
  AnnualEligibility = 'annual_eligibility',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FollowedLeaveCycle = 'followed_leave_cycle',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  LimitOfDaysAtOnce = 'limit_of_days_at_once',
  /** column name */
  MonthlyEarningAmount = 'monthly_earning_amount',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WillBeEarnedMonthly = 'will_be_earned_monthly',
  /** column name */
  WillCarryForward = 'will_carry_forward',
}

export type Organization_Active_Leave_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Active_Leave_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Active_Leave_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Active_Leave_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Active_Leave_Types_Var_Pop_Fields = {
  __typename?: 'organization_active_leave_types_var_pop_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Float']>;
  annual_eligibility?: Maybe<Scalars['Float']>;
  limit_of_days_at_once?: Maybe<Scalars['Float']>;
  monthly_earning_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Var_Pop_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Active_Leave_Types_Var_Samp_Fields = {
  __typename?: 'organization_active_leave_types_var_samp_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Float']>;
  annual_eligibility?: Maybe<Scalars['Float']>;
  limit_of_days_at_once?: Maybe<Scalars['Float']>;
  monthly_earning_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Var_Samp_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Active_Leave_Types_Variance_Fields = {
  __typename?: 'organization_active_leave_types_variance_fields';
  annual_carry_forward_amount?: Maybe<Scalars['Float']>;
  annual_eligibility?: Maybe<Scalars['Float']>;
  limit_of_days_at_once?: Maybe<Scalars['Float']>;
  monthly_earning_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization_active_leave_types" */
export type Organization_Active_Leave_Types_Variance_Order_By = {
  annual_carry_forward_amount?: InputMaybe<Order_By>;
  annual_eligibility?: InputMaybe<Order_By>;
  limit_of_days_at_once?: InputMaybe<Order_By>;
  monthly_earning_amount?: InputMaybe<Order_By>;
};

/** Represent org-admins of an organization */
export type Organization_Admin = {
  __typename?: 'organization_admin';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  is_owner: Scalars['Boolean'];
  manager_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByUserId: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "organization_admin" */
export type Organization_Admin_Aggregate = {
  __typename?: 'organization_admin_aggregate';
  aggregate?: Maybe<Organization_Admin_Aggregate_Fields>;
  nodes: Array<Organization_Admin>;
};

export type Organization_Admin_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organization_Admin_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organization_Admin_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organization_Admin_Aggregate_Bool_Exp_Count>;
};

export type Organization_Admin_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organization_Admin_Select_Column_Organization_Admin_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Admin_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Admin_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organization_Admin_Select_Column_Organization_Admin_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Admin_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Admin_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Admin_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_admin" */
export type Organization_Admin_Aggregate_Fields = {
  __typename?: 'organization_admin_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_Admin_Max_Fields>;
  min?: Maybe<Organization_Admin_Min_Fields>;
};

/** aggregate fields of "organization_admin" */
export type Organization_Admin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_admin" */
export type Organization_Admin_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Admin_Max_Order_By>;
  min?: InputMaybe<Organization_Admin_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_admin" */
export type Organization_Admin_Arr_Rel_Insert_Input = {
  data: Array<Organization_Admin_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Admin_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_admin". All fields are combined with a logical 'AND'. */
export type Organization_Admin_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Admin_Bool_Exp>>;
  _not?: InputMaybe<Organization_Admin_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Admin_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_owner?: InputMaybe<Boolean_Comparison_Exp>;
  manager_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByUserId?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_admin" */
export enum Organization_Admin_Constraint {
  /** unique or primary key constraint on columns "user_id", "organization_id" */
  OrganizationUserPkey = 'organization_user_pkey',
}

/** input type for inserting data into table "organization_admin" */
export type Organization_Admin_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_owner?: InputMaybe<Scalars['Boolean']>;
  manager_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Organization_Admin_Max_Fields = {
  __typename?: 'organization_admin_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  manager_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_admin" */
export type Organization_Admin_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Admin_Min_Fields = {
  __typename?: 'organization_admin_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  manager_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_admin" */
export type Organization_Admin_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_admin" */
export type Organization_Admin_Mutation_Response = {
  __typename?: 'organization_admin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Admin>;
};

/** on_conflict condition type for table "organization_admin" */
export type Organization_Admin_On_Conflict = {
  constraint: Organization_Admin_Constraint;
  update_columns?: Array<Organization_Admin_Update_Column>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_admin". */
export type Organization_Admin_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_owner?: InputMaybe<Order_By>;
  manager_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByUserId?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_admin */
export type Organization_Admin_Pk_Columns_Input = {
  organization_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "organization_admin" */
export enum Organization_Admin_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsOwner = 'is_owner',
  /** column name */
  ManagerId = 'manager_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "organization_admin_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_admin" */
export enum Organization_Admin_Select_Column_Organization_Admin_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsOwner = 'is_owner',
}

/** select "organization_admin_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_admin" */
export enum Organization_Admin_Select_Column_Organization_Admin_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsOwner = 'is_owner',
}

/** input type for updating data in table "organization_admin" */
export type Organization_Admin_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_owner?: InputMaybe<Scalars['Boolean']>;
  manager_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "organization_admin" */
export type Organization_Admin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Admin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Admin_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_owner?: InputMaybe<Scalars['Boolean']>;
  manager_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "organization_admin" */
export enum Organization_Admin_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsOwner = 'is_owner',
  /** column name */
  ManagerId = 'manager_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Organization_Admin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Admin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Admin_Bool_Exp;
};

/** aggregated selection of "organization" */
export type Organization_Aggregate = {
  __typename?: 'organization_aggregate';
  aggregate?: Maybe<Organization_Aggregate_Fields>;
  nodes: Array<Organization>;
};

export type Organization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Aggregate_Bool_Exp_Count>;
};

export type Organization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_Fields = {
  __typename?: 'organization_aggregate_fields';
  avg?: Maybe<Organization_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organization_Max_Fields>;
  min?: Maybe<Organization_Min_Fields>;
  stddev?: Maybe<Organization_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_Sum_Fields>;
  var_pop?: Maybe<Organization_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_Var_Samp_Fields>;
  variance?: Maybe<Organization_Variance_Fields>;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization" */
export type Organization_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Max_Order_By>;
  min?: InputMaybe<Organization_Min_Order_By>;
  stddev?: InputMaybe<Organization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organization_Append_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "organization" */
export type Organization_Arr_Rel_Insert_Input = {
  data: Array<Organization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Avg_Fields = {
  __typename?: 'organization_avg_fields';
  working_days?: Maybe<Scalars['Float']>;
  working_hours?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization" */
export type Organization_Avg_Order_By = {
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** columns and relationships of "organization_awards" */
export type Organization_Awards = {
  __typename?: 'organization_awards';
  /** An array relationship */
  award_types: Array<Awards>;
  /** An aggregate relationship */
  award_types_aggregate: Awards_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  manager_permission: Scalars['Boolean'];
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "organization_awards" */
export type Organization_AwardsAward_TypesArgs = {
  distinct_on?: InputMaybe<Array<Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Awards_Order_By>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

/** columns and relationships of "organization_awards" */
export type Organization_AwardsAward_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Awards_Order_By>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

/** aggregated selection of "organization_awards" */
export type Organization_Awards_Aggregate = {
  __typename?: 'organization_awards_aggregate';
  aggregate?: Maybe<Organization_Awards_Aggregate_Fields>;
  nodes: Array<Organization_Awards>;
};

/** aggregate fields of "organization_awards" */
export type Organization_Awards_Aggregate_Fields = {
  __typename?: 'organization_awards_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_Awards_Max_Fields>;
  min?: Maybe<Organization_Awards_Min_Fields>;
};

/** aggregate fields of "organization_awards" */
export type Organization_Awards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Awards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organization_awards". All fields are combined with a logical 'AND'. */
export type Organization_Awards_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Awards_Bool_Exp>>;
  _not?: InputMaybe<Organization_Awards_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Awards_Bool_Exp>>;
  award_types?: InputMaybe<Awards_Bool_Exp>;
  award_types_aggregate?: InputMaybe<Awards_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  manager_permission?: InputMaybe<Boolean_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_awards" */
export enum Organization_Awards_Constraint {
  /** unique or primary key constraint on columns "organization_id" */
  OrganizationAwardsOrganizationIdKey = 'organization_awards_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationAwardsPkey = 'organization_awards_pkey',
}

/** input type for inserting data into table "organization_awards" */
export type Organization_Awards_Insert_Input = {
  award_types?: InputMaybe<Awards_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  manager_permission?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organization_Awards_Max_Fields = {
  __typename?: 'organization_awards_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Organization_Awards_Min_Fields = {
  __typename?: 'organization_awards_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organization_awards" */
export type Organization_Awards_Mutation_Response = {
  __typename?: 'organization_awards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Awards>;
};

/** on_conflict condition type for table "organization_awards" */
export type Organization_Awards_On_Conflict = {
  constraint: Organization_Awards_Constraint;
  update_columns?: Array<Organization_Awards_Update_Column>;
  where?: InputMaybe<Organization_Awards_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_awards". */
export type Organization_Awards_Order_By = {
  award_types_aggregate?: InputMaybe<Awards_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  manager_permission?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_awards */
export type Organization_Awards_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_awards" */
export enum Organization_Awards_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  ManagerPermission = 'manager_permission',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_awards" */
export type Organization_Awards_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  manager_permission?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization_awards" */
export type Organization_Awards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Awards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Awards_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  manager_permission?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_awards" */
export enum Organization_Awards_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  ManagerPermission = 'manager_permission',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Awards_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Awards_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Awards_Bool_Exp;
};

/** Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'. */
export type Organization_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Bool_Exp>>;
  _not?: InputMaybe<Organization_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Bool_Exp>>;
  additional_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  anniversary_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  assessment_feedbacks?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  assessment_feedbacks_aggregate?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp>;
  assessment_records?: InputMaybe<Assessment_Record_Bool_Exp>;
  assessment_records_aggregate?: InputMaybe<Assessment_Record_Aggregate_Bool_Exp>;
  assessment_role_details?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
  assessment_role_details_aggregate?: InputMaybe<Assessment_Role_Details_Aggregate_Bool_Exp>;
  assessment_types?: InputMaybe<Assessment_Type_Bool_Exp>;
  assessment_types_aggregate?: InputMaybe<Assessment_Type_Aggregate_Bool_Exp>;
  assessments?: InputMaybe<Assessment_Bool_Exp>;
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Bool_Exp>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Bool_Exp>;
  calendars?: InputMaybe<Calendar_Bool_Exp>;
  calendars_aggregate?: InputMaybe<Calendar_Aggregate_Bool_Exp>;
  calls?: InputMaybe<Call_Bool_Exp>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Bool_Exp>;
  career_development_details?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  career_development_details_aggregate?: InputMaybe<Career_Development_Detail_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Bool_Exp>;
  employee_bandwidth_data?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  employee_bandwidth_data_aggregate?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp>;
  employee_capacities?: InputMaybe<Employee_Capacity_Bool_Exp>;
  employee_capacities_aggregate?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp>;
  holiday_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_modified_by?: InputMaybe<Uuid_Comparison_Exp>;
  leave_organizations?: InputMaybe<Leave_Organization_Bool_Exp>;
  leave_organizations_aggregate?: InputMaybe<Leave_Organization_Aggregate_Bool_Exp>;
  list_Of_Skills?: InputMaybe<List_Of_Skills_Bool_Exp>;
  list_Of_Skills_aggregate?: InputMaybe<List_Of_Skills_Aggregate_Bool_Exp>;
  logo_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  main_skills?: InputMaybe<Main_Skills_Bool_Exp>;
  main_skills_aggregate?: InputMaybe<Main_Skills_Aggregate_Bool_Exp>;
  meeting_rooms?: InputMaybe<Meeting_Room_Bool_Exp>;
  meeting_rooms_aggregate?: InputMaybe<Meeting_Room_Aggregate_Bool_Exp>;
  org_end_time?: InputMaybe<Timetz_Comparison_Exp>;
  org_start_time?: InputMaybe<Timetz_Comparison_Exp>;
  organization_admins?: InputMaybe<Organization_Admin_Bool_Exp>;
  organization_admins_aggregate?: InputMaybe<Organization_Admin_Aggregate_Bool_Exp>;
  organization_employees?: InputMaybe<Organization_Employee_Bool_Exp>;
  organization_employees_aggregate?: InputMaybe<Organization_Employee_Aggregate_Bool_Exp>;
  organization_holidays?: InputMaybe<Organization_Holidays_Bool_Exp>;
  organization_holidays_aggregate?: InputMaybe<Organization_Holidays_Aggregate_Bool_Exp>;
  organization_leave_cycles?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
  organization_leave_cycles_aggregate?: InputMaybe<Organization_Leave_Cycles_Aggregate_Bool_Exp>;
  organization_locations?: InputMaybe<Organization_Locations_Bool_Exp>;
  organization_locations_aggregate?: InputMaybe<Organization_Locations_Aggregate_Bool_Exp>;
  organization_name?: InputMaybe<String_Comparison_Exp>;
  organization_roles?: InputMaybe<Organization_Roles_Bool_Exp>;
  organization_roles_aggregate?: InputMaybe<Organization_Roles_Aggregate_Bool_Exp>;
  organization_users?: InputMaybe<Organization_User_Bool_Exp>;
  organization_users_aggregate?: InputMaybe<Organization_User_Aggregate_Bool_Exp>;
  organizations_active_leave_types?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  organizations_active_leave_types_aggregate?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  performance_review_settings?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  performance_review_settings_aggregate?: InputMaybe<Performance_Review_Settings_Aggregate_Bool_Exp>;
  personaliseds?: InputMaybe<Personalised_Bool_Exp>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Bool_Exp>;
  privacy_policies?: InputMaybe<String_Comparison_Exp>;
  profiles?: InputMaybe<Profile_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
  project_clients?: InputMaybe<Project_Client_Bool_Exp>;
  project_clients_aggregate?: InputMaybe<Project_Client_Aggregate_Bool_Exp>;
  project_sprints?: InputMaybe<Project_Sprints_Bool_Exp>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Bool_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  storageFileByLogoFileId?: InputMaybe<Storage_Files_Bool_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  stress_data?: InputMaybe<Stress_Data_Bool_Exp>;
  stress_data_aggregate?: InputMaybe<Stress_Data_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tags_Bool_Exp>;
  tags_aggregate?: InputMaybe<Tags_Aggregate_Bool_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  terms_and_conditions?: InputMaybe<String_Comparison_Exp>;
  timezone_name?: InputMaybe<String_Comparison_Exp>;
  training_feedbacks?: InputMaybe<Training_Feedback_Bool_Exp>;
  training_feedbacks_aggregate?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp>;
  training_role_details?: InputMaybe<Training_Role_Details_Bool_Exp>;
  training_role_details_aggregate?: InputMaybe<Training_Role_Details_Aggregate_Bool_Exp>;
  training_type_for_trainings?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
  training_type_for_trainings_aggregate?: InputMaybe<Training_Type_For_Training_Aggregate_Bool_Exp>;
  trainings?: InputMaybe<Training_Bool_Exp>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByOwnedBy?: InputMaybe<Users_Bool_Exp>;
  working_days?: InputMaybe<Int_Comparison_Exp>;
  working_hours?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization" */
export enum Organization_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationPkey = 'organization_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organization_Delete_At_Path_Input = {
  additional_fields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organization_Delete_Elem_Input = {
  additional_fields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organization_Delete_Key_Input = {
  additional_fields?: InputMaybe<Scalars['String']>;
};

/** organization employees link [manager-1,manager-2,employee] */
export type Organization_Employee = {
  __typename?: 'organization_employee';
  manager_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "organization_employee" */
export type Organization_Employee_Aggregate = {
  __typename?: 'organization_employee_aggregate';
  aggregate?: Maybe<Organization_Employee_Aggregate_Fields>;
  nodes: Array<Organization_Employee>;
};

export type Organization_Employee_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Employee_Aggregate_Bool_Exp_Count>;
};

export type Organization_Employee_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Employee_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_employee" */
export type Organization_Employee_Aggregate_Fields = {
  __typename?: 'organization_employee_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_Employee_Max_Fields>;
  min?: Maybe<Organization_Employee_Min_Fields>;
};

/** aggregate fields of "organization_employee" */
export type Organization_Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_employee" */
export type Organization_Employee_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Employee_Max_Order_By>;
  min?: InputMaybe<Organization_Employee_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_employee" */
export type Organization_Employee_Arr_Rel_Insert_Input = {
  data: Array<Organization_Employee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Employee_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_employee". All fields are combined with a logical 'AND'. */
export type Organization_Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Employee_Bool_Exp>>;
  _not?: InputMaybe<Organization_Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Employee_Bool_Exp>>;
  manager_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_employee" */
export enum Organization_Employee_Constraint {
  /** unique or primary key constraint on columns "user_id", "organization_id" */
  OrganizationEmployeePkey = 'organization_employee_pkey',
}

/** input type for inserting data into table "organization_employee" */
export type Organization_Employee_Insert_Input = {
  manager_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Organization_Employee_Max_Fields = {
  __typename?: 'organization_employee_max_fields';
  manager_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_employee" */
export type Organization_Employee_Max_Order_By = {
  manager_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Employee_Min_Fields = {
  __typename?: 'organization_employee_min_fields';
  manager_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_employee" */
export type Organization_Employee_Min_Order_By = {
  manager_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_employee" */
export type Organization_Employee_Mutation_Response = {
  __typename?: 'organization_employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Employee>;
};

/** on_conflict condition type for table "organization_employee" */
export type Organization_Employee_On_Conflict = {
  constraint: Organization_Employee_Constraint;
  update_columns?: Array<Organization_Employee_Update_Column>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_employee". */
export type Organization_Employee_Order_By = {
  manager_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_employee */
export type Organization_Employee_Pk_Columns_Input = {
  organization_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "organization_employee" */
export enum Organization_Employee_Select_Column {
  /** column name */
  ManagerId = 'manager_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "organization_employee" */
export type Organization_Employee_Set_Input = {
  manager_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "organization_employee" */
export type Organization_Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Employee_Stream_Cursor_Value_Input = {
  manager_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "organization_employee" */
export enum Organization_Employee_Update_Column {
  /** column name */
  ManagerId = 'manager_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UserId = 'user_id',
}

export type Organization_Employee_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Employee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Employee_Bool_Exp;
};

/** columns and relationships of "organization_holidays" */
export type Organization_Holidays = {
  __typename?: 'organization_holidays';
  date: Scalars['date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
};

/** aggregated selection of "organization_holidays" */
export type Organization_Holidays_Aggregate = {
  __typename?: 'organization_holidays_aggregate';
  aggregate?: Maybe<Organization_Holidays_Aggregate_Fields>;
  nodes: Array<Organization_Holidays>;
};

export type Organization_Holidays_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Holidays_Aggregate_Bool_Exp_Count>;
};

export type Organization_Holidays_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Holidays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Holidays_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_holidays" */
export type Organization_Holidays_Aggregate_Fields = {
  __typename?: 'organization_holidays_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_Holidays_Max_Fields>;
  min?: Maybe<Organization_Holidays_Min_Fields>;
};

/** aggregate fields of "organization_holidays" */
export type Organization_Holidays_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Holidays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_holidays" */
export type Organization_Holidays_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Holidays_Max_Order_By>;
  min?: InputMaybe<Organization_Holidays_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_holidays" */
export type Organization_Holidays_Arr_Rel_Insert_Input = {
  data: Array<Organization_Holidays_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Holidays_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_holidays". All fields are combined with a logical 'AND'. */
export type Organization_Holidays_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Holidays_Bool_Exp>>;
  _not?: InputMaybe<Organization_Holidays_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Holidays_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_holidays" */
export enum Organization_Holidays_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationHolidaysPkey = 'organization_holidays_pkey',
}

/** input type for inserting data into table "organization_holidays" */
export type Organization_Holidays_Insert_Input = {
  date?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Organization_Holidays_Max_Fields = {
  __typename?: 'organization_holidays_max_fields';
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_holidays" */
export type Organization_Holidays_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Holidays_Min_Fields = {
  __typename?: 'organization_holidays_min_fields';
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_holidays" */
export type Organization_Holidays_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_holidays" */
export type Organization_Holidays_Mutation_Response = {
  __typename?: 'organization_holidays_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Holidays>;
};

/** on_conflict condition type for table "organization_holidays" */
export type Organization_Holidays_On_Conflict = {
  constraint: Organization_Holidays_Constraint;
  update_columns?: Array<Organization_Holidays_Update_Column>;
  where?: InputMaybe<Organization_Holidays_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_holidays". */
export type Organization_Holidays_Order_By = {
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_holidays */
export type Organization_Holidays_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_holidays" */
export enum Organization_Holidays_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
}

/** input type for updating data in table "organization_holidays" */
export type Organization_Holidays_Set_Input = {
  date?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "organization_holidays" */
export type Organization_Holidays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Holidays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Holidays_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "organization_holidays" */
export enum Organization_Holidays_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
}

export type Organization_Holidays_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Holidays_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Holidays_Bool_Exp;
};

/** input type for incrementing numeric columns in table "organization" */
export type Organization_Inc_Input = {
  working_days?: InputMaybe<Scalars['Int']>;
  working_hours?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization" */
export type Organization_Insert_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  anniversary_date?: InputMaybe<Scalars['timestamptz']>;
  assessment_feedbacks?: InputMaybe<Assessment_Feedback_Arr_Rel_Insert_Input>;
  assessment_records?: InputMaybe<Assessment_Record_Arr_Rel_Insert_Input>;
  assessment_role_details?: InputMaybe<Assessment_Role_Details_Arr_Rel_Insert_Input>;
  assessment_types?: InputMaybe<Assessment_Type_Arr_Rel_Insert_Input>;
  assessments?: InputMaybe<Assessment_Arr_Rel_Insert_Input>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Arr_Rel_Insert_Input>;
  calendars?: InputMaybe<Calendar_Arr_Rel_Insert_Input>;
  calls?: InputMaybe<Call_Arr_Rel_Insert_Input>;
  career_development_details?: InputMaybe<Career_Development_Detail_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  employee_bandwidth_data?: InputMaybe<Employee_Bandwidth_Data_Arr_Rel_Insert_Input>;
  employee_capacities?: InputMaybe<Employee_Capacity_Arr_Rel_Insert_Input>;
  holiday_file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_modified_by?: InputMaybe<Scalars['uuid']>;
  leave_organizations?: InputMaybe<Leave_Organization_Arr_Rel_Insert_Input>;
  list_Of_Skills?: InputMaybe<List_Of_Skills_Arr_Rel_Insert_Input>;
  logo_file_id?: InputMaybe<Scalars['uuid']>;
  main_skills?: InputMaybe<Main_Skills_Arr_Rel_Insert_Input>;
  meeting_rooms?: InputMaybe<Meeting_Room_Arr_Rel_Insert_Input>;
  org_end_time?: InputMaybe<Scalars['timetz']>;
  org_start_time?: InputMaybe<Scalars['timetz']>;
  organization_admins?: InputMaybe<Organization_Admin_Arr_Rel_Insert_Input>;
  organization_employees?: InputMaybe<Organization_Employee_Arr_Rel_Insert_Input>;
  organization_holidays?: InputMaybe<Organization_Holidays_Arr_Rel_Insert_Input>;
  organization_leave_cycles?: InputMaybe<Organization_Leave_Cycles_Arr_Rel_Insert_Input>;
  organization_locations?: InputMaybe<Organization_Locations_Arr_Rel_Insert_Input>;
  organization_name?: InputMaybe<Scalars['String']>;
  organization_roles?: InputMaybe<Organization_Roles_Arr_Rel_Insert_Input>;
  organization_users?: InputMaybe<Organization_User_Arr_Rel_Insert_Input>;
  organizations_active_leave_types?: InputMaybe<Organization_Active_Leave_Types_Arr_Rel_Insert_Input>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  performance_review_settings?: InputMaybe<Performance_Review_Settings_Arr_Rel_Insert_Input>;
  personaliseds?: InputMaybe<Personalised_Arr_Rel_Insert_Input>;
  privacy_policies?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
  project_clients?: InputMaybe<Project_Client_Arr_Rel_Insert_Input>;
  project_sprints?: InputMaybe<Project_Sprints_Arr_Rel_Insert_Input>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  storageFileByLogoFileId?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  stress_data?: InputMaybe<Stress_Data_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tags_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  terms_and_conditions?: InputMaybe<Scalars['String']>;
  timezone_name?: InputMaybe<Scalars['String']>;
  training_feedbacks?: InputMaybe<Training_Feedback_Arr_Rel_Insert_Input>;
  training_role_details?: InputMaybe<Training_Role_Details_Arr_Rel_Insert_Input>;
  training_type_for_trainings?: InputMaybe<Training_Type_For_Training_Arr_Rel_Insert_Input>;
  trainings?: InputMaybe<Training_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByOwnedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  working_days?: InputMaybe<Scalars['Int']>;
  working_hours?: InputMaybe<Scalars['Int']>;
};

/** this table represents the leave cycles for an organization */
export type Organization_Leave_Cycles = {
  __typename?: 'organization_leave_cycles';
  created_at: Scalars['timestamptz'];
  current_month_date?: Maybe<Scalars['String']>;
  current_term_years?: Maybe<Scalars['String']>;
  ending_date?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  org_end_date?: Maybe<Scalars['date']>;
  org_id: Scalars['uuid'];
  org_start_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  org_user_leave_data: Array<Organization_User_Leave_Data>;
  /** An aggregate relationship */
  org_user_leave_data_aggregate: Organization_User_Leave_Data_Aggregate;
  /** An object relationship */
  organization: Organization;
  /** An array relationship */
  organizations_active_leave_types: Array<Organization_Active_Leave_Types>;
  /** An aggregate relationship */
  organizations_active_leave_types_aggregate: Organization_Active_Leave_Types_Aggregate;
  starting_date?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** this table represents the leave cycles for an organization */
export type Organization_Leave_CyclesOrg_User_Leave_DataArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Data_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
};

/** this table represents the leave cycles for an organization */
export type Organization_Leave_CyclesOrg_User_Leave_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Data_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
};

/** this table represents the leave cycles for an organization */
export type Organization_Leave_CyclesOrganizations_Active_Leave_TypesArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_Active_Leave_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Active_Leave_Types_Order_By>>;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

/** this table represents the leave cycles for an organization */
export type Organization_Leave_CyclesOrganizations_Active_Leave_Types_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Organization_Active_Leave_Types_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Organization_Active_Leave_Types_Order_By>>;
    where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  };

/** aggregated selection of "organization_leave_cycles" */
export type Organization_Leave_Cycles_Aggregate = {
  __typename?: 'organization_leave_cycles_aggregate';
  aggregate?: Maybe<Organization_Leave_Cycles_Aggregate_Fields>;
  nodes: Array<Organization_Leave_Cycles>;
};

export type Organization_Leave_Cycles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Leave_Cycles_Aggregate_Bool_Exp_Count>;
};

export type Organization_Leave_Cycles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Leave_Cycles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_leave_cycles" */
export type Organization_Leave_Cycles_Aggregate_Fields = {
  __typename?: 'organization_leave_cycles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_Leave_Cycles_Max_Fields>;
  min?: Maybe<Organization_Leave_Cycles_Min_Fields>;
};

/** aggregate fields of "organization_leave_cycles" */
export type Organization_Leave_Cycles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Leave_Cycles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Leave_Cycles_Max_Order_By>;
  min?: InputMaybe<Organization_Leave_Cycles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Arr_Rel_Insert_Input = {
  data: Array<Organization_Leave_Cycles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Leave_Cycles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_leave_cycles". All fields are combined with a logical 'AND'. */
export type Organization_Leave_Cycles_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Leave_Cycles_Bool_Exp>>;
  _not?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Leave_Cycles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_month_date?: InputMaybe<String_Comparison_Exp>;
  current_term_years?: InputMaybe<String_Comparison_Exp>;
  ending_date?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  org_end_date?: InputMaybe<Date_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_start_date?: InputMaybe<Date_Comparison_Exp>;
  org_user_leave_data?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  org_user_leave_data_aggregate?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organizations_active_leave_types?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  organizations_active_leave_types_aggregate?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Bool_Exp>;
  starting_date?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_leave_cycles" */
export enum Organization_Leave_Cycles_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationLeaveCyclesPkey = 'organization_leave_cycles_pkey',
}

/** input type for inserting data into table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_month_date?: InputMaybe<Scalars['String']>;
  current_term_years?: InputMaybe<Scalars['String']>;
  ending_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  org_end_date?: InputMaybe<Scalars['date']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_start_date?: InputMaybe<Scalars['date']>;
  org_user_leave_data?: InputMaybe<Organization_User_Leave_Data_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organizations_active_leave_types?: InputMaybe<Organization_Active_Leave_Types_Arr_Rel_Insert_Input>;
  starting_date?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organization_Leave_Cycles_Max_Fields = {
  __typename?: 'organization_leave_cycles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_month_date?: Maybe<Scalars['String']>;
  current_term_years?: Maybe<Scalars['String']>;
  ending_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  org_end_date?: Maybe<Scalars['date']>;
  org_id?: Maybe<Scalars['uuid']>;
  org_start_date?: Maybe<Scalars['date']>;
  starting_date?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_month_date?: InputMaybe<Order_By>;
  current_term_years?: InputMaybe<Order_By>;
  ending_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_end_date?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_start_date?: InputMaybe<Order_By>;
  starting_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Leave_Cycles_Min_Fields = {
  __typename?: 'organization_leave_cycles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_month_date?: Maybe<Scalars['String']>;
  current_term_years?: Maybe<Scalars['String']>;
  ending_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  org_end_date?: Maybe<Scalars['date']>;
  org_id?: Maybe<Scalars['uuid']>;
  org_start_date?: Maybe<Scalars['date']>;
  starting_date?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_month_date?: InputMaybe<Order_By>;
  current_term_years?: InputMaybe<Order_By>;
  ending_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_end_date?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_start_date?: InputMaybe<Order_By>;
  starting_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Mutation_Response = {
  __typename?: 'organization_leave_cycles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Leave_Cycles>;
};

/** input type for inserting object relation for remote table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Obj_Rel_Insert_Input = {
  data: Organization_Leave_Cycles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Leave_Cycles_On_Conflict>;
};

/** on_conflict condition type for table "organization_leave_cycles" */
export type Organization_Leave_Cycles_On_Conflict = {
  constraint: Organization_Leave_Cycles_Constraint;
  update_columns?: Array<Organization_Leave_Cycles_Update_Column>;
  where?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_leave_cycles". */
export type Organization_Leave_Cycles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_month_date?: InputMaybe<Order_By>;
  current_term_years?: InputMaybe<Order_By>;
  ending_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_end_date?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_start_date?: InputMaybe<Order_By>;
  org_user_leave_data_aggregate?: InputMaybe<Organization_User_Leave_Data_Aggregate_Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organizations_active_leave_types_aggregate?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Order_By>;
  starting_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_leave_cycles */
export type Organization_Leave_Cycles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_leave_cycles" */
export enum Organization_Leave_Cycles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentMonthDate = 'current_month_date',
  /** column name */
  CurrentTermYears = 'current_term_years',
  /** column name */
  EndingDate = 'ending_date',
  /** column name */
  Id = 'id',
  /** column name */
  OrgEndDate = 'org_end_date',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OrgStartDate = 'org_start_date',
  /** column name */
  StartingDate = 'starting_date',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_month_date?: InputMaybe<Scalars['String']>;
  current_term_years?: InputMaybe<Scalars['String']>;
  ending_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  org_end_date?: InputMaybe<Scalars['date']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_start_date?: InputMaybe<Scalars['date']>;
  starting_date?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization_leave_cycles" */
export type Organization_Leave_Cycles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Leave_Cycles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Leave_Cycles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_month_date?: InputMaybe<Scalars['String']>;
  current_term_years?: InputMaybe<Scalars['String']>;
  ending_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  org_end_date?: InputMaybe<Scalars['date']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_start_date?: InputMaybe<Scalars['date']>;
  starting_date?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_leave_cycles" */
export enum Organization_Leave_Cycles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentMonthDate = 'current_month_date',
  /** column name */
  CurrentTermYears = 'current_term_years',
  /** column name */
  EndingDate = 'ending_date',
  /** column name */
  Id = 'id',
  /** column name */
  OrgEndDate = 'org_end_date',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OrgStartDate = 'org_start_date',
  /** column name */
  StartingDate = 'starting_date',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Leave_Cycles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Leave_Cycles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Leave_Cycles_Bool_Exp;
};

/** Branch locations of an Organization */
export type Organization_Locations = {
  __typename?: 'organization_locations';
  /** An object relationship */
  address: Address;
  address_id: Scalars['uuid'];
  /** get count of booked seats of an organization location on a given date */
  booked_seats?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  hotdesk_bookings: Array<Hotdesk_Bookings>;
  /** An aggregate relationship */
  hotdesk_bookings_aggregate: Hotdesk_Bookings_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  timezone_name: Scalars['String'];
  title: Scalars['String'];
  total_seats: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** Branch locations of an Organization */
export type Organization_LocationsBooked_SeatsArgs = {
  args: Booked_Seats_Organization_Locations_Args;
};

/** Branch locations of an Organization */
export type Organization_LocationsHotdesk_BookingsArgs = {
  distinct_on?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hotdesk_Bookings_Order_By>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

/** Branch locations of an Organization */
export type Organization_LocationsHotdesk_Bookings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hotdesk_Bookings_Order_By>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

/** aggregated selection of "organization_locations" */
export type Organization_Locations_Aggregate = {
  __typename?: 'organization_locations_aggregate';
  aggregate?: Maybe<Organization_Locations_Aggregate_Fields>;
  nodes: Array<Organization_Locations>;
};

export type Organization_Locations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Locations_Aggregate_Bool_Exp_Count>;
};

export type Organization_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_locations" */
export type Organization_Locations_Aggregate_Fields = {
  __typename?: 'organization_locations_aggregate_fields';
  avg?: Maybe<Organization_Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organization_Locations_Max_Fields>;
  min?: Maybe<Organization_Locations_Min_Fields>;
  stddev?: Maybe<Organization_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_Locations_Sum_Fields>;
  var_pop?: Maybe<Organization_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_Locations_Var_Samp_Fields>;
  variance?: Maybe<Organization_Locations_Variance_Fields>;
};

/** aggregate fields of "organization_locations" */
export type Organization_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_locations" */
export type Organization_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Locations_Max_Order_By>;
  min?: InputMaybe<Organization_Locations_Min_Order_By>;
  stddev?: InputMaybe<Organization_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_locations" */
export type Organization_Locations_Arr_Rel_Insert_Input = {
  data: Array<Organization_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Locations_Avg_Fields = {
  __typename?: 'organization_locations_avg_fields';
  total_seats?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization_locations" */
export type Organization_Locations_Avg_Order_By = {
  total_seats?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_locations". All fields are combined with a logical 'AND'. */
export type Organization_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Locations_Bool_Exp>>;
  _not?: InputMaybe<Organization_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Locations_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hotdesk_bookings?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
  hotdesk_bookings_aggregate?: InputMaybe<Hotdesk_Bookings_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  timezone_name?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  total_seats?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_locations" */
export enum Organization_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationLocationsPkey = 'organization_locations_pkey',
}

/** input type for incrementing numeric columns in table "organization_locations" */
export type Organization_Locations_Inc_Input = {
  total_seats?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_locations" */
export type Organization_Locations_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hotdesk_bookings?: InputMaybe<Hotdesk_Bookings_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  timezone_name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  total_seats?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organization_Locations_Max_Fields = {
  __typename?: 'organization_locations_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  timezone_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_seats?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization_locations" */
export type Organization_Locations_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  timezone_name?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  total_seats?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Locations_Min_Fields = {
  __typename?: 'organization_locations_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  timezone_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_seats?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization_locations" */
export type Organization_Locations_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  timezone_name?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  total_seats?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_locations" */
export type Organization_Locations_Mutation_Response = {
  __typename?: 'organization_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Locations>;
};

/** input type for inserting object relation for remote table "organization_locations" */
export type Organization_Locations_Obj_Rel_Insert_Input = {
  data: Organization_Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Locations_On_Conflict>;
};

/** on_conflict condition type for table "organization_locations" */
export type Organization_Locations_On_Conflict = {
  constraint: Organization_Locations_Constraint;
  update_columns?: Array<Organization_Locations_Update_Column>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_locations". */
export type Organization_Locations_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  hotdesk_bookings_aggregate?: InputMaybe<Hotdesk_Bookings_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  timezone_name?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  total_seats?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_locations */
export type Organization_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_locations" */
export enum Organization_Locations_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TimezoneName = 'timezone_name',
  /** column name */
  Title = 'title',
  /** column name */
  TotalSeats = 'total_seats',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_locations" */
export type Organization_Locations_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  timezone_name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  total_seats?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Organization_Locations_Stddev_Fields = {
  __typename?: 'organization_locations_stddev_fields';
  total_seats?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization_locations" */
export type Organization_Locations_Stddev_Order_By = {
  total_seats?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Locations_Stddev_Pop_Fields = {
  __typename?: 'organization_locations_stddev_pop_fields';
  total_seats?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization_locations" */
export type Organization_Locations_Stddev_Pop_Order_By = {
  total_seats?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Locations_Stddev_Samp_Fields = {
  __typename?: 'organization_locations_stddev_samp_fields';
  total_seats?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization_locations" */
export type Organization_Locations_Stddev_Samp_Order_By = {
  total_seats?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_locations" */
export type Organization_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Locations_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  timezone_name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  total_seats?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Organization_Locations_Sum_Fields = {
  __typename?: 'organization_locations_sum_fields';
  total_seats?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization_locations" */
export type Organization_Locations_Sum_Order_By = {
  total_seats?: InputMaybe<Order_By>;
};

/** update columns of table "organization_locations" */
export enum Organization_Locations_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TimezoneName = 'timezone_name',
  /** column name */
  Title = 'title',
  /** column name */
  TotalSeats = 'total_seats',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Locations_Var_Pop_Fields = {
  __typename?: 'organization_locations_var_pop_fields';
  total_seats?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization_locations" */
export type Organization_Locations_Var_Pop_Order_By = {
  total_seats?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Locations_Var_Samp_Fields = {
  __typename?: 'organization_locations_var_samp_fields';
  total_seats?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization_locations" */
export type Organization_Locations_Var_Samp_Order_By = {
  total_seats?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Locations_Variance_Fields = {
  __typename?: 'organization_locations_variance_fields';
  total_seats?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization_locations" */
export type Organization_Locations_Variance_Order_By = {
  total_seats?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Organization_Max_Fields = {
  __typename?: 'organization_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  anniversary_date?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  holiday_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  last_modified_by?: Maybe<Scalars['uuid']>;
  logo_file_id?: Maybe<Scalars['uuid']>;
  org_end_time?: Maybe<Scalars['timetz']>;
  org_start_time?: Maybe<Scalars['timetz']>;
  organization_name?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  privacy_policies?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  terms_and_conditions?: Maybe<Scalars['String']>;
  timezone_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  working_days?: Maybe<Scalars['Int']>;
  working_hours?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "organization" */
export type Organization_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  anniversary_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  holiday_file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  org_end_time?: InputMaybe<Order_By>;
  org_start_time?: InputMaybe<Order_By>;
  organization_name?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  privacy_policies?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  terms_and_conditions?: InputMaybe<Order_By>;
  timezone_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Min_Fields = {
  __typename?: 'organization_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  anniversary_date?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  holiday_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  last_modified_by?: Maybe<Scalars['uuid']>;
  logo_file_id?: Maybe<Scalars['uuid']>;
  org_end_time?: Maybe<Scalars['timetz']>;
  org_start_time?: Maybe<Scalars['timetz']>;
  organization_name?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  privacy_policies?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  terms_and_conditions?: Maybe<Scalars['String']>;
  timezone_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  working_days?: Maybe<Scalars['Int']>;
  working_hours?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "organization" */
export type Organization_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  anniversary_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  holiday_file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  org_end_time?: InputMaybe<Order_By>;
  org_start_time?: InputMaybe<Order_By>;
  organization_name?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  privacy_policies?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  terms_and_conditions?: InputMaybe<Order_By>;
  timezone_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization" */
export type Organization_Mutation_Response = {
  __typename?: 'organization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "organization" */
export type Organization_Obj_Rel_Insert_Input = {
  data: Organization_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** on_conflict condition type for table "organization" */
export type Organization_On_Conflict = {
  constraint: Organization_Constraint;
  update_columns?: Array<Organization_Update_Column>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Ordering options when selecting data from "organization". */
export type Organization_Order_By = {
  additional_fields?: InputMaybe<Order_By>;
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  anniversary_date?: InputMaybe<Order_By>;
  assessment_feedbacks_aggregate?: InputMaybe<Assessment_Feedback_Aggregate_Order_By>;
  assessment_records_aggregate?: InputMaybe<Assessment_Record_Aggregate_Order_By>;
  assessment_role_details_aggregate?: InputMaybe<Assessment_Role_Details_Aggregate_Order_By>;
  assessment_types_aggregate?: InputMaybe<Assessment_Type_Aggregate_Order_By>;
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Order_By>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Order_By>;
  calendars_aggregate?: InputMaybe<Calendar_Aggregate_Order_By>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Order_By>;
  career_development_details_aggregate?: InputMaybe<Career_Development_Detail_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  employee_bandwidth_data_aggregate?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Order_By>;
  employee_capacities_aggregate?: InputMaybe<Employee_Capacity_Aggregate_Order_By>;
  holiday_file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  leave_organizations_aggregate?: InputMaybe<Leave_Organization_Aggregate_Order_By>;
  list_Of_Skills_aggregate?: InputMaybe<List_Of_Skills_Aggregate_Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  main_skills_aggregate?: InputMaybe<Main_Skills_Aggregate_Order_By>;
  meeting_rooms_aggregate?: InputMaybe<Meeting_Room_Aggregate_Order_By>;
  org_end_time?: InputMaybe<Order_By>;
  org_start_time?: InputMaybe<Order_By>;
  organization_admins_aggregate?: InputMaybe<Organization_Admin_Aggregate_Order_By>;
  organization_employees_aggregate?: InputMaybe<Organization_Employee_Aggregate_Order_By>;
  organization_holidays_aggregate?: InputMaybe<Organization_Holidays_Aggregate_Order_By>;
  organization_leave_cycles_aggregate?: InputMaybe<Organization_Leave_Cycles_Aggregate_Order_By>;
  organization_locations_aggregate?: InputMaybe<Organization_Locations_Aggregate_Order_By>;
  organization_name?: InputMaybe<Order_By>;
  organization_roles_aggregate?: InputMaybe<Organization_Roles_Aggregate_Order_By>;
  organization_users_aggregate?: InputMaybe<Organization_User_Aggregate_Order_By>;
  organizations_active_leave_types_aggregate?: InputMaybe<Organization_Active_Leave_Types_Aggregate_Order_By>;
  owned_by?: InputMaybe<Order_By>;
  performance_review_settings_aggregate?: InputMaybe<Performance_Review_Settings_Aggregate_Order_By>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Order_By>;
  privacy_policies?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
  project_clients_aggregate?: InputMaybe<Project_Client_Aggregate_Order_By>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  storageFileByLogoFileId?: InputMaybe<Storage_Files_Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  stress_data_aggregate?: InputMaybe<Stress_Data_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tags_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  terms_and_conditions?: InputMaybe<Order_By>;
  timezone_name?: InputMaybe<Order_By>;
  training_feedbacks_aggregate?: InputMaybe<Training_Feedback_Aggregate_Order_By>;
  training_role_details_aggregate?: InputMaybe<Training_Role_Details_Aggregate_Order_By>;
  training_type_for_trainings_aggregate?: InputMaybe<Training_Type_For_Training_Aggregate_Order_By>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByOwnedBy?: InputMaybe<Users_Order_By>;
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization */
export type Organization_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organization_Prepend_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "organization_roles" */
export type Organization_Roles = {
  __typename?: 'organization_roles';
  /** An array relationship */
  assessment_role_details: Array<Assessment_Role_Details>;
  /** An aggregate relationship */
  assessment_role_details_aggregate: Assessment_Role_Details_Aggregate;
  /** An array relationship */
  assessments: Array<Assessment>;
  /** An aggregate relationship */
  assessments_aggregate: Assessment_Aggregate;
  /** An array relationship */
  career_development_role_details: Array<Career_Development_Role_Details>;
  /** An aggregate relationship */
  career_development_role_details_aggregate: Career_Development_Role_Details_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  goalsWithRoles: Array<GoalsWithRoles>;
  /** An aggregate relationship */
  goalsWithRoles_aggregate: GoalsWithRoles_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  kpiWithRoles: Array<KpiWithRole>;
  /** An aggregate relationship */
  kpiWithRoles_aggregate: KpiWithRole_Aggregate;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An array relationship */
  performanceReviewStatuses: Array<PerformanceReviewStatus>;
  /** An aggregate relationship */
  performanceReviewStatuses_aggregate: PerformanceReviewStatus_Aggregate;
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregate relationship */
  profiles_aggregate: Profile_Aggregate;
  /** An object relationship */
  role: Auth_Roles;
  role_title: Scalars['String'];
  system_role: Scalars['String'];
  /** An array relationship */
  training_role_details: Array<Training_Role_Details>;
  /** An aggregate relationship */
  training_role_details_aggregate: Training_Role_Details_Aggregate;
  /** An array relationship */
  trainings: Array<Training>;
  /** An aggregate relationship */
  trainings_aggregate: Training_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesAssessment_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesAssessment_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesAssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesAssessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesCareer_Development_Role_DetailsArgs = {
  distinct_on?: InputMaybe<
    Array<Career_Development_Role_Details_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Role_Details_Order_By>>;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesCareer_Development_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Career_Development_Role_Details_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Role_Details_Order_By>>;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesGoalsWithRolesArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithRoles_Order_By>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesGoalsWithRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithRoles_Order_By>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesKpiWithRolesArgs = {
  distinct_on?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithRole_Order_By>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesKpiWithRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithRole_Order_By>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesPerformanceReviewStatusesArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesPerformanceReviewStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesTraining_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesTraining_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** columns and relationships of "organization_roles" */
export type Organization_RolesTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** aggregated selection of "organization_roles" */
export type Organization_Roles_Aggregate = {
  __typename?: 'organization_roles_aggregate';
  aggregate?: Maybe<Organization_Roles_Aggregate_Fields>;
  nodes: Array<Organization_Roles>;
};

export type Organization_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Roles_Aggregate_Bool_Exp_Count>;
};

export type Organization_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_roles" */
export type Organization_Roles_Aggregate_Fields = {
  __typename?: 'organization_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_Roles_Max_Fields>;
  min?: Maybe<Organization_Roles_Min_Fields>;
};

/** aggregate fields of "organization_roles" */
export type Organization_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_roles" */
export type Organization_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Roles_Max_Order_By>;
  min?: InputMaybe<Organization_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_roles" */
export type Organization_Roles_Arr_Rel_Insert_Input = {
  data: Array<Organization_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_roles". All fields are combined with a logical 'AND'. */
export type Organization_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Roles_Bool_Exp>>;
  _not?: InputMaybe<Organization_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Roles_Bool_Exp>>;
  assessment_role_details?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
  assessment_role_details_aggregate?: InputMaybe<Assessment_Role_Details_Aggregate_Bool_Exp>;
  assessments?: InputMaybe<Assessment_Bool_Exp>;
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Bool_Exp>;
  career_development_role_details?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
  career_development_role_details_aggregate?: InputMaybe<Career_Development_Role_Details_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  goalsWithRoles?: InputMaybe<GoalsWithRoles_Bool_Exp>;
  goalsWithRoles_aggregate?: InputMaybe<GoalsWithRoles_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpiWithRoles?: InputMaybe<KpiWithRole_Bool_Exp>;
  kpiWithRoles_aggregate?: InputMaybe<KpiWithRole_Aggregate_Bool_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Bool_Exp>;
  profiles?: InputMaybe<Profile_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
  role?: InputMaybe<Auth_Roles_Bool_Exp>;
  role_title?: InputMaybe<String_Comparison_Exp>;
  system_role?: InputMaybe<String_Comparison_Exp>;
  training_role_details?: InputMaybe<Training_Role_Details_Bool_Exp>;
  training_role_details_aggregate?: InputMaybe<Training_Role_Details_Aggregate_Bool_Exp>;
  trainings?: InputMaybe<Training_Bool_Exp>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_roles" */
export enum Organization_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationRolesPkey = 'organization_roles_pkey',
  /** unique or primary key constraint on columns "organization_id", "role_title" */
  OrganizationRolesRoleTitleOrganizationIdKey = 'organization_roles_role_title_organization_id_key',
}

/** input type for inserting data into table "organization_roles" */
export type Organization_Roles_Insert_Input = {
  assessment_role_details?: InputMaybe<Assessment_Role_Details_Arr_Rel_Insert_Input>;
  assessments?: InputMaybe<Assessment_Arr_Rel_Insert_Input>;
  career_development_role_details?: InputMaybe<Career_Development_Role_Details_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  goalsWithRoles?: InputMaybe<GoalsWithRoles_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  kpiWithRoles?: InputMaybe<KpiWithRole_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Arr_Rel_Insert_Input>;
  profiles?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Auth_Roles_Obj_Rel_Insert_Input>;
  role_title?: InputMaybe<Scalars['String']>;
  system_role?: InputMaybe<Scalars['String']>;
  training_role_details?: InputMaybe<Training_Role_Details_Arr_Rel_Insert_Input>;
  trainings?: InputMaybe<Training_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organization_Roles_Max_Fields = {
  __typename?: 'organization_roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_title?: Maybe<Scalars['String']>;
  system_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization_roles" */
export type Organization_Roles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_title?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Roles_Min_Fields = {
  __typename?: 'organization_roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_title?: Maybe<Scalars['String']>;
  system_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization_roles" */
export type Organization_Roles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_title?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_roles" */
export type Organization_Roles_Mutation_Response = {
  __typename?: 'organization_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Roles>;
};

/** input type for inserting object relation for remote table "organization_roles" */
export type Organization_Roles_Obj_Rel_Insert_Input = {
  data: Organization_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Roles_On_Conflict>;
};

/** on_conflict condition type for table "organization_roles" */
export type Organization_Roles_On_Conflict = {
  constraint: Organization_Roles_Constraint;
  update_columns?: Array<Organization_Roles_Update_Column>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_roles". */
export type Organization_Roles_Order_By = {
  assessment_role_details_aggregate?: InputMaybe<Assessment_Role_Details_Aggregate_Order_By>;
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Order_By>;
  career_development_role_details_aggregate?: InputMaybe<Career_Development_Role_Details_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  goalsWithRoles_aggregate?: InputMaybe<GoalsWithRoles_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  kpiWithRoles_aggregate?: InputMaybe<KpiWithRole_Aggregate_Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Order_By>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
  role?: InputMaybe<Auth_Roles_Order_By>;
  role_title?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  training_role_details_aggregate?: InputMaybe<Training_Role_Details_Aggregate_Order_By>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_roles */
export type Organization_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_roles" */
export enum Organization_Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleTitle = 'role_title',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_roles" */
export type Organization_Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  role_title?: InputMaybe<Scalars['String']>;
  system_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization_roles" */
export type Organization_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  role_title?: InputMaybe<Scalars['String']>;
  system_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_roles" */
export enum Organization_Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleTitle = 'role_title',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Roles_Bool_Exp;
};

/** select columns of table "organization" */
export enum Organization_Select_Column {
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  AnniversaryDate = 'anniversary_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HolidayFileId = 'holiday_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastModifiedBy = 'last_modified_by',
  /** column name */
  LogoFileId = 'logo_file_id',
  /** column name */
  OrgEndTime = 'org_end_time',
  /** column name */
  OrgStartTime = 'org_start_time',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  PrivacyPolicies = 'privacy_policies',
  /** column name */
  Slug = 'slug',
  /** column name */
  TermsAndConditions = 'terms_and_conditions',
  /** column name */
  TimezoneName = 'timezone_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkingDays = 'working_days',
  /** column name */
  WorkingHours = 'working_hours',
}

/** input type for updating data in table "organization" */
export type Organization_Set_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address_id?: InputMaybe<Scalars['uuid']>;
  anniversary_date?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  holiday_file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_modified_by?: InputMaybe<Scalars['uuid']>;
  logo_file_id?: InputMaybe<Scalars['uuid']>;
  org_end_time?: InputMaybe<Scalars['timetz']>;
  org_start_time?: InputMaybe<Scalars['timetz']>;
  organization_name?: InputMaybe<Scalars['String']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  privacy_policies?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  terms_and_conditions?: InputMaybe<Scalars['String']>;
  timezone_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  working_days?: InputMaybe<Scalars['Int']>;
  working_hours?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organization_Stddev_Fields = {
  __typename?: 'organization_stddev_fields';
  working_days?: Maybe<Scalars['Float']>;
  working_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization" */
export type Organization_Stddev_Order_By = {
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Stddev_Pop_Fields = {
  __typename?: 'organization_stddev_pop_fields';
  working_days?: Maybe<Scalars['Float']>;
  working_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization" */
export type Organization_Stddev_Pop_Order_By = {
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Stddev_Samp_Fields = {
  __typename?: 'organization_stddev_samp_fields';
  working_days?: Maybe<Scalars['Float']>;
  working_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization" */
export type Organization_Stddev_Samp_Order_By = {
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization" */
export type Organization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Stream_Cursor_Value_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address_id?: InputMaybe<Scalars['uuid']>;
  anniversary_date?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  holiday_file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_modified_by?: InputMaybe<Scalars['uuid']>;
  logo_file_id?: InputMaybe<Scalars['uuid']>;
  org_end_time?: InputMaybe<Scalars['timetz']>;
  org_start_time?: InputMaybe<Scalars['timetz']>;
  organization_name?: InputMaybe<Scalars['String']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  privacy_policies?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  terms_and_conditions?: InputMaybe<Scalars['String']>;
  timezone_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  working_days?: InputMaybe<Scalars['Int']>;
  working_hours?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Organization_Sum_Fields = {
  __typename?: 'organization_sum_fields';
  working_days?: Maybe<Scalars['Int']>;
  working_hours?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization" */
export type Organization_Sum_Order_By = {
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** update columns of table "organization" */
export enum Organization_Update_Column {
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  AnniversaryDate = 'anniversary_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HolidayFileId = 'holiday_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastModifiedBy = 'last_modified_by',
  /** column name */
  LogoFileId = 'logo_file_id',
  /** column name */
  OrgEndTime = 'org_end_time',
  /** column name */
  OrgStartTime = 'org_start_time',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  PrivacyPolicies = 'privacy_policies',
  /** column name */
  Slug = 'slug',
  /** column name */
  TermsAndConditions = 'terms_and_conditions',
  /** column name */
  TimezoneName = 'timezone_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkingDays = 'working_days',
  /** column name */
  WorkingHours = 'working_hours',
}

export type Organization_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Organization_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Organization_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Organization_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Organization_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Organization_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Bool_Exp;
};

/** columns and relationships of "organization_user" */
export type Organization_User = {
  __typename?: 'organization_user';
  /** An object relationship */
  department?: Maybe<Department>;
  department_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  manager?: Maybe<Users>;
  manager_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_title?: Maybe<Scalars['String']>;
  system_role?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "organization_user" */
export type Organization_User_Aggregate = {
  __typename?: 'organization_user_aggregate';
  aggregate?: Maybe<Organization_User_Aggregate_Fields>;
  nodes: Array<Organization_User>;
};

export type Organization_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_User_Aggregate_Bool_Exp_Count>;
};

export type Organization_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_user" */
export type Organization_User_Aggregate_Fields = {
  __typename?: 'organization_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_User_Max_Fields>;
  min?: Maybe<Organization_User_Min_Fields>;
};

/** aggregate fields of "organization_user" */
export type Organization_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_user" */
export type Organization_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_User_Max_Order_By>;
  min?: InputMaybe<Organization_User_Min_Order_By>;
};

export type Organization_User_Approved_Leaves_Args = {
  leave_data_row?: InputMaybe<Scalars['organization_user_leave_data_scalar']>;
};

/** input type for inserting array relation for remote table "organization_user" */
export type Organization_User_Arr_Rel_Insert_Input = {
  data: Array<Organization_User_Insert_Input>;
};

/** Boolean expression to filter rows from the table "organization_user". All fields are combined with a logical 'AND'. */
export type Organization_User_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_User_Bool_Exp>>;
  _not?: InputMaybe<Organization_User_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_User_Bool_Exp>>;
  department?: InputMaybe<Department_Bool_Exp>;
  department_id?: InputMaybe<Uuid_Comparison_Exp>;
  manager?: InputMaybe<Users_Bool_Exp>;
  manager_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  role_title?: InputMaybe<String_Comparison_Exp>;
  system_role?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "organization_user" */
export type Organization_User_Insert_Input = {
  department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  department_id?: InputMaybe<Scalars['uuid']>;
  manager?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  manager_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  role_title?: InputMaybe<Scalars['String']>;
  system_role?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** this table represents the leaves taken by the employees of an organization */
export type Organization_User_Leave_Application = {
  __typename?: 'organization_user_leave_application';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_leave_status_type: Enum_Leave_Status_Type;
  id: Scalars['uuid'];
  is_active?: Maybe<Scalars['Boolean']>;
  is_approved: Scalars['Boolean'];
  /** An array relationship */
  leaveDates: Array<Organization_User_Leave_Dates>;
  /** An aggregate relationship */
  leaveDates_aggregate: Organization_User_Leave_Dates_Aggregate;
  leave_amount_in_days: Scalars['Int'];
  leave_application_status: Enum_Leave_Status_Type_Enum;
  leave_end_date?: Maybe<Scalars['String']>;
  leave_reason?: Maybe<Scalars['String']>;
  leave_start_date?: Maybe<Scalars['String']>;
  leave_type: Scalars['String'];
  leave_type_id?: Maybe<Scalars['uuid']>;
  org_id: Scalars['uuid'];
  /** An object relationship */
  org_leave_type?: Maybe<Organization_Active_Leave_Types>;
  org_user_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  uploaded_files_for_leave: Array<Organization_User_Leave_Files>;
  /** An aggregate relationship */
  uploaded_files_for_leave_aggregate: Organization_User_Leave_Files_Aggregate;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  user_leave_data?: Maybe<Organization_User_Leave_Data>;
  year?: Maybe<Scalars['Int']>;
};

/** this table represents the leaves taken by the employees of an organization */
export type Organization_User_Leave_ApplicationLeaveDatesArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Dates_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
};

/** this table represents the leaves taken by the employees of an organization */
export type Organization_User_Leave_ApplicationLeaveDates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Dates_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
};

/** this table represents the leaves taken by the employees of an organization */
export type Organization_User_Leave_ApplicationUploaded_Files_For_LeaveArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Files_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
};

/** this table represents the leaves taken by the employees of an organization */
export type Organization_User_Leave_ApplicationUploaded_Files_For_Leave_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Organization_User_Leave_Files_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Organization_User_Leave_Files_Order_By>>;
    where?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
  };

/** aggregated selection of "organization_user_leave_application" */
export type Organization_User_Leave_Application_Aggregate = {
  __typename?: 'organization_user_leave_application_aggregate';
  aggregate?: Maybe<Organization_User_Leave_Application_Aggregate_Fields>;
  nodes: Array<Organization_User_Leave_Application>;
};

export type Organization_User_Leave_Application_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organization_User_Leave_Application_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organization_User_Leave_Application_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organization_User_Leave_Application_Aggregate_Bool_Exp_Count>;
};

export type Organization_User_Leave_Application_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organization_User_Leave_Application_Select_Column_Organization_User_Leave_Application_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_User_Leave_Application_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organization_User_Leave_Application_Select_Column_Organization_User_Leave_Application_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_User_Leave_Application_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_user_leave_application" */
export type Organization_User_Leave_Application_Aggregate_Fields = {
  __typename?: 'organization_user_leave_application_aggregate_fields';
  avg?: Maybe<Organization_User_Leave_Application_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organization_User_Leave_Application_Max_Fields>;
  min?: Maybe<Organization_User_Leave_Application_Min_Fields>;
  stddev?: Maybe<Organization_User_Leave_Application_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_User_Leave_Application_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_User_Leave_Application_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_User_Leave_Application_Sum_Fields>;
  var_pop?: Maybe<Organization_User_Leave_Application_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_User_Leave_Application_Var_Samp_Fields>;
  variance?: Maybe<Organization_User_Leave_Application_Variance_Fields>;
};

/** aggregate fields of "organization_user_leave_application" */
export type Organization_User_Leave_Application_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_User_Leave_Application_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_User_Leave_Application_Max_Order_By>;
  min?: InputMaybe<Organization_User_Leave_Application_Min_Order_By>;
  stddev?: InputMaybe<Organization_User_Leave_Application_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_User_Leave_Application_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_User_Leave_Application_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_User_Leave_Application_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_User_Leave_Application_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_User_Leave_Application_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_User_Leave_Application_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Arr_Rel_Insert_Input = {
  data: Array<Organization_User_Leave_Application_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_User_Leave_Application_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_User_Leave_Application_Avg_Fields = {
  __typename?: 'organization_user_leave_application_avg_fields';
  leave_amount_in_days?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Avg_Order_By = {
  leave_amount_in_days?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_user_leave_application". All fields are combined with a logical 'AND'. */
export type Organization_User_Leave_Application_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_User_Leave_Application_Bool_Exp>>;
  _not?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_User_Leave_Application_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_leave_status_type?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_approved?: InputMaybe<Boolean_Comparison_Exp>;
  leaveDates?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
  leaveDates_aggregate?: InputMaybe<Organization_User_Leave_Dates_Aggregate_Bool_Exp>;
  leave_amount_in_days?: InputMaybe<Int_Comparison_Exp>;
  leave_application_status?: InputMaybe<Enum_Leave_Status_Type_Enum_Comparison_Exp>;
  leave_end_date?: InputMaybe<String_Comparison_Exp>;
  leave_reason?: InputMaybe<String_Comparison_Exp>;
  leave_start_date?: InputMaybe<String_Comparison_Exp>;
  leave_type?: InputMaybe<String_Comparison_Exp>;
  leave_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_leave_type?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  org_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaded_files_for_leave?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
  uploaded_files_for_leave_aggregate?: InputMaybe<Organization_User_Leave_Files_Aggregate_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_leave_data?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_user_leave_application" */
export enum Organization_User_Leave_Application_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationLeavesTakenPkey = 'organization_leaves_taken_pkey',
}

/** input type for incrementing numeric columns in table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Inc_Input = {
  leave_amount_in_days?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_leave_status_type?: InputMaybe<Enum_Leave_Status_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  leaveDates?: InputMaybe<Organization_User_Leave_Dates_Arr_Rel_Insert_Input>;
  leave_amount_in_days?: InputMaybe<Scalars['Int']>;
  leave_application_status?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  leave_end_date?: InputMaybe<Scalars['String']>;
  leave_reason?: InputMaybe<Scalars['String']>;
  leave_start_date?: InputMaybe<Scalars['String']>;
  leave_type?: InputMaybe<Scalars['String']>;
  leave_type_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_leave_type?: InputMaybe<Organization_Active_Leave_Types_Obj_Rel_Insert_Input>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_files_for_leave?: InputMaybe<Organization_User_Leave_Files_Arr_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_leave_data?: InputMaybe<Organization_User_Leave_Data_Obj_Rel_Insert_Input>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Organization_User_Leave_Application_Max_Fields = {
  __typename?: 'organization_user_leave_application_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leave_amount_in_days?: Maybe<Scalars['Int']>;
  leave_end_date?: Maybe<Scalars['String']>;
  leave_reason?: Maybe<Scalars['String']>;
  leave_start_date?: Maybe<Scalars['String']>;
  leave_type?: Maybe<Scalars['String']>;
  leave_type_id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  org_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_amount_in_days?: InputMaybe<Order_By>;
  leave_end_date?: InputMaybe<Order_By>;
  leave_reason?: InputMaybe<Order_By>;
  leave_start_date?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  leave_type_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_User_Leave_Application_Min_Fields = {
  __typename?: 'organization_user_leave_application_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leave_amount_in_days?: Maybe<Scalars['Int']>;
  leave_end_date?: Maybe<Scalars['String']>;
  leave_reason?: Maybe<Scalars['String']>;
  leave_start_date?: Maybe<Scalars['String']>;
  leave_type?: Maybe<Scalars['String']>;
  leave_type_id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  org_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_amount_in_days?: InputMaybe<Order_By>;
  leave_end_date?: InputMaybe<Order_By>;
  leave_reason?: InputMaybe<Order_By>;
  leave_start_date?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  leave_type_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Mutation_Response = {
  __typename?: 'organization_user_leave_application_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_User_Leave_Application>;
};

/** input type for inserting object relation for remote table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Obj_Rel_Insert_Input = {
  data: Organization_User_Leave_Application_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_User_Leave_Application_On_Conflict>;
};

/** on_conflict condition type for table "organization_user_leave_application" */
export type Organization_User_Leave_Application_On_Conflict = {
  constraint: Organization_User_Leave_Application_Constraint;
  update_columns?: Array<Organization_User_Leave_Application_Update_Column>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_user_leave_application". */
export type Organization_User_Leave_Application_Order_By = {
  created_at?: InputMaybe<Order_By>;
  enum_leave_status_type?: InputMaybe<Enum_Leave_Status_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_approved?: InputMaybe<Order_By>;
  leaveDates_aggregate?: InputMaybe<Organization_User_Leave_Dates_Aggregate_Order_By>;
  leave_amount_in_days?: InputMaybe<Order_By>;
  leave_application_status?: InputMaybe<Order_By>;
  leave_end_date?: InputMaybe<Order_By>;
  leave_reason?: InputMaybe<Order_By>;
  leave_start_date?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  leave_type_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_leave_type?: InputMaybe<Organization_Active_Leave_Types_Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_files_for_leave_aggregate?: InputMaybe<Organization_User_Leave_Files_Aggregate_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_leave_data?: InputMaybe<Organization_User_Leave_Data_Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_user_leave_application */
export type Organization_User_Leave_Application_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_user_leave_application" */
export enum Organization_User_Leave_Application_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  LeaveAmountInDays = 'leave_amount_in_days',
  /** column name */
  LeaveApplicationStatus = 'leave_application_status',
  /** column name */
  LeaveEndDate = 'leave_end_date',
  /** column name */
  LeaveReason = 'leave_reason',
  /** column name */
  LeaveStartDate = 'leave_start_date',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  LeaveTypeId = 'leave_type_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OrgUserId = 'org_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** select "organization_user_leave_application_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_user_leave_application" */
export enum Organization_User_Leave_Application_Select_Column_Organization_User_Leave_Application_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsApproved = 'is_approved',
}

/** select "organization_user_leave_application_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_user_leave_application" */
export enum Organization_User_Leave_Application_Select_Column_Organization_User_Leave_Application_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsApproved = 'is_approved',
}

/** input type for updating data in table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  leave_amount_in_days?: InputMaybe<Scalars['Int']>;
  leave_application_status?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  leave_end_date?: InputMaybe<Scalars['String']>;
  leave_reason?: InputMaybe<Scalars['String']>;
  leave_start_date?: InputMaybe<Scalars['String']>;
  leave_type?: InputMaybe<Scalars['String']>;
  leave_type_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organization_User_Leave_Application_Stddev_Fields = {
  __typename?: 'organization_user_leave_application_stddev_fields';
  leave_amount_in_days?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Stddev_Order_By = {
  leave_amount_in_days?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_User_Leave_Application_Stddev_Pop_Fields = {
  __typename?: 'organization_user_leave_application_stddev_pop_fields';
  leave_amount_in_days?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Stddev_Pop_Order_By = {
  leave_amount_in_days?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_User_Leave_Application_Stddev_Samp_Fields = {
  __typename?: 'organization_user_leave_application_stddev_samp_fields';
  leave_amount_in_days?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Stddev_Samp_Order_By = {
  leave_amount_in_days?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_User_Leave_Application_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_User_Leave_Application_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  leave_amount_in_days?: InputMaybe<Scalars['Int']>;
  leave_application_status?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  leave_end_date?: InputMaybe<Scalars['String']>;
  leave_reason?: InputMaybe<Scalars['String']>;
  leave_start_date?: InputMaybe<Scalars['String']>;
  leave_type?: InputMaybe<Scalars['String']>;
  leave_type_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Organization_User_Leave_Application_Sum_Fields = {
  __typename?: 'organization_user_leave_application_sum_fields';
  leave_amount_in_days?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Sum_Order_By = {
  leave_amount_in_days?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** update columns of table "organization_user_leave_application" */
export enum Organization_User_Leave_Application_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  LeaveAmountInDays = 'leave_amount_in_days',
  /** column name */
  LeaveApplicationStatus = 'leave_application_status',
  /** column name */
  LeaveEndDate = 'leave_end_date',
  /** column name */
  LeaveReason = 'leave_reason',
  /** column name */
  LeaveStartDate = 'leave_start_date',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  LeaveTypeId = 'leave_type_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OrgUserId = 'org_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

export type Organization_User_Leave_Application_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_User_Leave_Application_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_User_Leave_Application_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_User_Leave_Application_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_User_Leave_Application_Var_Pop_Fields = {
  __typename?: 'organization_user_leave_application_var_pop_fields';
  leave_amount_in_days?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Var_Pop_Order_By = {
  leave_amount_in_days?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_User_Leave_Application_Var_Samp_Fields = {
  __typename?: 'organization_user_leave_application_var_samp_fields';
  leave_amount_in_days?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Var_Samp_Order_By = {
  leave_amount_in_days?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_User_Leave_Application_Variance_Fields = {
  __typename?: 'organization_user_leave_application_variance_fields';
  leave_amount_in_days?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization_user_leave_application" */
export type Organization_User_Leave_Application_Variance_Order_By = {
  leave_amount_in_days?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** this table represents the leave data of organizations users including managers, employees, and org-admin */
export type Organization_User_Leave_Data = {
  __typename?: 'organization_user_leave_data';
  created_at: Scalars['timestamptz'];
  earned_leaves_amount?: Maybe<Scalars['float8']>;
  /** An object relationship */
  followed_leave_cycle?: Maybe<Organization_Leave_Cycles>;
  id: Scalars['uuid'];
  last_year_carry_forward: Scalars['Int'];
  /** An array relationship */
  leave_applications: Array<Organization_User_Leave_Application>;
  /** An aggregate relationship */
  leave_applications_aggregate: Organization_User_Leave_Application_Aggregate;
  leave_cycle_id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  leave_type_data?: Maybe<Organization_Active_Leave_Types>;
  leave_type_id?: Maybe<Scalars['uuid']>;
  org_id: Scalars['uuid'];
  org_user_id: Scalars['uuid'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** A computed field, executes function "organization_user_pending_leaves" */
  pending_leaves?: Maybe<Array<Organization_User_Leave_Application>>;
  /** A computed field, executes function "organization_user_rejected_leaves" */
  rejected_leaves?: Maybe<Array<Organization_User_Leave_Application>>;
  updated_at: Scalars['timestamptz'];
  used_leave_amount: Scalars['Int'];
  /** A computed field, executes function "organization_user_approved_leaves" */
  used_leaves?: Maybe<Array<Organization_User_Leave_Application>>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_role: Scalars['String'];
};

/** this table represents the leave data of organizations users including managers, employees, and org-admin */
export type Organization_User_Leave_DataLeave_ApplicationsArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** this table represents the leave data of organizations users including managers, employees, and org-admin */
export type Organization_User_Leave_DataLeave_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** this table represents the leave data of organizations users including managers, employees, and org-admin */
export type Organization_User_Leave_DataPending_LeavesArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** this table represents the leave data of organizations users including managers, employees, and org-admin */
export type Organization_User_Leave_DataRejected_LeavesArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** this table represents the leave data of organizations users including managers, employees, and org-admin */
export type Organization_User_Leave_DataUsed_LeavesArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** aggregated selection of "organization_user_leave_data" */
export type Organization_User_Leave_Data_Aggregate = {
  __typename?: 'organization_user_leave_data_aggregate';
  aggregate?: Maybe<Organization_User_Leave_Data_Aggregate_Fields>;
  nodes: Array<Organization_User_Leave_Data>;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Organization_User_Leave_Data_Aggregate_Bool_Exp_Var_Samp>;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Avg = {
  arguments: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Corr = {
  arguments: Organization_User_Leave_Data_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_User_Leave_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Organization_User_Leave_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments =
  {
    X: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
    Y: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  };

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Max = {
  arguments: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Min = {
  arguments: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Sum = {
  arguments: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organization_User_Leave_Data_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "organization_user_leave_data" */
export type Organization_User_Leave_Data_Aggregate_Fields = {
  __typename?: 'organization_user_leave_data_aggregate_fields';
  avg?: Maybe<Organization_User_Leave_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organization_User_Leave_Data_Max_Fields>;
  min?: Maybe<Organization_User_Leave_Data_Min_Fields>;
  stddev?: Maybe<Organization_User_Leave_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_User_Leave_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_User_Leave_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_User_Leave_Data_Sum_Fields>;
  var_pop?: Maybe<Organization_User_Leave_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_User_Leave_Data_Var_Samp_Fields>;
  variance?: Maybe<Organization_User_Leave_Data_Variance_Fields>;
};

/** aggregate fields of "organization_user_leave_data" */
export type Organization_User_Leave_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_User_Leave_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_User_Leave_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_User_Leave_Data_Max_Order_By>;
  min?: InputMaybe<Organization_User_Leave_Data_Min_Order_By>;
  stddev?: InputMaybe<Organization_User_Leave_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_User_Leave_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_User_Leave_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_User_Leave_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_User_Leave_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_User_Leave_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_User_Leave_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Arr_Rel_Insert_Input = {
  data: Array<Organization_User_Leave_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_User_Leave_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_User_Leave_Data_Avg_Fields = {
  __typename?: 'organization_user_leave_data_avg_fields';
  earned_leaves_amount?: Maybe<Scalars['Float']>;
  last_year_carry_forward?: Maybe<Scalars['Float']>;
  used_leave_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Avg_Order_By = {
  earned_leaves_amount?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_user_leave_data". All fields are combined with a logical 'AND'. */
export type Organization_User_Leave_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_User_Leave_Data_Bool_Exp>>;
  _not?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_User_Leave_Data_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  earned_leaves_amount?: InputMaybe<Float8_Comparison_Exp>;
  followed_leave_cycle?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_year_carry_forward?: InputMaybe<Int_Comparison_Exp>;
  leave_applications?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  leave_applications_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Bool_Exp>;
  leave_cycle_id?: InputMaybe<Uuid_Comparison_Exp>;
  leave_type?: InputMaybe<String_Comparison_Exp>;
  leave_type_data?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
  leave_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  pending_leaves?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  rejected_leaves?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  used_leave_amount?: InputMaybe<Int_Comparison_Exp>;
  used_leaves?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_role?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationUserLeaveDataPkey = 'organization_user_leave_data_pkey',
}

/** input type for incrementing numeric columns in table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Inc_Input = {
  earned_leaves_amount?: InputMaybe<Scalars['float8']>;
  last_year_carry_forward?: InputMaybe<Scalars['Int']>;
  used_leave_amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  earned_leaves_amount?: InputMaybe<Scalars['float8']>;
  followed_leave_cycle?: InputMaybe<Organization_Leave_Cycles_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  last_year_carry_forward?: InputMaybe<Scalars['Int']>;
  leave_applications?: InputMaybe<Organization_User_Leave_Application_Arr_Rel_Insert_Input>;
  leave_cycle_id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  leave_type_data?: InputMaybe<Organization_Active_Leave_Types_Obj_Rel_Insert_Input>;
  leave_type_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_leave_amount?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_role?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organization_User_Leave_Data_Max_Fields = {
  __typename?: 'organization_user_leave_data_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  earned_leaves_amount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  last_year_carry_forward?: Maybe<Scalars['Int']>;
  leave_cycle_id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  leave_type_id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  org_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_leave_amount?: Maybe<Scalars['Int']>;
  user_role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  earned_leaves_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  leave_cycle_id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  leave_type_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
  user_role?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_User_Leave_Data_Min_Fields = {
  __typename?: 'organization_user_leave_data_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  earned_leaves_amount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  last_year_carry_forward?: Maybe<Scalars['Int']>;
  leave_cycle_id?: Maybe<Scalars['uuid']>;
  leave_type?: Maybe<Scalars['String']>;
  leave_type_id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  org_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_leave_amount?: Maybe<Scalars['Int']>;
  user_role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  earned_leaves_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  leave_cycle_id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  leave_type_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
  user_role?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Mutation_Response = {
  __typename?: 'organization_user_leave_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_User_Leave_Data>;
};

/** input type for inserting object relation for remote table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Obj_Rel_Insert_Input = {
  data: Organization_User_Leave_Data_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_User_Leave_Data_On_Conflict>;
};

/** on_conflict condition type for table "organization_user_leave_data" */
export type Organization_User_Leave_Data_On_Conflict = {
  constraint: Organization_User_Leave_Data_Constraint;
  update_columns?: Array<Organization_User_Leave_Data_Update_Column>;
  where?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_user_leave_data". */
export type Organization_User_Leave_Data_Order_By = {
  created_at?: InputMaybe<Order_By>;
  earned_leaves_amount?: InputMaybe<Order_By>;
  followed_leave_cycle?: InputMaybe<Organization_Leave_Cycles_Order_By>;
  id?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  leave_applications_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Order_By>;
  leave_cycle_id?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  leave_type_data?: InputMaybe<Organization_Active_Leave_Types_Order_By>;
  leave_type_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  pending_leaves_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Order_By>;
  rejected_leaves_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
  used_leaves_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_user_leave_data */
export type Organization_User_Leave_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
  /** column name */
  Id = 'id',
  /** column name */
  LastYearCarryForward = 'last_year_carry_forward',
  /** column name */
  LeaveCycleId = 'leave_cycle_id',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  LeaveTypeId = 'leave_type_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OrgUserId = 'org_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedLeaveAmount = 'used_leave_amount',
  /** column name */
  UserRole = 'user_role',
}

/** select "organization_user_leave_data_aggregate_bool_exp_avg_arguments_columns" columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
}

/** select "organization_user_leave_data_aggregate_bool_exp_corr_arguments_columns" columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
}

/** select "organization_user_leave_data_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
}

/** select "organization_user_leave_data_aggregate_bool_exp_max_arguments_columns" columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
}

/** select "organization_user_leave_data_aggregate_bool_exp_min_arguments_columns" columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
}

/** select "organization_user_leave_data_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
}

/** select "organization_user_leave_data_aggregate_bool_exp_sum_arguments_columns" columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
}

/** select "organization_user_leave_data_aggregate_bool_exp_var_samp_arguments_columns" columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Select_Column_Organization_User_Leave_Data_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
}

/** input type for updating data in table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  earned_leaves_amount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_year_carry_forward?: InputMaybe<Scalars['Int']>;
  leave_cycle_id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  leave_type_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_leave_amount?: InputMaybe<Scalars['Int']>;
  user_role?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organization_User_Leave_Data_Stddev_Fields = {
  __typename?: 'organization_user_leave_data_stddev_fields';
  earned_leaves_amount?: Maybe<Scalars['Float']>;
  last_year_carry_forward?: Maybe<Scalars['Float']>;
  used_leave_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Stddev_Order_By = {
  earned_leaves_amount?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_User_Leave_Data_Stddev_Pop_Fields = {
  __typename?: 'organization_user_leave_data_stddev_pop_fields';
  earned_leaves_amount?: Maybe<Scalars['Float']>;
  last_year_carry_forward?: Maybe<Scalars['Float']>;
  used_leave_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Stddev_Pop_Order_By = {
  earned_leaves_amount?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_User_Leave_Data_Stddev_Samp_Fields = {
  __typename?: 'organization_user_leave_data_stddev_samp_fields';
  earned_leaves_amount?: Maybe<Scalars['Float']>;
  last_year_carry_forward?: Maybe<Scalars['Float']>;
  used_leave_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Stddev_Samp_Order_By = {
  earned_leaves_amount?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_User_Leave_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_User_Leave_Data_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  earned_leaves_amount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_year_carry_forward?: InputMaybe<Scalars['Int']>;
  leave_cycle_id?: InputMaybe<Scalars['uuid']>;
  leave_type?: InputMaybe<Scalars['String']>;
  leave_type_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_leave_amount?: InputMaybe<Scalars['Int']>;
  user_role?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Organization_User_Leave_Data_Sum_Fields = {
  __typename?: 'organization_user_leave_data_sum_fields';
  earned_leaves_amount?: Maybe<Scalars['float8']>;
  last_year_carry_forward?: Maybe<Scalars['Int']>;
  used_leave_amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Sum_Order_By = {
  earned_leaves_amount?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
};

/** update columns of table "organization_user_leave_data" */
export enum Organization_User_Leave_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EarnedLeavesAmount = 'earned_leaves_amount',
  /** column name */
  Id = 'id',
  /** column name */
  LastYearCarryForward = 'last_year_carry_forward',
  /** column name */
  LeaveCycleId = 'leave_cycle_id',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  LeaveTypeId = 'leave_type_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OrgUserId = 'org_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedLeaveAmount = 'used_leave_amount',
  /** column name */
  UserRole = 'user_role',
}

export type Organization_User_Leave_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_User_Leave_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_User_Leave_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_User_Leave_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_User_Leave_Data_Var_Pop_Fields = {
  __typename?: 'organization_user_leave_data_var_pop_fields';
  earned_leaves_amount?: Maybe<Scalars['Float']>;
  last_year_carry_forward?: Maybe<Scalars['Float']>;
  used_leave_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Var_Pop_Order_By = {
  earned_leaves_amount?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_User_Leave_Data_Var_Samp_Fields = {
  __typename?: 'organization_user_leave_data_var_samp_fields';
  earned_leaves_amount?: Maybe<Scalars['Float']>;
  last_year_carry_forward?: Maybe<Scalars['Float']>;
  used_leave_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Var_Samp_Order_By = {
  earned_leaves_amount?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_User_Leave_Data_Variance_Fields = {
  __typename?: 'organization_user_leave_data_variance_fields';
  earned_leaves_amount?: Maybe<Scalars['Float']>;
  last_year_carry_forward?: Maybe<Scalars['Float']>;
  used_leave_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization_user_leave_data" */
export type Organization_User_Leave_Data_Variance_Order_By = {
  earned_leaves_amount?: InputMaybe<Order_By>;
  last_year_carry_forward?: InputMaybe<Order_By>;
  used_leave_amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "organization_user_leave_dates" */
export type Organization_User_Leave_Dates = {
  __typename?: 'organization_user_leave_dates';
  created_at: Scalars['timestamptz'];
  date: Scalars['String'];
  /** A computed field, executes function "leave_date_timestamp" */
  date_timestamp?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  leave_application?: Maybe<Organization_User_Leave_Application>;
  leave_application_id: Scalars['uuid'];
  org_user_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Aggregate = {
  __typename?: 'organization_user_leave_dates_aggregate';
  aggregate?: Maybe<Organization_User_Leave_Dates_Aggregate_Fields>;
  nodes: Array<Organization_User_Leave_Dates>;
};

export type Organization_User_Leave_Dates_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_User_Leave_Dates_Aggregate_Bool_Exp_Count>;
};

export type Organization_User_Leave_Dates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_User_Leave_Dates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Aggregate_Fields = {
  __typename?: 'organization_user_leave_dates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_User_Leave_Dates_Max_Fields>;
  min?: Maybe<Organization_User_Leave_Dates_Min_Fields>;
};

/** aggregate fields of "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_User_Leave_Dates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_User_Leave_Dates_Max_Order_By>;
  min?: InputMaybe<Organization_User_Leave_Dates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Arr_Rel_Insert_Input = {
  data: Array<Organization_User_Leave_Dates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_User_Leave_Dates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_user_leave_dates". All fields are combined with a logical 'AND'. */
export type Organization_User_Leave_Dates_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_User_Leave_Dates_Bool_Exp>>;
  _not?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_User_Leave_Dates_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<String_Comparison_Exp>;
  date_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leave_application?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  leave_application_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_user_leave_dates" */
export enum Organization_User_Leave_Dates_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationUserLeaveDatesPkey = 'organization_user_leave_dates_pkey',
}

/** input type for inserting data into table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_application?: InputMaybe<Organization_User_Leave_Application_Obj_Rel_Insert_Input>;
  leave_application_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organization_User_Leave_Dates_Max_Fields = {
  __typename?: 'organization_user_leave_dates_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  leave_application_id?: Maybe<Scalars['uuid']>;
  org_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_application_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_User_Leave_Dates_Min_Fields = {
  __typename?: 'organization_user_leave_dates_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  leave_application_id?: Maybe<Scalars['uuid']>;
  org_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_application_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Mutation_Response = {
  __typename?: 'organization_user_leave_dates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_User_Leave_Dates>;
};

/** on_conflict condition type for table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_On_Conflict = {
  constraint: Organization_User_Leave_Dates_Constraint;
  update_columns?: Array<Organization_User_Leave_Dates_Update_Column>;
  where?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_user_leave_dates". */
export type Organization_User_Leave_Dates_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  date_timestamp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_application?: InputMaybe<Organization_User_Leave_Application_Order_By>;
  leave_application_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_user_leave_dates */
export type Organization_User_Leave_Dates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_user_leave_dates" */
export enum Organization_User_Leave_Dates_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveApplicationId = 'leave_application_id',
  /** column name */
  OrgUserId = 'org_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_application_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization_user_leave_dates" */
export type Organization_User_Leave_Dates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_User_Leave_Dates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_User_Leave_Dates_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_application_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_user_leave_dates" */
export enum Organization_User_Leave_Dates_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveApplicationId = 'leave_application_id',
  /** column name */
  OrgUserId = 'org_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_User_Leave_Dates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_User_Leave_Dates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_User_Leave_Dates_Bool_Exp;
};

/** this table represents the files uploaded by an employee for a leave */
export type Organization_User_Leave_Files = {
  __typename?: 'organization_user_leave_files';
  created_at: Scalars['timestamptz'];
  file_url: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  leave_application?: Maybe<Organization_User_Leave_Application>;
  leave_applicaton_id: Scalars['uuid'];
  org_id: Scalars['uuid'];
  org_user_id: Scalars['uuid'];
  storage_file_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "organization_user_leave_files" */
export type Organization_User_Leave_Files_Aggregate = {
  __typename?: 'organization_user_leave_files_aggregate';
  aggregate?: Maybe<Organization_User_Leave_Files_Aggregate_Fields>;
  nodes: Array<Organization_User_Leave_Files>;
};

export type Organization_User_Leave_Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_User_Leave_Files_Aggregate_Bool_Exp_Count>;
};

export type Organization_User_Leave_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_User_Leave_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_user_leave_files" */
export type Organization_User_Leave_Files_Aggregate_Fields = {
  __typename?: 'organization_user_leave_files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_User_Leave_Files_Max_Fields>;
  min?: Maybe<Organization_User_Leave_Files_Min_Fields>;
};

/** aggregate fields of "organization_user_leave_files" */
export type Organization_User_Leave_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_User_Leave_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_user_leave_files" */
export type Organization_User_Leave_Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_User_Leave_Files_Max_Order_By>;
  min?: InputMaybe<Organization_User_Leave_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_user_leave_files" */
export type Organization_User_Leave_Files_Arr_Rel_Insert_Input = {
  data: Array<Organization_User_Leave_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_User_Leave_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_user_leave_files". All fields are combined with a logical 'AND'. */
export type Organization_User_Leave_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_User_Leave_Files_Bool_Exp>>;
  _not?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_User_Leave_Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leave_application?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  leave_applicaton_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  storage_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_user_leave_files" */
export enum Organization_User_Leave_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationEmplyeeLeaveFilesPkey = 'organization_emplyee_leave_files_pkey',
}

/** input type for inserting data into table "organization_user_leave_files" */
export type Organization_User_Leave_Files_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_application?: InputMaybe<Organization_User_Leave_Application_Obj_Rel_Insert_Input>;
  leave_applicaton_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organization_User_Leave_Files_Max_Fields = {
  __typename?: 'organization_user_leave_files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  leave_applicaton_id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  org_user_id?: Maybe<Scalars['uuid']>;
  storage_file_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization_user_leave_files" */
export type Organization_User_Leave_Files_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_applicaton_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_User_Leave_Files_Min_Fields = {
  __typename?: 'organization_user_leave_files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  leave_applicaton_id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  org_user_id?: Maybe<Scalars['uuid']>;
  storage_file_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization_user_leave_files" */
export type Organization_User_Leave_Files_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_applicaton_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_user_leave_files" */
export type Organization_User_Leave_Files_Mutation_Response = {
  __typename?: 'organization_user_leave_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_User_Leave_Files>;
};

/** on_conflict condition type for table "organization_user_leave_files" */
export type Organization_User_Leave_Files_On_Conflict = {
  constraint: Organization_User_Leave_Files_Constraint;
  update_columns?: Array<Organization_User_Leave_Files_Update_Column>;
  where?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_user_leave_files". */
export type Organization_User_Leave_Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leave_application?: InputMaybe<Organization_User_Leave_Application_Order_By>;
  leave_applicaton_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  org_user_id?: InputMaybe<Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_user_leave_files */
export type Organization_User_Leave_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_user_leave_files" */
export enum Organization_User_Leave_Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveApplicatonId = 'leave_applicaton_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OrgUserId = 'org_user_id',
  /** column name */
  StorageFileId = 'storage_file_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_user_leave_files" */
export type Organization_User_Leave_Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_applicaton_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization_user_leave_files" */
export type Organization_User_Leave_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_User_Leave_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_User_Leave_Files_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  leave_applicaton_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  org_user_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_user_leave_files" */
export enum Organization_User_Leave_Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveApplicatonId = 'leave_applicaton_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OrgUserId = 'org_user_id',
  /** column name */
  StorageFileId = 'storage_file_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_User_Leave_Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_User_Leave_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_User_Leave_Files_Bool_Exp;
};

/** aggregate max on columns */
export type Organization_User_Max_Fields = {
  __typename?: 'organization_user_max_fields';
  department_id?: Maybe<Scalars['uuid']>;
  manager_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_title?: Maybe<Scalars['String']>;
  system_role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_user" */
export type Organization_User_Max_Order_By = {
  department_id?: InputMaybe<Order_By>;
  manager_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_title?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_User_Min_Fields = {
  __typename?: 'organization_user_min_fields';
  department_id?: Maybe<Scalars['uuid']>;
  manager_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_title?: Maybe<Scalars['String']>;
  system_role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_user" */
export type Organization_User_Min_Order_By = {
  department_id?: InputMaybe<Order_By>;
  manager_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_title?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "organization_user" */
export type Organization_User_Obj_Rel_Insert_Input = {
  data: Organization_User_Insert_Input;
};

/** Ordering options when selecting data from "organization_user". */
export type Organization_User_Order_By = {
  department?: InputMaybe<Department_Order_By>;
  department_id?: InputMaybe<Order_By>;
  manager?: InputMaybe<Users_Order_By>;
  manager_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_title?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Organization_User_Pending_Leaves_Args = {
  leave_data_row?: InputMaybe<Scalars['organization_user_leave_data_scalar']>;
};

export type Organization_User_Rejected_Leaves_Args = {
  leave_data_row?: InputMaybe<Scalars['organization_user_leave_data_scalar']>;
};

/** select columns of table "organization_user" */
export enum Organization_User_Select_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  ManagerId = 'manager_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleTitle = 'role_title',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "organization_user" */
export type Organization_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_User_Stream_Cursor_Value_Input = {
  department_id?: InputMaybe<Scalars['uuid']>;
  manager_id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  role_title?: InputMaybe<Scalars['String']>;
  system_role?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate var_pop on columns */
export type Organization_Var_Pop_Fields = {
  __typename?: 'organization_var_pop_fields';
  working_days?: Maybe<Scalars['Float']>;
  working_hours?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization" */
export type Organization_Var_Pop_Order_By = {
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Var_Samp_Fields = {
  __typename?: 'organization_var_samp_fields';
  working_days?: Maybe<Scalars['Float']>;
  working_hours?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization" */
export type Organization_Var_Samp_Order_By = {
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Variance_Fields = {
  __typename?: 'organization_variance_fields';
  working_days?: Maybe<Scalars['Float']>;
  working_hours?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization" */
export type Organization_Variance_Order_By = {
  working_days?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

export type OrganizationscoreInput = {
  userId: Array<InputMaybe<Scalars['uuid']>>;
};

export type OrganizationscoreOuput = {
  __typename?: 'organizationscoreOuput';
  response: ScoreAssessmentData;
};

export type PerforamanceReviewtimePeriodInputId = {
  performaceReview_Timeperiod_Id: Scalars['uuid'];
};

export type PerformanceReviewPdfInput = {
  id?: InputMaybe<Scalars['uuid']>;
  peerId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

export type PerformanceReviewPdfOutput = {
  __typename?: 'performanceReviewPdfOutput';
  response: Scalars['String'];
};

/** columns and relationships of "performanceReviewStatus" */
export type PerformanceReviewStatus = {
  __typename?: 'performanceReviewStatus';
  /** An object relationship */
  department?: Maybe<Department>;
  department_Id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  organization_Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization_role?: Maybe<Organization_Roles>;
  performance_Id: Scalars['uuid'];
  performance_Period_Id: Scalars['uuid'];
  performance_Review_ByUser_Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  performance_review_byUser?: Maybe<Performance_Review_ByUser>;
  /** An object relationship */
  performance_review_setting: Performance_Review_Settings;
  /** An object relationship */
  performance_review_setting_With_Goal: Performance_Review_Setting_With_Goals;
  /** An object relationship */
  user: Users;
  user_Id: Scalars['uuid'];
};

/** aggregated selection of "performanceReviewStatus" */
export type PerformanceReviewStatus_Aggregate = {
  __typename?: 'performanceReviewStatus_aggregate';
  aggregate?: Maybe<PerformanceReviewStatus_Aggregate_Fields>;
  nodes: Array<PerformanceReviewStatus>;
};

export type PerformanceReviewStatus_Aggregate_Bool_Exp = {
  count?: InputMaybe<PerformanceReviewStatus_Aggregate_Bool_Exp_Count>;
};

export type PerformanceReviewStatus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performanceReviewStatus" */
export type PerformanceReviewStatus_Aggregate_Fields = {
  __typename?: 'performanceReviewStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PerformanceReviewStatus_Max_Fields>;
  min?: Maybe<PerformanceReviewStatus_Min_Fields>;
};

/** aggregate fields of "performanceReviewStatus" */
export type PerformanceReviewStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performanceReviewStatus" */
export type PerformanceReviewStatus_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PerformanceReviewStatus_Max_Order_By>;
  min?: InputMaybe<PerformanceReviewStatus_Min_Order_By>;
};

/** input type for inserting array relation for remote table "performanceReviewStatus" */
export type PerformanceReviewStatus_Arr_Rel_Insert_Input = {
  data: Array<PerformanceReviewStatus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PerformanceReviewStatus_On_Conflict>;
};

/** Boolean expression to filter rows from the table "performanceReviewStatus". All fields are combined with a logical 'AND'. */
export type PerformanceReviewStatus_Bool_Exp = {
  _and?: InputMaybe<Array<PerformanceReviewStatus_Bool_Exp>>;
  _not?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  _or?: InputMaybe<Array<PerformanceReviewStatus_Bool_Exp>>;
  department?: InputMaybe<Department_Bool_Exp>;
  department_Id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_Id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  performance_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_Period_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_Review_ByUser_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "performanceReviewStatus" */
export enum PerformanceReviewStatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceReviewStatusPkey = 'performanceReviewStatus_pkey',
}

/** input type for inserting data into table "performanceReviewStatus" */
export type PerformanceReviewStatus_Insert_Input = {
  department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  department_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_Id?: InputMaybe<Scalars['uuid']>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  performance_Id?: InputMaybe<Scalars['uuid']>;
  performance_Period_Id?: InputMaybe<Scalars['uuid']>;
  performance_Review_ByUser_Id?: InputMaybe<Scalars['uuid']>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Obj_Rel_Insert_Input>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Obj_Rel_Insert_Input>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PerformanceReviewStatus_Max_Fields = {
  __typename?: 'performanceReviewStatus_max_fields';
  department_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_Id?: Maybe<Scalars['uuid']>;
  performance_Id?: Maybe<Scalars['uuid']>;
  performance_Period_Id?: Maybe<Scalars['uuid']>;
  performance_Review_ByUser_Id?: Maybe<Scalars['uuid']>;
  user_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performanceReviewStatus" */
export type PerformanceReviewStatus_Max_Order_By = {
  department_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_Id?: InputMaybe<Order_By>;
  performance_Id?: InputMaybe<Order_By>;
  performance_Period_Id?: InputMaybe<Order_By>;
  performance_Review_ByUser_Id?: InputMaybe<Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PerformanceReviewStatus_Min_Fields = {
  __typename?: 'performanceReviewStatus_min_fields';
  department_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_Id?: Maybe<Scalars['uuid']>;
  performance_Id?: Maybe<Scalars['uuid']>;
  performance_Period_Id?: Maybe<Scalars['uuid']>;
  performance_Review_ByUser_Id?: Maybe<Scalars['uuid']>;
  user_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performanceReviewStatus" */
export type PerformanceReviewStatus_Min_Order_By = {
  department_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_Id?: InputMaybe<Order_By>;
  performance_Id?: InputMaybe<Order_By>;
  performance_Period_Id?: InputMaybe<Order_By>;
  performance_Review_ByUser_Id?: InputMaybe<Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performanceReviewStatus" */
export type PerformanceReviewStatus_Mutation_Response = {
  __typename?: 'performanceReviewStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PerformanceReviewStatus>;
};

/** on_conflict condition type for table "performanceReviewStatus" */
export type PerformanceReviewStatus_On_Conflict = {
  constraint: PerformanceReviewStatus_Constraint;
  update_columns?: Array<PerformanceReviewStatus_Update_Column>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "performanceReviewStatus". */
export type PerformanceReviewStatus_Order_By = {
  department?: InputMaybe<Department_Order_By>;
  department_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_Id?: InputMaybe<Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  performance_Id?: InputMaybe<Order_By>;
  performance_Period_Id?: InputMaybe<Order_By>;
  performance_Review_ByUser_Id?: InputMaybe<Order_By>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Order_By>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Order_By>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: performanceReviewStatus */
export type PerformanceReviewStatus_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performanceReviewStatus" */
export enum PerformanceReviewStatus_Select_Column {
  /** column name */
  DepartmentId = 'department_Id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_Id',
  /** column name */
  PerformanceId = 'performance_Id',
  /** column name */
  PerformancePeriodId = 'performance_Period_Id',
  /** column name */
  PerformanceReviewByUserId = 'performance_Review_ByUser_Id',
  /** column name */
  UserId = 'user_Id',
}

/** input type for updating data in table "performanceReviewStatus" */
export type PerformanceReviewStatus_Set_Input = {
  department_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_Id?: InputMaybe<Scalars['uuid']>;
  performance_Id?: InputMaybe<Scalars['uuid']>;
  performance_Period_Id?: InputMaybe<Scalars['uuid']>;
  performance_Review_ByUser_Id?: InputMaybe<Scalars['uuid']>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "performanceReviewStatus" */
export type PerformanceReviewStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PerformanceReviewStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PerformanceReviewStatus_Stream_Cursor_Value_Input = {
  department_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_Id?: InputMaybe<Scalars['uuid']>;
  performance_Id?: InputMaybe<Scalars['uuid']>;
  performance_Period_Id?: InputMaybe<Scalars['uuid']>;
  performance_Review_ByUser_Id?: InputMaybe<Scalars['uuid']>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "performanceReviewStatus" */
export enum PerformanceReviewStatus_Update_Column {
  /** column name */
  DepartmentId = 'department_Id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_Id',
  /** column name */
  PerformanceId = 'performance_Id',
  /** column name */
  PerformancePeriodId = 'performance_Period_Id',
  /** column name */
  PerformanceReviewByUserId = 'performance_Review_ByUser_Id',
  /** column name */
  UserId = 'user_Id',
}

export type PerformanceReviewStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PerformanceReviewStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: PerformanceReviewStatus_Bool_Exp;
};

/** columns and relationships of "performance_rating_scale" */
export type Performance_Rating_Scale = {
  __typename?: 'performance_rating_scale';
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  performance_review_setting?: Maybe<Performance_Review_Settings>;
  performance_review_setting_id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "performance_rating_scale" */
export type Performance_Rating_Scale_Aggregate = {
  __typename?: 'performance_rating_scale_aggregate';
  aggregate?: Maybe<Performance_Rating_Scale_Aggregate_Fields>;
  nodes: Array<Performance_Rating_Scale>;
};

export type Performance_Rating_Scale_Aggregate_Bool_Exp = {
  count?: InputMaybe<Performance_Rating_Scale_Aggregate_Bool_Exp_Count>;
};

export type Performance_Rating_Scale_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Performance_Rating_Scale_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performance_rating_scale" */
export type Performance_Rating_Scale_Aggregate_Fields = {
  __typename?: 'performance_rating_scale_aggregate_fields';
  avg?: Maybe<Performance_Rating_Scale_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Rating_Scale_Max_Fields>;
  min?: Maybe<Performance_Rating_Scale_Min_Fields>;
  stddev?: Maybe<Performance_Rating_Scale_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Rating_Scale_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Rating_Scale_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Rating_Scale_Sum_Fields>;
  var_pop?: Maybe<Performance_Rating_Scale_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Rating_Scale_Var_Samp_Fields>;
  variance?: Maybe<Performance_Rating_Scale_Variance_Fields>;
};

/** aggregate fields of "performance_rating_scale" */
export type Performance_Rating_Scale_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Performance_Rating_Scale_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_rating_scale" */
export type Performance_Rating_Scale_Aggregate_Order_By = {
  avg?: InputMaybe<Performance_Rating_Scale_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Rating_Scale_Max_Order_By>;
  min?: InputMaybe<Performance_Rating_Scale_Min_Order_By>;
  stddev?: InputMaybe<Performance_Rating_Scale_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Performance_Rating_Scale_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Performance_Rating_Scale_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Performance_Rating_Scale_Sum_Order_By>;
  var_pop?: InputMaybe<Performance_Rating_Scale_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Performance_Rating_Scale_Var_Samp_Order_By>;
  variance?: InputMaybe<Performance_Rating_Scale_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_rating_scale" */
export type Performance_Rating_Scale_Arr_Rel_Insert_Input = {
  data: Array<Performance_Rating_Scale_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Rating_Scale_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Rating_Scale_Avg_Fields = {
  __typename?: 'performance_rating_scale_avg_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_rating_scale". All fields are combined with a logical 'AND'. */
export type Performance_Rating_Scale_Bool_Exp = {
  _and?: InputMaybe<Array<Performance_Rating_Scale_Bool_Exp>>;
  _not?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Rating_Scale_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  performance_review_setting_id?: InputMaybe<Uuid_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_rating_scale" */
export enum Performance_Rating_Scale_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceRatingScalePkey = 'performance_rating_scale_pkey',
}

/** input type for incrementing numeric columns in table "performance_rating_scale" */
export type Performance_Rating_Scale_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "performance_rating_scale" */
export type Performance_Rating_Scale_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Obj_Rel_Insert_Input>;
  performance_review_setting_id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Performance_Rating_Scale_Max_Fields = {
  __typename?: 'performance_rating_scale_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  performance_review_setting_id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  performance_review_setting_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Rating_Scale_Min_Fields = {
  __typename?: 'performance_rating_scale_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  performance_review_setting_id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  performance_review_setting_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_rating_scale" */
export type Performance_Rating_Scale_Mutation_Response = {
  __typename?: 'performance_rating_scale_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Rating_Scale>;
};

/** on_conflict condition type for table "performance_rating_scale" */
export type Performance_Rating_Scale_On_Conflict = {
  constraint: Performance_Rating_Scale_Constraint;
  update_columns?: Array<Performance_Rating_Scale_Update_Column>;
  where?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_rating_scale". */
export type Performance_Rating_Scale_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Order_By>;
  performance_review_setting_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** primary key columns input for table: performance_rating_scale */
export type Performance_Rating_Scale_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_rating_scale" */
export enum Performance_Rating_Scale_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PerformanceReviewSettingId = 'performance_review_setting_id',
  /** column name */
  Rating = 'rating',
}

/** input type for updating data in table "performance_rating_scale" */
export type Performance_Rating_Scale_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  performance_review_setting_id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Performance_Rating_Scale_Stddev_Fields = {
  __typename?: 'performance_rating_scale_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Rating_Scale_Stddev_Pop_Fields = {
  __typename?: 'performance_rating_scale_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Rating_Scale_Stddev_Samp_Fields = {
  __typename?: 'performance_rating_scale_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "performance_rating_scale" */
export type Performance_Rating_Scale_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Rating_Scale_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Rating_Scale_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  performance_review_setting_id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Performance_Rating_Scale_Sum_Fields = {
  __typename?: 'performance_rating_scale_sum_fields';
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "performance_rating_scale" */
export enum Performance_Rating_Scale_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PerformanceReviewSettingId = 'performance_review_setting_id',
  /** column name */
  Rating = 'rating',
}

export type Performance_Rating_Scale_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Performance_Rating_Scale_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Rating_Scale_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Rating_Scale_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Rating_Scale_Var_Pop_Fields = {
  __typename?: 'performance_rating_scale_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Rating_Scale_Var_Samp_Fields = {
  __typename?: 'performance_rating_scale_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Rating_Scale_Variance_Fields = {
  __typename?: 'performance_rating_scale_variance_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_rating_scale" */
export type Performance_Rating_Scale_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "performance_review_byUser" */
export type Performance_Review_ByUser = {
  __typename?: 'performance_review_byUser';
  /** An array relationship */
  User_Peer_Reviews: Array<User_Peer_Review>;
  /** An aggregate relationship */
  User_Peer_Reviews_aggregate: User_Peer_Review_Aggregate;
  calendarId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  calendar_event?: Maybe<Calendar_Event>;
  created_at: Scalars['timestamptz'];
  feedBack?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  meetingStatus?: Maybe<Scalars['String']>;
  peer_Id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  performanceReviewStatuses: Array<PerformanceReviewStatus>;
  /** An aggregate relationship */
  performanceReviewStatuses_aggregate: PerformanceReviewStatus_Aggregate;
  performance_Id: Scalars['uuid'];
  /** An array relationship */
  performance_review_byUser_WithGoals: Array<Performance_Review_ByUser_WithGoal>;
  /** An aggregate relationship */
  performance_review_byUser_WithGoals_aggregate: Performance_Review_ByUser_WithGoal_Aggregate;
  /** An object relationship */
  performance_review_setting: Performance_Review_Settings;
  /** An object relationship */
  performance_review_setting_With_Goal: Performance_Review_Setting_With_Goals;
  performance_timePeriod_Id: Scalars['uuid'];
  progressBar?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  reviewStatus?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByPeerId?: Maybe<Users>;
  user_Id: Scalars['uuid'];
};

/** columns and relationships of "performance_review_byUser" */
export type Performance_Review_ByUserUser_Peer_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

/** columns and relationships of "performance_review_byUser" */
export type Performance_Review_ByUserUser_Peer_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

/** columns and relationships of "performance_review_byUser" */
export type Performance_Review_ByUserPerformanceReviewStatusesArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** columns and relationships of "performance_review_byUser" */
export type Performance_Review_ByUserPerformanceReviewStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** columns and relationships of "performance_review_byUser" */
export type Performance_Review_ByUserPerformance_Review_ByUser_WithGoalsArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_ByUser_WithGoal_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
};

/** columns and relationships of "performance_review_byUser" */
export type Performance_Review_ByUserPerformance_Review_ByUser_WithGoals_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_ByUser_WithGoal_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Order_By>>;
    where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  };

/** columns and relationships of "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi = {
  __typename?: 'performance_review_byUser_Kpi';
  comment?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  kpi_Id: Scalars['uuid'];
  kpi_Mark: Scalars['Int'];
  /** An object relationship */
  kpi_bank: Kpi_Bank;
  /** An object relationship */
  performance_review_byUser_WithGoal: Performance_Review_ByUser_WithGoal;
  performance_review_byUser_WithGoal_Id: Scalars['uuid'];
};

/** aggregated selection of "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Aggregate = {
  __typename?: 'performance_review_byUser_Kpi_aggregate';
  aggregate?: Maybe<Performance_Review_ByUser_Kpi_Aggregate_Fields>;
  nodes: Array<Performance_Review_ByUser_Kpi>;
};

export type Performance_Review_ByUser_Kpi_Aggregate_Bool_Exp = {
  count?: InputMaybe<Performance_Review_ByUser_Kpi_Aggregate_Bool_Exp_Count>;
};

export type Performance_Review_ByUser_Kpi_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Aggregate_Fields = {
  __typename?: 'performance_review_byUser_Kpi_aggregate_fields';
  avg?: Maybe<Performance_Review_ByUser_Kpi_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Review_ByUser_Kpi_Max_Fields>;
  min?: Maybe<Performance_Review_ByUser_Kpi_Min_Fields>;
  stddev?: Maybe<Performance_Review_ByUser_Kpi_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Review_ByUser_Kpi_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Review_ByUser_Kpi_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Review_ByUser_Kpi_Sum_Fields>;
  var_pop?: Maybe<Performance_Review_ByUser_Kpi_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Review_ByUser_Kpi_Var_Samp_Fields>;
  variance?: Maybe<Performance_Review_ByUser_Kpi_Variance_Fields>;
};

/** aggregate fields of "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Aggregate_Order_By = {
  avg?: InputMaybe<Performance_Review_ByUser_Kpi_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Review_ByUser_Kpi_Max_Order_By>;
  min?: InputMaybe<Performance_Review_ByUser_Kpi_Min_Order_By>;
  stddev?: InputMaybe<Performance_Review_ByUser_Kpi_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Performance_Review_ByUser_Kpi_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Performance_Review_ByUser_Kpi_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Performance_Review_ByUser_Kpi_Sum_Order_By>;
  var_pop?: InputMaybe<Performance_Review_ByUser_Kpi_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Performance_Review_ByUser_Kpi_Var_Samp_Order_By>;
  variance?: InputMaybe<Performance_Review_ByUser_Kpi_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Arr_Rel_Insert_Input = {
  data: Array<Performance_Review_ByUser_Kpi_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_ByUser_Kpi_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Review_ByUser_Kpi_Avg_Fields = {
  __typename?: 'performance_review_byUser_Kpi_avg_fields';
  kpi_Mark?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Avg_Order_By = {
  kpi_Mark?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_review_byUser_Kpi". All fields are combined with a logical 'AND'. */
export type Performance_Review_ByUser_Kpi_Bool_Exp = {
  _and?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Bool_Exp>>;
  _not?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_Id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_Mark?: InputMaybe<Int_Comparison_Exp>;
  kpi_bank?: InputMaybe<Kpi_Bank_Bool_Exp>;
  performance_review_byUser_WithGoal?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  performance_review_byUser_WithGoal_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_review_byUser_Kpi" */
export enum Performance_Review_ByUser_Kpi_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceReviewByUserKpiPkey = 'performance_review_byUser_Kpi_pkey',
}

/** input type for incrementing numeric columns in table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Inc_Input = {
  kpi_Mark?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
  kpi_Mark?: InputMaybe<Scalars['Int']>;
  kpi_bank?: InputMaybe<Kpi_Bank_Obj_Rel_Insert_Input>;
  performance_review_byUser_WithGoal?: InputMaybe<Performance_Review_ByUser_WithGoal_Obj_Rel_Insert_Input>;
  performance_review_byUser_WithGoal_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Performance_Review_ByUser_Kpi_Max_Fields = {
  __typename?: 'performance_review_byUser_Kpi_max_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kpi_Id?: Maybe<Scalars['uuid']>;
  kpi_Mark?: Maybe<Scalars['Int']>;
  performance_review_byUser_WithGoal_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
  kpi_Mark?: InputMaybe<Order_By>;
  performance_review_byUser_WithGoal_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Review_ByUser_Kpi_Min_Fields = {
  __typename?: 'performance_review_byUser_Kpi_min_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kpi_Id?: Maybe<Scalars['uuid']>;
  kpi_Mark?: Maybe<Scalars['Int']>;
  performance_review_byUser_WithGoal_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
  kpi_Mark?: InputMaybe<Order_By>;
  performance_review_byUser_WithGoal_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Mutation_Response = {
  __typename?: 'performance_review_byUser_Kpi_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Review_ByUser_Kpi>;
};

/** on_conflict condition type for table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_On_Conflict = {
  constraint: Performance_Review_ByUser_Kpi_Constraint;
  update_columns?: Array<Performance_Review_ByUser_Kpi_Update_Column>;
  where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_review_byUser_Kpi". */
export type Performance_Review_ByUser_Kpi_Order_By = {
  comment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_Id?: InputMaybe<Order_By>;
  kpi_Mark?: InputMaybe<Order_By>;
  kpi_bank?: InputMaybe<Kpi_Bank_Order_By>;
  performance_review_byUser_WithGoal?: InputMaybe<Performance_Review_ByUser_WithGoal_Order_By>;
  performance_review_byUser_WithGoal_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: performance_review_byUser_Kpi */
export type Performance_Review_ByUser_Kpi_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_review_byUser_Kpi" */
export enum Performance_Review_ByUser_Kpi_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  KpiId = 'kpi_Id',
  /** column name */
  KpiMark = 'kpi_Mark',
  /** column name */
  PerformanceReviewByUserWithGoalId = 'performance_review_byUser_WithGoal_Id',
}

/** input type for updating data in table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
  kpi_Mark?: InputMaybe<Scalars['Int']>;
  performance_review_byUser_WithGoal_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Performance_Review_ByUser_Kpi_Stddev_Fields = {
  __typename?: 'performance_review_byUser_Kpi_stddev_fields';
  kpi_Mark?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Stddev_Order_By = {
  kpi_Mark?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Review_ByUser_Kpi_Stddev_Pop_Fields = {
  __typename?: 'performance_review_byUser_Kpi_stddev_pop_fields';
  kpi_Mark?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Stddev_Pop_Order_By = {
  kpi_Mark?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Review_ByUser_Kpi_Stddev_Samp_Fields = {
  __typename?: 'performance_review_byUser_Kpi_stddev_samp_fields';
  kpi_Mark?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Stddev_Samp_Order_By = {
  kpi_Mark?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Review_ByUser_Kpi_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Review_ByUser_Kpi_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_Id?: InputMaybe<Scalars['uuid']>;
  kpi_Mark?: InputMaybe<Scalars['Int']>;
  performance_review_byUser_WithGoal_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Performance_Review_ByUser_Kpi_Sum_Fields = {
  __typename?: 'performance_review_byUser_Kpi_sum_fields';
  kpi_Mark?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Sum_Order_By = {
  kpi_Mark?: InputMaybe<Order_By>;
};

/** update columns of table "performance_review_byUser_Kpi" */
export enum Performance_Review_ByUser_Kpi_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  KpiId = 'kpi_Id',
  /** column name */
  KpiMark = 'kpi_Mark',
  /** column name */
  PerformanceReviewByUserWithGoalId = 'performance_review_byUser_WithGoal_Id',
}

export type Performance_Review_ByUser_Kpi_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Performance_Review_ByUser_Kpi_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Review_ByUser_Kpi_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Review_ByUser_Kpi_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Review_ByUser_Kpi_Var_Pop_Fields = {
  __typename?: 'performance_review_byUser_Kpi_var_pop_fields';
  kpi_Mark?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Var_Pop_Order_By = {
  kpi_Mark?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Review_ByUser_Kpi_Var_Samp_Fields = {
  __typename?: 'performance_review_byUser_Kpi_var_samp_fields';
  kpi_Mark?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Var_Samp_Order_By = {
  kpi_Mark?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Review_ByUser_Kpi_Variance_Fields = {
  __typename?: 'performance_review_byUser_Kpi_variance_fields';
  kpi_Mark?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_review_byUser_Kpi" */
export type Performance_Review_ByUser_Kpi_Variance_Order_By = {
  kpi_Mark?: InputMaybe<Order_By>;
};

/** columns and relationships of "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal = {
  __typename?: 'performance_review_byUser_WithGoal';
  /** An object relationship */
  goal: Goals;
  goal_Id: Scalars['uuid'];
  goal_Marks?: Maybe<Scalars['float8']>;
  id: Scalars['uuid'];
  /** An object relationship */
  performance_review_byUser: Performance_Review_ByUser;
  performance_review_byUser_Id: Scalars['uuid'];
  /** An array relationship */
  performance_review_byUser_Kpis: Array<Performance_Review_ByUser_Kpi>;
  /** An aggregate relationship */
  performance_review_byUser_Kpis_aggregate: Performance_Review_ByUser_Kpi_Aggregate;
};

/** columns and relationships of "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoalPerformance_Review_ByUser_KpisArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_ByUser_Kpi_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Order_By>>;
    where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
  };

/** columns and relationships of "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoalPerformance_Review_ByUser_Kpis_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_ByUser_Kpi_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Order_By>>;
    where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
  };

/** aggregated selection of "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Aggregate = {
  __typename?: 'performance_review_byUser_WithGoal_aggregate';
  aggregate?: Maybe<Performance_Review_ByUser_WithGoal_Aggregate_Fields>;
  nodes: Array<Performance_Review_ByUser_WithGoal>;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Var_Samp>;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Avg = {
  arguments: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Corr = {
  arguments: Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Corr_Arguments =
  {
    X: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Corr_Arguments_Columns;
    Y: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  };

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Performance_Review_ByUser_WithGoal_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Covar_Samp_Arguments =
  {
    X: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
    Y: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  };

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Max = {
  arguments: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Min = {
  arguments: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Stddev_Samp =
  {
    arguments: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
    distinct?: InputMaybe<Scalars['Boolean']>;
    filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
    predicate: Float8_Comparison_Exp;
  };

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Sum = {
  arguments: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Aggregate_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_aggregate_fields';
  avg?: Maybe<Performance_Review_ByUser_WithGoal_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Review_ByUser_WithGoal_Max_Fields>;
  min?: Maybe<Performance_Review_ByUser_WithGoal_Min_Fields>;
  stddev?: Maybe<Performance_Review_ByUser_WithGoal_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Review_ByUser_WithGoal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Review_ByUser_WithGoal_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Review_ByUser_WithGoal_Sum_Fields>;
  var_pop?: Maybe<Performance_Review_ByUser_WithGoal_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Review_ByUser_WithGoal_Var_Samp_Fields>;
  variance?: Maybe<Performance_Review_ByUser_WithGoal_Variance_Fields>;
};

/** aggregate fields of "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Aggregate_Order_By = {
  avg?: InputMaybe<Performance_Review_ByUser_WithGoal_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Review_ByUser_WithGoal_Max_Order_By>;
  min?: InputMaybe<Performance_Review_ByUser_WithGoal_Min_Order_By>;
  stddev?: InputMaybe<Performance_Review_ByUser_WithGoal_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Performance_Review_ByUser_WithGoal_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Performance_Review_ByUser_WithGoal_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Performance_Review_ByUser_WithGoal_Sum_Order_By>;
  var_pop?: InputMaybe<Performance_Review_ByUser_WithGoal_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Performance_Review_ByUser_WithGoal_Var_Samp_Order_By>;
  variance?: InputMaybe<Performance_Review_ByUser_WithGoal_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Arr_Rel_Insert_Input = {
  data: Array<Performance_Review_ByUser_WithGoal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_ByUser_WithGoal_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Review_ByUser_WithGoal_Avg_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_avg_fields';
  goal_Marks?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Avg_Order_By = {
  goal_Marks?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_review_byUser_WithGoal". All fields are combined with a logical 'AND'. */
export type Performance_Review_ByUser_WithGoal_Bool_Exp = {
  _and?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Bool_Exp>>;
  _not?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Bool_Exp>>;
  goal?: InputMaybe<Goals_Bool_Exp>;
  goal_Id?: InputMaybe<Uuid_Comparison_Exp>;
  goal_Marks?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  performance_review_byUser_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_review_byUser_Kpis?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
  performance_review_byUser_Kpis_aggregate?: InputMaybe<Performance_Review_ByUser_Kpi_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceReviewByUserWithGoalPkey = 'performance_review_byUser_WithGoal_pkey',
}

/** input type for incrementing numeric columns in table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Inc_Input = {
  goal_Marks?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Insert_Input = {
  goal?: InputMaybe<Goals_Obj_Rel_Insert_Input>;
  goal_Id?: InputMaybe<Scalars['uuid']>;
  goal_Marks?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Obj_Rel_Insert_Input>;
  performance_review_byUser_Id?: InputMaybe<Scalars['uuid']>;
  performance_review_byUser_Kpis?: InputMaybe<Performance_Review_ByUser_Kpi_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Performance_Review_ByUser_WithGoal_Max_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_max_fields';
  goal_Id?: Maybe<Scalars['uuid']>;
  goal_Marks?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  performance_review_byUser_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Max_Order_By = {
  goal_Id?: InputMaybe<Order_By>;
  goal_Marks?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  performance_review_byUser_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Review_ByUser_WithGoal_Min_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_min_fields';
  goal_Id?: Maybe<Scalars['uuid']>;
  goal_Marks?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  performance_review_byUser_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Min_Order_By = {
  goal_Id?: InputMaybe<Order_By>;
  goal_Marks?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  performance_review_byUser_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Mutation_Response = {
  __typename?: 'performance_review_byUser_WithGoal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Review_ByUser_WithGoal>;
};

/** input type for inserting object relation for remote table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Obj_Rel_Insert_Input = {
  data: Performance_Review_ByUser_WithGoal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_ByUser_WithGoal_On_Conflict>;
};

/** on_conflict condition type for table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_On_Conflict = {
  constraint: Performance_Review_ByUser_WithGoal_Constraint;
  update_columns?: Array<Performance_Review_ByUser_WithGoal_Update_Column>;
  where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_review_byUser_WithGoal". */
export type Performance_Review_ByUser_WithGoal_Order_By = {
  goal?: InputMaybe<Goals_Order_By>;
  goal_Id?: InputMaybe<Order_By>;
  goal_Marks?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  performance_review_byUser?: InputMaybe<Performance_Review_ByUser_Order_By>;
  performance_review_byUser_Id?: InputMaybe<Order_By>;
  performance_review_byUser_Kpis_aggregate?: InputMaybe<Performance_Review_ByUser_Kpi_Aggregate_Order_By>;
};

/** primary key columns input for table: performance_review_byUser_WithGoal */
export type Performance_Review_ByUser_WithGoal_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column {
  /** column name */
  GoalId = 'goal_Id',
  /** column name */
  GoalMarks = 'goal_Marks',
  /** column name */
  Id = 'id',
  /** column name */
  PerformanceReviewByUserId = 'performance_review_byUser_Id',
}

/** select "performance_review_byUser_WithGoal_aggregate_bool_exp_avg_arguments_columns" columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  GoalMarks = 'goal_Marks',
}

/** select "performance_review_byUser_WithGoal_aggregate_bool_exp_corr_arguments_columns" columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  GoalMarks = 'goal_Marks',
}

/** select "performance_review_byUser_WithGoal_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  GoalMarks = 'goal_Marks',
}

/** select "performance_review_byUser_WithGoal_aggregate_bool_exp_max_arguments_columns" columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  GoalMarks = 'goal_Marks',
}

/** select "performance_review_byUser_WithGoal_aggregate_bool_exp_min_arguments_columns" columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  GoalMarks = 'goal_Marks',
}

/** select "performance_review_byUser_WithGoal_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  GoalMarks = 'goal_Marks',
}

/** select "performance_review_byUser_WithGoal_aggregate_bool_exp_sum_arguments_columns" columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  GoalMarks = 'goal_Marks',
}

/** select "performance_review_byUser_WithGoal_aggregate_bool_exp_var_samp_arguments_columns" columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Select_Column_Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  GoalMarks = 'goal_Marks',
}

/** input type for updating data in table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Set_Input = {
  goal_Id?: InputMaybe<Scalars['uuid']>;
  goal_Marks?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  performance_review_byUser_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Performance_Review_ByUser_WithGoal_Stddev_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_stddev_fields';
  goal_Marks?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Stddev_Order_By = {
  goal_Marks?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Review_ByUser_WithGoal_Stddev_Pop_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_stddev_pop_fields';
  goal_Marks?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Stddev_Pop_Order_By = {
  goal_Marks?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Review_ByUser_WithGoal_Stddev_Samp_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_stddev_samp_fields';
  goal_Marks?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Stddev_Samp_Order_By = {
  goal_Marks?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Review_ByUser_WithGoal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Review_ByUser_WithGoal_Stream_Cursor_Value_Input = {
  goal_Id?: InputMaybe<Scalars['uuid']>;
  goal_Marks?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  performance_review_byUser_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Performance_Review_ByUser_WithGoal_Sum_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_sum_fields';
  goal_Marks?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Sum_Order_By = {
  goal_Marks?: InputMaybe<Order_By>;
};

/** update columns of table "performance_review_byUser_WithGoal" */
export enum Performance_Review_ByUser_WithGoal_Update_Column {
  /** column name */
  GoalId = 'goal_Id',
  /** column name */
  GoalMarks = 'goal_Marks',
  /** column name */
  Id = 'id',
  /** column name */
  PerformanceReviewByUserId = 'performance_review_byUser_Id',
}

export type Performance_Review_ByUser_WithGoal_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Performance_Review_ByUser_WithGoal_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Review_ByUser_WithGoal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Review_ByUser_WithGoal_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Review_ByUser_WithGoal_Var_Pop_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_var_pop_fields';
  goal_Marks?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Var_Pop_Order_By = {
  goal_Marks?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Review_ByUser_WithGoal_Var_Samp_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_var_samp_fields';
  goal_Marks?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Var_Samp_Order_By = {
  goal_Marks?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Review_ByUser_WithGoal_Variance_Fields = {
  __typename?: 'performance_review_byUser_WithGoal_variance_fields';
  goal_Marks?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_review_byUser_WithGoal" */
export type Performance_Review_ByUser_WithGoal_Variance_Order_By = {
  goal_Marks?: InputMaybe<Order_By>;
};

/** aggregated selection of "performance_review_byUser" */
export type Performance_Review_ByUser_Aggregate = {
  __typename?: 'performance_review_byUser_aggregate';
  aggregate?: Maybe<Performance_Review_ByUser_Aggregate_Fields>;
  nodes: Array<Performance_Review_ByUser>;
};

export type Performance_Review_ByUser_Aggregate_Bool_Exp = {
  count?: InputMaybe<Performance_Review_ByUser_Aggregate_Bool_Exp_Count>;
};

export type Performance_Review_ByUser_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performance_review_byUser" */
export type Performance_Review_ByUser_Aggregate_Fields = {
  __typename?: 'performance_review_byUser_aggregate_fields';
  avg?: Maybe<Performance_Review_ByUser_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Review_ByUser_Max_Fields>;
  min?: Maybe<Performance_Review_ByUser_Min_Fields>;
  stddev?: Maybe<Performance_Review_ByUser_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Review_ByUser_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Review_ByUser_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Review_ByUser_Sum_Fields>;
  var_pop?: Maybe<Performance_Review_ByUser_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Review_ByUser_Var_Samp_Fields>;
  variance?: Maybe<Performance_Review_ByUser_Variance_Fields>;
};

/** aggregate fields of "performance_review_byUser" */
export type Performance_Review_ByUser_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_review_byUser" */
export type Performance_Review_ByUser_Aggregate_Order_By = {
  avg?: InputMaybe<Performance_Review_ByUser_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Review_ByUser_Max_Order_By>;
  min?: InputMaybe<Performance_Review_ByUser_Min_Order_By>;
  stddev?: InputMaybe<Performance_Review_ByUser_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Performance_Review_ByUser_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Performance_Review_ByUser_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Performance_Review_ByUser_Sum_Order_By>;
  var_pop?: InputMaybe<Performance_Review_ByUser_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Performance_Review_ByUser_Var_Samp_Order_By>;
  variance?: InputMaybe<Performance_Review_ByUser_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_review_byUser" */
export type Performance_Review_ByUser_Arr_Rel_Insert_Input = {
  data: Array<Performance_Review_ByUser_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_ByUser_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Review_ByUser_Avg_Fields = {
  __typename?: 'performance_review_byUser_avg_fields';
  progressBar?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Avg_Order_By = {
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_review_byUser". All fields are combined with a logical 'AND'. */
export type Performance_Review_ByUser_Bool_Exp = {
  User_Peer_Reviews?: InputMaybe<User_Peer_Review_Bool_Exp>;
  User_Peer_Reviews_aggregate?: InputMaybe<User_Peer_Review_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Performance_Review_ByUser_Bool_Exp>>;
  _not?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Review_ByUser_Bool_Exp>>;
  calendarId?: InputMaybe<Uuid_Comparison_Exp>;
  calendar_event?: InputMaybe<Calendar_Event_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feedBack?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meetingStatus?: InputMaybe<String_Comparison_Exp>;
  peer_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Bool_Exp>;
  performance_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_review_byUser_WithGoals?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  performance_review_byUser_WithGoals_aggregate?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Bool_Exp>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  performance_timePeriod_Id?: InputMaybe<Uuid_Comparison_Exp>;
  progressBar?: InputMaybe<Int_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  reviewStatus?: InputMaybe<String_Comparison_Exp>;
  timePeriod?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByPeerId?: InputMaybe<Users_Bool_Exp>;
  user_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_review_byUser" */
export enum Performance_Review_ByUser_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceReviewByUserPkey = 'performance_review_byUser_pkey',
}

/** input type for incrementing numeric columns in table "performance_review_byUser" */
export type Performance_Review_ByUser_Inc_Input = {
  progressBar?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "performance_review_byUser" */
export type Performance_Review_ByUser_Insert_Input = {
  User_Peer_Reviews?: InputMaybe<User_Peer_Review_Arr_Rel_Insert_Input>;
  calendarId?: InputMaybe<Scalars['uuid']>;
  calendar_event?: InputMaybe<Calendar_Event_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedBack?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  meetingStatus?: InputMaybe<Scalars['String']>;
  peer_Id?: InputMaybe<Scalars['uuid']>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Arr_Rel_Insert_Input>;
  performance_Id?: InputMaybe<Scalars['uuid']>;
  performance_review_byUser_WithGoals?: InputMaybe<Performance_Review_ByUser_WithGoal_Arr_Rel_Insert_Input>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Obj_Rel_Insert_Input>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Obj_Rel_Insert_Input>;
  performance_timePeriod_Id?: InputMaybe<Scalars['uuid']>;
  progressBar?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  reviewStatus?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByPeerId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Performance_Review_ByUser_Max_Fields = {
  __typename?: 'performance_review_byUser_max_fields';
  calendarId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  feedBack?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meetingStatus?: Maybe<Scalars['String']>;
  peer_Id?: Maybe<Scalars['uuid']>;
  performance_Id?: Maybe<Scalars['uuid']>;
  performance_timePeriod_Id?: Maybe<Scalars['uuid']>;
  progressBar?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  reviewStatus?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  user_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Max_Order_By = {
  calendarId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedBack?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingStatus?: InputMaybe<Order_By>;
  peer_Id?: InputMaybe<Order_By>;
  performance_Id?: InputMaybe<Order_By>;
  performance_timePeriod_Id?: InputMaybe<Order_By>;
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reviewStatus?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Review_ByUser_Min_Fields = {
  __typename?: 'performance_review_byUser_min_fields';
  calendarId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  feedBack?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meetingStatus?: Maybe<Scalars['String']>;
  peer_Id?: Maybe<Scalars['uuid']>;
  performance_Id?: Maybe<Scalars['uuid']>;
  performance_timePeriod_Id?: Maybe<Scalars['uuid']>;
  progressBar?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  reviewStatus?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  user_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Min_Order_By = {
  calendarId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedBack?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingStatus?: InputMaybe<Order_By>;
  peer_Id?: InputMaybe<Order_By>;
  performance_Id?: InputMaybe<Order_By>;
  performance_timePeriod_Id?: InputMaybe<Order_By>;
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reviewStatus?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_review_byUser" */
export type Performance_Review_ByUser_Mutation_Response = {
  __typename?: 'performance_review_byUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Review_ByUser>;
};

/** input type for inserting object relation for remote table "performance_review_byUser" */
export type Performance_Review_ByUser_Obj_Rel_Insert_Input = {
  data: Performance_Review_ByUser_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_ByUser_On_Conflict>;
};

/** on_conflict condition type for table "performance_review_byUser" */
export type Performance_Review_ByUser_On_Conflict = {
  constraint: Performance_Review_ByUser_Constraint;
  update_columns?: Array<Performance_Review_ByUser_Update_Column>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_review_byUser". */
export type Performance_Review_ByUser_Order_By = {
  User_Peer_Reviews_aggregate?: InputMaybe<User_Peer_Review_Aggregate_Order_By>;
  calendarId?: InputMaybe<Order_By>;
  calendar_event?: InputMaybe<Calendar_Event_Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedBack?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingStatus?: InputMaybe<Order_By>;
  peer_Id?: InputMaybe<Order_By>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Order_By>;
  performance_Id?: InputMaybe<Order_By>;
  performance_review_byUser_WithGoals_aggregate?: InputMaybe<Performance_Review_ByUser_WithGoal_Aggregate_Order_By>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Order_By>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Order_By>;
  performance_timePeriod_Id?: InputMaybe<Order_By>;
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reviewStatus?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByPeerId?: InputMaybe<Users_Order_By>;
  user_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: performance_review_byUser */
export type Performance_Review_ByUser_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_review_byUser" */
export enum Performance_Review_ByUser_Select_Column {
  /** column name */
  CalendarId = 'calendarId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedBack = 'feedBack',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingStatus = 'meetingStatus',
  /** column name */
  PeerId = 'peer_Id',
  /** column name */
  PerformanceId = 'performance_Id',
  /** column name */
  PerformanceTimePeriodId = 'performance_timePeriod_Id',
  /** column name */
  ProgressBar = 'progressBar',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReviewStatus = 'reviewStatus',
  /** column name */
  TimePeriod = 'timePeriod',
  /** column name */
  UserId = 'user_Id',
}

/** input type for updating data in table "performance_review_byUser" */
export type Performance_Review_ByUser_Set_Input = {
  calendarId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedBack?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  meetingStatus?: InputMaybe<Scalars['String']>;
  peer_Id?: InputMaybe<Scalars['uuid']>;
  performance_Id?: InputMaybe<Scalars['uuid']>;
  performance_timePeriod_Id?: InputMaybe<Scalars['uuid']>;
  progressBar?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  reviewStatus?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Performance_Review_ByUser_Stddev_Fields = {
  __typename?: 'performance_review_byUser_stddev_fields';
  progressBar?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Stddev_Order_By = {
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Review_ByUser_Stddev_Pop_Fields = {
  __typename?: 'performance_review_byUser_stddev_pop_fields';
  progressBar?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Stddev_Pop_Order_By = {
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Review_ByUser_Stddev_Samp_Fields = {
  __typename?: 'performance_review_byUser_stddev_samp_fields';
  progressBar?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Stddev_Samp_Order_By = {
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "performance_review_byUser" */
export type Performance_Review_ByUser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Review_ByUser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Review_ByUser_Stream_Cursor_Value_Input = {
  calendarId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedBack?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  meetingStatus?: InputMaybe<Scalars['String']>;
  peer_Id?: InputMaybe<Scalars['uuid']>;
  performance_Id?: InputMaybe<Scalars['uuid']>;
  performance_timePeriod_Id?: InputMaybe<Scalars['uuid']>;
  progressBar?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  reviewStatus?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  user_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Performance_Review_ByUser_Sum_Fields = {
  __typename?: 'performance_review_byUser_sum_fields';
  progressBar?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Sum_Order_By = {
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "performance_review_byUser" */
export enum Performance_Review_ByUser_Update_Column {
  /** column name */
  CalendarId = 'calendarId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedBack = 'feedBack',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingStatus = 'meetingStatus',
  /** column name */
  PeerId = 'peer_Id',
  /** column name */
  PerformanceId = 'performance_Id',
  /** column name */
  PerformanceTimePeriodId = 'performance_timePeriod_Id',
  /** column name */
  ProgressBar = 'progressBar',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReviewStatus = 'reviewStatus',
  /** column name */
  TimePeriod = 'timePeriod',
  /** column name */
  UserId = 'user_Id',
}

export type Performance_Review_ByUser_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Performance_Review_ByUser_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Review_ByUser_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Review_ByUser_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Review_ByUser_Var_Pop_Fields = {
  __typename?: 'performance_review_byUser_var_pop_fields';
  progressBar?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Var_Pop_Order_By = {
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Review_ByUser_Var_Samp_Fields = {
  __typename?: 'performance_review_byUser_var_samp_fields';
  progressBar?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Var_Samp_Order_By = {
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Review_ByUser_Variance_Fields = {
  __typename?: 'performance_review_byUser_variance_fields';
  progressBar?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_review_byUser" */
export type Performance_Review_ByUser_Variance_Order_By = {
  progressBar?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Performance Review With Goal List */
export type Performance_Review_Setting_GoalsList = {
  __typename?: 'performance_review_setting_GoalsList';
  /** An object relationship */
  goal: Goals;
  goal_Id: Scalars['uuid'];
  id: Scalars['uuid'];
  performance_Period_Id: Scalars['uuid'];
  performance_id: Scalars['uuid'];
  /** An object relationship */
  performance_review_setting: Performance_Review_Settings;
  /** An object relationship */
  performance_review_setting_With_Goal: Performance_Review_Setting_With_Goals;
};

/** aggregated selection of "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Aggregate = {
  __typename?: 'performance_review_setting_GoalsList_aggregate';
  aggregate?: Maybe<Performance_Review_Setting_GoalsList_Aggregate_Fields>;
  nodes: Array<Performance_Review_Setting_GoalsList>;
};

export type Performance_Review_Setting_GoalsList_Aggregate_Bool_Exp = {
  count?: InputMaybe<Performance_Review_Setting_GoalsList_Aggregate_Bool_Exp_Count>;
};

export type Performance_Review_Setting_GoalsList_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Performance_Review_Setting_GoalsList_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Aggregate_Fields = {
  __typename?: 'performance_review_setting_GoalsList_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Performance_Review_Setting_GoalsList_Max_Fields>;
  min?: Maybe<Performance_Review_Setting_GoalsList_Min_Fields>;
};

/** aggregate fields of "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Performance_Review_Setting_GoalsList_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Review_Setting_GoalsList_Max_Order_By>;
  min?: InputMaybe<Performance_Review_Setting_GoalsList_Min_Order_By>;
};

/** input type for inserting array relation for remote table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Arr_Rel_Insert_Input = {
  data: Array<Performance_Review_Setting_GoalsList_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_Setting_GoalsList_On_Conflict>;
};

/** Boolean expression to filter rows from the table "performance_review_setting_GoalsList". All fields are combined with a logical 'AND'. */
export type Performance_Review_Setting_GoalsList_Bool_Exp = {
  _and?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Bool_Exp>>;
  _not?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Bool_Exp>>;
  goal?: InputMaybe<Goals_Bool_Exp>;
  goal_Id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_Period_Id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_id?: InputMaybe<Uuid_Comparison_Exp>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
};

/** unique or primary key constraints on table "performance_review_setting_GoalsList" */
export enum Performance_Review_Setting_GoalsList_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceReviewSettingGoalsListPkey = 'performance_review_setting_GoalsList_pkey',
}

/** input type for inserting data into table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Insert_Input = {
  goal?: InputMaybe<Goals_Obj_Rel_Insert_Input>;
  goal_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  performance_Period_Id?: InputMaybe<Scalars['uuid']>;
  performance_id?: InputMaybe<Scalars['uuid']>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Obj_Rel_Insert_Input>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Performance_Review_Setting_GoalsList_Max_Fields = {
  __typename?: 'performance_review_setting_GoalsList_max_fields';
  goal_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  performance_Period_Id?: Maybe<Scalars['uuid']>;
  performance_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Max_Order_By = {
  goal_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  performance_Period_Id?: InputMaybe<Order_By>;
  performance_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Review_Setting_GoalsList_Min_Fields = {
  __typename?: 'performance_review_setting_GoalsList_min_fields';
  goal_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  performance_Period_Id?: Maybe<Scalars['uuid']>;
  performance_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Min_Order_By = {
  goal_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  performance_Period_Id?: InputMaybe<Order_By>;
  performance_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Mutation_Response = {
  __typename?: 'performance_review_setting_GoalsList_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Review_Setting_GoalsList>;
};

/** on_conflict condition type for table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_On_Conflict = {
  constraint: Performance_Review_Setting_GoalsList_Constraint;
  update_columns?: Array<Performance_Review_Setting_GoalsList_Update_Column>;
  where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_review_setting_GoalsList". */
export type Performance_Review_Setting_GoalsList_Order_By = {
  goal?: InputMaybe<Goals_Order_By>;
  goal_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  performance_Period_Id?: InputMaybe<Order_By>;
  performance_id?: InputMaybe<Order_By>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Order_By>;
  performance_review_setting_With_Goal?: InputMaybe<Performance_Review_Setting_With_Goals_Order_By>;
};

/** primary key columns input for table: performance_review_setting_GoalsList */
export type Performance_Review_Setting_GoalsList_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_review_setting_GoalsList" */
export enum Performance_Review_Setting_GoalsList_Select_Column {
  /** column name */
  GoalId = 'goal_Id',
  /** column name */
  Id = 'id',
  /** column name */
  PerformancePeriodId = 'performance_Period_Id',
  /** column name */
  PerformanceId = 'performance_id',
}

/** input type for updating data in table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Set_Input = {
  goal_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  performance_Period_Id?: InputMaybe<Scalars['uuid']>;
  performance_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "performance_review_setting_GoalsList" */
export type Performance_Review_Setting_GoalsList_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Review_Setting_GoalsList_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Review_Setting_GoalsList_Stream_Cursor_Value_Input = {
  goal_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  performance_Period_Id?: InputMaybe<Scalars['uuid']>;
  performance_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "performance_review_setting_GoalsList" */
export enum Performance_Review_Setting_GoalsList_Update_Column {
  /** column name */
  GoalId = 'goal_Id',
  /** column name */
  Id = 'id',
  /** column name */
  PerformancePeriodId = 'performance_Period_Id',
  /** column name */
  PerformanceId = 'performance_id',
}

export type Performance_Review_Setting_GoalsList_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Review_Setting_GoalsList_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Review_Setting_GoalsList_Bool_Exp;
};

/** Performance Review Setting With Time Period */
export type Performance_Review_Setting_With_Goals = {
  __typename?: 'performance_review_setting_With_Goals';
  action: Scalars['String'];
  dueDate: Scalars['date'];
  endingDate?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  kpi_option?: Maybe<Scalars['Int']>;
  /** An array relationship */
  performanceReviewStatuses: Array<PerformanceReviewStatus>;
  /** An aggregate relationship */
  performanceReviewStatuses_aggregate: PerformanceReviewStatus_Aggregate;
  /** An array relationship */
  performance_review_byUsers: Array<Performance_Review_ByUser>;
  /** An aggregate relationship */
  performance_review_byUsers_aggregate: Performance_Review_ByUser_Aggregate;
  /** An object relationship */
  performance_review_setting: Performance_Review_Settings;
  /** An array relationship */
  performance_review_setting_GoalsLists: Array<Performance_Review_Setting_GoalsList>;
  /** An aggregate relationship */
  performance_review_setting_GoalsLists_aggregate: Performance_Review_Setting_GoalsList_Aggregate;
  preformance_Id: Scalars['uuid'];
  startDate: Scalars['date'];
  startingDate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  timePeriod: Scalars['String'];
  title: Scalars['String'];
  viewScoreOption?: Maybe<Scalars['String']>;
  year: Scalars['String'];
};

/** Performance Review Setting With Time Period */
export type Performance_Review_Setting_With_GoalsPerformanceReviewStatusesArgs =
  {
    distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
    where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  };

/** Performance Review Setting With Time Period */
export type Performance_Review_Setting_With_GoalsPerformanceReviewStatuses_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
    where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  };

/** Performance Review Setting With Time Period */
export type Performance_Review_Setting_With_GoalsPerformance_Review_ByUsersArgs =
  {
    distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
    where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  };

/** Performance Review Setting With Time Period */
export type Performance_Review_Setting_With_GoalsPerformance_Review_ByUsers_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
    where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  };

/** Performance Review Setting With Time Period */
export type Performance_Review_Setting_With_GoalsPerformance_Review_Setting_GoalsListsArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_Setting_GoalsList_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
    where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  };

/** Performance Review Setting With Time Period */
export type Performance_Review_Setting_With_GoalsPerformance_Review_Setting_GoalsLists_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_Setting_GoalsList_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
    where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  };

/** aggregated selection of "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Aggregate = {
  __typename?: 'performance_review_setting_With_Goals_aggregate';
  aggregate?: Maybe<Performance_Review_Setting_With_Goals_Aggregate_Fields>;
  nodes: Array<Performance_Review_Setting_With_Goals>;
};

export type Performance_Review_Setting_With_Goals_Aggregate_Bool_Exp = {
  count?: InputMaybe<Performance_Review_Setting_With_Goals_Aggregate_Bool_Exp_Count>;
};

export type Performance_Review_Setting_With_Goals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Performance_Review_Setting_With_Goals_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Aggregate_Fields = {
  __typename?: 'performance_review_setting_With_Goals_aggregate_fields';
  avg?: Maybe<Performance_Review_Setting_With_Goals_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Performance_Review_Setting_With_Goals_Max_Fields>;
  min?: Maybe<Performance_Review_Setting_With_Goals_Min_Fields>;
  stddev?: Maybe<Performance_Review_Setting_With_Goals_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Review_Setting_With_Goals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Review_Setting_With_Goals_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Review_Setting_With_Goals_Sum_Fields>;
  var_pop?: Maybe<Performance_Review_Setting_With_Goals_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Review_Setting_With_Goals_Var_Samp_Fields>;
  variance?: Maybe<Performance_Review_Setting_With_Goals_Variance_Fields>;
};

/** aggregate fields of "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Performance_Review_Setting_With_Goals_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Aggregate_Order_By = {
  avg?: InputMaybe<Performance_Review_Setting_With_Goals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Review_Setting_With_Goals_Max_Order_By>;
  min?: InputMaybe<Performance_Review_Setting_With_Goals_Min_Order_By>;
  stddev?: InputMaybe<Performance_Review_Setting_With_Goals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Performance_Review_Setting_With_Goals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Performance_Review_Setting_With_Goals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Performance_Review_Setting_With_Goals_Sum_Order_By>;
  var_pop?: InputMaybe<Performance_Review_Setting_With_Goals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Performance_Review_Setting_With_Goals_Var_Samp_Order_By>;
  variance?: InputMaybe<Performance_Review_Setting_With_Goals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Arr_Rel_Insert_Input = {
  data: Array<Performance_Review_Setting_With_Goals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_Setting_With_Goals_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Review_Setting_With_Goals_Avg_Fields = {
  __typename?: 'performance_review_setting_With_Goals_avg_fields';
  kpi_option?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Avg_Order_By = {
  kpi_option?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_review_setting_With_Goals". All fields are combined with a logical 'AND'. */
export type Performance_Review_Setting_With_Goals_Bool_Exp = {
  _and?: InputMaybe<Array<Performance_Review_Setting_With_Goals_Bool_Exp>>;
  _not?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Review_Setting_With_Goals_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  dueDate?: InputMaybe<Date_Comparison_Exp>;
  endingDate?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_option?: InputMaybe<Int_Comparison_Exp>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Bool_Exp>;
  performance_review_byUsers?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  performance_review_byUsers_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Bool_Exp>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  performance_review_setting_GoalsLists?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  performance_review_setting_GoalsLists_aggregate?: InputMaybe<Performance_Review_Setting_GoalsList_Aggregate_Bool_Exp>;
  preformance_Id?: InputMaybe<Uuid_Comparison_Exp>;
  startDate?: InputMaybe<Date_Comparison_Exp>;
  startingDate?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  timePeriod?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  viewScoreOption?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_review_setting_With_Goals" */
export enum Performance_Review_Setting_With_Goals_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceReviewSettingWithGoalsPkey = 'performance_review_setting_With_Goals_pkey',
}

/** input type for incrementing numeric columns in table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Inc_Input = {
  kpi_option?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['date']>;
  endingDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_option?: InputMaybe<Scalars['Int']>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Arr_Rel_Insert_Input>;
  performance_review_byUsers?: InputMaybe<Performance_Review_ByUser_Arr_Rel_Insert_Input>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Obj_Rel_Insert_Input>;
  performance_review_setting_GoalsLists?: InputMaybe<Performance_Review_Setting_GoalsList_Arr_Rel_Insert_Input>;
  preformance_Id?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['date']>;
  startingDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  viewScoreOption?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Performance_Review_Setting_With_Goals_Max_Fields = {
  __typename?: 'performance_review_setting_With_Goals_max_fields';
  action?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['date']>;
  endingDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kpi_option?: Maybe<Scalars['Int']>;
  preformance_Id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  startingDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  viewScoreOption?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  endingDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_option?: InputMaybe<Order_By>;
  preformance_Id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  startingDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  viewScoreOption?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Review_Setting_With_Goals_Min_Fields = {
  __typename?: 'performance_review_setting_With_Goals_min_fields';
  action?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['date']>;
  endingDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kpi_option?: Maybe<Scalars['Int']>;
  preformance_Id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  startingDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timePeriod?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  viewScoreOption?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  endingDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_option?: InputMaybe<Order_By>;
  preformance_Id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  startingDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  viewScoreOption?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Mutation_Response = {
  __typename?: 'performance_review_setting_With_Goals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Review_Setting_With_Goals>;
};

/** input type for inserting object relation for remote table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Obj_Rel_Insert_Input = {
  data: Performance_Review_Setting_With_Goals_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_Setting_With_Goals_On_Conflict>;
};

/** on_conflict condition type for table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_On_Conflict = {
  constraint: Performance_Review_Setting_With_Goals_Constraint;
  update_columns?: Array<Performance_Review_Setting_With_Goals_Update_Column>;
  where?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_review_setting_With_Goals". */
export type Performance_Review_Setting_With_Goals_Order_By = {
  action?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  endingDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_option?: InputMaybe<Order_By>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Order_By>;
  performance_review_byUsers_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Order_By>;
  performance_review_setting?: InputMaybe<Performance_Review_Settings_Order_By>;
  performance_review_setting_GoalsLists_aggregate?: InputMaybe<Performance_Review_Setting_GoalsList_Aggregate_Order_By>;
  preformance_Id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  startingDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  viewScoreOption?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: performance_review_setting_With_Goals */
export type Performance_Review_Setting_With_Goals_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_review_setting_With_Goals" */
export enum Performance_Review_Setting_With_Goals_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  EndingDate = 'endingDate',
  /** column name */
  Id = 'id',
  /** column name */
  KpiOption = 'kpi_option',
  /** column name */
  PreformanceId = 'preformance_Id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartingDate = 'startingDate',
  /** column name */
  Status = 'status',
  /** column name */
  TimePeriod = 'timePeriod',
  /** column name */
  Title = 'title',
  /** column name */
  ViewScoreOption = 'viewScoreOption',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['date']>;
  endingDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_option?: InputMaybe<Scalars['Int']>;
  preformance_Id?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['date']>;
  startingDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  viewScoreOption?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Performance_Review_Setting_With_Goals_Stddev_Fields = {
  __typename?: 'performance_review_setting_With_Goals_stddev_fields';
  kpi_option?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Stddev_Order_By = {
  kpi_option?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Review_Setting_With_Goals_Stddev_Pop_Fields = {
  __typename?: 'performance_review_setting_With_Goals_stddev_pop_fields';
  kpi_option?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Stddev_Pop_Order_By = {
  kpi_option?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Review_Setting_With_Goals_Stddev_Samp_Fields = {
  __typename?: 'performance_review_setting_With_Goals_stddev_samp_fields';
  kpi_option?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Stddev_Samp_Order_By = {
  kpi_option?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Review_Setting_With_Goals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Review_Setting_With_Goals_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['date']>;
  endingDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_option?: InputMaybe<Scalars['Int']>;
  preformance_Id?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['date']>;
  startingDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  timePeriod?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  viewScoreOption?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Performance_Review_Setting_With_Goals_Sum_Fields = {
  __typename?: 'performance_review_setting_With_Goals_sum_fields';
  kpi_option?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Sum_Order_By = {
  kpi_option?: InputMaybe<Order_By>;
};

/** update columns of table "performance_review_setting_With_Goals" */
export enum Performance_Review_Setting_With_Goals_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  EndingDate = 'endingDate',
  /** column name */
  Id = 'id',
  /** column name */
  KpiOption = 'kpi_option',
  /** column name */
  PreformanceId = 'preformance_Id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartingDate = 'startingDate',
  /** column name */
  Status = 'status',
  /** column name */
  TimePeriod = 'timePeriod',
  /** column name */
  Title = 'title',
  /** column name */
  ViewScoreOption = 'viewScoreOption',
  /** column name */
  Year = 'year',
}

export type Performance_Review_Setting_With_Goals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Performance_Review_Setting_With_Goals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Review_Setting_With_Goals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Review_Setting_With_Goals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Review_Setting_With_Goals_Var_Pop_Fields = {
  __typename?: 'performance_review_setting_With_Goals_var_pop_fields';
  kpi_option?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Var_Pop_Order_By = {
  kpi_option?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Review_Setting_With_Goals_Var_Samp_Fields = {
  __typename?: 'performance_review_setting_With_Goals_var_samp_fields';
  kpi_option?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Var_Samp_Order_By = {
  kpi_option?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Review_Setting_With_Goals_Variance_Fields = {
  __typename?: 'performance_review_setting_With_Goals_variance_fields';
  kpi_option?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "performance_review_setting_With_Goals" */
export type Performance_Review_Setting_With_Goals_Variance_Order_By = {
  kpi_option?: InputMaybe<Order_By>;
};

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_Settings = {
  __typename?: 'performance_review_settings';
  copy_previews_performance_goals?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  endDate?: Maybe<Scalars['String']>;
  frequency: Scalars['String'];
  id: Scalars['uuid'];
  instruction: Scalars['String'];
  manager_review?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org_goal_share?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization: Organization;
  organization_Id: Scalars['uuid'];
  peer_review?: Maybe<Scalars['String']>;
  /** An array relationship */
  performanceReviewStatuses: Array<PerformanceReviewStatus>;
  /** An aggregate relationship */
  performanceReviewStatuses_aggregate: PerformanceReviewStatus_Aggregate;
  /** An array relationship */
  performance_rating_scales: Array<Performance_Rating_Scale>;
  /** An aggregate relationship */
  performance_rating_scales_aggregate: Performance_Rating_Scale_Aggregate;
  /** An array relationship */
  performance_review_byUsers: Array<Performance_Review_ByUser>;
  /** An aggregate relationship */
  performance_review_byUsers_aggregate: Performance_Review_ByUser_Aggregate;
  /** An array relationship */
  performance_review_setting_GoalsLists: Array<Performance_Review_Setting_GoalsList>;
  /** An aggregate relationship */
  performance_review_setting_GoalsLists_aggregate: Performance_Review_Setting_GoalsList_Aggregate;
  /** An array relationship */
  performance_review_setting_With_Goals: Array<Performance_Review_Setting_With_Goals>;
  /** An aggregate relationship */
  performance_review_setting_With_Goals_aggregate: Performance_Review_Setting_With_Goals_Aggregate;
  self_review?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  view_option?: Maybe<Scalars['Boolean']>;
  year: Scalars['String'];
};

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformanceReviewStatusesArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformanceReviewStatuses_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
    where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  };

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformance_Rating_ScalesArgs = {
  distinct_on?: InputMaybe<Array<Performance_Rating_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Rating_Scale_Order_By>>;
  where?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
};

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformance_Rating_Scales_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Performance_Rating_Scale_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Rating_Scale_Order_By>>;
    where?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
  };

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformance_Review_ByUsersArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformance_Review_ByUsers_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
    where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  };

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformance_Review_Setting_GoalsListsArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_Setting_GoalsList_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
    where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  };

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformance_Review_Setting_GoalsLists_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_Setting_GoalsList_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
    where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  };

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformance_Review_Setting_With_GoalsArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_Setting_With_Goals_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<
      Array<Performance_Review_Setting_With_Goals_Order_By>
    >;
    where?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  };

/** columns and relationships of "performance_review_settings" */
export type Performance_Review_SettingsPerformance_Review_Setting_With_Goals_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_Setting_With_Goals_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<
      Array<Performance_Review_Setting_With_Goals_Order_By>
    >;
    where?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  };

/** aggregated selection of "performance_review_settings" */
export type Performance_Review_Settings_Aggregate = {
  __typename?: 'performance_review_settings_aggregate';
  aggregate?: Maybe<Performance_Review_Settings_Aggregate_Fields>;
  nodes: Array<Performance_Review_Settings>;
};

export type Performance_Review_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Performance_Review_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Performance_Review_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Performance_Review_Settings_Aggregate_Bool_Exp_Count>;
};

export type Performance_Review_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Performance_Review_Settings_Select_Column_Performance_Review_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Performance_Review_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Performance_Review_Settings_Select_Column_Performance_Review_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Performance_Review_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Performance_Review_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performance_review_settings" */
export type Performance_Review_Settings_Aggregate_Fields = {
  __typename?: 'performance_review_settings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Performance_Review_Settings_Max_Fields>;
  min?: Maybe<Performance_Review_Settings_Min_Fields>;
};

/** aggregate fields of "performance_review_settings" */
export type Performance_Review_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Performance_Review_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "performance_review_settings" */
export type Performance_Review_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Review_Settings_Max_Order_By>;
  min?: InputMaybe<Performance_Review_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "performance_review_settings" */
export type Performance_Review_Settings_Arr_Rel_Insert_Input = {
  data: Array<Performance_Review_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "performance_review_settings". All fields are combined with a logical 'AND'. */
export type Performance_Review_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Performance_Review_Settings_Bool_Exp>>;
  _not?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Review_Settings_Bool_Exp>>;
  copy_previews_performance_goals?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  endDate?: InputMaybe<String_Comparison_Exp>;
  frequency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instruction?: InputMaybe<String_Comparison_Exp>;
  manager_review?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_goal_share?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_Id?: InputMaybe<Uuid_Comparison_Exp>;
  peer_review?: InputMaybe<String_Comparison_Exp>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Bool_Exp>;
  performance_rating_scales?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
  performance_rating_scales_aggregate?: InputMaybe<Performance_Rating_Scale_Aggregate_Bool_Exp>;
  performance_review_byUsers?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  performance_review_byUsers_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Bool_Exp>;
  performance_review_setting_GoalsLists?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  performance_review_setting_GoalsLists_aggregate?: InputMaybe<Performance_Review_Setting_GoalsList_Aggregate_Bool_Exp>;
  performance_review_setting_With_Goals?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  performance_review_setting_With_Goals_aggregate?: InputMaybe<Performance_Review_Setting_With_Goals_Aggregate_Bool_Exp>;
  self_review?: InputMaybe<String_Comparison_Exp>;
  startDate?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  view_option?: InputMaybe<Boolean_Comparison_Exp>;
  year?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_review_settings" */
export enum Performance_Review_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceReviewSettingsPkey = 'performance_review_settings_pkey',
}

/** input type for inserting data into table "performance_review_settings" */
export type Performance_Review_Settings_Insert_Input = {
  copy_previews_performance_goals?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDate?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instruction?: InputMaybe<Scalars['String']>;
  manager_review?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  org_goal_share?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_Id?: InputMaybe<Scalars['uuid']>;
  peer_review?: InputMaybe<Scalars['String']>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Arr_Rel_Insert_Input>;
  performance_rating_scales?: InputMaybe<Performance_Rating_Scale_Arr_Rel_Insert_Input>;
  performance_review_byUsers?: InputMaybe<Performance_Review_ByUser_Arr_Rel_Insert_Input>;
  performance_review_setting_GoalsLists?: InputMaybe<Performance_Review_Setting_GoalsList_Arr_Rel_Insert_Input>;
  performance_review_setting_With_Goals?: InputMaybe<Performance_Review_Setting_With_Goals_Arr_Rel_Insert_Input>;
  self_review?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  view_option?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Performance_Review_Settings_Max_Fields = {
  __typename?: 'performance_review_settings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instruction?: Maybe<Scalars['String']>;
  manager_review?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org_goal_share?: Maybe<Scalars['String']>;
  organization_Id?: Maybe<Scalars['uuid']>;
  peer_review?: Maybe<Scalars['String']>;
  self_review?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "performance_review_settings" */
export type Performance_Review_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instruction?: InputMaybe<Order_By>;
  manager_review?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_goal_share?: InputMaybe<Order_By>;
  organization_Id?: InputMaybe<Order_By>;
  peer_review?: InputMaybe<Order_By>;
  self_review?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Review_Settings_Min_Fields = {
  __typename?: 'performance_review_settings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instruction?: Maybe<Scalars['String']>;
  manager_review?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org_goal_share?: Maybe<Scalars['String']>;
  organization_Id?: Maybe<Scalars['uuid']>;
  peer_review?: Maybe<Scalars['String']>;
  self_review?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "performance_review_settings" */
export type Performance_Review_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instruction?: InputMaybe<Order_By>;
  manager_review?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_goal_share?: InputMaybe<Order_By>;
  organization_Id?: InputMaybe<Order_By>;
  peer_review?: InputMaybe<Order_By>;
  self_review?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_review_settings" */
export type Performance_Review_Settings_Mutation_Response = {
  __typename?: 'performance_review_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Review_Settings>;
};

/** input type for inserting object relation for remote table "performance_review_settings" */
export type Performance_Review_Settings_Obj_Rel_Insert_Input = {
  data: Performance_Review_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Review_Settings_On_Conflict>;
};

/** on_conflict condition type for table "performance_review_settings" */
export type Performance_Review_Settings_On_Conflict = {
  constraint: Performance_Review_Settings_Constraint;
  update_columns?: Array<Performance_Review_Settings_Update_Column>;
  where?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_review_settings". */
export type Performance_Review_Settings_Order_By = {
  copy_previews_performance_goals?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instruction?: InputMaybe<Order_By>;
  manager_review?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_goal_share?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_Id?: InputMaybe<Order_By>;
  peer_review?: InputMaybe<Order_By>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Order_By>;
  performance_rating_scales_aggregate?: InputMaybe<Performance_Rating_Scale_Aggregate_Order_By>;
  performance_review_byUsers_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Order_By>;
  performance_review_setting_GoalsLists_aggregate?: InputMaybe<Performance_Review_Setting_GoalsList_Aggregate_Order_By>;
  performance_review_setting_With_Goals_aggregate?: InputMaybe<Performance_Review_Setting_With_Goals_Aggregate_Order_By>;
  self_review?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  view_option?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: performance_review_settings */
export type Performance_Review_Settings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "performance_review_settings" */
export enum Performance_Review_Settings_Select_Column {
  /** column name */
  CopyPreviewsPerformanceGoals = 'copy_previews_performance_goals',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Instruction = 'instruction',
  /** column name */
  ManagerReview = 'manager_review',
  /** column name */
  Name = 'name',
  /** column name */
  OrgGoalShare = 'org_goal_share',
  /** column name */
  OrganizationId = 'organization_Id',
  /** column name */
  PeerReview = 'peer_review',
  /** column name */
  SelfReview = 'self_review',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ViewOption = 'view_option',
  /** column name */
  Year = 'year',
}

/** select "performance_review_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "performance_review_settings" */
export enum Performance_Review_Settings_Select_Column_Performance_Review_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CopyPreviewsPerformanceGoals = 'copy_previews_performance_goals',
  /** column name */
  ViewOption = 'view_option',
}

/** select "performance_review_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "performance_review_settings" */
export enum Performance_Review_Settings_Select_Column_Performance_Review_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CopyPreviewsPerformanceGoals = 'copy_previews_performance_goals',
  /** column name */
  ViewOption = 'view_option',
}

/** input type for updating data in table "performance_review_settings" */
export type Performance_Review_Settings_Set_Input = {
  copy_previews_performance_goals?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDate?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instruction?: InputMaybe<Scalars['String']>;
  manager_review?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  org_goal_share?: InputMaybe<Scalars['String']>;
  organization_Id?: InputMaybe<Scalars['uuid']>;
  peer_review?: InputMaybe<Scalars['String']>;
  self_review?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  view_option?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "performance_review_settings" */
export type Performance_Review_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Review_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Review_Settings_Stream_Cursor_Value_Input = {
  copy_previews_performance_goals?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDate?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instruction?: InputMaybe<Scalars['String']>;
  manager_review?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  org_goal_share?: InputMaybe<Scalars['String']>;
  organization_Id?: InputMaybe<Scalars['uuid']>;
  peer_review?: InputMaybe<Scalars['String']>;
  self_review?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  view_option?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['String']>;
};

/** update columns of table "performance_review_settings" */
export enum Performance_Review_Settings_Update_Column {
  /** column name */
  CopyPreviewsPerformanceGoals = 'copy_previews_performance_goals',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Instruction = 'instruction',
  /** column name */
  ManagerReview = 'manager_review',
  /** column name */
  Name = 'name',
  /** column name */
  OrgGoalShare = 'org_goal_share',
  /** column name */
  OrganizationId = 'organization_Id',
  /** column name */
  PeerReview = 'peer_review',
  /** column name */
  SelfReview = 'self_review',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ViewOption = 'view_option',
  /** column name */
  Year = 'year',
}

export type Performance_Review_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Review_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Review_Settings_Bool_Exp;
};

/** columns and relationships of "personalised" */
export type Personalised = {
  __typename?: 'personalised';
  access: Enum_Personalised_Access_Enum;
  category: Enum_Personalised_Categories_Enum;
  created_at: Scalars['timestamp'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  enum_personalised_access: Enum_Personalised_Access;
  /** An object relationship */
  enum_personalised_category: Enum_Personalised_Categories;
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  storage_file: Storage_Files;
  storage_file_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "personalised" */
export type Personalised_Aggregate = {
  __typename?: 'personalised_aggregate';
  aggregate?: Maybe<Personalised_Aggregate_Fields>;
  nodes: Array<Personalised>;
};

export type Personalised_Aggregate_Bool_Exp = {
  count?: InputMaybe<Personalised_Aggregate_Bool_Exp_Count>;
};

export type Personalised_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Personalised_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Personalised_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "personalised" */
export type Personalised_Aggregate_Fields = {
  __typename?: 'personalised_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Personalised_Max_Fields>;
  min?: Maybe<Personalised_Min_Fields>;
};

/** aggregate fields of "personalised" */
export type Personalised_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Personalised_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "personalised" */
export type Personalised_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Personalised_Max_Order_By>;
  min?: InputMaybe<Personalised_Min_Order_By>;
};

/** input type for inserting array relation for remote table "personalised" */
export type Personalised_Arr_Rel_Insert_Input = {
  data: Array<Personalised_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Personalised_On_Conflict>;
};

/** Boolean expression to filter rows from the table "personalised". All fields are combined with a logical 'AND'. */
export type Personalised_Bool_Exp = {
  _and?: InputMaybe<Array<Personalised_Bool_Exp>>;
  _not?: InputMaybe<Personalised_Bool_Exp>;
  _or?: InputMaybe<Array<Personalised_Bool_Exp>>;
  access?: InputMaybe<Enum_Personalised_Access_Enum_Comparison_Exp>;
  category?: InputMaybe<Enum_Personalised_Categories_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  enum_personalised_access?: InputMaybe<Enum_Personalised_Access_Bool_Exp>;
  enum_personalised_category?: InputMaybe<Enum_Personalised_Categories_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  storage_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "personalised" */
export enum Personalised_Constraint {
  /** unique or primary key constraint on columns "created_at" */
  PersonalisedPkey = 'personalised_pkey',
}

/** input type for inserting data into table "personalised" */
export type Personalised_Insert_Input = {
  access?: InputMaybe<Enum_Personalised_Access_Enum>;
  category?: InputMaybe<Enum_Personalised_Categories_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  enum_personalised_access?: InputMaybe<Enum_Personalised_Access_Obj_Rel_Insert_Input>;
  enum_personalised_category?: InputMaybe<Enum_Personalised_Categories_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Personalised_Max_Fields = {
  __typename?: 'personalised_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  storage_file_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "personalised" */
export type Personalised_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Personalised_Min_Fields = {
  __typename?: 'personalised_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  storage_file_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "personalised" */
export type Personalised_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "personalised" */
export type Personalised_Mutation_Response = {
  __typename?: 'personalised_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Personalised>;
};

/** on_conflict condition type for table "personalised" */
export type Personalised_On_Conflict = {
  constraint: Personalised_Constraint;
  update_columns?: Array<Personalised_Update_Column>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** Ordering options when selecting data from "personalised". */
export type Personalised_Order_By = {
  access?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enum_personalised_access?: InputMaybe<Enum_Personalised_Access_Order_By>;
  enum_personalised_category?: InputMaybe<Enum_Personalised_Categories_Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: personalised */
export type Personalised_Pk_Columns_Input = {
  created_at: Scalars['timestamp'];
};

/** select columns of table "personalised" */
export enum Personalised_Select_Column {
  /** column name */
  Access = 'access',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StorageFileId = 'storage_file_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "personalised" */
export type Personalised_Set_Input = {
  access?: InputMaybe<Enum_Personalised_Access_Enum>;
  category?: InputMaybe<Enum_Personalised_Categories_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "personalised" */
export type Personalised_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Personalised_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Personalised_Stream_Cursor_Value_Input = {
  access?: InputMaybe<Enum_Personalised_Access_Enum>;
  category?: InputMaybe<Enum_Personalised_Categories_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "personalised" */
export enum Personalised_Update_Column {
  /** column name */
  Access = 'access',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StorageFileId = 'storage_file_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Personalised_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Personalised_Set_Input>;
  /** filter the rows which have to be updated */
  where: Personalised_Bool_Exp;
};

/** this table represents the files attached in a post comment */
export type Post_Comment_Files = {
  __typename?: 'post_comment_files';
  comment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  file_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  post_id: Scalars['uuid'];
  storage_file_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "post_comment_files" */
export type Post_Comment_Files_Aggregate = {
  __typename?: 'post_comment_files_aggregate';
  aggregate?: Maybe<Post_Comment_Files_Aggregate_Fields>;
  nodes: Array<Post_Comment_Files>;
};

/** aggregate fields of "post_comment_files" */
export type Post_Comment_Files_Aggregate_Fields = {
  __typename?: 'post_comment_files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Comment_Files_Max_Fields>;
  min?: Maybe<Post_Comment_Files_Min_Fields>;
};

/** aggregate fields of "post_comment_files" */
export type Post_Comment_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Comment_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "post_comment_files". All fields are combined with a logical 'AND'. */
export type Post_Comment_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Comment_Files_Bool_Exp>>;
  _not?: InputMaybe<Post_Comment_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Comment_Files_Bool_Exp>>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  storage_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_comment_files" */
export enum Post_Comment_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostCommentFilesPkey = 'post_comment_files_pkey',
}

/** input type for inserting data into table "post_comment_files" */
export type Post_Comment_Files_Insert_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Post_Comment_Files_Max_Fields = {
  __typename?: 'post_comment_files_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  storage_file_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Post_Comment_Files_Min_Fields = {
  __typename?: 'post_comment_files_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  storage_file_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "post_comment_files" */
export type Post_Comment_Files_Mutation_Response = {
  __typename?: 'post_comment_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Comment_Files>;
};

/** on_conflict condition type for table "post_comment_files" */
export type Post_Comment_Files_On_Conflict = {
  constraint: Post_Comment_Files_Constraint;
  update_columns?: Array<Post_Comment_Files_Update_Column>;
  where?: InputMaybe<Post_Comment_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "post_comment_files". */
export type Post_Comment_Files_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_comment_files */
export type Post_Comment_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_comment_files" */
export enum Post_Comment_Files_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  StorageFileId = 'storage_file_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "post_comment_files" */
export type Post_Comment_Files_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "post_comment_files" */
export type Post_Comment_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Comment_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Comment_Files_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "post_comment_files" */
export enum Post_Comment_Files_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  StorageFileId = 'storage_file_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Post_Comment_Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Comment_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Comment_Files_Bool_Exp;
};

/** this table represents the mentioned users on a comment */
export type Post_Comment_Mentioned_Users = {
  __typename?: 'post_comment_mentioned_users';
  /** An object relationship */
  comment?: Maybe<Posts_Comments>;
  comment_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Aggregate = {
  __typename?: 'post_comment_mentioned_users_aggregate';
  aggregate?: Maybe<Post_Comment_Mentioned_Users_Aggregate_Fields>;
  nodes: Array<Post_Comment_Mentioned_Users>;
};

export type Post_Comment_Mentioned_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Comment_Mentioned_Users_Aggregate_Bool_Exp_Count>;
};

export type Post_Comment_Mentioned_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Comment_Mentioned_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Aggregate_Fields = {
  __typename?: 'post_comment_mentioned_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Comment_Mentioned_Users_Max_Fields>;
  min?: Maybe<Post_Comment_Mentioned_Users_Min_Fields>;
};

/** aggregate fields of "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Comment_Mentioned_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Comment_Mentioned_Users_Max_Order_By>;
  min?: InputMaybe<Post_Comment_Mentioned_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Arr_Rel_Insert_Input = {
  data: Array<Post_Comment_Mentioned_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Comment_Mentioned_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_comment_mentioned_users". All fields are combined with a logical 'AND'. */
export type Post_Comment_Mentioned_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Comment_Mentioned_Users_Bool_Exp>>;
  _not?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Comment_Mentioned_Users_Bool_Exp>>;
  comment?: InputMaybe<Posts_Comments_Bool_Exp>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_comment_mentioned_users" */
export enum Post_Comment_Mentioned_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostCommentMentionedUsersPkey = 'post_comment_mentioned_users_pkey',
}

/** input type for inserting data into table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Insert_Input = {
  comment?: InputMaybe<Posts_Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Post_Comment_Mentioned_Users_Max_Fields = {
  __typename?: 'post_comment_mentioned_users_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Comment_Mentioned_Users_Min_Fields = {
  __typename?: 'post_comment_mentioned_users_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Mutation_Response = {
  __typename?: 'post_comment_mentioned_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Comment_Mentioned_Users>;
};

/** on_conflict condition type for table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_On_Conflict = {
  constraint: Post_Comment_Mentioned_Users_Constraint;
  update_columns?: Array<Post_Comment_Mentioned_Users_Update_Column>;
  where?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "post_comment_mentioned_users". */
export type Post_Comment_Mentioned_Users_Order_By = {
  comment?: InputMaybe<Posts_Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_comment_mentioned_users */
export type Post_Comment_Mentioned_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_comment_mentioned_users" */
export enum Post_Comment_Mentioned_Users_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "post_comment_mentioned_users" */
export type Post_Comment_Mentioned_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Comment_Mentioned_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Comment_Mentioned_Users_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "post_comment_mentioned_users" */
export enum Post_Comment_Mentioned_Users_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Post_Comment_Mentioned_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Comment_Mentioned_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Comment_Mentioned_Users_Bool_Exp;
};

/** this table represents the comments made on other comments */
export type Post_Comment_Replies = {
  __typename?: 'post_comment_replies';
  /** An object relationship */
  comment?: Maybe<Posts_Comments>;
  comment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  likes: Array<Post_Replies_Likes>;
  /** An aggregate relationship */
  likes_aggregate: Post_Replies_Likes_Aggregate;
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id: Scalars['uuid'];
  replied_by: Scalars['uuid'];
  /** An array relationship */
  repliesMentionedUsers: Array<Post_Comment_Replies_Mentioned_Users>;
  /** An aggregate relationship */
  repliesMentionedUsers_aggregate: Post_Comment_Replies_Mentioned_Users_Aggregate;
  reply?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  userWhoReplied?: Maybe<Users>;
};

/** this table represents the comments made on other comments */
export type Post_Comment_RepliesLikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Replies_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Replies_Likes_Order_By>>;
  where?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
};

/** this table represents the comments made on other comments */
export type Post_Comment_RepliesLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Replies_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Replies_Likes_Order_By>>;
  where?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
};

/** this table represents the comments made on other comments */
export type Post_Comment_RepliesRepliesMentionedUsersArgs = {
  distinct_on?: InputMaybe<
    Array<Post_Comment_Replies_Mentioned_Users_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
};

/** this table represents the comments made on other comments */
export type Post_Comment_RepliesRepliesMentionedUsers_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Post_Comment_Replies_Mentioned_Users_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
};

/** aggregated selection of "post_comment_replies" */
export type Post_Comment_Replies_Aggregate = {
  __typename?: 'post_comment_replies_aggregate';
  aggregate?: Maybe<Post_Comment_Replies_Aggregate_Fields>;
  nodes: Array<Post_Comment_Replies>;
};

export type Post_Comment_Replies_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Comment_Replies_Aggregate_Bool_Exp_Count>;
};

export type Post_Comment_Replies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Comment_Replies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_comment_replies" */
export type Post_Comment_Replies_Aggregate_Fields = {
  __typename?: 'post_comment_replies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Comment_Replies_Max_Fields>;
  min?: Maybe<Post_Comment_Replies_Min_Fields>;
};

/** aggregate fields of "post_comment_replies" */
export type Post_Comment_Replies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Comment_Replies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_comment_replies" */
export type Post_Comment_Replies_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Comment_Replies_Max_Order_By>;
  min?: InputMaybe<Post_Comment_Replies_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_comment_replies" */
export type Post_Comment_Replies_Arr_Rel_Insert_Input = {
  data: Array<Post_Comment_Replies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Comment_Replies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_comment_replies". All fields are combined with a logical 'AND'. */
export type Post_Comment_Replies_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Comment_Replies_Bool_Exp>>;
  _not?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Comment_Replies_Bool_Exp>>;
  comment?: InputMaybe<Posts_Comments_Bool_Exp>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  likes?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
  likes_aggregate?: InputMaybe<Post_Replies_Likes_Aggregate_Bool_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  replied_by?: InputMaybe<Uuid_Comparison_Exp>;
  repliesMentionedUsers?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
  repliesMentionedUsers_aggregate?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Aggregate_Bool_Exp>;
  reply?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  userWhoReplied?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "post_comment_replies" */
export enum Post_Comment_Replies_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostCommentRepliesPkey = 'post_comment_replies_pkey',
}

/** input type for inserting data into table "post_comment_replies" */
export type Post_Comment_Replies_Insert_Input = {
  comment?: InputMaybe<Posts_Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  likes?: InputMaybe<Post_Replies_Likes_Arr_Rel_Insert_Input>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  replied_by?: InputMaybe<Scalars['uuid']>;
  repliesMentionedUsers?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Arr_Rel_Insert_Input>;
  reply?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userWhoReplied?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Post_Comment_Replies_Max_Fields = {
  __typename?: 'post_comment_replies_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  replied_by?: Maybe<Scalars['uuid']>;
  reply?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "post_comment_replies" */
export type Post_Comment_Replies_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  replied_by?: InputMaybe<Order_By>;
  reply?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** this table represents the mentioned users for a comments replies */
export type Post_Comment_Replies_Mentioned_Users = {
  __typename?: 'post_comment_replies_mentioned_users';
  comment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  post_id: Scalars['uuid'];
  reply_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Aggregate = {
  __typename?: 'post_comment_replies_mentioned_users_aggregate';
  aggregate?: Maybe<Post_Comment_Replies_Mentioned_Users_Aggregate_Fields>;
  nodes: Array<Post_Comment_Replies_Mentioned_Users>;
};

export type Post_Comment_Replies_Mentioned_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Aggregate_Bool_Exp_Count>;
};

export type Post_Comment_Replies_Mentioned_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Post_Comment_Replies_Mentioned_Users_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Aggregate_Fields = {
  __typename?: 'post_comment_replies_mentioned_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Comment_Replies_Mentioned_Users_Max_Fields>;
  min?: Maybe<Post_Comment_Replies_Mentioned_Users_Min_Fields>;
};

/** aggregate fields of "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Post_Comment_Replies_Mentioned_Users_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Max_Order_By>;
  min?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Arr_Rel_Insert_Input = {
  data: Array<Post_Comment_Replies_Mentioned_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Comment_Replies_Mentioned_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_comment_replies_mentioned_users". All fields are combined with a logical 'AND'. */
export type Post_Comment_Replies_Mentioned_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Comment_Replies_Mentioned_Users_Bool_Exp>>;
  _not?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Comment_Replies_Mentioned_Users_Bool_Exp>>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  reply_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_comment_replies_mentioned_users" */
export enum Post_Comment_Replies_Mentioned_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostCommentRepliesMentionedUsersPkey = 'post_comment_replies_mentioned_users_pkey',
}

/** input type for inserting data into table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Insert_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  reply_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Post_Comment_Replies_Mentioned_Users_Max_Fields = {
  __typename?: 'post_comment_replies_mentioned_users_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  reply_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  reply_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Comment_Replies_Mentioned_Users_Min_Fields = {
  __typename?: 'post_comment_replies_mentioned_users_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  reply_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  reply_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Mutation_Response = {
  __typename?: 'post_comment_replies_mentioned_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Comment_Replies_Mentioned_Users>;
};

/** on_conflict condition type for table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_On_Conflict = {
  constraint: Post_Comment_Replies_Mentioned_Users_Constraint;
  update_columns?: Array<Post_Comment_Replies_Mentioned_Users_Update_Column>;
  where?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "post_comment_replies_mentioned_users". */
export type Post_Comment_Replies_Mentioned_Users_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  reply_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_comment_replies_mentioned_users */
export type Post_Comment_Replies_Mentioned_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_comment_replies_mentioned_users" */
export enum Post_Comment_Replies_Mentioned_Users_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ReplyId = 'reply_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  reply_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "post_comment_replies_mentioned_users" */
export type Post_Comment_Replies_Mentioned_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Comment_Replies_Mentioned_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Comment_Replies_Mentioned_Users_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  reply_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "post_comment_replies_mentioned_users" */
export enum Post_Comment_Replies_Mentioned_Users_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ReplyId = 'reply_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Post_Comment_Replies_Mentioned_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Comment_Replies_Mentioned_Users_Bool_Exp;
};

/** aggregate min on columns */
export type Post_Comment_Replies_Min_Fields = {
  __typename?: 'post_comment_replies_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  replied_by?: Maybe<Scalars['uuid']>;
  reply?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "post_comment_replies" */
export type Post_Comment_Replies_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  replied_by?: InputMaybe<Order_By>;
  reply?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_comment_replies" */
export type Post_Comment_Replies_Mutation_Response = {
  __typename?: 'post_comment_replies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Comment_Replies>;
};

/** input type for inserting object relation for remote table "post_comment_replies" */
export type Post_Comment_Replies_Obj_Rel_Insert_Input = {
  data: Post_Comment_Replies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Comment_Replies_On_Conflict>;
};

/** on_conflict condition type for table "post_comment_replies" */
export type Post_Comment_Replies_On_Conflict = {
  constraint: Post_Comment_Replies_Constraint;
  update_columns?: Array<Post_Comment_Replies_Update_Column>;
  where?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
};

/** Ordering options when selecting data from "post_comment_replies". */
export type Post_Comment_Replies_Order_By = {
  comment?: InputMaybe<Posts_Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likes_aggregate?: InputMaybe<Post_Replies_Likes_Aggregate_Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  replied_by?: InputMaybe<Order_By>;
  repliesMentionedUsers_aggregate?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Aggregate_Order_By>;
  reply?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userWhoReplied?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: post_comment_replies */
export type Post_Comment_Replies_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_comment_replies" */
export enum Post_Comment_Replies_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  RepliedBy = 'replied_by',
  /** column name */
  Reply = 'reply',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "post_comment_replies" */
export type Post_Comment_Replies_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  replied_by?: InputMaybe<Scalars['uuid']>;
  reply?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "post_comment_replies" */
export type Post_Comment_Replies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Comment_Replies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Comment_Replies_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  replied_by?: InputMaybe<Scalars['uuid']>;
  reply?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "post_comment_replies" */
export enum Post_Comment_Replies_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  RepliedBy = 'replied_by',
  /** column name */
  Reply = 'reply',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Post_Comment_Replies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Comment_Replies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Comment_Replies_Bool_Exp;
};

/** represents the likes on post comments */
export type Post_Comments_Likes = {
  __typename?: 'post_comments_likes';
  /** An object relationship */
  comment?: Maybe<Posts_Comments>;
  comment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  liked_by: Scalars['uuid'];
  post_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "post_comments_likes" */
export type Post_Comments_Likes_Aggregate = {
  __typename?: 'post_comments_likes_aggregate';
  aggregate?: Maybe<Post_Comments_Likes_Aggregate_Fields>;
  nodes: Array<Post_Comments_Likes>;
};

export type Post_Comments_Likes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Comments_Likes_Aggregate_Bool_Exp_Count>;
};

export type Post_Comments_Likes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Comments_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_comments_likes" */
export type Post_Comments_Likes_Aggregate_Fields = {
  __typename?: 'post_comments_likes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Comments_Likes_Max_Fields>;
  min?: Maybe<Post_Comments_Likes_Min_Fields>;
};

/** aggregate fields of "post_comments_likes" */
export type Post_Comments_Likes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Comments_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_comments_likes" */
export type Post_Comments_Likes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Comments_Likes_Max_Order_By>;
  min?: InputMaybe<Post_Comments_Likes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_comments_likes" */
export type Post_Comments_Likes_Arr_Rel_Insert_Input = {
  data: Array<Post_Comments_Likes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Comments_Likes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_comments_likes". All fields are combined with a logical 'AND'. */
export type Post_Comments_Likes_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Comments_Likes_Bool_Exp>>;
  _not?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Comments_Likes_Bool_Exp>>;
  comment?: InputMaybe<Posts_Comments_Bool_Exp>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  liked_by?: InputMaybe<Uuid_Comparison_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "post_comments_likes" */
export enum Post_Comments_Likes_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostCommentsLikesPkey = 'post_comments_likes_pkey',
}

/** input type for inserting data into table "post_comments_likes" */
export type Post_Comments_Likes_Insert_Input = {
  comment?: InputMaybe<Posts_Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Post_Comments_Likes_Max_Fields = {
  __typename?: 'post_comments_likes_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  liked_by?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "post_comments_likes" */
export type Post_Comments_Likes_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Comments_Likes_Min_Fields = {
  __typename?: 'post_comments_likes_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  liked_by?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "post_comments_likes" */
export type Post_Comments_Likes_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_comments_likes" */
export type Post_Comments_Likes_Mutation_Response = {
  __typename?: 'post_comments_likes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Comments_Likes>;
};

/** on_conflict condition type for table "post_comments_likes" */
export type Post_Comments_Likes_On_Conflict = {
  constraint: Post_Comments_Likes_Constraint;
  update_columns?: Array<Post_Comments_Likes_Update_Column>;
  where?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
};

/** Ordering options when selecting data from "post_comments_likes". */
export type Post_Comments_Likes_Order_By = {
  comment?: InputMaybe<Posts_Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: post_comments_likes */
export type Post_Comments_Likes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_comments_likes" */
export enum Post_Comments_Likes_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LikedBy = 'liked_by',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "post_comments_likes" */
export type Post_Comments_Likes_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "post_comments_likes" */
export type Post_Comments_Likes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Comments_Likes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Comments_Likes_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "post_comments_likes" */
export enum Post_Comments_Likes_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LikedBy = 'liked_by',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Post_Comments_Likes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Comments_Likes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Comments_Likes_Bool_Exp;
};

/** this table represents the likes for a post */
export type Post_Likes = {
  __typename?: 'post_likes';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  likedByUser: Users;
  liked_by: Scalars['uuid'];
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "post_likes" */
export type Post_Likes_Aggregate = {
  __typename?: 'post_likes_aggregate';
  aggregate?: Maybe<Post_Likes_Aggregate_Fields>;
  nodes: Array<Post_Likes>;
};

export type Post_Likes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Likes_Aggregate_Bool_Exp_Count>;
};

export type Post_Likes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Likes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_likes" */
export type Post_Likes_Aggregate_Fields = {
  __typename?: 'post_likes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Likes_Max_Fields>;
  min?: Maybe<Post_Likes_Min_Fields>;
};

/** aggregate fields of "post_likes" */
export type Post_Likes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_likes" */
export type Post_Likes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Likes_Max_Order_By>;
  min?: InputMaybe<Post_Likes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_likes" */
export type Post_Likes_Arr_Rel_Insert_Input = {
  data: Array<Post_Likes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Likes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_likes". All fields are combined with a logical 'AND'. */
export type Post_Likes_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Likes_Bool_Exp>>;
  _not?: InputMaybe<Post_Likes_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Likes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  likedByUser?: InputMaybe<Users_Bool_Exp>;
  liked_by?: InputMaybe<Uuid_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_likes" */
export enum Post_Likes_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostLikesPkey = 'post_likes_pkey',
}

/** input type for inserting data into table "post_likes" */
export type Post_Likes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  likedByUser?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Post_Likes_Max_Fields = {
  __typename?: 'post_likes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  liked_by?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "post_likes" */
export type Post_Likes_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Likes_Min_Fields = {
  __typename?: 'post_likes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  liked_by?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "post_likes" */
export type Post_Likes_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_likes" */
export type Post_Likes_Mutation_Response = {
  __typename?: 'post_likes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Likes>;
};

/** on_conflict condition type for table "post_likes" */
export type Post_Likes_On_Conflict = {
  constraint: Post_Likes_Constraint;
  update_columns?: Array<Post_Likes_Update_Column>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

/** Ordering options when selecting data from "post_likes". */
export type Post_Likes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likedByUser?: InputMaybe<Users_Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_likes */
export type Post_Likes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_likes" */
export enum Post_Likes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LikedBy = 'liked_by',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "post_likes" */
export type Post_Likes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "post_likes" */
export type Post_Likes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Likes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Likes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "post_likes" */
export enum Post_Likes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LikedBy = 'liked_by',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Post_Likes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Likes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Likes_Bool_Exp;
};

/** this table represents the users who are mentioned in a post */
export type Post_Mentioned_Users = {
  __typename?: 'post_mentioned_users';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "post_mentioned_users" */
export type Post_Mentioned_Users_Aggregate = {
  __typename?: 'post_mentioned_users_aggregate';
  aggregate?: Maybe<Post_Mentioned_Users_Aggregate_Fields>;
  nodes: Array<Post_Mentioned_Users>;
};

export type Post_Mentioned_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Mentioned_Users_Aggregate_Bool_Exp_Count>;
};

export type Post_Mentioned_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Mentioned_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_mentioned_users" */
export type Post_Mentioned_Users_Aggregate_Fields = {
  __typename?: 'post_mentioned_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Mentioned_Users_Max_Fields>;
  min?: Maybe<Post_Mentioned_Users_Min_Fields>;
};

/** aggregate fields of "post_mentioned_users" */
export type Post_Mentioned_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Mentioned_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_mentioned_users" */
export type Post_Mentioned_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Mentioned_Users_Max_Order_By>;
  min?: InputMaybe<Post_Mentioned_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_mentioned_users" */
export type Post_Mentioned_Users_Arr_Rel_Insert_Input = {
  data: Array<Post_Mentioned_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Mentioned_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_mentioned_users". All fields are combined with a logical 'AND'. */
export type Post_Mentioned_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Mentioned_Users_Bool_Exp>>;
  _not?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Mentioned_Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_mentioned_users" */
export enum Post_Mentioned_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostMentionedUsersPkey = 'post_mentioned_users_pkey',
}

/** input type for inserting data into table "post_mentioned_users" */
export type Post_Mentioned_Users_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Post_Mentioned_Users_Max_Fields = {
  __typename?: 'post_mentioned_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "post_mentioned_users" */
export type Post_Mentioned_Users_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Mentioned_Users_Min_Fields = {
  __typename?: 'post_mentioned_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "post_mentioned_users" */
export type Post_Mentioned_Users_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_mentioned_users" */
export type Post_Mentioned_Users_Mutation_Response = {
  __typename?: 'post_mentioned_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Mentioned_Users>;
};

/** on_conflict condition type for table "post_mentioned_users" */
export type Post_Mentioned_Users_On_Conflict = {
  constraint: Post_Mentioned_Users_Constraint;
  update_columns?: Array<Post_Mentioned_Users_Update_Column>;
  where?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "post_mentioned_users". */
export type Post_Mentioned_Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_mentioned_users */
export type Post_Mentioned_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_mentioned_users" */
export enum Post_Mentioned_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "post_mentioned_users" */
export type Post_Mentioned_Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "post_mentioned_users" */
export type Post_Mentioned_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Mentioned_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Mentioned_Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "post_mentioned_users" */
export enum Post_Mentioned_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Post_Mentioned_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Mentioned_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Mentioned_Users_Bool_Exp;
};

/** this table represents the likes for a comments reply */
export type Post_Replies_Likes = {
  __typename?: 'post_replies_likes';
  /** An object relationship */
  comment?: Maybe<Posts_Comments>;
  comment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  liked_by: Scalars['uuid'];
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id: Scalars['uuid'];
  /** An object relationship */
  reply?: Maybe<Post_Comment_Replies>;
  reply_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "post_replies_likes" */
export type Post_Replies_Likes_Aggregate = {
  __typename?: 'post_replies_likes_aggregate';
  aggregate?: Maybe<Post_Replies_Likes_Aggregate_Fields>;
  nodes: Array<Post_Replies_Likes>;
};

export type Post_Replies_Likes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Replies_Likes_Aggregate_Bool_Exp_Count>;
};

export type Post_Replies_Likes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Replies_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_replies_likes" */
export type Post_Replies_Likes_Aggregate_Fields = {
  __typename?: 'post_replies_likes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Replies_Likes_Max_Fields>;
  min?: Maybe<Post_Replies_Likes_Min_Fields>;
};

/** aggregate fields of "post_replies_likes" */
export type Post_Replies_Likes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Replies_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_replies_likes" */
export type Post_Replies_Likes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Replies_Likes_Max_Order_By>;
  min?: InputMaybe<Post_Replies_Likes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_replies_likes" */
export type Post_Replies_Likes_Arr_Rel_Insert_Input = {
  data: Array<Post_Replies_Likes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Replies_Likes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_replies_likes". All fields are combined with a logical 'AND'. */
export type Post_Replies_Likes_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Replies_Likes_Bool_Exp>>;
  _not?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Replies_Likes_Bool_Exp>>;
  comment?: InputMaybe<Posts_Comments_Bool_Exp>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  liked_by?: InputMaybe<Uuid_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  reply?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
  reply_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "post_replies_likes" */
export enum Post_Replies_Likes_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostRepliesLikesPkey = 'post_replies_likes_pkey',
}

/** input type for inserting data into table "post_replies_likes" */
export type Post_Replies_Likes_Insert_Input = {
  comment?: InputMaybe<Posts_Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  reply?: InputMaybe<Post_Comment_Replies_Obj_Rel_Insert_Input>;
  reply_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Post_Replies_Likes_Max_Fields = {
  __typename?: 'post_replies_likes_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  liked_by?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  reply_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "post_replies_likes" */
export type Post_Replies_Likes_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  reply_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Replies_Likes_Min_Fields = {
  __typename?: 'post_replies_likes_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  liked_by?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  reply_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "post_replies_likes" */
export type Post_Replies_Likes_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  reply_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_replies_likes" */
export type Post_Replies_Likes_Mutation_Response = {
  __typename?: 'post_replies_likes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Replies_Likes>;
};

/** on_conflict condition type for table "post_replies_likes" */
export type Post_Replies_Likes_On_Conflict = {
  constraint: Post_Replies_Likes_Constraint;
  update_columns?: Array<Post_Replies_Likes_Update_Column>;
  where?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
};

/** Ordering options when selecting data from "post_replies_likes". */
export type Post_Replies_Likes_Order_By = {
  comment?: InputMaybe<Posts_Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  liked_by?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  reply?: InputMaybe<Post_Comment_Replies_Order_By>;
  reply_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: post_replies_likes */
export type Post_Replies_Likes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_replies_likes" */
export enum Post_Replies_Likes_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LikedBy = 'liked_by',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ReplyId = 'reply_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "post_replies_likes" */
export type Post_Replies_Likes_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  reply_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "post_replies_likes" */
export type Post_Replies_Likes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Replies_Likes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Replies_Likes_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  liked_by?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  reply_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "post_replies_likes" */
export enum Post_Replies_Likes_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LikedBy = 'liked_by',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ReplyId = 'reply_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Post_Replies_Likes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Replies_Likes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Replies_Likes_Bool_Exp;
};

/** this table contains info for the users posts */
export type Posts = {
  __typename?: 'posts';
  /** An array relationship */
  comments: Array<Posts_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Posts_Comments_Aggregate;
  created_at: Scalars['timestamptz'];
  date: Scalars['date'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_post_type?: Maybe<Enum_Post_Type>;
  holiday_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  /** An array relationship */
  likes: Array<Post_Likes>;
  /** An aggregate relationship */
  likes_aggregate: Post_Likes_Aggregate;
  /** An array relationship */
  mentioned_users: Array<Post_Mentioned_Users>;
  /** An aggregate relationship */
  mentioned_users_aggregate: Post_Mentioned_Users_Aggregate;
  message: Scalars['String'];
  org_id: Scalars['uuid'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** An array relationship */
  post_files: Array<Posts_Files>;
  /** An aggregate relationship */
  post_files_aggregate: Posts_Files_Aggregate;
  post_type?: Maybe<Enum_Post_Type_Enum>;
  /** An array relationship */
  posts_poll: Array<Posts_Poll_Choices>;
  /** An aggregate relationship */
  posts_poll_aggregate: Posts_Poll_Choices_Aggregate;
  /** An object relationship */
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  visibility: Scalars['String'];
};

/** this table contains info for the users posts */
export type PostsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Comments_Order_By>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Comments_Order_By>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsLikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Likes_Order_By>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Likes_Order_By>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsMentioned_UsersArgs = {
  distinct_on?: InputMaybe<Array<Post_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsMentioned_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsPost_FilesArgs = {
  distinct_on?: InputMaybe<Array<Posts_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Files_Order_By>>;
  where?: InputMaybe<Posts_Files_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsPost_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Files_Order_By>>;
  where?: InputMaybe<Posts_Files_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsPosts_PollArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
};

/** this table contains info for the users posts */
export type PostsPosts_Poll_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
};

/** aggregated selection of "posts" */
export type Posts_Aggregate = {
  __typename?: 'posts_aggregate';
  aggregate?: Maybe<Posts_Aggregate_Fields>;
  nodes: Array<Posts>;
};

export type Posts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Posts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Posts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Posts_Aggregate_Bool_Exp_Count>;
};

export type Posts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Posts_Select_Column_Posts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Posts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Posts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Posts_Select_Column_Posts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Posts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Posts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Posts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "posts" */
export type Posts_Aggregate_Fields = {
  __typename?: 'posts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Posts_Max_Fields>;
  min?: Maybe<Posts_Min_Fields>;
};

/** aggregate fields of "posts" */
export type Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "posts" */
export type Posts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Posts_Max_Order_By>;
  min?: InputMaybe<Posts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "posts" */
export type Posts_Arr_Rel_Insert_Input = {
  data: Array<Posts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Posts_Bool_Exp>>;
  _not?: InputMaybe<Posts_Bool_Exp>;
  _or?: InputMaybe<Array<Posts_Bool_Exp>>;
  comments?: InputMaybe<Posts_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Posts_Comments_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disappeared_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_post_type?: InputMaybe<Enum_Post_Type_Bool_Exp>;
  holiday_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  likes?: InputMaybe<Post_Likes_Bool_Exp>;
  likes_aggregate?: InputMaybe<Post_Likes_Aggregate_Bool_Exp>;
  mentioned_users?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
  mentioned_users_aggregate?: InputMaybe<Post_Mentioned_Users_Aggregate_Bool_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  post_files?: InputMaybe<Posts_Files_Bool_Exp>;
  post_files_aggregate?: InputMaybe<Posts_Files_Aggregate_Bool_Exp>;
  post_type?: InputMaybe<Enum_Post_Type_Enum_Comparison_Exp>;
  posts_poll?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
  posts_poll_aggregate?: InputMaybe<Posts_Poll_Choices_Aggregate_Bool_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  visibility?: InputMaybe<String_Comparison_Exp>;
};

/** this table represents the comments on a post */
export type Posts_Comments = {
  __typename?: 'posts_comments';
  comment: Scalars['String'];
  /** An array relationship */
  commentReplies: Array<Post_Comment_Replies>;
  /** An aggregate relationship */
  commentReplies_aggregate: Post_Comment_Replies_Aggregate;
  /** An object relationship */
  commentedByUser: Users;
  commented_by: Scalars['uuid'];
  /** An array relationship */
  commentsLikes: Array<Post_Comments_Likes>;
  /** An aggregate relationship */
  commentsLikes_aggregate: Post_Comments_Likes_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  mentioned_users: Array<Post_Comment_Mentioned_Users>;
  /** An aggregate relationship */
  mentioned_users_aggregate: Post_Comment_Mentioned_Users_Aggregate;
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** this table represents the comments on a post */
export type Posts_CommentsCommentRepliesArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
};

/** this table represents the comments on a post */
export type Posts_CommentsCommentReplies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
};

/** this table represents the comments on a post */
export type Posts_CommentsCommentsLikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comments_Likes_Order_By>>;
  where?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
};

/** this table represents the comments on a post */
export type Posts_CommentsCommentsLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comments_Likes_Order_By>>;
  where?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
};

/** this table represents the comments on a post */
export type Posts_CommentsMentioned_UsersArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
};

/** this table represents the comments on a post */
export type Posts_CommentsMentioned_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
};

/** aggregated selection of "posts_comments" */
export type Posts_Comments_Aggregate = {
  __typename?: 'posts_comments_aggregate';
  aggregate?: Maybe<Posts_Comments_Aggregate_Fields>;
  nodes: Array<Posts_Comments>;
};

export type Posts_Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Posts_Comments_Aggregate_Bool_Exp_Count>;
};

export type Posts_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Posts_Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "posts_comments" */
export type Posts_Comments_Aggregate_Fields = {
  __typename?: 'posts_comments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Posts_Comments_Max_Fields>;
  min?: Maybe<Posts_Comments_Min_Fields>;
};

/** aggregate fields of "posts_comments" */
export type Posts_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "posts_comments" */
export type Posts_Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Posts_Comments_Max_Order_By>;
  min?: InputMaybe<Posts_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "posts_comments" */
export type Posts_Comments_Arr_Rel_Insert_Input = {
  data: Array<Posts_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "posts_comments". All fields are combined with a logical 'AND'. */
export type Posts_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Posts_Comments_Bool_Exp>>;
  _not?: InputMaybe<Posts_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Posts_Comments_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  commentReplies?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
  commentReplies_aggregate?: InputMaybe<Post_Comment_Replies_Aggregate_Bool_Exp>;
  commentedByUser?: InputMaybe<Users_Bool_Exp>;
  commented_by?: InputMaybe<Uuid_Comparison_Exp>;
  commentsLikes?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
  commentsLikes_aggregate?: InputMaybe<Post_Comments_Likes_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mentioned_users?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
  mentioned_users_aggregate?: InputMaybe<Post_Comment_Mentioned_Users_Aggregate_Bool_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "posts_comments" */
export enum Posts_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsCommentsPkey = 'posts_comments_pkey',
}

/** input type for inserting data into table "posts_comments" */
export type Posts_Comments_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  commentReplies?: InputMaybe<Post_Comment_Replies_Arr_Rel_Insert_Input>;
  commentedByUser?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  commented_by?: InputMaybe<Scalars['uuid']>;
  commentsLikes?: InputMaybe<Post_Comments_Likes_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  mentioned_users?: InputMaybe<Post_Comment_Mentioned_Users_Arr_Rel_Insert_Input>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Posts_Comments_Max_Fields = {
  __typename?: 'posts_comments_max_fields';
  comment?: Maybe<Scalars['String']>;
  commented_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "posts_comments" */
export type Posts_Comments_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  commented_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Posts_Comments_Min_Fields = {
  __typename?: 'posts_comments_min_fields';
  comment?: Maybe<Scalars['String']>;
  commented_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "posts_comments" */
export type Posts_Comments_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  commented_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts_comments" */
export type Posts_Comments_Mutation_Response = {
  __typename?: 'posts_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Posts_Comments>;
};

/** input type for inserting object relation for remote table "posts_comments" */
export type Posts_Comments_Obj_Rel_Insert_Input = {
  data: Posts_Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_Comments_On_Conflict>;
};

/** on_conflict condition type for table "posts_comments" */
export type Posts_Comments_On_Conflict = {
  constraint: Posts_Comments_Constraint;
  update_columns?: Array<Posts_Comments_Update_Column>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "posts_comments". */
export type Posts_Comments_Order_By = {
  comment?: InputMaybe<Order_By>;
  commentReplies_aggregate?: InputMaybe<Post_Comment_Replies_Aggregate_Order_By>;
  commentedByUser?: InputMaybe<Users_Order_By>;
  commented_by?: InputMaybe<Order_By>;
  commentsLikes_aggregate?: InputMaybe<Post_Comments_Likes_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentioned_users_aggregate?: InputMaybe<Post_Comment_Mentioned_Users_Aggregate_Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: posts_comments */
export type Posts_Comments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "posts_comments" */
export enum Posts_Comments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CommentedBy = 'commented_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "posts_comments" */
export type Posts_Comments_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  commented_by?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "posts_comments" */
export type Posts_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Posts_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Comments_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  commented_by?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "posts_comments" */
export enum Posts_Comments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CommentedBy = 'commented_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Posts_Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Posts_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Posts_Comments_Bool_Exp;
};

/** unique or primary key constraints on table "posts" */
export enum Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPkey = 'posts_pkey',
}

/** this table keeps track of the files that were uploaded with the posts itself */
export type Posts_Files = {
  __typename?: 'posts_files';
  created_at: Scalars['timestamptz'];
  file_id: Scalars['uuid'];
  file_url: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id: Scalars['uuid'];
  /** An object relationship */
  storage_file?: Maybe<Storage_Files>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "posts_files" */
export type Posts_Files_Aggregate = {
  __typename?: 'posts_files_aggregate';
  aggregate?: Maybe<Posts_Files_Aggregate_Fields>;
  nodes: Array<Posts_Files>;
};

export type Posts_Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Posts_Files_Aggregate_Bool_Exp_Count>;
};

export type Posts_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Posts_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Posts_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "posts_files" */
export type Posts_Files_Aggregate_Fields = {
  __typename?: 'posts_files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Posts_Files_Max_Fields>;
  min?: Maybe<Posts_Files_Min_Fields>;
};

/** aggregate fields of "posts_files" */
export type Posts_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Posts_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "posts_files" */
export type Posts_Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Posts_Files_Max_Order_By>;
  min?: InputMaybe<Posts_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "posts_files" */
export type Posts_Files_Arr_Rel_Insert_Input = {
  data: Array<Posts_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "posts_files". All fields are combined with a logical 'AND'. */
export type Posts_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Posts_Files_Bool_Exp>>;
  _not?: InputMaybe<Posts_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Posts_Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "posts_files" */
export enum Posts_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsFilesPkey = 'posts_files_pkey',
}

/** input type for inserting data into table "posts_files" */
export type Posts_Files_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Posts_Files_Max_Fields = {
  __typename?: 'posts_files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "posts_files" */
export type Posts_Files_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Posts_Files_Min_Fields = {
  __typename?: 'posts_files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "posts_files" */
export type Posts_Files_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts_files" */
export type Posts_Files_Mutation_Response = {
  __typename?: 'posts_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Posts_Files>;
};

/** on_conflict condition type for table "posts_files" */
export type Posts_Files_On_Conflict = {
  constraint: Posts_Files_Constraint;
  update_columns?: Array<Posts_Files_Update_Column>;
  where?: InputMaybe<Posts_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "posts_files". */
export type Posts_Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: posts_files */
export type Posts_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "posts_files" */
export enum Posts_Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "posts_files" */
export type Posts_Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "posts_files" */
export type Posts_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Posts_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Files_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "posts_files" */
export enum Posts_Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Posts_Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Posts_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Posts_Files_Bool_Exp;
};

/** input type for inserting data into table "posts" */
export type Posts_Insert_Input = {
  comments?: InputMaybe<Posts_Comments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  disappeared_at?: InputMaybe<Scalars['timestamptz']>;
  enum_post_type?: InputMaybe<Enum_Post_Type_Obj_Rel_Insert_Input>;
  holiday_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  likes?: InputMaybe<Post_Likes_Arr_Rel_Insert_Input>;
  mentioned_users?: InputMaybe<Post_Mentioned_Users_Arr_Rel_Insert_Input>;
  message?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  post_files?: InputMaybe<Posts_Files_Arr_Rel_Insert_Input>;
  post_type?: InputMaybe<Enum_Post_Type_Enum>;
  posts_poll?: InputMaybe<Posts_Poll_Choices_Arr_Rel_Insert_Input>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Posts_Max_Fields = {
  __typename?: 'posts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  holiday_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  visibility?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "posts" */
export type Posts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  disappeared_at?: InputMaybe<Order_By>;
  holiday_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visibility?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Posts_Min_Fields = {
  __typename?: 'posts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  holiday_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  visibility?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "posts" */
export type Posts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  disappeared_at?: InputMaybe<Order_By>;
  holiday_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visibility?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts" */
export type Posts_Mutation_Response = {
  __typename?: 'posts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Posts>;
};

/** input type for inserting object relation for remote table "posts" */
export type Posts_Obj_Rel_Insert_Input = {
  data: Posts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** on_conflict condition type for table "posts" */
export type Posts_On_Conflict = {
  constraint: Posts_Constraint;
  update_columns?: Array<Posts_Update_Column>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "posts". */
export type Posts_Order_By = {
  comments_aggregate?: InputMaybe<Posts_Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  disappeared_at?: InputMaybe<Order_By>;
  enum_post_type?: InputMaybe<Enum_Post_Type_Order_By>;
  holiday_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  likes_aggregate?: InputMaybe<Post_Likes_Aggregate_Order_By>;
  mentioned_users_aggregate?: InputMaybe<Post_Mentioned_Users_Aggregate_Order_By>;
  message?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  post_files_aggregate?: InputMaybe<Posts_Files_Aggregate_Order_By>;
  post_type?: InputMaybe<Order_By>;
  posts_poll_aggregate?: InputMaybe<Posts_Poll_Choices_Aggregate_Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  visibility?: InputMaybe<Order_By>;
};

/** primary key columns input for table: posts */
export type Posts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "posts_poll_choices" */
export type Posts_Poll_Choices = {
  __typename?: 'posts_poll_choices';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  poll_name: Scalars['String'];
  post_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  votes: Array<Posts_Poll_Choices_Votes>;
  /** An aggregate relationship */
  votes_aggregate: Posts_Poll_Choices_Votes_Aggregate;
};

/** columns and relationships of "posts_poll_choices" */
export type Posts_Poll_ChoicesVotesArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Votes_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
};

/** columns and relationships of "posts_poll_choices" */
export type Posts_Poll_ChoicesVotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Votes_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
};

/** aggregated selection of "posts_poll_choices" */
export type Posts_Poll_Choices_Aggregate = {
  __typename?: 'posts_poll_choices_aggregate';
  aggregate?: Maybe<Posts_Poll_Choices_Aggregate_Fields>;
  nodes: Array<Posts_Poll_Choices>;
};

export type Posts_Poll_Choices_Aggregate_Bool_Exp = {
  count?: InputMaybe<Posts_Poll_Choices_Aggregate_Bool_Exp_Count>;
};

export type Posts_Poll_Choices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Posts_Poll_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "posts_poll_choices" */
export type Posts_Poll_Choices_Aggregate_Fields = {
  __typename?: 'posts_poll_choices_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Posts_Poll_Choices_Max_Fields>;
  min?: Maybe<Posts_Poll_Choices_Min_Fields>;
};

/** aggregate fields of "posts_poll_choices" */
export type Posts_Poll_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Posts_Poll_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "posts_poll_choices" */
export type Posts_Poll_Choices_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Posts_Poll_Choices_Max_Order_By>;
  min?: InputMaybe<Posts_Poll_Choices_Min_Order_By>;
};

/** input type for inserting array relation for remote table "posts_poll_choices" */
export type Posts_Poll_Choices_Arr_Rel_Insert_Input = {
  data: Array<Posts_Poll_Choices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_Poll_Choices_On_Conflict>;
};

/** Boolean expression to filter rows from the table "posts_poll_choices". All fields are combined with a logical 'AND'. */
export type Posts_Poll_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Posts_Poll_Choices_Bool_Exp>>;
  _not?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Posts_Poll_Choices_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  poll_name?: InputMaybe<String_Comparison_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  votes?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
  votes_aggregate?: InputMaybe<Posts_Poll_Choices_Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "posts_poll_choices" */
export enum Posts_Poll_Choices_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPollPkey = 'posts_poll_pkey',
}

/** input type for inserting data into table "posts_poll_choices" */
export type Posts_Poll_Choices_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  poll_name?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  votes?: InputMaybe<Posts_Poll_Choices_Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Posts_Poll_Choices_Max_Fields = {
  __typename?: 'posts_poll_choices_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  poll_name?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "posts_poll_choices" */
export type Posts_Poll_Choices_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  poll_name?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Posts_Poll_Choices_Min_Fields = {
  __typename?: 'posts_poll_choices_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  poll_name?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "posts_poll_choices" */
export type Posts_Poll_Choices_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  poll_name?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts_poll_choices" */
export type Posts_Poll_Choices_Mutation_Response = {
  __typename?: 'posts_poll_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Posts_Poll_Choices>;
};

/** input type for inserting object relation for remote table "posts_poll_choices" */
export type Posts_Poll_Choices_Obj_Rel_Insert_Input = {
  data: Posts_Poll_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_Poll_Choices_On_Conflict>;
};

/** on_conflict condition type for table "posts_poll_choices" */
export type Posts_Poll_Choices_On_Conflict = {
  constraint: Posts_Poll_Choices_Constraint;
  update_columns?: Array<Posts_Poll_Choices_Update_Column>;
  where?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "posts_poll_choices". */
export type Posts_Poll_Choices_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  poll_name?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  votes_aggregate?: InputMaybe<Posts_Poll_Choices_Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: posts_poll_choices */
export type Posts_Poll_Choices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "posts_poll_choices" */
export enum Posts_Poll_Choices_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PollName = 'poll_name',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "posts_poll_choices" */
export type Posts_Poll_Choices_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  poll_name?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "posts_poll_choices" */
export type Posts_Poll_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Posts_Poll_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Poll_Choices_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  poll_name?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "posts_poll_choices" */
export enum Posts_Poll_Choices_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PollName = 'poll_name',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Posts_Poll_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Posts_Poll_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Posts_Poll_Choices_Bool_Exp;
};

/** columns and relationships of "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes = {
  __typename?: 'posts_poll_choices_votes';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  poll_choice_id: Scalars['uuid'];
  /** An object relationship */
  posts_poll_choice: Posts_Poll_Choices;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  voted_by: Scalars['uuid'];
};

/** aggregated selection of "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Aggregate = {
  __typename?: 'posts_poll_choices_votes_aggregate';
  aggregate?: Maybe<Posts_Poll_Choices_Votes_Aggregate_Fields>;
  nodes: Array<Posts_Poll_Choices_Votes>;
};

export type Posts_Poll_Choices_Votes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Posts_Poll_Choices_Votes_Aggregate_Bool_Exp_Count>;
};

export type Posts_Poll_Choices_Votes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Posts_Poll_Choices_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Aggregate_Fields = {
  __typename?: 'posts_poll_choices_votes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Posts_Poll_Choices_Votes_Max_Fields>;
  min?: Maybe<Posts_Poll_Choices_Votes_Min_Fields>;
};

/** aggregate fields of "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Posts_Poll_Choices_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Posts_Poll_Choices_Votes_Max_Order_By>;
  min?: InputMaybe<Posts_Poll_Choices_Votes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Arr_Rel_Insert_Input = {
  data: Array<Posts_Poll_Choices_Votes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_Poll_Choices_Votes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "posts_poll_choices_votes". All fields are combined with a logical 'AND'. */
export type Posts_Poll_Choices_Votes_Bool_Exp = {
  _and?: InputMaybe<Array<Posts_Poll_Choices_Votes_Bool_Exp>>;
  _not?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
  _or?: InputMaybe<Array<Posts_Poll_Choices_Votes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  poll_choice_id?: InputMaybe<Uuid_Comparison_Exp>;
  posts_poll_choice?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  voted_by?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "posts_poll_choices_votes" */
export enum Posts_Poll_Choices_Votes_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPollVotesPkey = 'posts_poll_votes_pkey',
}

/** input type for inserting data into table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  poll_choice_id?: InputMaybe<Scalars['uuid']>;
  posts_poll_choice?: InputMaybe<Posts_Poll_Choices_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  voted_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Posts_Poll_Choices_Votes_Max_Fields = {
  __typename?: 'posts_poll_choices_votes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  poll_choice_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voted_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  poll_choice_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  voted_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Posts_Poll_Choices_Votes_Min_Fields = {
  __typename?: 'posts_poll_choices_votes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  poll_choice_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voted_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  poll_choice_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  voted_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Mutation_Response = {
  __typename?: 'posts_poll_choices_votes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Posts_Poll_Choices_Votes>;
};

/** on_conflict condition type for table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_On_Conflict = {
  constraint: Posts_Poll_Choices_Votes_Constraint;
  update_columns?: Array<Posts_Poll_Choices_Votes_Update_Column>;
  where?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "posts_poll_choices_votes". */
export type Posts_Poll_Choices_Votes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  poll_choice_id?: InputMaybe<Order_By>;
  posts_poll_choice?: InputMaybe<Posts_Poll_Choices_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  voted_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: posts_poll_choices_votes */
export type Posts_Poll_Choices_Votes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "posts_poll_choices_votes" */
export enum Posts_Poll_Choices_Votes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PollChoiceId = 'poll_choice_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VotedBy = 'voted_by',
}

/** input type for updating data in table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  poll_choice_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  voted_by?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "posts_poll_choices_votes" */
export type Posts_Poll_Choices_Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Posts_Poll_Choices_Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Poll_Choices_Votes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  poll_choice_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  voted_by?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "posts_poll_choices_votes" */
export enum Posts_Poll_Choices_Votes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PollChoiceId = 'poll_choice_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VotedBy = 'voted_by',
}

export type Posts_Poll_Choices_Votes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Posts_Poll_Choices_Votes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Posts_Poll_Choices_Votes_Bool_Exp;
};

/** select columns of table "posts" */
export enum Posts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisappearedAt = 'disappeared_at',
  /** column name */
  HolidayId = 'holiday_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  PostType = 'post_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Visibility = 'visibility',
}

/** select "posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "posts" */
export enum Posts_Select_Column_Posts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "posts" */
export enum Posts_Select_Column_Posts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "posts" */
export type Posts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  disappeared_at?: InputMaybe<Scalars['timestamptz']>;
  holiday_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  post_type?: InputMaybe<Enum_Post_Type_Enum>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "posts" */
export type Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  disappeared_at?: InputMaybe<Scalars['timestamptz']>;
  holiday_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  post_type?: InputMaybe<Enum_Post_Type_Enum>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<Scalars['String']>;
};

/** update columns of table "posts" */
export enum Posts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisappearedAt = 'disappeared_at',
  /** column name */
  HolidayId = 'holiday_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  PostType = 'post_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Visibility = 'visibility',
}

export type Posts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Posts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Posts_Bool_Exp;
};

/** Profile of employees [manager-1,manager-2,employee] */
export type Profile = {
  __typename?: 'profile';
  about_me?: Maybe<Scalars['String']>;
  activity_status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_activity_status_time" */
  activity_status_since?: Maybe<Scalars['timestamptz']>;
  additional_fields: Scalars['jsonb'];
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  agora_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  avatar_file?: Maybe<Storage_Files>;
  avatar_file_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  date_of_birth?: Maybe<Scalars['String']>;
  /** An object relationship */
  enum_user_gender?: Maybe<Enum_User_Gender>;
  gender?: Maybe<Enum_User_Gender_Enum>;
  id: Scalars['uuid'];
  invited_by?: Maybe<Scalars['uuid']>;
  is_completed: Scalars['Boolean'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization_role?: Maybe<Organization_Roles>;
  organization_role_id?: Maybe<Scalars['uuid']>;
  pronouns?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByUserId: Users;
  user_id: Scalars['uuid'];
};

/** Profile of employees [manager-1,manager-2,employee] */
export type ProfileAdditional_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

export type Profile_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Profile_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Profile_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Profile_Aggregate_Bool_Exp_Count>;
};

export type Profile_Aggregate_Bool_Exp_Bool_And = {
  arguments: Profile_Select_Column_Profile_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Profile_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Profile_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Profile_Select_Column_Profile_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Profile_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Profile_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Profile_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  avg?: Maybe<Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
  stddev?: Maybe<Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Sum_Fields>;
  var_pop?: Maybe<Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Var_Samp_Fields>;
  variance?: Maybe<Profile_Variance_Fields>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile" */
export type Profile_Aggregate_Order_By = {
  avg?: InputMaybe<Profile_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Profile_Max_Order_By>;
  min?: InputMaybe<Profile_Min_Order_By>;
  stddev?: InputMaybe<Profile_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Profile_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Profile_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Profile_Sum_Order_By>;
  var_pop?: InputMaybe<Profile_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Profile_Var_Samp_Order_By>;
  variance?: InputMaybe<Profile_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Profile_Append_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "profile" */
export type Profile_Arr_Rel_Insert_Input = {
  data: Array<Profile_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Avg_Fields = {
  __typename?: 'profile_avg_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile" */
export type Profile_Avg_Order_By = {
  agora_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Bool_Exp>>;
  _not?: InputMaybe<Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Bool_Exp>>;
  about_me?: InputMaybe<String_Comparison_Exp>;
  activity_status?: InputMaybe<String_Comparison_Exp>;
  activity_status_since?: InputMaybe<Timestamptz_Comparison_Exp>;
  additional_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  agora_id?: InputMaybe<Int_Comparison_Exp>;
  avatar_file?: InputMaybe<Storage_Files_Bool_Exp>;
  avatar_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: InputMaybe<String_Comparison_Exp>;
  enum_user_gender?: InputMaybe<Enum_User_Gender_Bool_Exp>;
  gender?: InputMaybe<Enum_User_Gender_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  organization_role_id?: InputMaybe<Uuid_Comparison_Exp>;
  pronouns?: InputMaybe<String_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByUserId?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile" */
export enum Profile_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfilePkey = 'profile_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Profile_Delete_At_Path_Input = {
  additional_fields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Profile_Delete_Elem_Input = {
  additional_fields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Profile_Delete_Key_Input = {
  additional_fields?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "profile" */
export type Profile_Inc_Input = {
  agora_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "profile" */
export type Profile_Insert_Input = {
  about_me?: InputMaybe<Scalars['String']>;
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  agora_id?: InputMaybe<Scalars['Int']>;
  avatar_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  avatar_file_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  enum_user_gender?: InputMaybe<Enum_User_Gender_Obj_Rel_Insert_Input>;
  gender?: InputMaybe<Enum_User_Gender_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  organization_role_id?: InputMaybe<Scalars['uuid']>;
  pronouns?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  about_me?: Maybe<Scalars['String']>;
  address_id?: Maybe<Scalars['uuid']>;
  agora_id?: Maybe<Scalars['Int']>;
  avatar_file_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invited_by?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  organization_role_id?: Maybe<Scalars['uuid']>;
  pronouns?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "profile" */
export type Profile_Max_Order_By = {
  about_me?: InputMaybe<Order_By>;
  address_id?: InputMaybe<Order_By>;
  agora_id?: InputMaybe<Order_By>;
  avatar_file_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_role_id?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  about_me?: Maybe<Scalars['String']>;
  address_id?: Maybe<Scalars['uuid']>;
  agora_id?: Maybe<Scalars['Int']>;
  avatar_file_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invited_by?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  organization_role_id?: Maybe<Scalars['uuid']>;
  pronouns?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "profile" */
export type Profile_Min_Order_By = {
  about_me?: InputMaybe<Order_By>;
  address_id?: InputMaybe<Order_By>;
  agora_id?: InputMaybe<Order_By>;
  avatar_file_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_role_id?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profile" */
export type Profile_Mutation_Response = {
  __typename?: 'profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile>;
};

/** on_conflict condition type for table "profile" */
export type Profile_On_Conflict = {
  constraint: Profile_Constraint;
  update_columns?: Array<Profile_Update_Column>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "profile". */
export type Profile_Order_By = {
  about_me?: InputMaybe<Order_By>;
  activity_status?: InputMaybe<Order_By>;
  activity_status_since?: InputMaybe<Order_By>;
  additional_fields?: InputMaybe<Order_By>;
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  agora_id?: InputMaybe<Order_By>;
  avatar_file?: InputMaybe<Storage_Files_Order_By>;
  avatar_file_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  enum_user_gender?: InputMaybe<Enum_User_Gender_Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  is_completed?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  organization_role_id?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByUserId?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profile */
export type Profile_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Profile_Prepend_Input = {
  additional_fields?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  AgoraId = 'agora_id',
  /** column name */
  AvatarFileId = 'avatar_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationRoleId = 'organization_role_id',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "profile_aggregate_bool_exp_bool_and_arguments_columns" columns of table "profile" */
export enum Profile_Select_Column_Profile_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed',
}

/** select "profile_aggregate_bool_exp_bool_or_arguments_columns" columns of table "profile" */
export enum Profile_Select_Column_Profile_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed',
}

/** input type for updating data in table "profile" */
export type Profile_Set_Input = {
  about_me?: InputMaybe<Scalars['String']>;
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address_id?: InputMaybe<Scalars['uuid']>;
  agora_id?: InputMaybe<Scalars['Int']>;
  avatar_file_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Enum_User_Gender_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  organization_role_id?: InputMaybe<Scalars['uuid']>;
  pronouns?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Profile_Stddev_Fields = {
  __typename?: 'profile_stddev_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile" */
export type Profile_Stddev_Order_By = {
  agora_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Stddev_Pop_Fields = {
  __typename?: 'profile_stddev_pop_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile" */
export type Profile_Stddev_Pop_Order_By = {
  agora_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Stddev_Samp_Fields = {
  __typename?: 'profile_stddev_samp_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile" */
export type Profile_Stddev_Samp_Order_By = {
  agora_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "profile" */
export type Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profile_Stream_Cursor_Value_Input = {
  about_me?: InputMaybe<Scalars['String']>;
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  address_id?: InputMaybe<Scalars['uuid']>;
  agora_id?: InputMaybe<Scalars['Int']>;
  avatar_file_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Enum_User_Gender_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  organization_role_id?: InputMaybe<Scalars['uuid']>;
  pronouns?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Profile_Sum_Fields = {
  __typename?: 'profile_sum_fields';
  agora_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "profile" */
export type Profile_Sum_Order_By = {
  agora_id?: InputMaybe<Order_By>;
};

/** update columns of table "profile" */
export enum Profile_Update_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  AdditionalFields = 'additional_fields',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  AgoraId = 'agora_id',
  /** column name */
  AvatarFileId = 'avatar_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationRoleId = 'organization_role_id',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Profile_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Profile_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Profile_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Profile_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Profile_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Profile_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Profile_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Profile_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Profile_Var_Pop_Fields = {
  __typename?: 'profile_var_pop_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile" */
export type Profile_Var_Pop_Order_By = {
  agora_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Var_Samp_Fields = {
  __typename?: 'profile_var_samp_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile" */
export type Profile_Var_Samp_Order_By = {
  agora_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Variance_Fields = {
  __typename?: 'profile_variance_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile" */
export type Profile_Variance_Order_By = {
  agora_id?: InputMaybe<Order_By>;
};

export type ProjList = {
  __typename?: 'projList';
  projectTitle?: Maybe<Scalars['String']>;
};

/** Projects in an organization */
export type Project = {
  __typename?: 'project';
  /** An object relationship */
  IconStoragefile?: Maybe<Storage_Files>;
  chat_group_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  chat_room?: Maybe<Chat_Room>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  enum_project_status?: Maybe<Enum_Project_Status>;
  /** An array relationship */
  filter_definitions: Array<Filter_Definition>;
  /** An aggregate relationship */
  filter_definitions_aggregate: Filter_Definition_Aggregate;
  id: Scalars['uuid'];
  is_billable: Scalars['Boolean'];
  is_deleted: Scalars['Boolean'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  owned_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project_client: Project_Client;
  project_icon?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  project_links: Array<Project_Links>;
  /** An aggregate relationship */
  project_links_aggregate: Project_Links_Aggregate;
  /** An array relationship */
  project_members: Array<Project_Members>;
  /** An aggregate relationship */
  project_members_aggregate: Project_Members_Aggregate;
  /** An array relationship */
  project_milestones: Array<Project_Milestones>;
  /** An aggregate relationship */
  project_milestones_aggregate: Project_Milestones_Aggregate;
  /** An object relationship */
  project_notification_setting?: Maybe<Project_Notification_Settings>;
  /** An array relationship */
  project_section: Array<Project_Section>;
  /** An aggregate relationship */
  project_section_aggregate: Project_Section_Aggregate;
  /** An array relationship */
  project_sprints: Array<Project_Sprints>;
  /** An aggregate relationship */
  project_sprints_aggregate: Project_Sprints_Aggregate;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  status?: Maybe<Enum_Project_Status_Enum>;
  /** An array relationship */
  task_unscheduleds: Array<Task_Unscheduled>;
  /** An aggregate relationship */
  task_unscheduleds_aggregate: Task_Unscheduled_Aggregate;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByOwnedBy?: Maybe<Users>;
};

/** Projects in an organization */
export type ProjectFilter_DefinitionsArgs = {
  distinct_on?: InputMaybe<Array<Filter_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filter_Definition_Order_By>>;
  where?: InputMaybe<Filter_Definition_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectFilter_Definitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filter_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filter_Definition_Order_By>>;
  where?: InputMaybe<Filter_Definition_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_LinksArgs = {
  distinct_on?: InputMaybe<Array<Project_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Links_Order_By>>;
  where?: InputMaybe<Project_Links_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Links_Order_By>>;
  where?: InputMaybe<Project_Links_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_MembersArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_Order_By>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_Order_By>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_MilestonesArgs = {
  distinct_on?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Milestones_Order_By>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_Milestones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Milestones_Order_By>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_SectionArgs = {
  distinct_on?: InputMaybe<Array<Project_Section_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Section_Order_By>>;
  where?: InputMaybe<Project_Section_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_Section_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Section_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Section_Order_By>>;
  where?: InputMaybe<Project_Section_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_SprintsArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectProject_Sprints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectTask_UnscheduledsArgs = {
  distinct_on?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Unscheduled_Order_By>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectTask_Unscheduleds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Unscheduled_Order_By>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Projects in an organization */
export type ProjectTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "project" */
export type Project_Aggregate = {
  __typename?: 'project_aggregate';
  aggregate?: Maybe<Project_Aggregate_Fields>;
  nodes: Array<Project>;
};

export type Project_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_Aggregate_Bool_Exp_Count>;
};

export type Project_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project" */
export type Project_Aggregate_Fields = {
  __typename?: 'project_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Max_Fields>;
  min?: Maybe<Project_Min_Fields>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project" */
export type Project_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Max_Order_By>;
  min?: InputMaybe<Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project" */
export type Project_Arr_Rel_Insert_Input = {
  data: Array<Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
  IconStoragefile?: InputMaybe<Storage_Files_Bool_Exp>;
  _and?: InputMaybe<Array<Project_Bool_Exp>>;
  _not?: InputMaybe<Project_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Bool_Exp>>;
  chat_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  chat_room?: InputMaybe<Chat_Room_Bool_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  enum_project_status?: InputMaybe<Enum_Project_Status_Bool_Exp>;
  filter_definitions?: InputMaybe<Filter_Definition_Bool_Exp>;
  filter_definitions_aggregate?: InputMaybe<Filter_Definition_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_billable?: InputMaybe<Boolean_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  project_client?: InputMaybe<Project_Client_Bool_Exp>;
  project_icon?: InputMaybe<Uuid_Comparison_Exp>;
  project_links?: InputMaybe<Project_Links_Bool_Exp>;
  project_links_aggregate?: InputMaybe<Project_Links_Aggregate_Bool_Exp>;
  project_members?: InputMaybe<Project_Members_Bool_Exp>;
  project_members_aggregate?: InputMaybe<Project_Members_Aggregate_Bool_Exp>;
  project_milestones?: InputMaybe<Project_Milestones_Bool_Exp>;
  project_milestones_aggregate?: InputMaybe<Project_Milestones_Aggregate_Bool_Exp>;
  project_notification_setting?: InputMaybe<Project_Notification_Settings_Bool_Exp>;
  project_section?: InputMaybe<Project_Section_Bool_Exp>;
  project_section_aggregate?: InputMaybe<Project_Section_Aggregate_Bool_Exp>;
  project_sprints?: InputMaybe<Project_Sprints_Bool_Exp>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<Enum_Project_Status_Enum_Comparison_Exp>;
  task_unscheduleds?: InputMaybe<Task_Unscheduled_Bool_Exp>;
  task_unscheduleds_aggregate?: InputMaybe<Task_Unscheduled_Aggregate_Bool_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByOwnedBy?: InputMaybe<Users_Bool_Exp>;
};

/** Client for which projects are happening */
export type Project_Client = {
  __typename?: 'project_client';
  client_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** Client for which projects are happening */
export type Project_ClientProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Client for which projects are happening */
export type Project_ClientProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** aggregated selection of "project_client" */
export type Project_Client_Aggregate = {
  __typename?: 'project_client_aggregate';
  aggregate?: Maybe<Project_Client_Aggregate_Fields>;
  nodes: Array<Project_Client>;
};

export type Project_Client_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Client_Aggregate_Bool_Exp_Count>;
};

export type Project_Client_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Client_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Client_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_client" */
export type Project_Client_Aggregate_Fields = {
  __typename?: 'project_client_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Client_Max_Fields>;
  min?: Maybe<Project_Client_Min_Fields>;
};

/** aggregate fields of "project_client" */
export type Project_Client_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Client_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_client" */
export type Project_Client_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Client_Max_Order_By>;
  min?: InputMaybe<Project_Client_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_client" */
export type Project_Client_Arr_Rel_Insert_Input = {
  data: Array<Project_Client_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Client_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_client". All fields are combined with a logical 'AND'. */
export type Project_Client_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Client_Bool_Exp>>;
  _not?: InputMaybe<Project_Client_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Client_Bool_Exp>>;
  client_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_client" */
export enum Project_Client_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectClientsPkey = 'project_clients_pkey',
}

/** input type for inserting data into table "project_client" */
export type Project_Client_Insert_Input = {
  client_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Project_Client_Max_Fields = {
  __typename?: 'project_client_max_fields';
  client_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project_client" */
export type Project_Client_Max_Order_By = {
  client_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Client_Min_Fields = {
  __typename?: 'project_client_min_fields';
  client_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "project_client" */
export type Project_Client_Min_Order_By = {
  client_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_client" */
export type Project_Client_Mutation_Response = {
  __typename?: 'project_client_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Client>;
};

/** input type for inserting object relation for remote table "project_client" */
export type Project_Client_Obj_Rel_Insert_Input = {
  data: Project_Client_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Client_On_Conflict>;
};

/** on_conflict condition type for table "project_client" */
export type Project_Client_On_Conflict = {
  constraint: Project_Client_Constraint;
  update_columns?: Array<Project_Client_Update_Column>;
  where?: InputMaybe<Project_Client_Bool_Exp>;
};

/** Ordering options when selecting data from "project_client". */
export type Project_Client_Order_By = {
  client_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_client */
export type Project_Client_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_client" */
export enum Project_Client_Select_Column {
  /** column name */
  ClientName = 'client_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "project_client" */
export type Project_Client_Set_Input = {
  client_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "project_client" */
export type Project_Client_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Client_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Client_Stream_Cursor_Value_Input = {
  client_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "project_client" */
export enum Project_Client_Update_Column {
  /** column name */
  ClientName = 'client_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Client_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Client_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Client_Bool_Exp;
};

/** unique or primary key constraints on table "project" */
export enum Project_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey',
}

/** input type for inserting data into table "project" */
export type Project_Insert_Input = {
  IconStoragefile?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  chat_group_id?: InputMaybe<Scalars['uuid']>;
  chat_room?: InputMaybe<Chat_Room_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  enum_project_status?: InputMaybe<Enum_Project_Status_Obj_Rel_Insert_Input>;
  filter_definitions?: InputMaybe<Filter_Definition_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_billable?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  project_client?: InputMaybe<Project_Client_Obj_Rel_Insert_Input>;
  project_icon?: InputMaybe<Scalars['uuid']>;
  project_links?: InputMaybe<Project_Links_Arr_Rel_Insert_Input>;
  project_members?: InputMaybe<Project_Members_Arr_Rel_Insert_Input>;
  project_milestones?: InputMaybe<Project_Milestones_Arr_Rel_Insert_Input>;
  project_notification_setting?: InputMaybe<Project_Notification_Settings_Obj_Rel_Insert_Input>;
  project_section?: InputMaybe<Project_Section_Arr_Rel_Insert_Input>;
  project_sprints?: InputMaybe<Project_Sprints_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Enum_Project_Status_Enum>;
  task_unscheduleds?: InputMaybe<Task_Unscheduled_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByOwnedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** The links associated with a project */
export type Project_Links = {
  __typename?: 'project_links';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "project_links" */
export type Project_Links_Aggregate = {
  __typename?: 'project_links_aggregate';
  aggregate?: Maybe<Project_Links_Aggregate_Fields>;
  nodes: Array<Project_Links>;
};

export type Project_Links_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Links_Aggregate_Bool_Exp_Count>;
};

export type Project_Links_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Links_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_links" */
export type Project_Links_Aggregate_Fields = {
  __typename?: 'project_links_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Links_Max_Fields>;
  min?: Maybe<Project_Links_Min_Fields>;
};

/** aggregate fields of "project_links" */
export type Project_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_links" */
export type Project_Links_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Links_Max_Order_By>;
  min?: InputMaybe<Project_Links_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_links" */
export type Project_Links_Arr_Rel_Insert_Input = {
  data: Array<Project_Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Links_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_links". All fields are combined with a logical 'AND'. */
export type Project_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Links_Bool_Exp>>;
  _not?: InputMaybe<Project_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Links_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_links" */
export enum Project_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectLinksPkey = 'project_links_pkey',
}

/** input type for inserting data into table "project_links" */
export type Project_Links_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Links_Max_Fields = {
  __typename?: 'project_links_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_links" */
export type Project_Links_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Links_Min_Fields = {
  __typename?: 'project_links_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_links" */
export type Project_Links_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_links" */
export type Project_Links_Mutation_Response = {
  __typename?: 'project_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Links>;
};

/** on_conflict condition type for table "project_links" */
export type Project_Links_On_Conflict = {
  constraint: Project_Links_Constraint;
  update_columns?: Array<Project_Links_Update_Column>;
  where?: InputMaybe<Project_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "project_links". */
export type Project_Links_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_links */
export type Project_Links_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_links" */
export enum Project_Links_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "project_links" */
export type Project_Links_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_links" */
export type Project_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Links_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_links" */
export enum Project_Links_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

export type Project_Links_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Links_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Links_Bool_Exp;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
  __typename?: 'project_max_fields';
  chat_group_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  project_icon?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project" */
export type Project_Max_Order_By = {
  chat_group_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  project_icon?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Members part of a project. These people have access to projects and notifications */
export type Project_Members = {
  __typename?: 'project_members';
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  release_date: Scalars['date'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "project_members" */
export type Project_Members_Aggregate = {
  __typename?: 'project_members_aggregate';
  aggregate?: Maybe<Project_Members_Aggregate_Fields>;
  nodes: Array<Project_Members>;
};

export type Project_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Members_Aggregate_Bool_Exp_Count>;
};

export type Project_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_members" */
export type Project_Members_Aggregate_Fields = {
  __typename?: 'project_members_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Members_Max_Fields>;
  min?: Maybe<Project_Members_Min_Fields>;
};

/** aggregate fields of "project_members" */
export type Project_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_members" */
export type Project_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Members_Max_Order_By>;
  min?: InputMaybe<Project_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_members" */
export type Project_Members_Arr_Rel_Insert_Input = {
  data: Array<Project_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_members". All fields are combined with a logical 'AND'. */
export type Project_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Members_Bool_Exp>>;
  _not?: InputMaybe<Project_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Members_Bool_Exp>>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  release_date?: InputMaybe<Date_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_members" */
export enum Project_Members_Constraint {
  /** unique or primary key constraint on columns "project_id", "user_id" */
  ProjectMembersPkey = 'project_members_pkey',
}

/** input type for inserting data into table "project_members" */
export type Project_Members_Insert_Input = {
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  release_date?: InputMaybe<Scalars['date']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Members_Max_Fields = {
  __typename?: 'project_members_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
  release_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_members" */
export type Project_Members_Max_Order_By = {
  project_id?: InputMaybe<Order_By>;
  release_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Members_Min_Fields = {
  __typename?: 'project_members_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
  release_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_members" */
export type Project_Members_Min_Order_By = {
  project_id?: InputMaybe<Order_By>;
  release_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_members" */
export type Project_Members_Mutation_Response = {
  __typename?: 'project_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Members>;
};

/** on_conflict condition type for table "project_members" */
export type Project_Members_On_Conflict = {
  constraint: Project_Members_Constraint;
  update_columns?: Array<Project_Members_Update_Column>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "project_members". */
export type Project_Members_Order_By = {
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  release_date?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_members */
export type Project_Members_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "project_members" */
export enum Project_Members_Select_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReleaseDate = 'release_date',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "project_members" */
export type Project_Members_Set_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
  release_date?: InputMaybe<Scalars['date']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project_members" */
export type Project_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Members_Stream_Cursor_Value_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
  release_date?: InputMaybe<Scalars['date']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "project_members" */
export enum Project_Members_Update_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReleaseDate = 'release_date',
  /** column name */
  UserId = 'user_id',
}

export type Project_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Members_Bool_Exp;
};

/** Milestones associated with project */
export type Project_Milestones = {
  __typename?: 'project_milestones';
  created_at: Scalars['timestamptz'];
  date: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  enum_project_milestone_status: Enum_Project_Milestone_Status;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  status: Enum_Project_Milestone_Status_Enum;
  /** An array relationship */
  task_milestones: Array<Task_Milestones>;
  /** An aggregate relationship */
  task_milestones_aggregate: Task_Milestones_Aggregate;
};

/** Milestones associated with project */
export type Project_MilestonesTask_MilestonesArgs = {
  distinct_on?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Milestones_Order_By>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

/** Milestones associated with project */
export type Project_MilestonesTask_Milestones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Milestones_Order_By>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

/** aggregated selection of "project_milestones" */
export type Project_Milestones_Aggregate = {
  __typename?: 'project_milestones_aggregate';
  aggregate?: Maybe<Project_Milestones_Aggregate_Fields>;
  nodes: Array<Project_Milestones>;
};

export type Project_Milestones_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Milestones_Aggregate_Bool_Exp_Count>;
};

export type Project_Milestones_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Milestones_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_milestones" */
export type Project_Milestones_Aggregate_Fields = {
  __typename?: 'project_milestones_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Milestones_Max_Fields>;
  min?: Maybe<Project_Milestones_Min_Fields>;
};

/** aggregate fields of "project_milestones" */
export type Project_Milestones_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_milestones" */
export type Project_Milestones_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Milestones_Max_Order_By>;
  min?: InputMaybe<Project_Milestones_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_milestones" */
export type Project_Milestones_Arr_Rel_Insert_Input = {
  data: Array<Project_Milestones_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Milestones_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_milestones". All fields are combined with a logical 'AND'. */
export type Project_Milestones_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Milestones_Bool_Exp>>;
  _not?: InputMaybe<Project_Milestones_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Milestones_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  enum_project_milestone_status?: InputMaybe<Enum_Project_Milestone_Status_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Enum_Project_Milestone_Status_Enum_Comparison_Exp>;
  task_milestones?: InputMaybe<Task_Milestones_Bool_Exp>;
  task_milestones_aggregate?: InputMaybe<Task_Milestones_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "project_milestones" */
export enum Project_Milestones_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProjectMilestonesNameKey = 'project_milestones_name_key',
  /** unique or primary key constraint on columns "id" */
  ProjectMilestonesPkey = 'project_milestones_pkey',
}

/** input type for inserting data into table "project_milestones" */
export type Project_Milestones_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  enum_project_milestone_status?: InputMaybe<Enum_Project_Milestone_Status_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Project_Milestone_Status_Enum>;
  task_milestones?: InputMaybe<Task_Milestones_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Project_Milestones_Max_Fields = {
  __typename?: 'project_milestones_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_milestones" */
export type Project_Milestones_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Milestones_Min_Fields = {
  __typename?: 'project_milestones_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_milestones" */
export type Project_Milestones_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_milestones" */
export type Project_Milestones_Mutation_Response = {
  __typename?: 'project_milestones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Milestones>;
};

/** input type for inserting object relation for remote table "project_milestones" */
export type Project_Milestones_Obj_Rel_Insert_Input = {
  data: Project_Milestones_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Milestones_On_Conflict>;
};

/** on_conflict condition type for table "project_milestones" */
export type Project_Milestones_On_Conflict = {
  constraint: Project_Milestones_Constraint;
  update_columns?: Array<Project_Milestones_Update_Column>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

/** Ordering options when selecting data from "project_milestones". */
export type Project_Milestones_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  enum_project_milestone_status?: InputMaybe<Enum_Project_Milestone_Status_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  task_milestones_aggregate?: InputMaybe<Task_Milestones_Aggregate_Order_By>;
};

/** primary key columns input for table: project_milestones */
export type Project_Milestones_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_milestones" */
export enum Project_Milestones_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "project_milestones" */
export type Project_Milestones_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Project_Milestone_Status_Enum>;
};

/** Streaming cursor of the table "project_milestones" */
export type Project_Milestones_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Milestones_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Milestones_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Project_Milestone_Status_Enum>;
};

/** update columns of table "project_milestones" */
export enum Project_Milestones_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
}

export type Project_Milestones_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Milestones_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Milestones_Bool_Exp;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
  __typename?: 'project_min_fields';
  chat_group_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  project_icon?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "project" */
export type Project_Min_Order_By = {
  chat_group_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  project_icon?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project" */
export type Project_Mutation_Response = {
  __typename?: 'project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project>;
};

/** Notification settings for projects */
export type Project_Notification_Settings = {
  __typename?: 'project_notification_settings';
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  task_assigned: Scalars['Boolean'];
  task_updated: Scalars['Boolean'];
};

/** aggregated selection of "project_notification_settings" */
export type Project_Notification_Settings_Aggregate = {
  __typename?: 'project_notification_settings_aggregate';
  aggregate?: Maybe<Project_Notification_Settings_Aggregate_Fields>;
  nodes: Array<Project_Notification_Settings>;
};

/** aggregate fields of "project_notification_settings" */
export type Project_Notification_Settings_Aggregate_Fields = {
  __typename?: 'project_notification_settings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Notification_Settings_Max_Fields>;
  min?: Maybe<Project_Notification_Settings_Min_Fields>;
};

/** aggregate fields of "project_notification_settings" */
export type Project_Notification_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Notification_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_notification_settings". All fields are combined with a logical 'AND'. */
export type Project_Notification_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Notification_Settings_Bool_Exp>>;
  _not?: InputMaybe<Project_Notification_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Notification_Settings_Bool_Exp>>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  task_assigned?: InputMaybe<Boolean_Comparison_Exp>;
  task_updated?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_notification_settings" */
export enum Project_Notification_Settings_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  ProjectNotificationSettingsPkey = 'project_notification_settings_pkey',
}

/** input type for inserting data into table "project_notification_settings" */
export type Project_Notification_Settings_Insert_Input = {
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  task_assigned?: InputMaybe<Scalars['Boolean']>;
  task_updated?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Project_Notification_Settings_Max_Fields = {
  __typename?: 'project_notification_settings_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Notification_Settings_Min_Fields = {
  __typename?: 'project_notification_settings_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project_notification_settings" */
export type Project_Notification_Settings_Mutation_Response = {
  __typename?: 'project_notification_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Notification_Settings>;
};

/** input type for inserting object relation for remote table "project_notification_settings" */
export type Project_Notification_Settings_Obj_Rel_Insert_Input = {
  data: Project_Notification_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Notification_Settings_On_Conflict>;
};

/** on_conflict condition type for table "project_notification_settings" */
export type Project_Notification_Settings_On_Conflict = {
  constraint: Project_Notification_Settings_Constraint;
  update_columns?: Array<Project_Notification_Settings_Update_Column>;
  where?: InputMaybe<Project_Notification_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "project_notification_settings". */
export type Project_Notification_Settings_Order_By = {
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  task_assigned?: InputMaybe<Order_By>;
  task_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_notification_settings */
export type Project_Notification_Settings_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** select columns of table "project_notification_settings" */
export enum Project_Notification_Settings_Select_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TaskAssigned = 'task_assigned',
  /** column name */
  TaskUpdated = 'task_updated',
}

/** input type for updating data in table "project_notification_settings" */
export type Project_Notification_Settings_Set_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
  task_assigned?: InputMaybe<Scalars['Boolean']>;
  task_updated?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "project_notification_settings" */
export type Project_Notification_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Notification_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Notification_Settings_Stream_Cursor_Value_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
  task_assigned?: InputMaybe<Scalars['Boolean']>;
  task_updated?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "project_notification_settings" */
export enum Project_Notification_Settings_Update_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TaskAssigned = 'task_assigned',
  /** column name */
  TaskUpdated = 'task_updated',
}

export type Project_Notification_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Notification_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Notification_Settings_Bool_Exp;
};

/** input type for inserting object relation for remote table "project" */
export type Project_Obj_Rel_Insert_Input = {
  data: Project_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** on_conflict condition type for table "project" */
export type Project_On_Conflict = {
  constraint: Project_Constraint;
  update_columns?: Array<Project_Update_Column>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Ordering options when selecting data from "project". */
export type Project_Order_By = {
  IconStoragefile?: InputMaybe<Storage_Files_Order_By>;
  chat_group_id?: InputMaybe<Order_By>;
  chat_room?: InputMaybe<Chat_Room_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  enum_project_status?: InputMaybe<Enum_Project_Status_Order_By>;
  filter_definitions_aggregate?: InputMaybe<Filter_Definition_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_billable?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  project_client?: InputMaybe<Project_Client_Order_By>;
  project_icon?: InputMaybe<Order_By>;
  project_links_aggregate?: InputMaybe<Project_Links_Aggregate_Order_By>;
  project_members_aggregate?: InputMaybe<Project_Members_Aggregate_Order_By>;
  project_milestones_aggregate?: InputMaybe<Project_Milestones_Aggregate_Order_By>;
  project_notification_setting?: InputMaybe<Project_Notification_Settings_Order_By>;
  project_section_aggregate?: InputMaybe<Project_Section_Aggregate_Order_By>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  task_unscheduleds_aggregate?: InputMaybe<Task_Unscheduled_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByOwnedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: project */
export type Project_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "project_section" */
export type Project_Section = {
  __typename?: 'project_section';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  updated_at: Scalars['timestamp'];
};

/** columns and relationships of "project_section" */
export type Project_SectionTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "project_section" */
export type Project_SectionTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "project_section" */
export type Project_Section_Aggregate = {
  __typename?: 'project_section_aggregate';
  aggregate?: Maybe<Project_Section_Aggregate_Fields>;
  nodes: Array<Project_Section>;
};

export type Project_Section_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Section_Aggregate_Bool_Exp_Count>;
};

export type Project_Section_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Section_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Section_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_section" */
export type Project_Section_Aggregate_Fields = {
  __typename?: 'project_section_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Section_Max_Fields>;
  min?: Maybe<Project_Section_Min_Fields>;
};

/** aggregate fields of "project_section" */
export type Project_Section_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Section_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_section" */
export type Project_Section_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Section_Max_Order_By>;
  min?: InputMaybe<Project_Section_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_section" */
export type Project_Section_Arr_Rel_Insert_Input = {
  data: Array<Project_Section_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Section_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_section". All fields are combined with a logical 'AND'. */
export type Project_Section_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Section_Bool_Exp>>;
  _not?: InputMaybe<Project_Section_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Section_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_section" */
export enum Project_Section_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectSectionPkey = 'project_section_pkey',
}

/** input type for inserting data into table "project_section" */
export type Project_Section_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Project_Section_Max_Fields = {
  __typename?: 'project_section_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "project_section" */
export type Project_Section_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Section_Min_Fields = {
  __typename?: 'project_section_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "project_section" */
export type Project_Section_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_section" */
export type Project_Section_Mutation_Response = {
  __typename?: 'project_section_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Section>;
};

/** input type for inserting object relation for remote table "project_section" */
export type Project_Section_Obj_Rel_Insert_Input = {
  data: Project_Section_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Section_On_Conflict>;
};

/** on_conflict condition type for table "project_section" */
export type Project_Section_On_Conflict = {
  constraint: Project_Section_Constraint;
  update_columns?: Array<Project_Section_Update_Column>;
  where?: InputMaybe<Project_Section_Bool_Exp>;
};

/** Ordering options when selecting data from "project_section". */
export type Project_Section_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_section */
export type Project_Section_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_section" */
export enum Project_Section_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "project_section" */
export type Project_Section_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "project_section" */
export type Project_Section_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Section_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Section_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "project_section" */
export enum Project_Section_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Section_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Section_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Section_Bool_Exp;
};

/** select columns of table "project" */
export enum Project_Select_Column {
  /** column name */
  ChatGroupId = 'chat_group_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsBillable = 'is_billable',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  ProjectIcon = 'project_icon',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "project_aggregate_bool_exp_bool_and_arguments_columns" columns of table "project" */
export enum Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsBillable = 'is_billable',
  /** column name */
  IsDeleted = 'is_deleted',
}

/** select "project_aggregate_bool_exp_bool_or_arguments_columns" columns of table "project" */
export enum Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsBillable = 'is_billable',
  /** column name */
  IsDeleted = 'is_deleted',
}

/** input type for updating data in table "project" */
export type Project_Set_Input = {
  chat_group_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_billable?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  project_icon?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Enum_Project_Status_Enum>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Sprints that are part of a project */
export type Project_Sprints = {
  __typename?: 'project_sprints';
  /** An object relationship */
  calendar_event?: Maybe<Calendar_Event>;
  category: Enum_Project_Sprint_Category_Enum;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  duration_in_weeks: Scalars['numeric'];
  end_date: Scalars['date'];
  /** An object relationship */
  enum_project_sprint_category: Enum_Project_Sprint_Category;
  id: Scalars['uuid'];
  meeting_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  sort_position: Scalars['bigint'];
  start_date: Scalars['date'];
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** Sprints that are part of a project */
export type Project_SprintsTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Sprints that are part of a project */
export type Project_SprintsTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "project_sprints" */
export type Project_Sprints_Aggregate = {
  __typename?: 'project_sprints_aggregate';
  aggregate?: Maybe<Project_Sprints_Aggregate_Fields>;
  nodes: Array<Project_Sprints>;
};

export type Project_Sprints_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Sprints_Aggregate_Bool_Exp_Count>;
};

export type Project_Sprints_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Sprints_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_sprints" */
export type Project_Sprints_Aggregate_Fields = {
  __typename?: 'project_sprints_aggregate_fields';
  avg?: Maybe<Project_Sprints_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Sprints_Max_Fields>;
  min?: Maybe<Project_Sprints_Min_Fields>;
  stddev?: Maybe<Project_Sprints_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Sprints_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Sprints_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Sprints_Sum_Fields>;
  var_pop?: Maybe<Project_Sprints_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Sprints_Var_Samp_Fields>;
  variance?: Maybe<Project_Sprints_Variance_Fields>;
};

/** aggregate fields of "project_sprints" */
export type Project_Sprints_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_sprints" */
export type Project_Sprints_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Sprints_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Sprints_Max_Order_By>;
  min?: InputMaybe<Project_Sprints_Min_Order_By>;
  stddev?: InputMaybe<Project_Sprints_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Sprints_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Sprints_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Sprints_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Sprints_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Sprints_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Sprints_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_sprints" */
export type Project_Sprints_Arr_Rel_Insert_Input = {
  data: Array<Project_Sprints_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Sprints_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Sprints_Avg_Fields = {
  __typename?: 'project_sprints_avg_fields';
  duration_in_weeks?: Maybe<Scalars['Float']>;
  sort_position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_sprints" */
export type Project_Sprints_Avg_Order_By = {
  duration_in_weeks?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_sprints". All fields are combined with a logical 'AND'. */
export type Project_Sprints_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Sprints_Bool_Exp>>;
  _not?: InputMaybe<Project_Sprints_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Sprints_Bool_Exp>>;
  calendar_event?: InputMaybe<Calendar_Event_Bool_Exp>;
  category?: InputMaybe<Enum_Project_Sprint_Category_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  duration_in_weeks?: InputMaybe<Numeric_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  enum_project_sprint_category?: InputMaybe<Enum_Project_Sprint_Category_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meeting_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort_position?: InputMaybe<Bigint_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "project_sprints" */
export enum Project_Sprints_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectSprintsPkey = 'project_sprints_pkey',
}

/** input type for incrementing numeric columns in table "project_sprints" */
export type Project_Sprints_Inc_Input = {
  duration_in_weeks?: InputMaybe<Scalars['numeric']>;
  sort_position?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_sprints" */
export type Project_Sprints_Insert_Input = {
  calendar_event?: InputMaybe<Calendar_Event_Obj_Rel_Insert_Input>;
  category?: InputMaybe<Enum_Project_Sprint_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  duration_in_weeks?: InputMaybe<Scalars['numeric']>;
  end_date?: InputMaybe<Scalars['date']>;
  enum_project_sprint_category?: InputMaybe<Enum_Project_Sprint_Category_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  sort_position?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['date']>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Project_Sprints_Max_Fields = {
  __typename?: 'project_sprints_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  duration_in_weeks?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  sort_position?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project_sprints" */
export type Project_Sprints_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  duration_in_weeks?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Sprints_Min_Fields = {
  __typename?: 'project_sprints_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  duration_in_weeks?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  sort_position?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "project_sprints" */
export type Project_Sprints_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  duration_in_weeks?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_sprints" */
export type Project_Sprints_Mutation_Response = {
  __typename?: 'project_sprints_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Sprints>;
};

/** input type for inserting object relation for remote table "project_sprints" */
export type Project_Sprints_Obj_Rel_Insert_Input = {
  data: Project_Sprints_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Sprints_On_Conflict>;
};

/** on_conflict condition type for table "project_sprints" */
export type Project_Sprints_On_Conflict = {
  constraint: Project_Sprints_Constraint;
  update_columns?: Array<Project_Sprints_Update_Column>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** Ordering options when selecting data from "project_sprints". */
export type Project_Sprints_Order_By = {
  calendar_event?: InputMaybe<Calendar_Event_Order_By>;
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  duration_in_weeks?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  enum_project_sprint_category?: InputMaybe<Enum_Project_Sprint_Category_Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: project_sprints */
export type Project_Sprints_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_sprints" */
export enum Project_Sprints_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DurationInWeeks = 'duration_in_weeks',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SortPosition = 'sort_position',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "project_sprints" */
export type Project_Sprints_Set_Input = {
  category?: InputMaybe<Enum_Project_Sprint_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  duration_in_weeks?: InputMaybe<Scalars['numeric']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  sort_position?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Project_Sprints_Stddev_Fields = {
  __typename?: 'project_sprints_stddev_fields';
  duration_in_weeks?: Maybe<Scalars['Float']>;
  sort_position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_sprints" */
export type Project_Sprints_Stddev_Order_By = {
  duration_in_weeks?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Sprints_Stddev_Pop_Fields = {
  __typename?: 'project_sprints_stddev_pop_fields';
  duration_in_weeks?: Maybe<Scalars['Float']>;
  sort_position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_sprints" */
export type Project_Sprints_Stddev_Pop_Order_By = {
  duration_in_weeks?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Sprints_Stddev_Samp_Fields = {
  __typename?: 'project_sprints_stddev_samp_fields';
  duration_in_weeks?: Maybe<Scalars['Float']>;
  sort_position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_sprints" */
export type Project_Sprints_Stddev_Samp_Order_By = {
  duration_in_weeks?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_sprints" */
export type Project_Sprints_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Sprints_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Sprints_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Enum_Project_Sprint_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  duration_in_weeks?: InputMaybe<Scalars['numeric']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  sort_position?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Project_Sprints_Sum_Fields = {
  __typename?: 'project_sprints_sum_fields';
  duration_in_weeks?: Maybe<Scalars['numeric']>;
  sort_position?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "project_sprints" */
export type Project_Sprints_Sum_Order_By = {
  duration_in_weeks?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
};

/** update columns of table "project_sprints" */
export enum Project_Sprints_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DurationInWeeks = 'duration_in_weeks',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SortPosition = 'sort_position',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Sprints_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Sprints_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Sprints_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Sprints_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Sprints_Var_Pop_Fields = {
  __typename?: 'project_sprints_var_pop_fields';
  duration_in_weeks?: Maybe<Scalars['Float']>;
  sort_position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_sprints" */
export type Project_Sprints_Var_Pop_Order_By = {
  duration_in_weeks?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Sprints_Var_Samp_Fields = {
  __typename?: 'project_sprints_var_samp_fields';
  duration_in_weeks?: Maybe<Scalars['Float']>;
  sort_position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_sprints" */
export type Project_Sprints_Var_Samp_Order_By = {
  duration_in_weeks?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Sprints_Variance_Fields = {
  __typename?: 'project_sprints_variance_fields';
  duration_in_weeks?: Maybe<Scalars['Float']>;
  sort_position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_sprints" */
export type Project_Sprints_Variance_Order_By = {
  duration_in_weeks?: InputMaybe<Order_By>;
  sort_position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project" */
export type Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Stream_Cursor_Value_Input = {
  chat_group_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_billable?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  project_icon?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Enum_Project_Status_Enum>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "project" */
export enum Project_Update_Column {
  /** column name */
  ChatGroupId = 'chat_group_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsBillable = 'is_billable',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  ProjectIcon = 'project_icon',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "User_Peer_Review" */
  User_Peer_Review: Array<User_Peer_Review>;
  /** fetch aggregated fields from the table: "User_Peer_Review" */
  User_Peer_Review_aggregate: User_Peer_Review_Aggregate;
  /** fetch data from the table: "User_Peer_Review" using primary key columns */
  User_Peer_Review_by_pk?: Maybe<User_Peer_Review>;
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "assessment" */
  assessment: Array<Assessment>;
  /** An array relationship */
  assessment_Skills: Array<Assessment_Skills>;
  /** An aggregate relationship */
  assessment_Skills_aggregate: Assessment_Skills_Aggregate;
  /** fetch data from the table: "assessment_Skills" using primary key columns */
  assessment_Skills_by_pk?: Maybe<Assessment_Skills>;
  /** fetch aggregated fields from the table: "assessment" */
  assessment_aggregate: Assessment_Aggregate;
  /** fetch data from the table: "assessment" using primary key columns */
  assessment_by_pk?: Maybe<Assessment>;
  /** fetch data from the table: "assessment_feedback" */
  assessment_feedback: Array<Assessment_Feedback>;
  /** fetch aggregated fields from the table: "assessment_feedback" */
  assessment_feedback_aggregate: Assessment_Feedback_Aggregate;
  /** fetch data from the table: "assessment_feedback" using primary key columns */
  assessment_feedback_by_pk?: Maybe<Assessment_Feedback>;
  /** fetch data from the table: "assessment_record" */
  assessment_record: Array<Assessment_Record>;
  /** fetch aggregated fields from the table: "assessment_record" */
  assessment_record_aggregate: Assessment_Record_Aggregate;
  /** fetch data from the table: "assessment_record" using primary key columns */
  assessment_record_by_pk?: Maybe<Assessment_Record>;
  /** An array relationship */
  assessment_role_details: Array<Assessment_Role_Details>;
  /** An aggregate relationship */
  assessment_role_details_aggregate: Assessment_Role_Details_Aggregate;
  /** fetch data from the table: "assessment_role_details" using primary key columns */
  assessment_role_details_by_pk?: Maybe<Assessment_Role_Details>;
  /** fetch data from the table: "assessment_stage_career" */
  assessment_stage_career: Array<Assessment_Stage_Career>;
  /** fetch aggregated fields from the table: "assessment_stage_career" */
  assessment_stage_career_aggregate: Assessment_Stage_Career_Aggregate;
  /** fetch data from the table: "assessment_stage_career" using primary key columns */
  assessment_stage_career_by_pk?: Maybe<Assessment_Stage_Career>;
  /** fetch data from the table: "assessment_type" */
  assessment_type: Array<Assessment_Type>;
  /** fetch aggregated fields from the table: "assessment_type" */
  assessment_type_aggregate: Assessment_Type_Aggregate;
  /** fetch data from the table: "assessment_type" using primary key columns */
  assessment_type_by_pk?: Maybe<Assessment_Type>;
  /** fetch data from the table: "assign_career_development_plan" */
  assign_career_development_plan: Array<Assign_Career_Development_Plan>;
  /** fetch aggregated fields from the table: "assign_career_development_plan" */
  assign_career_development_plan_aggregate: Assign_Career_Development_Plan_Aggregate;
  /** fetch data from the table: "assign_career_development_plan" using primary key columns */
  assign_career_development_plan_by_pk?: Maybe<Assign_Career_Development_Plan>;
  /** fetch data from the table: "audit.user_state" */
  audit_user_state: Array<Audit_User_State>;
  /** fetch aggregated fields from the table: "audit.user_state" */
  audit_user_state_aggregate: Audit_User_State_Aggregate;
  /** fetch data from the table: "audit.user_state" using primary key columns */
  audit_user_state_by_pk?: Maybe<Audit_User_State>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** An array relationship */
  award_posts: Array<Award_Posts>;
  /** An aggregate relationship */
  award_posts_aggregate: Award_Posts_Aggregate;
  /** fetch data from the table: "award_posts" using primary key columns */
  award_posts_by_pk?: Maybe<Award_Posts>;
  /** An array relationship */
  awards: Array<Awards>;
  /** An aggregate relationship */
  awards_aggregate: Awards_Aggregate;
  /** fetch data from the table: "awards" using primary key columns */
  awards_by_pk?: Maybe<Awards>;
  /** fetch data from the table: "calendar" */
  calendar: Array<Calendar>;
  /** fetch aggregated fields from the table: "calendar" */
  calendar_aggregate: Calendar_Aggregate;
  /** fetch data from the table: "calendar_attendee" */
  calendar_attendee: Array<Calendar_Attendee>;
  /** fetch aggregated fields from the table: "calendar_attendee" */
  calendar_attendee_aggregate: Calendar_Attendee_Aggregate;
  /** fetch data from the table: "calendar_attendee" using primary key columns */
  calendar_attendee_by_pk?: Maybe<Calendar_Attendee>;
  /** fetch data from the table: "calendar" using primary key columns */
  calendar_by_pk?: Maybe<Calendar>;
  /** An array relationship */
  calendar_delete_events: Array<Calendar_Delete_Events>;
  /** An aggregate relationship */
  calendar_delete_events_aggregate: Calendar_Delete_Events_Aggregate;
  /** fetch data from the table: "calendar_delete_events" using primary key columns */
  calendar_delete_events_by_pk?: Maybe<Calendar_Delete_Events>;
  /** fetch data from the table: "calendar_event" */
  calendar_event: Array<Calendar_Event>;
  /** fetch aggregated fields from the table: "calendar_event" */
  calendar_event_aggregate: Calendar_Event_Aggregate;
  /** fetch data from the table: "calendar_event" using primary key columns */
  calendar_event_by_pk?: Maybe<Calendar_Event>;
  /** fetch data from the table: "calendar_event_material_view" */
  calendar_event_material_view: Array<Calendar_Event_Material_View>;
  /** fetch aggregated fields from the table: "calendar_event_material_view" */
  calendar_event_material_view_aggregate: Calendar_Event_Material_View_Aggregate;
  /** fetch data from the table: "calendar_meeting_call" */
  calendar_meeting_call: Array<Calendar_Meeting_Call>;
  /** fetch aggregated fields from the table: "calendar_meeting_call" */
  calendar_meeting_call_aggregate: Calendar_Meeting_Call_Aggregate;
  /** fetch data from the table: "calendar_meeting_call" using primary key columns */
  calendar_meeting_call_by_pk?: Maybe<Calendar_Meeting_Call>;
  /** fetch data from the table: "calendar_meeting_call_request" */
  calendar_meeting_call_request: Array<Calendar_Meeting_Call_Request>;
  /** fetch aggregated fields from the table: "calendar_meeting_call_request" */
  calendar_meeting_call_request_aggregate: Calendar_Meeting_Call_Request_Aggregate;
  /** fetch data from the table: "calendar_meeting_call_request" using primary key columns */
  calendar_meeting_call_request_by_pk?: Maybe<Calendar_Meeting_Call_Request>;
  /** fetch data from the table: "calendar_meeting_call_user" */
  calendar_meeting_call_user: Array<Calendar_Meeting_Call_User>;
  /** fetch aggregated fields from the table: "calendar_meeting_call_user" */
  calendar_meeting_call_user_aggregate: Calendar_Meeting_Call_User_Aggregate;
  /** fetch data from the table: "calendar_meeting_call_user" using primary key columns */
  calendar_meeting_call_user_by_pk?: Maybe<Calendar_Meeting_Call_User>;
  /** fetch data from the table: "calendar_meeting_view" */
  calendar_meeting_view: Array<Calendar_Meeting_View>;
  /** fetch aggregated fields from the table: "calendar_meeting_view" */
  calendar_meeting_view_aggregate: Calendar_Meeting_View_Aggregate;
  /** fetch data from the table: "call" */
  call: Array<Call>;
  /** fetch aggregated fields from the table: "call" */
  call_aggregate: Call_Aggregate;
  /** fetch data from the table: "call" using primary key columns */
  call_by_pk?: Maybe<Call>;
  /** fetch data from the table: "call_request" */
  call_request: Array<Call_Request>;
  /** fetch aggregated fields from the table: "call_request" */
  call_request_aggregate: Call_Request_Aggregate;
  /** fetch data from the table: "call_request" using primary key columns */
  call_request_by_pk?: Maybe<Call_Request>;
  /** fetch data from the table: "call_user" */
  call_user: Array<Call_User>;
  /** fetch aggregated fields from the table: "call_user" */
  call_user_aggregate: Call_User_Aggregate;
  /** fetch data from the table: "call_user" using primary key columns */
  call_user_by_pk?: Maybe<Call_User>;
  /** fetch data from the table: "career_development_detail" */
  career_development_detail: Array<Career_Development_Detail>;
  /** fetch aggregated fields from the table: "career_development_detail" */
  career_development_detail_aggregate: Career_Development_Detail_Aggregate;
  /** fetch data from the table: "career_development_detail" using primary key columns */
  career_development_detail_by_pk?: Maybe<Career_Development_Detail>;
  /** An array relationship */
  career_development_role_details: Array<Career_Development_Role_Details>;
  /** An aggregate relationship */
  career_development_role_details_aggregate: Career_Development_Role_Details_Aggregate;
  /** fetch data from the table: "career_development_role_details" using primary key columns */
  career_development_role_details_by_pk?: Maybe<Career_Development_Role_Details>;
  /** fetch data from the table: "career_external_certification" */
  career_external_certification: Array<Career_External_Certification>;
  /** fetch aggregated fields from the table: "career_external_certification" */
  career_external_certification_aggregate: Career_External_Certification_Aggregate;
  /** fetch data from the table: "career_external_certification" using primary key columns */
  career_external_certification_by_pk?: Maybe<Career_External_Certification>;
  /** fetch data from the table: "chat_message" */
  chat_message: Array<Chat_Message>;
  /** fetch data from the table: "chat_message_action" */
  chat_message_action: Array<Chat_Message_Action>;
  /** fetch aggregated fields from the table: "chat_message_action" */
  chat_message_action_aggregate: Chat_Message_Action_Aggregate;
  /** fetch data from the table: "chat_message_action" using primary key columns */
  chat_message_action_by_pk?: Maybe<Chat_Message_Action>;
  /** fetch aggregated fields from the table: "chat_message" */
  chat_message_aggregate: Chat_Message_Aggregate;
  /** fetch data from the table: "chat_message" using primary key columns */
  chat_message_by_pk?: Maybe<Chat_Message>;
  /** fetch data from the table: "chat_message_file" */
  chat_message_file: Array<Chat_Message_File>;
  /** fetch aggregated fields from the table: "chat_message_file" */
  chat_message_file_aggregate: Chat_Message_File_Aggregate;
  /** fetch data from the table: "chat_message_file" using primary key columns */
  chat_message_file_by_pk?: Maybe<Chat_Message_File>;
  /** An array relationship */
  chat_message_reactions: Array<Chat_Message_Reactions>;
  /** An aggregate relationship */
  chat_message_reactions_aggregate: Chat_Message_Reactions_Aggregate;
  /** fetch data from the table: "chat_message_reactions" using primary key columns */
  chat_message_reactions_by_pk?: Maybe<Chat_Message_Reactions>;
  /** fetch data from the table: "chat_pinned" */
  chat_pinned: Array<Chat_Pinned>;
  /** fetch aggregated fields from the table: "chat_pinned" */
  chat_pinned_aggregate: Chat_Pinned_Aggregate;
  /** fetch data from the table: "chat_pinned" using primary key columns */
  chat_pinned_by_pk?: Maybe<Chat_Pinned>;
  /** fetch data from the table: "chat_room" */
  chat_room: Array<Chat_Room>;
  /** fetch aggregated fields from the table: "chat_room" */
  chat_room_aggregate: Chat_Room_Aggregate;
  /** fetch data from the table: "chat_room" using primary key columns */
  chat_room_by_pk?: Maybe<Chat_Room>;
  /** fetch data from the table: "chat_room_user" */
  chat_room_user: Array<Chat_Room_User>;
  /** fetch aggregated fields from the table: "chat_room_user" */
  chat_room_user_aggregate: Chat_Room_User_Aggregate;
  /** fetch data from the table: "chat_room_user" using primary key columns */
  chat_room_user_by_pk?: Maybe<Chat_Room_User>;
  /** fetch data from the table: "dashboard_billable" */
  dashboard_billable: Array<Dashboard_Billable>;
  /** fetch aggregated fields from the table: "dashboard_billable" */
  dashboard_billable_aggregate: Dashboard_Billable_Aggregate;
  /** fetch data from the table: "dashboard_billable_employee" */
  dashboard_billable_employee: Array<Dashboard_Billable_Employee>;
  /** fetch aggregated fields from the table: "dashboard_billable_employee" */
  dashboard_billable_employee_aggregate: Dashboard_Billable_Employee_Aggregate;
  /** fetch data from the table: "dashboard_billable_manager" */
  dashboard_billable_manager: Array<Dashboard_Billable_Manager>;
  /** fetch aggregated fields from the table: "dashboard_billable_manager" */
  dashboard_billable_manager_aggregate: Dashboard_Billable_Manager_Aggregate;
  /** fetch data from the table: "dashboard_meeting" */
  dashboard_meeting: Array<Dashboard_Meeting>;
  /** fetch aggregated fields from the table: "dashboard_meeting" */
  dashboard_meeting_aggregate: Dashboard_Meeting_Aggregate;
  /** fetch data from the table: "dashboard_remainders" */
  dashboard_remainders: Array<Dashboard_Remainders>;
  /** fetch aggregated fields from the table: "dashboard_remainders" */
  dashboard_remainders_aggregate: Dashboard_Remainders_Aggregate;
  /** fetch data from the table: "dashboard_remainders" using primary key columns */
  dashboard_remainders_by_pk?: Maybe<Dashboard_Remainders>;
  /** fetch data from the table: "department" */
  department: Array<Department>;
  /** fetch aggregated fields from the table: "department" */
  department_aggregate: Department_Aggregate;
  /** fetch data from the table: "department" using primary key columns */
  department_by_pk?: Maybe<Department>;
  /** fetch data from the table: "department_employee" */
  department_employee: Array<Department_Employee>;
  /** fetch aggregated fields from the table: "department_employee" */
  department_employee_aggregate: Department_Employee_Aggregate;
  /** fetch data from the table: "department_employee" using primary key columns */
  department_employee_by_pk?: Maybe<Department_Employee>;
  /** fetch data from the table: "document_token" */
  document_token: Array<Document_Token>;
  /** fetch aggregated fields from the table: "document_token" */
  document_token_aggregate: Document_Token_Aggregate;
  /** fetch data from the table: "document_token" using primary key columns */
  document_token_by_pk?: Maybe<Document_Token>;
  /** fetch data from the table: "email_templates" */
  email_templates: Array<Email_Templates>;
  /** fetch aggregated fields from the table: "email_templates" */
  email_templates_aggregate: Email_Templates_Aggregate;
  /** fetch data from the table: "email_templates" using primary key columns */
  email_templates_by_pk?: Maybe<Email_Templates>;
  /** An array relationship */
  employee_bandwidth_data: Array<Employee_Bandwidth_Data>;
  /** An aggregate relationship */
  employee_bandwidth_data_aggregate: Employee_Bandwidth_Data_Aggregate;
  /** fetch data from the table: "employee_bandwidth_data" using primary key columns */
  employee_bandwidth_data_by_pk?: Maybe<Employee_Bandwidth_Data>;
  /** fetch data from the table: "employee_capacity" */
  employee_capacity: Array<Employee_Capacity>;
  /** fetch aggregated fields from the table: "employee_capacity" */
  employee_capacity_aggregate: Employee_Capacity_Aggregate;
  /** fetch data from the table: "employee_capacity" using primary key columns */
  employee_capacity_by_pk?: Maybe<Employee_Capacity>;
  /** fetch data from the table: "employee_capacity_view" */
  employee_capacity_view: Array<Employee_Capacity_View>;
  /** fetch aggregated fields from the table: "employee_capacity_view" */
  employee_capacity_view_aggregate: Employee_Capacity_View_Aggregate;
  /** fetch data from the table: "enum_activity_state" */
  enum_activity_state: Array<Enum_Activity_State>;
  /** fetch aggregated fields from the table: "enum_activity_state" */
  enum_activity_state_aggregate: Enum_Activity_State_Aggregate;
  /** fetch data from the table: "enum_activity_state" using primary key columns */
  enum_activity_state_by_pk?: Maybe<Enum_Activity_State>;
  /** fetch data from the table: "enum_activity_type" */
  enum_activity_type: Array<Enum_Activity_Type>;
  /** fetch aggregated fields from the table: "enum_activity_type" */
  enum_activity_type_aggregate: Enum_Activity_Type_Aggregate;
  /** fetch data from the table: "enum_activity_type" using primary key columns */
  enum_activity_type_by_pk?: Maybe<Enum_Activity_Type>;
  /** fetch data from the table: "enum_attendee_status_type" */
  enum_attendee_status_type: Array<Enum_Attendee_Status_Type>;
  /** fetch aggregated fields from the table: "enum_attendee_status_type" */
  enum_attendee_status_type_aggregate: Enum_Attendee_Status_Type_Aggregate;
  /** fetch data from the table: "enum_attendee_status_type" using primary key columns */
  enum_attendee_status_type_by_pk?: Maybe<Enum_Attendee_Status_Type>;
  /** fetch data from the table: "enum_attendee_type" */
  enum_attendee_type: Array<Enum_Attendee_Type>;
  /** fetch aggregated fields from the table: "enum_attendee_type" */
  enum_attendee_type_aggregate: Enum_Attendee_Type_Aggregate;
  /** fetch data from the table: "enum_attendee_type" using primary key columns */
  enum_attendee_type_by_pk?: Maybe<Enum_Attendee_Type>;
  /** fetch data from the table: "enum_award_cycle_type" */
  enum_award_cycle_type: Array<Enum_Award_Cycle_Type>;
  /** fetch aggregated fields from the table: "enum_award_cycle_type" */
  enum_award_cycle_type_aggregate: Enum_Award_Cycle_Type_Aggregate;
  /** fetch data from the table: "enum_award_cycle_type" using primary key columns */
  enum_award_cycle_type_by_pk?: Maybe<Enum_Award_Cycle_Type>;
  /** fetch data from the table: "enum_call_access_policy" */
  enum_call_access_policy: Array<Enum_Call_Access_Policy>;
  /** fetch aggregated fields from the table: "enum_call_access_policy" */
  enum_call_access_policy_aggregate: Enum_Call_Access_Policy_Aggregate;
  /** fetch data from the table: "enum_call_access_policy" using primary key columns */
  enum_call_access_policy_by_pk?: Maybe<Enum_Call_Access_Policy>;
  /** fetch data from the table: "enum_call_broadcasting_type" */
  enum_call_broadcasting_type: Array<Enum_Call_Broadcasting_Type>;
  /** fetch aggregated fields from the table: "enum_call_broadcasting_type" */
  enum_call_broadcasting_type_aggregate: Enum_Call_Broadcasting_Type_Aggregate;
  /** fetch data from the table: "enum_call_broadcasting_type" using primary key columns */
  enum_call_broadcasting_type_by_pk?: Maybe<Enum_Call_Broadcasting_Type>;
  /** fetch data from the table: "enum_call_media_type" */
  enum_call_media_type: Array<Enum_Call_Media_Type>;
  /** fetch aggregated fields from the table: "enum_call_media_type" */
  enum_call_media_type_aggregate: Enum_Call_Media_Type_Aggregate;
  /** fetch data from the table: "enum_call_media_type" using primary key columns */
  enum_call_media_type_by_pk?: Maybe<Enum_Call_Media_Type>;
  /** fetch data from the table: "enum_call_recording_status" */
  enum_call_recording_status: Array<Enum_Call_Recording_Status>;
  /** fetch aggregated fields from the table: "enum_call_recording_status" */
  enum_call_recording_status_aggregate: Enum_Call_Recording_Status_Aggregate;
  /** fetch data from the table: "enum_call_recording_status" using primary key columns */
  enum_call_recording_status_by_pk?: Maybe<Enum_Call_Recording_Status>;
  /** fetch data from the table: "enum_call_request_response_type" */
  enum_call_request_response_type: Array<Enum_Call_Request_Response_Type>;
  /** fetch aggregated fields from the table: "enum_call_request_response_type" */
  enum_call_request_response_type_aggregate: Enum_Call_Request_Response_Type_Aggregate;
  /** fetch data from the table: "enum_call_request_response_type" using primary key columns */
  enum_call_request_response_type_by_pk?: Maybe<Enum_Call_Request_Response_Type>;
  /** fetch data from the table: "enum_call_request_status_type" */
  enum_call_request_status_type: Array<Enum_Call_Request_Status_Type>;
  /** fetch aggregated fields from the table: "enum_call_request_status_type" */
  enum_call_request_status_type_aggregate: Enum_Call_Request_Status_Type_Aggregate;
  /** fetch data from the table: "enum_call_request_status_type" using primary key columns */
  enum_call_request_status_type_by_pk?: Maybe<Enum_Call_Request_Status_Type>;
  /** fetch data from the table: "enum_call_request_type" */
  enum_call_request_type: Array<Enum_Call_Request_Type>;
  /** fetch aggregated fields from the table: "enum_call_request_type" */
  enum_call_request_type_aggregate: Enum_Call_Request_Type_Aggregate;
  /** fetch data from the table: "enum_call_request_type" using primary key columns */
  enum_call_request_type_by_pk?: Maybe<Enum_Call_Request_Type>;
  /** fetch data from the table: "enum_call_status_type" */
  enum_call_status_type: Array<Enum_Call_Status_Type>;
  /** fetch aggregated fields from the table: "enum_call_status_type" */
  enum_call_status_type_aggregate: Enum_Call_Status_Type_Aggregate;
  /** fetch data from the table: "enum_call_status_type" using primary key columns */
  enum_call_status_type_by_pk?: Maybe<Enum_Call_Status_Type>;
  /** fetch data from the table: "enum_call_type" */
  enum_call_type: Array<Enum_Call_Type>;
  /** fetch aggregated fields from the table: "enum_call_type" */
  enum_call_type_aggregate: Enum_Call_Type_Aggregate;
  /** fetch data from the table: "enum_call_type" using primary key columns */
  enum_call_type_by_pk?: Maybe<Enum_Call_Type>;
  /** fetch data from the table: "enum_call_user_status" */
  enum_call_user_status: Array<Enum_Call_User_Status>;
  /** fetch aggregated fields from the table: "enum_call_user_status" */
  enum_call_user_status_aggregate: Enum_Call_User_Status_Aggregate;
  /** fetch data from the table: "enum_call_user_status" using primary key columns */
  enum_call_user_status_by_pk?: Maybe<Enum_Call_User_Status>;
  /** fetch data from the table: "enum_chat_message_type" */
  enum_chat_message_type: Array<Enum_Chat_Message_Type>;
  /** fetch aggregated fields from the table: "enum_chat_message_type" */
  enum_chat_message_type_aggregate: Enum_Chat_Message_Type_Aggregate;
  /** fetch data from the table: "enum_chat_message_type" using primary key columns */
  enum_chat_message_type_by_pk?: Maybe<Enum_Chat_Message_Type>;
  /** fetch data from the table: "enum_chat_notification" */
  enum_chat_notification: Array<Enum_Chat_Notification>;
  /** fetch aggregated fields from the table: "enum_chat_notification" */
  enum_chat_notification_aggregate: Enum_Chat_Notification_Aggregate;
  /** fetch data from the table: "enum_chat_notification" using primary key columns */
  enum_chat_notification_by_pk?: Maybe<Enum_Chat_Notification>;
  /** fetch data from the table: "enum_chat_room_notification_status" */
  enum_chat_room_notification_status: Array<Enum_Chat_Room_Notification_Status>;
  /** fetch aggregated fields from the table: "enum_chat_room_notification_status" */
  enum_chat_room_notification_status_aggregate: Enum_Chat_Room_Notification_Status_Aggregate;
  /** fetch data from the table: "enum_chat_room_notification_status" using primary key columns */
  enum_chat_room_notification_status_by_pk?: Maybe<Enum_Chat_Room_Notification_Status>;
  /** fetch data from the table: "enum_chat_room_type" */
  enum_chat_room_type: Array<Enum_Chat_Room_Type>;
  /** fetch aggregated fields from the table: "enum_chat_room_type" */
  enum_chat_room_type_aggregate: Enum_Chat_Room_Type_Aggregate;
  /** fetch data from the table: "enum_chat_room_type" using primary key columns */
  enum_chat_room_type_by_pk?: Maybe<Enum_Chat_Room_Type>;
  /** fetch data from the table: "enum_chat_room_user_status" */
  enum_chat_room_user_status: Array<Enum_Chat_Room_User_Status>;
  /** fetch aggregated fields from the table: "enum_chat_room_user_status" */
  enum_chat_room_user_status_aggregate: Enum_Chat_Room_User_Status_Aggregate;
  /** fetch data from the table: "enum_chat_room_user_status" using primary key columns */
  enum_chat_room_user_status_by_pk?: Maybe<Enum_Chat_Room_User_Status>;
  /** fetch data from the table: "enum_dashboard_remainder_type" */
  enum_dashboard_remainder_type: Array<Enum_Dashboard_Remainder_Type>;
  /** fetch aggregated fields from the table: "enum_dashboard_remainder_type" */
  enum_dashboard_remainder_type_aggregate: Enum_Dashboard_Remainder_Type_Aggregate;
  /** fetch data from the table: "enum_dashboard_remainder_type" using primary key columns */
  enum_dashboard_remainder_type_by_pk?: Maybe<Enum_Dashboard_Remainder_Type>;
  /** fetch data from the table: "enum_document_token_type" */
  enum_document_token_type: Array<Enum_Document_Token_Type>;
  /** fetch aggregated fields from the table: "enum_document_token_type" */
  enum_document_token_type_aggregate: Enum_Document_Token_Type_Aggregate;
  /** fetch data from the table: "enum_document_token_type" using primary key columns */
  enum_document_token_type_by_pk?: Maybe<Enum_Document_Token_Type>;
  /** fetch data from the table: "enum_event_type" */
  enum_event_type: Array<Enum_Event_Type>;
  /** fetch aggregated fields from the table: "enum_event_type" */
  enum_event_type_aggregate: Enum_Event_Type_Aggregate;
  /** fetch data from the table: "enum_event_type" using primary key columns */
  enum_event_type_by_pk?: Maybe<Enum_Event_Type>;
  /** fetch data from the table: "enum_leave_donate_type" */
  enum_leave_donate_type: Array<Enum_Leave_Donate_Type>;
  /** fetch aggregated fields from the table: "enum_leave_donate_type" */
  enum_leave_donate_type_aggregate: Enum_Leave_Donate_Type_Aggregate;
  /** fetch data from the table: "enum_leave_donate_type" using primary key columns */
  enum_leave_donate_type_by_pk?: Maybe<Enum_Leave_Donate_Type>;
  /** fetch data from the table: "enum_leave_status_type" */
  enum_leave_status_type: Array<Enum_Leave_Status_Type>;
  /** fetch aggregated fields from the table: "enum_leave_status_type" */
  enum_leave_status_type_aggregate: Enum_Leave_Status_Type_Aggregate;
  /** fetch data from the table: "enum_leave_status_type" using primary key columns */
  enum_leave_status_type_by_pk?: Maybe<Enum_Leave_Status_Type>;
  /** fetch data from the table: "enum_live_call_request_status" */
  enum_live_call_request_status: Array<Enum_Live_Call_Request_Status>;
  /** fetch aggregated fields from the table: "enum_live_call_request_status" */
  enum_live_call_request_status_aggregate: Enum_Live_Call_Request_Status_Aggregate;
  /** fetch data from the table: "enum_live_call_request_status" using primary key columns */
  enum_live_call_request_status_by_pk?: Maybe<Enum_Live_Call_Request_Status>;
  /** fetch data from the table: "enum_live_call_status" */
  enum_live_call_status: Array<Enum_Live_Call_Status>;
  /** fetch aggregated fields from the table: "enum_live_call_status" */
  enum_live_call_status_aggregate: Enum_Live_Call_Status_Aggregate;
  /** fetch data from the table: "enum_live_call_status" using primary key columns */
  enum_live_call_status_by_pk?: Maybe<Enum_Live_Call_Status>;
  /** fetch data from the table: "enum_live_call_user_status" */
  enum_live_call_user_status: Array<Enum_Live_Call_User_Status>;
  /** fetch aggregated fields from the table: "enum_live_call_user_status" */
  enum_live_call_user_status_aggregate: Enum_Live_Call_User_Status_Aggregate;
  /** fetch data from the table: "enum_live_call_user_status" using primary key columns */
  enum_live_call_user_status_by_pk?: Maybe<Enum_Live_Call_User_Status>;
  /** fetch data from the table: "enum_live_emote_type" */
  enum_live_emote_type: Array<Enum_Live_Emote_Type>;
  /** fetch aggregated fields from the table: "enum_live_emote_type" */
  enum_live_emote_type_aggregate: Enum_Live_Emote_Type_Aggregate;
  /** fetch data from the table: "enum_live_emote_type" using primary key columns */
  enum_live_emote_type_by_pk?: Maybe<Enum_Live_Emote_Type>;
  /** fetch data from the table: "enum_manual_state" */
  enum_manual_state: Array<Enum_Manual_State>;
  /** fetch aggregated fields from the table: "enum_manual_state" */
  enum_manual_state_aggregate: Enum_Manual_State_Aggregate;
  /** fetch data from the table: "enum_manual_state" using primary key columns */
  enum_manual_state_by_pk?: Maybe<Enum_Manual_State>;
  /** fetch data from the table: "enum_meeting_room_status" */
  enum_meeting_room_status: Array<Enum_Meeting_Room_Status>;
  /** fetch aggregated fields from the table: "enum_meeting_room_status" */
  enum_meeting_room_status_aggregate: Enum_Meeting_Room_Status_Aggregate;
  /** fetch data from the table: "enum_meeting_room_status" using primary key columns */
  enum_meeting_room_status_by_pk?: Maybe<Enum_Meeting_Room_Status>;
  /** fetch data from the table: "enum_meeting_room_type" */
  enum_meeting_room_type: Array<Enum_Meeting_Room_Type>;
  /** fetch aggregated fields from the table: "enum_meeting_room_type" */
  enum_meeting_room_type_aggregate: Enum_Meeting_Room_Type_Aggregate;
  /** fetch data from the table: "enum_meeting_room_type" using primary key columns */
  enum_meeting_room_type_by_pk?: Maybe<Enum_Meeting_Room_Type>;
  /** fetch data from the table: "enum_meeting_room_user_state" */
  enum_meeting_room_user_state: Array<Enum_Meeting_Room_User_State>;
  /** fetch aggregated fields from the table: "enum_meeting_room_user_state" */
  enum_meeting_room_user_state_aggregate: Enum_Meeting_Room_User_State_Aggregate;
  /** fetch data from the table: "enum_meeting_room_user_state" using primary key columns */
  enum_meeting_room_user_state_by_pk?: Maybe<Enum_Meeting_Room_User_State>;
  /** fetch data from the table: "enum_notification_type" */
  enum_notification_type: Array<Enum_Notification_Type>;
  /** fetch aggregated fields from the table: "enum_notification_type" */
  enum_notification_type_aggregate: Enum_Notification_Type_Aggregate;
  /** fetch data from the table: "enum_notification_type" using primary key columns */
  enum_notification_type_by_pk?: Maybe<Enum_Notification_Type>;
  /** fetch data from the table: "enum_personalised_access" */
  enum_personalised_access: Array<Enum_Personalised_Access>;
  /** fetch aggregated fields from the table: "enum_personalised_access" */
  enum_personalised_access_aggregate: Enum_Personalised_Access_Aggregate;
  /** fetch data from the table: "enum_personalised_access" using primary key columns */
  enum_personalised_access_by_pk?: Maybe<Enum_Personalised_Access>;
  /** fetch data from the table: "enum_personalised_categories" */
  enum_personalised_categories: Array<Enum_Personalised_Categories>;
  /** fetch aggregated fields from the table: "enum_personalised_categories" */
  enum_personalised_categories_aggregate: Enum_Personalised_Categories_Aggregate;
  /** fetch data from the table: "enum_personalised_categories" using primary key columns */
  enum_personalised_categories_by_pk?: Maybe<Enum_Personalised_Categories>;
  /** fetch data from the table: "enum_post_type" */
  enum_post_type: Array<Enum_Post_Type>;
  /** fetch aggregated fields from the table: "enum_post_type" */
  enum_post_type_aggregate: Enum_Post_Type_Aggregate;
  /** fetch data from the table: "enum_post_type" using primary key columns */
  enum_post_type_by_pk?: Maybe<Enum_Post_Type>;
  /** fetch data from the table: "enum_presence_connection_status" */
  enum_presence_connection_status: Array<Enum_Presence_Connection_Status>;
  /** fetch aggregated fields from the table: "enum_presence_connection_status" */
  enum_presence_connection_status_aggregate: Enum_Presence_Connection_Status_Aggregate;
  /** fetch data from the table: "enum_presence_connection_status" using primary key columns */
  enum_presence_connection_status_by_pk?: Maybe<Enum_Presence_Connection_Status>;
  /** fetch data from the table: "enum_project_milestone_status" */
  enum_project_milestone_status: Array<Enum_Project_Milestone_Status>;
  /** fetch aggregated fields from the table: "enum_project_milestone_status" */
  enum_project_milestone_status_aggregate: Enum_Project_Milestone_Status_Aggregate;
  /** fetch data from the table: "enum_project_milestone_status" using primary key columns */
  enum_project_milestone_status_by_pk?: Maybe<Enum_Project_Milestone_Status>;
  /** fetch data from the table: "enum_project_sprint_category" */
  enum_project_sprint_category: Array<Enum_Project_Sprint_Category>;
  /** fetch aggregated fields from the table: "enum_project_sprint_category" */
  enum_project_sprint_category_aggregate: Enum_Project_Sprint_Category_Aggregate;
  /** fetch data from the table: "enum_project_sprint_category" using primary key columns */
  enum_project_sprint_category_by_pk?: Maybe<Enum_Project_Sprint_Category>;
  /** fetch data from the table: "enum_project_status" */
  enum_project_status: Array<Enum_Project_Status>;
  /** fetch aggregated fields from the table: "enum_project_status" */
  enum_project_status_aggregate: Enum_Project_Status_Aggregate;
  /** fetch data from the table: "enum_project_status" using primary key columns */
  enum_project_status_by_pk?: Maybe<Enum_Project_Status>;
  /** fetch data from the table: "enum_project_task_category" */
  enum_project_task_category: Array<Enum_Project_Task_Category>;
  /** fetch aggregated fields from the table: "enum_project_task_category" */
  enum_project_task_category_aggregate: Enum_Project_Task_Category_Aggregate;
  /** fetch data from the table: "enum_project_task_category" using primary key columns */
  enum_project_task_category_by_pk?: Maybe<Enum_Project_Task_Category>;
  /** fetch data from the table: "enum_setting_type" */
  enum_setting_type: Array<Enum_Setting_Type>;
  /** fetch aggregated fields from the table: "enum_setting_type" */
  enum_setting_type_aggregate: Enum_Setting_Type_Aggregate;
  /** fetch data from the table: "enum_setting_type" using primary key columns */
  enum_setting_type_by_pk?: Maybe<Enum_Setting_Type>;
  /** fetch data from the table: "enum_storage_file_type" */
  enum_storage_file_type: Array<Enum_Storage_File_Type>;
  /** fetch aggregated fields from the table: "enum_storage_file_type" */
  enum_storage_file_type_aggregate: Enum_Storage_File_Type_Aggregate;
  /** fetch data from the table: "enum_storage_file_type" using primary key columns */
  enum_storage_file_type_by_pk?: Maybe<Enum_Storage_File_Type>;
  /** fetch data from the table: "enum_system_state" */
  enum_system_state: Array<Enum_System_State>;
  /** fetch aggregated fields from the table: "enum_system_state" */
  enum_system_state_aggregate: Enum_System_State_Aggregate;
  /** fetch data from the table: "enum_system_state" using primary key columns */
  enum_system_state_by_pk?: Maybe<Enum_System_State>;
  /** fetch data from the table: "enum_system_state_owner" */
  enum_system_state_owner: Array<Enum_System_State_Owner>;
  /** fetch aggregated fields from the table: "enum_system_state_owner" */
  enum_system_state_owner_aggregate: Enum_System_State_Owner_Aggregate;
  /** fetch data from the table: "enum_system_state_owner" using primary key columns */
  enum_system_state_owner_by_pk?: Maybe<Enum_System_State_Owner>;
  /** fetch data from the table: "enum_task_priority" */
  enum_task_priority: Array<Enum_Task_Priority>;
  /** fetch aggregated fields from the table: "enum_task_priority" */
  enum_task_priority_aggregate: Enum_Task_Priority_Aggregate;
  /** fetch data from the table: "enum_task_priority" using primary key columns */
  enum_task_priority_by_pk?: Maybe<Enum_Task_Priority>;
  /** fetch data from the table: "enum_task_recurring_frequency" */
  enum_task_recurring_frequency: Array<Enum_Task_Recurring_Frequency>;
  /** fetch aggregated fields from the table: "enum_task_recurring_frequency" */
  enum_task_recurring_frequency_aggregate: Enum_Task_Recurring_Frequency_Aggregate;
  /** fetch data from the table: "enum_task_recurring_frequency" using primary key columns */
  enum_task_recurring_frequency_by_pk?: Maybe<Enum_Task_Recurring_Frequency>;
  /** fetch data from the table: "enum_task_status" */
  enum_task_status: Array<Enum_Task_Status>;
  /** fetch aggregated fields from the table: "enum_task_status" */
  enum_task_status_aggregate: Enum_Task_Status_Aggregate;
  /** fetch data from the table: "enum_task_status" using primary key columns */
  enum_task_status_by_pk?: Maybe<Enum_Task_Status>;
  /** fetch data from the table: "enum_task_time_sheet_status" */
  enum_task_time_sheet_status: Array<Enum_Task_Time_Sheet_Status>;
  /** fetch aggregated fields from the table: "enum_task_time_sheet_status" */
  enum_task_time_sheet_status_aggregate: Enum_Task_Time_Sheet_Status_Aggregate;
  /** fetch data from the table: "enum_task_time_sheet_status" using primary key columns */
  enum_task_time_sheet_status_by_pk?: Maybe<Enum_Task_Time_Sheet_Status>;
  /** fetch data from the table: "enum_user_activity_tracker_status_type" */
  enum_user_activity_tracker_status_type: Array<Enum_User_Activity_Tracker_Status_Type>;
  /** fetch aggregated fields from the table: "enum_user_activity_tracker_status_type" */
  enum_user_activity_tracker_status_type_aggregate: Enum_User_Activity_Tracker_Status_Type_Aggregate;
  /** fetch data from the table: "enum_user_activity_tracker_status_type" using primary key columns */
  enum_user_activity_tracker_status_type_by_pk?: Maybe<Enum_User_Activity_Tracker_Status_Type>;
  /** fetch data from the table: "enum_user_avatar_types" */
  enum_user_avatar_types: Array<Enum_User_Avatar_Types>;
  /** fetch aggregated fields from the table: "enum_user_avatar_types" */
  enum_user_avatar_types_aggregate: Enum_User_Avatar_Types_Aggregate;
  /** fetch data from the table: "enum_user_avatar_types" using primary key columns */
  enum_user_avatar_types_by_pk?: Maybe<Enum_User_Avatar_Types>;
  /** fetch data from the table: "enum_user_gender" */
  enum_user_gender: Array<Enum_User_Gender>;
  /** fetch aggregated fields from the table: "enum_user_gender" */
  enum_user_gender_aggregate: Enum_User_Gender_Aggregate;
  /** fetch data from the table: "enum_user_gender" using primary key columns */
  enum_user_gender_by_pk?: Maybe<Enum_User_Gender>;
  /** fetch data from the table: "enum_user_presence_provider_type" */
  enum_user_presence_provider_type: Array<Enum_User_Presence_Provider_Type>;
  /** fetch aggregated fields from the table: "enum_user_presence_provider_type" */
  enum_user_presence_provider_type_aggregate: Enum_User_Presence_Provider_Type_Aggregate;
  /** fetch data from the table: "enum_user_presence_provider_type" using primary key columns */
  enum_user_presence_provider_type_by_pk?: Maybe<Enum_User_Presence_Provider_Type>;
  /** fetch data from the table: "enum_user_settings_type" */
  enum_user_settings_type: Array<Enum_User_Settings_Type>;
  /** fetch aggregated fields from the table: "enum_user_settings_type" */
  enum_user_settings_type_aggregate: Enum_User_Settings_Type_Aggregate;
  /** fetch data from the table: "enum_user_settings_type" using primary key columns */
  enum_user_settings_type_by_pk?: Maybe<Enum_User_Settings_Type>;
  /** fetch data from the table: "filter_definition" */
  filter_definition: Array<Filter_Definition>;
  /** fetch aggregated fields from the table: "filter_definition" */
  filter_definition_aggregate: Filter_Definition_Aggregate;
  /** fetch data from the table: "filter_definition" using primary key columns */
  filter_definition_by_pk?: Maybe<Filter_Definition>;
  /** fetch data from the table: "financialYear" */
  financialYear: Array<FinancialYear>;
  /** fetch aggregated fields from the table: "financialYear" */
  financialYear_aggregate: FinancialYear_Aggregate;
  /** fetch data from the table: "financialYear" using primary key columns */
  financialYear_by_pk?: Maybe<FinancialYear>;
  /** fetch data from the table: "frequency" */
  frequency: Array<Frequency>;
  /** fetch aggregated fields from the table: "frequency" */
  frequency_aggregate: Frequency_Aggregate;
  /** fetch data from the table: "frequency" using primary key columns */
  frequency_by_pk?: Maybe<Frequency>;
  /** execute function "get_all_dependent_tasks_new" which returns "task_dependencies" */
  get_all_dependent_tasks_new: Array<Task_Dependencies>;
  /** execute function "get_all_dependent_tasks_new" and query aggregates on result of table type "task_dependencies" */
  get_all_dependent_tasks_new_aggregate: Task_Dependencies_Aggregate;
  /** execute function "get_chat_room_id_from_event_id_for_meeting_call" which returns "chat_room" */
  get_chat_room_id_from_event_id_for_meeting_call: Array<Chat_Room>;
  /** execute function "get_chat_room_id_from_event_id_for_meeting_call" and query aggregates on result of table type "chat_room" */
  get_chat_room_id_from_event_id_for_meeting_call_aggregate: Chat_Room_Aggregate;
  /** execute function "get_employee_billable_hours" which returns "dashboard_billable_employee" */
  get_employee_billable_hours: Array<Dashboard_Billable_Employee>;
  /** execute function "get_employee_billable_hours" and query aggregates on result of table type "dashboard_billable_employee" */
  get_employee_billable_hours_aggregate: Dashboard_Billable_Employee_Aggregate;
  /** execute function "get_employee_billable_hours_as_manager" which returns "dashboard_billable_manager" */
  get_employee_billable_hours_as_manager: Array<Dashboard_Billable_Manager>;
  /** execute function "get_employee_billable_hours_as_manager" and query aggregates on result of table type "dashboard_billable_manager" */
  get_employee_billable_hours_as_manager_aggregate: Dashboard_Billable_Manager_Aggregate;
  /** fetch data from the table: "goalWithKpi" */
  goalWithKpi: Array<GoalWithKpi>;
  /** fetch aggregated fields from the table: "goalWithKpi" */
  goalWithKpi_aggregate: GoalWithKpi_Aggregate;
  /** fetch data from the table: "goalWithKpi" using primary key columns */
  goalWithKpi_by_pk?: Maybe<GoalWithKpi>;
  /** fetch data from the table: "goalWithTimePeriod" */
  goalWithTimePeriod: Array<GoalWithTimePeriod>;
  /** fetch aggregated fields from the table: "goalWithTimePeriod" */
  goalWithTimePeriod_aggregate: GoalWithTimePeriod_Aggregate;
  /** fetch data from the table: "goalWithTimePeriod" using primary key columns */
  goalWithTimePeriod_by_pk?: Maybe<GoalWithTimePeriod>;
  /** fetch data from the table: "goals" */
  goals: Array<Goals>;
  /** An array relationship */
  goalsWithDepts: Array<GoalsWithDepts>;
  /** An aggregate relationship */
  goalsWithDepts_aggregate: GoalsWithDepts_Aggregate;
  /** fetch data from the table: "goalsWithDepts" using primary key columns */
  goalsWithDepts_by_pk?: Maybe<GoalsWithDepts>;
  /** An array relationship */
  goalsWithFreqs: Array<GoalsWithFreqs>;
  /** An aggregate relationship */
  goalsWithFreqs_aggregate: GoalsWithFreqs_Aggregate;
  /** fetch data from the table: "goalsWithFreqs" using primary key columns */
  goalsWithFreqs_by_pk?: Maybe<GoalsWithFreqs>;
  /** An array relationship */
  goalsWithRoles: Array<GoalsWithRoles>;
  /** An aggregate relationship */
  goalsWithRoles_aggregate: GoalsWithRoles_Aggregate;
  /** fetch data from the table: "goalsWithRoles" using primary key columns */
  goalsWithRoles_by_pk?: Maybe<GoalsWithRoles>;
  /** fetch aggregated fields from the table: "goals" */
  goals_aggregate: Goals_Aggregate;
  /** fetch data from the table: "goals" using primary key columns */
  goals_by_pk?: Maybe<Goals>;
  /** An array relationship */
  hotdesk_bookings: Array<Hotdesk_Bookings>;
  /** An aggregate relationship */
  hotdesk_bookings_aggregate: Hotdesk_Bookings_Aggregate;
  /** fetch data from the table: "hotdesk_bookings" using primary key columns */
  hotdesk_bookings_by_pk?: Maybe<Hotdesk_Bookings>;
  /** execute function "joined_call_usrs" which returns "calendar_meeting_call_user" */
  joined_call_usrs: Array<Calendar_Meeting_Call_User>;
  /** execute function "joined_call_usrs" and query aggregates on result of table type "calendar_meeting_call_user" */
  joined_call_usrs_aggregate: Calendar_Meeting_Call_User_Aggregate;
  /** fetch data from the table: "kpiWithDept" */
  kpiWithDept: Array<KpiWithDept>;
  /** fetch aggregated fields from the table: "kpiWithDept" */
  kpiWithDept_aggregate: KpiWithDept_Aggregate;
  /** fetch data from the table: "kpiWithDept" using primary key columns */
  kpiWithDept_by_pk?: Maybe<KpiWithDept>;
  /** fetch data from the table: "kpiWithRole" */
  kpiWithRole: Array<KpiWithRole>;
  /** fetch aggregated fields from the table: "kpiWithRole" */
  kpiWithRole_aggregate: KpiWithRole_Aggregate;
  /** fetch data from the table: "kpiWithRole" using primary key columns */
  kpiWithRole_by_pk?: Maybe<KpiWithRole>;
  /** fetch data from the table: "kpi_bank" */
  kpi_bank: Array<Kpi_Bank>;
  /** fetch aggregated fields from the table: "kpi_bank" */
  kpi_bank_aggregate: Kpi_Bank_Aggregate;
  /** fetch data from the table: "kpi_bank" using primary key columns */
  kpi_bank_by_pk?: Maybe<Kpi_Bank>;
  /** fetch data from the table: "leave" */
  leave: Array<Leave>;
  /** fetch aggregated fields from the table: "leave" */
  leave_aggregate: Leave_Aggregate;
  /** fetch data from the table: "leave" using primary key columns */
  leave_by_pk?: Maybe<Leave>;
  /** fetch data from the table: "leave_donate" */
  leave_donate: Array<Leave_Donate>;
  /** fetch aggregated fields from the table: "leave_donate" */
  leave_donate_aggregate: Leave_Donate_Aggregate;
  /** fetch data from the table: "leave_donate" using primary key columns */
  leave_donate_by_pk?: Maybe<Leave_Donate>;
  /** fetch data from the table: "leave_organization" */
  leave_organization: Array<Leave_Organization>;
  /** fetch aggregated fields from the table: "leave_organization" */
  leave_organization_aggregate: Leave_Organization_Aggregate;
  /** fetch data from the table: "leave_organization" using primary key columns */
  leave_organization_by_pk?: Maybe<Leave_Organization>;
  /** An array relationship */
  list_Of_Skills: Array<List_Of_Skills>;
  /** An aggregate relationship */
  list_Of_Skills_aggregate: List_Of_Skills_Aggregate;
  /** fetch data from the table: "list_Of_Skills" using primary key columns */
  list_Of_Skills_by_pk?: Maybe<List_Of_Skills>;
  /** fetch data from the table: "live_call" */
  live_call: Array<Live_Call>;
  /** fetch aggregated fields from the table: "live_call" */
  live_call_aggregate: Live_Call_Aggregate;
  /** fetch data from the table: "live_call" using primary key columns */
  live_call_by_pk?: Maybe<Live_Call>;
  /** fetch data from the table: "live_call_request" */
  live_call_request: Array<Live_Call_Request>;
  /** fetch aggregated fields from the table: "live_call_request" */
  live_call_request_aggregate: Live_Call_Request_Aggregate;
  /** fetch data from the table: "live_call_request" using primary key columns */
  live_call_request_by_pk?: Maybe<Live_Call_Request>;
  /** fetch data from the table: "live_call_user" */
  live_call_user: Array<Live_Call_User>;
  /** fetch aggregated fields from the table: "live_call_user" */
  live_call_user_aggregate: Live_Call_User_Aggregate;
  /** fetch data from the table: "live_call_user" using primary key columns */
  live_call_user_by_pk?: Maybe<Live_Call_User>;
  /** An array relationship */
  main_skills: Array<Main_Skills>;
  /** An aggregate relationship */
  main_skills_aggregate: Main_Skills_Aggregate;
  /** fetch data from the table: "main_skills" using primary key columns */
  main_skills_by_pk?: Maybe<Main_Skills>;
  /** fetch data from the table: "meeting_call_schedular" */
  meeting_call_schedular: Array<Meeting_Call_Schedular>;
  /** fetch aggregated fields from the table: "meeting_call_schedular" */
  meeting_call_schedular_aggregate: Meeting_Call_Schedular_Aggregate;
  /** fetch data from the table: "meeting_call_schedular" using primary key columns */
  meeting_call_schedular_by_pk?: Maybe<Meeting_Call_Schedular>;
  /** fetch data from the table: "meeting_room" */
  meeting_room: Array<Meeting_Room>;
  /** fetch aggregated fields from the table: "meeting_room" */
  meeting_room_aggregate: Meeting_Room_Aggregate;
  /** fetch data from the table: "meeting_room" using primary key columns */
  meeting_room_by_pk?: Maybe<Meeting_Room>;
  /** An array relationship */
  meeting_room_users: Array<Meeting_Room_Users>;
  /** An aggregate relationship */
  meeting_room_users_aggregate: Meeting_Room_Users_Aggregate;
  /** fetch data from the table: "meeting_room_users" using primary key columns */
  meeting_room_users_by_pk?: Maybe<Meeting_Room_Users>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch data from the table: "organization_active_leave_types" */
  organization_active_leave_types: Array<Organization_Active_Leave_Types>;
  /** fetch aggregated fields from the table: "organization_active_leave_types" */
  organization_active_leave_types_aggregate: Organization_Active_Leave_Types_Aggregate;
  /** fetch data from the table: "organization_active_leave_types" using primary key columns */
  organization_active_leave_types_by_pk?: Maybe<Organization_Active_Leave_Types>;
  /** An array relationship */
  organization_admin: Array<Organization_Admin>;
  /** An aggregate relationship */
  organization_admin_aggregate: Organization_Admin_Aggregate;
  /** fetch data from the table: "organization_admin" using primary key columns */
  organization_admin_by_pk?: Maybe<Organization_Admin>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: Organization_Aggregate;
  /** fetch data from the table: "organization_awards" */
  organization_awards: Array<Organization_Awards>;
  /** fetch aggregated fields from the table: "organization_awards" */
  organization_awards_aggregate: Organization_Awards_Aggregate;
  /** fetch data from the table: "organization_awards" using primary key columns */
  organization_awards_by_pk?: Maybe<Organization_Awards>;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk?: Maybe<Organization>;
  /** fetch data from the table: "organization_employee" */
  organization_employee: Array<Organization_Employee>;
  /** fetch aggregated fields from the table: "organization_employee" */
  organization_employee_aggregate: Organization_Employee_Aggregate;
  /** fetch data from the table: "organization_employee" using primary key columns */
  organization_employee_by_pk?: Maybe<Organization_Employee>;
  /** An array relationship */
  organization_holidays: Array<Organization_Holidays>;
  /** An aggregate relationship */
  organization_holidays_aggregate: Organization_Holidays_Aggregate;
  /** fetch data from the table: "organization_holidays" using primary key columns */
  organization_holidays_by_pk?: Maybe<Organization_Holidays>;
  /** An array relationship */
  organization_leave_cycles: Array<Organization_Leave_Cycles>;
  /** An aggregate relationship */
  organization_leave_cycles_aggregate: Organization_Leave_Cycles_Aggregate;
  /** fetch data from the table: "organization_leave_cycles" using primary key columns */
  organization_leave_cycles_by_pk?: Maybe<Organization_Leave_Cycles>;
  /** An array relationship */
  organization_locations: Array<Organization_Locations>;
  /** An aggregate relationship */
  organization_locations_aggregate: Organization_Locations_Aggregate;
  /** fetch data from the table: "organization_locations" using primary key columns */
  organization_locations_by_pk?: Maybe<Organization_Locations>;
  /** An array relationship */
  organization_roles: Array<Organization_Roles>;
  /** An aggregate relationship */
  organization_roles_aggregate: Organization_Roles_Aggregate;
  /** fetch data from the table: "organization_roles" using primary key columns */
  organization_roles_by_pk?: Maybe<Organization_Roles>;
  /** fetch data from the table: "organization_user" */
  organization_user: Array<Organization_User>;
  /** fetch aggregated fields from the table: "organization_user" */
  organization_user_aggregate: Organization_User_Aggregate;
  /** execute function "organization_user_approved_leaves" which returns "organization_user_leave_application" */
  organization_user_approved_leaves: Array<Organization_User_Leave_Application>;
  /** execute function "organization_user_approved_leaves" and query aggregates on result of table type "organization_user_leave_application" */
  organization_user_approved_leaves_aggregate: Organization_User_Leave_Application_Aggregate;
  /** fetch data from the table: "organization_user_leave_application" */
  organization_user_leave_application: Array<Organization_User_Leave_Application>;
  /** fetch aggregated fields from the table: "organization_user_leave_application" */
  organization_user_leave_application_aggregate: Organization_User_Leave_Application_Aggregate;
  /** fetch data from the table: "organization_user_leave_application" using primary key columns */
  organization_user_leave_application_by_pk?: Maybe<Organization_User_Leave_Application>;
  /** fetch data from the table: "organization_user_leave_data" */
  organization_user_leave_data: Array<Organization_User_Leave_Data>;
  /** fetch aggregated fields from the table: "organization_user_leave_data" */
  organization_user_leave_data_aggregate: Organization_User_Leave_Data_Aggregate;
  /** fetch data from the table: "organization_user_leave_data" using primary key columns */
  organization_user_leave_data_by_pk?: Maybe<Organization_User_Leave_Data>;
  /** fetch data from the table: "organization_user_leave_dates" */
  organization_user_leave_dates: Array<Organization_User_Leave_Dates>;
  /** fetch aggregated fields from the table: "organization_user_leave_dates" */
  organization_user_leave_dates_aggregate: Organization_User_Leave_Dates_Aggregate;
  /** fetch data from the table: "organization_user_leave_dates" using primary key columns */
  organization_user_leave_dates_by_pk?: Maybe<Organization_User_Leave_Dates>;
  /** fetch data from the table: "organization_user_leave_files" */
  organization_user_leave_files: Array<Organization_User_Leave_Files>;
  /** fetch aggregated fields from the table: "organization_user_leave_files" */
  organization_user_leave_files_aggregate: Organization_User_Leave_Files_Aggregate;
  /** fetch data from the table: "organization_user_leave_files" using primary key columns */
  organization_user_leave_files_by_pk?: Maybe<Organization_User_Leave_Files>;
  /** execute function "organization_user_pending_leaves" which returns "organization_user_leave_application" */
  organization_user_pending_leaves: Array<Organization_User_Leave_Application>;
  /** execute function "organization_user_pending_leaves" and query aggregates on result of table type "organization_user_leave_application" */
  organization_user_pending_leaves_aggregate: Organization_User_Leave_Application_Aggregate;
  /** execute function "organization_user_rejected_leaves" which returns "organization_user_leave_application" */
  organization_user_rejected_leaves: Array<Organization_User_Leave_Application>;
  /** execute function "organization_user_rejected_leaves" and query aggregates on result of table type "organization_user_leave_application" */
  organization_user_rejected_leaves_aggregate: Organization_User_Leave_Application_Aggregate;
  /** fetch data from the table: "performanceReviewStatus" */
  performanceReviewStatus: Array<PerformanceReviewStatus>;
  /** fetch aggregated fields from the table: "performanceReviewStatus" */
  performanceReviewStatus_aggregate: PerformanceReviewStatus_Aggregate;
  /** fetch data from the table: "performanceReviewStatus" using primary key columns */
  performanceReviewStatus_by_pk?: Maybe<PerformanceReviewStatus>;
  /** fetch data from the table: "performance_rating_scale" */
  performance_rating_scale: Array<Performance_Rating_Scale>;
  /** fetch aggregated fields from the table: "performance_rating_scale" */
  performance_rating_scale_aggregate: Performance_Rating_Scale_Aggregate;
  /** fetch data from the table: "performance_rating_scale" using primary key columns */
  performance_rating_scale_by_pk?: Maybe<Performance_Rating_Scale>;
  /** fetch data from the table: "performance_review_byUser" */
  performance_review_byUser: Array<Performance_Review_ByUser>;
  /** fetch data from the table: "performance_review_byUser_Kpi" */
  performance_review_byUser_Kpi: Array<Performance_Review_ByUser_Kpi>;
  /** fetch aggregated fields from the table: "performance_review_byUser_Kpi" */
  performance_review_byUser_Kpi_aggregate: Performance_Review_ByUser_Kpi_Aggregate;
  /** fetch data from the table: "performance_review_byUser_Kpi" using primary key columns */
  performance_review_byUser_Kpi_by_pk?: Maybe<Performance_Review_ByUser_Kpi>;
  /** fetch data from the table: "performance_review_byUser_WithGoal" */
  performance_review_byUser_WithGoal: Array<Performance_Review_ByUser_WithGoal>;
  /** fetch aggregated fields from the table: "performance_review_byUser_WithGoal" */
  performance_review_byUser_WithGoal_aggregate: Performance_Review_ByUser_WithGoal_Aggregate;
  /** fetch data from the table: "performance_review_byUser_WithGoal" using primary key columns */
  performance_review_byUser_WithGoal_by_pk?: Maybe<Performance_Review_ByUser_WithGoal>;
  /** fetch aggregated fields from the table: "performance_review_byUser" */
  performance_review_byUser_aggregate: Performance_Review_ByUser_Aggregate;
  /** fetch data from the table: "performance_review_byUser" using primary key columns */
  performance_review_byUser_by_pk?: Maybe<Performance_Review_ByUser>;
  /** fetch data from the table: "performance_review_setting_GoalsList" */
  performance_review_setting_GoalsList: Array<Performance_Review_Setting_GoalsList>;
  /** fetch aggregated fields from the table: "performance_review_setting_GoalsList" */
  performance_review_setting_GoalsList_aggregate: Performance_Review_Setting_GoalsList_Aggregate;
  /** fetch data from the table: "performance_review_setting_GoalsList" using primary key columns */
  performance_review_setting_GoalsList_by_pk?: Maybe<Performance_Review_Setting_GoalsList>;
  /** An array relationship */
  performance_review_setting_With_Goals: Array<Performance_Review_Setting_With_Goals>;
  /** An aggregate relationship */
  performance_review_setting_With_Goals_aggregate: Performance_Review_Setting_With_Goals_Aggregate;
  /** fetch data from the table: "performance_review_setting_With_Goals" using primary key columns */
  performance_review_setting_With_Goals_by_pk?: Maybe<Performance_Review_Setting_With_Goals>;
  /** An array relationship */
  performance_review_settings: Array<Performance_Review_Settings>;
  /** An aggregate relationship */
  performance_review_settings_aggregate: Performance_Review_Settings_Aggregate;
  /** fetch data from the table: "performance_review_settings" using primary key columns */
  performance_review_settings_by_pk?: Maybe<Performance_Review_Settings>;
  /** fetch data from the table: "personalised" */
  personalised: Array<Personalised>;
  /** fetch aggregated fields from the table: "personalised" */
  personalised_aggregate: Personalised_Aggregate;
  /** fetch data from the table: "personalised" using primary key columns */
  personalised_by_pk?: Maybe<Personalised>;
  /** fetch data from the table: "post_comment_files" */
  post_comment_files: Array<Post_Comment_Files>;
  /** fetch aggregated fields from the table: "post_comment_files" */
  post_comment_files_aggregate: Post_Comment_Files_Aggregate;
  /** fetch data from the table: "post_comment_files" using primary key columns */
  post_comment_files_by_pk?: Maybe<Post_Comment_Files>;
  /** fetch data from the table: "post_comment_mentioned_users" */
  post_comment_mentioned_users: Array<Post_Comment_Mentioned_Users>;
  /** fetch aggregated fields from the table: "post_comment_mentioned_users" */
  post_comment_mentioned_users_aggregate: Post_Comment_Mentioned_Users_Aggregate;
  /** fetch data from the table: "post_comment_mentioned_users" using primary key columns */
  post_comment_mentioned_users_by_pk?: Maybe<Post_Comment_Mentioned_Users>;
  /** fetch data from the table: "post_comment_replies" */
  post_comment_replies: Array<Post_Comment_Replies>;
  /** fetch aggregated fields from the table: "post_comment_replies" */
  post_comment_replies_aggregate: Post_Comment_Replies_Aggregate;
  /** fetch data from the table: "post_comment_replies" using primary key columns */
  post_comment_replies_by_pk?: Maybe<Post_Comment_Replies>;
  /** fetch data from the table: "post_comment_replies_mentioned_users" */
  post_comment_replies_mentioned_users: Array<Post_Comment_Replies_Mentioned_Users>;
  /** fetch aggregated fields from the table: "post_comment_replies_mentioned_users" */
  post_comment_replies_mentioned_users_aggregate: Post_Comment_Replies_Mentioned_Users_Aggregate;
  /** fetch data from the table: "post_comment_replies_mentioned_users" using primary key columns */
  post_comment_replies_mentioned_users_by_pk?: Maybe<Post_Comment_Replies_Mentioned_Users>;
  /** fetch data from the table: "post_comments_likes" */
  post_comments_likes: Array<Post_Comments_Likes>;
  /** fetch aggregated fields from the table: "post_comments_likes" */
  post_comments_likes_aggregate: Post_Comments_Likes_Aggregate;
  /** fetch data from the table: "post_comments_likes" using primary key columns */
  post_comments_likes_by_pk?: Maybe<Post_Comments_Likes>;
  /** An array relationship */
  post_likes: Array<Post_Likes>;
  /** An aggregate relationship */
  post_likes_aggregate: Post_Likes_Aggregate;
  /** fetch data from the table: "post_likes" using primary key columns */
  post_likes_by_pk?: Maybe<Post_Likes>;
  /** fetch data from the table: "post_mentioned_users" */
  post_mentioned_users: Array<Post_Mentioned_Users>;
  /** fetch aggregated fields from the table: "post_mentioned_users" */
  post_mentioned_users_aggregate: Post_Mentioned_Users_Aggregate;
  /** fetch data from the table: "post_mentioned_users" using primary key columns */
  post_mentioned_users_by_pk?: Maybe<Post_Mentioned_Users>;
  /** fetch data from the table: "post_replies_likes" */
  post_replies_likes: Array<Post_Replies_Likes>;
  /** fetch aggregated fields from the table: "post_replies_likes" */
  post_replies_likes_aggregate: Post_Replies_Likes_Aggregate;
  /** fetch data from the table: "post_replies_likes" using primary key columns */
  post_replies_likes_by_pk?: Maybe<Post_Replies_Likes>;
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  /** An array relationship */
  posts_comments: Array<Posts_Comments>;
  /** An aggregate relationship */
  posts_comments_aggregate: Posts_Comments_Aggregate;
  /** fetch data from the table: "posts_comments" using primary key columns */
  posts_comments_by_pk?: Maybe<Posts_Comments>;
  /** fetch data from the table: "posts_files" */
  posts_files: Array<Posts_Files>;
  /** fetch aggregated fields from the table: "posts_files" */
  posts_files_aggregate: Posts_Files_Aggregate;
  /** fetch data from the table: "posts_files" using primary key columns */
  posts_files_by_pk?: Maybe<Posts_Files>;
  /** fetch data from the table: "posts_poll_choices" */
  posts_poll_choices: Array<Posts_Poll_Choices>;
  /** fetch aggregated fields from the table: "posts_poll_choices" */
  posts_poll_choices_aggregate: Posts_Poll_Choices_Aggregate;
  /** fetch data from the table: "posts_poll_choices" using primary key columns */
  posts_poll_choices_by_pk?: Maybe<Posts_Poll_Choices>;
  /** fetch data from the table: "posts_poll_choices_votes" */
  posts_poll_choices_votes: Array<Posts_Poll_Choices_Votes>;
  /** fetch aggregated fields from the table: "posts_poll_choices_votes" */
  posts_poll_choices_votes_aggregate: Posts_Poll_Choices_Votes_Aggregate;
  /** fetch data from the table: "posts_poll_choices_votes" using primary key columns */
  posts_poll_choices_votes_by_pk?: Maybe<Posts_Poll_Choices_Votes>;
  /** An array relationship */
  profile: Array<Profile>;
  /** An aggregate relationship */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_client" */
  project_client: Array<Project_Client>;
  /** fetch aggregated fields from the table: "project_client" */
  project_client_aggregate: Project_Client_Aggregate;
  /** fetch data from the table: "project_client" using primary key columns */
  project_client_by_pk?: Maybe<Project_Client>;
  /** An array relationship */
  project_links: Array<Project_Links>;
  /** An aggregate relationship */
  project_links_aggregate: Project_Links_Aggregate;
  /** fetch data from the table: "project_links" using primary key columns */
  project_links_by_pk?: Maybe<Project_Links>;
  /** An array relationship */
  project_members: Array<Project_Members>;
  /** An aggregate relationship */
  project_members_aggregate: Project_Members_Aggregate;
  /** fetch data from the table: "project_members" using primary key columns */
  project_members_by_pk?: Maybe<Project_Members>;
  /** An array relationship */
  project_milestones: Array<Project_Milestones>;
  /** An aggregate relationship */
  project_milestones_aggregate: Project_Milestones_Aggregate;
  /** fetch data from the table: "project_milestones" using primary key columns */
  project_milestones_by_pk?: Maybe<Project_Milestones>;
  /** fetch data from the table: "project_notification_settings" */
  project_notification_settings: Array<Project_Notification_Settings>;
  /** fetch aggregated fields from the table: "project_notification_settings" */
  project_notification_settings_aggregate: Project_Notification_Settings_Aggregate;
  /** fetch data from the table: "project_notification_settings" using primary key columns */
  project_notification_settings_by_pk?: Maybe<Project_Notification_Settings>;
  /** An array relationship */
  project_section: Array<Project_Section>;
  /** An aggregate relationship */
  project_section_aggregate: Project_Section_Aggregate;
  /** fetch data from the table: "project_section" using primary key columns */
  project_section_by_pk?: Maybe<Project_Section>;
  /** An array relationship */
  project_sprints: Array<Project_Sprints>;
  /** An aggregate relationship */
  project_sprints_aggregate: Project_Sprints_Aggregate;
  /** fetch data from the table: "project_sprints" using primary key columns */
  project_sprints_by_pk?: Maybe<Project_Sprints>;
  /** An array relationship */
  question_options: Array<Question_Options>;
  /** An aggregate relationship */
  question_options_aggregate: Question_Options_Aggregate;
  /** fetch data from the table: "question_options" using primary key columns */
  question_options_by_pk?: Maybe<Question_Options>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** execute function "search_chat_message_text" which returns "chat_message" */
  search_chat_message_text: Array<Chat_Message>;
  /** execute function "search_chat_message_text" and query aggregates on result of table type "chat_message" */
  search_chat_message_text_aggregate: Chat_Message_Aggregate;
  /** fetch data from the table: "skills_detail" */
  skills_detail: Array<Skills_Detail>;
  /** fetch aggregated fields from the table: "skills_detail" */
  skills_detail_aggregate: Skills_Detail_Aggregate;
  /** fetch data from the table: "skills_detail" using primary key columns */
  skills_detail_by_pk?: Maybe<Skills_Detail>;
  /** fetch data from the table: "stage_career_detail" */
  stage_career_detail: Array<Stage_Career_Detail>;
  /** fetch aggregated fields from the table: "stage_career_detail" */
  stage_career_detail_aggregate: Stage_Career_Detail_Aggregate;
  /** fetch data from the table: "stage_career_detail" using primary key columns */
  stage_career_detail_by_pk?: Maybe<Stage_Career_Detail>;
  /** An array relationship */
  storage_files: Array<Storage_Files>;
  /** An aggregate relationship */
  storage_files_aggregate: Storage_Files_Aggregate;
  /** fetch data from the table: "storage_files" using primary key columns */
  storage_files_by_pk?: Maybe<Storage_Files>;
  /** An array relationship */
  stress_data: Array<Stress_Data>;
  /** An aggregate relationship */
  stress_data_aggregate: Stress_Data_Aggregate;
  /** fetch data from the table: "stress_data" using primary key columns */
  stress_data_by_pk?: Maybe<Stress_Data>;
  /** An array relationship */
  sub_skills: Array<Sub_Skills>;
  /** An aggregate relationship */
  sub_skills_aggregate: Sub_Skills_Aggregate;
  /** fetch data from the table: "sub_skills" using primary key columns */
  sub_skills_by_pk?: Maybe<Sub_Skills>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: Task_Aggregate;
  /** An array relationship */
  task_approvers: Array<Task_Approvers>;
  /** An aggregate relationship */
  task_approvers_aggregate: Task_Approvers_Aggregate;
  /** fetch data from the table: "task_approvers" using primary key columns */
  task_approvers_by_pk?: Maybe<Task_Approvers>;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** fetch data from the table: "task_checklist" */
  task_checklist: Array<Task_Checklist>;
  /** fetch aggregated fields from the table: "task_checklist" */
  task_checklist_aggregate: Task_Checklist_Aggregate;
  /** fetch data from the table: "task_checklist" using primary key columns */
  task_checklist_by_pk?: Maybe<Task_Checklist>;
  /** fetch data from the table: "task_collaborator" */
  task_collaborator: Array<Task_Collaborator>;
  /** fetch aggregated fields from the table: "task_collaborator" */
  task_collaborator_aggregate: Task_Collaborator_Aggregate;
  /** fetch data from the table: "task_collaborator" using primary key columns */
  task_collaborator_by_pk?: Maybe<Task_Collaborator>;
  /** fetch data from the table: "task_combined" */
  task_combined: Array<Task_Combined>;
  /** fetch aggregated fields from the table: "task_combined" */
  task_combined_aggregate: Task_Combined_Aggregate;
  /** fetch data from the table: "task_comment" */
  task_comment: Array<Task_Comment>;
  /** fetch aggregated fields from the table: "task_comment" */
  task_comment_aggregate: Task_Comment_Aggregate;
  /** fetch data from the table: "task_comment" using primary key columns */
  task_comment_by_pk?: Maybe<Task_Comment>;
  /** fetch data from the table: "task_comment_like" */
  task_comment_like: Array<Task_Comment_Like>;
  /** fetch aggregated fields from the table: "task_comment_like" */
  task_comment_like_aggregate: Task_Comment_Like_Aggregate;
  /** fetch data from the table: "task_comment_like" using primary key columns */
  task_comment_like_by_pk?: Maybe<Task_Comment_Like>;
  /** An array relationship */
  task_dependencies: Array<Task_Dependencies>;
  /** An aggregate relationship */
  task_dependencies_aggregate: Task_Dependencies_Aggregate;
  /** fetch data from the table: "task_dependencies" using primary key columns */
  task_dependencies_by_pk?: Maybe<Task_Dependencies>;
  /** fetch data from the table: "task_file" */
  task_file: Array<Task_File>;
  /** fetch aggregated fields from the table: "task_file" */
  task_file_aggregate: Task_File_Aggregate;
  /** fetch data from the table: "task_file" using primary key columns */
  task_file_by_pk?: Maybe<Task_File>;
  /** An array relationship */
  task_milestones: Array<Task_Milestones>;
  /** An aggregate relationship */
  task_milestones_aggregate: Task_Milestones_Aggregate;
  /** fetch data from the table: "task_milestones" using primary key columns */
  task_milestones_by_pk?: Maybe<Task_Milestones>;
  /** An array relationship */
  task_tags: Array<Task_Tags>;
  /** An aggregate relationship */
  task_tags_aggregate: Task_Tags_Aggregate;
  /** fetch data from the table: "task_tags" using primary key columns */
  task_tags_by_pk?: Maybe<Task_Tags>;
  /** fetch data from the table: "task_time_sheet" */
  task_time_sheet: Array<Task_Time_Sheet>;
  /** fetch aggregated fields from the table: "task_time_sheet" */
  task_time_sheet_aggregate: Task_Time_Sheet_Aggregate;
  /** fetch data from the table: "task_time_sheet" using primary key columns */
  task_time_sheet_by_pk?: Maybe<Task_Time_Sheet>;
  /** fetch data from the table: "task_unscheduled" */
  task_unscheduled: Array<Task_Unscheduled>;
  /** fetch aggregated fields from the table: "task_unscheduled" */
  task_unscheduled_aggregate: Task_Unscheduled_Aggregate;
  /** fetch data from the table: "task_unscheduled" using primary key columns */
  task_unscheduled_by_pk?: Maybe<Task_Unscheduled>;
  /** fetch data from the table: "time_period" */
  time_period: Array<Time_Period>;
  /** fetch aggregated fields from the table: "time_period" */
  time_period_aggregate: Time_Period_Aggregate;
  /** fetch data from the table: "time_period" using primary key columns */
  time_period_by_pk?: Maybe<Time_Period>;
  /** fetch data from the table: "training" */
  training: Array<Training>;
  /** fetch data from the table: "training_Skill" */
  training_Skill: Array<Training_Skill>;
  /** fetch aggregated fields from the table: "training_Skill" */
  training_Skill_aggregate: Training_Skill_Aggregate;
  /** fetch data from the table: "training_Skill" using primary key columns */
  training_Skill_by_pk?: Maybe<Training_Skill>;
  /** fetch aggregated fields from the table: "training" */
  training_aggregate: Training_Aggregate;
  /** fetch data from the table: "training" using primary key columns */
  training_by_pk?: Maybe<Training>;
  /** fetch data from the table: "training_feedback" */
  training_feedback: Array<Training_Feedback>;
  /** fetch aggregated fields from the table: "training_feedback" */
  training_feedback_aggregate: Training_Feedback_Aggregate;
  /** fetch data from the table: "training_feedback" using primary key columns */
  training_feedback_by_pk?: Maybe<Training_Feedback>;
  /** fetch data from the table: "training_progress_record" */
  training_progress_record: Array<Training_Progress_Record>;
  /** fetch aggregated fields from the table: "training_progress_record" */
  training_progress_record_aggregate: Training_Progress_Record_Aggregate;
  /** fetch data from the table: "training_progress_record" using primary key columns */
  training_progress_record_by_pk?: Maybe<Training_Progress_Record>;
  /** An array relationship */
  training_role_details: Array<Training_Role_Details>;
  /** An aggregate relationship */
  training_role_details_aggregate: Training_Role_Details_Aggregate;
  /** fetch data from the table: "training_role_details" using primary key columns */
  training_role_details_by_pk?: Maybe<Training_Role_Details>;
  /** fetch data from the table: "training_stage_career" */
  training_stage_career: Array<Training_Stage_Career>;
  /** fetch aggregated fields from the table: "training_stage_career" */
  training_stage_career_aggregate: Training_Stage_Career_Aggregate;
  /** fetch data from the table: "training_stage_career" using primary key columns */
  training_stage_career_by_pk?: Maybe<Training_Stage_Career>;
  /** An array relationship */
  training_table_of_contents: Array<Training_Table_Of_Contents>;
  /** An aggregate relationship */
  training_table_of_contents_aggregate: Training_Table_Of_Contents_Aggregate;
  /** fetch data from the table: "training_table_of_contents" using primary key columns */
  training_table_of_contents_by_pk?: Maybe<Training_Table_Of_Contents>;
  /** fetch data from the table: "training_type_for_training" */
  training_type_for_training: Array<Training_Type_For_Training>;
  /** fetch aggregated fields from the table: "training_type_for_training" */
  training_type_for_training_aggregate: Training_Type_For_Training_Aggregate;
  /** fetch data from the table: "training_type_for_training" using primary key columns */
  training_type_for_training_by_pk?: Maybe<Training_Type_For_Training>;
  /** For updating rank of task after drag and drop */
  updateTaskRank?: Maybe<UpdateTaskRank>;
  /** fetch data from the table: "used_leave_view" */
  used_leave_view: Array<Used_Leave_View>;
  /** fetch aggregated fields from the table: "used_leave_view" */
  used_leave_view_aggregate: Used_Leave_View_Aggregate;
  /** fetch data from the table: "user_Competency" */
  user_Competency: Array<User_Competency>;
  /** fetch aggregated fields from the table: "user_Competency" */
  user_Competency_aggregate: User_Competency_Aggregate;
  /** fetch data from the table: "user_Competency" using primary key columns */
  user_Competency_by_pk?: Maybe<User_Competency>;
  /** An array relationship */
  user_Skills: Array<User_Skills>;
  /** fetch data from the table: "user_Skills_Assessment" */
  user_Skills_Assessment: Array<User_Skills_Assessment>;
  /** fetch aggregated fields from the table: "user_Skills_Assessment" */
  user_Skills_Assessment_aggregate: User_Skills_Assessment_Aggregate;
  /** fetch data from the table: "user_Skills_Assessment" using primary key columns */
  user_Skills_Assessment_by_pk?: Maybe<User_Skills_Assessment>;
  /** fetch data from the table: "user_Skills_Training" */
  user_Skills_Training: Array<User_Skills_Training>;
  /** fetch aggregated fields from the table: "user_Skills_Training" */
  user_Skills_Training_aggregate: User_Skills_Training_Aggregate;
  /** fetch data from the table: "user_Skills_Training" using primary key columns */
  user_Skills_Training_by_pk?: Maybe<User_Skills_Training>;
  /** An aggregate relationship */
  user_Skills_aggregate: User_Skills_Aggregate;
  /** fetch data from the table: "user_Skills" using primary key columns */
  user_Skills_by_pk?: Maybe<User_Skills>;
  /** fetch data from the table: "user_activity_tracker" */
  user_activity_tracker: Array<User_Activity_Tracker>;
  /** fetch aggregated fields from the table: "user_activity_tracker" */
  user_activity_tracker_aggregate: User_Activity_Tracker_Aggregate;
  /** fetch data from the table: "user_activity_tracker" using primary key columns */
  user_activity_tracker_by_pk?: Maybe<User_Activity_Tracker>;
  /** fetch data from the table: "user_avatar" */
  user_avatar: Array<User_Avatar>;
  /** fetch aggregated fields from the table: "user_avatar" */
  user_avatar_aggregate: User_Avatar_Aggregate;
  /** fetch data from the table: "user_avatar" using primary key columns */
  user_avatar_by_pk?: Maybe<User_Avatar>;
  /** An array relationship */
  user_avatar_files: Array<User_Avatar_Files>;
  /** An aggregate relationship */
  user_avatar_files_aggregate: User_Avatar_Files_Aggregate;
  /** fetch data from the table: "user_avatar_files" using primary key columns */
  user_avatar_files_by_pk?: Maybe<User_Avatar_Files>;
  /** fetch data from the table: "user_career_development_record" */
  user_career_development_record: Array<User_Career_Development_Record>;
  /** fetch aggregated fields from the table: "user_career_development_record" */
  user_career_development_record_aggregate: User_Career_Development_Record_Aggregate;
  /** fetch data from the table: "user_career_development_record" using primary key columns */
  user_career_development_record_by_pk?: Maybe<User_Career_Development_Record>;
  /** fetch data from the table: "user_career_external_certification" */
  user_career_external_certification: Array<User_Career_External_Certification>;
  /** fetch aggregated fields from the table: "user_career_external_certification" */
  user_career_external_certification_aggregate: User_Career_External_Certification_Aggregate;
  /** fetch data from the table: "user_career_external_certification" using primary key columns */
  user_career_external_certification_by_pk?: Maybe<User_Career_External_Certification>;
  /** fetch data from the table: "user_presence" */
  user_presence: Array<User_Presence>;
  /** fetch aggregated fields from the table: "user_presence" */
  user_presence_aggregate: User_Presence_Aggregate;
  /** fetch data from the table: "user_presence" using primary key columns */
  user_presence_by_pk?: Maybe<User_Presence>;
  /** An array relationship */
  user_settings: Array<User_Settings>;
  /** An aggregate relationship */
  user_settings_aggregate: User_Settings_Aggregate;
  /** fetch data from the table: "user_settings" using primary key columns */
  user_settings_by_pk?: Maybe<User_Settings>;
  /** fetch data from the table: "user_state" */
  user_state: Array<User_State>;
  /** fetch aggregated fields from the table: "user_state" */
  user_state_aggregate: User_State_Aggregate;
  /** fetch data from the table: "user_state" using primary key columns */
  user_state_by_pk?: Maybe<User_State>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};

export type Query_RootUser_Peer_ReviewArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

export type Query_RootUser_Peer_Review_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

export type Query_RootUser_Peer_Review_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Query_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssessmentArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

export type Query_RootAssessment_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Skills_Order_By>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

export type Query_RootAssessment_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Skills_Order_By>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

export type Query_RootAssessment_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

export type Query_RootAssessment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssessment_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

export type Query_RootAssessment_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

export type Query_RootAssessment_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssessment_RecordArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

export type Query_RootAssessment_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

export type Query_RootAssessment_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssessment_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

export type Query_RootAssessment_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

export type Query_RootAssessment_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssessment_Stage_CareerArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Stage_Career_Order_By>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

export type Query_RootAssessment_Stage_Career_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Stage_Career_Order_By>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

export type Query_RootAssessment_Stage_Career_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssessment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Query_RootAssessment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Query_RootAssessment_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssign_Career_Development_PlanArgs = {
  distinct_on?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assign_Career_Development_Plan_Order_By>>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

export type Query_RootAssign_Career_Development_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assign_Career_Development_Plan_Order_By>>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

export type Query_RootAssign_Career_Development_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAudit_User_StateArgs = {
  distinct_on?: InputMaybe<Array<Audit_User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_User_State_Order_By>>;
  where?: InputMaybe<Audit_User_State_Bool_Exp>;
};

export type Query_RootAudit_User_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_User_State_Order_By>>;
  where?: InputMaybe<Audit_User_State_Bool_Exp>;
};

export type Query_RootAudit_User_State_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAuth_Account_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

export type Query_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

export type Query_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAuth_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

export type Query_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

export type Query_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAuth_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

export type Query_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

export type Query_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAuth_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};

export type Query_RootAuth_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};

export type Query_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};

export type Query_RootAuth_Refresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

export type Query_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

export type Query_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};

export type Query_RootAuth_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Roles_Order_By>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};

export type Query_RootAuth_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Roles_Order_By>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};

export type Query_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};

export type Query_RootAward_PostsArgs = {
  distinct_on?: InputMaybe<Array<Award_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Award_Posts_Order_By>>;
  where?: InputMaybe<Award_Posts_Bool_Exp>;
};

export type Query_RootAward_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Award_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Award_Posts_Order_By>>;
  where?: InputMaybe<Award_Posts_Bool_Exp>;
};

export type Query_RootAward_Posts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAwardsArgs = {
  distinct_on?: InputMaybe<Array<Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Awards_Order_By>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

export type Query_RootAwards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Awards_Order_By>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

export type Query_RootAwards_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCalendarArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Order_By>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

export type Query_RootCalendar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Order_By>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

export type Query_RootCalendar_AttendeeArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

export type Query_RootCalendar_Attendee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

export type Query_RootCalendar_Attendee_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCalendar_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCalendar_Delete_EventsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Delete_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Delete_Events_Order_By>>;
  where?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
};

export type Query_RootCalendar_Delete_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Delete_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Delete_Events_Order_By>>;
  where?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
};

export type Query_RootCalendar_Delete_Events_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCalendar_EventArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

export type Query_RootCalendar_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

export type Query_RootCalendar_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCalendar_Event_Material_ViewArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Material_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Material_View_Order_By>>;
  where?: InputMaybe<Calendar_Event_Material_View_Bool_Exp>;
};

export type Query_RootCalendar_Event_Material_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Material_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Material_View_Order_By>>;
  where?: InputMaybe<Calendar_Event_Material_View_Bool_Exp>;
};

export type Query_RootCalendar_Meeting_CallArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

export type Query_RootCalendar_Meeting_Call_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

export type Query_RootCalendar_Meeting_Call_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCalendar_Meeting_Call_RequestArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

export type Query_RootCalendar_Meeting_Call_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

export type Query_RootCalendar_Meeting_Call_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCalendar_Meeting_Call_UserArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Query_RootCalendar_Meeting_Call_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Query_RootCalendar_Meeting_Call_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCalendar_Meeting_ViewArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_View_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_View_Bool_Exp>;
};

export type Query_RootCalendar_Meeting_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_View_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_View_Bool_Exp>;
};

export type Query_RootCallArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

export type Query_RootCall_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

export type Query_RootCall_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCall_RequestArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

export type Query_RootCall_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

export type Query_RootCall_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCall_UserArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

export type Query_RootCall_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

export type Query_RootCall_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCareer_Development_DetailArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

export type Query_RootCareer_Development_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

export type Query_RootCareer_Development_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCareer_Development_Role_DetailsArgs = {
  distinct_on?: InputMaybe<
    Array<Career_Development_Role_Details_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Role_Details_Order_By>>;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

export type Query_RootCareer_Development_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Career_Development_Role_Details_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Role_Details_Order_By>>;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

export type Query_RootCareer_Development_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCareer_External_CertificationArgs = {
  distinct_on?: InputMaybe<Array<Career_External_Certification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_External_Certification_Order_By>>;
  where?: InputMaybe<Career_External_Certification_Bool_Exp>;
};

export type Query_RootCareer_External_Certification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Career_External_Certification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_External_Certification_Order_By>>;
  where?: InputMaybe<Career_External_Certification_Bool_Exp>;
};

export type Query_RootCareer_External_Certification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_MessageArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Query_RootChat_Message_ActionArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Action_Order_By>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

export type Query_RootChat_Message_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Action_Order_By>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

export type Query_RootChat_Message_Action_By_PkArgs = {
  message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootChat_Message_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Query_RootChat_Message_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Message_FileArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_File_Order_By>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

export type Query_RootChat_Message_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_File_Order_By>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

export type Query_RootChat_Message_File_By_PkArgs = {
  chat_message_id: Scalars['uuid'];
  storage_file_id: Scalars['uuid'];
};

export type Query_RootChat_Message_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Reactions_Order_By>>;
  where?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
};

export type Query_RootChat_Message_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Reactions_Order_By>>;
  where?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
};

export type Query_RootChat_Message_Reactions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_PinnedArgs = {
  distinct_on?: InputMaybe<Array<Chat_Pinned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Pinned_Order_By>>;
  where?: InputMaybe<Chat_Pinned_Bool_Exp>;
};

export type Query_RootChat_Pinned_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Pinned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Pinned_Order_By>>;
  where?: InputMaybe<Chat_Pinned_Bool_Exp>;
};

export type Query_RootChat_Pinned_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_RoomArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

export type Query_RootChat_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

export type Query_RootChat_Room_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Room_UserArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

export type Query_RootChat_Room_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

export type Query_RootChat_Room_User_By_PkArgs = {
  chat_room_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootDashboard_BillableArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Bool_Exp>;
};

export type Query_RootDashboard_Billable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Bool_Exp>;
};

export type Query_RootDashboard_Billable_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Employee_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Query_RootDashboard_Billable_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Employee_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Query_RootDashboard_Billable_ManagerArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Manager_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
};

export type Query_RootDashboard_Billable_Manager_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Manager_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
};

export type Query_RootDashboard_MeetingArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Meeting_Order_By>>;
  where?: InputMaybe<Dashboard_Meeting_Bool_Exp>;
};

export type Query_RootDashboard_Meeting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Meeting_Order_By>>;
  where?: InputMaybe<Dashboard_Meeting_Bool_Exp>;
};

export type Query_RootDashboard_RemaindersArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Remainders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Remainders_Order_By>>;
  where?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
};

export type Query_RootDashboard_Remainders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Remainders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Remainders_Order_By>>;
  where?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
};

export type Query_RootDashboard_Remainders_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDepartmentArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Query_RootDepartment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Query_RootDepartment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDepartment_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Department_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Employee_Order_By>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

export type Query_RootDepartment_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Employee_Order_By>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

export type Query_RootDepartment_Employee_By_PkArgs = {
  department_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootDocument_TokenArgs = {
  distinct_on?: InputMaybe<Array<Document_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Token_Order_By>>;
  where?: InputMaybe<Document_Token_Bool_Exp>;
};

export type Query_RootDocument_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Token_Order_By>>;
  where?: InputMaybe<Document_Token_Bool_Exp>;
};

export type Query_RootDocument_Token_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Query_RootEmail_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Email_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Templates_Order_By>>;
  where?: InputMaybe<Email_Templates_Bool_Exp>;
};

export type Query_RootEmail_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Templates_Order_By>>;
  where?: InputMaybe<Email_Templates_Bool_Exp>;
};

export type Query_RootEmail_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootEmployee_Bandwidth_DataArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

export type Query_RootEmployee_Bandwidth_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

export type Query_RootEmployee_Bandwidth_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootEmployee_CapacityArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_Order_By>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

export type Query_RootEmployee_Capacity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_Order_By>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

export type Query_RootEmployee_Capacity_By_PkArgs = {
  date: Scalars['date'];
  employee_id: Scalars['uuid'];
};

export type Query_RootEmployee_Capacity_ViewArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_View_Order_By>>;
  where?: InputMaybe<Employee_Capacity_View_Bool_Exp>;
};

export type Query_RootEmployee_Capacity_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_View_Order_By>>;
  where?: InputMaybe<Employee_Capacity_View_Bool_Exp>;
};

export type Query_RootEnum_Activity_StateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Activity_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Activity_State_Order_By>>;
  where?: InputMaybe<Enum_Activity_State_Bool_Exp>;
};

export type Query_RootEnum_Activity_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Activity_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Activity_State_Order_By>>;
  where?: InputMaybe<Enum_Activity_State_Bool_Exp>;
};

export type Query_RootEnum_Activity_State_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Activity_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Activity_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Activity_Type_Order_By>>;
  where?: InputMaybe<Enum_Activity_Type_Bool_Exp>;
};

export type Query_RootEnum_Activity_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Activity_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Activity_Type_Order_By>>;
  where?: InputMaybe<Enum_Activity_Type_Bool_Exp>;
};

export type Query_RootEnum_Activity_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Attendee_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Attendee_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Attendee_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Attendee_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_Attendee_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Attendee_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Attendee_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Attendee_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_Attendee_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Attendee_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Attendee_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Attendee_Type_Order_By>>;
  where?: InputMaybe<Enum_Attendee_Type_Bool_Exp>;
};

export type Query_RootEnum_Attendee_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Attendee_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Attendee_Type_Order_By>>;
  where?: InputMaybe<Enum_Attendee_Type_Bool_Exp>;
};

export type Query_RootEnum_Attendee_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Award_Cycle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Award_Cycle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Award_Cycle_Type_Order_By>>;
  where?: InputMaybe<Enum_Award_Cycle_Type_Bool_Exp>;
};

export type Query_RootEnum_Award_Cycle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Award_Cycle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Award_Cycle_Type_Order_By>>;
  where?: InputMaybe<Enum_Award_Cycle_Type_Bool_Exp>;
};

export type Query_RootEnum_Award_Cycle_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_Access_PolicyArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Access_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Access_Policy_Order_By>>;
  where?: InputMaybe<Enum_Call_Access_Policy_Bool_Exp>;
};

export type Query_RootEnum_Call_Access_Policy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Access_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Access_Policy_Order_By>>;
  where?: InputMaybe<Enum_Call_Access_Policy_Bool_Exp>;
};

export type Query_RootEnum_Call_Access_Policy_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_Broadcasting_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Broadcasting_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Broadcasting_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Broadcasting_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Broadcasting_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_Media_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Media_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Media_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Media_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Media_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Media_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Media_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Media_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Media_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_Recording_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Recording_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Recording_Status_Order_By>>;
  where?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
};

export type Query_RootEnum_Call_Recording_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Recording_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Recording_Status_Order_By>>;
  where?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
};

export type Query_RootEnum_Call_Recording_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_Request_Response_TypeArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Call_Request_Response_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Response_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Response_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Request_Response_Type_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Call_Request_Response_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Response_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Response_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Request_Response_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_Request_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Request_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Request_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Request_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Request_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_Request_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Request_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Request_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Request_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Request_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Type_Bool_Exp>;
};

export type Query_RootEnum_Call_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Call_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
};

export type Query_RootEnum_Call_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
};

export type Query_RootEnum_Call_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Chat_Message_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Message_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Message_Type_Order_By>>;
  where?: InputMaybe<Enum_Chat_Message_Type_Bool_Exp>;
};

export type Query_RootEnum_Chat_Message_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Message_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Message_Type_Order_By>>;
  where?: InputMaybe<Enum_Chat_Message_Type_Bool_Exp>;
};

export type Query_RootEnum_Chat_Message_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Chat_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Notification_Order_By>>;
  where?: InputMaybe<Enum_Chat_Notification_Bool_Exp>;
};

export type Query_RootEnum_Chat_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Notification_Order_By>>;
  where?: InputMaybe<Enum_Chat_Notification_Bool_Exp>;
};

export type Query_RootEnum_Chat_Notification_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Chat_Room_Notification_StatusArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Chat_Room_Notification_Status_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_Notification_Status_Bool_Exp>;
};

export type Query_RootEnum_Chat_Room_Notification_Status_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Chat_Room_Notification_Status_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_Notification_Status_Bool_Exp>;
};

export type Query_RootEnum_Chat_Room_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Chat_Room_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_Type_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_Type_Bool_Exp>;
};

export type Query_RootEnum_Chat_Room_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_Type_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_Type_Bool_Exp>;
};

export type Query_RootEnum_Chat_Room_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Chat_Room_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Room_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_User_Status_Bool_Exp>;
};

export type Query_RootEnum_Chat_Room_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Room_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_User_Status_Bool_Exp>;
};

export type Query_RootEnum_Chat_Room_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Dashboard_Remainder_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Order_By>>;
  where?: InputMaybe<Enum_Dashboard_Remainder_Type_Bool_Exp>;
};

export type Query_RootEnum_Dashboard_Remainder_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Order_By>>;
  where?: InputMaybe<Enum_Dashboard_Remainder_Type_Bool_Exp>;
};

export type Query_RootEnum_Dashboard_Remainder_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Document_Token_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Document_Token_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Document_Token_Type_Order_By>>;
  where?: InputMaybe<Enum_Document_Token_Type_Bool_Exp>;
};

export type Query_RootEnum_Document_Token_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Document_Token_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Document_Token_Type_Order_By>>;
  where?: InputMaybe<Enum_Document_Token_Type_Bool_Exp>;
};

export type Query_RootEnum_Document_Token_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Event_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Event_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Event_Type_Order_By>>;
  where?: InputMaybe<Enum_Event_Type_Bool_Exp>;
};

export type Query_RootEnum_Event_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Event_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Event_Type_Order_By>>;
  where?: InputMaybe<Enum_Event_Type_Bool_Exp>;
};

export type Query_RootEnum_Event_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Leave_Donate_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Leave_Donate_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Leave_Donate_Type_Order_By>>;
  where?: InputMaybe<Enum_Leave_Donate_Type_Bool_Exp>;
};

export type Query_RootEnum_Leave_Donate_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Leave_Donate_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Leave_Donate_Type_Order_By>>;
  where?: InputMaybe<Enum_Leave_Donate_Type_Bool_Exp>;
};

export type Query_RootEnum_Leave_Donate_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Leave_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Leave_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Leave_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_Leave_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Leave_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Leave_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_Leave_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Live_Call_Request_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_Request_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_Request_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_Request_Status_Bool_Exp>;
};

export type Query_RootEnum_Live_Call_Request_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_Request_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_Request_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_Request_Status_Bool_Exp>;
};

export type Query_RootEnum_Live_Call_Request_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Live_Call_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_Status_Bool_Exp>;
};

export type Query_RootEnum_Live_Call_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_Status_Bool_Exp>;
};

export type Query_RootEnum_Live_Call_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Live_Call_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_User_Status_Bool_Exp>;
};

export type Query_RootEnum_Live_Call_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_User_Status_Bool_Exp>;
};

export type Query_RootEnum_Live_Call_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Live_Emote_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Emote_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Emote_Type_Order_By>>;
  where?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
};

export type Query_RootEnum_Live_Emote_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Emote_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Emote_Type_Order_By>>;
  where?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
};

export type Query_RootEnum_Live_Emote_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Manual_StateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Manual_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Manual_State_Order_By>>;
  where?: InputMaybe<Enum_Manual_State_Bool_Exp>;
};

export type Query_RootEnum_Manual_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Manual_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Manual_State_Order_By>>;
  where?: InputMaybe<Enum_Manual_State_Bool_Exp>;
};

export type Query_RootEnum_Manual_State_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Meeting_Room_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_Status_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_Status_Bool_Exp>;
};

export type Query_RootEnum_Meeting_Room_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_Status_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_Status_Bool_Exp>;
};

export type Query_RootEnum_Meeting_Room_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Meeting_Room_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_Type_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_Type_Bool_Exp>;
};

export type Query_RootEnum_Meeting_Room_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_Type_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_Type_Bool_Exp>;
};

export type Query_RootEnum_Meeting_Room_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Meeting_Room_User_StateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_User_State_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_User_State_Bool_Exp>;
};

export type Query_RootEnum_Meeting_Room_User_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_User_State_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_User_State_Bool_Exp>;
};

export type Query_RootEnum_Meeting_Room_User_State_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Notification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Notification_Type_Order_By>>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};

export type Query_RootEnum_Notification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Notification_Type_Order_By>>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};

export type Query_RootEnum_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Personalised_AccessArgs = {
  distinct_on?: InputMaybe<Array<Enum_Personalised_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Personalised_Access_Order_By>>;
  where?: InputMaybe<Enum_Personalised_Access_Bool_Exp>;
};

export type Query_RootEnum_Personalised_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Personalised_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Personalised_Access_Order_By>>;
  where?: InputMaybe<Enum_Personalised_Access_Bool_Exp>;
};

export type Query_RootEnum_Personalised_Access_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Personalised_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Enum_Personalised_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Personalised_Categories_Order_By>>;
  where?: InputMaybe<Enum_Personalised_Categories_Bool_Exp>;
};

export type Query_RootEnum_Personalised_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Personalised_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Personalised_Categories_Order_By>>;
  where?: InputMaybe<Enum_Personalised_Categories_Bool_Exp>;
};

export type Query_RootEnum_Personalised_Categories_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Post_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Post_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Post_Type_Order_By>>;
  where?: InputMaybe<Enum_Post_Type_Bool_Exp>;
};

export type Query_RootEnum_Post_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Post_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Post_Type_Order_By>>;
  where?: InputMaybe<Enum_Post_Type_Bool_Exp>;
};

export type Query_RootEnum_Post_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Presence_Connection_StatusArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Presence_Connection_Status_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Presence_Connection_Status_Order_By>>;
  where?: InputMaybe<Enum_Presence_Connection_Status_Bool_Exp>;
};

export type Query_RootEnum_Presence_Connection_Status_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Presence_Connection_Status_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Presence_Connection_Status_Order_By>>;
  where?: InputMaybe<Enum_Presence_Connection_Status_Bool_Exp>;
};

export type Query_RootEnum_Presence_Connection_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Project_Milestone_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Milestone_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Milestone_Status_Order_By>>;
  where?: InputMaybe<Enum_Project_Milestone_Status_Bool_Exp>;
};

export type Query_RootEnum_Project_Milestone_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Milestone_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Milestone_Status_Order_By>>;
  where?: InputMaybe<Enum_Project_Milestone_Status_Bool_Exp>;
};

export type Query_RootEnum_Project_Milestone_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Project_Sprint_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Sprint_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Sprint_Category_Order_By>>;
  where?: InputMaybe<Enum_Project_Sprint_Category_Bool_Exp>;
};

export type Query_RootEnum_Project_Sprint_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Sprint_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Sprint_Category_Order_By>>;
  where?: InputMaybe<Enum_Project_Sprint_Category_Bool_Exp>;
};

export type Query_RootEnum_Project_Sprint_Category_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Project_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Status_Order_By>>;
  where?: InputMaybe<Enum_Project_Status_Bool_Exp>;
};

export type Query_RootEnum_Project_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Status_Order_By>>;
  where?: InputMaybe<Enum_Project_Status_Bool_Exp>;
};

export type Query_RootEnum_Project_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Project_Task_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Task_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Task_Category_Order_By>>;
  where?: InputMaybe<Enum_Project_Task_Category_Bool_Exp>;
};

export type Query_RootEnum_Project_Task_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Task_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Task_Category_Order_By>>;
  where?: InputMaybe<Enum_Project_Task_Category_Bool_Exp>;
};

export type Query_RootEnum_Project_Task_Category_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Setting_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Setting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Setting_Type_Order_By>>;
  where?: InputMaybe<Enum_Setting_Type_Bool_Exp>;
};

export type Query_RootEnum_Setting_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Setting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Setting_Type_Order_By>>;
  where?: InputMaybe<Enum_Setting_Type_Bool_Exp>;
};

export type Query_RootEnum_Setting_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Storage_File_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Storage_File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Storage_File_Type_Order_By>>;
  where?: InputMaybe<Enum_Storage_File_Type_Bool_Exp>;
};

export type Query_RootEnum_Storage_File_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Storage_File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Storage_File_Type_Order_By>>;
  where?: InputMaybe<Enum_Storage_File_Type_Bool_Exp>;
};

export type Query_RootEnum_Storage_File_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_System_StateArgs = {
  distinct_on?: InputMaybe<Array<Enum_System_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_System_State_Order_By>>;
  where?: InputMaybe<Enum_System_State_Bool_Exp>;
};

export type Query_RootEnum_System_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_System_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_System_State_Order_By>>;
  where?: InputMaybe<Enum_System_State_Bool_Exp>;
};

export type Query_RootEnum_System_State_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_System_State_OwnerArgs = {
  distinct_on?: InputMaybe<Array<Enum_System_State_Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_System_State_Owner_Order_By>>;
  where?: InputMaybe<Enum_System_State_Owner_Bool_Exp>;
};

export type Query_RootEnum_System_State_Owner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_System_State_Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_System_State_Owner_Order_By>>;
  where?: InputMaybe<Enum_System_State_Owner_Bool_Exp>;
};

export type Query_RootEnum_System_State_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Task_PriorityArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Priority_Order_By>>;
  where?: InputMaybe<Enum_Task_Priority_Bool_Exp>;
};

export type Query_RootEnum_Task_Priority_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Priority_Order_By>>;
  where?: InputMaybe<Enum_Task_Priority_Bool_Exp>;
};

export type Query_RootEnum_Task_Priority_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Task_Recurring_FrequencyArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Order_By>>;
  where?: InputMaybe<Enum_Task_Recurring_Frequency_Bool_Exp>;
};

export type Query_RootEnum_Task_Recurring_Frequency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Order_By>>;
  where?: InputMaybe<Enum_Task_Recurring_Frequency_Bool_Exp>;
};

export type Query_RootEnum_Task_Recurring_Frequency_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Task_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Status_Order_By>>;
  where?: InputMaybe<Enum_Task_Status_Bool_Exp>;
};

export type Query_RootEnum_Task_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Status_Order_By>>;
  where?: InputMaybe<Enum_Task_Status_Bool_Exp>;
};

export type Query_RootEnum_Task_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Task_Time_Sheet_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Order_By>>;
  where?: InputMaybe<Enum_Task_Time_Sheet_Status_Bool_Exp>;
};

export type Query_RootEnum_Task_Time_Sheet_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Order_By>>;
  where?: InputMaybe<Enum_Task_Time_Sheet_Status_Bool_Exp>;
};

export type Query_RootEnum_Task_Time_Sheet_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_User_Activity_Tracker_Status_TypeArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_User_Activity_Tracker_Status_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Activity_Tracker_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_User_Activity_Tracker_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_User_Activity_Tracker_Status_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Activity_Tracker_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>;
};

export type Query_RootEnum_User_Activity_Tracker_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_User_Avatar_TypesArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Avatar_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Avatar_Types_Order_By>>;
  where?: InputMaybe<Enum_User_Avatar_Types_Bool_Exp>;
};

export type Query_RootEnum_User_Avatar_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Avatar_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Avatar_Types_Order_By>>;
  where?: InputMaybe<Enum_User_Avatar_Types_Bool_Exp>;
};

export type Query_RootEnum_User_Avatar_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_User_GenderArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Gender_Order_By>>;
  where?: InputMaybe<Enum_User_Gender_Bool_Exp>;
};

export type Query_RootEnum_User_Gender_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Gender_Order_By>>;
  where?: InputMaybe<Enum_User_Gender_Bool_Exp>;
};

export type Query_RootEnum_User_Gender_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_User_Presence_Provider_TypeArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_User_Presence_Provider_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Presence_Provider_Type_Bool_Exp>;
};

export type Query_RootEnum_User_Presence_Provider_Type_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_User_Presence_Provider_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Presence_Provider_Type_Bool_Exp>;
};

export type Query_RootEnum_User_Presence_Provider_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_User_Settings_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Settings_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Settings_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Settings_Type_Bool_Exp>;
};

export type Query_RootEnum_User_Settings_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Settings_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Settings_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Settings_Type_Bool_Exp>;
};

export type Query_RootEnum_User_Settings_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootFilter_DefinitionArgs = {
  distinct_on?: InputMaybe<Array<Filter_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filter_Definition_Order_By>>;
  where?: InputMaybe<Filter_Definition_Bool_Exp>;
};

export type Query_RootFilter_Definition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filter_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filter_Definition_Order_By>>;
  where?: InputMaybe<Filter_Definition_Bool_Exp>;
};

export type Query_RootFilter_Definition_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFinancialYearArgs = {
  distinct_on?: InputMaybe<Array<FinancialYear_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FinancialYear_Order_By>>;
  where?: InputMaybe<FinancialYear_Bool_Exp>;
};

export type Query_RootFinancialYear_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FinancialYear_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FinancialYear_Order_By>>;
  where?: InputMaybe<FinancialYear_Bool_Exp>;
};

export type Query_RootFinancialYear_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFrequencyArgs = {
  distinct_on?: InputMaybe<Array<Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frequency_Order_By>>;
  where?: InputMaybe<Frequency_Bool_Exp>;
};

export type Query_RootFrequency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frequency_Order_By>>;
  where?: InputMaybe<Frequency_Bool_Exp>;
};

export type Query_RootFrequency_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGet_All_Dependent_Tasks_NewArgs = {
  args: Get_All_Dependent_Tasks_New_Args;
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Query_RootGet_All_Dependent_Tasks_New_AggregateArgs = {
  args: Get_All_Dependent_Tasks_New_Args;
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Query_RootGet_Chat_Room_Id_From_Event_Id_For_Meeting_CallArgs = {
  args: Get_Chat_Room_Id_From_Event_Id_For_Meeting_Call_Args;
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

export type Query_RootGet_Chat_Room_Id_From_Event_Id_For_Meeting_Call_AggregateArgs =
  {
    args: Get_Chat_Room_Id_From_Event_Id_For_Meeting_Call_Args;
    distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
    where?: InputMaybe<Chat_Room_Bool_Exp>;
  };

export type Query_RootGet_Employee_Billable_HoursArgs = {
  args: Get_Employee_Billable_Hours_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Employee_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Query_RootGet_Employee_Billable_Hours_AggregateArgs = {
  args: Get_Employee_Billable_Hours_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Employee_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Query_RootGet_Employee_Billable_Hours_As_ManagerArgs = {
  args: Get_Employee_Billable_Hours_As_Manager_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Manager_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
};

export type Query_RootGet_Employee_Billable_Hours_As_Manager_AggregateArgs = {
  args: Get_Employee_Billable_Hours_As_Manager_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Manager_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
};

export type Query_RootGoalWithKpiArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

export type Query_RootGoalWithKpi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

export type Query_RootGoalWithKpi_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGoalWithTimePeriodArgs = {
  distinct_on?: InputMaybe<Array<GoalWithTimePeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithTimePeriod_Order_By>>;
  where?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
};

export type Query_RootGoalWithTimePeriod_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalWithTimePeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithTimePeriod_Order_By>>;
  where?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
};

export type Query_RootGoalWithTimePeriod_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGoalsArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

export type Query_RootGoalsWithDeptsArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithDepts_Order_By>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

export type Query_RootGoalsWithDepts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithDepts_Order_By>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

export type Query_RootGoalsWithDepts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGoalsWithFreqsArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithFreqs_Order_By>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

export type Query_RootGoalsWithFreqs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithFreqs_Order_By>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

export type Query_RootGoalsWithFreqs_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGoalsWithRolesArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithRoles_Order_By>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

export type Query_RootGoalsWithRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithRoles_Order_By>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

export type Query_RootGoalsWithRoles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

export type Query_RootGoals_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHotdesk_BookingsArgs = {
  distinct_on?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hotdesk_Bookings_Order_By>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

export type Query_RootHotdesk_Bookings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hotdesk_Bookings_Order_By>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

export type Query_RootHotdesk_Bookings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootJoined_Call_UsrsArgs = {
  args: Joined_Call_Usrs_Args;
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Query_RootJoined_Call_Usrs_AggregateArgs = {
  args: Joined_Call_Usrs_Args;
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Query_RootKpiWithDeptArgs = {
  distinct_on?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithDept_Order_By>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

export type Query_RootKpiWithDept_AggregateArgs = {
  distinct_on?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithDept_Order_By>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

export type Query_RootKpiWithDept_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootKpiWithRoleArgs = {
  distinct_on?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithRole_Order_By>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

export type Query_RootKpiWithRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithRole_Order_By>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

export type Query_RootKpiWithRole_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootKpi_BankArgs = {
  distinct_on?: InputMaybe<Array<Kpi_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kpi_Bank_Order_By>>;
  where?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

export type Query_RootKpi_Bank_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kpi_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kpi_Bank_Order_By>>;
  where?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

export type Query_RootKpi_Bank_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLeaveArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

export type Query_RootLeave_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

export type Query_RootLeave_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLeave_DonateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

export type Query_RootLeave_Donate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

export type Query_RootLeave_Donate_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLeave_OrganizationArgs = {
  distinct_on?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Organization_Order_By>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

export type Query_RootLeave_Organization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Organization_Order_By>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

export type Query_RootLeave_Organization_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootList_Of_SkillsArgs = {
  distinct_on?: InputMaybe<Array<List_Of_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Of_Skills_Order_By>>;
  where?: InputMaybe<List_Of_Skills_Bool_Exp>;
};

export type Query_RootList_Of_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Of_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Of_Skills_Order_By>>;
  where?: InputMaybe<List_Of_Skills_Bool_Exp>;
};

export type Query_RootList_Of_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLive_CallArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Order_By>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

export type Query_RootLive_Call_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Order_By>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

export type Query_RootLive_Call_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLive_Call_RequestArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

export type Query_RootLive_Call_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

export type Query_RootLive_Call_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLive_Call_UserArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

export type Query_RootLive_Call_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

export type Query_RootLive_Call_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMain_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Main_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Main_Skills_Order_By>>;
  where?: InputMaybe<Main_Skills_Bool_Exp>;
};

export type Query_RootMain_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Main_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Main_Skills_Order_By>>;
  where?: InputMaybe<Main_Skills_Bool_Exp>;
};

export type Query_RootMain_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMeeting_Call_SchedularArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Call_Schedular_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Call_Schedular_Order_By>>;
  where?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
};

export type Query_RootMeeting_Call_Schedular_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Call_Schedular_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Call_Schedular_Order_By>>;
  where?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
};

export type Query_RootMeeting_Call_Schedular_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMeeting_RoomArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

export type Query_RootMeeting_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

export type Query_RootMeeting_Room_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMeeting_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

export type Query_RootMeeting_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

export type Query_RootMeeting_Room_Users_By_PkArgs = {
  meeting_room_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Query_RootOrganization_Active_Leave_TypesArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_Active_Leave_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Active_Leave_Types_Order_By>>;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

export type Query_RootOrganization_Active_Leave_Types_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_Active_Leave_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Active_Leave_Types_Order_By>>;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

export type Query_RootOrganization_Active_Leave_Types_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_AdminArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

export type Query_RootOrganization_Admin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

export type Query_RootOrganization_Admin_By_PkArgs = {
  organization_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Query_RootOrganization_AwardsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Awards_Order_By>>;
  where?: InputMaybe<Organization_Awards_Bool_Exp>;
};

export type Query_RootOrganization_Awards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Awards_Order_By>>;
  where?: InputMaybe<Organization_Awards_Bool_Exp>;
};

export type Query_RootOrganization_Awards_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Query_RootOrganization_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Query_RootOrganization_Employee_By_PkArgs = {
  organization_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootOrganization_HolidaysArgs = {
  distinct_on?: InputMaybe<Array<Organization_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Holidays_Order_By>>;
  where?: InputMaybe<Organization_Holidays_Bool_Exp>;
};

export type Query_RootOrganization_Holidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Holidays_Order_By>>;
  where?: InputMaybe<Organization_Holidays_Bool_Exp>;
};

export type Query_RootOrganization_Holidays_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_Leave_CyclesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leave_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leave_Cycles_Order_By>>;
  where?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
};

export type Query_RootOrganization_Leave_Cycles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leave_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leave_Cycles_Order_By>>;
  where?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
};

export type Query_RootOrganization_Leave_Cycles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

export type Query_RootOrganization_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

export type Query_RootOrganization_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_RolesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Roles_Order_By>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

export type Query_RootOrganization_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Roles_Order_By>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

export type Query_RootOrganization_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_UserArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Order_By>>;
  where?: InputMaybe<Organization_User_Bool_Exp>;
};

export type Query_RootOrganization_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Order_By>>;
  where?: InputMaybe<Organization_User_Bool_Exp>;
};

export type Query_RootOrganization_User_Approved_LeavesArgs = {
  args: Organization_User_Approved_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Query_RootOrganization_User_Approved_Leaves_AggregateArgs = {
  args: Organization_User_Approved_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_ApplicationArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_Application_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_Application_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_User_Leave_DataArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Data_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Data_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_User_Leave_DatesArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Dates_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_Dates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Dates_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_Dates_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_User_Leave_FilesArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Files_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Files_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
};

export type Query_RootOrganization_User_Leave_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganization_User_Pending_LeavesArgs = {
  args: Organization_User_Pending_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Query_RootOrganization_User_Pending_Leaves_AggregateArgs = {
  args: Organization_User_Pending_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Query_RootOrganization_User_Rejected_LeavesArgs = {
  args: Organization_User_Rejected_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Query_RootOrganization_User_Rejected_Leaves_AggregateArgs = {
  args: Organization_User_Rejected_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Query_RootPerformanceReviewStatusArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

export type Query_RootPerformanceReviewStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

export type Query_RootPerformanceReviewStatus_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPerformance_Rating_ScaleArgs = {
  distinct_on?: InputMaybe<Array<Performance_Rating_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Rating_Scale_Order_By>>;
  where?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
};

export type Query_RootPerformance_Rating_Scale_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Rating_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Rating_Scale_Order_By>>;
  where?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
};

export type Query_RootPerformance_Rating_Scale_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPerformance_Review_ByUserArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

export type Query_RootPerformance_Review_ByUser_KpiArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
};

export type Query_RootPerformance_Review_ByUser_Kpi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
};

export type Query_RootPerformance_Review_ByUser_Kpi_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPerformance_Review_ByUser_WithGoalArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_ByUser_WithGoal_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
};

export type Query_RootPerformance_Review_ByUser_WithGoal_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_ByUser_WithGoal_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
};

export type Query_RootPerformance_Review_ByUser_WithGoal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPerformance_Review_ByUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

export type Query_RootPerformance_Review_ByUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPerformance_Review_Setting_GoalsListArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_Setting_GoalsList_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
  where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
};

export type Query_RootPerformance_Review_Setting_GoalsList_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_Setting_GoalsList_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
  where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
};

export type Query_RootPerformance_Review_Setting_GoalsList_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPerformance_Review_Setting_With_GoalsArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_Setting_With_Goals_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Setting_With_Goals_Order_By>>;
  where?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
};

export type Query_RootPerformance_Review_Setting_With_Goals_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_Setting_With_Goals_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Setting_With_Goals_Order_By>>;
  where?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
};

export type Query_RootPerformance_Review_Setting_With_Goals_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPerformance_Review_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Settings_Order_By>>;
  where?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
};

export type Query_RootPerformance_Review_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Settings_Order_By>>;
  where?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
};

export type Query_RootPerformance_Review_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPersonalisedArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

export type Query_RootPersonalised_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

export type Query_RootPersonalised_By_PkArgs = {
  created_at: Scalars['timestamp'];
};

export type Query_RootPost_Comment_FilesArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Files_Order_By>>;
  where?: InputMaybe<Post_Comment_Files_Bool_Exp>;
};

export type Query_RootPost_Comment_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Files_Order_By>>;
  where?: InputMaybe<Post_Comment_Files_Bool_Exp>;
};

export type Query_RootPost_Comment_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPost_Comment_Mentioned_UsersArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
};

export type Query_RootPost_Comment_Mentioned_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
};

export type Query_RootPost_Comment_Mentioned_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPost_Comment_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
};

export type Query_RootPost_Comment_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
};

export type Query_RootPost_Comment_Replies_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPost_Comment_Replies_Mentioned_UsersArgs = {
  distinct_on?: InputMaybe<
    Array<Post_Comment_Replies_Mentioned_Users_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
};

export type Query_RootPost_Comment_Replies_Mentioned_Users_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Post_Comment_Replies_Mentioned_Users_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
};

export type Query_RootPost_Comment_Replies_Mentioned_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPost_Comments_LikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comments_Likes_Order_By>>;
  where?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
};

export type Query_RootPost_Comments_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comments_Likes_Order_By>>;
  where?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
};

export type Query_RootPost_Comments_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPost_LikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Likes_Order_By>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

export type Query_RootPost_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Likes_Order_By>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

export type Query_RootPost_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPost_Mentioned_UsersArgs = {
  distinct_on?: InputMaybe<Array<Post_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
};

export type Query_RootPost_Mentioned_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
};

export type Query_RootPost_Mentioned_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPost_Replies_LikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Replies_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Replies_Likes_Order_By>>;
  where?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
};

export type Query_RootPost_Replies_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Replies_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Replies_Likes_Order_By>>;
  where?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
};

export type Query_RootPost_Replies_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

export type Query_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

export type Query_RootPosts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPosts_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Comments_Order_By>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

export type Query_RootPosts_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Comments_Order_By>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

export type Query_RootPosts_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPosts_FilesArgs = {
  distinct_on?: InputMaybe<Array<Posts_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Files_Order_By>>;
  where?: InputMaybe<Posts_Files_Bool_Exp>;
};

export type Query_RootPosts_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Files_Order_By>>;
  where?: InputMaybe<Posts_Files_Bool_Exp>;
};

export type Query_RootPosts_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPosts_Poll_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
};

export type Query_RootPosts_Poll_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
};

export type Query_RootPosts_Poll_Choices_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPosts_Poll_Choices_VotesArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Votes_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
};

export type Query_RootPosts_Poll_Choices_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Votes_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
};

export type Query_RootPosts_Poll_Choices_Votes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

export type Query_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

export type Query_RootProfile_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Query_RootProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Query_RootProject_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_ClientArgs = {
  distinct_on?: InputMaybe<Array<Project_Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Client_Order_By>>;
  where?: InputMaybe<Project_Client_Bool_Exp>;
};

export type Query_RootProject_Client_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Client_Order_By>>;
  where?: InputMaybe<Project_Client_Bool_Exp>;
};

export type Query_RootProject_Client_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_LinksArgs = {
  distinct_on?: InputMaybe<Array<Project_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Links_Order_By>>;
  where?: InputMaybe<Project_Links_Bool_Exp>;
};

export type Query_RootProject_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Links_Order_By>>;
  where?: InputMaybe<Project_Links_Bool_Exp>;
};

export type Query_RootProject_Links_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_MembersArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_Order_By>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

export type Query_RootProject_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_Order_By>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

export type Query_RootProject_Members_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootProject_MilestonesArgs = {
  distinct_on?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Milestones_Order_By>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

export type Query_RootProject_Milestones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Milestones_Order_By>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

export type Query_RootProject_Milestones_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Notification_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Project_Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Notification_Settings_Order_By>>;
  where?: InputMaybe<Project_Notification_Settings_Bool_Exp>;
};

export type Query_RootProject_Notification_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Notification_Settings_Order_By>>;
  where?: InputMaybe<Project_Notification_Settings_Bool_Exp>;
};

export type Query_RootProject_Notification_Settings_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootProject_SectionArgs = {
  distinct_on?: InputMaybe<Array<Project_Section_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Section_Order_By>>;
  where?: InputMaybe<Project_Section_Bool_Exp>;
};

export type Query_RootProject_Section_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Section_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Section_Order_By>>;
  where?: InputMaybe<Project_Section_Bool_Exp>;
};

export type Query_RootProject_Section_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_SprintsArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

export type Query_RootProject_Sprints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

export type Query_RootProject_Sprints_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootQuestion_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Options_Order_By>>;
  where?: InputMaybe<Question_Options_Bool_Exp>;
};

export type Query_RootQuestion_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Options_Order_By>>;
  where?: InputMaybe<Question_Options_Bool_Exp>;
};

export type Query_RootQuestion_Options_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

export type Query_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSearch_Chat_Message_TextArgs = {
  args: Search_Chat_Message_Text_Args;
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Query_RootSearch_Chat_Message_Text_AggregateArgs = {
  args: Search_Chat_Message_Text_Args;
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Query_RootSkills_DetailArgs = {
  distinct_on?: InputMaybe<Array<Skills_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skills_Detail_Order_By>>;
  where?: InputMaybe<Skills_Detail_Bool_Exp>;
};

export type Query_RootSkills_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skills_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skills_Detail_Order_By>>;
  where?: InputMaybe<Skills_Detail_Bool_Exp>;
};

export type Query_RootSkills_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStage_Career_DetailArgs = {
  distinct_on?: InputMaybe<Array<Stage_Career_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stage_Career_Detail_Order_By>>;
  where?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
};

export type Query_RootStage_Career_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stage_Career_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stage_Career_Detail_Order_By>>;
  where?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
};

export type Query_RootStage_Career_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStorage_FilesArgs = {
  distinct_on?: InputMaybe<Array<Storage_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Files_Order_By>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

export type Query_RootStorage_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Files_Order_By>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

export type Query_RootStorage_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStress_DataArgs = {
  distinct_on?: InputMaybe<Array<Stress_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stress_Data_Order_By>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

export type Query_RootStress_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stress_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stress_Data_Order_By>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

export type Query_RootStress_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSub_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sub_Skills_Order_By>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

export type Query_RootSub_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sub_Skills_Order_By>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

export type Query_RootSub_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTaskArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type Query_RootTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type Query_RootTask_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Approvers_Order_By>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

export type Query_RootTask_Approvers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Approvers_Order_By>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

export type Query_RootTask_Approvers_By_PkArgs = {
  approver_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Query_RootTask_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTask_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Checklist_Order_By>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

export type Query_RootTask_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Checklist_Order_By>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

export type Query_RootTask_Checklist_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTask_CollaboratorArgs = {
  distinct_on?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Collaborator_Order_By>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

export type Query_RootTask_Collaborator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Collaborator_Order_By>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

export type Query_RootTask_Collaborator_By_PkArgs = {
  task_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootTask_CombinedArgs = {
  distinct_on?: InputMaybe<Array<Task_Combined_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Combined_Order_By>>;
  where?: InputMaybe<Task_Combined_Bool_Exp>;
};

export type Query_RootTask_Combined_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Combined_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Combined_Order_By>>;
  where?: InputMaybe<Task_Combined_Bool_Exp>;
};

export type Query_RootTask_CommentArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

export type Query_RootTask_Comment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

export type Query_RootTask_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTask_Comment_LikeArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Like_Order_By>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

export type Query_RootTask_Comment_Like_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Like_Order_By>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

export type Query_RootTask_Comment_Like_By_PkArgs = {
  task_comment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Query_RootTask_DependenciesArgs = {
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Query_RootTask_Dependencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Query_RootTask_Dependencies_By_PkArgs = {
  dependent_on_task_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Query_RootTask_FileArgs = {
  distinct_on?: InputMaybe<Array<Task_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_File_Order_By>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

export type Query_RootTask_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_File_Order_By>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

export type Query_RootTask_File_By_PkArgs = {
  storage_file_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Query_RootTask_MilestonesArgs = {
  distinct_on?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Milestones_Order_By>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

export type Query_RootTask_Milestones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Milestones_Order_By>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

export type Query_RootTask_Milestones_By_PkArgs = {
  milestone_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Query_RootTask_TagsArgs = {
  distinct_on?: InputMaybe<Array<Task_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Tags_Order_By>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

export type Query_RootTask_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Tags_Order_By>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

export type Query_RootTask_Tags_By_PkArgs = {
  tag_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Query_RootTask_Time_SheetArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

export type Query_RootTask_Time_Sheet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

export type Query_RootTask_Time_Sheet_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTask_UnscheduledArgs = {
  distinct_on?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Unscheduled_Order_By>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

export type Query_RootTask_Unscheduled_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Unscheduled_Order_By>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

export type Query_RootTask_Unscheduled_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTime_PeriodArgs = {
  distinct_on?: InputMaybe<Array<Time_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Period_Order_By>>;
  where?: InputMaybe<Time_Period_Bool_Exp>;
};

export type Query_RootTime_Period_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Time_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Period_Order_By>>;
  where?: InputMaybe<Time_Period_Bool_Exp>;
};

export type Query_RootTime_Period_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

export type Query_RootTraining_SkillArgs = {
  distinct_on?: InputMaybe<Array<Training_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Skill_Order_By>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

export type Query_RootTraining_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Skill_Order_By>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

export type Query_RootTraining_Skill_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTraining_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

export type Query_RootTraining_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTraining_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

export type Query_RootTraining_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

export type Query_RootTraining_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTraining_Progress_RecordArgs = {
  distinct_on?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Progress_Record_Order_By>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

export type Query_RootTraining_Progress_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Progress_Record_Order_By>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

export type Query_RootTraining_Progress_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTraining_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

export type Query_RootTraining_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

export type Query_RootTraining_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTraining_Stage_CareerArgs = {
  distinct_on?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Stage_Career_Order_By>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

export type Query_RootTraining_Stage_Career_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Stage_Career_Order_By>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

export type Query_RootTraining_Stage_Career_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTraining_Table_Of_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Training_Table_Of_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Table_Of_Contents_Order_By>>;
  where?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
};

export type Query_RootTraining_Table_Of_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Table_Of_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Table_Of_Contents_Order_By>>;
  where?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
};

export type Query_RootTraining_Table_Of_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTraining_Type_For_TrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Type_For_Training_Order_By>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

export type Query_RootTraining_Type_For_Training_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Type_For_Training_Order_By>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

export type Query_RootTraining_Type_For_Training_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUpdateTaskRankArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUsed_Leave_ViewArgs = {
  distinct_on?: InputMaybe<Array<Used_Leave_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Used_Leave_View_Order_By>>;
  where?: InputMaybe<Used_Leave_View_Bool_Exp>;
};

export type Query_RootUsed_Leave_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Used_Leave_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Used_Leave_View_Order_By>>;
  where?: InputMaybe<Used_Leave_View_Bool_Exp>;
};

export type Query_RootUser_CompetencyArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

export type Query_RootUser_Competency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

export type Query_RootUser_Competency_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_SkillsArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Order_By>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

export type Query_RootUser_Skills_AssessmentArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Assessment_Order_By>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

export type Query_RootUser_Skills_Assessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Assessment_Order_By>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

export type Query_RootUser_Skills_Assessment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Skills_TrainingArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Training_Order_By>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

export type Query_RootUser_Skills_Training_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Training_Order_By>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

export type Query_RootUser_Skills_Training_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Order_By>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

export type Query_RootUser_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Activity_TrackerArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Tracker_Order_By>>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

export type Query_RootUser_Activity_Tracker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Tracker_Order_By>>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

export type Query_RootUser_Activity_Tracker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_AvatarArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Order_By>>;
  where?: InputMaybe<User_Avatar_Bool_Exp>;
};

export type Query_RootUser_Avatar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Order_By>>;
  where?: InputMaybe<User_Avatar_Bool_Exp>;
};

export type Query_RootUser_Avatar_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Query_RootUser_Avatar_FilesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

export type Query_RootUser_Avatar_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

export type Query_RootUser_Avatar_Files_By_PkArgs = {
  avatar_id: Scalars['uuid'];
};

export type Query_RootUser_Career_Development_RecordArgs = {
  distinct_on?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_Development_Record_Order_By>>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

export type Query_RootUser_Career_Development_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_Development_Record_Order_By>>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

export type Query_RootUser_Career_Development_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Career_External_CertificationArgs = {
  distinct_on?: InputMaybe<
    Array<User_Career_External_Certification_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

export type Query_RootUser_Career_External_Certification_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<User_Career_External_Certification_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

export type Query_RootUser_Career_External_Certification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_PresenceArgs = {
  distinct_on?: InputMaybe<Array<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

export type Query_RootUser_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

export type Query_RootUser_Presence_By_PkArgs = {
  connection_id: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type Query_RootUser_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

export type Query_RootUser_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

export type Query_RootUser_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_StateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

export type Query_RootUser_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

export type Query_RootUser_State_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** each option for particular question */
export type Question_Options = {
  __typename?: 'question_options';
  correct_answer: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "question_options" */
export type Question_Options_Aggregate = {
  __typename?: 'question_options_aggregate';
  aggregate?: Maybe<Question_Options_Aggregate_Fields>;
  nodes: Array<Question_Options>;
};

export type Question_Options_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Question_Options_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Question_Options_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Question_Options_Aggregate_Bool_Exp_Count>;
};

export type Question_Options_Aggregate_Bool_Exp_Bool_And = {
  arguments: Question_Options_Select_Column_Question_Options_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Question_Options_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Question_Options_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Question_Options_Select_Column_Question_Options_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Question_Options_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Question_Options_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Question_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Question_Options_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "question_options" */
export type Question_Options_Aggregate_Fields = {
  __typename?: 'question_options_aggregate_fields';
  avg?: Maybe<Question_Options_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Question_Options_Max_Fields>;
  min?: Maybe<Question_Options_Min_Fields>;
  stddev?: Maybe<Question_Options_Stddev_Fields>;
  stddev_pop?: Maybe<Question_Options_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Question_Options_Stddev_Samp_Fields>;
  sum?: Maybe<Question_Options_Sum_Fields>;
  var_pop?: Maybe<Question_Options_Var_Pop_Fields>;
  var_samp?: Maybe<Question_Options_Var_Samp_Fields>;
  variance?: Maybe<Question_Options_Variance_Fields>;
};

/** aggregate fields of "question_options" */
export type Question_Options_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Question_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "question_options" */
export type Question_Options_Aggregate_Order_By = {
  avg?: InputMaybe<Question_Options_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Question_Options_Max_Order_By>;
  min?: InputMaybe<Question_Options_Min_Order_By>;
  stddev?: InputMaybe<Question_Options_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Question_Options_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Question_Options_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Question_Options_Sum_Order_By>;
  var_pop?: InputMaybe<Question_Options_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Question_Options_Var_Samp_Order_By>;
  variance?: InputMaybe<Question_Options_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "question_options" */
export type Question_Options_Arr_Rel_Insert_Input = {
  data: Array<Question_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_Options_On_Conflict>;
};

/** aggregate avg on columns */
export type Question_Options_Avg_Fields = {
  __typename?: 'question_options_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "question_options" */
export type Question_Options_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "question_options". All fields are combined with a logical 'AND'. */
export type Question_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Question_Options_Bool_Exp>>;
  _not?: InputMaybe<Question_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Options_Bool_Exp>>;
  correct_answer?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "question_options" */
export enum Question_Options_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionOptionsPkey = 'question_options_pkey',
}

/** input type for incrementing numeric columns in table "question_options" */
export type Question_Options_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "question_options" */
export type Question_Options_Insert_Input = {
  correct_answer?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Question_Options_Max_Fields = {
  __typename?: 'question_options_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "question_options" */
export type Question_Options_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Question_Options_Min_Fields = {
  __typename?: 'question_options_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "question_options" */
export type Question_Options_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "question_options" */
export type Question_Options_Mutation_Response = {
  __typename?: 'question_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Question_Options>;
};

/** on_conflict condition type for table "question_options" */
export type Question_Options_On_Conflict = {
  constraint: Question_Options_Constraint;
  update_columns?: Array<Question_Options_Update_Column>;
  where?: InputMaybe<Question_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "question_options". */
export type Question_Options_Order_By = {
  correct_answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: question_options */
export type Question_Options_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "question_options" */
export enum Question_Options_Select_Column {
  /** column name */
  CorrectAnswer = 'correct_answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "question_options_aggregate_bool_exp_bool_and_arguments_columns" columns of table "question_options" */
export enum Question_Options_Select_Column_Question_Options_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CorrectAnswer = 'correct_answer',
}

/** select "question_options_aggregate_bool_exp_bool_or_arguments_columns" columns of table "question_options" */
export enum Question_Options_Select_Column_Question_Options_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CorrectAnswer = 'correct_answer',
}

/** input type for updating data in table "question_options" */
export type Question_Options_Set_Input = {
  correct_answer?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Question_Options_Stddev_Fields = {
  __typename?: 'question_options_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "question_options" */
export type Question_Options_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Question_Options_Stddev_Pop_Fields = {
  __typename?: 'question_options_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "question_options" */
export type Question_Options_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Question_Options_Stddev_Samp_Fields = {
  __typename?: 'question_options_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "question_options" */
export type Question_Options_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "question_options" */
export type Question_Options_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Question_Options_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Question_Options_Stream_Cursor_Value_Input = {
  correct_answer?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Question_Options_Sum_Fields = {
  __typename?: 'question_options_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "question_options" */
export type Question_Options_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "question_options" */
export enum Question_Options_Update_Column {
  /** column name */
  CorrectAnswer = 'correct_answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Question_Options_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Question_Options_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Options_Set_Input>;
  /** filter the rows which have to be updated */
  where: Question_Options_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Question_Options_Var_Pop_Fields = {
  __typename?: 'question_options_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "question_options" */
export type Question_Options_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Question_Options_Var_Samp_Fields = {
  __typename?: 'question_options_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "question_options" */
export type Question_Options_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Question_Options_Variance_Fields = {
  __typename?: 'question_options_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "question_options" */
export type Question_Options_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** questions */
export type Questions = {
  __typename?: 'questions';
  /** An object relationship */
  assessment: Assessment;
  assessment_id: Scalars['uuid'];
  /** An array relationship */
  assessment_records: Array<Assessment_Record>;
  /** An aggregate relationship */
  assessment_records_aggregate: Assessment_Record_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  image_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An array relationship */
  question_options: Array<Question_Options>;
  /** An aggregate relationship */
  question_options_aggregate: Question_Options_Aggregate;
  /** An object relationship */
  storage_file?: Maybe<Storage_Files>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** questions */
export type QuestionsAssessment_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** questions */
export type QuestionsAssessment_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** questions */
export type QuestionsQuestion_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Options_Order_By>>;
  where?: InputMaybe<Question_Options_Bool_Exp>;
};

/** questions */
export type QuestionsQuestion_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Options_Order_By>>;
  where?: InputMaybe<Question_Options_Bool_Exp>;
};

/** aggregated selection of "questions" */
export type Questions_Aggregate = {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

export type Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Questions_Aggregate_Bool_Exp_Count>;
};

export type Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_Fields = {
  __typename?: 'questions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questions" */
export type Questions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Max_Order_By>;
  min?: InputMaybe<Questions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questions" */
export type Questions_Arr_Rel_Insert_Input = {
  data: Array<Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export type Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Bool_Exp>>;
  _not?: InputMaybe<Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  assessment_records?: InputMaybe<Assessment_Record_Bool_Exp>;
  assessment_records_aggregate?: InputMaybe<Assessment_Record_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  question_options?: InputMaybe<Question_Options_Bool_Exp>;
  question_options_aggregate?: InputMaybe<Question_Options_Aggregate_Bool_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions" */
export enum Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'questions_pkey',
}

/** input type for inserting data into table "questions" */
export type Questions_Insert_Input = {
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  assessment_id?: InputMaybe<Scalars['uuid']>;
  assessment_records?: InputMaybe<Assessment_Record_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  question_options?: InputMaybe<Question_Options_Arr_Rel_Insert_Input>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Questions_Max_Fields = {
  __typename?: 'questions_max_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "questions" */
export type Questions_Max_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Min_Fields = {
  __typename?: 'questions_min_fields';
  assessment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "questions" */
export type Questions_Min_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions" */
export type Questions_Mutation_Response = {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions>;
};

/** input type for inserting object relation for remote table "questions" */
export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** on_conflict condition type for table "questions" */
export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns?: Array<Questions_Update_Column>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "questions". */
export type Questions_Order_By = {
  assessment?: InputMaybe<Assessment_Order_By>;
  assessment_id?: InputMaybe<Order_By>;
  assessment_records_aggregate?: InputMaybe<Assessment_Record_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  question_options_aggregate?: InputMaybe<Question_Options_Aggregate_Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions */
export type Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "questions" */
export enum Questions_Select_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "questions" */
export type Questions_Set_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "questions" */
export type Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Stream_Cursor_Value_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "questions" */
export enum Questions_Update_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Questions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Bool_Exp;
};

export type ResponseData = {
  __typename?: 'responseData';
  completedBy: Array<CompletedBy>;
  completedData?: Maybe<Array<Maybe<Completed>>>;
  count: Scalars['Int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  passingScore: Scalars['Int'];
  recomendedTraining?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  status: Scalars['String'];
  time: Scalars['Int'];
  type: Scalars['String'];
};

export type ScoreAssessmentData = {
  __typename?: 'scoreAssessmentData';
  displayName?: Maybe<Scalars['String']>;
  pendingAssessment: Scalars['Int'];
  total: Scalars['Int'];
  totalAttendedAssessment: Scalars['Int'];
  totalCount: Scalars['Int'];
  userProfile?: Maybe<Scalars['String']>;
};

export type ScoreCardOutput = {
  __typename?: 'scoreCardOutput';
  response?: Maybe<Array<Maybe<ScoreData>>>;
};

export type ScoreData = {
  __typename?: 'scoreData';
  employeeName: Scalars['String'];
  name: Scalars['String'];
  noOfTries: Scalars['Int'];
  passingScore: Scalars['Int'];
  position: Scalars['String'];
  role: Scalars['String'];
  score: Scalars['Int'];
};

export type ScoreInput = {
  userId: Scalars['uuid'];
};

export type ScoreOuput = {
  __typename?: 'scoreOuput';
  response: ScoreAssessmentData;
};

export type ScoredCardInput = {
  id: Scalars['uuid'];
};

export type Search_Chat_Message_Text_Args = {
  msg_text?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "skills_detail" */
export type Skills_Detail = {
  __typename?: 'skills_detail';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  list_Of_Skill: List_Of_Skills;
  rating?: Maybe<Scalars['Int']>;
  skills_Id: Scalars['uuid'];
};

/** aggregated selection of "skills_detail" */
export type Skills_Detail_Aggregate = {
  __typename?: 'skills_detail_aggregate';
  aggregate?: Maybe<Skills_Detail_Aggregate_Fields>;
  nodes: Array<Skills_Detail>;
};

export type Skills_Detail_Aggregate_Bool_Exp = {
  count?: InputMaybe<Skills_Detail_Aggregate_Bool_Exp_Count>;
};

export type Skills_Detail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Skills_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Skills_Detail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "skills_detail" */
export type Skills_Detail_Aggregate_Fields = {
  __typename?: 'skills_detail_aggregate_fields';
  avg?: Maybe<Skills_Detail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skills_Detail_Max_Fields>;
  min?: Maybe<Skills_Detail_Min_Fields>;
  stddev?: Maybe<Skills_Detail_Stddev_Fields>;
  stddev_pop?: Maybe<Skills_Detail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skills_Detail_Stddev_Samp_Fields>;
  sum?: Maybe<Skills_Detail_Sum_Fields>;
  var_pop?: Maybe<Skills_Detail_Var_Pop_Fields>;
  var_samp?: Maybe<Skills_Detail_Var_Samp_Fields>;
  variance?: Maybe<Skills_Detail_Variance_Fields>;
};

/** aggregate fields of "skills_detail" */
export type Skills_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Skills_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "skills_detail" */
export type Skills_Detail_Aggregate_Order_By = {
  avg?: InputMaybe<Skills_Detail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Skills_Detail_Max_Order_By>;
  min?: InputMaybe<Skills_Detail_Min_Order_By>;
  stddev?: InputMaybe<Skills_Detail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Skills_Detail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Skills_Detail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Skills_Detail_Sum_Order_By>;
  var_pop?: InputMaybe<Skills_Detail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Skills_Detail_Var_Samp_Order_By>;
  variance?: InputMaybe<Skills_Detail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "skills_detail" */
export type Skills_Detail_Arr_Rel_Insert_Input = {
  data: Array<Skills_Detail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Skills_Detail_On_Conflict>;
};

/** aggregate avg on columns */
export type Skills_Detail_Avg_Fields = {
  __typename?: 'skills_detail_avg_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "skills_detail" */
export type Skills_Detail_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "skills_detail". All fields are combined with a logical 'AND'. */
export type Skills_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Skills_Detail_Bool_Exp>>;
  _not?: InputMaybe<Skills_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Skills_Detail_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Bool_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  skills_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "skills_detail" */
export enum Skills_Detail_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillsDetailPkey = 'skills_detail_pkey',
}

/** input type for incrementing numeric columns in table "skills_detail" */
export type Skills_Detail_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "skills_detail" */
export type Skills_Detail_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Obj_Rel_Insert_Input>;
  rating?: InputMaybe<Scalars['Int']>;
  skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Skills_Detail_Max_Fields = {
  __typename?: 'skills_detail_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
  skills_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "skills_detail" */
export type Skills_Detail_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skills_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Skills_Detail_Min_Fields = {
  __typename?: 'skills_detail_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
  skills_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "skills_detail" */
export type Skills_Detail_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skills_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "skills_detail" */
export type Skills_Detail_Mutation_Response = {
  __typename?: 'skills_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skills_Detail>;
};

/** on_conflict condition type for table "skills_detail" */
export type Skills_Detail_On_Conflict = {
  constraint: Skills_Detail_Constraint;
  update_columns?: Array<Skills_Detail_Update_Column>;
  where?: InputMaybe<Skills_Detail_Bool_Exp>;
};

/** Ordering options when selecting data from "skills_detail". */
export type Skills_Detail_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Order_By>;
  rating?: InputMaybe<Order_By>;
  skills_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: skills_detail */
export type Skills_Detail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "skills_detail" */
export enum Skills_Detail_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  SkillsId = 'skills_Id',
}

/** input type for updating data in table "skills_detail" */
export type Skills_Detail_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Skills_Detail_Stddev_Fields = {
  __typename?: 'skills_detail_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "skills_detail" */
export type Skills_Detail_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Skills_Detail_Stddev_Pop_Fields = {
  __typename?: 'skills_detail_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "skills_detail" */
export type Skills_Detail_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Skills_Detail_Stddev_Samp_Fields = {
  __typename?: 'skills_detail_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "skills_detail" */
export type Skills_Detail_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "skills_detail" */
export type Skills_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skills_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skills_Detail_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Skills_Detail_Sum_Fields = {
  __typename?: 'skills_detail_sum_fields';
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "skills_detail" */
export type Skills_Detail_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "skills_detail" */
export enum Skills_Detail_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  SkillsId = 'skills_Id',
}

export type Skills_Detail_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Skills_Detail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Skills_Detail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skills_Detail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Skills_Detail_Var_Pop_Fields = {
  __typename?: 'skills_detail_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "skills_detail" */
export type Skills_Detail_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Skills_Detail_Var_Samp_Fields = {
  __typename?: 'skills_detail_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "skills_detail" */
export type Skills_Detail_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Skills_Detail_Variance_Fields = {
  __typename?: 'skills_detail_variance_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "skills_detail" */
export type Skills_Detail_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "stage_career_detail" */
export type Stage_Career_Detail = {
  __typename?: 'stage_career_detail';
  /** An array relationship */
  assessment_stage_careers: Array<Assessment_Stage_Career>;
  /** An aggregate relationship */
  assessment_stage_careers_aggregate: Assessment_Stage_Career_Aggregate;
  /** An object relationship */
  career_development_detail: Career_Development_Detail;
  career_development_detail_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  order_line?: Maybe<Scalars['Int']>;
  stage_name: Scalars['String'];
  /** An array relationship */
  training_stage_careers: Array<Training_Stage_Career>;
  /** An aggregate relationship */
  training_stage_careers_aggregate: Training_Stage_Career_Aggregate;
};

/** columns and relationships of "stage_career_detail" */
export type Stage_Career_DetailAssessment_Stage_CareersArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Stage_Career_Order_By>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

/** columns and relationships of "stage_career_detail" */
export type Stage_Career_DetailAssessment_Stage_Careers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Stage_Career_Order_By>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

/** columns and relationships of "stage_career_detail" */
export type Stage_Career_DetailTraining_Stage_CareersArgs = {
  distinct_on?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Stage_Career_Order_By>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

/** columns and relationships of "stage_career_detail" */
export type Stage_Career_DetailTraining_Stage_Careers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Stage_Career_Order_By>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

/** aggregated selection of "stage_career_detail" */
export type Stage_Career_Detail_Aggregate = {
  __typename?: 'stage_career_detail_aggregate';
  aggregate?: Maybe<Stage_Career_Detail_Aggregate_Fields>;
  nodes: Array<Stage_Career_Detail>;
};

export type Stage_Career_Detail_Aggregate_Bool_Exp = {
  count?: InputMaybe<Stage_Career_Detail_Aggregate_Bool_Exp_Count>;
};

export type Stage_Career_Detail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stage_Career_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stage_career_detail" */
export type Stage_Career_Detail_Aggregate_Fields = {
  __typename?: 'stage_career_detail_aggregate_fields';
  avg?: Maybe<Stage_Career_Detail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stage_Career_Detail_Max_Fields>;
  min?: Maybe<Stage_Career_Detail_Min_Fields>;
  stddev?: Maybe<Stage_Career_Detail_Stddev_Fields>;
  stddev_pop?: Maybe<Stage_Career_Detail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stage_Career_Detail_Stddev_Samp_Fields>;
  sum?: Maybe<Stage_Career_Detail_Sum_Fields>;
  var_pop?: Maybe<Stage_Career_Detail_Var_Pop_Fields>;
  var_samp?: Maybe<Stage_Career_Detail_Var_Samp_Fields>;
  variance?: Maybe<Stage_Career_Detail_Variance_Fields>;
};

/** aggregate fields of "stage_career_detail" */
export type Stage_Career_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stage_Career_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stage_career_detail" */
export type Stage_Career_Detail_Aggregate_Order_By = {
  avg?: InputMaybe<Stage_Career_Detail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stage_Career_Detail_Max_Order_By>;
  min?: InputMaybe<Stage_Career_Detail_Min_Order_By>;
  stddev?: InputMaybe<Stage_Career_Detail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stage_Career_Detail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stage_Career_Detail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stage_Career_Detail_Sum_Order_By>;
  var_pop?: InputMaybe<Stage_Career_Detail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stage_Career_Detail_Var_Samp_Order_By>;
  variance?: InputMaybe<Stage_Career_Detail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stage_career_detail" */
export type Stage_Career_Detail_Arr_Rel_Insert_Input = {
  data: Array<Stage_Career_Detail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stage_Career_Detail_On_Conflict>;
};

/** aggregate avg on columns */
export type Stage_Career_Detail_Avg_Fields = {
  __typename?: 'stage_career_detail_avg_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Avg_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stage_career_detail". All fields are combined with a logical 'AND'. */
export type Stage_Career_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Stage_Career_Detail_Bool_Exp>>;
  _not?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Stage_Career_Detail_Bool_Exp>>;
  assessment_stage_careers?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
  assessment_stage_careers_aggregate?: InputMaybe<Assessment_Stage_Career_Aggregate_Bool_Exp>;
  career_development_detail?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  career_development_detail_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_line?: InputMaybe<Int_Comparison_Exp>;
  stage_name?: InputMaybe<String_Comparison_Exp>;
  training_stage_careers?: InputMaybe<Training_Stage_Career_Bool_Exp>;
  training_stage_careers_aggregate?: InputMaybe<Training_Stage_Career_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stage_career_detail" */
export enum Stage_Career_Detail_Constraint {
  /** unique or primary key constraint on columns "id" */
  StageCareerDetailPkey = 'stage_career_detail_pkey',
}

/** input type for incrementing numeric columns in table "stage_career_detail" */
export type Stage_Career_Detail_Inc_Input = {
  order_line?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stage_career_detail" */
export type Stage_Career_Detail_Insert_Input = {
  assessment_stage_careers?: InputMaybe<Assessment_Stage_Career_Arr_Rel_Insert_Input>;
  career_development_detail?: InputMaybe<Career_Development_Detail_Obj_Rel_Insert_Input>;
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_name?: InputMaybe<Scalars['String']>;
  training_stage_careers?: InputMaybe<Training_Stage_Career_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stage_Career_Detail_Max_Fields = {
  __typename?: 'stage_career_detail_max_fields';
  career_development_detail_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order_line?: Maybe<Scalars['Int']>;
  stage_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Max_Order_By = {
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stage_Career_Detail_Min_Fields = {
  __typename?: 'stage_career_detail_min_fields';
  career_development_detail_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order_line?: Maybe<Scalars['Int']>;
  stage_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Min_Order_By = {
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stage_career_detail" */
export type Stage_Career_Detail_Mutation_Response = {
  __typename?: 'stage_career_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stage_Career_Detail>;
};

/** input type for inserting object relation for remote table "stage_career_detail" */
export type Stage_Career_Detail_Obj_Rel_Insert_Input = {
  data: Stage_Career_Detail_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stage_Career_Detail_On_Conflict>;
};

/** on_conflict condition type for table "stage_career_detail" */
export type Stage_Career_Detail_On_Conflict = {
  constraint: Stage_Career_Detail_Constraint;
  update_columns?: Array<Stage_Career_Detail_Update_Column>;
  where?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
};

/** Ordering options when selecting data from "stage_career_detail". */
export type Stage_Career_Detail_Order_By = {
  assessment_stage_careers_aggregate?: InputMaybe<Assessment_Stage_Career_Aggregate_Order_By>;
  career_development_detail?: InputMaybe<Career_Development_Detail_Order_By>;
  career_development_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_name?: InputMaybe<Order_By>;
  training_stage_careers_aggregate?: InputMaybe<Training_Stage_Career_Aggregate_Order_By>;
};

/** primary key columns input for table: stage_career_detail */
export type Stage_Career_Detail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "stage_career_detail" */
export enum Stage_Career_Detail_Select_Column {
  /** column name */
  CareerDevelopmentDetailId = 'career_development_detail_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderLine = 'order_line',
  /** column name */
  StageName = 'stage_name',
}

/** input type for updating data in table "stage_career_detail" */
export type Stage_Career_Detail_Set_Input = {
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stage_Career_Detail_Stddev_Fields = {
  __typename?: 'stage_career_detail_stddev_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Stddev_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stage_Career_Detail_Stddev_Pop_Fields = {
  __typename?: 'stage_career_detail_stddev_pop_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Stddev_Pop_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stage_Career_Detail_Stddev_Samp_Fields = {
  __typename?: 'stage_career_detail_stddev_samp_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Stddev_Samp_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stage_career_detail" */
export type Stage_Career_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stage_Career_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stage_Career_Detail_Stream_Cursor_Value_Input = {
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Stage_Career_Detail_Sum_Fields = {
  __typename?: 'stage_career_detail_sum_fields';
  order_line?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Sum_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** update columns of table "stage_career_detail" */
export enum Stage_Career_Detail_Update_Column {
  /** column name */
  CareerDevelopmentDetailId = 'career_development_detail_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderLine = 'order_line',
  /** column name */
  StageName = 'stage_name',
}

export type Stage_Career_Detail_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stage_Career_Detail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stage_Career_Detail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stage_Career_Detail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stage_Career_Detail_Var_Pop_Fields = {
  __typename?: 'stage_career_detail_var_pop_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Var_Pop_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stage_Career_Detail_Var_Samp_Fields = {
  __typename?: 'stage_career_detail_var_samp_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Var_Samp_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stage_Career_Detail_Variance_Fields = {
  __typename?: 'stage_career_detail_variance_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stage_career_detail" */
export type Stage_Career_Detail_Variance_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

export type StartRecordingInput = {
  call_id?: InputMaybe<Scalars['String']>;
  meeting_call_id?: InputMaybe<Scalars['String']>;
};

export type StartRecordingOutput = {
  __typename?: 'startRecordingOutput';
  call_id: Scalars['String'];
  started: Scalars['Boolean'];
};

export type StopRecordingInput = {
  call_id?: InputMaybe<Scalars['String']>;
  meeting_call_id?: InputMaybe<Scalars['String']>;
};

export type StopRecordingOutput = {
  __typename?: 'stopRecordingOutput';
  call_id?: Maybe<Scalars['String']>;
  stopped?: Maybe<Scalars['Boolean']>;
};

/** Represent a file in s3 bucket  */
export type Storage_Files = {
  __typename?: 'storage_files';
  /** An array relationship */
  career_development_details: Array<Career_Development_Detail>;
  /** An aggregate relationship */
  career_development_details_aggregate: Career_Development_Detail_Aggregate;
  /** An array relationship */
  chat_message_files: Array<Chat_Message_File>;
  /** An aggregate relationship */
  chat_message_files_aggregate: Chat_Message_File_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  enum_storage_file_type: Enum_Storage_File_Type;
  file_url: Scalars['String'];
  id: Scalars['uuid'];
  metadata: Scalars['jsonb'];
  /** An array relationship */
  organizations: Array<Organization>;
  /** An array relationship */
  organizationsByLogoFileId: Array<Organization>;
  /** An aggregate relationship */
  organizationsByLogoFileId_aggregate: Organization_Aggregate;
  /** An aggregate relationship */
  organizations_aggregate: Organization_Aggregate;
  /** An array relationship */
  personaliseds: Array<Personalised>;
  /** An aggregate relationship */
  personaliseds_aggregate: Personalised_Aggregate;
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregate relationship */
  profiles_aggregate: Profile_Aggregate;
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** An array relationship */
  task_files: Array<Task_File>;
  /** An aggregate relationship */
  task_files_aggregate: Task_File_Aggregate;
  /** An array relationship */
  trainings: Array<Training>;
  /** An aggregate relationship */
  trainings_aggregate: Training_Aggregate;
  type: Enum_Storage_File_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An array relationship */
  user_avatar_files: Array<User_Avatar_Files>;
  /** An aggregate relationship */
  user_avatar_files_aggregate: User_Avatar_Files_Aggregate;
  /** An array relationship */
  user_career_external_certifications: Array<User_Career_External_Certification>;
  /** An aggregate relationship */
  user_career_external_certifications_aggregate: User_Career_External_Certification_Aggregate;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesCareer_Development_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesCareer_Development_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesChat_Message_FilesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_File_Order_By>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesChat_Message_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_File_Order_By>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesOrganizationsByLogoFileIdArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesOrganizationsByLogoFileId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesPersonalisedsArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesPersonaliseds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesTask_FilesArgs = {
  distinct_on?: InputMaybe<Array<Task_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_File_Order_By>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesTask_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_File_Order_By>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesUser_Avatar_FilesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesUser_Avatar_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesUser_Career_External_CertificationsArgs = {
  distinct_on?: InputMaybe<
    Array<User_Career_External_Certification_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

/** Represent a file in s3 bucket  */
export type Storage_FilesUser_Career_External_Certifications_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<User_Career_External_Certification_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

/** aggregated selection of "storage_files" */
export type Storage_Files_Aggregate = {
  __typename?: 'storage_files_aggregate';
  aggregate?: Maybe<Storage_Files_Aggregate_Fields>;
  nodes: Array<Storage_Files>;
};

export type Storage_Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Storage_Files_Aggregate_Bool_Exp_Count>;
};

export type Storage_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Storage_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Storage_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "storage_files" */
export type Storage_Files_Aggregate_Fields = {
  __typename?: 'storage_files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Storage_Files_Max_Fields>;
  min?: Maybe<Storage_Files_Min_Fields>;
};

/** aggregate fields of "storage_files" */
export type Storage_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Storage_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "storage_files" */
export type Storage_Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Storage_Files_Max_Order_By>;
  min?: InputMaybe<Storage_Files_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Storage_Files_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "storage_files" */
export type Storage_Files_Arr_Rel_Insert_Input = {
  data: Array<Storage_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Storage_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "storage_files". All fields are combined with a logical 'AND'. */
export type Storage_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Storage_Files_Bool_Exp>>;
  _not?: InputMaybe<Storage_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Storage_Files_Bool_Exp>>;
  career_development_details?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  career_development_details_aggregate?: InputMaybe<Career_Development_Detail_Aggregate_Bool_Exp>;
  chat_message_files?: InputMaybe<Chat_Message_File_Bool_Exp>;
  chat_message_files_aggregate?: InputMaybe<Chat_Message_File_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  enum_storage_file_type?: InputMaybe<Enum_Storage_File_Type_Bool_Exp>;
  file_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  organizations?: InputMaybe<Organization_Bool_Exp>;
  organizationsByLogoFileId?: InputMaybe<Organization_Bool_Exp>;
  organizationsByLogoFileId_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
  organizations_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
  personaliseds?: InputMaybe<Personalised_Bool_Exp>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Bool_Exp>;
  profiles?: InputMaybe<Profile_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
  task_files?: InputMaybe<Task_File_Bool_Exp>;
  task_files_aggregate?: InputMaybe<Task_File_Aggregate_Bool_Exp>;
  trainings?: InputMaybe<Training_Bool_Exp>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Bool_Exp>;
  type?: InputMaybe<Enum_Storage_File_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_avatar_files?: InputMaybe<User_Avatar_Files_Bool_Exp>;
  user_avatar_files_aggregate?: InputMaybe<User_Avatar_Files_Aggregate_Bool_Exp>;
  user_career_external_certifications?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
  user_career_external_certifications_aggregate?: InputMaybe<User_Career_External_Certification_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "storage_files" */
export enum Storage_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  StorageFilesPkey = 'storage_files_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Storage_Files_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Storage_Files_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Storage_Files_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "storage_files" */
export type Storage_Files_Insert_Input = {
  career_development_details?: InputMaybe<Career_Development_Detail_Arr_Rel_Insert_Input>;
  chat_message_files?: InputMaybe<Chat_Message_File_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  enum_storage_file_type?: InputMaybe<Enum_Storage_File_Type_Obj_Rel_Insert_Input>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  organizations?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
  organizationsByLogoFileId?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
  personaliseds?: InputMaybe<Personalised_Arr_Rel_Insert_Input>;
  profiles?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
  task_files?: InputMaybe<Task_File_Arr_Rel_Insert_Input>;
  trainings?: InputMaybe<Training_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Enum_Storage_File_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_avatar_files?: InputMaybe<User_Avatar_Files_Arr_Rel_Insert_Input>;
  user_career_external_certifications?: InputMaybe<User_Career_External_Certification_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Storage_Files_Max_Fields = {
  __typename?: 'storage_files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "storage_files" */
export type Storage_Files_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Storage_Files_Min_Fields = {
  __typename?: 'storage_files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "storage_files" */
export type Storage_Files_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "storage_files" */
export type Storage_Files_Mutation_Response = {
  __typename?: 'storage_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Storage_Files>;
};

/** input type for inserting object relation for remote table "storage_files" */
export type Storage_Files_Obj_Rel_Insert_Input = {
  data: Storage_Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Storage_Files_On_Conflict>;
};

/** on_conflict condition type for table "storage_files" */
export type Storage_Files_On_Conflict = {
  constraint: Storage_Files_Constraint;
  update_columns?: Array<Storage_Files_Update_Column>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "storage_files". */
export type Storage_Files_Order_By = {
  career_development_details_aggregate?: InputMaybe<Career_Development_Detail_Aggregate_Order_By>;
  chat_message_files_aggregate?: InputMaybe<Chat_Message_File_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enum_storage_file_type?: InputMaybe<Enum_Storage_File_Type_Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  organizationsByLogoFileId_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
  organizations_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Order_By>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
  task_files_aggregate?: InputMaybe<Task_File_Aggregate_Order_By>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_avatar_files_aggregate?: InputMaybe<User_Avatar_Files_Aggregate_Order_By>;
  user_career_external_certifications_aggregate?: InputMaybe<User_Career_External_Certification_Aggregate_Order_By>;
};

/** primary key columns input for table: storage_files */
export type Storage_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Storage_Files_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "storage_files" */
export enum Storage_Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "storage_files" */
export type Storage_Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Enum_Storage_File_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "storage_files" */
export type Storage_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Storage_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Storage_Files_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Enum_Storage_File_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "storage_files" */
export enum Storage_Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Storage_Files_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Storage_Files_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Storage_Files_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Storage_Files_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Storage_Files_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Storage_Files_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Storage_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Storage_Files_Bool_Exp;
};

export type StressData = {
  __typename?: 'stressData';
  week1?: Maybe<Array<Maybe<WeekDataCount>>>;
  week2?: Maybe<Array<Maybe<WeekDataCount>>>;
  week3?: Maybe<Array<Maybe<WeekDataCount>>>;
  week4?: Maybe<Array<Maybe<WeekDataCount>>>;
  week5?: Maybe<Array<Maybe<WeekDataCount>>>;
};

/** columns and relationships of "stress_data" */
export type Stress_Data = {
  __typename?: 'stress_data';
  created_at: Scalars['timestamptz'];
  feedback: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  stress_date?: Maybe<Scalars['date']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "stress_data" */
export type Stress_Data_Aggregate = {
  __typename?: 'stress_data_aggregate';
  aggregate?: Maybe<Stress_Data_Aggregate_Fields>;
  nodes: Array<Stress_Data>;
};

export type Stress_Data_Aggregate_Bool_Exp = {
  count?: InputMaybe<Stress_Data_Aggregate_Bool_Exp_Count>;
};

export type Stress_Data_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stress_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stress_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stress_data" */
export type Stress_Data_Aggregate_Fields = {
  __typename?: 'stress_data_aggregate_fields';
  avg?: Maybe<Stress_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stress_Data_Max_Fields>;
  min?: Maybe<Stress_Data_Min_Fields>;
  stddev?: Maybe<Stress_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Stress_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stress_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Stress_Data_Sum_Fields>;
  var_pop?: Maybe<Stress_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Stress_Data_Var_Samp_Fields>;
  variance?: Maybe<Stress_Data_Variance_Fields>;
};

/** aggregate fields of "stress_data" */
export type Stress_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stress_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stress_data" */
export type Stress_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Stress_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stress_Data_Max_Order_By>;
  min?: InputMaybe<Stress_Data_Min_Order_By>;
  stddev?: InputMaybe<Stress_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stress_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stress_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stress_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Stress_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stress_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Stress_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stress_data" */
export type Stress_Data_Arr_Rel_Insert_Input = {
  data: Array<Stress_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stress_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Stress_Data_Avg_Fields = {
  __typename?: 'stress_data_avg_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stress_data" */
export type Stress_Data_Avg_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stress_data". All fields are combined with a logical 'AND'. */
export type Stress_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Stress_Data_Bool_Exp>>;
  _not?: InputMaybe<Stress_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Stress_Data_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feedback?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  stress_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "stress_data" */
export enum Stress_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  StressDataPkey = 'stress_data_pkey',
}

/** input type for incrementing numeric columns in table "stress_data" */
export type Stress_Data_Inc_Input = {
  feedback?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stress_data" */
export type Stress_Data_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  stress_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Stress_Data_Max_Fields = {
  __typename?: 'stress_data_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  feedback?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  stress_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "stress_data" */
export type Stress_Data_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  stress_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stress_Data_Min_Fields = {
  __typename?: 'stress_data_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  feedback?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  stress_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "stress_data" */
export type Stress_Data_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  stress_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stress_data" */
export type Stress_Data_Mutation_Response = {
  __typename?: 'stress_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stress_Data>;
};

/** on_conflict condition type for table "stress_data" */
export type Stress_Data_On_Conflict = {
  constraint: Stress_Data_Constraint;
  update_columns?: Array<Stress_Data_Update_Column>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "stress_data". */
export type Stress_Data_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  stress_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stress_data */
export type Stress_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "stress_data" */
export enum Stress_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StressDate = 'stress_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "stress_data" */
export type Stress_Data_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  stress_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Stress_Data_Stddev_Fields = {
  __typename?: 'stress_data_stddev_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stress_data" */
export type Stress_Data_Stddev_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stress_Data_Stddev_Pop_Fields = {
  __typename?: 'stress_data_stddev_pop_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stress_data" */
export type Stress_Data_Stddev_Pop_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stress_Data_Stddev_Samp_Fields = {
  __typename?: 'stress_data_stddev_samp_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stress_data" */
export type Stress_Data_Stddev_Samp_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stress_data" */
export type Stress_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stress_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stress_Data_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  stress_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Stress_Data_Sum_Fields = {
  __typename?: 'stress_data_sum_fields';
  feedback?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stress_data" */
export type Stress_Data_Sum_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** update columns of table "stress_data" */
export enum Stress_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StressDate = 'stress_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Stress_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stress_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stress_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stress_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stress_Data_Var_Pop_Fields = {
  __typename?: 'stress_data_var_pop_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stress_data" */
export type Stress_Data_Var_Pop_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stress_Data_Var_Samp_Fields = {
  __typename?: 'stress_data_var_samp_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stress_data" */
export type Stress_Data_Var_Samp_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stress_Data_Variance_Fields = {
  __typename?: 'stress_data_variance_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stress_data" */
export type Stress_Data_Variance_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** columns and relationships of "sub_skills" */
export type Sub_Skills = {
  __typename?: 'sub_skills';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  list_Of_Skill?: Maybe<List_Of_Skills>;
  /** An object relationship */
  main_skill: Main_Skills;
  main_skills_ID: Scalars['uuid'];
  rating?: Maybe<Scalars['Int']>;
  skill_Id?: Maybe<Scalars['uuid']>;
  sub_skill?: Maybe<Scalars['String']>;
  /** An array relationship */
  user_Skills: Array<User_Skills>;
  /** An aggregate relationship */
  user_Skills_aggregate: User_Skills_Aggregate;
};

/** columns and relationships of "sub_skills" */
export type Sub_SkillsUser_SkillsArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Order_By>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

/** columns and relationships of "sub_skills" */
export type Sub_SkillsUser_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Order_By>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

/** aggregated selection of "sub_skills" */
export type Sub_Skills_Aggregate = {
  __typename?: 'sub_skills_aggregate';
  aggregate?: Maybe<Sub_Skills_Aggregate_Fields>;
  nodes: Array<Sub_Skills>;
};

export type Sub_Skills_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sub_Skills_Aggregate_Bool_Exp_Count>;
};

export type Sub_Skills_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sub_Skills_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sub_skills" */
export type Sub_Skills_Aggregate_Fields = {
  __typename?: 'sub_skills_aggregate_fields';
  avg?: Maybe<Sub_Skills_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sub_Skills_Max_Fields>;
  min?: Maybe<Sub_Skills_Min_Fields>;
  stddev?: Maybe<Sub_Skills_Stddev_Fields>;
  stddev_pop?: Maybe<Sub_Skills_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sub_Skills_Stddev_Samp_Fields>;
  sum?: Maybe<Sub_Skills_Sum_Fields>;
  var_pop?: Maybe<Sub_Skills_Var_Pop_Fields>;
  var_samp?: Maybe<Sub_Skills_Var_Samp_Fields>;
  variance?: Maybe<Sub_Skills_Variance_Fields>;
};

/** aggregate fields of "sub_skills" */
export type Sub_Skills_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sub_skills" */
export type Sub_Skills_Aggregate_Order_By = {
  avg?: InputMaybe<Sub_Skills_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sub_Skills_Max_Order_By>;
  min?: InputMaybe<Sub_Skills_Min_Order_By>;
  stddev?: InputMaybe<Sub_Skills_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sub_Skills_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sub_Skills_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sub_Skills_Sum_Order_By>;
  var_pop?: InputMaybe<Sub_Skills_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sub_Skills_Var_Samp_Order_By>;
  variance?: InputMaybe<Sub_Skills_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sub_skills" */
export type Sub_Skills_Arr_Rel_Insert_Input = {
  data: Array<Sub_Skills_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sub_Skills_On_Conflict>;
};

/** aggregate avg on columns */
export type Sub_Skills_Avg_Fields = {
  __typename?: 'sub_skills_avg_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sub_skills" */
export type Sub_Skills_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sub_skills". All fields are combined with a logical 'AND'. */
export type Sub_Skills_Bool_Exp = {
  _and?: InputMaybe<Array<Sub_Skills_Bool_Exp>>;
  _not?: InputMaybe<Sub_Skills_Bool_Exp>;
  _or?: InputMaybe<Array<Sub_Skills_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Bool_Exp>;
  main_skill?: InputMaybe<Main_Skills_Bool_Exp>;
  main_skills_ID?: InputMaybe<Uuid_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  skill_Id?: InputMaybe<Uuid_Comparison_Exp>;
  sub_skill?: InputMaybe<String_Comparison_Exp>;
  user_Skills?: InputMaybe<User_Skills_Bool_Exp>;
  user_Skills_aggregate?: InputMaybe<User_Skills_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "sub_skills" */
export enum Sub_Skills_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubSkillsPkey = 'sub_skills_pkey',
}

/** input type for incrementing numeric columns in table "sub_skills" */
export type Sub_Skills_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sub_skills" */
export type Sub_Skills_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Obj_Rel_Insert_Input>;
  main_skill?: InputMaybe<Main_Skills_Obj_Rel_Insert_Input>;
  main_skills_ID?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  skill_Id?: InputMaybe<Scalars['uuid']>;
  sub_skill?: InputMaybe<Scalars['String']>;
  user_Skills?: InputMaybe<User_Skills_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Sub_Skills_Max_Fields = {
  __typename?: 'sub_skills_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  main_skills_ID?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
  skill_Id?: Maybe<Scalars['uuid']>;
  sub_skill?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sub_skills" */
export type Sub_Skills_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_skills_ID?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skill_Id?: InputMaybe<Order_By>;
  sub_skill?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sub_Skills_Min_Fields = {
  __typename?: 'sub_skills_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  main_skills_ID?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
  skill_Id?: Maybe<Scalars['uuid']>;
  sub_skill?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sub_skills" */
export type Sub_Skills_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_skills_ID?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skill_Id?: InputMaybe<Order_By>;
  sub_skill?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sub_skills" */
export type Sub_Skills_Mutation_Response = {
  __typename?: 'sub_skills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sub_Skills>;
};

/** input type for inserting object relation for remote table "sub_skills" */
export type Sub_Skills_Obj_Rel_Insert_Input = {
  data: Sub_Skills_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sub_Skills_On_Conflict>;
};

/** on_conflict condition type for table "sub_skills" */
export type Sub_Skills_On_Conflict = {
  constraint: Sub_Skills_Constraint;
  update_columns?: Array<Sub_Skills_Update_Column>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

/** Ordering options when selecting data from "sub_skills". */
export type Sub_Skills_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Order_By>;
  main_skill?: InputMaybe<Main_Skills_Order_By>;
  main_skills_ID?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skill_Id?: InputMaybe<Order_By>;
  sub_skill?: InputMaybe<Order_By>;
  user_Skills_aggregate?: InputMaybe<User_Skills_Aggregate_Order_By>;
};

/** primary key columns input for table: sub_skills */
export type Sub_Skills_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sub_skills" */
export enum Sub_Skills_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MainSkillsId = 'main_skills_ID',
  /** column name */
  Rating = 'rating',
  /** column name */
  SkillId = 'skill_Id',
  /** column name */
  SubSkill = 'sub_skill',
}

/** input type for updating data in table "sub_skills" */
export type Sub_Skills_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  main_skills_ID?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  skill_Id?: InputMaybe<Scalars['uuid']>;
  sub_skill?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Sub_Skills_Stddev_Fields = {
  __typename?: 'sub_skills_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sub_skills" */
export type Sub_Skills_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sub_Skills_Stddev_Pop_Fields = {
  __typename?: 'sub_skills_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sub_skills" */
export type Sub_Skills_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sub_Skills_Stddev_Samp_Fields = {
  __typename?: 'sub_skills_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sub_skills" */
export type Sub_Skills_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sub_skills" */
export type Sub_Skills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sub_Skills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sub_Skills_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  main_skills_ID?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  skill_Id?: InputMaybe<Scalars['uuid']>;
  sub_skill?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Sub_Skills_Sum_Fields = {
  __typename?: 'sub_skills_sum_fields';
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sub_skills" */
export type Sub_Skills_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "sub_skills" */
export enum Sub_Skills_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MainSkillsId = 'main_skills_ID',
  /** column name */
  Rating = 'rating',
  /** column name */
  SkillId = 'skill_Id',
  /** column name */
  SubSkill = 'sub_skill',
}

export type Sub_Skills_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sub_Skills_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sub_Skills_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sub_Skills_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sub_Skills_Var_Pop_Fields = {
  __typename?: 'sub_skills_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sub_skills" */
export type Sub_Skills_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sub_Skills_Var_Samp_Fields = {
  __typename?: 'sub_skills_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sub_skills" */
export type Sub_Skills_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sub_Skills_Variance_Fields = {
  __typename?: 'sub_skills_variance_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sub_skills" */
export type Sub_Skills_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "User_Peer_Review" */
  User_Peer_Review: Array<User_Peer_Review>;
  /** fetch aggregated fields from the table: "User_Peer_Review" */
  User_Peer_Review_aggregate: User_Peer_Review_Aggregate;
  /** fetch data from the table: "User_Peer_Review" using primary key columns */
  User_Peer_Review_by_pk?: Maybe<User_Peer_Review>;
  /** fetch data from the table in a streaming manner: "User_Peer_Review" */
  User_Peer_Review_stream: Array<User_Peer_Review>;
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "assessment" */
  assessment: Array<Assessment>;
  /** An array relationship */
  assessment_Skills: Array<Assessment_Skills>;
  /** An aggregate relationship */
  assessment_Skills_aggregate: Assessment_Skills_Aggregate;
  /** fetch data from the table: "assessment_Skills" using primary key columns */
  assessment_Skills_by_pk?: Maybe<Assessment_Skills>;
  /** fetch data from the table in a streaming manner: "assessment_Skills" */
  assessment_Skills_stream: Array<Assessment_Skills>;
  /** fetch aggregated fields from the table: "assessment" */
  assessment_aggregate: Assessment_Aggregate;
  /** fetch data from the table: "assessment" using primary key columns */
  assessment_by_pk?: Maybe<Assessment>;
  /** fetch data from the table: "assessment_feedback" */
  assessment_feedback: Array<Assessment_Feedback>;
  /** fetch aggregated fields from the table: "assessment_feedback" */
  assessment_feedback_aggregate: Assessment_Feedback_Aggregate;
  /** fetch data from the table: "assessment_feedback" using primary key columns */
  assessment_feedback_by_pk?: Maybe<Assessment_Feedback>;
  /** fetch data from the table in a streaming manner: "assessment_feedback" */
  assessment_feedback_stream: Array<Assessment_Feedback>;
  /** fetch data from the table: "assessment_record" */
  assessment_record: Array<Assessment_Record>;
  /** fetch aggregated fields from the table: "assessment_record" */
  assessment_record_aggregate: Assessment_Record_Aggregate;
  /** fetch data from the table: "assessment_record" using primary key columns */
  assessment_record_by_pk?: Maybe<Assessment_Record>;
  /** fetch data from the table in a streaming manner: "assessment_record" */
  assessment_record_stream: Array<Assessment_Record>;
  /** An array relationship */
  assessment_role_details: Array<Assessment_Role_Details>;
  /** An aggregate relationship */
  assessment_role_details_aggregate: Assessment_Role_Details_Aggregate;
  /** fetch data from the table: "assessment_role_details" using primary key columns */
  assessment_role_details_by_pk?: Maybe<Assessment_Role_Details>;
  /** fetch data from the table in a streaming manner: "assessment_role_details" */
  assessment_role_details_stream: Array<Assessment_Role_Details>;
  /** fetch data from the table: "assessment_stage_career" */
  assessment_stage_career: Array<Assessment_Stage_Career>;
  /** fetch aggregated fields from the table: "assessment_stage_career" */
  assessment_stage_career_aggregate: Assessment_Stage_Career_Aggregate;
  /** fetch data from the table: "assessment_stage_career" using primary key columns */
  assessment_stage_career_by_pk?: Maybe<Assessment_Stage_Career>;
  /** fetch data from the table in a streaming manner: "assessment_stage_career" */
  assessment_stage_career_stream: Array<Assessment_Stage_Career>;
  /** fetch data from the table in a streaming manner: "assessment" */
  assessment_stream: Array<Assessment>;
  /** fetch data from the table: "assessment_type" */
  assessment_type: Array<Assessment_Type>;
  /** fetch aggregated fields from the table: "assessment_type" */
  assessment_type_aggregate: Assessment_Type_Aggregate;
  /** fetch data from the table: "assessment_type" using primary key columns */
  assessment_type_by_pk?: Maybe<Assessment_Type>;
  /** fetch data from the table in a streaming manner: "assessment_type" */
  assessment_type_stream: Array<Assessment_Type>;
  /** fetch data from the table: "assign_career_development_plan" */
  assign_career_development_plan: Array<Assign_Career_Development_Plan>;
  /** fetch aggregated fields from the table: "assign_career_development_plan" */
  assign_career_development_plan_aggregate: Assign_Career_Development_Plan_Aggregate;
  /** fetch data from the table: "assign_career_development_plan" using primary key columns */
  assign_career_development_plan_by_pk?: Maybe<Assign_Career_Development_Plan>;
  /** fetch data from the table in a streaming manner: "assign_career_development_plan" */
  assign_career_development_plan_stream: Array<Assign_Career_Development_Plan>;
  /** fetch data from the table: "audit.user_state" */
  audit_user_state: Array<Audit_User_State>;
  /** fetch aggregated fields from the table: "audit.user_state" */
  audit_user_state_aggregate: Audit_User_State_Aggregate;
  /** fetch data from the table: "audit.user_state" using primary key columns */
  audit_user_state_by_pk?: Maybe<Audit_User_State>;
  /** fetch data from the table in a streaming manner: "audit.user_state" */
  audit_user_state_stream: Array<Audit_User_State>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table in a streaming manner: "auth.account_providers" */
  auth_account_providers_stream: Array<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table in a streaming manner: "auth.account_roles" */
  auth_account_roles_stream: Array<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table in a streaming manner: "auth.accounts" */
  auth_accounts_stream: Array<Auth_Accounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table in a streaming manner: "auth.providers" */
  auth_providers_stream: Array<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  auth_refresh_tokens_stream: Array<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table in a streaming manner: "auth.roles" */
  auth_roles_stream: Array<Auth_Roles>;
  /** An array relationship */
  award_posts: Array<Award_Posts>;
  /** An aggregate relationship */
  award_posts_aggregate: Award_Posts_Aggregate;
  /** fetch data from the table: "award_posts" using primary key columns */
  award_posts_by_pk?: Maybe<Award_Posts>;
  /** fetch data from the table in a streaming manner: "award_posts" */
  award_posts_stream: Array<Award_Posts>;
  /** An array relationship */
  awards: Array<Awards>;
  /** An aggregate relationship */
  awards_aggregate: Awards_Aggregate;
  /** fetch data from the table: "awards" using primary key columns */
  awards_by_pk?: Maybe<Awards>;
  /** fetch data from the table in a streaming manner: "awards" */
  awards_stream: Array<Awards>;
  /** fetch data from the table: "calendar" */
  calendar: Array<Calendar>;
  /** fetch aggregated fields from the table: "calendar" */
  calendar_aggregate: Calendar_Aggregate;
  /** fetch data from the table: "calendar_attendee" */
  calendar_attendee: Array<Calendar_Attendee>;
  /** fetch aggregated fields from the table: "calendar_attendee" */
  calendar_attendee_aggregate: Calendar_Attendee_Aggregate;
  /** fetch data from the table: "calendar_attendee" using primary key columns */
  calendar_attendee_by_pk?: Maybe<Calendar_Attendee>;
  /** fetch data from the table in a streaming manner: "calendar_attendee" */
  calendar_attendee_stream: Array<Calendar_Attendee>;
  /** fetch data from the table: "calendar" using primary key columns */
  calendar_by_pk?: Maybe<Calendar>;
  /** An array relationship */
  calendar_delete_events: Array<Calendar_Delete_Events>;
  /** An aggregate relationship */
  calendar_delete_events_aggregate: Calendar_Delete_Events_Aggregate;
  /** fetch data from the table: "calendar_delete_events" using primary key columns */
  calendar_delete_events_by_pk?: Maybe<Calendar_Delete_Events>;
  /** fetch data from the table in a streaming manner: "calendar_delete_events" */
  calendar_delete_events_stream: Array<Calendar_Delete_Events>;
  /** fetch data from the table: "calendar_event" */
  calendar_event: Array<Calendar_Event>;
  /** fetch aggregated fields from the table: "calendar_event" */
  calendar_event_aggregate: Calendar_Event_Aggregate;
  /** fetch data from the table: "calendar_event" using primary key columns */
  calendar_event_by_pk?: Maybe<Calendar_Event>;
  /** fetch data from the table: "calendar_event_material_view" */
  calendar_event_material_view: Array<Calendar_Event_Material_View>;
  /** fetch aggregated fields from the table: "calendar_event_material_view" */
  calendar_event_material_view_aggregate: Calendar_Event_Material_View_Aggregate;
  /** fetch data from the table in a streaming manner: "calendar_event_material_view" */
  calendar_event_material_view_stream: Array<Calendar_Event_Material_View>;
  /** fetch data from the table in a streaming manner: "calendar_event" */
  calendar_event_stream: Array<Calendar_Event>;
  /** fetch data from the table: "calendar_meeting_call" */
  calendar_meeting_call: Array<Calendar_Meeting_Call>;
  /** fetch aggregated fields from the table: "calendar_meeting_call" */
  calendar_meeting_call_aggregate: Calendar_Meeting_Call_Aggregate;
  /** fetch data from the table: "calendar_meeting_call" using primary key columns */
  calendar_meeting_call_by_pk?: Maybe<Calendar_Meeting_Call>;
  /** fetch data from the table: "calendar_meeting_call_request" */
  calendar_meeting_call_request: Array<Calendar_Meeting_Call_Request>;
  /** fetch aggregated fields from the table: "calendar_meeting_call_request" */
  calendar_meeting_call_request_aggregate: Calendar_Meeting_Call_Request_Aggregate;
  /** fetch data from the table: "calendar_meeting_call_request" using primary key columns */
  calendar_meeting_call_request_by_pk?: Maybe<Calendar_Meeting_Call_Request>;
  /** fetch data from the table in a streaming manner: "calendar_meeting_call_request" */
  calendar_meeting_call_request_stream: Array<Calendar_Meeting_Call_Request>;
  /** fetch data from the table in a streaming manner: "calendar_meeting_call" */
  calendar_meeting_call_stream: Array<Calendar_Meeting_Call>;
  /** fetch data from the table: "calendar_meeting_call_user" */
  calendar_meeting_call_user: Array<Calendar_Meeting_Call_User>;
  /** fetch aggregated fields from the table: "calendar_meeting_call_user" */
  calendar_meeting_call_user_aggregate: Calendar_Meeting_Call_User_Aggregate;
  /** fetch data from the table: "calendar_meeting_call_user" using primary key columns */
  calendar_meeting_call_user_by_pk?: Maybe<Calendar_Meeting_Call_User>;
  /** fetch data from the table in a streaming manner: "calendar_meeting_call_user" */
  calendar_meeting_call_user_stream: Array<Calendar_Meeting_Call_User>;
  /** fetch data from the table: "calendar_meeting_view" */
  calendar_meeting_view: Array<Calendar_Meeting_View>;
  /** fetch aggregated fields from the table: "calendar_meeting_view" */
  calendar_meeting_view_aggregate: Calendar_Meeting_View_Aggregate;
  /** fetch data from the table in a streaming manner: "calendar_meeting_view" */
  calendar_meeting_view_stream: Array<Calendar_Meeting_View>;
  /** fetch data from the table in a streaming manner: "calendar" */
  calendar_stream: Array<Calendar>;
  /** fetch data from the table: "call" */
  call: Array<Call>;
  /** fetch aggregated fields from the table: "call" */
  call_aggregate: Call_Aggregate;
  /** fetch data from the table: "call" using primary key columns */
  call_by_pk?: Maybe<Call>;
  /** fetch data from the table: "call_request" */
  call_request: Array<Call_Request>;
  /** fetch aggregated fields from the table: "call_request" */
  call_request_aggregate: Call_Request_Aggregate;
  /** fetch data from the table: "call_request" using primary key columns */
  call_request_by_pk?: Maybe<Call_Request>;
  /** fetch data from the table in a streaming manner: "call_request" */
  call_request_stream: Array<Call_Request>;
  /** fetch data from the table in a streaming manner: "call" */
  call_stream: Array<Call>;
  /** fetch data from the table: "call_user" */
  call_user: Array<Call_User>;
  /** fetch aggregated fields from the table: "call_user" */
  call_user_aggregate: Call_User_Aggregate;
  /** fetch data from the table: "call_user" using primary key columns */
  call_user_by_pk?: Maybe<Call_User>;
  /** fetch data from the table in a streaming manner: "call_user" */
  call_user_stream: Array<Call_User>;
  /** fetch data from the table: "career_development_detail" */
  career_development_detail: Array<Career_Development_Detail>;
  /** fetch aggregated fields from the table: "career_development_detail" */
  career_development_detail_aggregate: Career_Development_Detail_Aggregate;
  /** fetch data from the table: "career_development_detail" using primary key columns */
  career_development_detail_by_pk?: Maybe<Career_Development_Detail>;
  /** fetch data from the table in a streaming manner: "career_development_detail" */
  career_development_detail_stream: Array<Career_Development_Detail>;
  /** An array relationship */
  career_development_role_details: Array<Career_Development_Role_Details>;
  /** An aggregate relationship */
  career_development_role_details_aggregate: Career_Development_Role_Details_Aggregate;
  /** fetch data from the table: "career_development_role_details" using primary key columns */
  career_development_role_details_by_pk?: Maybe<Career_Development_Role_Details>;
  /** fetch data from the table in a streaming manner: "career_development_role_details" */
  career_development_role_details_stream: Array<Career_Development_Role_Details>;
  /** fetch data from the table: "career_external_certification" */
  career_external_certification: Array<Career_External_Certification>;
  /** fetch aggregated fields from the table: "career_external_certification" */
  career_external_certification_aggregate: Career_External_Certification_Aggregate;
  /** fetch data from the table: "career_external_certification" using primary key columns */
  career_external_certification_by_pk?: Maybe<Career_External_Certification>;
  /** fetch data from the table in a streaming manner: "career_external_certification" */
  career_external_certification_stream: Array<Career_External_Certification>;
  /** fetch data from the table: "chat_message" */
  chat_message: Array<Chat_Message>;
  /** fetch data from the table: "chat_message_action" */
  chat_message_action: Array<Chat_Message_Action>;
  /** fetch aggregated fields from the table: "chat_message_action" */
  chat_message_action_aggregate: Chat_Message_Action_Aggregate;
  /** fetch data from the table: "chat_message_action" using primary key columns */
  chat_message_action_by_pk?: Maybe<Chat_Message_Action>;
  /** fetch data from the table in a streaming manner: "chat_message_action" */
  chat_message_action_stream: Array<Chat_Message_Action>;
  /** fetch aggregated fields from the table: "chat_message" */
  chat_message_aggregate: Chat_Message_Aggregate;
  /** fetch data from the table: "chat_message" using primary key columns */
  chat_message_by_pk?: Maybe<Chat_Message>;
  /** fetch data from the table: "chat_message_file" */
  chat_message_file: Array<Chat_Message_File>;
  /** fetch aggregated fields from the table: "chat_message_file" */
  chat_message_file_aggregate: Chat_Message_File_Aggregate;
  /** fetch data from the table: "chat_message_file" using primary key columns */
  chat_message_file_by_pk?: Maybe<Chat_Message_File>;
  /** fetch data from the table in a streaming manner: "chat_message_file" */
  chat_message_file_stream: Array<Chat_Message_File>;
  /** An array relationship */
  chat_message_reactions: Array<Chat_Message_Reactions>;
  /** An aggregate relationship */
  chat_message_reactions_aggregate: Chat_Message_Reactions_Aggregate;
  /** fetch data from the table: "chat_message_reactions" using primary key columns */
  chat_message_reactions_by_pk?: Maybe<Chat_Message_Reactions>;
  /** fetch data from the table in a streaming manner: "chat_message_reactions" */
  chat_message_reactions_stream: Array<Chat_Message_Reactions>;
  /** fetch data from the table in a streaming manner: "chat_message" */
  chat_message_stream: Array<Chat_Message>;
  /** fetch data from the table: "chat_pinned" */
  chat_pinned: Array<Chat_Pinned>;
  /** fetch aggregated fields from the table: "chat_pinned" */
  chat_pinned_aggregate: Chat_Pinned_Aggregate;
  /** fetch data from the table: "chat_pinned" using primary key columns */
  chat_pinned_by_pk?: Maybe<Chat_Pinned>;
  /** fetch data from the table in a streaming manner: "chat_pinned" */
  chat_pinned_stream: Array<Chat_Pinned>;
  /** fetch data from the table: "chat_room" */
  chat_room: Array<Chat_Room>;
  /** fetch aggregated fields from the table: "chat_room" */
  chat_room_aggregate: Chat_Room_Aggregate;
  /** fetch data from the table: "chat_room" using primary key columns */
  chat_room_by_pk?: Maybe<Chat_Room>;
  /** fetch data from the table in a streaming manner: "chat_room" */
  chat_room_stream: Array<Chat_Room>;
  /** fetch data from the table: "chat_room_user" */
  chat_room_user: Array<Chat_Room_User>;
  /** fetch aggregated fields from the table: "chat_room_user" */
  chat_room_user_aggregate: Chat_Room_User_Aggregate;
  /** fetch data from the table: "chat_room_user" using primary key columns */
  chat_room_user_by_pk?: Maybe<Chat_Room_User>;
  /** fetch data from the table in a streaming manner: "chat_room_user" */
  chat_room_user_stream: Array<Chat_Room_User>;
  /** fetch data from the table: "dashboard_billable" */
  dashboard_billable: Array<Dashboard_Billable>;
  /** fetch aggregated fields from the table: "dashboard_billable" */
  dashboard_billable_aggregate: Dashboard_Billable_Aggregate;
  /** fetch data from the table: "dashboard_billable_employee" */
  dashboard_billable_employee: Array<Dashboard_Billable_Employee>;
  /** fetch aggregated fields from the table: "dashboard_billable_employee" */
  dashboard_billable_employee_aggregate: Dashboard_Billable_Employee_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_billable_employee" */
  dashboard_billable_employee_stream: Array<Dashboard_Billable_Employee>;
  /** fetch data from the table: "dashboard_billable_manager" */
  dashboard_billable_manager: Array<Dashboard_Billable_Manager>;
  /** fetch aggregated fields from the table: "dashboard_billable_manager" */
  dashboard_billable_manager_aggregate: Dashboard_Billable_Manager_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_billable_manager" */
  dashboard_billable_manager_stream: Array<Dashboard_Billable_Manager>;
  /** fetch data from the table in a streaming manner: "dashboard_billable" */
  dashboard_billable_stream: Array<Dashboard_Billable>;
  /** fetch data from the table: "dashboard_meeting" */
  dashboard_meeting: Array<Dashboard_Meeting>;
  /** fetch aggregated fields from the table: "dashboard_meeting" */
  dashboard_meeting_aggregate: Dashboard_Meeting_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_meeting" */
  dashboard_meeting_stream: Array<Dashboard_Meeting>;
  /** fetch data from the table: "dashboard_remainders" */
  dashboard_remainders: Array<Dashboard_Remainders>;
  /** fetch aggregated fields from the table: "dashboard_remainders" */
  dashboard_remainders_aggregate: Dashboard_Remainders_Aggregate;
  /** fetch data from the table: "dashboard_remainders" using primary key columns */
  dashboard_remainders_by_pk?: Maybe<Dashboard_Remainders>;
  /** fetch data from the table in a streaming manner: "dashboard_remainders" */
  dashboard_remainders_stream: Array<Dashboard_Remainders>;
  /** fetch data from the table: "department" */
  department: Array<Department>;
  /** fetch aggregated fields from the table: "department" */
  department_aggregate: Department_Aggregate;
  /** fetch data from the table: "department" using primary key columns */
  department_by_pk?: Maybe<Department>;
  /** fetch data from the table: "department_employee" */
  department_employee: Array<Department_Employee>;
  /** fetch aggregated fields from the table: "department_employee" */
  department_employee_aggregate: Department_Employee_Aggregate;
  /** fetch data from the table: "department_employee" using primary key columns */
  department_employee_by_pk?: Maybe<Department_Employee>;
  /** fetch data from the table in a streaming manner: "department_employee" */
  department_employee_stream: Array<Department_Employee>;
  /** fetch data from the table in a streaming manner: "department" */
  department_stream: Array<Department>;
  /** fetch data from the table: "document_token" */
  document_token: Array<Document_Token>;
  /** fetch aggregated fields from the table: "document_token" */
  document_token_aggregate: Document_Token_Aggregate;
  /** fetch data from the table: "document_token" using primary key columns */
  document_token_by_pk?: Maybe<Document_Token>;
  /** fetch data from the table in a streaming manner: "document_token" */
  document_token_stream: Array<Document_Token>;
  /** fetch data from the table: "email_templates" */
  email_templates: Array<Email_Templates>;
  /** fetch aggregated fields from the table: "email_templates" */
  email_templates_aggregate: Email_Templates_Aggregate;
  /** fetch data from the table: "email_templates" using primary key columns */
  email_templates_by_pk?: Maybe<Email_Templates>;
  /** fetch data from the table in a streaming manner: "email_templates" */
  email_templates_stream: Array<Email_Templates>;
  /** An array relationship */
  employee_bandwidth_data: Array<Employee_Bandwidth_Data>;
  /** An aggregate relationship */
  employee_bandwidth_data_aggregate: Employee_Bandwidth_Data_Aggregate;
  /** fetch data from the table: "employee_bandwidth_data" using primary key columns */
  employee_bandwidth_data_by_pk?: Maybe<Employee_Bandwidth_Data>;
  /** fetch data from the table in a streaming manner: "employee_bandwidth_data" */
  employee_bandwidth_data_stream: Array<Employee_Bandwidth_Data>;
  /** fetch data from the table: "employee_capacity" */
  employee_capacity: Array<Employee_Capacity>;
  /** fetch aggregated fields from the table: "employee_capacity" */
  employee_capacity_aggregate: Employee_Capacity_Aggregate;
  /** fetch data from the table: "employee_capacity" using primary key columns */
  employee_capacity_by_pk?: Maybe<Employee_Capacity>;
  /** fetch data from the table in a streaming manner: "employee_capacity" */
  employee_capacity_stream: Array<Employee_Capacity>;
  /** fetch data from the table: "employee_capacity_view" */
  employee_capacity_view: Array<Employee_Capacity_View>;
  /** fetch aggregated fields from the table: "employee_capacity_view" */
  employee_capacity_view_aggregate: Employee_Capacity_View_Aggregate;
  /** fetch data from the table in a streaming manner: "employee_capacity_view" */
  employee_capacity_view_stream: Array<Employee_Capacity_View>;
  /** fetch data from the table: "enum_activity_state" */
  enum_activity_state: Array<Enum_Activity_State>;
  /** fetch aggregated fields from the table: "enum_activity_state" */
  enum_activity_state_aggregate: Enum_Activity_State_Aggregate;
  /** fetch data from the table: "enum_activity_state" using primary key columns */
  enum_activity_state_by_pk?: Maybe<Enum_Activity_State>;
  /** fetch data from the table in a streaming manner: "enum_activity_state" */
  enum_activity_state_stream: Array<Enum_Activity_State>;
  /** fetch data from the table: "enum_activity_type" */
  enum_activity_type: Array<Enum_Activity_Type>;
  /** fetch aggregated fields from the table: "enum_activity_type" */
  enum_activity_type_aggregate: Enum_Activity_Type_Aggregate;
  /** fetch data from the table: "enum_activity_type" using primary key columns */
  enum_activity_type_by_pk?: Maybe<Enum_Activity_Type>;
  /** fetch data from the table in a streaming manner: "enum_activity_type" */
  enum_activity_type_stream: Array<Enum_Activity_Type>;
  /** fetch data from the table: "enum_attendee_status_type" */
  enum_attendee_status_type: Array<Enum_Attendee_Status_Type>;
  /** fetch aggregated fields from the table: "enum_attendee_status_type" */
  enum_attendee_status_type_aggregate: Enum_Attendee_Status_Type_Aggregate;
  /** fetch data from the table: "enum_attendee_status_type" using primary key columns */
  enum_attendee_status_type_by_pk?: Maybe<Enum_Attendee_Status_Type>;
  /** fetch data from the table in a streaming manner: "enum_attendee_status_type" */
  enum_attendee_status_type_stream: Array<Enum_Attendee_Status_Type>;
  /** fetch data from the table: "enum_attendee_type" */
  enum_attendee_type: Array<Enum_Attendee_Type>;
  /** fetch aggregated fields from the table: "enum_attendee_type" */
  enum_attendee_type_aggregate: Enum_Attendee_Type_Aggregate;
  /** fetch data from the table: "enum_attendee_type" using primary key columns */
  enum_attendee_type_by_pk?: Maybe<Enum_Attendee_Type>;
  /** fetch data from the table in a streaming manner: "enum_attendee_type" */
  enum_attendee_type_stream: Array<Enum_Attendee_Type>;
  /** fetch data from the table: "enum_award_cycle_type" */
  enum_award_cycle_type: Array<Enum_Award_Cycle_Type>;
  /** fetch aggregated fields from the table: "enum_award_cycle_type" */
  enum_award_cycle_type_aggregate: Enum_Award_Cycle_Type_Aggregate;
  /** fetch data from the table: "enum_award_cycle_type" using primary key columns */
  enum_award_cycle_type_by_pk?: Maybe<Enum_Award_Cycle_Type>;
  /** fetch data from the table in a streaming manner: "enum_award_cycle_type" */
  enum_award_cycle_type_stream: Array<Enum_Award_Cycle_Type>;
  /** fetch data from the table: "enum_call_access_policy" */
  enum_call_access_policy: Array<Enum_Call_Access_Policy>;
  /** fetch aggregated fields from the table: "enum_call_access_policy" */
  enum_call_access_policy_aggregate: Enum_Call_Access_Policy_Aggregate;
  /** fetch data from the table: "enum_call_access_policy" using primary key columns */
  enum_call_access_policy_by_pk?: Maybe<Enum_Call_Access_Policy>;
  /** fetch data from the table in a streaming manner: "enum_call_access_policy" */
  enum_call_access_policy_stream: Array<Enum_Call_Access_Policy>;
  /** fetch data from the table: "enum_call_broadcasting_type" */
  enum_call_broadcasting_type: Array<Enum_Call_Broadcasting_Type>;
  /** fetch aggregated fields from the table: "enum_call_broadcasting_type" */
  enum_call_broadcasting_type_aggregate: Enum_Call_Broadcasting_Type_Aggregate;
  /** fetch data from the table: "enum_call_broadcasting_type" using primary key columns */
  enum_call_broadcasting_type_by_pk?: Maybe<Enum_Call_Broadcasting_Type>;
  /** fetch data from the table in a streaming manner: "enum_call_broadcasting_type" */
  enum_call_broadcasting_type_stream: Array<Enum_Call_Broadcasting_Type>;
  /** fetch data from the table: "enum_call_media_type" */
  enum_call_media_type: Array<Enum_Call_Media_Type>;
  /** fetch aggregated fields from the table: "enum_call_media_type" */
  enum_call_media_type_aggregate: Enum_Call_Media_Type_Aggregate;
  /** fetch data from the table: "enum_call_media_type" using primary key columns */
  enum_call_media_type_by_pk?: Maybe<Enum_Call_Media_Type>;
  /** fetch data from the table in a streaming manner: "enum_call_media_type" */
  enum_call_media_type_stream: Array<Enum_Call_Media_Type>;
  /** fetch data from the table: "enum_call_recording_status" */
  enum_call_recording_status: Array<Enum_Call_Recording_Status>;
  /** fetch aggregated fields from the table: "enum_call_recording_status" */
  enum_call_recording_status_aggregate: Enum_Call_Recording_Status_Aggregate;
  /** fetch data from the table: "enum_call_recording_status" using primary key columns */
  enum_call_recording_status_by_pk?: Maybe<Enum_Call_Recording_Status>;
  /** fetch data from the table in a streaming manner: "enum_call_recording_status" */
  enum_call_recording_status_stream: Array<Enum_Call_Recording_Status>;
  /** fetch data from the table: "enum_call_request_response_type" */
  enum_call_request_response_type: Array<Enum_Call_Request_Response_Type>;
  /** fetch aggregated fields from the table: "enum_call_request_response_type" */
  enum_call_request_response_type_aggregate: Enum_Call_Request_Response_Type_Aggregate;
  /** fetch data from the table: "enum_call_request_response_type" using primary key columns */
  enum_call_request_response_type_by_pk?: Maybe<Enum_Call_Request_Response_Type>;
  /** fetch data from the table in a streaming manner: "enum_call_request_response_type" */
  enum_call_request_response_type_stream: Array<Enum_Call_Request_Response_Type>;
  /** fetch data from the table: "enum_call_request_status_type" */
  enum_call_request_status_type: Array<Enum_Call_Request_Status_Type>;
  /** fetch aggregated fields from the table: "enum_call_request_status_type" */
  enum_call_request_status_type_aggregate: Enum_Call_Request_Status_Type_Aggregate;
  /** fetch data from the table: "enum_call_request_status_type" using primary key columns */
  enum_call_request_status_type_by_pk?: Maybe<Enum_Call_Request_Status_Type>;
  /** fetch data from the table in a streaming manner: "enum_call_request_status_type" */
  enum_call_request_status_type_stream: Array<Enum_Call_Request_Status_Type>;
  /** fetch data from the table: "enum_call_request_type" */
  enum_call_request_type: Array<Enum_Call_Request_Type>;
  /** fetch aggregated fields from the table: "enum_call_request_type" */
  enum_call_request_type_aggregate: Enum_Call_Request_Type_Aggregate;
  /** fetch data from the table: "enum_call_request_type" using primary key columns */
  enum_call_request_type_by_pk?: Maybe<Enum_Call_Request_Type>;
  /** fetch data from the table in a streaming manner: "enum_call_request_type" */
  enum_call_request_type_stream: Array<Enum_Call_Request_Type>;
  /** fetch data from the table: "enum_call_status_type" */
  enum_call_status_type: Array<Enum_Call_Status_Type>;
  /** fetch aggregated fields from the table: "enum_call_status_type" */
  enum_call_status_type_aggregate: Enum_Call_Status_Type_Aggregate;
  /** fetch data from the table: "enum_call_status_type" using primary key columns */
  enum_call_status_type_by_pk?: Maybe<Enum_Call_Status_Type>;
  /** fetch data from the table in a streaming manner: "enum_call_status_type" */
  enum_call_status_type_stream: Array<Enum_Call_Status_Type>;
  /** fetch data from the table: "enum_call_type" */
  enum_call_type: Array<Enum_Call_Type>;
  /** fetch aggregated fields from the table: "enum_call_type" */
  enum_call_type_aggregate: Enum_Call_Type_Aggregate;
  /** fetch data from the table: "enum_call_type" using primary key columns */
  enum_call_type_by_pk?: Maybe<Enum_Call_Type>;
  /** fetch data from the table in a streaming manner: "enum_call_type" */
  enum_call_type_stream: Array<Enum_Call_Type>;
  /** fetch data from the table: "enum_call_user_status" */
  enum_call_user_status: Array<Enum_Call_User_Status>;
  /** fetch aggregated fields from the table: "enum_call_user_status" */
  enum_call_user_status_aggregate: Enum_Call_User_Status_Aggregate;
  /** fetch data from the table: "enum_call_user_status" using primary key columns */
  enum_call_user_status_by_pk?: Maybe<Enum_Call_User_Status>;
  /** fetch data from the table in a streaming manner: "enum_call_user_status" */
  enum_call_user_status_stream: Array<Enum_Call_User_Status>;
  /** fetch data from the table: "enum_chat_message_type" */
  enum_chat_message_type: Array<Enum_Chat_Message_Type>;
  /** fetch aggregated fields from the table: "enum_chat_message_type" */
  enum_chat_message_type_aggregate: Enum_Chat_Message_Type_Aggregate;
  /** fetch data from the table: "enum_chat_message_type" using primary key columns */
  enum_chat_message_type_by_pk?: Maybe<Enum_Chat_Message_Type>;
  /** fetch data from the table in a streaming manner: "enum_chat_message_type" */
  enum_chat_message_type_stream: Array<Enum_Chat_Message_Type>;
  /** fetch data from the table: "enum_chat_notification" */
  enum_chat_notification: Array<Enum_Chat_Notification>;
  /** fetch aggregated fields from the table: "enum_chat_notification" */
  enum_chat_notification_aggregate: Enum_Chat_Notification_Aggregate;
  /** fetch data from the table: "enum_chat_notification" using primary key columns */
  enum_chat_notification_by_pk?: Maybe<Enum_Chat_Notification>;
  /** fetch data from the table in a streaming manner: "enum_chat_notification" */
  enum_chat_notification_stream: Array<Enum_Chat_Notification>;
  /** fetch data from the table: "enum_chat_room_notification_status" */
  enum_chat_room_notification_status: Array<Enum_Chat_Room_Notification_Status>;
  /** fetch aggregated fields from the table: "enum_chat_room_notification_status" */
  enum_chat_room_notification_status_aggregate: Enum_Chat_Room_Notification_Status_Aggregate;
  /** fetch data from the table: "enum_chat_room_notification_status" using primary key columns */
  enum_chat_room_notification_status_by_pk?: Maybe<Enum_Chat_Room_Notification_Status>;
  /** fetch data from the table in a streaming manner: "enum_chat_room_notification_status" */
  enum_chat_room_notification_status_stream: Array<Enum_Chat_Room_Notification_Status>;
  /** fetch data from the table: "enum_chat_room_type" */
  enum_chat_room_type: Array<Enum_Chat_Room_Type>;
  /** fetch aggregated fields from the table: "enum_chat_room_type" */
  enum_chat_room_type_aggregate: Enum_Chat_Room_Type_Aggregate;
  /** fetch data from the table: "enum_chat_room_type" using primary key columns */
  enum_chat_room_type_by_pk?: Maybe<Enum_Chat_Room_Type>;
  /** fetch data from the table in a streaming manner: "enum_chat_room_type" */
  enum_chat_room_type_stream: Array<Enum_Chat_Room_Type>;
  /** fetch data from the table: "enum_chat_room_user_status" */
  enum_chat_room_user_status: Array<Enum_Chat_Room_User_Status>;
  /** fetch aggregated fields from the table: "enum_chat_room_user_status" */
  enum_chat_room_user_status_aggregate: Enum_Chat_Room_User_Status_Aggregate;
  /** fetch data from the table: "enum_chat_room_user_status" using primary key columns */
  enum_chat_room_user_status_by_pk?: Maybe<Enum_Chat_Room_User_Status>;
  /** fetch data from the table in a streaming manner: "enum_chat_room_user_status" */
  enum_chat_room_user_status_stream: Array<Enum_Chat_Room_User_Status>;
  /** fetch data from the table: "enum_dashboard_remainder_type" */
  enum_dashboard_remainder_type: Array<Enum_Dashboard_Remainder_Type>;
  /** fetch aggregated fields from the table: "enum_dashboard_remainder_type" */
  enum_dashboard_remainder_type_aggregate: Enum_Dashboard_Remainder_Type_Aggregate;
  /** fetch data from the table: "enum_dashboard_remainder_type" using primary key columns */
  enum_dashboard_remainder_type_by_pk?: Maybe<Enum_Dashboard_Remainder_Type>;
  /** fetch data from the table in a streaming manner: "enum_dashboard_remainder_type" */
  enum_dashboard_remainder_type_stream: Array<Enum_Dashboard_Remainder_Type>;
  /** fetch data from the table: "enum_document_token_type" */
  enum_document_token_type: Array<Enum_Document_Token_Type>;
  /** fetch aggregated fields from the table: "enum_document_token_type" */
  enum_document_token_type_aggregate: Enum_Document_Token_Type_Aggregate;
  /** fetch data from the table: "enum_document_token_type" using primary key columns */
  enum_document_token_type_by_pk?: Maybe<Enum_Document_Token_Type>;
  /** fetch data from the table in a streaming manner: "enum_document_token_type" */
  enum_document_token_type_stream: Array<Enum_Document_Token_Type>;
  /** fetch data from the table: "enum_event_type" */
  enum_event_type: Array<Enum_Event_Type>;
  /** fetch aggregated fields from the table: "enum_event_type" */
  enum_event_type_aggregate: Enum_Event_Type_Aggregate;
  /** fetch data from the table: "enum_event_type" using primary key columns */
  enum_event_type_by_pk?: Maybe<Enum_Event_Type>;
  /** fetch data from the table in a streaming manner: "enum_event_type" */
  enum_event_type_stream: Array<Enum_Event_Type>;
  /** fetch data from the table: "enum_leave_donate_type" */
  enum_leave_donate_type: Array<Enum_Leave_Donate_Type>;
  /** fetch aggregated fields from the table: "enum_leave_donate_type" */
  enum_leave_donate_type_aggregate: Enum_Leave_Donate_Type_Aggregate;
  /** fetch data from the table: "enum_leave_donate_type" using primary key columns */
  enum_leave_donate_type_by_pk?: Maybe<Enum_Leave_Donate_Type>;
  /** fetch data from the table in a streaming manner: "enum_leave_donate_type" */
  enum_leave_donate_type_stream: Array<Enum_Leave_Donate_Type>;
  /** fetch data from the table: "enum_leave_status_type" */
  enum_leave_status_type: Array<Enum_Leave_Status_Type>;
  /** fetch aggregated fields from the table: "enum_leave_status_type" */
  enum_leave_status_type_aggregate: Enum_Leave_Status_Type_Aggregate;
  /** fetch data from the table: "enum_leave_status_type" using primary key columns */
  enum_leave_status_type_by_pk?: Maybe<Enum_Leave_Status_Type>;
  /** fetch data from the table in a streaming manner: "enum_leave_status_type" */
  enum_leave_status_type_stream: Array<Enum_Leave_Status_Type>;
  /** fetch data from the table: "enum_live_call_request_status" */
  enum_live_call_request_status: Array<Enum_Live_Call_Request_Status>;
  /** fetch aggregated fields from the table: "enum_live_call_request_status" */
  enum_live_call_request_status_aggregate: Enum_Live_Call_Request_Status_Aggregate;
  /** fetch data from the table: "enum_live_call_request_status" using primary key columns */
  enum_live_call_request_status_by_pk?: Maybe<Enum_Live_Call_Request_Status>;
  /** fetch data from the table in a streaming manner: "enum_live_call_request_status" */
  enum_live_call_request_status_stream: Array<Enum_Live_Call_Request_Status>;
  /** fetch data from the table: "enum_live_call_status" */
  enum_live_call_status: Array<Enum_Live_Call_Status>;
  /** fetch aggregated fields from the table: "enum_live_call_status" */
  enum_live_call_status_aggregate: Enum_Live_Call_Status_Aggregate;
  /** fetch data from the table: "enum_live_call_status" using primary key columns */
  enum_live_call_status_by_pk?: Maybe<Enum_Live_Call_Status>;
  /** fetch data from the table in a streaming manner: "enum_live_call_status" */
  enum_live_call_status_stream: Array<Enum_Live_Call_Status>;
  /** fetch data from the table: "enum_live_call_user_status" */
  enum_live_call_user_status: Array<Enum_Live_Call_User_Status>;
  /** fetch aggregated fields from the table: "enum_live_call_user_status" */
  enum_live_call_user_status_aggregate: Enum_Live_Call_User_Status_Aggregate;
  /** fetch data from the table: "enum_live_call_user_status" using primary key columns */
  enum_live_call_user_status_by_pk?: Maybe<Enum_Live_Call_User_Status>;
  /** fetch data from the table in a streaming manner: "enum_live_call_user_status" */
  enum_live_call_user_status_stream: Array<Enum_Live_Call_User_Status>;
  /** fetch data from the table: "enum_live_emote_type" */
  enum_live_emote_type: Array<Enum_Live_Emote_Type>;
  /** fetch aggregated fields from the table: "enum_live_emote_type" */
  enum_live_emote_type_aggregate: Enum_Live_Emote_Type_Aggregate;
  /** fetch data from the table: "enum_live_emote_type" using primary key columns */
  enum_live_emote_type_by_pk?: Maybe<Enum_Live_Emote_Type>;
  /** fetch data from the table in a streaming manner: "enum_live_emote_type" */
  enum_live_emote_type_stream: Array<Enum_Live_Emote_Type>;
  /** fetch data from the table: "enum_manual_state" */
  enum_manual_state: Array<Enum_Manual_State>;
  /** fetch aggregated fields from the table: "enum_manual_state" */
  enum_manual_state_aggregate: Enum_Manual_State_Aggregate;
  /** fetch data from the table: "enum_manual_state" using primary key columns */
  enum_manual_state_by_pk?: Maybe<Enum_Manual_State>;
  /** fetch data from the table in a streaming manner: "enum_manual_state" */
  enum_manual_state_stream: Array<Enum_Manual_State>;
  /** fetch data from the table: "enum_meeting_room_status" */
  enum_meeting_room_status: Array<Enum_Meeting_Room_Status>;
  /** fetch aggregated fields from the table: "enum_meeting_room_status" */
  enum_meeting_room_status_aggregate: Enum_Meeting_Room_Status_Aggregate;
  /** fetch data from the table: "enum_meeting_room_status" using primary key columns */
  enum_meeting_room_status_by_pk?: Maybe<Enum_Meeting_Room_Status>;
  /** fetch data from the table in a streaming manner: "enum_meeting_room_status" */
  enum_meeting_room_status_stream: Array<Enum_Meeting_Room_Status>;
  /** fetch data from the table: "enum_meeting_room_type" */
  enum_meeting_room_type: Array<Enum_Meeting_Room_Type>;
  /** fetch aggregated fields from the table: "enum_meeting_room_type" */
  enum_meeting_room_type_aggregate: Enum_Meeting_Room_Type_Aggregate;
  /** fetch data from the table: "enum_meeting_room_type" using primary key columns */
  enum_meeting_room_type_by_pk?: Maybe<Enum_Meeting_Room_Type>;
  /** fetch data from the table in a streaming manner: "enum_meeting_room_type" */
  enum_meeting_room_type_stream: Array<Enum_Meeting_Room_Type>;
  /** fetch data from the table: "enum_meeting_room_user_state" */
  enum_meeting_room_user_state: Array<Enum_Meeting_Room_User_State>;
  /** fetch aggregated fields from the table: "enum_meeting_room_user_state" */
  enum_meeting_room_user_state_aggregate: Enum_Meeting_Room_User_State_Aggregate;
  /** fetch data from the table: "enum_meeting_room_user_state" using primary key columns */
  enum_meeting_room_user_state_by_pk?: Maybe<Enum_Meeting_Room_User_State>;
  /** fetch data from the table in a streaming manner: "enum_meeting_room_user_state" */
  enum_meeting_room_user_state_stream: Array<Enum_Meeting_Room_User_State>;
  /** fetch data from the table: "enum_notification_type" */
  enum_notification_type: Array<Enum_Notification_Type>;
  /** fetch aggregated fields from the table: "enum_notification_type" */
  enum_notification_type_aggregate: Enum_Notification_Type_Aggregate;
  /** fetch data from the table: "enum_notification_type" using primary key columns */
  enum_notification_type_by_pk?: Maybe<Enum_Notification_Type>;
  /** fetch data from the table in a streaming manner: "enum_notification_type" */
  enum_notification_type_stream: Array<Enum_Notification_Type>;
  /** fetch data from the table: "enum_personalised_access" */
  enum_personalised_access: Array<Enum_Personalised_Access>;
  /** fetch aggregated fields from the table: "enum_personalised_access" */
  enum_personalised_access_aggregate: Enum_Personalised_Access_Aggregate;
  /** fetch data from the table: "enum_personalised_access" using primary key columns */
  enum_personalised_access_by_pk?: Maybe<Enum_Personalised_Access>;
  /** fetch data from the table in a streaming manner: "enum_personalised_access" */
  enum_personalised_access_stream: Array<Enum_Personalised_Access>;
  /** fetch data from the table: "enum_personalised_categories" */
  enum_personalised_categories: Array<Enum_Personalised_Categories>;
  /** fetch aggregated fields from the table: "enum_personalised_categories" */
  enum_personalised_categories_aggregate: Enum_Personalised_Categories_Aggregate;
  /** fetch data from the table: "enum_personalised_categories" using primary key columns */
  enum_personalised_categories_by_pk?: Maybe<Enum_Personalised_Categories>;
  /** fetch data from the table in a streaming manner: "enum_personalised_categories" */
  enum_personalised_categories_stream: Array<Enum_Personalised_Categories>;
  /** fetch data from the table: "enum_post_type" */
  enum_post_type: Array<Enum_Post_Type>;
  /** fetch aggregated fields from the table: "enum_post_type" */
  enum_post_type_aggregate: Enum_Post_Type_Aggregate;
  /** fetch data from the table: "enum_post_type" using primary key columns */
  enum_post_type_by_pk?: Maybe<Enum_Post_Type>;
  /** fetch data from the table in a streaming manner: "enum_post_type" */
  enum_post_type_stream: Array<Enum_Post_Type>;
  /** fetch data from the table: "enum_presence_connection_status" */
  enum_presence_connection_status: Array<Enum_Presence_Connection_Status>;
  /** fetch aggregated fields from the table: "enum_presence_connection_status" */
  enum_presence_connection_status_aggregate: Enum_Presence_Connection_Status_Aggregate;
  /** fetch data from the table: "enum_presence_connection_status" using primary key columns */
  enum_presence_connection_status_by_pk?: Maybe<Enum_Presence_Connection_Status>;
  /** fetch data from the table in a streaming manner: "enum_presence_connection_status" */
  enum_presence_connection_status_stream: Array<Enum_Presence_Connection_Status>;
  /** fetch data from the table: "enum_project_milestone_status" */
  enum_project_milestone_status: Array<Enum_Project_Milestone_Status>;
  /** fetch aggregated fields from the table: "enum_project_milestone_status" */
  enum_project_milestone_status_aggregate: Enum_Project_Milestone_Status_Aggregate;
  /** fetch data from the table: "enum_project_milestone_status" using primary key columns */
  enum_project_milestone_status_by_pk?: Maybe<Enum_Project_Milestone_Status>;
  /** fetch data from the table in a streaming manner: "enum_project_milestone_status" */
  enum_project_milestone_status_stream: Array<Enum_Project_Milestone_Status>;
  /** fetch data from the table: "enum_project_sprint_category" */
  enum_project_sprint_category: Array<Enum_Project_Sprint_Category>;
  /** fetch aggregated fields from the table: "enum_project_sprint_category" */
  enum_project_sprint_category_aggregate: Enum_Project_Sprint_Category_Aggregate;
  /** fetch data from the table: "enum_project_sprint_category" using primary key columns */
  enum_project_sprint_category_by_pk?: Maybe<Enum_Project_Sprint_Category>;
  /** fetch data from the table in a streaming manner: "enum_project_sprint_category" */
  enum_project_sprint_category_stream: Array<Enum_Project_Sprint_Category>;
  /** fetch data from the table: "enum_project_status" */
  enum_project_status: Array<Enum_Project_Status>;
  /** fetch aggregated fields from the table: "enum_project_status" */
  enum_project_status_aggregate: Enum_Project_Status_Aggregate;
  /** fetch data from the table: "enum_project_status" using primary key columns */
  enum_project_status_by_pk?: Maybe<Enum_Project_Status>;
  /** fetch data from the table in a streaming manner: "enum_project_status" */
  enum_project_status_stream: Array<Enum_Project_Status>;
  /** fetch data from the table: "enum_project_task_category" */
  enum_project_task_category: Array<Enum_Project_Task_Category>;
  /** fetch aggregated fields from the table: "enum_project_task_category" */
  enum_project_task_category_aggregate: Enum_Project_Task_Category_Aggregate;
  /** fetch data from the table: "enum_project_task_category" using primary key columns */
  enum_project_task_category_by_pk?: Maybe<Enum_Project_Task_Category>;
  /** fetch data from the table in a streaming manner: "enum_project_task_category" */
  enum_project_task_category_stream: Array<Enum_Project_Task_Category>;
  /** fetch data from the table: "enum_setting_type" */
  enum_setting_type: Array<Enum_Setting_Type>;
  /** fetch aggregated fields from the table: "enum_setting_type" */
  enum_setting_type_aggregate: Enum_Setting_Type_Aggregate;
  /** fetch data from the table: "enum_setting_type" using primary key columns */
  enum_setting_type_by_pk?: Maybe<Enum_Setting_Type>;
  /** fetch data from the table in a streaming manner: "enum_setting_type" */
  enum_setting_type_stream: Array<Enum_Setting_Type>;
  /** fetch data from the table: "enum_storage_file_type" */
  enum_storage_file_type: Array<Enum_Storage_File_Type>;
  /** fetch aggregated fields from the table: "enum_storage_file_type" */
  enum_storage_file_type_aggregate: Enum_Storage_File_Type_Aggregate;
  /** fetch data from the table: "enum_storage_file_type" using primary key columns */
  enum_storage_file_type_by_pk?: Maybe<Enum_Storage_File_Type>;
  /** fetch data from the table in a streaming manner: "enum_storage_file_type" */
  enum_storage_file_type_stream: Array<Enum_Storage_File_Type>;
  /** fetch data from the table: "enum_system_state" */
  enum_system_state: Array<Enum_System_State>;
  /** fetch aggregated fields from the table: "enum_system_state" */
  enum_system_state_aggregate: Enum_System_State_Aggregate;
  /** fetch data from the table: "enum_system_state" using primary key columns */
  enum_system_state_by_pk?: Maybe<Enum_System_State>;
  /** fetch data from the table: "enum_system_state_owner" */
  enum_system_state_owner: Array<Enum_System_State_Owner>;
  /** fetch aggregated fields from the table: "enum_system_state_owner" */
  enum_system_state_owner_aggregate: Enum_System_State_Owner_Aggregate;
  /** fetch data from the table: "enum_system_state_owner" using primary key columns */
  enum_system_state_owner_by_pk?: Maybe<Enum_System_State_Owner>;
  /** fetch data from the table in a streaming manner: "enum_system_state_owner" */
  enum_system_state_owner_stream: Array<Enum_System_State_Owner>;
  /** fetch data from the table in a streaming manner: "enum_system_state" */
  enum_system_state_stream: Array<Enum_System_State>;
  /** fetch data from the table: "enum_task_priority" */
  enum_task_priority: Array<Enum_Task_Priority>;
  /** fetch aggregated fields from the table: "enum_task_priority" */
  enum_task_priority_aggregate: Enum_Task_Priority_Aggregate;
  /** fetch data from the table: "enum_task_priority" using primary key columns */
  enum_task_priority_by_pk?: Maybe<Enum_Task_Priority>;
  /** fetch data from the table in a streaming manner: "enum_task_priority" */
  enum_task_priority_stream: Array<Enum_Task_Priority>;
  /** fetch data from the table: "enum_task_recurring_frequency" */
  enum_task_recurring_frequency: Array<Enum_Task_Recurring_Frequency>;
  /** fetch aggregated fields from the table: "enum_task_recurring_frequency" */
  enum_task_recurring_frequency_aggregate: Enum_Task_Recurring_Frequency_Aggregate;
  /** fetch data from the table: "enum_task_recurring_frequency" using primary key columns */
  enum_task_recurring_frequency_by_pk?: Maybe<Enum_Task_Recurring_Frequency>;
  /** fetch data from the table in a streaming manner: "enum_task_recurring_frequency" */
  enum_task_recurring_frequency_stream: Array<Enum_Task_Recurring_Frequency>;
  /** fetch data from the table: "enum_task_status" */
  enum_task_status: Array<Enum_Task_Status>;
  /** fetch aggregated fields from the table: "enum_task_status" */
  enum_task_status_aggregate: Enum_Task_Status_Aggregate;
  /** fetch data from the table: "enum_task_status" using primary key columns */
  enum_task_status_by_pk?: Maybe<Enum_Task_Status>;
  /** fetch data from the table in a streaming manner: "enum_task_status" */
  enum_task_status_stream: Array<Enum_Task_Status>;
  /** fetch data from the table: "enum_task_time_sheet_status" */
  enum_task_time_sheet_status: Array<Enum_Task_Time_Sheet_Status>;
  /** fetch aggregated fields from the table: "enum_task_time_sheet_status" */
  enum_task_time_sheet_status_aggregate: Enum_Task_Time_Sheet_Status_Aggregate;
  /** fetch data from the table: "enum_task_time_sheet_status" using primary key columns */
  enum_task_time_sheet_status_by_pk?: Maybe<Enum_Task_Time_Sheet_Status>;
  /** fetch data from the table in a streaming manner: "enum_task_time_sheet_status" */
  enum_task_time_sheet_status_stream: Array<Enum_Task_Time_Sheet_Status>;
  /** fetch data from the table: "enum_user_activity_tracker_status_type" */
  enum_user_activity_tracker_status_type: Array<Enum_User_Activity_Tracker_Status_Type>;
  /** fetch aggregated fields from the table: "enum_user_activity_tracker_status_type" */
  enum_user_activity_tracker_status_type_aggregate: Enum_User_Activity_Tracker_Status_Type_Aggregate;
  /** fetch data from the table: "enum_user_activity_tracker_status_type" using primary key columns */
  enum_user_activity_tracker_status_type_by_pk?: Maybe<Enum_User_Activity_Tracker_Status_Type>;
  /** fetch data from the table in a streaming manner: "enum_user_activity_tracker_status_type" */
  enum_user_activity_tracker_status_type_stream: Array<Enum_User_Activity_Tracker_Status_Type>;
  /** fetch data from the table: "enum_user_avatar_types" */
  enum_user_avatar_types: Array<Enum_User_Avatar_Types>;
  /** fetch aggregated fields from the table: "enum_user_avatar_types" */
  enum_user_avatar_types_aggregate: Enum_User_Avatar_Types_Aggregate;
  /** fetch data from the table: "enum_user_avatar_types" using primary key columns */
  enum_user_avatar_types_by_pk?: Maybe<Enum_User_Avatar_Types>;
  /** fetch data from the table in a streaming manner: "enum_user_avatar_types" */
  enum_user_avatar_types_stream: Array<Enum_User_Avatar_Types>;
  /** fetch data from the table: "enum_user_gender" */
  enum_user_gender: Array<Enum_User_Gender>;
  /** fetch aggregated fields from the table: "enum_user_gender" */
  enum_user_gender_aggregate: Enum_User_Gender_Aggregate;
  /** fetch data from the table: "enum_user_gender" using primary key columns */
  enum_user_gender_by_pk?: Maybe<Enum_User_Gender>;
  /** fetch data from the table in a streaming manner: "enum_user_gender" */
  enum_user_gender_stream: Array<Enum_User_Gender>;
  /** fetch data from the table: "enum_user_presence_provider_type" */
  enum_user_presence_provider_type: Array<Enum_User_Presence_Provider_Type>;
  /** fetch aggregated fields from the table: "enum_user_presence_provider_type" */
  enum_user_presence_provider_type_aggregate: Enum_User_Presence_Provider_Type_Aggregate;
  /** fetch data from the table: "enum_user_presence_provider_type" using primary key columns */
  enum_user_presence_provider_type_by_pk?: Maybe<Enum_User_Presence_Provider_Type>;
  /** fetch data from the table in a streaming manner: "enum_user_presence_provider_type" */
  enum_user_presence_provider_type_stream: Array<Enum_User_Presence_Provider_Type>;
  /** fetch data from the table: "enum_user_settings_type" */
  enum_user_settings_type: Array<Enum_User_Settings_Type>;
  /** fetch aggregated fields from the table: "enum_user_settings_type" */
  enum_user_settings_type_aggregate: Enum_User_Settings_Type_Aggregate;
  /** fetch data from the table: "enum_user_settings_type" using primary key columns */
  enum_user_settings_type_by_pk?: Maybe<Enum_User_Settings_Type>;
  /** fetch data from the table in a streaming manner: "enum_user_settings_type" */
  enum_user_settings_type_stream: Array<Enum_User_Settings_Type>;
  /** fetch data from the table: "filter_definition" */
  filter_definition: Array<Filter_Definition>;
  /** fetch aggregated fields from the table: "filter_definition" */
  filter_definition_aggregate: Filter_Definition_Aggregate;
  /** fetch data from the table: "filter_definition" using primary key columns */
  filter_definition_by_pk?: Maybe<Filter_Definition>;
  /** fetch data from the table in a streaming manner: "filter_definition" */
  filter_definition_stream: Array<Filter_Definition>;
  /** fetch data from the table: "financialYear" */
  financialYear: Array<FinancialYear>;
  /** fetch aggregated fields from the table: "financialYear" */
  financialYear_aggregate: FinancialYear_Aggregate;
  /** fetch data from the table: "financialYear" using primary key columns */
  financialYear_by_pk?: Maybe<FinancialYear>;
  /** fetch data from the table in a streaming manner: "financialYear" */
  financialYear_stream: Array<FinancialYear>;
  /** fetch data from the table: "frequency" */
  frequency: Array<Frequency>;
  /** fetch aggregated fields from the table: "frequency" */
  frequency_aggregate: Frequency_Aggregate;
  /** fetch data from the table: "frequency" using primary key columns */
  frequency_by_pk?: Maybe<Frequency>;
  /** fetch data from the table in a streaming manner: "frequency" */
  frequency_stream: Array<Frequency>;
  /** execute function "get_all_dependent_tasks_new" which returns "task_dependencies" */
  get_all_dependent_tasks_new: Array<Task_Dependencies>;
  /** execute function "get_all_dependent_tasks_new" and query aggregates on result of table type "task_dependencies" */
  get_all_dependent_tasks_new_aggregate: Task_Dependencies_Aggregate;
  /** execute function "get_chat_room_id_from_event_id_for_meeting_call" which returns "chat_room" */
  get_chat_room_id_from_event_id_for_meeting_call: Array<Chat_Room>;
  /** execute function "get_chat_room_id_from_event_id_for_meeting_call" and query aggregates on result of table type "chat_room" */
  get_chat_room_id_from_event_id_for_meeting_call_aggregate: Chat_Room_Aggregate;
  /** execute function "get_employee_billable_hours" which returns "dashboard_billable_employee" */
  get_employee_billable_hours: Array<Dashboard_Billable_Employee>;
  /** execute function "get_employee_billable_hours" and query aggregates on result of table type "dashboard_billable_employee" */
  get_employee_billable_hours_aggregate: Dashboard_Billable_Employee_Aggregate;
  /** execute function "get_employee_billable_hours_as_manager" which returns "dashboard_billable_manager" */
  get_employee_billable_hours_as_manager: Array<Dashboard_Billable_Manager>;
  /** execute function "get_employee_billable_hours_as_manager" and query aggregates on result of table type "dashboard_billable_manager" */
  get_employee_billable_hours_as_manager_aggregate: Dashboard_Billable_Manager_Aggregate;
  /** fetch data from the table: "goalWithKpi" */
  goalWithKpi: Array<GoalWithKpi>;
  /** fetch aggregated fields from the table: "goalWithKpi" */
  goalWithKpi_aggregate: GoalWithKpi_Aggregate;
  /** fetch data from the table: "goalWithKpi" using primary key columns */
  goalWithKpi_by_pk?: Maybe<GoalWithKpi>;
  /** fetch data from the table in a streaming manner: "goalWithKpi" */
  goalWithKpi_stream: Array<GoalWithKpi>;
  /** fetch data from the table: "goalWithTimePeriod" */
  goalWithTimePeriod: Array<GoalWithTimePeriod>;
  /** fetch aggregated fields from the table: "goalWithTimePeriod" */
  goalWithTimePeriod_aggregate: GoalWithTimePeriod_Aggregate;
  /** fetch data from the table: "goalWithTimePeriod" using primary key columns */
  goalWithTimePeriod_by_pk?: Maybe<GoalWithTimePeriod>;
  /** fetch data from the table in a streaming manner: "goalWithTimePeriod" */
  goalWithTimePeriod_stream: Array<GoalWithTimePeriod>;
  /** fetch data from the table: "goals" */
  goals: Array<Goals>;
  /** An array relationship */
  goalsWithDepts: Array<GoalsWithDepts>;
  /** An aggregate relationship */
  goalsWithDepts_aggregate: GoalsWithDepts_Aggregate;
  /** fetch data from the table: "goalsWithDepts" using primary key columns */
  goalsWithDepts_by_pk?: Maybe<GoalsWithDepts>;
  /** fetch data from the table in a streaming manner: "goalsWithDepts" */
  goalsWithDepts_stream: Array<GoalsWithDepts>;
  /** An array relationship */
  goalsWithFreqs: Array<GoalsWithFreqs>;
  /** An aggregate relationship */
  goalsWithFreqs_aggregate: GoalsWithFreqs_Aggregate;
  /** fetch data from the table: "goalsWithFreqs" using primary key columns */
  goalsWithFreqs_by_pk?: Maybe<GoalsWithFreqs>;
  /** fetch data from the table in a streaming manner: "goalsWithFreqs" */
  goalsWithFreqs_stream: Array<GoalsWithFreqs>;
  /** An array relationship */
  goalsWithRoles: Array<GoalsWithRoles>;
  /** An aggregate relationship */
  goalsWithRoles_aggregate: GoalsWithRoles_Aggregate;
  /** fetch data from the table: "goalsWithRoles" using primary key columns */
  goalsWithRoles_by_pk?: Maybe<GoalsWithRoles>;
  /** fetch data from the table in a streaming manner: "goalsWithRoles" */
  goalsWithRoles_stream: Array<GoalsWithRoles>;
  /** fetch aggregated fields from the table: "goals" */
  goals_aggregate: Goals_Aggregate;
  /** fetch data from the table: "goals" using primary key columns */
  goals_by_pk?: Maybe<Goals>;
  /** fetch data from the table in a streaming manner: "goals" */
  goals_stream: Array<Goals>;
  /** An array relationship */
  hotdesk_bookings: Array<Hotdesk_Bookings>;
  /** An aggregate relationship */
  hotdesk_bookings_aggregate: Hotdesk_Bookings_Aggregate;
  /** fetch data from the table: "hotdesk_bookings" using primary key columns */
  hotdesk_bookings_by_pk?: Maybe<Hotdesk_Bookings>;
  /** fetch data from the table in a streaming manner: "hotdesk_bookings" */
  hotdesk_bookings_stream: Array<Hotdesk_Bookings>;
  /** execute function "joined_call_usrs" which returns "calendar_meeting_call_user" */
  joined_call_usrs: Array<Calendar_Meeting_Call_User>;
  /** execute function "joined_call_usrs" and query aggregates on result of table type "calendar_meeting_call_user" */
  joined_call_usrs_aggregate: Calendar_Meeting_Call_User_Aggregate;
  /** fetch data from the table: "kpiWithDept" */
  kpiWithDept: Array<KpiWithDept>;
  /** fetch aggregated fields from the table: "kpiWithDept" */
  kpiWithDept_aggregate: KpiWithDept_Aggregate;
  /** fetch data from the table: "kpiWithDept" using primary key columns */
  kpiWithDept_by_pk?: Maybe<KpiWithDept>;
  /** fetch data from the table in a streaming manner: "kpiWithDept" */
  kpiWithDept_stream: Array<KpiWithDept>;
  /** fetch data from the table: "kpiWithRole" */
  kpiWithRole: Array<KpiWithRole>;
  /** fetch aggregated fields from the table: "kpiWithRole" */
  kpiWithRole_aggregate: KpiWithRole_Aggregate;
  /** fetch data from the table: "kpiWithRole" using primary key columns */
  kpiWithRole_by_pk?: Maybe<KpiWithRole>;
  /** fetch data from the table in a streaming manner: "kpiWithRole" */
  kpiWithRole_stream: Array<KpiWithRole>;
  /** fetch data from the table: "kpi_bank" */
  kpi_bank: Array<Kpi_Bank>;
  /** fetch aggregated fields from the table: "kpi_bank" */
  kpi_bank_aggregate: Kpi_Bank_Aggregate;
  /** fetch data from the table: "kpi_bank" using primary key columns */
  kpi_bank_by_pk?: Maybe<Kpi_Bank>;
  /** fetch data from the table in a streaming manner: "kpi_bank" */
  kpi_bank_stream: Array<Kpi_Bank>;
  /** fetch data from the table: "leave" */
  leave: Array<Leave>;
  /** fetch aggregated fields from the table: "leave" */
  leave_aggregate: Leave_Aggregate;
  /** fetch data from the table: "leave" using primary key columns */
  leave_by_pk?: Maybe<Leave>;
  /** fetch data from the table: "leave_donate" */
  leave_donate: Array<Leave_Donate>;
  /** fetch aggregated fields from the table: "leave_donate" */
  leave_donate_aggregate: Leave_Donate_Aggregate;
  /** fetch data from the table: "leave_donate" using primary key columns */
  leave_donate_by_pk?: Maybe<Leave_Donate>;
  /** fetch data from the table in a streaming manner: "leave_donate" */
  leave_donate_stream: Array<Leave_Donate>;
  /** fetch data from the table: "leave_organization" */
  leave_organization: Array<Leave_Organization>;
  /** fetch aggregated fields from the table: "leave_organization" */
  leave_organization_aggregate: Leave_Organization_Aggregate;
  /** fetch data from the table: "leave_organization" using primary key columns */
  leave_organization_by_pk?: Maybe<Leave_Organization>;
  /** fetch data from the table in a streaming manner: "leave_organization" */
  leave_organization_stream: Array<Leave_Organization>;
  /** fetch data from the table in a streaming manner: "leave" */
  leave_stream: Array<Leave>;
  /** An array relationship */
  list_Of_Skills: Array<List_Of_Skills>;
  /** An aggregate relationship */
  list_Of_Skills_aggregate: List_Of_Skills_Aggregate;
  /** fetch data from the table: "list_Of_Skills" using primary key columns */
  list_Of_Skills_by_pk?: Maybe<List_Of_Skills>;
  /** fetch data from the table in a streaming manner: "list_Of_Skills" */
  list_Of_Skills_stream: Array<List_Of_Skills>;
  /** fetch data from the table: "live_call" */
  live_call: Array<Live_Call>;
  /** fetch aggregated fields from the table: "live_call" */
  live_call_aggregate: Live_Call_Aggregate;
  /** fetch data from the table: "live_call" using primary key columns */
  live_call_by_pk?: Maybe<Live_Call>;
  /** fetch data from the table: "live_call_request" */
  live_call_request: Array<Live_Call_Request>;
  /** fetch aggregated fields from the table: "live_call_request" */
  live_call_request_aggregate: Live_Call_Request_Aggregate;
  /** fetch data from the table: "live_call_request" using primary key columns */
  live_call_request_by_pk?: Maybe<Live_Call_Request>;
  /** fetch data from the table in a streaming manner: "live_call_request" */
  live_call_request_stream: Array<Live_Call_Request>;
  /** fetch data from the table in a streaming manner: "live_call" */
  live_call_stream: Array<Live_Call>;
  /** fetch data from the table: "live_call_user" */
  live_call_user: Array<Live_Call_User>;
  /** fetch aggregated fields from the table: "live_call_user" */
  live_call_user_aggregate: Live_Call_User_Aggregate;
  /** fetch data from the table: "live_call_user" using primary key columns */
  live_call_user_by_pk?: Maybe<Live_Call_User>;
  /** fetch data from the table in a streaming manner: "live_call_user" */
  live_call_user_stream: Array<Live_Call_User>;
  /** An array relationship */
  main_skills: Array<Main_Skills>;
  /** An aggregate relationship */
  main_skills_aggregate: Main_Skills_Aggregate;
  /** fetch data from the table: "main_skills" using primary key columns */
  main_skills_by_pk?: Maybe<Main_Skills>;
  /** fetch data from the table in a streaming manner: "main_skills" */
  main_skills_stream: Array<Main_Skills>;
  /** fetch data from the table: "meeting_call_schedular" */
  meeting_call_schedular: Array<Meeting_Call_Schedular>;
  /** fetch aggregated fields from the table: "meeting_call_schedular" */
  meeting_call_schedular_aggregate: Meeting_Call_Schedular_Aggregate;
  /** fetch data from the table: "meeting_call_schedular" using primary key columns */
  meeting_call_schedular_by_pk?: Maybe<Meeting_Call_Schedular>;
  /** fetch data from the table in a streaming manner: "meeting_call_schedular" */
  meeting_call_schedular_stream: Array<Meeting_Call_Schedular>;
  /** fetch data from the table: "meeting_room" */
  meeting_room: Array<Meeting_Room>;
  /** fetch aggregated fields from the table: "meeting_room" */
  meeting_room_aggregate: Meeting_Room_Aggregate;
  /** fetch data from the table: "meeting_room" using primary key columns */
  meeting_room_by_pk?: Maybe<Meeting_Room>;
  /** fetch data from the table in a streaming manner: "meeting_room" */
  meeting_room_stream: Array<Meeting_Room>;
  /** An array relationship */
  meeting_room_users: Array<Meeting_Room_Users>;
  /** An aggregate relationship */
  meeting_room_users_aggregate: Meeting_Room_Users_Aggregate;
  /** fetch data from the table: "meeting_room_users" using primary key columns */
  meeting_room_users_by_pk?: Maybe<Meeting_Room_Users>;
  /** fetch data from the table in a streaming manner: "meeting_room_users" */
  meeting_room_users_stream: Array<Meeting_Room_Users>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch data from the table: "organization_active_leave_types" */
  organization_active_leave_types: Array<Organization_Active_Leave_Types>;
  /** fetch aggregated fields from the table: "organization_active_leave_types" */
  organization_active_leave_types_aggregate: Organization_Active_Leave_Types_Aggregate;
  /** fetch data from the table: "organization_active_leave_types" using primary key columns */
  organization_active_leave_types_by_pk?: Maybe<Organization_Active_Leave_Types>;
  /** fetch data from the table in a streaming manner: "organization_active_leave_types" */
  organization_active_leave_types_stream: Array<Organization_Active_Leave_Types>;
  /** An array relationship */
  organization_admin: Array<Organization_Admin>;
  /** An aggregate relationship */
  organization_admin_aggregate: Organization_Admin_Aggregate;
  /** fetch data from the table: "organization_admin" using primary key columns */
  organization_admin_by_pk?: Maybe<Organization_Admin>;
  /** fetch data from the table in a streaming manner: "organization_admin" */
  organization_admin_stream: Array<Organization_Admin>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: Organization_Aggregate;
  /** fetch data from the table: "organization_awards" */
  organization_awards: Array<Organization_Awards>;
  /** fetch aggregated fields from the table: "organization_awards" */
  organization_awards_aggregate: Organization_Awards_Aggregate;
  /** fetch data from the table: "organization_awards" using primary key columns */
  organization_awards_by_pk?: Maybe<Organization_Awards>;
  /** fetch data from the table in a streaming manner: "organization_awards" */
  organization_awards_stream: Array<Organization_Awards>;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk?: Maybe<Organization>;
  /** fetch data from the table: "organization_employee" */
  organization_employee: Array<Organization_Employee>;
  /** fetch aggregated fields from the table: "organization_employee" */
  organization_employee_aggregate: Organization_Employee_Aggregate;
  /** fetch data from the table: "organization_employee" using primary key columns */
  organization_employee_by_pk?: Maybe<Organization_Employee>;
  /** fetch data from the table in a streaming manner: "organization_employee" */
  organization_employee_stream: Array<Organization_Employee>;
  /** An array relationship */
  organization_holidays: Array<Organization_Holidays>;
  /** An aggregate relationship */
  organization_holidays_aggregate: Organization_Holidays_Aggregate;
  /** fetch data from the table: "organization_holidays" using primary key columns */
  organization_holidays_by_pk?: Maybe<Organization_Holidays>;
  /** fetch data from the table in a streaming manner: "organization_holidays" */
  organization_holidays_stream: Array<Organization_Holidays>;
  /** An array relationship */
  organization_leave_cycles: Array<Organization_Leave_Cycles>;
  /** An aggregate relationship */
  organization_leave_cycles_aggregate: Organization_Leave_Cycles_Aggregate;
  /** fetch data from the table: "organization_leave_cycles" using primary key columns */
  organization_leave_cycles_by_pk?: Maybe<Organization_Leave_Cycles>;
  /** fetch data from the table in a streaming manner: "organization_leave_cycles" */
  organization_leave_cycles_stream: Array<Organization_Leave_Cycles>;
  /** An array relationship */
  organization_locations: Array<Organization_Locations>;
  /** An aggregate relationship */
  organization_locations_aggregate: Organization_Locations_Aggregate;
  /** fetch data from the table: "organization_locations" using primary key columns */
  organization_locations_by_pk?: Maybe<Organization_Locations>;
  /** fetch data from the table in a streaming manner: "organization_locations" */
  organization_locations_stream: Array<Organization_Locations>;
  /** An array relationship */
  organization_roles: Array<Organization_Roles>;
  /** An aggregate relationship */
  organization_roles_aggregate: Organization_Roles_Aggregate;
  /** fetch data from the table: "organization_roles" using primary key columns */
  organization_roles_by_pk?: Maybe<Organization_Roles>;
  /** fetch data from the table in a streaming manner: "organization_roles" */
  organization_roles_stream: Array<Organization_Roles>;
  /** fetch data from the table in a streaming manner: "organization" */
  organization_stream: Array<Organization>;
  /** fetch data from the table: "organization_user" */
  organization_user: Array<Organization_User>;
  /** fetch aggregated fields from the table: "organization_user" */
  organization_user_aggregate: Organization_User_Aggregate;
  /** execute function "organization_user_approved_leaves" which returns "organization_user_leave_application" */
  organization_user_approved_leaves: Array<Organization_User_Leave_Application>;
  /** execute function "organization_user_approved_leaves" and query aggregates on result of table type "organization_user_leave_application" */
  organization_user_approved_leaves_aggregate: Organization_User_Leave_Application_Aggregate;
  /** fetch data from the table: "organization_user_leave_application" */
  organization_user_leave_application: Array<Organization_User_Leave_Application>;
  /** fetch aggregated fields from the table: "organization_user_leave_application" */
  organization_user_leave_application_aggregate: Organization_User_Leave_Application_Aggregate;
  /** fetch data from the table: "organization_user_leave_application" using primary key columns */
  organization_user_leave_application_by_pk?: Maybe<Organization_User_Leave_Application>;
  /** fetch data from the table in a streaming manner: "organization_user_leave_application" */
  organization_user_leave_application_stream: Array<Organization_User_Leave_Application>;
  /** fetch data from the table: "organization_user_leave_data" */
  organization_user_leave_data: Array<Organization_User_Leave_Data>;
  /** fetch aggregated fields from the table: "organization_user_leave_data" */
  organization_user_leave_data_aggregate: Organization_User_Leave_Data_Aggregate;
  /** fetch data from the table: "organization_user_leave_data" using primary key columns */
  organization_user_leave_data_by_pk?: Maybe<Organization_User_Leave_Data>;
  /** fetch data from the table in a streaming manner: "organization_user_leave_data" */
  organization_user_leave_data_stream: Array<Organization_User_Leave_Data>;
  /** fetch data from the table: "organization_user_leave_dates" */
  organization_user_leave_dates: Array<Organization_User_Leave_Dates>;
  /** fetch aggregated fields from the table: "organization_user_leave_dates" */
  organization_user_leave_dates_aggregate: Organization_User_Leave_Dates_Aggregate;
  /** fetch data from the table: "organization_user_leave_dates" using primary key columns */
  organization_user_leave_dates_by_pk?: Maybe<Organization_User_Leave_Dates>;
  /** fetch data from the table in a streaming manner: "organization_user_leave_dates" */
  organization_user_leave_dates_stream: Array<Organization_User_Leave_Dates>;
  /** fetch data from the table: "organization_user_leave_files" */
  organization_user_leave_files: Array<Organization_User_Leave_Files>;
  /** fetch aggregated fields from the table: "organization_user_leave_files" */
  organization_user_leave_files_aggregate: Organization_User_Leave_Files_Aggregate;
  /** fetch data from the table: "organization_user_leave_files" using primary key columns */
  organization_user_leave_files_by_pk?: Maybe<Organization_User_Leave_Files>;
  /** fetch data from the table in a streaming manner: "organization_user_leave_files" */
  organization_user_leave_files_stream: Array<Organization_User_Leave_Files>;
  /** execute function "organization_user_pending_leaves" which returns "organization_user_leave_application" */
  organization_user_pending_leaves: Array<Organization_User_Leave_Application>;
  /** execute function "organization_user_pending_leaves" and query aggregates on result of table type "organization_user_leave_application" */
  organization_user_pending_leaves_aggregate: Organization_User_Leave_Application_Aggregate;
  /** execute function "organization_user_rejected_leaves" which returns "organization_user_leave_application" */
  organization_user_rejected_leaves: Array<Organization_User_Leave_Application>;
  /** execute function "organization_user_rejected_leaves" and query aggregates on result of table type "organization_user_leave_application" */
  organization_user_rejected_leaves_aggregate: Organization_User_Leave_Application_Aggregate;
  /** fetch data from the table in a streaming manner: "organization_user" */
  organization_user_stream: Array<Organization_User>;
  /** fetch data from the table: "performanceReviewStatus" */
  performanceReviewStatus: Array<PerformanceReviewStatus>;
  /** fetch aggregated fields from the table: "performanceReviewStatus" */
  performanceReviewStatus_aggregate: PerformanceReviewStatus_Aggregate;
  /** fetch data from the table: "performanceReviewStatus" using primary key columns */
  performanceReviewStatus_by_pk?: Maybe<PerformanceReviewStatus>;
  /** fetch data from the table in a streaming manner: "performanceReviewStatus" */
  performanceReviewStatus_stream: Array<PerformanceReviewStatus>;
  /** fetch data from the table: "performance_rating_scale" */
  performance_rating_scale: Array<Performance_Rating_Scale>;
  /** fetch aggregated fields from the table: "performance_rating_scale" */
  performance_rating_scale_aggregate: Performance_Rating_Scale_Aggregate;
  /** fetch data from the table: "performance_rating_scale" using primary key columns */
  performance_rating_scale_by_pk?: Maybe<Performance_Rating_Scale>;
  /** fetch data from the table in a streaming manner: "performance_rating_scale" */
  performance_rating_scale_stream: Array<Performance_Rating_Scale>;
  /** fetch data from the table: "performance_review_byUser" */
  performance_review_byUser: Array<Performance_Review_ByUser>;
  /** fetch data from the table: "performance_review_byUser_Kpi" */
  performance_review_byUser_Kpi: Array<Performance_Review_ByUser_Kpi>;
  /** fetch aggregated fields from the table: "performance_review_byUser_Kpi" */
  performance_review_byUser_Kpi_aggregate: Performance_Review_ByUser_Kpi_Aggregate;
  /** fetch data from the table: "performance_review_byUser_Kpi" using primary key columns */
  performance_review_byUser_Kpi_by_pk?: Maybe<Performance_Review_ByUser_Kpi>;
  /** fetch data from the table in a streaming manner: "performance_review_byUser_Kpi" */
  performance_review_byUser_Kpi_stream: Array<Performance_Review_ByUser_Kpi>;
  /** fetch data from the table: "performance_review_byUser_WithGoal" */
  performance_review_byUser_WithGoal: Array<Performance_Review_ByUser_WithGoal>;
  /** fetch aggregated fields from the table: "performance_review_byUser_WithGoal" */
  performance_review_byUser_WithGoal_aggregate: Performance_Review_ByUser_WithGoal_Aggregate;
  /** fetch data from the table: "performance_review_byUser_WithGoal" using primary key columns */
  performance_review_byUser_WithGoal_by_pk?: Maybe<Performance_Review_ByUser_WithGoal>;
  /** fetch data from the table in a streaming manner: "performance_review_byUser_WithGoal" */
  performance_review_byUser_WithGoal_stream: Array<Performance_Review_ByUser_WithGoal>;
  /** fetch aggregated fields from the table: "performance_review_byUser" */
  performance_review_byUser_aggregate: Performance_Review_ByUser_Aggregate;
  /** fetch data from the table: "performance_review_byUser" using primary key columns */
  performance_review_byUser_by_pk?: Maybe<Performance_Review_ByUser>;
  /** fetch data from the table in a streaming manner: "performance_review_byUser" */
  performance_review_byUser_stream: Array<Performance_Review_ByUser>;
  /** fetch data from the table: "performance_review_setting_GoalsList" */
  performance_review_setting_GoalsList: Array<Performance_Review_Setting_GoalsList>;
  /** fetch aggregated fields from the table: "performance_review_setting_GoalsList" */
  performance_review_setting_GoalsList_aggregate: Performance_Review_Setting_GoalsList_Aggregate;
  /** fetch data from the table: "performance_review_setting_GoalsList" using primary key columns */
  performance_review_setting_GoalsList_by_pk?: Maybe<Performance_Review_Setting_GoalsList>;
  /** fetch data from the table in a streaming manner: "performance_review_setting_GoalsList" */
  performance_review_setting_GoalsList_stream: Array<Performance_Review_Setting_GoalsList>;
  /** An array relationship */
  performance_review_setting_With_Goals: Array<Performance_Review_Setting_With_Goals>;
  /** An aggregate relationship */
  performance_review_setting_With_Goals_aggregate: Performance_Review_Setting_With_Goals_Aggregate;
  /** fetch data from the table: "performance_review_setting_With_Goals" using primary key columns */
  performance_review_setting_With_Goals_by_pk?: Maybe<Performance_Review_Setting_With_Goals>;
  /** fetch data from the table in a streaming manner: "performance_review_setting_With_Goals" */
  performance_review_setting_With_Goals_stream: Array<Performance_Review_Setting_With_Goals>;
  /** An array relationship */
  performance_review_settings: Array<Performance_Review_Settings>;
  /** An aggregate relationship */
  performance_review_settings_aggregate: Performance_Review_Settings_Aggregate;
  /** fetch data from the table: "performance_review_settings" using primary key columns */
  performance_review_settings_by_pk?: Maybe<Performance_Review_Settings>;
  /** fetch data from the table in a streaming manner: "performance_review_settings" */
  performance_review_settings_stream: Array<Performance_Review_Settings>;
  /** fetch data from the table: "personalised" */
  personalised: Array<Personalised>;
  /** fetch aggregated fields from the table: "personalised" */
  personalised_aggregate: Personalised_Aggregate;
  /** fetch data from the table: "personalised" using primary key columns */
  personalised_by_pk?: Maybe<Personalised>;
  /** fetch data from the table in a streaming manner: "personalised" */
  personalised_stream: Array<Personalised>;
  /** fetch data from the table: "post_comment_files" */
  post_comment_files: Array<Post_Comment_Files>;
  /** fetch aggregated fields from the table: "post_comment_files" */
  post_comment_files_aggregate: Post_Comment_Files_Aggregate;
  /** fetch data from the table: "post_comment_files" using primary key columns */
  post_comment_files_by_pk?: Maybe<Post_Comment_Files>;
  /** fetch data from the table in a streaming manner: "post_comment_files" */
  post_comment_files_stream: Array<Post_Comment_Files>;
  /** fetch data from the table: "post_comment_mentioned_users" */
  post_comment_mentioned_users: Array<Post_Comment_Mentioned_Users>;
  /** fetch aggregated fields from the table: "post_comment_mentioned_users" */
  post_comment_mentioned_users_aggregate: Post_Comment_Mentioned_Users_Aggregate;
  /** fetch data from the table: "post_comment_mentioned_users" using primary key columns */
  post_comment_mentioned_users_by_pk?: Maybe<Post_Comment_Mentioned_Users>;
  /** fetch data from the table in a streaming manner: "post_comment_mentioned_users" */
  post_comment_mentioned_users_stream: Array<Post_Comment_Mentioned_Users>;
  /** fetch data from the table: "post_comment_replies" */
  post_comment_replies: Array<Post_Comment_Replies>;
  /** fetch aggregated fields from the table: "post_comment_replies" */
  post_comment_replies_aggregate: Post_Comment_Replies_Aggregate;
  /** fetch data from the table: "post_comment_replies" using primary key columns */
  post_comment_replies_by_pk?: Maybe<Post_Comment_Replies>;
  /** fetch data from the table: "post_comment_replies_mentioned_users" */
  post_comment_replies_mentioned_users: Array<Post_Comment_Replies_Mentioned_Users>;
  /** fetch aggregated fields from the table: "post_comment_replies_mentioned_users" */
  post_comment_replies_mentioned_users_aggregate: Post_Comment_Replies_Mentioned_Users_Aggregate;
  /** fetch data from the table: "post_comment_replies_mentioned_users" using primary key columns */
  post_comment_replies_mentioned_users_by_pk?: Maybe<Post_Comment_Replies_Mentioned_Users>;
  /** fetch data from the table in a streaming manner: "post_comment_replies_mentioned_users" */
  post_comment_replies_mentioned_users_stream: Array<Post_Comment_Replies_Mentioned_Users>;
  /** fetch data from the table in a streaming manner: "post_comment_replies" */
  post_comment_replies_stream: Array<Post_Comment_Replies>;
  /** fetch data from the table: "post_comments_likes" */
  post_comments_likes: Array<Post_Comments_Likes>;
  /** fetch aggregated fields from the table: "post_comments_likes" */
  post_comments_likes_aggregate: Post_Comments_Likes_Aggregate;
  /** fetch data from the table: "post_comments_likes" using primary key columns */
  post_comments_likes_by_pk?: Maybe<Post_Comments_Likes>;
  /** fetch data from the table in a streaming manner: "post_comments_likes" */
  post_comments_likes_stream: Array<Post_Comments_Likes>;
  /** An array relationship */
  post_likes: Array<Post_Likes>;
  /** An aggregate relationship */
  post_likes_aggregate: Post_Likes_Aggregate;
  /** fetch data from the table: "post_likes" using primary key columns */
  post_likes_by_pk?: Maybe<Post_Likes>;
  /** fetch data from the table in a streaming manner: "post_likes" */
  post_likes_stream: Array<Post_Likes>;
  /** fetch data from the table: "post_mentioned_users" */
  post_mentioned_users: Array<Post_Mentioned_Users>;
  /** fetch aggregated fields from the table: "post_mentioned_users" */
  post_mentioned_users_aggregate: Post_Mentioned_Users_Aggregate;
  /** fetch data from the table: "post_mentioned_users" using primary key columns */
  post_mentioned_users_by_pk?: Maybe<Post_Mentioned_Users>;
  /** fetch data from the table in a streaming manner: "post_mentioned_users" */
  post_mentioned_users_stream: Array<Post_Mentioned_Users>;
  /** fetch data from the table: "post_replies_likes" */
  post_replies_likes: Array<Post_Replies_Likes>;
  /** fetch aggregated fields from the table: "post_replies_likes" */
  post_replies_likes_aggregate: Post_Replies_Likes_Aggregate;
  /** fetch data from the table: "post_replies_likes" using primary key columns */
  post_replies_likes_by_pk?: Maybe<Post_Replies_Likes>;
  /** fetch data from the table in a streaming manner: "post_replies_likes" */
  post_replies_likes_stream: Array<Post_Replies_Likes>;
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  /** An array relationship */
  posts_comments: Array<Posts_Comments>;
  /** An aggregate relationship */
  posts_comments_aggregate: Posts_Comments_Aggregate;
  /** fetch data from the table: "posts_comments" using primary key columns */
  posts_comments_by_pk?: Maybe<Posts_Comments>;
  /** fetch data from the table in a streaming manner: "posts_comments" */
  posts_comments_stream: Array<Posts_Comments>;
  /** fetch data from the table: "posts_files" */
  posts_files: Array<Posts_Files>;
  /** fetch aggregated fields from the table: "posts_files" */
  posts_files_aggregate: Posts_Files_Aggregate;
  /** fetch data from the table: "posts_files" using primary key columns */
  posts_files_by_pk?: Maybe<Posts_Files>;
  /** fetch data from the table in a streaming manner: "posts_files" */
  posts_files_stream: Array<Posts_Files>;
  /** fetch data from the table: "posts_poll_choices" */
  posts_poll_choices: Array<Posts_Poll_Choices>;
  /** fetch aggregated fields from the table: "posts_poll_choices" */
  posts_poll_choices_aggregate: Posts_Poll_Choices_Aggregate;
  /** fetch data from the table: "posts_poll_choices" using primary key columns */
  posts_poll_choices_by_pk?: Maybe<Posts_Poll_Choices>;
  /** fetch data from the table in a streaming manner: "posts_poll_choices" */
  posts_poll_choices_stream: Array<Posts_Poll_Choices>;
  /** fetch data from the table: "posts_poll_choices_votes" */
  posts_poll_choices_votes: Array<Posts_Poll_Choices_Votes>;
  /** fetch aggregated fields from the table: "posts_poll_choices_votes" */
  posts_poll_choices_votes_aggregate: Posts_Poll_Choices_Votes_Aggregate;
  /** fetch data from the table: "posts_poll_choices_votes" using primary key columns */
  posts_poll_choices_votes_by_pk?: Maybe<Posts_Poll_Choices_Votes>;
  /** fetch data from the table in a streaming manner: "posts_poll_choices_votes" */
  posts_poll_choices_votes_stream: Array<Posts_Poll_Choices_Votes>;
  /** fetch data from the table in a streaming manner: "posts" */
  posts_stream: Array<Posts>;
  /** An array relationship */
  profile: Array<Profile>;
  /** An aggregate relationship */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table in a streaming manner: "profile" */
  profile_stream: Array<Profile>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_client" */
  project_client: Array<Project_Client>;
  /** fetch aggregated fields from the table: "project_client" */
  project_client_aggregate: Project_Client_Aggregate;
  /** fetch data from the table: "project_client" using primary key columns */
  project_client_by_pk?: Maybe<Project_Client>;
  /** fetch data from the table in a streaming manner: "project_client" */
  project_client_stream: Array<Project_Client>;
  /** An array relationship */
  project_links: Array<Project_Links>;
  /** An aggregate relationship */
  project_links_aggregate: Project_Links_Aggregate;
  /** fetch data from the table: "project_links" using primary key columns */
  project_links_by_pk?: Maybe<Project_Links>;
  /** fetch data from the table in a streaming manner: "project_links" */
  project_links_stream: Array<Project_Links>;
  /** An array relationship */
  project_members: Array<Project_Members>;
  /** An aggregate relationship */
  project_members_aggregate: Project_Members_Aggregate;
  /** fetch data from the table: "project_members" using primary key columns */
  project_members_by_pk?: Maybe<Project_Members>;
  /** fetch data from the table in a streaming manner: "project_members" */
  project_members_stream: Array<Project_Members>;
  /** An array relationship */
  project_milestones: Array<Project_Milestones>;
  /** An aggregate relationship */
  project_milestones_aggregate: Project_Milestones_Aggregate;
  /** fetch data from the table: "project_milestones" using primary key columns */
  project_milestones_by_pk?: Maybe<Project_Milestones>;
  /** fetch data from the table in a streaming manner: "project_milestones" */
  project_milestones_stream: Array<Project_Milestones>;
  /** fetch data from the table: "project_notification_settings" */
  project_notification_settings: Array<Project_Notification_Settings>;
  /** fetch aggregated fields from the table: "project_notification_settings" */
  project_notification_settings_aggregate: Project_Notification_Settings_Aggregate;
  /** fetch data from the table: "project_notification_settings" using primary key columns */
  project_notification_settings_by_pk?: Maybe<Project_Notification_Settings>;
  /** fetch data from the table in a streaming manner: "project_notification_settings" */
  project_notification_settings_stream: Array<Project_Notification_Settings>;
  /** An array relationship */
  project_section: Array<Project_Section>;
  /** An aggregate relationship */
  project_section_aggregate: Project_Section_Aggregate;
  /** fetch data from the table: "project_section" using primary key columns */
  project_section_by_pk?: Maybe<Project_Section>;
  /** fetch data from the table in a streaming manner: "project_section" */
  project_section_stream: Array<Project_Section>;
  /** An array relationship */
  project_sprints: Array<Project_Sprints>;
  /** An aggregate relationship */
  project_sprints_aggregate: Project_Sprints_Aggregate;
  /** fetch data from the table: "project_sprints" using primary key columns */
  project_sprints_by_pk?: Maybe<Project_Sprints>;
  /** fetch data from the table in a streaming manner: "project_sprints" */
  project_sprints_stream: Array<Project_Sprints>;
  /** fetch data from the table in a streaming manner: "project" */
  project_stream: Array<Project>;
  /** An array relationship */
  question_options: Array<Question_Options>;
  /** An aggregate relationship */
  question_options_aggregate: Question_Options_Aggregate;
  /** fetch data from the table: "question_options" using primary key columns */
  question_options_by_pk?: Maybe<Question_Options>;
  /** fetch data from the table in a streaming manner: "question_options" */
  question_options_stream: Array<Question_Options>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table in a streaming manner: "questions" */
  questions_stream: Array<Questions>;
  /** execute function "search_chat_message_text" which returns "chat_message" */
  search_chat_message_text: Array<Chat_Message>;
  /** execute function "search_chat_message_text" and query aggregates on result of table type "chat_message" */
  search_chat_message_text_aggregate: Chat_Message_Aggregate;
  /** fetch data from the table: "skills_detail" */
  skills_detail: Array<Skills_Detail>;
  /** fetch aggregated fields from the table: "skills_detail" */
  skills_detail_aggregate: Skills_Detail_Aggregate;
  /** fetch data from the table: "skills_detail" using primary key columns */
  skills_detail_by_pk?: Maybe<Skills_Detail>;
  /** fetch data from the table in a streaming manner: "skills_detail" */
  skills_detail_stream: Array<Skills_Detail>;
  /** fetch data from the table: "stage_career_detail" */
  stage_career_detail: Array<Stage_Career_Detail>;
  /** fetch aggregated fields from the table: "stage_career_detail" */
  stage_career_detail_aggregate: Stage_Career_Detail_Aggregate;
  /** fetch data from the table: "stage_career_detail" using primary key columns */
  stage_career_detail_by_pk?: Maybe<Stage_Career_Detail>;
  /** fetch data from the table in a streaming manner: "stage_career_detail" */
  stage_career_detail_stream: Array<Stage_Career_Detail>;
  /** An array relationship */
  storage_files: Array<Storage_Files>;
  /** An aggregate relationship */
  storage_files_aggregate: Storage_Files_Aggregate;
  /** fetch data from the table: "storage_files" using primary key columns */
  storage_files_by_pk?: Maybe<Storage_Files>;
  /** fetch data from the table in a streaming manner: "storage_files" */
  storage_files_stream: Array<Storage_Files>;
  /** An array relationship */
  stress_data: Array<Stress_Data>;
  /** An aggregate relationship */
  stress_data_aggregate: Stress_Data_Aggregate;
  /** fetch data from the table: "stress_data" using primary key columns */
  stress_data_by_pk?: Maybe<Stress_Data>;
  /** fetch data from the table in a streaming manner: "stress_data" */
  stress_data_stream: Array<Stress_Data>;
  /** An array relationship */
  sub_skills: Array<Sub_Skills>;
  /** An aggregate relationship */
  sub_skills_aggregate: Sub_Skills_Aggregate;
  /** fetch data from the table: "sub_skills" using primary key columns */
  sub_skills_by_pk?: Maybe<Sub_Skills>;
  /** fetch data from the table in a streaming manner: "sub_skills" */
  sub_skills_stream: Array<Sub_Skills>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  tags_stream: Array<Tags>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: Task_Aggregate;
  /** An array relationship */
  task_approvers: Array<Task_Approvers>;
  /** An aggregate relationship */
  task_approvers_aggregate: Task_Approvers_Aggregate;
  /** fetch data from the table: "task_approvers" using primary key columns */
  task_approvers_by_pk?: Maybe<Task_Approvers>;
  /** fetch data from the table in a streaming manner: "task_approvers" */
  task_approvers_stream: Array<Task_Approvers>;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** fetch data from the table: "task_checklist" */
  task_checklist: Array<Task_Checklist>;
  /** fetch aggregated fields from the table: "task_checklist" */
  task_checklist_aggregate: Task_Checklist_Aggregate;
  /** fetch data from the table: "task_checklist" using primary key columns */
  task_checklist_by_pk?: Maybe<Task_Checklist>;
  /** fetch data from the table in a streaming manner: "task_checklist" */
  task_checklist_stream: Array<Task_Checklist>;
  /** fetch data from the table: "task_collaborator" */
  task_collaborator: Array<Task_Collaborator>;
  /** fetch aggregated fields from the table: "task_collaborator" */
  task_collaborator_aggregate: Task_Collaborator_Aggregate;
  /** fetch data from the table: "task_collaborator" using primary key columns */
  task_collaborator_by_pk?: Maybe<Task_Collaborator>;
  /** fetch data from the table in a streaming manner: "task_collaborator" */
  task_collaborator_stream: Array<Task_Collaborator>;
  /** fetch data from the table: "task_combined" */
  task_combined: Array<Task_Combined>;
  /** fetch aggregated fields from the table: "task_combined" */
  task_combined_aggregate: Task_Combined_Aggregate;
  /** fetch data from the table in a streaming manner: "task_combined" */
  task_combined_stream: Array<Task_Combined>;
  /** fetch data from the table: "task_comment" */
  task_comment: Array<Task_Comment>;
  /** fetch aggregated fields from the table: "task_comment" */
  task_comment_aggregate: Task_Comment_Aggregate;
  /** fetch data from the table: "task_comment" using primary key columns */
  task_comment_by_pk?: Maybe<Task_Comment>;
  /** fetch data from the table: "task_comment_like" */
  task_comment_like: Array<Task_Comment_Like>;
  /** fetch aggregated fields from the table: "task_comment_like" */
  task_comment_like_aggregate: Task_Comment_Like_Aggregate;
  /** fetch data from the table: "task_comment_like" using primary key columns */
  task_comment_like_by_pk?: Maybe<Task_Comment_Like>;
  /** fetch data from the table in a streaming manner: "task_comment_like" */
  task_comment_like_stream: Array<Task_Comment_Like>;
  /** fetch data from the table in a streaming manner: "task_comment" */
  task_comment_stream: Array<Task_Comment>;
  /** An array relationship */
  task_dependencies: Array<Task_Dependencies>;
  /** An aggregate relationship */
  task_dependencies_aggregate: Task_Dependencies_Aggregate;
  /** fetch data from the table: "task_dependencies" using primary key columns */
  task_dependencies_by_pk?: Maybe<Task_Dependencies>;
  /** fetch data from the table in a streaming manner: "task_dependencies" */
  task_dependencies_stream: Array<Task_Dependencies>;
  /** fetch data from the table: "task_file" */
  task_file: Array<Task_File>;
  /** fetch aggregated fields from the table: "task_file" */
  task_file_aggregate: Task_File_Aggregate;
  /** fetch data from the table: "task_file" using primary key columns */
  task_file_by_pk?: Maybe<Task_File>;
  /** fetch data from the table in a streaming manner: "task_file" */
  task_file_stream: Array<Task_File>;
  /** An array relationship */
  task_milestones: Array<Task_Milestones>;
  /** An aggregate relationship */
  task_milestones_aggregate: Task_Milestones_Aggregate;
  /** fetch data from the table: "task_milestones" using primary key columns */
  task_milestones_by_pk?: Maybe<Task_Milestones>;
  /** fetch data from the table in a streaming manner: "task_milestones" */
  task_milestones_stream: Array<Task_Milestones>;
  /** fetch data from the table in a streaming manner: "task" */
  task_stream: Array<Task>;
  /** An array relationship */
  task_tags: Array<Task_Tags>;
  /** An aggregate relationship */
  task_tags_aggregate: Task_Tags_Aggregate;
  /** fetch data from the table: "task_tags" using primary key columns */
  task_tags_by_pk?: Maybe<Task_Tags>;
  /** fetch data from the table in a streaming manner: "task_tags" */
  task_tags_stream: Array<Task_Tags>;
  /** fetch data from the table: "task_time_sheet" */
  task_time_sheet: Array<Task_Time_Sheet>;
  /** fetch aggregated fields from the table: "task_time_sheet" */
  task_time_sheet_aggregate: Task_Time_Sheet_Aggregate;
  /** fetch data from the table: "task_time_sheet" using primary key columns */
  task_time_sheet_by_pk?: Maybe<Task_Time_Sheet>;
  /** fetch data from the table in a streaming manner: "task_time_sheet" */
  task_time_sheet_stream: Array<Task_Time_Sheet>;
  /** fetch data from the table: "task_unscheduled" */
  task_unscheduled: Array<Task_Unscheduled>;
  /** fetch aggregated fields from the table: "task_unscheduled" */
  task_unscheduled_aggregate: Task_Unscheduled_Aggregate;
  /** fetch data from the table: "task_unscheduled" using primary key columns */
  task_unscheduled_by_pk?: Maybe<Task_Unscheduled>;
  /** fetch data from the table in a streaming manner: "task_unscheduled" */
  task_unscheduled_stream: Array<Task_Unscheduled>;
  /** fetch data from the table: "time_period" */
  time_period: Array<Time_Period>;
  /** fetch aggregated fields from the table: "time_period" */
  time_period_aggregate: Time_Period_Aggregate;
  /** fetch data from the table: "time_period" using primary key columns */
  time_period_by_pk?: Maybe<Time_Period>;
  /** fetch data from the table in a streaming manner: "time_period" */
  time_period_stream: Array<Time_Period>;
  /** fetch data from the table: "training" */
  training: Array<Training>;
  /** fetch data from the table: "training_Skill" */
  training_Skill: Array<Training_Skill>;
  /** fetch aggregated fields from the table: "training_Skill" */
  training_Skill_aggregate: Training_Skill_Aggregate;
  /** fetch data from the table: "training_Skill" using primary key columns */
  training_Skill_by_pk?: Maybe<Training_Skill>;
  /** fetch data from the table in a streaming manner: "training_Skill" */
  training_Skill_stream: Array<Training_Skill>;
  /** fetch aggregated fields from the table: "training" */
  training_aggregate: Training_Aggregate;
  /** fetch data from the table: "training" using primary key columns */
  training_by_pk?: Maybe<Training>;
  /** fetch data from the table: "training_feedback" */
  training_feedback: Array<Training_Feedback>;
  /** fetch aggregated fields from the table: "training_feedback" */
  training_feedback_aggregate: Training_Feedback_Aggregate;
  /** fetch data from the table: "training_feedback" using primary key columns */
  training_feedback_by_pk?: Maybe<Training_Feedback>;
  /** fetch data from the table in a streaming manner: "training_feedback" */
  training_feedback_stream: Array<Training_Feedback>;
  /** fetch data from the table: "training_progress_record" */
  training_progress_record: Array<Training_Progress_Record>;
  /** fetch aggregated fields from the table: "training_progress_record" */
  training_progress_record_aggregate: Training_Progress_Record_Aggregate;
  /** fetch data from the table: "training_progress_record" using primary key columns */
  training_progress_record_by_pk?: Maybe<Training_Progress_Record>;
  /** fetch data from the table in a streaming manner: "training_progress_record" */
  training_progress_record_stream: Array<Training_Progress_Record>;
  /** An array relationship */
  training_role_details: Array<Training_Role_Details>;
  /** An aggregate relationship */
  training_role_details_aggregate: Training_Role_Details_Aggregate;
  /** fetch data from the table: "training_role_details" using primary key columns */
  training_role_details_by_pk?: Maybe<Training_Role_Details>;
  /** fetch data from the table in a streaming manner: "training_role_details" */
  training_role_details_stream: Array<Training_Role_Details>;
  /** fetch data from the table: "training_stage_career" */
  training_stage_career: Array<Training_Stage_Career>;
  /** fetch aggregated fields from the table: "training_stage_career" */
  training_stage_career_aggregate: Training_Stage_Career_Aggregate;
  /** fetch data from the table: "training_stage_career" using primary key columns */
  training_stage_career_by_pk?: Maybe<Training_Stage_Career>;
  /** fetch data from the table in a streaming manner: "training_stage_career" */
  training_stage_career_stream: Array<Training_Stage_Career>;
  /** fetch data from the table in a streaming manner: "training" */
  training_stream: Array<Training>;
  /** An array relationship */
  training_table_of_contents: Array<Training_Table_Of_Contents>;
  /** An aggregate relationship */
  training_table_of_contents_aggregate: Training_Table_Of_Contents_Aggregate;
  /** fetch data from the table: "training_table_of_contents" using primary key columns */
  training_table_of_contents_by_pk?: Maybe<Training_Table_Of_Contents>;
  /** fetch data from the table in a streaming manner: "training_table_of_contents" */
  training_table_of_contents_stream: Array<Training_Table_Of_Contents>;
  /** fetch data from the table: "training_type_for_training" */
  training_type_for_training: Array<Training_Type_For_Training>;
  /** fetch aggregated fields from the table: "training_type_for_training" */
  training_type_for_training_aggregate: Training_Type_For_Training_Aggregate;
  /** fetch data from the table: "training_type_for_training" using primary key columns */
  training_type_for_training_by_pk?: Maybe<Training_Type_For_Training>;
  /** fetch data from the table in a streaming manner: "training_type_for_training" */
  training_type_for_training_stream: Array<Training_Type_For_Training>;
  /** For updating rank of task after drag and drop */
  updateTaskRank?: Maybe<UpdateTaskRank>;
  /** fetch data from the table: "used_leave_view" */
  used_leave_view: Array<Used_Leave_View>;
  /** fetch aggregated fields from the table: "used_leave_view" */
  used_leave_view_aggregate: Used_Leave_View_Aggregate;
  /** fetch data from the table in a streaming manner: "used_leave_view" */
  used_leave_view_stream: Array<Used_Leave_View>;
  /** fetch data from the table: "user_Competency" */
  user_Competency: Array<User_Competency>;
  /** fetch aggregated fields from the table: "user_Competency" */
  user_Competency_aggregate: User_Competency_Aggregate;
  /** fetch data from the table: "user_Competency" using primary key columns */
  user_Competency_by_pk?: Maybe<User_Competency>;
  /** fetch data from the table in a streaming manner: "user_Competency" */
  user_Competency_stream: Array<User_Competency>;
  /** An array relationship */
  user_Skills: Array<User_Skills>;
  /** fetch data from the table: "user_Skills_Assessment" */
  user_Skills_Assessment: Array<User_Skills_Assessment>;
  /** fetch aggregated fields from the table: "user_Skills_Assessment" */
  user_Skills_Assessment_aggregate: User_Skills_Assessment_Aggregate;
  /** fetch data from the table: "user_Skills_Assessment" using primary key columns */
  user_Skills_Assessment_by_pk?: Maybe<User_Skills_Assessment>;
  /** fetch data from the table in a streaming manner: "user_Skills_Assessment" */
  user_Skills_Assessment_stream: Array<User_Skills_Assessment>;
  /** fetch data from the table: "user_Skills_Training" */
  user_Skills_Training: Array<User_Skills_Training>;
  /** fetch aggregated fields from the table: "user_Skills_Training" */
  user_Skills_Training_aggregate: User_Skills_Training_Aggregate;
  /** fetch data from the table: "user_Skills_Training" using primary key columns */
  user_Skills_Training_by_pk?: Maybe<User_Skills_Training>;
  /** fetch data from the table in a streaming manner: "user_Skills_Training" */
  user_Skills_Training_stream: Array<User_Skills_Training>;
  /** An aggregate relationship */
  user_Skills_aggregate: User_Skills_Aggregate;
  /** fetch data from the table: "user_Skills" using primary key columns */
  user_Skills_by_pk?: Maybe<User_Skills>;
  /** fetch data from the table in a streaming manner: "user_Skills" */
  user_Skills_stream: Array<User_Skills>;
  /** fetch data from the table: "user_activity_tracker" */
  user_activity_tracker: Array<User_Activity_Tracker>;
  /** fetch aggregated fields from the table: "user_activity_tracker" */
  user_activity_tracker_aggregate: User_Activity_Tracker_Aggregate;
  /** fetch data from the table: "user_activity_tracker" using primary key columns */
  user_activity_tracker_by_pk?: Maybe<User_Activity_Tracker>;
  /** fetch data from the table in a streaming manner: "user_activity_tracker" */
  user_activity_tracker_stream: Array<User_Activity_Tracker>;
  /** fetch data from the table: "user_avatar" */
  user_avatar: Array<User_Avatar>;
  /** fetch aggregated fields from the table: "user_avatar" */
  user_avatar_aggregate: User_Avatar_Aggregate;
  /** fetch data from the table: "user_avatar" using primary key columns */
  user_avatar_by_pk?: Maybe<User_Avatar>;
  /** An array relationship */
  user_avatar_files: Array<User_Avatar_Files>;
  /** An aggregate relationship */
  user_avatar_files_aggregate: User_Avatar_Files_Aggregate;
  /** fetch data from the table: "user_avatar_files" using primary key columns */
  user_avatar_files_by_pk?: Maybe<User_Avatar_Files>;
  /** fetch data from the table in a streaming manner: "user_avatar_files" */
  user_avatar_files_stream: Array<User_Avatar_Files>;
  /** fetch data from the table in a streaming manner: "user_avatar" */
  user_avatar_stream: Array<User_Avatar>;
  /** fetch data from the table: "user_career_development_record" */
  user_career_development_record: Array<User_Career_Development_Record>;
  /** fetch aggregated fields from the table: "user_career_development_record" */
  user_career_development_record_aggregate: User_Career_Development_Record_Aggregate;
  /** fetch data from the table: "user_career_development_record" using primary key columns */
  user_career_development_record_by_pk?: Maybe<User_Career_Development_Record>;
  /** fetch data from the table in a streaming manner: "user_career_development_record" */
  user_career_development_record_stream: Array<User_Career_Development_Record>;
  /** fetch data from the table: "user_career_external_certification" */
  user_career_external_certification: Array<User_Career_External_Certification>;
  /** fetch aggregated fields from the table: "user_career_external_certification" */
  user_career_external_certification_aggregate: User_Career_External_Certification_Aggregate;
  /** fetch data from the table: "user_career_external_certification" using primary key columns */
  user_career_external_certification_by_pk?: Maybe<User_Career_External_Certification>;
  /** fetch data from the table in a streaming manner: "user_career_external_certification" */
  user_career_external_certification_stream: Array<User_Career_External_Certification>;
  /** fetch data from the table: "user_presence" */
  user_presence: Array<User_Presence>;
  /** fetch aggregated fields from the table: "user_presence" */
  user_presence_aggregate: User_Presence_Aggregate;
  /** fetch data from the table: "user_presence" using primary key columns */
  user_presence_by_pk?: Maybe<User_Presence>;
  /** fetch data from the table in a streaming manner: "user_presence" */
  user_presence_stream: Array<User_Presence>;
  /** An array relationship */
  user_settings: Array<User_Settings>;
  /** An aggregate relationship */
  user_settings_aggregate: User_Settings_Aggregate;
  /** fetch data from the table: "user_settings" using primary key columns */
  user_settings_by_pk?: Maybe<User_Settings>;
  /** fetch data from the table in a streaming manner: "user_settings" */
  user_settings_stream: Array<User_Settings>;
  /** fetch data from the table: "user_state" */
  user_state: Array<User_State>;
  /** fetch aggregated fields from the table: "user_state" */
  user_state_aggregate: User_State_Aggregate;
  /** fetch data from the table: "user_state" using primary key columns */
  user_state_by_pk?: Maybe<User_State>;
  /** fetch data from the table in a streaming manner: "user_state" */
  user_state_stream: Array<User_State>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};

export type Subscription_RootUser_Peer_ReviewArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

export type Subscription_RootUser_Peer_Review_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

export type Subscription_RootUser_Peer_Review_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Peer_Review_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Peer_Review_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

export type Subscription_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Subscription_RootAssessmentArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

export type Subscription_RootAssessment_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Skills_Order_By>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

export type Subscription_RootAssessment_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Skills_Order_By>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

export type Subscription_RootAssessment_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssessment_Skills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assessment_Skills_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Skills_Bool_Exp>;
};

export type Subscription_RootAssessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

export type Subscription_RootAssessment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssessment_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

export type Subscription_RootAssessment_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

export type Subscription_RootAssessment_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssessment_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assessment_Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

export type Subscription_RootAssessment_RecordArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

export type Subscription_RootAssessment_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

export type Subscription_RootAssessment_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssessment_Record_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assessment_Record_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

export type Subscription_RootAssessment_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

export type Subscription_RootAssessment_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Role_Details_Order_By>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

export type Subscription_RootAssessment_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssessment_Role_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assessment_Role_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Role_Details_Bool_Exp>;
};

export type Subscription_RootAssessment_Stage_CareerArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Stage_Career_Order_By>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

export type Subscription_RootAssessment_Stage_Career_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Stage_Career_Order_By>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

export type Subscription_RootAssessment_Stage_Career_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssessment_Stage_Career_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assessment_Stage_Career_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Stage_Career_Bool_Exp>;
};

export type Subscription_RootAssessment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assessment_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

export type Subscription_RootAssessment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Subscription_RootAssessment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Subscription_RootAssessment_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssessment_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assessment_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Subscription_RootAssign_Career_Development_PlanArgs = {
  distinct_on?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assign_Career_Development_Plan_Order_By>>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

export type Subscription_RootAssign_Career_Development_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assign_Career_Development_Plan_Order_By>>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

export type Subscription_RootAssign_Career_Development_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssign_Career_Development_Plan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assign_Career_Development_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

export type Subscription_RootAudit_User_StateArgs = {
  distinct_on?: InputMaybe<Array<Audit_User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_User_State_Order_By>>;
  where?: InputMaybe<Audit_User_State_Bool_Exp>;
};

export type Subscription_RootAudit_User_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_User_State_Order_By>>;
  where?: InputMaybe<Audit_User_State_Bool_Exp>;
};

export type Subscription_RootAudit_User_State_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAudit_User_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Audit_User_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_User_State_Bool_Exp>;
};

export type Subscription_RootAuth_Account_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

export type Subscription_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

export type Subscription_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAuth_Account_Providers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Account_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

export type Subscription_RootAuth_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

export type Subscription_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

export type Subscription_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAuth_Account_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Account_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

export type Subscription_RootAuth_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

export type Subscription_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

export type Subscription_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAuth_Accounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

export type Subscription_RootAuth_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};

export type Subscription_RootAuth_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};

export type Subscription_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};

export type Subscription_RootAuth_Providers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};

export type Subscription_RootAuth_Refresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

export type Subscription_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

export type Subscription_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};

export type Subscription_RootAuth_Refresh_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Refresh_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

export type Subscription_RootAuth_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Roles_Order_By>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};

export type Subscription_RootAuth_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Roles_Order_By>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};

export type Subscription_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};

export type Subscription_RootAuth_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};

export type Subscription_RootAward_PostsArgs = {
  distinct_on?: InputMaybe<Array<Award_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Award_Posts_Order_By>>;
  where?: InputMaybe<Award_Posts_Bool_Exp>;
};

export type Subscription_RootAward_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Award_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Award_Posts_Order_By>>;
  where?: InputMaybe<Award_Posts_Bool_Exp>;
};

export type Subscription_RootAward_Posts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAward_Posts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Award_Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Award_Posts_Bool_Exp>;
};

export type Subscription_RootAwardsArgs = {
  distinct_on?: InputMaybe<Array<Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Awards_Order_By>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

export type Subscription_RootAwards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Awards_Order_By>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

export type Subscription_RootAwards_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAwards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Awards_Stream_Cursor_Input>>;
  where?: InputMaybe<Awards_Bool_Exp>;
};

export type Subscription_RootCalendarArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Order_By>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

export type Subscription_RootCalendar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Order_By>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

export type Subscription_RootCalendar_AttendeeArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

export type Subscription_RootCalendar_Attendee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

export type Subscription_RootCalendar_Attendee_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCalendar_Attendee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Attendee_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

export type Subscription_RootCalendar_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCalendar_Delete_EventsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Delete_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Delete_Events_Order_By>>;
  where?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
};

export type Subscription_RootCalendar_Delete_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Delete_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Delete_Events_Order_By>>;
  where?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
};

export type Subscription_RootCalendar_Delete_Events_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCalendar_Delete_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Delete_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Delete_Events_Bool_Exp>;
};

export type Subscription_RootCalendar_EventArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

export type Subscription_RootCalendar_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

export type Subscription_RootCalendar_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCalendar_Event_Material_ViewArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Material_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Material_View_Order_By>>;
  where?: InputMaybe<Calendar_Event_Material_View_Bool_Exp>;
};

export type Subscription_RootCalendar_Event_Material_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Material_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Material_View_Order_By>>;
  where?: InputMaybe<Calendar_Event_Material_View_Bool_Exp>;
};

export type Subscription_RootCalendar_Event_Material_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Event_Material_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Event_Material_View_Bool_Exp>;
};

export type Subscription_RootCalendar_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_CallArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_Call_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_Call_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCalendar_Meeting_Call_RequestArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_Call_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_Call_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCalendar_Meeting_Call_Request_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Meeting_Call_Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_Call_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Meeting_Call_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_Call_UserArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_Call_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_Call_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCalendar_Meeting_Call_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Meeting_Call_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_ViewArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_View_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_View_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_View_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_View_Bool_Exp>;
};

export type Subscription_RootCalendar_Meeting_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Meeting_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Meeting_View_Bool_Exp>;
};

export type Subscription_RootCalendar_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

export type Subscription_RootCallArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

export type Subscription_RootCall_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

export type Subscription_RootCall_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCall_RequestArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

export type Subscription_RootCall_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

export type Subscription_RootCall_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCall_Request_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Call_Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

export type Subscription_RootCall_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Call_Stream_Cursor_Input>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

export type Subscription_RootCall_UserArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

export type Subscription_RootCall_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

export type Subscription_RootCall_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCall_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Call_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

export type Subscription_RootCareer_Development_DetailArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

export type Subscription_RootCareer_Development_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

export type Subscription_RootCareer_Development_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCareer_Development_Detail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Career_Development_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

export type Subscription_RootCareer_Development_Role_DetailsArgs = {
  distinct_on?: InputMaybe<
    Array<Career_Development_Role_Details_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Role_Details_Order_By>>;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

export type Subscription_RootCareer_Development_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Career_Development_Role_Details_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Role_Details_Order_By>>;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

export type Subscription_RootCareer_Development_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCareer_Development_Role_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Career_Development_Role_Details_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Career_Development_Role_Details_Bool_Exp>;
};

export type Subscription_RootCareer_External_CertificationArgs = {
  distinct_on?: InputMaybe<Array<Career_External_Certification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_External_Certification_Order_By>>;
  where?: InputMaybe<Career_External_Certification_Bool_Exp>;
};

export type Subscription_RootCareer_External_Certification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Career_External_Certification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_External_Certification_Order_By>>;
  where?: InputMaybe<Career_External_Certification_Bool_Exp>;
};

export type Subscription_RootCareer_External_Certification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCareer_External_Certification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Career_External_Certification_Stream_Cursor_Input>>;
  where?: InputMaybe<Career_External_Certification_Bool_Exp>;
};

export type Subscription_RootChat_MessageArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Subscription_RootChat_Message_ActionArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Action_Order_By>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

export type Subscription_RootChat_Message_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Action_Order_By>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

export type Subscription_RootChat_Message_Action_By_PkArgs = {
  message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootChat_Message_Action_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Message_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

export type Subscription_RootChat_Message_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Subscription_RootChat_Message_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Message_FileArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_File_Order_By>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

export type Subscription_RootChat_Message_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_File_Order_By>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

export type Subscription_RootChat_Message_File_By_PkArgs = {
  chat_message_id: Scalars['uuid'];
  storage_file_id: Scalars['uuid'];
};

export type Subscription_RootChat_Message_File_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Message_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Message_File_Bool_Exp>;
};

export type Subscription_RootChat_Message_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Reactions_Order_By>>;
  where?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
};

export type Subscription_RootChat_Message_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Reactions_Order_By>>;
  where?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
};

export type Subscription_RootChat_Message_Reactions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Message_Reactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Message_Reactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Message_Reactions_Bool_Exp>;
};

export type Subscription_RootChat_Message_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Message_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Subscription_RootChat_PinnedArgs = {
  distinct_on?: InputMaybe<Array<Chat_Pinned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Pinned_Order_By>>;
  where?: InputMaybe<Chat_Pinned_Bool_Exp>;
};

export type Subscription_RootChat_Pinned_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Pinned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Pinned_Order_By>>;
  where?: InputMaybe<Chat_Pinned_Bool_Exp>;
};

export type Subscription_RootChat_Pinned_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Pinned_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Pinned_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Pinned_Bool_Exp>;
};

export type Subscription_RootChat_RoomArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

export type Subscription_RootChat_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

export type Subscription_RootChat_Room_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Room_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Room_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

export type Subscription_RootChat_Room_UserArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

export type Subscription_RootChat_Room_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

export type Subscription_RootChat_Room_User_By_PkArgs = {
  chat_room_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootChat_Room_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Room_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

export type Subscription_RootDashboard_BillableArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Bool_Exp>;
};

export type Subscription_RootDashboard_Billable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Bool_Exp>;
};

export type Subscription_RootDashboard_Billable_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Employee_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Subscription_RootDashboard_Billable_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Employee_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Subscription_RootDashboard_Billable_Employee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Billable_Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Subscription_RootDashboard_Billable_ManagerArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Manager_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
};

export type Subscription_RootDashboard_Billable_Manager_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Manager_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
};

export type Subscription_RootDashboard_Billable_Manager_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Billable_Manager_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
};

export type Subscription_RootDashboard_Billable_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Billable_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Billable_Bool_Exp>;
};

export type Subscription_RootDashboard_MeetingArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Meeting_Order_By>>;
  where?: InputMaybe<Dashboard_Meeting_Bool_Exp>;
};

export type Subscription_RootDashboard_Meeting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Meeting_Order_By>>;
  where?: InputMaybe<Dashboard_Meeting_Bool_Exp>;
};

export type Subscription_RootDashboard_Meeting_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Meeting_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Meeting_Bool_Exp>;
};

export type Subscription_RootDashboard_RemaindersArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Remainders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Remainders_Order_By>>;
  where?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
};

export type Subscription_RootDashboard_Remainders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Remainders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Remainders_Order_By>>;
  where?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
};

export type Subscription_RootDashboard_Remainders_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDashboard_Remainders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Remainders_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Remainders_Bool_Exp>;
};

export type Subscription_RootDepartmentArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Subscription_RootDepartment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Subscription_RootDepartment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDepartment_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Department_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Employee_Order_By>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

export type Subscription_RootDepartment_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Employee_Order_By>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

export type Subscription_RootDepartment_Employee_By_PkArgs = {
  department_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootDepartment_Employee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Department_Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

export type Subscription_RootDepartment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Department_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Subscription_RootDocument_TokenArgs = {
  distinct_on?: InputMaybe<Array<Document_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Token_Order_By>>;
  where?: InputMaybe<Document_Token_Bool_Exp>;
};

export type Subscription_RootDocument_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Token_Order_By>>;
  where?: InputMaybe<Document_Token_Bool_Exp>;
};

export type Subscription_RootDocument_Token_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Subscription_RootDocument_Token_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Document_Token_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Token_Bool_Exp>;
};

export type Subscription_RootEmail_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Email_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Templates_Order_By>>;
  where?: InputMaybe<Email_Templates_Bool_Exp>;
};

export type Subscription_RootEmail_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Templates_Order_By>>;
  where?: InputMaybe<Email_Templates_Bool_Exp>;
};

export type Subscription_RootEmail_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEmail_Templates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Templates_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Templates_Bool_Exp>;
};

export type Subscription_RootEmployee_Bandwidth_DataArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

export type Subscription_RootEmployee_Bandwidth_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

export type Subscription_RootEmployee_Bandwidth_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEmployee_Bandwidth_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Bandwidth_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

export type Subscription_RootEmployee_CapacityArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_Order_By>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

export type Subscription_RootEmployee_Capacity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_Order_By>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

export type Subscription_RootEmployee_Capacity_By_PkArgs = {
  date: Scalars['date'];
  employee_id: Scalars['uuid'];
};

export type Subscription_RootEmployee_Capacity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Capacity_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

export type Subscription_RootEmployee_Capacity_ViewArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_View_Order_By>>;
  where?: InputMaybe<Employee_Capacity_View_Bool_Exp>;
};

export type Subscription_RootEmployee_Capacity_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_View_Order_By>>;
  where?: InputMaybe<Employee_Capacity_View_Bool_Exp>;
};

export type Subscription_RootEmployee_Capacity_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Capacity_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Capacity_View_Bool_Exp>;
};

export type Subscription_RootEnum_Activity_StateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Activity_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Activity_State_Order_By>>;
  where?: InputMaybe<Enum_Activity_State_Bool_Exp>;
};

export type Subscription_RootEnum_Activity_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Activity_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Activity_State_Order_By>>;
  where?: InputMaybe<Enum_Activity_State_Bool_Exp>;
};

export type Subscription_RootEnum_Activity_State_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Activity_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Activity_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Activity_State_Bool_Exp>;
};

export type Subscription_RootEnum_Activity_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Activity_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Activity_Type_Order_By>>;
  where?: InputMaybe<Enum_Activity_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Activity_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Activity_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Activity_Type_Order_By>>;
  where?: InputMaybe<Enum_Activity_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Activity_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Activity_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Activity_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Activity_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Attendee_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Attendee_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Attendee_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Attendee_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Attendee_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Attendee_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Attendee_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Attendee_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Attendee_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Attendee_Status_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Attendee_Status_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Attendee_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Attendee_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Attendee_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Attendee_Type_Order_By>>;
  where?: InputMaybe<Enum_Attendee_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Attendee_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Attendee_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Attendee_Type_Order_By>>;
  where?: InputMaybe<Enum_Attendee_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Attendee_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Attendee_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Attendee_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Attendee_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Award_Cycle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Award_Cycle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Award_Cycle_Type_Order_By>>;
  where?: InputMaybe<Enum_Award_Cycle_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Award_Cycle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Award_Cycle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Award_Cycle_Type_Order_By>>;
  where?: InputMaybe<Enum_Award_Cycle_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Award_Cycle_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Award_Cycle_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Award_Cycle_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Award_Cycle_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Access_PolicyArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Access_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Access_Policy_Order_By>>;
  where?: InputMaybe<Enum_Call_Access_Policy_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Access_Policy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Access_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Access_Policy_Order_By>>;
  where?: InputMaybe<Enum_Call_Access_Policy_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Access_Policy_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Access_Policy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_Access_Policy_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_Access_Policy_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Broadcasting_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Broadcasting_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Broadcasting_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Broadcasting_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Broadcasting_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Broadcasting_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Broadcasting_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_Broadcasting_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_Broadcasting_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Media_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Media_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Media_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Media_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Media_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Media_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Media_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Media_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Media_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Media_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_Media_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_Media_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Recording_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Recording_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Recording_Status_Order_By>>;
  where?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Recording_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Recording_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Recording_Status_Order_By>>;
  where?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Recording_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Recording_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_Recording_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_Recording_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_Response_TypeArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Call_Request_Response_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Response_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Response_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_Response_Type_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Call_Request_Response_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Response_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Response_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_Response_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Request_Response_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Enum_Call_Request_Response_Type_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Enum_Call_Request_Response_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Request_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Request_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Request_Status_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_Request_Status_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_Request_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Request_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Request_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Request_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Request_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Request_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Request_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_Request_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_Request_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Status_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_Status_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_Type_Order_By>>;
  where?: InputMaybe<Enum_Call_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Call_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Call_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Call_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Call_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Call_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Call_User_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Call_User_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Call_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Message_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Message_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Message_Type_Order_By>>;
  where?: InputMaybe<Enum_Chat_Message_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Message_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Message_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Message_Type_Order_By>>;
  where?: InputMaybe<Enum_Chat_Message_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Message_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Chat_Message_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Chat_Message_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Chat_Message_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Notification_Order_By>>;
  where?: InputMaybe<Enum_Chat_Notification_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Notification_Order_By>>;
  where?: InputMaybe<Enum_Chat_Notification_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Notification_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Chat_Notification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Chat_Notification_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Chat_Notification_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Room_Notification_StatusArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Chat_Room_Notification_Status_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_Notification_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Room_Notification_Status_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Enum_Chat_Room_Notification_Status_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Enum_Chat_Room_Notification_Status_Order_By>>;
    where?: InputMaybe<Enum_Chat_Room_Notification_Status_Bool_Exp>;
  };

export type Subscription_RootEnum_Chat_Room_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Chat_Room_Notification_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Enum_Chat_Room_Notification_Status_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Enum_Chat_Room_Notification_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Room_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_Type_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Room_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_Type_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Room_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Chat_Room_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Chat_Room_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Chat_Room_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Room_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Room_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Room_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Chat_Room_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Chat_Room_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Chat_Room_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Chat_Room_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Chat_Room_User_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Chat_Room_User_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Chat_Room_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Dashboard_Remainder_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Order_By>>;
  where?: InputMaybe<Enum_Dashboard_Remainder_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Dashboard_Remainder_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Dashboard_Remainder_Type_Order_By>>;
  where?: InputMaybe<Enum_Dashboard_Remainder_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Dashboard_Remainder_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Dashboard_Remainder_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Dashboard_Remainder_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Dashboard_Remainder_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Document_Token_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Document_Token_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Document_Token_Type_Order_By>>;
  where?: InputMaybe<Enum_Document_Token_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Document_Token_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Document_Token_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Document_Token_Type_Order_By>>;
  where?: InputMaybe<Enum_Document_Token_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Document_Token_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Document_Token_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Document_Token_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Document_Token_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Event_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Event_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Event_Type_Order_By>>;
  where?: InputMaybe<Enum_Event_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Event_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Event_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Event_Type_Order_By>>;
  where?: InputMaybe<Enum_Event_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Event_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Event_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Event_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Event_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Leave_Donate_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Leave_Donate_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Leave_Donate_Type_Order_By>>;
  where?: InputMaybe<Enum_Leave_Donate_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Leave_Donate_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Leave_Donate_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Leave_Donate_Type_Order_By>>;
  where?: InputMaybe<Enum_Leave_Donate_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Leave_Donate_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Leave_Donate_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Leave_Donate_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Leave_Donate_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Leave_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Leave_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Leave_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Leave_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Leave_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Leave_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Leave_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Leave_Status_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Leave_Status_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Leave_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_Request_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_Request_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_Request_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_Request_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_Request_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_Request_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_Request_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_Request_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_Request_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Live_Call_Request_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Live_Call_Request_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Live_Call_Request_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Live_Call_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Live_Call_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Live_Call_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Call_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Call_User_Status_Order_By>>;
  where?: InputMaybe<Enum_Live_Call_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Call_User_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Live_Call_User_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Live_Call_User_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Live_Call_User_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Emote_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Emote_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Emote_Type_Order_By>>;
  where?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Emote_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Live_Emote_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Live_Emote_Type_Order_By>>;
  where?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Live_Emote_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Live_Emote_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Live_Emote_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Live_Emote_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Manual_StateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Manual_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Manual_State_Order_By>>;
  where?: InputMaybe<Enum_Manual_State_Bool_Exp>;
};

export type Subscription_RootEnum_Manual_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Manual_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Manual_State_Order_By>>;
  where?: InputMaybe<Enum_Manual_State_Bool_Exp>;
};

export type Subscription_RootEnum_Manual_State_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Manual_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Manual_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Manual_State_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_Status_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_Status_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Meeting_Room_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Meeting_Room_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Meeting_Room_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_Type_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_Type_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Meeting_Room_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Meeting_Room_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Meeting_Room_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_User_StateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_User_State_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_User_State_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_User_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Meeting_Room_User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Meeting_Room_User_State_Order_By>>;
  where?: InputMaybe<Enum_Meeting_Room_User_State_Bool_Exp>;
};

export type Subscription_RootEnum_Meeting_Room_User_State_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Meeting_Room_User_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Meeting_Room_User_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Meeting_Room_User_State_Bool_Exp>;
};

export type Subscription_RootEnum_Notification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Notification_Type_Order_By>>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Notification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Notification_Type_Order_By>>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Notification_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Notification_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Personalised_AccessArgs = {
  distinct_on?: InputMaybe<Array<Enum_Personalised_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Personalised_Access_Order_By>>;
  where?: InputMaybe<Enum_Personalised_Access_Bool_Exp>;
};

export type Subscription_RootEnum_Personalised_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Personalised_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Personalised_Access_Order_By>>;
  where?: InputMaybe<Enum_Personalised_Access_Bool_Exp>;
};

export type Subscription_RootEnum_Personalised_Access_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Personalised_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Personalised_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Personalised_Access_Bool_Exp>;
};

export type Subscription_RootEnum_Personalised_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Enum_Personalised_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Personalised_Categories_Order_By>>;
  where?: InputMaybe<Enum_Personalised_Categories_Bool_Exp>;
};

export type Subscription_RootEnum_Personalised_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Personalised_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Personalised_Categories_Order_By>>;
  where?: InputMaybe<Enum_Personalised_Categories_Bool_Exp>;
};

export type Subscription_RootEnum_Personalised_Categories_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Personalised_Categories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Personalised_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Personalised_Categories_Bool_Exp>;
};

export type Subscription_RootEnum_Post_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Post_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Post_Type_Order_By>>;
  where?: InputMaybe<Enum_Post_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Post_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Post_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Post_Type_Order_By>>;
  where?: InputMaybe<Enum_Post_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Post_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Post_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Post_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Post_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Presence_Connection_StatusArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Presence_Connection_Status_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Presence_Connection_Status_Order_By>>;
  where?: InputMaybe<Enum_Presence_Connection_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Presence_Connection_Status_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_Presence_Connection_Status_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Presence_Connection_Status_Order_By>>;
  where?: InputMaybe<Enum_Presence_Connection_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Presence_Connection_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Presence_Connection_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Enum_Presence_Connection_Status_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Enum_Presence_Connection_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Milestone_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Milestone_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Milestone_Status_Order_By>>;
  where?: InputMaybe<Enum_Project_Milestone_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Milestone_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Milestone_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Milestone_Status_Order_By>>;
  where?: InputMaybe<Enum_Project_Milestone_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Milestone_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Project_Milestone_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Project_Milestone_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Project_Milestone_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Sprint_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Sprint_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Sprint_Category_Order_By>>;
  where?: InputMaybe<Enum_Project_Sprint_Category_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Sprint_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Sprint_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Sprint_Category_Order_By>>;
  where?: InputMaybe<Enum_Project_Sprint_Category_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Sprint_Category_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Project_Sprint_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Project_Sprint_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Project_Sprint_Category_Bool_Exp>;
};

export type Subscription_RootEnum_Project_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Status_Order_By>>;
  where?: InputMaybe<Enum_Project_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Status_Order_By>>;
  where?: InputMaybe<Enum_Project_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Project_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Project_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Project_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Task_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Task_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Task_Category_Order_By>>;
  where?: InputMaybe<Enum_Project_Task_Category_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Task_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Project_Task_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Project_Task_Category_Order_By>>;
  where?: InputMaybe<Enum_Project_Task_Category_Bool_Exp>;
};

export type Subscription_RootEnum_Project_Task_Category_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Project_Task_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Project_Task_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Project_Task_Category_Bool_Exp>;
};

export type Subscription_RootEnum_Setting_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Setting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Setting_Type_Order_By>>;
  where?: InputMaybe<Enum_Setting_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Setting_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Setting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Setting_Type_Order_By>>;
  where?: InputMaybe<Enum_Setting_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Setting_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Setting_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Setting_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Setting_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Storage_File_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Storage_File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Storage_File_Type_Order_By>>;
  where?: InputMaybe<Enum_Storage_File_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Storage_File_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Storage_File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Storage_File_Type_Order_By>>;
  where?: InputMaybe<Enum_Storage_File_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Storage_File_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Storage_File_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Storage_File_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Storage_File_Type_Bool_Exp>;
};

export type Subscription_RootEnum_System_StateArgs = {
  distinct_on?: InputMaybe<Array<Enum_System_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_System_State_Order_By>>;
  where?: InputMaybe<Enum_System_State_Bool_Exp>;
};

export type Subscription_RootEnum_System_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_System_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_System_State_Order_By>>;
  where?: InputMaybe<Enum_System_State_Bool_Exp>;
};

export type Subscription_RootEnum_System_State_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_System_State_OwnerArgs = {
  distinct_on?: InputMaybe<Array<Enum_System_State_Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_System_State_Owner_Order_By>>;
  where?: InputMaybe<Enum_System_State_Owner_Bool_Exp>;
};

export type Subscription_RootEnum_System_State_Owner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_System_State_Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_System_State_Owner_Order_By>>;
  where?: InputMaybe<Enum_System_State_Owner_Bool_Exp>;
};

export type Subscription_RootEnum_System_State_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_System_State_Owner_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_System_State_Owner_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_System_State_Owner_Bool_Exp>;
};

export type Subscription_RootEnum_System_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_System_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_System_State_Bool_Exp>;
};

export type Subscription_RootEnum_Task_PriorityArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Priority_Order_By>>;
  where?: InputMaybe<Enum_Task_Priority_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Priority_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Priority_Order_By>>;
  where?: InputMaybe<Enum_Task_Priority_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Priority_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Task_Priority_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Task_Priority_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Task_Priority_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Recurring_FrequencyArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Order_By>>;
  where?: InputMaybe<Enum_Task_Recurring_Frequency_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Recurring_Frequency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Recurring_Frequency_Order_By>>;
  where?: InputMaybe<Enum_Task_Recurring_Frequency_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Recurring_Frequency_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Task_Recurring_Frequency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Task_Recurring_Frequency_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Task_Recurring_Frequency_Bool_Exp>;
};

export type Subscription_RootEnum_Task_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Status_Order_By>>;
  where?: InputMaybe<Enum_Task_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Status_Order_By>>;
  where?: InputMaybe<Enum_Task_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Task_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Task_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Task_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Time_Sheet_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Order_By>>;
  where?: InputMaybe<Enum_Task_Time_Sheet_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Time_Sheet_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Task_Time_Sheet_Status_Order_By>>;
  where?: InputMaybe<Enum_Task_Time_Sheet_Status_Bool_Exp>;
};

export type Subscription_RootEnum_Task_Time_Sheet_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Task_Time_Sheet_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Task_Time_Sheet_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Task_Time_Sheet_Status_Bool_Exp>;
};

export type Subscription_RootEnum_User_Activity_Tracker_Status_TypeArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_User_Activity_Tracker_Status_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Activity_Tracker_Status_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>;
};

export type Subscription_RootEnum_User_Activity_Tracker_Status_Type_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Enum_User_Activity_Tracker_Status_Type_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<
      Array<Enum_User_Activity_Tracker_Status_Type_Order_By>
    >;
    where?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>;
  };

export type Subscription_RootEnum_User_Activity_Tracker_Status_Type_By_PkArgs =
  {
    value: Scalars['String'];
  };

export type Subscription_RootEnum_User_Activity_Tracker_Status_Type_StreamArgs =
  {
    batch_size: Scalars['Int'];
    cursor: Array<
      InputMaybe<Enum_User_Activity_Tracker_Status_Type_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>;
  };

export type Subscription_RootEnum_User_Avatar_TypesArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Avatar_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Avatar_Types_Order_By>>;
  where?: InputMaybe<Enum_User_Avatar_Types_Bool_Exp>;
};

export type Subscription_RootEnum_User_Avatar_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Avatar_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Avatar_Types_Order_By>>;
  where?: InputMaybe<Enum_User_Avatar_Types_Bool_Exp>;
};

export type Subscription_RootEnum_User_Avatar_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_User_Avatar_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_User_Avatar_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_User_Avatar_Types_Bool_Exp>;
};

export type Subscription_RootEnum_User_GenderArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Gender_Order_By>>;
  where?: InputMaybe<Enum_User_Gender_Bool_Exp>;
};

export type Subscription_RootEnum_User_Gender_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Gender_Order_By>>;
  where?: InputMaybe<Enum_User_Gender_Bool_Exp>;
};

export type Subscription_RootEnum_User_Gender_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_User_Gender_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_User_Gender_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_User_Gender_Bool_Exp>;
};

export type Subscription_RootEnum_User_Presence_Provider_TypeArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_User_Presence_Provider_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Presence_Provider_Type_Bool_Exp>;
};

export type Subscription_RootEnum_User_Presence_Provider_Type_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Enum_User_Presence_Provider_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Presence_Provider_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Presence_Provider_Type_Bool_Exp>;
};

export type Subscription_RootEnum_User_Presence_Provider_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_User_Presence_Provider_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Enum_User_Presence_Provider_Type_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Enum_User_Presence_Provider_Type_Bool_Exp>;
};

export type Subscription_RootEnum_User_Settings_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Settings_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Settings_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Settings_Type_Bool_Exp>;
};

export type Subscription_RootEnum_User_Settings_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Settings_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Settings_Type_Order_By>>;
  where?: InputMaybe<Enum_User_Settings_Type_Bool_Exp>;
};

export type Subscription_RootEnum_User_Settings_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_User_Settings_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_User_Settings_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_User_Settings_Type_Bool_Exp>;
};

export type Subscription_RootFilter_DefinitionArgs = {
  distinct_on?: InputMaybe<Array<Filter_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filter_Definition_Order_By>>;
  where?: InputMaybe<Filter_Definition_Bool_Exp>;
};

export type Subscription_RootFilter_Definition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filter_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filter_Definition_Order_By>>;
  where?: InputMaybe<Filter_Definition_Bool_Exp>;
};

export type Subscription_RootFilter_Definition_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFilter_Definition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Filter_Definition_Stream_Cursor_Input>>;
  where?: InputMaybe<Filter_Definition_Bool_Exp>;
};

export type Subscription_RootFinancialYearArgs = {
  distinct_on?: InputMaybe<Array<FinancialYear_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FinancialYear_Order_By>>;
  where?: InputMaybe<FinancialYear_Bool_Exp>;
};

export type Subscription_RootFinancialYear_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FinancialYear_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FinancialYear_Order_By>>;
  where?: InputMaybe<FinancialYear_Bool_Exp>;
};

export type Subscription_RootFinancialYear_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFinancialYear_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FinancialYear_Stream_Cursor_Input>>;
  where?: InputMaybe<FinancialYear_Bool_Exp>;
};

export type Subscription_RootFrequencyArgs = {
  distinct_on?: InputMaybe<Array<Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frequency_Order_By>>;
  where?: InputMaybe<Frequency_Bool_Exp>;
};

export type Subscription_RootFrequency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frequency_Order_By>>;
  where?: InputMaybe<Frequency_Bool_Exp>;
};

export type Subscription_RootFrequency_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFrequency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Frequency_Stream_Cursor_Input>>;
  where?: InputMaybe<Frequency_Bool_Exp>;
};

export type Subscription_RootGet_All_Dependent_Tasks_NewArgs = {
  args: Get_All_Dependent_Tasks_New_Args;
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Subscription_RootGet_All_Dependent_Tasks_New_AggregateArgs = {
  args: Get_All_Dependent_Tasks_New_Args;
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Subscription_RootGet_Chat_Room_Id_From_Event_Id_For_Meeting_CallArgs =
  {
    args: Get_Chat_Room_Id_From_Event_Id_For_Meeting_Call_Args;
    distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
    where?: InputMaybe<Chat_Room_Bool_Exp>;
  };

export type Subscription_RootGet_Chat_Room_Id_From_Event_Id_For_Meeting_Call_AggregateArgs =
  {
    args: Get_Chat_Room_Id_From_Event_Id_For_Meeting_Call_Args;
    distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
    where?: InputMaybe<Chat_Room_Bool_Exp>;
  };

export type Subscription_RootGet_Employee_Billable_HoursArgs = {
  args: Get_Employee_Billable_Hours_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Employee_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Subscription_RootGet_Employee_Billable_Hours_AggregateArgs = {
  args: Get_Employee_Billable_Hours_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Employee_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Employee_Bool_Exp>;
};

export type Subscription_RootGet_Employee_Billable_Hours_As_ManagerArgs = {
  args: Get_Employee_Billable_Hours_As_Manager_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Billable_Manager_Order_By>>;
  where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
};

export type Subscription_RootGet_Employee_Billable_Hours_As_Manager_AggregateArgs =
  {
    args: Get_Employee_Billable_Hours_As_Manager_Args;
    distinct_on?: InputMaybe<Array<Dashboard_Billable_Manager_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Dashboard_Billable_Manager_Order_By>>;
    where?: InputMaybe<Dashboard_Billable_Manager_Bool_Exp>;
  };

export type Subscription_RootGoalWithKpiArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

export type Subscription_RootGoalWithKpi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

export type Subscription_RootGoalWithKpi_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGoalWithKpi_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoalWithKpi_Stream_Cursor_Input>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

export type Subscription_RootGoalWithTimePeriodArgs = {
  distinct_on?: InputMaybe<Array<GoalWithTimePeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithTimePeriod_Order_By>>;
  where?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
};

export type Subscription_RootGoalWithTimePeriod_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalWithTimePeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithTimePeriod_Order_By>>;
  where?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
};

export type Subscription_RootGoalWithTimePeriod_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGoalWithTimePeriod_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoalWithTimePeriod_Stream_Cursor_Input>>;
  where?: InputMaybe<GoalWithTimePeriod_Bool_Exp>;
};

export type Subscription_RootGoalsArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

export type Subscription_RootGoalsWithDeptsArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithDepts_Order_By>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

export type Subscription_RootGoalsWithDepts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithDepts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithDepts_Order_By>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

export type Subscription_RootGoalsWithDepts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGoalsWithDepts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoalsWithDepts_Stream_Cursor_Input>>;
  where?: InputMaybe<GoalsWithDepts_Bool_Exp>;
};

export type Subscription_RootGoalsWithFreqsArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithFreqs_Order_By>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

export type Subscription_RootGoalsWithFreqs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithFreqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithFreqs_Order_By>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

export type Subscription_RootGoalsWithFreqs_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGoalsWithFreqs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoalsWithFreqs_Stream_Cursor_Input>>;
  where?: InputMaybe<GoalsWithFreqs_Bool_Exp>;
};

export type Subscription_RootGoalsWithRolesArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithRoles_Order_By>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

export type Subscription_RootGoalsWithRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalsWithRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalsWithRoles_Order_By>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

export type Subscription_RootGoalsWithRoles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGoalsWithRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoalsWithRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<GoalsWithRoles_Bool_Exp>;
};

export type Subscription_RootGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

export type Subscription_RootGoals_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGoals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Goals_Stream_Cursor_Input>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

export type Subscription_RootHotdesk_BookingsArgs = {
  distinct_on?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hotdesk_Bookings_Order_By>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

export type Subscription_RootHotdesk_Bookings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hotdesk_Bookings_Order_By>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

export type Subscription_RootHotdesk_Bookings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHotdesk_Bookings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hotdesk_Bookings_Stream_Cursor_Input>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

export type Subscription_RootJoined_Call_UsrsArgs = {
  args: Joined_Call_Usrs_Args;
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Subscription_RootJoined_Call_Usrs_AggregateArgs = {
  args: Joined_Call_Usrs_Args;
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

export type Subscription_RootKpiWithDeptArgs = {
  distinct_on?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithDept_Order_By>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

export type Subscription_RootKpiWithDept_AggregateArgs = {
  distinct_on?: InputMaybe<Array<KpiWithDept_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithDept_Order_By>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

export type Subscription_RootKpiWithDept_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootKpiWithDept_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<KpiWithDept_Stream_Cursor_Input>>;
  where?: InputMaybe<KpiWithDept_Bool_Exp>;
};

export type Subscription_RootKpiWithRoleArgs = {
  distinct_on?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithRole_Order_By>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

export type Subscription_RootKpiWithRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<KpiWithRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KpiWithRole_Order_By>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

export type Subscription_RootKpiWithRole_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootKpiWithRole_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<KpiWithRole_Stream_Cursor_Input>>;
  where?: InputMaybe<KpiWithRole_Bool_Exp>;
};

export type Subscription_RootKpi_BankArgs = {
  distinct_on?: InputMaybe<Array<Kpi_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kpi_Bank_Order_By>>;
  where?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

export type Subscription_RootKpi_Bank_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kpi_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kpi_Bank_Order_By>>;
  where?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

export type Subscription_RootKpi_Bank_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootKpi_Bank_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kpi_Bank_Stream_Cursor_Input>>;
  where?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

export type Subscription_RootLeaveArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

export type Subscription_RootLeave_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

export type Subscription_RootLeave_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLeave_DonateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

export type Subscription_RootLeave_Donate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

export type Subscription_RootLeave_Donate_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLeave_Donate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leave_Donate_Stream_Cursor_Input>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

export type Subscription_RootLeave_OrganizationArgs = {
  distinct_on?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Organization_Order_By>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

export type Subscription_RootLeave_Organization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Organization_Order_By>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

export type Subscription_RootLeave_Organization_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLeave_Organization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leave_Organization_Stream_Cursor_Input>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

export type Subscription_RootLeave_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leave_Stream_Cursor_Input>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

export type Subscription_RootList_Of_SkillsArgs = {
  distinct_on?: InputMaybe<Array<List_Of_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Of_Skills_Order_By>>;
  where?: InputMaybe<List_Of_Skills_Bool_Exp>;
};

export type Subscription_RootList_Of_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Of_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Of_Skills_Order_By>>;
  where?: InputMaybe<List_Of_Skills_Bool_Exp>;
};

export type Subscription_RootList_Of_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootList_Of_Skills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<List_Of_Skills_Stream_Cursor_Input>>;
  where?: InputMaybe<List_Of_Skills_Bool_Exp>;
};

export type Subscription_RootLive_CallArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Order_By>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

export type Subscription_RootLive_Call_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Order_By>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

export type Subscription_RootLive_Call_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLive_Call_RequestArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

export type Subscription_RootLive_Call_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

export type Subscription_RootLive_Call_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLive_Call_Request_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Live_Call_Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

export type Subscription_RootLive_Call_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Live_Call_Stream_Cursor_Input>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

export type Subscription_RootLive_Call_UserArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

export type Subscription_RootLive_Call_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

export type Subscription_RootLive_Call_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLive_Call_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Live_Call_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

export type Subscription_RootMain_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Main_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Main_Skills_Order_By>>;
  where?: InputMaybe<Main_Skills_Bool_Exp>;
};

export type Subscription_RootMain_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Main_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Main_Skills_Order_By>>;
  where?: InputMaybe<Main_Skills_Bool_Exp>;
};

export type Subscription_RootMain_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMain_Skills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Main_Skills_Stream_Cursor_Input>>;
  where?: InputMaybe<Main_Skills_Bool_Exp>;
};

export type Subscription_RootMeeting_Call_SchedularArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Call_Schedular_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Call_Schedular_Order_By>>;
  where?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
};

export type Subscription_RootMeeting_Call_Schedular_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Call_Schedular_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Call_Schedular_Order_By>>;
  where?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
};

export type Subscription_RootMeeting_Call_Schedular_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMeeting_Call_Schedular_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Call_Schedular_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Call_Schedular_Bool_Exp>;
};

export type Subscription_RootMeeting_RoomArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

export type Subscription_RootMeeting_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

export type Subscription_RootMeeting_Room_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMeeting_Room_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Room_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

export type Subscription_RootMeeting_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

export type Subscription_RootMeeting_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

export type Subscription_RootMeeting_Room_Users_By_PkArgs = {
  meeting_room_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootMeeting_Room_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Room_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_Active_Leave_TypesArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_Active_Leave_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Active_Leave_Types_Order_By>>;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

export type Subscription_RootOrganization_Active_Leave_Types_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_Active_Leave_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Active_Leave_Types_Order_By>>;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

export type Subscription_RootOrganization_Active_Leave_Types_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Active_Leave_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Organization_Active_Leave_Types_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Organization_Active_Leave_Types_Bool_Exp>;
};

export type Subscription_RootOrganization_AdminArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

export type Subscription_RootOrganization_Admin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

export type Subscription_RootOrganization_Admin_By_PkArgs = {
  organization_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Admin_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Admin_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

export type Subscription_RootOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_AwardsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Awards_Order_By>>;
  where?: InputMaybe<Organization_Awards_Bool_Exp>;
};

export type Subscription_RootOrganization_Awards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Awards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Awards_Order_By>>;
  where?: InputMaybe<Organization_Awards_Bool_Exp>;
};

export type Subscription_RootOrganization_Awards_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Awards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Awards_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Awards_Bool_Exp>;
};

export type Subscription_RootOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Subscription_RootOrganization_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Subscription_RootOrganization_Employee_By_PkArgs = {
  organization_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Employee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Subscription_RootOrganization_HolidaysArgs = {
  distinct_on?: InputMaybe<Array<Organization_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Holidays_Order_By>>;
  where?: InputMaybe<Organization_Holidays_Bool_Exp>;
};

export type Subscription_RootOrganization_Holidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Holidays_Order_By>>;
  where?: InputMaybe<Organization_Holidays_Bool_Exp>;
};

export type Subscription_RootOrganization_Holidays_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Holidays_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Holidays_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Holidays_Bool_Exp>;
};

export type Subscription_RootOrganization_Leave_CyclesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leave_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leave_Cycles_Order_By>>;
  where?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
};

export type Subscription_RootOrganization_Leave_Cycles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leave_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leave_Cycles_Order_By>>;
  where?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
};

export type Subscription_RootOrganization_Leave_Cycles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Leave_Cycles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Leave_Cycles_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Leave_Cycles_Bool_Exp>;
};

export type Subscription_RootOrganization_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

export type Subscription_RootOrganization_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

export type Subscription_RootOrganization_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};

export type Subscription_RootOrganization_RolesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Roles_Order_By>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

export type Subscription_RootOrganization_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Roles_Order_By>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

export type Subscription_RootOrganization_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Roles_Bool_Exp>;
};

export type Subscription_RootOrganization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_UserArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Order_By>>;
  where?: InputMaybe<Organization_User_Bool_Exp>;
};

export type Subscription_RootOrganization_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Order_By>>;
  where?: InputMaybe<Organization_User_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Approved_LeavesArgs = {
  args: Organization_User_Approved_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Approved_Leaves_AggregateArgs = {
  args: Organization_User_Approved_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_ApplicationArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_Application_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Organization_User_Leave_Application_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
    where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  };

export type Subscription_RootOrganization_User_Leave_Application_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_User_Leave_Application_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Organization_User_Leave_Application_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_DataArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Data_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Data_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_User_Leave_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_User_Leave_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_User_Leave_Data_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_DatesArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Dates_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_Dates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Dates_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_Dates_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_User_Leave_Dates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_User_Leave_Dates_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_User_Leave_Dates_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_FilesArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Files_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_User_Leave_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Files_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Leave_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganization_User_Leave_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_User_Leave_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_User_Leave_Files_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Pending_LeavesArgs = {
  args: Organization_User_Pending_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Pending_Leaves_AggregateArgs = {
  args: Organization_User_Pending_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Rejected_LeavesArgs = {
  args: Organization_User_Rejected_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Subscription_RootOrganization_User_Rejected_Leaves_AggregateArgs = {
  args: Organization_User_Rejected_Leaves_Args;
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

export type Subscription_RootOrganization_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_User_Bool_Exp>;
};

export type Subscription_RootPerformanceReviewStatusArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

export type Subscription_RootPerformanceReviewStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

export type Subscription_RootPerformanceReviewStatus_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPerformanceReviewStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PerformanceReviewStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

export type Subscription_RootPerformance_Rating_ScaleArgs = {
  distinct_on?: InputMaybe<Array<Performance_Rating_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Rating_Scale_Order_By>>;
  where?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
};

export type Subscription_RootPerformance_Rating_Scale_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Rating_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Rating_Scale_Order_By>>;
  where?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
};

export type Subscription_RootPerformance_Rating_Scale_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPerformance_Rating_Scale_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Performance_Rating_Scale_Stream_Cursor_Input>>;
  where?: InputMaybe<Performance_Rating_Scale_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_ByUserArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_ByUser_KpiArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_ByUser_Kpi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Kpi_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_ByUser_Kpi_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPerformance_Review_ByUser_Kpi_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Performance_Review_ByUser_Kpi_Stream_Cursor_Input>>;
  where?: InputMaybe<Performance_Review_ByUser_Kpi_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_ByUser_WithGoalArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_ByUser_WithGoal_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_ByUser_WithGoal_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_ByUser_WithGoal_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_ByUser_WithGoal_Order_By>>;
    where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
  };

export type Subscription_RootPerformance_Review_ByUser_WithGoal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPerformance_Review_ByUser_WithGoal_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Performance_Review_ByUser_WithGoal_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Performance_Review_ByUser_WithGoal_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_ByUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_ByUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPerformance_Review_ByUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Performance_Review_ByUser_Stream_Cursor_Input>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_Setting_GoalsListArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_Setting_GoalsList_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
  where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_Setting_GoalsList_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_Setting_GoalsList_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Performance_Review_Setting_GoalsList_Order_By>>;
    where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
  };

export type Subscription_RootPerformance_Review_Setting_GoalsList_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPerformance_Review_Setting_GoalsList_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Performance_Review_Setting_GoalsList_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Performance_Review_Setting_GoalsList_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_Setting_With_GoalsArgs = {
  distinct_on?: InputMaybe<
    Array<Performance_Review_Setting_With_Goals_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Setting_With_Goals_Order_By>>;
  where?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_Setting_With_Goals_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Performance_Review_Setting_With_Goals_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<
      Array<Performance_Review_Setting_With_Goals_Order_By>
    >;
    where?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  };

export type Subscription_RootPerformance_Review_Setting_With_Goals_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPerformance_Review_Setting_With_Goals_StreamArgs =
  {
    batch_size: Scalars['Int'];
    cursor: Array<
      InputMaybe<Performance_Review_Setting_With_Goals_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Performance_Review_Setting_With_Goals_Bool_Exp>;
  };

export type Subscription_RootPerformance_Review_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Settings_Order_By>>;
  where?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_Settings_Order_By>>;
  where?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
};

export type Subscription_RootPerformance_Review_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPerformance_Review_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Performance_Review_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Performance_Review_Settings_Bool_Exp>;
};

export type Subscription_RootPersonalisedArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

export type Subscription_RootPersonalised_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

export type Subscription_RootPersonalised_By_PkArgs = {
  created_at: Scalars['timestamp'];
};

export type Subscription_RootPersonalised_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Personalised_Stream_Cursor_Input>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

export type Subscription_RootPost_Comment_FilesArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Files_Order_By>>;
  where?: InputMaybe<Post_Comment_Files_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Files_Order_By>>;
  where?: InputMaybe<Post_Comment_Files_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPost_Comment_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Comment_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Comment_Files_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Mentioned_UsersArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Mentioned_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Mentioned_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPost_Comment_Mentioned_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Comment_Mentioned_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Comment_Mentioned_Users_Bool_Exp>;
};

export type Subscription_RootPost_Comment_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comment_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Replies_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPost_Comment_Replies_Mentioned_UsersArgs = {
  distinct_on?: InputMaybe<
    Array<Post_Comment_Replies_Mentioned_Users_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comment_Replies_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Replies_Mentioned_Users_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Post_Comment_Replies_Mentioned_Users_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Post_Comment_Replies_Mentioned_Users_Order_By>>;
    where?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
  };

export type Subscription_RootPost_Comment_Replies_Mentioned_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPost_Comment_Replies_Mentioned_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Post_Comment_Replies_Mentioned_Users_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Post_Comment_Replies_Mentioned_Users_Bool_Exp>;
};

export type Subscription_RootPost_Comment_Replies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Comment_Replies_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Comment_Replies_Bool_Exp>;
};

export type Subscription_RootPost_Comments_LikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comments_Likes_Order_By>>;
  where?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
};

export type Subscription_RootPost_Comments_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Comments_Likes_Order_By>>;
  where?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
};

export type Subscription_RootPost_Comments_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPost_Comments_Likes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Comments_Likes_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Comments_Likes_Bool_Exp>;
};

export type Subscription_RootPost_LikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Likes_Order_By>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

export type Subscription_RootPost_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Likes_Order_By>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

export type Subscription_RootPost_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPost_Likes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Likes_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

export type Subscription_RootPost_Mentioned_UsersArgs = {
  distinct_on?: InputMaybe<Array<Post_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
};

export type Subscription_RootPost_Mentioned_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Mentioned_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Mentioned_Users_Order_By>>;
  where?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
};

export type Subscription_RootPost_Mentioned_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPost_Mentioned_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Mentioned_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Mentioned_Users_Bool_Exp>;
};

export type Subscription_RootPost_Replies_LikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Replies_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Replies_Likes_Order_By>>;
  where?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
};

export type Subscription_RootPost_Replies_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Replies_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Replies_Likes_Order_By>>;
  where?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
};

export type Subscription_RootPost_Replies_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPost_Replies_Likes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Replies_Likes_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Replies_Likes_Bool_Exp>;
};

export type Subscription_RootPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

export type Subscription_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

export type Subscription_RootPosts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPosts_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Comments_Order_By>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

export type Subscription_RootPosts_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Comments_Order_By>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

export type Subscription_RootPosts_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPosts_Comments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Posts_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

export type Subscription_RootPosts_FilesArgs = {
  distinct_on?: InputMaybe<Array<Posts_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Files_Order_By>>;
  where?: InputMaybe<Posts_Files_Bool_Exp>;
};

export type Subscription_RootPosts_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Files_Order_By>>;
  where?: InputMaybe<Posts_Files_Bool_Exp>;
};

export type Subscription_RootPosts_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPosts_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Posts_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Files_Bool_Exp>;
};

export type Subscription_RootPosts_Poll_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
};

export type Subscription_RootPosts_Poll_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
};

export type Subscription_RootPosts_Poll_Choices_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPosts_Poll_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Posts_Poll_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Poll_Choices_Bool_Exp>;
};

export type Subscription_RootPosts_Poll_Choices_VotesArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Votes_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
};

export type Subscription_RootPosts_Poll_Choices_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Poll_Choices_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Poll_Choices_Votes_Order_By>>;
  where?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
};

export type Subscription_RootPosts_Poll_Choices_Votes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPosts_Poll_Choices_Votes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Posts_Poll_Choices_Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Poll_Choices_Votes_Bool_Exp>;
};

export type Subscription_RootPosts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

export type Subscription_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

export type Subscription_RootProfile_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProfile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

export type Subscription_RootProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootProject_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_ClientArgs = {
  distinct_on?: InputMaybe<Array<Project_Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Client_Order_By>>;
  where?: InputMaybe<Project_Client_Bool_Exp>;
};

export type Subscription_RootProject_Client_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Client_Order_By>>;
  where?: InputMaybe<Project_Client_Bool_Exp>;
};

export type Subscription_RootProject_Client_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Client_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Client_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Client_Bool_Exp>;
};

export type Subscription_RootProject_LinksArgs = {
  distinct_on?: InputMaybe<Array<Project_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Links_Order_By>>;
  where?: InputMaybe<Project_Links_Bool_Exp>;
};

export type Subscription_RootProject_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Links_Order_By>>;
  where?: InputMaybe<Project_Links_Bool_Exp>;
};

export type Subscription_RootProject_Links_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Links_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Links_Bool_Exp>;
};

export type Subscription_RootProject_MembersArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_Order_By>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

export type Subscription_RootProject_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_Order_By>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

export type Subscription_RootProject_Members_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootProject_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

export type Subscription_RootProject_MilestonesArgs = {
  distinct_on?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Milestones_Order_By>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

export type Subscription_RootProject_Milestones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Milestones_Order_By>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

export type Subscription_RootProject_Milestones_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Milestones_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Milestones_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Milestones_Bool_Exp>;
};

export type Subscription_RootProject_Notification_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Project_Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Notification_Settings_Order_By>>;
  where?: InputMaybe<Project_Notification_Settings_Bool_Exp>;
};

export type Subscription_RootProject_Notification_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Notification_Settings_Order_By>>;
  where?: InputMaybe<Project_Notification_Settings_Bool_Exp>;
};

export type Subscription_RootProject_Notification_Settings_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootProject_Notification_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Notification_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Notification_Settings_Bool_Exp>;
};

export type Subscription_RootProject_SectionArgs = {
  distinct_on?: InputMaybe<Array<Project_Section_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Section_Order_By>>;
  where?: InputMaybe<Project_Section_Bool_Exp>;
};

export type Subscription_RootProject_Section_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Section_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Section_Order_By>>;
  where?: InputMaybe<Project_Section_Bool_Exp>;
};

export type Subscription_RootProject_Section_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Section_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Section_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Section_Bool_Exp>;
};

export type Subscription_RootProject_SprintsArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

export type Subscription_RootProject_Sprints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

export type Subscription_RootProject_Sprints_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Sprints_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Sprints_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

export type Subscription_RootProject_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootQuestion_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Options_Order_By>>;
  where?: InputMaybe<Question_Options_Bool_Exp>;
};

export type Subscription_RootQuestion_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Options_Order_By>>;
  where?: InputMaybe<Question_Options_Bool_Exp>;
};

export type Subscription_RootQuestion_Options_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootQuestion_Options_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Question_Options_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Options_Bool_Exp>;
};

export type Subscription_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

export type Subscription_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootQuestions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

export type Subscription_RootSearch_Chat_Message_TextArgs = {
  args: Search_Chat_Message_Text_Args;
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Subscription_RootSearch_Chat_Message_Text_AggregateArgs = {
  args: Search_Chat_Message_Text_Args;
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

export type Subscription_RootSkills_DetailArgs = {
  distinct_on?: InputMaybe<Array<Skills_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skills_Detail_Order_By>>;
  where?: InputMaybe<Skills_Detail_Bool_Exp>;
};

export type Subscription_RootSkills_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skills_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skills_Detail_Order_By>>;
  where?: InputMaybe<Skills_Detail_Bool_Exp>;
};

export type Subscription_RootSkills_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSkills_Detail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Skills_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Skills_Detail_Bool_Exp>;
};

export type Subscription_RootStage_Career_DetailArgs = {
  distinct_on?: InputMaybe<Array<Stage_Career_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stage_Career_Detail_Order_By>>;
  where?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
};

export type Subscription_RootStage_Career_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stage_Career_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stage_Career_Detail_Order_By>>;
  where?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
};

export type Subscription_RootStage_Career_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStage_Career_Detail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stage_Career_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
};

export type Subscription_RootStorage_FilesArgs = {
  distinct_on?: InputMaybe<Array<Storage_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Files_Order_By>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

export type Subscription_RootStorage_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Files_Order_By>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

export type Subscription_RootStorage_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStorage_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Storage_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

export type Subscription_RootStress_DataArgs = {
  distinct_on?: InputMaybe<Array<Stress_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stress_Data_Order_By>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

export type Subscription_RootStress_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stress_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stress_Data_Order_By>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

export type Subscription_RootStress_Data_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStress_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stress_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

export type Subscription_RootSub_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sub_Skills_Order_By>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

export type Subscription_RootSub_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sub_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sub_Skills_Order_By>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

export type Subscription_RootSub_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSub_Skills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sub_Skills_Stream_Cursor_Input>>;
  where?: InputMaybe<Sub_Skills_Bool_Exp>;
};

export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTaskArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type Subscription_RootTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type Subscription_RootTask_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Approvers_Order_By>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

export type Subscription_RootTask_Approvers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Approvers_Order_By>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

export type Subscription_RootTask_Approvers_By_PkArgs = {
  approver_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Subscription_RootTask_Approvers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Approvers_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

export type Subscription_RootTask_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTask_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Checklist_Order_By>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

export type Subscription_RootTask_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Checklist_Order_By>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

export type Subscription_RootTask_Checklist_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTask_Checklist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Checklist_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

export type Subscription_RootTask_CollaboratorArgs = {
  distinct_on?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Collaborator_Order_By>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

export type Subscription_RootTask_Collaborator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Collaborator_Order_By>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

export type Subscription_RootTask_Collaborator_By_PkArgs = {
  task_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootTask_Collaborator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Collaborator_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

export type Subscription_RootTask_CombinedArgs = {
  distinct_on?: InputMaybe<Array<Task_Combined_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Combined_Order_By>>;
  where?: InputMaybe<Task_Combined_Bool_Exp>;
};

export type Subscription_RootTask_Combined_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Combined_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Combined_Order_By>>;
  where?: InputMaybe<Task_Combined_Bool_Exp>;
};

export type Subscription_RootTask_Combined_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Combined_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Combined_Bool_Exp>;
};

export type Subscription_RootTask_CommentArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

export type Subscription_RootTask_Comment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

export type Subscription_RootTask_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTask_Comment_LikeArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Like_Order_By>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

export type Subscription_RootTask_Comment_Like_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Like_Order_By>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

export type Subscription_RootTask_Comment_Like_By_PkArgs = {
  task_comment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootTask_Comment_Like_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Comment_Like_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

export type Subscription_RootTask_Comment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Comment_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

export type Subscription_RootTask_DependenciesArgs = {
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Subscription_RootTask_Dependencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Subscription_RootTask_Dependencies_By_PkArgs = {
  dependent_on_task_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Subscription_RootTask_Dependencies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Dependencies_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

export type Subscription_RootTask_FileArgs = {
  distinct_on?: InputMaybe<Array<Task_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_File_Order_By>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

export type Subscription_RootTask_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_File_Order_By>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

export type Subscription_RootTask_File_By_PkArgs = {
  storage_file_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Subscription_RootTask_File_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

export type Subscription_RootTask_MilestonesArgs = {
  distinct_on?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Milestones_Order_By>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

export type Subscription_RootTask_Milestones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Milestones_Order_By>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

export type Subscription_RootTask_Milestones_By_PkArgs = {
  milestone_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Subscription_RootTask_Milestones_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Milestones_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

export type Subscription_RootTask_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type Subscription_RootTask_TagsArgs = {
  distinct_on?: InputMaybe<Array<Task_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Tags_Order_By>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

export type Subscription_RootTask_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Tags_Order_By>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

export type Subscription_RootTask_Tags_By_PkArgs = {
  tag_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

export type Subscription_RootTask_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

export type Subscription_RootTask_Time_SheetArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

export type Subscription_RootTask_Time_Sheet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

export type Subscription_RootTask_Time_Sheet_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTask_Time_Sheet_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Time_Sheet_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

export type Subscription_RootTask_UnscheduledArgs = {
  distinct_on?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Unscheduled_Order_By>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

export type Subscription_RootTask_Unscheduled_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Unscheduled_Order_By>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

export type Subscription_RootTask_Unscheduled_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTask_Unscheduled_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Unscheduled_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

export type Subscription_RootTime_PeriodArgs = {
  distinct_on?: InputMaybe<Array<Time_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Period_Order_By>>;
  where?: InputMaybe<Time_Period_Bool_Exp>;
};

export type Subscription_RootTime_Period_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Time_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Period_Order_By>>;
  where?: InputMaybe<Time_Period_Bool_Exp>;
};

export type Subscription_RootTime_Period_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTime_Period_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Time_Period_Stream_Cursor_Input>>;
  where?: InputMaybe<Time_Period_Bool_Exp>;
};

export type Subscription_RootTrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

export type Subscription_RootTraining_SkillArgs = {
  distinct_on?: InputMaybe<Array<Training_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Skill_Order_By>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

export type Subscription_RootTraining_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Skill_Order_By>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

export type Subscription_RootTraining_Skill_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTraining_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

export type Subscription_RootTraining_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

export type Subscription_RootTraining_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTraining_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

export type Subscription_RootTraining_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

export type Subscription_RootTraining_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTraining_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

export type Subscription_RootTraining_Progress_RecordArgs = {
  distinct_on?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Progress_Record_Order_By>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

export type Subscription_RootTraining_Progress_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Progress_Record_Order_By>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

export type Subscription_RootTraining_Progress_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTraining_Progress_Record_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Progress_Record_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

export type Subscription_RootTraining_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

export type Subscription_RootTraining_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

export type Subscription_RootTraining_Role_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTraining_Role_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Role_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

export type Subscription_RootTraining_Stage_CareerArgs = {
  distinct_on?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Stage_Career_Order_By>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

export type Subscription_RootTraining_Stage_Career_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Stage_Career_Order_By>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

export type Subscription_RootTraining_Stage_Career_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTraining_Stage_Career_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Stage_Career_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

export type Subscription_RootTraining_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

export type Subscription_RootTraining_Table_Of_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Training_Table_Of_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Table_Of_Contents_Order_By>>;
  where?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
};

export type Subscription_RootTraining_Table_Of_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Table_Of_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Table_Of_Contents_Order_By>>;
  where?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
};

export type Subscription_RootTraining_Table_Of_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTraining_Table_Of_Contents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Table_Of_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
};

export type Subscription_RootTraining_Type_For_TrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Type_For_Training_Order_By>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

export type Subscription_RootTraining_Type_For_Training_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Type_For_Training_Order_By>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

export type Subscription_RootTraining_Type_For_Training_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTraining_Type_For_Training_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Type_For_Training_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

export type Subscription_RootUpdateTaskRankArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUsed_Leave_ViewArgs = {
  distinct_on?: InputMaybe<Array<Used_Leave_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Used_Leave_View_Order_By>>;
  where?: InputMaybe<Used_Leave_View_Bool_Exp>;
};

export type Subscription_RootUsed_Leave_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Used_Leave_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Used_Leave_View_Order_By>>;
  where?: InputMaybe<Used_Leave_View_Bool_Exp>;
};

export type Subscription_RootUsed_Leave_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Used_Leave_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Used_Leave_View_Bool_Exp>;
};

export type Subscription_RootUser_CompetencyArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

export type Subscription_RootUser_Competency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

export type Subscription_RootUser_Competency_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Competency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Competency_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

export type Subscription_RootUser_SkillsArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Order_By>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

export type Subscription_RootUser_Skills_AssessmentArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Assessment_Order_By>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

export type Subscription_RootUser_Skills_Assessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Assessment_Order_By>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

export type Subscription_RootUser_Skills_Assessment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Skills_Assessment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Skills_Assessment_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

export type Subscription_RootUser_Skills_TrainingArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Training_Order_By>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

export type Subscription_RootUser_Skills_Training_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Training_Order_By>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

export type Subscription_RootUser_Skills_Training_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Skills_Training_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Skills_Training_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

export type Subscription_RootUser_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Order_By>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

export type Subscription_RootUser_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Skills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Skills_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

export type Subscription_RootUser_Activity_TrackerArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Tracker_Order_By>>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

export type Subscription_RootUser_Activity_Tracker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Tracker_Order_By>>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

export type Subscription_RootUser_Activity_Tracker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Activity_Tracker_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Activity_Tracker_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

export type Subscription_RootUser_AvatarArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Order_By>>;
  where?: InputMaybe<User_Avatar_Bool_Exp>;
};

export type Subscription_RootUser_Avatar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Order_By>>;
  where?: InputMaybe<User_Avatar_Bool_Exp>;
};

export type Subscription_RootUser_Avatar_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Subscription_RootUser_Avatar_FilesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

export type Subscription_RootUser_Avatar_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

export type Subscription_RootUser_Avatar_Files_By_PkArgs = {
  avatar_id: Scalars['uuid'];
};

export type Subscription_RootUser_Avatar_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Avatar_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

export type Subscription_RootUser_Avatar_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Avatar_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Avatar_Bool_Exp>;
};

export type Subscription_RootUser_Career_Development_RecordArgs = {
  distinct_on?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_Development_Record_Order_By>>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

export type Subscription_RootUser_Career_Development_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_Development_Record_Order_By>>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

export type Subscription_RootUser_Career_Development_Record_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Career_Development_Record_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Career_Development_Record_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

export type Subscription_RootUser_Career_External_CertificationArgs = {
  distinct_on?: InputMaybe<
    Array<User_Career_External_Certification_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

export type Subscription_RootUser_Career_External_Certification_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<User_Career_External_Certification_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
    where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
  };

export type Subscription_RootUser_Career_External_Certification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Career_External_Certification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<User_Career_External_Certification_Stream_Cursor_Input>
  >;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

export type Subscription_RootUser_PresenceArgs = {
  distinct_on?: InputMaybe<Array<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

export type Subscription_RootUser_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

export type Subscription_RootUser_Presence_By_PkArgs = {
  connection_id: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootUser_Presence_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Presence_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

export type Subscription_RootUser_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

export type Subscription_RootUser_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

export type Subscription_RootUser_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

export type Subscription_RootUser_StateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

export type Subscription_RootUser_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Order_By>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

export type Subscription_RootUser_State_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Subscription_RootUser_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_State_Stream_Cursor_Input>>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type TableContents = {
  endTime?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  showContent?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Tags that can be added to a task */
export type Tags = {
  __typename?: 'tags';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  organisation_id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  /** An array relationship */
  task_tags: Array<Task_Tags>;
  /** An aggregate relationship */
  task_tags_aggregate: Task_Tags_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** Tags that can be added to a task */
export type TagsTask_TagsArgs = {
  distinct_on?: InputMaybe<Array<Task_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Tags_Order_By>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

/** Tags that can be added to a task */
export type TagsTask_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Tags_Order_By>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

export type Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tags_Aggregate_Bool_Exp_Count>;
};

export type Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tags" */
export type Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tags_Max_Order_By>;
  min?: InputMaybe<Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tags" */
export type Tags_Arr_Rel_Insert_Input = {
  data: Array<Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  task_tags?: InputMaybe<Task_Tags_Bool_Exp>;
  task_tags_aggregate?: InputMaybe<Task_Tags_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "name", "organisation_id" */
  TagsOrganisationIdNameKey = 'tags_organisation_id_name_key',
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey',
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  task_tags?: InputMaybe<Task_Tags_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tags" */
export type Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tags" */
export type Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  task_tags_aggregate?: InputMaybe<Task_Tags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tags_Bool_Exp;
};

/** Tasks of a project */
export type Task = {
  __typename?: 'task';
  assigned_at: Scalars['timestamptz'];
  assigned_by?: Maybe<Scalars['uuid']>;
  assigned_to?: Maybe<Scalars['uuid']>;
  category?: Maybe<Enum_Project_Task_Category_Enum>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  due_date_time?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  employee_bandwidth_data: Array<Employee_Bandwidth_Data>;
  /** An aggregate relationship */
  employee_bandwidth_data_aggregate: Employee_Bandwidth_Data_Aggregate;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_project_task_category?: Maybe<Enum_Project_Task_Category>;
  /** An object relationship */
  enum_task_priority?: Maybe<Enum_Task_Priority>;
  /** An object relationship */
  enum_task_recurring_frequency?: Maybe<Enum_Task_Recurring_Frequency>;
  /** An object relationship */
  enum_task_status?: Maybe<Enum_Task_Status>;
  estimated_time?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  is_draft: Scalars['Boolean'];
  is_recurring: Scalars['Boolean'];
  next_recurred_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  parent_recurred_task?: Maybe<Task>;
  /** An object relationship */
  parent_task?: Maybe<Task>;
  priority?: Maybe<Enum_Task_Priority_Enum>;
  /** An object relationship */
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project_section?: Maybe<Project_Section>;
  /** An object relationship */
  project_sprint?: Maybe<Project_Sprints>;
  rank?: Maybe<Scalars['float8']>;
  recurred_from?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  recurred_tasks: Array<Task>;
  /** An aggregate relationship */
  recurred_tasks_aggregate: Task_Aggregate;
  recurring_frequency?: Maybe<Enum_Task_Recurring_Frequency_Enum>;
  section_id?: Maybe<Scalars['uuid']>;
  sprint_id?: Maybe<Scalars['uuid']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Enum_Task_Status_Enum>;
  /** An array relationship */
  sub_tasks: Array<Task>;
  /** An aggregate relationship */
  sub_tasks_aggregate: Task_Aggregate;
  subtask_of?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  taskDependenciesByTaskId: Array<Task_Dependencies>;
  /** An aggregate relationship */
  taskDependenciesByTaskId_aggregate: Task_Dependencies_Aggregate;
  /** An array relationship */
  task_approvers: Array<Task_Approvers>;
  /** An aggregate relationship */
  task_approvers_aggregate: Task_Approvers_Aggregate;
  /** An array relationship */
  task_checklists: Array<Task_Checklist>;
  /** An aggregate relationship */
  task_checklists_aggregate: Task_Checklist_Aggregate;
  /** An array relationship */
  task_collaborators: Array<Task_Collaborator>;
  /** An aggregate relationship */
  task_collaborators_aggregate: Task_Collaborator_Aggregate;
  /** An array relationship */
  task_comments: Array<Task_Comment>;
  /** An aggregate relationship */
  task_comments_aggregate: Task_Comment_Aggregate;
  /** An array relationship */
  task_dependencies: Array<Task_Dependencies>;
  /** An aggregate relationship */
  task_dependencies_aggregate: Task_Dependencies_Aggregate;
  /** An array relationship */
  task_files: Array<Task_File>;
  /** An aggregate relationship */
  task_files_aggregate: Task_File_Aggregate;
  /** An array relationship */
  task_milestones: Array<Task_Milestones>;
  /** An aggregate relationship */
  task_milestones_aggregate: Task_Milestones_Aggregate;
  /** An array relationship */
  task_tags: Array<Task_Tags>;
  /** An aggregate relationship */
  task_tags_aggregate: Task_Tags_Aggregate;
  /** An array relationship */
  task_time_sheets: Array<Task_Time_Sheet>;
  /** An aggregate relationship */
  task_time_sheets_aggregate: Task_Time_Sheet_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByAssignedBy?: Maybe<Users>;
  /** An object relationship */
  userByCreatedBy: Users;
  /** An object relationship */
  userByUpdatedBy?: Maybe<Users>;
};

/** Tasks of a project */
export type TaskEmployee_Bandwidth_DataArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

/** Tasks of a project */
export type TaskEmployee_Bandwidth_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

/** Tasks of a project */
export type TaskRecurred_TasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Tasks of a project */
export type TaskRecurred_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Tasks of a project */
export type TaskSub_TasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Tasks of a project */
export type TaskSub_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTaskDependenciesByTaskIdArgs = {
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTaskDependenciesByTaskId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Approvers_Order_By>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Approvers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Approvers_Order_By>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_ChecklistsArgs = {
  distinct_on?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Checklist_Order_By>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Checklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Checklist_Order_By>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_CollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Collaborator_Order_By>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Collaborator_Order_By>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_DependenciesArgs = {
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Dependencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Dependencies_Order_By>>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_FilesArgs = {
  distinct_on?: InputMaybe<Array<Task_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_File_Order_By>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_File_Order_By>>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_MilestonesArgs = {
  distinct_on?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Milestones_Order_By>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Milestones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Milestones_Order_By>>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_TagsArgs = {
  distinct_on?: InputMaybe<Array<Task_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Tags_Order_By>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Tags_Order_By>>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Time_SheetsArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** Tasks of a project */
export type TaskTask_Time_Sheets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

export type TaskDetails = {
  __typename?: 'taskDetails';
  due_date_time?: Maybe<Scalars['String']>;
  end_date_time?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TaskInfo = {
  __typename?: 'taskInfo';
  title?: Maybe<Scalars['String']>;
};

export type TaskProductivityData = {
  __typename?: 'taskProductivityData';
  name: Scalars['String'];
  profile: Scalars['String'];
  task?: Maybe<Array<Maybe<TaskDetails>>>;
  timeDelayed: Scalars['Int'];
  timelyCompleted: Scalars['Int'];
  totalTask: Scalars['Int'];
};

/** aggregated selection of "task" */
export type Task_Aggregate = {
  __typename?: 'task_aggregate';
  aggregate?: Maybe<Task_Aggregate_Fields>;
  nodes: Array<Task>;
};

export type Task_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Task_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Task_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Task_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Task_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Task_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Task_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Task_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Task_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Task_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Task_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Task_Aggregate_Bool_Exp_Var_Samp>;
};

export type Task_Aggregate_Bool_Exp_Avg = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Bool_And = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Corr = {
  arguments: Task_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Task_Select_Column_Task_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Task_Select_Column_Task_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Task_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Task_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Task_Select_Column_Task_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Task_Select_Column_Task_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Task_Aggregate_Bool_Exp_Max = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Min = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Sum = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "task" */
export type Task_Aggregate_Fields = {
  __typename?: 'task_aggregate_fields';
  avg?: Maybe<Task_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Task_Max_Fields>;
  min?: Maybe<Task_Min_Fields>;
  stddev?: Maybe<Task_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Sum_Fields>;
  var_pop?: Maybe<Task_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Var_Samp_Fields>;
  variance?: Maybe<Task_Variance_Fields>;
};

/** aggregate fields of "task" */
export type Task_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task" */
export type Task_Aggregate_Order_By = {
  avg?: InputMaybe<Task_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Max_Order_By>;
  min?: InputMaybe<Task_Min_Order_By>;
  stddev?: InputMaybe<Task_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Task_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Task_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Task_Sum_Order_By>;
  var_pop?: InputMaybe<Task_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Task_Var_Samp_Order_By>;
  variance?: InputMaybe<Task_Variance_Order_By>;
};

/** Approvers for task checklist */
export type Task_Approvers = {
  __typename?: 'task_approvers';
  approver_id: Scalars['uuid'];
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "task_approvers" */
export type Task_Approvers_Aggregate = {
  __typename?: 'task_approvers_aggregate';
  aggregate?: Maybe<Task_Approvers_Aggregate_Fields>;
  nodes: Array<Task_Approvers>;
};

export type Task_Approvers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Approvers_Aggregate_Bool_Exp_Count>;
};

export type Task_Approvers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Approvers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_approvers" */
export type Task_Approvers_Aggregate_Fields = {
  __typename?: 'task_approvers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Approvers_Max_Fields>;
  min?: Maybe<Task_Approvers_Min_Fields>;
};

/** aggregate fields of "task_approvers" */
export type Task_Approvers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_approvers" */
export type Task_Approvers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Approvers_Max_Order_By>;
  min?: InputMaybe<Task_Approvers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_approvers" */
export type Task_Approvers_Arr_Rel_Insert_Input = {
  data: Array<Task_Approvers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Approvers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_approvers". All fields are combined with a logical 'AND'. */
export type Task_Approvers_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Approvers_Bool_Exp>>;
  _not?: InputMaybe<Task_Approvers_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Approvers_Bool_Exp>>;
  approver_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "task_approvers" */
export enum Task_Approvers_Constraint {
  /** unique or primary key constraint on columns "approver_id", "task_id" */
  TaskApproversPkey = 'task_approvers_pkey',
}

/** input type for inserting data into table "task_approvers" */
export type Task_Approvers_Insert_Input = {
  approver_id?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Task_Approvers_Max_Fields = {
  __typename?: 'task_approvers_max_fields';
  approver_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_approvers" */
export type Task_Approvers_Max_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Approvers_Min_Fields = {
  __typename?: 'task_approvers_min_fields';
  approver_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_approvers" */
export type Task_Approvers_Min_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_approvers" */
export type Task_Approvers_Mutation_Response = {
  __typename?: 'task_approvers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Approvers>;
};

/** on_conflict condition type for table "task_approvers" */
export type Task_Approvers_On_Conflict = {
  constraint: Task_Approvers_Constraint;
  update_columns?: Array<Task_Approvers_Update_Column>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

/** Ordering options when selecting data from "task_approvers". */
export type Task_Approvers_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: task_approvers */
export type Task_Approvers_Pk_Columns_Input = {
  approver_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "task_approvers" */
export enum Task_Approvers_Select_Column {
  /** column name */
  ApproverId = 'approver_id',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "task_approvers" */
export type Task_Approvers_Set_Input = {
  approver_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_approvers" */
export type Task_Approvers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Approvers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Approvers_Stream_Cursor_Value_Input = {
  approver_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "task_approvers" */
export enum Task_Approvers_Update_Column {
  /** column name */
  ApproverId = 'approver_id',
  /** column name */
  TaskId = 'task_id',
}

export type Task_Approvers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Approvers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Approvers_Bool_Exp;
};

/** input type for inserting array relation for remote table "task" */
export type Task_Arr_Rel_Insert_Input = {
  data: Array<Task_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** aggregate avg on columns */
export type Task_Avg_Fields = {
  __typename?: 'task_avg_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "task" */
export type Task_Avg_Order_By = {
  estimated_time?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
export type Task_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Bool_Exp>>;
  _not?: InputMaybe<Task_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Bool_Exp>>;
  assigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  assigned_by?: InputMaybe<Uuid_Comparison_Exp>;
  assigned_to?: InputMaybe<Uuid_Comparison_Exp>;
  category?: InputMaybe<Enum_Project_Task_Category_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  due_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee_bandwidth_data?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  employee_bandwidth_data_aggregate?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp>;
  end_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_project_task_category?: InputMaybe<Enum_Project_Task_Category_Bool_Exp>;
  enum_task_priority?: InputMaybe<Enum_Task_Priority_Bool_Exp>;
  enum_task_recurring_frequency?: InputMaybe<Enum_Task_Recurring_Frequency_Bool_Exp>;
  enum_task_status?: InputMaybe<Enum_Task_Status_Bool_Exp>;
  estimated_time?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_draft?: InputMaybe<Boolean_Comparison_Exp>;
  is_recurring?: InputMaybe<Boolean_Comparison_Exp>;
  next_recurred_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_recurred_task?: InputMaybe<Task_Bool_Exp>;
  parent_task?: InputMaybe<Task_Bool_Exp>;
  priority?: InputMaybe<Enum_Task_Priority_Enum_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_section?: InputMaybe<Project_Section_Bool_Exp>;
  project_sprint?: InputMaybe<Project_Sprints_Bool_Exp>;
  rank?: InputMaybe<Float8_Comparison_Exp>;
  recurred_from?: InputMaybe<Uuid_Comparison_Exp>;
  recurred_tasks?: InputMaybe<Task_Bool_Exp>;
  recurred_tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  recurring_frequency?: InputMaybe<Enum_Task_Recurring_Frequency_Enum_Comparison_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
  sprint_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Enum_Task_Status_Enum_Comparison_Exp>;
  sub_tasks?: InputMaybe<Task_Bool_Exp>;
  sub_tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  subtask_of?: InputMaybe<Uuid_Comparison_Exp>;
  taskDependenciesByTaskId?: InputMaybe<Task_Dependencies_Bool_Exp>;
  taskDependenciesByTaskId_aggregate?: InputMaybe<Task_Dependencies_Aggregate_Bool_Exp>;
  task_approvers?: InputMaybe<Task_Approvers_Bool_Exp>;
  task_approvers_aggregate?: InputMaybe<Task_Approvers_Aggregate_Bool_Exp>;
  task_checklists?: InputMaybe<Task_Checklist_Bool_Exp>;
  task_checklists_aggregate?: InputMaybe<Task_Checklist_Aggregate_Bool_Exp>;
  task_collaborators?: InputMaybe<Task_Collaborator_Bool_Exp>;
  task_collaborators_aggregate?: InputMaybe<Task_Collaborator_Aggregate_Bool_Exp>;
  task_comments?: InputMaybe<Task_Comment_Bool_Exp>;
  task_comments_aggregate?: InputMaybe<Task_Comment_Aggregate_Bool_Exp>;
  task_dependencies?: InputMaybe<Task_Dependencies_Bool_Exp>;
  task_dependencies_aggregate?: InputMaybe<Task_Dependencies_Aggregate_Bool_Exp>;
  task_files?: InputMaybe<Task_File_Bool_Exp>;
  task_files_aggregate?: InputMaybe<Task_File_Aggregate_Bool_Exp>;
  task_milestones?: InputMaybe<Task_Milestones_Bool_Exp>;
  task_milestones_aggregate?: InputMaybe<Task_Milestones_Aggregate_Bool_Exp>;
  task_tags?: InputMaybe<Task_Tags_Bool_Exp>;
  task_tags_aggregate?: InputMaybe<Task_Tags_Aggregate_Bool_Exp>;
  task_time_sheets?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
  task_time_sheets_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByAssignedBy?: InputMaybe<Users_Bool_Exp>;
  userByCreatedBy?: InputMaybe<Users_Bool_Exp>;
  userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "task_checklist" */
export type Task_Checklist = {
  __typename?: 'task_checklist';
  approved_by?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_approved: Scalars['Boolean'];
  item_name: Scalars['String'];
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "task_checklist" */
export type Task_Checklist_Aggregate = {
  __typename?: 'task_checklist_aggregate';
  aggregate?: Maybe<Task_Checklist_Aggregate_Fields>;
  nodes: Array<Task_Checklist>;
};

export type Task_Checklist_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Task_Checklist_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Task_Checklist_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Task_Checklist_Aggregate_Bool_Exp_Count>;
};

export type Task_Checklist_Aggregate_Bool_Exp_Bool_And = {
  arguments: Task_Checklist_Select_Column_Task_Checklist_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Checklist_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Checklist_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Task_Checklist_Select_Column_Task_Checklist_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Checklist_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Checklist_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Checklist_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_checklist" */
export type Task_Checklist_Aggregate_Fields = {
  __typename?: 'task_checklist_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Checklist_Max_Fields>;
  min?: Maybe<Task_Checklist_Min_Fields>;
};

/** aggregate fields of "task_checklist" */
export type Task_Checklist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_checklist" */
export type Task_Checklist_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Checklist_Max_Order_By>;
  min?: InputMaybe<Task_Checklist_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_checklist" */
export type Task_Checklist_Arr_Rel_Insert_Input = {
  data: Array<Task_Checklist_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Checklist_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_checklist". All fields are combined with a logical 'AND'. */
export type Task_Checklist_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Checklist_Bool_Exp>>;
  _not?: InputMaybe<Task_Checklist_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Checklist_Bool_Exp>>;
  approved_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_approved?: InputMaybe<Boolean_Comparison_Exp>;
  item_name?: InputMaybe<String_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "task_checklist" */
export enum Task_Checklist_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskChecklistPkey = 'task_checklist_pkey',
}

/** input type for inserting data into table "task_checklist" */
export type Task_Checklist_Insert_Input = {
  approved_by?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  item_name?: InputMaybe<Scalars['String']>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Task_Checklist_Max_Fields = {
  __typename?: 'task_checklist_max_fields';
  approved_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_name?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "task_checklist" */
export type Task_Checklist_Max_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_name?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Checklist_Min_Fields = {
  __typename?: 'task_checklist_min_fields';
  approved_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_name?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "task_checklist" */
export type Task_Checklist_Min_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_name?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_checklist" */
export type Task_Checklist_Mutation_Response = {
  __typename?: 'task_checklist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Checklist>;
};

/** on_conflict condition type for table "task_checklist" */
export type Task_Checklist_On_Conflict = {
  constraint: Task_Checklist_Constraint;
  update_columns?: Array<Task_Checklist_Update_Column>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

/** Ordering options when selecting data from "task_checklist". */
export type Task_Checklist_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_approved?: InputMaybe<Order_By>;
  item_name?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: task_checklist */
export type Task_Checklist_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task_checklist" */
export enum Task_Checklist_Select_Column {
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  ItemName = 'item_name',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "task_checklist_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task_checklist" */
export enum Task_Checklist_Select_Column_Task_Checklist_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsApproved = 'is_approved',
}

/** select "task_checklist_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task_checklist" */
export enum Task_Checklist_Select_Column_Task_Checklist_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsApproved = 'is_approved',
}

/** input type for updating data in table "task_checklist" */
export type Task_Checklist_Set_Input = {
  approved_by?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  item_name?: InputMaybe<Scalars['String']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "task_checklist" */
export type Task_Checklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Checklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Checklist_Stream_Cursor_Value_Input = {
  approved_by?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  item_name?: InputMaybe<Scalars['String']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "task_checklist" */
export enum Task_Checklist_Update_Column {
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  ItemName = 'item_name',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Task_Checklist_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Checklist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Checklist_Bool_Exp;
};

/** Users involved in a task */
export type Task_Collaborator = {
  __typename?: 'task_collaborator';
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "task_collaborator" */
export type Task_Collaborator_Aggregate = {
  __typename?: 'task_collaborator_aggregate';
  aggregate?: Maybe<Task_Collaborator_Aggregate_Fields>;
  nodes: Array<Task_Collaborator>;
};

export type Task_Collaborator_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Collaborator_Aggregate_Bool_Exp_Count>;
};

export type Task_Collaborator_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Collaborator_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_collaborator" */
export type Task_Collaborator_Aggregate_Fields = {
  __typename?: 'task_collaborator_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Collaborator_Max_Fields>;
  min?: Maybe<Task_Collaborator_Min_Fields>;
};

/** aggregate fields of "task_collaborator" */
export type Task_Collaborator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_collaborator" */
export type Task_Collaborator_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Collaborator_Max_Order_By>;
  min?: InputMaybe<Task_Collaborator_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_collaborator" */
export type Task_Collaborator_Arr_Rel_Insert_Input = {
  data: Array<Task_Collaborator_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Collaborator_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_collaborator". All fields are combined with a logical 'AND'. */
export type Task_Collaborator_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Collaborator_Bool_Exp>>;
  _not?: InputMaybe<Task_Collaborator_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Collaborator_Bool_Exp>>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_collaborator" */
export enum Task_Collaborator_Constraint {
  /** unique or primary key constraint on columns "user_id", "task_id" */
  TaskCollaboratorsPkey = 'task_collaborators_pkey',
}

/** input type for inserting data into table "task_collaborator" */
export type Task_Collaborator_Insert_Input = {
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Collaborator_Max_Fields = {
  __typename?: 'task_collaborator_max_fields';
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_collaborator" */
export type Task_Collaborator_Max_Order_By = {
  task_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Collaborator_Min_Fields = {
  __typename?: 'task_collaborator_min_fields';
  task_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_collaborator" */
export type Task_Collaborator_Min_Order_By = {
  task_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_collaborator" */
export type Task_Collaborator_Mutation_Response = {
  __typename?: 'task_collaborator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Collaborator>;
};

/** on_conflict condition type for table "task_collaborator" */
export type Task_Collaborator_On_Conflict = {
  constraint: Task_Collaborator_Constraint;
  update_columns?: Array<Task_Collaborator_Update_Column>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

/** Ordering options when selecting data from "task_collaborator". */
export type Task_Collaborator_Order_By = {
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_collaborator */
export type Task_Collaborator_Pk_Columns_Input = {
  task_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "task_collaborator" */
export enum Task_Collaborator_Select_Column {
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "task_collaborator" */
export type Task_Collaborator_Set_Input = {
  task_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_collaborator" */
export type Task_Collaborator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Collaborator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Collaborator_Stream_Cursor_Value_Input = {
  task_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "task_collaborator" */
export enum Task_Collaborator_Update_Column {
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UserId = 'user_id',
}

export type Task_Collaborator_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Collaborator_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Collaborator_Bool_Exp;
};

/** columns and relationships of "task_combined" */
export type Task_Combined = {
  __typename?: 'task_combined';
  id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['uuid']>;
  task_type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "task_combined" */
export type Task_Combined_Aggregate = {
  __typename?: 'task_combined_aggregate';
  aggregate?: Maybe<Task_Combined_Aggregate_Fields>;
  nodes: Array<Task_Combined>;
};

/** aggregate fields of "task_combined" */
export type Task_Combined_Aggregate_Fields = {
  __typename?: 'task_combined_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Combined_Max_Fields>;
  min?: Maybe<Task_Combined_Min_Fields>;
};

/** aggregate fields of "task_combined" */
export type Task_Combined_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Combined_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_combined". All fields are combined with a logical 'AND'. */
export type Task_Combined_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Combined_Bool_Exp>>;
  _not?: InputMaybe<Task_Combined_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Combined_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  task_type?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Task_Combined_Max_Fields = {
  __typename?: 'task_combined_max_fields';
  id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Task_Combined_Min_Fields = {
  __typename?: 'task_combined_min_fields';
  id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "task_combined". */
export type Task_Combined_Order_By = {
  id?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** select columns of table "task_combined" */
export enum Task_Combined_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Title = 'title',
}

/** Streaming cursor of the table "task_combined" */
export type Task_Combined_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Combined_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Combined_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  task_type?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** User comments on a task */
export type Task_Comment = {
  __typename?: 'task_comment';
  activity_type?: Maybe<Enum_Activity_Type_Enum>;
  comment_text: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  enum_activity_type?: Maybe<Enum_Activity_Type>;
  id: Scalars['uuid'];
  /** An object relationship */
  task: Task;
  /** An array relationship */
  task_comment_likes: Array<Task_Comment_Like>;
  /** An aggregate relationship */
  task_comment_likes_aggregate: Task_Comment_Like_Aggregate;
  task_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** User comments on a task */
export type Task_CommentTask_Comment_LikesArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Like_Order_By>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

/** User comments on a task */
export type Task_CommentTask_Comment_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Like_Order_By>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

/** aggregated selection of "task_comment" */
export type Task_Comment_Aggregate = {
  __typename?: 'task_comment_aggregate';
  aggregate?: Maybe<Task_Comment_Aggregate_Fields>;
  nodes: Array<Task_Comment>;
};

export type Task_Comment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Comment_Aggregate_Bool_Exp_Count>;
};

export type Task_Comment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Comment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Comment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_comment" */
export type Task_Comment_Aggregate_Fields = {
  __typename?: 'task_comment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Comment_Max_Fields>;
  min?: Maybe<Task_Comment_Min_Fields>;
};

/** aggregate fields of "task_comment" */
export type Task_Comment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Comment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_comment" */
export type Task_Comment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Comment_Max_Order_By>;
  min?: InputMaybe<Task_Comment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_comment" */
export type Task_Comment_Arr_Rel_Insert_Input = {
  data: Array<Task_Comment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Comment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_comment". All fields are combined with a logical 'AND'. */
export type Task_Comment_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Comment_Bool_Exp>>;
  _not?: InputMaybe<Task_Comment_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Comment_Bool_Exp>>;
  activity_type?: InputMaybe<Enum_Activity_Type_Enum_Comparison_Exp>;
  comment_text?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  enum_activity_type?: InputMaybe<Enum_Activity_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_comment_likes?: InputMaybe<Task_Comment_Like_Bool_Exp>;
  task_comment_likes_aggregate?: InputMaybe<Task_Comment_Like_Aggregate_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "task_comment" */
export enum Task_Comment_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskCommentPkey = 'task_comment_pkey',
}

/** input type for inserting data into table "task_comment" */
export type Task_Comment_Insert_Input = {
  activity_type?: InputMaybe<Enum_Activity_Type_Enum>;
  comment_text?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  enum_activity_type?: InputMaybe<Enum_Activity_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_comment_likes?: InputMaybe<Task_Comment_Like_Arr_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** columns and relationships of "task_comment_like" */
export type Task_Comment_Like = {
  __typename?: 'task_comment_like';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  task_comment: Task_Comment;
  task_comment_id: Scalars['uuid'];
  update_at: Scalars['timestamp'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "task_comment_like" */
export type Task_Comment_Like_Aggregate = {
  __typename?: 'task_comment_like_aggregate';
  aggregate?: Maybe<Task_Comment_Like_Aggregate_Fields>;
  nodes: Array<Task_Comment_Like>;
};

export type Task_Comment_Like_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Comment_Like_Aggregate_Bool_Exp_Count>;
};

export type Task_Comment_Like_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Comment_Like_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_comment_like" */
export type Task_Comment_Like_Aggregate_Fields = {
  __typename?: 'task_comment_like_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Comment_Like_Max_Fields>;
  min?: Maybe<Task_Comment_Like_Min_Fields>;
};

/** aggregate fields of "task_comment_like" */
export type Task_Comment_Like_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_comment_like" */
export type Task_Comment_Like_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Comment_Like_Max_Order_By>;
  min?: InputMaybe<Task_Comment_Like_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_comment_like" */
export type Task_Comment_Like_Arr_Rel_Insert_Input = {
  data: Array<Task_Comment_Like_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Comment_Like_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_comment_like". All fields are combined with a logical 'AND'. */
export type Task_Comment_Like_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Comment_Like_Bool_Exp>>;
  _not?: InputMaybe<Task_Comment_Like_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Comment_Like_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  task_comment?: InputMaybe<Task_Comment_Bool_Exp>;
  task_comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  update_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_comment_like" */
export enum Task_Comment_Like_Constraint {
  /** unique or primary key constraint on columns "user_id", "task_comment_id" */
  TaskCommentLikePkey = 'task_comment_like_pkey',
}

/** input type for inserting data into table "task_comment_like" */
export type Task_Comment_Like_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  task_comment?: InputMaybe<Task_Comment_Obj_Rel_Insert_Input>;
  task_comment_id?: InputMaybe<Scalars['uuid']>;
  update_at?: InputMaybe<Scalars['timestamp']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Comment_Like_Max_Fields = {
  __typename?: 'task_comment_like_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  task_comment_id?: Maybe<Scalars['uuid']>;
  update_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_comment_like" */
export type Task_Comment_Like_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  task_comment_id?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Comment_Like_Min_Fields = {
  __typename?: 'task_comment_like_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  task_comment_id?: Maybe<Scalars['uuid']>;
  update_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_comment_like" */
export type Task_Comment_Like_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  task_comment_id?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_comment_like" */
export type Task_Comment_Like_Mutation_Response = {
  __typename?: 'task_comment_like_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Comment_Like>;
};

/** on_conflict condition type for table "task_comment_like" */
export type Task_Comment_Like_On_Conflict = {
  constraint: Task_Comment_Like_Constraint;
  update_columns?: Array<Task_Comment_Like_Update_Column>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

/** Ordering options when selecting data from "task_comment_like". */
export type Task_Comment_Like_Order_By = {
  created_at?: InputMaybe<Order_By>;
  task_comment?: InputMaybe<Task_Comment_Order_By>;
  task_comment_id?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_comment_like */
export type Task_Comment_Like_Pk_Columns_Input = {
  task_comment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "task_comment_like" */
export enum Task_Comment_Like_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TaskCommentId = 'task_comment_id',
  /** column name */
  UpdateAt = 'update_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "task_comment_like" */
export type Task_Comment_Like_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  task_comment_id?: InputMaybe<Scalars['uuid']>;
  update_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_comment_like" */
export type Task_Comment_Like_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Comment_Like_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Comment_Like_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  task_comment_id?: InputMaybe<Scalars['uuid']>;
  update_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "task_comment_like" */
export enum Task_Comment_Like_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TaskCommentId = 'task_comment_id',
  /** column name */
  UpdateAt = 'update_at',
  /** column name */
  UserId = 'user_id',
}

export type Task_Comment_Like_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Comment_Like_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Comment_Like_Bool_Exp;
};

/** aggregate max on columns */
export type Task_Comment_Max_Fields = {
  __typename?: 'task_comment_max_fields';
  comment_text?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "task_comment" */
export type Task_Comment_Max_Order_By = {
  comment_text?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Comment_Min_Fields = {
  __typename?: 'task_comment_min_fields';
  comment_text?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "task_comment" */
export type Task_Comment_Min_Order_By = {
  comment_text?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_comment" */
export type Task_Comment_Mutation_Response = {
  __typename?: 'task_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Comment>;
};

/** input type for inserting object relation for remote table "task_comment" */
export type Task_Comment_Obj_Rel_Insert_Input = {
  data: Task_Comment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Comment_On_Conflict>;
};

/** on_conflict condition type for table "task_comment" */
export type Task_Comment_On_Conflict = {
  constraint: Task_Comment_Constraint;
  update_columns?: Array<Task_Comment_Update_Column>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

/** Ordering options when selecting data from "task_comment". */
export type Task_Comment_Order_By = {
  activity_type?: InputMaybe<Order_By>;
  comment_text?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enum_activity_type?: InputMaybe<Enum_Activity_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_comment_likes_aggregate?: InputMaybe<Task_Comment_Like_Aggregate_Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: task_comment */
export type Task_Comment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task_comment" */
export enum Task_Comment_Select_Column {
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  CommentText = 'comment_text',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "task_comment" */
export type Task_Comment_Set_Input = {
  activity_type?: InputMaybe<Enum_Activity_Type_Enum>;
  comment_text?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "task_comment" */
export type Task_Comment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Comment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Comment_Stream_Cursor_Value_Input = {
  activity_type?: InputMaybe<Enum_Activity_Type_Enum>;
  comment_text?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "task_comment" */
export enum Task_Comment_Update_Column {
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  CommentText = 'comment_text',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Task_Comment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Comment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Comment_Bool_Exp;
};

/** unique or primary key constraints on table "task" */
export enum Task_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'task_pkey',
}

/** his table stores task dependencies between tasks in the timeline view, where tasks can depend on other tasks. */
export type Task_Dependencies = {
  __typename?: 'task_dependencies';
  dependent_on_task_id: Scalars['uuid'];
  /** An object relationship */
  task: Task;
  /** An object relationship */
  taskByTaskId: Task;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "task_dependencies" */
export type Task_Dependencies_Aggregate = {
  __typename?: 'task_dependencies_aggregate';
  aggregate?: Maybe<Task_Dependencies_Aggregate_Fields>;
  nodes: Array<Task_Dependencies>;
};

export type Task_Dependencies_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Dependencies_Aggregate_Bool_Exp_Count>;
};

export type Task_Dependencies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Dependencies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_dependencies" */
export type Task_Dependencies_Aggregate_Fields = {
  __typename?: 'task_dependencies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Dependencies_Max_Fields>;
  min?: Maybe<Task_Dependencies_Min_Fields>;
};

/** aggregate fields of "task_dependencies" */
export type Task_Dependencies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Dependencies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_dependencies" */
export type Task_Dependencies_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Dependencies_Max_Order_By>;
  min?: InputMaybe<Task_Dependencies_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_dependencies" */
export type Task_Dependencies_Arr_Rel_Insert_Input = {
  data: Array<Task_Dependencies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Dependencies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_dependencies". All fields are combined with a logical 'AND'. */
export type Task_Dependencies_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Dependencies_Bool_Exp>>;
  _not?: InputMaybe<Task_Dependencies_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Dependencies_Bool_Exp>>;
  dependent_on_task_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  taskByTaskId?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_dependencies" */
export enum Task_Dependencies_Constraint {
  /** unique or primary key constraint on columns "dependent_on_task_id", "task_id" */
  TaskDependenciesPkey = 'task_dependencies_pkey',
}

/** input type for inserting data into table "task_dependencies" */
export type Task_Dependencies_Insert_Input = {
  dependent_on_task_id?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  taskByTaskId?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Dependencies_Max_Fields = {
  __typename?: 'task_dependencies_max_fields';
  dependent_on_task_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_dependencies" */
export type Task_Dependencies_Max_Order_By = {
  dependent_on_task_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Dependencies_Min_Fields = {
  __typename?: 'task_dependencies_min_fields';
  dependent_on_task_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_dependencies" */
export type Task_Dependencies_Min_Order_By = {
  dependent_on_task_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_dependencies" */
export type Task_Dependencies_Mutation_Response = {
  __typename?: 'task_dependencies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Dependencies>;
};

/** on_conflict condition type for table "task_dependencies" */
export type Task_Dependencies_On_Conflict = {
  constraint: Task_Dependencies_Constraint;
  update_columns?: Array<Task_Dependencies_Update_Column>;
  where?: InputMaybe<Task_Dependencies_Bool_Exp>;
};

/** Ordering options when selecting data from "task_dependencies". */
export type Task_Dependencies_Order_By = {
  dependent_on_task_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  taskByTaskId?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_dependencies */
export type Task_Dependencies_Pk_Columns_Input = {
  dependent_on_task_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "task_dependencies" */
export enum Task_Dependencies_Select_Column {
  /** column name */
  DependentOnTaskId = 'dependent_on_task_id',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "task_dependencies" */
export type Task_Dependencies_Set_Input = {
  dependent_on_task_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_dependencies" */
export type Task_Dependencies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Dependencies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Dependencies_Stream_Cursor_Value_Input = {
  dependent_on_task_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "task_dependencies" */
export enum Task_Dependencies_Update_Column {
  /** column name */
  DependentOnTaskId = 'dependent_on_task_id',
  /** column name */
  TaskId = 'task_id',
}

export type Task_Dependencies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Dependencies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Dependencies_Bool_Exp;
};

/** Files attached to a task */
export type Task_File = {
  __typename?: 'task_file';
  /** An object relationship */
  storage_file: Storage_Files;
  storage_file_id: Scalars['uuid'];
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "task_file" */
export type Task_File_Aggregate = {
  __typename?: 'task_file_aggregate';
  aggregate?: Maybe<Task_File_Aggregate_Fields>;
  nodes: Array<Task_File>;
};

export type Task_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_File_Aggregate_Bool_Exp_Count>;
};

export type Task_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_file" */
export type Task_File_Aggregate_Fields = {
  __typename?: 'task_file_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_File_Max_Fields>;
  min?: Maybe<Task_File_Min_Fields>;
};

/** aggregate fields of "task_file" */
export type Task_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_file" */
export type Task_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_File_Max_Order_By>;
  min?: InputMaybe<Task_File_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_file" */
export type Task_File_Arr_Rel_Insert_Input = {
  data: Array<Task_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_File_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_file". All fields are combined with a logical 'AND'. */
export type Task_File_Bool_Exp = {
  _and?: InputMaybe<Array<Task_File_Bool_Exp>>;
  _not?: InputMaybe<Task_File_Bool_Exp>;
  _or?: InputMaybe<Array<Task_File_Bool_Exp>>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  storage_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_file" */
export enum Task_File_Constraint {
  /** unique or primary key constraint on columns "task_id", "storage_file_id" */
  TaskFilesPkey = 'task_files_pkey',
}

/** input type for inserting data into table "task_file" */
export type Task_File_Insert_Input = {
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_File_Max_Fields = {
  __typename?: 'task_file_max_fields';
  storage_file_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_file" */
export type Task_File_Max_Order_By = {
  storage_file_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_File_Min_Fields = {
  __typename?: 'task_file_min_fields';
  storage_file_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_file" */
export type Task_File_Min_Order_By = {
  storage_file_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_file" */
export type Task_File_Mutation_Response = {
  __typename?: 'task_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_File>;
};

/** on_conflict condition type for table "task_file" */
export type Task_File_On_Conflict = {
  constraint: Task_File_Constraint;
  update_columns?: Array<Task_File_Update_Column>;
  where?: InputMaybe<Task_File_Bool_Exp>;
};

/** Ordering options when selecting data from "task_file". */
export type Task_File_Order_By = {
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  storage_file_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_file */
export type Task_File_Pk_Columns_Input = {
  storage_file_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "task_file" */
export enum Task_File_Select_Column {
  /** column name */
  StorageFileId = 'storage_file_id',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "task_file" */
export type Task_File_Set_Input = {
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_file" */
export type Task_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_File_Stream_Cursor_Value_Input = {
  storage_file_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "task_file" */
export enum Task_File_Update_Column {
  /** column name */
  StorageFileId = 'storage_file_id',
  /** column name */
  TaskId = 'task_id',
}

export type Task_File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_File_Bool_Exp;
};

/** input type for incrementing numeric columns in table "task" */
export type Task_Inc_Input = {
  estimated_time?: InputMaybe<Scalars['numeric']>;
  rank?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "task" */
export type Task_Insert_Input = {
  assigned_at?: InputMaybe<Scalars['timestamptz']>;
  assigned_by?: InputMaybe<Scalars['uuid']>;
  assigned_to?: InputMaybe<Scalars['uuid']>;
  category?: InputMaybe<Enum_Project_Task_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  due_date_time?: InputMaybe<Scalars['timestamptz']>;
  employee_bandwidth_data?: InputMaybe<Employee_Bandwidth_Data_Arr_Rel_Insert_Input>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  enum_project_task_category?: InputMaybe<Enum_Project_Task_Category_Obj_Rel_Insert_Input>;
  enum_task_priority?: InputMaybe<Enum_Task_Priority_Obj_Rel_Insert_Input>;
  enum_task_recurring_frequency?: InputMaybe<Enum_Task_Recurring_Frequency_Obj_Rel_Insert_Input>;
  enum_task_status?: InputMaybe<Enum_Task_Status_Obj_Rel_Insert_Input>;
  estimated_time?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  is_recurring?: InputMaybe<Scalars['Boolean']>;
  next_recurred_date?: InputMaybe<Scalars['timestamptz']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  parent_recurred_task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  parent_task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  priority?: InputMaybe<Enum_Task_Priority_Enum>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  project_section?: InputMaybe<Project_Section_Obj_Rel_Insert_Input>;
  project_sprint?: InputMaybe<Project_Sprints_Obj_Rel_Insert_Input>;
  rank?: InputMaybe<Scalars['float8']>;
  recurred_from?: InputMaybe<Scalars['uuid']>;
  recurred_tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  recurring_frequency?: InputMaybe<Enum_Task_Recurring_Frequency_Enum>;
  section_id?: InputMaybe<Scalars['uuid']>;
  sprint_id?: InputMaybe<Scalars['uuid']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_Task_Status_Enum>;
  sub_tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  subtask_of?: InputMaybe<Scalars['uuid']>;
  taskDependenciesByTaskId?: InputMaybe<Task_Dependencies_Arr_Rel_Insert_Input>;
  task_approvers?: InputMaybe<Task_Approvers_Arr_Rel_Insert_Input>;
  task_checklists?: InputMaybe<Task_Checklist_Arr_Rel_Insert_Input>;
  task_collaborators?: InputMaybe<Task_Collaborator_Arr_Rel_Insert_Input>;
  task_comments?: InputMaybe<Task_Comment_Arr_Rel_Insert_Input>;
  task_dependencies?: InputMaybe<Task_Dependencies_Arr_Rel_Insert_Input>;
  task_files?: InputMaybe<Task_File_Arr_Rel_Insert_Input>;
  task_milestones?: InputMaybe<Task_Milestones_Arr_Rel_Insert_Input>;
  task_tags?: InputMaybe<Task_Tags_Arr_Rel_Insert_Input>;
  task_time_sheets?: InputMaybe<Task_Time_Sheet_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByAssignedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByCreatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Task_Max_Fields = {
  __typename?: 'task_max_fields';
  assigned_at?: Maybe<Scalars['timestamptz']>;
  assigned_by?: Maybe<Scalars['uuid']>;
  assigned_to?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  due_date_time?: Maybe<Scalars['timestamptz']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  estimated_time?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  next_recurred_date?: Maybe<Scalars['timestamptz']>;
  organization_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['float8']>;
  recurred_from?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
  sprint_id?: Maybe<Scalars['uuid']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  subtask_of?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task" */
export type Task_Max_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date_time?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  estimated_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_recurred_date?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  recurred_from?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  sprint_id?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  subtask_of?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Mapping table b/w task and project_milestones */
export type Task_Milestones = {
  __typename?: 'task_milestones';
  milestone_id: Scalars['uuid'];
  /** An object relationship */
  project_milestone: Project_Milestones;
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "task_milestones" */
export type Task_Milestones_Aggregate = {
  __typename?: 'task_milestones_aggregate';
  aggregate?: Maybe<Task_Milestones_Aggregate_Fields>;
  nodes: Array<Task_Milestones>;
};

export type Task_Milestones_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Milestones_Aggregate_Bool_Exp_Count>;
};

export type Task_Milestones_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Milestones_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_milestones" */
export type Task_Milestones_Aggregate_Fields = {
  __typename?: 'task_milestones_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Milestones_Max_Fields>;
  min?: Maybe<Task_Milestones_Min_Fields>;
};

/** aggregate fields of "task_milestones" */
export type Task_Milestones_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Milestones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_milestones" */
export type Task_Milestones_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Milestones_Max_Order_By>;
  min?: InputMaybe<Task_Milestones_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_milestones" */
export type Task_Milestones_Arr_Rel_Insert_Input = {
  data: Array<Task_Milestones_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Milestones_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_milestones". All fields are combined with a logical 'AND'. */
export type Task_Milestones_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Milestones_Bool_Exp>>;
  _not?: InputMaybe<Task_Milestones_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Milestones_Bool_Exp>>;
  milestone_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_milestone?: InputMaybe<Project_Milestones_Bool_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_milestones" */
export enum Task_Milestones_Constraint {
  /** unique or primary key constraint on columns "milestone_id", "task_id" */
  TaskMilestonesPkey = 'task_milestones_pkey',
}

/** input type for inserting data into table "task_milestones" */
export type Task_Milestones_Insert_Input = {
  milestone_id?: InputMaybe<Scalars['uuid']>;
  project_milestone?: InputMaybe<Project_Milestones_Obj_Rel_Insert_Input>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Milestones_Max_Fields = {
  __typename?: 'task_milestones_max_fields';
  milestone_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_milestones" */
export type Task_Milestones_Max_Order_By = {
  milestone_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Milestones_Min_Fields = {
  __typename?: 'task_milestones_min_fields';
  milestone_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_milestones" */
export type Task_Milestones_Min_Order_By = {
  milestone_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_milestones" */
export type Task_Milestones_Mutation_Response = {
  __typename?: 'task_milestones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Milestones>;
};

/** on_conflict condition type for table "task_milestones" */
export type Task_Milestones_On_Conflict = {
  constraint: Task_Milestones_Constraint;
  update_columns?: Array<Task_Milestones_Update_Column>;
  where?: InputMaybe<Task_Milestones_Bool_Exp>;
};

/** Ordering options when selecting data from "task_milestones". */
export type Task_Milestones_Order_By = {
  milestone_id?: InputMaybe<Order_By>;
  project_milestone?: InputMaybe<Project_Milestones_Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_milestones */
export type Task_Milestones_Pk_Columns_Input = {
  milestone_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "task_milestones" */
export enum Task_Milestones_Select_Column {
  /** column name */
  MilestoneId = 'milestone_id',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "task_milestones" */
export type Task_Milestones_Set_Input = {
  milestone_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_milestones" */
export type Task_Milestones_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Milestones_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Milestones_Stream_Cursor_Value_Input = {
  milestone_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "task_milestones" */
export enum Task_Milestones_Update_Column {
  /** column name */
  MilestoneId = 'milestone_id',
  /** column name */
  TaskId = 'task_id',
}

export type Task_Milestones_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Milestones_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Milestones_Bool_Exp;
};

/** aggregate min on columns */
export type Task_Min_Fields = {
  __typename?: 'task_min_fields';
  assigned_at?: Maybe<Scalars['timestamptz']>;
  assigned_by?: Maybe<Scalars['uuid']>;
  assigned_to?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  due_date_time?: Maybe<Scalars['timestamptz']>;
  end_date_time?: Maybe<Scalars['timestamptz']>;
  estimated_time?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  next_recurred_date?: Maybe<Scalars['timestamptz']>;
  organization_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['float8']>;
  recurred_from?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
  sprint_id?: Maybe<Scalars['uuid']>;
  start_date_time?: Maybe<Scalars['timestamptz']>;
  subtask_of?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task" */
export type Task_Min_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date_time?: InputMaybe<Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  estimated_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_recurred_date?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  recurred_from?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  sprint_id?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  subtask_of?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task" */
export type Task_Mutation_Response = {
  __typename?: 'task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task>;
};

/** input type for inserting object relation for remote table "task" */
export type Task_Obj_Rel_Insert_Input = {
  data: Task_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** on_conflict condition type for table "task" */
export type Task_On_Conflict = {
  constraint: Task_Constraint;
  update_columns?: Array<Task_Update_Column>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Ordering options when selecting data from "task". */
export type Task_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date_time?: InputMaybe<Order_By>;
  employee_bandwidth_data_aggregate?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Order_By>;
  end_date_time?: InputMaybe<Order_By>;
  enum_project_task_category?: InputMaybe<Enum_Project_Task_Category_Order_By>;
  enum_task_priority?: InputMaybe<Enum_Task_Priority_Order_By>;
  enum_task_recurring_frequency?: InputMaybe<Enum_Task_Recurring_Frequency_Order_By>;
  enum_task_status?: InputMaybe<Enum_Task_Status_Order_By>;
  estimated_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_draft?: InputMaybe<Order_By>;
  is_recurring?: InputMaybe<Order_By>;
  next_recurred_date?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  parent_recurred_task?: InputMaybe<Task_Order_By>;
  parent_task?: InputMaybe<Task_Order_By>;
  priority?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  project_section?: InputMaybe<Project_Section_Order_By>;
  project_sprint?: InputMaybe<Project_Sprints_Order_By>;
  rank?: InputMaybe<Order_By>;
  recurred_from?: InputMaybe<Order_By>;
  recurred_tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  recurring_frequency?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  sprint_id?: InputMaybe<Order_By>;
  start_date_time?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sub_tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  subtask_of?: InputMaybe<Order_By>;
  taskDependenciesByTaskId_aggregate?: InputMaybe<Task_Dependencies_Aggregate_Order_By>;
  task_approvers_aggregate?: InputMaybe<Task_Approvers_Aggregate_Order_By>;
  task_checklists_aggregate?: InputMaybe<Task_Checklist_Aggregate_Order_By>;
  task_collaborators_aggregate?: InputMaybe<Task_Collaborator_Aggregate_Order_By>;
  task_comments_aggregate?: InputMaybe<Task_Comment_Aggregate_Order_By>;
  task_dependencies_aggregate?: InputMaybe<Task_Dependencies_Aggregate_Order_By>;
  task_files_aggregate?: InputMaybe<Task_File_Aggregate_Order_By>;
  task_milestones_aggregate?: InputMaybe<Task_Milestones_Aggregate_Order_By>;
  task_tags_aggregate?: InputMaybe<Task_Tags_Aggregate_Order_By>;
  task_time_sheets_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByAssignedBy?: InputMaybe<Users_Order_By>;
  userByCreatedBy?: InputMaybe<Users_Order_By>;
  userByUpdatedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: task */
export type Task_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task" */
export enum Task_Select_Column {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedBy = 'assigned_by',
  /** column name */
  AssignedTo = 'assigned_to',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueDateTime = 'due_date_time',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  EstimatedTime = 'estimated_time',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  IsRecurring = 'is_recurring',
  /** column name */
  NextRecurredDate = 'next_recurred_date',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rank = 'rank',
  /** column name */
  RecurredFrom = 'recurred_from',
  /** column name */
  RecurringFrequency = 'recurring_frequency',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  SprintId = 'sprint_id',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  Status = 'status',
  /** column name */
  SubtaskOf = 'subtask_of',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** select "task_aggregate_bool_exp_avg_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Rank = 'rank',
}

/** select "task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  IsRecurring = 'is_recurring',
}

/** select "task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  IsRecurring = 'is_recurring',
}

/** select "task_aggregate_bool_exp_corr_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Rank = 'rank',
}

/** select "task_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Rank = 'rank',
}

/** select "task_aggregate_bool_exp_max_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Rank = 'rank',
}

/** select "task_aggregate_bool_exp_min_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Rank = 'rank',
}

/** select "task_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Rank = 'rank',
}

/** select "task_aggregate_bool_exp_sum_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Rank = 'rank',
}

/** select "task_aggregate_bool_exp_var_samp_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Rank = 'rank',
}

/** input type for updating data in table "task" */
export type Task_Set_Input = {
  assigned_at?: InputMaybe<Scalars['timestamptz']>;
  assigned_by?: InputMaybe<Scalars['uuid']>;
  assigned_to?: InputMaybe<Scalars['uuid']>;
  category?: InputMaybe<Enum_Project_Task_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  due_date_time?: InputMaybe<Scalars['timestamptz']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  estimated_time?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  is_recurring?: InputMaybe<Scalars['Boolean']>;
  next_recurred_date?: InputMaybe<Scalars['timestamptz']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Enum_Task_Priority_Enum>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rank?: InputMaybe<Scalars['float8']>;
  recurred_from?: InputMaybe<Scalars['uuid']>;
  recurring_frequency?: InputMaybe<Enum_Task_Recurring_Frequency_Enum>;
  section_id?: InputMaybe<Scalars['uuid']>;
  sprint_id?: InputMaybe<Scalars['uuid']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_Task_Status_Enum>;
  subtask_of?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Task_Stddev_Fields = {
  __typename?: 'task_stddev_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "task" */
export type Task_Stddev_Order_By = {
  estimated_time?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Stddev_Pop_Fields = {
  __typename?: 'task_stddev_pop_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "task" */
export type Task_Stddev_Pop_Order_By = {
  estimated_time?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Stddev_Samp_Fields = {
  __typename?: 'task_stddev_samp_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "task" */
export type Task_Stddev_Samp_Order_By = {
  estimated_time?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "task" */
export type Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Stream_Cursor_Value_Input = {
  assigned_at?: InputMaybe<Scalars['timestamptz']>;
  assigned_by?: InputMaybe<Scalars['uuid']>;
  assigned_to?: InputMaybe<Scalars['uuid']>;
  category?: InputMaybe<Enum_Project_Task_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  due_date_time?: InputMaybe<Scalars['timestamptz']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
  estimated_time?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  is_recurring?: InputMaybe<Scalars['Boolean']>;
  next_recurred_date?: InputMaybe<Scalars['timestamptz']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Enum_Task_Priority_Enum>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rank?: InputMaybe<Scalars['float8']>;
  recurred_from?: InputMaybe<Scalars['uuid']>;
  recurring_frequency?: InputMaybe<Enum_Task_Recurring_Frequency_Enum>;
  section_id?: InputMaybe<Scalars['uuid']>;
  sprint_id?: InputMaybe<Scalars['uuid']>;
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_Task_Status_Enum>;
  subtask_of?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Task_Sum_Fields = {
  __typename?: 'task_sum_fields';
  estimated_time?: Maybe<Scalars['numeric']>;
  rank?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "task" */
export type Task_Sum_Order_By = {
  estimated_time?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Tags connected to tasks */
export type Task_Tags = {
  __typename?: 'task_tags';
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid'];
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid'];
};

/** aggregated selection of "task_tags" */
export type Task_Tags_Aggregate = {
  __typename?: 'task_tags_aggregate';
  aggregate?: Maybe<Task_Tags_Aggregate_Fields>;
  nodes: Array<Task_Tags>;
};

export type Task_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Tags_Aggregate_Bool_Exp_Count>;
};

export type Task_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_tags" */
export type Task_Tags_Aggregate_Fields = {
  __typename?: 'task_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Tags_Max_Fields>;
  min?: Maybe<Task_Tags_Min_Fields>;
};

/** aggregate fields of "task_tags" */
export type Task_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_tags" */
export type Task_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Tags_Max_Order_By>;
  min?: InputMaybe<Task_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_tags" */
export type Task_Tags_Arr_Rel_Insert_Input = {
  data: Array<Task_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_tags". All fields are combined with a logical 'AND'. */
export type Task_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Tags_Bool_Exp>>;
  _not?: InputMaybe<Task_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Tags_Bool_Exp>>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_tags" */
export enum Task_Tags_Constraint {
  /** unique or primary key constraint on columns "tag_id", "task_id" */
  TaskTagsPkey = 'task_tags_pkey',
}

/** input type for inserting data into table "task_tags" */
export type Task_Tags_Insert_Input = {
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Tags_Max_Fields = {
  __typename?: 'task_tags_max_fields';
  tag_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "task_tags" */
export type Task_Tags_Max_Order_By = {
  tag_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Tags_Min_Fields = {
  __typename?: 'task_tags_min_fields';
  tag_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "task_tags" */
export type Task_Tags_Min_Order_By = {
  tag_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_tags" */
export type Task_Tags_Mutation_Response = {
  __typename?: 'task_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Tags>;
};

/** on_conflict condition type for table "task_tags" */
export type Task_Tags_On_Conflict = {
  constraint: Task_Tags_Constraint;
  update_columns?: Array<Task_Tags_Update_Column>;
  where?: InputMaybe<Task_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "task_tags". */
export type Task_Tags_Order_By = {
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_tags */
export type Task_Tags_Pk_Columns_Input = {
  tag_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
};

/** select columns of table "task_tags" */
export enum Task_Tags_Select_Column {
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "task_tags" */
export type Task_Tags_Set_Input = {
  tag_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_tags" */
export type Task_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Tags_Stream_Cursor_Value_Input = {
  tag_id?: InputMaybe<Scalars['uuid']>;
  task_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "task_tags" */
export enum Task_Tags_Update_Column {
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TaskId = 'task_id',
}

export type Task_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Tags_Bool_Exp;
};

/** Task worked hours tracking */
export type Task_Time_Sheet = {
  __typename?: 'task_time_sheet';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  draft_hours?: Maybe<Scalars['numeric']>;
  draft_minutes?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  enum_task_time_sheet_status: Enum_Task_Time_Sheet_Status;
  id: Scalars['uuid'];
  manager_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project?: Maybe<Project>;
  project_id: Scalars['uuid'];
  status: Enum_Task_Time_Sheet_Status_Enum;
  /** An object relationship */
  task?: Maybe<Task>;
  /** A computed field, executes function "get_time_sheet_task_combined_id" */
  task_combined_id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_time_sheet_task_combined_type" */
  task_combined_type?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
  task_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  task_unscheduled?: Maybe<Task_Unscheduled>;
  task_unscheduled_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByManagerBy?: Maybe<Users>;
  work_date: Scalars['date'];
  work_hours?: Maybe<Scalars['numeric']>;
  work_minutes?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "task_time_sheet" */
export type Task_Time_Sheet_Aggregate = {
  __typename?: 'task_time_sheet_aggregate';
  aggregate?: Maybe<Task_Time_Sheet_Aggregate_Fields>;
  nodes: Array<Task_Time_Sheet>;
};

export type Task_Time_Sheet_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Time_Sheet_Aggregate_Bool_Exp_Count>;
};

export type Task_Time_Sheet_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_time_sheet" */
export type Task_Time_Sheet_Aggregate_Fields = {
  __typename?: 'task_time_sheet_aggregate_fields';
  avg?: Maybe<Task_Time_Sheet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Task_Time_Sheet_Max_Fields>;
  min?: Maybe<Task_Time_Sheet_Min_Fields>;
  stddev?: Maybe<Task_Time_Sheet_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Time_Sheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Time_Sheet_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Time_Sheet_Sum_Fields>;
  var_pop?: Maybe<Task_Time_Sheet_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Time_Sheet_Var_Samp_Fields>;
  variance?: Maybe<Task_Time_Sheet_Variance_Fields>;
};

/** aggregate fields of "task_time_sheet" */
export type Task_Time_Sheet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_time_sheet" */
export type Task_Time_Sheet_Aggregate_Order_By = {
  avg?: InputMaybe<Task_Time_Sheet_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Time_Sheet_Max_Order_By>;
  min?: InputMaybe<Task_Time_Sheet_Min_Order_By>;
  stddev?: InputMaybe<Task_Time_Sheet_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Task_Time_Sheet_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Task_Time_Sheet_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Task_Time_Sheet_Sum_Order_By>;
  var_pop?: InputMaybe<Task_Time_Sheet_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Task_Time_Sheet_Var_Samp_Order_By>;
  variance?: InputMaybe<Task_Time_Sheet_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "task_time_sheet" */
export type Task_Time_Sheet_Arr_Rel_Insert_Input = {
  data: Array<Task_Time_Sheet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Time_Sheet_On_Conflict>;
};

/** aggregate avg on columns */
export type Task_Time_Sheet_Avg_Fields = {
  __typename?: 'task_time_sheet_avg_fields';
  draft_hours?: Maybe<Scalars['Float']>;
  draft_minutes?: Maybe<Scalars['Float']>;
  work_hours?: Maybe<Scalars['Float']>;
  work_minutes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Avg_Order_By = {
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task_time_sheet". All fields are combined with a logical 'AND'. */
export type Task_Time_Sheet_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Time_Sheet_Bool_Exp>>;
  _not?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Time_Sheet_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  draft_hours?: InputMaybe<Numeric_Comparison_Exp>;
  draft_minutes?: InputMaybe<Numeric_Comparison_Exp>;
  enum_task_time_sheet_status?: InputMaybe<Enum_Task_Time_Sheet_Status_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  manager_by?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Enum_Task_Time_Sheet_Status_Enum_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_combined_id?: InputMaybe<String_Comparison_Exp>;
  task_combined_type?: InputMaybe<String_Comparison_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  task_name?: InputMaybe<String_Comparison_Exp>;
  task_unscheduled?: InputMaybe<Task_Unscheduled_Bool_Exp>;
  task_unscheduled_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByManagerBy?: InputMaybe<Users_Bool_Exp>;
  work_date?: InputMaybe<Date_Comparison_Exp>;
  work_hours?: InputMaybe<Numeric_Comparison_Exp>;
  work_minutes?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_time_sheet" */
export enum Task_Time_Sheet_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskTimeSheetPkey = 'task_time_sheet_pkey',
  /** unique or primary key constraint on columns "work_date", "task_id" */
  TaskTimeSheetTaskIdWorkDateKey = 'task_time_sheet_task_id_work_date_key',
}

/** input type for incrementing numeric columns in table "task_time_sheet" */
export type Task_Time_Sheet_Inc_Input = {
  draft_hours?: InputMaybe<Scalars['numeric']>;
  draft_minutes?: InputMaybe<Scalars['numeric']>;
  work_hours?: InputMaybe<Scalars['numeric']>;
  work_minutes?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "task_time_sheet" */
export type Task_Time_Sheet_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  draft_hours?: InputMaybe<Scalars['numeric']>;
  draft_minutes?: InputMaybe<Scalars['numeric']>;
  enum_task_time_sheet_status?: InputMaybe<Enum_Task_Time_Sheet_Status_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  manager_by?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Task_Time_Sheet_Status_Enum>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
  task_unscheduled?: InputMaybe<Task_Unscheduled_Obj_Rel_Insert_Input>;
  task_unscheduled_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByManagerBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  work_date?: InputMaybe<Scalars['date']>;
  work_hours?: InputMaybe<Scalars['numeric']>;
  work_minutes?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Task_Time_Sheet_Max_Fields = {
  __typename?: 'task_time_sheet_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  draft_hours?: Maybe<Scalars['numeric']>;
  draft_minutes?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  manager_by?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  task_unscheduled_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_date?: Maybe<Scalars['date']>;
  work_hours?: Maybe<Scalars['numeric']>;
  work_minutes?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_by?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  task_unscheduled_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_date?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Time_Sheet_Min_Fields = {
  __typename?: 'task_time_sheet_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  draft_hours?: Maybe<Scalars['numeric']>;
  draft_minutes?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  manager_by?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  task_unscheduled_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_date?: Maybe<Scalars['date']>;
  work_hours?: Maybe<Scalars['numeric']>;
  work_minutes?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_by?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  task_unscheduled_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_date?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_time_sheet" */
export type Task_Time_Sheet_Mutation_Response = {
  __typename?: 'task_time_sheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Time_Sheet>;
};

/** on_conflict condition type for table "task_time_sheet" */
export type Task_Time_Sheet_On_Conflict = {
  constraint: Task_Time_Sheet_Constraint;
  update_columns?: Array<Task_Time_Sheet_Update_Column>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** Ordering options when selecting data from "task_time_sheet". */
export type Task_Time_Sheet_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  enum_task_time_sheet_status?: InputMaybe<Enum_Task_Time_Sheet_Status_Order_By>;
  id?: InputMaybe<Order_By>;
  manager_by?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_combined_id?: InputMaybe<Order_By>;
  task_combined_type?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  task_name?: InputMaybe<Order_By>;
  task_unscheduled?: InputMaybe<Task_Unscheduled_Order_By>;
  task_unscheduled_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByManagerBy?: InputMaybe<Users_Order_By>;
  work_date?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_time_sheet */
export type Task_Time_Sheet_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task_time_sheet" */
export enum Task_Time_Sheet_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DraftHours = 'draft_hours',
  /** column name */
  DraftMinutes = 'draft_minutes',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerBy = 'manager_by',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  TaskUnscheduledId = 'task_unscheduled_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkDate = 'work_date',
  /** column name */
  WorkHours = 'work_hours',
  /** column name */
  WorkMinutes = 'work_minutes',
}

/** input type for updating data in table "task_time_sheet" */
export type Task_Time_Sheet_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  draft_hours?: InputMaybe<Scalars['numeric']>;
  draft_minutes?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  manager_by?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Task_Time_Sheet_Status_Enum>;
  task_id?: InputMaybe<Scalars['uuid']>;
  task_unscheduled_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  work_date?: InputMaybe<Scalars['date']>;
  work_hours?: InputMaybe<Scalars['numeric']>;
  work_minutes?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Task_Time_Sheet_Stddev_Fields = {
  __typename?: 'task_time_sheet_stddev_fields';
  draft_hours?: Maybe<Scalars['Float']>;
  draft_minutes?: Maybe<Scalars['Float']>;
  work_hours?: Maybe<Scalars['Float']>;
  work_minutes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Stddev_Order_By = {
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Time_Sheet_Stddev_Pop_Fields = {
  __typename?: 'task_time_sheet_stddev_pop_fields';
  draft_hours?: Maybe<Scalars['Float']>;
  draft_minutes?: Maybe<Scalars['Float']>;
  work_hours?: Maybe<Scalars['Float']>;
  work_minutes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Stddev_Pop_Order_By = {
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Time_Sheet_Stddev_Samp_Fields = {
  __typename?: 'task_time_sheet_stddev_samp_fields';
  draft_hours?: Maybe<Scalars['Float']>;
  draft_minutes?: Maybe<Scalars['Float']>;
  work_hours?: Maybe<Scalars['Float']>;
  work_minutes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Stddev_Samp_Order_By = {
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "task_time_sheet" */
export type Task_Time_Sheet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Time_Sheet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Time_Sheet_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  draft_hours?: InputMaybe<Scalars['numeric']>;
  draft_minutes?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  manager_by?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Task_Time_Sheet_Status_Enum>;
  task_id?: InputMaybe<Scalars['uuid']>;
  task_unscheduled_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  work_date?: InputMaybe<Scalars['date']>;
  work_hours?: InputMaybe<Scalars['numeric']>;
  work_minutes?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Task_Time_Sheet_Sum_Fields = {
  __typename?: 'task_time_sheet_sum_fields';
  draft_hours?: Maybe<Scalars['numeric']>;
  draft_minutes?: Maybe<Scalars['numeric']>;
  work_hours?: Maybe<Scalars['numeric']>;
  work_minutes?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Sum_Order_By = {
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** update columns of table "task_time_sheet" */
export enum Task_Time_Sheet_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DraftHours = 'draft_hours',
  /** column name */
  DraftMinutes = 'draft_minutes',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerBy = 'manager_by',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  TaskUnscheduledId = 'task_unscheduled_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkDate = 'work_date',
  /** column name */
  WorkHours = 'work_hours',
  /** column name */
  WorkMinutes = 'work_minutes',
}

export type Task_Time_Sheet_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Task_Time_Sheet_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Time_Sheet_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Time_Sheet_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Task_Time_Sheet_Var_Pop_Fields = {
  __typename?: 'task_time_sheet_var_pop_fields';
  draft_hours?: Maybe<Scalars['Float']>;
  draft_minutes?: Maybe<Scalars['Float']>;
  work_hours?: Maybe<Scalars['Float']>;
  work_minutes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Var_Pop_Order_By = {
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Time_Sheet_Var_Samp_Fields = {
  __typename?: 'task_time_sheet_var_samp_fields';
  draft_hours?: Maybe<Scalars['Float']>;
  draft_minutes?: Maybe<Scalars['Float']>;
  work_hours?: Maybe<Scalars['Float']>;
  work_minutes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Var_Samp_Order_By = {
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Time_Sheet_Variance_Fields = {
  __typename?: 'task_time_sheet_variance_fields';
  draft_hours?: Maybe<Scalars['Float']>;
  draft_minutes?: Maybe<Scalars['Float']>;
  work_hours?: Maybe<Scalars['Float']>;
  work_minutes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "task_time_sheet" */
export type Task_Time_Sheet_Variance_Order_By = {
  draft_hours?: InputMaybe<Order_By>;
  draft_minutes?: InputMaybe<Order_By>;
  work_hours?: InputMaybe<Order_By>;
  work_minutes?: InputMaybe<Order_By>;
};

/** unscheduled created by employees for time sheet */
export type Task_Unscheduled = {
  __typename?: 'task_unscheduled';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  /** An array relationship */
  task_time_sheets: Array<Task_Time_Sheet>;
  /** An aggregate relationship */
  task_time_sheets_aggregate: Task_Time_Sheet_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** unscheduled created by employees for time sheet */
export type Task_UnscheduledTask_Time_SheetsArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** unscheduled created by employees for time sheet */
export type Task_UnscheduledTask_Time_Sheets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** aggregated selection of "task_unscheduled" */
export type Task_Unscheduled_Aggregate = {
  __typename?: 'task_unscheduled_aggregate';
  aggregate?: Maybe<Task_Unscheduled_Aggregate_Fields>;
  nodes: Array<Task_Unscheduled>;
};

export type Task_Unscheduled_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Unscheduled_Aggregate_Bool_Exp_Count>;
};

export type Task_Unscheduled_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Unscheduled_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_unscheduled" */
export type Task_Unscheduled_Aggregate_Fields = {
  __typename?: 'task_unscheduled_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Unscheduled_Max_Fields>;
  min?: Maybe<Task_Unscheduled_Min_Fields>;
};

/** aggregate fields of "task_unscheduled" */
export type Task_Unscheduled_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_unscheduled" */
export type Task_Unscheduled_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Unscheduled_Max_Order_By>;
  min?: InputMaybe<Task_Unscheduled_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_unscheduled" */
export type Task_Unscheduled_Arr_Rel_Insert_Input = {
  data: Array<Task_Unscheduled_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Unscheduled_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_unscheduled". All fields are combined with a logical 'AND'. */
export type Task_Unscheduled_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Unscheduled_Bool_Exp>>;
  _not?: InputMaybe<Task_Unscheduled_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Unscheduled_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  task_time_sheets?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
  task_time_sheets_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "task_unscheduled" */
export enum Task_Unscheduled_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskUnscheduledPkey = 'task_unscheduled_pkey',
}

/** input type for inserting data into table "task_unscheduled" */
export type Task_Unscheduled_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  task_time_sheets?: InputMaybe<Task_Time_Sheet_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Task_Unscheduled_Max_Fields = {
  __typename?: 'task_unscheduled_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "task_unscheduled" */
export type Task_Unscheduled_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Unscheduled_Min_Fields = {
  __typename?: 'task_unscheduled_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "task_unscheduled" */
export type Task_Unscheduled_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_unscheduled" */
export type Task_Unscheduled_Mutation_Response = {
  __typename?: 'task_unscheduled_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Unscheduled>;
};

/** input type for inserting object relation for remote table "task_unscheduled" */
export type Task_Unscheduled_Obj_Rel_Insert_Input = {
  data: Task_Unscheduled_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Unscheduled_On_Conflict>;
};

/** on_conflict condition type for table "task_unscheduled" */
export type Task_Unscheduled_On_Conflict = {
  constraint: Task_Unscheduled_Constraint;
  update_columns?: Array<Task_Unscheduled_Update_Column>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

/** Ordering options when selecting data from "task_unscheduled". */
export type Task_Unscheduled_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  task_time_sheets_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: task_unscheduled */
export type Task_Unscheduled_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task_unscheduled" */
export enum Task_Unscheduled_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "task_unscheduled" */
export type Task_Unscheduled_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "task_unscheduled" */
export type Task_Unscheduled_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Unscheduled_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Unscheduled_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "task_unscheduled" */
export enum Task_Unscheduled_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Task_Unscheduled_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Unscheduled_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Unscheduled_Bool_Exp;
};

/** update columns of table "task" */
export enum Task_Update_Column {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedBy = 'assigned_by',
  /** column name */
  AssignedTo = 'assigned_to',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueDateTime = 'due_date_time',
  /** column name */
  EndDateTime = 'end_date_time',
  /** column name */
  EstimatedTime = 'estimated_time',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  IsRecurring = 'is_recurring',
  /** column name */
  NextRecurredDate = 'next_recurred_date',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rank = 'rank',
  /** column name */
  RecurredFrom = 'recurred_from',
  /** column name */
  RecurringFrequency = 'recurring_frequency',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  SprintId = 'sprint_id',
  /** column name */
  StartDateTime = 'start_date_time',
  /** column name */
  Status = 'status',
  /** column name */
  SubtaskOf = 'subtask_of',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Task_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Task_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Task_Var_Pop_Fields = {
  __typename?: 'task_var_pop_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "task" */
export type Task_Var_Pop_Order_By = {
  estimated_time?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Var_Samp_Fields = {
  __typename?: 'task_var_samp_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "task" */
export type Task_Var_Samp_Order_By = {
  estimated_time?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Variance_Fields = {
  __typename?: 'task_variance_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "task" */
export type Task_Variance_Order_By = {
  estimated_time?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

export type TaskproductivityGraphInput = {
  currentDate?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  managerId: Scalars['String'];
  organizationId: Scalars['String'];
  startDate: Scalars['String'];
  type: Scalars['String'];
  userId?: InputMaybe<Scalars['uuid']>;
};

export type TaskproductivityGraphOutput = {
  __typename?: 'taskproductivityGraphOutput';
  response: Array<Maybe<TaskProductivityData>>;
};

export type TimeGraphData = {
  __typename?: 'timeGraphData';
  clientName: Scalars['String'];
  holiday: Scalars['Int'];
  projectWorkHours?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  workHours: Scalars['Int'];
};

export type TimePeriodRespList = {
  __typename?: 'timePeriodRespList';
  timePeriod?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type TimeSpentGraphInput = {
  endDate: Scalars['String'];
  orgId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  userId: Array<InputMaybe<Scalars['String']>>;
};

export type TimeSpentGraphOutput = {
  __typename?: 'timeSpentGraphOutput';
  response: Array<Maybe<GraphData>>;
};

/** columns and relationships of "time_period" */
export type Time_Period = {
  __typename?: 'time_period';
  datePeriod: Scalars['String'];
  id: Scalars['uuid'];
  timePeriod: Scalars['String'];
};

/** aggregated selection of "time_period" */
export type Time_Period_Aggregate = {
  __typename?: 'time_period_aggregate';
  aggregate?: Maybe<Time_Period_Aggregate_Fields>;
  nodes: Array<Time_Period>;
};

/** aggregate fields of "time_period" */
export type Time_Period_Aggregate_Fields = {
  __typename?: 'time_period_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Time_Period_Max_Fields>;
  min?: Maybe<Time_Period_Min_Fields>;
};

/** aggregate fields of "time_period" */
export type Time_Period_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Time_Period_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "time_period". All fields are combined with a logical 'AND'. */
export type Time_Period_Bool_Exp = {
  _and?: InputMaybe<Array<Time_Period_Bool_Exp>>;
  _not?: InputMaybe<Time_Period_Bool_Exp>;
  _or?: InputMaybe<Array<Time_Period_Bool_Exp>>;
  datePeriod?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  timePeriod?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "time_period" */
export enum Time_Period_Constraint {
  /** unique or primary key constraint on columns "id" */
  TimePeriodPkey = 'time_period_pkey',
}

/** input type for inserting data into table "time_period" */
export type Time_Period_Insert_Input = {
  datePeriod?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Time_Period_Max_Fields = {
  __typename?: 'time_period_max_fields';
  datePeriod?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  timePeriod?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Time_Period_Min_Fields = {
  __typename?: 'time_period_min_fields';
  datePeriod?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  timePeriod?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "time_period" */
export type Time_Period_Mutation_Response = {
  __typename?: 'time_period_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Time_Period>;
};

/** on_conflict condition type for table "time_period" */
export type Time_Period_On_Conflict = {
  constraint: Time_Period_Constraint;
  update_columns?: Array<Time_Period_Update_Column>;
  where?: InputMaybe<Time_Period_Bool_Exp>;
};

/** Ordering options when selecting data from "time_period". */
export type Time_Period_Order_By = {
  datePeriod?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timePeriod?: InputMaybe<Order_By>;
};

/** primary key columns input for table: time_period */
export type Time_Period_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "time_period" */
export enum Time_Period_Select_Column {
  /** column name */
  DatePeriod = 'datePeriod',
  /** column name */
  Id = 'id',
  /** column name */
  TimePeriod = 'timePeriod',
}

/** input type for updating data in table "time_period" */
export type Time_Period_Set_Input = {
  datePeriod?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "time_period" */
export type Time_Period_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Time_Period_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Time_Period_Stream_Cursor_Value_Input = {
  datePeriod?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  timePeriod?: InputMaybe<Scalars['String']>;
};

/** update columns of table "time_period" */
export enum Time_Period_Update_Column {
  /** column name */
  DatePeriod = 'datePeriod',
  /** column name */
  Id = 'id',
  /** column name */
  TimePeriod = 'timePeriod',
}

export type Time_Period_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Time_Period_Set_Input>;
  /** filter the rows which have to be updated */
  where: Time_Period_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

/** video training data */
export type Training = {
  __typename?: 'training';
  created_at: Scalars['timestamptz'];
  duration?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
  id: Scalars['uuid'];
  image_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  organization_role?: Maybe<Organization_Roles>;
  rewards: Scalars['Int'];
  role_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  storage_file?: Maybe<Storage_Files>;
  tags?: Maybe<Scalars['String']>;
  /** An array relationship */
  training_Skills: Array<Training_Skill>;
  /** An aggregate relationship */
  training_Skills_aggregate: Training_Skill_Aggregate;
  /** An array relationship */
  training_feedbacks: Array<Training_Feedback>;
  /** An aggregate relationship */
  training_feedbacks_aggregate: Training_Feedback_Aggregate;
  /** An array relationship */
  training_progress_records: Array<Training_Progress_Record>;
  /** An aggregate relationship */
  training_progress_records_aggregate: Training_Progress_Record_Aggregate;
  /** An array relationship */
  training_role_details: Array<Training_Role_Details>;
  /** An aggregate relationship */
  training_role_details_aggregate: Training_Role_Details_Aggregate;
  /** An array relationship */
  training_stage_careers: Array<Training_Stage_Career>;
  /** An aggregate relationship */
  training_stage_careers_aggregate: Training_Stage_Career_Aggregate;
  /** An array relationship */
  training_table_of_contents: Array<Training_Table_Of_Contents>;
  /** An aggregate relationship */
  training_table_of_contents_aggregate: Training_Table_Of_Contents_Aggregate;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Users;
  /** An array relationship */
  user_Skills_Trainings: Array<User_Skills_Training>;
  /** An aggregate relationship */
  user_Skills_Trainings_aggregate: User_Skills_Training_Aggregate;
  user_id: Scalars['uuid'];
};

/** video training data */
export type TrainingTraining_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Training_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Skill_Order_By>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Skill_Order_By>>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Progress_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Progress_Record_Order_By>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Progress_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Progress_Record_Order_By>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Role_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Role_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Role_Details_Order_By>>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Stage_CareersArgs = {
  distinct_on?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Stage_Career_Order_By>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Stage_Careers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Stage_Career_Order_By>>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Table_Of_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Training_Table_Of_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Table_Of_Contents_Order_By>>;
  where?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
};

/** video training data */
export type TrainingTraining_Table_Of_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Table_Of_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Table_Of_Contents_Order_By>>;
  where?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
};

/** video training data */
export type TrainingUser_Skills_TrainingsArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Training_Order_By>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

/** video training data */
export type TrainingUser_Skills_Trainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Training_Order_By>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

export type TrainingDetailInfo = {
  __typename?: 'trainingDetailInfo';
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  storage_file?: Maybe<StorageFileDetail>;
  trainingId?: Maybe<Scalars['uuid']>;
  trainingName?: Maybe<Scalars['String']>;
  training_feedbacks_aggregate?: Maybe<TrainingFeedbacksAggregate>;
  training_progress_records?: Maybe<Array<Maybe<TrainingProgressDetails>>>;
};

/** columns and relationships of "training_Skill" */
export type Training_Skill = {
  __typename?: 'training_Skill';
  id: Scalars['uuid'];
  /** An object relationship */
  list_Of_Skill?: Maybe<List_Of_Skills>;
  list_Of_Skill_Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  training?: Maybe<Training>;
  training_Id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "training_Skill" */
export type Training_Skill_Aggregate = {
  __typename?: 'training_Skill_aggregate';
  aggregate?: Maybe<Training_Skill_Aggregate_Fields>;
  nodes: Array<Training_Skill>;
};

export type Training_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Training_Skill_Aggregate_Bool_Exp_Count>;
};

export type Training_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training_Skill" */
export type Training_Skill_Aggregate_Fields = {
  __typename?: 'training_Skill_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Training_Skill_Max_Fields>;
  min?: Maybe<Training_Skill_Min_Fields>;
};

/** aggregate fields of "training_Skill" */
export type Training_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training_Skill" */
export type Training_Skill_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Skill_Max_Order_By>;
  min?: InputMaybe<Training_Skill_Min_Order_By>;
};

/** input type for inserting array relation for remote table "training_Skill" */
export type Training_Skill_Arr_Rel_Insert_Input = {
  data: Array<Training_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_Skill_On_Conflict>;
};

/** Boolean expression to filter rows from the table "training_Skill". All fields are combined with a logical 'AND'. */
export type Training_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Skill_Bool_Exp>>;
  _not?: InputMaybe<Training_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Skill_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Bool_Exp>;
  list_Of_Skill_Id?: InputMaybe<Uuid_Comparison_Exp>;
  training?: InputMaybe<Training_Bool_Exp>;
  training_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "training_Skill" */
export enum Training_Skill_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSkillPkey = 'training_Skill_pkey',
}

/** input type for inserting data into table "training_Skill" */
export type Training_Skill_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Obj_Rel_Insert_Input>;
  list_Of_Skill_Id?: InputMaybe<Scalars['uuid']>;
  training?: InputMaybe<Training_Obj_Rel_Insert_Input>;
  training_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Training_Skill_Max_Fields = {
  __typename?: 'training_Skill_max_fields';
  id?: Maybe<Scalars['uuid']>;
  list_Of_Skill_Id?: Maybe<Scalars['uuid']>;
  training_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "training_Skill" */
export type Training_Skill_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  list_Of_Skill_Id?: InputMaybe<Order_By>;
  training_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Skill_Min_Fields = {
  __typename?: 'training_Skill_min_fields';
  id?: Maybe<Scalars['uuid']>;
  list_Of_Skill_Id?: Maybe<Scalars['uuid']>;
  training_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "training_Skill" */
export type Training_Skill_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  list_Of_Skill_Id?: InputMaybe<Order_By>;
  training_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "training_Skill" */
export type Training_Skill_Mutation_Response = {
  __typename?: 'training_Skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Training_Skill>;
};

/** on_conflict condition type for table "training_Skill" */
export type Training_Skill_On_Conflict = {
  constraint: Training_Skill_Constraint;
  update_columns?: Array<Training_Skill_Update_Column>;
  where?: InputMaybe<Training_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "training_Skill". */
export type Training_Skill_Order_By = {
  id?: InputMaybe<Order_By>;
  list_Of_Skill?: InputMaybe<List_Of_Skills_Order_By>;
  list_Of_Skill_Id?: InputMaybe<Order_By>;
  training?: InputMaybe<Training_Order_By>;
  training_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training_Skill */
export type Training_Skill_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "training_Skill" */
export enum Training_Skill_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ListOfSkillId = 'list_Of_Skill_Id',
  /** column name */
  TrainingId = 'training_Id',
}

/** input type for updating data in table "training_Skill" */
export type Training_Skill_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  list_Of_Skill_Id?: InputMaybe<Scalars['uuid']>;
  training_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "training_Skill" */
export type Training_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Skill_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  list_Of_Skill_Id?: InputMaybe<Scalars['uuid']>;
  training_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "training_Skill" */
export enum Training_Skill_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ListOfSkillId = 'list_Of_Skill_Id',
  /** column name */
  TrainingId = 'training_Id',
}

export type Training_Skill_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Training_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Training_Skill_Bool_Exp;
};

/** aggregated selection of "training" */
export type Training_Aggregate = {
  __typename?: 'training_aggregate';
  aggregate?: Maybe<Training_Aggregate_Fields>;
  nodes: Array<Training>;
};

export type Training_Aggregate_Bool_Exp = {
  count?: InputMaybe<Training_Aggregate_Bool_Exp_Count>;
};

export type Training_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training" */
export type Training_Aggregate_Fields = {
  __typename?: 'training_aggregate_fields';
  avg?: Maybe<Training_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Training_Max_Fields>;
  min?: Maybe<Training_Min_Fields>;
  stddev?: Maybe<Training_Stddev_Fields>;
  stddev_pop?: Maybe<Training_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Training_Stddev_Samp_Fields>;
  sum?: Maybe<Training_Sum_Fields>;
  var_pop?: Maybe<Training_Var_Pop_Fields>;
  var_samp?: Maybe<Training_Var_Samp_Fields>;
  variance?: Maybe<Training_Variance_Fields>;
};

/** aggregate fields of "training" */
export type Training_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training" */
export type Training_Aggregate_Order_By = {
  avg?: InputMaybe<Training_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Max_Order_By>;
  min?: InputMaybe<Training_Min_Order_By>;
  stddev?: InputMaybe<Training_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Training_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Training_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Training_Sum_Order_By>;
  var_pop?: InputMaybe<Training_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Training_Var_Samp_Order_By>;
  variance?: InputMaybe<Training_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "training" */
export type Training_Arr_Rel_Insert_Input = {
  data: Array<Training_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_On_Conflict>;
};

/** aggregate avg on columns */
export type Training_Avg_Fields = {
  __typename?: 'training_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  rewards?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "training" */
export type Training_Avg_Order_By = {
  duration?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "training". All fields are combined with a logical 'AND'. */
export type Training_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Bool_Exp>>;
  _not?: InputMaybe<Training_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  format?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  rewards?: InputMaybe<Int_Comparison_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  tags?: InputMaybe<String_Comparison_Exp>;
  training_Skills?: InputMaybe<Training_Skill_Bool_Exp>;
  training_Skills_aggregate?: InputMaybe<Training_Skill_Aggregate_Bool_Exp>;
  training_feedbacks?: InputMaybe<Training_Feedback_Bool_Exp>;
  training_feedbacks_aggregate?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp>;
  training_progress_records?: InputMaybe<Training_Progress_Record_Bool_Exp>;
  training_progress_records_aggregate?: InputMaybe<Training_Progress_Record_Aggregate_Bool_Exp>;
  training_role_details?: InputMaybe<Training_Role_Details_Bool_Exp>;
  training_role_details_aggregate?: InputMaybe<Training_Role_Details_Aggregate_Bool_Exp>;
  training_stage_careers?: InputMaybe<Training_Stage_Career_Bool_Exp>;
  training_stage_careers_aggregate?: InputMaybe<Training_Stage_Career_Aggregate_Bool_Exp>;
  training_table_of_contents?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
  training_table_of_contents_aggregate?: InputMaybe<Training_Table_Of_Contents_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_Skills_Trainings?: InputMaybe<User_Skills_Training_Bool_Exp>;
  user_Skills_Trainings_aggregate?: InputMaybe<User_Skills_Training_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "training" */
export enum Training_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrainingPkey = 'training_pkey',
}

/** columns and relationships of "training_feedback" */
export type Training_Feedback = {
  __typename?: 'training_feedback';
  created_at: Scalars['timestamptz'];
  feedback: Scalars['float8'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  training: Training;
  training_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "training_feedback" */
export type Training_Feedback_Aggregate = {
  __typename?: 'training_feedback_aggregate';
  aggregate?: Maybe<Training_Feedback_Aggregate_Fields>;
  nodes: Array<Training_Feedback>;
};

export type Training_Feedback_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp_Var_Samp>;
};

export type Training_Feedback_Aggregate_Bool_Exp_Avg = {
  arguments: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Training_Feedback_Aggregate_Bool_Exp_Corr = {
  arguments: Training_Feedback_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Training_Feedback_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Training_Feedback_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Training_Feedback_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Training_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Training_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Training_Feedback_Aggregate_Bool_Exp_Max = {
  arguments: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Training_Feedback_Aggregate_Bool_Exp_Min = {
  arguments: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Training_Feedback_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Training_Feedback_Aggregate_Bool_Exp_Sum = {
  arguments: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Training_Feedback_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Feedback_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "training_feedback" */
export type Training_Feedback_Aggregate_Fields = {
  __typename?: 'training_feedback_aggregate_fields';
  avg?: Maybe<Training_Feedback_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Training_Feedback_Max_Fields>;
  min?: Maybe<Training_Feedback_Min_Fields>;
  stddev?: Maybe<Training_Feedback_Stddev_Fields>;
  stddev_pop?: Maybe<Training_Feedback_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Training_Feedback_Stddev_Samp_Fields>;
  sum?: Maybe<Training_Feedback_Sum_Fields>;
  var_pop?: Maybe<Training_Feedback_Var_Pop_Fields>;
  var_samp?: Maybe<Training_Feedback_Var_Samp_Fields>;
  variance?: Maybe<Training_Feedback_Variance_Fields>;
};

/** aggregate fields of "training_feedback" */
export type Training_Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training_feedback" */
export type Training_Feedback_Aggregate_Order_By = {
  avg?: InputMaybe<Training_Feedback_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Feedback_Max_Order_By>;
  min?: InputMaybe<Training_Feedback_Min_Order_By>;
  stddev?: InputMaybe<Training_Feedback_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Training_Feedback_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Training_Feedback_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Training_Feedback_Sum_Order_By>;
  var_pop?: InputMaybe<Training_Feedback_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Training_Feedback_Var_Samp_Order_By>;
  variance?: InputMaybe<Training_Feedback_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "training_feedback" */
export type Training_Feedback_Arr_Rel_Insert_Input = {
  data: Array<Training_Feedback_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_Feedback_On_Conflict>;
};

/** aggregate avg on columns */
export type Training_Feedback_Avg_Fields = {
  __typename?: 'training_feedback_avg_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "training_feedback" */
export type Training_Feedback_Avg_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "training_feedback". All fields are combined with a logical 'AND'. */
export type Training_Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Feedback_Bool_Exp>>;
  _not?: InputMaybe<Training_Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Feedback_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feedback?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  training?: InputMaybe<Training_Bool_Exp>;
  training_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "training_feedback" */
export enum Training_Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrainingFeedbackPkey = 'training_feedback_pkey',
}

/** input type for incrementing numeric columns in table "training_feedback" */
export type Training_Feedback_Inc_Input = {
  feedback?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "training_feedback" */
export type Training_Feedback_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  training?: InputMaybe<Training_Obj_Rel_Insert_Input>;
  training_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Training_Feedback_Max_Fields = {
  __typename?: 'training_feedback_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  feedback?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  training_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "training_feedback" */
export type Training_Feedback_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Feedback_Min_Fields = {
  __typename?: 'training_feedback_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  feedback?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  training_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "training_feedback" */
export type Training_Feedback_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "training_feedback" */
export type Training_Feedback_Mutation_Response = {
  __typename?: 'training_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Training_Feedback>;
};

/** on_conflict condition type for table "training_feedback" */
export type Training_Feedback_On_Conflict = {
  constraint: Training_Feedback_Constraint;
  update_columns?: Array<Training_Feedback_Update_Column>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "training_feedback". */
export type Training_Feedback_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  training?: InputMaybe<Training_Order_By>;
  training_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training_feedback */
export type Training_Feedback_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "training_feedback" */
export enum Training_Feedback_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "training_feedback_aggregate_bool_exp_avg_arguments_columns" columns of table "training_feedback" */
export enum Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "training_feedback_aggregate_bool_exp_corr_arguments_columns" columns of table "training_feedback" */
export enum Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "training_feedback_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "training_feedback" */
export enum Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "training_feedback_aggregate_bool_exp_max_arguments_columns" columns of table "training_feedback" */
export enum Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "training_feedback_aggregate_bool_exp_min_arguments_columns" columns of table "training_feedback" */
export enum Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "training_feedback_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "training_feedback" */
export enum Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "training_feedback_aggregate_bool_exp_sum_arguments_columns" columns of table "training_feedback" */
export enum Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** select "training_feedback_aggregate_bool_exp_var_samp_arguments_columns" columns of table "training_feedback" */
export enum Training_Feedback_Select_Column_Training_Feedback_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Feedback = 'feedback',
}

/** input type for updating data in table "training_feedback" */
export type Training_Feedback_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  training_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Training_Feedback_Stddev_Fields = {
  __typename?: 'training_feedback_stddev_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "training_feedback" */
export type Training_Feedback_Stddev_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Training_Feedback_Stddev_Pop_Fields = {
  __typename?: 'training_feedback_stddev_pop_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "training_feedback" */
export type Training_Feedback_Stddev_Pop_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Training_Feedback_Stddev_Samp_Fields = {
  __typename?: 'training_feedback_stddev_samp_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "training_feedback" */
export type Training_Feedback_Stddev_Samp_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "training_feedback" */
export type Training_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Feedback_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  training_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Training_Feedback_Sum_Fields = {
  __typename?: 'training_feedback_sum_fields';
  feedback?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "training_feedback" */
export type Training_Feedback_Sum_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** update columns of table "training_feedback" */
export enum Training_Feedback_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Training_Feedback_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Training_Feedback_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Training_Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Training_Feedback_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Training_Feedback_Var_Pop_Fields = {
  __typename?: 'training_feedback_var_pop_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "training_feedback" */
export type Training_Feedback_Var_Pop_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Training_Feedback_Var_Samp_Fields = {
  __typename?: 'training_feedback_var_samp_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "training_feedback" */
export type Training_Feedback_Var_Samp_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Training_Feedback_Variance_Fields = {
  __typename?: 'training_feedback_variance_fields';
  feedback?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "training_feedback" */
export type Training_Feedback_Variance_Order_By = {
  feedback?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "training" */
export type Training_Inc_Input = {
  duration?: InputMaybe<Scalars['Int']>;
  rewards?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "training" */
export type Training_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  rewards?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  tags?: InputMaybe<Scalars['String']>;
  training_Skills?: InputMaybe<Training_Skill_Arr_Rel_Insert_Input>;
  training_feedbacks?: InputMaybe<Training_Feedback_Arr_Rel_Insert_Input>;
  training_progress_records?: InputMaybe<Training_Progress_Record_Arr_Rel_Insert_Input>;
  training_role_details?: InputMaybe<Training_Role_Details_Arr_Rel_Insert_Input>;
  training_stage_careers?: InputMaybe<Training_Stage_Career_Arr_Rel_Insert_Input>;
  training_table_of_contents?: InputMaybe<Training_Table_Of_Contents_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_Skills_Trainings?: InputMaybe<User_Skills_Training_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Training_Max_Fields = {
  __typename?: 'training_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  rewards?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "training" */
export type Training_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  format?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Min_Fields = {
  __typename?: 'training_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  rewards?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "training" */
export type Training_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  format?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "training" */
export type Training_Mutation_Response = {
  __typename?: 'training_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Training>;
};

/** input type for inserting object relation for remote table "training" */
export type Training_Obj_Rel_Insert_Input = {
  data: Training_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_On_Conflict>;
};

/** on_conflict condition type for table "training" */
export type Training_On_Conflict = {
  constraint: Training_Constraint;
  update_columns?: Array<Training_Update_Column>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** Ordering options when selecting data from "training". */
export type Training_Order_By = {
  created_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  format?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  rewards?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  tags?: InputMaybe<Order_By>;
  training_Skills_aggregate?: InputMaybe<Training_Skill_Aggregate_Order_By>;
  training_feedbacks_aggregate?: InputMaybe<Training_Feedback_Aggregate_Order_By>;
  training_progress_records_aggregate?: InputMaybe<Training_Progress_Record_Aggregate_Order_By>;
  training_role_details_aggregate?: InputMaybe<Training_Role_Details_Aggregate_Order_By>;
  training_stage_careers_aggregate?: InputMaybe<Training_Stage_Career_Aggregate_Order_By>;
  training_table_of_contents_aggregate?: InputMaybe<Training_Table_Of_Contents_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_Skills_Trainings_aggregate?: InputMaybe<User_Skills_Training_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training */
export type Training_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "training_progress_record" */
export type Training_Progress_Record = {
  __typename?: 'training_progress_record';
  career_training_record?: Maybe<Scalars['Boolean']>;
  docData: Scalars['String'];
  id: Scalars['uuid'];
  progress: Scalars['String'];
  skill_training_Status?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  training: Training;
  training_id: Scalars['uuid'];
  type: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "training_progress_record" */
export type Training_Progress_Record_Aggregate = {
  __typename?: 'training_progress_record_aggregate';
  aggregate?: Maybe<Training_Progress_Record_Aggregate_Fields>;
  nodes: Array<Training_Progress_Record>;
};

export type Training_Progress_Record_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Training_Progress_Record_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Training_Progress_Record_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Training_Progress_Record_Aggregate_Bool_Exp_Count>;
};

export type Training_Progress_Record_Aggregate_Bool_Exp_Bool_And = {
  arguments: Training_Progress_Record_Select_Column_Training_Progress_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Progress_Record_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Training_Progress_Record_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Training_Progress_Record_Select_Column_Training_Progress_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Progress_Record_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Training_Progress_Record_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Progress_Record_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training_progress_record" */
export type Training_Progress_Record_Aggregate_Fields = {
  __typename?: 'training_progress_record_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Training_Progress_Record_Max_Fields>;
  min?: Maybe<Training_Progress_Record_Min_Fields>;
};

/** aggregate fields of "training_progress_record" */
export type Training_Progress_Record_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training_progress_record" */
export type Training_Progress_Record_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Progress_Record_Max_Order_By>;
  min?: InputMaybe<Training_Progress_Record_Min_Order_By>;
};

/** input type for inserting array relation for remote table "training_progress_record" */
export type Training_Progress_Record_Arr_Rel_Insert_Input = {
  data: Array<Training_Progress_Record_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_Progress_Record_On_Conflict>;
};

/** Boolean expression to filter rows from the table "training_progress_record". All fields are combined with a logical 'AND'. */
export type Training_Progress_Record_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Progress_Record_Bool_Exp>>;
  _not?: InputMaybe<Training_Progress_Record_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Progress_Record_Bool_Exp>>;
  career_training_record?: InputMaybe<Boolean_Comparison_Exp>;
  docData?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  progress?: InputMaybe<String_Comparison_Exp>;
  skill_training_Status?: InputMaybe<Boolean_Comparison_Exp>;
  training?: InputMaybe<Training_Bool_Exp>;
  training_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "training_progress_record" */
export enum Training_Progress_Record_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrainingProgressRecordPkey = 'training_progress_record_pkey',
}

/** input type for inserting data into table "training_progress_record" */
export type Training_Progress_Record_Insert_Input = {
  career_training_record?: InputMaybe<Scalars['Boolean']>;
  docData?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  progress?: InputMaybe<Scalars['String']>;
  skill_training_Status?: InputMaybe<Scalars['Boolean']>;
  training?: InputMaybe<Training_Obj_Rel_Insert_Input>;
  training_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Training_Progress_Record_Max_Fields = {
  __typename?: 'training_progress_record_max_fields';
  docData?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['String']>;
  training_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "training_progress_record" */
export type Training_Progress_Record_Max_Order_By = {
  docData?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Progress_Record_Min_Fields = {
  __typename?: 'training_progress_record_min_fields';
  docData?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['String']>;
  training_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "training_progress_record" */
export type Training_Progress_Record_Min_Order_By = {
  docData?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "training_progress_record" */
export type Training_Progress_Record_Mutation_Response = {
  __typename?: 'training_progress_record_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Training_Progress_Record>;
};

/** on_conflict condition type for table "training_progress_record" */
export type Training_Progress_Record_On_Conflict = {
  constraint: Training_Progress_Record_Constraint;
  update_columns?: Array<Training_Progress_Record_Update_Column>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

/** Ordering options when selecting data from "training_progress_record". */
export type Training_Progress_Record_Order_By = {
  career_training_record?: InputMaybe<Order_By>;
  docData?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  skill_training_Status?: InputMaybe<Order_By>;
  training?: InputMaybe<Training_Order_By>;
  training_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training_progress_record */
export type Training_Progress_Record_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "training_progress_record" */
export enum Training_Progress_Record_Select_Column {
  /** column name */
  CareerTrainingRecord = 'career_training_record',
  /** column name */
  DocData = 'docData',
  /** column name */
  Id = 'id',
  /** column name */
  Progress = 'progress',
  /** column name */
  SkillTrainingStatus = 'skill_training_Status',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
}

/** select "training_progress_record_aggregate_bool_exp_bool_and_arguments_columns" columns of table "training_progress_record" */
export enum Training_Progress_Record_Select_Column_Training_Progress_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CareerTrainingRecord = 'career_training_record',
  /** column name */
  SkillTrainingStatus = 'skill_training_Status',
}

/** select "training_progress_record_aggregate_bool_exp_bool_or_arguments_columns" columns of table "training_progress_record" */
export enum Training_Progress_Record_Select_Column_Training_Progress_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CareerTrainingRecord = 'career_training_record',
  /** column name */
  SkillTrainingStatus = 'skill_training_Status',
}

/** input type for updating data in table "training_progress_record" */
export type Training_Progress_Record_Set_Input = {
  career_training_record?: InputMaybe<Scalars['Boolean']>;
  docData?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  progress?: InputMaybe<Scalars['String']>;
  skill_training_Status?: InputMaybe<Scalars['Boolean']>;
  training_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "training_progress_record" */
export type Training_Progress_Record_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Progress_Record_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Progress_Record_Stream_Cursor_Value_Input = {
  career_training_record?: InputMaybe<Scalars['Boolean']>;
  docData?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  progress?: InputMaybe<Scalars['String']>;
  skill_training_Status?: InputMaybe<Scalars['Boolean']>;
  training_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "training_progress_record" */
export enum Training_Progress_Record_Update_Column {
  /** column name */
  CareerTrainingRecord = 'career_training_record',
  /** column name */
  DocData = 'docData',
  /** column name */
  Id = 'id',
  /** column name */
  Progress = 'progress',
  /** column name */
  SkillTrainingStatus = 'skill_training_Status',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
}

export type Training_Progress_Record_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Training_Progress_Record_Set_Input>;
  /** filter the rows which have to be updated */
  where: Training_Progress_Record_Bool_Exp;
};

/** columns and relationships of "training_role_details" */
export type Training_Role_Details = {
  __typename?: 'training_role_details';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  organization_role: Organization_Roles;
  role_id: Scalars['uuid'];
  /** An object relationship */
  training: Training;
  training_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "training_role_details" */
export type Training_Role_Details_Aggregate = {
  __typename?: 'training_role_details_aggregate';
  aggregate?: Maybe<Training_Role_Details_Aggregate_Fields>;
  nodes: Array<Training_Role_Details>;
};

export type Training_Role_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Training_Role_Details_Aggregate_Bool_Exp_Count>;
};

export type Training_Role_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Role_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training_role_details" */
export type Training_Role_Details_Aggregate_Fields = {
  __typename?: 'training_role_details_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Training_Role_Details_Max_Fields>;
  min?: Maybe<Training_Role_Details_Min_Fields>;
};

/** aggregate fields of "training_role_details" */
export type Training_Role_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Role_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training_role_details" */
export type Training_Role_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Role_Details_Max_Order_By>;
  min?: InputMaybe<Training_Role_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "training_role_details" */
export type Training_Role_Details_Arr_Rel_Insert_Input = {
  data: Array<Training_Role_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_Role_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "training_role_details". All fields are combined with a logical 'AND'. */
export type Training_Role_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Role_Details_Bool_Exp>>;
  _not?: InputMaybe<Training_Role_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Role_Details_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_role?: InputMaybe<Organization_Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  training?: InputMaybe<Training_Bool_Exp>;
  training_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "training_role_details" */
export enum Training_Role_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrainingRoleDetailsPkey = 'training_role_details_pkey',
}

/** input type for inserting data into table "training_role_details" */
export type Training_Role_Details_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  organization_role?: InputMaybe<Organization_Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']>;
  training?: InputMaybe<Training_Obj_Rel_Insert_Input>;
  training_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Training_Role_Details_Max_Fields = {
  __typename?: 'training_role_details_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  training_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "training_role_details" */
export type Training_Role_Details_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Role_Details_Min_Fields = {
  __typename?: 'training_role_details_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  training_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "training_role_details" */
export type Training_Role_Details_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "training_role_details" */
export type Training_Role_Details_Mutation_Response = {
  __typename?: 'training_role_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Training_Role_Details>;
};

/** on_conflict condition type for table "training_role_details" */
export type Training_Role_Details_On_Conflict = {
  constraint: Training_Role_Details_Constraint;
  update_columns?: Array<Training_Role_Details_Update_Column>;
  where?: InputMaybe<Training_Role_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "training_role_details". */
export type Training_Role_Details_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_role?: InputMaybe<Organization_Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  training?: InputMaybe<Training_Order_By>;
  training_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training_role_details */
export type Training_Role_Details_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "training_role_details" */
export enum Training_Role_Details_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "training_role_details" */
export type Training_Role_Details_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  training_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "training_role_details" */
export type Training_Role_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Role_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Role_Details_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  training_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "training_role_details" */
export enum Training_Role_Details_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Training_Role_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Training_Role_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Training_Role_Details_Bool_Exp;
};

/** select columns of table "training" */
export enum Training_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Rewards = 'rewards',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "training" */
export type Training_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  rewards?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "training_stage_career" */
export type Training_Stage_Career = {
  __typename?: 'training_stage_career';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  order_line?: Maybe<Scalars['Int']>;
  /** An object relationship */
  stage_career_detail: Stage_Career_Detail;
  stage_career_detail_id: Scalars['uuid'];
  /** An object relationship */
  training: Training;
  training_id: Scalars['uuid'];
};

/** aggregated selection of "training_stage_career" */
export type Training_Stage_Career_Aggregate = {
  __typename?: 'training_stage_career_aggregate';
  aggregate?: Maybe<Training_Stage_Career_Aggregate_Fields>;
  nodes: Array<Training_Stage_Career>;
};

export type Training_Stage_Career_Aggregate_Bool_Exp = {
  count?: InputMaybe<Training_Stage_Career_Aggregate_Bool_Exp_Count>;
};

export type Training_Stage_Career_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Stage_Career_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training_stage_career" */
export type Training_Stage_Career_Aggregate_Fields = {
  __typename?: 'training_stage_career_aggregate_fields';
  avg?: Maybe<Training_Stage_Career_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Training_Stage_Career_Max_Fields>;
  min?: Maybe<Training_Stage_Career_Min_Fields>;
  stddev?: Maybe<Training_Stage_Career_Stddev_Fields>;
  stddev_pop?: Maybe<Training_Stage_Career_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Training_Stage_Career_Stddev_Samp_Fields>;
  sum?: Maybe<Training_Stage_Career_Sum_Fields>;
  var_pop?: Maybe<Training_Stage_Career_Var_Pop_Fields>;
  var_samp?: Maybe<Training_Stage_Career_Var_Samp_Fields>;
  variance?: Maybe<Training_Stage_Career_Variance_Fields>;
};

/** aggregate fields of "training_stage_career" */
export type Training_Stage_Career_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Stage_Career_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training_stage_career" */
export type Training_Stage_Career_Aggregate_Order_By = {
  avg?: InputMaybe<Training_Stage_Career_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Stage_Career_Max_Order_By>;
  min?: InputMaybe<Training_Stage_Career_Min_Order_By>;
  stddev?: InputMaybe<Training_Stage_Career_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Training_Stage_Career_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Training_Stage_Career_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Training_Stage_Career_Sum_Order_By>;
  var_pop?: InputMaybe<Training_Stage_Career_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Training_Stage_Career_Var_Samp_Order_By>;
  variance?: InputMaybe<Training_Stage_Career_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "training_stage_career" */
export type Training_Stage_Career_Arr_Rel_Insert_Input = {
  data: Array<Training_Stage_Career_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_Stage_Career_On_Conflict>;
};

/** aggregate avg on columns */
export type Training_Stage_Career_Avg_Fields = {
  __typename?: 'training_stage_career_avg_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "training_stage_career" */
export type Training_Stage_Career_Avg_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "training_stage_career". All fields are combined with a logical 'AND'. */
export type Training_Stage_Career_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Stage_Career_Bool_Exp>>;
  _not?: InputMaybe<Training_Stage_Career_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Stage_Career_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_line?: InputMaybe<Int_Comparison_Exp>;
  stage_career_detail?: InputMaybe<Stage_Career_Detail_Bool_Exp>;
  stage_career_detail_id?: InputMaybe<Uuid_Comparison_Exp>;
  training?: InputMaybe<Training_Bool_Exp>;
  training_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "training_stage_career" */
export enum Training_Stage_Career_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrainingStageCareerPkey = 'training_stage_career_pkey',
}

/** input type for incrementing numeric columns in table "training_stage_career" */
export type Training_Stage_Career_Inc_Input = {
  order_line?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "training_stage_career" */
export type Training_Stage_Career_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_career_detail?: InputMaybe<Stage_Career_Detail_Obj_Rel_Insert_Input>;
  stage_career_detail_id?: InputMaybe<Scalars['uuid']>;
  training?: InputMaybe<Training_Obj_Rel_Insert_Input>;
  training_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Training_Stage_Career_Max_Fields = {
  __typename?: 'training_stage_career_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order_line?: Maybe<Scalars['Int']>;
  stage_career_detail_id?: Maybe<Scalars['uuid']>;
  training_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "training_stage_career" */
export type Training_Stage_Career_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_career_detail_id?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Stage_Career_Min_Fields = {
  __typename?: 'training_stage_career_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order_line?: Maybe<Scalars['Int']>;
  stage_career_detail_id?: Maybe<Scalars['uuid']>;
  training_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "training_stage_career" */
export type Training_Stage_Career_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_career_detail_id?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "training_stage_career" */
export type Training_Stage_Career_Mutation_Response = {
  __typename?: 'training_stage_career_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Training_Stage_Career>;
};

/** on_conflict condition type for table "training_stage_career" */
export type Training_Stage_Career_On_Conflict = {
  constraint: Training_Stage_Career_Constraint;
  update_columns?: Array<Training_Stage_Career_Update_Column>;
  where?: InputMaybe<Training_Stage_Career_Bool_Exp>;
};

/** Ordering options when selecting data from "training_stage_career". */
export type Training_Stage_Career_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line?: InputMaybe<Order_By>;
  stage_career_detail?: InputMaybe<Stage_Career_Detail_Order_By>;
  stage_career_detail_id?: InputMaybe<Order_By>;
  training?: InputMaybe<Training_Order_By>;
  training_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training_stage_career */
export type Training_Stage_Career_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "training_stage_career" */
export enum Training_Stage_Career_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderLine = 'order_line',
  /** column name */
  StageCareerDetailId = 'stage_career_detail_id',
  /** column name */
  TrainingId = 'training_id',
}

/** input type for updating data in table "training_stage_career" */
export type Training_Stage_Career_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_career_detail_id?: InputMaybe<Scalars['uuid']>;
  training_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Training_Stage_Career_Stddev_Fields = {
  __typename?: 'training_stage_career_stddev_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "training_stage_career" */
export type Training_Stage_Career_Stddev_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Training_Stage_Career_Stddev_Pop_Fields = {
  __typename?: 'training_stage_career_stddev_pop_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "training_stage_career" */
export type Training_Stage_Career_Stddev_Pop_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Training_Stage_Career_Stddev_Samp_Fields = {
  __typename?: 'training_stage_career_stddev_samp_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "training_stage_career" */
export type Training_Stage_Career_Stddev_Samp_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "training_stage_career" */
export type Training_Stage_Career_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Stage_Career_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Stage_Career_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_line?: InputMaybe<Scalars['Int']>;
  stage_career_detail_id?: InputMaybe<Scalars['uuid']>;
  training_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Training_Stage_Career_Sum_Fields = {
  __typename?: 'training_stage_career_sum_fields';
  order_line?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "training_stage_career" */
export type Training_Stage_Career_Sum_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** update columns of table "training_stage_career" */
export enum Training_Stage_Career_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderLine = 'order_line',
  /** column name */
  StageCareerDetailId = 'stage_career_detail_id',
  /** column name */
  TrainingId = 'training_id',
}

export type Training_Stage_Career_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Training_Stage_Career_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Training_Stage_Career_Set_Input>;
  /** filter the rows which have to be updated */
  where: Training_Stage_Career_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Training_Stage_Career_Var_Pop_Fields = {
  __typename?: 'training_stage_career_var_pop_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "training_stage_career" */
export type Training_Stage_Career_Var_Pop_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Training_Stage_Career_Var_Samp_Fields = {
  __typename?: 'training_stage_career_var_samp_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "training_stage_career" */
export type Training_Stage_Career_Var_Samp_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Training_Stage_Career_Variance_Fields = {
  __typename?: 'training_stage_career_variance_fields';
  order_line?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "training_stage_career" */
export type Training_Stage_Career_Variance_Order_By = {
  order_line?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Training_Stddev_Fields = {
  __typename?: 'training_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  rewards?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "training" */
export type Training_Stddev_Order_By = {
  duration?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Training_Stddev_Pop_Fields = {
  __typename?: 'training_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  rewards?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "training" */
export type Training_Stddev_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Training_Stddev_Samp_Fields = {
  __typename?: 'training_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  rewards?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "training" */
export type Training_Stddev_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "training" */
export type Training_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  rewards?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Training_Sum_Fields = {
  __typename?: 'training_sum_fields';
  duration?: Maybe<Scalars['Int']>;
  rewards?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "training" */
export type Training_Sum_Order_By = {
  duration?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
};

/** columns and relationships of "training_table_of_contents" */
export type Training_Table_Of_Contents = {
  __typename?: 'training_table_of_contents';
  end_time?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  page?: Maybe<Scalars['Int']>;
  showContents: Scalars['Boolean'];
  start_time?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** An object relationship */
  training: Training;
  training_id: Scalars['uuid'];
  type: Scalars['String'];
};

/** aggregated selection of "training_table_of_contents" */
export type Training_Table_Of_Contents_Aggregate = {
  __typename?: 'training_table_of_contents_aggregate';
  aggregate?: Maybe<Training_Table_Of_Contents_Aggregate_Fields>;
  nodes: Array<Training_Table_Of_Contents>;
};

export type Training_Table_Of_Contents_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Training_Table_Of_Contents_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Training_Table_Of_Contents_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Training_Table_Of_Contents_Aggregate_Bool_Exp_Count>;
};

export type Training_Table_Of_Contents_Aggregate_Bool_Exp_Bool_And = {
  arguments: Training_Table_Of_Contents_Select_Column_Training_Table_Of_Contents_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Training_Table_Of_Contents_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Training_Table_Of_Contents_Select_Column_Training_Table_Of_Contents_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Training_Table_Of_Contents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Table_Of_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training_table_of_contents" */
export type Training_Table_Of_Contents_Aggregate_Fields = {
  __typename?: 'training_table_of_contents_aggregate_fields';
  avg?: Maybe<Training_Table_Of_Contents_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Training_Table_Of_Contents_Max_Fields>;
  min?: Maybe<Training_Table_Of_Contents_Min_Fields>;
  stddev?: Maybe<Training_Table_Of_Contents_Stddev_Fields>;
  stddev_pop?: Maybe<Training_Table_Of_Contents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Training_Table_Of_Contents_Stddev_Samp_Fields>;
  sum?: Maybe<Training_Table_Of_Contents_Sum_Fields>;
  var_pop?: Maybe<Training_Table_Of_Contents_Var_Pop_Fields>;
  var_samp?: Maybe<Training_Table_Of_Contents_Var_Samp_Fields>;
  variance?: Maybe<Training_Table_Of_Contents_Variance_Fields>;
};

/** aggregate fields of "training_table_of_contents" */
export type Training_Table_Of_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Table_Of_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Aggregate_Order_By = {
  avg?: InputMaybe<Training_Table_Of_Contents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Table_Of_Contents_Max_Order_By>;
  min?: InputMaybe<Training_Table_Of_Contents_Min_Order_By>;
  stddev?: InputMaybe<Training_Table_Of_Contents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Training_Table_Of_Contents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Training_Table_Of_Contents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Training_Table_Of_Contents_Sum_Order_By>;
  var_pop?: InputMaybe<Training_Table_Of_Contents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Training_Table_Of_Contents_Var_Samp_Order_By>;
  variance?: InputMaybe<Training_Table_Of_Contents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "training_table_of_contents" */
export type Training_Table_Of_Contents_Arr_Rel_Insert_Input = {
  data: Array<Training_Table_Of_Contents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_Table_Of_Contents_On_Conflict>;
};

/** aggregate avg on columns */
export type Training_Table_Of_Contents_Avg_Fields = {
  __typename?: 'training_table_of_contents_avg_fields';
  page?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Avg_Order_By = {
  page?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "training_table_of_contents". All fields are combined with a logical 'AND'. */
export type Training_Table_Of_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Table_Of_Contents_Bool_Exp>>;
  _not?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Table_Of_Contents_Bool_Exp>>;
  end_time?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  page?: InputMaybe<Int_Comparison_Exp>;
  showContents?: InputMaybe<Boolean_Comparison_Exp>;
  start_time?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  training?: InputMaybe<Training_Bool_Exp>;
  training_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "training_table_of_contents" */
export enum Training_Table_Of_Contents_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrainingTableOfContentsPkey = 'training_table_of_contents_pkey',
}

/** input type for incrementing numeric columns in table "training_table_of_contents" */
export type Training_Table_Of_Contents_Inc_Input = {
  page?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "training_table_of_contents" */
export type Training_Table_Of_Contents_Insert_Input = {
  end_time?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  page?: InputMaybe<Scalars['Int']>;
  showContents?: InputMaybe<Scalars['Boolean']>;
  start_time?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  training?: InputMaybe<Training_Obj_Rel_Insert_Input>;
  training_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Training_Table_Of_Contents_Max_Fields = {
  __typename?: 'training_table_of_contents_max_fields';
  end_time?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  page?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  training_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Max_Order_By = {
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  page?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Table_Of_Contents_Min_Fields = {
  __typename?: 'training_table_of_contents_min_fields';
  end_time?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  page?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  training_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Min_Order_By = {
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  page?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  training_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "training_table_of_contents" */
export type Training_Table_Of_Contents_Mutation_Response = {
  __typename?: 'training_table_of_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Training_Table_Of_Contents>;
};

/** on_conflict condition type for table "training_table_of_contents" */
export type Training_Table_Of_Contents_On_Conflict = {
  constraint: Training_Table_Of_Contents_Constraint;
  update_columns?: Array<Training_Table_Of_Contents_Update_Column>;
  where?: InputMaybe<Training_Table_Of_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "training_table_of_contents". */
export type Training_Table_Of_Contents_Order_By = {
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  page?: InputMaybe<Order_By>;
  showContents?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  training?: InputMaybe<Training_Order_By>;
  training_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training_table_of_contents */
export type Training_Table_Of_Contents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "training_table_of_contents" */
export enum Training_Table_Of_Contents_Select_Column {
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  Page = 'page',
  /** column name */
  ShowContents = 'showContents',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Title = 'title',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  Type = 'type',
}

/** select "training_table_of_contents_aggregate_bool_exp_bool_and_arguments_columns" columns of table "training_table_of_contents" */
export enum Training_Table_Of_Contents_Select_Column_Training_Table_Of_Contents_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShowContents = 'showContents',
}

/** select "training_table_of_contents_aggregate_bool_exp_bool_or_arguments_columns" columns of table "training_table_of_contents" */
export enum Training_Table_Of_Contents_Select_Column_Training_Table_Of_Contents_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShowContents = 'showContents',
}

/** input type for updating data in table "training_table_of_contents" */
export type Training_Table_Of_Contents_Set_Input = {
  end_time?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  page?: InputMaybe<Scalars['Int']>;
  showContents?: InputMaybe<Scalars['Boolean']>;
  start_time?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  training_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Training_Table_Of_Contents_Stddev_Fields = {
  __typename?: 'training_table_of_contents_stddev_fields';
  page?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Stddev_Order_By = {
  page?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Training_Table_Of_Contents_Stddev_Pop_Fields = {
  __typename?: 'training_table_of_contents_stddev_pop_fields';
  page?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Stddev_Pop_Order_By = {
  page?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Training_Table_Of_Contents_Stddev_Samp_Fields = {
  __typename?: 'training_table_of_contents_stddev_samp_fields';
  page?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Stddev_Samp_Order_By = {
  page?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "training_table_of_contents" */
export type Training_Table_Of_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Table_Of_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Table_Of_Contents_Stream_Cursor_Value_Input = {
  end_time?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  page?: InputMaybe<Scalars['Int']>;
  showContents?: InputMaybe<Scalars['Boolean']>;
  start_time?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  training_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Training_Table_Of_Contents_Sum_Fields = {
  __typename?: 'training_table_of_contents_sum_fields';
  page?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Sum_Order_By = {
  page?: InputMaybe<Order_By>;
};

/** update columns of table "training_table_of_contents" */
export enum Training_Table_Of_Contents_Update_Column {
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  Page = 'page',
  /** column name */
  ShowContents = 'showContents',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Title = 'title',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  Type = 'type',
}

export type Training_Table_Of_Contents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Training_Table_Of_Contents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Training_Table_Of_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Training_Table_Of_Contents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Training_Table_Of_Contents_Var_Pop_Fields = {
  __typename?: 'training_table_of_contents_var_pop_fields';
  page?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Var_Pop_Order_By = {
  page?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Training_Table_Of_Contents_Var_Samp_Fields = {
  __typename?: 'training_table_of_contents_var_samp_fields';
  page?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Var_Samp_Order_By = {
  page?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Training_Table_Of_Contents_Variance_Fields = {
  __typename?: 'training_table_of_contents_variance_fields';
  page?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "training_table_of_contents" */
export type Training_Table_Of_Contents_Variance_Order_By = {
  page?: InputMaybe<Order_By>;
};

/** columns and relationships of "training_type_for_training" */
export type Training_Type_For_Training = {
  __typename?: 'training_type_for_training';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  meta_tags?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  status: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "training_type_for_training" */
export type Training_Type_For_Training_Aggregate = {
  __typename?: 'training_type_for_training_aggregate';
  aggregate?: Maybe<Training_Type_For_Training_Aggregate_Fields>;
  nodes: Array<Training_Type_For_Training>;
};

export type Training_Type_For_Training_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Training_Type_For_Training_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Training_Type_For_Training_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Training_Type_For_Training_Aggregate_Bool_Exp_Count>;
};

export type Training_Type_For_Training_Aggregate_Bool_Exp_Bool_And = {
  arguments: Training_Type_For_Training_Select_Column_Training_Type_For_Training_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Training_Type_For_Training_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Training_Type_For_Training_Select_Column_Training_Type_For_Training_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Training_Type_For_Training_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training_type_for_training" */
export type Training_Type_For_Training_Aggregate_Fields = {
  __typename?: 'training_type_for_training_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Training_Type_For_Training_Max_Fields>;
  min?: Maybe<Training_Type_For_Training_Min_Fields>;
};

/** aggregate fields of "training_type_for_training" */
export type Training_Type_For_Training_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training_type_for_training" */
export type Training_Type_For_Training_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Type_For_Training_Max_Order_By>;
  min?: InputMaybe<Training_Type_For_Training_Min_Order_By>;
};

/** input type for inserting array relation for remote table "training_type_for_training" */
export type Training_Type_For_Training_Arr_Rel_Insert_Input = {
  data: Array<Training_Type_For_Training_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Training_Type_For_Training_On_Conflict>;
};

/** Boolean expression to filter rows from the table "training_type_for_training". All fields are combined with a logical 'AND'. */
export type Training_Type_For_Training_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Type_For_Training_Bool_Exp>>;
  _not?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Type_For_Training_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meta_tags?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "training_type_for_training" */
export enum Training_Type_For_Training_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrainingTypeForTrainingPkey = 'training_type_for_training_pkey',
}

/** input type for inserting data into table "training_type_for_training" */
export type Training_Type_For_Training_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta_tags?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Training_Type_For_Training_Max_Fields = {
  __typename?: 'training_type_for_training_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meta_tags?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "training_type_for_training" */
export type Training_Type_For_Training_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_tags?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Type_For_Training_Min_Fields = {
  __typename?: 'training_type_for_training_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meta_tags?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "training_type_for_training" */
export type Training_Type_For_Training_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_tags?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "training_type_for_training" */
export type Training_Type_For_Training_Mutation_Response = {
  __typename?: 'training_type_for_training_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Training_Type_For_Training>;
};

/** on_conflict condition type for table "training_type_for_training" */
export type Training_Type_For_Training_On_Conflict = {
  constraint: Training_Type_For_Training_Constraint;
  update_columns?: Array<Training_Type_For_Training_Update_Column>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

/** Ordering options when selecting data from "training_type_for_training". */
export type Training_Type_For_Training_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_tags?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training_type_for_training */
export type Training_Type_For_Training_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "training_type_for_training" */
export enum Training_Type_For_Training_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetaTags = 'meta_tags',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "training_type_for_training_aggregate_bool_exp_bool_and_arguments_columns" columns of table "training_type_for_training" */
export enum Training_Type_For_Training_Select_Column_Training_Type_For_Training_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Status = 'status',
}

/** select "training_type_for_training_aggregate_bool_exp_bool_or_arguments_columns" columns of table "training_type_for_training" */
export enum Training_Type_For_Training_Select_Column_Training_Type_For_Training_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "training_type_for_training" */
export type Training_Type_For_Training_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta_tags?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "training_type_for_training" */
export type Training_Type_For_Training_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Type_For_Training_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Type_For_Training_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta_tags?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "training_type_for_training" */
export enum Training_Type_For_Training_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetaTags = 'meta_tags',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Training_Type_For_Training_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Training_Type_For_Training_Set_Input>;
  /** filter the rows which have to be updated */
  where: Training_Type_For_Training_Bool_Exp;
};

/** update columns of table "training" */
export enum Training_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Rewards = 'rewards',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

export type Training_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Training_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Training_Set_Input>;
  /** filter the rows which have to be updated */
  where: Training_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Training_Var_Pop_Fields = {
  __typename?: 'training_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  rewards?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "training" */
export type Training_Var_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Training_Var_Samp_Fields = {
  __typename?: 'training_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  rewards?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "training" */
export type Training_Var_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Training_Variance_Fields = {
  __typename?: 'training_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  rewards?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "training" */
export type Training_Variance_Order_By = {
  duration?: InputMaybe<Order_By>;
  rewards?: InputMaybe<Order_By>;
};

export type UpdateRoleInput = {
  roleId: Scalars['uuid'];
};

/** fields of action: "updateTaskRank" */
export type UpdateTaskRank = {
  __typename?: 'updateTaskRank';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']>;
  /** the unique id of an action */
  id: Scalars['uuid'];
  /** the output fields of this action */
  output?: Maybe<UpdateTaskRankOutput>;
};

export type UpdateTrainingInput = {
  duration?: InputMaybe<Scalars['Int']>;
  fileFormat: Scalars['String'];
  id: Scalars['String'];
  imageId: Scalars['uuid'];
  organizationId: Scalars['uuid'];
  rewards: Scalars['Int'];
  tags?: InputMaybe<Scalars['String']>;
  trainingName: Scalars['String'];
  trainingType: Scalars['String'];
  training_Skills?: InputMaybe<Array<InputMaybe<UpdateTrainingSkillsInput>>>;
  training_role_details: Array<InputMaybe<UpdateRoleInput>>;
  training_table_of_contents?: InputMaybe<
    Array<InputMaybe<UpdatetableContents>>
  >;
  userId: Scalars['uuid'];
};

export type UpdateTrainingOutput = {
  __typename?: 'updateTrainingOutput';
  id: Scalars['uuid'];
};

export type UpdatetableContents = {
  endTime?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  showContent?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "used_leave_view" */
export type Used_Leave_View = {
  __typename?: 'used_leave_view';
  allocated_days?: Maybe<Scalars['numeric']>;
  leave_type?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "used_leave_view" */
export type Used_Leave_View_Aggregate = {
  __typename?: 'used_leave_view_aggregate';
  aggregate?: Maybe<Used_Leave_View_Aggregate_Fields>;
  nodes: Array<Used_Leave_View>;
};

/** aggregate fields of "used_leave_view" */
export type Used_Leave_View_Aggregate_Fields = {
  __typename?: 'used_leave_view_aggregate_fields';
  avg?: Maybe<Used_Leave_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Used_Leave_View_Max_Fields>;
  min?: Maybe<Used_Leave_View_Min_Fields>;
  stddev?: Maybe<Used_Leave_View_Stddev_Fields>;
  stddev_pop?: Maybe<Used_Leave_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Used_Leave_View_Stddev_Samp_Fields>;
  sum?: Maybe<Used_Leave_View_Sum_Fields>;
  var_pop?: Maybe<Used_Leave_View_Var_Pop_Fields>;
  var_samp?: Maybe<Used_Leave_View_Var_Samp_Fields>;
  variance?: Maybe<Used_Leave_View_Variance_Fields>;
};

/** aggregate fields of "used_leave_view" */
export type Used_Leave_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Used_Leave_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Used_Leave_View_Avg_Fields = {
  __typename?: 'used_leave_view_avg_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "used_leave_view". All fields are combined with a logical 'AND'. */
export type Used_Leave_View_Bool_Exp = {
  _and?: InputMaybe<Array<Used_Leave_View_Bool_Exp>>;
  _not?: InputMaybe<Used_Leave_View_Bool_Exp>;
  _or?: InputMaybe<Array<Used_Leave_View_Bool_Exp>>;
  allocated_days?: InputMaybe<Numeric_Comparison_Exp>;
  leave_type?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  submitted_by?: InputMaybe<Uuid_Comparison_Exp>;
  sum?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Used_Leave_View_Max_Fields = {
  __typename?: 'used_leave_view_max_fields';
  allocated_days?: Maybe<Scalars['numeric']>;
  leave_type?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Used_Leave_View_Min_Fields = {
  __typename?: 'used_leave_view_min_fields';
  allocated_days?: Maybe<Scalars['numeric']>;
  leave_type?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "used_leave_view". */
export type Used_Leave_View_Order_By = {
  allocated_days?: InputMaybe<Order_By>;
  leave_type?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
  sum?: InputMaybe<Order_By>;
};

/** select columns of table "used_leave_view" */
export enum Used_Leave_View_Select_Column {
  /** column name */
  AllocatedDays = 'allocated_days',
  /** column name */
  LeaveType = 'leave_type',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  Sum = 'sum',
}

/** aggregate stddev on columns */
export type Used_Leave_View_Stddev_Fields = {
  __typename?: 'used_leave_view_stddev_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Used_Leave_View_Stddev_Pop_Fields = {
  __typename?: 'used_leave_view_stddev_pop_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Used_Leave_View_Stddev_Samp_Fields = {
  __typename?: 'used_leave_view_stddev_samp_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "used_leave_view" */
export type Used_Leave_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Used_Leave_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Used_Leave_View_Stream_Cursor_Value_Input = {
  allocated_days?: InputMaybe<Scalars['numeric']>;
  leave_type?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  submitted_by?: InputMaybe<Scalars['uuid']>;
  sum?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Used_Leave_View_Sum_Fields = {
  __typename?: 'used_leave_view_sum_fields';
  allocated_days?: Maybe<Scalars['numeric']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Used_Leave_View_Var_Pop_Fields = {
  __typename?: 'used_leave_view_var_pop_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Used_Leave_View_Var_Samp_Fields = {
  __typename?: 'used_leave_view_var_samp_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Used_Leave_View_Variance_Fields = {
  __typename?: 'used_leave_view_variance_fields';
  allocated_days?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_Competency" */
export type User_Competency = {
  __typename?: 'user_Competency';
  competencyId: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  main_skill: Main_Skills;
  managerId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByManagerid?: Maybe<Users>;
  userId: Scalars['uuid'];
  /** An array relationship */
  user_Skills: Array<User_Skills>;
  /** An aggregate relationship */
  user_Skills_aggregate: User_Skills_Aggregate;
};

/** columns and relationships of "user_Competency" */
export type User_CompetencyUser_SkillsArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Order_By>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

/** columns and relationships of "user_Competency" */
export type User_CompetencyUser_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Order_By>>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

/** aggregated selection of "user_Competency" */
export type User_Competency_Aggregate = {
  __typename?: 'user_Competency_aggregate';
  aggregate?: Maybe<User_Competency_Aggregate_Fields>;
  nodes: Array<User_Competency>;
};

export type User_Competency_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Competency_Aggregate_Bool_Exp_Count>;
};

export type User_Competency_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Competency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Competency_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_Competency" */
export type User_Competency_Aggregate_Fields = {
  __typename?: 'user_Competency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Competency_Max_Fields>;
  min?: Maybe<User_Competency_Min_Fields>;
};

/** aggregate fields of "user_Competency" */
export type User_Competency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Competency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_Competency" */
export type User_Competency_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Competency_Max_Order_By>;
  min?: InputMaybe<User_Competency_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_Competency" */
export type User_Competency_Arr_Rel_Insert_Input = {
  data: Array<User_Competency_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Competency_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_Competency". All fields are combined with a logical 'AND'. */
export type User_Competency_Bool_Exp = {
  _and?: InputMaybe<Array<User_Competency_Bool_Exp>>;
  _not?: InputMaybe<User_Competency_Bool_Exp>;
  _or?: InputMaybe<Array<User_Competency_Bool_Exp>>;
  competencyId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  main_skill?: InputMaybe<Main_Skills_Bool_Exp>;
  managerId?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByManagerid?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  user_Skills?: InputMaybe<User_Skills_Bool_Exp>;
  user_Skills_aggregate?: InputMaybe<User_Skills_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_Competency" */
export enum User_Competency_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCompetencyPkey = 'user_Competency_pkey',
}

/** input type for inserting data into table "user_Competency" */
export type User_Competency_Insert_Input = {
  competencyId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  main_skill?: InputMaybe<Main_Skills_Obj_Rel_Insert_Input>;
  managerId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByManagerid?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  user_Skills?: InputMaybe<User_Skills_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Competency_Max_Fields = {
  __typename?: 'user_Competency_max_fields';
  competencyId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  managerId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_Competency" */
export type User_Competency_Max_Order_By = {
  competencyId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  managerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Competency_Min_Fields = {
  __typename?: 'user_Competency_min_fields';
  competencyId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  managerId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_Competency" */
export type User_Competency_Min_Order_By = {
  competencyId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  managerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_Competency" */
export type User_Competency_Mutation_Response = {
  __typename?: 'user_Competency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Competency>;
};

/** input type for inserting object relation for remote table "user_Competency" */
export type User_Competency_Obj_Rel_Insert_Input = {
  data: User_Competency_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Competency_On_Conflict>;
};

/** on_conflict condition type for table "user_Competency" */
export type User_Competency_On_Conflict = {
  constraint: User_Competency_Constraint;
  update_columns?: Array<User_Competency_Update_Column>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

/** Ordering options when selecting data from "user_Competency". */
export type User_Competency_Order_By = {
  competencyId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_skill?: InputMaybe<Main_Skills_Order_By>;
  managerId?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByManagerid?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  user_Skills_aggregate?: InputMaybe<User_Skills_Aggregate_Order_By>;
};

/** primary key columns input for table: user_Competency */
export type User_Competency_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_Competency" */
export enum User_Competency_Select_Column {
  /** column name */
  CompetencyId = 'competencyId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "user_Competency" */
export type User_Competency_Set_Input = {
  competencyId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_Competency" */
export type User_Competency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Competency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Competency_Stream_Cursor_Value_Input = {
  competencyId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_Competency" */
export enum User_Competency_Update_Column {
  /** column name */
  CompetencyId = 'competencyId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  UserId = 'userId',
}

export type User_Competency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Competency_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Competency_Bool_Exp;
};

/** columns and relationships of "user_Skills" */
export type User_Skills = {
  __typename?: 'user_Skills';
  id: Scalars['uuid'];
  rating: Scalars['Int'];
  skill_Id: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  sub_skill: Sub_Skills;
  userCompt_Id: Scalars['uuid'];
  /** An object relationship */
  user_Competency: User_Competency;
  /** An array relationship */
  user_Skills_Assessments: Array<User_Skills_Assessment>;
  /** An aggregate relationship */
  user_Skills_Assessments_aggregate: User_Skills_Assessment_Aggregate;
  /** An array relationship */
  user_Skills_Trainings: Array<User_Skills_Training>;
  /** An aggregate relationship */
  user_Skills_Trainings_aggregate: User_Skills_Training_Aggregate;
};

/** columns and relationships of "user_Skills" */
export type User_SkillsUser_Skills_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Assessment_Order_By>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

/** columns and relationships of "user_Skills" */
export type User_SkillsUser_Skills_Assessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Assessment_Order_By>>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

/** columns and relationships of "user_Skills" */
export type User_SkillsUser_Skills_TrainingsArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Training_Order_By>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

/** columns and relationships of "user_Skills" */
export type User_SkillsUser_Skills_Trainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Skills_Training_Order_By>>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

/** columns and relationships of "user_Skills_Assessment" */
export type User_Skills_Assessment = {
  __typename?: 'user_Skills_Assessment';
  Status?: Maybe<Scalars['String']>;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  assessment_Id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  user_Skill?: Maybe<User_Skills>;
  user_Skills_Id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_Skills_Assessment" */
export type User_Skills_Assessment_Aggregate = {
  __typename?: 'user_Skills_Assessment_aggregate';
  aggregate?: Maybe<User_Skills_Assessment_Aggregate_Fields>;
  nodes: Array<User_Skills_Assessment>;
};

export type User_Skills_Assessment_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Skills_Assessment_Aggregate_Bool_Exp_Count>;
};

export type User_Skills_Assessment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_Skills_Assessment" */
export type User_Skills_Assessment_Aggregate_Fields = {
  __typename?: 'user_Skills_Assessment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Skills_Assessment_Max_Fields>;
  min?: Maybe<User_Skills_Assessment_Min_Fields>;
};

/** aggregate fields of "user_Skills_Assessment" */
export type User_Skills_Assessment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Skills_Assessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_Skills_Assessment" */
export type User_Skills_Assessment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Skills_Assessment_Max_Order_By>;
  min?: InputMaybe<User_Skills_Assessment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_Skills_Assessment" */
export type User_Skills_Assessment_Arr_Rel_Insert_Input = {
  data: Array<User_Skills_Assessment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Skills_Assessment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_Skills_Assessment". All fields are combined with a logical 'AND'. */
export type User_Skills_Assessment_Bool_Exp = {
  Status?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Skills_Assessment_Bool_Exp>>;
  _not?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
  _or?: InputMaybe<Array<User_Skills_Assessment_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  assessment_Id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_Skill?: InputMaybe<User_Skills_Bool_Exp>;
  user_Skills_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_Skills_Assessment" */
export enum User_Skills_Assessment_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSkillsAssessmentPkey = 'user_Skills_Assessment_pkey',
}

/** input type for inserting data into table "user_Skills_Assessment" */
export type User_Skills_Assessment_Insert_Input = {
  Status?: InputMaybe<Scalars['String']>;
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  assessment_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_Skill?: InputMaybe<User_Skills_Obj_Rel_Insert_Input>;
  user_Skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Skills_Assessment_Max_Fields = {
  __typename?: 'user_Skills_Assessment_max_fields';
  Status?: Maybe<Scalars['String']>;
  assessment_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_Skills_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_Skills_Assessment" */
export type User_Skills_Assessment_Max_Order_By = {
  Status?: InputMaybe<Order_By>;
  assessment_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_Skills_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Skills_Assessment_Min_Fields = {
  __typename?: 'user_Skills_Assessment_min_fields';
  Status?: Maybe<Scalars['String']>;
  assessment_Id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_Skills_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_Skills_Assessment" */
export type User_Skills_Assessment_Min_Order_By = {
  Status?: InputMaybe<Order_By>;
  assessment_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_Skills_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_Skills_Assessment" */
export type User_Skills_Assessment_Mutation_Response = {
  __typename?: 'user_Skills_Assessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Skills_Assessment>;
};

/** on_conflict condition type for table "user_Skills_Assessment" */
export type User_Skills_Assessment_On_Conflict = {
  constraint: User_Skills_Assessment_Constraint;
  update_columns?: Array<User_Skills_Assessment_Update_Column>;
  where?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
};

/** Ordering options when selecting data from "user_Skills_Assessment". */
export type User_Skills_Assessment_Order_By = {
  Status?: InputMaybe<Order_By>;
  assessment?: InputMaybe<Assessment_Order_By>;
  assessment_Id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_Skill?: InputMaybe<User_Skills_Order_By>;
  user_Skills_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_Skills_Assessment */
export type User_Skills_Assessment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_Skills_Assessment" */
export enum User_Skills_Assessment_Select_Column {
  /** column name */
  Status = 'Status',
  /** column name */
  AssessmentId = 'assessment_Id',
  /** column name */
  Id = 'id',
  /** column name */
  UserSkillsId = 'user_Skills_Id',
}

/** input type for updating data in table "user_Skills_Assessment" */
export type User_Skills_Assessment_Set_Input = {
  Status?: InputMaybe<Scalars['String']>;
  assessment_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_Skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_Skills_Assessment" */
export type User_Skills_Assessment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Skills_Assessment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Skills_Assessment_Stream_Cursor_Value_Input = {
  Status?: InputMaybe<Scalars['String']>;
  assessment_Id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_Skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_Skills_Assessment" */
export enum User_Skills_Assessment_Update_Column {
  /** column name */
  Status = 'Status',
  /** column name */
  AssessmentId = 'assessment_Id',
  /** column name */
  Id = 'id',
  /** column name */
  UserSkillsId = 'user_Skills_Id',
}

export type User_Skills_Assessment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Skills_Assessment_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Skills_Assessment_Bool_Exp;
};

/** columns and relationships of "user_Skills_Training" */
export type User_Skills_Training = {
  __typename?: 'user_Skills_Training';
  id: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  training?: Maybe<Training>;
  training_Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user_Skill?: Maybe<User_Skills>;
  user_Skills_Id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_Skills_Training" */
export type User_Skills_Training_Aggregate = {
  __typename?: 'user_Skills_Training_aggregate';
  aggregate?: Maybe<User_Skills_Training_Aggregate_Fields>;
  nodes: Array<User_Skills_Training>;
};

export type User_Skills_Training_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Skills_Training_Aggregate_Bool_Exp_Count>;
};

export type User_Skills_Training_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Skills_Training_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_Skills_Training" */
export type User_Skills_Training_Aggregate_Fields = {
  __typename?: 'user_Skills_Training_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Skills_Training_Max_Fields>;
  min?: Maybe<User_Skills_Training_Min_Fields>;
};

/** aggregate fields of "user_Skills_Training" */
export type User_Skills_Training_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Skills_Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_Skills_Training" */
export type User_Skills_Training_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Skills_Training_Max_Order_By>;
  min?: InputMaybe<User_Skills_Training_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_Skills_Training" */
export type User_Skills_Training_Arr_Rel_Insert_Input = {
  data: Array<User_Skills_Training_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Skills_Training_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_Skills_Training". All fields are combined with a logical 'AND'. */
export type User_Skills_Training_Bool_Exp = {
  _and?: InputMaybe<Array<User_Skills_Training_Bool_Exp>>;
  _not?: InputMaybe<User_Skills_Training_Bool_Exp>;
  _or?: InputMaybe<Array<User_Skills_Training_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  training?: InputMaybe<Training_Bool_Exp>;
  training_Id?: InputMaybe<Uuid_Comparison_Exp>;
  user_Skill?: InputMaybe<User_Skills_Bool_Exp>;
  user_Skills_Id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_Skills_Training" */
export enum User_Skills_Training_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSkillsTrainingPkey = 'user_Skills_Training_pkey',
}

/** input type for inserting data into table "user_Skills_Training" */
export type User_Skills_Training_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  training?: InputMaybe<Training_Obj_Rel_Insert_Input>;
  training_Id?: InputMaybe<Scalars['uuid']>;
  user_Skill?: InputMaybe<User_Skills_Obj_Rel_Insert_Input>;
  user_Skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Skills_Training_Max_Fields = {
  __typename?: 'user_Skills_Training_max_fields';
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  training_Id?: Maybe<Scalars['uuid']>;
  user_Skills_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_Skills_Training" */
export type User_Skills_Training_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  training_Id?: InputMaybe<Order_By>;
  user_Skills_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Skills_Training_Min_Fields = {
  __typename?: 'user_Skills_Training_min_fields';
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  training_Id?: Maybe<Scalars['uuid']>;
  user_Skills_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_Skills_Training" */
export type User_Skills_Training_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  training_Id?: InputMaybe<Order_By>;
  user_Skills_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_Skills_Training" */
export type User_Skills_Training_Mutation_Response = {
  __typename?: 'user_Skills_Training_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Skills_Training>;
};

/** on_conflict condition type for table "user_Skills_Training" */
export type User_Skills_Training_On_Conflict = {
  constraint: User_Skills_Training_Constraint;
  update_columns?: Array<User_Skills_Training_Update_Column>;
  where?: InputMaybe<User_Skills_Training_Bool_Exp>;
};

/** Ordering options when selecting data from "user_Skills_Training". */
export type User_Skills_Training_Order_By = {
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  training?: InputMaybe<Training_Order_By>;
  training_Id?: InputMaybe<Order_By>;
  user_Skill?: InputMaybe<User_Skills_Order_By>;
  user_Skills_Id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_Skills_Training */
export type User_Skills_Training_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_Skills_Training" */
export enum User_Skills_Training_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TrainingId = 'training_Id',
  /** column name */
  UserSkillsId = 'user_Skills_Id',
}

/** input type for updating data in table "user_Skills_Training" */
export type User_Skills_Training_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  training_Id?: InputMaybe<Scalars['uuid']>;
  user_Skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_Skills_Training" */
export type User_Skills_Training_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Skills_Training_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Skills_Training_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  training_Id?: InputMaybe<Scalars['uuid']>;
  user_Skills_Id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_Skills_Training" */
export enum User_Skills_Training_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TrainingId = 'training_Id',
  /** column name */
  UserSkillsId = 'user_Skills_Id',
}

export type User_Skills_Training_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Skills_Training_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Skills_Training_Bool_Exp;
};

/** aggregated selection of "user_Skills" */
export type User_Skills_Aggregate = {
  __typename?: 'user_Skills_aggregate';
  aggregate?: Maybe<User_Skills_Aggregate_Fields>;
  nodes: Array<User_Skills>;
};

export type User_Skills_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Skills_Aggregate_Bool_Exp_Count>;
};

export type User_Skills_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Skills_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_Skills" */
export type User_Skills_Aggregate_Fields = {
  __typename?: 'user_Skills_aggregate_fields';
  avg?: Maybe<User_Skills_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Skills_Max_Fields>;
  min?: Maybe<User_Skills_Min_Fields>;
  stddev?: Maybe<User_Skills_Stddev_Fields>;
  stddev_pop?: Maybe<User_Skills_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Skills_Stddev_Samp_Fields>;
  sum?: Maybe<User_Skills_Sum_Fields>;
  var_pop?: Maybe<User_Skills_Var_Pop_Fields>;
  var_samp?: Maybe<User_Skills_Var_Samp_Fields>;
  variance?: Maybe<User_Skills_Variance_Fields>;
};

/** aggregate fields of "user_Skills" */
export type User_Skills_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Skills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_Skills" */
export type User_Skills_Aggregate_Order_By = {
  avg?: InputMaybe<User_Skills_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Skills_Max_Order_By>;
  min?: InputMaybe<User_Skills_Min_Order_By>;
  stddev?: InputMaybe<User_Skills_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Skills_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Skills_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Skills_Sum_Order_By>;
  var_pop?: InputMaybe<User_Skills_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Skills_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Skills_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_Skills" */
export type User_Skills_Arr_Rel_Insert_Input = {
  data: Array<User_Skills_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Skills_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Skills_Avg_Fields = {
  __typename?: 'user_Skills_avg_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_Skills" */
export type User_Skills_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_Skills". All fields are combined with a logical 'AND'. */
export type User_Skills_Bool_Exp = {
  _and?: InputMaybe<Array<User_Skills_Bool_Exp>>;
  _not?: InputMaybe<User_Skills_Bool_Exp>;
  _or?: InputMaybe<Array<User_Skills_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  skill_Id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  sub_skill?: InputMaybe<Sub_Skills_Bool_Exp>;
  userCompt_Id?: InputMaybe<Uuid_Comparison_Exp>;
  user_Competency?: InputMaybe<User_Competency_Bool_Exp>;
  user_Skills_Assessments?: InputMaybe<User_Skills_Assessment_Bool_Exp>;
  user_Skills_Assessments_aggregate?: InputMaybe<User_Skills_Assessment_Aggregate_Bool_Exp>;
  user_Skills_Trainings?: InputMaybe<User_Skills_Training_Bool_Exp>;
  user_Skills_Trainings_aggregate?: InputMaybe<User_Skills_Training_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_Skills" */
export enum User_Skills_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSkillsPkey = 'user_Skills_pkey',
}

/** input type for incrementing numeric columns in table "user_Skills" */
export type User_Skills_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_Skills" */
export type User_Skills_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  skill_Id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  sub_skill?: InputMaybe<Sub_Skills_Obj_Rel_Insert_Input>;
  userCompt_Id?: InputMaybe<Scalars['uuid']>;
  user_Competency?: InputMaybe<User_Competency_Obj_Rel_Insert_Input>;
  user_Skills_Assessments?: InputMaybe<User_Skills_Assessment_Arr_Rel_Insert_Input>;
  user_Skills_Trainings?: InputMaybe<User_Skills_Training_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Skills_Max_Fields = {
  __typename?: 'user_Skills_max_fields';
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
  skill_Id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  userCompt_Id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_Skills" */
export type User_Skills_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skill_Id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userCompt_Id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Skills_Min_Fields = {
  __typename?: 'user_Skills_min_fields';
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
  skill_Id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  userCompt_Id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_Skills" */
export type User_Skills_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skill_Id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userCompt_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_Skills" */
export type User_Skills_Mutation_Response = {
  __typename?: 'user_Skills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Skills>;
};

/** input type for inserting object relation for remote table "user_Skills" */
export type User_Skills_Obj_Rel_Insert_Input = {
  data: User_Skills_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Skills_On_Conflict>;
};

/** on_conflict condition type for table "user_Skills" */
export type User_Skills_On_Conflict = {
  constraint: User_Skills_Constraint;
  update_columns?: Array<User_Skills_Update_Column>;
  where?: InputMaybe<User_Skills_Bool_Exp>;
};

/** Ordering options when selecting data from "user_Skills". */
export type User_Skills_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skill_Id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sub_skill?: InputMaybe<Sub_Skills_Order_By>;
  userCompt_Id?: InputMaybe<Order_By>;
  user_Competency?: InputMaybe<User_Competency_Order_By>;
  user_Skills_Assessments_aggregate?: InputMaybe<User_Skills_Assessment_Aggregate_Order_By>;
  user_Skills_Trainings_aggregate?: InputMaybe<User_Skills_Training_Aggregate_Order_By>;
};

/** primary key columns input for table: user_Skills */
export type User_Skills_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_Skills" */
export enum User_Skills_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  SkillId = 'skill_Id',
  /** column name */
  Status = 'status',
  /** column name */
  UserComptId = 'userCompt_Id',
}

/** input type for updating data in table "user_Skills" */
export type User_Skills_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  skill_Id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  userCompt_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Skills_Stddev_Fields = {
  __typename?: 'user_Skills_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_Skills" */
export type User_Skills_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Skills_Stddev_Pop_Fields = {
  __typename?: 'user_Skills_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_Skills" */
export type User_Skills_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Skills_Stddev_Samp_Fields = {
  __typename?: 'user_Skills_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_Skills" */
export type User_Skills_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_Skills" */
export type User_Skills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Skills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Skills_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  skill_Id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  userCompt_Id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Skills_Sum_Fields = {
  __typename?: 'user_Skills_sum_fields';
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_Skills" */
export type User_Skills_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "user_Skills" */
export enum User_Skills_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  SkillId = 'skill_Id',
  /** column name */
  Status = 'status',
  /** column name */
  UserComptId = 'userCompt_Id',
}

export type User_Skills_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Skills_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Skills_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Skills_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Skills_Var_Pop_Fields = {
  __typename?: 'user_Skills_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_Skills" */
export type User_Skills_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Skills_Var_Samp_Fields = {
  __typename?: 'user_Skills_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_Skills" */
export type User_Skills_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Skills_Variance_Fields = {
  __typename?: 'user_Skills_variance_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_Skills" */
export type User_Skills_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_activity_tracker" */
export type User_Activity_Tracker = {
  __typename?: 'user_activity_tracker';
  active_seconds: Scalars['bigint'];
  away_seconds: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_user_activity_tracker_status_type: Enum_User_Activity_Tracker_Status_Type;
  /** A computed field, executes function "get_user_extra_seconds" */
  extra_seconds?: Maybe<Scalars['bigint']>;
  id: Scalars['uuid'];
  last_status_updated_at: Scalars['timestamptz'];
  status: Enum_User_Activity_Tracker_Status_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_activity_tracker" */
export type User_Activity_Tracker_Aggregate = {
  __typename?: 'user_activity_tracker_aggregate';
  aggregate?: Maybe<User_Activity_Tracker_Aggregate_Fields>;
  nodes: Array<User_Activity_Tracker>;
};

export type User_Activity_Tracker_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Activity_Tracker_Aggregate_Bool_Exp_Count>;
};

export type User_Activity_Tracker_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_activity_tracker" */
export type User_Activity_Tracker_Aggregate_Fields = {
  __typename?: 'user_activity_tracker_aggregate_fields';
  avg?: Maybe<User_Activity_Tracker_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Activity_Tracker_Max_Fields>;
  min?: Maybe<User_Activity_Tracker_Min_Fields>;
  stddev?: Maybe<User_Activity_Tracker_Stddev_Fields>;
  stddev_pop?: Maybe<User_Activity_Tracker_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Activity_Tracker_Stddev_Samp_Fields>;
  sum?: Maybe<User_Activity_Tracker_Sum_Fields>;
  var_pop?: Maybe<User_Activity_Tracker_Var_Pop_Fields>;
  var_samp?: Maybe<User_Activity_Tracker_Var_Samp_Fields>;
  variance?: Maybe<User_Activity_Tracker_Variance_Fields>;
};

/** aggregate fields of "user_activity_tracker" */
export type User_Activity_Tracker_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_activity_tracker" */
export type User_Activity_Tracker_Aggregate_Order_By = {
  avg?: InputMaybe<User_Activity_Tracker_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Activity_Tracker_Max_Order_By>;
  min?: InputMaybe<User_Activity_Tracker_Min_Order_By>;
  stddev?: InputMaybe<User_Activity_Tracker_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Activity_Tracker_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Activity_Tracker_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Activity_Tracker_Sum_Order_By>;
  var_pop?: InputMaybe<User_Activity_Tracker_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Activity_Tracker_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Activity_Tracker_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_activity_tracker" */
export type User_Activity_Tracker_Arr_Rel_Insert_Input = {
  data: Array<User_Activity_Tracker_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Activity_Tracker_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Activity_Tracker_Avg_Fields = {
  __typename?: 'user_activity_tracker_avg_fields';
  active_seconds?: Maybe<Scalars['Float']>;
  away_seconds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Avg_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_activity_tracker". All fields are combined with a logical 'AND'. */
export type User_Activity_Tracker_Bool_Exp = {
  _and?: InputMaybe<Array<User_Activity_Tracker_Bool_Exp>>;
  _not?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
  _or?: InputMaybe<Array<User_Activity_Tracker_Bool_Exp>>;
  active_seconds?: InputMaybe<Bigint_Comparison_Exp>;
  away_seconds?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_user_activity_tracker_status_type?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Bool_Exp>;
  extra_seconds?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_status_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_activity_tracker" */
export enum User_Activity_Tracker_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserActivityTrackerPkey = 'user_activity_tracker_pkey',
}

/** input type for incrementing numeric columns in table "user_activity_tracker" */
export type User_Activity_Tracker_Inc_Input = {
  active_seconds?: InputMaybe<Scalars['bigint']>;
  away_seconds?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_activity_tracker" */
export type User_Activity_Tracker_Insert_Input = {
  active_seconds?: InputMaybe<Scalars['bigint']>;
  away_seconds?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_user_activity_tracker_status_type?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  last_status_updated_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Activity_Tracker_Max_Fields = {
  __typename?: 'user_activity_tracker_max_fields';
  active_seconds?: Maybe<Scalars['bigint']>;
  away_seconds?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_status_updated_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Max_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_status_updated_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Activity_Tracker_Min_Fields = {
  __typename?: 'user_activity_tracker_min_fields';
  active_seconds?: Maybe<Scalars['bigint']>;
  away_seconds?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_status_updated_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Min_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_status_updated_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_activity_tracker" */
export type User_Activity_Tracker_Mutation_Response = {
  __typename?: 'user_activity_tracker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Activity_Tracker>;
};

/** on_conflict condition type for table "user_activity_tracker" */
export type User_Activity_Tracker_On_Conflict = {
  constraint: User_Activity_Tracker_Constraint;
  update_columns?: Array<User_Activity_Tracker_Update_Column>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

/** Ordering options when selecting data from "user_activity_tracker". */
export type User_Activity_Tracker_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_user_activity_tracker_status_type?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Order_By>;
  extra_seconds?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_status_updated_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_activity_tracker */
export type User_Activity_Tracker_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_activity_tracker" */
export enum User_Activity_Tracker_Select_Column {
  /** column name */
  ActiveSeconds = 'active_seconds',
  /** column name */
  AwaySeconds = 'away_seconds',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastStatusUpdatedAt = 'last_status_updated_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_activity_tracker" */
export type User_Activity_Tracker_Set_Input = {
  active_seconds?: InputMaybe<Scalars['bigint']>;
  away_seconds?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_status_updated_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Activity_Tracker_Stddev_Fields = {
  __typename?: 'user_activity_tracker_stddev_fields';
  active_seconds?: Maybe<Scalars['Float']>;
  away_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Stddev_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Activity_Tracker_Stddev_Pop_Fields = {
  __typename?: 'user_activity_tracker_stddev_pop_fields';
  active_seconds?: Maybe<Scalars['Float']>;
  away_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Stddev_Pop_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Activity_Tracker_Stddev_Samp_Fields = {
  __typename?: 'user_activity_tracker_stddev_samp_fields';
  active_seconds?: Maybe<Scalars['Float']>;
  away_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Stddev_Samp_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_activity_tracker" */
export type User_Activity_Tracker_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Activity_Tracker_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Activity_Tracker_Stream_Cursor_Value_Input = {
  active_seconds?: InputMaybe<Scalars['bigint']>;
  away_seconds?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_status_updated_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Enum_User_Activity_Tracker_Status_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Activity_Tracker_Sum_Fields = {
  __typename?: 'user_activity_tracker_sum_fields';
  active_seconds?: Maybe<Scalars['bigint']>;
  away_seconds?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Sum_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
};

/** update columns of table "user_activity_tracker" */
export enum User_Activity_Tracker_Update_Column {
  /** column name */
  ActiveSeconds = 'active_seconds',
  /** column name */
  AwaySeconds = 'away_seconds',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastStatusUpdatedAt = 'last_status_updated_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Activity_Tracker_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Activity_Tracker_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Activity_Tracker_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Activity_Tracker_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Activity_Tracker_Var_Pop_Fields = {
  __typename?: 'user_activity_tracker_var_pop_fields';
  active_seconds?: Maybe<Scalars['Float']>;
  away_seconds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Var_Pop_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Activity_Tracker_Var_Samp_Fields = {
  __typename?: 'user_activity_tracker_var_samp_fields';
  active_seconds?: Maybe<Scalars['Float']>;
  away_seconds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Var_Samp_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Activity_Tracker_Variance_Fields = {
  __typename?: 'user_activity_tracker_variance_fields';
  active_seconds?: Maybe<Scalars['Float']>;
  away_seconds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_activity_tracker" */
export type User_Activity_Tracker_Variance_Order_By = {
  active_seconds?: InputMaybe<Order_By>;
  away_seconds?: InputMaybe<Order_By>;
};

/** Avatars as per sdk profile id */
export type User_Avatar = {
  __typename?: 'user_avatar';
  player_id: Scalars['uuid'];
  player_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_avatar" */
export type User_Avatar_Aggregate = {
  __typename?: 'user_avatar_aggregate';
  aggregate?: Maybe<User_Avatar_Aggregate_Fields>;
  nodes: Array<User_Avatar>;
};

/** aggregate fields of "user_avatar" */
export type User_Avatar_Aggregate_Fields = {
  __typename?: 'user_avatar_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Avatar_Max_Fields>;
  min?: Maybe<User_Avatar_Min_Fields>;
};

/** aggregate fields of "user_avatar" */
export type User_Avatar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Avatar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_avatar". All fields are combined with a logical 'AND'. */
export type User_Avatar_Bool_Exp = {
  _and?: InputMaybe<Array<User_Avatar_Bool_Exp>>;
  _not?: InputMaybe<User_Avatar_Bool_Exp>;
  _or?: InputMaybe<Array<User_Avatar_Bool_Exp>>;
  player_id?: InputMaybe<Uuid_Comparison_Exp>;
  player_url?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_avatar" */
export enum User_Avatar_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserAvatarPkey = 'user_avatar_pkey',
}

/** List of avatars for all users */
export type User_Avatar_Files = {
  __typename?: 'user_avatar_files';
  avatar_id: Scalars['uuid'];
  avatar_image_id: Scalars['uuid'];
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_user_avatar_type?: Maybe<Enum_User_Avatar_Types>;
  file_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  storage_file: Storage_Files;
  type?: Maybe<Enum_User_Avatar_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_avatar_files" */
export type User_Avatar_Files_Aggregate = {
  __typename?: 'user_avatar_files_aggregate';
  aggregate?: Maybe<User_Avatar_Files_Aggregate_Fields>;
  nodes: Array<User_Avatar_Files>;
};

export type User_Avatar_Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Avatar_Files_Aggregate_Bool_Exp_Count>;
};

export type User_Avatar_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Avatar_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_avatar_files" */
export type User_Avatar_Files_Aggregate_Fields = {
  __typename?: 'user_avatar_files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Avatar_Files_Max_Fields>;
  min?: Maybe<User_Avatar_Files_Min_Fields>;
};

/** aggregate fields of "user_avatar_files" */
export type User_Avatar_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_avatar_files" */
export type User_Avatar_Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Avatar_Files_Max_Order_By>;
  min?: InputMaybe<User_Avatar_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_avatar_files" */
export type User_Avatar_Files_Arr_Rel_Insert_Input = {
  data: Array<User_Avatar_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Avatar_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_avatar_files". All fields are combined with a logical 'AND'. */
export type User_Avatar_Files_Bool_Exp = {
  _and?: InputMaybe<Array<User_Avatar_Files_Bool_Exp>>;
  _not?: InputMaybe<User_Avatar_Files_Bool_Exp>;
  _or?: InputMaybe<Array<User_Avatar_Files_Bool_Exp>>;
  avatar_id?: InputMaybe<Uuid_Comparison_Exp>;
  avatar_image_id?: InputMaybe<Uuid_Comparison_Exp>;
  avatar_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_user_avatar_type?: InputMaybe<Enum_User_Avatar_Types_Bool_Exp>;
  file_url?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  type?: InputMaybe<Enum_User_Avatar_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_avatar_files" */
export enum User_Avatar_Files_Constraint {
  /** unique or primary key constraint on columns "avatar_id" */
  UserAvatarFilesPkey = 'user_avatar_files_pkey',
  /** unique or primary key constraint on columns "type", "user_id" */
  UserAvatarFilesUserIdTypeKey = 'user_avatar_files_user_id_type_key',
}

/** input type for inserting data into table "user_avatar_files" */
export type User_Avatar_Files_Insert_Input = {
  avatar_id?: InputMaybe<Scalars['uuid']>;
  avatar_image_id?: InputMaybe<Scalars['uuid']>;
  avatar_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_user_avatar_type?: InputMaybe<Enum_User_Avatar_Types_Obj_Rel_Insert_Input>;
  file_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Enum_User_Avatar_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Avatar_Files_Max_Fields = {
  __typename?: 'user_avatar_files_max_fields';
  avatar_id?: Maybe<Scalars['uuid']>;
  avatar_image_id?: Maybe<Scalars['uuid']>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_avatar_files" */
export type User_Avatar_Files_Max_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  avatar_image_id?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Avatar_Files_Min_Fields = {
  __typename?: 'user_avatar_files_min_fields';
  avatar_id?: Maybe<Scalars['uuid']>;
  avatar_image_id?: Maybe<Scalars['uuid']>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_avatar_files" */
export type User_Avatar_Files_Min_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  avatar_image_id?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_avatar_files" */
export type User_Avatar_Files_Mutation_Response = {
  __typename?: 'user_avatar_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Avatar_Files>;
};

/** on_conflict condition type for table "user_avatar_files" */
export type User_Avatar_Files_On_Conflict = {
  constraint: User_Avatar_Files_Constraint;
  update_columns?: Array<User_Avatar_Files_Update_Column>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "user_avatar_files". */
export type User_Avatar_Files_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  avatar_image_id?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_user_avatar_type?: InputMaybe<Enum_User_Avatar_Types_Order_By>;
  file_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_avatar_files */
export type User_Avatar_Files_Pk_Columns_Input = {
  avatar_id: Scalars['uuid'];
};

/** select columns of table "user_avatar_files" */
export enum User_Avatar_Files_Select_Column {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  AvatarImageId = 'avatar_image_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_avatar_files" */
export type User_Avatar_Files_Set_Input = {
  avatar_id?: InputMaybe<Scalars['uuid']>;
  avatar_image_id?: InputMaybe<Scalars['uuid']>;
  avatar_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_User_Avatar_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_avatar_files" */
export type User_Avatar_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Avatar_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Avatar_Files_Stream_Cursor_Value_Input = {
  avatar_id?: InputMaybe<Scalars['uuid']>;
  avatar_image_id?: InputMaybe<Scalars['uuid']>;
  avatar_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_User_Avatar_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_avatar_files" */
export enum User_Avatar_Files_Update_Column {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  AvatarImageId = 'avatar_image_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Avatar_Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Avatar_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Avatar_Files_Bool_Exp;
};

/** input type for inserting data into table "user_avatar" */
export type User_Avatar_Insert_Input = {
  player_id?: InputMaybe<Scalars['uuid']>;
  player_url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Avatar_Max_Fields = {
  __typename?: 'user_avatar_max_fields';
  player_id?: Maybe<Scalars['uuid']>;
  player_url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Avatar_Min_Fields = {
  __typename?: 'user_avatar_min_fields';
  player_id?: Maybe<Scalars['uuid']>;
  player_url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_avatar" */
export type User_Avatar_Mutation_Response = {
  __typename?: 'user_avatar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Avatar>;
};

/** on_conflict condition type for table "user_avatar" */
export type User_Avatar_On_Conflict = {
  constraint: User_Avatar_Constraint;
  update_columns?: Array<User_Avatar_Update_Column>;
  where?: InputMaybe<User_Avatar_Bool_Exp>;
};

/** Ordering options when selecting data from "user_avatar". */
export type User_Avatar_Order_By = {
  player_id?: InputMaybe<Order_By>;
  player_url?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_avatar */
export type User_Avatar_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_avatar" */
export enum User_Avatar_Select_Column {
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  PlayerUrl = 'player_url',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_avatar" */
export type User_Avatar_Set_Input = {
  player_id?: InputMaybe<Scalars['uuid']>;
  player_url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_avatar" */
export type User_Avatar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Avatar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Avatar_Stream_Cursor_Value_Input = {
  player_id?: InputMaybe<Scalars['uuid']>;
  player_url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_avatar" */
export enum User_Avatar_Update_Column {
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  PlayerUrl = 'player_url',
  /** column name */
  UserId = 'user_id',
}

export type User_Avatar_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Avatar_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Avatar_Bool_Exp;
};

/** columns and relationships of "user_career_development_record" */
export type User_Career_Development_Record = {
  __typename?: 'user_career_development_record';
  /** An object relationship */
  career_development_detail: Career_Development_Detail;
  career_development_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  progress: Scalars['float8'];
  stage: Scalars['String'];
  status: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_career_development_record" */
export type User_Career_Development_Record_Aggregate = {
  __typename?: 'user_career_development_record_aggregate';
  aggregate?: Maybe<User_Career_Development_Record_Aggregate_Fields>;
  nodes: Array<User_Career_Development_Record>;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp = {
  avg?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp_Var_Samp>;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Avg = {
  arguments: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Corr = {
  arguments: User_Career_Development_Record_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Corr_Arguments = {
  X: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: User_Career_Development_Record_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Covar_Samp_Arguments =
  {
    X: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
    Y: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  };

export type User_Career_Development_Record_Aggregate_Bool_Exp_Max = {
  arguments: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Min = {
  arguments: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Sum = {
  arguments: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Career_Development_Record_Aggregate_Bool_Exp_Var_Samp = {
  arguments: User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "user_career_development_record" */
export type User_Career_Development_Record_Aggregate_Fields = {
  __typename?: 'user_career_development_record_aggregate_fields';
  avg?: Maybe<User_Career_Development_Record_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Career_Development_Record_Max_Fields>;
  min?: Maybe<User_Career_Development_Record_Min_Fields>;
  stddev?: Maybe<User_Career_Development_Record_Stddev_Fields>;
  stddev_pop?: Maybe<User_Career_Development_Record_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Career_Development_Record_Stddev_Samp_Fields>;
  sum?: Maybe<User_Career_Development_Record_Sum_Fields>;
  var_pop?: Maybe<User_Career_Development_Record_Var_Pop_Fields>;
  var_samp?: Maybe<User_Career_Development_Record_Var_Samp_Fields>;
  variance?: Maybe<User_Career_Development_Record_Variance_Fields>;
};

/** aggregate fields of "user_career_development_record" */
export type User_Career_Development_Record_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_career_development_record" */
export type User_Career_Development_Record_Aggregate_Order_By = {
  avg?: InputMaybe<User_Career_Development_Record_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Career_Development_Record_Max_Order_By>;
  min?: InputMaybe<User_Career_Development_Record_Min_Order_By>;
  stddev?: InputMaybe<User_Career_Development_Record_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Career_Development_Record_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Career_Development_Record_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Career_Development_Record_Sum_Order_By>;
  var_pop?: InputMaybe<User_Career_Development_Record_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Career_Development_Record_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Career_Development_Record_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_career_development_record" */
export type User_Career_Development_Record_Arr_Rel_Insert_Input = {
  data: Array<User_Career_Development_Record_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Career_Development_Record_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Career_Development_Record_Avg_Fields = {
  __typename?: 'user_career_development_record_avg_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Avg_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_career_development_record". All fields are combined with a logical 'AND'. */
export type User_Career_Development_Record_Bool_Exp = {
  _and?: InputMaybe<Array<User_Career_Development_Record_Bool_Exp>>;
  _not?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  _or?: InputMaybe<Array<User_Career_Development_Record_Bool_Exp>>;
  career_development_detail?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  career_development_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  progress?: InputMaybe<Float8_Comparison_Exp>;
  stage?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_career_development_record" */
export enum User_Career_Development_Record_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCareerDevelopmentRecordPkey = 'user_career_development_record_pkey',
}

/** input type for incrementing numeric columns in table "user_career_development_record" */
export type User_Career_Development_Record_Inc_Input = {
  progress?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "user_career_development_record" */
export type User_Career_Development_Record_Insert_Input = {
  career_development_detail?: InputMaybe<Career_Development_Detail_Obj_Rel_Insert_Input>;
  career_development_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  progress?: InputMaybe<Scalars['float8']>;
  stage?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Career_Development_Record_Max_Fields = {
  __typename?: 'user_career_development_record_max_fields';
  career_development_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['float8']>;
  stage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Max_Order_By = {
  career_development_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Career_Development_Record_Min_Fields = {
  __typename?: 'user_career_development_record_min_fields';
  career_development_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['float8']>;
  stage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Min_Order_By = {
  career_development_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_career_development_record" */
export type User_Career_Development_Record_Mutation_Response = {
  __typename?: 'user_career_development_record_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Career_Development_Record>;
};

/** on_conflict condition type for table "user_career_development_record" */
export type User_Career_Development_Record_On_Conflict = {
  constraint: User_Career_Development_Record_Constraint;
  update_columns?: Array<User_Career_Development_Record_Update_Column>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

/** Ordering options when selecting data from "user_career_development_record". */
export type User_Career_Development_Record_Order_By = {
  career_development_detail?: InputMaybe<Career_Development_Detail_Order_By>;
  career_development_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_career_development_record */
export type User_Career_Development_Record_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column {
  /** column name */
  CareerDevelopmentId = 'career_development_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Progress = 'progress',
  /** column name */
  Stage = 'stage',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id',
}

/** select "user_career_development_record_aggregate_bool_exp_avg_arguments_columns" columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Progress = 'progress',
}

/** select "user_career_development_record_aggregate_bool_exp_corr_arguments_columns" columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Progress = 'progress',
}

/** select "user_career_development_record_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Progress = 'progress',
}

/** select "user_career_development_record_aggregate_bool_exp_max_arguments_columns" columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Progress = 'progress',
}

/** select "user_career_development_record_aggregate_bool_exp_min_arguments_columns" columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Progress = 'progress',
}

/** select "user_career_development_record_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Progress = 'progress',
}

/** select "user_career_development_record_aggregate_bool_exp_sum_arguments_columns" columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Progress = 'progress',
}

/** select "user_career_development_record_aggregate_bool_exp_var_samp_arguments_columns" columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Select_Column_User_Career_Development_Record_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Progress = 'progress',
}

/** input type for updating data in table "user_career_development_record" */
export type User_Career_Development_Record_Set_Input = {
  career_development_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  progress?: InputMaybe<Scalars['float8']>;
  stage?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Career_Development_Record_Stddev_Fields = {
  __typename?: 'user_career_development_record_stddev_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Stddev_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Career_Development_Record_Stddev_Pop_Fields = {
  __typename?: 'user_career_development_record_stddev_pop_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Stddev_Pop_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Career_Development_Record_Stddev_Samp_Fields = {
  __typename?: 'user_career_development_record_stddev_samp_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Stddev_Samp_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_career_development_record" */
export type User_Career_Development_Record_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Career_Development_Record_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Career_Development_Record_Stream_Cursor_Value_Input = {
  career_development_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  progress?: InputMaybe<Scalars['float8']>;
  stage?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Career_Development_Record_Sum_Fields = {
  __typename?: 'user_career_development_record_sum_fields';
  progress?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Sum_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** update columns of table "user_career_development_record" */
export enum User_Career_Development_Record_Update_Column {
  /** column name */
  CareerDevelopmentId = 'career_development_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Progress = 'progress',
  /** column name */
  Stage = 'stage',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id',
}

export type User_Career_Development_Record_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Career_Development_Record_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Career_Development_Record_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Career_Development_Record_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Career_Development_Record_Var_Pop_Fields = {
  __typename?: 'user_career_development_record_var_pop_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Var_Pop_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Career_Development_Record_Var_Samp_Fields = {
  __typename?: 'user_career_development_record_var_samp_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Var_Samp_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Career_Development_Record_Variance_Fields = {
  __typename?: 'user_career_development_record_variance_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_career_development_record" */
export type User_Career_Development_Record_Variance_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_career_external_certification" */
export type User_Career_External_Certification = {
  __typename?: 'user_career_external_certification';
  /** An object relationship */
  career_external_certification?: Maybe<Career_External_Certification>;
  career_external_certification_id?: Maybe<Scalars['uuid']>;
  certificate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  storage_file?: Maybe<Storage_Files>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_career_external_certification" */
export type User_Career_External_Certification_Aggregate = {
  __typename?: 'user_career_external_certification_aggregate';
  aggregate?: Maybe<User_Career_External_Certification_Aggregate_Fields>;
  nodes: Array<User_Career_External_Certification>;
};

export type User_Career_External_Certification_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Career_External_Certification_Aggregate_Bool_Exp_Count>;
};

export type User_Career_External_Certification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<User_Career_External_Certification_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_career_external_certification" */
export type User_Career_External_Certification_Aggregate_Fields = {
  __typename?: 'user_career_external_certification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Career_External_Certification_Max_Fields>;
  min?: Maybe<User_Career_External_Certification_Min_Fields>;
};

/** aggregate fields of "user_career_external_certification" */
export type User_Career_External_Certification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Career_External_Certification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_career_external_certification" */
export type User_Career_External_Certification_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Career_External_Certification_Max_Order_By>;
  min?: InputMaybe<User_Career_External_Certification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_career_external_certification" */
export type User_Career_External_Certification_Arr_Rel_Insert_Input = {
  data: Array<User_Career_External_Certification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Career_External_Certification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_career_external_certification". All fields are combined with a logical 'AND'. */
export type User_Career_External_Certification_Bool_Exp = {
  _and?: InputMaybe<Array<User_Career_External_Certification_Bool_Exp>>;
  _not?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
  _or?: InputMaybe<Array<User_Career_External_Certification_Bool_Exp>>;
  career_external_certification?: InputMaybe<Career_External_Certification_Bool_Exp>;
  career_external_certification_id?: InputMaybe<Uuid_Comparison_Exp>;
  certificate_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  storage_file?: InputMaybe<Storage_Files_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_career_external_certification" */
export enum User_Career_External_Certification_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCareerExternalCertificationPkey = 'user_career_external_certification_pkey',
}

/** input type for inserting data into table "user_career_external_certification" */
export type User_Career_External_Certification_Insert_Input = {
  career_external_certification?: InputMaybe<Career_External_Certification_Obj_Rel_Insert_Input>;
  career_external_certification_id?: InputMaybe<Scalars['uuid']>;
  certificate_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  storage_file?: InputMaybe<Storage_Files_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Career_External_Certification_Max_Fields = {
  __typename?: 'user_career_external_certification_max_fields';
  career_external_certification_id?: Maybe<Scalars['uuid']>;
  certificate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_career_external_certification" */
export type User_Career_External_Certification_Max_Order_By = {
  career_external_certification_id?: InputMaybe<Order_By>;
  certificate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Career_External_Certification_Min_Fields = {
  __typename?: 'user_career_external_certification_min_fields';
  career_external_certification_id?: Maybe<Scalars['uuid']>;
  certificate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_career_external_certification" */
export type User_Career_External_Certification_Min_Order_By = {
  career_external_certification_id?: InputMaybe<Order_By>;
  certificate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_career_external_certification" */
export type User_Career_External_Certification_Mutation_Response = {
  __typename?: 'user_career_external_certification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Career_External_Certification>;
};

/** on_conflict condition type for table "user_career_external_certification" */
export type User_Career_External_Certification_On_Conflict = {
  constraint: User_Career_External_Certification_Constraint;
  update_columns?: Array<User_Career_External_Certification_Update_Column>;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

/** Ordering options when selecting data from "user_career_external_certification". */
export type User_Career_External_Certification_Order_By = {
  career_external_certification?: InputMaybe<Career_External_Certification_Order_By>;
  career_external_certification_id?: InputMaybe<Order_By>;
  certificate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  storage_file?: InputMaybe<Storage_Files_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_career_external_certification */
export type User_Career_External_Certification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_career_external_certification" */
export enum User_Career_External_Certification_Select_Column {
  /** column name */
  CareerExternalCertificationId = 'career_external_certification_id',
  /** column name */
  CertificateId = 'certificate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_career_external_certification" */
export type User_Career_External_Certification_Set_Input = {
  career_external_certification_id?: InputMaybe<Scalars['uuid']>;
  certificate_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_career_external_certification" */
export type User_Career_External_Certification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Career_External_Certification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Career_External_Certification_Stream_Cursor_Value_Input = {
  career_external_certification_id?: InputMaybe<Scalars['uuid']>;
  certificate_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_career_external_certification" */
export enum User_Career_External_Certification_Update_Column {
  /** column name */
  CareerExternalCertificationId = 'career_external_certification_id',
  /** column name */
  CertificateId = 'certificate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

export type User_Career_External_Certification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Career_External_Certification_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Career_External_Certification_Bool_Exp;
};

/** Track presence for disconnected users */
export type User_Presence = {
  __typename?: 'user_presence';
  connection_id: Scalars['String'];
  connection_status: Enum_Presence_Connection_Status_Enum;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enum_presence_connection_status: Enum_Presence_Connection_Status;
  /** An object relationship */
  enum_user_presence_provider_type?: Maybe<Enum_User_Presence_Provider_Type>;
  metadata?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Enum_User_Presence_Provider_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['uuid'];
};

/** Track presence for disconnected users */
export type User_PresenceMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_presence" */
export type User_Presence_Aggregate = {
  __typename?: 'user_presence_aggregate';
  aggregate?: Maybe<User_Presence_Aggregate_Fields>;
  nodes: Array<User_Presence>;
};

export type User_Presence_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Presence_Aggregate_Bool_Exp_Count>;
};

export type User_Presence_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Presence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Presence_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_presence" */
export type User_Presence_Aggregate_Fields = {
  __typename?: 'user_presence_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Presence_Max_Fields>;
  min?: Maybe<User_Presence_Min_Fields>;
};

/** aggregate fields of "user_presence" */
export type User_Presence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Presence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_presence" */
export type User_Presence_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Presence_Max_Order_By>;
  min?: InputMaybe<User_Presence_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Presence_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_presence" */
export type User_Presence_Arr_Rel_Insert_Input = {
  data: Array<User_Presence_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Presence_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_presence". All fields are combined with a logical 'AND'. */
export type User_Presence_Bool_Exp = {
  _and?: InputMaybe<Array<User_Presence_Bool_Exp>>;
  _not?: InputMaybe<User_Presence_Bool_Exp>;
  _or?: InputMaybe<Array<User_Presence_Bool_Exp>>;
  connection_id?: InputMaybe<String_Comparison_Exp>;
  connection_status?: InputMaybe<Enum_Presence_Connection_Status_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_presence_connection_status?: InputMaybe<Enum_Presence_Connection_Status_Bool_Exp>;
  enum_user_presence_provider_type?: InputMaybe<Enum_User_Presence_Provider_Type_Bool_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Enum_User_Presence_Provider_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_presence" */
export enum User_Presence_Constraint {
  /** unique or primary key constraint on columns "connection_id" */
  UserPresenceConnectionIdKey = 'user_presence_connection_id_key',
  /** unique or primary key constraint on columns "user_id", "connection_id" */
  UserPresencePkey = 'user_presence_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserPresenceUserIdKey = 'user_presence_user_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Presence_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Presence_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Presence_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_presence" */
export type User_Presence_Insert_Input = {
  connection_id?: InputMaybe<Scalars['String']>;
  connection_status?: InputMaybe<Enum_Presence_Connection_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_presence_connection_status?: InputMaybe<Enum_Presence_Connection_Status_Obj_Rel_Insert_Input>;
  enum_user_presence_provider_type?: InputMaybe<Enum_User_Presence_Provider_Type_Obj_Rel_Insert_Input>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Enum_User_Presence_Provider_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Presence_Max_Fields = {
  __typename?: 'user_presence_max_fields';
  connection_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_presence" */
export type User_Presence_Max_Order_By = {
  connection_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Presence_Min_Fields = {
  __typename?: 'user_presence_min_fields';
  connection_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_presence" */
export type User_Presence_Min_Order_By = {
  connection_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_presence" */
export type User_Presence_Mutation_Response = {
  __typename?: 'user_presence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Presence>;
};

/** on_conflict condition type for table "user_presence" */
export type User_Presence_On_Conflict = {
  constraint: User_Presence_Constraint;
  update_columns?: Array<User_Presence_Update_Column>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};

/** Ordering options when selecting data from "user_presence". */
export type User_Presence_Order_By = {
  connection_id?: InputMaybe<Order_By>;
  connection_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_presence_connection_status?: InputMaybe<Enum_Presence_Connection_Status_Order_By>;
  enum_user_presence_provider_type?: InputMaybe<Enum_User_Presence_Provider_Type_Order_By>;
  metadata?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_presence */
export type User_Presence_Pk_Columns_Input = {
  connection_id: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Presence_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_presence" */
export enum User_Presence_Select_Column {
  /** column name */
  ConnectionId = 'connection_id',
  /** column name */
  ConnectionStatus = 'connection_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_presence" */
export type User_Presence_Set_Input = {
  connection_id?: InputMaybe<Scalars['String']>;
  connection_status?: InputMaybe<Enum_Presence_Connection_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Enum_User_Presence_Provider_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_presence" */
export type User_Presence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Presence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Presence_Stream_Cursor_Value_Input = {
  connection_id?: InputMaybe<Scalars['String']>;
  connection_status?: InputMaybe<Enum_Presence_Connection_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Enum_User_Presence_Provider_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_presence" */
export enum User_Presence_Update_Column {
  /** column name */
  ConnectionId = 'connection_id',
  /** column name */
  ConnectionStatus = 'connection_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Presence_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Presence_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Presence_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Presence_Bool_Exp;
};

/** Application settings of a user  */
export type User_Settings = {
  __typename?: 'user_settings';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  enum_user_settings_type: Enum_User_Settings_Type;
  id: Scalars['uuid'];
  setting_type: Enum_User_Settings_Type_Enum;
  setting_value: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** Application settings of a user  */
export type User_SettingsSetting_ValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_settings" */
export type User_Settings_Aggregate = {
  __typename?: 'user_settings_aggregate';
  aggregate?: Maybe<User_Settings_Aggregate_Fields>;
  nodes: Array<User_Settings>;
};

export type User_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Settings_Aggregate_Bool_Exp_Count>;
};

export type User_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_settings" */
export type User_Settings_Aggregate_Fields = {
  __typename?: 'user_settings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Settings_Max_Fields>;
  min?: Maybe<User_Settings_Min_Fields>;
};

/** aggregate fields of "user_settings" */
export type User_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_settings" */
export type User_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Settings_Max_Order_By>;
  min?: InputMaybe<User_Settings_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Settings_Append_Input = {
  setting_value?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_settings" */
export type User_Settings_Arr_Rel_Insert_Input = {
  data: Array<User_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_settings". All fields are combined with a logical 'AND'. */
export type User_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<User_Settings_Bool_Exp>>;
  _not?: InputMaybe<User_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<User_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  enum_user_settings_type?: InputMaybe<Enum_User_Settings_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  setting_type?: InputMaybe<Enum_User_Settings_Type_Enum_Comparison_Exp>;
  setting_value?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "user_settings" */
export enum User_Settings_Constraint {
  /** unique or primary key constraint on columns "created_by", "setting_type" */
  UserSettingsCreatedBySettingTypeKey = 'user_settings_created_by_setting_type_key',
  /** unique or primary key constraint on columns "id" */
  UserSettingsPkey = 'user_settings_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Settings_Delete_At_Path_Input = {
  setting_value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Settings_Delete_Elem_Input = {
  setting_value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Settings_Delete_Key_Input = {
  setting_value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_settings" */
export type User_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  enum_user_settings_type?: InputMaybe<Enum_User_Settings_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  setting_type?: InputMaybe<Enum_User_Settings_Type_Enum>;
  setting_value?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Settings_Max_Fields = {
  __typename?: 'user_settings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user_settings" */
export type User_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Settings_Min_Fields = {
  __typename?: 'user_settings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user_settings" */
export type User_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_settings" */
export type User_Settings_Mutation_Response = {
  __typename?: 'user_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Settings>;
};

/** on_conflict condition type for table "user_settings" */
export type User_Settings_On_Conflict = {
  constraint: User_Settings_Constraint;
  update_columns?: Array<User_Settings_Update_Column>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "user_settings". */
export type User_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enum_user_settings_type?: InputMaybe<Enum_User_Settings_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  setting_type?: InputMaybe<Order_By>;
  setting_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: user_settings */
export type User_Settings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Settings_Prepend_Input = {
  setting_value?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_settings" */
export enum User_Settings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  SettingType = 'setting_type',
  /** column name */
  SettingValue = 'setting_value',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user_settings" */
export type User_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  setting_type?: InputMaybe<Enum_User_Settings_Type_Enum>;
  setting_value?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user_settings" */
export type User_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  setting_type?: InputMaybe<Enum_User_Settings_Type_Enum>;
  setting_value?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user_settings" */
export enum User_Settings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  SettingType = 'setting_type',
  /** column name */
  SettingValue = 'setting_value',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type User_Settings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Settings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Settings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Settings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Settings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Settings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Settings_Bool_Exp;
};

/** columns and relationships of "user_state" */
export type User_State = {
  __typename?: 'user_state';
  activity_state: Enum_Activity_State_Enum;
  activity_state_last_updated: Scalars['timestamptz'];
  /** An object relationship */
  enum_activity_state: Enum_Activity_State;
  /** An object relationship */
  enum_manual_state: Enum_Manual_State;
  /** An object relationship */
  enum_system_state: Enum_System_State;
  /** An object relationship */
  enum_system_state_owner?: Maybe<Enum_System_State_Owner>;
  manual_state: Enum_Manual_State_Enum;
  /** A computed field, executes function "get_previous_activity_status" */
  previous_activity_status?: Maybe<Scalars['String']>;
  previous_user_state: Scalars['jsonb'];
  system_state: Enum_System_State_Enum;
  system_state_owner?: Maybe<Enum_System_State_Owner_Enum>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  user_state_since: Scalars['timestamptz'];
};

/** columns and relationships of "user_state" */
export type User_StatePrevious_User_StateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_state" */
export type User_State_Aggregate = {
  __typename?: 'user_state_aggregate';
  aggregate?: Maybe<User_State_Aggregate_Fields>;
  nodes: Array<User_State>;
};

export type User_State_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_State_Aggregate_Bool_Exp_Count>;
};

export type User_State_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_State_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_state" */
export type User_State_Aggregate_Fields = {
  __typename?: 'user_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_State_Max_Fields>;
  min?: Maybe<User_State_Min_Fields>;
};

/** aggregate fields of "user_state" */
export type User_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_state" */
export type User_State_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_State_Max_Order_By>;
  min?: InputMaybe<User_State_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_State_Append_Input = {
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_state" */
export type User_State_Arr_Rel_Insert_Input = {
  data: Array<User_State_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_State_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_state". All fields are combined with a logical 'AND'. */
export type User_State_Bool_Exp = {
  _and?: InputMaybe<Array<User_State_Bool_Exp>>;
  _not?: InputMaybe<User_State_Bool_Exp>;
  _or?: InputMaybe<Array<User_State_Bool_Exp>>;
  activity_state?: InputMaybe<Enum_Activity_State_Enum_Comparison_Exp>;
  activity_state_last_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_activity_state?: InputMaybe<Enum_Activity_State_Bool_Exp>;
  enum_manual_state?: InputMaybe<Enum_Manual_State_Bool_Exp>;
  enum_system_state?: InputMaybe<Enum_System_State_Bool_Exp>;
  enum_system_state_owner?: InputMaybe<Enum_System_State_Owner_Bool_Exp>;
  manual_state?: InputMaybe<Enum_Manual_State_Enum_Comparison_Exp>;
  previous_activity_status?: InputMaybe<String_Comparison_Exp>;
  previous_user_state?: InputMaybe<Jsonb_Comparison_Exp>;
  system_state?: InputMaybe<Enum_System_State_Enum_Comparison_Exp>;
  system_state_owner?: InputMaybe<Enum_System_State_Owner_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_state_since?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_state" */
export enum User_State_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserStatePkey = 'user_state_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_State_Delete_At_Path_Input = {
  previous_user_state?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_State_Delete_Elem_Input = {
  previous_user_state?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_State_Delete_Key_Input = {
  previous_user_state?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_state" */
export type User_State_Insert_Input = {
  activity_state?: InputMaybe<Enum_Activity_State_Enum>;
  activity_state_last_updated?: InputMaybe<Scalars['timestamptz']>;
  enum_activity_state?: InputMaybe<Enum_Activity_State_Obj_Rel_Insert_Input>;
  enum_manual_state?: InputMaybe<Enum_Manual_State_Obj_Rel_Insert_Input>;
  enum_system_state?: InputMaybe<Enum_System_State_Obj_Rel_Insert_Input>;
  enum_system_state_owner?: InputMaybe<Enum_System_State_Owner_Obj_Rel_Insert_Input>;
  manual_state?: InputMaybe<Enum_Manual_State_Enum>;
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
  system_state?: InputMaybe<Enum_System_State_Enum>;
  system_state_owner?: InputMaybe<Enum_System_State_Owner_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_state_since?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_State_Max_Fields = {
  __typename?: 'user_state_max_fields';
  activity_state_last_updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_state_since?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user_state" */
export type User_State_Max_Order_By = {
  activity_state_last_updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_state_since?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_State_Min_Fields = {
  __typename?: 'user_state_min_fields';
  activity_state_last_updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_state_since?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user_state" */
export type User_State_Min_Order_By = {
  activity_state_last_updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_state_since?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_state" */
export type User_State_Mutation_Response = {
  __typename?: 'user_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_State>;
};

/** input type for inserting object relation for remote table "user_state" */
export type User_State_Obj_Rel_Insert_Input = {
  data: User_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_State_On_Conflict>;
};

/** on_conflict condition type for table "user_state" */
export type User_State_On_Conflict = {
  constraint: User_State_Constraint;
  update_columns?: Array<User_State_Update_Column>;
  where?: InputMaybe<User_State_Bool_Exp>;
};

/** Ordering options when selecting data from "user_state". */
export type User_State_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  activity_state_last_updated?: InputMaybe<Order_By>;
  enum_activity_state?: InputMaybe<Enum_Activity_State_Order_By>;
  enum_manual_state?: InputMaybe<Enum_Manual_State_Order_By>;
  enum_system_state?: InputMaybe<Enum_System_State_Order_By>;
  enum_system_state_owner?: InputMaybe<Enum_System_State_Owner_Order_By>;
  manual_state?: InputMaybe<Order_By>;
  previous_activity_status?: InputMaybe<Order_By>;
  previous_user_state?: InputMaybe<Order_By>;
  system_state?: InputMaybe<Order_By>;
  system_state_owner?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_state_since?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_state */
export type User_State_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_State_Prepend_Input = {
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_state" */
export enum User_State_Select_Column {
  /** column name */
  ActivityState = 'activity_state',
  /** column name */
  ActivityStateLastUpdated = 'activity_state_last_updated',
  /** column name */
  ManualState = 'manual_state',
  /** column name */
  PreviousUserState = 'previous_user_state',
  /** column name */
  SystemState = 'system_state',
  /** column name */
  SystemStateOwner = 'system_state_owner',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserStateSince = 'user_state_since',
}

/** input type for updating data in table "user_state" */
export type User_State_Set_Input = {
  activity_state?: InputMaybe<Enum_Activity_State_Enum>;
  activity_state_last_updated?: InputMaybe<Scalars['timestamptz']>;
  manual_state?: InputMaybe<Enum_Manual_State_Enum>;
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
  system_state?: InputMaybe<Enum_System_State_Enum>;
  system_state_owner?: InputMaybe<Enum_System_State_Owner_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_state_since?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user_state" */
export type User_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_State_Stream_Cursor_Value_Input = {
  activity_state?: InputMaybe<Enum_Activity_State_Enum>;
  activity_state_last_updated?: InputMaybe<Scalars['timestamptz']>;
  manual_state?: InputMaybe<Enum_Manual_State_Enum>;
  previous_user_state?: InputMaybe<Scalars['jsonb']>;
  system_state?: InputMaybe<Enum_System_State_Enum>;
  system_state_owner?: InputMaybe<Enum_System_State_Owner_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_state_since?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user_state" */
export enum User_State_Update_Column {
  /** column name */
  ActivityState = 'activity_state',
  /** column name */
  ActivityStateLastUpdated = 'activity_state_last_updated',
  /** column name */
  ManualState = 'manual_state',
  /** column name */
  PreviousUserState = 'previous_user_state',
  /** column name */
  SystemState = 'system_state',
  /** column name */
  SystemStateOwner = 'system_state_owner',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserStateSince = 'user_state_since',
}

export type User_State_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_State_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_State_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_State_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_State_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_State_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_State_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  User_Peer_Reviews: Array<User_Peer_Review>;
  /** An aggregate relationship */
  User_Peer_Reviews_aggregate: User_Peer_Review_Aggregate;
  /** An object relationship */
  account?: Maybe<Auth_Accounts>;
  /** An array relationship */
  assessment_feedbacks: Array<Assessment_Feedback>;
  /** An aggregate relationship */
  assessment_feedbacks_aggregate: Assessment_Feedback_Aggregate;
  /** An array relationship */
  assessment_records: Array<Assessment_Record>;
  /** An aggregate relationship */
  assessment_records_aggregate: Assessment_Record_Aggregate;
  /** An array relationship */
  assessment_types: Array<Assessment_Type>;
  /** An aggregate relationship */
  assessment_types_aggregate: Assessment_Type_Aggregate;
  /** An array relationship */
  assessments: Array<Assessment>;
  /** An aggregate relationship */
  assessments_aggregate: Assessment_Aggregate;
  /** An array relationship */
  assign_career_development_plans: Array<Assign_Career_Development_Plan>;
  /** An aggregate relationship */
  assign_career_development_plans_aggregate: Assign_Career_Development_Plan_Aggregate;
  /** An array relationship */
  calendarAttendeesByCreatedBy: Array<Calendar_Attendee>;
  /** An aggregate relationship */
  calendarAttendeesByCreatedBy_aggregate: Calendar_Attendee_Aggregate;
  /** An array relationship */
  calendarMeetingCallRequestsByUserId: Array<Calendar_Meeting_Call_Request>;
  /** An aggregate relationship */
  calendarMeetingCallRequestsByUserId_aggregate: Calendar_Meeting_Call_Request_Aggregate;
  /** An array relationship */
  calendar_attendees: Array<Calendar_Attendee>;
  /** An aggregate relationship */
  calendar_attendees_aggregate: Calendar_Attendee_Aggregate;
  /** An array relationship */
  calendar_events: Array<Calendar_Event>;
  /** An aggregate relationship */
  calendar_events_aggregate: Calendar_Event_Aggregate;
  /** An array relationship */
  calendar_meeting_call_requests: Array<Calendar_Meeting_Call_Request>;
  /** An aggregate relationship */
  calendar_meeting_call_requests_aggregate: Calendar_Meeting_Call_Request_Aggregate;
  /** An array relationship */
  calendar_meeting_call_users: Array<Calendar_Meeting_Call_User>;
  /** An aggregate relationship */
  calendar_meeting_call_users_aggregate: Calendar_Meeting_Call_User_Aggregate;
  /** An array relationship */
  calendar_meeting_calls: Array<Calendar_Meeting_Call>;
  /** An aggregate relationship */
  calendar_meeting_calls_aggregate: Calendar_Meeting_Call_Aggregate;
  /** An array relationship */
  calendars: Array<Calendar>;
  /** An aggregate relationship */
  calendars_aggregate: Calendar_Aggregate;
  /** An array relationship */
  callRequestsByCreatedBy: Array<Call_Request>;
  /** An aggregate relationship */
  callRequestsByCreatedBy_aggregate: Call_Request_Aggregate;
  /** An array relationship */
  call_requests: Array<Call_Request>;
  /** An aggregate relationship */
  call_requests_aggregate: Call_Request_Aggregate;
  /** An array relationship */
  call_users: Array<Call_User>;
  /** An aggregate relationship */
  call_users_aggregate: Call_User_Aggregate;
  /** An array relationship */
  calls: Array<Call>;
  /** An aggregate relationship */
  calls_aggregate: Call_Aggregate;
  /** An array relationship */
  career_development_details: Array<Career_Development_Detail>;
  /** An aggregate relationship */
  career_development_details_aggregate: Career_Development_Detail_Aggregate;
  /** An array relationship */
  chat_message_actions: Array<Chat_Message_Action>;
  /** An aggregate relationship */
  chat_message_actions_aggregate: Chat_Message_Action_Aggregate;
  /** An array relationship */
  chat_messages: Array<Chat_Message>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Message_Aggregate;
  /** An array relationship */
  chat_room_users: Array<Chat_Room_User>;
  /** An aggregate relationship */
  chat_room_users_aggregate: Chat_Room_User_Aggregate;
  /** An array relationship */
  chat_rooms: Array<Chat_Room>;
  /** An aggregate relationship */
  chat_rooms_aggregate: Chat_Room_Aggregate;
  /** An array relationship */
  chat_rooms_created: Array<Chat_Room>;
  /** An aggregate relationship */
  chat_rooms_created_aggregate: Chat_Room_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  department_employees: Array<Department_Employee>;
  /** An aggregate relationship */
  department_employees_aggregate: Department_Employee_Aggregate;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  departmentsByManagerId: Array<Department>;
  /** An aggregate relationship */
  departmentsByManagerId_aggregate: Department_Aggregate;
  /** An aggregate relationship */
  departments_aggregate: Department_Aggregate;
  display_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  employee_bandwidth_data: Array<Employee_Bandwidth_Data>;
  /** An aggregate relationship */
  employee_bandwidth_data_aggregate: Employee_Bandwidth_Data_Aggregate;
  /** An array relationship */
  employee_capacities: Array<Employee_Capacity>;
  /** An aggregate relationship */
  employee_capacities_aggregate: Employee_Capacity_Aggregate;
  expecting_user?: Maybe<Scalars['jsonb']>;
  first_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  goalWithKpis: Array<GoalWithKpi>;
  /** An aggregate relationship */
  goalWithKpis_aggregate: GoalWithKpi_Aggregate;
  /** An array relationship */
  goalsByManagerId: Array<Goals>;
  /** An aggregate relationship */
  goalsByManagerId_aggregate: Goals_Aggregate;
  /** An array relationship */
  hotdesk_bookings: Array<Hotdesk_Bookings>;
  /** An aggregate relationship */
  hotdesk_bookings_aggregate: Hotdesk_Bookings_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  kpi_banks: Array<Kpi_Bank>;
  /** An aggregate relationship */
  kpi_banks_aggregate: Kpi_Bank_Aggregate;
  last_name?: Maybe<Scalars['String']>;
  last_sign_date_time?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  leaveDonatesByDonatedTo: Array<Leave_Donate>;
  /** An aggregate relationship */
  leaveDonatesByDonatedTo_aggregate: Leave_Donate_Aggregate;
  /** An array relationship */
  leave_donates: Array<Leave_Donate>;
  /** An aggregate relationship */
  leave_donates_aggregate: Leave_Donate_Aggregate;
  /** An array relationship */
  leave_organizations: Array<Leave_Organization>;
  /** An aggregate relationship */
  leave_organizations_aggregate: Leave_Organization_Aggregate;
  /** An array relationship */
  leaves: Array<Leave>;
  /** An array relationship */
  leavesBySubmittedBy: Array<Leave>;
  /** An aggregate relationship */
  leavesBySubmittedBy_aggregate: Leave_Aggregate;
  /** An aggregate relationship */
  leaves_aggregate: Leave_Aggregate;
  /** An array relationship */
  liveCallRequestsByUserId: Array<Live_Call_Request>;
  /** An aggregate relationship */
  liveCallRequestsByUserId_aggregate: Live_Call_Request_Aggregate;
  /** An array relationship */
  liveCallUsersByUserId: Array<Live_Call_User>;
  /** An aggregate relationship */
  liveCallUsersByUserId_aggregate: Live_Call_User_Aggregate;
  /** An array relationship */
  live_call_requests: Array<Live_Call_Request>;
  /** An aggregate relationship */
  live_call_requests_aggregate: Live_Call_Request_Aggregate;
  /** An array relationship */
  live_call_users: Array<Live_Call_User>;
  /** An aggregate relationship */
  live_call_users_aggregate: Live_Call_User_Aggregate;
  /** An array relationship */
  live_calls: Array<Live_Call>;
  /** An aggregate relationship */
  live_calls_aggregate: Live_Call_Aggregate;
  /** An array relationship */
  meetingRoomUsersByUserId: Array<Meeting_Room_Users>;
  /** An aggregate relationship */
  meetingRoomUsersByUserId_aggregate: Meeting_Room_Users_Aggregate;
  /** An array relationship */
  meeting_room_users: Array<Meeting_Room_Users>;
  /** An aggregate relationship */
  meeting_room_users_aggregate: Meeting_Room_Users_Aggregate;
  /** An array relationship */
  meeting_rooms: Array<Meeting_Room>;
  /** An aggregate relationship */
  meeting_rooms_aggregate: Meeting_Room_Aggregate;
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  my_organization?: Maybe<Organization_User>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An array relationship */
  notificationsByOwnedBy: Array<Notifications>;
  /** An aggregate relationship */
  notificationsByOwnedBy_aggregate: Notifications_Aggregate;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An array relationship */
  organization_admin: Array<Organization_Admin>;
  /** An aggregate relationship */
  organization_admin_aggregate: Organization_Admin_Aggregate;
  /** An array relationship */
  organization_admins: Array<Organization_Admin>;
  /** An aggregate relationship */
  organization_admins_aggregate: Organization_Admin_Aggregate;
  /** An array relationship */
  organization_employees: Array<Organization_Employee>;
  /** An aggregate relationship */
  organization_employees_aggregate: Organization_Employee_Aggregate;
  /** An array relationship */
  organization_user_leave_applications: Array<Organization_User_Leave_Application>;
  /** An aggregate relationship */
  organization_user_leave_applications_aggregate: Organization_User_Leave_Application_Aggregate;
  /** An array relationship */
  organizations: Array<Organization>;
  /** An array relationship */
  organizationsByOwnedBy: Array<Organization>;
  /** An aggregate relationship */
  organizationsByOwnedBy_aggregate: Organization_Aggregate;
  /** An aggregate relationship */
  organizations_aggregate: Organization_Aggregate;
  /** An array relationship */
  performanceReviewByusersByPeerId: Array<Performance_Review_ByUser>;
  /** An aggregate relationship */
  performanceReviewByusersByPeerId_aggregate: Performance_Review_ByUser_Aggregate;
  /** An array relationship */
  performanceReviewStatuses: Array<PerformanceReviewStatus>;
  /** An aggregate relationship */
  performanceReviewStatuses_aggregate: PerformanceReviewStatus_Aggregate;
  /** An array relationship */
  performance_review_byUsers: Array<Performance_Review_ByUser>;
  /** An aggregate relationship */
  performance_review_byUsers_aggregate: Performance_Review_ByUser_Aggregate;
  /** An array relationship */
  personaliseds: Array<Personalised>;
  /** An aggregate relationship */
  personaliseds_aggregate: Personalised_Aggregate;
  /** An array relationship */
  post_likes: Array<Post_Likes>;
  /** An aggregate relationship */
  post_likes_aggregate: Post_Likes_Aggregate;
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate;
  /** An array relationship */
  posts_comments: Array<Posts_Comments>;
  /** An aggregate relationship */
  posts_comments_aggregate: Posts_Comments_Aggregate;
  /** An array relationship */
  profile: Array<Profile>;
  /** An aggregate relationship */
  profile_aggregate: Profile_Aggregate;
  /** An array relationship */
  profilesByInvited: Array<Profile>;
  /** An aggregate relationship */
  profilesByInvited_aggregate: Profile_Aggregate;
  /** An array relationship */
  project_members: Array<Project_Members>;
  /** An aggregate relationship */
  project_members_aggregate: Project_Members_Aggregate;
  /** An array relationship */
  project_sprints: Array<Project_Sprints>;
  /** An aggregate relationship */
  project_sprints_aggregate: Project_Sprints_Aggregate;
  /** An array relationship */
  projects: Array<Project>;
  /** An array relationship */
  projectsByOwnedBy: Array<Project>;
  /** An aggregate relationship */
  projectsByOwnedBy_aggregate: Project_Aggregate;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  /** An array relationship */
  storage_files: Array<Storage_Files>;
  /** An aggregate relationship */
  storage_files_aggregate: Storage_Files_Aggregate;
  /** An array relationship */
  stress_data: Array<Stress_Data>;
  /** An aggregate relationship */
  stress_data_aggregate: Stress_Data_Aggregate;
  /** An array relationship */
  taskTimeSheetsByManagerBy: Array<Task_Time_Sheet>;
  /** An aggregate relationship */
  taskTimeSheetsByManagerBy_aggregate: Task_Time_Sheet_Aggregate;
  /** An array relationship */
  task_approvers: Array<Task_Approvers>;
  /** An aggregate relationship */
  task_approvers_aggregate: Task_Approvers_Aggregate;
  /** An array relationship */
  task_checklists: Array<Task_Checklist>;
  /** An aggregate relationship */
  task_checklists_aggregate: Task_Checklist_Aggregate;
  /** An array relationship */
  task_collaborators: Array<Task_Collaborator>;
  /** An aggregate relationship */
  task_collaborators_aggregate: Task_Collaborator_Aggregate;
  /** An array relationship */
  task_comment_likes: Array<Task_Comment_Like>;
  /** An aggregate relationship */
  task_comment_likes_aggregate: Task_Comment_Like_Aggregate;
  /** An array relationship */
  task_comments: Array<Task_Comment>;
  /** An aggregate relationship */
  task_comments_aggregate: Task_Comment_Aggregate;
  /** An array relationship */
  task_time_sheets: Array<Task_Time_Sheet>;
  /** An aggregate relationship */
  task_time_sheets_aggregate: Task_Time_Sheet_Aggregate;
  /** An array relationship */
  task_unscheduleds: Array<Task_Unscheduled>;
  /** An aggregate relationship */
  task_unscheduleds_aggregate: Task_Unscheduled_Aggregate;
  /** An array relationship */
  tasks: Array<Task>;
  /** An array relationship */
  tasksByAssignedBy: Array<Task>;
  /** An aggregate relationship */
  tasksByAssignedBy_aggregate: Task_Aggregate;
  /** An array relationship */
  tasksByCreatedBy: Array<Task>;
  /** An aggregate relationship */
  tasksByCreatedBy_aggregate: Task_Aggregate;
  /** An array relationship */
  tasksByUpdatedBy: Array<Task>;
  /** An aggregate relationship */
  tasksByUpdatedBy_aggregate: Task_Aggregate;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  /** An array relationship */
  training_feedbacks: Array<Training_Feedback>;
  /** An aggregate relationship */
  training_feedbacks_aggregate: Training_Feedback_Aggregate;
  /** An array relationship */
  training_progress_records: Array<Training_Progress_Record>;
  /** An aggregate relationship */
  training_progress_records_aggregate: Training_Progress_Record_Aggregate;
  /** An array relationship */
  training_type_for_trainings: Array<Training_Type_For_Training>;
  /** An aggregate relationship */
  training_type_for_trainings_aggregate: Training_Type_For_Training_Aggregate;
  /** An array relationship */
  trainings: Array<Training>;
  /** An aggregate relationship */
  trainings_aggregate: Training_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  userCompetenciesByManagerid: Array<User_Competency>;
  /** An aggregate relationship */
  userCompetenciesByManagerid_aggregate: User_Competency_Aggregate;
  /** An array relationship */
  userPeerReviewsByUserId: Array<User_Peer_Review>;
  /** An aggregate relationship */
  userPeerReviewsByUserId_aggregate: User_Peer_Review_Aggregate;
  /** An array relationship */
  user_Competencies: Array<User_Competency>;
  /** An aggregate relationship */
  user_Competencies_aggregate: User_Competency_Aggregate;
  /** An array relationship */
  user_activity_trackers: Array<User_Activity_Tracker>;
  /** An aggregate relationship */
  user_activity_trackers_aggregate: User_Activity_Tracker_Aggregate;
  /** An array relationship */
  user_avatar_files: Array<User_Avatar_Files>;
  /** An aggregate relationship */
  user_avatar_files_aggregate: User_Avatar_Files_Aggregate;
  /** An array relationship */
  user_career_development_records: Array<User_Career_Development_Record>;
  /** An aggregate relationship */
  user_career_development_records_aggregate: User_Career_Development_Record_Aggregate;
  /** An array relationship */
  user_career_external_certifications: Array<User_Career_External_Certification>;
  /** An aggregate relationship */
  user_career_external_certifications_aggregate: User_Career_External_Certification_Aggregate;
  /** An array relationship */
  user_settings: Array<User_Settings>;
  /** An aggregate relationship */
  user_settings_aggregate: User_Settings_Aggregate;
  /** An object relationship */
  user_state?: Maybe<User_State>;
};

/** columns and relationships of "users" */
export type UsersUser_Peer_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Peer_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessment_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessment_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Feedback_Order_By>>;
  where?: InputMaybe<Assessment_Feedback_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessment_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessment_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Record_Order_By>>;
  where?: InputMaybe<Assessment_Record_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessment_TypesArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessment_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
  where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssign_Career_Development_PlansArgs = {
  distinct_on?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assign_Career_Development_Plan_Order_By>>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssign_Career_Development_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assign_Career_Development_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assign_Career_Development_Plan_Order_By>>;
  where?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendarAttendeesByCreatedByArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendarAttendeesByCreatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendarMeetingCallRequestsByUserIdArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendarMeetingCallRequestsByUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_AttendeesArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_Attendees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Attendee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Attendee_Order_By>>;
  where?: InputMaybe<Calendar_Attendee_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_EventsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Event_Order_By>>;
  where?: InputMaybe<Calendar_Event_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_Meeting_Call_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_Meeting_Call_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Request_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_Meeting_Call_UsersArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_Meeting_Call_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_User_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_Meeting_CallsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendar_Meeting_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Meeting_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Meeting_Call_Order_By>>;
  where?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Order_By>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalendars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Order_By>>;
  where?: InputMaybe<Calendar_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCallRequestsByCreatedByArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCallRequestsByCreatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCall_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCall_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Request_Order_By>>;
  where?: InputMaybe<Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCall_UsersArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCall_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_User_Order_By>>;
  where?: InputMaybe<Call_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCallsArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCalls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Call_Order_By>>;
  where?: InputMaybe<Call_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCareer_Development_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCareer_Development_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Career_Development_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Career_Development_Detail_Order_By>>;
  where?: InputMaybe<Career_Development_Detail_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_Message_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Action_Order_By>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_Message_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Action_Order_By>>;
  where?: InputMaybe<Chat_Message_Action_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_User_Order_By>>;
  where?: InputMaybe<Chat_Room_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_Rooms_CreatedArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChat_Rooms_Created_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersDepartment_EmployeesArgs = {
  distinct_on?: InputMaybe<Array<Department_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Employee_Order_By>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersDepartment_Employees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Employee_Order_By>>;
  where?: InputMaybe<Department_Employee_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersDepartmentsByManagerIdArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersDepartmentsByManagerId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersDepartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersEmployee_Bandwidth_DataArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersEmployee_Bandwidth_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Bandwidth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Bandwidth_Data_Order_By>>;
  where?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersEmployee_CapacitiesArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_Order_By>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersEmployee_Capacities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Capacity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Capacity_Order_By>>;
  where?: InputMaybe<Employee_Capacity_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersExpecting_UserArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users" */
export type UsersGoalWithKpisArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersGoalWithKpis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoalWithKpi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoalWithKpi_Order_By>>;
  where?: InputMaybe<GoalWithKpi_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersGoalsByManagerIdArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersGoalsByManagerId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersHotdesk_BookingsArgs = {
  distinct_on?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hotdesk_Bookings_Order_By>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersHotdesk_Bookings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hotdesk_Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hotdesk_Bookings_Order_By>>;
  where?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersKpi_BanksArgs = {
  distinct_on?: InputMaybe<Array<Kpi_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kpi_Bank_Order_By>>;
  where?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersKpi_Banks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kpi_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kpi_Bank_Order_By>>;
  where?: InputMaybe<Kpi_Bank_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeaveDonatesByDonatedToArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeaveDonatesByDonatedTo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeave_DonatesArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeave_Donates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Donate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Donate_Order_By>>;
  where?: InputMaybe<Leave_Donate_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeave_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Organization_Order_By>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeave_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Organization_Order_By>>;
  where?: InputMaybe<Leave_Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeavesArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeavesBySubmittedByArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeavesBySubmittedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLeaves_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leave_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leave_Order_By>>;
  where?: InputMaybe<Leave_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLiveCallRequestsByUserIdArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLiveCallRequestsByUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLiveCallUsersByUserIdArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLiveCallUsersByUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLive_Call_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLive_Call_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Request_Order_By>>;
  where?: InputMaybe<Live_Call_Request_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLive_Call_UsersArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLive_Call_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_User_Order_By>>;
  where?: InputMaybe<Live_Call_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLive_CallsArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Order_By>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLive_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_Order_By>>;
  where?: InputMaybe<Live_Call_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersMeetingRoomUsersByUserIdArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersMeetingRoomUsersByUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersMeeting_Room_UsersArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersMeeting_Room_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Users_Order_By>>;
  where?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersMeeting_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersMeeting_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Room_Order_By>>;
  where?: InputMaybe<Meeting_Room_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users" */
export type UsersNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersNotificationsByOwnedByArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersNotificationsByOwnedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_AdminArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Admin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Admins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Admin_Order_By>>;
  where?: InputMaybe<Organization_Admin_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_EmployeesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Employees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_User_Leave_ApplicationsArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_User_Leave_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Organization_User_Leave_Application_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_User_Leave_Application_Order_By>>;
  where?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationsByOwnedByArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationsByOwnedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPerformanceReviewByusersByPeerIdArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPerformanceReviewByusersByPeerId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPerformanceReviewStatusesArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPerformanceReviewStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PerformanceReviewStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PerformanceReviewStatus_Order_By>>;
  where?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPerformance_Review_ByUsersArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPerformance_Review_ByUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Review_ByUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Performance_Review_ByUser_Order_By>>;
  where?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPersonalisedsArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPersonaliseds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personalised_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personalised_Order_By>>;
  where?: InputMaybe<Personalised_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPost_LikesArgs = {
  distinct_on?: InputMaybe<Array<Post_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Likes_Order_By>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPost_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Likes_Order_By>>;
  where?: InputMaybe<Post_Likes_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPosts_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Comments_Order_By>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPosts_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Comments_Order_By>>;
  where?: InputMaybe<Posts_Comments_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProfilesByInvitedArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProfilesByInvited_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProject_MembersArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_Order_By>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProject_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_Order_By>>;
  where?: InputMaybe<Project_Members_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProject_SprintsArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProject_Sprints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Sprints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Sprints_Order_By>>;
  where?: InputMaybe<Project_Sprints_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProjectsByOwnedByArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProjectsByOwnedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersStorage_FilesArgs = {
  distinct_on?: InputMaybe<Array<Storage_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Files_Order_By>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersStorage_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Files_Order_By>>;
  where?: InputMaybe<Storage_Files_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersStress_DataArgs = {
  distinct_on?: InputMaybe<Array<Stress_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stress_Data_Order_By>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersStress_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stress_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stress_Data_Order_By>>;
  where?: InputMaybe<Stress_Data_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTaskTimeSheetsByManagerByArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTaskTimeSheetsByManagerBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Approvers_Order_By>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Approvers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Approvers_Order_By>>;
  where?: InputMaybe<Task_Approvers_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_ChecklistsArgs = {
  distinct_on?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Checklist_Order_By>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Checklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Checklist_Order_By>>;
  where?: InputMaybe<Task_Checklist_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_CollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Collaborator_Order_By>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Collaborator_Order_By>>;
  where?: InputMaybe<Task_Collaborator_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Comment_LikesArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Like_Order_By>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Comment_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Like_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Like_Order_By>>;
  where?: InputMaybe<Task_Comment_Like_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Comment_Order_By>>;
  where?: InputMaybe<Task_Comment_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Time_SheetsArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Time_Sheets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Time_Sheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Time_Sheet_Order_By>>;
  where?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_UnscheduledsArgs = {
  distinct_on?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Unscheduled_Order_By>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTask_Unscheduleds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Unscheduled_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Unscheduled_Order_By>>;
  where?: InputMaybe<Task_Unscheduled_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTasksByAssignedByArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTasksByAssignedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTasksByCreatedByArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTasksByCreatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTasksByUpdatedByArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTasksByUpdatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTraining_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTraining_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Feedback_Order_By>>;
  where?: InputMaybe<Training_Feedback_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTraining_Progress_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Progress_Record_Order_By>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTraining_Progress_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Progress_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Progress_Record_Order_By>>;
  where?: InputMaybe<Training_Progress_Record_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTraining_Type_For_TrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Type_For_Training_Order_By>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTraining_Type_For_Trainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Type_For_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Type_For_Training_Order_By>>;
  where?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUserCompetenciesByManageridArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUserCompetenciesByManagerid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUserPeerReviewsByUserIdArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUserPeerReviewsByUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Peer_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Peer_Review_Order_By>>;
  where?: InputMaybe<User_Peer_Review_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_CompetenciesArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Competencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Competency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Competency_Order_By>>;
  where?: InputMaybe<User_Competency_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Activity_TrackersArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Tracker_Order_By>>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Activity_Trackers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Tracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Tracker_Order_By>>;
  where?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Avatar_FilesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Avatar_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Avatar_Files_Order_By>>;
  where?: InputMaybe<User_Avatar_Files_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Career_Development_RecordsArgs = {
  distinct_on?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_Development_Record_Order_By>>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Career_Development_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Career_Development_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_Development_Record_Order_By>>;
  where?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Career_External_CertificationsArgs = {
  distinct_on?: InputMaybe<
    Array<User_Career_External_Certification_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Career_External_Certifications_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<User_Career_External_Certification_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Career_External_Certification_Order_By>>;
  where?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  expecting_user?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  User_Peer_Reviews?: InputMaybe<User_Peer_Review_Bool_Exp>;
  User_Peer_Reviews_aggregate?: InputMaybe<User_Peer_Review_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  assessment_feedbacks?: InputMaybe<Assessment_Feedback_Bool_Exp>;
  assessment_feedbacks_aggregate?: InputMaybe<Assessment_Feedback_Aggregate_Bool_Exp>;
  assessment_records?: InputMaybe<Assessment_Record_Bool_Exp>;
  assessment_records_aggregate?: InputMaybe<Assessment_Record_Aggregate_Bool_Exp>;
  assessment_types?: InputMaybe<Assessment_Type_Bool_Exp>;
  assessment_types_aggregate?: InputMaybe<Assessment_Type_Aggregate_Bool_Exp>;
  assessments?: InputMaybe<Assessment_Bool_Exp>;
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Bool_Exp>;
  assign_career_development_plans?: InputMaybe<Assign_Career_Development_Plan_Bool_Exp>;
  assign_career_development_plans_aggregate?: InputMaybe<Assign_Career_Development_Plan_Aggregate_Bool_Exp>;
  calendarAttendeesByCreatedBy?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  calendarAttendeesByCreatedBy_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp>;
  calendarMeetingCallRequestsByUserId?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
  calendarMeetingCallRequestsByUserId_aggregate?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Bool_Exp>;
  calendar_attendees?: InputMaybe<Calendar_Attendee_Bool_Exp>;
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Bool_Exp>;
  calendar_events?: InputMaybe<Calendar_Event_Bool_Exp>;
  calendar_events_aggregate?: InputMaybe<Calendar_Event_Aggregate_Bool_Exp>;
  calendar_meeting_call_requests?: InputMaybe<Calendar_Meeting_Call_Request_Bool_Exp>;
  calendar_meeting_call_requests_aggregate?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Bool_Exp>;
  calendar_meeting_call_users?: InputMaybe<Calendar_Meeting_Call_User_Bool_Exp>;
  calendar_meeting_call_users_aggregate?: InputMaybe<Calendar_Meeting_Call_User_Aggregate_Bool_Exp>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Bool_Exp>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Bool_Exp>;
  calendars?: InputMaybe<Calendar_Bool_Exp>;
  calendars_aggregate?: InputMaybe<Calendar_Aggregate_Bool_Exp>;
  callRequestsByCreatedBy?: InputMaybe<Call_Request_Bool_Exp>;
  callRequestsByCreatedBy_aggregate?: InputMaybe<Call_Request_Aggregate_Bool_Exp>;
  call_requests?: InputMaybe<Call_Request_Bool_Exp>;
  call_requests_aggregate?: InputMaybe<Call_Request_Aggregate_Bool_Exp>;
  call_users?: InputMaybe<Call_User_Bool_Exp>;
  call_users_aggregate?: InputMaybe<Call_User_Aggregate_Bool_Exp>;
  calls?: InputMaybe<Call_Bool_Exp>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Bool_Exp>;
  career_development_details?: InputMaybe<Career_Development_Detail_Bool_Exp>;
  career_development_details_aggregate?: InputMaybe<Career_Development_Detail_Aggregate_Bool_Exp>;
  chat_message_actions?: InputMaybe<Chat_Message_Action_Bool_Exp>;
  chat_message_actions_aggregate?: InputMaybe<Chat_Message_Action_Aggregate_Bool_Exp>;
  chat_messages?: InputMaybe<Chat_Message_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Bool_Exp>;
  chat_room_users?: InputMaybe<Chat_Room_User_Bool_Exp>;
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Bool_Exp>;
  chat_rooms?: InputMaybe<Chat_Room_Bool_Exp>;
  chat_rooms_aggregate?: InputMaybe<Chat_Room_Aggregate_Bool_Exp>;
  chat_rooms_created?: InputMaybe<Chat_Room_Bool_Exp>;
  chat_rooms_created_aggregate?: InputMaybe<Chat_Room_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  department_employees?: InputMaybe<Department_Employee_Bool_Exp>;
  department_employees_aggregate?: InputMaybe<Department_Employee_Aggregate_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  departmentsByManagerId?: InputMaybe<Department_Bool_Exp>;
  departmentsByManagerId_aggregate?: InputMaybe<Department_Aggregate_Bool_Exp>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Bool_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  employee_bandwidth_data?: InputMaybe<Employee_Bandwidth_Data_Bool_Exp>;
  employee_bandwidth_data_aggregate?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Bool_Exp>;
  employee_capacities?: InputMaybe<Employee_Capacity_Bool_Exp>;
  employee_capacities_aggregate?: InputMaybe<Employee_Capacity_Aggregate_Bool_Exp>;
  expecting_user?: InputMaybe<Jsonb_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  goalWithKpis?: InputMaybe<GoalWithKpi_Bool_Exp>;
  goalWithKpis_aggregate?: InputMaybe<GoalWithKpi_Aggregate_Bool_Exp>;
  goalsByManagerId?: InputMaybe<Goals_Bool_Exp>;
  goalsByManagerId_aggregate?: InputMaybe<Goals_Aggregate_Bool_Exp>;
  hotdesk_bookings?: InputMaybe<Hotdesk_Bookings_Bool_Exp>;
  hotdesk_bookings_aggregate?: InputMaybe<Hotdesk_Bookings_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kpi_banks?: InputMaybe<Kpi_Bank_Bool_Exp>;
  kpi_banks_aggregate?: InputMaybe<Kpi_Bank_Aggregate_Bool_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  last_sign_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  leaveDonatesByDonatedTo?: InputMaybe<Leave_Donate_Bool_Exp>;
  leaveDonatesByDonatedTo_aggregate?: InputMaybe<Leave_Donate_Aggregate_Bool_Exp>;
  leave_donates?: InputMaybe<Leave_Donate_Bool_Exp>;
  leave_donates_aggregate?: InputMaybe<Leave_Donate_Aggregate_Bool_Exp>;
  leave_organizations?: InputMaybe<Leave_Organization_Bool_Exp>;
  leave_organizations_aggregate?: InputMaybe<Leave_Organization_Aggregate_Bool_Exp>;
  leaves?: InputMaybe<Leave_Bool_Exp>;
  leavesBySubmittedBy?: InputMaybe<Leave_Bool_Exp>;
  leavesBySubmittedBy_aggregate?: InputMaybe<Leave_Aggregate_Bool_Exp>;
  leaves_aggregate?: InputMaybe<Leave_Aggregate_Bool_Exp>;
  liveCallRequestsByUserId?: InputMaybe<Live_Call_Request_Bool_Exp>;
  liveCallRequestsByUserId_aggregate?: InputMaybe<Live_Call_Request_Aggregate_Bool_Exp>;
  liveCallUsersByUserId?: InputMaybe<Live_Call_User_Bool_Exp>;
  liveCallUsersByUserId_aggregate?: InputMaybe<Live_Call_User_Aggregate_Bool_Exp>;
  live_call_requests?: InputMaybe<Live_Call_Request_Bool_Exp>;
  live_call_requests_aggregate?: InputMaybe<Live_Call_Request_Aggregate_Bool_Exp>;
  live_call_users?: InputMaybe<Live_Call_User_Bool_Exp>;
  live_call_users_aggregate?: InputMaybe<Live_Call_User_Aggregate_Bool_Exp>;
  live_calls?: InputMaybe<Live_Call_Bool_Exp>;
  live_calls_aggregate?: InputMaybe<Live_Call_Aggregate_Bool_Exp>;
  meetingRoomUsersByUserId?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
  meetingRoomUsersByUserId_aggregate?: InputMaybe<Meeting_Room_Users_Aggregate_Bool_Exp>;
  meeting_room_users?: InputMaybe<Meeting_Room_Users_Bool_Exp>;
  meeting_room_users_aggregate?: InputMaybe<Meeting_Room_Users_Aggregate_Bool_Exp>;
  meeting_rooms?: InputMaybe<Meeting_Room_Bool_Exp>;
  meeting_rooms_aggregate?: InputMaybe<Meeting_Room_Aggregate_Bool_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  my_organization?: InputMaybe<Organization_User_Bool_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notificationsByOwnedBy?: InputMaybe<Notifications_Bool_Exp>;
  notificationsByOwnedBy_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  organization_admin?: InputMaybe<Organization_Admin_Bool_Exp>;
  organization_admin_aggregate?: InputMaybe<Organization_Admin_Aggregate_Bool_Exp>;
  organization_admins?: InputMaybe<Organization_Admin_Bool_Exp>;
  organization_admins_aggregate?: InputMaybe<Organization_Admin_Aggregate_Bool_Exp>;
  organization_employees?: InputMaybe<Organization_Employee_Bool_Exp>;
  organization_employees_aggregate?: InputMaybe<Organization_Employee_Aggregate_Bool_Exp>;
  organization_user_leave_applications?: InputMaybe<Organization_User_Leave_Application_Bool_Exp>;
  organization_user_leave_applications_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Bool_Exp>;
  organizations?: InputMaybe<Organization_Bool_Exp>;
  organizationsByOwnedBy?: InputMaybe<Organization_Bool_Exp>;
  organizationsByOwnedBy_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
  organizations_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
  performanceReviewByusersByPeerId?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  performanceReviewByusersByPeerId_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Bool_Exp>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Bool_Exp>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Bool_Exp>;
  performance_review_byUsers?: InputMaybe<Performance_Review_ByUser_Bool_Exp>;
  performance_review_byUsers_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Bool_Exp>;
  personaliseds?: InputMaybe<Personalised_Bool_Exp>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Bool_Exp>;
  post_likes?: InputMaybe<Post_Likes_Bool_Exp>;
  post_likes_aggregate?: InputMaybe<Post_Likes_Aggregate_Bool_Exp>;
  posts?: InputMaybe<Posts_Bool_Exp>;
  posts_aggregate?: InputMaybe<Posts_Aggregate_Bool_Exp>;
  posts_comments?: InputMaybe<Posts_Comments_Bool_Exp>;
  posts_comments_aggregate?: InputMaybe<Posts_Comments_Aggregate_Bool_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
  profilesByInvited?: InputMaybe<Profile_Bool_Exp>;
  profilesByInvited_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
  project_members?: InputMaybe<Project_Members_Bool_Exp>;
  project_members_aggregate?: InputMaybe<Project_Members_Aggregate_Bool_Exp>;
  project_sprints?: InputMaybe<Project_Sprints_Bool_Exp>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Bool_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projectsByOwnedBy?: InputMaybe<Project_Bool_Exp>;
  projectsByOwnedBy_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  storage_files?: InputMaybe<Storage_Files_Bool_Exp>;
  storage_files_aggregate?: InputMaybe<Storage_Files_Aggregate_Bool_Exp>;
  stress_data?: InputMaybe<Stress_Data_Bool_Exp>;
  stress_data_aggregate?: InputMaybe<Stress_Data_Aggregate_Bool_Exp>;
  taskTimeSheetsByManagerBy?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
  taskTimeSheetsByManagerBy_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Bool_Exp>;
  task_approvers?: InputMaybe<Task_Approvers_Bool_Exp>;
  task_approvers_aggregate?: InputMaybe<Task_Approvers_Aggregate_Bool_Exp>;
  task_checklists?: InputMaybe<Task_Checklist_Bool_Exp>;
  task_checklists_aggregate?: InputMaybe<Task_Checklist_Aggregate_Bool_Exp>;
  task_collaborators?: InputMaybe<Task_Collaborator_Bool_Exp>;
  task_collaborators_aggregate?: InputMaybe<Task_Collaborator_Aggregate_Bool_Exp>;
  task_comment_likes?: InputMaybe<Task_Comment_Like_Bool_Exp>;
  task_comment_likes_aggregate?: InputMaybe<Task_Comment_Like_Aggregate_Bool_Exp>;
  task_comments?: InputMaybe<Task_Comment_Bool_Exp>;
  task_comments_aggregate?: InputMaybe<Task_Comment_Aggregate_Bool_Exp>;
  task_time_sheets?: InputMaybe<Task_Time_Sheet_Bool_Exp>;
  task_time_sheets_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Bool_Exp>;
  task_unscheduleds?: InputMaybe<Task_Unscheduled_Bool_Exp>;
  task_unscheduleds_aggregate?: InputMaybe<Task_Unscheduled_Aggregate_Bool_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasksByAssignedBy?: InputMaybe<Task_Bool_Exp>;
  tasksByAssignedBy_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  tasksByCreatedBy?: InputMaybe<Task_Bool_Exp>;
  tasksByCreatedBy_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  tasksByUpdatedBy?: InputMaybe<Task_Bool_Exp>;
  tasksByUpdatedBy_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  training_feedbacks?: InputMaybe<Training_Feedback_Bool_Exp>;
  training_feedbacks_aggregate?: InputMaybe<Training_Feedback_Aggregate_Bool_Exp>;
  training_progress_records?: InputMaybe<Training_Progress_Record_Bool_Exp>;
  training_progress_records_aggregate?: InputMaybe<Training_Progress_Record_Aggregate_Bool_Exp>;
  training_type_for_trainings?: InputMaybe<Training_Type_For_Training_Bool_Exp>;
  training_type_for_trainings_aggregate?: InputMaybe<Training_Type_For_Training_Aggregate_Bool_Exp>;
  trainings?: InputMaybe<Training_Bool_Exp>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  userCompetenciesByManagerid?: InputMaybe<User_Competency_Bool_Exp>;
  userCompetenciesByManagerid_aggregate?: InputMaybe<User_Competency_Aggregate_Bool_Exp>;
  userPeerReviewsByUserId?: InputMaybe<User_Peer_Review_Bool_Exp>;
  userPeerReviewsByUserId_aggregate?: InputMaybe<User_Peer_Review_Aggregate_Bool_Exp>;
  user_Competencies?: InputMaybe<User_Competency_Bool_Exp>;
  user_Competencies_aggregate?: InputMaybe<User_Competency_Aggregate_Bool_Exp>;
  user_activity_trackers?: InputMaybe<User_Activity_Tracker_Bool_Exp>;
  user_activity_trackers_aggregate?: InputMaybe<User_Activity_Tracker_Aggregate_Bool_Exp>;
  user_avatar_files?: InputMaybe<User_Avatar_Files_Bool_Exp>;
  user_avatar_files_aggregate?: InputMaybe<User_Avatar_Files_Aggregate_Bool_Exp>;
  user_career_development_records?: InputMaybe<User_Career_Development_Record_Bool_Exp>;
  user_career_development_records_aggregate?: InputMaybe<User_Career_Development_Record_Aggregate_Bool_Exp>;
  user_career_external_certifications?: InputMaybe<User_Career_External_Certification_Bool_Exp>;
  user_career_external_certifications_aggregate?: InputMaybe<User_Career_External_Certification_Aggregate_Bool_Exp>;
  user_settings?: InputMaybe<User_Settings_Bool_Exp>;
  user_settings_aggregate?: InputMaybe<User_Settings_Aggregate_Bool_Exp>;
  user_state?: InputMaybe<User_State_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  expecting_user?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  expecting_user?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  expecting_user?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  User_Peer_Reviews?: InputMaybe<User_Peer_Review_Arr_Rel_Insert_Input>;
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  assessment_feedbacks?: InputMaybe<Assessment_Feedback_Arr_Rel_Insert_Input>;
  assessment_records?: InputMaybe<Assessment_Record_Arr_Rel_Insert_Input>;
  assessment_types?: InputMaybe<Assessment_Type_Arr_Rel_Insert_Input>;
  assessments?: InputMaybe<Assessment_Arr_Rel_Insert_Input>;
  assign_career_development_plans?: InputMaybe<Assign_Career_Development_Plan_Arr_Rel_Insert_Input>;
  calendarAttendeesByCreatedBy?: InputMaybe<Calendar_Attendee_Arr_Rel_Insert_Input>;
  calendarMeetingCallRequestsByUserId?: InputMaybe<Calendar_Meeting_Call_Request_Arr_Rel_Insert_Input>;
  calendar_attendees?: InputMaybe<Calendar_Attendee_Arr_Rel_Insert_Input>;
  calendar_events?: InputMaybe<Calendar_Event_Arr_Rel_Insert_Input>;
  calendar_meeting_call_requests?: InputMaybe<Calendar_Meeting_Call_Request_Arr_Rel_Insert_Input>;
  calendar_meeting_call_users?: InputMaybe<Calendar_Meeting_Call_User_Arr_Rel_Insert_Input>;
  calendar_meeting_calls?: InputMaybe<Calendar_Meeting_Call_Arr_Rel_Insert_Input>;
  calendars?: InputMaybe<Calendar_Arr_Rel_Insert_Input>;
  callRequestsByCreatedBy?: InputMaybe<Call_Request_Arr_Rel_Insert_Input>;
  call_requests?: InputMaybe<Call_Request_Arr_Rel_Insert_Input>;
  call_users?: InputMaybe<Call_User_Arr_Rel_Insert_Input>;
  calls?: InputMaybe<Call_Arr_Rel_Insert_Input>;
  career_development_details?: InputMaybe<Career_Development_Detail_Arr_Rel_Insert_Input>;
  chat_message_actions?: InputMaybe<Chat_Message_Action_Arr_Rel_Insert_Input>;
  chat_messages?: InputMaybe<Chat_Message_Arr_Rel_Insert_Input>;
  chat_room_users?: InputMaybe<Chat_Room_User_Arr_Rel_Insert_Input>;
  chat_rooms?: InputMaybe<Chat_Room_Arr_Rel_Insert_Input>;
  chat_rooms_created?: InputMaybe<Chat_Room_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  department_employees?: InputMaybe<Department_Employee_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  departmentsByManagerId?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  display_name?: InputMaybe<Scalars['String']>;
  employee_bandwidth_data?: InputMaybe<Employee_Bandwidth_Data_Arr_Rel_Insert_Input>;
  employee_capacities?: InputMaybe<Employee_Capacity_Arr_Rel_Insert_Input>;
  expecting_user?: InputMaybe<Scalars['jsonb']>;
  first_name?: InputMaybe<Scalars['String']>;
  goalWithKpis?: InputMaybe<GoalWithKpi_Arr_Rel_Insert_Input>;
  goalsByManagerId?: InputMaybe<Goals_Arr_Rel_Insert_Input>;
  hotdesk_bookings?: InputMaybe<Hotdesk_Bookings_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  kpi_banks?: InputMaybe<Kpi_Bank_Arr_Rel_Insert_Input>;
  last_name?: InputMaybe<Scalars['String']>;
  last_sign_date_time?: InputMaybe<Scalars['timestamptz']>;
  leaveDonatesByDonatedTo?: InputMaybe<Leave_Donate_Arr_Rel_Insert_Input>;
  leave_donates?: InputMaybe<Leave_Donate_Arr_Rel_Insert_Input>;
  leave_organizations?: InputMaybe<Leave_Organization_Arr_Rel_Insert_Input>;
  leaves?: InputMaybe<Leave_Arr_Rel_Insert_Input>;
  leavesBySubmittedBy?: InputMaybe<Leave_Arr_Rel_Insert_Input>;
  liveCallRequestsByUserId?: InputMaybe<Live_Call_Request_Arr_Rel_Insert_Input>;
  liveCallUsersByUserId?: InputMaybe<Live_Call_User_Arr_Rel_Insert_Input>;
  live_call_requests?: InputMaybe<Live_Call_Request_Arr_Rel_Insert_Input>;
  live_call_users?: InputMaybe<Live_Call_User_Arr_Rel_Insert_Input>;
  live_calls?: InputMaybe<Live_Call_Arr_Rel_Insert_Input>;
  meetingRoomUsersByUserId?: InputMaybe<Meeting_Room_Users_Arr_Rel_Insert_Input>;
  meeting_room_users?: InputMaybe<Meeting_Room_Users_Arr_Rel_Insert_Input>;
  meeting_rooms?: InputMaybe<Meeting_Room_Arr_Rel_Insert_Input>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  my_organization?: InputMaybe<Organization_User_Obj_Rel_Insert_Input>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  notificationsByOwnedBy?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  organization_admin?: InputMaybe<Organization_Admin_Arr_Rel_Insert_Input>;
  organization_admins?: InputMaybe<Organization_Admin_Arr_Rel_Insert_Input>;
  organization_employees?: InputMaybe<Organization_Employee_Arr_Rel_Insert_Input>;
  organization_user_leave_applications?: InputMaybe<Organization_User_Leave_Application_Arr_Rel_Insert_Input>;
  organizations?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
  organizationsByOwnedBy?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
  performanceReviewByusersByPeerId?: InputMaybe<Performance_Review_ByUser_Arr_Rel_Insert_Input>;
  performanceReviewStatuses?: InputMaybe<PerformanceReviewStatus_Arr_Rel_Insert_Input>;
  performance_review_byUsers?: InputMaybe<Performance_Review_ByUser_Arr_Rel_Insert_Input>;
  personaliseds?: InputMaybe<Personalised_Arr_Rel_Insert_Input>;
  post_likes?: InputMaybe<Post_Likes_Arr_Rel_Insert_Input>;
  posts?: InputMaybe<Posts_Arr_Rel_Insert_Input>;
  posts_comments?: InputMaybe<Posts_Comments_Arr_Rel_Insert_Input>;
  profile?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
  profilesByInvited?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
  project_members?: InputMaybe<Project_Members_Arr_Rel_Insert_Input>;
  project_sprints?: InputMaybe<Project_Sprints_Arr_Rel_Insert_Input>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  projectsByOwnedBy?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  storage_files?: InputMaybe<Storage_Files_Arr_Rel_Insert_Input>;
  stress_data?: InputMaybe<Stress_Data_Arr_Rel_Insert_Input>;
  taskTimeSheetsByManagerBy?: InputMaybe<Task_Time_Sheet_Arr_Rel_Insert_Input>;
  task_approvers?: InputMaybe<Task_Approvers_Arr_Rel_Insert_Input>;
  task_checklists?: InputMaybe<Task_Checklist_Arr_Rel_Insert_Input>;
  task_collaborators?: InputMaybe<Task_Collaborator_Arr_Rel_Insert_Input>;
  task_comment_likes?: InputMaybe<Task_Comment_Like_Arr_Rel_Insert_Input>;
  task_comments?: InputMaybe<Task_Comment_Arr_Rel_Insert_Input>;
  task_time_sheets?: InputMaybe<Task_Time_Sheet_Arr_Rel_Insert_Input>;
  task_unscheduleds?: InputMaybe<Task_Unscheduled_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  tasksByAssignedBy?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  tasksByCreatedBy?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  tasksByUpdatedBy?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  training_feedbacks?: InputMaybe<Training_Feedback_Arr_Rel_Insert_Input>;
  training_progress_records?: InputMaybe<Training_Progress_Record_Arr_Rel_Insert_Input>;
  training_type_for_trainings?: InputMaybe<Training_Type_For_Training_Arr_Rel_Insert_Input>;
  trainings?: InputMaybe<Training_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userCompetenciesByManagerid?: InputMaybe<User_Competency_Arr_Rel_Insert_Input>;
  userPeerReviewsByUserId?: InputMaybe<User_Peer_Review_Arr_Rel_Insert_Input>;
  user_Competencies?: InputMaybe<User_Competency_Arr_Rel_Insert_Input>;
  user_activity_trackers?: InputMaybe<User_Activity_Tracker_Arr_Rel_Insert_Input>;
  user_avatar_files?: InputMaybe<User_Avatar_Files_Arr_Rel_Insert_Input>;
  user_career_development_records?: InputMaybe<User_Career_Development_Record_Arr_Rel_Insert_Input>;
  user_career_external_certifications?: InputMaybe<User_Career_External_Certification_Arr_Rel_Insert_Input>;
  user_settings?: InputMaybe<User_Settings_Arr_Rel_Insert_Input>;
  user_state?: InputMaybe<User_State_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  last_sign_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  last_sign_date_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  User_Peer_Reviews_aggregate?: InputMaybe<User_Peer_Review_Aggregate_Order_By>;
  account?: InputMaybe<Auth_Accounts_Order_By>;
  assessment_feedbacks_aggregate?: InputMaybe<Assessment_Feedback_Aggregate_Order_By>;
  assessment_records_aggregate?: InputMaybe<Assessment_Record_Aggregate_Order_By>;
  assessment_types_aggregate?: InputMaybe<Assessment_Type_Aggregate_Order_By>;
  assessments_aggregate?: InputMaybe<Assessment_Aggregate_Order_By>;
  assign_career_development_plans_aggregate?: InputMaybe<Assign_Career_Development_Plan_Aggregate_Order_By>;
  calendarAttendeesByCreatedBy_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Order_By>;
  calendarMeetingCallRequestsByUserId_aggregate?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Order_By>;
  calendar_attendees_aggregate?: InputMaybe<Calendar_Attendee_Aggregate_Order_By>;
  calendar_events_aggregate?: InputMaybe<Calendar_Event_Aggregate_Order_By>;
  calendar_meeting_call_requests_aggregate?: InputMaybe<Calendar_Meeting_Call_Request_Aggregate_Order_By>;
  calendar_meeting_call_users_aggregate?: InputMaybe<Calendar_Meeting_Call_User_Aggregate_Order_By>;
  calendar_meeting_calls_aggregate?: InputMaybe<Calendar_Meeting_Call_Aggregate_Order_By>;
  calendars_aggregate?: InputMaybe<Calendar_Aggregate_Order_By>;
  callRequestsByCreatedBy_aggregate?: InputMaybe<Call_Request_Aggregate_Order_By>;
  call_requests_aggregate?: InputMaybe<Call_Request_Aggregate_Order_By>;
  call_users_aggregate?: InputMaybe<Call_User_Aggregate_Order_By>;
  calls_aggregate?: InputMaybe<Call_Aggregate_Order_By>;
  career_development_details_aggregate?: InputMaybe<Career_Development_Detail_Aggregate_Order_By>;
  chat_message_actions_aggregate?: InputMaybe<Chat_Message_Action_Aggregate_Order_By>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Order_By>;
  chat_room_users_aggregate?: InputMaybe<Chat_Room_User_Aggregate_Order_By>;
  chat_rooms_aggregate?: InputMaybe<Chat_Room_Aggregate_Order_By>;
  chat_rooms_created_aggregate?: InputMaybe<Chat_Room_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  department_employees_aggregate?: InputMaybe<Department_Employee_Aggregate_Order_By>;
  departmentsByManagerId_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  display_name?: InputMaybe<Order_By>;
  employee_bandwidth_data_aggregate?: InputMaybe<Employee_Bandwidth_Data_Aggregate_Order_By>;
  employee_capacities_aggregate?: InputMaybe<Employee_Capacity_Aggregate_Order_By>;
  expecting_user?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  goalWithKpis_aggregate?: InputMaybe<GoalWithKpi_Aggregate_Order_By>;
  goalsByManagerId_aggregate?: InputMaybe<Goals_Aggregate_Order_By>;
  hotdesk_bookings_aggregate?: InputMaybe<Hotdesk_Bookings_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  kpi_banks_aggregate?: InputMaybe<Kpi_Bank_Aggregate_Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_sign_date_time?: InputMaybe<Order_By>;
  leaveDonatesByDonatedTo_aggregate?: InputMaybe<Leave_Donate_Aggregate_Order_By>;
  leave_donates_aggregate?: InputMaybe<Leave_Donate_Aggregate_Order_By>;
  leave_organizations_aggregate?: InputMaybe<Leave_Organization_Aggregate_Order_By>;
  leavesBySubmittedBy_aggregate?: InputMaybe<Leave_Aggregate_Order_By>;
  leaves_aggregate?: InputMaybe<Leave_Aggregate_Order_By>;
  liveCallRequestsByUserId_aggregate?: InputMaybe<Live_Call_Request_Aggregate_Order_By>;
  liveCallUsersByUserId_aggregate?: InputMaybe<Live_Call_User_Aggregate_Order_By>;
  live_call_requests_aggregate?: InputMaybe<Live_Call_Request_Aggregate_Order_By>;
  live_call_users_aggregate?: InputMaybe<Live_Call_User_Aggregate_Order_By>;
  live_calls_aggregate?: InputMaybe<Live_Call_Aggregate_Order_By>;
  meetingRoomUsersByUserId_aggregate?: InputMaybe<Meeting_Room_Users_Aggregate_Order_By>;
  meeting_room_users_aggregate?: InputMaybe<Meeting_Room_Users_Aggregate_Order_By>;
  meeting_rooms_aggregate?: InputMaybe<Meeting_Room_Aggregate_Order_By>;
  metadata?: InputMaybe<Order_By>;
  my_organization?: InputMaybe<Organization_User_Order_By>;
  notificationsByOwnedBy_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  organization_admin_aggregate?: InputMaybe<Organization_Admin_Aggregate_Order_By>;
  organization_admins_aggregate?: InputMaybe<Organization_Admin_Aggregate_Order_By>;
  organization_employees_aggregate?: InputMaybe<Organization_Employee_Aggregate_Order_By>;
  organization_user_leave_applications_aggregate?: InputMaybe<Organization_User_Leave_Application_Aggregate_Order_By>;
  organizationsByOwnedBy_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
  organizations_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
  performanceReviewByusersByPeerId_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Order_By>;
  performanceReviewStatuses_aggregate?: InputMaybe<PerformanceReviewStatus_Aggregate_Order_By>;
  performance_review_byUsers_aggregate?: InputMaybe<Performance_Review_ByUser_Aggregate_Order_By>;
  personaliseds_aggregate?: InputMaybe<Personalised_Aggregate_Order_By>;
  post_likes_aggregate?: InputMaybe<Post_Likes_Aggregate_Order_By>;
  posts_aggregate?: InputMaybe<Posts_Aggregate_Order_By>;
  posts_comments_aggregate?: InputMaybe<Posts_Comments_Aggregate_Order_By>;
  profile_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
  profilesByInvited_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
  project_members_aggregate?: InputMaybe<Project_Members_Aggregate_Order_By>;
  project_sprints_aggregate?: InputMaybe<Project_Sprints_Aggregate_Order_By>;
  projectsByOwnedBy_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  storage_files_aggregate?: InputMaybe<Storage_Files_Aggregate_Order_By>;
  stress_data_aggregate?: InputMaybe<Stress_Data_Aggregate_Order_By>;
  taskTimeSheetsByManagerBy_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Order_By>;
  task_approvers_aggregate?: InputMaybe<Task_Approvers_Aggregate_Order_By>;
  task_checklists_aggregate?: InputMaybe<Task_Checklist_Aggregate_Order_By>;
  task_collaborators_aggregate?: InputMaybe<Task_Collaborator_Aggregate_Order_By>;
  task_comment_likes_aggregate?: InputMaybe<Task_Comment_Like_Aggregate_Order_By>;
  task_comments_aggregate?: InputMaybe<Task_Comment_Aggregate_Order_By>;
  task_time_sheets_aggregate?: InputMaybe<Task_Time_Sheet_Aggregate_Order_By>;
  task_unscheduleds_aggregate?: InputMaybe<Task_Unscheduled_Aggregate_Order_By>;
  tasksByAssignedBy_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  tasksByCreatedBy_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  tasksByUpdatedBy_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  training_feedbacks_aggregate?: InputMaybe<Training_Feedback_Aggregate_Order_By>;
  training_progress_records_aggregate?: InputMaybe<Training_Progress_Record_Aggregate_Order_By>;
  training_type_for_trainings_aggregate?: InputMaybe<Training_Type_For_Training_Aggregate_Order_By>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userCompetenciesByManagerid_aggregate?: InputMaybe<User_Competency_Aggregate_Order_By>;
  userPeerReviewsByUserId_aggregate?: InputMaybe<User_Peer_Review_Aggregate_Order_By>;
  user_Competencies_aggregate?: InputMaybe<User_Competency_Aggregate_Order_By>;
  user_activity_trackers_aggregate?: InputMaybe<User_Activity_Tracker_Aggregate_Order_By>;
  user_avatar_files_aggregate?: InputMaybe<User_Avatar_Files_Aggregate_Order_By>;
  user_career_development_records_aggregate?: InputMaybe<User_Career_Development_Record_Aggregate_Order_By>;
  user_career_external_certifications_aggregate?: InputMaybe<User_Career_External_Certification_Aggregate_Order_By>;
  user_settings_aggregate?: InputMaybe<User_Settings_Aggregate_Order_By>;
  user_state?: InputMaybe<User_State_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  expecting_user?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  ExpectingUser = 'expecting_user',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSignDateTime = 'last_sign_date_time',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  expecting_user?: InputMaybe<Scalars['jsonb']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_sign_date_time?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  expecting_user?: InputMaybe<Scalars['jsonb']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_sign_date_time?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  ExpectingUser = 'expecting_user',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSignDateTime = 'last_sign_date_time',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type WeekDataCount = {
  __typename?: 'weekDataCount';
  amusingCount?: Maybe<Scalars['Int']>;
  balancedCount?: Maybe<Scalars['Int']>;
  relaxedCount?: Maybe<Scalars['Int']>;
  stressedCount?: Maybe<Scalars['Int']>;
  veryStressedCount?: Maybe<Scalars['Int']>;
};

export type LeaveCallByCallIdMutationVariables = Exact<{
  call_id: Scalars['String'];
}>;

export type LeaveCallByCallIdMutation = {
  __typename?: 'mutation_root';
  leaveCall?: { __typename?: 'LeaveCallOutput'; response: boolean } | null;
};

export type CallRequestSubscriptionVariables = Exact<{
  user_id: Scalars['uuid'];
}>;

export type CallRequestSubscription = {
  __typename?: 'subscription_root';
  call_request: Array<{
    __typename?: 'call_request';
    call_id: any;
    request_type: Enum_Call_Request_Type_Enum;
    id: any;
    created_by_user: {
      __typename?: 'users';
      id: any;
      first_name?: string | null;
      last_name?: string | null;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type RespondToCallMutationVariables = Exact<{
  call_id: Scalars['String'];
  response_type?: InputMaybe<CallRequestResponseType>;
}>;

export type RespondToCallMutation = {
  __typename?: 'mutation_root';
  respondToCall: {
    __typename?: 'RespondToCallOutput';
    call_id?: string | null;
    channel_id?: string | null;
    chat_id?: string | null;
    response: boolean;
  };
};

export type LatestChatMessageSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type LatestChatMessageSubscription = {
  __typename?: 'subscription_root';
  chat_message_action: Array<{
    __typename?: 'chat_message_action';
    chat_message: {
      __typename?: 'chat_message';
      id: any;
      chat_room_id: any;
      created_at: any;
      message_text: string;
      chat_room: {
        __typename?: 'chat_room';
        room_name?: string | null;
        chat_room_type: Enum_Chat_Room_Type_Enum;
      };
      user: {
        __typename?: 'users';
        first_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    };
  }>;
};

export type GetChatRoomUserIdsQueryVariables = Exact<{
  chatRoomId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type GetChatRoomUserIdsQuery = {
  __typename?: 'query_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    chat_room_users: Array<{ __typename?: 'chat_room_user'; user_id: any }>;
  }>;
};

export type UpdateChatMessageStatusesMutationVariables = Exact<{
  object: Chat_Message_Action_Insert_Input;
}>;

export type UpdateChatMessageStatusesMutation = {
  __typename?: 'mutation_root';
  insert_chat_message_action_one?: {
    __typename?: 'chat_message_action';
    message_id: any;
  } | null;
};

export type SendReplyMessageMutationVariables = Exact<{
  userId: Scalars['uuid'];
  chatRoomId: Scalars['uuid'];
  messageText: Scalars['String'];
  replied_to: Scalars['uuid'];
}>;

export type SendReplyMessageMutation = {
  __typename?: 'mutation_root';
  insert_chat_message?: {
    __typename?: 'chat_message_mutation_response';
    returning: Array<{ __typename?: 'chat_message'; id: any }>;
  } | null;
};

export type LiveCallRequestSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type LiveCallRequestSubscription = {
  __typename?: 'subscription_root';
  live_call_user: Array<{
    __typename?: 'live_call_user';
    live_call: {
      __typename?: 'live_call';
      id: any;
      live_call_users: Array<{
        __typename?: 'live_call_user';
        user: {
          __typename?: 'users';
          id: any;
          first_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            activity_status?: string | null;
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      }>;
    };
  }>;
};

export type GetOnlineModeLiveCallRequestSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetOnlineModeLiveCallRequestSubscription = {
  __typename?: 'subscription_root';
  live_call_request: Array<{
    __typename?: 'live_call_request';
    live_call_id: any;
    created_user: {
      __typename?: 'users';
      id: any;
      first_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type LeaveMeetCallMutationVariables = Exact<{
  meeting_call_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type LeaveMeetCallMutation = {
  __typename?: 'mutation_root';
  leaveMeetCall?: {
    __typename?: 'LeaveMeetCallOutput';
    status: boolean;
  } | null;
};

export type GetCalenderMeetingRequestSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetCalenderMeetingRequestSubscription = {
  __typename?: 'subscription_root';
  calendar_meeting_call_request: Array<{
    __typename?: 'calendar_meeting_call_request';
    id: any;
    calendar_meeting_call_id: any;
    user: {
      __typename?: 'users';
      id: any;
      first_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
    calendar_meeting_call: {
      __typename?: 'calendar_meeting_call';
      calendar_event: {
        __typename?: 'calendar_event';
        event_name: string;
        user: { __typename?: 'users'; first_name?: string | null };
      };
    };
  }>;
};

export type RespondMeetingCallMutationVariables = Exact<{
  meeting_call_id: Scalars['uuid'];
  response_type: CallRequestResponseType;
}>;

export type RespondMeetingCallMutation = {
  __typename?: 'mutation_root';
  respondMeetingCall?: {
    __typename?: 'RespondMeetingCallOutput';
    event_id?: string | null;
    slug?: string | null;
    success?: boolean | null;
  } | null;
};

export type LeaveMeetingRoomMutationVariables = Exact<{
  meetingRoomId: Scalars['uuid'];
}>;

export type LeaveMeetingRoomMutation = {
  __typename?: 'mutation_root';
  leaveMeetingRoom?: {
    __typename?: 'LeaveMeetingRoomOutput';
    success: boolean;
  } | null;
};

export type GetMyMeetingRoomInviteSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetMyMeetingRoomInviteSubscription = {
  __typename?: 'subscription_root';
  meeting_room_users: Array<{
    __typename?: 'meeting_room_users';
    meeting_room: {
      __typename?: 'meeting_room';
      id: any;
      type: Enum_Meeting_Room_Type_Enum;
      status: Enum_Meeting_Room_Status_Enum;
      updated_at: any;
      userByCreatedBy: {
        __typename?: 'users';
        first_name?: string | null;
        id: any;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    };
  }>;
};

export type RespondMeetingRoomMutationVariables = Exact<{
  object: RespondMeetingRoomInput;
}>;

export type RespondMeetingRoomMutation = {
  __typename?: 'mutation_root';
  respondMeetingRoom?: {
    __typename?: 'RespondMeetingRoomOutput';
    success: boolean;
  } | null;
};

export type GetMyLatestNotificationsSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyLatestNotificationsSubscription = {
  __typename?: 'subscription_root';
  notifications: Array<{
    __typename?: 'notifications';
    id: any;
    message: string;
    type: Enum_Notification_Type_Enum;
    resource_id?: any | null;
    created_at: any;
    user?: {
      __typename?: 'users';
      first_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    Resource_Calendar_Event?: {
      __typename?: 'calendar_event';
      start_date_time: any;
      event_name: string;
    } | null;
  }>;
};

export type SetNotificationShownMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type SetNotificationShownMutation = {
  __typename?: 'mutation_root';
  update_notifications_by_pk?: { __typename?: 'notifications'; id: any } | null;
};

export type SetNotificationReadMutationVariables = Exact<{
  notificationId: Scalars['uuid'];
}>;

export type SetNotificationReadMutation = {
  __typename?: 'mutation_root';
  update_notifications?: {
    __typename?: 'notifications_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetMyActiveTimeFromYesterdayQueryVariables = Exact<{
  yesterday: Scalars['timestamptz'];
  userId: Scalars['uuid'];
}>;

export type GetMyActiveTimeFromYesterdayQuery = {
  __typename?: 'query_root';
  user_activity_tracker: Array<{
    __typename?: 'user_activity_tracker';
    active_seconds: any;
  }>;
};

export type StartCallMutationVariables = Exact<{
  users: Array<Scalars['String']> | Scalars['String'];
  organizationId: Scalars['uuid'];
  media_type?: InputMaybe<StartCallMediaType>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  chat_room_id?: InputMaybe<Scalars['uuid']>;
}>;

export type StartCallMutation = {
  __typename?: 'mutation_root';
  startCall: { __typename?: 'StartCallOutput'; callId: string };
};

export type CallSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type CallSubscription = {
  __typename?: 'subscription_root';
  call_by_pk?: {
    __typename?: 'call';
    metadata?: any | null;
    call_status?: Enum_Call_Status_Type_Enum | null;
    call_type: Enum_Call_Type_Enum;
    channel_id: string;
    chat_id?: any | null;
    call_recording_status?: Enum_Call_Recording_Status_Enum | null;
    call_broadcasting_type?: Enum_Call_Broadcasting_Type_Enum | null;
    media_type: Enum_Call_Media_Type_Enum;
    id: any;
    created_by_user: {
      __typename?: 'users';
      id: any;
      first_name?: string | null;
      last_name?: string | null;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
    call_requests: Array<{
      __typename?: 'call_request';
      call_request_status?: Enum_Call_Request_Status_Type_Enum | null;
      created_for_user: {
        __typename?: 'users';
        id: any;
        first_name?: string | null;
        last_name?: string | null;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
    call_users: Array<{
      __typename?: 'call_user';
      id: any;
      status: Enum_Call_User_Status_Enum;
      user: {
        __typename?: 'users';
        id: any;
        first_name?: string | null;
        last_name?: string | null;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          agora_id?: number | null;
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type StartRecordingMutationVariables = Exact<{
  object: StartRecordingInput;
}>;

export type StartRecordingMutation = {
  __typename?: 'mutation_root';
  startRecording?: {
    __typename?: 'startRecordingOutput';
    call_id: string;
    started: boolean;
  } | null;
};

export type StopRecordingMutationVariables = Exact<{
  object: StopRecordingInput;
}>;

export type StopRecordingMutation = {
  __typename?: 'mutation_root';
  stopRecording?: {
    __typename?: 'stopRecordingOutput';
    call_id?: string | null;
    stopped?: boolean | null;
  } | null;
};

export type GetOrganizationUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  organization_id: Scalars['uuid'];
  display_name?: InputMaybe<Scalars['String']>;
  user_id: Scalars['uuid'];
}>;

export type GetOrganizationUsersQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    role_title?: string | null;
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type AddUsersToCallMutationVariables = Exact<{
  object: AddUsersToCallInput;
}>;

export type AddUsersToCallMutation = {
  __typename?: 'mutation_root';
  addUsersToCall: { __typename?: 'AddUsersToCallOutput'; response: boolean };
};

export type GetDepartmentsByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetDepartmentsByOrgIdQuery = {
  __typename?: 'query_root';
  department: Array<{ __typename?: 'department'; id: any; name: string }>;
};

export type GetOurChatRoomMutationVariables = Exact<{
  users: Array<Scalars['uuid']> | Scalars['uuid'];
  resource_id?: InputMaybe<Scalars['String']>;
}>;

export type GetOurChatRoomMutation = {
  __typename?: 'mutation_root';
  startChat?: { __typename?: 'StartChatOutput'; chatRoomId: string } | null;
};

export type SendMessageMutationVariables = Exact<{
  userId: Scalars['uuid'];
  chatRoomId: Scalars['uuid'];
  messageText: Scalars['String'];
  replied_to: Scalars['uuid'];
  message_type: Enum_Chat_Message_Type_Enum;
}>;

export type SendMessageMutation = {
  __typename?: 'mutation_root';
  insert_chat_message?: {
    __typename?: 'chat_message_mutation_response';
    returning: Array<{ __typename?: 'chat_message'; id: any }>;
  } | null;
};

export type OurChatMessagesQueryQueryVariables = Exact<{
  chatRoomId: Scalars['uuid'];
  offset: Scalars['Int'];
}>;

export type OurChatMessagesQueryQuery = {
  __typename?: 'query_root';
  chat_message: Array<{
    __typename?: 'chat_message';
    id: any;
    message_text: string;
    created_by: any;
    updated_at: any;
    replied_to?: any | null;
    message_type: Enum_Chat_Message_Type_Enum;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      id: any;
    };
    replied_to_message?: {
      __typename?: 'chat_message';
      message_text: string;
      user: { __typename?: 'users'; display_name?: string | null };
      chat_message_actions: Array<{
        __typename?: 'chat_message_action';
        is_read: boolean;
      }>;
      chat_message_files: Array<{
        __typename?: 'chat_message_file';
        storage_file: { __typename?: 'storage_files'; metadata: any };
      }>;
    } | null;
    chat_message_actions: Array<{
      __typename?: 'chat_message_action';
      is_read: boolean;
    }>;
    chat_message_files: Array<{
      __typename?: 'chat_message_file';
      storage_file_id: any;
      storage_file: {
        __typename?: 'storage_files';
        file_url: string;
        metadata: any;
      };
    }>;
  }>;
};

export type OurChatMessageSubscriptionVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type OurChatMessageSubscription = {
  __typename?: 'subscription_root';
  chat_message: Array<{
    __typename?: 'chat_message';
    id: any;
    message_text: string;
    created_by: any;
    updated_at: any;
    replied_to?: any | null;
    message_type: Enum_Chat_Message_Type_Enum;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      id: any;
    };
    chat_message_actions: Array<{
      __typename?: 'chat_message_action';
      is_read: boolean;
    }>;
    replied_to_message?: {
      __typename?: 'chat_message';
      message_text: string;
      user: { __typename?: 'users'; display_name?: string | null };
      chat_message_actions: Array<{
        __typename?: 'chat_message_action';
        is_read: boolean;
      }>;
      chat_message_files: Array<{
        __typename?: 'chat_message_file';
        storage_file: { __typename?: 'storage_files'; metadata: any };
      }>;
    } | null;
    chat_message_files: Array<{
      __typename?: 'chat_message_file';
      storage_file_id: any;
      storage_file: {
        __typename?: 'storage_files';
        file_url: string;
        metadata: any;
      };
    }>;
  }>;
};

export type OurChatFilesQueryQueryVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type OurChatFilesQueryQuery = {
  __typename?: 'query_root';
  chat_message: Array<{
    __typename?: 'chat_message';
    id: any;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      my_organization?: {
        __typename?: 'organization_user';
        organization?: {
          __typename?: 'organization';
          organization_name: string;
        } | null;
      } | null;
    };
    chat_message_files: Array<{
      __typename?: 'chat_message_file';
      storage_file_id: any;
      storage_file: {
        __typename?: 'storage_files';
        file_url: string;
        metadata: any;
      };
    }>;
  }>;
};

export type GetDocsByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetDocsByProjectIdQuery = {
  __typename?: 'query_root';
  files: Array<{
    __typename?: 'storage_files';
    id: any;
    file_url: string;
    metadata: any;
    user?: {
      __typename?: 'users';
      display_name?: string | null;
      my_organization?: {
        __typename?: 'organization_user';
        organization?: {
          __typename?: 'organization';
          organization_name: string;
        } | null;
      } | null;
    } | null;
  }>;
};

export type GetChatToReplyQueryVariables = Exact<{
  message_id: Scalars['uuid'];
}>;

export type GetChatToReplyQuery = {
  __typename?: 'query_root';
  chat_message: Array<{
    __typename?: 'chat_message';
    chat_room_id: any;
    created_at: any;
    created_by: any;
    message_text: string;
    id: any;
    message_type: Enum_Chat_Message_Type_Enum;
    chat_message_files: Array<{
      __typename?: 'chat_message_file';
      storage_file: { __typename?: 'storage_files'; metadata: any };
    }>;
  }>;
};

export type MyChatsSubscriptionVariables = Exact<{
  myUserId: Scalars['uuid'];
  organizations_id: Scalars['uuid'];
}>;

export type MyChatsSubscription = {
  __typename?: 'subscription_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    chat_room_type: Enum_Chat_Room_Type_Enum;
    last_message_id?: any | null;
    updated_at: any;
    room_name?: string | null;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      user_id: any;
      status: Enum_Chat_Room_User_Status_Enum;
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          about_me?: string | null;
          activity_status?: string | null;
          address?: { __typename?: 'address'; id: any } | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
    chat_room_users_aggregate: {
      __typename?: 'chat_room_user_aggregate';
      aggregate?: {
        __typename?: 'chat_room_user_aggregate_fields';
        count: number;
      } | null;
    };
    chat_messages: Array<{
      __typename?: 'chat_message';
      chat_message_actions: Array<{
        __typename?: 'chat_message_action';
        is_read: boolean;
        message_id: any;
      }>;
    }>;
  }>;
};

export type GetChatRoomUsersQueryVariables = Exact<{
  chatRoomId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type GetChatRoomUsersQuery = {
  __typename?: 'query_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    chat_room_type: Enum_Chat_Room_Type_Enum;
    created_by: any;
    managed_by?: any | null;
    room_name?: string | null;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      user_id: any;
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          about_me?: string | null;
          activity_status?: string | null;
          activity_status_since?: any | null;
          organization_role?: {
            __typename?: 'organization_roles';
            role_title: string;
            id: any;
          } | null;
          address?: { __typename?: 'address'; id: any } | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
        my_organization?: {
          __typename?: 'organization_user';
          manager?: { __typename?: 'users'; first_name?: string | null } | null;
        } | null;
      };
    }>;
    chat_room_users_aggregate: {
      __typename?: 'chat_room_user_aggregate';
      aggregate?: {
        __typename?: 'chat_room_user_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type AddUsersToGroupChatMutationVariables = Exact<{
  chatRoomId: Scalars['String'];
  users: Array<Scalars['String']> | Scalars['String'];
}>;

export type AddUsersToGroupChatMutation = {
  __typename?: 'mutation_root';
  addUsersToChat?: {
    __typename?: 'AddUsersToChatOutput';
    success: boolean;
  } | null;
};

export type UpdateChatGroupNameMutationVariables = Exact<{
  chatRoomId: Scalars['uuid'];
  groupName: Scalars['String'];
}>;

export type UpdateChatGroupNameMutation = {
  __typename?: 'mutation_root';
  update_chat_room?: {
    __typename?: 'chat_room_mutation_response';
    affected_rows: number;
  } | null;
};

export type LeaveGroupChatMutationVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type LeaveGroupChatMutation = {
  __typename?: 'mutation_root';
  leaveChat?: { __typename?: 'LeaveChatOutput'; success: boolean } | null;
};

export type RemoveUserFromChatMutationVariables = Exact<{
  object: RemoveChatUserInput;
}>;

export type RemoveUserFromChatMutation = {
  __typename?: 'mutation_root';
  removeUserFromChat?: {
    __typename?: 'RemoveChatUserOutput';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type UpdateMessageSeenStatusMutationVariables = Exact<{
  object?: InputMaybe<Chat_Message_Action_Insert_Input>;
}>;

export type UpdateMessageSeenStatusMutation = {
  __typename?: 'mutation_root';
  insert_chat_message_action_one?: {
    __typename?: 'chat_message_action';
    is_read: boolean;
  } | null;
};

export type ToggleChatNotificationMutationVariables = Exact<{
  object: ToggleChatNotificationInput;
}>;

export type ToggleChatNotificationMutation = {
  __typename?: 'mutation_root';
  toggleChatNotification?: {
    __typename?: 'ToggleChatNotificationOutput';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type InsertIntoChatFilesMutationVariables = Exact<{
  chat_message_id: Scalars['uuid'];
  storage_file_id: Scalars['uuid'];
}>;

export type InsertIntoChatFilesMutation = {
  __typename?: 'mutation_root';
  insert_chat_message_file?: {
    __typename?: 'chat_message_file_mutation_response';
    returning: Array<{
      __typename?: 'chat_message_file';
      chat_message_id: any;
      storage_file_id: any;
    }>;
  } | null;
};

export type DeleteGroupChatMutationVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type DeleteGroupChatMutation = {
  __typename?: 'mutation_root';
  update_chat_room?: {
    __typename?: 'chat_room_mutation_response';
    returning: Array<{
      __typename?: 'chat_room';
      id: any;
      room_name?: string | null;
      deleted_at?: any | null;
    }>;
  } | null;
};

export type DeleteChatSessionMutationVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type DeleteChatSessionMutation = {
  __typename?: 'mutation_root';
  delete_chat_message?: {
    __typename?: 'chat_message_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetChatRoomTypeByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetChatRoomTypeByIdQuery = {
  __typename?: 'query_root';
  chat_room?: {
    __typename?: 'chat_room';
    chat_room_type: Enum_Chat_Room_Type_Enum;
    projects: Array<{ __typename?: 'project'; id: any; title: string }>;
  } | null;
};

export type GetChatGroupCreatorQueryVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type GetChatGroupCreatorQuery = {
  __typename?: 'query_root';
  chat_room?: { __typename?: 'chat_room'; created_by: any } | null;
};

export type GetChatRoomDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetChatRoomDetailsQuery = {
  __typename?: 'query_root';
  chat_room?: {
    __typename?: 'chat_room';
    chat_room_type: Enum_Chat_Room_Type_Enum;
    projects: Array<{ __typename?: 'project'; id: any; title: string }>;
  } | null;
};

export type GetChatInfoForMeetingQueryVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type GetChatInfoForMeetingQuery = {
  __typename?: 'query_root';
  chat_room?: {
    __typename?: 'chat_room';
    room_name?: string | null;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        calendars: Array<{ __typename?: 'calendar'; id: any }>;
        account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type GetMyTasksForProjectQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  createdBy?: InputMaybe<Scalars['uuid']>;
}>;

export type GetMyTasksForProjectQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
  }>;
};

export type GetLatestMessageByRoomIdQueryVariables = Exact<{
  roomId: Scalars['uuid'];
}>;

export type GetLatestMessageByRoomIdQuery = {
  __typename?: 'query_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    chat_messages: Array<{
      __typename?: 'chat_message';
      id: any;
      message_text: string;
      message_type: Enum_Chat_Message_Type_Enum;
    }>;
  }>;
};

export type AddReactionToMessageMutationVariables = Exact<{
  object: Chat_Message_Reactions_Insert_Input;
}>;

export type AddReactionToMessageMutation = {
  __typename?: 'mutation_root';
  insert_chat_message_reactions_one?: {
    __typename?: 'chat_message_reactions';
    id: any;
  } | null;
};

export type AddChatEmoteReactionMutationVariables = Exact<{
  object: AddChatEmoteReactionInput;
}>;

export type AddChatEmoteReactionMutation = {
  __typename?: 'mutation_root';
  addChatEmoteReaction?: {
    __typename?: 'AddChatEmoteReactionOutput';
    reactionId: string;
  } | null;
};

export type GetMessageReactionsSubscriptionVariables = Exact<{
  messageId: Scalars['uuid'];
}>;

export type GetMessageReactionsSubscription = {
  __typename?: 'subscription_root';
  chat_message_reactions: Array<{
    __typename?: 'chat_message_reactions';
    id: any;
  }>;
};

export type GetMessageReactionsDetailQueryVariables = Exact<{
  reactionIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetMessageReactionsDetailQuery = {
  __typename?: 'query_root';
  chat_message_reactions: Array<{
    __typename?: 'chat_message_reactions';
    id: any;
    reaction_type: Enum_Live_Emote_Type_Enum;
    user?: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type StartMeetingChatMutationVariables = Exact<{
  object: StartMeetingChatInput;
}>;

export type StartMeetingChatMutation = {
  __typename?: 'mutation_root';
  startMeetingChat?: {
    __typename?: 'StartMeetingChatOutput';
    chatRoomId: string;
  } | null;
};

export type UpdateManualStateMutationVariables = Exact<{
  userId: Scalars['uuid'];
  manualState: Enum_Manual_State_Enum;
}>;

export type UpdateManualStateMutation = {
  __typename?: 'mutation_root';
  update_user_state_by_pk?: {
    __typename?: 'user_state';
    manual_state: Enum_Manual_State_Enum;
  } | null;
};

export type GetUserStatusSubSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserStatusSubSubscription = {
  __typename?: 'subscription_root';
  profile: Array<{ __typename?: 'profile'; activity_status?: string | null }>;
};

export type SetWorkingFromByUserIdMutationVariables = Exact<{
  setting_value: Scalars['jsonb'];
  userId: Scalars['uuid'];
}>;

export type SetWorkingFromByUserIdMutation = {
  __typename?: 'mutation_root';
  insert_user_settings_one?: { __typename?: 'user_settings'; id: any } | null;
};

export type GetWorkingFromSettingsByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetWorkingFromSettingsByUserIdQuery = {
  __typename?: 'query_root';
  user_settings: Array<{
    __typename?: 'user_settings';
    id: any;
    setting_value: any;
  }>;
};

export type GetAllAwardsByDateSubscriptionVariables = Exact<{
  fromDate: Scalars['timestamptz'];
  toDate: Scalars['timestamptz'];
  orgId: Scalars['uuid'];
}>;

export type GetAllAwardsByDateSubscription = {
  __typename?: 'subscription_root';
  awards: Array<{
    __typename?: 'awards';
    id: any;
    name: string;
    cycle: Enum_Award_Cycle_Type_Enum;
    award_posts: Array<{
      __typename?: 'award_posts';
      id: any;
      award_id: any;
      period_description?: string | null;
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        first_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          organization_role?: {
            __typename?: 'organization_roles';
            system_role: string;
            role_title: string;
          } | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      } | null;
    }>;
  }>;
};

export type GetEmployeeBillableHoursByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetEmployeeBillableHoursByUserIdQuery = {
  __typename?: 'query_root';
  get_employee_billable_hours: Array<{
    __typename?: 'dashboard_billable_employee';
    client_id?: any | null;
    work_hours?: any | null;
    project_client?: {
      __typename?: 'project_client';
      client_name: string;
    } | null;
  }>;
};

export type CumulativeDataQueryVariables = Exact<{
  _in: Array<Scalars['uuid']> | Scalars['uuid'];
  _gte: Scalars['date'];
  _lte: Scalars['date'];
}>;

export type CumulativeDataQuery = {
  __typename?: 'query_root';
  dashboard_billable_aggregate: {
    __typename?: 'dashboard_billable_aggregate';
    aggregate?: {
      __typename?: 'dashboard_billable_aggregate_fields';
      sum?: {
        __typename?: 'dashboard_billable_sum_fields';
        work_hours?: any | null;
      } | null;
    } | null;
  };
};

export type Get_UserByManagerQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_UserByManagerQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user_id?: any | null;
  }>;
};

export type Get_HolidaysDataQueryVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_HolidaysDataQuery = {
  __typename?: 'query_root';
  organization_user_leave_application: Array<{
    __typename?: 'organization_user_leave_application';
    id: any;
  }>;
};

export type Get_Non_BillableDataQueryVariables = Exact<{
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type Get_Non_BillableDataQuery = {
  __typename?: 'query_root';
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: {
      __typename?: 'task_aggregate_fields';
      count: number;
      sum?: {
        __typename?: 'task_sum_fields';
        estimated_time?: any | null;
      } | null;
    } | null;
  };
};

export type Non_BillableDataInfoQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type Non_BillableDataInfoQuery = {
  __typename?: 'query_root';
  task_time_sheet_aggregate: {
    __typename?: 'task_time_sheet_aggregate';
    aggregate?: {
      __typename?: 'task_time_sheet_aggregate_fields';
      sum?: {
        __typename?: 'task_time_sheet_sum_fields';
        work_hours?: any | null;
      } | null;
    } | null;
  };
};

export type BillableDataInfoQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type BillableDataInfoQuery = {
  __typename?: 'query_root';
  task_time_sheet_aggregate: {
    __typename?: 'task_time_sheet_aggregate';
    aggregate?: {
      __typename?: 'task_time_sheet_aggregate_fields';
      sum?: {
        __typename?: 'task_time_sheet_sum_fields';
        work_hours?: any | null;
      } | null;
    } | null;
  };
};

export type BillableDataCumulativeQueryVariables = Exact<{
  _lte: Scalars['date'];
  _gte: Scalars['date'];
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type BillableDataCumulativeQuery = {
  __typename?: 'query_root';
  task_time_sheet_aggregate: {
    __typename?: 'task_time_sheet_aggregate';
    aggregate?: {
      __typename?: 'task_time_sheet_aggregate_fields';
      sum?: {
        __typename?: 'task_time_sheet_sum_fields';
        work_hours?: any | null;
      } | null;
    } | null;
  };
};

export type NonBillableDataCumulativeQueryVariables = Exact<{
  _lte: Scalars['date'];
  _gte: Scalars['date'];
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type NonBillableDataCumulativeQuery = {
  __typename?: 'query_root';
  task_time_sheet_aggregate: {
    __typename?: 'task_time_sheet_aggregate';
    aggregate?: {
      __typename?: 'task_time_sheet_aggregate_fields';
      sum?: {
        __typename?: 'task_time_sheet_sum_fields';
        work_hours?: any | null;
      } | null;
    } | null;
  };
};

export type LeaveDataCumulativeQueryVariables = Exact<{
  _lte?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type LeaveDataCumulativeQuery = {
  __typename?: 'query_root';
  organization_user_leave_application: Array<{
    __typename?: 'organization_user_leave_application';
    is_approved: boolean;
    id: any;
    leaveDates_aggregate: {
      __typename?: 'organization_user_leave_dates_aggregate';
      aggregate?: {
        __typename?: 'organization_user_leave_dates_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type OrgHolidayCumulativeQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['date']>;
  _eq?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['date']>;
}>;

export type OrgHolidayCumulativeQuery = {
  __typename?: 'query_root';
  organization_holidays_aggregate: {
    __typename?: 'organization_holidays_aggregate';
    aggregate?: {
      __typename?: 'organization_holidays_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetTimeSpentGraphForIndividualMutationVariables = Exact<{
  object: TimeSpentGraphInput;
}>;

export type GetTimeSpentGraphForIndividualMutation = {
  __typename?: 'mutation_root';
  timeSpentGraph?: {
    __typename?: 'timeSpentGraphOutput';
    response: Array<{
      __typename?: 'GraphData';
      biblableWorkHours: number;
      nonBillableWorkHours: string;
      leaveData: number;
      name?: number | null;
      clientName: Array<{
        __typename?: 'ClientDetails';
        name: string;
        workHours: number;
      } | null>;
      nonBillableClientName?: Array<{
        __typename?: 'ClientDetails';
        name: string;
        workHours: number;
      } | null> | null;
    } | null>;
  } | null;
};

export type GetEmployeeBillableHoursAsManagerQueryVariables = Exact<{
  managerId: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetEmployeeBillableHoursAsManagerQuery = {
  __typename?: 'query_root';
  get_employee_billable_hours_as_manager: Array<{
    __typename?: 'dashboard_billable_manager';
    client_id?: any | null;
    user_id?: any | null;
    work_hours?: any | null;
    user?: { __typename?: 'users'; display_name?: string | null } | null;
    project_client?: {
      __typename?: 'project_client';
      client_name: string;
    } | null;
  }>;
};

export type GetEmployeeCapacityByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
  dateStartTimestamp: Scalars['timestamptz'];
  dateEndTimestamp: Scalars['timestamptz'];
}>;

export type GetEmployeeCapacityByUserIdQuery = {
  __typename?: 'query_root';
  employee_capacity_view: Array<{
    __typename?: 'employee_capacity_view';
    display_name?: string | null;
    estimate_day?: any | null;
    estimated_time?: any | null;
  }>;
};

export type GetIndividualEmployeeCapacityQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetIndividualEmployeeCapacityQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    display_name?: string | null;
    employee_bandwidth_data: Array<{
      __typename?: 'employee_bandwidth_data';
      user_id: any;
      work_day: any;
      estimated_hours: any;
      task: { __typename?: 'task'; title: string };
    }>;
  }>;
};

export type GetCalenderIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetCalenderIdQuery = {
  __typename?: 'query_root';
  calendar: Array<{ __typename?: 'calendar'; id: any }>;
};

export type GetMeetingTimeQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
}>;

export type GetMeetingTimeQuery = {
  __typename?: 'query_root';
  calendar_event_material_view: Array<{
    __typename?: 'calendar_event_material_view';
    end_date_time?: any | null;
    start_date_time?: any | null;
  }>;
};

export type GetEmployeeCapacityByManagerIdQueryVariables = Exact<{
  manager_id: Scalars['uuid'];
  dateStartTimestamp: Scalars['timestamptz'];
  dateEndTimestamp: Scalars['timestamptz'];
}>;

export type GetEmployeeCapacityByManagerIdQuery = {
  __typename?: 'query_root';
  employee_capacity_view: Array<{
    __typename?: 'employee_capacity_view';
    display_name?: string | null;
    estimate_day?: any | null;
    estimated_time?: any | null;
    employee_id?: any | null;
  }>;
};

export type GetEmployeeCapacityQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetEmployeeCapacityQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    display_name?: string | null;
    employee_bandwidth_data: Array<{
      __typename?: 'employee_bandwidth_data';
      user_id: any;
      work_day: any;
      estimated_hours: any;
      task: { __typename?: 'task'; title: string };
    }>;
  }>;
};

export type GetEmployeeCapacityGraphDataMutationVariables = Exact<{
  object: LoadEmployeeCapactiyGraphInput;
}>;

export type GetEmployeeCapacityGraphDataMutation = {
  __typename?: 'mutation_root';
  loadEmployeeCapactiyGraph?: {
    __typename?: 'loadEmployeeCapactiyGraphOutput';
    response?: Array<{
      __typename?: 'employeeCapacityData';
      displayName?: string | null;
      employeeCapacity?: Array<{
        __typename?: 'capacityData';
        userId?: string | null;
        meetingHours?: number | null;
        estimatedHours?: number | null;
        work_day?: string | null;
        task?: { __typename?: 'taskInfo'; title?: string | null } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetProjectsByPeopleQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
}>;

export type GetProjectsByPeopleQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      first_name?: string | null;
      display_name?: string | null;
      tasks: Array<{
        __typename?: 'task';
        due_date_time?: any | null;
        title: string;
        estimated_time?: any | null;
        start_date_time?: any | null;
        status?: Enum_Task_Status_Enum | null;
        assigned_at: any;
        created_at: any;
        user?: {
          __typename?: 'users';
          display_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        } | null;
      }>;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type GetProjectsByIndividualQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _lte: Scalars['timestamptz'];
  _gte: Scalars['timestamptz'];
}>;

export type GetProjectsByIndividualQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    display_name?: string | null;
    first_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
    tasks: Array<{
      __typename?: 'task';
      due_date_time?: any | null;
      estimated_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      assigned_at: any;
      created_at: any;
      start_date_time?: any | null;
      title: string;
    }>;
  }>;
};

export type GetAllUserSkillDetailsMutationVariables = Exact<{
  object: GetAllUsersBySkillInput;
}>;

export type GetAllUserSkillDetailsMutation = {
  __typename?: 'mutation_root';
  getAllUserSkillDetails?: {
    __typename?: 'GetAllUsersBySkillOutput';
    response?: Array<{
      __typename?: 'AllUserRatingDetail';
      userId?: any | null;
      userName?: any | null;
      profileImage?: string | null;
      roleName?: string | null;
      comptPercentage?: number | null;
      skillDetail?: Array<{
        __typename?: 'UserSkillsDetail';
        skillId?: any | null;
        skillName?: string | null;
        userRating?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetSkillResourceFilterMutationVariables = Exact<{
  object: SkillResourceFilterInput;
}>;

export type GetSkillResourceFilterMutation = {
  __typename?: 'mutation_root';
  getSkillResourceFilter?: {
    __typename?: 'SkillResourceFilterOutput';
    response?: Array<{
      __typename?: 'UserListSkill';
      userId?: any | null;
      userName?: string | null;
      profileImage?: string | null;
      matchScore?: number | null;
      allocation?: number | null;
      detail?: Array<{
        __typename?: 'UserDetailedInfo';
        currentProject?: string | null;
        releaseDate?: string | null;
        lastTaskDate?: string | null;
        projectAge?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GoalListQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type GoalListQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
    performance_review_byUsers: Array<{
      __typename?: 'performance_review_byUser';
      timePeriod?: string | null;
    }>;
  }>;
};

export type SpecificUserDetailsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type SpecificUserDetailsQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
      } | null;
    }>;
  }>;
};

export type ListOfSkillsUnderCompetencyInManagerDashbaordQueryVariables =
  Exact<{
    _eq: Scalars['uuid'];
  }>;

export type ListOfSkillsUnderCompetencyInManagerDashbaordQuery = {
  __typename?: 'query_root';
  main_skills: Array<{
    __typename?: 'main_skills';
    id: any;
    name: string;
    sub_skills: Array<{
      __typename?: 'sub_skills';
      id: any;
      list_Of_Skill?: {
        __typename?: 'list_Of_Skills';
        id: any;
        name: string;
      } | null;
    }>;
  }>;
};

export type GetTwoXTwoMatrixMutationVariables = Exact<{
  object: TwoXTwoMatrixInput;
}>;

export type GetTwoXTwoMatrixMutation = {
  __typename?: 'mutation_root';
  getTwoXTwoMatrix?: {
    __typename?: 'TwoXTwoMatrixOutput';
    response?: Array<{
      __typename?: 'UserMatrixDetail';
      id?: any | null;
      userName?: string | null;
      profileImage?: string | null;
      performaceMarks?: number | null;
      potentialMarks?: number | null;
    } | null> | null;
  } | null;
};

export type GetLatestTimePeriodQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type GetLatestTimePeriodQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      timePeriod: string;
    }>;
  }>;
};

export type GetStressGraphDataMutationVariables = Exact<{
  object: LoadStressDataForGraphInput;
}>;

export type GetStressGraphDataMutation = {
  __typename?: 'mutation_root';
  loadStressDataForGraph?: {
    __typename?: 'loadStressDataForGraphOutput';
    response?: Array<{
      __typename?: 'stressData';
      week1?: Array<{
        __typename?: 'weekDataCount';
        amusingCount?: number | null;
        relaxedCount?: number | null;
        balancedCount?: number | null;
        stressedCount?: number | null;
        veryStressedCount?: number | null;
      } | null> | null;
      week2?: Array<{
        __typename?: 'weekDataCount';
        amusingCount?: number | null;
        relaxedCount?: number | null;
        balancedCount?: number | null;
        stressedCount?: number | null;
        veryStressedCount?: number | null;
      } | null> | null;
      week3?: Array<{
        __typename?: 'weekDataCount';
        amusingCount?: number | null;
        relaxedCount?: number | null;
        balancedCount?: number | null;
        stressedCount?: number | null;
        veryStressedCount?: number | null;
      } | null> | null;
      week4?: Array<{
        __typename?: 'weekDataCount';
        amusingCount?: number | null;
        relaxedCount?: number | null;
        balancedCount?: number | null;
        stressedCount?: number | null;
        veryStressedCount?: number | null;
      } | null> | null;
      week5?: Array<{
        __typename?: 'weekDataCount';
        amusingCount?: number | null;
        relaxedCount?: number | null;
        balancedCount?: number | null;
        stressedCount?: number | null;
        veryStressedCount?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetTaskProductivityGraphMutationVariables = Exact<{
  object: TaskproductivityGraphInput;
}>;

export type GetTaskProductivityGraphMutation = {
  __typename?: 'mutation_root';
  taskproductivityGraph?: {
    __typename?: 'taskproductivityGraphOutput';
    response: Array<{
      __typename?: 'taskProductivityData';
      totalTask: number;
      timelyCompleted: number;
      timeDelayed: number;
      name: string;
      profile: string;
    } | null>;
  } | null;
};

export type TaskCountGraphQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
}>;

export type TaskCountGraphQuery = {
  __typename?: 'query_root';
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type TaskTimelyCompletedGraphQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _lte: Scalars['timestamptz'];
  _gte: Scalars['timestamptz'];
  _lte1: Scalars['timestamptz'];
}>;

export type TaskTimelyCompletedGraphQuery = {
  __typename?: 'query_root';
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type TaskTimeDelayedGraphQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
  _lte1: Scalars['timestamptz'];
}>;

export type TaskTimeDelayedGraphQuery = {
  __typename?: 'query_root';
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type GetTaskProductivityGraphIndividualMutationVariables = Exact<{
  object: TaskproductivityGraphInput;
}>;

export type GetTaskProductivityGraphIndividualMutation = {
  __typename?: 'mutation_root';
  taskproductivityGraph?: {
    __typename?: 'taskproductivityGraphOutput';
    response: Array<{
      __typename?: 'taskProductivityData';
      totalTask: number;
      timelyCompleted: number;
      timeDelayed: number;
      name: string;
      profile: string;
      task?: Array<{
        __typename?: 'taskDetails';
        id?: string | null;
        end_date_time?: string | null;
        title?: string | null;
        status?: string | null;
        due_date_time?: string | null;
      } | null> | null;
    } | null>;
  } | null;
};

export type GetTimeSpentGraphMutationVariables = Exact<{
  object: TimeSpentGraphInput;
}>;

export type GetTimeSpentGraphMutation = {
  __typename?: 'mutation_root';
  timeSpentGraph?: {
    __typename?: 'timeSpentGraphOutput';
    response: Array<{
      __typename?: 'GraphData';
      biblableWorkHours: number;
      nonBillableWorkHours: string;
      leaveData: number;
      name?: number | null;
      clientName: Array<{
        __typename?: 'ClientDetails';
        name: string;
        workHours: number;
      } | null>;
    } | null>;
  } | null;
};

export type Get_TableViewDataQueryVariables = Exact<{
  _eq1?: InputMaybe<Scalars['uuid']>;
  _eq?: InputMaybe<Scalars['uuid']>;
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
  _gte1: Scalars['timestamptz'];
  _lte1: Scalars['timestamptz'];
  order_by?: InputMaybe<
    Array<User_Activity_Tracker_Order_By> | User_Activity_Tracker_Order_By
  >;
}>;

export type Get_TableViewDataQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      first_name?: string | null;
      last_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
      user_activity_trackers_aggregate: {
        __typename?: 'user_activity_tracker_aggregate';
        aggregate?: {
          __typename?: 'user_activity_tracker_aggregate_fields';
          sum?: {
            __typename?: 'user_activity_tracker_sum_fields';
            active_seconds?: any | null;
            away_seconds?: any | null;
          } | null;
        } | null;
      };
      user_activity_trackers: Array<{
        __typename?: 'user_activity_tracker';
        created_at: any;
        user_id: any;
      }>;
    } | null;
  }>;
};

export type Get_TableViewIndividualDataQueryVariables = Exact<{
  _eq1?: InputMaybe<Scalars['uuid']>;
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
  _gte1: Scalars['timestamptz'];
  _lte1: Scalars['timestamptz'];
  order_by?: InputMaybe<
    Array<User_Activity_Tracker_Order_By> | User_Activity_Tracker_Order_By
  >;
}>;

export type Get_TableViewIndividualDataQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      first_name?: string | null;
      last_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
      user_activity_trackers_aggregate: {
        __typename?: 'user_activity_tracker_aggregate';
        aggregate?: {
          __typename?: 'user_activity_tracker_aggregate_fields';
          sum?: {
            __typename?: 'user_activity_tracker_sum_fields';
            active_seconds?: any | null;
            away_seconds?: any | null;
          } | null;
        } | null;
      };
      user_activity_trackers: Array<{
        __typename?: 'user_activity_tracker';
        created_at: any;
        user_id: any;
      }>;
    } | null;
  }>;
};

export type GetTimeSpentAnalysisDateRangeQueryVariables = Exact<{
  userId: Scalars['uuid'];
  dateStartTimestamp: Scalars['timestamptz'];
  dateEndTimestamp: Scalars['timestamptz'];
  dateStartDate: Scalars['date'];
  dateEndDate: Scalars['date'];
}>;

export type GetTimeSpentAnalysisDateRangeQuery = {
  __typename?: 'query_root';
  user_activity_tracker_aggregate: {
    __typename?: 'user_activity_tracker_aggregate';
    aggregate?: {
      __typename?: 'user_activity_tracker_aggregate_fields';
      sum?: {
        __typename?: 'user_activity_tracker_sum_fields';
        away_seconds?: any | null;
      } | null;
    } | null;
  };
  dashboard_meeting_aggregate: {
    __typename?: 'dashboard_meeting_aggregate';
    aggregate?: {
      __typename?: 'dashboard_meeting_aggregate_fields';
      sum?: {
        __typename?: 'dashboard_meeting_sum_fields';
        event_duration?: any | null;
      } | null;
    } | null;
  };
  dashboard_billable_aggregate: {
    __typename?: 'dashboard_billable_aggregate';
    aggregate?: {
      __typename?: 'dashboard_billable_aggregate_fields';
      sum?: {
        __typename?: 'dashboard_billable_sum_fields';
        work_hours?: any | null;
      } | null;
    } | null;
  };
  organization_user_leave_dates_aggregate: {
    __typename?: 'organization_user_leave_dates_aggregate';
    aggregate?: {
      __typename?: 'organization_user_leave_dates_aggregate_fields';
      count: number;
    } | null;
  };
};

export type Get_ScoresQueryVariables = Exact<{
  _in: Array<Scalars['String']> | Scalars['String'];
  _eq: Scalars['uuid'];
}>;

export type Get_ScoresQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    id: any;
    name: string;
    type: string;
    passing_score: any;
    assessment_records_aggregate: {
      __typename?: 'assessment_record_aggregate';
      aggregate?: {
        __typename?: 'assessment_record_aggregate_fields';
        sum?: {
          __typename?: 'assessment_record_sum_fields';
          marks?: number | null;
        } | null;
      } | null;
    };
  }>;
};

export type Individual_ScoreMutationVariables = Exact<{
  object: ScoreInput;
}>;

export type Individual_ScoreMutation = {
  __typename?: 'mutation_root';
  loadindividualScore?: {
    __typename?: 'scoreOuput';
    response: {
      __typename?: 'scoreAssessmentData';
      totalCount: number;
      totalAttendedAssessment: number;
      pendingAssessment: number;
      total: number;
    };
  } | null;
};

export type GetUserInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetUserInfoQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: any;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      agora_id?: number | null;
      activity_status?: string | null;
      timezone?: string | null;
      avatar_file?: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
      } | null;
    }>;
  } | null;
};

export type UsersAssessmentTypeQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type UsersAssessmentTypeQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    profile: Array<{
      __typename?: 'profile';
      organization_role?: {
        __typename?: 'organization_roles';
        assessments: Array<{
          __typename?: 'assessment';
          type: string;
          id: any;
        }>;
      } | null;
    }>;
  }>;
};

export type GraphDataForUpSkillMutationVariables = Exact<{
  object: AssessmentScoreForAssessmentTypeInput;
}>;

export type GraphDataForUpSkillMutation = {
  __typename?: 'mutation_root';
  loadAssessmentScoreForAssessmentType?: {
    __typename?: 'AssessmentScoreForAssessmentTypeOuput';
    response?: Array<{
      __typename?: 'assessmentTypeWiseData';
      assessmentType?: string | null;
      assessmentTypeId?: string | null;
      skilledData?: number | null;
      deviatedData?: number | null;
      totalSkilled?: number | null;
      totalDeviated?: number | null;
      userId?: Array<string | null> | null;
      userCount?: number | null;
    } | null> | null;
  } | null;
};

export type EmpDataListOfAssessmentTypeMutationVariables = Exact<{
  object: EmployeeAssessmentScoreInput;
}>;

export type EmpDataListOfAssessmentTypeMutation = {
  __typename?: 'mutation_root';
  loadEmployeeAssessmentScore?: {
    __typename?: 'employeeAssessmentScoreOuput';
    response: Array<{
      __typename?: 'empScoreAssessmentData';
      total: number;
      displayName?: string | null;
      userProfile?: string | null;
      userId?: any | null;
    } | null>;
  } | null;
};

export type IndividualEmpDataOfAssessmentTypeMutationVariables = Exact<{
  object: IndividualAssessmentTypeGraphInput;
}>;

export type IndividualEmpDataOfAssessmentTypeMutation = {
  __typename?: 'mutation_root';
  loadIndividualAssessmentTypeGraph?: {
    __typename?: 'IndividualAssessmentTypeGraphOuput';
    response?: Array<{
      __typename?: 'individualScoreAssessmentData';
      assessmentName?: string | null;
      score: number;
      totalAssessment?: number | null;
      pendingAssessment?: number | null;
      attempted?: number | null;
      passed?: number | null;
      status?: boolean | null;
      result?: string | null;
    } | null> | null;
  } | null;
};

export type InsertDashboardRemainderMutationVariables = Exact<{
  isRead: Scalars['Boolean'];
  name: Scalars['String'];
  userId: Scalars['uuid'];
  remainderType: Enum_Dashboard_Remainder_Type_Enum;
}>;

export type InsertDashboardRemainderMutation = {
  __typename?: 'mutation_root';
  insert_dashboard_remainders_one?: {
    __typename?: 'dashboard_remainders';
    id: any;
    is_read: boolean;
    name: string;
    remainder_type?: Enum_Dashboard_Remainder_Type_Enum | null;
    user_id: any;
  } | null;
};

export type GetDashboardRemaindersQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetDashboardRemaindersQuery = {
  __typename?: 'query_root';
  dashboard_remainders: Array<{
    __typename?: 'dashboard_remainders';
    id: any;
    name: string;
    user_id: any;
    remainder_type?: Enum_Dashboard_Remainder_Type_Enum | null;
    created_at: any;
    updated_at: any;
  }>;
};

export type GetMyDashboardRemaindersSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyDashboardRemaindersSubscription = {
  __typename?: 'subscription_root';
  dashboard_remainders: Array<{
    __typename?: 'dashboard_remainders';
    id: any;
    name: string;
    user_id: any;
    remainder_type?: Enum_Dashboard_Remainder_Type_Enum | null;
    created_at: any;
    updated_at: any;
  }>;
};

export type CheckReminderExistsQueryVariables = Exact<{
  userId: Scalars['uuid'];
  type: Enum_Dashboard_Remainder_Type_Enum;
}>;

export type CheckReminderExistsQuery = {
  __typename?: 'query_root';
  dashboard_remainders: Array<{ __typename?: 'dashboard_remainders'; id: any }>;
};

export type ListOfKpiByRoleIdQueryVariables = Exact<{
  roleId: Scalars['uuid'];
  orgId: Scalars['uuid'];
}>;

export type ListOfKpiByRoleIdQuery = {
  __typename?: 'query_root';
  kpiWithRole: Array<{
    __typename?: 'kpiWithRole';
    kpi_bank: {
      __typename?: 'kpi_bank';
      id: any;
      title: string;
      description: string;
    };
  }>;
};

export type MarkAsReadByIdsMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type MarkAsReadByIdsMutation = {
  __typename?: 'mutation_root';
  update_dashboard_remainders?: {
    __typename?: 'dashboard_remainders_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetUserTimeCardForManagerQueryVariables = Exact<{
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
  startTimestamp: Scalars['timestamptz'];
  endTimestamp: Scalars['timestamptz'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetUserTimeCardForManagerQuery = {
  __typename?: 'query_root';
  user_activity_tracker: Array<{
    __typename?: 'user_activity_tracker';
    active_seconds: any;
    extra_seconds?: any | null;
    away_seconds: any;
  }>;
  dashboard_meeting_aggregate: {
    __typename?: 'dashboard_meeting_aggregate';
    aggregate?: {
      __typename?: 'dashboard_meeting_aggregate_fields';
      sum?: {
        __typename?: 'dashboard_meeting_sum_fields';
        event_duration?: any | null;
      } | null;
    } | null;
  };
  dashboard_billable_aggregate: {
    __typename?: 'dashboard_billable_aggregate';
    aggregate?: {
      __typename?: 'dashboard_billable_aggregate_fields';
      sum?: {
        __typename?: 'dashboard_billable_sum_fields';
        work_hours?: any | null;
      } | null;
    } | null;
  };
};

export type GetEmployeesByManagerIdQueryVariables = Exact<{
  manager_id: Scalars['uuid'];
}>;

export type GetEmployeesByManagerIdQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user_id?: any | null;
  }>;
};

export type GetUserTimeCardForEmployeeQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  startTimestamp: Scalars['timestamptz'];
  endTimestamp: Scalars['timestamptz'];
  remoteStartDate: Scalars['date'];
  remoteEndDate: Scalars['date'];
}>;

export type GetUserTimeCardForEmployeeQuery = {
  __typename?: 'query_root';
  user_activity_tracker: Array<{
    __typename?: 'user_activity_tracker';
    active_seconds: any;
    extra_seconds?: any | null;
  }>;
  hotdesk_bookings_aggregate: {
    __typename?: 'hotdesk_bookings_aggregate';
    aggregate?: {
      __typename?: 'hotdesk_bookings_aggregate_fields';
      count: number;
    } | null;
  };
  dashboard_meeting_aggregate: {
    __typename?: 'dashboard_meeting_aggregate';
    aggregate?: {
      __typename?: 'dashboard_meeting_aggregate_fields';
      sum?: {
        __typename?: 'dashboard_meeting_sum_fields';
        event_duration?: any | null;
      } | null;
    } | null;
  };
};

export type GetUpcomingEventsQueryVariables = Exact<{
  calendar_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  start_date_time: Scalars['timestamptz'];
  end_date_time: Scalars['timestamptz'];
}>;

export type GetUpcomingEventsQuery = {
  __typename?: 'query_root';
  calendar_event_material_view: Array<{
    __typename?: 'calendar_event_material_view';
    additional_fields?: any | null;
    all_day?: boolean | null;
    calendar_id?: any | null;
    created_at?: any | null;
    created_by?: any | null;
    end_date_time?: any | null;
    event_name?: string | null;
    event_type?: string | null;
    start_date_time?: any | null;
    updated_at?: any | null;
    id?: any | null;
    joinedMeetingUsers?: Array<{
      __typename?: 'calendar_meeting_call_user';
      status: Enum_Call_User_Status_Enum;
      user: { __typename?: 'users'; display_name?: string | null };
    }> | null;
    calendar_events?: {
      __typename?: 'calendar_event';
      calendar_meeting_calls: Array<{
        __typename?: 'calendar_meeting_call';
        meeting_call_users: Array<{
          __typename?: 'calendar_meeting_call_user';
          status: Enum_Call_User_Status_Enum;
          user: { __typename?: 'users'; display_name?: string | null };
        }>;
      }>;
    } | null;
  }>;
};

export type GetUserStateByIdSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserStateByIdSubscription = {
  __typename?: 'subscription_root';
  user_state: Array<{
    __typename?: 'user_state';
    manual_state: Enum_Manual_State_Enum;
    activity_state: Enum_Activity_State_Enum;
  }>;
};

export type GetProfilePicQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetProfilePicQuery = {
  __typename?: 'query_root';
  profile: Array<{
    __typename?: 'profile';
    avatar_file?: {
      __typename?: 'storage_files';
      file_url: string;
      id: any;
    } | null;
  }>;
};

export type GetAllDepartmentsQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetAllDepartmentsQuery = {
  __typename?: 'query_root';
  department: Array<{
    __typename?: 'department';
    id: any;
    manager_id?: any | null;
    name: string;
    organization_id: any;
  }>;
};

export type NewPostMutationVariables = Exact<{
  object?: InputMaybe<NewPostInput>;
}>;

export type NewPostMutation = {
  __typename?: 'mutation_root';
  newPost?: { __typename?: 'newPostOutput'; post_id?: string | null } | null;
};

export type GetMyPostsQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  offset?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetMyPostsQuery = {
  __typename?: 'query_root';
  posts: Array<{
    __typename?: 'posts';
    created_at: any;
    id: any;
    is_active: boolean;
    post_type?: Enum_Post_Type_Enum | null;
    message: string;
    date: any;
    disappeared_at?: any | null;
    holiday_id?: any | null;
    project_id?: any | null;
    visibility: string;
    user_id: any;
    comments_aggregate: {
      __typename?: 'posts_comments_aggregate';
      aggregate?: {
        __typename?: 'posts_comments_aggregate_fields';
        count: number;
      } | null;
    };
    comments: Array<{
      __typename?: 'posts_comments';
      id: any;
      comment: string;
      created_at: any;
      commentedByUser: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
    post_files: Array<{
      __typename?: 'posts_files';
      file_url: string;
      storage_file?: { __typename?: 'storage_files'; metadata: any } | null;
    }>;
    likes_aggregate: {
      __typename?: 'post_likes_aggregate';
      aggregate?: {
        __typename?: 'post_likes_aggregate_fields';
        count: number;
      } | null;
    };
    likes: Array<{
      __typename?: 'post_likes';
      likedByUser: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      };
    }>;
    mentioned_users: Array<{
      __typename?: 'post_mentioned_users';
      id: any;
      user_id: any;
      post_id: any;
    }>;
    posts_poll: Array<{
      __typename?: 'posts_poll_choices';
      created_at: any;
      id: any;
      poll_name: string;
      post_id: any;
      updated_at: any;
      votes: Array<{
        __typename?: 'posts_poll_choices_votes';
        created_at: any;
        id: any;
        poll_choice_id: any;
        updated_at: any;
        voted_by: any;
        user?: { __typename?: 'users'; display_name?: string | null } | null;
      }>;
      votes_aggregate: {
        __typename?: 'posts_poll_choices_votes_aggregate';
        aggregate?: {
          __typename?: 'posts_poll_choices_votes_aggregate_fields';
          count: number;
        } | null;
      };
    }>;
    project?: {
      __typename?: 'project';
      id: any;
      title: string;
      project_members: Array<{
        __typename?: 'project_members';
        user: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
          first_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            activity_status?: string | null;
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      }>;
    } | null;
  }>;
};

export type GetTodayEventQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  offset?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  today: Scalars['date'];
}>;

export type GetTodayEventQuery = {
  __typename?: 'query_root';
  posts: Array<{
    __typename?: 'posts';
    created_at: any;
    id: any;
    is_active: boolean;
    post_type?: Enum_Post_Type_Enum | null;
    message: string;
    date: any;
    visibility: string;
    user_id: any;
    comments_aggregate: {
      __typename?: 'posts_comments_aggregate';
      aggregate?: {
        __typename?: 'posts_comments_aggregate_fields';
        count: number;
      } | null;
    };
    comments: Array<{
      __typename?: 'posts_comments';
      id: any;
      comment: string;
      created_at: any;
      commentedByUser: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
    post_files: Array<{
      __typename?: 'posts_files';
      file_url: string;
      storage_file?: { __typename?: 'storage_files'; metadata: any } | null;
    }>;
    likes_aggregate: {
      __typename?: 'post_likes_aggregate';
      aggregate?: {
        __typename?: 'post_likes_aggregate_fields';
        count: number;
      } | null;
    };
    likes: Array<{
      __typename?: 'post_likes';
      likedByUser: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      };
    }>;
    mentioned_users: Array<{
      __typename?: 'post_mentioned_users';
      id: any;
      user_id: any;
      post_id: any;
    }>;
  }>;
};

export type GetMoreCommentsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetMoreCommentsQuery = {
  __typename?: 'query_root';
  posts_comments: Array<{
    __typename?: 'posts_comments';
    id: any;
    comment: string;
    created_at: any;
    commented_by: any;
    commentsLikes_aggregate: {
      __typename?: 'post_comments_likes_aggregate';
      aggregate?: {
        __typename?: 'post_comments_likes_aggregate_fields';
        count: number;
      } | null;
    };
    commentsLikes: Array<{
      __typename?: 'post_comments_likes';
      liked_by: any;
      user?: { __typename?: 'users'; display_name?: string | null } | null;
    }>;
    commentReplies: Array<{
      __typename?: 'post_comment_replies';
      created_at: any;
      reply?: string | null;
      replied_by: any;
      comment_id: any;
      id: any;
      userWhoReplied?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      } | null;
      likes: Array<{
        __typename?: 'post_replies_likes';
        liked_by: any;
        user?: { __typename?: 'users'; display_name?: string | null } | null;
      }>;
      likes_aggregate: {
        __typename?: 'post_replies_likes_aggregate';
        aggregate?: {
          __typename?: 'post_replies_likes_aggregate_fields';
          count: number;
        } | null;
      };
    }>;
    commentedByUser: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type GetPostAggregateSubscriptionVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetPostAggregateSubscription = {
  __typename?: 'subscription_root';
  posts_aggregate: {
    __typename?: 'posts_aggregate';
    aggregate?: { __typename?: 'posts_aggregate_fields'; count: number } | null;
  };
};

export type AddCommentMutationVariables = Exact<{
  object?: InputMaybe<AddCommentInput>;
}>;

export type AddCommentMutation = {
  __typename?: 'mutation_root';
  addComment?: {
    __typename?: 'addCommentOutput';
    comment_id?: string | null;
    post_id?: string | null;
  } | null;
};

export type LikePostMutationVariables = Exact<{
  object?: InputMaybe<LikesInput>;
}>;

export type LikePostMutation = {
  __typename?: 'mutation_root';
  postLikes?: {
    __typename?: 'likesOutput';
    like_id?: string | null;
    post_id?: string | null;
  } | null;
};

export type DeletePostMutationVariables = Exact<{
  object?: InputMaybe<EditPostInput>;
}>;

export type DeletePostMutation = {
  __typename?: 'mutation_root';
  editPost?: { __typename?: 'editPostOutput'; post_id?: string | null } | null;
};

export type DeleteCommentMutationVariables = Exact<{
  object?: InputMaybe<EditCommentInput>;
}>;

export type DeleteCommentMutation = {
  __typename?: 'mutation_root';
  editComment: {
    __typename?: 'editCommentOutput';
    post_id?: string | null;
    comment_id?: string | null;
    message?: string | null;
  };
};

export type GetOrganizationLogoByOrganizationIdQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
}>;

export type GetOrganizationLogoByOrganizationIdQuery = {
  __typename?: 'query_root';
  organization_by_pk?: {
    __typename?: 'organization';
    id: any;
    logo_file_id?: any | null;
    storageFileByLogoFileId?: {
      __typename?: 'storage_files';
      file_url: string;
    } | null;
  } | null;
};

export type Get_UsersInfoOf_OrganizationAdminQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_UsersInfoOf_OrganizationAdminQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type GetTodayLeavesQueryVariables = Exact<{
  date: Scalars['String'];
  organization_id: Scalars['uuid'];
}>;

export type GetTodayLeavesQuery = {
  __typename?: 'query_root';
  organization_user_leave_dates: Array<{
    __typename?: 'organization_user_leave_dates';
    leave_application?: {
      __typename?: 'organization_user_leave_application';
      id: any;
      user_leave_data?: {
        __typename?: 'organization_user_leave_data';
        user?: { __typename?: 'users'; first_name?: string | null } | null;
      } | null;
    } | null;
  }>;
};

export type InsertStressGraphDataMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Stress_Data_Insert_Input> | Stress_Data_Insert_Input
  >;
}>;

export type InsertStressGraphDataMutation = {
  __typename?: 'mutation_root';
  insert_stress_data?: {
    __typename?: 'stress_data_mutation_response';
    returning: Array<{ __typename?: 'stress_data'; id: any }>;
  } | null;
};

export type CheckStressDataQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _gte: Scalars['date'];
  _lte: Scalars['date'];
}>;

export type CheckStressDataQuery = {
  __typename?: 'query_root';
  stress_data: Array<{ __typename?: 'stress_data'; id: any; feedback: number }>;
};

export type GetUserTimeCardForUserQueryVariables = Exact<{
  userIds: Scalars['uuid'];
  startTimestamp: Scalars['timestamptz'];
  endTimestamp: Scalars['timestamptz'];
}>;

export type GetUserTimeCardForUserQuery = {
  __typename?: 'query_root';
  user_activity_tracker: Array<{
    __typename?: 'user_activity_tracker';
    active_seconds: any;
    extra_seconds?: any | null;
    away_seconds: any;
  }>;
};

export type Get_ListingYear_GraphQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_ListingYear_GraphQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    year: string;
  }>;
};

export type Get_ListOf_EmployeesByOrgIdQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_ListOf_EmployeesByOrgIdQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role_id?: any | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type Get_ListOfPeersByUserQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type Get_ListOfPeersByUserQuery = {
  __typename?: 'query_root';
  User_Peer_Review: Array<{
    __typename?: 'User_Peer_Review';
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  }>;
};

export type GetAllTasksForDashboardSubscriptionVariables = Exact<{
  empId: Scalars['uuid'];
}>;

export type GetAllTasksForDashboardSubscription = {
  __typename?: 'subscription_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    updated_at: any;
  }>;
};

export type InitiateDashboardRemindersMutationVariables = Exact<{
  object: RemainderInput;
}>;

export type InitiateDashboardRemindersMutation = {
  __typename?: 'mutation_root';
  getDashboardReminders: { __typename?: 'RemainderOutput'; id: any };
};

export type GetMyTimesheetForGivenWeekSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
}>;

export type GetMyTimesheetForGivenWeekSubscription = {
  __typename?: 'subscription_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    id: any;
    work_date: any;
    task_name?: string | null;
  }>;
};

export type CheckMandatoryAssessmentStatusQueryVariables = Exact<{
  roleId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type CheckMandatoryAssessmentStatusQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    id: any;
    user: { __typename?: 'users'; id: any; display_name?: string | null };
    assessment_records: Array<{
      __typename?: 'assessment_record';
      id: any;
      user: { __typename?: 'users'; id: any; display_name?: string | null };
    }>;
  }>;
};

export type NewPollPostMutationVariables = Exact<{
  object?: InputMaybe<NewPollPostInput>;
}>;

export type NewPollPostMutation = {
  __typename?: 'mutation_root';
  newPollPost?: {
    __typename?: 'newPollPostOutput';
    post_id?: string | null;
  } | null;
};

export type GetPollByPostIdQueryVariables = Exact<{
  postId: Scalars['uuid'];
}>;

export type GetPollByPostIdQuery = {
  __typename?: 'query_root';
  posts: Array<{
    __typename?: 'posts';
    id: any;
    posts_poll: Array<{
      __typename?: 'posts_poll_choices';
      created_at: any;
      id: any;
      poll_name: string;
      post_id: any;
      updated_at: any;
      votes: Array<{
        __typename?: 'posts_poll_choices_votes';
        created_at: any;
        id: any;
        poll_choice_id: any;
        updated_at: any;
        voted_by: any;
      }>;
      votes_aggregate: {
        __typename?: 'posts_poll_choices_votes_aggregate';
        aggregate?: {
          __typename?: 'posts_poll_choices_votes_aggregate_fields';
          count: number;
        } | null;
      };
    }>;
  }>;
};

export type AddNewVoteToPollMutationVariables = Exact<{
  pollChoiceId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type AddNewVoteToPollMutation = {
  __typename?: 'mutation_root';
  insert_posts_poll_choices_votes_one?: {
    __typename?: 'posts_poll_choices_votes';
    id: any;
  } | null;
};

export type RemoveVoteFromPollByVoterMutationVariables = Exact<{
  pollChoiceId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveVoteFromPollByVoterMutation = {
  __typename?: 'mutation_root';
  delete_posts_poll_choices_votes?: {
    __typename?: 'posts_poll_choices_votes_mutation_response';
    affected_rows: number;
  } | null;
};

export type SetPollPostInactiveMutationVariables = Exact<{
  postId: Scalars['uuid'];
}>;

export type SetPollPostInactiveMutation = {
  __typename?: 'mutation_root';
  update_posts?: {
    __typename?: 'posts_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'posts'; id: any; is_active: boolean }>;
  } | null;
};

export type GetPollQuestionAndTimeQueryVariables = Exact<{
  postId: Scalars['uuid'];
}>;

export type GetPollQuestionAndTimeQuery = {
  __typename?: 'query_root';
  posts: Array<{
    __typename?: 'posts';
    disappeared_at?: any | null;
    message: string;
  }>;
};

export type GetHolidayDataByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetHolidayDataByIdQuery = {
  __typename?: 'query_root';
  organization_holidays: Array<{
    __typename?: 'organization_holidays';
    id: any;
    date: any;
    description?: string | null;
    name: string;
  }>;
};

export type GetActiveOrgAwardsSubscriptionVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetActiveOrgAwardsSubscription = {
  __typename?: 'subscription_root';
  organization_awards: Array<{
    __typename?: 'organization_awards';
    id: any;
    is_active: boolean;
    manager_permission: boolean;
    created_at: any;
    deleted_at?: any | null;
    award_types: Array<{
      __typename?: 'awards';
      id: any;
      name: string;
      description?: string | null;
      cycle: Enum_Award_Cycle_Type_Enum;
      limit?: number | null;
    }>;
  }>;
};

export type CheckIsAwardActiveSubscriptionVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type CheckIsAwardActiveSubscription = {
  __typename?: 'subscription_root';
  organization_awards: Array<{
    __typename?: 'organization_awards';
    id: any;
    is_active: boolean;
  }>;
};

export type CheckIsManagerActiveSubscriptionVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type CheckIsManagerActiveSubscription = {
  __typename?: 'subscription_root';
  organization_awards: Array<{
    __typename?: 'organization_awards';
    id: any;
    manager_permission: boolean;
  }>;
};

export type AssignAwardMutationVariables = Exact<{
  object: AssignAwardInput;
}>;

export type AssignAwardMutation = {
  __typename?: 'mutation_root';
  assignAward?: { __typename?: 'assignAwardOutput'; id?: string | null } | null;
};

export type GetAwardTypeByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetAwardTypeByIdQuery = {
  __typename?: 'query_root';
  awards: Array<{
    __typename?: 'awards';
    id: any;
    post_id?: any | null;
    cycle: Enum_Award_Cycle_Type_Enum;
    limit?: number | null;
  }>;
};

export type GetPostMentionedUsersByPostIdQueryVariables = Exact<{
  postId: Scalars['uuid'];
}>;

export type GetPostMentionedUsersByPostIdQuery = {
  __typename?: 'query_root';
  post_mentioned_users: Array<{
    __typename?: 'post_mentioned_users';
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type GetTokenByUserIdSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetTokenByUserIdSubscription = {
  __typename?: 'subscription_root';
  document_token: Array<{
    __typename?: 'document_token';
    access_token: string;
    token_type: Enum_Document_Token_Type_Enum;
  }>;
};

export type OnedriveAuthenticationMutationVariables = Exact<{
  object: OnedriveAuthenticationInput;
}>;

export type OnedriveAuthenticationMutation = {
  __typename?: 'mutation_root';
  onedriveAuthentication?: {
    __typename?: 'OnedriveAuthenticationOutPut';
    accessToken: string;
  } | null;
};

export type OnedriveLogoutMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type OnedriveLogoutMutation = {
  __typename?: 'mutation_root';
  onedriveLogOut?: {
    __typename?: 'OnedriveLogoutOutput';
    status: string;
  } | null;
};

export type GoogledriveAuthenticationMutationVariables = Exact<{
  object: GoogledriveAuthenticationInput;
}>;

export type GoogledriveAuthenticationMutation = {
  __typename?: 'mutation_root';
  googledriveAuthentication?: {
    __typename?: 'GoogledriveAuthenticationOutput';
    accessToken: string;
  } | null;
};

export type GoogledriveLogoutMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GoogledriveLogoutMutation = {
  __typename?: 'mutation_root';
  googledriveLogOut?: {
    __typename?: 'GoogledriveLogoutOutput';
    status: string;
  } | null;
};

export type DropboxAuthenticationMutationVariables = Exact<{
  object: DropboxAuthenticationInput;
}>;

export type DropboxAuthenticationMutation = {
  __typename?: 'mutation_root';
  dropboxAuthentication?: {
    __typename?: 'DropboxAuthenticationOutput';
    accessToken: string;
  } | null;
};

export type DropboxLogOutMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type DropboxLogOutMutation = {
  __typename?: 'mutation_root';
  dropboxLogOut?: { __typename?: 'DropboxLogoutOutput'; status: string } | null;
};

export type EmployeeOnBoardingMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  avatar_file_id?: InputMaybe<Scalars['uuid']>;
  gender?: InputMaybe<Enum_User_Gender_Enum>;
  timezone?: InputMaybe<Scalars['String']>;
}>;

export type EmployeeOnBoardingMutation = {
  __typename?: 'mutation_root';
  update_users_by_pk?: { __typename?: 'users'; id: any } | null;
  update_profile?: {
    __typename?: 'profile_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetOrganizationLocationsQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
  title?: InputMaybe<Scalars['String']>;
}>;

export type GetOrganizationLocationsQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    id: any;
    title: string;
  }>;
};

export type GetBookedUserNameQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
  date?: InputMaybe<Scalars['date']>;
}>;

export type GetBookedUserNameQuery = {
  __typename?: 'query_root';
  hotdesk_bookings: Array<{
    __typename?: 'hotdesk_bookings';
    user: { __typename?: 'users'; display_name?: string | null };
  }>;
};

export type GetLocationDetailsQueryVariables = Exact<{
  booking_date?: InputMaybe<Scalars['date']>;
  locationId: Scalars['uuid'];
}>;

export type GetLocationDetailsQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    id: any;
    title: string;
    total_seats: number;
    booked_seats?: any | null;
  }>;
};

export type BookSeatsMutationVariables = Exact<{
  objects: Array<Hotdesk_Bookings_Insert_Input> | Hotdesk_Bookings_Insert_Input;
}>;

export type BookSeatsMutation = {
  __typename?: 'mutation_root';
  insert_hotdesk_bookings?: {
    __typename?: 'hotdesk_bookings_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetUserBookingsQueryVariables = Exact<{
  userId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetUserBookingsQuery = {
  __typename?: 'query_root';
  hotdesk_bookings: Array<{
    __typename?: 'hotdesk_bookings';
    id: any;
    date: any;
    location_id: any;
    created_at: any;
  }>;
  hotdesk_bookings_aggregate: {
    __typename?: 'hotdesk_bookings_aggregate';
    aggregate?: {
      __typename?: 'hotdesk_bookings_aggregate_fields';
      count: number;
    } | null;
  };
};

export type PermanentDeleteMutationVariables = Exact<{
  bookingId: Scalars['uuid'];
}>;

export type PermanentDeleteMutation = {
  __typename?: 'mutation_root';
  delete_hotdesk_bookings_by_pk?: {
    __typename?: 'hotdesk_bookings';
    id: any;
  } | null;
};

export type UpdateBookingMutationVariables = Exact<{
  booking_id: Scalars['uuid'];
  date?: InputMaybe<Scalars['date']>;
  location_id: Scalars['uuid'];
}>;

export type UpdateBookingMutation = {
  __typename?: 'mutation_root';
  update_hotdesk_bookings_by_pk?: {
    __typename?: 'hotdesk_bookings';
    id: any;
  } | null;
};

export type GetUserLeaveDataSubscriptionVariables = Exact<{
  user_id: Scalars['uuid'];
}>;

export type GetUserLeaveDataSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    earned_leaves_amount?: any | null;
    last_year_carry_forward: number;
    leave_type?: string | null;
    id: any;
    used_leave_amount: number;
    org_id: any;
    leave_type_id?: any | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      leave_type: string;
      annual_carry_forward_amount?: number | null;
      will_be_earned_monthly: boolean;
      annual_eligibility: number;
      limit_of_days_at_once?: number | null;
    } | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      created_at: any;
      leave_type: string;
      id: any;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_reason?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      leave_amount_in_days: number;
    }> | null;
    leave_applications: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      is_approved: boolean;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      is_active?: boolean | null;
      year?: number | null;
      leave_reason?: string | null;
      leaveDates: Array<{
        __typename?: 'organization_user_leave_dates';
        id: any;
        created_at: any;
        updated_at: any;
        org_user_id: any;
        leave_application_id: any;
        date: string;
      }>;
    }>;
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      leave_amount_in_days: number;
    }> | null;
  }>;
};

export type LeaveApplicationsByUserIdAndStatusSubscriptionVariables = Exact<{
  org_id: Scalars['uuid'];
  user_id?: InputMaybe<Scalars['uuid']>;
  leaveStatus?: InputMaybe<
    Array<Enum_Leave_Status_Type_Enum> | Enum_Leave_Status_Type_Enum
  >;
  orderBy?: InputMaybe<
    | Array<Organization_User_Leave_Application_Order_By>
    | Organization_User_Leave_Application_Order_By
  >;
}>;

export type LeaveApplicationsByUserIdAndStatusSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_application: Array<{
    __typename?: 'organization_user_leave_application';
    created_at: any;
    leave_type: string;
    id: any;
    leave_start_date?: string | null;
    leave_end_date?: string | null;
    leave_reason?: string | null;
    leave_amount_in_days: number;
    leave_application_status: Enum_Leave_Status_Type_Enum;
    user_leave_data?: {
      __typename?: 'organization_user_leave_data';
      user_role: string;
      user?: { __typename?: 'users'; display_name?: string | null } | null;
    } | null;
    leaveDates: Array<{
      __typename?: 'organization_user_leave_dates';
      date: string;
    }>;
  }>;
};

export type ApprovalRequestsByStatusSubscriptionVariables = Exact<{
  org_id: Scalars['uuid'];
  user_id?: InputMaybe<Scalars['uuid']>;
  leaveStatus?: InputMaybe<
    Array<Enum_Leave_Status_Type_Enum> | Enum_Leave_Status_Type_Enum
  >;
  orderBy?: InputMaybe<
    | Array<Organization_User_Leave_Application_Order_By>
    | Organization_User_Leave_Application_Order_By
  >;
}>;

export type ApprovalRequestsByStatusSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_application: Array<{
    __typename?: 'organization_user_leave_application';
    created_at: any;
    leave_type: string;
    id: any;
    leave_start_date?: string | null;
    leave_end_date?: string | null;
    leave_reason?: string | null;
    leave_amount_in_days: number;
    leave_application_status: Enum_Leave_Status_Type_Enum;
    user_leave_data?: {
      __typename?: 'organization_user_leave_data';
      user_role: string;
      user?: {
        __typename?: 'users';
        display_name?: string | null;
        organization_employees: Array<{
          __typename?: 'organization_employee';
          manager_id?: any | null;
        }>;
      } | null;
    } | null;
    leaveDates: Array<{
      __typename?: 'organization_user_leave_dates';
      date: string;
    }>;
  }>;
};

export type SubmitLeaveMutationVariables = Exact<{
  org_id: Scalars['String'];
  leave_type_id: Scalars['String'];
  leave_start_date?: InputMaybe<Scalars['String']>;
  leave_end_date?: InputMaybe<Scalars['String']>;
  leave_files_id?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  leave_dates:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
  leave_reason?: InputMaybe<Scalars['String']>;
}>;

export type SubmitLeaveMutation = {
  __typename?: 'mutation_root';
  submitLeave: {
    __typename: 'SubmitLeaveOutput';
    new_employee_leave_id: string;
  };
};

export type UpdateLeaveStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  status?: InputMaybe<Enum_Leave_Status_Type_Enum>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateLeaveStatusMutation = {
  __typename?: 'mutation_root';
  update_organization_user_leave_application?: {
    __typename: 'organization_user_leave_application_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteLeaveApplicationAndInfoMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteLeaveApplicationAndInfoMutation = {
  __typename?: 'mutation_root';
  delete_organization_user_leave_dates?: {
    __typename?: 'organization_user_leave_dates_mutation_response';
    affected_rows: number;
  } | null;
  delete_organization_user_leave_files?: {
    __typename?: 'organization_user_leave_files_mutation_response';
    affected_rows: number;
  } | null;
  delete_organization_user_leave_application_by_pk?: {
    __typename?: 'organization_user_leave_application';
    id: any;
  } | null;
};

export type GetHolidaysInStartAndEndDateQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
  start_date: Scalars['date'];
  end_date: Scalars['date'];
}>;

export type GetHolidaysInStartAndEndDateQuery = {
  __typename?: 'query_root';
  holidays: Array<{
    __typename?: 'organization_holidays';
    id: any;
    name: string;
    description?: string | null;
    date: any;
  }>;
};

export type GetLiveCallInfoByIdSubscriptionVariables = Exact<{
  liveCallId: Scalars['uuid'];
}>;

export type GetLiveCallInfoByIdSubscription = {
  __typename?: 'subscription_root';
  live_call: Array<{
    __typename?: 'live_call';
    live_call_status: Enum_Live_Call_Status_Enum;
    live_call_requests: Array<{
      __typename?: 'live_call_request';
      request_status: Enum_Live_Call_Request_Status_Enum;
    }>;
  }>;
};

export type GetMyChatGroupsSubscriptionVariables = Exact<{
  myUserId: Scalars['uuid'];
  organizationsId: Scalars['uuid'];
}>;

export type GetMyChatGroupsSubscription = {
  __typename?: 'subscription_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    room_name?: string | null;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      chat_room: { __typename?: 'chat_room'; updated_at: any };
    }>;
  }>;
};

export type GetMyChatGroupsWithSearchQueryVariables = Exact<{
  myUserId: Scalars['uuid'];
  organizationsId: Scalars['uuid'];
  roomName?: InputMaybe<Scalars['String']>;
}>;

export type GetMyChatGroupsWithSearchQuery = {
  __typename?: 'query_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    room_name?: string | null;
  }>;
};

export type GetCurrentSelectionOfUsersQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  chatRoomId?: InputMaybe<Scalars['uuid']>;
  departmentId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetCurrentSelectionOfUsersQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      chat_room_users: Array<{
        __typename?: 'chat_room_user';
        chat_room: { __typename?: 'chat_room'; id: any; updated_at: any };
      }>;
    } | null;
  }>;
};

export type GetUserIdsByOrganizationIdQueryVariables = Exact<{
  departmentId?: InputMaybe<Scalars['uuid']>;
  organizationId: Scalars['uuid'];
  search?: InputMaybe<Scalars['String']>;
}>;

export type GetUserIdsByOrganizationIdQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user_id?: any | null;
  }>;
};

export type GetUserInfoForLiveOfficeSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserInfoForLiveOfficeSubscription = {
  __typename?: 'subscription_root';
  user?: {
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    my_organization?: {
      __typename?: 'organization_user';
      role_title?: string | null;
      manager?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      } | null;
    } | null;
    user_settings: Array<{ __typename?: 'user_settings'; working_from: any }>;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      activity_status_since?: any | null;
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
    live_call_users: Array<{
      __typename?: 'live_call_user';
      status: Enum_Live_Call_User_Status_Enum;
    }>;
  } | null;
};

export type GetUserInfoForLiveOfficeQQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserInfoForLiveOfficeQQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    my_organization?: {
      __typename?: 'organization_user';
      role_title?: string | null;
      manager?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      } | null;
    } | null;
    user_settings: Array<{ __typename?: 'user_settings'; working_from: any }>;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      activity_status_since?: any | null;
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
    live_call_users: Array<{
      __typename?: 'live_call_user';
      status: Enum_Live_Call_User_Status_Enum;
    }>;
  } | null;
};

export type GetDepartmentsByOrgIdFilterQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  departmentName?: InputMaybe<Scalars['String']>;
}>;

export type GetDepartmentsByOrgIdFilterQuery = {
  __typename?: 'query_root';
  department: Array<{ __typename?: 'department'; id: any; name: string }>;
};

export type GetChatRoomInfoQueryVariables = Exact<{
  roomId: Scalars['uuid'];
}>;

export type GetChatRoomInfoQuery = {
  __typename?: 'query_root';
  chat_room_by_pk?: {
    __typename?: 'chat_room';
    id: any;
    room_name?: string | null;
  } | null;
};

export type GetMyUnreadMessagesCountSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
  chatRoomId: Scalars['uuid'];
}>;

export type GetMyUnreadMessagesCountSubscription = {
  __typename?: 'subscription_root';
  chat_message_action_aggregate: {
    __typename?: 'chat_message_action_aggregate';
    aggregate?: {
      __typename?: 'chat_message_action_aggregate_fields';
      count: number;
    } | null;
  };
};

export type PinChatMutationVariables = Exact<{
  object: Chat_Pinned_Insert_Input;
}>;

export type PinChatMutation = {
  __typename?: 'mutation_root';
  insert_chat_pinned_one?: { __typename?: 'chat_pinned'; id: any } | null;
};

export type GetPinnedChatsSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetPinnedChatsSubscription = {
  __typename?: 'subscription_root';
  chat_pinned: Array<{
    __typename?: 'chat_pinned';
    id: any;
    user_id: any;
    is_pinned: boolean;
    room?: {
      __typename?: 'chat_room';
      id: any;
      room_name?: string | null;
    } | null;
  }>;
};

export type DeletePinnedChatByIdMutationVariables = Exact<{
  Id?: InputMaybe<Scalars['uuid']>;
}>;

export type DeletePinnedChatByIdMutation = {
  __typename?: 'mutation_root';
  delete_chat_pinned?: {
    __typename?: 'chat_pinned_mutation_response';
    returning: Array<{ __typename?: 'chat_pinned'; id: any }>;
  } | null;
};

export type CheckIsPinnedQueryVariables = Exact<{
  userId: Scalars['uuid'];
  chatRoomId: Scalars['uuid'];
}>;

export type CheckIsPinnedQuery = {
  __typename?: 'query_root';
  chat_pinned: Array<{ __typename?: 'chat_pinned'; id: any }>;
};

export type GetMeetingCallBySlugSubscriptionVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetMeetingCallBySlugSubscription = {
  __typename?: 'subscription_root';
  calendar_meeting_call: Array<{
    __typename?: 'calendar_meeting_call';
    id: any;
    slug?: string | null;
    channel_id: string;
    chat_id?: any | null;
    meet_status?: Enum_Call_Status_Type_Enum | null;
    start_date_time?: any | null;
    end_date_time?: any | null;
    org_id: any;
    event_id: any;
    recording_status?: Enum_Call_Recording_Status_Enum | null;
    calendar_event: {
      __typename?: 'calendar_event';
      event_name: string;
      additional_fields: any;
    };
    created_by_user: {
      __typename?: 'users';
      id: any;
      first_name?: string | null;
      last_name?: string | null;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
    meeting_call_users: Array<{
      __typename?: 'calendar_meeting_call_user';
      user_id: any;
      status: Enum_Call_User_Status_Enum;
      user: {
        __typename?: 'users';
        first_name?: string | null;
        last_name?: string | null;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          agora_id?: number | null;
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
    chat_room?: Array<{
      __typename?: 'chat_room';
      id: any;
      room_name?: string | null;
    }> | null;
  }>;
};

export type JoinMeetCallMutationVariables = Exact<{
  meetingCallId: Scalars['uuid'];
}>;

export type JoinMeetCallMutation = {
  __typename?: 'mutation_root';
  joinMeetCall?: {
    __typename?: 'JoinMeetOutput';
    calendarMeetingCallUserId: any;
  } | null;
};

export type RequestCalendarMeetCallMutationVariables = Exact<{
  meeting_call_id: Scalars['uuid'];
  user_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type RequestCalendarMeetCallMutation = {
  __typename?: 'mutation_root';
  requestCalendarMeetCall?: {
    __typename?: 'RequestCalendarMeetCallOutput';
    success?: boolean | null;
  } | null;
};

export type GetRequestedUsersSubscriptionVariables = Exact<{
  meetCallId: Scalars['uuid'];
}>;

export type GetRequestedUsersSubscription = {
  __typename?: 'subscription_root';
  request: Array<{
    __typename?: 'calendar_meeting_call_request';
    status?: Enum_Call_Request_Status_Type_Enum | null;
    user: {
      __typename?: 'users';
      id: any;
      first_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type GetCalendarMeetByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetCalendarMeetByIdSubscription = {
  __typename?: 'subscription_root';
  calendar_event_by_pk?: {
    __typename?: 'calendar_event';
    additional_fields: any;
    all_day: boolean;
    id: any;
    event_name: string;
    start_date_time: any;
    end_date_time: any;
    created_by: any;
    rrule?: string | null;
    calendar_meeting_calls: Array<{
      __typename?: 'calendar_meeting_call';
      meet_status?: Enum_Call_Status_Type_Enum | null;
      channel_id: string;
      slug?: string | null;
      meeting_call_users: Array<{
        __typename?: 'calendar_meeting_call_user';
        status: Enum_Call_User_Status_Enum;
        user: { __typename?: 'users'; display_name?: string | null };
      }>;
    }>;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  } | null;
};

export type GetMeetingIdByMeetingIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetMeetingIdByMeetingIdQuery = {
  __typename?: 'query_root';
  calendar_event_by_pk?: { __typename?: 'calendar_event'; id: any } | null;
};

export type DeleteCalendarEventHandlerMutationVariables = Exact<{
  object?: InputMaybe<DeleteCalendarEventInput>;
}>;

export type DeleteCalendarEventHandlerMutation = {
  __typename?: 'mutation_root';
  deleteCalendarEvent?: {
    __typename?: 'deleteCalendarEventOutput';
    status: string;
  } | null;
};

export type UpdateCalendarEventMutationVariables = Exact<{
  object?: InputMaybe<UpdateCalendarEventInput>;
}>;

export type UpdateCalendarEventMutation = {
  __typename?: 'mutation_root';
  updateCalendarEvent?: {
    __typename?: 'UpdateCalendarEventOutput';
    id: any;
  } | null;
};

export type CreateEventMutationVariables = Exact<{
  object?: InputMaybe<CreateCalendarEventInput>;
}>;

export type CreateEventMutation = {
  __typename?: 'mutation_root';
  createCalendarEvent?: {
    __typename?: 'CreateCalendarEventOutput';
    id: any;
  } | null;
};

export type AddAttendeesMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Calendar_Attendee_Insert_Input> | Calendar_Attendee_Insert_Input
  >;
}>;

export type AddAttendeesMutation = {
  __typename?: 'mutation_root';
  insert_calendar_attendee?: {
    __typename?: 'calendar_attendee_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetCalendarEventsSubscriptionVariables = Exact<{
  calendar_id: Scalars['uuid'];
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
}>;

export type GetCalendarEventsSubscription = {
  __typename?: 'subscription_root';
  calendar_meeting_view: Array<{
    __typename?: 'calendar_meeting_view';
    id?: any | null;
    created_by?: any | null;
    event_name?: string | null;
    end_date_time?: any | null;
    start_date_time?: any | null;
    additional_fields?: any | null;
    rrule?: string | null;
    all_day?: boolean | null;
    CreatedByUser?: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    calendar_event?: {
      __typename?: 'calendar_event';
      calendar_meeting_calls: Array<{
        __typename?: 'calendar_meeting_call';
        meet_status?: Enum_Call_Status_Type_Enum | null;
        channel_id: string;
        slug?: string | null;
        meeting_call_users: Array<{
          __typename?: 'calendar_meeting_call_user';
          status: Enum_Call_User_Status_Enum;
          user: { __typename?: 'users'; display_name?: string | null };
        }>;
      }>;
    } | null;
  }>;
};

export type GetCalendarEventIdsSubscriptionVariables = Exact<{
  calendar_id: Scalars['uuid'];
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
}>;

export type GetCalendarEventIdsSubscription = {
  __typename?: 'subscription_root';
  calendar_meeting_view: Array<{
    __typename?: 'calendar_meeting_view';
    id?: any | null;
  }>;
};

export type GetCalendarEventsQueryQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
  calendar_id: Scalars['uuid'];
}>;

export type GetCalendarEventsQueryQuery = {
  __typename?: 'query_root';
  calendar_meeting_view: Array<{
    __typename?: 'calendar_meeting_view';
    id?: any | null;
    created_by?: any | null;
    event_name?: string | null;
    end_date_time?: any | null;
    start_date_time?: any | null;
    additional_fields?: any | null;
    rrule?: string | null;
    all_day?: boolean | null;
    CreatedByUser?: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    calendar_event?: {
      __typename?: 'calendar_event';
      calendar_meeting_calls: Array<{
        __typename?: 'calendar_meeting_call';
        meet_status?: Enum_Call_Status_Type_Enum | null;
        channel_id: string;
        slug?: string | null;
        meeting_call_users: Array<{
          __typename?: 'calendar_meeting_call_user';
          status: Enum_Call_User_Status_Enum;
          user: { __typename?: 'users'; display_name?: string | null };
        }>;
      }>;
    } | null;
  }>;
};

export type GetAttendeesQueryVariables = Exact<{
  calendar_event_id: Scalars['uuid'];
}>;

export type GetAttendeesQuery = {
  __typename?: 'query_root';
  calendar_attendee_aggregate: {
    __typename?: 'calendar_attendee_aggregate';
    aggregate?: {
      __typename?: 'calendar_attendee_aggregate_fields';
      count: number;
    } | null;
    nodes: Array<{
      __typename?: 'calendar_attendee';
      attendee_type: Enum_Attendee_Type_Enum;
      email?: string | null;
      attendee_status: Enum_Attendee_Status_Type_Enum;
      attendee_id?: any | null;
      attendee_name?: string | null;
      calendar_id?: any | null;
      calendar_event_id: any;
      id: any;
      user?: {
        __typename?: 'users';
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type GetIndividualAttendeeStatusQueryVariables = Exact<{
  myUserId: Scalars['uuid'];
  calendar_event_id: Scalars['uuid'];
}>;

export type GetIndividualAttendeeStatusQuery = {
  __typename?: 'query_root';
  calendar_attendee: Array<{
    __typename?: 'calendar_attendee';
    attendee_id?: any | null;
    attendee_name?: string | null;
    attendee_status: Enum_Attendee_Status_Type_Enum;
  }>;
};

export type GetAttendeesStatusQueryVariables = Exact<{
  calendar_event_id: Scalars['uuid'];
}>;

export type GetAttendeesStatusQuery = {
  __typename?: 'query_root';
  calendar_attendee: Array<{
    __typename?: 'calendar_attendee';
    attendee_status: Enum_Attendee_Status_Type_Enum;
    attendee_id?: any | null;
    attendee_name?: string | null;
    calendar_event_id: any;
    email?: string | null;
  }>;
};

export type UpdateAttendingStatusMutationVariables = Exact<{
  attendee_status?: InputMaybe<Enum_Attendee_Status_Type_Enum>;
  attendeeId: Scalars['uuid'];
  calendar_event_id: Scalars['uuid'];
}>;

export type UpdateAttendingStatusMutation = {
  __typename?: 'mutation_root';
  update_calendar_attendee?: {
    __typename?: 'calendar_attendee_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMeetingFromCalendarMutationVariables = Exact<{
  attendeeId: Scalars['uuid'];
  calendar_event_id: Scalars['uuid'];
}>;

export type DeleteMeetingFromCalendarMutation = {
  __typename?: 'mutation_root';
  update_calendar_attendee?: {
    __typename?: 'calendar_attendee_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateMeetCallMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type CreateMeetCallMutation = {
  __typename?: 'mutation_root';
  createMeetCall?: { __typename?: 'CreateMeetCallOutput'; slug: string } | null;
};

export type GetLeavesForDateSubscriptionVariables = Exact<{
  start_date: Scalars['timestamptz'];
  end_date: Scalars['timestamptz'];
  organization_id: Scalars['uuid'];
}>;

export type GetLeavesForDateSubscription = {
  __typename?: 'subscription_root';
  leave: Array<{
    __typename?: 'organization_user_leave_application';
    user_leave_data?: {
      __typename?: 'organization_user_leave_data';
      user?: { __typename?: 'users'; first_name?: string | null } | null;
    } | null;
    dates: Array<{
      __typename?: 'organization_user_leave_dates';
      date_timestamp?: any | null;
    }>;
  }>;
};

export type GetHolidaysInRangeSubscriptionVariables = Exact<{
  organization_id: Scalars['uuid'];
  start_date: Scalars['date'];
  end_date: Scalars['date'];
}>;

export type GetHolidaysInRangeSubscription = {
  __typename?: 'subscription_root';
  holidays: Array<{
    __typename?: 'organization_holidays';
    id: any;
    name: string;
    description?: string | null;
    date: any;
  }>;
};

export type CreateCalendarOneToOneMutationVariables = Exact<{
  object: CalendarEventOneToOneInput;
}>;

export type CreateCalendarOneToOneMutation = {
  __typename?: 'mutation_root';
  createCalendarOneToOne?: {
    __typename?: 'CalendarEventOneToOneOutput';
    id: any;
  } | null;
};

export type GetLiveTrainingCalendarEventIdsSubscriptionVariables = Exact<{
  calendar_id: Scalars['uuid'];
  start_date_time?: InputMaybe<Scalars['timestamptz']>;
  end_date_time?: InputMaybe<Scalars['timestamptz']>;
}>;

export type GetLiveTrainingCalendarEventIdsSubscription = {
  __typename?: 'subscription_root';
  calendar_meeting_view: Array<{
    __typename?: 'calendar_meeting_view';
    id?: any | null;
  }>;
};

export type GetLiveTrainingCalendarEventsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
  calendar_id: Scalars['uuid'];
}>;

export type GetLiveTrainingCalendarEventsQuery = {
  __typename?: 'query_root';
  calendar_meeting_view: Array<{
    __typename?: 'calendar_meeting_view';
    id?: any | null;
    created_by?: any | null;
    event_name?: string | null;
    end_date_time?: any | null;
    start_date_time?: any | null;
    additional_fields?: any | null;
    rrule?: string | null;
    all_day?: boolean | null;
    CreatedByUser?: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    calendar_event?: {
      __typename?: 'calendar_event';
      calendar_meeting_calls: Array<{
        __typename?: 'calendar_meeting_call';
        meet_status?: Enum_Call_Status_Type_Enum | null;
        channel_id: string;
        slug?: string | null;
        meeting_call_users: Array<{
          __typename?: 'calendar_meeting_call_user';
          status: Enum_Call_User_Status_Enum;
          user: { __typename?: 'users'; display_name?: string | null };
        }>;
      }>;
    } | null;
  }>;
};

export type GetMeetingRoomByOrganizationIdSubscriptionVariables = Exact<{
  organization_id: Scalars['uuid'];
}>;

export type GetMeetingRoomByOrganizationIdSubscription = {
  __typename?: 'subscription_root';
  meeting_room: Array<{
    __typename?: 'meeting_room';
    id: any;
    type: Enum_Meeting_Room_Type_Enum;
    status: Enum_Meeting_Room_Status_Enum;
    created_by: any;
  }>;
};

export type CreateMeetingRoomMutationVariables = Exact<{
  object: CreateMeetingRoomInput;
}>;

export type CreateMeetingRoomMutation = {
  __typename?: 'mutation_root';
  createMeetingRoom?: {
    __typename?: 'CreateMeetingRoomOutput';
    room_id: any;
  } | null;
};

export type GetMyNotificationsSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyNotificationsSubscription = {
  __typename?: 'subscription_root';
  notifications: Array<{
    __typename?: 'notifications';
    id: any;
    is_read: boolean;
    message: string;
    type: Enum_Notification_Type_Enum;
    owned_by: any;
    created_by?: any | null;
    created_at: any;
    resource_id?: any | null;
    user?: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    Resource_Calendar_Event?: {
      __typename?: 'calendar_event';
      id: any;
      calendar_attendees: Array<{
        __typename?: 'calendar_attendee';
        attendee_status: Enum_Attendee_Status_Type_Enum;
        attendee_id?: any | null;
      }>;
    } | null;
  }>;
};

export type GetChatNotificationStatusSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetChatNotificationStatusSubscription = {
  __typename?: 'subscription_root';
  chat_message_action: Array<{
    __typename?: 'chat_message_action';
    message_id: any;
  }>;
};

export type UpdateIsReadNotificationMutationVariables = Exact<{
  notificationId: Scalars['uuid'];
  is_read?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateIsReadNotificationMutation = {
  __typename?: 'mutation_root';
  update_notifications?: {
    __typename?: 'notifications_mutation_response';
    affected_rows: number;
  } | null;
};

export type ReadAllNotificationsMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type ReadAllNotificationsMutation = {
  __typename?: 'mutation_root';
  update_notifications?: {
    __typename?: 'notifications_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateIsDeletedNotificationMutationVariables = Exact<{
  userId: Scalars['uuid'];
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
}>;

export type UpdateIsDeletedNotificationMutation = {
  __typename?: 'mutation_root';
  update_notifications?: {
    __typename?: 'notifications_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetMyEventAttendingStatusQueryVariables = Exact<{
  userId: Scalars['uuid'];
  eventId: Scalars['uuid'];
}>;

export type GetMyEventAttendingStatusQuery = {
  __typename?: 'query_root';
  calendar_attendee: Array<{
    __typename?: 'calendar_attendee';
    attendee_status: Enum_Attendee_Status_Type_Enum;
  }>;
};

export type GetMyNotificationIdsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyNotificationIdsQuery = {
  __typename?: 'query_root';
  notifications: Array<{
    __typename?: 'notifications';
    id: any;
    is_read: boolean;
    created_at: any;
  }>;
};

export type GetNotificationDetailsQueryVariables = Exact<{
  notificationIds: Array<Scalars['uuid']> | Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type GetNotificationDetailsQuery = {
  __typename?: 'query_root';
  notifications: Array<{
    __typename?: 'notifications';
    id: any;
    is_read: boolean;
    message: string;
    type: Enum_Notification_Type_Enum;
    owned_by: any;
    created_by?: any | null;
    created_at: any;
    resource_id?: any | null;
    user?: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    Resource_Calendar_Event?: {
      __typename?: 'calendar_event';
      id: any;
      calendar_attendees: Array<{
        __typename?: 'calendar_attendee';
        attendee_status: Enum_Attendee_Status_Type_Enum;
        attendee_id?: any | null;
      }>;
    } | null;
  }>;
};

export type CreateKpiMutationVariables = Exact<{
  object: KpiInput;
}>;

export type CreateKpiMutation = {
  __typename?: 'mutation_root';
  createKpi?: { __typename?: 'KpiOutput'; id: any } | null;
};

export type CreateGoalsMutationVariables = Exact<{
  object: GoalsInput;
}>;

export type CreateGoalsMutation = {
  __typename?: 'mutation_root';
  createGoals?: { __typename?: 'GoalsOutput'; id: any } | null;
};

export type UpdateGoalsMutationVariables = Exact<{
  object: UpdateGoalInput;
}>;

export type UpdateGoalsMutation = {
  __typename?: 'mutation_root';
  updateGoals?: { __typename?: 'UpdateGoalOutput'; id: any } | null;
};

export type Delete_Goal_DataMutationVariables = Exact<{
  object: DeleteGoalInput;
}>;

export type Delete_Goal_DataMutation = {
  __typename?: 'mutation_root';
  deleteGoal?: { __typename?: 'DeleteGoalOutput'; id: any } | null;
};

export type GetTimePeriodQueryVariables = Exact<{ [key: string]: never }>;

export type GetTimePeriodQuery = {
  __typename?: 'query_root';
  time_period: Array<{
    __typename?: 'time_period';
    timePeriod: string;
    datePeriod: string;
    id: any;
  }>;
};

export type ListOfFrequencyQueryVariables = Exact<{ [key: string]: never }>;

export type ListOfFrequencyQuery = {
  __typename?: 'query_root';
  frequency: Array<{ __typename?: 'frequency'; id: any; value: string }>;
};

export type GetListOfGoalCountQueryVariables = Exact<{
  _eq1?: InputMaybe<Scalars['uuid']>;
  _eq2?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetListOfGoalCountQuery = {
  __typename?: 'query_root';
  goals_aggregate: {
    __typename?: 'goals_aggregate';
    aggregate?: { __typename?: 'goals_aggregate_fields'; count: number } | null;
  };
};

export type ListOfGoalsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
  _eq2?: InputMaybe<Scalars['uuid']>;
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type ListOfGoalsQuery = {
  __typename?: 'query_root';
  goals: Array<{
    __typename?: 'goals';
    id: any;
    title: string;
    description: string;
    weightage_s?: string | null;
    timePeriodic?: string | null;
    goalWithKpis_aggregate: {
      __typename?: 'goalWithKpi_aggregate';
      aggregate?: {
        __typename?: 'goalWithKpi_aggregate_fields';
        count: number;
      } | null;
      nodes: Array<{
        __typename?: 'goalWithKpi';
        kpi_bank: {
          __typename?: 'kpi_bank';
          title: string;
          description: string;
          kpiWithRoles: Array<{
            __typename?: 'kpiWithRole';
            organization_role?: {
              __typename?: 'organization_roles';
              role_title: string;
              id: any;
            } | null;
          }>;
          kpiWithRolesAndDepts: Array<{
            __typename?: 'kpiWithDept';
            department?: {
              __typename?: 'department';
              id: any;
              name: string;
            } | null;
          }>;
        };
      }>;
    };
    goalsWithDepts: Array<{
      __typename?: 'goalsWithDepts';
      department?: { __typename?: 'department'; name: string; id: any } | null;
    }>;
    goalsWithRoles: Array<{
      __typename?: 'goalsWithRoles';
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    goalWithTimePeriods: Array<{
      __typename?: 'goalWithTimePeriod';
      id: any;
      timePeriod: string;
    }>;
  }>;
};

export type GetGoalListQueryVariables = Exact<{
  role_id: Array<Scalars['uuid']> | Scalars['uuid'];
  dept_id: Array<Scalars['uuid']> | Scalars['uuid'];
  time_period: Array<Scalars['String']> | Scalars['String'];
  org_id: Scalars['uuid'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetGoalListQuery = {
  __typename?: 'query_root';
  goals: Array<{
    __typename?: 'goals';
    id: any;
    title: string;
    description: string;
    manager_Id: any;
    created_At: any;
    goalsWithRoles: Array<{
      __typename?: 'goalsWithRoles';
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    goalsWithDepts: Array<{
      __typename?: 'goalsWithDepts';
      department?: { __typename?: 'department'; name: string; id: any } | null;
    }>;
    goalWithTimePeriods: Array<{
      __typename?: 'goalWithTimePeriod';
      id: any;
      timePeriod: string;
    }>;
    goalWithKpis_aggregate: {
      __typename?: 'goalWithKpi_aggregate';
      aggregate?: {
        __typename?: 'goalWithKpi_aggregate_fields';
        count: number;
      } | null;
    };
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      kpi_bank: {
        __typename?: 'kpi_bank';
        id: any;
        title: string;
        description: string;
      };
    }>;
  }>;
  goals_aggregate: {
    __typename?: 'goals_aggregate';
    aggregate?: { __typename?: 'goals_aggregate_fields'; count: number } | null;
  };
};

export type GetRoleListForOrgAdminQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type GetRoleListForOrgAdminQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    id: any;
    role_title: string;
    kpiWithRoles_aggregate: {
      __typename?: 'kpiWithRole_aggregate';
      aggregate?: {
        __typename?: 'kpiWithRole_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetDeptListForOrgAdminQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type GetDeptListForOrgAdminQuery = {
  __typename?: 'query_root';
  department: Array<{
    __typename?: 'department';
    id: any;
    name: string;
    kpiWithRolesAndDepts_aggregate: {
      __typename?: 'kpiWithDept_aggregate';
      aggregate?: {
        __typename?: 'kpiWithDept_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetListOfKipByGoalIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetListOfKipByGoalIdQuery = {
  __typename?: 'query_root';
  goalWithKpi: Array<{
    __typename?: 'goalWithKpi';
    id: any;
    createdBy?: any | null;
    kpi_bank: {
      __typename?: 'kpi_bank';
      id: any;
      title: string;
      description: string;
      managerId: any;
      kpiWithRoles: Array<{
        __typename?: 'kpiWithRole';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
      kpiWithRolesAndDepts: Array<{
        __typename?: 'kpiWithDept';
        department?: {
          __typename?: 'department';
          id: any;
          name: string;
        } | null;
      }>;
    };
  }>;
};

export type GetGoalDetailsByIdMutationVariables = Exact<{
  object: GoalDetailsInput;
}>;

export type GetGoalDetailsByIdMutation = {
  __typename?: 'mutation_root';
  goalDetailById?: {
    __typename?: 'GoalDetailsOutput';
    response?: {
      __typename?: 'GoalDetailsByIdResponse';
      id?: any | null;
      goal_Id?: any | null;
      manager_Id?: any | null;
      title?: string | null;
      weightage_s?: string | null;
      timePeriodic?: string | null;
      description?: string | null;
      goalsWithRoles?: Array<{
        __typename?: 'GoalDetailWithRoles';
        id?: any | null;
        organization_role?: {
          __typename?: 'OrgRoleDetails';
          id?: any | null;
          role_title?: string | null;
        } | null;
      } | null> | null;
      goalsWithFreqs?: Array<{
        __typename?: 'GoalDetailWithFreq';
        id?: any | null;
        frequency?: {
          __typename?: 'OrgFreqDetails';
          value?: string | null;
          id?: any | null;
        } | null;
      } | null> | null;
      goalsWithDepts?: Array<{
        __typename?: 'GoalDetailWithDepts';
        id?: any | null;
        department?: {
          __typename?: 'OrgDeptDetails';
          id?: any | null;
          name?: string | null;
        } | null;
      } | null> | null;
      goalWithKpis?: Array<{
        __typename?: 'GoalDetailWithKpis';
        kpi_ID?: any | null;
      } | null> | null;
      goalWithTimePeriods?: Array<{
        __typename?: 'GoalDetailWithTimePeriod';
        id?: any | null;
        timePeriod?: string | null;
      } | null> | null;
      goalWithRoleWeightage?: Array<{
        __typename?: 'GoalDetailWithRoleWeightage';
        role_id?: any | null;
        role_name?: string | null;
        time_period?: Array<{
          __typename?: 'GoalDetailWithWeightage';
          available_weightage?: number | null;
          weightage?: number | null;
          timePeriod?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteGoalDataMutationVariables = Exact<{
  goalId: Scalars['uuid'];
}>;

export type DeleteGoalDataMutation = {
  __typename?: 'mutation_root';
  delete_goalsWithDepts?: {
    __typename?: 'goalsWithDepts_mutation_response';
    affected_rows: number;
  } | null;
  delete_goalWithTimePeriod?: {
    __typename?: 'goalWithTimePeriod_mutation_response';
    affected_rows: number;
  } | null;
  delete_goalsWithRoles?: {
    __typename?: 'goalsWithRoles_mutation_response';
    affected_rows: number;
  } | null;
  delete_goalWithKpi?: {
    __typename?: 'goalWithKpi_mutation_response';
    affected_rows: number;
  } | null;
  delete_goals_by_pk?: { __typename?: 'goals'; id: any } | null;
};

export type CreateGoalKpiMutationVariables = Exact<{
  object: GoalKpiInput;
}>;

export type CreateGoalKpiMutation = {
  __typename?: 'mutation_root';
  createGoalKpi?: { __typename?: 'GoalKpiOuput'; id: any } | null;
};

export type UpdateKpiMutationVariables = Exact<{
  object: UpdateKpiInput;
}>;

export type UpdateKpiMutation = {
  __typename?: 'mutation_root';
  updateKpi?: { __typename?: 'UpdateKpiOutput'; id: any } | null;
};

export type DeleteKpiMutationVariables = Exact<{
  _kpi: Scalars['uuid'];
}>;

export type DeleteKpiMutation = {
  __typename?: 'mutation_root';
  delete_kpiWithRole?: {
    __typename?: 'kpiWithRole_mutation_response';
    affected_rows: number;
  } | null;
  delete_kpiWithDept?: {
    __typename?: 'kpiWithDept_mutation_response';
    affected_rows: number;
  } | null;
  delete_kpi_bank_by_pk?: { __typename?: 'kpi_bank'; id: any } | null;
};

export type ListOfKpiByRoleQueryVariables = Exact<{
  _ilike?: InputMaybe<Scalars['String']>;
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
  _eq3?: InputMaybe<Scalars['uuid']>;
}>;

export type ListOfKpiByRoleQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{
    __typename?: 'kpi_bank';
    id: any;
    description: string;
    title: string;
    managerId: any;
    kpiWithRoles: Array<{
      __typename?: 'kpiWithRole';
      role_Id?: any | null;
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
      } | null;
    }>;
    kpiWithRolesAndDepts: Array<{
      __typename?: 'kpiWithDept';
      department_Id?: any | null;
      department?: { __typename?: 'department'; name: string } | null;
    }>;
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      createdBy?: any | null;
      kpi_ID: any;
    }>;
  }>;
  kpi_bank_aggregate: {
    __typename?: 'kpi_bank_aggregate';
    aggregate?: {
      __typename?: 'kpi_bank_aggregate_fields';
      count: number;
    } | null;
  };
};

export type ListOfKpiByDepartmentQueryVariables = Exact<{
  _ilike?: InputMaybe<Scalars['String']>;
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
  _eq3?: InputMaybe<Scalars['uuid']>;
}>;

export type ListOfKpiByDepartmentQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{
    __typename?: 'kpi_bank';
    id: any;
    description: string;
    title: string;
    managerId: any;
    kpiWithRoles: Array<{
      __typename?: 'kpiWithRole';
      role_Id?: any | null;
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
      } | null;
    }>;
    kpiWithRolesAndDepts: Array<{
      __typename?: 'kpiWithDept';
      department_Id?: any | null;
      department?: { __typename?: 'department'; name: string } | null;
    }>;
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      createdBy?: any | null;
      kpi_ID: any;
    }>;
  }>;
  kpi_bank_aggregate: {
    __typename?: 'kpi_bank_aggregate';
    aggregate?: {
      __typename?: 'kpi_bank_aggregate_fields';
      count: number;
    } | null;
  };
};

export type ListOfDepartmentsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type ListOfDepartmentsQuery = {
  __typename?: 'query_root';
  kpiWithDept_aggregate: {
    __typename?: 'kpiWithDept_aggregate';
    nodes: Array<{
      __typename?: 'kpiWithDept';
      department?: {
        __typename?: 'department';
        name: string;
        id: any;
        kpiWithRolesAndDepts_aggregate: {
          __typename?: 'kpiWithDept_aggregate';
          aggregate?: {
            __typename?: 'kpiWithDept_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
    }>;
  };
};

export type ListOfRolesQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type ListOfRolesQuery = {
  __typename?: 'query_root';
  kpiWithRole_aggregate: {
    __typename?: 'kpiWithRole_aggregate';
    nodes: Array<{
      __typename?: 'kpiWithRole';
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
        id: any;
        kpiWithRoles_aggregate: {
          __typename?: 'kpiWithRole_aggregate';
          aggregate?: {
            __typename?: 'kpiWithRole_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
    }>;
  };
};

export type KpiBankDataQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type KpiBankDataQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{
    __typename?: 'kpi_bank';
    title: string;
    description: string;
    open_ended_kpi?: boolean | null;
    kpiWithRoles: Array<{
      __typename?: 'kpiWithRole';
      id: any;
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
        id: any;
      } | null;
    }>;
    kpiWithRolesAndDepts: Array<{
      __typename?: 'kpiWithDept';
      id: any;
      department?: { __typename?: 'department'; id: any; name: string } | null;
    }>;
  }>;
};

export type LoadWeightageForGoalsMutationVariables = Exact<{
  object: Load_Role_Weightage_Input;
}>;

export type LoadWeightageForGoalsMutation = {
  __typename?: 'mutation_root';
  loadWeightageForGoals?: {
    __typename?: 'Load_Role_Weightage_Output';
    response?: Array<{
      __typename?: 'Role_Weightage_Response';
      role_name?: string | null;
      role_id?: any | null;
      time_period?: Array<{
        __typename?: 'RoleWeightagePerTP';
        available_weightage?: number | null;
        weightage?: number | null;
        timePeriod?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Get_DeptByManagerQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike?: InputMaybe<Scalars['String']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_DeptByManagerQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    department?: {
      __typename?: 'department';
      id: any;
      name: string;
      kpiWithRolesAndDepts_aggregate: {
        __typename?: 'kpiWithDept_aggregate';
        aggregate?: {
          __typename?: 'kpiWithDept_aggregate_fields';
          count: number;
        } | null;
      };
    } | null;
  }>;
};

export type Get_RolesByManagerQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike?: InputMaybe<Scalars['String']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_RolesByManagerQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    role_title?: string | null;
    user?: {
      __typename?: 'users';
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
          kpiWithRoles_aggregate: {
            __typename?: 'kpiWithRole_aggregate';
            aggregate?: {
              __typename?: 'kpiWithRole_aggregate_fields';
              count: number;
            } | null;
          };
        } | null;
      }>;
    } | null;
  }>;
};

export type CheckPerformanceReviewTimePeriodQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['String'];
}>;

export type CheckPerformanceReviewTimePeriodQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    frequency: string;
    year: string;
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      id: any;
      timePeriod: string;
      preformance_Id: any;
      action: string;
    }>;
  }>;
};

export type FinancialYearInfoQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  todaysDate: Scalars['String'];
}>;

export type FinancialYearInfoQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    startDate?: string | null;
    endDate?: string | null;
    year: string;
  }>;
};

export type GetPerformanceReviewDetailInfoMutationVariables = Exact<{
  object: PerfReviewInput;
}>;

export type GetPerformanceReviewDetailInfoMutation = {
  __typename?: 'mutation_root';
  getPerformanceReviewDetailInfo?: {
    __typename?: 'PerfReviewOutput';
    response: Array<{
      __typename?: 'PerformanceReviewInfo';
      id: any;
      frequency: string;
      instruction: string;
      year: string;
      organizationId: any;
      rating?: number | null;
      rating_detail?: Array<{
        __typename?: 'Rating_scale_Info';
        name?: string | null;
        scale?: number | null;
      } | null> | null;
      performance_review_setting_With_Goals: {
        __typename?: 'PerformanceReviewWithGoals';
        id: any;
        title: string;
        startDate: string;
        dueDate: string;
        year: string;
        action: string;
        status: string;
        performance_review_setting_GoalsLists: Array<{
          __typename?: 'GoalObject';
          performance_Period_Id: any;
          goal: {
            __typename?: 'Goal';
            id: any;
            description: string;
            timePeriodic: string;
            title: string;
            weightage_s: string;
            goalWithKpis: Array<{
              __typename?: 'KpiObject';
              kpi_bank: {
                __typename?: 'KpiBank';
                id: any;
                title: string;
                description: string;
                open_ended_kpi?: boolean | null;
              };
            }>;
          };
        }>;
      };
    } | null>;
  } | null;
};

export type GetPerformanceReviewDetailByRoleQueryVariables = Exact<{
  _orgId: Scalars['uuid'];
  _ilike: Scalars['String'];
  _roleId: Scalars['uuid'];
}>;

export type GetPerformanceReviewDetailByRoleQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
    frequency: string;
    instruction: string;
    organization_Id: any;
    year: string;
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      id: any;
      dueDate: any;
      action: string;
      startDate: any;
      status: string;
      title: string;
      year: string;
      performance_review_setting_GoalsLists: Array<{
        __typename?: 'performance_review_setting_GoalsList';
        performance_Period_Id: any;
        goal: {
          __typename?: 'goals';
          description: string;
          id: any;
          timePeriodic?: string | null;
          title: string;
          weightage_s?: string | null;
          goalWithKpis: Array<{
            __typename?: 'goalWithKpi';
            kpi_bank: {
              __typename?: 'kpi_bank';
              description: string;
              title: string;
              id: any;
            };
          }>;
        };
      }>;
    }>;
  }>;
};

export type GetUserRoleIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetUserRoleIdQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    profile: Array<{
      __typename?: 'profile';
      organization_role_id?: any | null;
    }>;
  } | null;
};

export type GetGoalsAssociatedWithEmployeeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetGoalsAssociatedWithEmployeeQuery = {
  __typename?: 'query_root';
  goalsWithRoles: Array<{
    __typename?: 'goalsWithRoles';
    goal: {
      __typename?: 'goals';
      id: any;
      title: string;
      description: string;
      goalWithKpis: Array<{
        __typename?: 'goalWithKpi';
        kpi_bank: {
          __typename?: 'kpi_bank';
          id: any;
          title: string;
          description: string;
        };
      }>;
      goalWithTimePeriods: Array<{
        __typename?: 'goalWithTimePeriod';
        timePeriod: string;
      }>;
      goalWithKpis_aggregate: {
        __typename?: 'goalWithKpi_aggregate';
        aggregate?: {
          __typename?: 'goalWithKpi_aggregate_fields';
          count: number;
        } | null;
      };
    };
  }>;
};

export type CheckActionQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type CheckActionQuery = {
  __typename?: 'query_root';
  performance_review_setting_With_Goals: Array<{
    __typename?: 'performance_review_setting_With_Goals';
    id: any;
    title: string;
    timePeriod: string;
    startDate: any;
    dueDate: any;
    status: string;
    action: string;
    year: string;
    performance_review_setting: {
      __typename?: 'performance_review_settings';
      id: any;
      frequency: string;
    };
  }>;
};

export type PerformanceReviewActionQueryVariables = Exact<{
  _orgId: Scalars['uuid'];
  _roleId: Scalars['uuid'];
}>;

export type PerformanceReviewActionQuery = {
  __typename?: 'query_root';
  performance_review_setting_With_Goals: Array<{
    __typename?: 'performance_review_setting_With_Goals';
    id: any;
    title: string;
    year: string;
    startDate: any;
    action: string;
    timePeriod: string;
    startingDate?: string | null;
    endingDate?: string | null;
    dueDate: any;
    performance_review_setting_GoalsLists: Array<{
      __typename?: 'performance_review_setting_GoalsList';
      goal: { __typename?: 'goals'; timePeriodic?: string | null };
      performance_review_setting: {
        __typename?: 'performance_review_settings';
        frequency: string;
      };
    }>;
  }>;
};

export type GetGoalsAssociatedWithEmployeeCountQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type GetGoalsAssociatedWithEmployeeCountQuery = {
  __typename?: 'query_root';
  goalsWithRoles_aggregate: {
    __typename?: 'goalsWithRoles_aggregate';
    aggregate?: {
      __typename?: 'goalsWithRoles_aggregate_fields';
      count: number;
    } | null;
  };
};

export type SubmitReviewRatingMutationVariables = Exact<{
  object: PerformanceReviewUsersInput;
}>;

export type SubmitReviewRatingMutation = {
  __typename?: 'mutation_root';
  createReviewUsers?: {
    __typename?: 'PerformanceReviewUsersOutput';
    id: any;
  } | null;
};

export type RequestPeerMutationVariables = Exact<{
  object: PeerInput;
}>;

export type RequestPeerMutation = {
  __typename?: 'mutation_root';
  addPeer?: { __typename?: 'PeerOutput'; id: any } | null;
};

export type Get_Peers_ReviewSubscriptionVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['String'];
}>;

export type Get_Peers_ReviewSubscription = {
  __typename?: 'subscription_root';
  User_Peer_Review: Array<{
    __typename?: 'User_Peer_Review';
    peer_Id: any;
    id: any;
    performanceReviewByUser_Id?: any | null;
    status?: string | null;
    user_Id: any;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          organization_id: any;
          role_title: string;
          id: any;
        } | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type List_Of_EmployeeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type List_Of_EmployeeQuery = {
  __typename?: 'query_root';
  organization_employee: Array<{
    __typename?: 'organization_employee';
    organization_id: any;
    manager_id?: any | null;
    user: { __typename?: 'users'; display_name?: string | null; id: any };
  }>;
};

export type GetUserManagerIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetUserManagerIdQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    organization_employees: Array<{
      __typename?: 'organization_employee';
      manager_id?: any | null;
    }>;
  }>;
};

export type GetPeersRequestToReviewSubscriptionVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type GetPeersRequestToReviewSubscription = {
  __typename?: 'subscription_root';
  User_Peer_Review: Array<{
    __typename?: 'User_Peer_Review';
    user_Id: any;
    id: any;
    performanceReviewByUser_Id?: any | null;
    status?: string | null;
    timePeriod?: string | null;
    userByUserId: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          organization_id: any;
          role_title: string;
          id: any;
        } | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
      performance_review_byUsers: Array<{
        __typename?: 'performance_review_byUser';
        calendarId?: any | null;
        progressBar?: number | null;
        calendar_event?: {
          __typename?: 'calendar_event';
          id: any;
          event_name: string;
          start_date_time: any;
        } | null;
      }>;
    };
  }>;
};

export type CheckPeerRequestCountSubscriptionVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type CheckPeerRequestCountSubscription = {
  __typename?: 'subscription_root';
  User_Peer_Review_aggregate: {
    __typename?: 'User_Peer_Review_aggregate';
    aggregate?: {
      __typename?: 'User_Peer_Review_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetPerformanceReviewStatusSubscriptionVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type GetPerformanceReviewStatusSubscription = {
  __typename?: 'subscription_root';
  performanceReviewStatus: Array<{
    __typename?: 'performanceReviewStatus';
    id: any;
    performance_Period_Id: any;
  }>;
};

export type GetUserDetailsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetUserDetailsQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
        id: any;
      } | null;
    }>;
  }>;
};

export type PerformanceReviewGraphMutationVariables = Exact<{
  object: PerformanceReviewGraphInput;
}>;

export type PerformanceReviewGraphMutation = {
  __typename?: 'mutation_root';
  performanceReviewGraph?: {
    __typename?: 'PerformanceReviewGraphOutput';
    overReviewMarks?: number | null;
    totalMark?: number | null;
    response: Array<{
      __typename?: 'PerformanceReviewUserMarks';
      goalId?: any | null;
      goalInfo?: string | null;
      goalMarks?: number | null;
      peerMarks?: number | null;
      status?: string | null;
      weightage?: string | null;
      total_peer_marks?: number | null;
    } | null>;
  } | null;
};

export type RemoveOrDeclinePeersMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type RemoveOrDeclinePeersMutation = {
  __typename?: 'mutation_root';
  delete_User_Peer_Review?: {
    __typename?: 'User_Peer_Review_mutation_response';
    returning: Array<{ __typename?: 'User_Peer_Review'; id: any }>;
  } | null;
};

export type GetPerformanceTimePeriodByYearQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['String'];
}>;

export type GetPerformanceTimePeriodByYearQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      timePeriod: string;
    }>;
  }>;
};

export type PerformanceReviewMarksPerUsersMutationVariables = Exact<{
  object: PerformanceReviewUserInput;
}>;

export type PerformanceReviewMarksPerUsersMutation = {
  __typename?: 'mutation_root';
  performanceReviewMarksPerUsers?: {
    __typename?: 'PerformanceReviewUserOutput';
    response: Array<{
      __typename?: 'PerformanceReviewDetailPerUser';
      overAllReview?: number | null;
      peerReview?: number | null;
      selfReview?: number | null;
      managerReview?: number | null;
      status?: string | null;
      totalMark?: number | null;
      manager_user_review?: number | null;
      self_user_review?: number | null;
      peer_user_marks?: number | null;
    } | null>;
  } | null;
};

export type PerformanceReviewNotificationMutationVariables = Exact<{
  object: PerformanceReviewNotifyInput;
}>;

export type PerformanceReviewNotificationMutation = {
  __typename?: 'mutation_root';
  performanceReviewNotification?: {
    __typename?: 'PerformanceReviewNotifyOutput';
    response: string;
  } | null;
};

export type Get_ListingYearQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_ListingYearQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    year: string;
  }>;
};

export type GetPerformanceReviewsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  peerId?: InputMaybe<Scalars['uuid']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _ilike: Scalars['String'];
}>;

export type GetPerformanceReviewsQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
    user_Id: any;
    peer_Id?: any | null;
    timePeriod?: string | null;
    performance_Id: any;
    performance_timePeriod_Id: any;
    performance_review_setting_With_Goal: {
      __typename?: 'performance_review_setting_With_Goals';
      title: string;
      dueDate: any;
    };
  }>;
  performance_review_byUser_WithGoal: Array<{
    __typename?: 'performance_review_byUser_WithGoal';
    goal_Marks?: any | null;
    goal: { __typename?: 'goals'; id: any; title: string };
    performance_review_byUser_Kpis: Array<{
      __typename?: 'performance_review_byUser_Kpi';
      kpi_Mark: number;
      comment?: string | null;
      kpi_bank: {
        __typename?: 'kpi_bank';
        id: any;
        title: string;
        description: string;
        open_ended_kpi?: boolean | null;
      };
    }>;
  }>;
};

export type SelfReview_ScoreSheetByUserIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type SelfReview_ScoreSheetByUserIdQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
    user_Id: any;
    peer_Id?: any | null;
    timePeriod?: string | null;
    performance_Id: any;
    performance_timePeriod_Id: any;
    performance_review_setting_With_Goal: {
      __typename?: 'performance_review_setting_With_Goals';
      title: string;
      dueDate: any;
    };
  }>;
  performance_review_byUser_WithGoal: Array<{
    __typename?: 'performance_review_byUser_WithGoal';
    goal_Marks?: any | null;
    goal: { __typename?: 'goals'; id: any; title: string };
    performance_review_byUser_Kpis: Array<{
      __typename?: 'performance_review_byUser_Kpi';
      kpi_Mark: number;
      comment?: string | null;
      kpi_bank: {
        __typename?: 'kpi_bank';
        id: any;
        title: string;
        description: string;
        open_ended_kpi?: boolean | null;
      };
    }>;
  }>;
};

export type PeerReview_ScoreSheetByUserIdQueryVariables = Exact<{
  _eq1: Scalars['uuid'];
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
  _eq4: Scalars['uuid'];
  _eq5: Scalars['String'];
}>;

export type PeerReview_ScoreSheetByUserIdQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
    user_Id: any;
    peer_Id?: any | null;
    timePeriod?: string | null;
    performance_Id: any;
    performance_timePeriod_Id: any;
    performance_review_setting_With_Goal: {
      __typename?: 'performance_review_setting_With_Goals';
      title: string;
      dueDate: any;
    };
  }>;
  performance_review_byUser_WithGoal: Array<{
    __typename?: 'performance_review_byUser_WithGoal';
    goal_Marks?: any | null;
    goal: {
      __typename?: 'goals';
      id: any;
      title: string;
      goalsWithRoles: Array<{
        __typename?: 'goalsWithRoles';
        role_ID?: any | null;
        role_weightage?: any | null;
        time_period?: string | null;
      }>;
    };
    performance_review_byUser_Kpis: Array<{
      __typename?: 'performance_review_byUser_Kpi';
      kpi_Mark: number;
      comment?: string | null;
      kpi_bank: {
        __typename?: 'kpi_bank';
        id: any;
        title: string;
        description: string;
        open_ended_kpi?: boolean | null;
      };
    }>;
  }>;
};

export type GetScoreSheetMutationVariables = Exact<{
  object: GetScoreSheetInput;
}>;

export type GetScoreSheetMutation = {
  __typename?: 'mutation_root';
  getScoreSheet?: {
    __typename?: 'GetScoreSheetOutput';
    response?: {
      __typename?: 'UserScoreSheetResponse';
      id?: any | null;
      title?: string | null;
      timePeriod?: string | null;
      viewOption?: string | null;
      rating_scale?: number | null;
      goalDetail?: Array<{
        __typename?: 'UserGoalScoreSheet';
        id?: any | null;
        goal_Id?: any | null;
        title?: string | null;
        marks?: number | null;
        goal_weightage_marks?: number | null;
        kpiDetail?: {
          __typename?: 'UserKpiScoreSheet';
          id?: any | null;
          kpi_id?: any | null;
          kpi_title?: string | null;
          open_ended_kpi?: boolean | null;
          userDetails?: Array<{
            __typename?: 'UserDetailScoreInfo';
            marks?: number | null;
            comments?: string | null;
            profileImage?: string | null;
            userName?: string | null;
            role?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PeerCompletedCountWithoutManagerQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['uuid']>;
}>;

export type PeerCompletedCountWithoutManagerQuery = {
  __typename?: 'query_root';
  User_Peer_Review_aggregate: {
    __typename?: 'User_Peer_Review_aggregate';
    aggregate?: {
      __typename?: 'User_Peer_Review_aggregate_fields';
      count: number;
    } | null;
  };
};

export type PeerCompletedCountQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type PeerCompletedCountQuery = {
  __typename?: 'query_root';
  User_Peer_Review_aggregate: {
    __typename?: 'User_Peer_Review_aggregate';
    aggregate?: {
      __typename?: 'User_Peer_Review_aggregate_fields';
      count: number;
    } | null;
  };
};

export type ReviewFeedBackMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  rating: Scalars['Int'];
  feedBack: Scalars['String'];
}>;

export type ReviewFeedBackMutation = {
  __typename?: 'mutation_root';
  update_performance_review_byUser?: {
    __typename?: 'performance_review_byUser_mutation_response';
    returning: Array<{ __typename?: 'performance_review_byUser'; id: any }>;
  } | null;
};

export type Update_OneToOneMarkCompleteMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type Update_OneToOneMarkCompleteMutation = {
  __typename?: 'mutation_root';
  update_performance_review_byUser?: {
    __typename?: 'performance_review_byUser_mutation_response';
    returning: Array<{ __typename?: 'performance_review_byUser'; id: any }>;
  } | null;
};

export type AddProgressBarMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  progressBar: Scalars['Int'];
}>;

export type AddProgressBarMutation = {
  __typename?: 'mutation_root';
  update_performance_review_byUser?: {
    __typename?: 'performance_review_byUser_mutation_response';
    returning: Array<{ __typename?: 'performance_review_byUser'; id: any }>;
  } | null;
};

export type GetProgressBarValueSubscriptionVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type GetProgressBarValueSubscription = {
  __typename?: 'subscription_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    progressBar?: number | null;
  }>;
};

export type GetOneToOneMeetingStatusQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type GetOneToOneMeetingStatusQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    meetingStatus?: string | null;
  }>;
};

export type GetFeedBackInfoSubscriptionVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type GetFeedBackInfoSubscription = {
  __typename?: 'subscription_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    rating?: number | null;
    feedBack?: string | null;
  }>;
};

export type OneToOneDiscussionWithDetailInfoQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type OneToOneDiscussionWithDetailInfoQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    calendar_event?: {
      __typename?: 'calendar_event';
      id: any;
      event_name: string;
      start_date_time: any;
      calendar_attendees: Array<{
        __typename?: 'calendar_attendee';
        attendee_name?: string | null;
        user?: {
          __typename?: 'users';
          id: any;
          profile: Array<{
            __typename?: 'profile';
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
  }>;
};

export type Check_PeersCompletedQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type Check_PeersCompletedQuery = {
  __typename?: 'query_root';
  User_Peer_Review_aggregate: {
    __typename?: 'User_Peer_Review_aggregate';
    aggregate?: {
      __typename?: 'User_Peer_Review_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetRatingCountByUserIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['String'];
}>;

export type GetRatingCountByUserIdQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    rating?: number | null;
    feedBack?: string | null;
  }>;
};

export type GetKpiListMutationVariables = Exact<{
  object: GetKpiListPerRoleInput;
}>;

export type GetKpiListMutation = {
  __typename?: 'mutation_root';
  getKpiList?: {
    __typename?: 'GetKpiListPerRoleOutput';
    response?: {
      __typename?: 'kpiResponses';
      totalCount?: number | null;
      listOfKpi: Array<{
        __typename?: 'ListOfKpiPerRole';
        id: any;
        title: string;
        description: string;
        managerId: any;
        open_ended_kpi?: boolean | null;
        kpiWithRoles?: Array<{
          __typename?: 'KpiRoleInfo';
          organization_role?: {
            __typename?: 'RoleKpiDetails';
            id?: any | null;
            role_title?: string | null;
          } | null;
        } | null> | null;
        kpiWithRolesAndDepts?: Array<{
          __typename?: 'KpiDeptInfo';
          department?: {
            __typename?: 'DepartmentKpiDetails';
            id?: any | null;
            name?: string | null;
          } | null;
        } | null> | null;
        goalWithKpis?: Array<{
          __typename?: 'GoalKpiInfoList';
          createdBy?: any | null;
          kpi_ID?: any | null;
        } | null> | null;
      } | null>;
    } | null;
  } | null;
};

export type GetRatingTagQueryVariables = Exact<{
  performance_id: Scalars['uuid'];
  rating: Scalars['Int'];
}>;

export type GetRatingTagQuery = {
  __typename?: 'query_root';
  performance_rating_scale: Array<{
    __typename?: 'performance_rating_scale';
    rating?: number | null;
    name?: string | null;
  }>;
};

export type CheckSelfWeightageQueryVariables = Exact<{
  org_id: Scalars['uuid'];
  year: Scalars['String'];
}>;

export type CheckSelfWeightageQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    self_review?: string | null;
  }>;
};

export type GetRoleIdOfUserQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetRoleIdOfUserQuery = {
  __typename?: 'query_root';
  users_by_pk?: {
    __typename?: 'users';
    profile: Array<{
      __typename?: 'profile';
      organization_role_id?: any | null;
    }>;
  } | null;
};

export type GetMyProfileQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyProfileQuery = {
  __typename?: 'query_root';
  profile: Array<{
    __typename?: 'profile';
    date_of_birth?: string | null;
    avatar_file_id?: any | null;
    id: any;
    timezone?: string | null;
    gender?: Enum_User_Gender_Enum | null;
    additional_fields: any;
    userByUserId: {
      __typename?: 'users';
      first_name?: string | null;
      last_name?: string | null;
    };
    avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
  }>;
};

export type UpdateProfileAndUserMutationVariables = Exact<{
  userId: Scalars['uuid'];
  additional_fields?: InputMaybe<Scalars['jsonb']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Enum_User_Gender_Enum>;
  tz: Scalars['String'];
}>;

export type UpdateProfileAndUserMutation = {
  __typename?: 'mutation_root';
  update_profile?: {
    __typename?: 'profile_mutation_response';
    affected_rows: number;
  } | null;
  update_users?: {
    __typename?: 'users_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateProfilePicMutationVariables = Exact<{
  userId: Scalars['uuid'];
  avatar_file_id: Scalars['uuid'];
}>;

export type UpdateProfilePicMutation = {
  __typename?: 'mutation_root';
  update_profile?: {
    __typename?: 'profile_mutation_response';
    affected_rows: number;
  } | null;
};

export type SetNotificaionPreferencesMutationVariables = Exact<{
  created_by: Scalars['uuid'];
  setting_value?: InputMaybe<Scalars['jsonb']>;
  setting_type?: InputMaybe<Enum_User_Settings_Type_Enum>;
}>;

export type SetNotificaionPreferencesMutation = {
  __typename?: 'mutation_root';
  insert_user_settings_one?: {
    __typename?: 'user_settings';
    id: any;
    created_at: any;
    updated_at: any;
    setting_type: Enum_User_Settings_Type_Enum;
    setting_value: any;
    created_by: any;
  } | null;
};

export type GetUserNotificationPreferencesQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserNotificationPreferencesQuery = {
  __typename?: 'query_root';
  user_settings: Array<{
    __typename?: 'user_settings';
    id: any;
    setting_type: Enum_User_Settings_Type_Enum;
    setting_value: any;
    created_by: any;
  }>;
};

export type GetAvatar3DByUserIdSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetAvatar3DByUserIdSubscription = {
  __typename?: 'subscription_root';
  user_avatar_files: Array<{
    __typename?: 'user_avatar_files';
    avatar_id: any;
    file_url?: string | null;
    status?: string | null;
  }>;
};

export type GetMyApprovedTimesheetQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyApprovedTimesheetQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    created_by: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      project?: { __typename?: 'project'; title: string } | null;
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          work_hours?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
    userByCreatedBy: { __typename?: 'users'; display_name?: string | null };
  }>;
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    created_by: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      manager_by?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        display_name?: string | null;
      } | null;
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          work_hours?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
  }>;
};

export type GetMyPendingTimesheetQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyPendingTimesheetQuery = {
  __typename?: 'query_root';
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    created_by: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      manager_by?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        display_name?: string | null;
      } | null;
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          work_hours?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
  }>;
};

export type GetMyAllTimesheetByDateQueryVariables = Exact<{
  userId: Scalars['uuid'];
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
}>;

export type GetMyAllTimesheetByDateQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    created_by: any;
    created_at: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      created_at: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      work_minutes?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        display_name?: string | null;
      } | null;
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          workHours?: any | null;
          workMinutes?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
    userByCreatedBy: { __typename?: 'users'; display_name?: string | null };
  }>;
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    created_by: any;
    created_at: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      created_at: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      work_minutes?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      manager_by?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        display_name?: string | null;
      } | null;
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          workHours?: any | null;
          workMinutes?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
  }>;
};

export type GetMyTimesheetsByDateQueryVariables = Exact<{
  userId: Scalars['uuid'];
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
}>;

export type GetMyTimesheetsByDateQuery = {
  __typename?: 'query_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    id: any;
    status: Enum_Task_Time_Sheet_Status_Enum;
    draft_minutes?: any | null;
    work_minutes?: any | null;
    work_hours?: any | null;
    created_at: any;
    work_date: any;
    task_name?: string | null;
    project?: { __typename?: 'project'; title: string } | null;
    userByManagerBy?: {
      __typename?: 'users';
      display_name?: string | null;
    } | null;
  }>;
};

export type GetMyDeclinedTimesheetQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyDeclinedTimesheetQuery = {
  __typename?: 'query_root';
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    created_by: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      manager_by?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        display_name?: string | null;
      } | null;
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          work_hours?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
  }>;
};

export type GetMyApprovalApprovedListQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyApprovalApprovedListQuery = {
  __typename?: 'query_root';
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    created_by: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      manager_by?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        display_name?: string | null;
      } | null;
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          work_hours?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
    user: { __typename?: 'users'; display_name?: string | null };
  }>;
};

export type GetMyAllApprovalListByDateQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  excludeUserId?: InputMaybe<Scalars['uuid']>;
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
  selectedUserId?: InputMaybe<Scalars['uuid']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  orgId: Scalars['uuid'];
}>;

export type GetMyAllApprovalListByDateQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    created_by: any;
    created_at: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      work_minutes?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      manager_by?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      } | null;
      user: { __typename?: 'users'; id: any; display_name?: string | null };
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          workHours?: any | null;
          workMinutes?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
    userByCreatedBy: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    };
  }>;
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    created_by: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      work_minutes?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      manager_by?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        display_name?: string | null;
      } | null;
      user: { __typename?: 'users'; id: any; display_name?: string | null };
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          workHours?: any | null;
          workMinutes?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
    filtered_total: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        count: number;
      } | null;
    };
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  }>;
  task_unscheduled_aggregate: {
    __typename?: 'task_unscheduled_aggregate';
    aggregate?: {
      __typename?: 'task_unscheduled_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetMyAllFilteredApprovalListByDateQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  excludeUserId?: InputMaybe<Scalars['uuid']>;
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
  selectedUserId?: InputMaybe<Scalars['uuid']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  orgId: Scalars['uuid'];
}>;

export type GetMyAllFilteredApprovalListByDateQuery = {
  __typename?: 'query_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    id: any;
    created_by: any;
    created_at: any;
    draft_hours?: any | null;
    draft_minutes?: any | null;
    status: Enum_Task_Time_Sheet_Status_Enum;
    manager_by?: any | null;
    work_date: any;
    work_hours?: any | null;
    work_minutes?: any | null;
    task?: {
      __typename?: 'task';
      title: string;
      id: any;
      project_id?: any | null;
      status?: Enum_Task_Status_Enum | null;
    } | null;
    task_unscheduled?: {
      __typename?: 'task_unscheduled';
      title: string;
      id: any;
      project_id: any;
    } | null;
    user: { __typename?: 'users'; id: any; display_name?: string | null };
    userByManagerBy?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    } | null;
    project?: { __typename?: 'project'; title: string; id: any } | null;
  }>;
  task_time_sheet_aggregate: {
    __typename?: 'task_time_sheet_aggregate';
    aggregate?: {
      __typename?: 'task_time_sheet_aggregate_fields';
      count: number;
      sum?: {
        __typename?: 'task_time_sheet_sum_fields';
        work_hours?: any | null;
        work_minutes?: any | null;
      } | null;
    } | null;
  };
};

export type GetMyApprovalRejectedListQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetMyApprovalRejectedListQuery = {
  __typename?: 'query_root';
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    created_by: any;
    task_time_sheets: Array<{
      __typename?: 'task_time_sheet';
      created_by: any;
      project_id: any;
      task_id?: any | null;
      task_name?: string | null;
      work_date: any;
      work_hours?: any | null;
      status: Enum_Task_Time_Sheet_Status_Enum;
      id: any;
      manager_by?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      userByManagerBy?: {
        __typename?: 'users';
        display_name?: string | null;
      } | null;
    }>;
    total_work_hours: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          work_hours?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        task_name?: string | null;
      }>;
    };
    user: { __typename?: 'users'; display_name?: string | null };
  }>;
};

export type SaveTimeSheetDayMutationVariables = Exact<{
  objects: Array<TimeSheet> | TimeSheet;
  saveType: Scalars['String'];
}>;

export type SaveTimeSheetDayMutation = {
  __typename?: 'mutation_root';
  saveTimeSheetDay?: {
    __typename?: 'SaveTimeSheetOutput';
    timeSheetIds: Array<any>;
  } | null;
};

export type SaveTimeSheetWeekMutationVariables = Exact<{
  objects: Array<TimeSheetWeekObject> | TimeSheetWeekObject;
  saveType: Scalars['String'];
}>;

export type SaveTimeSheetWeekMutation = {
  __typename?: 'mutation_root';
  saveTimeSheetWeek?: {
    __typename?: 'SaveTimeSheetWeekOutput';
    timeSheetIds: Array<any>;
  } | null;
};

export type DeleteTaskTimelineByIdsMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type DeleteTaskTimelineByIdsMutation = {
  __typename?: 'mutation_root';
  delete_task_unscheduled?: {
    __typename?: 'task_unscheduled_mutation_response';
    affected_rows: number;
  } | null;
  delete_task_time_sheet?: {
    __typename?: 'task_time_sheet_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetMyTaskTimingsForTodayQueryVariables = Exact<{
  today: Scalars['date'];
  userId: Scalars['uuid'];
}>;

export type GetMyTaskTimingsForTodayQuery = {
  __typename?: 'query_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    id: any;
    work_hours?: any | null;
    draft_hours?: any | null;
    work_date: any;
    task_name?: string | null;
    task_combined_id?: string | null;
    task_combined_type?: string | null;
    project?: { __typename?: 'project'; id: any; title: string } | null;
  }>;
};

export type GetMyTaskTimingsForThisWeekQueryVariables = Exact<{
  userId: Scalars['uuid'];
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
}>;

export type GetMyTaskTimingsForThisWeekQuery = {
  __typename?: 'query_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    id: any;
    work_hours?: any | null;
    draft_hours?: any | null;
    work_minutes?: any | null;
    draft_minutes?: any | null;
    work_date: any;
    task_name?: string | null;
    task_combined_id?: string | null;
    task_combined_type?: string | null;
    project?: { __typename?: 'project'; id: any; title: string } | null;
  }>;
};

export type GetTaskCombinedByTitleQueryVariables = Exact<{
  userId: Scalars['uuid'];
  title?: InputMaybe<Scalars['String']>;
}>;

export type GetTaskCombinedByTitleQuery = {
  __typename?: 'query_root';
  task_combined: Array<{
    __typename?: 'task_combined';
    id?: any | null;
    title?: string | null;
    project_id?: any | null;
    task_type?: string | null;
    project?: { __typename?: 'project'; id: any; title: string } | null;
  }>;
};

export type GetOrganizationAllEmployeeWithFilterQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  search?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  managerId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetOrganizationAllEmployeeWithFilterQuery = {
  __typename?: 'query_root';
  organization_employee: Array<{
    __typename?: 'organization_employee';
    manager_id?: any | null;
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      last_sign_date_time?: any | null;
      account?: {
        __typename?: 'auth_accounts';
        active: boolean;
        email?: any | null;
      } | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
      department_employees: Array<{
        __typename?: 'department_employee';
        department: { __typename?: 'department'; id: any; name: string };
      }>;
    };
  }>;
  filtered_total: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
  active: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
  inactive: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetTimesheetsForCsvQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<
    Array<Enum_Task_Time_Sheet_Status_Enum> | Enum_Task_Time_Sheet_Status_Enum
  >;
  startDate?: InputMaybe<Scalars['date']>;
  endDate?: InputMaybe<Scalars['date']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  selectedUserId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetTimesheetsForCsvQuery = {
  __typename?: 'query_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    id: any;
    task_name?: string | null;
    created_by: any;
    project_id: any;
    manager_by?: any | null;
    task_id?: any | null;
    work_date: any;
    work_hours?: any | null;
    work_minutes?: any | null;
    status: Enum_Task_Time_Sheet_Status_Enum;
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      first_name?: string | null;
    };
    project?: { __typename?: 'project'; title: string } | null;
  }>;
};

export type AddAssessmentTypeMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Assessment_Type_Insert_Input> | Assessment_Type_Insert_Input
  >;
}>;

export type AddAssessmentTypeMutation = {
  __typename?: 'mutation_root';
  insert_assessment_type?: {
    __typename?: 'assessment_type_mutation_response';
    returning: Array<{ __typename?: 'assessment_type'; id: any }>;
  } | null;
};

export type LoadAssessmentTypeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type LoadAssessmentTypeQuery = {
  __typename?: 'query_root';
  assessment_type: Array<{
    __typename?: 'assessment_type';
    name: string;
    meta_tags: string;
    id: any;
    user_id: any;
    status: boolean;
    organization_id: any;
    user: { __typename?: 'users'; display_name?: string | null };
  }>;
};

export type TrainingsForDevelopmentPlanQueryVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type TrainingsForDevelopmentPlanQuery = {
  __typename?: 'query_root';
  training: Array<{
    __typename?: 'training';
    id: any;
    name: string;
    rewards: number;
    type: string;
    format: string;
    user_id: any;
    duration?: number | null;
    storage_file?: {
      __typename?: 'storage_files';
      id: any;
      file_url: string;
      type: Enum_Storage_File_Type_Enum;
      metadata: any;
    } | null;
    training_feedbacks_aggregate: {
      __typename?: 'training_feedback_aggregate';
      aggregate?: {
        __typename?: 'training_feedback_aggregate_fields';
        count: number;
        sum?: {
          __typename?: 'training_feedback_sum_fields';
          feedback?: any | null;
        } | null;
      } | null;
    };
  }>;
};

export type AssessmentsForDevelopmentPlanQueryVariables = Exact<{
  org_id?: InputMaybe<Scalars['uuid']>;
}>;

export type AssessmentsForDevelopmentPlanQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    id: any;
    name: string;
    type: string;
    questions_aggregate: {
      __typename?: 'questions_aggregate';
      aggregate?: {
        __typename?: 'questions_aggregate_fields';
        count: number;
      } | null;
    };
    assessment_Skills: Array<{
      __typename?: 'assessment_Skills';
      list_Of_Skill?: { __typename?: 'list_Of_Skills'; name: string } | null;
    }>;
  }>;
};

export type CareerDevelopmentDashboardMutationVariables = Exact<{
  object: CareerDevelopmentDashboardInput;
}>;

export type CareerDevelopmentDashboardMutation = {
  __typename?: 'mutation_root';
  loadCareerDevelopmentDashboard?: {
    __typename?: 'CareerDevelopmentDashboardOutput';
    response?: Array<{
      __typename?: 'User_Career_Development_Dashboard';
      id?: any | null;
      title?: string | null;
      trainingCount?: number | null;
      assessmentCount?: number | null;
      progress?: number | null;
      status?: string | null;
      createdBy?: any | null;
      stages?: Array<{
        __typename?: 'User_Stage_Detail';
        stageName?: string | null;
        status?: string | null;
      } | null> | null;
      completedBy?: Array<{
        __typename?: 'User_Completed_Career';
        display_name?: string | null;
        profileImage?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateCareerDevelopmentPlanMutationVariables = Exact<{
  object: CareerDevelopmentInput;
}>;

export type CreateCareerDevelopmentPlanMutation = {
  __typename?: 'mutation_root';
  createCareerDevelopmentPlan?: {
    __typename?: 'CareerDevelopmentOutput';
    id: any;
  } | null;
};

export type LoadUsersCareerPlanDetailsMutationVariables = Exact<{
  object: LoadUserSelectedCareerDetailInput;
}>;

export type LoadUsersCareerPlanDetailsMutation = {
  __typename?: 'mutation_root';
  loadUsersCareerPlanDetails?: {
    __typename?: 'LoadUserSelectedCareerDetailOutput';
    response?: {
      __typename?: 'UserCareerFlowDetail';
      id?: any | null;
      title?: string | null;
      description?: string | null;
      trainingCount?: number | null;
      assessmentCount?: number | null;
      progress?: number | null;
      storage_file?: {
        __typename?: 'CoverImageDetail';
        id?: any | null;
        file_url?: string | null;
        metadata?: string | null;
        type?: string | null;
      } | null;
      stage_details?: Array<{
        __typename?: 'UserCareerStageDetails';
        stage_name?: string | null;
        totalCount?: string | null;
        stage_status?: string | null;
        taskDetail?: Array<{
          __typename?: 'UserCareerStageTasksDetails';
          assessment_id?: any | null;
          training_id?: any | null;
          type?: string | null;
          name?: string | null;
          training_progress_records?: number | null;
          docData?: string | null;
          status?: string | null;
          assessment_type?: string | null;
          question_list?: number | null;
          storage_file?: {
            __typename?: 'TaskStorageFileDetails';
            file_url?: string | null;
            metadata?: string | null;
            type?: string | null;
          } | null;
          skill_detail?: Array<{
            __typename?: 'AssessmentTrainingSkills';
            skill_name?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
      external_certification?: Array<{
        __typename?: 'External_Certification_Details';
        id?: any | null;
        name?: string | null;
        status?: string | null;
        certificate?: {
          __typename?: 'Certification_Details';
          file_url?: string | null;
          metadata?: string | null;
          type?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Delete_Career_Development_PlanMutationVariables = Exact<{
  career_development_detail_id?: InputMaybe<Scalars['uuid']>;
}>;

export type Delete_Career_Development_PlanMutation = {
  __typename?: 'mutation_root';
  delete_user_career_development_record?: {
    __typename?: 'user_career_development_record_mutation_response';
    affected_rows: number;
  } | null;
  delete_assign_career_development_plan?: {
    __typename?: 'assign_career_development_plan_mutation_response';
    affected_rows: number;
  } | null;
  delete_assessment_stage_career?: {
    __typename?: 'assessment_stage_career_mutation_response';
    affected_rows: number;
  } | null;
  delete_training_stage_career?: {
    __typename?: 'training_stage_career_mutation_response';
    affected_rows: number;
  } | null;
  delete_stage_career_detail?: {
    __typename?: 'stage_career_detail_mutation_response';
    affected_rows: number;
  } | null;
  delete_career_external_certification?: {
    __typename?: 'career_external_certification_mutation_response';
    affected_rows: number;
  } | null;
  delete_career_development_role_details?: {
    __typename?: 'career_development_role_details_mutation_response';
    affected_rows: number;
  } | null;
  delete_career_development_detail?: {
    __typename?: 'career_development_detail_mutation_response';
    returning: Array<{ __typename?: 'career_development_detail'; id: any }>;
  } | null;
};

export type List_Of_Assigned_Users_In_Career_PlanQueryVariables = Exact<{
  career_id: Scalars['uuid'];
}>;

export type List_Of_Assigned_Users_In_Career_PlanQuery = {
  __typename?: 'query_root';
  assign_career_development_plan: Array<{
    __typename?: 'assign_career_development_plan';
    user_id: any;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
        organization_role?: {
          __typename?: 'organization_roles';
          role_title: string;
        } | null;
      }>;
    };
  }>;
};

export type List_Of_UsersQueryVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type List_Of_UsersQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
  }>;
};

export type Assign_User_To_Career_PlanMutationVariables = Exact<{
  object: AddAssignUserToCareerPlanInput;
}>;

export type Assign_User_To_Career_PlanMutation = {
  __typename?: 'mutation_root';
  addUserToCareerDevelopmentPlan?: {
    __typename?: 'AddAssignUserToCareerPlanOutput';
    response: string;
  } | null;
};

export type Delete_Assigend_User_In_Career_JourneyMutationVariables = Exact<{
  career_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type Delete_Assigend_User_In_Career_JourneyMutation = {
  __typename?: 'mutation_root';
  delete_user_career_development_record?: {
    __typename?: 'user_career_development_record_mutation_response';
    affected_rows: number;
  } | null;
  delete_assign_career_development_plan?: {
    __typename?: 'assign_career_development_plan_mutation_response';
    returning: Array<{
      __typename?: 'assign_career_development_plan';
      id: any;
    }>;
  } | null;
};

export type LoadCareerDevelopmentPlanQueryVariables = Exact<{
  career_id?: InputMaybe<Scalars['uuid']>;
}>;

export type LoadCareerDevelopmentPlanQuery = {
  __typename?: 'query_root';
  career_development_detail: Array<{
    __typename?: 'career_development_detail';
    id: any;
    duration: number;
    description?: string | null;
    cover_image_id: any;
    title: string;
    created_by: any;
    storage_file: {
      __typename?: 'storage_files';
      file_url: string;
      metadata: any;
      type: Enum_Storage_File_Type_Enum;
    };
    career_external_certifications: Array<{
      __typename?: 'career_external_certification';
      external_certification_name?: string | null;
    }>;
    career_development_role_details: Array<{
      __typename?: 'career_development_role_details';
      organization_role: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      };
    }>;
    stage_career_details: Array<{
      __typename?: 'stage_career_detail';
      id: any;
      stage_name: string;
      training_stage_careers: Array<{
        __typename?: 'training_stage_career';
        training: {
          __typename?: 'training';
          id: any;
          name: string;
          storage_file?: {
            __typename?: 'storage_files';
            file_url: string;
            metadata: any;
            type: Enum_Storage_File_Type_Enum;
          } | null;
        };
      }>;
      assessment_stage_careers: Array<{
        __typename?: 'assessment_stage_career';
        assessment: {
          __typename?: 'assessment';
          id: any;
          name: string;
          type: string;
          questions_aggregate: {
            __typename?: 'questions_aggregate';
            aggregate?: {
              __typename?: 'questions_aggregate_fields';
              count: number;
            } | null;
          };
          assessment_Skills: Array<{
            __typename?: 'assessment_Skills';
            list_Of_Skill?: {
              __typename?: 'list_Of_Skills';
              name: string;
            } | null;
          }>;
        };
      }>;
    }>;
  }>;
};

export type UpdateCareerDevelopmentPlanMutationVariables = Exact<{
  object: UpdateCareerDevelopmentPlanInput;
}>;

export type UpdateCareerDevelopmentPlanMutation = {
  __typename?: 'mutation_root';
  updateCareerDevelopmentPlan?: {
    __typename?: 'UpdateCareerDevelopmentPlanOutput';
    id: any;
  } | null;
};

export type UploadExternalCertificationMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<User_Career_External_Certification_Insert_Input>
    | User_Career_External_Certification_Insert_Input
  >;
}>;

export type UploadExternalCertificationMutation = {
  __typename?: 'mutation_root';
  insert_user_career_external_certification?: {
    __typename?: 'user_career_external_certification_mutation_response';
    returning: Array<{
      __typename?: 'user_career_external_certification';
      id: any;
    }>;
  } | null;
};

export type CareerViewStatsMutationVariables = Exact<{
  object: CareerViewStatsInput;
}>;

export type CareerViewStatsMutation = {
  __typename?: 'mutation_root';
  careerViewStats?: {
    __typename?: 'CareerViewStatsOutput';
    response?: {
      __typename?: 'ViewStatsResponse';
      count?: number | null;
      deviated_skills?: Array<{
        __typename?: 'Deviated_Skills';
        skill_name?: string | null;
        deviated_percentage?: number | null;
      } | null> | null;
      skilled_skills?: Array<{
        __typename?: 'Skilled_Skills';
        skill_name?: string | null;
        skilled_percentage?: number | null;
      } | null> | null;
      skill_forecasting?: Array<{
        __typename?: 'Skill_Forecasting';
        skill_name?: string | null;
        total_emp?: number | null;
        deviated_emp?: {
          __typename?: 'Deviated_Emp';
          deviated_count?: number | null;
          percentage?: number | null;
          user_list?: Array<{
            __typename?: 'Skill_User_List';
            user_name?: string | null;
            profile_pic?: string | null;
            role_title?: string | null;
          } | null> | null;
        } | null;
        skilled_emp?: {
          __typename?: 'Skilled_Emp';
          skilled_count?: number | null;
          percentage?: number | null;
          user_list?: Array<{
            __typename?: 'Skill_User_List';
            user_name?: string | null;
            profile_pic?: string | null;
            role_title?: string | null;
          } | null> | null;
        } | null;
        training?: {
          __typename?: 'Skill_Training_List';
          count?: number | null;
          training_names?: Array<string | null> | null;
          training_details?: Array<{
            __typename?: 'User_Training_Details';
            training_name?: string | null;
            completedBy?: Array<{
              __typename?: 'User_Data_Info';
              name?: string | null;
              profile_pic?: string | null;
            } | null> | null;
            not_CompletedBy?: Array<{
              __typename?: 'User_Data_Info';
              name?: string | null;
              profile_pic?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        assessment?: {
          __typename?: 'Skill_Assessment_List';
          count?: number | null;
          assessment_names?: Array<string | null> | null;
          assessment_details?: Array<{
            __typename?: 'User_Assessment_Details';
            assessment_name?: string | null;
            pass_percentage?: number | null;
            completedBy?: Array<{
              __typename?: 'User_Data_Info';
              name?: string | null;
              profile_pic?: string | null;
            } | null> | null;
            not_CompletedBy?: Array<{
              __typename?: 'User_Data_Info';
              name?: string | null;
              profile_pic?: string | null;
            } | null> | null;
            failed_CompletedBy?: Array<{
              __typename?: 'User_Data_Info';
              name?: string | null;
              profile_pic?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null> | null;
      skill_reporting?: Array<{
        __typename?: 'Skill_Reporting';
        user_name?: string | null;
        profile_pic?: string | null;
        deviated_skill?: {
          __typename?: 'User_Skill_Deviated';
          count?: string | null;
          skill_names?: Array<string | null> | null;
          skill_details?: Array<{
            __typename?: 'User_Skill_Deviated_Info';
            skill_name?: string | null;
            deviation?: string | null;
            rating?: number | null;
          } | null> | null;
        } | null;
        training?: {
          __typename?: 'User_Skill_Reporting_Training';
          count?: string | null;
          training_names?: Array<string | null> | null;
          training_details?: Array<{
            __typename?: 'User_Training_Details_Info';
            training_name?: string | null;
            status?: string | null;
          } | null> | null;
        } | null;
        assessment?: {
          __typename?: 'User_Skill_Reporting_Assessment';
          count?: string | null;
          assessment_names?: Array<string | null> | null;
          assessment_details?: Array<{
            __typename?: 'User_Assessment_Details_Info';
            assessment_name?: string | null;
            status?: string | null;
            percentage?: number | null;
          } | null> | null;
        } | null;
        ldp_journey?: {
          __typename?: 'User_Skill_Reporting_Journey';
          count?: number | null;
          career_names?: Array<string | null> | null;
          career_details?: Array<{
            __typename?: 'User_Joureny_Details_Info';
            career_name?: string | null;
            status?: Array<{
              __typename?: 'Stages_Journey_Status';
              stage_name?: string | null;
              status?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Update_UserSkillsByManagerMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  rating: Scalars['Int'];
}>;

export type Update_UserSkillsByManagerMutation = {
  __typename?: 'mutation_root';
  update_user_Skills?: {
    __typename?: 'user_Skills_mutation_response';
    returning: Array<{ __typename?: 'user_Skills'; id: any }>;
  } | null;
};

export type GetUserListForSkillApprovalMutationVariables = Exact<{
  object: GetUSerListForSkillApprovalInput;
}>;

export type GetUserListForSkillApprovalMutation = {
  __typename?: 'mutation_root';
  getUserListForSkillApproval?: {
    __typename?: 'GetUSerListForSkillApprovalOutput';
    response?: {
      __typename?: 'UserListResp';
      pendingList?: Array<{
        __typename?: 'UserList';
        userId?: any | null;
        userName?: string | null;
        roleName?: string | null;
        profileImage?: string | null;
        skillCount?: number | null;
      } | null> | null;
      approvedList?: Array<{
        __typename?: 'UserList';
        userId?: any | null;
        userName?: string | null;
        roleName?: string | null;
        profileImage?: string | null;
        skillCount?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetUsersSkillDetailMutationVariables = Exact<{
  object: GetUserSkillInfoInput;
}>;

export type GetUsersSkillDetailMutation = {
  __typename?: 'mutation_root';
  getUsersSkillDetail?: {
    __typename?: 'GetUserSkillInfoOutput';
    response?: Array<{
      __typename?: 'UserSkillResponse';
      id?: any | null;
      userSkillId?: any | null;
      skillName?: string | null;
      userRating?: number | null;
      description?: string | null;
      action?: string | null;
      competencyName?: string | null;
      trainingCount?: number | null;
      assessmentCount?: number | null;
      reviseSkill?: Array<{
        __typename?: 'SkillsDescription';
        rating?: number | null;
        description?: string | null;
      } | null> | null;
      trainingData?: Array<{
        __typename?: 'TrainingData';
        name?: string | null;
        status?: string | null;
      } | null> | null;
      assessmentData?: Array<{
        __typename?: 'AssessmentData';
        name?: string | null;
        score?: number | null;
        pass?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ApprovalOfSkillsByManagerMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  rating: Scalars['Int'];
}>;

export type ApprovalOfSkillsByManagerMutation = {
  __typename?: 'mutation_root';
  update_user_Skills?: {
    __typename?: 'user_Skills_mutation_response';
    returning: Array<{ __typename?: 'user_Skills'; id: any }>;
  } | null;
};

export type CreateTriningTypeMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Training_Type_For_Training_Insert_Input>
    | Training_Type_For_Training_Insert_Input
  >;
}>;

export type CreateTriningTypeMutation = {
  __typename?: 'mutation_root';
  insert_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetUserQuery = {
  __typename?: 'query_root';
  organization_by_pk?: {
    __typename?: 'organization';
    user: { __typename?: 'users'; id: any };
  } | null;
};

export type GetTrainingTypeQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  trainingType?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetTrainingTypeQuery = {
  __typename?: 'query_root';
  training_type_for_training: Array<{
    __typename?: 'training_type_for_training';
    name: string;
    status: boolean;
    id: any;
    user_id: any;
    user: { __typename?: 'users'; first_name?: string | null };
  }>;
};

export type DeleteTrainingTypeMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteTrainingTypeMutation = {
  __typename?: 'mutation_root';
  delete_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export type EditTrainingTypeMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  status: Scalars['Boolean'];
}>;

export type EditTrainingTypeMutation = {
  __typename?: 'mutation_root';
  update_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export type CompetencyListQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type CompetencyListQuery = {
  __typename?: 'query_root';
  main_skills: Array<{ __typename?: 'main_skills'; id: any; name: string }>;
};

export type AddUserSkillsMutationVariables = Exact<{
  object: AddUsersSkillsAndComptInput;
}>;

export type AddUserSkillsMutation = {
  __typename?: 'mutation_root';
  addUserSkills?: {
    __typename?: 'AddUsersSkillsAndComptOutput';
    id: any;
  } | null;
};

export type ListOfSkillUnderComptMutationVariables = Exact<{
  object: ListofSkillsUnderComptInput;
}>;

export type ListOfSkillUnderComptMutation = {
  __typename?: 'mutation_root';
  skillsUnderComepetency?: {
    __typename?: 'ListofSkillsUnderComptOutput';
    response?: Array<{
      __typename?: 'ListOfComptSkills';
      id?: any | null;
      skillId?: any | null;
      userRating?: number | null;
      skillName?: string | null;
      skillDetail?: Array<{
        __typename?: 'SkillDescription';
        rating?: number | null;
        description?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DeleteUserSkillsMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteUserSkillsMutation = {
  __typename?: 'mutation_root';
  delete_user_Skills_Assessment?: {
    __typename?: 'user_Skills_Assessment_mutation_response';
    affected_rows: number;
  } | null;
  delete_user_Skills_Training?: {
    __typename?: 'user_Skills_Training_mutation_response';
    affected_rows: number;
  } | null;
  delete_user_Skills?: {
    __typename?: 'user_Skills_mutation_response';
    returning: Array<{ __typename?: 'user_Skills'; id: any }>;
  } | null;
};

export type Update_UserSkillsMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  rating: Scalars['Int'];
}>;

export type Update_UserSkillsMutation = {
  __typename?: 'mutation_root';
  update_user_Skills?: {
    __typename?: 'user_Skills_mutation_response';
    returning: Array<{ __typename?: 'user_Skills'; id: any }>;
  } | null;
};

export type GetUserSkillInDashboardMutationVariables = Exact<{
  object: GetUserSkillDashboardInput;
}>;

export type GetUserSkillInDashboardMutation = {
  __typename?: 'mutation_root';
  getUserSkillInDashboard?: {
    __typename?: 'GetUserSkillDashboardOutput';
    response?: {
      __typename?: 'Resp';
      trainingCount?: number | null;
      assessmentCount?: number | null;
      trainingAttended?: number | null;
      assessmentAttended?: number | null;
      underManagerApproval?: Array<{
        __typename?: 'Competency';
        competencyId?: any | null;
        competencyName?: string | null;
        id?: any | null;
        listOfSkills?: Array<{
          __typename?: 'Skills';
          id?: any | null;
          skillId?: any | null;
          skillName?: string | null;
          status?: string | null;
          userRating?: number | null;
          skillDetail?: Array<{
            __typename?: 'SkillDescription';
            rating?: number | null;
            description?: string | null;
          } | null> | null;
          assessmentDetail: Array<{
            __typename?: 'assessmentDetailInfo';
            id?: any | null;
            assessmentName?: string | null;
            assessmentId?: any | null;
            status?: string | null;
          } | null>;
          trainingDetail: Array<{
            __typename?: 'trainingDetailInfo';
            id?: any | null;
            trainingName?: string | null;
            trainingId?: any | null;
            status?: string | null;
            storage_file?: {
              __typename?: 'StorageFileDetail';
              id?: any | null;
              file_url?: string | null;
              type?: string | null;
              metadata?: string | null;
            } | null;
            training_progress_records?: Array<{
              __typename?: 'TrainingProgressDetails';
              id?: any | null;
              docData?: string | null;
              progress?: string | null;
              type?: string | null;
            } | null> | null;
            training_feedbacks_aggregate?: {
              __typename?: 'TrainingFeedbacksAggregate';
              aggregate?: {
                __typename?: 'AggregateInfo';
                count?: number | null;
                sum?: {
                  __typename?: 'AggregateSum';
                  feedback?: number | null;
                } | null;
              } | null;
              nodes?: Array<{
                __typename?: 'UserInfoNodes';
                user?: {
                  __typename?: 'UserInfoData';
                  id?: any | null;
                  display_name?: string | null;
                  profile?: {
                    __typename?: 'UserProfileInfo';
                    activity_status?: string | null;
                    avatar_file?: {
                      __typename?: 'AvatarFileInfo';
                      file_url?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null>;
        } | null> | null;
      } | null> | null;
      approvedSkills?: Array<{
        __typename?: 'Competency';
        competencyId?: any | null;
        id?: any | null;
        competencyName?: string | null;
        listOfSkills?: Array<{
          __typename?: 'Skills';
          id?: any | null;
          skillId?: any | null;
          skillName?: string | null;
          status?: string | null;
          userRating?: number | null;
          skillDetail?: Array<{
            __typename?: 'SkillDescription';
            rating?: number | null;
            description?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
      project?: Array<{
        __typename?: 'projList';
        projectTitle?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateAssessmentMutationVariables = Exact<{
  object: AssessmentInput;
}>;

export type CreateAssessmentMutation = {
  __typename?: 'mutation_root';
  createAssessment?: { __typename?: 'AssessmentOutput'; id: any } | null;
};

export type UpdateTrainingInfoMutationVariables = Exact<{
  object: UpdateTrainingInput;
}>;

export type UpdateTrainingInfoMutation = {
  __typename?: 'mutation_root';
  updateTraining?: { __typename?: 'updateTrainingOutput'; id: any } | null;
};

export type FeedBack_CountMutationVariables = Exact<{
  object: FeedbackInput;
}>;

export type FeedBack_CountMutation = {
  __typename?: 'mutation_root';
  loadfeedback?: {
    __typename?: 'feedbackOutput';
    count: number;
    feedback: number;
  } | null;
};

export type SeeScoreCardMutationVariables = Exact<{
  object: ScoredCardInput;
}>;

export type SeeScoreCardMutation = {
  __typename?: 'mutation_root';
  loadscorecard?: {
    __typename?: 'scoreCardOutput';
    response?: Array<{
      __typename?: 'scoreData';
      name: string;
      role: string;
      position: string;
      employeeName: string;
      score: number;
      noOfTries: number;
      passingScore: number;
    } | null> | null;
  } | null;
};

export type UpdateAssessmentMutationVariables = Exact<{
  data: UpdateAssessmentInput;
}>;

export type UpdateAssessmentMutation = {
  __typename?: 'mutation_root';
  updateAssessment?: { __typename?: 'UpdateAssessmentOutput'; id: any } | null;
};

export type Submit_AssessmentMutationVariables = Exact<{
  object: AttendAssessmentInput;
}>;

export type Submit_AssessmentMutation = {
  __typename?: 'mutation_root';
  attendAssessment?: { __typename?: 'attendAssessmentOutput'; id: any } | null;
};

export type LoadMandatoryAssessmentMutationVariables = Exact<{
  object: LoadMandatoryAssessmentInput;
}>;

export type LoadMandatoryAssessmentMutation = {
  __typename?: 'mutation_root';
  loadMandatoryAssessment?: {
    __typename?: 'loadMandatoryAssessmentOutput';
    response?: Array<{
      __typename?: 'responseData';
      id: any;
      name: string;
      type: string;
      status: string;
      score: number;
      time: number;
      recomendedTraining?: string | null;
      passingScore: number;
      count: number;
      completedData?: Array<{
        __typename?: 'completed';
        displayName: string;
        profile: string;
        status: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateNewTrainingMutationVariables = Exact<{
  object: TrainingInput;
}>;

export type CreateNewTrainingMutation = {
  __typename?: 'mutation_root';
  createTraining?: { __typename?: 'AssessmentOutput'; id: any } | null;
};

export type CreateTrainingProgressDataMutationVariables = Exact<{
  object: TrainingProgressInput;
}>;

export type CreateTrainingProgressDataMutation = {
  __typename?: 'mutation_root';
  createTrainingProgress?: {
    __typename?: 'TrainingProgressOutput';
    id: string;
  } | null;
};

export type CreateTrainingFeddbackMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Training_Feedback_Insert_Input> | Training_Feedback_Insert_Input
  >;
}>;

export type CreateTrainingFeddbackMutation = {
  __typename?: 'mutation_root';
  insert_training_feedback?: {
    __typename?: 'training_feedback_mutation_response';
    returning: Array<{ __typename?: 'training_feedback'; id: any }>;
  } | null;
};

export type TrainingFeedBack_CountQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type TrainingFeedBack_CountQuery = {
  __typename?: 'query_root';
  training_feedback: Array<{
    __typename?: 'training_feedback';
    training_id: any;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
  training_feedback_aggregate: {
    __typename?: 'training_feedback_aggregate';
    aggregate?: {
      __typename?: 'training_feedback_aggregate_fields';
      count: number;
      sum?: {
        __typename?: 'training_feedback_sum_fields';
        feedback?: any | null;
      } | null;
    } | null;
  };
};

export type LoadActiveAssessmentTypeQueryVariables = Exact<{
  _eq1: Scalars['uuid'];
}>;

export type LoadActiveAssessmentTypeQuery = {
  __typename?: 'query_root';
  assessment_type: Array<{
    __typename?: 'assessment_type';
    name: string;
    meta_tags: string;
    id: any;
    user_id: any;
    status: boolean;
    organization_id: any;
    user: { __typename?: 'users'; display_name?: string | null };
  }>;
};

export type GetOrganizationRolesByOrganizationIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  roleText?: InputMaybe<Scalars['String']>;
}>;

export type GetOrganizationRolesByOrganizationIdQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    id: any;
    role_title: string;
    system_role: string;
  }>;
};

export type GetDepartmentByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  departmentText?: InputMaybe<Scalars['String']>;
}>;

export type GetDepartmentByOrgIdQuery = {
  __typename?: 'query_root';
  department: Array<{ __typename?: 'department'; id: any; name: string }>;
};

export type Frontend_GetUser_ByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type Frontend_GetUser_ByIdQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    last_sign_date_time?: any | null;
    ip_address?: any | null;
    account?: {
      __typename?: 'auth_accounts';
      email?: any | null;
      active: boolean;
    } | null;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    department_employees: Array<{
      __typename?: 'department_employee';
      department: { __typename?: 'department'; id: any; name: string };
    }>;
    organization_employees: Array<{
      __typename?: 'organization_employee';
      manager_id?: any | null;
    }>;
  } | null;
};

export type Org_AdminRoleIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Org_AdminRoleIdQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    id: any;
    system_role: string;
    role_title: string;
  }>;
};

export type Close_AssessmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  status: Scalars['Boolean'];
}>;

export type Close_AssessmentMutation = {
  __typename?: 'mutation_root';
  update_assessment_by_pk?: { __typename?: 'assessment'; id: any } | null;
};

export type Delete_AssessmentMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Delete_AssessmentMutation = {
  __typename?: 'mutation_root';
  delete_assessment?: {
    __typename?: 'assessment_mutation_response';
    returning: Array<{ __typename?: 'assessment'; id: any }>;
  } | null;
};

export type Load_AssessmentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Load_AssessmentQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    id: any;
    name: string;
    type: string;
    status: boolean;
    recommended_training?: string | null;
    active_till: number;
    assessment_role_details: Array<{
      __typename?: 'assessment_role_details';
      role_id: any;
      organization_role: {
        __typename?: 'organization_roles';
        system_role: string;
        role_title: string;
      };
    }>;
  }>;
};

export type Get_AssessmentQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type Get_AssessmentQuery = {
  __typename?: 'query_root';
  assessment_by_pk?: {
    __typename?: 'assessment';
    passing_score: any;
    active_till: number;
    recommended_training?: string | null;
    id: any;
    name: string;
    status: boolean;
    tags?: string | null;
    type: string;
    user_id: any;
    assessment_type_id: any;
    department?: { __typename?: 'department'; id: any; name: string } | null;
    organization_role?: {
      __typename?: 'organization_roles';
      system_role: string;
      role_title: string;
      id: any;
    } | null;
    questions: Array<{
      __typename?: 'questions';
      assessment_id: any;
      name: string;
      id: any;
      type: string;
      question_options: Array<{
        __typename?: 'question_options';
        correct_answer: boolean;
        name: string;
        id: any;
      }>;
      storage_file?: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
      } | null;
    }>;
    assessment_role_details: Array<{
      __typename?: 'assessment_role_details';
      role_id: any;
      organization_role: {
        __typename?: 'organization_roles';
        system_role: string;
        role_title: string;
      };
    }>;
    assessment_Skills: Array<{
      __typename?: 'assessment_Skills';
      list_Of_Skill?: {
        __typename?: 'list_Of_Skills';
        id: any;
        name: string;
      } | null;
    }>;
  } | null;
};

export type GetLatestAssessmentMarksMutationVariables = Exact<{
  object: GetLatestAssessmentRecordMarksInput;
}>;

export type GetLatestAssessmentMarksMutation = {
  __typename?: 'mutation_root';
  get_LatestAssessmentMarks?: {
    __typename?: 'GetLatestAssessmentRecordMarksOutput';
    correct: number;
    question: number;
    pass: string;
    response: Array<{
      __typename?: 'allAnswers';
      given_answer: string;
      correct_answer: string;
      question: {
        __typename?: 'AllQuestions';
        id: any;
        name: string;
        type: string;
        question_options: { __typename?: 'AllQuestionsOptions'; name: string };
        storage_file?: {
          __typename?: 'QuestionStorageFile';
          file_url?: string | null;
        } | null;
      };
    } | null>;
  } | null;
};

export type CommonTrainingQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _neq: Scalars['uuid'];
  _nin?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['String']>;
  _eq3: Scalars['uuid'];
  _in?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  _eq4?: InputMaybe<Scalars['String']>;
}>;

export type CommonTrainingQuery = {
  __typename?: 'query_root';
  training: Array<{
    __typename?: 'training';
    id: any;
    name: string;
    rewards: number;
    type: string;
    format: string;
    user_id: any;
    duration?: number | null;
    storage_file?: {
      __typename?: 'storage_files';
      id: any;
      file_url: string;
      type: Enum_Storage_File_Type_Enum;
      metadata: any;
    } | null;
    training_progress_records: Array<{
      __typename?: 'training_progress_record';
      docData: string;
      id: any;
      type: string;
      progress: string;
      user_id: any;
    }>;
    training_feedbacks_aggregate: {
      __typename?: 'training_feedback_aggregate';
      aggregate?: {
        __typename?: 'training_feedback_aggregate_fields';
        count: number;
        sum?: {
          __typename?: 'training_feedback_sum_fields';
          feedback?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'training_feedback';
        user: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            activity_status?: string | null;
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      }>;
    };
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type Load_TrainingQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']>;
  _eq1: Scalars['uuid'];
  _eq2: Scalars['uuid'];
  _eq3: Scalars['uuid'];
  _eq4?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type Load_TrainingQuery = {
  __typename?: 'query_root';
  training: Array<{
    __typename?: 'training';
    id: any;
    name: string;
    rewards: number;
    type: string;
    format: string;
    user_id: any;
    duration?: number | null;
    storage_file?: {
      __typename?: 'storage_files';
      id: any;
      file_url: string;
      type: Enum_Storage_File_Type_Enum;
      metadata: any;
    } | null;
    training_progress_records: Array<{
      __typename?: 'training_progress_record';
      docData: string;
      id: any;
      type: string;
      progress: string;
      user_id: any;
    }>;
    training_feedbacks_aggregate: {
      __typename?: 'training_feedback_aggregate';
      aggregate?: {
        __typename?: 'training_feedback_aggregate_fields';
        count: number;
        sum?: {
          __typename?: 'training_feedback_sum_fields';
          feedback?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'training_feedback';
        user: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            activity_status?: string | null;
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      }>;
    };
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type Load_TrainingOrg_AdminQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
  _eq3?: InputMaybe<Scalars['uuid']>;
  _eq4?: InputMaybe<Scalars['String']>;
  _in1?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type Load_TrainingOrg_AdminQuery = {
  __typename?: 'query_root';
  training: Array<{
    __typename?: 'training';
    id: any;
    name: string;
    rewards: number;
    type: string;
    format: string;
    user_id: any;
    duration?: number | null;
    storage_file?: {
      __typename?: 'storage_files';
      id: any;
      file_url: string;
      type: Enum_Storage_File_Type_Enum;
      metadata: any;
    } | null;
    training_progress_records: Array<{
      __typename?: 'training_progress_record';
      docData: string;
      id: any;
      type: string;
      progress: string;
      user_id: any;
    }>;
    training_feedbacks_aggregate: {
      __typename?: 'training_feedback_aggregate';
      aggregate?: {
        __typename?: 'training_feedback_aggregate_fields';
        count: number;
        sum?: {
          __typename?: 'training_feedback_sum_fields';
          feedback?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'training_feedback';
        user: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            activity_status?: string | null;
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      }>;
    };
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type TrainingDataQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type TrainingDataQuery = {
  __typename?: 'query_root';
  storage_files: Array<{
    __typename?: 'storage_files';
    id: any;
    file_url: string;
    type: Enum_Storage_File_Type_Enum;
  }>;
};

export type Delete_TrainingMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Delete_TrainingMutation = {
  __typename?: 'mutation_root';
  delete_training?: {
    __typename?: 'training_mutation_response';
    returning: Array<{ __typename?: 'training'; id: any }>;
  } | null;
};

export type Get_QuestionsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_QuestionsQuery = {
  __typename?: 'query_root';
  questions: Array<{
    __typename?: 'questions';
    name: string;
    id: any;
    type: string;
    storage_file?: { __typename?: 'storage_files'; file_url: string } | null;
    question_options: Array<{
      __typename?: 'question_options';
      name: string;
      id: any;
    }>;
    assessment: { __typename?: 'assessment'; name: string };
  }>;
};

export type LoadTrainingTypesQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type LoadTrainingTypesQuery = {
  __typename?: 'query_root';
  training_type_for_training: Array<{
    __typename?: 'training_type_for_training';
    name: string;
    status: boolean;
    id: any;
    user_id: any;
    meta_tags?: string | null;
    user: { __typename?: 'users'; first_name?: string | null };
  }>;
};

export type Submit_FeedBackMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Assessment_Feedback_Insert_Input> | Assessment_Feedback_Insert_Input
  >;
}>;

export type Submit_FeedBackMutation = {
  __typename?: 'mutation_root';
  insert_assessment_feedback?: {
    __typename?: 'assessment_feedback_mutation_response';
    returning: Array<{ __typename?: 'assessment_feedback'; id: any }>;
  } | null;
};

export type Get_FeedBackDataQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_FeedBackDataQuery = {
  __typename?: 'query_root';
  assessment_feedback: Array<{
    __typename?: 'assessment_feedback';
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type Load_TrainingForAssessmentQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Load_TrainingForAssessmentQuery = {
  __typename?: 'query_root';
  training: Array<{
    __typename?: 'training';
    id: any;
    name: string;
    rewards: number;
    type: string;
    format: string;
    storage_file?: {
      __typename?: 'storage_files';
      id: any;
      file_url: string;
      type: Enum_Storage_File_Type_Enum;
    } | null;
  }>;
};

export type Get_SelectedTrainingQueryVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type Get_SelectedTrainingQuery = {
  __typename?: 'query_root';
  training: Array<{
    __typename?: 'training';
    id: any;
    name: string;
    duration?: number | null;
    type: string;
    format: string;
    storage_file?: {
      __typename?: 'storage_files';
      metadata: any;
      file_url: string;
      id: any;
    } | null;
  }>;
};

export type AssessmentRecordQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type AssessmentRecordQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{
    __typename?: 'assessment_record';
    assessment_id: any;
    correct_answer: string;
    given_answer?: string | null;
    id: any;
    marks: number;
    organization_id: any;
    question_id: any;
    user_id: any;
  }>;
};

export type Assessment_TypeQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Assessment_TypeQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; type: string }>;
};

export type Get_TrainingInoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type Get_TrainingInoQuery = {
  __typename?: 'query_root';
  training_by_pk?: {
    __typename?: 'training';
    name: string;
    format: string;
    storage_file?: {
      __typename?: 'storage_files';
      file_url: string;
      id: any;
      metadata: any;
    } | null;
  } | null;
};

export type Get_UsersInfoOf_OrganizationQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type Get_UsersInfoOf_OrganizationQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type GetTableOfContentsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetTableOfContentsQuery = {
  __typename?: 'query_root';
  training_table_of_contents: Array<{
    __typename?: 'training_table_of_contents';
    end_time?: string | null;
    id: any;
    page?: number | null;
    showContents: boolean;
    start_time?: string | null;
    title?: string | null;
    training_id: any;
    type: string;
  }>;
};

export type LoadTrainingProgressQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type LoadTrainingProgressQuery = {
  __typename?: 'query_root';
  training_progress_record: Array<{
    __typename?: 'training_progress_record';
    user_id: any;
    type: string;
    training_id: any;
    progress: string;
    id: any;
    docData: string;
  }>;
};

export type Get_Training_DataQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_Training_DataQuery = {
  __typename?: 'query_root';
  training: Array<{
    __typename?: 'training';
    created_at: any;
    format: string;
    id: any;
    image_id?: any | null;
    name: string;
    organization_id: any;
    rewards: number;
    role_id?: any | null;
    tags?: string | null;
    type: string;
    updated_at: any;
    url?: string | null;
    user_id: any;
    storage_file?: {
      __typename?: 'storage_files';
      file_url: string;
      id: any;
      metadata: any;
    } | null;
    training_table_of_contents: Array<{
      __typename?: 'training_table_of_contents';
      end_time?: string | null;
      id: any;
      page?: number | null;
      showContents: boolean;
      start_time?: string | null;
      title?: string | null;
    }>;
    training_role_details: Array<{
      __typename?: 'training_role_details';
      role_id: any;
    }>;
    training_Skills: Array<{
      __typename?: 'training_Skill';
      list_Of_Skill?: {
        __typename?: 'list_Of_Skills';
        id: any;
        name: string;
      } | null;
    }>;
  }>;
};

export type ListOfSkillsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type ListOfSkillsQuery = {
  __typename?: 'query_root';
  list_Of_Skills: Array<{
    __typename?: 'list_Of_Skills';
    id: any;
    name: string;
  }>;
};

export type ListOfUsersUnderRolesQueryVariables = Exact<{
  org_id: Scalars['uuid'];
  role_id: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type ListOfUsersUnderRolesQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    profiles: Array<{
      __typename?: 'profile';
      userByUserId: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
        calendars: Array<{ __typename?: 'calendar'; id: any }>;
        profile: Array<{
          __typename?: 'profile';
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
  }>;
};

export type LiveCallSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type LiveCallSubscription = {
  __typename?: 'subscription_root';
  live_call_user: Array<{
    __typename?: 'live_call_user';
    user_id: any;
    created_by: any;
    live_call: {
      __typename?: 'live_call';
      id: any;
      channel_id: string;
      created_by: any;
      start_date_time?: any | null;
      live_call_users: Array<{
        __typename?: 'live_call_user';
        id: any;
        status: Enum_Live_Call_User_Status_Enum;
        user: { __typename?: 'users'; id: any; display_name?: string | null };
      }>;
    };
  }>;
};

export type CreateLiveCallMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type CreateLiveCallMutation = {
  __typename?: 'mutation_root';
  startLiveCall?: {
    __typename?: 'StartLiveCallOutput';
    liveCallId: any;
  } | null;
};

export type LeaveLiveCallMutationVariables = Exact<{
  liveCallId: Scalars['uuid'];
}>;

export type LeaveLiveCallMutation = {
  __typename?: 'mutation_root';
  leaveLiveCall?: {
    __typename?: 'LeaveLiveCallOutput';
    success: boolean;
  } | null;
};

export type JoinLiveCallMutationVariables = Exact<{
  liveCallId: Scalars['uuid'];
}>;

export type JoinLiveCallMutation = {
  __typename?: 'mutation_root';
  joinLiveCall?: { __typename?: 'JoinLiveCallOutput'; success: boolean } | null;
};

export type GetMyPendingMessagesNotificationSubscriptionVariables = Exact<{
  user_id: Scalars['uuid'];
}>;

export type GetMyPendingMessagesNotificationSubscription = {
  __typename?: 'subscription_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      notification_status?: Enum_Chat_Room_Notification_Status_Enum | null;
    }>;
    chat_messages: Array<{
      __typename?: 'chat_message';
      id: any;
      chat_room_id: any;
      message_text: string;
      message_type: Enum_Chat_Message_Type_Enum;
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        first_name?: string | null;
        last_name?: string | null;
      };
    }>;
  }>;
};

export type GetMyActiveMeetingRoomSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetMyActiveMeetingRoomSubscription = {
  __typename?: 'subscription_root';
  meeting_room: Array<{
    __typename?: 'meeting_room';
    id: any;
    type: Enum_Meeting_Room_Type_Enum;
    status: Enum_Meeting_Room_Status_Enum;
    userByCreatedBy: {
      __typename?: 'users';
      first_name?: string | null;
      id: any;
    };
  }>;
};

export type GetMeetingRoomInfoByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetMeetingRoomInfoByIdSubscription = {
  __typename?: 'subscription_root';
  meeting_room?: {
    __typename?: 'meeting_room';
    id: any;
    status: Enum_Meeting_Room_Status_Enum;
    type: Enum_Meeting_Room_Type_Enum;
    created_by: any;
    created_at: any;
    organization_id: any;
    updated_at: any;
    meeting_room_users: Array<{
      __typename?: 'meeting_room_users';
      user_id: any;
      status?: Enum_Meeting_Room_User_State_Enum | null;
      userByUserId: {
        __typename?: 'users';
        display_name?: string | null;
        user_avatar_files: Array<{
          __typename?: 'user_avatar_files';
          avatar_id: any;
          file_url?: string | null;
        }>;
        profile: Array<{
          __typename?: 'profile';
          agora_id?: number | null;
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type InviteMeetingRoomMutationVariables = Exact<{
  object: InviteMeetingRoomInput;
}>;

export type InviteMeetingRoomMutation = {
  __typename?: 'mutation_root';
  inviteMeetingRoom?: {
    __typename?: 'InviteMeetingRoomOutput';
    affected_rows: string;
  } | null;
};

export type LatestMeetingRoomUserSubscriptionVariables = Exact<{
  meetingRoomId: Scalars['uuid'];
}>;

export type LatestMeetingRoomUserSubscription = {
  __typename?: 'subscription_root';
  meeting_room_users: Array<{
    __typename?: 'meeting_room_users';
    status?: Enum_Meeting_Room_User_State_Enum | null;
    updated_at?: any | null;
    userByUserId: { __typename?: 'users'; id: any; first_name?: string | null };
  }>;
};

export type GetLastGonePersonSubscriptionVariables = Exact<{
  departmentId?: InputMaybe<Scalars['uuid']>;
  organizationId: Scalars['uuid'];
}>;

export type GetLastGonePersonSubscription = {
  __typename?: 'subscription_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      first_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
      }>;
      user_state?: {
        __typename?: 'user_state';
        activity_state_last_updated: any;
      } | null;
    } | null;
  }>;
};

export type GetLatestOnlinePersonSubscriptionVariables = Exact<{
  departmentId?: InputMaybe<Scalars['uuid']>;
  organizationId: Scalars['uuid'];
}>;

export type GetLatestOnlinePersonSubscription = {
  __typename?: 'subscription_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      first_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
      }>;
      user_state?: {
        __typename?: 'user_state';
        activity_state_last_updated: any;
        previous_activity_status?: string | null;
      } | null;
    } | null;
  }>;
};

export type GetLiveCallRequestSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetLiveCallRequestSubscription = {
  __typename?: 'subscription_root';
  live_call_request: Array<{
    __typename?: 'live_call_request';
    id: any;
    live_call_id: any;
    created_by: any;
    created_user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      my_organization?: {
        __typename?: 'organization_user';
        role_title?: string | null;
        manager?: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
        } | null;
      } | null;
      user_settings: Array<{ __typename?: 'user_settings'; working_from: any }>;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        activity_status_since?: any | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
      live_call_users: Array<{
        __typename?: 'live_call_user';
        status: Enum_Live_Call_User_Status_Enum;
      }>;
    };
  }>;
};

export type GetLatestUnreadMessageToMeQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetLatestUnreadMessageToMeQuery = {
  __typename?: 'query_root';
  chat_message_action: Array<{
    __typename?: 'chat_message_action';
    chat_message: {
      __typename?: 'chat_message';
      created_at: any;
      user: {
        __typename?: 'users';
        id: any;
        first_name?: string | null;
        last_name?: string | null;
        display_name?: string | null;
        live_call_users: Array<{
          __typename?: 'live_call_user';
          status: Enum_Live_Call_User_Status_Enum;
        }>;
        user_settings: Array<{
          __typename?: 'user_settings';
          working_from: any;
        }>;
        profile: Array<{
          __typename?: 'profile';
          date_of_birth?: string | null;
          activity_status?: string | null;
          organization_role?: {
            __typename?: 'organization_roles';
            id: any;
            role_title: string;
          } | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
          address?: { __typename?: 'address'; id: any } | null;
        }>;
        my_organization?: {
          __typename?: 'organization_user';
          manager?: { __typename?: 'users'; first_name?: string | null } | null;
        } | null;
      };
    };
  }>;
};

export type RespondToLiveCallMutationVariables = Exact<{
  object: RespondToLiveCallInput;
}>;

export type RespondToLiveCallMutation = {
  __typename?: 'mutation_root';
  respondToLiveCall: {
    __typename?: 'RespondToLiveCallOutput';
    success?: boolean | null;
  };
};

export type ActivateAccountMutationVariables = Exact<{
  ticket: Scalars['uuid'];
}>;

export type ActivateAccountMutation = {
  __typename?: 'mutation_root';
  activateAccount?: {
    __typename?: 'ActivateOutput';
    resetPassword?: string | null;
    refreshToken?: string | null;
  } | null;
};

export type ResendActivateEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResendActivateEmailMutation = {
  __typename?: 'mutation_root';
  resendActivateEmail?: {
    __typename?: 'ResendActivateEmailOutput';
    success?: boolean | null;
  } | null;
};

export type FrontendCreateAnnouncementMutationVariables = Exact<{
  message: Scalars['String'];
}>;

export type FrontendCreateAnnouncementMutation = {
  __typename?: 'mutation_root';
  createAnAnnouncement: {
    __typename?: 'createAnnouncementOutput';
    post_id: string;
  };
};

export type GetPostsByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetPostsByOrgIdQuery = {
  __typename?: 'query_root';
  posts: Array<{ __typename?: 'posts'; id: any; message: string }>;
};

export type UpdatePostMessageMutationVariables = Exact<{
  postId: Scalars['uuid'];
  message: Scalars['String'];
}>;

export type UpdatePostMessageMutation = {
  __typename?: 'mutation_root';
  update_posts_by_pk?: { __typename?: 'posts'; id: any } | null;
};

export type DeleteAnnouncementMutationVariables = Exact<{
  announcementId: Scalars['String'];
}>;

export type DeleteAnnouncementMutation = {
  __typename?: 'mutation_root';
  editPost?: {
    __typename?: 'editPostOutput';
    ok?: boolean | null;
    message?: string | null;
    post_id?: string | null;
  } | null;
};

export type AddAssessmentTypeOrgAdminMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Assessment_Type_Insert_Input> | Assessment_Type_Insert_Input
  >;
}>;

export type AddAssessmentTypeOrgAdminMutation = {
  __typename?: 'mutation_root';
  insert_assessment_type?: {
    __typename?: 'assessment_type_mutation_response';
    returning: Array<{ __typename?: 'assessment_type'; id: any }>;
  } | null;
};

export type LoadAssessmentTypeOrgAdminQueryVariables = Exact<{
  assessmentType?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  _eq: Scalars['uuid'];
}>;

export type LoadAssessmentTypeOrgAdminQuery = {
  __typename?: 'query_root';
  assessment_type: Array<{
    __typename?: 'assessment_type';
    name: string;
    meta_tags: string;
    id: any;
    user_id: any;
    status: boolean;
    organization_id: any;
    user: { __typename?: 'users'; display_name?: string | null };
  }>;
  assessment_type_aggregate: {
    __typename?: 'assessment_type_aggregate';
    aggregate?: {
      __typename?: 'assessment_type_aggregate_fields';
      count: number;
    } | null;
  };
};

export type UpdateAssessmentTypeMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  status?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateAssessmentTypeMutation = {
  __typename?: 'mutation_root';
  update_assessment_type?: {
    __typename?: 'assessment_type_mutation_response';
    returning: Array<{ __typename?: 'assessment_type'; id: any }>;
  } | null;
};

export type DeleteAssessmentTypeOrgAdminMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteAssessmentTypeOrgAdminMutation = {
  __typename?: 'mutation_root';
  delete_assessment_type?: {
    __typename?: 'assessment_type_mutation_response';
    returning: Array<{ __typename?: 'assessment_type'; id: any }>;
  } | null;
};

export type GetOrgAwardsQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetOrgAwardsQuery = {
  __typename?: 'query_root';
  organization_awards: Array<{
    __typename?: 'organization_awards';
    id: any;
    is_active: boolean;
    manager_permission: boolean;
    created_at: any;
    deleted_at?: any | null;
    award_types: Array<{
      __typename?: 'awards';
      id: any;
      name: string;
      description?: string | null;
      cycle: Enum_Award_Cycle_Type_Enum;
    }>;
  }>;
};

export type UpdateOrganizationAwardsByOrgIdMutationVariables = Exact<{
  orgId: Scalars['uuid'];
  data: Organization_Awards_Set_Input;
}>;

export type UpdateOrganizationAwardsByOrgIdMutation = {
  __typename?: 'mutation_root';
  update_organization_awards?: {
    __typename?: 'organization_awards_mutation_response';
    returning: Array<{ __typename?: 'organization_awards'; id: any }>;
  } | null;
};

export type InsertOrganizationAwardMutationVariables = Exact<{
  object: Organization_Awards_Insert_Input;
}>;

export type InsertOrganizationAwardMutation = {
  __typename?: 'mutation_root';
  insert_organization_awards_one?: {
    __typename?: 'organization_awards';
    organization_id: any;
  } | null;
};

export type CreateNewAwardTypeMutationVariables = Exact<{
  object: CreateAwardTypeInput;
}>;

export type CreateNewAwardTypeMutation = {
  __typename?: 'mutation_root';
  createAwardType?: {
    __typename?: 'createAwardTypeOutput';
    name?: string | null;
  } | null;
};

export type GetAllAwardsByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetAllAwardsByOrgIdQuery = {
  __typename?: 'query_root';
  awards: Array<{
    __typename?: 'awards';
    id: any;
    name: string;
    cycle: Enum_Award_Cycle_Type_Enum;
    description?: string | null;
    created_at: any;
    created_by: any;
    permissions?: string | null;
    limit?: number | null;
  }>;
};

export type GetAwardDetailsByIdQueryVariables = Exact<{
  awardId: Scalars['uuid'];
}>;

export type GetAwardDetailsByIdQuery = {
  __typename?: 'query_root';
  awards: Array<{
    __typename?: 'awards';
    id: any;
    name: string;
    cycle: Enum_Award_Cycle_Type_Enum;
    description?: string | null;
    created_at: any;
    created_by: any;
    permissions?: string | null;
    limit?: number | null;
  }>;
};

export type UpdateAwardsByIdMutationVariables = Exact<{
  awardId: Scalars['uuid'];
  data: Awards_Set_Input;
}>;

export type UpdateAwardsByIdMutation = {
  __typename?: 'mutation_root';
  update_awards_by_pk?: { __typename?: 'awards'; id: any } | null;
};

export type UpdateAwardsByOrgIdMutationVariables = Exact<{
  orgId: Scalars['uuid'];
  data: Awards_Set_Input;
}>;

export type UpdateAwardsByOrgIdMutation = {
  __typename?: 'mutation_root';
  update_awards?: {
    __typename?: 'awards_mutation_response';
    returning: Array<{ __typename?: 'awards'; id: any }>;
  } | null;
};

export type DeleteAwardsByIdMutationVariables = Exact<{
  awardId: Scalars['uuid'];
}>;

export type DeleteAwardsByIdMutation = {
  __typename?: 'mutation_root';
  delete_awards?: {
    __typename?: 'awards_mutation_response';
    affected_rows: number;
  } | null;
  delete_awards_by_pk?: {
    __typename?: 'awards';
    id: any;
    name: string;
    cycle: Enum_Award_Cycle_Type_Enum;
  } | null;
};

export type AddCompetencyMutationVariables = Exact<{
  object: AddCompetencyInput;
}>;

export type AddCompetencyMutation = {
  __typename?: 'mutation_root';
  addCompetency?: { __typename?: 'AddCompetencyOutput'; id: any } | null;
};

export type ListOfCompetencyQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type ListOfCompetencyQuery = {
  __typename?: 'query_root';
  main_skills: Array<{
    __typename?: 'main_skills';
    id: any;
    name: string;
    sub_skills: Array<{
      __typename?: 'sub_skills';
      skill_Id?: any | null;
      list_Of_Skill?: {
        __typename?: 'list_Of_Skills';
        id: any;
        name: string;
        skills_details: Array<{
          __typename?: 'skills_detail';
          rating?: number | null;
          description?: string | null;
        }>;
      } | null;
    }>;
  }>;
  main_skills_aggregate: {
    __typename?: 'main_skills_aggregate';
    aggregate?: {
      __typename?: 'main_skills_aggregate_fields';
      count: number;
    } | null;
  };
};

export type UpdateCompetnecyNameMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
}>;

export type UpdateCompetnecyNameMutation = {
  __typename?: 'mutation_root';
  update_main_skills_by_pk?: {
    __typename?: 'main_skills';
    name: string;
  } | null;
};

export type DeleteCompetencyMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteCompetencyMutation = {
  __typename?: 'mutation_root';
  delete_sub_skills?: {
    __typename?: 'sub_skills_mutation_response';
    affected_rows: number;
  } | null;
  delete_main_skills?: {
    __typename?: 'main_skills_mutation_response';
    returning: Array<{ __typename?: 'main_skills'; id: any }>;
  } | null;
};

export type EditCompetencyMutationVariables = Exact<{
  object: EditCompetencyInput;
}>;

export type EditCompetencyMutation = {
  __typename?: 'mutation_root';
  editCompetency?: {
    __typename?: 'EditCompetencyOutput';
    response: string;
  } | null;
};

export type ViewCompetencyQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type ViewCompetencyQuery = {
  __typename?: 'query_root';
  main_skills: Array<{
    __typename?: 'main_skills';
    id: any;
    name: string;
    org_Id: any;
    sub_skills: Array<{
      __typename?: 'sub_skills';
      id: any;
      skill_Id?: any | null;
      list_Of_Skill?: {
        __typename?: 'list_Of_Skills';
        id: any;
        name: string;
        skills_details: Array<{
          __typename?: 'skills_detail';
          id: any;
          rating?: number | null;
          description?: string | null;
        }>;
      } | null;
    }>;
  }>;
};

export type AddSkillInfoMutationVariables = Exact<{
  object: AddSkillDetailsInput;
}>;

export type AddSkillInfoMutation = {
  __typename?: 'mutation_root';
  addSkillInfo?: { __typename?: 'AddSkillDetailsOutput'; id: any } | null;
};

export type GetListOfSkillsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetListOfSkillsQuery = {
  __typename?: 'query_root';
  list_Of_Skills: Array<{
    __typename?: 'list_Of_Skills';
    id: any;
    name: string;
    skills_details: Array<{
      __typename?: 'skills_detail';
      rating?: number | null;
      description?: string | null;
    }>;
    sub_skills: Array<{
      __typename?: 'sub_skills';
      main_skill: { __typename?: 'main_skills'; id: any; name: string };
    }>;
  }>;
  list_Of_Skills_aggregate: {
    __typename?: 'list_Of_Skills_aggregate';
    aggregate?: {
      __typename?: 'list_Of_Skills_aggregate_fields';
      count: number;
    } | null;
  };
};

export type EditSkillDetailsMutationVariables = Exact<{
  object: EditSkillDetailsInput;
}>;

export type EditSkillDetailsMutation = {
  __typename?: 'mutation_root';
  editSkillDetails?: {
    __typename?: 'EditSkillDetailsOutput';
    response: string;
  } | null;
};

export type DeleteSkillsMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteSkillsMutation = {
  __typename?: 'mutation_root';
  delete_sub_skills?: {
    __typename?: 'sub_skills_mutation_response';
    affected_rows: number;
  } | null;
  delete_skills_detail?: {
    __typename?: 'skills_detail_mutation_response';
    affected_rows: number;
  } | null;
  delete_list_Of_Skills?: {
    __typename?: 'list_Of_Skills_mutation_response';
    returning: Array<{ __typename?: 'list_Of_Skills'; id: any }>;
  } | null;
};

export type GetSkillDetailsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetSkillDetailsQuery = {
  __typename?: 'query_root';
  list_Of_Skills: Array<{
    __typename?: 'list_Of_Skills';
    id: any;
    name: string;
    skills_details: Array<{
      __typename?: 'skills_detail';
      rating?: number | null;
      description?: string | null;
    }>;
  }>;
};

export type ContactUsMutationVariables = Exact<{
  object?: InputMaybe<ContactUsInput>;
}>;

export type ContactUsMutation = {
  __typename?: 'mutation_root';
  contactUs: { __typename?: 'ContactUsOutput'; success: string };
};

export type GetManagerQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
  search?: InputMaybe<Scalars['String']>;
}>;

export type GetManagerQuery = {
  __typename?: 'query_root';
  users: Array<{ __typename?: 'users'; id: any; display_name?: string | null }>;
};

export type InsertDepartmentMutationVariables = Exact<{
  created_by: Scalars['uuid'];
  manager_id: Scalars['uuid'];
  organization_id: Scalars['uuid'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type InsertDepartmentMutation = {
  __typename?: 'mutation_root';
  insert_department_one?: { __typename?: 'department'; id: any } | null;
};

export type GetDepartmentQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
}>;

export type GetDepartmentQuery = {
  __typename?: 'query_root';
  department: Array<{
    __typename?: 'department';
    id: any;
    name: string;
    manager_user?: {
      __typename?: 'users';
      display_name?: string | null;
      id: any;
    } | null;
    memberCount: {
      __typename?: 'department_employee_aggregate';
      aggregate?: {
        __typename?: 'department_employee_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetDepartmentEmployeeQueryVariables = Exact<{
  departmentID: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['uuid'];
}>;

export type GetDepartmentEmployeeQuery = {
  __typename?: 'query_root';
  department_employee: Array<{
    __typename?: 'department_employee';
    user: {
      __typename?: 'users';
      display_name?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
      account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
    };
  }>;
};

export type GetDepartmentEmployeeLengthQueryVariables = Exact<{
  departmentId: Scalars['uuid'];
  search?: InputMaybe<Scalars['String']>;
}>;

export type GetDepartmentEmployeeLengthQuery = {
  __typename?: 'query_root';
  department_employee_aggregate: {
    __typename?: 'department_employee_aggregate';
    aggregate?: {
      __typename?: 'department_employee_aggregate_fields';
      count: number;
    } | null;
  };
  department_employee: Array<{
    __typename?: 'department_employee';
    user_id: any;
  }>;
};

export type DeleteDepartmentMutationVariables = Exact<{
  departmentId: Scalars['uuid'];
}>;

export type DeleteDepartmentMutation = {
  __typename?: 'mutation_root';
  delete_department?: {
    __typename?: 'department_mutation_response';
    affected_rows: number;
  } | null;
};

export type EditDepartmentMutationVariables = Exact<{
  manager_id: Scalars['uuid'];
  name?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['uuid'];
}>;

export type EditDepartmentMutation = {
  __typename?: 'mutation_root';
  update_department?: {
    __typename?: 'department_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetOrganizationEmployeeWithFilterQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  search?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrganizationEmployeeWithFilterQuery = {
  __typename?: 'query_root';
  organization_employee: Array<{
    __typename?: 'organization_employee';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      last_sign_date_time?: any | null;
      account?: {
        __typename?: 'auth_accounts';
        active: boolean;
        email?: any | null;
      } | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
      department_employees: Array<{
        __typename?: 'department_employee';
        department: { __typename?: 'department'; id: any; name: string };
      }>;
    };
  }>;
  filtered_total: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
  active: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
  inactive: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetDeletedUsersOfOrganizationQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetDeletedUsersOfOrganizationQuery = {
  __typename?: 'query_root';
  organization_employee: Array<{
    __typename?: 'organization_employee';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      last_sign_date_time?: any | null;
      account?: {
        __typename?: 'auth_accounts';
        active: boolean;
        email?: any | null;
      } | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
      department_employees: Array<{
        __typename?: 'department_employee';
        department: { __typename?: 'department'; id: any; name: string };
      }>;
    };
  }>;
};

export type SaveEmployeeMutationVariables = Exact<{
  object: SaveEmployeeInput;
}>;

export type SaveEmployeeMutation = {
  __typename?: 'mutation_root';
  saveEmployee?: { __typename?: 'SaveEmployeeOutput'; userId: string } | null;
};

export type GetDepartmentByOrgIdForAdminQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  departmentText?: InputMaybe<Scalars['String']>;
}>;

export type GetDepartmentByOrgIdForAdminQuery = {
  __typename?: 'query_root';
  department: Array<{ __typename?: 'department'; id: any; name: string }>;
};

export type GetOrganizationRolesByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  roleText?: InputMaybe<Scalars['String']>;
}>;

export type GetOrganizationRolesByOrgIdQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    id: any;
    role_title: string;
  }>;
};

export type GetManagersByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  managerText?: InputMaybe<Scalars['String']>;
}>;

export type GetManagersByOrgIdQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    my_organization?: {
      __typename?: 'organization_user';
      role_title?: string | null;
    } | null;
  }>;
};

export type Frontend_GetUserByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type Frontend_GetUserByIdQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    last_sign_date_time?: any | null;
    ip_address?: any | null;
    account?: {
      __typename?: 'auth_accounts';
      email?: any | null;
      active: boolean;
    } | null;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    department_employees: Array<{
      __typename?: 'department_employee';
      department: { __typename?: 'department'; id: any; name: string };
    }>;
    organization_employees: Array<{
      __typename?: 'organization_employee';
      manager_id?: any | null;
    }>;
  } | null;
};

export type DeleteEmployeeMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type DeleteEmployeeMutation = {
  __typename?: 'mutation_root';
  deleteEmployee?: {
    __typename?: 'DeleteEmployeeOutput';
    success?: boolean | null;
  } | null;
};

export type CreateHolidayMutationVariables = Exact<{
  data:
    | Array<Organization_Holidays_Insert_Input>
    | Organization_Holidays_Insert_Input;
}>;

export type CreateHolidayMutation = {
  __typename?: 'mutation_root';
  insert_organization_holidays?: {
    __typename?: 'organization_holidays_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetHolidaysQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
}>;

export type GetHolidaysQuery = {
  __typename?: 'query_root';
  holidays: Array<{
    __typename?: 'organization_holidays';
    id: any;
    name: string;
    description?: string | null;
    date: any;
  }>;
};

export type UpdateHolidayMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: Organization_Holidays_Set_Input;
}>;

export type UpdateHolidayMutation = {
  __typename?: 'mutation_root';
  holiday?: { __typename?: 'organization_holidays'; id: any } | null;
};

export type DeleteHolidayMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteHolidayMutation = {
  __typename?: 'mutation_root';
  holiday?: { __typename?: 'organization_holidays'; id: any } | null;
};

export type GetOrganizationLocationsDetailsQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrganizationLocationsDetailsQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    id: any;
    title: string;
    total_seats: number;
    timezone_name: string;
    address: {
      __typename?: 'address';
      id: any;
      address_line: string;
      additional_fields?: any | null;
      state: string;
      country: string;
      pin_code: string;
      landmark?: string | null;
    };
  }>;
  organization_locations_aggregate: {
    __typename?: 'organization_locations_aggregate';
    aggregate?: {
      __typename?: 'organization_locations_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetOrganizationLocationsTitleQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
  title?: InputMaybe<Scalars['String']>;
}>;

export type GetOrganizationLocationsTitleQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    id: any;
    title: string;
  }>;
};

export type UpdateOrganizationLocationMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: Organization_Locations_Set_Input;
}>;

export type UpdateOrganizationLocationMutation = {
  __typename?: 'mutation_root';
  update_organization_locations_by_pk?: {
    __typename?: 'organization_locations';
    id: any;
  } | null;
};

export type UpdateAddressMutationVariables = Exact<{
  data: Address_Set_Input;
  id: Scalars['uuid'];
}>;

export type UpdateAddressMutation = {
  __typename?: 'mutation_root';
  update_address_by_pk?: { __typename?: 'address'; id: any } | null;
};

export type GetLocationBookingsQueryVariables = Exact<{
  date: Scalars['date'];
  organizationId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;

export type GetLocationBookingsQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    total_seats: number;
    booked_count: {
      __typename?: 'hotdesk_bookings_aggregate';
      aggregate?: {
        __typename?: 'hotdesk_bookings_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
  hotdesk_bookings: Array<{
    __typename?: 'hotdesk_bookings';
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  }>;
};

export type GetOrganizationLocationByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetOrganizationLocationByIdQuery = {
  __typename?: 'query_root';
  location?: {
    __typename?: 'organization_locations';
    id: any;
    title: string;
    total_seats: number;
    timezone_name: string;
    address: {
      __typename?: 'address';
      id: any;
      address_line: string;
      additional_fields?: any | null;
      state: string;
      country: string;
      pin_code: string;
      landmark?: string | null;
    };
  } | null;
};

export type DeleteOrgLocationMutationVariables = Exact<{
  orgId: Scalars['String'];
  locationId: Scalars['String'];
}>;

export type DeleteOrgLocationMutation = {
  __typename?: 'mutation_root';
  deleteOrgLocation?: {
    __typename?: 'DeleteOrgLocationOutput';
    status?: number | null;
    message?: string | null;
  } | null;
};

export type CreateLeaveCycleMutationVariables = Exact<{
  org_id: Scalars['uuid'];
  starting_date: Scalars['String'];
}>;

export type CreateLeaveCycleMutation = {
  __typename?: 'mutation_root';
  createLeaveCycle?: {
    __typename?: 'LeaveCycleOutput';
    leave_cycle_id: string;
  } | null;
};

export type GetLeaveCycleSubscriptionVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type GetLeaveCycleSubscription = {
  __typename?: 'subscription_root';
  organization_leave_cycles: Array<{
    __typename: 'organization_leave_cycles';
    starting_date?: string | null;
    ending_date?: string | null;
  }>;
};

export type AddNewLeaveTypeMutationVariables = Exact<{
  object: AddNewLeaveTypeInput;
}>;

export type AddNewLeaveTypeMutation = {
  __typename?: 'mutation_root';
  addNewLeaveType?: {
    __typename?: 'AddNewLeaveTypeOutput';
    new_leave_type_id?: string | null;
  } | null;
};

export type UpdateLeaveTypeByIdMutationVariables = Exact<{
  object: EditLeaveTypeInput;
}>;

export type UpdateLeaveTypeByIdMutation = {
  __typename?: 'mutation_root';
  editLeaveType?: {
    __typename?: 'EditLeaveTypeOutput';
    leave_type_id: string;
  } | null;
};

export type DeleteOrgActiveLeaveTypeMutationVariables = Exact<{
  object: EditLeaveTypeInput;
}>;

export type DeleteOrgActiveLeaveTypeMutation = {
  __typename?: 'mutation_root';
  editLeaveType?: {
    __typename?: 'EditLeaveTypeOutput';
    leave_type_id: string;
  } | null;
};

export type ApplyForLeaveMutationVariables = Exact<{
  org_id: Scalars['String'];
  leave_type_id: Scalars['String'];
  leave_start_date?: InputMaybe<Scalars['String']>;
  leave_end_date?: InputMaybe<Scalars['String']>;
  leave_dates:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
}>;

export type ApplyForLeaveMutation = {
  __typename?: 'mutation_root';
  submitLeave: {
    __typename?: 'SubmitLeaveOutput';
    new_employee_leave_id: string;
  };
};

export type GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscriptionVariables =
  Exact<{
    orgId: Scalars['uuid'];
    starting_date: Scalars['String'];
  }>;

export type GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription = {
  __typename?: 'subscription_root';
  organization_leave_cycles: Array<{
    __typename?: 'organization_leave_cycles';
    id: any;
  }>;
};

export type GetEmployeeLeaveApplicationByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetEmployeeLeaveApplicationByIdSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_application_by_pk?: {
    __typename?: 'organization_user_leave_application';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    leave_type: string;
    is_approved: boolean;
    leave_start_date?: string | null;
    leave_end_date?: string | null;
    leave_application_status: Enum_Leave_Status_Type_Enum;
    leave_type_id?: any | null;
    leaveDates: Array<{
      __typename?: 'organization_user_leave_dates';
      id: any;
      created_at: any;
      updated_at: any;
      leave_application_id: any;
      date: string;
    }>;
    user_leave_data?: {
      __typename?: 'organization_user_leave_data';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      user_role: string;
      leave_type?: string | null;
      leave_type_id?: any | null;
      earned_leaves_amount?: any | null;
    } | null;
  } | null;
};

export type GetOrgActiveLeaveTypesSubscriptionVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type GetOrgActiveLeaveTypesSubscription = {
  __typename?: 'subscription_root';
  organization_active_leave_types_aggregate: {
    __typename?: 'organization_active_leave_types_aggregate';
    nodes: Array<{
      __typename?: 'organization_active_leave_types';
      id: any;
      annual_eligibility: number;
      leave_type: string;
      monthly_earning_amount?: any | null;
      annual_carry_forward_amount?: number | null;
      limit_of_days_at_once?: number | null;
    }>;
    aggregate?: {
      __typename?: 'organization_active_leave_types_aggregate_fields';
      sum?: {
        __typename?: 'organization_active_leave_types_sum_fields';
        annual_eligibility?: number | null;
      } | null;
    } | null;
  };
};

export type GetLeaveTypeByIdSubscriptionVariables = Exact<{
  edit_id: Scalars['uuid'];
}>;

export type GetLeaveTypeByIdSubscription = {
  __typename?: 'subscription_root';
  organization_active_leave_types_by_pk?: {
    __typename?: 'organization_active_leave_types';
    annual_carry_forward_amount?: number | null;
    leave_type: string;
    annual_eligibility: number;
    monthly_earning_amount?: any | null;
    limit_of_days_at_once?: number | null;
    will_carry_forward: boolean;
    will_be_earned_monthly: boolean;
  } | null;
};

export type GetUserLeavesDataSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserLeavesDataSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
  }>;
};

export type GetLeaveDataOfOrganizationByLeaveIdSubscriptionVariables = Exact<{
  leave_cycle_id: Scalars['uuid'];
}>;

export type GetLeaveDataOfOrganizationByLeaveIdSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    user_role: string;
    leave_type?: string | null;
    leave_type_id?: any | null;
    earned_leaves_amount?: any | null;
    used_leave_amount: number;
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
      organization_leave_cycle?: {
        __typename?: 'organization_leave_cycles';
        id: any;
        starting_date?: string | null;
        ending_date?: string | null;
        current_term_years?: string | null;
      } | null;
    } | null;
    leave_applications: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
    }>;
  }>;
};

export type GetOrganizationLeaveDataQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  organizationId: Scalars['uuid'];
}>;

export type GetOrganizationLeaveDataQuery = {
  __typename?: 'query_root';
  organization_user_leave_dates: Array<{
    __typename?: 'organization_user_leave_dates';
    date: string;
    leave_application?: {
      __typename?: 'organization_user_leave_application';
      leave_type: string;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_reason?: string | null;
      user: {
        __typename?: 'users';
        first_name?: string | null;
        last_name?: string | null;
      };
    } | null;
  }>;
};

export type LoginMutationVariables = Exact<{
  object: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'mutation_root';
  login?: {
    __typename?: 'LoginOutput';
    user_id?: any | null;
    jwt_token: string;
    jwt_expires_in: number;
    refresh_token: string;
    setup_complete?: boolean | null;
  } | null;
};

export type InsertOrganizationMutationVariables = Exact<{
  object: Organization_Insert_Input;
}>;

export type InsertOrganizationMutation = {
  __typename?: 'mutation_root';
  insert_organization_one?: { __typename?: 'organization'; id: any } | null;
};

export type InsertOrganizationAdminMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  object: Organization_Admin_Insert_Input;
  default_role?: InputMaybe<Scalars['String']>;
}>;

export type InsertOrganizationAdminMutation = {
  __typename?: 'mutation_root';
  insert_organization_admin_one?: {
    __typename?: 'organization_admin';
    id: any;
  } | null;
  update_auth_accounts?: {
    __typename?: 'auth_accounts_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertAddressMutationVariables = Exact<{
  object: Address_Insert_Input;
}>;

export type InsertAddressMutation = {
  __typename?: 'mutation_root';
  insert_address_one?: { __typename?: 'address'; id: any } | null;
};

export type CreateDepartmentsMutationVariables = Exact<{
  object: Array<Department_Insert_Input> | Department_Insert_Input;
}>;

export type CreateDepartmentsMutation = {
  __typename?: 'mutation_root';
  insert_department?: {
    __typename?: 'department_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertOrganizationLocationMutationVariables = Exact<{
  object: Organization_Locations_Insert_Input;
}>;

export type InsertOrganizationLocationMutation = {
  __typename?: 'mutation_root';
  insert_organization_locations_one?: {
    __typename?: 'organization_locations';
    id: any;
  } | null;
};

export type CreateOrganizationMutationVariables = Exact<{
  object: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'mutation_root';
  createOrganization?: {
    __typename?: 'createOrganizationOutput';
    organizationId: any;
  } | null;
};

export type GetTimePeriodListQueryVariables = Exact<{
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type GetTimePeriodListQuery = {
  __typename?: 'query_root';
  time_period: Array<{
    __typename?: 'time_period';
    datePeriod: string;
    timePeriod: string;
  }>;
};

export type GetPerformanceReviewTableDataQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['String']>;
}>;

export type GetPerformanceReviewTableDataQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
    frequency: string;
    year: string;
    instruction: string;
    organization_Id: any;
    startDate?: string | null;
    endDate?: string | null;
    name?: string | null;
    org_goal_share?: string | null;
    copy_previews_performance_goals?: boolean | null;
    self_review?: string | null;
    manager_review?: string | null;
    peer_review?: string | null;
    view_option?: boolean | null;
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      action: string;
      dueDate: any;
      id: any;
      startDate: any;
      timePeriod: string;
      status: string;
      startingDate?: string | null;
      endingDate?: string | null;
      title: string;
      year: string;
      preformance_Id: any;
      viewScoreOption?: string | null;
      performance_review_setting_GoalsLists_aggregate: {
        __typename?: 'performance_review_setting_GoalsList_aggregate';
        aggregate?: {
          __typename?: 'performance_review_setting_GoalsList_aggregate_fields';
          count: number;
        } | null;
      };
    }>;
    performanceReviewStatuses_aggregate: {
      __typename?: 'performanceReviewStatus_aggregate';
      aggregate?: {
        __typename?: 'performanceReviewStatus_aggregate_fields';
        count: number;
      } | null;
    };
    organization: {
      __typename?: 'organization';
      organization_employees_aggregate: {
        __typename?: 'organization_employee_aggregate';
        aggregate?: {
          __typename?: 'organization_employee_aggregate_fields';
          count: number;
        } | null;
      };
    };
    performance_rating_scales: Array<{
      __typename?: 'performance_rating_scale';
      id: any;
      name?: string | null;
      rating?: number | null;
    }>;
  }>;
};

export type CreatePerformanceReviewMutationVariables = Exact<{
  object: PerformanceReviewInput;
}>;

export type CreatePerformanceReviewMutation = {
  __typename?: 'mutation_root';
  createPerformanceReview?: {
    __typename?: 'PerformanceReviewOutput';
    id: any;
  } | null;
};

export type UpdatePerformanceReviewMutationVariables = Exact<{
  object: UpdatePerofrmanceReviewInput;
}>;

export type UpdatePerformanceReviewMutation = {
  __typename?: 'mutation_root';
  updatePerformanceReview?: {
    __typename?: 'UpdatePerofrmanceReviewOutput';
    id: any;
  } | null;
};

export type FinancialYearValueQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  todaysDate: Scalars['String'];
}>;

export type FinancialYearValueQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    startDate?: string | null;
    endDate?: string | null;
    year: string;
  }>;
};

export type GetGoalNoPerRolesMutationVariables = Exact<{
  object: GoalCountPerRolesInput;
}>;

export type GetGoalNoPerRolesMutation = {
  __typename?: 'mutation_root';
  getGoalNoPerRoles?: {
    __typename?: 'GoalCountPerRolesOutput';
    response: Array<{
      __typename?: 'GoalDetailPerRoles';
      roleTitle?: string | null;
      count?: number | null;
      managerId?: any | null;
      roleId?: any | null;
    } | null>;
  } | null;
};

export type PerformanceReviewNotificationForManagerMutationVariables = Exact<{
  object: PerformanceReviewNotifyInput;
}>;

export type PerformanceReviewNotificationForManagerMutation = {
  __typename?: 'mutation_root';
  performanceReviewNotification?: {
    __typename?: 'PerformanceReviewNotifyOutput';
    response: string;
  } | null;
};

export type Get_ListingYear_Org_AdminQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_ListingYear_Org_AdminQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    year: string;
  }>;
};

export type Get_TimePeriod_ListMutationVariables = Exact<{
  object: TimePeriodListInput;
}>;

export type Get_TimePeriod_ListMutation = {
  __typename?: 'mutation_root';
  getTimePeriodList?: {
    __typename?: 'TimePeriodListOutput';
    response: Array<{
      __typename?: 'timePeriodRespList';
      timePeriod?: string | null;
      year?: string | null;
    } | null>;
  } | null;
};

export type CheckingPerformanceReviewStatusQueryVariables = Exact<{
  _eq1: Scalars['uuid'];
  _eq: Scalars['uuid'];
}>;

export type CheckingPerformanceReviewStatusQuery = {
  __typename?: 'query_root';
  performanceReviewStatus_aggregate: {
    __typename?: 'performanceReviewStatus_aggregate';
    aggregate?: {
      __typename?: 'performanceReviewStatus_aggregate_fields';
      count: number;
    } | null;
  };
};

export type CheckGoalCountMutationVariables = Exact<{
  object: GoalCountPerTimeperiodInput;
}>;

export type CheckGoalCountMutation = {
  __typename?: 'mutation_root';
  checkGoalCount?: {
    __typename?: 'goalCountPerTimeperiodOutput';
    response: string;
  } | null;
};

export type LoadPerformanceReviewMutationVariables = Exact<{
  object: LoadPerformanceReviewInput;
}>;

export type LoadPerformanceReviewMutation = {
  __typename?: 'mutation_root';
  loadPerformanceReview?: {
    __typename?: 'LoadPerformanceReviewOutput';
    response?: Array<{
      __typename?: 'List_Of_Performance_Review';
      id?: any | null;
      name?: string | null;
      duration?: string | null;
      year?: string | null;
      status?: string | null;
      period?: Array<{
        __typename?: 'List_Of_TimePeriod';
        id?: any | null;
        title?: any | null;
        timePeriod?: string | null;
        startDate?: string | null;
        dueDate?: string | null;
        startingDate?: string | null;
        endingDate?: string | null;
        status?: string | null;
        action?: string | null;
        goalDetails?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type PreviousYearTimePeriodQueryVariables = Exact<{
  org_id: Scalars['uuid'];
  year: Scalars['String'];
}>;

export type PreviousYearTimePeriodQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      timePeriod: string;
    }>;
  }>;
};

export type InsertUserProfileMutationVariables = Exact<{
  object: Profile_Insert_Input;
}>;

export type InsertUserProfileMutation = {
  __typename?: 'mutation_root';
  insert_profile_one?: { __typename?: 'profile'; id: any } | null;
};

export type CreateOrganizationOwnerMutationVariables = Exact<{
  profile: ProfileInput;
  user: UserInput;
}>;

export type CreateOrganizationOwnerMutation = {
  __typename?: 'mutation_root';
  createOrganizationOwner?: {
    __typename?: 'createOrganizationOwnerOutput';
    userId: any;
  } | null;
};

export type InsertOrganizationRoleMutationVariables = Exact<{
  roleTitle: Scalars['String'];
  systemRole: Scalars['String'];
  orgId: Scalars['uuid'];
}>;

export type InsertOrganizationRoleMutation = {
  __typename?: 'mutation_root';
  insert_organization_roles_one?: {
    __typename?: 'organization_roles';
    id: any;
  } | null;
};

export type GetOrganizationRolesQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  roleTitle?: InputMaybe<Scalars['String']>;
}>;

export type GetOrganizationRolesQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    id: any;
    role_title: string;
    system_role: string;
  }>;
  totalFiltered: {
    __typename?: 'organization_roles_aggregate';
    aggregate?: {
      __typename?: 'organization_roles_aggregate_fields';
      count: number;
    } | null;
  };
};

export type DeletRoleMutationVariables = Exact<{
  role_id: Scalars['String'];
}>;

export type DeletRoleMutation = {
  __typename?: 'mutation_root';
  deleteOrganizationRole?: {
    __typename?: 'DeleteOrgRoleOutput';
    message?: string | null;
    status?: number | null;
  } | null;
};

export type GetOrganizationAdminsByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;

export type GetOrganizationAdminsByOrganizationIdQuery = {
  __typename?: 'query_root';
  organization_admin: Array<{
    __typename?: 'organization_admin';
    id: any;
    is_owner: boolean;
    userByUserId: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
    };
  }>;
  organization_admin_aggregate: {
    __typename?: 'organization_admin_aggregate';
    aggregate?: {
      __typename?: 'organization_admin_aggregate_fields';
      count: number;
    } | null;
  };
};

export type DeleteOrganizationAdminMutationVariables = Exact<{
  object: DeleteOrgAdminInput;
}>;

export type DeleteOrganizationAdminMutation = {
  __typename?: 'mutation_root';
  deleteOrgAdmin?: {
    __typename?: 'DeleteOrgAdminOutput';
    message?: string | null;
    ok?: boolean | null;
    status?: number | null;
  } | null;
};

export type TransferOrganizationOwnershipMutationVariables = Exact<{
  object: TransferOwnershipInput;
}>;

export type TransferOrganizationOwnershipMutation = {
  __typename?: 'mutation_root';
  transferOwnership?: {
    __typename?: 'TransferOwnershipOutput';
    message?: string | null;
    ok?: boolean | null;
    status?: number | null;
  } | null;
};

export type SaveOrganizationAdminMutationVariables = Exact<{
  object: SaveOrganizationAdminInput;
}>;

export type SaveOrganizationAdminMutation = {
  __typename?: 'mutation_root';
  saveOrganizationAdmin: {
    __typename?: 'SaveOrganizationAdminOutput';
    userId: any;
  };
};

export type GetOrganizationDetailsQueryVariables = Exact<{
  organization_id: Scalars['uuid'];
}>;

export type GetOrganizationDetailsQuery = {
  __typename?: 'query_root';
  organization: Array<{
    __typename?: 'organization';
    anniversary_date?: any | null;
    organization_name: string;
    working_days: number;
    working_hours: number;
    holiday_file_id?: any | null;
    logo_file_id?: any | null;
    org_end_time: any;
    org_start_time: any;
    timezone_name: string;
    address?: {
      __typename?: 'address';
      id: any;
      address_line: string;
      country: string;
      pin_code: string;
      state: string;
    } | null;
    organization_employees_aggregate: {
      __typename?: 'organization_employee_aggregate';
      aggregate?: {
        __typename?: 'organization_employee_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type UpdateAddressDetailsMutationVariables = Exact<{
  data: Address_Set_Input;
  addressID: Scalars['uuid'];
}>;

export type UpdateAddressDetailsMutation = {
  __typename?: 'mutation_root';
  update_address?: {
    __typename?: 'address_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateOrganizationDetailsMutationVariables = Exact<{
  data: Organization_Set_Input;
  organizationID: Scalars['uuid'];
}>;

export type UpdateOrganizationDetailsMutation = {
  __typename?: 'mutation_root';
  update_organization?: {
    __typename?: 'organization_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetProjectsByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  projectTitle?: InputMaybe<Scalars['String']>;
}>;

export type GetProjectsByOrganizationIdQuery = {
  __typename?: 'query_root';
  project: Array<{
    __typename?: 'project';
    id: any;
    title: string;
    description?: string | null;
    is_billable: boolean;
    project_client: {
      __typename?: 'project_client';
      id: any;
      client_name: string;
    };
  }>;
  project_aggregate: {
    __typename?: 'project_aggregate';
    aggregate?: {
      __typename?: 'project_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetClientsByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  clientName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetClientsByOrganizationIdQuery = {
  __typename?: 'query_root';
  project_client: Array<{
    __typename?: 'project_client';
    id: any;
    client_name: string;
  }>;
  project_client_aggregate: {
    __typename?: 'project_client_aggregate';
    aggregate?: {
      __typename?: 'project_client_aggregate_fields';
      count: number;
    } | null;
  };
};

export type CreateProjectByAdminMutationVariables = Exact<{
  client: ClientInput;
  project: ProjectInput;
}>;

export type CreateProjectByAdminMutation = {
  __typename?: 'mutation_root';
  createProject?: { __typename?: 'CreateProjectOutput'; projectId: any } | null;
};

export type CreateClientMutationVariables = Exact<{
  object: Project_Client_Insert_Input;
}>;

export type CreateClientMutation = {
  __typename?: 'mutation_root';
  insert_project_client_one?: { __typename?: 'project_client'; id: any } | null;
};

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  data: Project_Set_Input;
}>;

export type UpdateProjectMutation = {
  __typename?: 'mutation_root';
  update_project_by_pk?: { __typename?: 'project'; id: any } | null;
};

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'mutation_root';
  deleteProject?: { __typename?: 'deleteProjectOutput'; status: string } | null;
};

export type UpdateClientMutationVariables = Exact<{
  clientId: Scalars['uuid'];
  data: Project_Client_Set_Input;
}>;

export type UpdateClientMutation = {
  __typename?: 'mutation_root';
  update_project_client_by_pk?: {
    __typename?: 'project_client';
    id: any;
  } | null;
};

export type DeleteClientMutationVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type DeleteClientMutation = {
  __typename?: 'mutation_root';
  delete_project_client_by_pk?: {
    __typename?: 'project_client';
    id: any;
  } | null;
};

export type DeleteClientSoftMutationVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type DeleteClientSoftMutation = {
  __typename?: 'mutation_root';
  update_project_client_by_pk?: {
    __typename?: 'project_client';
    id: any;
  } | null;
};

export type CreateTriningTypeOrgAdminMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Training_Type_For_Training_Insert_Input>
    | Training_Type_For_Training_Insert_Input
  >;
}>;

export type CreateTriningTypeOrgAdminMutation = {
  __typename?: 'mutation_root';
  insert_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export type GetTrainingTypeOrgAdminQueryVariables = Exact<{
  trainingType?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  _eq: Scalars['uuid'];
}>;

export type GetTrainingTypeOrgAdminQuery = {
  __typename?: 'query_root';
  training_type_for_training: Array<{
    __typename?: 'training_type_for_training';
    name: string;
    status: boolean;
    id: any;
    user_id: any;
    meta_tags?: string | null;
    user: { __typename?: 'users'; first_name?: string | null };
  }>;
  training_type_for_training_aggregate: {
    __typename?: 'training_type_for_training_aggregate';
    aggregate?: {
      __typename?: 'training_type_for_training_aggregate_fields';
      count: number;
    } | null;
  };
};

export type DeleteTrainingTypeOrgAdminMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteTrainingTypeOrgAdminMutation = {
  __typename?: 'mutation_root';
  delete_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export type EditTrainingTypeOrgAdminMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  status: Scalars['Boolean'];
}>;

export type EditTrainingTypeOrgAdminMutation = {
  __typename?: 'mutation_root';
  update_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export type GetOrganizationByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetOrganizationByUserIdQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    organization?: {
      __typename?: 'organization';
      id: any;
      owned_by: any;
    } | null;
  }>;
};

export type InsertStorageFileMutationVariables = Exact<{
  object: Storage_Files_Insert_Input;
}>;

export type InsertStorageFileMutation = {
  __typename?: 'mutation_root';
  insert_storage_files_one?: { __typename?: 'storage_files'; id: any } | null;
};

export type SuperAdminTableDataSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SuperAdminTableDataSubscription = {
  __typename?: 'subscription_root';
  organization: Array<{
    __typename?: 'organization';
    organization_name: string;
    created_at: any;
    address?: { __typename?: 'address'; address_line: string } | null;
    organization_employees_aggregate: {
      __typename?: 'organization_employee_aggregate';
      aggregate?: {
        __typename?: 'organization_employee_aggregate_fields';
        count: number;
      } | null;
    };
    organization_admins: Array<{
      __typename?: 'organization_admin';
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
        profile: Array<{ __typename?: 'profile'; id: any }>;
      } | null;
    }>;
  }>;
};

export type CreateAccountRoleMutationVariables = Exact<{
  object: Auth_Account_Roles_Insert_Input;
}>;

export type CreateAccountRoleMutation = {
  __typename?: 'mutation_root';
  insert_auth_account_roles_one?: {
    __typename?: 'auth_account_roles';
    role: string;
    account_id: any;
  } | null;
};

export type DeleteAccountRoleMutationVariables = Exact<{
  account_id: Scalars['uuid'];
  role: Scalars['String'];
}>;

export type DeleteAccountRoleMutation = {
  __typename?: 'mutation_root';
  delete_auth_account_roles?: {
    __typename?: 'auth_account_roles_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateAccountByUserIdMutationVariables = Exact<{
  userId: Scalars['uuid'];
  object: Auth_Accounts_Set_Input;
}>;

export type UpdateAccountByUserIdMutation = {
  __typename?: 'mutation_root';
  update_auth_accounts?: {
    __typename?: 'auth_accounts_mutation_response';
    returning: Array<{
      __typename?: 'auth_accounts';
      active: boolean;
      email?: any | null;
    }>;
  } | null;
};

export type GetAccountByUserIdQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;

export type GetAccountByUserIdQuery = {
  __typename?: 'query_root';
  auth_accounts: Array<{
    __typename?: 'auth_accounts';
    email?: any | null;
    id: any;
    created_at: any;
    updated_at: any;
    user_id: any;
    active: boolean;
    new_email?: any | null;
    password_hash?: string | null;
    default_role: string;
    is_anonymous: boolean;
    custom_register_data?: any | null;
    otp_secret?: string | null;
    mfa_enabled: boolean;
    ticket: any;
    ticket_expires_at: any;
  }>;
};

export type GetAccountByTicketQueryVariables = Exact<{
  ticket: Scalars['uuid'];
}>;

export type GetAccountByTicketQuery = {
  __typename?: 'query_root';
  auth_accounts: Array<{
    __typename?: 'auth_accounts';
    email?: any | null;
    id: any;
    created_at: any;
    updated_at: any;
    user_id: any;
    active: boolean;
    new_email?: any | null;
    password_hash?: string | null;
    default_role: string;
    is_anonymous: boolean;
    custom_register_data?: any | null;
    otp_secret?: string | null;
    mfa_enabled: boolean;
    ticket: any;
    ticket_expires_at: any;
  }>;
};

export type CreateAccountMutationVariables = Exact<{
  object: Auth_Accounts_Insert_Input;
}>;

export type CreateAccountMutation = {
  __typename?: 'mutation_root';
  insert_auth_accounts_one?: {
    __typename?: 'auth_accounts';
    id: any;
    created_at: any;
    updated_at: any;
    user_id: any;
    active: boolean;
    email?: any | null;
    new_email?: any | null;
    password_hash?: string | null;
    default_role: string;
    is_anonymous: boolean;
    custom_register_data?: any | null;
    otp_secret?: string | null;
    mfa_enabled: boolean;
    ticket: any;
    ticket_expires_at: any;
  } | null;
};

export type GetAccountIdByEmailPatternQueryVariables = Exact<{
  emailPattern: Scalars['citext'];
}>;

export type GetAccountIdByEmailPatternQuery = {
  __typename?: 'query_root';
  auth_accounts: Array<{ __typename?: 'auth_accounts'; id: any }>;
};

export type CreateRefreshTokenMutationVariables = Exact<{
  object: Auth_Refresh_Tokens_Insert_Input;
}>;

export type CreateRefreshTokenMutation = {
  __typename?: 'mutation_root';
  insert_auth_refresh_tokens_one?: {
    __typename?: 'auth_refresh_tokens';
    account_id: any;
    expires_at: any;
    refresh_token: any;
  } | null;
};

export type DeleteRefreshTokenByAccountIdMutationVariables = Exact<{
  accountId: Scalars['uuid'];
}>;

export type DeleteRefreshTokenByAccountIdMutation = {
  __typename?: 'mutation_root';
  delete_auth_refresh_tokens?: {
    __typename?: 'auth_refresh_tokens_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteRefreshTokenByTokenMutationVariables = Exact<{
  token: Scalars['uuid'];
}>;

export type DeleteRefreshTokenByTokenMutation = {
  __typename?: 'mutation_root';
  delete_auth_refresh_tokens_by_pk?: {
    __typename?: 'auth_refresh_tokens';
    refresh_token: any;
    created_at: any;
    account_id: any;
  } | null;
};

export type CreateNewAssessmentMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Assessment_Insert_Input> | Assessment_Insert_Input
  >;
}>;

export type CreateNewAssessmentMutation = {
  __typename?: 'mutation_root';
  insert_assessment?: {
    __typename?: 'assessment_mutation_response';
    returning: Array<{ __typename?: 'assessment'; id: any }>;
  } | null;
};

export type UpdateNewAssessmentMutationVariables = Exact<{
  _set?: InputMaybe<Assessment_Set_Input>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type UpdateNewAssessmentMutation = {
  __typename?: 'mutation_root';
  update_assessment?: {
    __typename?: 'assessment_mutation_response';
    returning: Array<{ __typename?: 'assessment'; id: any }>;
  } | null;
};

export type Load_Single_QuestionQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Load_Single_QuestionQuery = {
  __typename?: 'query_root';
  questions_aggregate: {
    __typename?: 'questions_aggregate';
    aggregate?: {
      __typename?: 'questions_aggregate_fields';
      count: number;
    } | null;
  };
};

export type Delete_Question_OptionsMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Delete_Question_OptionsMutation = {
  __typename?: 'mutation_root';
  delete_question_options?: {
    __typename?: 'question_options_mutation_response';
    affected_rows: number;
  } | null;
};

export type Insert_QuestionsMutationVariables = Exact<{
  object?: InputMaybe<Questions_Insert_Input>;
}>;

export type Insert_QuestionsMutation = {
  __typename?: 'mutation_root';
  insert_questions_one?: { __typename?: 'questions'; id: any } | null;
};

export type Insert_Question_OptionsMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Question_Options_Insert_Input> | Question_Options_Insert_Input
  >;
}>;

export type Insert_Question_OptionsMutation = {
  __typename?: 'mutation_root';
  insert_question_options?: {
    __typename?: 'question_options_mutation_response';
    returning: Array<{ __typename?: 'question_options'; id: any }>;
  } | null;
};

export type Update_QuestionMutationVariables = Exact<{
  _set?: InputMaybe<Questions_Set_Input>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Update_QuestionMutation = {
  __typename?: 'mutation_root';
  update_questions?: {
    __typename?: 'questions_mutation_response';
    returning: Array<{ __typename?: 'questions'; id: any }>;
  } | null;
};

export type LoadAssessmentQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']>;
  roleId: Scalars['uuid'];
}>;

export type LoadAssessmentQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    name: string;
    id: any;
    recommended_training?: string | null;
    type: string;
    passing_score: any;
    active_till: number;
    created_at: any;
    questions: Array<{ __typename?: 'questions'; id: any }>;
    assessment_records: Array<{
      __typename?: 'assessment_record';
      user: {
        __typename?: 'users';
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
    assessment_records_aggregate: {
      __typename?: 'assessment_record_aggregate';
      aggregate?: {
        __typename?: 'assessment_record_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type AssessmentMarksQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type AssessmentMarksQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      sum?: {
        __typename?: 'assessment_record_sum_fields';
        marks?: number | null;
      } | null;
    } | null;
  };
};

export type UserCompletedStatusQueryVariables = Exact<{
  userId: Scalars['uuid'];
  assessmentId: Scalars['uuid'];
}>;

export type UserCompletedStatusQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{ __typename?: 'assessment_record'; id: any }>;
};

export type UserCreatedDateQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type UserCreatedDateQuery = {
  __typename?: 'query_root';
  users: Array<{ __typename?: 'users'; created_at: any }>;
};

export type GetAttendedRecordQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  id: Scalars['uuid'];
}>;

export type GetAttendedRecordQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{ __typename?: 'assessment_record'; id: any }>;
};

export type DeletePreviousRecordOfSkillAssessmentMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type DeletePreviousRecordOfSkillAssessmentMutation = {
  __typename?: 'mutation_root';
  delete_assessment_record?: {
    __typename?: 'assessment_record_mutation_response';
    returning: Array<{ __typename?: 'assessment_record'; id: any }>;
  } | null;
};

export type AttendAssessmentMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Assessment_Record_Insert_Input> | Assessment_Record_Insert_Input
  >;
}>;

export type AttendAssessmentMutation = {
  __typename?: 'mutation_root';
  insert_assessment_record?: {
    __typename?: 'assessment_record_mutation_response';
    returning: Array<{ __typename?: 'assessment_record'; id: any }>;
  } | null;
};

export type GetCorrectAnswerQueryVariables = Exact<{
  quesId: Scalars['uuid'];
}>;

export type GetCorrectAnswerQuery = {
  __typename?: 'query_root';
  question_options: Array<{
    __typename?: 'question_options';
    id: any;
    name: string;
  }>;
};

export type LoadFeedbackQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type LoadFeedbackQuery = {
  __typename?: 'query_root';
  assessment_feedback_aggregate: {
    __typename?: 'assessment_feedback_aggregate';
    aggregate?: {
      __typename?: 'assessment_feedback_aggregate_fields';
      count: number;
      avg?: {
        __typename?: 'assessment_feedback_avg_fields';
        feedback?: number | null;
      } | null;
    } | null;
  };
};

export type LoadScoreCardQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type LoadScoreCardQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{
    __typename?: 'assessment_record';
    user: { __typename?: 'users'; display_name?: string | null; id: any };
    assessment: {
      __typename?: 'assessment';
      name: string;
      id: any;
      passing_score: any;
      department?: { __typename?: 'department'; name: string; id: any } | null;
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
        id: any;
      } | null;
    };
  }>;
};

export type LoadNoofTriesQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type LoadNoofTriesQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{ __typename?: 'assessment_record'; id: any }>;
};

export type TotalScoreQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type TotalScoreQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      sum?: {
        __typename?: 'assessment_record_sum_fields';
        marks?: number | null;
      } | null;
      avg?: {
        __typename?: 'assessment_record_avg_fields';
        marks?: number | null;
      } | null;
    } | null;
  };
};

export type GetRoleOfUserQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetRoleOfUserQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    role_title: string;
    id: any;
  }>;
};

export type GetAllAssessmentQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetAllAssessmentQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; id: any }>;
};

export type GetAttendedAssessmentQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetAttendedAssessmentQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{ __typename?: 'assessment_record'; id: any }>;
};

export type GetMarksOfAssessmentQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetMarksOfAssessmentQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      avg?: {
        __typename?: 'assessment_record_avg_fields';
        marks?: number | null;
      } | null;
      sum?: {
        __typename?: 'assessment_record_sum_fields';
        marks?: number | null;
      } | null;
    } | null;
  };
};

export type GetRoleOfUserBulkQueryVariables = Exact<{
  _in: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetRoleOfUserBulkQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    role_title: string;
    id: any;
  }>;
};

export type GetAllAssessmentBulkQueryVariables = Exact<{
  _in: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetAllAssessmentBulkQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; id: any }>;
};

export type GetAssesmentAttendedDataQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetAssesmentAttendedDataQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{
    __typename?: 'assessment_record';
    id: any;
    assessment_id: any;
  }>;
};

export type GetMarksOfAssessmentbulkQueryVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetMarksOfAssessmentbulkQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      avg?: {
        __typename?: 'assessment_record_avg_fields';
        marks?: number | null;
      } | null;
      sum?: {
        __typename?: 'assessment_record_sum_fields';
        marks?: number | null;
      } | null;
    } | null;
  };
};

export type Individual_Graph_DataQueryVariables = Exact<{
  _in: Array<Scalars['String']> | Scalars['String'];
  _eq: Scalars['uuid'];
}>;

export type Individual_Graph_DataQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    id: any;
    name: string;
    type: string;
    assessment_records_aggregate: {
      __typename?: 'assessment_record_aggregate';
      aggregate?: {
        __typename?: 'assessment_record_aggregate_fields';
        sum?: {
          __typename?: 'assessment_record_sum_fields';
          marks?: number | null;
        } | null;
      } | null;
    };
  }>;
};

export type UpdateoldRecordDataMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type UpdateoldRecordDataMutation = {
  __typename?: 'mutation_root';
  update_assessment_record?: {
    __typename?: 'assessment_record_mutation_response';
    returning: Array<{ __typename?: 'assessment_record'; id: any }>;
  } | null;
};

export type UpdateoldRecordDataOfSkillAssessmentMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type UpdateoldRecordDataOfSkillAssessmentMutation = {
  __typename?: 'mutation_root';
  update_assessment_record?: {
    __typename?: 'assessment_record_mutation_response';
    returning: Array<{ __typename?: 'assessment_record'; id: any }>;
  } | null;
};

export type GetRoleOfUserIndividualQueryVariables = Exact<{
  _in: Scalars['uuid'];
}>;

export type GetRoleOfUserIndividualQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    role_title: string;
    id: any;
  }>;
};

export type GetAllAssessmentIndividualQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetAllAssessmentIndividualQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; id: any }>;
};

export type GetMarksOfAssessmentIndividualQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetMarksOfAssessmentIndividualQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      avg?: {
        __typename?: 'assessment_record_avg_fields';
        marks?: number | null;
      } | null;
      sum?: {
        __typename?: 'assessment_record_sum_fields';
        marks?: number | null;
      } | null;
    } | null;
  };
};

export type UserIndiviudalProfileQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type UserIndiviudalProfileQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
  }>;
};

export type InsertAssessmentRoleDetailsMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Assessment_Role_Details_Insert_Input>
    | Assessment_Role_Details_Insert_Input
  >;
}>;

export type InsertAssessmentRoleDetailsMutation = {
  __typename?: 'mutation_root';
  insert_assessment_role_details?: {
    __typename?: 'assessment_role_details_mutation_response';
    returning: Array<{ __typename?: 'assessment_role_details'; id: any }>;
  } | null;
};

export type DeleteAssessmentRoleDetailsMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteAssessmentRoleDetailsMutation = {
  __typename?: 'mutation_root';
  delete_assessment_role_details?: {
    __typename?: 'assessment_role_details_mutation_response';
    returning: Array<{ __typename?: 'assessment_role_details'; id: any }>;
  } | null;
};

export type CreateOneSkillDetailMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Skills_Detail_Insert_Input> | Skills_Detail_Insert_Input
  >;
}>;

export type CreateOneSkillDetailMutation = {
  __typename?: 'mutation_root';
  insert_skills_detail?: {
    __typename?: 'skills_detail_mutation_response';
    returning: Array<{ __typename?: 'skills_detail'; id: any }>;
  } | null;
};

export type Edit_SkillsDetailsMutationVariables = Exact<{
  _set?: InputMaybe<Skills_Detail_Set_Input>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Edit_SkillsDetailsMutation = {
  __typename?: 'mutation_root';
  update_skills_detail?: {
    __typename?: 'skills_detail_mutation_response';
    affected_rows: number;
  } | null;
};

export type Delete_SkillDetailMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Delete_SkillDetailMutation = {
  __typename?: 'mutation_root';
  delete_skills_detail?: {
    __typename?: 'skills_detail_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateOneCompetencyMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Main_Skills_Insert_Input> | Main_Skills_Insert_Input
  >;
}>;

export type CreateOneCompetencyMutation = {
  __typename?: 'mutation_root';
  insert_main_skills?: {
    __typename?: 'main_skills_mutation_response';
    returning: Array<{ __typename?: 'main_skills'; id: any }>;
  } | null;
};

export type Edit_CompetencyMutationVariables = Exact<{
  _eq1: Scalars['uuid'];
  _set?: InputMaybe<Main_Skills_Set_Input>;
}>;

export type Edit_CompetencyMutation = {
  __typename?: 'mutation_root';
  update_main_skills?: {
    __typename?: 'main_skills_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateOneSkillsMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Sub_Skills_Insert_Input> | Sub_Skills_Insert_Input
  >;
}>;

export type CreateOneSkillsMutation = {
  __typename?: 'mutation_root';
  insert_sub_skills?: {
    __typename?: 'sub_skills_mutation_response';
    returning: Array<{ __typename?: 'sub_skills'; id: any }>;
  } | null;
};

export type Delete_SkillsMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Delete_SkillsMutation = {
  __typename?: 'mutation_root';
  delete_sub_skills?: {
    __typename?: 'sub_skills_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateSkillInListMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<List_Of_Skills_Insert_Input> | List_Of_Skills_Insert_Input
  >;
}>;

export type CreateSkillInListMutation = {
  __typename?: 'mutation_root';
  insert_list_Of_Skills?: {
    __typename?: 'list_Of_Skills_mutation_response';
    returning: Array<{ __typename?: 'list_Of_Skills'; id: any }>;
  } | null;
};

export type FindSkillsInCompetencyQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1?: InputMaybe<Scalars['uuid']>;
  _ilike: Scalars['String'];
}>;

export type FindSkillsInCompetencyQuery = {
  __typename?: 'query_root';
  main_skills: Array<{ __typename?: 'main_skills'; id: any }>;
};

export type Edit_SkillMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
}>;

export type Edit_SkillMutation = {
  __typename?: 'mutation_root';
  update_list_Of_Skills_by_pk?: {
    __typename?: 'list_Of_Skills';
    id: any;
  } | null;
};

export type SkillsUnderCompetencyQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type SkillsUnderCompetencyQuery = {
  __typename?: 'query_root';
  main_skills: Array<{
    __typename?: 'main_skills';
    id: any;
    name: string;
    sub_skills: Array<{
      __typename?: 'sub_skills';
      id: any;
      skill_Id?: any | null;
      list_Of_Skill?: {
        __typename?: 'list_Of_Skills';
        id: any;
        name: string;
        skills_details: Array<{
          __typename?: 'skills_detail';
          rating?: number | null;
          description?: string | null;
        }>;
      } | null;
    }>;
  }>;
};

export type UsersComptAndSkillsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type UsersComptAndSkillsQuery = {
  __typename?: 'query_root';
  user_Competency: Array<{
    __typename?: 'user_Competency';
    id: any;
    competencyId: any;
    user_Skills: Array<{
      __typename?: 'user_Skills';
      id: any;
      skill_Id: any;
      rating: number;
    }>;
  }>;
};

export type CreateUserCompetencyAndSkillsMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<User_Competency_Insert_Input> | User_Competency_Insert_Input
  >;
}>;

export type CreateUserCompetencyAndSkillsMutation = {
  __typename?: 'mutation_root';
  insert_user_Competency?: {
    __typename?: 'user_Competency_mutation_response';
    returning: Array<{ __typename?: 'user_Competency'; id: any }>;
  } | null;
};

export type EmployeesManagerIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type EmployeesManagerIdQuery = {
  __typename?: 'query_root';
  organization_employee: Array<{
    __typename?: 'organization_employee';
    manager_id?: any | null;
  }>;
};

export type CreateOneUserCompetencyMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<User_Competency_Insert_Input> | User_Competency_Insert_Input
  >;
}>;

export type CreateOneUserCompetencyMutation = {
  __typename?: 'mutation_root';
  insert_user_Competency?: {
    __typename?: 'user_Competency_mutation_response';
    returning: Array<{ __typename?: 'user_Competency'; id: any }>;
  } | null;
};

export type UserSkillDetailQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type UserSkillDetailQuery = {
  __typename?: 'query_root';
  user_Skills: Array<{
    __typename?: 'user_Skills';
    id: any;
    rating: number;
    status?: string | null;
    skill_Id: any;
    sub_skill: {
      __typename?: 'sub_skills';
      id: any;
      list_Of_Skill?: {
        __typename?: 'list_Of_Skills';
        name: string;
        skills_details: Array<{
          __typename?: 'skills_detail';
          rating?: number | null;
          description?: string | null;
        }>;
      } | null;
      main_skill: { __typename?: 'main_skills'; name: string };
    };
    user_Skills_Assessments: Array<{
      __typename?: 'user_Skills_Assessment';
      Status?: string | null;
      assessment?: {
        __typename?: 'assessment';
        id: any;
        name: string;
        passing_score: any;
        assessment_records: Array<{
          __typename?: 'assessment_record';
          total_marks?: number | null;
        }>;
      } | null;
    }>;
    user_Skills_Trainings: Array<{
      __typename?: 'user_Skills_Training';
      status?: string | null;
      training?: { __typename?: 'training'; id: any; name: string } | null;
    }>;
  }>;
  users: Array<{
    __typename?: 'users';
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
  }>;
};

export type UserDetailsWithProjectQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type UserDetailsWithProjectQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    project_members: Array<{
      __typename?: 'project_members';
      project: { __typename?: 'project'; title: string };
    }>;
  }>;
};

export type UserSkillDetailStatusQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type UserSkillDetailStatusQuery = {
  __typename?: 'query_root';
  user_Competency: Array<{
    __typename?: 'user_Competency';
    id: any;
    main_skill: { __typename?: 'main_skills'; id: any; name: string };
    user_Skills: Array<{
      __typename?: 'user_Skills';
      id: any;
      rating: number;
      status?: string | null;
      skill_Id: any;
      sub_skill: {
        __typename?: 'sub_skills';
        id: any;
        list_Of_Skill?: {
          __typename?: 'list_Of_Skills';
          name: string;
          skills_details: Array<{
            __typename?: 'skills_detail';
            rating?: number | null;
            description?: string | null;
          }>;
        } | null;
      };
      user_Skills_Assessments: Array<{
        __typename?: 'user_Skills_Assessment';
        id: any;
        Status?: string | null;
        assessment?: {
          __typename?: 'assessment';
          id: any;
          name: string;
        } | null;
      }>;
      user_Skills_Trainings: Array<{
        __typename?: 'user_Skills_Training';
        id: any;
        status?: string | null;
        training?: {
          __typename?: 'training';
          id: any;
          name: string;
          storage_file?: {
            __typename?: 'storage_files';
            id: any;
            file_url: string;
            type: Enum_Storage_File_Type_Enum;
            metadata: any;
          } | null;
          training_progress_records: Array<{
            __typename?: 'training_progress_record';
            docData: string;
            id: any;
            type: string;
            progress: string;
          }>;
          training_feedbacks_aggregate: {
            __typename?: 'training_feedback_aggregate';
            aggregate?: {
              __typename?: 'training_feedback_aggregate_fields';
              count: number;
              sum?: {
                __typename?: 'training_feedback_sum_fields';
                feedback?: any | null;
              } | null;
            } | null;
            nodes: Array<{
              __typename?: 'training_feedback';
              user: {
                __typename?: 'users';
                id: any;
                display_name?: string | null;
                profile: Array<{
                  __typename?: 'profile';
                  activity_status?: string | null;
                  avatar_file?: {
                    __typename?: 'storage_files';
                    file_url: string;
                  } | null;
                }>;
              };
            }>;
          };
        } | null;
      }>;
    }>;
  }>;
};

export type ListOfUserWithSkillUnderComptQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type ListOfUserWithSkillUnderComptQuery = {
  __typename?: 'query_root';
  main_skills: Array<{
    __typename?: 'main_skills';
    id: any;
    name: string;
    user_Competencies: Array<{
      __typename?: 'user_Competency';
      user: { __typename?: 'users'; id: any; display_name?: string | null };
      user_Skills: Array<{
        __typename?: 'user_Skills';
        rating: number;
        skill_Id: any;
        sub_skill: {
          __typename?: 'sub_skills';
          list_Of_Skill?: {
            __typename?: 'list_Of_Skills';
            name: string;
          } | null;
        };
      }>;
    }>;
  }>;
};

export type ListOfSkillsUnderComptInManagerDashbaordQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type ListOfSkillsUnderComptInManagerDashbaordQuery = {
  __typename?: 'query_root';
  main_skills: Array<{
    __typename?: 'main_skills';
    id: any;
    name: string;
    sub_skills: Array<{
      __typename?: 'sub_skills';
      id: any;
      list_Of_Skill?: {
        __typename?: 'list_Of_Skills';
        id: any;
        name: string;
      } | null;
    }>;
  }>;
};

export type ListOfEmployeesUnderOrgQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type ListOfEmployeesUnderOrgQuery = {
  __typename?: 'query_root';
  organization: Array<{
    __typename?: 'organization';
    organization_employees: Array<{
      __typename?: 'organization_employee';
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          organization_role_id?: any | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
  }>;
};

export type UserDetailOfProjectAndTasksQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type UserDetailOfProjectAndTasksQuery = {
  __typename?: 'query_root';
  project: Array<{
    __typename?: 'project';
    id: any;
    title: string;
    end_date?: any | null;
    created_at: any;
    project_members: Array<{
      __typename?: 'project_members';
      release_date: any;
    }>;
    tasks: Array<{
      __typename?: 'task';
      estimated_time?: any | null;
      due_date_time?: any | null;
      start_date_time?: any | null;
    }>;
  }>;
};

export type GetUserSkillDetailQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetUserSkillDetailQuery = {
  __typename?: 'query_root';
  user_Competency: Array<{
    __typename?: 'user_Competency';
    userId: any;
    competencyId: any;
    user_Skills: Array<{
      __typename?: 'user_Skills';
      rating: number;
      skill_Id: any;
    }>;
  }>;
};

export type GetUserPerformanceReviewMarksQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type GetUserPerformanceReviewMarksQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    year: string;
    performance_review_byUsers: Array<{
      __typename?: 'performance_review_byUser';
      peer_Id?: any | null;
      performance_review_byUser_WithGoals: Array<{
        __typename?: 'performance_review_byUser_WithGoal';
        goal_Id: any;
        goal_Marks?: any | null;
      }>;
    }>;
  }>;
};

export type UserManagerMarksQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type UserManagerMarksQuery = {
  __typename?: 'query_root';
  User_Peer_Review: Array<{ __typename?: 'User_Peer_Review'; peer_Id: any }>;
};

export type AssessmentCountByRolesQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type AssessmentCountByRolesQuery = {
  __typename?: 'query_root';
  assessment_role_details_aggregate: {
    __typename?: 'assessment_role_details_aggregate';
    aggregate?: {
      __typename?: 'assessment_role_details_aggregate_fields';
      count: number;
    } | null;
    nodes: Array<{
      __typename?: 'assessment_role_details';
      assessment: {
        __typename?: 'assessment';
        id: any;
        name: string;
        passing_score: any;
      };
    }>;
  };
};

export type TrainingCountByRolesQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type TrainingCountByRolesQuery = {
  __typename?: 'query_root';
  training_role_details_aggregate: {
    __typename?: 'training_role_details_aggregate';
    aggregate?: {
      __typename?: 'training_role_details_aggregate_fields';
      count: number;
    } | null;
    nodes: Array<{
      __typename?: 'training_role_details';
      training: { __typename?: 'training'; id: any; name: string };
    }>;
  };
};

export type Get_UserDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type Get_UserDetailsQuery = {
  __typename?: 'query_root';
  users_by_pk?: {
    __typename?: 'users';
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
        organization_id: any;
      } | null;
    }>;
  } | null;
};

export type Get_UserSkillApprovedQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_UserSkillApprovedQuery = {
  __typename?: 'query_root';
  user_Competency: Array<{
    __typename?: 'user_Competency';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
    };
    user_Skills_aggregate: {
      __typename?: 'user_Skills_aggregate';
      aggregate?: {
        __typename?: 'user_Skills_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type Get_UserSkillPendingToApproveQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_UserSkillPendingToApproveQuery = {
  __typename?: 'query_root';
  user_Competency: Array<{
    __typename?: 'user_Competency';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
    };
    user_Skills_aggregate: {
      __typename?: 'user_Skills_aggregate';
      aggregate?: {
        __typename?: 'user_Skills_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type UpdateNewAssessmentSkillsMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _set?: InputMaybe<Assessment_Skills_Set_Input>;
}>;

export type UpdateNewAssessmentSkillsMutation = {
  __typename?: 'mutation_root';
  update_assessment_Skills?: {
    __typename?: 'assessment_Skills_mutation_response';
    returning: Array<{ __typename?: 'assessment_Skills'; id: any }>;
  } | null;
};

export type DeleteAssessmentSkillsMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteAssessmentSkillsMutation = {
  __typename?: 'mutation_root';
  delete_assessment_Skills?: {
    __typename?: 'assessment_Skills_mutation_response';
    returning: Array<{ __typename?: 'assessment_Skills'; id: any }>;
  } | null;
};

export type UpdateNewTrainingSkillsMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _set?: InputMaybe<Training_Skill_Set_Input>;
}>;

export type UpdateNewTrainingSkillsMutation = {
  __typename?: 'mutation_root';
  update_training_Skill?: {
    __typename?: 'training_Skill_mutation_response';
    returning: Array<{ __typename?: 'training_Skill'; id: any }>;
  } | null;
};

export type DeleteTrainingSkillsMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteTrainingSkillsMutation = {
  __typename?: 'mutation_root';
  delete_training_Skill?: {
    __typename?: 'training_Skill_mutation_response';
    returning: Array<{ __typename?: 'training_Skill'; id: any }>;
  } | null;
};

export type Get_SkillIdAndNameFrmSubSkillQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_SkillIdAndNameFrmSubSkillQuery = {
  __typename?: 'query_root';
  sub_skills: Array<{
    __typename?: 'sub_skills';
    list_Of_Skill?: {
      __typename?: 'list_Of_Skills';
      id: any;
      name: string;
      assessment_Skills: Array<{
        __typename?: 'assessment_Skills';
        assessment_Id?: any | null;
      }>;
      training_Skills: Array<{
        __typename?: 'training_Skill';
        training_Id?: any | null;
      }>;
    } | null;
  }>;
};

export type CreateUserSkillAssessmentMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<User_Skills_Assessment_Insert_Input>
    | User_Skills_Assessment_Insert_Input
  >;
}>;

export type CreateUserSkillAssessmentMutation = {
  __typename?: 'mutation_root';
  insert_user_Skills_Assessment?: {
    __typename?: 'user_Skills_Assessment_mutation_response';
    returning: Array<{ __typename?: 'user_Skills_Assessment'; id: any }>;
  } | null;
};

export type Get_UserSkill_IdsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_UserSkill_IdsQuery = {
  __typename?: 'query_root';
  user_Competency: Array<{
    __typename?: 'user_Competency';
    user_Skills: Array<{ __typename?: 'user_Skills'; id: any }>;
  }>;
};

export type CreateUserSkillTrainingMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<User_Skills_Training_Insert_Input> | User_Skills_Training_Insert_Input
  >;
}>;

export type CreateUserSkillTrainingMutation = {
  __typename?: 'mutation_root';
  insert_user_Skills_Training?: {
    __typename?: 'user_Skills_Training_mutation_response';
    returning: Array<{ __typename?: 'user_Skills_Training'; id: any }>;
  } | null;
};

export type Update_UserSkillAssessmentMutationVariables = Exact<{
  _eq1: Scalars['uuid'];
  Status: Scalars['String'];
}>;

export type Update_UserSkillAssessmentMutation = {
  __typename?: 'mutation_root';
  update_user_Skills_Assessment?: {
    __typename?: 'user_Skills_Assessment_mutation_response';
    returning: Array<{ __typename?: 'user_Skills_Assessment'; id: any }>;
  } | null;
};

export type Update_UserSkillTrainingMutationVariables = Exact<{
  _eq1: Scalars['uuid'];
  status: Scalars['String'];
}>;

export type Update_UserSkillTrainingMutation = {
  __typename?: 'mutation_root';
  update_user_Skills_Training?: {
    __typename?: 'user_Skills_Training_mutation_response';
    returning: Array<{ __typename?: 'user_Skills_Training'; id: any }>;
  } | null;
};

export type CreateSkillsForAssessmentMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Assessment_Skills_Insert_Input> | Assessment_Skills_Insert_Input
  >;
}>;

export type CreateSkillsForAssessmentMutation = {
  __typename?: 'mutation_root';
  insert_assessment_Skills?: {
    __typename?: 'assessment_Skills_mutation_response';
    returning: Array<{ __typename?: 'assessment_Skills'; id: any }>;
  } | null;
};

export type Get_PassingScoreOfAssessmentIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_PassingScoreOfAssessmentIdQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; passing_score: any }>;
};

export type Get_TrainingDurationQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_TrainingDurationQuery = {
  __typename?: 'query_root';
  training: Array<{ __typename?: 'training'; duration?: number | null }>;
};

export type Get_AssessmentCountAttendByUserQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_AssessmentCountAttendByUserQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    assessment_records_aggregate: {
      __typename?: 'assessment_record_aggregate';
      aggregate?: {
        __typename?: 'assessment_record_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type Get_UserWithComptIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_UserWithComptIdQuery = {
  __typename?: 'query_root';
  user_Competency: Array<{ __typename?: 'user_Competency'; id: any }>;
};

export type Get_UserRoleQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_UserRoleQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    account?: { __typename?: 'auth_accounts'; default_role: string } | null;
  }>;
};

export type GetUsersRatingQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type GetUsersRatingQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    user_Competencies: Array<{
      __typename?: 'user_Competency';
      user_Skills: Array<{ __typename?: 'user_Skills'; rating: number }>;
    }>;
  }>;
};

export type GetSkillCountQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetSkillCountQuery = {
  __typename?: 'query_root';
  main_skills: Array<{
    __typename?: 'main_skills';
    name: string;
    sub_skills_aggregate: {
      __typename?: 'sub_skills_aggregate';
      aggregate?: {
        __typename?: 'sub_skills_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type Get_LatestAssessmentRecordQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  _eq2: Scalars['Boolean'];
  _eq3: Scalars['Boolean'];
}>;

export type Get_LatestAssessmentRecordQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{
    __typename?: 'assessment_record';
    given_answer?: string | null;
    correct_answer: string;
    question: {
      __typename?: 'questions';
      id: any;
      name: string;
      type: string;
      question_options: Array<{
        __typename?: 'question_options';
        name: string;
      }>;
      storage_file?: { __typename?: 'storage_files'; file_url: string } | null;
    };
    assessment: { __typename?: 'assessment'; passing_score: any };
  }>;
};

export type Create_CareerDevelopmentPlanMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Career_Development_Detail_Insert_Input>
    | Career_Development_Detail_Insert_Input
  >;
}>;

export type Create_CareerDevelopmentPlanMutation = {
  __typename?: 'mutation_root';
  insert_career_development_detail?: {
    __typename?: 'career_development_detail_mutation_response';
    returning: Array<{ __typename?: 'career_development_detail'; id: any }>;
  } | null;
};

export type Update_CareerDevelopmentPlanMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _set?: InputMaybe<Career_Development_Detail_Set_Input>;
}>;

export type Update_CareerDevelopmentPlanMutation = {
  __typename?: 'mutation_root';
  update_career_development_detail?: {
    __typename?: 'career_development_detail_mutation_response';
    returning: Array<{ __typename?: 'career_development_detail'; id: any }>;
  } | null;
};

export type Delete_ExistingRole_CareerDevelopmentMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Delete_ExistingRole_CareerDevelopmentMutation = {
  __typename?: 'mutation_root';
  delete_career_development_role_details?: {
    __typename?: 'career_development_role_details_mutation_response';
    affected_rows: number;
  } | null;
};

export type Delete_StageCareerDetailsMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Delete_StageCareerDetailsMutation = {
  __typename?: 'mutation_root';
  delete_training_stage_career?: {
    __typename?: 'training_stage_career_mutation_response';
    affected_rows: number;
  } | null;
  delete_assessment_stage_career?: {
    __typename?: 'assessment_stage_career_mutation_response';
    affected_rows: number;
  } | null;
  delete_stage_career_detail?: {
    __typename?: 'stage_career_detail_mutation_response';
    returning: Array<{ __typename?: 'stage_career_detail'; id: any }>;
  } | null;
};

export type Create_Career_Development_Role_DetailsMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Career_Development_Role_Details_Insert_Input>
    | Career_Development_Role_Details_Insert_Input
  >;
}>;

export type Create_Career_Development_Role_DetailsMutation = {
  __typename?: 'mutation_root';
  insert_career_development_role_details?: {
    __typename?: 'career_development_role_details_mutation_response';
    returning: Array<{
      __typename?: 'career_development_role_details';
      id: any;
    }>;
  } | null;
};

export type Create_Stage_Career_DevelopmentMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Stage_Career_Detail_Insert_Input> | Stage_Career_Detail_Insert_Input
  >;
}>;

export type Create_Stage_Career_DevelopmentMutation = {
  __typename?: 'mutation_root';
  insert_stage_career_detail?: {
    __typename?: 'stage_career_detail_mutation_response';
    returning: Array<{ __typename?: 'stage_career_detail'; id: any }>;
  } | null;
};

export type Add_User_To_Career_Dev_PlanMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Assign_Career_Development_Plan_Insert_Input>
    | Assign_Career_Development_Plan_Insert_Input
  >;
}>;

export type Add_User_To_Career_Dev_PlanMutation = {
  __typename?: 'mutation_root';
  insert_assign_career_development_plan?: {
    __typename?: 'assign_career_development_plan_mutation_response';
    returning: Array<{
      __typename?: 'assign_career_development_plan';
      id: any;
    }>;
  } | null;
};

export type Check_User_AssignedQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  career_development_plan_id: Scalars['uuid'];
}>;

export type Check_User_AssignedQuery = {
  __typename?: 'query_root';
  assign_career_development_plan: Array<{
    __typename?: 'assign_career_development_plan';
    id: any;
  }>;
};

export type Get_Users_Career_Development_DetailQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type Get_Users_Career_Development_DetailQuery = {
  __typename?: 'query_root';
  assign_career_development_plan: Array<{
    __typename?: 'assign_career_development_plan';
    status: string;
    career_development_detail: {
      __typename?: 'career_development_detail';
      id: any;
      title: string;
      description?: string | null;
      duration: number;
      created_by: any;
      user_career_development_records: Array<{
        __typename?: 'user_career_development_record';
        progress: any;
        stage: string;
        status: string;
      }>;
      stage_career_details: Array<{
        __typename?: 'stage_career_detail';
        stage_name: string;
        order_line?: number | null;
        training_stage_careers: Array<{
          __typename?: 'training_stage_career';
          id: any;
          training_id: any;
          order_line?: number | null;
        }>;
        assessment_stage_careers: Array<{
          __typename?: 'assessment_stage_career';
          id: any;
          assessment_id: any;
          order_line?: number | null;
        }>;
        assessment_stage_careers_aggregate: {
          __typename?: 'assessment_stage_career_aggregate';
          aggregate?: {
            __typename?: 'assessment_stage_career_aggregate_fields';
            count: number;
          } | null;
        };
        training_stage_careers_aggregate: {
          __typename?: 'training_stage_career_aggregate';
          aggregate?: {
            __typename?: 'training_stage_career_aggregate_fields';
            count: number;
          } | null;
        };
      }>;
    };
  }>;
};

export type User_Completed_Career_DevelopmentQueryVariables = Exact<{
  career_development_id: Scalars['uuid'];
}>;

export type User_Completed_Career_DevelopmentQuery = {
  __typename?: 'query_root';
  assign_career_development_plan: Array<{
    __typename?: 'assign_career_development_plan';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type Get_Stage_InfoQueryVariables = Exact<{
  career_development_id?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Stage_InfoQuery = {
  __typename?: 'query_root';
  stage_career_detail: Array<{
    __typename?: 'stage_career_detail';
    stage_name: string;
  }>;
};

export type Get_Career_Development_DetailQueryVariables = Exact<{
  career_development_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type Get_Career_Development_DetailQuery = {
  __typename?: 'query_root';
  career_development_detail: Array<{
    __typename?: 'career_development_detail';
    id: any;
    title: string;
    description?: string | null;
    duration: number;
    cover_image_id: any;
    storage_file: {
      __typename?: 'storage_files';
      id: any;
      file_url: string;
      metadata: any;
      type: Enum_Storage_File_Type_Enum;
    };
    career_external_certifications: Array<{
      __typename?: 'career_external_certification';
      id: any;
      external_certification_name?: string | null;
    }>;
    user_career_development_records: Array<{
      __typename?: 'user_career_development_record';
      progress: any;
      stage: string;
      status: string;
    }>;
    stage_career_details: Array<{
      __typename?: 'stage_career_detail';
      stage_name: string;
      order_line?: number | null;
      training_stage_careers: Array<{
        __typename?: 'training_stage_career';
        id: any;
        training_id: any;
        order_line?: number | null;
      }>;
      assessment_stage_careers: Array<{
        __typename?: 'assessment_stage_career';
        id: any;
        assessment_id: any;
        order_line?: number | null;
      }>;
      assessment_stage_careers_aggregate: {
        __typename?: 'assessment_stage_career_aggregate';
        aggregate?: {
          __typename?: 'assessment_stage_career_aggregate_fields';
          count: number;
        } | null;
      };
      training_stage_careers_aggregate: {
        __typename?: 'training_stage_career_aggregate';
        aggregate?: {
          __typename?: 'training_stage_career_aggregate_fields';
          count: number;
        } | null;
      };
    }>;
  }>;
};

export type User_Career_Training_ProgressQueryVariables = Exact<{
  training_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type User_Career_Training_ProgressQuery = {
  __typename?: 'query_root';
  training_progress_record: Array<{
    __typename?: 'training_progress_record';
    progress: string;
    type: string;
    docData: string;
    training: {
      __typename?: 'training';
      name: string;
      type: string;
      duration?: number | null;
      storage_file?: {
        __typename?: 'storage_files';
        file_url: string;
        metadata: any;
        type: Enum_Storage_File_Type_Enum;
      } | null;
    };
  }>;
};

export type User_Career_Assessment_ProgressQueryVariables = Exact<{
  assessment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type User_Career_Assessment_ProgressQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{
    __typename?: 'assessment_record';
    id: any;
    marks: number;
    total_marks?: number | null;
    assessment: {
      __typename?: 'assessment';
      id: any;
      name: string;
      passing_score: any;
    };
  }>;
};

export type Update_User_Career_Development_RecordMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  career_development_id: Scalars['uuid'];
  _set?: InputMaybe<User_Career_Development_Record_Set_Input>;
}>;

export type Update_User_Career_Development_RecordMutation = {
  __typename?: 'mutation_root';
  update_user_career_development_record?: {
    __typename?: 'user_career_development_record_mutation_response';
    returning: Array<{
      __typename?: 'user_career_development_record';
      id: any;
    }>;
  } | null;
};

export type Update_Old_Record_Data_Of_Career_AssessmentMutationVariables =
  Exact<{
    assessment_id: Scalars['uuid'];
    user_id: Scalars['uuid'];
  }>;

export type Update_Old_Record_Data_Of_Career_AssessmentMutation = {
  __typename?: 'mutation_root';
  update_assessment_record?: {
    __typename?: 'assessment_record_mutation_response';
    returning: Array<{ __typename?: 'assessment_record'; id: any }>;
  } | null;
};

export type Delete_Previous_Record_Of_Career_AssessmentMutationVariables =
  Exact<{
    user_id: Scalars['uuid'];
    assessment_id: Scalars['uuid'];
  }>;

export type Delete_Previous_Record_Of_Career_AssessmentMutation = {
  __typename?: 'mutation_root';
  delete_assessment_record?: {
    __typename?: 'assessment_record_mutation_response';
    returning: Array<{ __typename?: 'assessment_record'; id: any }>;
  } | null;
};

export type Get_List_Of_AssessmentQueryVariables = Exact<{
  skillId: Scalars['uuid'];
}>;

export type Get_List_Of_AssessmentQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; id: any; name: string }>;
};

export type Get_List_Of_User_AssessmentQueryVariables = Exact<{
  skillId: Scalars['uuid'];
}>;

export type Get_List_Of_User_AssessmentQuery = {
  __typename?: 'query_root';
  user_Skills_Assessment: Array<{
    __typename?: 'user_Skills_Assessment';
    id: any;
    assessment_Id?: any | null;
  }>;
};

export type Get_List_Of_User_Selected_SkillsQueryVariables = Exact<{
  user: Scalars['uuid'];
}>;

export type Get_List_Of_User_Selected_SkillsQuery = {
  __typename?: 'query_root';
  user_Skills: Array<{ __typename?: 'user_Skills'; id: any; skill_Id: any }>;
};

export type Add_User_Skills_AssessmentMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<User_Skills_Assessment_Insert_Input>
    | User_Skills_Assessment_Insert_Input
  >;
}>;

export type Add_User_Skills_AssessmentMutation = {
  __typename?: 'mutation_root';
  insert_user_Skills_Assessment?: {
    __typename?: 'user_Skills_Assessment_mutation_response';
    returning: Array<{ __typename?: 'user_Skills_Assessment'; id: any }>;
  } | null;
};

export type Get_List_Of_TrainingQueryVariables = Exact<{
  skillId?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_List_Of_TrainingQuery = {
  __typename?: 'query_root';
  training: Array<{ __typename?: 'training'; id: any; name: string }>;
};

export type Get_List_Of_User_TrainingQueryVariables = Exact<{
  skillId: Scalars['uuid'];
}>;

export type Get_List_Of_User_TrainingQuery = {
  __typename?: 'query_root';
  user_Skills_Training: Array<{
    __typename?: 'user_Skills_Training';
    id: any;
    training_Id?: any | null;
  }>;
};

export type Add_User_Skills_TrainingMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<User_Skills_Training_Insert_Input> | User_Skills_Training_Insert_Input
  >;
}>;

export type Add_User_Skills_TrainingMutation = {
  __typename?: 'mutation_root';
  insert_user_Skills_Training?: {
    __typename?: 'user_Skills_Training_mutation_response';
    returning: Array<{ __typename?: 'user_Skills_Training'; id: any }>;
  } | null;
};

export type Get_Career_Training_DetailsQueryVariables = Exact<{
  training_id: Scalars['uuid'];
}>;

export type Get_Career_Training_DetailsQuery = {
  __typename?: 'query_root';
  training: Array<{
    __typename?: 'training';
    name: string;
    storage_file?: {
      __typename?: 'storage_files';
      file_url: string;
      metadata: any;
      type: Enum_Storage_File_Type_Enum;
    } | null;
  }>;
};

export type Get_Career_Assessment_DetailsQueryVariables = Exact<{
  assessment_id: Scalars['uuid'];
}>;

export type Get_Career_Assessment_DetailsQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    name: string;
    passing_score: any;
    type: string;
    questions_aggregate: {
      __typename?: 'questions_aggregate';
      aggregate?: {
        __typename?: 'questions_aggregate_fields';
        count: number;
      } | null;
    };
    assessment_Skills: Array<{
      __typename?: 'assessment_Skills';
      list_Of_Skill?: { __typename?: 'list_Of_Skills'; name: string } | null;
    }>;
  }>;
};

export type Create_User_Career_Development_RecordMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<User_Career_Development_Record_Insert_Input>
    | User_Career_Development_Record_Insert_Input
  >;
}>;

export type Create_User_Career_Development_RecordMutation = {
  __typename?: 'mutation_root';
  insert_user_career_development_record?: {
    __typename?: 'user_career_development_record_mutation_response';
    returning: Array<{
      __typename?: 'user_career_development_record';
      id: any;
    }>;
  } | null;
};

export type Delete_External_CertificationMutationVariables = Exact<{
  career_id: Scalars['uuid'];
}>;

export type Delete_External_CertificationMutation = {
  __typename?: 'mutation_root';
  delete_career_external_certification?: {
    __typename?: 'career_external_certification_mutation_response';
    affected_rows: number;
  } | null;
};

export type Create_External_CertificationsMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Career_External_Certification_Insert_Input>
    | Career_External_Certification_Insert_Input
  >;
}>;

export type Create_External_CertificationsMutation = {
  __typename?: 'mutation_root';
  insert_career_external_certification?: {
    __typename?: 'career_external_certification_mutation_response';
    returning: Array<{ __typename?: 'career_external_certification'; id: any }>;
  } | null;
};

export type Get_Users_With_Completed_External_CertificationsQueryVariables =
  Exact<{
    id: Scalars['uuid'];
  }>;

export type Get_Users_With_Completed_External_CertificationsQuery = {
  __typename?: 'query_root';
  user_career_external_certification: Array<{
    __typename?: 'user_career_external_certification';
    career_external_certification_id?: any | null;
    storage_file?: {
      __typename?: 'storage_files';
      file_url: string;
      metadata: any;
      type: Enum_Storage_File_Type_Enum;
    } | null;
  }>;
};

export type List_Of_SkillsQueryVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type List_Of_SkillsQuery = {
  __typename?: 'query_root';
  list_Of_Skills: Array<{
    __typename?: 'list_Of_Skills';
    id: any;
    name: string;
    sub_skills: Array<{ __typename?: 'sub_skills'; id: any }>;
  }>;
};

export type Deviated_SkillsQueryVariables = Exact<{
  skill_id: Scalars['uuid'];
}>;

export type Deviated_SkillsQuery = {
  __typename?: 'query_root';
  user_Skills: Array<{
    __typename?: 'user_Skills';
    rating: number;
    user_Competency: {
      __typename?: 'user_Competency';
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          organization_role?: {
            __typename?: 'organization_roles';
            role_title: string;
          } | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    };
  }>;
};

export type Skilled_SkillsQueryVariables = Exact<{
  skill_id: Scalars['uuid'];
}>;

export type Skilled_SkillsQuery = {
  __typename?: 'query_root';
  user_Skills: Array<{
    __typename?: 'user_Skills';
    rating: number;
    user_Competency: {
      __typename?: 'user_Competency';
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          organization_role?: {
            __typename?: 'organization_roles';
            role_title: string;
          } | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    };
  }>;
};

export type User_CountQueryVariables = Exact<{
  skill_id: Scalars['uuid'];
}>;

export type User_CountQuery = {
  __typename?: 'query_root';
  user_Skills_aggregate: {
    __typename?: 'user_Skills_aggregate';
    aggregate?: {
      __typename?: 'user_Skills_aggregate_fields';
      count: number;
    } | null;
  };
};

export type User_Training_SkillQueryVariables = Exact<{
  skill_id: Scalars['uuid'];
  status?: InputMaybe<Scalars['String']>;
  training_id: Scalars['uuid'];
}>;

export type User_Training_SkillQuery = {
  __typename?: 'query_root';
  user_Skills_Training: Array<{
    __typename?: 'user_Skills_Training';
    status?: string | null;
    training?: { __typename?: 'training'; name: string } | null;
    user_Skill?: {
      __typename?: 'user_Skills';
      user_Competency: {
        __typename?: 'user_Competency';
        user: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      };
    } | null;
  }>;
};

export type User_Assessment_SkillsQueryVariables = Exact<{
  skill_id: Scalars['uuid'];
  status?: InputMaybe<Scalars['String']>;
  assessment_id: Scalars['uuid'];
}>;

export type User_Assessment_SkillsQuery = {
  __typename?: 'query_root';
  user_Skills_Assessment: Array<{
    __typename?: 'user_Skills_Assessment';
    assessment?: { __typename?: 'assessment'; name: string } | null;
    user_Skill?: {
      __typename?: 'user_Skills';
      user_Competency: {
        __typename?: 'user_Competency';
        user: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      };
    } | null;
  }>;
};

export type Get_List_Of_Assessment_SkillQueryVariables = Exact<{
  skill_id: Scalars['uuid'];
}>;

export type Get_List_Of_Assessment_SkillQuery = {
  __typename?: 'query_root';
  assessment_Skills_aggregate: {
    __typename?: 'assessment_Skills_aggregate';
    aggregate?: {
      __typename?: 'assessment_Skills_aggregate_fields';
      count: number;
    } | null;
    nodes: Array<{
      __typename?: 'assessment_Skills';
      assessment?: { __typename?: 'assessment'; id: any; name: string } | null;
    }>;
  };
};

export type Get_List_Of_Training_SkillQueryVariables = Exact<{
  skill_id: Scalars['uuid'];
}>;

export type Get_List_Of_Training_SkillQuery = {
  __typename?: 'query_root';
  training_Skill_aggregate: {
    __typename?: 'training_Skill_aggregate';
    aggregate?: {
      __typename?: 'training_Skill_aggregate_fields';
      count: number;
    } | null;
    nodes: Array<{
      __typename?: 'training_Skill';
      training?: { __typename?: 'training'; id: any; name: string } | null;
    }>;
  };
};

export type List_Of_Users_In_OrgQueryVariables = Exact<{
  org_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type List_Of_Users_In_OrgQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      organization_role_id?: any | null;
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
  }>;
};

export type Get_Count_Of_Deviated_SkillQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;

export type Get_Count_Of_Deviated_SkillQuery = {
  __typename?: 'query_root';
  user_Skills_aggregate: {
    __typename?: 'user_Skills_aggregate';
    aggregate?: {
      __typename?: 'user_Skills_aggregate_fields';
      count: number;
    } | null;
  };
};

export type List_Of_Skills_For_UserQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;

export type List_Of_Skills_For_UserQuery = {
  __typename?: 'query_root';
  user_Skills_aggregate: {
    __typename?: 'user_Skills_aggregate';
    aggregate?: {
      __typename?: 'user_Skills_aggregate_fields';
      count: number;
    } | null;
    nodes: Array<{
      __typename?: 'user_Skills';
      skill_Id: any;
      rating: number;
      sub_skill: {
        __typename?: 'sub_skills';
        list_Of_Skill?: { __typename?: 'list_Of_Skills'; name: string } | null;
      };
    }>;
  };
};

export type Get_Training_StatusQueryVariables = Exact<{
  training_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type Get_Training_StatusQuery = {
  __typename?: 'query_root';
  training_feedback: Array<{ __typename?: 'training_feedback'; id: any }>;
};

export type Get_Assessment_DetailQueryVariables = Exact<{
  assessment_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Assessment_DetailQuery = {
  __typename?: 'query_root';
  assessment_record: Array<{
    __typename?: 'assessment_record';
    total_marks?: number | null;
  }>;
};

export type Career_Development_ProgrammQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;

export type Career_Development_ProgrammQuery = {
  __typename?: 'query_root';
  user_career_development_record_aggregate: {
    __typename?: 'user_career_development_record_aggregate';
    aggregate?: {
      __typename?: 'user_career_development_record_aggregate_fields';
      count: number;
    } | null;
    nodes: Array<{
      __typename?: 'user_career_development_record';
      career_development_id: any;
      stage: string;
      status: string;
      career_development_detail: {
        __typename?: 'career_development_detail';
        id: any;
        title: string;
        stage_career_details: Array<{
          __typename?: 'stage_career_detail';
          stage_name: string;
          training_stage_careers: Array<{
            __typename?: 'training_stage_career';
            id: any;
            training_id: any;
          }>;
          assessment_stage_careers: Array<{
            __typename?: 'assessment_stage_career';
            id: any;
            assessment_id: any;
          }>;
          assessment_stage_careers_aggregate: {
            __typename?: 'assessment_stage_career_aggregate';
            aggregate?: {
              __typename?: 'assessment_stage_career_aggregate_fields';
              count: number;
            } | null;
          };
          training_stage_careers_aggregate: {
            __typename?: 'training_stage_career_aggregate';
            aggregate?: {
              __typename?: 'training_stage_career_aggregate_fields';
              count: number;
            } | null;
          };
        }>;
      };
    }>;
  };
};

export type Career_Development_Plan_DetailQueryVariables = Exact<{
  career_id: Scalars['uuid'];
}>;

export type Career_Development_Plan_DetailQuery = {
  __typename?: 'query_root';
  career_development_detail: Array<{
    __typename?: 'career_development_detail';
    stage_career_details: Array<{
      __typename?: 'stage_career_detail';
      stage_name: string;
    }>;
  }>;
};

export type Not_Completed_Assessment_UsersQueryVariables = Exact<{
  skill_id: Scalars['uuid'];
  assessment_id: Scalars['uuid'];
}>;

export type Not_Completed_Assessment_UsersQuery = {
  __typename?: 'query_root';
  user_Skills_Assessment: Array<{
    __typename?: 'user_Skills_Assessment';
    assessment?: { __typename?: 'assessment'; name: string } | null;
    user_Skill?: {
      __typename?: 'user_Skills';
      user_Competency: {
        __typename?: 'user_Competency';
        user: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      };
    } | null;
  }>;
};

export type Get_OrgAdmin_Role_IdQueryVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type Get_OrgAdmin_Role_IdQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{ __typename?: 'organization_roles'; id: any }>;
};

export type Update_Assign_Career_Journey_Of_An_UserMutationVariables = Exact<{
  career_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  status: Scalars['String'];
}>;

export type Update_Assign_Career_Journey_Of_An_UserMutation = {
  __typename?: 'mutation_root';
  update_assign_career_development_plan?: {
    __typename?: 'assign_career_development_plan_mutation_response';
    returning: Array<{
      __typename?: 'assign_career_development_plan';
      id: any;
    }>;
  } | null;
};

export type List_Of_Skills_OrgIdQueryVariables = Exact<{
  org_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type List_Of_Skills_OrgIdQuery = {
  __typename?: 'query_root';
  list_Of_Skills: Array<{
    __typename?: 'list_Of_Skills';
    id: any;
    name: string;
    sub_skills: Array<{ __typename?: 'sub_skills'; id: any }>;
  }>;
};

export type List_Of_Users_By_RoleIdQueryVariables = Exact<{
  role_id: Scalars['uuid'];
}>;

export type List_Of_Users_By_RoleIdQuery = {
  __typename?: 'query_root';
  users: Array<{ __typename?: 'users'; id: any }>;
};

export type List_Of_Users_In_Org_CountQueryVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type List_Of_Users_In_Org_CountQuery = {
  __typename?: 'query_root';
  users: Array<{ __typename?: 'users'; id: any }>;
};

export type Get_OrgAdmin_User_IdQueryVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type Get_OrgAdmin_User_IdQuery = {
  __typename?: 'query_root';
  organization: Array<{ __typename?: 'organization'; owned_by: any }>;
};

export type GetUserAvatarByUserIdQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;

export type GetUserAvatarByUserIdQuery = {
  __typename?: 'query_root';
  user_avatar_by_pk?: {
    __typename?: 'user_avatar';
    player_id: any;
    player_url?: string | null;
    user_id: any;
  } | null;
};

export type GetAvatarFileInfoQueryVariables = Exact<{
  avatar_id: Scalars['uuid'];
}>;

export type GetAvatarFileInfoQuery = {
  __typename?: 'query_root';
  user_avatar_files_by_pk?: {
    __typename?: 'user_avatar_files';
    avatar_id: any;
    created_at?: any | null;
    type?: Enum_User_Avatar_Types_Enum | null;
    avatar_image_id: any;
    avatar_url?: string | null;
  } | null;
};

export type InsertAvatarPlayerMutationVariables = Exact<{
  object: User_Avatar_Insert_Input;
}>;

export type InsertAvatarPlayerMutation = {
  __typename?: 'mutation_root';
  insert_user_avatar_one?: {
    __typename?: 'user_avatar';
    player_id: any;
  } | null;
};

export type InsertAvatarForUserMutationVariables = Exact<{
  object: User_Avatar_Files_Insert_Input;
}>;

export type InsertAvatarForUserMutation = {
  __typename?: 'mutation_root';
  insert_user_avatar_files_one?: {
    __typename?: 'user_avatar_files';
    avatar_id: any;
  } | null;
};

export type UpdateAvatarForUserMutationVariables = Exact<{
  avatar_id: Scalars['uuid'];
  data: User_Avatar_Files_Set_Input;
}>;

export type UpdateAvatarForUserMutation = {
  __typename?: 'mutation_root';
  update_user_avatar_files_by_pk?: {
    __typename?: 'user_avatar_files';
    avatar_id: any;
  } | null;
};

export type InsertAwardTypeMutationVariables = Exact<{
  object: Awards_Insert_Input;
}>;

export type InsertAwardTypeMutation = {
  __typename?: 'mutation_root';
  insert_awards_one?: {
    __typename?: 'awards';
    id: any;
    name: string;
    cycle: Enum_Award_Cycle_Type_Enum;
    created_by: any;
    limit?: number | null;
  } | null;
};

export type GetOrgAwardsByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetOrgAwardsByOrgIdQuery = {
  __typename?: 'query_root';
  organization_awards: Array<{
    __typename?: 'organization_awards';
    id: any;
    is_active: boolean;
    manager_permission: boolean;
    created_at: any;
    deleted_at?: any | null;
    award_types: Array<{
      __typename?: 'awards';
      id: any;
      name: string;
      description?: string | null;
      cycle: Enum_Award_Cycle_Type_Enum;
    }>;
  }>;
};

export type InsertAwardPostMutationVariables = Exact<{
  object: Award_Posts_Insert_Input;
}>;

export type InsertAwardPostMutation = {
  __typename?: 'mutation_root';
  insert_award_posts_one?: {
    __typename?: 'award_posts';
    id: any;
    created_at: any;
    period_description?: string | null;
    user_id: any;
  } | null;
};

export type UpdateAwardPostMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: Award_Posts_Set_Input;
}>;

export type UpdateAwardPostMutation = {
  __typename?: 'mutation_root';
  update_award_posts_by_pk?: { __typename?: 'award_posts'; id: any } | null;
};

export type UpdateAwardsTypeByIdMutationVariables = Exact<{
  awardId: Scalars['uuid'];
  data: Awards_Set_Input;
}>;

export type UpdateAwardsTypeByIdMutation = {
  __typename?: 'mutation_root';
  update_awards_by_pk?: { __typename?: 'awards'; id: any } | null;
};

export type GetAwardsTypeDetailByIdQueryVariables = Exact<{
  awardId: Scalars['uuid'];
}>;

export type GetAwardsTypeDetailByIdQuery = {
  __typename?: 'query_root';
  awards: Array<{
    __typename?: 'awards';
    id: any;
    name: string;
    cycle: Enum_Award_Cycle_Type_Enum;
    description?: string | null;
    created_at: any;
    created_by: any;
    permissions?: string | null;
    limit?: number | null;
  }>;
};

export type GetTodayAnniverseryQueryVariables = Exact<{ [key: string]: never }>;

export type GetTodayAnniverseryQuery = {
  __typename?: 'query_root';
  profile: Array<{
    __typename?: 'profile';
    id: any;
    created_at: any;
    updated_at: any;
    user_id: any;
    organization_id?: any | null;
    pronouns?: string | null;
    about_me?: string | null;
    date_of_birth?: string | null;
    invited_by?: any | null;
    address_id?: any | null;
    additional_fields: any;
    agora_id?: number | null;
    organization_role_id?: any | null;
    avatar_file_id?: any | null;
    is_completed: boolean;
    gender?: Enum_User_Gender_Enum | null;
    timezone?: string | null;
    userByUserId: {
      __typename?: 'users';
      first_name?: string | null;
      display_name?: string | null;
      id: any;
      deleted_at?: any | null;
      organizations: Array<{ __typename?: 'organization'; id: any }>;
    };
  }>;
};

export type BackendGetAllTheUsersDateOfJoiningByConditionQueryVariables =
  Exact<{ [key: string]: never }>;

export type BackendGetAllTheUsersDateOfJoiningByConditionQuery = {
  __typename?: 'query_root';
  profile: Array<{
    __typename?: 'profile';
    id: any;
    created_at: any;
    updated_at: any;
    user_id: any;
    organization_id?: any | null;
    pronouns?: string | null;
    about_me?: string | null;
    date_of_birth?: string | null;
    invited_by?: any | null;
    address_id?: any | null;
    agora_id?: number | null;
    organization_role_id?: any | null;
    avatar_file_id?: any | null;
    is_completed: boolean;
    gender?: Enum_User_Gender_Enum | null;
    timezone?: string | null;
    date_of_joining: any;
    userByUserId: {
      __typename?: 'users';
      first_name?: string | null;
      display_name?: string | null;
      id: any;
      deleted_at?: any | null;
      organizations: Array<{ __typename?: 'organization'; id: any }>;
    };
    organization?: {
      __typename?: 'organization';
      owned_by: any;
      organization_employees: Array<{
        __typename?: 'organization_employee';
        user_id: any;
      }>;
    } | null;
  }>;
};

export type GetTodayBirthdayQueryVariables = Exact<{
  date?: InputMaybe<Scalars['String']>;
}>;

export type GetTodayBirthdayQuery = {
  __typename?: 'query_root';
  profile: Array<{
    __typename?: 'profile';
    id: any;
    created_at: any;
    updated_at: any;
    user_id: any;
    organization_id?: any | null;
    pronouns?: string | null;
    about_me?: string | null;
    date_of_birth?: string | null;
    invited_by?: any | null;
    address_id?: any | null;
    additional_fields: any;
    agora_id?: number | null;
    organization_role_id?: any | null;
    avatar_file_id?: any | null;
    is_completed: boolean;
    gender?: Enum_User_Gender_Enum | null;
    timezone?: string | null;
    userByUserId: {
      __typename?: 'users';
      first_name?: string | null;
      deleted_at?: any | null;
      display_name?: string | null;
      id: any;
      organizations: Array<{ __typename?: 'organization'; id: any }>;
    };
    organization?: {
      __typename?: 'organization';
      owned_by: any;
      organization_employees: Array<{
        __typename?: 'organization_employee';
        user_id: any;
      }>;
    } | null;
  }>;
};

export type CreateCalendarMutationVariables = Exact<{
  object: Calendar_Insert_Input;
}>;

export type CreateCalendarMutation = {
  __typename?: 'mutation_root';
  insert_calendar_one?: {
    __typename?: 'calendar';
    id: any;
    created_by: any;
    organization_id: any;
    calendar_name: string;
    default_timezone: string;
  } | null;
};

export type CreateCalendarEventMutationVariables = Exact<{
  object: Calendar_Event_Insert_Input;
}>;

export type CreateCalendarEventMutation = {
  __typename?: 'mutation_root';
  insert_calendar_event_one?: {
    __typename?: 'calendar_event';
    id: any;
    event_name: string;
    event_type: Enum_Event_Type_Enum;
    end_date_time: any;
    start_date_time: any;
    created_by: any;
    created_at: any;
    updated_at: any;
    calendar_id: any;
    additional_fields: any;
    all_day: boolean;
    calendar_attendees: Array<{
      __typename?: 'calendar_attendee';
      id: any;
      attendee_id?: any | null;
    }>;
  } | null;
};

export type CreateAttendeesMutationVariables = Exact<{
  objects:
    | Array<Calendar_Attendee_Insert_Input>
    | Calendar_Attendee_Insert_Input;
}>;

export type CreateAttendeesMutation = {
  __typename?: 'mutation_root';
  insert_calendar_attendee?: {
    __typename?: 'calendar_attendee_mutation_response';
    returning: Array<{
      __typename?: 'calendar_attendee';
      attendee_name?: string | null;
    }>;
  } | null;
};

export type DeleteCalendarAttendeesMutationVariables = Exact<{
  calendar_event_id: Scalars['uuid'];
}>;

export type DeleteCalendarAttendeesMutation = {
  __typename?: 'mutation_root';
  delete_calendar_attendee?: {
    __typename?: 'calendar_attendee_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'calendar_attendee'; id: any }>;
  } | null;
};

export type DeleteAttendeeByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteAttendeeByPkMutation = {
  __typename?: 'mutation_root';
  delete_calendar_attendee_by_pk?: {
    __typename?: 'calendar_attendee';
    attendee_name?: string | null;
  } | null;
};

export type DeleteCalendarEventMutationVariables = Exact<{
  calendar_event_id: Scalars['uuid'];
}>;

export type DeleteCalendarEventMutation = {
  __typename?: 'mutation_root';
  delete_calendar_event_by_pk?: {
    __typename?: 'calendar_event';
    id: any;
  } | null;
};

export type DeleteCalendarDeleteEventsMutationVariables = Exact<{
  calendar_event_id: Scalars['uuid'];
}>;

export type DeleteCalendarDeleteEventsMutation = {
  __typename?: 'mutation_root';
  delete_calendar_delete_events?: {
    __typename?: 'calendar_delete_events_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'calendar_delete_events'; id: any }>;
  } | null;
};

export type CreateCalendarMeetingCallMutationVariables = Exact<{
  object: Calendar_Meeting_Call_Insert_Input;
}>;

export type CreateCalendarMeetingCallMutation = {
  __typename?: 'mutation_root';
  insert_calendar_meeting_call_one?: {
    __typename?: 'calendar_meeting_call';
    id: any;
    created_by: any;
    channel_id: string;
    slug?: string | null;
    meet_status?: Enum_Call_Status_Type_Enum | null;
    start_date_time?: any | null;
    end_date_time?: any | null;
    org_id: any;
    metadata?: any | null;
    event_id: any;
    created_at: any;
    updated_at: any;
  } | null;
};

export type GetCalendarMeetingCallsByEventIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetCalendarMeetingCallsByEventIdQuery = {
  __typename?: 'query_root';
  calendar_meeting_call: Array<{
    __typename?: 'calendar_meeting_call';
    id: any;
    created_by: any;
    channel_id: string;
    slug?: string | null;
    meet_status?: Enum_Call_Status_Type_Enum | null;
    start_date_time?: any | null;
    end_date_time?: any | null;
    org_id: any;
    metadata?: any | null;
    event_id: any;
    created_at: any;
    updated_at: any;
  }>;
};

export type UpdateCalendarMeetingCallUserMutationVariables = Exact<{
  where: Calendar_Meeting_Call_User_Bool_Exp;
  set: Calendar_Meeting_Call_User_Set_Input;
}>;

export type UpdateCalendarMeetingCallUserMutation = {
  __typename?: 'mutation_root';
  update_calendar_meeting_call_user?: {
    __typename?: 'calendar_meeting_call_user_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetCalenderAttendeesByEventIdQueryVariables = Exact<{
  calendarEventId: Scalars['uuid'];
}>;

export type GetCalenderAttendeesByEventIdQuery = {
  __typename?: 'query_root';
  calendar_attendee: Array<{
    __typename?: 'calendar_attendee';
    attendee_id?: any | null;
    attendee_name?: string | null;
    attendee_type: Enum_Attendee_Type_Enum;
    calendar_event_id: any;
    attendee_status: Enum_Attendee_Status_Type_Enum;
    calendar_id?: any | null;
    created_at: any;
    created_by: any;
    email?: string | null;
    id: any;
    updated_at: any;
    calendar_event: {
      __typename?: 'calendar_event';
      event_name: string;
      start_date_time: any;
      additional_fields: any;
      created_by: any;
    };
    user?: { __typename?: 'users'; display_name?: string | null } | null;
  }>;
};

export type JoinCalendarMeetingMutationVariables = Exact<{
  object: Calendar_Meeting_Call_User_Insert_Input;
}>;

export type JoinCalendarMeetingMutation = {
  __typename?: 'mutation_root';
  insert_calendar_meeting_call_user_one?: {
    __typename?: 'calendar_meeting_call_user';
    id: any;
    meeting_call_id: any;
    user_id: any;
    status: Enum_Call_User_Status_Enum;
    call_joined_date?: any | null;
    calendar_event_id?: any | null;
  } | null;
};

export type CreateCalendarCallRequestMutationVariables = Exact<{
  objects:
    | Array<Calendar_Meeting_Call_Request_Insert_Input>
    | Calendar_Meeting_Call_Request_Insert_Input;
}>;

export type CreateCalendarCallRequestMutation = {
  __typename?: 'mutation_root';
  insert_calendar_meeting_call_request?: {
    __typename?: 'calendar_meeting_call_request_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateCalendarCallRequestMutationVariables = Exact<{
  data: Calendar_Meeting_Call_Request_Set_Input;
  userId: Scalars['uuid'];
  meetingCallId: Scalars['uuid'];
}>;

export type UpdateCalendarCallRequestMutation = {
  __typename?: 'mutation_root';
  update_calendar_meeting_call_request?: {
    __typename?: 'calendar_meeting_call_request_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetCalendarMeetingCallsByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetCalendarMeetingCallsByIdQuery = {
  __typename?: 'query_root';
  meet_call?: {
    __typename?: 'calendar_meeting_call';
    id: any;
    created_by: any;
    channel_id: string;
    slug?: string | null;
    meet_status?: Enum_Call_Status_Type_Enum | null;
    start_date_time?: any | null;
    end_date_time?: any | null;
    metadata?: any | null;
    event_id: any;
  } | null;
};

export type GetCalenderEventByIdQueryVariables = Exact<{
  eventId: Scalars['uuid'];
}>;

export type GetCalenderEventByIdQuery = {
  __typename?: 'query_root';
  calendar_event_by_pk?: {
    __typename?: 'calendar_event';
    id: any;
    event_name: string;
    event_type: Enum_Event_Type_Enum;
    created_by: any;
    start_date_time: any;
    end_date_time: any;
    rrule?: string | null;
    scheduler_rrule?: string | null;
    all_day: boolean;
    calendar_id: any;
    created_at: any;
    updated_at: any;
    additional_fields: any;
    calendar_attendees: Array<{
      __typename?: 'calendar_attendee';
      email?: string | null;
      attendee_type: Enum_Attendee_Type_Enum;
      attendee_id?: any | null;
      attendee_name?: string | null;
      id: any;
      user?: { __typename?: 'users'; display_name?: string | null } | null;
    }>;
    calendar_meeting_calls: Array<{
      __typename?: 'calendar_meeting_call';
      id: any;
      meetingSchedulars: Array<{
        __typename?: 'meeting_call_schedular';
        id: any;
        schedular_id?: string | null;
        meeting_event_id: any;
        calendar_event_id: any;
      }>;
    }>;
  } | null;
};

export type GetAttendeesEmployeeCapacityQueryVariables = Exact<{
  attendeeIds: Array<Scalars['uuid']> | Scalars['uuid'];
  date: Scalars['date'];
}>;

export type GetAttendeesEmployeeCapacityQuery = {
  __typename?: 'query_root';
  employee_capacity: Array<{
    __typename?: 'employee_capacity';
    workload_hours: any;
    date: any;
    employee_id: any;
  }>;
};

export type GetAllRecurringEventsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllRecurringEventsQuery = {
  __typename?: 'query_root';
  calendar_event: Array<{
    __typename?: 'calendar_event';
    id: any;
    event_name: string;
    event_type: Enum_Event_Type_Enum;
    created_by: any;
    start_date_time: any;
    end_date_time: any;
    rrule?: string | null;
    scheduler_rrule?: string | null;
    all_day: boolean;
    calendar_id: any;
    created_at: any;
    updated_at: any;
    additional_fields: any;
    calendar_attendees: Array<{
      __typename?: 'calendar_attendee';
      email?: string | null;
      attendee_type: Enum_Attendee_Type_Enum;
      attendee_id?: any | null;
      attendee_name?: string | null;
      id: any;
      user?: { __typename?: 'users'; display_name?: string | null } | null;
    }>;
    calendar_meeting_calls: Array<{
      __typename?: 'calendar_meeting_call';
      id: any;
      meetingSchedulars: Array<{
        __typename?: 'meeting_call_schedular';
        id: any;
        schedular_id?: string | null;
        meeting_event_id: any;
        calendar_event_id: any;
        for_date?: any | null;
      }>;
    }>;
  }>;
};

export type UpdateCalenderEventByIdMutationVariables = Exact<{
  eventId: Scalars['uuid'];
  _set?: InputMaybe<Calendar_Event_Set_Input>;
}>;

export type UpdateCalenderEventByIdMutation = {
  __typename?: 'mutation_root';
  update_calendar_event?: {
    __typename?: 'calendar_event_mutation_response';
    returning: Array<{
      __typename?: 'calendar_event';
      id: any;
      created_at: any;
      updated_at: any;
      event_name: string;
      start_date_time: any;
      end_date_time: any;
      calendar_id: any;
      rrule?: string | null;
      all_day: boolean;
      additional_fields: any;
      created_by: any;
      event_type: Enum_Event_Type_Enum;
    }>;
  } | null;
};

export type CreateCalendarDeleteEventMutationVariables = Exact<{
  object: Calendar_Delete_Events_Insert_Input;
}>;

export type CreateCalendarDeleteEventMutation = {
  __typename?: 'mutation_root';
  insert_calendar_delete_events_one?: {
    __typename?: 'calendar_delete_events';
    id: any;
    calendar_event_id: any;
    excluded_date: any;
  } | null;
};

export type GetMeetingCallDataByMeetingCallIdQueryVariables = Exact<{
  meetingCallId: Scalars['uuid'];
}>;

export type GetMeetingCallDataByMeetingCallIdQuery = {
  __typename?: 'query_root';
  calendar_meeting_call: Array<{
    __typename?: 'calendar_meeting_call';
    id: any;
    event_id: any;
    uid?: string | null;
    sid?: string | null;
    resource_id?: string | null;
    recording_link?: string | null;
    channel_id: string;
    chat_id?: any | null;
    recording_status?: Enum_Call_Recording_Status_Enum | null;
    metadata?: any | null;
    reacording_started_by?: any | null;
    meet_status?: Enum_Call_Status_Type_Enum | null;
    meeting_call_users: Array<{
      __typename?: 'calendar_meeting_call_user';
      user_id: any;
      status: Enum_Call_User_Status_Enum;
      call_joined_date?: any | null;
      user: { __typename?: 'users'; display_name?: string | null };
    }>;
    calendar_event: {
      __typename?: 'calendar_event';
      id: any;
      event_type: Enum_Event_Type_Enum;
      scheduler_rrule?: string | null;
      rrule?: string | null;
      event_name: string;
      created_by: any;
      calendar_attendees: Array<{
        __typename?: 'calendar_attendee';
        attendee_id?: any | null;
        attendee_name?: string | null;
        attendee_status: Enum_Attendee_Status_Type_Enum;
      }>;
    };
    chat_room?: Array<{
      __typename?: 'chat_room';
      id: any;
      room_name?: string | null;
    }> | null;
  }>;
};

export type BackendUpdateCalendarMeetingCallByIdMutationVariables = Exact<{
  meeting_call_id: Scalars['uuid'];
  set: Calendar_Meeting_Call_Set_Input;
}>;

export type BackendUpdateCalendarMeetingCallByIdMutation = {
  __typename?: 'mutation_root';
  update_calendar_meeting_call?: {
    __typename?: 'calendar_meeting_call_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateCallMutationVariables = Exact<{
  object: Call_Insert_Input;
}>;

export type CreateCallMutation = {
  __typename?: 'mutation_root';
  insert_call_one?: { __typename?: 'call'; id: any } | null;
};

export type CreateCallRequestMutationVariables = Exact<{
  object: Call_Request_Insert_Input;
}>;

export type CreateCallRequestMutation = {
  __typename?: 'mutation_root';
  insert_call_request_one?: { __typename?: 'call_request'; id: any } | null;
};

export type UpdateCallMutationVariables = Exact<{
  callId: Scalars['uuid'];
  set: Call_Set_Input;
}>;

export type UpdateCallMutation = {
  __typename?: 'mutation_root';
  update_call?: {
    __typename?: 'call_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateCallRequestMutationVariables = Exact<{
  callId: Scalars['uuid'];
  userId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Call_Request_Status_Type_Enum>;
  set: Call_Request_Set_Input;
}>;

export type UpdateCallRequestMutation = {
  __typename?: 'mutation_root';
  update_call_request?: {
    __typename?: 'call_request_mutation_response';
    returning: Array<{ __typename?: 'call_request'; user_id: any }>;
  } | null;
};

export type GetCallRequestsByCallIdQueryVariables = Exact<{
  callId: Scalars['uuid'];
  requestStatus: Enum_Call_Request_Status_Type_Enum;
}>;

export type GetCallRequestsByCallIdQuery = {
  __typename?: 'query_root';
  call_request: Array<{
    __typename?: 'call_request';
    id: any;
    call_id: any;
    user_id: any;
    call_request_status?: Enum_Call_Request_Status_Type_Enum | null;
    request_type: Enum_Call_Request_Type_Enum;
    created_by: any;
    created_at: any;
    updated_at: any;
  }>;
};

export type GetCallRequestCountByCallIdQueryVariables = Exact<{
  callId: Scalars['uuid'];
}>;

export type GetCallRequestCountByCallIdQuery = {
  __typename?: 'query_root';
  call_request_aggregate: {
    __typename?: 'call_request_aggregate';
    aggregate?: {
      __typename?: 'call_request_aggregate_fields';
      count: number;
    } | null;
  };
};

export type LeaveCallMutationVariables = Exact<{
  object: LeaveCallInput;
}>;

export type LeaveCallMutation = {
  __typename?: 'mutation_root';
  leaveCall?: { __typename?: 'LeaveCallOutput'; response: boolean } | null;
};

export type GetCallDetailsQueryVariables = Exact<{
  callId: Scalars['uuid'];
}>;

export type GetCallDetailsQuery = {
  __typename?: 'query_root';
  call_by_pk?: {
    __typename?: 'call';
    id: any;
    call_broadcasting_type?: Enum_Call_Broadcasting_Type_Enum | null;
    call_status?: Enum_Call_Status_Type_Enum | null;
    call_type: Enum_Call_Type_Enum;
    created_by: any;
    media_type: Enum_Call_Media_Type_Enum;
    chat_id?: any | null;
    slug?: string | null;
    access_policy?: Enum_Call_Access_Policy_Enum | null;
    created_at: any;
    updated_at: any;
    channel_id: string;
    call_resource_id?: string | null;
    call_sid?: string | null;
    call_uid?: string | null;
    metadata?: any | null;
    call_recording_link?: string | null;
    call_recording_status?: Enum_Call_Recording_Status_Enum | null;
    start_date_time?: any | null;
    end_date_time?: any | null;
    recording_started_by?: any | null;
    call_users: Array<{
      __typename?: 'call_user';
      user_id: any;
      status: Enum_Call_User_Status_Enum;
    }>;
    call_users_aggregate: {
      __typename?: 'call_user_aggregate';
      aggregate?: {
        __typename?: 'call_user_aggregate_fields';
        count: number;
      } | null;
    };
  } | null;
};

export type CreateCallUsersMutationVariables = Exact<{
  object: Call_User_Insert_Input;
}>;

export type CreateCallUsersMutation = {
  __typename?: 'mutation_root';
  insert_call_user_one?: {
    __typename?: 'call_user';
    id: any;
    call_id: any;
    status: Enum_Call_User_Status_Enum;
  } | null;
};

export type CallUserUpdateMutationVariables = Exact<{
  callId: Scalars['uuid'];
  userId?: InputMaybe<Scalars['uuid']>;
  set: Call_User_Set_Input;
}>;

export type CallUserUpdateMutation = {
  __typename?: 'mutation_root';
  update_call_user?: {
    __typename?: 'call_user_mutation_response';
    affected_rows: number;
  } | null;
};

export type CallUserUpdateWithoutUserIdMutationVariables = Exact<{
  callId: Scalars['uuid'];
  set: Call_User_Set_Input;
}>;

export type CallUserUpdateWithoutUserIdMutation = {
  __typename?: 'mutation_root';
  update_call_user?: {
    __typename?: 'call_user_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetCallUsersDetailsByCallIdQueryVariables = Exact<{
  callId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Call_User_Status_Enum>;
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetCallUsersDetailsByCallIdQuery = {
  __typename?: 'query_root';
  call_user: Array<{
    __typename?: 'call_user';
    id: any;
    status: Enum_Call_User_Status_Enum;
    call_id: any;
  }>;
};

export type CreateChatMessageMutationVariables = Exact<{
  object: Chat_Message_Insert_Input;
}>;

export type CreateChatMessageMutation = {
  __typename?: 'mutation_root';
  insert_chat_message_one?: { __typename?: 'chat_message'; id: any } | null;
};

export type CreateChatRoomUsersMutationVariables = Exact<{
  objects: Array<Chat_Room_User_Insert_Input> | Chat_Room_User_Insert_Input;
}>;

export type CreateChatRoomUsersMutation = {
  __typename?: 'mutation_root';
  insert_chat_room_user?: {
    __typename?: 'chat_room_user_mutation_response';
    returning: Array<{
      __typename?: 'chat_room_user';
      chat_room_id: any;
      user_id: any;
    }>;
  } | null;
};

export type UpdateChatRoomUserMutationVariables = Exact<{
  userId: Scalars['uuid'];
  chatRoomId: Scalars['uuid'];
  object: Chat_Room_User_Set_Input;
}>;

export type UpdateChatRoomUserMutation = {
  __typename?: 'mutation_root';
  chat_room_user?: {
    __typename?: 'chat_room_user';
    user_id: any;
    chat_room_id: any;
    status: Enum_Chat_Room_User_Status_Enum;
  } | null;
};

export type DeleteChatRoomUserMutationVariables = Exact<{
  userId: Scalars['uuid'];
  chatRoomId: Scalars['uuid'];
}>;

export type DeleteChatRoomUserMutation = {
  __typename?: 'mutation_root';
  delete_chat_room_user_by_pk?: {
    __typename?: 'chat_room_user';
    chat_room_id: any;
    user_id: any;
  } | null;
};

export type DeleteChatRoomMessagesMutationVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type DeleteChatRoomMessagesMutation = {
  __typename?: 'mutation_root';
  delete_chat_message?: {
    __typename?: 'chat_message_mutation_response';
    returning: Array<{ __typename?: 'chat_message'; chat_room_id: any }>;
  } | null;
};

export type DeleteChatRoomMutationVariables = Exact<{
  chatRoomId: Scalars['uuid'];
}>;

export type DeleteChatRoomMutation = {
  __typename?: 'mutation_root';
  delete_chat_room_by_pk?: { __typename?: 'chat_room'; id: any } | null;
};

export type CreateChatRoomMutationVariables = Exact<{
  object: Chat_Room_Insert_Input;
}>;

export type CreateChatRoomMutation = {
  __typename?: 'mutation_root';
  insert_chat_room_one?: {
    __typename?: 'chat_room';
    id: any;
    created_by: any;
    chat_room_type: Enum_Chat_Room_Type_Enum;
  } | null;
};

export type GetOneToOneChatRoomQueryVariables = Exact<{
  firstUser: Scalars['uuid'];
  secondUser: Scalars['uuid'];
}>;

export type GetOneToOneChatRoomQuery = {
  __typename?: 'query_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      user: { __typename?: 'users'; id: any; display_name?: string | null };
    }>;
  }>;
};

export type GetMeetingChatRoomQueryVariables = Exact<{
  meetingChatDetails: Scalars['jsonb'];
}>;

export type GetMeetingChatRoomQuery = {
  __typename?: 'query_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    created_at: any;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      user_id: any;
      created_at?: any | null;
    }>;
    chat_room_users_aggregate: {
      __typename?: 'chat_room_user_aggregate';
      aggregate?: {
        __typename?: 'chat_room_user_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetGroupChatRoomQueryVariables = Exact<{
  combinedUsers?: InputMaybe<Array<Chat_Room_Bool_Exp> | Chat_Room_Bool_Exp>;
}>;

export type GetGroupChatRoomQuery = {
  __typename?: 'query_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    created_at: any;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      user_id: any;
      created_at?: any | null;
    }>;
    chat_room_users_aggregate: {
      __typename?: 'chat_room_user_aggregate';
      aggregate?: {
        __typename?: 'chat_room_user_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetChatRoomByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetChatRoomByIdQuery = {
  __typename?: 'query_root';
  chat_room?: {
    __typename?: 'chat_room';
    id: any;
    created_by: any;
    last_message_id?: any | null;
    chat_room_type: Enum_Chat_Room_Type_Enum;
    created_at: any;
    updated_at: any;
    room_name?: string | null;
    metadata?: any | null;
    managed_by?: any | null;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      user_id: any;
      created_at?: any | null;
      status: Enum_Chat_Room_User_Status_Enum;
      notification_settings: Enum_Chat_Notification_Enum;
    }>;
  } | null;
};

export type UpdateChatRoomByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  object: Chat_Room_Set_Input;
}>;

export type UpdateChatRoomByIdMutation = {
  __typename?: 'mutation_root';
  update_chat_room_by_pk?: {
    __typename?: 'chat_room';
    id: any;
    created_by: any;
    chat_room_type: Enum_Chat_Room_Type_Enum;
  } | null;
};

export type GetNewChatRoomManagerQueryVariables = Exact<{
  chat_room_id: Scalars['uuid'];
}>;

export type GetNewChatRoomManagerQuery = {
  __typename?: 'query_root';
  chat_room_user: Array<{
    __typename?: 'chat_room_user';
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  }>;
};

export type GetChatRoomIdByMetaDataQueryVariables = Exact<{
  metaData: Scalars['jsonb'];
}>;

export type GetChatRoomIdByMetaDataQuery = {
  __typename?: 'query_root';
  chat_room: Array<{ __typename?: 'chat_room'; id: any }>;
};

export type BackendGetChatRoomQueryVariables = Exact<{
  condition: Chat_Room_Bool_Exp;
}>;

export type BackendGetChatRoomQuery = {
  __typename?: 'query_root';
  chat_room: Array<{
    __typename?: 'chat_room';
    id: any;
    created_by: any;
    last_message_id?: any | null;
    chat_room_type: Enum_Chat_Room_Type_Enum;
    created_at: any;
    updated_at: any;
    room_name?: string | null;
    metadata?: any | null;
    managed_by?: any | null;
    chat_room_users: Array<{
      __typename?: 'chat_room_user';
      user_id: any;
      created_at?: any | null;
      status: Enum_Chat_Room_User_Status_Enum;
      notification_settings: Enum_Chat_Notification_Enum;
    }>;
  }>;
};

export type BackendDeleteChatRoomByCalendarEventIdMutationVariables = Exact<{
  condition: Chat_Room_Bool_Exp;
}>;

export type BackendDeleteChatRoomByCalendarEventIdMutation = {
  __typename?: 'mutation_root';
  delete_chat_room?: {
    __typename?: 'chat_room_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertChatEmoteReactionMutationVariables = Exact<{
  object: Chat_Message_Reactions_Insert_Input;
}>;

export type InsertChatEmoteReactionMutation = {
  __typename?: 'mutation_root';
  insert_chat_message_reactions_one?: {
    __typename?: 'chat_message_reactions';
    id: any;
  } | null;
};

export type UpdateEmoteReactionByMsgIdMutationVariables = Exact<{
  messageId: Scalars['uuid'];
  userId: Scalars['uuid'];
  data: Chat_Message_Reactions_Set_Input;
}>;

export type UpdateEmoteReactionByMsgIdMutation = {
  __typename?: 'mutation_root';
  update_chat_message_reactions?: {
    __typename?: 'chat_message_reactions_mutation_response';
    affected_rows: number;
  } | null;
};

export type CheckExistingReactionQueryVariables = Exact<{
  messageId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type CheckExistingReactionQuery = {
  __typename?: 'query_root';
  chat_message_reactions: Array<{
    __typename?: 'chat_message_reactions';
    id: any;
    reaction_type: Enum_Live_Emote_Type_Enum;
  }>;
};

export type GetDashboardRemindersByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetDashboardRemindersByUserIdQuery = {
  __typename?: 'query_root';
  dashboard_remainders: Array<{
    __typename?: 'dashboard_remainders';
    id: any;
    is_read: boolean;
    name: string;
    remainder_type?: Enum_Dashboard_Remainder_Type_Enum | null;
    user_id: any;
    created_at: any;
    updated_at: any;
  }>;
};

export type InsertDashboardReminderMutationVariables = Exact<{
  object: Dashboard_Remainders_Insert_Input;
}>;

export type InsertDashboardReminderMutation = {
  __typename?: 'mutation_root';
  insert_dashboard_remainders_one?: {
    __typename?: 'dashboard_remainders';
    id: any;
    is_read: boolean;
    name: string;
    remainder_type?: Enum_Dashboard_Remainder_Type_Enum | null;
    user_id: any;
  } | null;
};

export type GetAllDashboardRemindersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllDashboardRemindersQuery = {
  __typename?: 'query_root';
  dashboard_remainders: Array<{
    __typename?: 'dashboard_remainders';
    id: any;
    name: string;
    remainder_type?: Enum_Dashboard_Remainder_Type_Enum | null;
  }>;
};

export type CheckExistingRemainderQueryVariables = Exact<{
  userId: Scalars['uuid'];
  remainderTypes:
    | Array<Enum_Dashboard_Remainder_Type_Enum>
    | Enum_Dashboard_Remainder_Type_Enum;
}>;

export type CheckExistingRemainderQuery = {
  __typename?: 'query_root';
  dashboard_remainders: Array<{
    __typename?: 'dashboard_remainders';
    id: any;
    remainder_type?: Enum_Dashboard_Remainder_Type_Enum | null;
  }>;
};

export type GetPerformanceReviewStartedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPerformanceReviewStartedQuery = {
  __typename?: 'query_root';
  performance_review_setting_With_Goals: Array<{
    __typename?: 'performance_review_setting_With_Goals';
    id: any;
    title: string;
    timePeriod: string;
    startDate: any;
    dueDate: any;
    status: string;
    action: string;
    performance_review_setting: {
      __typename?: 'performance_review_settings';
      frequency: string;
    };
  }>;
};

export type CheckPerformanceReviewStartedQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type CheckPerformanceReviewStartedQuery = {
  __typename?: 'query_root';
  performance_review_setting_With_Goals: Array<{
    __typename?: 'performance_review_setting_With_Goals';
    id: any;
    title: string;
    timePeriod: string;
    startDate: any;
    dueDate: any;
    status: string;
    action: string;
    performance_review_setting: {
      __typename?: 'performance_review_settings';
      frequency: string;
    };
  }>;
};

export type CheckPendingAssessmentsQueryVariables = Exact<{
  roleId: Scalars['uuid'];
  userId: Scalars['uuid'];
  orgId: Scalars['uuid'];
}>;

export type CheckPendingAssessmentsQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    id: any;
    user: { __typename?: 'users'; id: any; display_name?: string | null };
    assessment_records: Array<{
      __typename?: 'assessment_record';
      id: any;
      user: { __typename?: 'users'; id: any; display_name?: string | null };
    }>;
  }>;
};

export type GetAllKpiBanksByRoleIdQueryVariables = Exact<{
  roleId: Scalars['uuid'];
}>;

export type GetAllKpiBanksByRoleIdQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{
    __typename?: 'kpi_bank';
    id: any;
    title: string;
    description: string;
  }>;
};

export type DeleteAllDashboardRemindersMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type DeleteAllDashboardRemindersMutation = {
  __typename?: 'mutation_root';
  delete_dashboard_remainders?: {
    __typename?: 'dashboard_remainders_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertDocumentTokenMutationVariables = Exact<{
  object: Document_Token_Insert_Input;
}>;

export type InsertDocumentTokenMutation = {
  __typename?: 'mutation_root';
  insert_document_token_one?: {
    __typename?: 'document_token';
    access_token: string;
  } | null;
};

export type DeleteDocumentTokenMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  token_type: Enum_Document_Token_Type_Enum;
}>;

export type DeleteDocumentTokenMutation = {
  __typename?: 'mutation_root';
  delete_document_token?: {
    __typename?: 'document_token_mutation_response';
    returning: Array<{ __typename?: 'document_token'; user_id: any }>;
  } | null;
};

export type DocumentTokenByPkQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type DocumentTokenByPkQuery = {
  __typename?: 'query_root';
  document_token_by_pk?: {
    __typename?: 'document_token';
    user_id: any;
    token_type: Enum_Document_Token_Type_Enum;
    access_token: string;
    refresh_token?: string | null;
  } | null;
};

export type LoadTimeSpentIndividualGraphsQueryVariables = Exact<{
  _in: Array<Scalars['uuid']> | Scalars['uuid'];
  _gte: Scalars['date'];
  _lte: Scalars['date'];
}>;

export type LoadTimeSpentIndividualGraphsQuery = {
  __typename?: 'query_root';
  dashboard_billable: Array<{
    __typename?: 'dashboard_billable';
    client_name?: string | null;
    title?: string | null;
    work_hours?: any | null;
    created_by?: any | null;
  }>;
};

export type GetBillableProjectIdQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _eq: Scalars['uuid'];
  org_id: Scalars['uuid'];
}>;

export type GetBillableProjectIdQuery = {
  __typename?: 'query_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    project_id: any;
    created_at: any;
    project?: {
      __typename?: 'project';
      title: string;
      project_client: { __typename?: 'project_client'; client_name: string };
    } | null;
  }>;
};

export type BilableProjectDataQueryVariables = Exact<{
  _projectID?: InputMaybe<Scalars['uuid']>;
  _userID: Scalars['uuid'];
  _gte?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  orgId: Scalars['uuid'];
}>;

export type BilableProjectDataQuery = {
  __typename?: 'query_root';
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    task_time_sheets_aggregate: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          work_hours?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        project?: {
          __typename?: 'project';
          project_client: {
            __typename?: 'project_client';
            client_name: string;
          };
        } | null;
      }>;
    };
  }>;
};

export type NonBilableProjectDataQueryVariables = Exact<{
  _lte?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _userId: Scalars['uuid'];
  orgId: Scalars['uuid'];
}>;

export type NonBilableProjectDataQuery = {
  __typename?: 'query_root';
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    task_time_sheets_aggregate: {
      __typename?: 'task_time_sheet_aggregate';
      aggregate?: {
        __typename?: 'task_time_sheet_aggregate_fields';
        sum?: {
          __typename?: 'task_time_sheet_sum_fields';
          work_hours?: any | null;
        } | null;
      } | null;
      nodes: Array<{
        __typename?: 'task_time_sheet';
        user: { __typename?: 'users'; display_name?: string | null };
        project?: {
          __typename?: 'project';
          project_client: {
            __typename?: 'project_client';
            client_name: string;
          };
        } | null;
      }>;
    };
  }>;
};

export type UserNameQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type UserNameQuery = {
  __typename?: 'query_root';
  users: Array<{ __typename?: 'users'; display_name?: string | null }>;
};

export type LeaveDataQueryVariables = Exact<{
  _lte?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _eq: Scalars['uuid'];
}>;

export type LeaveDataQuery = {
  __typename?: 'query_root';
  organization_user_leave_application: Array<{
    __typename?: 'organization_user_leave_application';
    is_approved: boolean;
    id: any;
    leaveDates_aggregate: {
      __typename?: 'organization_user_leave_dates_aggregate';
      aggregate?: {
        __typename?: 'organization_user_leave_dates_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type OrgHolidayQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['date']>;
  _eq?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['date']>;
}>;

export type OrgHolidayQuery = {
  __typename?: 'query_root';
  organization_holidays_aggregate: {
    __typename?: 'organization_holidays_aggregate';
    aggregate?: {
      __typename?: 'organization_holidays_aggregate_fields';
      count: number;
    } | null;
  };
};

export type LoadUserBasedOnManagerQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type LoadUserBasedOnManagerQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      display_name?: string | null;
      first_name?: string | null;
      id: any;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type LoadUserBasedOnOrganizationQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type LoadUserBasedOnOrganizationQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      display_name?: string | null;
      id: any;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type TaskCountQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type TaskCountQuery = {
  __typename?: 'query_root';
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
    nodes: Array<{
      __typename?: 'task';
      id: any;
      end_date_time?: any | null;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
    }>;
  };
};

export type TaskTimelyCompletedQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type TaskTimelyCompletedQuery = {
  __typename?: 'query_root';
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type TaskTimeDelayedQueryVariables = Exact<{
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type TaskTimeDelayedQuery = {
  __typename?: 'query_root';
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type GetAssessmentTypeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type GetAssessmentTypeQuery = {
  __typename?: 'query_root';
  assessment_role_details: Array<{
    __typename?: 'assessment_role_details';
    assessment: {
      __typename?: 'assessment';
      assessment_type_id: any;
      type: string;
    };
  }>;
};

export type GetAssessmentUnderTypeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type GetAssessmentUnderTypeQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; id: any }>;
};

export type GetAssessmentRecordQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type GetAssessmentRecordQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      sum?: {
        __typename?: 'assessment_record_sum_fields';
        marks?: number | null;
      } | null;
    } | null;
    nodes: Array<{ __typename?: 'assessment_record'; assessment_id: any }>;
  };
};

export type UserInOrganizationQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['String']>;
  _eq2?: InputMaybe<Scalars['String']>;
}>;

export type UserInOrganizationQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user_id?: any | null;
  }>;
};

export type GetAssessmentOnTypeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type GetAssessmentOnTypeQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; id: any }>;
};

export type GetEmprecordDataQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type GetEmprecordDataQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      sum?: {
        __typename?: 'assessment_record_sum_fields';
        marks?: number | null;
      } | null;
    } | null;
    nodes: Array<{
      __typename?: 'assessment_record';
      user: {
        __typename?: 'users';
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
  };
};

export type UserProfileDataQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type UserProfileDataQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
  }>;
};

export type GetAssessmentOnTypeAndRoleQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type GetAssessmentOnTypeAndRoleQuery = {
  __typename?: 'query_root';
  assessment: Array<{ __typename?: 'assessment'; id: any }>;
};

export type GetIndividualAssessmentAttendedDataQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type GetIndividualAssessmentAttendedDataQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      count: number;
    } | null;
  };
};

export type ScoredDataOfAssessmentQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type ScoredDataOfAssessmentQuery = {
  __typename?: 'query_root';
  assessment_record_aggregate: {
    __typename?: 'assessment_record_aggregate';
    aggregate?: {
      __typename?: 'assessment_record_aggregate_fields';
      sum?: {
        __typename?: 'assessment_record_sum_fields';
        marks?: number | null;
      } | null;
    } | null;
  };
  assessment: Array<{
    __typename?: 'assessment';
    passing_score: any;
    active_till: number;
    name: string;
  }>;
};

export type GetOrgAdminQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type GetOrgAdminQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    system_role?: string | null;
  }>;
};

export type StressGraphDataQueryVariables = Exact<{
  _lte?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _eq?: InputMaybe<Scalars['Int']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type StressGraphDataQuery = {
  __typename?: 'query_root';
  stress_data_aggregate: {
    __typename?: 'stress_data_aggregate';
    aggregate?: {
      __typename?: 'stress_data_aggregate_fields';
      count: number;
    } | null;
  };
  stress_data: Array<{
    __typename?: 'stress_data';
    id: any;
    feedback: number;
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  }>;
};

export type GetAllAssessmentTypeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type GetAllAssessmentTypeQuery = {
  __typename?: 'query_root';
  assessment: Array<{
    __typename?: 'assessment';
    assessment_type_id: any;
    type: string;
  }>;
};

export type AllUserQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type AllUserQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user_id?: any | null;
  }>;
};

export type LoadTaskDetailsForEmployeeBandwidthQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type LoadTaskDetailsForEmployeeBandwidthQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    assigned_to?: any | null;
    id: any;
    estimated_time?: any | null;
    created_at: any;
    start_date_time?: any | null;
    due_date_time?: any | null;
  }>;
};

export type AddEmployeeBandwidthDataMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Employee_Bandwidth_Data_Insert_Input>
    | Employee_Bandwidth_Data_Insert_Input
  >;
}>;

export type AddEmployeeBandwidthDataMutation = {
  __typename?: 'mutation_root';
  insert_employee_bandwidth_data?: {
    __typename?: 'employee_bandwidth_data_mutation_response';
    returning: Array<{ __typename?: 'employee_bandwidth_data'; id: any }>;
  } | null;
};

export type GetEmployeeCapacityUserDataQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetEmployeeCapacityUserDataQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    display_name?: string | null;
    employee_bandwidth_data: Array<{
      __typename?: 'employee_bandwidth_data';
      user_id: any;
      work_day: any;
      estimated_hours: any;
      task: { __typename?: 'task'; title: string };
    }>;
  }>;
};

export type GetUserCalenderIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type GetUserCalenderIdQuery = {
  __typename?: 'query_root';
  calendar: Array<{ __typename?: 'calendar'; id: any }>;
};

export type GetMeetingHoursQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _gte: Scalars['timestamptz'];
  _lte: Scalars['timestamptz'];
}>;

export type GetMeetingHoursQuery = {
  __typename?: 'query_root';
  calendar_event_material_view: Array<{
    __typename?: 'calendar_event_material_view';
    end_date_time?: any | null;
    start_date_time?: any | null;
  }>;
};

export type GetMeetingHoursForEmployeeQueryVariables = Exact<{
  attendeeId: Scalars['uuid'];
  startDateTime: Scalars['timestamptz'];
  endDateTime: Scalars['timestamptz'];
}>;

export type GetMeetingHoursForEmployeeQuery = {
  __typename?: 'query_root';
  calendar_attendee: Array<{
    __typename?: 'calendar_attendee';
    calendar_event: {
      __typename?: 'calendar_event';
      start_date_time: any;
      end_date_time: any;
    };
  }>;
};

export type InsertEmployeeCapacityBulkMutationVariables = Exact<{
  objects:
    | Array<Employee_Capacity_Insert_Input>
    | Employee_Capacity_Insert_Input;
}>;

export type InsertEmployeeCapacityBulkMutation = {
  __typename?: 'mutation_root';
  insert_employee_capacity?: {
    __typename?: 'employee_capacity_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetEmployeeLeaveApplicationFromUserIdStartDateQueryVariables =
  Exact<{
    userId: Scalars['uuid'];
    start_date: Scalars['String'];
  }>;

export type GetEmployeeLeaveApplicationFromUserIdStartDateQuery = {
  __typename?: 'query_root';
  organization_user_leave_application: Array<{
    __typename?: 'organization_user_leave_application';
    id: any;
    leave_application_status: Enum_Leave_Status_Type_Enum;
  }>;
};

export type AddLeaveDatesForOrgUserMutationVariables = Exact<{
  object: Organization_User_Leave_Dates_Insert_Input;
}>;

export type AddLeaveDatesForOrgUserMutation = {
  __typename?: 'mutation_root';
  insert_organization_user_leave_dates_one?: {
    __typename?: 'organization_user_leave_dates';
    id: any;
  } | null;
};

export type FindUserLeaveDatesByUserIdQueryVariables = Exact<{
  conditions: Organization_User_Leave_Dates_Bool_Exp;
}>;

export type FindUserLeaveDatesByUserIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_dates: Array<{
    __typename?: 'organization_user_leave_dates';
    id: any;
    created_at: any;
    updated_at: any;
    org_user_id: any;
    leave_application_id: any;
    date: string;
    leave_application?: {
      __typename?: 'organization_user_leave_application';
      id: any;
      leave_application_status: Enum_Leave_Status_Type_Enum;
    } | null;
  }>;
};

export type GetEmployeeLeaveApplicationInfoByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetEmployeeLeaveApplicationInfoByIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_application_by_pk?: {
    __typename?: 'organization_user_leave_application';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    leave_type: string;
    is_approved: boolean;
    is_active?: boolean | null;
    leave_start_date?: string | null;
    leave_end_date?: string | null;
    leave_application_status: Enum_Leave_Status_Type_Enum;
    leave_amount_in_days: number;
    leave_type_id?: any | null;
    org_leave_type?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
    } | null;
    leaveDates: Array<{
      __typename?: 'organization_user_leave_dates';
      id: any;
      created_at: any;
      updated_at: any;
      leave_application_id: any;
      org_user_id: any;
      date: string;
    }>;
    user_leave_data?: {
      __typename?: 'organization_user_leave_data';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      user_role: string;
      leave_type?: string | null;
      leave_type_id?: any | null;
      used_leave_amount: number;
      earned_leaves_amount?: any | null;
    } | null;
  } | null;
};

export type UpdateUserLeaveApplicationByIdMutationVariables = Exact<{
  leave_application_id: Scalars['uuid'];
  set?: InputMaybe<Organization_User_Leave_Application_Set_Input>;
}>;

export type UpdateUserLeaveApplicationByIdMutation = {
  __typename?: 'mutation_root';
  update_organization_user_leave_application_by_pk?: {
    __typename?: 'organization_user_leave_application';
    id: any;
    is_approved: boolean;
    is_active?: boolean | null;
    leave_application_status: Enum_Leave_Status_Type_Enum;
  } | null;
};

export type AddNewEmployeeLeaveApplicationMutationVariables = Exact<{
  object: Organization_User_Leave_Application_Insert_Input;
}>;

export type AddNewEmployeeLeaveApplicationMutation = {
  __typename?: 'mutation_root';
  insert_organization_user_leave_application_one?: {
    __typename?: 'organization_user_leave_application';
    id: any;
  } | null;
};

export type AddEmployeeLeaveApplicationFilesMutationVariables = Exact<{
  object: Organization_User_Leave_Files_Insert_Input;
}>;

export type AddEmployeeLeaveApplicationFilesMutation = {
  __typename?: 'mutation_root';
  insert_organization_user_leave_files_one?: {
    __typename?: 'organization_user_leave_files';
    id: any;
  } | null;
};

export type GetUserLeaveApplicationByUserIdAndEndDateQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  end_date: Scalars['String'];
}>;

export type GetUserLeaveApplicationByUserIdAndEndDateQuery = {
  __typename?: 'query_root';
  organization_user_leave_application: Array<{
    __typename?: 'organization_user_leave_application';
    id: any;
    leave_application_status: Enum_Leave_Status_Type_Enum;
  }>;
};

export type GetPendingLeaveApplicationsByLeaveTypeIdQueryVariables = Exact<{
  leave_type_id: Scalars['uuid'];
}>;

export type GetPendingLeaveApplicationsByLeaveTypeIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_application: Array<{
    __typename?: 'organization_user_leave_application';
    id: any;
  }>;
};

export type UpdateUserLeaveApplicationByUserIdAndYearMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  year?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Organization_User_Leave_Application_Set_Input>;
}>;

export type UpdateUserLeaveApplicationByUserIdAndYearMutation = {
  __typename?: 'mutation_root';
  update_organization_user_leave_application?: {
    __typename?: 'organization_user_leave_application_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertNewLeaveTypeMutationVariables = Exact<{
  object: Organization_Active_Leave_Types_Insert_Input;
}>;

export type InsertNewLeaveTypeMutation = {
  __typename?: 'mutation_root';
  insert_organization_active_leave_types_one?: {
    __typename?: 'organization_active_leave_types';
    id: any;
  } | null;
};

export type GetOrganizationLeaveActiveLeaveTypesByOrgIdAndLeaveTypeQueryVariables =
  Exact<{
    org_id: Scalars['uuid'];
    leave_type: Scalars['String'];
  }>;

export type GetOrganizationLeaveActiveLeaveTypesByOrgIdAndLeaveTypeQuery = {
  __typename?: 'query_root';
  organization_active_leave_types: Array<{
    __typename?: 'organization_active_leave_types';
    id: any;
  }>;
};

export type GetOrganizationLeaveTypesByLeaveIdQueryVariables = Exact<{
  leave_id: Scalars['uuid'];
}>;

export type GetOrganizationLeaveTypesByLeaveIdQuery = {
  __typename?: 'query_root';
  organization_active_leave_types_by_pk?: {
    __typename?: 'organization_active_leave_types';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    leave_type: string;
    will_carry_forward: boolean;
    will_be_earned_monthly: boolean;
    monthly_earning_amount?: any | null;
    annual_eligibility: number;
    annual_carry_forward_amount?: number | null;
    followed_leave_cycle?: any | null;
  } | null;
};

export type UpdateOrganizationLeaveTypeByLeaveTypeIdMutationVariables = Exact<{
  leave_type_id: Scalars['uuid'];
  set: Organization_Active_Leave_Types_Set_Input;
}>;

export type UpdateOrganizationLeaveTypeByLeaveTypeIdMutation = {
  __typename?: 'mutation_root';
  update_organization_active_leave_types_by_pk?: {
    __typename?: 'organization_active_leave_types';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    leave_type: string;
    will_carry_forward: boolean;
    will_be_earned_monthly: boolean;
    monthly_earning_amount?: any | null;
    annual_eligibility: number;
    annual_carry_forward_amount?: number | null;
    followed_leave_cycle?: any | null;
  } | null;
};

export type DeleteLeaveTypeByLeaveTypeIdMutationVariables = Exact<{
  leave_type_id: Scalars['uuid'];
}>;

export type DeleteLeaveTypeByLeaveTypeIdMutation = {
  __typename?: 'mutation_root';
  update_organization_user_leave_application?: {
    __typename?: 'organization_user_leave_application_mutation_response';
    affected_rows: number;
  } | null;
  delete_organization_user_leave_data?: {
    __typename?: 'organization_user_leave_data_mutation_response';
    affected_rows: number;
  } | null;
  delete_organization_active_leave_types_by_pk?: {
    __typename?: 'organization_active_leave_types';
    id: any;
  } | null;
};

export type InsertLeaveCycleMutationVariables = Exact<{
  object: Organization_Leave_Cycles_Insert_Input;
}>;

export type InsertLeaveCycleMutation = {
  __typename?: 'mutation_root';
  insert_organization_leave_cycles_one?: {
    __typename?: 'organization_leave_cycles';
    id: any;
  } | null;
};

export type GetOrganizationLeaveCycleByOrgIdAndStartingDateQueryVariables =
  Exact<{
    orgId: Scalars['uuid'];
    starting_date: Scalars['String'];
  }>;

export type GetOrganizationLeaveCycleByOrgIdAndStartingDateQuery = {
  __typename?: 'query_root';
  organization_leave_cycles: Array<{
    __typename?: 'organization_leave_cycles';
    id: any;
  }>;
};

export type GetAllTheOrganizationCyclesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllTheOrganizationCyclesQuery = {
  __typename?: 'query_root';
  organization_leave_cycles: Array<{
    __typename?: 'organization_leave_cycles';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    starting_date?: string | null;
    ending_date?: string | null;
    current_term_years?: string | null;
    current_month_date?: string | null;
  }>;
};

export type UpdateOrganizationLeaveCyclesByIdMutationVariables = Exact<{
  org_cycle_id: Scalars['uuid'];
  set: Organization_Leave_Cycles_Set_Input;
}>;

export type UpdateOrganizationLeaveCyclesByIdMutation = {
  __typename?: 'mutation_root';
  update_organization_leave_cycles_by_pk?: {
    __typename?: 'organization_leave_cycles';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    starting_date?: string | null;
    ending_date?: string | null;
    current_term_years?: string | null;
    current_month_date?: string | null;
  } | null;
};

export type GetOrganizationLeaveCycleByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetOrganizationLeaveCycleByOrgIdQuery = {
  __typename?: 'query_root';
  organization_leave_cycles: Array<{
    __typename?: 'organization_leave_cycles';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    starting_date?: string | null;
    ending_date?: string | null;
    current_term_years?: string | null;
    current_month_date?: string | null;
    org_start_date?: any | null;
    org_end_date?: any | null;
    organizations_active_leave_types: Array<{
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
    }>;
  }>;
};

export type GetAllUsersOfAnOrganizationQueryVariables = Exact<{
  org_id: Scalars['uuid'];
}>;

export type GetAllUsersOfAnOrganizationQuery = {
  __typename?: 'query_root';
  organization: Array<{
    __typename?: 'organization';
    organization_admins: Array<{
      __typename?: 'organization_admin';
      userByUserId: {
        __typename?: 'users';
        id: any;
        account?: { __typename?: 'auth_accounts'; default_role: string } | null;
      };
    }>;
    organization_employees: Array<{
      __typename?: 'organization_employee';
      user: {
        __typename?: 'users';
        id: any;
        account?: { __typename?: 'auth_accounts'; default_role: string } | null;
      };
    }>;
  }>;
};

export type AddUsersToOrganizationLeaveDataMutationVariables = Exact<{
  objects:
    | Array<Organization_User_Leave_Data_Insert_Input>
    | Organization_User_Leave_Data_Insert_Input;
}>;

export type AddUsersToOrganizationLeaveDataMutation = {
  __typename?: 'mutation_root';
  insert_organization_user_leave_data?: {
    __typename?: 'organization_user_leave_data_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddUserOrganizationLeaveDataMutationVariables = Exact<{
  object: Organization_User_Leave_Data_Insert_Input;
}>;

export type AddUserOrganizationLeaveDataMutation = {
  __typename?: 'mutation_root';
  insert_organization_user_leave_data_one?: {
    __typename?: 'organization_user_leave_data';
    id: any;
    created_at: any;
    updated_at: any;
    org_user_id: any;
    org_id: any;
    user_role: string;
    leave_type?: string | null;
    earned_leaves_amount?: any | null;
    leave_type_id?: any | null;
    used_leave_amount: number;
    leave_cycle_id?: any | null;
  } | null;
};

export type UpdateUserOrganizationLeaveDataByIdMutationVariables = Exact<{
  user_leave_data_id: Scalars['uuid'];
  set: Organization_User_Leave_Data_Set_Input;
}>;

export type UpdateUserOrganizationLeaveDataByIdMutation = {
  __typename?: 'mutation_root';
  update_organization_user_leave_data_by_pk?: {
    __typename?: 'organization_user_leave_data';
    id: any;
  } | null;
};

export type DeleteLeaveApplicationMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteLeaveApplicationMutation = {
  __typename?: 'mutation_root';
  delete_organization_user_leave_dates?: {
    __typename?: 'organization_user_leave_dates_mutation_response';
    affected_rows: number;
  } | null;
  delete_organization_user_leave_files?: {
    __typename?: 'organization_user_leave_files_mutation_response';
    affected_rows: number;
  } | null;
  delete_organization_user_leave_application_by_pk?: {
    __typename?: 'organization_user_leave_application';
    id: any;
  } | null;
};

export type GetLeaveDatesByLeaveIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetLeaveDatesByLeaveIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_dates: Array<{
    __typename?: 'organization_user_leave_dates';
    date: string;
    created_at: any;
  }>;
};

export type GetOrganizationLeaveDataByUserIdLeaveTypeIdOrdIdQueryVariables =
  Exact<{
    org_user_id: Scalars['uuid'];
    leave_type_id: Scalars['uuid'];
    org_id: Scalars['uuid'];
  }>;

export type GetOrganizationLeaveDataByUserIdLeaveTypeIdOrdIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    id: any;
    org_user_id: any;
    leave_type?: string | null;
    used_leave_amount: number;
    earned_leaves_amount?: any | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
    } | null;
  }>;
};

export type GetOrganizationUserLeaveDataByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetOrganizationUserLeaveDataByIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_data_by_pk?: {
    __typename?: 'organization_user_leave_data';
    id: any;
    org_user_id: any;
    leave_type?: string | null;
    used_leave_amount: number;
    earned_leaves_amount?: any | null;
  } | null;
};

export type GetUserLeaveDataByUserIdQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['uuid']>;
}>;

export type GetUserLeaveDataByUserIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    user_role: string;
    leave_type?: string | null;
    leave_type_id?: any | null;
    earned_leaves_amount?: any | null;
    used_leave_amount: number;
    user?: {
      __typename?: 'users';
      id: any;
      profile: Array<{ __typename?: 'profile'; additional_fields: any }>;
    } | null;
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
      organization_leave_cycle?: {
        __typename?: 'organization_leave_cycles';
        id: any;
        starting_date?: string | null;
        ending_date?: string | null;
        current_term_years?: string | null;
      } | null;
    } | null;
    leave_applications: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
    }>;
  }>;
};

export type GetAllLeaveDataByOrganizationIdQueryVariables = Exact<{
  org_id?: InputMaybe<Scalars['uuid']>;
}>;

export type GetAllLeaveDataByOrganizationIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    user_role: string;
    leave_type?: string | null;
    leave_type_id?: any | null;
    earned_leaves_amount?: any | null;
    used_leave_amount: number;
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
      organization_leave_cycle?: {
        __typename?: 'organization_leave_cycles';
        id: any;
        starting_date?: string | null;
        ending_date?: string | null;
        current_term_years?: string | null;
      } | null;
    } | null;
    leave_applications: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
    }>;
  }>;
};

export type GetAllLeaveDataOfAllOrganizationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllLeaveDataOfAllOrganizationsQuery = {
  __typename?: 'query_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    user_role: string;
    leave_type?: string | null;
    leave_type_id?: any | null;
    earned_leaves_amount?: any | null;
    used_leave_amount: number;
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
      organization_leave_cycle?: {
        __typename?: 'organization_leave_cycles';
        id: any;
        starting_date?: string | null;
        ending_date?: string | null;
        current_term_years?: string | null;
      } | null;
    } | null;
    leave_applications: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
    }>;
  }>;
};

export type GetLeaveDataOfOrganizationByLeaveCycleIdQueryVariables = Exact<{
  leave_cycle_id: Scalars['uuid'];
}>;

export type GetLeaveDataOfOrganizationByLeaveCycleIdQuery = {
  __typename?: 'query_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    user_role: string;
    leave_type?: string | null;
    leave_type_id?: any | null;
    earned_leaves_amount?: any | null;
    used_leave_amount: number;
    last_year_carry_forward: number;
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
      organization_leave_cycle?: {
        __typename?: 'organization_leave_cycles';
        id: any;
        starting_date?: string | null;
        ending_date?: string | null;
        current_term_years?: string | null;
      } | null;
    } | null;
    leave_applications: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      leaveDates: Array<{
        __typename?: 'organization_user_leave_dates';
        date: string;
      }>;
    }>;
  }>;
};

export type CreateLiveCallRequestMutationVariables = Exact<{
  object: Live_Call_Request_Insert_Input;
}>;

export type CreateLiveCallRequestMutation = {
  __typename?: 'mutation_root';
  insert_live_call_request_one?: {
    __typename?: 'live_call_request';
    id: any;
    live_call_id: any;
    request_status: Enum_Live_Call_Request_Status_Enum;
    created_by: any;
  } | null;
};

export type GetLiveCallRequestByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetLiveCallRequestByIdQuery = {
  __typename?: 'query_root';
  live_call_request_by_pk?: {
    __typename?: 'live_call_request';
    created_by: any;
    live_call_id: any;
    user_id: any;
  } | null;
};

export type UpdateLiveCallRequestByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  object: Live_Call_Request_Set_Input;
}>;

export type UpdateLiveCallRequestByIdMutation = {
  __typename?: 'mutation_root';
  update_live_call_request_by_pk?: {
    __typename?: 'live_call_request';
    created_by: any;
    live_call_id: any;
    user_id: any;
  } | null;
};

export type GetLiveCallRequestByLiveCallIdQueryVariables = Exact<{
  liveCallId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetLiveCallRequestByLiveCallIdQuery = {
  __typename?: 'query_root';
  live_call_request: Array<{
    __typename?: 'live_call_request';
    id: any;
    created_by: any;
    user_id: any;
    request_status: Enum_Live_Call_Request_Status_Enum;
  }>;
};

export type CreateLiveCallUserMutationVariables = Exact<{
  object: Live_Call_User_Insert_Input;
}>;

export type CreateLiveCallUserMutation = {
  __typename?: 'mutation_root';
  insert_live_call_user_one?: {
    __typename?: 'live_call_user';
    id: any;
    created_by: any;
  } | null;
};

export type GetLiveCallActiveUsersCountQueryVariables = Exact<{
  callId: Scalars['uuid'];
}>;

export type GetLiveCallActiveUsersCountQuery = {
  __typename?: 'query_root';
  live_call_user_aggregate: {
    __typename?: 'live_call_user_aggregate';
    aggregate?: {
      __typename?: 'live_call_user_aggregate_fields';
      count: number;
    } | null;
  };
};

export type UpdateLiveCallUserByCallIdMutationVariables = Exact<{
  liveCallId: Scalars['uuid'];
  userId: Scalars['uuid'];
  object: Live_Call_User_Set_Input;
}>;

export type UpdateLiveCallUserByCallIdMutation = {
  __typename?: 'mutation_root';
  update_live_call_user?: {
    __typename?: 'live_call_user_mutation_response';
    returning: Array<{
      __typename?: 'live_call_user';
      id: any;
      status: Enum_Live_Call_User_Status_Enum;
    }>;
  } | null;
};

export type UpdateLiveCallUsersByCallIdMutationVariables = Exact<{
  liveCallId: Scalars['uuid'];
  object: Live_Call_User_Set_Input;
}>;

export type UpdateLiveCallUsersByCallIdMutation = {
  __typename?: 'mutation_root';
  update_live_call_user?: {
    __typename?: 'live_call_user_mutation_response';
    returning: Array<{
      __typename?: 'live_call_user';
      id: any;
      status: Enum_Live_Call_User_Status_Enum;
    }>;
  } | null;
};

export type GetLiveCallUsersQueryVariables = Exact<{
  liveCallId: Scalars['uuid'];
}>;

export type GetLiveCallUsersQuery = {
  __typename?: 'query_root';
  live_call_user: Array<{
    __typename?: 'live_call_user';
    id: any;
    user_id: any;
  }>;
};

export type InsertLiveCallMutationVariables = Exact<{
  object: Live_Call_Insert_Input;
}>;

export type InsertLiveCallMutation = {
  __typename?: 'mutation_root';
  insert_live_call_one?: { __typename?: 'live_call'; id: any } | null;
};

export type GetLiveCallByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetLiveCallByIdQuery = {
  __typename?: 'query_root';
  live_call?: {
    __typename?: 'live_call';
    id: any;
    created_by: any;
    live_call_status: Enum_Live_Call_Status_Enum;
    end_date_time?: any | null;
  } | null;
};

export type UpdateLiveCallByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  object: Live_Call_Set_Input;
}>;

export type UpdateLiveCallByIdMutation = {
  __typename?: 'mutation_root';
  live_call?: {
    __typename?: 'live_call';
    id: any;
    end_date_time?: any | null;
  } | null;
};

export type AddSchedularForAMeetingEventMutationVariables = Exact<{
  newSchedular: Meeting_Call_Schedular_Insert_Input;
}>;

export type AddSchedularForAMeetingEventMutation = {
  __typename?: 'mutation_root';
  insert_meeting_call_schedular_one?: {
    __typename?: 'meeting_call_schedular';
    id: any;
    calendar_event_id: any;
    meeting_event_id: any;
    schedular_id?: string | null;
  } | null;
};

export type DeleteMeetingSchedularsForAMeetingMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  calendarEventId?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteMeetingSchedularsForAMeetingMutation = {
  __typename?: 'mutation_root';
  delete_meeting_call_schedular?: {
    __typename?: 'meeting_call_schedular_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMeetingSchedularsByConditionMutationVariables = Exact<{
  condition: Meeting_Call_Schedular_Bool_Exp;
}>;

export type DeleteMeetingSchedularsByConditionMutation = {
  __typename?: 'mutation_root';
  delete_meeting_call_schedular?: {
    __typename?: 'meeting_call_schedular_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMeetingSchedularByCalendarEventIdMutationVariables = Exact<{
  calendarEventId?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteMeetingSchedularByCalendarEventIdMutation = {
  __typename?: 'mutation_root';
  delete_meeting_call_schedular?: {
    __typename?: 'meeting_call_schedular_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetAllTheSchedularForAMeetingByMeetingIdQueryVariables = Exact<{
  meetingId: Scalars['uuid'];
  calendarEventId: Scalars['uuid'];
}>;

export type GetAllTheSchedularForAMeetingByMeetingIdQuery = {
  __typename?: 'query_root';
  meeting_call_schedular: Array<{
    __typename?: 'meeting_call_schedular';
    id: any;
    calendar_event_id: any;
    meeting_event_id: any;
    schedular_id?: string | null;
  }>;
};

export type DeleteMeetingCallByserByUserIdAndMeetingIdMutationVariables =
  Exact<{
    user_id: Scalars['uuid'];
    meeting_call_id: Scalars['uuid'];
  }>;

export type DeleteMeetingCallByserByUserIdAndMeetingIdMutation = {
  __typename?: 'mutation_root';
  delete_calendar_meeting_call_user?: {
    __typename?: 'calendar_meeting_call_user_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMeetingRoomByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteMeetingRoomByIdMutation = {
  __typename?: 'mutation_root';
  delete_meeting_room_by_pk?: { __typename?: 'meeting_room'; id: any } | null;
};

export type GetMeetingRoomByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetMeetingRoomByIdQuery = {
  __typename?: 'query_root';
  meeting_room?: {
    __typename?: 'meeting_room';
    id: any;
    status: Enum_Meeting_Room_Status_Enum;
    type: Enum_Meeting_Room_Type_Enum;
    created_by: any;
    created_at: any;
    organization_id: any;
    updated_at: any;
    createdByUser: { __typename?: 'users'; first_name?: string | null };
    meeting_room_users: Array<{
      __typename?: 'meeting_room_users';
      user_id: any;
      status?: Enum_Meeting_Room_User_State_Enum | null;
    }>;
  } | null;
};

export type InsertMeetingRoomMutationVariables = Exact<{
  object: Meeting_Room_Insert_Input;
}>;

export type InsertMeetingRoomMutation = {
  __typename?: 'mutation_root';
  insert_meeting_room_one?: { __typename?: 'meeting_room'; id: any } | null;
};

export type AddMeetingRoomUsersMutationVariables = Exact<{
  objects:
    | Array<Meeting_Room_Users_Insert_Input>
    | Meeting_Room_Users_Insert_Input;
}>;

export type AddMeetingRoomUsersMutation = {
  __typename?: 'mutation_root';
  insert_meeting_room_users?: {
    __typename?: 'meeting_room_users_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteAMeetingRoomUserMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  meeting_room_id: Scalars['uuid'];
}>;

export type DeleteAMeetingRoomUserMutation = {
  __typename?: 'mutation_root';
  delete_meeting_room_users_by_pk?: {
    __typename?: 'meeting_room_users';
    user_id: any;
  } | null;
};

export type UpdateMeetingRoomUsersMutationVariables = Exact<{
  data: Meeting_Room_Users_Set_Input;
  user_id: Scalars['uuid'];
  meeting_room_id: Scalars['uuid'];
}>;

export type UpdateMeetingRoomUsersMutation = {
  __typename?: 'mutation_root';
  update_meeting_room_users?: {
    __typename?: 'meeting_room_users_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetMeetingRoomUserByPkQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  meeting_room_id: Scalars['uuid'];
}>;

export type GetMeetingRoomUserByPkQuery = {
  __typename?: 'query_root';
  meeting_room_user?: {
    __typename?: 'meeting_room_users';
    user_id: any;
    meeting_room_id: any;
    status?: Enum_Meeting_Room_User_State_Enum | null;
    created_at?: any | null;
    added_by: any;
    updated_at?: any | null;
  } | null;
};

export type CreateNotificationMutationVariables = Exact<{
  object: Notifications_Insert_Input;
}>;

export type CreateNotificationMutation = {
  __typename?: 'mutation_root';
  insert_notifications_one?: {
    __typename?: 'notifications';
    id: any;
    is_read: boolean;
    created_by?: any | null;
    message: string;
  } | null;
};

export type DeleteNotificationByResourceIdMutationVariables = Exact<{
  resourceId: Scalars['uuid'];
}>;

export type DeleteNotificationByResourceIdMutation = {
  __typename?: 'mutation_root';
  delete_notifications?: {
    __typename?: 'notifications_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetDepartmentByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetDepartmentByIdQuery = {
  __typename?: 'query_root';
  department?: {
    __typename?: 'department';
    id: any;
    manager_id?: any | null;
  } | null;
};

export type CreateDepartmentEmployeeMutationVariables = Exact<{
  object: Department_Employee_Insert_Input;
}>;

export type CreateDepartmentEmployeeMutation = {
  __typename?: 'mutation_root';
  insert_department_employee_one?: {
    __typename?: 'department_employee';
    department_id: any;
    user_id: any;
  } | null;
};

export type CreateDepartmentMutationVariables = Exact<{
  object: Department_Insert_Input;
}>;

export type CreateDepartmentMutation = {
  __typename?: 'mutation_root';
  insert_department_one?: {
    __typename?: 'department';
    id: any;
    name: string;
    created_by: any;
    manager_id?: any | null;
    organization_id: any;
  } | null;
};

export type CreateOrganizationEmployeeMutationVariables = Exact<{
  object: Organization_Employee_Insert_Input;
}>;

export type CreateOrganizationEmployeeMutation = {
  __typename?: 'mutation_root';
  insert_organization_employee_one?: {
    __typename?: 'organization_employee';
    manager_id?: any | null;
    organization_id: any;
    user_id: any;
  } | null;
};

export type GetOrgEmployeesQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;

export type GetOrgEmployeesQuery = {
  __typename?: 'query_root';
  organization_employee_aggregate: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
  organization_employee: Array<{
    __typename?: 'organization_employee';
    user_id: any;
    user: {
      __typename?: 'users';
      display_name?: string | null;
      profile: Array<{ __typename?: 'profile'; additional_fields: any }>;
    };
  }>;
};

export type GetOrganizationEmployeesPaginatedQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrganizationEmployeesPaginatedQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user_id?: any | null;
    user?: { __typename?: 'users'; display_name?: string | null } | null;
  }>;
};

export type DeleteEmployeeCapacityMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteEmployeeCapacityMutation = {
  __typename?: 'mutation_root';
  delete_employee_capacity?: {
    __typename?: 'employee_capacity_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetOrganizationEmployeeQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetOrganizationEmployeeQuery = {
  __typename?: 'query_root';
  organization_employee: Array<{
    __typename?: 'organization_employee';
    user_id: any;
    organization_id: any;
    manager_id?: any | null;
  }>;
};

export type GetOrgEmployeeByPkQueryVariables = Exact<{
  userId: Scalars['uuid'];
  orgId: Scalars['uuid'];
}>;

export type GetOrgEmployeeByPkQuery = {
  __typename?: 'query_root';
  organization_employee_by_pk?: {
    __typename?: 'organization_employee';
    manager_id?: any | null;
    user_id: any;
    organization_id: any;
  } | null;
};

export type UpdateEmployeeRoleMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
}>;

export type UpdateEmployeeRoleMutation = {
  __typename?: 'mutation_root';
  update_profile?: {
    __typename?: 'profile_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateEmployeeManagerMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  managerId?: InputMaybe<Scalars['uuid']>;
}>;

export type UpdateEmployeeManagerMutation = {
  __typename?: 'mutation_root';
  update_organization_employee?: {
    __typename?: 'organization_employee_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateEmployeeDepartmentMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  departmentId?: InputMaybe<Scalars['uuid']>;
}>;

export type UpdateEmployeeDepartmentMutation = {
  __typename?: 'mutation_root';
  update_department_employee?: {
    __typename?: 'department_employee_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteOrgAdminMutationVariables = Exact<{
  orgId: Scalars['uuid'];
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteOrgAdminMutation = {
  __typename?: 'mutation_root';
  delete_organization_admin?: {
    __typename?: 'organization_admin_mutation_response';
    affected_rows: number;
  } | null;
};

export type TransferOrgOwnerShipMutationVariables = Exact<{
  orgId: Scalars['uuid'];
  adminId: Scalars['uuid'];
  ownerState?: InputMaybe<Scalars['Boolean']>;
}>;

export type TransferOrgOwnerShipMutation = {
  __typename?: 'mutation_root';
  update_organization_admin?: {
    __typename?: 'organization_admin_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetAllOrgAdminsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllOrgAdminsQuery = {
  __typename?: 'query_root';
  organization_admin: Array<{
    __typename?: 'organization_admin';
    id: any;
    user_id: any;
    organization_id: any;
  }>;
};

export type GetOrganizationAdminQueryVariables = Exact<{
  userId: Scalars['uuid'];
  orgId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetOrganizationAdminQuery = {
  __typename?: 'query_root';
  organization_admin: Array<{
    __typename?: 'organization_admin';
    user_id: any;
    organization_id: any;
    is_owner: boolean;
  }>;
};

export type GetOrganizationAdminWhoIsOwnerQueryVariables = Exact<{
  userId: Scalars['uuid'];
  orgId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetOrganizationAdminWhoIsOwnerQuery = {
  __typename?: 'query_root';
  organization_admin: Array<{
    __typename?: 'organization_admin';
    user_id: any;
    organization_id: any;
    is_owner: boolean;
  }>;
};

export type UpdateOrgAdminMutationVariables = Exact<{
  orgId: Scalars['uuid'];
  adminId: Scalars['uuid'];
  set: Organization_Admin_Set_Input;
}>;

export type UpdateOrgAdminMutation = {
  __typename?: 'mutation_root';
  update_organization_admin?: {
    __typename?: 'organization_admin_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateOrganizationAdminMutationVariables = Exact<{
  object: Organization_Admin_Insert_Input;
}>;

export type CreateOrganizationAdminMutation = {
  __typename?: 'mutation_root';
  insert_organization_admin_one?: {
    __typename?: 'organization_admin';
    id: any;
    is_owner: boolean;
    organization_id: any;
  } | null;
};

export type GetOrganizationLocationQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetOrganizationLocationQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    id: any;
    title: string;
    timezone_name: string;
  }>;
};

export type DeleteOrganizationLocationMutationVariables = Exact<{
  locationId: Scalars['uuid'];
}>;

export type DeleteOrganizationLocationMutation = {
  __typename?: 'mutation_root';
  delete_organization_locations?: {
    __typename?: 'organization_locations_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateOrganizationLocationMutationVariables = Exact<{
  object: Organization_Locations_Insert_Input;
}>;

export type CreateOrganizationLocationMutation = {
  __typename?: 'mutation_root';
  insert_organization_locations_one?: {
    __typename?: 'organization_locations';
    id: any;
    title: string;
    timezone_name: string;
  } | null;
};

export type GetOrganizationRoleByIdQueryVariables = Exact<{
  organizationRoleId: Scalars['uuid'];
}>;

export type GetOrganizationRoleByIdQuery = {
  __typename?: 'query_root';
  organization_roles?: {
    __typename?: 'organization_roles';
    id: any;
    role_title: string;
    system_role: string;
  } | null;
};

export type GetOrganizationRoleForOrgAdminQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;

export type GetOrganizationRoleForOrgAdminQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{ __typename?: 'organization_roles'; id: any }>;
};

export type GetProfileCountByOrgRoleQueryVariables = Exact<{
  roleId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetProfileCountByOrgRoleQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    profiles: Array<{ __typename?: 'profile'; id: any }>;
  }>;
};

export type DeleteRoleMutationVariables = Exact<{
  roleId: Scalars['uuid'];
}>;

export type DeleteRoleMutation = {
  __typename?: 'mutation_root';
  delete_organization_roles?: {
    __typename?: 'organization_roles_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateOrganizationRoleMutationVariables = Exact<{
  object: Organization_Roles_Insert_Input;
}>;

export type CreateOrganizationRoleMutation = {
  __typename?: 'mutation_root';
  insert_organization_roles_one?: {
    __typename?: 'organization_roles';
    id: any;
  } | null;
};

export type InsertOrganizationGetInfoMutationVariables = Exact<{
  object: Organization_Insert_Input;
}>;

export type InsertOrganizationGetInfoMutation = {
  __typename?: 'mutation_root';
  insert_organization_one?: {
    __typename?: 'organization';
    id: any;
    owned_by: any;
    last_modified_by: any;
    address_id?: any | null;
    organization_name: string;
    created_at: any;
  } | null;
};

export type UpdateOrgOwnedByMutationVariables = Exact<{
  orgId: Scalars['uuid'];
  prevUserId: Scalars['uuid'];
  newUserId: Scalars['uuid'];
}>;

export type UpdateOrgOwnedByMutation = {
  __typename?: 'mutation_root';
  update_organization?: {
    __typename?: 'organization_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetOrganizationByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetOrganizationByIdQuery = {
  __typename?: 'query_root';
  organization_by_pk?: {
    __typename?: 'organization';
    id: any;
    organization_name: string;
    owned_by: any;
    timezone_name: string;
    additional_fields: any;
  } | null;
};

export type GetAllOrganizationIdsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllOrganizationIdsQuery = {
  __typename?: 'query_root';
  organization: Array<{ __typename?: 'organization'; id: any }>;
};

export type UpdateOrganizationByIdMutationVariables = Exact<{
  orgId: Scalars['uuid'];
  set: Organization_Set_Input;
}>;

export type UpdateOrganizationByIdMutation = {
  __typename?: 'mutation_root';
  update_organization?: {
    __typename?: 'organization_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertOrganizationHolidaysMutationVariables = Exact<{
  objects:
    | Array<Organization_Holidays_Insert_Input>
    | Organization_Holidays_Insert_Input;
}>;

export type InsertOrganizationHolidaysMutation = {
  __typename?: 'mutation_root';
  insert_organization_holidays?: {
    __typename?: 'organization_holidays_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateNewKpiMutationVariables = Exact<{
  objects?: InputMaybe<Array<Kpi_Bank_Insert_Input> | Kpi_Bank_Insert_Input>;
}>;

export type CreateNewKpiMutation = {
  __typename?: 'mutation_root';
  insert_kpi_bank?: {
    __typename?: 'kpi_bank_mutation_response';
    returning: Array<{ __typename?: 'kpi_bank'; id: any }>;
  } | null;
};

export type UpdateNewKpiMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _set?: InputMaybe<Kpi_Bank_Set_Input>;
}>;

export type UpdateNewKpiMutation = {
  __typename?: 'mutation_root';
  update_kpi_bank?: {
    __typename?: 'kpi_bank_mutation_response';
    returning: Array<{ __typename?: 'kpi_bank'; id: any }>;
  } | null;
};

export type UpdateKpiRolesMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _set?: InputMaybe<KpiWithRole_Set_Input>;
}>;

export type UpdateKpiRolesMutation = {
  __typename?: 'mutation_root';
  update_kpiWithRole?: {
    __typename?: 'kpiWithRole_mutation_response';
    returning: Array<{ __typename?: 'kpiWithRole'; id: any }>;
  } | null;
};

export type InsertKpiRolesMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<KpiWithRole_Insert_Input> | KpiWithRole_Insert_Input
  >;
}>;

export type InsertKpiRolesMutation = {
  __typename?: 'mutation_root';
  insert_kpiWithRole?: {
    __typename?: 'kpiWithRole_mutation_response';
    returning: Array<{ __typename?: 'kpiWithRole'; id: any }>;
  } | null;
};

export type InsertKpiDeptMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<KpiWithDept_Insert_Input> | KpiWithDept_Insert_Input
  >;
}>;

export type InsertKpiDeptMutation = {
  __typename?: 'mutation_root';
  insert_kpiWithDept?: {
    __typename?: 'kpiWithDept_mutation_response';
    returning: Array<{ __typename?: 'kpiWithDept'; id: any }>;
  } | null;
};

export type DeleteKpiRolesMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteKpiRolesMutation = {
  __typename?: 'mutation_root';
  delete_kpiWithRole?: {
    __typename?: 'kpiWithRole_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteKpiDeptMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteKpiDeptMutation = {
  __typename?: 'mutation_root';
  delete_kpiWithDept?: {
    __typename?: 'kpiWithDept_mutation_response';
    affected_rows: number;
  } | null;
};

export type Delete_AllKpiRolesMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Delete_AllKpiRolesMutation = {
  __typename?: 'mutation_root';
  delete_kpiWithRole?: {
    __typename?: 'kpiWithRole_mutation_response';
    affected_rows: number;
  } | null;
};

export type Delete_AllKpidDeptMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Delete_AllKpidDeptMutation = {
  __typename?: 'mutation_root';
  delete_kpiWithDept?: {
    __typename?: 'kpiWithDept_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateNewGoalsMutationVariables = Exact<{
  objects?: InputMaybe<Array<Goals_Insert_Input> | Goals_Insert_Input>;
}>;

export type CreateNewGoalsMutation = {
  __typename?: 'mutation_root';
  insert_goals?: {
    __typename?: 'goals_mutation_response';
    returning: Array<{ __typename?: 'goals'; id: any }>;
  } | null;
};

export type CreateNewGoalKpiMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalWithKpi_Insert_Input> | GoalWithKpi_Insert_Input
  >;
}>;

export type CreateNewGoalKpiMutation = {
  __typename?: 'mutation_root';
  insert_goalWithKpi?: {
    __typename?: 'goalWithKpi_mutation_response';
    returning: Array<{ __typename?: 'goalWithKpi'; id: any }>;
  } | null;
};

export type AddNewGoalKpiMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalWithKpi_Insert_Input> | GoalWithKpi_Insert_Input
  >;
}>;

export type AddNewGoalKpiMutation = {
  __typename?: 'mutation_root';
  insert_goalWithKpi?: {
    __typename?: 'goalWithKpi_mutation_response';
    returning: Array<{ __typename?: 'goalWithKpi'; id: any }>;
  } | null;
};

export type Delete_AllGoalWithKpiMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Delete_AllGoalWithKpiMutation = {
  __typename?: 'mutation_root';
  delete_goalWithKpi?: {
    __typename?: 'goalWithKpi_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateNewGoalsMutationVariables = Exact<{
  _set?: InputMaybe<Goals_Set_Input>;
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type UpdateNewGoalsMutation = {
  __typename?: 'mutation_root';
  update_goals?: {
    __typename?: 'goals_mutation_response';
    returning: Array<{ __typename?: 'goals'; id: any }>;
  } | null;
};

export type DeleteGoalDeptsMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteGoalDeptsMutation = {
  __typename?: 'mutation_root';
  delete_goalsWithDepts?: {
    __typename?: 'goalsWithDepts_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteGoalRolesMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteGoalRolesMutation = {
  __typename?: 'mutation_root';
  delete_goalsWithRoles?: {
    __typename?: 'goalsWithRoles_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteGoalFreqMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteGoalFreqMutation = {
  __typename?: 'mutation_root';
  delete_goalsWithFreqs?: {
    __typename?: 'goalsWithFreqs_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertGoalDeptMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalsWithDepts_Insert_Input> | GoalsWithDepts_Insert_Input
  >;
}>;

export type InsertGoalDeptMutation = {
  __typename?: 'mutation_root';
  insert_goalsWithDepts?: {
    __typename?: 'goalsWithDepts_mutation_response';
    returning: Array<{ __typename?: 'goalsWithDepts'; id: any }>;
  } | null;
};

export type InsertGoalRolesMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalsWithRoles_Insert_Input> | GoalsWithRoles_Insert_Input
  >;
}>;

export type InsertGoalRolesMutation = {
  __typename?: 'mutation_root';
  insert_goalsWithRoles?: {
    __typename?: 'goalsWithRoles_mutation_response';
    returning: Array<{ __typename?: 'goalsWithRoles'; id: any }>;
  } | null;
};

export type InsertGoalFreqsMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalsWithFreqs_Insert_Input> | GoalsWithFreqs_Insert_Input
  >;
}>;

export type InsertGoalFreqsMutation = {
  __typename?: 'mutation_root';
  insert_goalsWithFreqs?: {
    __typename?: 'goalsWithFreqs_mutation_response';
    returning: Array<{ __typename?: 'goalsWithFreqs'; id: any }>;
  } | null;
};

export type CreateNewPerformanceReviewMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Performance_Review_Settings_Insert_Input>
    | Performance_Review_Settings_Insert_Input
  >;
}>;

export type CreateNewPerformanceReviewMutation = {
  __typename?: 'mutation_root';
  insert_performance_review_settings?: {
    __typename?: 'performance_review_settings_mutation_response';
    returning: Array<{ __typename?: 'performance_review_settings'; id: any }>;
  } | null;
};

export type GetGoalIdsWithTimePeriodQueryVariables = Exact<{
  _ilike: Scalars['String'];
}>;

export type GetGoalIdsWithTimePeriodQuery = {
  __typename?: 'query_root';
  goals: Array<{ __typename?: 'goals'; id: any }>;
};

export type CreateNewPrWithPeriodMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Performance_Review_Setting_With_Goals_Insert_Input>
    | Performance_Review_Setting_With_Goals_Insert_Input
  >;
}>;

export type CreateNewPrWithPeriodMutation = {
  __typename?: 'mutation_root';
  insert_performance_review_setting_With_Goals?: {
    __typename?: 'performance_review_setting_With_Goals_mutation_response';
    returning: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      id: any;
    }>;
  } | null;
};

export type UpdateNewPerformanceReviewMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _set?: InputMaybe<Performance_Review_Settings_Set_Input>;
}>;

export type UpdateNewPerformanceReviewMutation = {
  __typename?: 'mutation_root';
  update_performance_review_settings?: {
    __typename?: 'performance_review_settings_mutation_response';
    returning: Array<{ __typename?: 'performance_review_settings'; id: any }>;
  } | null;
};

export type Delete_PerformanceReviewGoalIdMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Delete_PerformanceReviewGoalIdMutation = {
  __typename?: 'mutation_root';
  delete_performance_review_setting_With_Goals?: {
    __typename?: 'performance_review_setting_With_Goals_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateNewPrGoalListsMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Performance_Review_Setting_GoalsList_Insert_Input>
    | Performance_Review_Setting_GoalsList_Insert_Input
  >;
}>;

export type CreateNewPrGoalListsMutation = {
  __typename?: 'mutation_root';
  insert_performance_review_setting_GoalsList?: {
    __typename?: 'performance_review_setting_GoalsList_mutation_response';
    returning: Array<{
      __typename?: 'performance_review_setting_GoalsList';
      id: any;
    }>;
  } | null;
};

export type Delete_PerformanceReviewAllGoalsMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Delete_PerformanceReviewAllGoalsMutation = {
  __typename?: 'mutation_root';
  delete_performance_review_setting_GoalsList?: {
    __typename?: 'performance_review_setting_GoalsList_mutation_response';
    affected_rows: number;
  } | null;
  delete_performance_review_setting_With_Goals?: {
    __typename?: 'performance_review_setting_With_Goals_mutation_response';
    returning: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      id: any;
    }>;
  } | null;
};

export type Get_PerformanceReviewStatusByPerformanceIdQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_PerformanceReviewStatusByPerformanceIdQuery = {
  __typename?: 'query_root';
  performanceReviewStatus: Array<{
    __typename?: 'performanceReviewStatus';
    id: any;
  }>;
};

export type Get_PerformanceReviewWithTimePeriodQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_PerformanceReviewWithTimePeriodQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
    frequency: string;
    instruction: string;
    organization_Id: any;
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      action: string;
      dueDate: any;
      id: any;
      startDate: any;
      status: string;
      title: string;
      year: string;
      preformance_Id: any;
    }>;
  }>;
};

export type Get_ListOfGoalWithRolesQueryVariables = Exact<{
  id: Scalars['uuid'];
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type Get_ListOfGoalWithRolesQuery = {
  __typename?: 'query_root';
  organization_roles_by_pk?: {
    __typename?: 'organization_roles';
    goalsWithRoles: Array<{
      __typename?: 'goalsWithRoles';
      goal: {
        __typename?: 'goals';
        description: string;
        id: any;
        timePeriodic?: string | null;
        title: string;
        weightage_s?: string | null;
        goalWithKpis: Array<{
          __typename?: 'goalWithKpi';
          kpi_bank: {
            __typename?: 'kpi_bank';
            description: string;
            title: string;
            id: any;
          };
        }>;
      };
    }>;
  } | null;
};

export type Get_PerformanceReviewDetailQueryVariables = Exact<{
  _orgId: Scalars['uuid'];
  _ilike: Scalars['String'];
  _roleId: Scalars['uuid'];
  _year: Scalars['String'];
  time_period: Scalars['String'];
}>;

export type Get_PerformanceReviewDetailQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
    frequency: string;
    instruction: string;
    organization_Id: any;
    year: string;
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      id: any;
      dueDate: any;
      action: string;
      startDate: any;
      status: string;
      title: string;
      year: string;
      performance_review_setting_GoalsLists: Array<{
        __typename?: 'performance_review_setting_GoalsList';
        performance_Period_Id: any;
        goal: {
          __typename?: 'goals';
          description: string;
          id: any;
          timePeriodic?: string | null;
          title: string;
          weightage_s?: string | null;
          goalsWithRoles: Array<{
            __typename?: 'goalsWithRoles';
            role_weightage?: any | null;
          }>;
          goalWithKpis: Array<{
            __typename?: 'goalWithKpi';
            kpi_bank: {
              __typename?: 'kpi_bank';
              description: string;
              title: string;
              id: any;
              open_ended_kpi?: boolean | null;
            };
          }>;
        };
      }>;
    }>;
  }>;
};

export type Insert_PerformaceReview_With_GoalListMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Performance_Review_Setting_GoalsList_Insert_Input>
    | Performance_Review_Setting_GoalsList_Insert_Input
  >;
}>;

export type Insert_PerformaceReview_With_GoalListMutation = {
  __typename?: 'mutation_root';
  insert_performance_review_setting_GoalsList?: {
    __typename?: 'performance_review_setting_GoalsList_mutation_response';
    returning: Array<{
      __typename?: 'performance_review_setting_GoalsList';
      id: any;
    }>;
  } | null;
};

export type Create_SelfReviewByUserMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Performance_Review_ByUser_Insert_Input>
    | Performance_Review_ByUser_Insert_Input
  >;
}>;

export type Create_SelfReviewByUserMutation = {
  __typename?: 'mutation_root';
  insert_performance_review_byUser?: {
    __typename?: 'performance_review_byUser_mutation_response';
    returning: Array<{ __typename?: 'performance_review_byUser'; id: any }>;
  } | null;
};

export type Insert_UserPerformanceReviewStatusMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<PerformanceReviewStatus_Insert_Input>
    | PerformanceReviewStatus_Insert_Input
  >;
}>;

export type Insert_UserPerformanceReviewStatusMutation = {
  __typename?: 'mutation_root';
  insert_performanceReviewStatus?: {
    __typename?: 'performanceReviewStatus_mutation_response';
    returning: Array<{ __typename?: 'performanceReviewStatus'; id: any }>;
  } | null;
};

export type Insert_Peer_DataMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<User_Peer_Review_Insert_Input> | User_Peer_Review_Insert_Input
  >;
}>;

export type Insert_Peer_DataMutation = {
  __typename?: 'mutation_root';
  insert_User_Peer_Review?: {
    __typename?: 'User_Peer_Review_mutation_response';
    returning: Array<{ __typename?: 'User_Peer_Review'; id: any }>;
  } | null;
};

export type Update_Peer_DataMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _set?: InputMaybe<User_Peer_Review_Set_Input>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type Update_Peer_DataMutation = {
  __typename?: 'mutation_root';
  update_User_Peer_Review?: {
    __typename?: 'User_Peer_Review_mutation_response';
    returning: Array<{ __typename?: 'User_Peer_Review'; id: any }>;
  } | null;
};

export type Get_User_ManagerIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_User_ManagerIdQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    organization_employees: Array<{
      __typename?: 'organization_employee';
      manager_id?: any | null;
    }>;
  }>;
};

export type Check_Peer_AddedQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
  _eq1: Scalars['uuid'];
}>;

export type Check_Peer_AddedQuery = {
  __typename?: 'query_root';
  User_Peer_Review: Array<{ __typename?: 'User_Peer_Review'; id: any }>;
};

export type Get_UsersSelf_ReviewQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
  time_period: Scalars['String'];
  role_id: Scalars['uuid'];
}>;

export type Get_UsersSelf_ReviewQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
    performance_Id: any;
    timePeriod?: string | null;
    performance_timePeriod_Id: any;
    performance_review_byUser_WithGoals: Array<{
      __typename?: 'performance_review_byUser_WithGoal';
      goal_Marks?: any | null;
      goal_Id: any;
      id: any;
      goal: {
        __typename?: 'goals';
        title: string;
        weightage_s?: string | null;
        goalsWithRoles: Array<{
          __typename?: 'goalsWithRoles';
          role_weightage?: any | null;
        }>;
      };
    }>;
  }>;
};

export type Get_PeersReview_Of_UserQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type Get_PeersReview_Of_UserQuery = {
  __typename?: 'query_root';
  User_Peer_Review: Array<{
    __typename?: 'User_Peer_Review';
    id: any;
    peer_Id: any;
    status?: string | null;
    timePeriod?: string | null;
    user_Id: any;
    performance_review_byUser?: {
      __typename?: 'performance_review_byUser';
      id: any;
      performance_Id: any;
      performance_timePeriod_Id: any;
      performance_review_byUser_WithGoals: Array<{
        __typename?: 'performance_review_byUser_WithGoal';
        goal_Marks?: any | null;
        goal_Id: any;
        id: any;
        goal: {
          __typename?: 'goals';
          title: string;
          weightage_s?: string | null;
        };
      }>;
    } | null;
  }>;
};

export type Get_UsersDetailQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_UsersDetailQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
        id: any;
      } | null;
    }>;
  }>;
};

export type Download_PerformanceReviewByUserQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Download_PerformanceReviewByUserQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
    user_Id: any;
    peer_Id?: any | null;
    timePeriod?: string | null;
    performance_Id: any;
    performance_timePeriod_Id: any;
    performance_review_setting_With_Goal: {
      __typename?: 'performance_review_setting_With_Goals';
      title: string;
      dueDate: any;
    };
  }>;
  performance_review_byUser_WithGoal: Array<{
    __typename?: 'performance_review_byUser_WithGoal';
    goal_Marks?: any | null;
    goal: { __typename?: 'goals'; id: any; title: string };
    performance_review_byUser_Kpis: Array<{
      __typename?: 'performance_review_byUser_Kpi';
      kpi_Mark: number;
      kpi_bank: {
        __typename?: 'kpi_bank';
        id: any;
        title: string;
        description: string;
      };
    }>;
  }>;
};

export type Get_ListOfRolesPerOrgAdminQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_ListOfRolesPerOrgAdminQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    department_id?: any | null;
    role_title?: string | null;
    system_role?: string | null;
    manager?: {
      __typename?: 'users';
      display_name?: string | null;
      id: any;
    } | null;
    user?: {
      __typename?: 'users';
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          role_title: string;
          id: any;
        } | null;
      }>;
    } | null;
  }>;
};

export type Get_GoalCountPerRolesQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type Get_GoalCountPerRolesQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
    instruction: string;
    frequency: string;
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      title: string;
      startDate: any;
      dueDate: any;
      status: string;
      timePeriod: string;
      action: string;
      performance_review_setting_GoalsLists: Array<{
        __typename?: 'performance_review_setting_GoalsList';
        goal: {
          __typename?: 'goals';
          goalsWithRoles: Array<{
            __typename?: 'goalsWithRoles';
            goal_ID: any;
            organization_role?: {
              __typename?: 'organization_roles';
              id: any;
              role_title: string;
            } | null;
          }>;
        };
      }>;
    }>;
  }>;
};

export type Check_UserTakenPerformanceReviewQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type Check_UserTakenPerformanceReviewQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
  }>;
};

export type Check_PeerTakenPerformanceReviewForUserQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
  _eq1: Scalars['uuid'];
}>;

export type Check_PeerTakenPerformanceReviewForUserQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
  }>;
};

export type UserCompletedPerformanceReviewCountQueryVariables = Exact<{
  _eq1: Scalars['uuid'];
}>;

export type UserCompletedPerformanceReviewCountQuery = {
  __typename?: 'query_root';
  performanceReviewStatus_aggregate: {
    __typename?: 'performanceReviewStatus_aggregate';
    aggregate?: {
      __typename?: 'performanceReviewStatus_aggregate_fields';
      count: number;
    } | null;
  };
};

export type Org_TotalEmployeeQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Org_TotalEmployeeQuery = {
  __typename?: 'query_root';
  organization_employee_aggregate: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
};

export type UpdateCompletedPeformanceReviewStatusMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  status: Scalars['String'];
}>;

export type UpdateCompletedPeformanceReviewStatusMutation = {
  __typename?: 'mutation_root';
  update_performance_review_setting_With_Goals?: {
    __typename?: 'performance_review_setting_With_Goals_mutation_response';
    returning: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      id: any;
    }>;
  } | null;
};

export type Get_OrgIdUsingPeformanceReviewSettingQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_OrgIdUsingPeformanceReviewSettingQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    organization_Id: any;
  }>;
};

export type DeleteGoalTimePeriodMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteGoalTimePeriodMutation = {
  __typename?: 'mutation_root';
  delete_goalWithTimePeriod?: {
    __typename?: 'goalWithTimePeriod_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertGoalTimePeriodMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalWithTimePeriod_Insert_Input> | GoalWithTimePeriod_Insert_Input
  >;
}>;

export type InsertGoalTimePeriodMutation = {
  __typename?: 'mutation_root';
  insert_goalWithTimePeriod?: {
    __typename?: 'goalWithTimePeriod_mutation_response';
    returning: Array<{ __typename?: 'goalWithTimePeriod'; id: any }>;
  } | null;
};

export type Delete_PerformanceReviewSettingGoalListMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  _eq2: Scalars['uuid'];
}>;

export type Delete_PerformanceReviewSettingGoalListMutation = {
  __typename?: 'mutation_root';
  delete_performance_review_setting_GoalsList?: {
    __typename?: 'performance_review_setting_GoalsList_mutation_response';
    affected_rows: number;
  } | null;
};

export type Get_TimePeriodNameUsingPeriodIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_TimePeriodNameUsingPeriodIdQuery = {
  __typename?: 'query_root';
  performance_review_setting_With_Goals: Array<{
    __typename?: 'performance_review_setting_With_Goals';
    id: any;
    timePeriod: string;
  }>;
};

export type Get_PerformanceTimePeriodUsingGoalIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_PerformanceTimePeriodUsingGoalIdQuery = {
  __typename?: 'query_root';
  performance_review_setting_GoalsList: Array<{
    __typename?: 'performance_review_setting_GoalsList';
    performance_review_setting_With_Goal: {
      __typename?: 'performance_review_setting_With_Goals';
      timePeriod: string;
      id: any;
    };
  }>;
};

export type Update_PerformanceReviewActionMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  action: Scalars['String'];
}>;

export type Update_PerformanceReviewActionMutation = {
  __typename?: 'mutation_root';
  update_performance_review_setting_With_Goals?: {
    __typename?: 'performance_review_setting_With_Goals_mutation_response';
    returning: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      id: any;
    }>;
  } | null;
};

export type Check_SelfReviewTakenQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type Check_SelfReviewTakenQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
  }>;
};

export type Update_CalendarForOneToOneDiscussionMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  calendarId: Scalars['uuid'];
}>;

export type Update_CalendarForOneToOneDiscussionMutation = {
  __typename?: 'mutation_root';
  update_performance_review_byUser?: {
    __typename?: 'performance_review_byUser_mutation_response';
    returning: Array<{ __typename?: 'performance_review_byUser'; id: any }>;
  } | null;
};

export type Get_ListOfRolesQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  orgId?: InputMaybe<Scalars['uuid']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  _ilike?: InputMaybe<Scalars['String']>;
}>;

export type Get_ListOfRolesQuery = {
  __typename?: 'query_root';
  goals: Array<{
    __typename?: 'goals';
    id: any;
    title: string;
    description: string;
    manager_Id: any;
    created_At: any;
    goalsWithRoles: Array<{
      __typename?: 'goalsWithRoles';
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    goalsWithDepts: Array<{
      __typename?: 'goalsWithDepts';
      department?: { __typename?: 'department'; name: string; id: any } | null;
    }>;
    goalWithTimePeriods: Array<{
      __typename?: 'goalWithTimePeriod';
      id: any;
      timePeriod: string;
    }>;
    goalWithKpis_aggregate: {
      __typename?: 'goalWithKpi_aggregate';
      aggregate?: {
        __typename?: 'goalWithKpi_aggregate_fields';
        count: number;
      } | null;
    };
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      kpi_bank: {
        __typename?: 'kpi_bank';
        id: any;
        title: string;
        description: string;
      };
    }>;
  }>;
};

export type Get_ListOfGoalsByRolesQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  orgId: Scalars['uuid'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type Get_ListOfGoalsByRolesQuery = {
  __typename?: 'query_root';
  goals: Array<{
    __typename?: 'goals';
    id: any;
    title: string;
    description: string;
    manager_Id: any;
    goalsWithRoles: Array<{
      __typename?: 'goalsWithRoles';
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    goalsWithDepts: Array<{
      __typename?: 'goalsWithDepts';
      department?: { __typename?: 'department'; name: string; id: any } | null;
    }>;
    goalWithTimePeriods: Array<{
      __typename?: 'goalWithTimePeriod';
      id: any;
      timePeriod: string;
    }>;
    goalWithKpis_aggregate: {
      __typename?: 'goalWithKpi_aggregate';
      aggregate?: {
        __typename?: 'goalWithKpi_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type Check_PerformanceReviewSettingCreatedQueryVariables = Exact<{
  _eq: Scalars['String'];
  _eq1: Scalars['uuid'];
}>;

export type Check_PerformanceReviewSettingCreatedQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
  }>;
};

export type Check_GoalCreationQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Check_GoalCreationQuery = {
  __typename?: 'query_root';
  goals: Array<{ __typename?: 'goals'; id: any; manager_Id: any }>;
};

export type Check_OrgAdminIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Check_OrgAdminIdQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    account?: { __typename?: 'auth_accounts'; default_role: string } | null;
  }>;
};

export type Delete_AllDataWithGoalIdMutationVariables = Exact<{
  goalId: Scalars['uuid'];
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type Delete_AllDataWithGoalIdMutation = {
  __typename?: 'mutation_root';
  delete_performance_review_setting_GoalsList?: {
    __typename?: 'performance_review_setting_GoalsList_mutation_response';
    affected_rows: number;
  } | null;
  delete_goalsWithDepts?: {
    __typename?: 'goalsWithDepts_mutation_response';
    affected_rows: number;
  } | null;
  delete_goalWithTimePeriod?: {
    __typename?: 'goalWithTimePeriod_mutation_response';
    affected_rows: number;
  } | null;
  delete_goalsWithRoles?: {
    __typename?: 'goalsWithRoles_mutation_response';
    affected_rows: number;
  } | null;
  delete_goalWithKpi?: {
    __typename?: 'goalWithKpi_mutation_response';
    affected_rows: number;
  } | null;
  delete_goals_by_pk?: { __typename?: 'goals'; id: any } | null;
};

export type Check_PerformanceReviewActionQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type Check_PerformanceReviewActionQuery = {
  __typename?: 'query_root';
  performance_review_setting_GoalsList: Array<{
    __typename?: 'performance_review_setting_GoalsList';
    id: any;
    performance_review_setting_With_Goal: {
      __typename?: 'performance_review_setting_With_Goals';
      action: string;
    };
  }>;
};

export type Get_AllDeptByManagerQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_AllDeptByManagerQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    department?: { __typename?: 'department'; id: any; name: string } | null;
  }>;
};

export type Get_AllRolesByManagerQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_AllRolesByManagerQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    role_title?: string | null;
    user?: {
      __typename?: 'users';
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
    } | null;
  }>;
};

export type Get_KpiDetailQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_KpiDetailQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{
    __typename?: 'kpi_bank';
    title: string;
    kpiWithRoles: Array<{ __typename?: 'kpiWithRole'; role_Id?: any | null }>;
    kpiWithRolesAndDepts: Array<{
      __typename?: 'kpiWithDept';
      department_Id?: any | null;
    }>;
  }>;
};

export type Get_UserDetailQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_UserDetailQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    account?: { __typename?: 'auth_accounts'; default_role: string } | null;
    my_organization?: {
      __typename?: 'organization_user';
      organization_id?: any | null;
    } | null;
  }>;
};

export type Get_GoalDetailQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_GoalDetailQuery = {
  __typename?: 'query_root';
  goals: Array<{
    __typename?: 'goals';
    manager_Id: any;
    goalsWithDepts: Array<{
      __typename?: 'goalsWithDepts';
      department_ID?: any | null;
    }>;
    goalsWithRoles: Array<{
      __typename?: 'goalsWithRoles';
      role_ID?: any | null;
    }>;
    goalWithTimePeriods: Array<{
      __typename?: 'goalWithTimePeriod';
      timePeriod: string;
    }>;
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      kpi_bank: {
        __typename?: 'kpi_bank';
        id: any;
        title: string;
        kpiWithRoles: Array<{
          __typename?: 'kpiWithRole';
          role_Id?: any | null;
        }>;
        kpiWithRolesAndDepts: Array<{ __typename?: 'kpiWithDept'; id: any }>;
      };
    }>;
  }>;
};

export type Check_GoalWithPerformanceReviewUsersQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type Check_GoalWithPerformanceReviewUsersQuery = {
  __typename?: 'query_root';
  performance_review_byUser_WithGoal: Array<{
    __typename?: 'performance_review_byUser_WithGoal';
    id: any;
  }>;
};

export type GetManagerIdOfUserForPrQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type GetManagerIdOfUserForPrQuery = {
  __typename?: 'query_root';
  User_Peer_Review: Array<{
    __typename?: 'User_Peer_Review';
    id: any;
    peer_Id: any;
    status?: string | null;
    timePeriod?: string | null;
    user_Id: any;
    user: { __typename?: 'users'; id: any; display_name?: string | null };
    performance_review_byUser?: {
      __typename?: 'performance_review_byUser';
      id: any;
      performance_Id: any;
      performance_timePeriod_Id: any;
      performance_review_byUser_WithGoals: Array<{
        __typename?: 'performance_review_byUser_WithGoal';
        goal_Marks?: any | null;
        goal_Id: any;
        id: any;
        goal: {
          __typename?: 'goals';
          title: string;
          weightage_s?: string | null;
        };
      }>;
    } | null;
  }>;
};

export type Get_Peers_ReviewInfoQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
  peerId: Scalars['uuid'];
  role_id: Scalars['uuid'];
  time_period: Scalars['String'];
}>;

export type Get_Peers_ReviewInfoQuery = {
  __typename?: 'query_root';
  User_Peer_Review: Array<{
    __typename?: 'User_Peer_Review';
    id: any;
    peer_Id: any;
    status?: string | null;
    timePeriod?: string | null;
    user_Id: any;
    performance_review_byUser?: {
      __typename?: 'performance_review_byUser';
      id: any;
      performance_Id: any;
      performance_timePeriod_Id: any;
      performance_review_byUser_WithGoals: Array<{
        __typename?: 'performance_review_byUser_WithGoal';
        goal_Marks?: any | null;
        goal_Id: any;
        id: any;
        goal: {
          __typename?: 'goals';
          title: string;
          weightage_s?: string | null;
          goalsWithRoles: Array<{
            __typename?: 'goalsWithRoles';
            role_weightage?: any | null;
          }>;
        };
      }>;
    } | null;
  }>;
};

export type Get_ListOfKpiWithRolesQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  _eq2?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_ListOfKpiWithRolesQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{
    __typename?: 'kpi_bank';
    id: any;
    title: string;
    description: string;
    open_ended_kpi?: boolean | null;
    managerId: any;
    kpiWithRoles: Array<{
      __typename?: 'kpiWithRole';
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    kpiWithRolesAndDepts: Array<{
      __typename?: 'kpiWithDept';
      department?: { __typename?: 'department'; id: any; name: string } | null;
    }>;
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      createdBy?: any | null;
      kpi_ID: any;
    }>;
  }>;
};

export type Get_ListOfKpiWithDeptsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  _eq2?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_ListOfKpiWithDeptsQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{
    __typename?: 'kpi_bank';
    id: any;
    title: string;
    description: string;
    open_ended_kpi?: boolean | null;
    managerId: any;
    kpiWithRoles: Array<{
      __typename?: 'kpiWithRole';
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    kpiWithRolesAndDepts: Array<{
      __typename?: 'kpiWithDept';
      department?: { __typename?: 'department'; id: any; name: string } | null;
    }>;
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      createdBy?: any | null;
      kpi_ID: any;
    }>;
  }>;
};

export type Get_OrgAdminIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_OrgAdminIdQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    organizations: Array<{ __typename?: 'organization'; owned_by: any }>;
  }>;
};

export type Get_ManagerIdOfKpiIdQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_ManagerIdOfKpiIdQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{ __typename?: 'kpi_bank'; managerId: any }>;
};

export type All_KpiRoleCountQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type All_KpiRoleCountQuery = {
  __typename?: 'query_root';
  kpi_bank_aggregate: {
    __typename?: 'kpi_bank_aggregate';
    aggregate?: {
      __typename?: 'kpi_bank_aggregate_fields';
      count: number;
    } | null;
  };
};

export type All_KpiDeptCountQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type All_KpiDeptCountQuery = {
  __typename?: 'query_root';
  kpi_bank_aggregate: {
    __typename?: 'kpi_bank_aggregate';
    aggregate?: {
      __typename?: 'kpi_bank_aggregate_fields';
      count: number;
    } | null;
  };
};

export type Get_Users_ScoreSheetQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1?: InputMaybe<Scalars['String']>;
  role_id: Scalars['uuid'];
  time_period: Scalars['String'];
}>;

export type Get_Users_ScoreSheetQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
    timePeriod?: string | null;
    performance_Id: any;
    performance_timePeriod_Id: any;
    performance_review_setting_With_Goal: {
      __typename?: 'performance_review_setting_With_Goals';
      title: string;
    };
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
    userByPeerId?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    performance_review_byUser_WithGoals: Array<{
      __typename?: 'performance_review_byUser_WithGoal';
      id: any;
      goal_Id: any;
      goal_Marks?: any | null;
      goal: {
        __typename?: 'goals';
        title: string;
        goalsWithRoles: Array<{
          __typename?: 'goalsWithRoles';
          role_weightage?: any | null;
        }>;
      };
      performance_review_byUser_Kpis: Array<{
        __typename?: 'performance_review_byUser_Kpi';
        id: any;
        kpi_Id: any;
        kpi_Mark: number;
        comment?: string | null;
        kpi_bank: {
          __typename?: 'kpi_bank';
          title: string;
          open_ended_kpi?: boolean | null;
        };
      }>;
    }>;
  }>;
};

export type Get_Peer_ScoreSheetQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
  _eq2?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  time_period?: InputMaybe<Scalars['String']>;
}>;

export type Get_Peer_ScoreSheetQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
    timePeriod?: string | null;
    performance_Id: any;
    performance_timePeriod_Id: any;
    performance_review_setting_With_Goal: {
      __typename?: 'performance_review_setting_With_Goals';
      title: string;
    };
    userByPeerId?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    performance_review_byUser_WithGoals: Array<{
      __typename?: 'performance_review_byUser_WithGoal';
      id: any;
      goal_Id: any;
      goal_Marks?: any | null;
      goal: {
        __typename?: 'goals';
        title: string;
        goalsWithRoles: Array<{
          __typename?: 'goalsWithRoles';
          role_weightage?: any | null;
        }>;
      };
      performance_review_byUser_Kpis: Array<{
        __typename?: 'performance_review_byUser_Kpi';
        id: any;
        kpi_Id: any;
        kpi_Mark: number;
        comment?: string | null;
        kpi_bank: {
          __typename?: 'kpi_bank';
          title: string;
          open_ended_kpi?: boolean | null;
        };
      }>;
    }>;
  }>;
};

export type Get_PeerListQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _ilike: Scalars['String'];
}>;

export type Get_PeerListQuery = {
  __typename?: 'query_root';
  User_Peer_Review: Array<{
    __typename?: 'User_Peer_Review';
    id: any;
    peer_Id: any;
    status?: string | null;
    timePeriod?: string | null;
    user_Id: any;
  }>;
};

export type ViewOptionQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type ViewOptionQuery = {
  __typename?: 'query_root';
  performance_review_setting_With_Goals: Array<{
    __typename?: 'performance_review_setting_With_Goals';
    viewScoreOption?: string | null;
  }>;
};

export type Get_PerviousfalseScoreSheetOfUserQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['String'];
}>;

export type Get_PerviousfalseScoreSheetOfUserQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
  }>;
};

export type Get_PerviousfalseScoreSheetOfPeerQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['String'];
  _eq2: Scalars['uuid'];
}>;

export type Get_PerviousfalseScoreSheetOfPeerQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
  }>;
};

export type Delete_PreviousFalseSheetMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Delete_PreviousFalseSheetMutation = {
  __typename?: 'mutation_root';
  delete_performance_review_byUser_Kpi?: {
    __typename?: 'performance_review_byUser_Kpi_mutation_response';
    affected_rows: number;
  } | null;
  delete_performance_review_byUser_WithGoal?: {
    __typename?: 'performance_review_byUser_WithGoal_mutation_response';
    affected_rows: number;
  } | null;
  delete_performance_review_byUser?: {
    __typename?: 'performance_review_byUser_mutation_response';
    returning: Array<{ __typename?: 'performance_review_byUser'; id: any }>;
  } | null;
};

export type List_Of_Performance_SettingQueryVariables = Exact<{
  org_id?: InputMaybe<Scalars['uuid']>;
}>;

export type List_Of_Performance_SettingQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
    name?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    year: string;
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      id: any;
      title: string;
      timePeriod: string;
      startDate: any;
      dueDate: any;
      startingDate?: string | null;
      endingDate?: string | null;
      status: string;
      action: string;
    }>;
  }>;
};

export type Delete_Performance_Rating_ScaleMutationVariables = Exact<{
  performance_id: Scalars['uuid'];
}>;

export type Delete_Performance_Rating_ScaleMutation = {
  __typename?: 'mutation_root';
  delete_performance_rating_scale?: {
    __typename?: 'performance_rating_scale_mutation_response';
    affected_rows: number;
  } | null;
};

export type Insert_Performance_Rating_ScaleMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Performance_Rating_Scale_Insert_Input>
    | Performance_Rating_Scale_Insert_Input
  >;
}>;

export type Insert_Performance_Rating_ScaleMutation = {
  __typename?: 'mutation_root';
  insert_performance_rating_scale?: {
    __typename?: 'performance_rating_scale_mutation_response';
    returning: Array<{ __typename?: 'performance_rating_scale'; id: any }>;
  } | null;
};

export type Get_Reviewers_WeightageQueryVariables = Exact<{
  time_period?: InputMaybe<Scalars['String']>;
}>;

export type Get_Reviewers_WeightageQuery = {
  __typename?: 'query_root';
  performance_review_setting_With_Goals: Array<{
    __typename?: 'performance_review_setting_With_Goals';
    performance_review_setting: {
      __typename?: 'performance_review_settings';
      self_review?: string | null;
      manager_review?: string | null;
      peer_review?: string | null;
    };
  }>;
};

export type Get_List_Of_PeersQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['uuid']>;
  time_period?: InputMaybe<Scalars['String']>;
}>;

export type Get_List_Of_PeersQuery = {
  __typename?: 'query_root';
  User_Peer_Review: Array<{
    __typename?: 'User_Peer_Review';
    id: any;
    peer_Id: any;
    status?: string | null;
    timePeriod?: string | null;
    user_Id: any;
  }>;
};

export type Get_Peer_Completed_CountQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  time_period: Scalars['String'];
}>;

export type Get_Peer_Completed_CountQuery = {
  __typename?: 'query_root';
  User_Peer_Review_aggregate: {
    __typename?: 'User_Peer_Review_aggregate';
    aggregate?: {
      __typename?: 'User_Peer_Review_aggregate_fields';
      count: number;
    } | null;
  };
};

export type Performance_Review_Of_PeerQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  time_period: Scalars['String'];
  peer_id: Scalars['uuid'];
  role_id?: InputMaybe<Scalars['uuid']>;
}>;

export type Performance_Review_Of_PeerQuery = {
  __typename?: 'query_root';
  performance_review_byUser: Array<{
    __typename?: 'performance_review_byUser';
    id: any;
    performance_Id: any;
    performance_timePeriod_Id: any;
    performance_review_byUser_WithGoals: Array<{
      __typename?: 'performance_review_byUser_WithGoal';
      goal_Marks?: any | null;
      goal_Id: any;
      id: any;
      goal: {
        __typename?: 'goals';
        title: string;
        weightage_s?: string | null;
        goalsWithRoles: Array<{
          __typename?: 'goalsWithRoles';
          role_weightage?: any | null;
        }>;
      };
    }>;
  }>;
};

export type List_Of_Roles_In_OrgQueryVariables = Exact<{
  org_id?: InputMaybe<Scalars['uuid']>;
}>;

export type List_Of_Roles_In_OrgQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    id: any;
    role_title: string;
  }>;
};

export type List_Of_Roles_And_WeigthageQueryVariables = Exact<{
  time_period: Scalars['String'];
  role_id: Scalars['uuid'];
}>;

export type List_Of_Roles_And_WeigthageQuery = {
  __typename?: 'query_root';
  goalsWithRoles: Array<{
    __typename?: 'goalsWithRoles';
    role_weightage?: any | null;
    goal_ID: any;
    organization_role?: {
      __typename?: 'organization_roles';
      id: any;
      role_title: string;
    } | null;
  }>;
};

export type Performance_RatingQueryVariables = Exact<{
  setting_id: Scalars['uuid'];
}>;

export type Performance_RatingQuery = {
  __typename?: 'query_root';
  performance_rating_scale: Array<{
    __typename?: 'performance_rating_scale';
    name?: string | null;
    rating?: number | null;
  }>;
};

export type Org_ShareQueryVariables = Exact<{
  org_id: Scalars['uuid'];
  time_period: Scalars['String'];
}>;

export type Org_ShareQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    org_goal_share?: string | null;
  }>;
};

export type GetRole_WeightageByRoleIdAndTimePeriodQueryVariables = Exact<{
  role_id?: InputMaybe<Scalars['uuid']>;
  time_period?: InputMaybe<Scalars['String']>;
}>;

export type GetRole_WeightageByRoleIdAndTimePeriodQuery = {
  __typename?: 'query_root';
  goalsWithRoles: Array<{
    __typename?: 'goalsWithRoles';
    id: any;
    role_weightage?: any | null;
    time_period?: string | null;
  }>;
};

export type InsertRoleAndWeightageMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalsWithRoles_Insert_Input> | GoalsWithRoles_Insert_Input
  >;
}>;

export type InsertRoleAndWeightageMutation = {
  __typename?: 'mutation_root';
  insert_goalsWithRoles?: {
    __typename?: 'goalsWithRoles_mutation_response';
    returning: Array<{ __typename?: 'goalsWithRoles'; id: any }>;
  } | null;
};

export type Update_Role_WeightageMutationVariables = Exact<{
  role_weightage: Scalars['float8'];
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type Update_Role_WeightageMutation = {
  __typename?: 'mutation_root';
  update_goalsWithRoles?: {
    __typename?: 'goalsWithRoles_mutation_response';
    returning: Array<{ __typename?: 'goalsWithRoles'; id: any }>;
  } | null;
};

export type GoalDetailsByIdsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GoalDetailsByIdsQuery = {
  __typename?: 'query_root';
  goals_by_pk?: {
    __typename?: 'goals';
    id: any;
    manager_Id: any;
    title: string;
    weightage_s?: string | null;
    timePeriodic?: string | null;
    description: string;
    goalsWithRoles: Array<{
      __typename?: 'goalsWithRoles';
      id: any;
      role_weightage?: any | null;
      time_period?: string | null;
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    goalsWithFreqs: Array<{
      __typename?: 'goalsWithFreqs';
      id: any;
      frequency?: { __typename?: 'frequency'; value: string; id: any } | null;
    }>;
    goalsWithDepts: Array<{
      __typename?: 'goalsWithDepts';
      id: any;
      department?: { __typename?: 'department'; id: any; name: string } | null;
    }>;
    goalWithKpis: Array<{ __typename?: 'goalWithKpi'; kpi_ID: any }>;
    goalWithTimePeriods: Array<{
      __typename?: 'goalWithTimePeriod';
      id: any;
      timePeriod: string;
    }>;
  } | null;
};

export type RoleDetailsInGoalPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type RoleDetailsInGoalPkQuery = {
  __typename?: 'query_root';
  goalsWithRoles: Array<{
    __typename?: 'goalsWithRoles';
    id: any;
    organization_role?: {
      __typename?: 'organization_roles';
      id: any;
      role_title: string;
    } | null;
  }>;
};

export type Get_Performance_Rating_Scale_AgvQueryVariables = Exact<{
  _eq: Scalars['String'];
}>;

export type Get_Performance_Rating_Scale_AgvQuery = {
  __typename?: 'query_root';
  performance_rating_scale_aggregate: {
    __typename?: 'performance_rating_scale_aggregate';
    aggregate?: {
      __typename?: 'performance_rating_scale_aggregate_fields';
      count: number;
    } | null;
  };
};

export type Copy_Goals_Pervious_YrQueryVariables = Exact<{
  org_id?: InputMaybe<Scalars['uuid']>;
  year: Scalars['String'];
  time_period: Scalars['String'];
}>;

export type Copy_Goals_Pervious_YrQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      performance_review_setting_GoalsLists: Array<{
        __typename?: 'performance_review_setting_GoalsList';
        goal_Id: any;
      }>;
    }>;
  }>;
};

export type Get_Role_Detail_GoalIdQueryVariables = Exact<{
  goal_id: Scalars['uuid'];
  time_period: Scalars['String'];
}>;

export type Get_Role_Detail_GoalIdQuery = {
  __typename?: 'query_root';
  goalsWithRoles: Array<{
    __typename?: 'goalsWithRoles';
    role_ID?: any | null;
    role_weightage?: any | null;
    time_period?: string | null;
  }>;
};

export type Get_Goal_Details_PkQueryVariables = Exact<{
  goal_id: Scalars['uuid'];
  time_period: Scalars['String'];
}>;

export type Get_Goal_Details_PkQuery = {
  __typename?: 'query_root';
  goals: Array<{
    __typename?: 'goals';
    title: string;
    description: string;
    weightage_s?: string | null;
    manager_Id: any;
    goalsWithRoles: Array<{
      __typename?: 'goalsWithRoles';
      role_ID?: any | null;
      role_weightage?: any | null;
    }>;
    goalsWithDepts: Array<{
      __typename?: 'goalsWithDepts';
      department_ID?: any | null;
    }>;
    goalWithTimePeriods: Array<{
      __typename?: 'goalWithTimePeriod';
      timePeriod: string;
    }>;
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      kpi_ID: any;
      createdBy?: any | null;
    }>;
  }>;
};

export type Create_GoalMutationVariables = Exact<{
  objects?: InputMaybe<Array<Goals_Insert_Input> | Goals_Insert_Input>;
}>;

export type Create_GoalMutation = {
  __typename?: 'mutation_root';
  insert_goals?: {
    __typename?: 'goals_mutation_response';
    returning: Array<{ __typename?: 'goals'; id: any }>;
  } | null;
};

export type Create_TimePeriod_For_GoalMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalWithKpi_Insert_Input> | GoalWithKpi_Insert_Input
  >;
}>;

export type Create_TimePeriod_For_GoalMutation = {
  __typename?: 'mutation_root';
  insert_goalWithKpi?: {
    __typename?: 'goalWithKpi_mutation_response';
    returning: Array<{ __typename?: 'goalWithKpi'; id: any }>;
  } | null;
};

export type Create_Role_For_GoalMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<GoalsWithRoles_Insert_Input> | GoalsWithRoles_Insert_Input
  >;
}>;

export type Create_Role_For_GoalMutation = {
  __typename?: 'mutation_root';
  insert_goalsWithRoles?: {
    __typename?: 'goalsWithRoles_mutation_response';
    returning: Array<{ __typename?: 'goalsWithRoles'; id: any }>;
  } | null;
};

export type GetRolesOfUserQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetRolesOfUserQuery = {
  __typename?: 'query_root';
  users_by_pk?: {
    __typename?: 'users';
    profile: Array<{
      __typename?: 'profile';
      organization_role_id?: any | null;
    }>;
  } | null;
};

export type Get_OrgAdmin_RoleidQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type Get_OrgAdmin_RoleidQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{ __typename?: 'organization_roles'; id: any }>;
};

export type Get_ListOfKpiListQueryVariables = Exact<{
  role_id?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  dept_id?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  org_id: Scalars['uuid'];
  goal_id?: InputMaybe<Scalars['uuid']>;
  search?: InputMaybe<Scalars['String']>;
}>;

export type Get_ListOfKpiListQuery = {
  __typename?: 'query_root';
  kpi_bank: Array<{
    __typename?: 'kpi_bank';
    id: any;
    title: string;
    description: string;
    open_ended_kpi?: boolean | null;
    managerId: any;
    kpiWithRoles: Array<{
      __typename?: 'kpiWithRole';
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    kpiWithRolesAndDepts: Array<{
      __typename?: 'kpiWithDept';
      department?: { __typename?: 'department'; id: any; name: string } | null;
    }>;
    goalWithKpis: Array<{
      __typename?: 'goalWithKpi';
      createdBy?: any | null;
      kpi_ID: any;
    }>;
  }>;
  kpi_bank_aggregate: {
    __typename?: 'kpi_bank_aggregate';
    aggregate?: {
      __typename?: 'kpi_bank_aggregate_fields';
      count: number;
    } | null;
  };
};

export type DeletePostCommentByPostIdMutationVariables = Exact<{
  post_id: Scalars['uuid'];
}>;

export type DeletePostCommentByPostIdMutation = {
  __typename?: 'mutation_root';
  delete_posts_comments?: {
    __typename?: 'posts_comments_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddNewPostCommentMutationVariables = Exact<{
  new_comment: Posts_Comments_Insert_Input;
}>;

export type AddNewPostCommentMutation = {
  __typename?: 'mutation_root';
  insert_posts_comments_one?: {
    __typename?: 'posts_comments';
    id: any;
    post_id: any;
    commented_by: any;
    comment: string;
    created_at: any;
    updated_at: any;
    commentedByUser: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    };
  } | null;
};

export type EditACommentByCommentIdMutationVariables = Exact<{
  comment_id: Scalars['uuid'];
  set: Posts_Comments_Set_Input;
}>;

export type EditACommentByCommentIdMutation = {
  __typename?: 'mutation_root';
  update_posts_comments?: {
    __typename?: 'posts_comments_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteCommentLikesByCommentIdMutationVariables = Exact<{
  commentId: Scalars['uuid'];
}>;

export type DeleteCommentLikesByCommentIdMutation = {
  __typename?: 'mutation_root';
  delete_post_comments_likes?: {
    __typename?: 'post_comments_likes_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetCommentByCommentIdQueryVariables = Exact<{
  comment_id: Scalars['uuid'];
}>;

export type GetCommentByCommentIdQuery = {
  __typename?: 'query_root';
  posts_comments_by_pk?: {
    __typename?: 'posts_comments';
    id: any;
    post_id: any;
    commented_by: any;
    comment: string;
    created_at: any;
    updated_at: any;
    commentedByUser: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    };
    mentioned_users: Array<{
      __typename?: 'post_comment_mentioned_users';
      id: any;
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        organizations: Array<{ __typename?: 'organization'; id: any }>;
      } | null;
    }>;
    commentsLikes: Array<{
      __typename?: 'post_comments_likes';
      id: any;
      created_at: any;
      updated_at: any;
      post_id: any;
      comment_id: any;
      liked_by: any;
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      } | null;
    }>;
  } | null;
};

export type GetCommentLikesByAUserQueryVariables = Exact<{
  condition: Post_Comments_Likes_Bool_Exp;
}>;

export type GetCommentLikesByAUserQuery = {
  __typename?: 'query_root';
  post_comments_likes: Array<{
    __typename?: 'post_comments_likes';
    id: any;
    created_at: any;
    updated_at: any;
    post_id: any;
    comment_id: any;
    liked_by: any;
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    } | null;
  }>;
};

export type AttachFilesToCommentByIdMutationVariables = Exact<{
  newFiles:
    | Array<Post_Comment_Files_Insert_Input>
    | Post_Comment_Files_Insert_Input;
}>;

export type AttachFilesToCommentByIdMutation = {
  __typename?: 'mutation_root';
  insert_post_comment_files?: {
    __typename?: 'post_comment_files_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'post_comment_files';
      id: any;
      post_id: any;
      comment_id: any;
      file_url?: string | null;
      storage_file_id: any;
    }>;
  } | null;
};

export type DeleteFilesFromCommentByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteFilesFromCommentByIdMutation = {
  __typename?: 'mutation_root';
  delete_post_comment_files?: {
    __typename?: 'post_comment_files_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddMentionUsersToCommentMutationVariables = Exact<{
  newMentions:
    | Array<Post_Comment_Mentioned_Users_Insert_Input>
    | Post_Comment_Mentioned_Users_Insert_Input;
}>;

export type AddMentionUsersToCommentMutation = {
  __typename?: 'mutation_root';
  insert_post_comment_mentioned_users?: {
    __typename?: 'post_comment_mentioned_users_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'post_comment_mentioned_users';
      id: any;
      user_id: any;
    }>;
  } | null;
};

export type DeleteMentionedUsersByCommentIdMutationVariables = Exact<{
  commentId: Scalars['uuid'];
}>;

export type DeleteMentionedUsersByCommentIdMutation = {
  __typename?: 'mutation_root';
  delete_post_comment_mentioned_users?: {
    __typename?: 'post_comment_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteCommentLikeForAUserMutationVariables = Exact<{
  condition: Post_Comments_Likes_Bool_Exp;
}>;

export type DeleteCommentLikeForAUserMutation = {
  __typename?: 'mutation_root';
  delete_post_comments_likes?: {
    __typename?: 'post_comments_likes_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeletePostsCommentByCommentIdMutationVariables = Exact<{
  comment_id: Scalars['uuid'];
}>;

export type DeletePostsCommentByCommentIdMutation = {
  __typename?: 'mutation_root';
  delete_posts_comments?: {
    __typename?: 'posts_comments_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comments_likes?: {
    __typename?: 'post_comments_likes_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_mentioned_users?: {
    __typename?: 'post_comment_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_replies?: {
    __typename?: 'post_comment_replies_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_replies_likes?: {
    __typename?: 'post_replies_likes_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_replies_mentioned_users?: {
    __typename?: 'post_comment_replies_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeletePostsLikesByPostIdMutationVariables = Exact<{
  post_id: Scalars['uuid'];
}>;

export type DeletePostsLikesByPostIdMutation = {
  __typename?: 'mutation_root';
  delete_posts_by_pk?: { __typename?: 'posts'; id: any } | null;
  delete_posts_files?: {
    __typename?: 'posts_files_mutation_response';
    affected_rows: number;
  } | null;
  delete_posts_comments?: {
    __typename?: 'posts_comments_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_likes?: {
    __typename?: 'post_likes_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'post_likes'; id: any }>;
  } | null;
  delete_post_mentioned_users?: {
    __typename?: 'post_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comments_likes?: {
    __typename?: 'post_comments_likes_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_mentioned_users?: {
    __typename?: 'post_comment_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_replies?: {
    __typename?: 'post_comment_replies_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_replies_likes?: {
    __typename?: 'post_replies_likes_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_replies_mentioned_users?: {
    __typename?: 'post_comment_replies_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddNewLikeToPostMutationVariables = Exact<{
  new_like: Post_Likes_Insert_Input;
}>;

export type AddNewLikeToPostMutation = {
  __typename?: 'mutation_root';
  insert_post_likes_one?: {
    __typename?: 'post_likes';
    id: any;
    created_at: any;
    updated_at: any;
    liked_by: any;
    likedByUser: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    };
  } | null;
};

export type RemoveLikeFromPostByPostIdAndLikerIdMutationVariables = Exact<{
  post_id: Scalars['uuid'];
  liker_id: Scalars['uuid'];
}>;

export type RemoveLikeFromPostByPostIdAndLikerIdMutation = {
  __typename?: 'mutation_root';
  delete_post_likes?: {
    __typename?: 'post_likes_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'post_likes'; id: any }>;
  } | null;
};

export type GetLikesByLikerIdAndPostIdQueryVariables = Exact<{
  post_id: Scalars['uuid'];
  liker_id: Scalars['uuid'];
}>;

export type GetLikesByLikerIdAndPostIdQuery = {
  __typename?: 'query_root';
  post_likes: Array<{
    __typename?: 'post_likes';
    id: any;
    post_id: any;
    liked_by: any;
    created_at: any;
    updated_at: any;
    likedByUser: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    };
  }>;
};

export type AddNewMentionedUsersMutationVariables = Exact<{
  mentioned_users:
    | Array<Post_Mentioned_Users_Insert_Input>
    | Post_Mentioned_Users_Insert_Input;
}>;

export type AddNewMentionedUsersMutation = {
  __typename?: 'mutation_root';
  insert_post_mentioned_users?: {
    __typename?: 'post_mentioned_users_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'post_mentioned_users';
      id: any;
      post_id: any;
      user_id: any;
    }>;
  } | null;
};

export type DeletePostMentionedUsersByPostIdMutationVariables = Exact<{
  postId: Scalars['uuid'];
}>;

export type DeletePostMentionedUsersByPostIdMutation = {
  __typename?: 'mutation_root';
  delete_post_mentioned_users?: {
    __typename?: 'post_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeletePostFilesByPostIdMutationVariables = Exact<{
  post_id: Scalars['uuid'];
}>;

export type DeletePostFilesByPostIdMutation = {
  __typename?: 'mutation_root';
  delete_posts_files?: {
    __typename?: 'posts_files_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddNewPostFilesMutationVariables = Exact<{
  new_files: Array<Posts_Files_Insert_Input> | Posts_Files_Insert_Input;
}>;

export type AddNewPostFilesMutation = {
  __typename?: 'mutation_root';
  insert_posts_files?: {
    __typename?: 'posts_files_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddANewCommentReplyMutationVariables = Exact<{
  object: Post_Comment_Replies_Insert_Input;
}>;

export type AddANewCommentReplyMutation = {
  __typename?: 'mutation_root';
  insert_post_comment_replies_one?: {
    __typename?: 'post_comment_replies';
    id: any;
    post_id: any;
    comment_id: any;
    reply?: string | null;
    replied_by: any;
    comment?: { __typename?: 'posts_comments'; id: any } | null;
  } | null;
};

export type PostCommentsLikesByCommentIdMutationVariables = Exact<{
  object: Post_Comments_Likes_Insert_Input;
}>;

export type PostCommentsLikesByCommentIdMutation = {
  __typename?: 'mutation_root';
  insert_post_comments_likes_one?: {
    __typename?: 'post_comments_likes';
    id: any;
    liked_by: any;
    post_id: any;
    comment_id: any;
  } | null;
};

export type GetCommentReplyByReplyIdQueryVariables = Exact<{
  replyId: Scalars['uuid'];
}>;

export type GetCommentReplyByReplyIdQuery = {
  __typename?: 'query_root';
  post_comment_replies_by_pk?: {
    __typename?: 'post_comment_replies';
    id: any;
    post_id: any;
    replied_by: any;
    reply?: string | null;
    comment_id: any;
    comment?: { __typename?: 'posts_comments'; id: any; post_id: any } | null;
  } | null;
};

export type AddNewReplyLikeMutationVariables = Exact<{
  object: Post_Replies_Likes_Insert_Input;
}>;

export type AddNewReplyLikeMutation = {
  __typename?: 'mutation_root';
  insert_post_replies_likes_one?: {
    __typename?: 'post_replies_likes';
    id: any;
    created_at: any;
    updated_at: any;
    post_id: any;
    comment_id: any;
    reply_id: any;
    liked_by: any;
  } | null;
};

export type GetReplyLikesByAUserQueryVariables = Exact<{
  condition: Post_Replies_Likes_Bool_Exp;
}>;

export type GetReplyLikesByAUserQuery = {
  __typename?: 'query_root';
  post_replies_likes: Array<{
    __typename?: 'post_replies_likes';
    id: any;
    created_at: any;
    updated_at: any;
    post_id: any;
    comment_id: any;
    reply_id: any;
    liked_by: any;
  }>;
};

export type DeleteReplyLikesForAUserMutationVariables = Exact<{
  condition: Post_Replies_Likes_Bool_Exp;
}>;

export type DeleteReplyLikesForAUserMutation = {
  __typename?: 'mutation_root';
  delete_post_replies_likes?: {
    __typename?: 'post_replies_likes_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddNewMentionedUserForCommentReplyMutationVariables = Exact<{
  object: Post_Comment_Replies_Mentioned_Users_Insert_Input;
}>;

export type AddNewMentionedUserForCommentReplyMutation = {
  __typename?: 'mutation_root';
  insert_post_comment_replies_mentioned_users_one?: {
    __typename?: 'post_comment_replies_mentioned_users';
    id: any;
    post_id: any;
    comment_id: any;
    user_id: any;
    reply_id: any;
  } | null;
};

export type DeleteCommentReplyByReplyIdMutationVariables = Exact<{
  replyId: Scalars['uuid'];
}>;

export type DeleteCommentReplyByReplyIdMutation = {
  __typename?: 'mutation_root';
  delete_post_comment_replies?: {
    __typename?: 'post_comment_replies_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_replies_likes?: {
    __typename?: 'post_replies_likes_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_replies_mentioned_users?: {
    __typename?: 'post_comment_replies_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteAllTheRepliesOfACommentMutationVariables = Exact<{
  condition: Post_Comment_Replies_Bool_Exp;
}>;

export type DeleteAllTheRepliesOfACommentMutation = {
  __typename?: 'mutation_root';
  delete_post_comment_replies?: {
    __typename?: 'post_comment_replies_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddANewPostMutationVariables = Exact<{
  newPost: Posts_Insert_Input;
}>;

export type AddANewPostMutation = {
  __typename?: 'mutation_root';
  insert_posts_one?: {
    __typename?: 'posts';
    id: any;
    created_at: any;
    updated_at: any;
    message: string;
    visibility: string;
    deleted_at?: any | null;
    is_active: boolean;
    user_id: any;
    org_id: any;
    project_id?: any | null;
    likes: Array<{ __typename?: 'post_likes'; id: any; liked_by: any }>;
    comments: Array<{
      __typename?: 'posts_comments';
      id: any;
      comment: string;
      commented_by: any;
    }>;
  } | null;
};

export type EditAPostByPostIdMutationVariables = Exact<{
  post_id: Scalars['uuid'];
  set: Posts_Set_Input;
}>;

export type EditAPostByPostIdMutation = {
  __typename?: 'mutation_root';
  update_posts_by_pk?: {
    __typename?: 'posts';
    id: any;
    created_at: any;
    updated_at: any;
    message: string;
    visibility: string;
    deleted_at?: any | null;
    is_active: boolean;
    user_id: any;
    org_id: any;
    likes: Array<{ __typename?: 'post_likes'; id: any; liked_by: any }>;
    comments: Array<{
      __typename?: 'posts_comments';
      id: any;
      comment: string;
      commented_by: any;
    }>;
  } | null;
};

export type GetPostByPostIdQueryVariables = Exact<{
  post_id: Scalars['uuid'];
}>;

export type GetPostByPostIdQuery = {
  __typename?: 'query_root';
  posts_by_pk?: {
    __typename?: 'posts';
    id: any;
    user_id: any;
    org_id: any;
    message: string;
    visibility: string;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    is_active: boolean;
    likes: Array<{
      __typename?: 'post_likes';
      id: any;
      liked_by: any;
      likedByUser: { __typename?: 'users'; display_name?: string | null };
    }>;
    comments: Array<{
      __typename?: 'posts_comments';
      id: any;
      comment: string;
      commented_by: any;
      commentedByUser: { __typename?: 'users'; display_name?: string | null };
    }>;
    mentioned_users: Array<{
      __typename?: 'post_mentioned_users';
      id: any;
      post_id: any;
      user_id: any;
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      } | null;
    }>;
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  } | null;
};

export type GetAPostByConditionQueryVariables = Exact<{
  condition: Posts_Bool_Exp;
}>;

export type GetAPostByConditionQuery = {
  __typename?: 'query_root';
  posts: Array<{
    __typename?: 'posts';
    id: any;
    user_id: any;
    org_id: any;
    message: string;
    visibility: string;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    is_active: boolean;
    post_type?: Enum_Post_Type_Enum | null;
    likes: Array<{
      __typename?: 'post_likes';
      id: any;
      liked_by: any;
      likedByUser: { __typename?: 'users'; display_name?: string | null };
    }>;
    comments: Array<{
      __typename?: 'posts_comments';
      id: any;
      comment: string;
      commented_by: any;
      commentedByUser: { __typename?: 'users'; display_name?: string | null };
    }>;
    mentioned_users: Array<{
      __typename?: 'post_mentioned_users';
      id: any;
      post_id: any;
      user_id: any;
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      } | null;
    }>;
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  }>;
};

export type GetAllThePostsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllThePostsQuery = {
  __typename?: 'query_root';
  posts: Array<{
    __typename?: 'posts';
    id: any;
    user_id: any;
    org_id: any;
    message: string;
    visibility: string;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    is_active: boolean;
    post_type?: Enum_Post_Type_Enum | null;
    likes: Array<{
      __typename?: 'post_likes';
      id: any;
      liked_by: any;
      likedByUser: { __typename?: 'users'; display_name?: string | null };
    }>;
    comments: Array<{
      __typename?: 'posts_comments';
      id: any;
      comment: string;
      commented_by: any;
      commentedByUser: { __typename?: 'users'; display_name?: string | null };
      commentReplies: Array<{
        __typename?: 'post_comment_replies';
        id: any;
        replied_by: any;
        reply?: string | null;
        userWhoReplied?: {
          __typename?: 'users';
          display_name?: string | null;
        } | null;
      }>;
    }>;
    mentioned_users: Array<{
      __typename?: 'post_mentioned_users';
      id: any;
      post_id: any;
      user_id: any;
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
      } | null;
    }>;
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  }>;
};

export type DeleteAPostByPostIdMutationVariables = Exact<{
  post_id: Scalars['uuid'];
}>;

export type DeleteAPostByPostIdMutation = {
  __typename?: 'mutation_root';
  delete_post_comment_replies_mentioned_users?: {
    __typename?: 'post_comment_replies_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_replies?: {
    __typename?: 'post_comment_replies_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_replies_likes?: {
    __typename?: 'post_replies_likes_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_mentioned_users?: {
    __typename?: 'post_comment_mentioned_users_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comment_files?: {
    __typename?: 'post_comment_files_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_comments_likes?: {
    __typename?: 'post_comments_likes_mutation_response';
    affected_rows: number;
  } | null;
  delete_posts_comments?: {
    __typename?: 'posts_comments_mutation_response';
    affected_rows: number;
  } | null;
  delete_posts_files?: {
    __typename?: 'posts_files_mutation_response';
    affected_rows: number;
  } | null;
  delete_post_likes?: {
    __typename?: 'post_likes_mutation_response';
    affected_rows: number;
  } | null;
  delete_posts?: {
    __typename?: 'posts_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreatePollChoicesMutationVariables = Exact<{
  choiceName?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['uuid']>;
}>;

export type CreatePollChoicesMutation = {
  __typename?: 'mutation_root';
  insert_posts_poll_choices?: {
    __typename?: 'posts_poll_choices_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddDisappearDateMutationVariables = Exact<{
  postId: Scalars['uuid'];
  date: Scalars['timestamptz'];
}>;

export type AddDisappearDateMutation = {
  __typename?: 'mutation_root';
  update_posts?: {
    __typename?: 'posts_mutation_response';
    returning: Array<{
      __typename?: 'posts';
      id: any;
      disappeared_at?: any | null;
    }>;
  } | null;
};

export type GetPollDetailsByPostIdQueryVariables = Exact<{
  postId: Scalars['uuid'];
}>;

export type GetPollDetailsByPostIdQuery = {
  __typename?: 'query_root';
  posts: Array<{
    __typename?: 'posts';
    disappeared_at?: any | null;
    message: string;
  }>;
};

export type GetAllOrgHolidaysQueryVariables = Exact<{
  date?: InputMaybe<Scalars['date']>;
}>;

export type GetAllOrgHolidaysQuery = {
  __typename?: 'query_root';
  organization_holidays: Array<{
    __typename?: 'organization_holidays';
    id: any;
    date: any;
    description?: string | null;
    organization_id: any;
    organization: {
      __typename?: 'organization';
      organization_name: string;
      organization_admins: Array<{
        __typename?: 'organization_admin';
        id: any;
        user_id: any;
      }>;
    };
  }>;
};

export type DeletePostByProjectIdAndStatusMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  status: Enum_Post_Type_Enum;
}>;

export type DeletePostByProjectIdAndStatusMutation = {
  __typename?: 'mutation_root';
  delete_posts?: {
    __typename?: 'posts_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetUserSettingsQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['uuid']>;
}>;

export type GetUserSettingsQuery = {
  __typename?: 'query_root';
  user_settings: Array<{
    __typename?: 'user_settings';
    id: any;
    setting_type: Enum_User_Settings_Type_Enum;
    setting_value: any;
  }>;
};

export type CreateProjectClientMutationVariables = Exact<{
  object: Project_Client_Insert_Input;
}>;

export type CreateProjectClientMutation = {
  __typename?: 'mutation_root';
  insert_project_client_one?: {
    __typename?: 'project_client';
    id: any;
    client_name: string;
    organization_id: any;
  } | null;
};

export type GetProjectClientByIdQueryVariables = Exact<{
  projectClientId: Scalars['uuid'];
}>;

export type GetProjectClientByIdQuery = {
  __typename?: 'query_root';
  project_client?: {
    __typename?: 'project_client';
    id: any;
    client_name: string;
    organization_id: any;
  } | null;
};

export type GetProjectMembersQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;

export type GetProjectMembersQuery = {
  __typename?: 'query_root';
  project_members: Array<{
    __typename?: 'project_members';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
      calendars: Array<{ __typename?: 'calendar'; id: any }>;
    };
  }>;
};

export type InsertProjectMembersMutationVariables = Exact<{
  newMembers?: InputMaybe<
    Array<Project_Members_Insert_Input> | Project_Members_Insert_Input
  >;
}>;

export type InsertProjectMembersMutation = {
  __typename?: 'mutation_root';
  insert_project_members?: {
    __typename?: 'project_members_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddMembersToProjectsMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Project_Members_Insert_Input> | Project_Members_Insert_Input
  >;
}>;

export type AddMembersToProjectsMutation = {
  __typename?: 'mutation_root';
  insert_project_members?: {
    __typename?: 'project_members_mutation_response';
    returning: Array<{ __typename?: 'project_members'; user_id: any }>;
  } | null;
};

export type ProjectOwnedByQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type ProjectOwnedByQuery = {
  __typename?: 'query_root';
  project: Array<{ __typename?: 'project'; owned_by?: any | null }>;
};

export type CheckMemberExistProjectQueryVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type CheckMemberExistProjectQuery = {
  __typename?: 'query_root';
  project_members: Array<{ __typename?: 'project_members'; user_id: any }>;
};

export type InsertProjectMilestoneMutationVariables = Exact<{
  milestone: Project_Milestones_Insert_Input;
}>;

export type InsertProjectMilestoneMutation = {
  __typename?: 'mutation_root';
  insert_project_milestones_one?: {
    __typename?: 'project_milestones';
    created_at: any;
    date: any;
    id: any;
    name: string;
    project_id: any;
    status: Enum_Project_Milestone_Status_Enum;
  } | null;
};

export type InsertMultipleTasksForMilestoneMutationVariables = Exact<{
  data: Array<Task_Milestones_Insert_Input> | Task_Milestones_Insert_Input;
}>;

export type InsertMultipleTasksForMilestoneMutation = {
  __typename?: 'mutation_root';
  insert_task_milestones?: {
    __typename?: 'task_milestones_mutation_response';
    returning: Array<{ __typename?: 'task_milestones'; task_id: any }>;
  } | null;
};

export type UpdateProjectMilestoneByIdMutationVariables = Exact<{
  milestoneId: Scalars['uuid'];
  data?: InputMaybe<Project_Milestones_Set_Input>;
}>;

export type UpdateProjectMilestoneByIdMutation = {
  __typename?: 'mutation_root';
  update_project_milestones_by_pk?: {
    __typename?: 'project_milestones';
    created_at: any;
    date: any;
    id: any;
    name: string;
    project_id: any;
    status: Enum_Project_Milestone_Status_Enum;
  } | null;
};

export type DeleteTaskMilestonesMutationVariables = Exact<{
  milestoneId: Scalars['uuid'];
  taskIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type DeleteTaskMilestonesMutation = {
  __typename?: 'mutation_root';
  delete_task_milestones?: {
    __typename?: 'task_milestones_mutation_response';
    returning: Array<{ __typename?: 'task_milestones'; milestone_id: any }>;
  } | null;
};

export type DeleteProjectMilestoneByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteProjectMilestoneByIdMutation = {
  __typename?: 'mutation_root';
  delete_project_milestones_by_pk?: {
    __typename?: 'project_milestones';
    id: any;
    project_id: any;
  } | null;
};

export type CreateSprintsMutationVariables = Exact<{
  sprints: Array<Project_Sprints_Insert_Input> | Project_Sprints_Insert_Input;
}>;

export type CreateSprintsMutation = {
  __typename?: 'mutation_root';
  insert_project_sprints?: {
    __typename?: 'project_sprints_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'project_sprints'; id: any }>;
  } | null;
};

export type GetSprintDetailsByIdQueryVariables = Exact<{
  sprintId: Scalars['uuid'];
}>;

export type GetSprintDetailsByIdQuery = {
  __typename?: 'query_root';
  project_sprints_by_pk?: {
    __typename?: 'project_sprints';
    project_id: any;
    meeting_id?: any | null;
    project: {
      __typename?: 'project';
      created_by?: any | null;
      owned_by?: any | null;
    };
  } | null;
};

export type GetTasksBySprintIdQueryVariables = Exact<{
  sprintId: Scalars['uuid'];
}>;

export type GetTasksBySprintIdQuery = {
  __typename?: 'query_root';
  project_sprints_by_pk?: {
    __typename?: 'project_sprints';
    project_id: any;
    tasks: Array<{ __typename?: 'task'; id: any }>;
  } | null;
};

export type DeleteCalendarEventAssociatedToSprintMutationVariables = Exact<{
  eventId: Scalars['uuid'];
}>;

export type DeleteCalendarEventAssociatedToSprintMutation = {
  __typename?: 'mutation_root';
  delete_calendar_event_by_pk?: {
    __typename?: 'calendar_event';
    calendar_id: any;
  } | null;
};

export type DeleteProjectSprintByIdMutationVariables = Exact<{
  sprintId: Scalars['uuid'];
}>;

export type DeleteProjectSprintByIdMutation = {
  __typename?: 'mutation_root';
  delete_project_sprints_by_pk?: {
    __typename?: 'project_sprints';
    id: any;
  } | null;
};

export type InsertProjectMutationVariables = Exact<{
  object: Project_Insert_Input;
}>;

export type InsertProjectMutation = {
  __typename?: 'mutation_root';
  insert_project_one?: {
    __typename?: 'project';
    id: any;
    client_id: any;
    description?: string | null;
    is_billable: boolean;
    organization_id: any;
    title: string;
  } | null;
};

export type GetProjectInfoByIdQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;

export type GetProjectInfoByIdQuery = {
  __typename?: 'query_root';
  project_by_pk?: {
    __typename?: 'project';
    id: any;
    title: string;
    description?: string | null;
    client_id: any;
    is_billable: boolean;
    is_deleted: boolean;
    created_by?: any | null;
    organization_id: any;
    created_at: any;
    updated_at: any;
    owned_by?: any | null;
    chat_room?: { __typename?: 'chat_room'; id: any } | null;
  } | null;
};

export type UpdateProjectByIdMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  data: Project_Set_Input;
}>;

export type UpdateProjectByIdMutation = {
  __typename?: 'mutation_root';
  update_project_by_pk?: { __typename?: 'project'; id: any } | null;
};

export type GetTasksByProjectIdQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;

export type GetTasksByProjectIdQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    description?: string | null;
  }>;
};

export type GetUnsheduledTasksByProjectIdQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;

export type GetUnsheduledTasksByProjectIdQuery = {
  __typename?: 'query_root';
  task_unscheduled: Array<{
    __typename?: 'task_unscheduled';
    id: any;
    title: string;
  }>;
};

export type GetTaskTimeSheetByProjectIdQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;

export type GetTaskTimeSheetByProjectIdQuery = {
  __typename?: 'query_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    id: any;
    task_id?: any | null;
    task_name?: string | null;
  }>;
};

export type DeleteProjectByIdMutationVariables = Exact<{
  project_id: Scalars['uuid'];
}>;

export type DeleteProjectByIdMutation = {
  __typename?: 'mutation_root';
  delete_project_members?: {
    __typename?: 'project_members_mutation_response';
    affected_rows: number;
  } | null;
  delete_project_by_pk?: {
    __typename?: 'project';
    id: any;
    title: string;
    description?: string | null;
  } | null;
};

export type InsertUserPresenceMutationVariables = Exact<{
  object: User_Presence_Insert_Input;
}>;

export type InsertUserPresenceMutation = {
  __typename?: 'mutation_root';
  insert_user_presence_one?: {
    __typename?: 'user_presence';
    user_id: any;
  } | null;
};

export type DeleteUserPresenceMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  connection_id: Scalars['String'];
}>;

export type DeleteUserPresenceMutation = {
  __typename?: 'mutation_root';
  delete_user_presence_by_pk?: {
    __typename?: 'user_presence';
    user_id: any;
  } | null;
};

export type UpdateUserPresenceMutationVariables = Exact<{
  where: User_Presence_Bool_Exp;
  _set: User_Presence_Set_Input;
}>;

export type UpdateUserPresenceMutation = {
  __typename?: 'mutation_root';
  update_user_presence?: {
    __typename?: 'user_presence_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user_presence';
      user_id: any;
      connection_id: string;
    }>;
  } | null;
};

export type GetUserPresenceQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['uuid']>;
  connection_id?: InputMaybe<Scalars['String']>;
}>;

export type GetUserPresenceQuery = {
  __typename?: 'query_root';
  user_presence: Array<{
    __typename?: 'user_presence';
    connection_id: string;
    user_id: any;
    connection_status: Enum_Presence_Connection_Status_Enum;
    type?: Enum_User_Presence_Provider_Type_Enum | null;
    metadata?: any | null;
  }>;
};

export type GetStorageFileByIdQueryVariables = Exact<{
  fileId: Scalars['uuid'];
}>;

export type GetStorageFileByIdQuery = {
  __typename?: 'query_root';
  storage_file?: {
    __typename?: 'storage_files';
    id: any;
    file_url: string;
    type: Enum_Storage_File_Type_Enum;
  } | null;
};

export type UpdateStorageFileByIdMutationVariables = Exact<{
  storageFileId: Scalars['uuid'];
  object: Storage_Files_Set_Input;
}>;

export type UpdateStorageFileByIdMutation = {
  __typename?: 'mutation_root';
  update_storage_files_by_pk?: {
    __typename?: 'storage_files';
    id: any;
    file_url: string;
    metadata: any;
    created_by?: any | null;
  } | null;
};

export type GetTaskApproversByTaskIdQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetTaskApproversByTaskIdQuery = {
  __typename?: 'query_root';
  task_approvers: Array<{ __typename?: 'task_approvers'; approver_id: any }>;
};

export type Insert_Multiple_Task_ApproversMutationVariables = Exact<{
  approvers: Array<Task_Approvers_Insert_Input> | Task_Approvers_Insert_Input;
}>;

export type Insert_Multiple_Task_ApproversMutation = {
  __typename?: 'mutation_root';
  insert_task_approvers?: {
    __typename?: 'task_approvers_mutation_response';
    returning: Array<{ __typename?: 'task_approvers'; approver_id: any }>;
  } | null;
};

export type Delete_Multiple_Task_ApproversMutationVariables = Exact<{
  taskId: Scalars['uuid'];
  approverIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type Delete_Multiple_Task_ApproversMutation = {
  __typename?: 'mutation_root';
  delete_task_approvers?: {
    __typename?: 'task_approvers_mutation_response';
    returning: Array<{
      __typename?: 'task_approvers';
      approver_id: any;
      task_id: any;
    }>;
  } | null;
};

export type InsertChecklistMutationVariables = Exact<{
  itemName: Scalars['String'];
  taskId: Scalars['uuid'];
  isApproved?: InputMaybe<Scalars['Boolean']>;
  approvedBy?: InputMaybe<Scalars['uuid']>;
}>;

export type InsertChecklistMutation = {
  __typename?: 'mutation_root';
  insert_task_checklist_one?: {
    __typename?: 'task_checklist';
    created_at: any;
    id: any;
    is_approved: boolean;
    item_name: string;
    task_id: any;
    updated_at: any;
    approved_by?: any | null;
  } | null;
};

export type UpdateChecklistItemNameMutationVariables = Exact<{
  id: Scalars['uuid'];
  itemName: Scalars['String'];
}>;

export type UpdateChecklistItemNameMutation = {
  __typename?: 'mutation_root';
  update_task_checklist_by_pk?: {
    __typename?: 'task_checklist';
    id: any;
    item_name: string;
  } | null;
};

export type UpdateChecklistApprovedByMutationVariables = Exact<{
  id: Scalars['uuid'];
  isApproved: Scalars['Boolean'];
  approvedBy: Scalars['uuid'];
}>;

export type UpdateChecklistApprovedByMutation = {
  __typename?: 'mutation_root';
  update_task_checklist_by_pk?: {
    __typename?: 'task_checklist';
    approved_by?: any | null;
    id: any;
    is_approved: boolean;
    updated_at: any;
  } | null;
};

export type GetChecklistByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetChecklistByIdQuery = {
  __typename?: 'query_root';
  checklist?: {
    __typename?: 'task_checklist';
    approved_by?: any | null;
    is_approved: boolean;
    item_name: string;
    task: {
      __typename?: 'task';
      created_by: any;
      assigned_to?: any | null;
      project_id?: any | null;
      id: any;
      project?: { __typename?: 'project'; created_by?: any | null } | null;
      task_approvers: Array<{
        __typename?: 'task_approvers';
        approver_id: any;
      }>;
    };
  } | null;
};

export type DeleteTaskChecklistByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteTaskChecklistByIdMutation = {
  __typename?: 'mutation_root';
  delete_task_checklist_by_pk?: {
    __typename?: 'task_checklist';
    id: any;
  } | null;
};

export type CreateTaskCollaboratorMutationVariables = Exact<{
  object: Task_Collaborator_Insert_Input;
}>;

export type CreateTaskCollaboratorMutation = {
  __typename?: 'mutation_root';
  insert_task_collaborator_one?: {
    __typename?: 'task_collaborator';
    user_id: any;
    task_id: any;
  } | null;
};

export type GetTaskCollaboratorByTaskIdQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetTaskCollaboratorByTaskIdQuery = {
  __typename?: 'query_root';
  task_collaborator: Array<{
    __typename?: 'task_collaborator';
    task_id: any;
    user_id: any;
  }>;
};

export type GetProjectMembersAndTasksByIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetProjectMembersAndTasksByIdQuery = {
  __typename?: 'query_root';
  project_members: Array<{
    __typename?: 'project_members';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      first_name?: string | null;
    };
  }>;
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    status?: Enum_Task_Status_Enum | null;
  }>;
};

export type CreateTaskFileMutationVariables = Exact<{
  object: Task_File_Insert_Input;
}>;

export type CreateTaskFileMutation = {
  __typename?: 'mutation_root';
  insert_task_file_one?: {
    __typename?: 'task_file';
    storage_file_id: any;
    task_id: any;
  } | null;
};

export type SaveTimeSheetMutationVariables = Exact<{
  object: Task_Time_Sheet_Insert_Input;
  update_columns?: InputMaybe<
    Array<Task_Time_Sheet_Update_Column> | Task_Time_Sheet_Update_Column
  >;
}>;

export type SaveTimeSheetMutation = {
  __typename?: 'mutation_root';
  insert_task_time_sheet_one?: {
    __typename?: 'task_time_sheet';
    id: any;
    task_name?: string | null;
    work_date: any;
    work_hours?: any | null;
    work_minutes?: any | null;
    status: Enum_Task_Time_Sheet_Status_Enum;
  } | null;
};

export type CreateTaskUnscheduledMutationVariables = Exact<{
  object: Task_Unscheduled_Insert_Input;
}>;

export type CreateTaskUnscheduledMutation = {
  __typename?: 'mutation_root';
  insert_task_unscheduled_one?: {
    __typename?: 'task_unscheduled';
    id: any;
    title: string;
    project_id: any;
    created_by: any;
  } | null;
};

export type GetUnScheduledTaskByIdQueryVariables = Exact<{
  unscheduled_task_id: Scalars['uuid'];
}>;

export type GetUnScheduledTaskByIdQuery = {
  __typename?: 'query_root';
  task_unscheduled_by_pk?: {
    __typename?: 'task_unscheduled';
    id: any;
    created_at: any;
    updated_at: any;
    title: string;
    created_by: any;
    project_id: any;
  } | null;
};

export type UpdateTaskUnscheduledMutationVariables = Exact<{
  id: Scalars['uuid'];
  object: Task_Unscheduled_Set_Input;
}>;

export type UpdateTaskUnscheduledMutation = {
  __typename?: 'mutation_root';
  update_task_unscheduled_by_pk?: {
    __typename?: 'task_unscheduled';
    id: any;
    title: string;
    project_id: any;
    created_by: any;
  } | null;
};

export type InsertTaskMutationVariables = Exact<{
  object: Task_Insert_Input;
}>;

export type InsertTaskMutation = {
  __typename?: 'mutation_root';
  insert_task_one?: {
    __typename?: 'task';
    assigned_to?: any | null;
    created_by: any;
    created_at: any;
    description?: string | null;
    due_date_time?: any | null;
    id: any;
    organization_id: any;
    project_id?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    title: string;
    start_date_time?: any | null;
  } | null;
};

export type InsertTaskMilestonesMutationVariables = Exact<{
  data: Array<Task_Milestones_Insert_Input> | Task_Milestones_Insert_Input;
}>;

export type InsertTaskMilestonesMutation = {
  __typename?: 'mutation_root';
  insert_task_milestones?: {
    __typename?: 'task_milestones_mutation_response';
    returning: Array<{ __typename?: 'task_milestones'; milestone_id: any }>;
  } | null;
};

export type GetMilestonesOfTaskQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetMilestonesOfTaskQuery = {
  __typename?: 'query_root';
  task_milestones: Array<{ __typename?: 'task_milestones'; milestone_id: any }>;
};

export type InsertTaskTagsMutationVariables = Exact<{
  objects: Array<Task_Tags_Insert_Input> | Task_Tags_Insert_Input;
}>;

export type InsertTaskTagsMutation = {
  __typename?: 'mutation_root';
  insert_task_tags?: {
    __typename?: 'task_tags_mutation_response';
    returning: Array<{ __typename?: 'task_tags'; tag_id: any; task_id: any }>;
  } | null;
};

export type RemoveMilestonesOfTaskMutationVariables = Exact<{
  task_id: Scalars['uuid'];
  milestone_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type RemoveMilestonesOfTaskMutation = {
  __typename?: 'mutation_root';
  delete_task_milestones?: {
    __typename?: 'task_milestones_mutation_response';
    returning: Array<{ __typename?: 'task_milestones'; milestone_id: any }>;
  } | null;
};

export type GetTaskRanksForProjectQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetTaskRanksForProjectQuery = {
  __typename?: 'query_root';
  task: Array<{ __typename?: 'task'; id: any; rank?: any | null }>;
};

export type UpdateTaskManyMutationVariables = Exact<{
  updates: Array<Task_Updates> | Task_Updates;
}>;

export type UpdateTaskManyMutation = {
  __typename?: 'mutation_root';
  update_task_many?: Array<{
    __typename?: 'task_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'task';
      id: any;
      start_date_time?: any | null;
      due_date_time?: any | null;
      rank?: any | null;
    }>;
  } | null> | null;
};

export type UpdateEmployeeCapacitiesMutationVariables = Exact<{
  updates: Array<Employee_Capacity_Updates> | Employee_Capacity_Updates;
}>;

export type UpdateEmployeeCapacitiesMutation = {
  __typename?: 'mutation_root';
  update_employee_capacity_many?: Array<{
    __typename?: 'employee_capacity_mutation_response';
    affected_rows: number;
  } | null> | null;
};

export type GetTaskByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetTaskByIdQuery = {
  __typename?: 'query_root';
  task?: {
    __typename?: 'task';
    id: any;
    created_at: any;
    updated_at: any;
    title: string;
    description?: string | null;
    status?: Enum_Task_Status_Enum | null;
    created_by: any;
    assigned_to?: any | null;
    due_date_time?: any | null;
    start_date_time?: any | null;
    organization_id: any;
    estimated_time?: any | null;
    project_id?: any | null;
    updated_by?: any | null;
    assigned_by?: any | null;
    assigned_at: any;
    priority?: Enum_Task_Priority_Enum | null;
    is_draft: boolean;
    rank?: any | null;
    task_approvers: Array<{ __typename?: 'task_approvers'; approver_id: any }>;
    project?: {
      __typename?: 'project';
      created_by?: any | null;
      owned_by?: any | null;
    } | null;
  } | null;
};

export type GetTasksAssignedToUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetTasksAssignedToUserQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    due_date_time?: any | null;
  }>;
};

export type GetPreviousTaskRankQueryVariables = Exact<{
  refRank: Scalars['float8'];
  projectId: Scalars['uuid'];
}>;

export type GetPreviousTaskRankQuery = {
  __typename?: 'query_root';
  task: Array<{ __typename?: 'task'; rank?: any | null }>;
};

export type GetNextTaskRankQueryVariables = Exact<{
  refRank: Scalars['float8'];
  projectId: Scalars['uuid'];
}>;

export type GetNextTaskRankQuery = {
  __typename?: 'query_root';
  task: Array<{ __typename?: 'task'; rank?: any | null }>;
};

export type GetFirstRankedTaskQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetFirstRankedTaskQuery = {
  __typename?: 'query_root';
  task: Array<{ __typename?: 'task'; id: any; rank?: any | null }>;
};

export type GetMultipleTaskDetailsQueryVariables = Exact<{
  task_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetMultipleTaskDetailsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    created_by: any;
    project_id?: any | null;
    id: any;
    due_date_time?: any | null;
    start_date_time?: any | null;
    assigned_to?: any | null;
    assigned_by?: any | null;
    rank?: any | null;
    project?: {
      __typename?: 'project';
      created_by?: any | null;
      owned_by?: any | null;
    } | null;
  }>;
};

export type GetPrecalculatedEmployeeCapacityQueryVariables = Exact<{
  employeeId: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetPrecalculatedEmployeeCapacityQuery = {
  __typename?: 'query_root';
  employee_capacity: Array<{
    __typename?: 'employee_capacity';
    date: any;
    workload_hours: any;
    employee_id: any;
  }>;
};

export type InsertMultipleTasksMutationVariables = Exact<{
  objects: Array<Task_Insert_Input> | Task_Insert_Input;
}>;

export type InsertMultipleTasksMutation = {
  __typename?: 'mutation_root';
  insert_task?: {
    __typename?: 'task_mutation_response';
    returning: Array<{ __typename?: 'task'; id: any }>;
  } | null;
};

export type UpdateTasksNextRecurredDateMutationVariables = Exact<{
  taskIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  nextRecurredDate: Scalars['timestamptz'];
}>;

export type UpdateTasksNextRecurredDateMutation = {
  __typename?: 'mutation_root';
  update_task?: {
    __typename?: 'task_mutation_response';
    returning: Array<{
      __typename?: 'task';
      id: any;
      next_recurred_date?: any | null;
    }>;
  } | null;
};

export type GetAllRecurringTasksQueryVariables = Exact<{
  recurringStartDate: Scalars['timestamptz'];
  recurringEndDate: Scalars['timestamptz'];
}>;

export type GetAllRecurringTasksQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    assigned_by?: any | null;
    assigned_to?: any | null;
    category?: Enum_Project_Task_Category_Enum | null;
    created_by: any;
    description?: string | null;
    due_date_time?: any | null;
    estimated_time?: any | null;
    next_recurred_date?: any | null;
    organization_id: any;
    priority?: Enum_Task_Priority_Enum | null;
    project_id?: any | null;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    sprint_id?: any | null;
    start_date_time?: any | null;
    title: string;
    id: any;
  }>;
};

export type GetAllDependentTasksQueryVariables = Exact<{
  parentTaskId: Scalars['uuid'];
}>;

export type GetAllDependentTasksQuery = {
  __typename?: 'query_root';
  get_all_dependent_tasks_new: Array<{
    __typename?: 'task_dependencies';
    task_id: any;
    dependent_on_task_id: any;
  }>;
};

export type AddDependenciesToTaskMutationVariables = Exact<{
  objects:
    | Array<Task_Dependencies_Insert_Input>
    | Task_Dependencies_Insert_Input;
}>;

export type AddDependenciesToTaskMutation = {
  __typename?: 'mutation_root';
  insert_task_dependencies?: {
    __typename?: 'task_dependencies_mutation_response';
    affected_rows: number;
  } | null;
};

export type RemoveDependenciesToTaskMutationVariables = Exact<{
  taskId: Scalars['uuid'];
  dependentOnTaskId: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type RemoveDependenciesToTaskMutation = {
  __typename?: 'mutation_root';
  delete_task_dependencies?: {
    __typename?: 'task_dependencies_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateTaskReturnIdMutationVariables = Exact<{
  data: Task_Set_Input;
  taskId: Scalars['uuid'];
}>;

export type UpdateTaskReturnIdMutation = {
  __typename?: 'mutation_root';
  update_task?: {
    __typename?: 'task_mutation_response';
    returning: Array<{ __typename?: 'task'; id: any }>;
  } | null;
};

export type GetTaskCollaboratorByIdsQueryVariables = Exact<{
  collaboratorId: Scalars['uuid'];
  taskId: Scalars['uuid'];
}>;

export type GetTaskCollaboratorByIdsQuery = {
  __typename?: 'query_root';
  task_collaborator: Array<{
    __typename?: 'task_collaborator';
    user: { __typename?: 'users'; display_name?: string | null };
  }>;
};

export type GetCollaboratorsByTaskIdQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetCollaboratorsByTaskIdQuery = {
  __typename?: 'query_root';
  task_collaborator: Array<{ __typename?: 'task_collaborator'; user_id: any }>;
};

export type MemberReleaseDateQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type MemberReleaseDateQuery = {
  __typename?: 'query_root';
  project_members: Array<{ __typename?: 'project_members'; release_date: any }>;
};

export type GetProjectMemberByPkQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type GetProjectMemberByPkQuery = {
  __typename?: 'query_root';
  project_members_by_pk?: {
    __typename?: 'project_members';
    release_date: any;
  } | null;
};

export type ActivityTaskLogMutationVariables = Exact<{
  comment_text: Scalars['String'];
  created_by: Scalars['uuid'];
  task_id: Scalars['uuid'];
  activity_type: Enum_Activity_Type_Enum;
}>;

export type ActivityTaskLogMutation = {
  __typename?: 'mutation_root';
  insert_task_comment?: {
    __typename?: 'task_comment_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'task_comment'; id: any }>;
  } | null;
};

export type InsertMultipleTaskCollaboratorsMutationVariables = Exact<{
  add_collaborators?: InputMaybe<
    Array<Task_Collaborator_Insert_Input> | Task_Collaborator_Insert_Input
  >;
}>;

export type InsertMultipleTaskCollaboratorsMutation = {
  __typename?: 'mutation_root';
  insert_task_collaborator?: {
    __typename?: 'task_collaborator_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertMultipleTaskTagsMutationVariables = Exact<{
  add_tags?: InputMaybe<Array<Task_Tags_Insert_Input> | Task_Tags_Insert_Input>;
}>;

export type InsertMultipleTaskTagsMutation = {
  __typename?: 'mutation_root';
  insert_task_tags?: {
    __typename?: 'task_tags_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateMultipleTasksFieldMutationVariables = Exact<{
  task_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  updateTasks: Task_Set_Input;
}>;

export type UpdateMultipleTasksFieldMutation = {
  __typename?: 'mutation_root';
  update_task?: {
    __typename?: 'task_mutation_response';
    returning: Array<{ __typename?: 'task'; id: any }>;
  } | null;
};

export type DeleteTasksMutationVariables = Exact<{
  task_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type DeleteTasksMutation = {
  __typename?: 'mutation_root';
  delete_task?: {
    __typename?: 'task_mutation_response';
    returning: Array<{ __typename?: 'task'; id: any }>;
  } | null;
};

export type UpdateTaskFieldMutationVariables = Exact<{
  taskInput: Task_Set_Input;
  taskId: Scalars['uuid'];
  newTags?: InputMaybe<Array<Task_Tags_Insert_Input> | Task_Tags_Insert_Input>;
  newCollaborators?: InputMaybe<
    Array<Task_Collaborator_Insert_Input> | Task_Collaborator_Insert_Input
  >;
  filesInput?: InputMaybe<
    Array<Task_File_Insert_Input> | Task_File_Insert_Input
  >;
  removedCollaboratorsUserIds?: InputMaybe<
    Array<Scalars['uuid']> | Scalars['uuid']
  >;
  removedTagIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  projectMembers?: InputMaybe<
    Array<Project_Members_Insert_Input> | Project_Members_Insert_Input
  >;
}>;

export type UpdateTaskFieldMutation = {
  __typename?: 'mutation_root';
  update_task?: {
    __typename?: 'task_mutation_response';
    affected_rows: number;
  } | null;
  insert_task_tags?: {
    __typename?: 'task_tags_mutation_response';
    affected_rows: number;
  } | null;
  delete_task_tags?: {
    __typename?: 'task_tags_mutation_response';
    affected_rows: number;
  } | null;
  insert_task_collaborator?: {
    __typename?: 'task_collaborator_mutation_response';
    affected_rows: number;
  } | null;
  delete_task_collaborator?: {
    __typename?: 'task_collaborator_mutation_response';
    affected_rows: number;
  } | null;
  insert_task_file?: {
    __typename?: 'task_file_mutation_response';
    affected_rows: number;
  } | null;
  insert_project_members?: {
    __typename?: 'project_members_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetCollaboratorFilteredTaskQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetCollaboratorFilteredTaskQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type GetTagsFilteredTaskQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetTagsFilteredTaskQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type GetTagsAndCollaboratorsFilteredTaskQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetTagsAndCollaboratorsFilteredTaskQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type GetFilteredTaskQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetFilteredTaskQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  task_aggregate: {
    __typename?: 'task_aggregate';
    aggregate?: { __typename?: 'task_aggregate_fields'; count: number } | null;
  };
};

export type GroupBySprintQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
}>;

export type GroupBySprintQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project_sprints: Array<{
    __typename?: 'project_sprints';
    id: any;
    name: string;
    start_date: any;
    end_date: any;
    category: Enum_Project_Sprint_Category_Enum;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupBySprintWithCollaboratorsQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupBySprintWithCollaboratorsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project_sprints: Array<{
    __typename?: 'project_sprints';
    id: any;
    name: string;
    start_date: any;
    end_date: any;
    category: Enum_Project_Sprint_Category_Enum;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupBySprintWithTagsQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupBySprintWithTagsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project_sprints: Array<{
    __typename?: 'project_sprints';
    id: any;
    name: string;
    start_date: any;
    end_date: any;
    category: Enum_Project_Sprint_Category_Enum;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupBySprintWithTagsAndCollaboratorsQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupBySprintWithTagsAndCollaboratorsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project_sprints: Array<{
    __typename?: 'project_sprints';
    id: any;
    name: string;
    start_date: any;
    end_date: any;
    category: Enum_Project_Sprint_Category_Enum;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByPriorityQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
}>;

export type GroupByPriorityQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  enum_task_priority: Array<{
    __typename?: 'enum_task_priority';
    value: string;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByPriorityWithCollaboratorsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByPriorityWithCollaboratorsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  enum_task_priority: Array<{
    __typename?: 'enum_task_priority';
    value: string;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByPriorityWithTagsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByPriorityWithTagsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  enum_task_priority: Array<{
    __typename?: 'enum_task_priority';
    value: string;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByPriorityWithTagsAndCollaboratorsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByPriorityWithTagsAndCollaboratorsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  enum_task_priority: Array<{
    __typename?: 'enum_task_priority';
    value: string;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByStatusQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
}>;

export type GroupByStatusQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  enum_task_status: Array<{
    __typename?: 'enum_task_status';
    value: string;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByStatusWithCollaboratorsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByStatusWithCollaboratorsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  enum_task_status: Array<{
    __typename?: 'enum_task_status';
    value: string;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByStatusWithTagsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByStatusWithTagsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  enum_task_status: Array<{
    __typename?: 'enum_task_status';
    value: string;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByStatusWithTagsAndCollaboratorsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByStatusWithTagsAndCollaboratorsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  enum_task_status: Array<{
    __typename?: 'enum_task_status';
    value: string;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByProjectWithCollaboratorsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByProjectWithCollaboratorsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project: Array<{
    __typename?: 'project';
    title: string;
    organization_id: any;
    id: any;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByProjectWithTagsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByProjectWithTagsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project: Array<{
    __typename?: 'project';
    title: string;
    organization_id: any;
    id: any;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByProjectWithCollaboratorsAndTagsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByProjectWithCollaboratorsAndTagsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project: Array<{
    __typename?: 'project';
    title: string;
    organization_id: any;
    id: any;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupByProjectQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GroupByProjectQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project: Array<{
    __typename?: 'project';
    title: string;
    organization_id: any;
    id: any;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupBySectionQueryVariables = Exact<{
  sectionId?: InputMaybe<Scalars['uuid']>;
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GroupBySectionQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project_section: Array<{
    __typename?: 'project_section';
    id: any;
    name: string;
    created_at: any;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      project_section?: {
        __typename?: 'project_section';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupBySectionWithTagsQueryVariables = Exact<{
  sectionId?: InputMaybe<Scalars['uuid']>;
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GroupBySectionWithTagsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project_section: Array<{
    __typename?: 'project_section';
    id: any;
    name: string;
    created_at: any;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      project_section?: {
        __typename?: 'project_section';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupBySectionWithCollaboratorsQueryVariables = Exact<{
  sectionId?: InputMaybe<Scalars['uuid']>;
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GroupBySectionWithCollaboratorsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project_section: Array<{
    __typename?: 'project_section';
    id: any;
    name: string;
    created_at: any;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      project_section?: {
        __typename?: 'project_section';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GroupBySectionWithCollaboratorsAndTagsQueryVariables = Exact<{
  sectionId?: InputMaybe<Scalars['uuid']>;
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<
    Array<Enum_Project_Task_Category_Enum> | Enum_Project_Task_Category_Enum
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
  dueDateStartTime?: InputMaybe<Scalars['timestamptz']>;
  dueDateEndTime?: InputMaybe<Scalars['timestamptz']>;
  priority?: InputMaybe<
    Array<Enum_Task_Priority_Enum> | Enum_Task_Priority_Enum
  >;
  recurringFrequency?: InputMaybe<
    | Array<Enum_Task_Recurring_Frequency_Enum>
    | Enum_Task_Recurring_Frequency_Enum
  >;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  collaboratorIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GroupBySectionWithCollaboratorsAndTagsQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    priority?: Enum_Task_Priority_Enum | null;
    created_by: any;
    created_at: any;
    estimated_time?: any | null;
    start_date_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    is_draft: boolean;
    project?: { __typename?: 'project'; id: any; title: string } | null;
    user?: { __typename?: 'users'; id: any } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        type: Enum_Storage_File_Type_Enum;
        metadata: any;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag_id: any;
      tag: { __typename?: 'tags'; name: string };
    }>;
    taskDependenciesByTaskId: Array<{
      __typename?: 'task_dependencies';
      dependent_on_task_id: any;
    }>;
  }>;
  project_section: Array<{
    __typename?: 'project_section';
    id: any;
    name: string;
    created_at: any;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      priority?: Enum_Task_Priority_Enum | null;
      created_by: any;
      created_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      is_draft: boolean;
      project?: { __typename?: 'project'; id: any; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
      task_files: Array<{
        __typename?: 'task_file';
        storage_file: {
          __typename?: 'storage_files';
          id: any;
          file_url: string;
          type: Enum_Storage_File_Type_Enum;
          metadata: any;
          enum_storage_file_type: {
            __typename?: 'enum_storage_file_type';
            value: string;
          };
        };
      }>;
      project_sprint?: {
        __typename?: 'project_sprints';
        id: any;
        name: string;
      } | null;
      project_section?: {
        __typename?: 'project_section';
        id: any;
        name: string;
      } | null;
      task_tags: Array<{
        __typename?: 'task_tags';
        tag_id: any;
        tag: { __typename?: 'tags'; name: string };
      }>;
      taskDependenciesByTaskId: Array<{
        __typename?: 'task_dependencies';
        dependent_on_task_id: any;
      }>;
    }>;
  }>;
};

export type GetAllTimesheetInRangeQueryVariables = Exact<{
  startOfWeek: Scalars['date'];
  endOfWeek: Scalars['date'];
}>;

export type GetAllTimesheetInRangeQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      first_name?: string | null;
      task_time_sheets: Array<{
        __typename?: 'task_time_sheet';
        work_date: any;
      }>;
    } | null;
  }>;
};

export type GetMyTimesheetsForGivenWeekQueryVariables = Exact<{
  userId: Scalars['uuid'];
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
}>;

export type GetMyTimesheetsForGivenWeekQuery = {
  __typename?: 'query_root';
  task_time_sheet: Array<{
    __typename?: 'task_time_sheet';
    id: any;
    work_date: any;
    task_name?: string | null;
  }>;
};

export type CreateTrainingMutationVariables = Exact<{
  objects?: InputMaybe<Array<Training_Insert_Input> | Training_Insert_Input>;
}>;

export type CreateTrainingMutation = {
  __typename?: 'mutation_root';
  insert_training?: {
    __typename?: 'training_mutation_response';
    returning: Array<{ __typename?: 'training'; id: any }>;
  } | null;
};

export type DeleteTrainingProgressMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type DeleteTrainingProgressMutation = {
  __typename?: 'mutation_root';
  delete_training_progress_record?: {
    __typename?: 'training_progress_record_mutation_response';
    returning: Array<{ __typename?: 'training_progress_record'; id: any }>;
  } | null;
};

export type CreateTrainingProgressMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Training_Progress_Record_Insert_Input>
    | Training_Progress_Record_Insert_Input
  >;
}>;

export type CreateTrainingProgressMutation = {
  __typename?: 'mutation_root';
  insert_training_progress_record?: {
    __typename?: 'training_progress_record_mutation_response';
    returning: Array<{ __typename?: 'training_progress_record'; id: any }>;
  } | null;
};

export type UpdateTrainingMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _set?: InputMaybe<Training_Set_Input>;
}>;

export type UpdateTrainingMutation = {
  __typename?: 'mutation_root';
  update_training?: {
    __typename?: 'training_mutation_response';
    returning: Array<{ __typename?: 'training'; id: any }>;
  } | null;
};

export type DeleteTrainingDataMutationVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type DeleteTrainingDataMutation = {
  __typename?: 'mutation_root';
  delete_training_role_details?: {
    __typename?: 'training_role_details_mutation_response';
    returning: Array<{ __typename?: 'training_role_details'; id: any }>;
  } | null;
  delete_training_table_of_contents?: {
    __typename?: 'training_table_of_contents_mutation_response';
    returning: Array<{ __typename?: 'training_table_of_contents'; id: any }>;
  } | null;
};

export type UpdateRoleAndContentsMutationVariables = Exact<{
  objects?: InputMaybe<
    | Array<Training_Role_Details_Insert_Input>
    | Training_Role_Details_Insert_Input
  >;
  objects1?: InputMaybe<
    | Array<Training_Table_Of_Contents_Insert_Input>
    | Training_Table_Of_Contents_Insert_Input
  >;
}>;

export type UpdateRoleAndContentsMutation = {
  __typename?: 'mutation_root';
  insert_training_role_details?: {
    __typename?: 'training_role_details_mutation_response';
    returning: Array<{ __typename?: 'training_role_details'; id: any }>;
  } | null;
  insert_training_table_of_contents?: {
    __typename?: 'training_table_of_contents_mutation_response';
    returning: Array<{ __typename?: 'training_table_of_contents'; id: any }>;
  } | null;
};

export type DeleteTrainingProgressOfSKillMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type DeleteTrainingProgressOfSKillMutation = {
  __typename?: 'mutation_root';
  delete_training_progress_record?: {
    __typename?: 'training_progress_record_mutation_response';
    returning: Array<{ __typename?: 'training_progress_record'; id: any }>;
  } | null;
};

export type CreateSkillsForTrainingMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Training_Skill_Insert_Input> | Training_Skill_Insert_Input
  >;
}>;

export type CreateSkillsForTrainingMutation = {
  __typename?: 'mutation_root';
  insert_training_Skill?: {
    __typename?: 'training_Skill_mutation_response';
    returning: Array<{ __typename?: 'training_Skill'; id: any }>;
  } | null;
};

export type Get_TrainingCountAttendByUserQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_TrainingCountAttendByUserQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    training_progress_records_aggregate: {
      __typename?: 'training_progress_record_aggregate';
      aggregate?: {
        __typename?: 'training_progress_record_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type Delete_Training_Progress_Of_CareerMutationVariables = Exact<{
  training_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type Delete_Training_Progress_Of_CareerMutation = {
  __typename?: 'mutation_root';
  delete_training_progress_record?: {
    __typename?: 'training_progress_record_mutation_response';
    returning: Array<{ __typename?: 'training_progress_record'; id: any }>;
  } | null;
};

export type AddUserActivityTrackerMutationVariables = Exact<{
  object: User_Activity_Tracker_Insert_Input;
}>;

export type AddUserActivityTrackerMutation = {
  __typename?: 'mutation_root';
  insert_user_activity_tracker_one?: {
    __typename?: 'user_activity_tracker';
    id: any;
  } | null;
};

export type UpdateUserActivityTrackerMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: User_Activity_Tracker_Set_Input;
}>;

export type UpdateUserActivityTrackerMutation = {
  __typename?: 'mutation_root';
  update_user_activity_tracker_by_pk?: {
    __typename?: 'user_activity_tracker';
    id: any;
  } | null;
};

export type GetUserActivityTrackerQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  start_date?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
}>;

export type GetUserActivityTrackerQuery = {
  __typename?: 'query_root';
  user_activity_tracker: Array<{
    __typename?: 'user_activity_tracker';
    id: any;
    created_at: any;
    updated_at: any;
    user_id: any;
    status: Enum_User_Activity_Tracker_Status_Type_Enum;
    last_status_updated_at: any;
    active_seconds: any;
    extra_seconds?: any | null;
  }>;
};

export type GetAllYesterdayUserActivityTrackerQueryVariables = Exact<{
  yesterday: Scalars['timestamptz'];
}>;

export type GetAllYesterdayUserActivityTrackerQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      first_name?: string | null;
      user_activity_trackers: Array<{
        __typename?: 'user_activity_tracker';
        active_seconds: any;
      }>;
    } | null;
  }>;
};

export type GetAllActiveTimeByDateQueryVariables = Exact<{
  date: Scalars['timestamptz'];
}>;

export type GetAllActiveTimeByDateQuery = {
  __typename?: 'query_root';
  user_activity_tracker: Array<{
    __typename?: 'user_activity_tracker';
    active_seconds: any;
    created_at: any;
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      first_name?: string | null;
    };
  }>;
};

export type CreateProfileMutationVariables = Exact<{
  object: Profile_Insert_Input;
}>;

export type CreateProfileMutation = {
  __typename?: 'mutation_root';
  insert_profile_one?: {
    __typename?: 'profile';
    id: any;
    organization_role_id?: any | null;
  } | null;
};

export type UpdateProfileMutationVariables = Exact<{
  userId: Scalars['uuid'];
  object: Profile_Set_Input;
}>;

export type UpdateProfileMutation = {
  __typename?: 'mutation_root';
  update_profile?: {
    __typename?: 'profile_mutation_response';
    returning: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
    }>;
  } | null;
};

export type GetUserStatusQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserStatusQuery = {
  __typename?: 'query_root';
  profile: Array<{ __typename?: 'profile'; activity_status?: string | null }>;
};

export type CreateUserStateMutationVariables = Exact<{
  object: User_State_Insert_Input;
}>;

export type CreateUserStateMutation = {
  __typename?: 'mutation_root';
  insert_user_state_one?: {
    __typename?: 'user_state';
    activity_state: Enum_Activity_State_Enum;
    manual_state: Enum_Manual_State_Enum;
    system_state: Enum_System_State_Enum;
  } | null;
};

export type UpdateSystemStateMutationVariables = Exact<{
  userId: Scalars['uuid'];
  systemState: Enum_System_State_Enum;
  systemStateOwner?: InputMaybe<Enum_System_State_Owner_Enum>;
}>;

export type UpdateSystemStateMutation = {
  __typename?: 'mutation_root';
  update_user_state_by_pk?: {
    __typename?: 'user_state';
    activity_state: Enum_Activity_State_Enum;
  } | null;
};

export type UpdateUserStateByUserIdMutationVariables = Exact<{
  userId: Scalars['uuid'];
  object: User_State_Set_Input;
}>;

export type UpdateUserStateByUserIdMutation = {
  __typename?: 'mutation_root';
  update_user_state_by_pk?: {
    __typename?: 'user_state';
    activity_state: Enum_Activity_State_Enum;
    manual_state: Enum_Manual_State_Enum;
    system_state: Enum_System_State_Enum;
  } | null;
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars['citext'];
}>;

export type GetUserByEmailQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    expecting_user?: any | null;
    account?: {
      __typename?: 'auth_accounts';
      active: boolean;
      default_role: string;
    } | null;
  }>;
};

export type GetUserWithRoleByIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
  acceptedRoles: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetUserWithRoleByIdQuery = {
  __typename?: 'query_root';
  users: Array<{ __typename?: 'users'; id: any }>;
};

export type UpdateUserDefaultRoleMutationVariables = Exact<{
  userId: Scalars['uuid'];
  defaultRole: Scalars['String'];
}>;

export type UpdateUserDefaultRoleMutation = {
  __typename?: 'mutation_root';
  update_auth_accounts?: {
    __typename?: 'auth_accounts_mutation_response';
    returning: Array<{ __typename?: 'auth_accounts'; id: any }>;
  } | null;
};

export type GetUserInfoByIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserInfoByIdQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: any;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
    deleted_at?: any | null;
    expecting_user?: any | null;
    organization_employees: Array<{
      __typename?: 'organization_employee';
      organization_id: any;
    }>;
    my_organization?: {
      __typename?: 'organization_user';
      manager_id?: any | null;
    } | null;
    account?: {
      __typename?: 'auth_accounts';
      id: any;
      default_role: string;
      active: boolean;
      email?: any | null;
    } | null;
    calendars: Array<{ __typename?: 'calendar'; id: any }>;
    profile: Array<{
      __typename?: 'profile';
      id: any;
      timezone?: string | null;
      date_of_birth?: string | null;
      additional_fields: any;
      organization_id?: any | null;
    }>;
  } | null;
};

export type UpdateUserByIdMutationVariables = Exact<{
  userId: Scalars['uuid'];
  object: Users_Set_Input;
}>;

export type UpdateUserByIdMutation = {
  __typename?: 'mutation_root';
  update_users_by_pk?: { __typename?: 'users'; id: any } | null;
};

export type CreateUserMutationVariables = Exact<{
  object: Users_Insert_Input;
}>;

export type CreateUserMutation = {
  __typename?: 'mutation_root';
  insert_users_one?: {
    __typename?: 'users';
    id: any;
    display_name?: string | null;
  } | null;
};

export type GetUserNameByIdsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetUserNameByIdsQuery = {
  __typename?: 'query_root';
  users: Array<{ __typename?: 'users'; display_name?: string | null; id: any }>;
};

export type GetProjectsNameForGraphShdQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetProjectsNameForGraphShdQuery = {
  __typename?: 'query_root';
  project: Array<{
    __typename?: 'project';
    id: any;
    title: string;
    project_client: {
      __typename?: 'project_client';
      id: any;
      client_name: string;
    };
  }>;
};

export type GetUsersInfoByOrgAdminShdQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _eq1?: InputMaybe<Scalars['uuid']>;
}>;

export type GetUsersInfoByOrgAdminShdQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type GetProjectsNameQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetProjectsNameQuery = {
  __typename?: 'query_root';
  project: Array<{
    __typename?: 'project';
    id: any;
    title: string;
    project_client: {
      __typename?: 'project_client';
      id: any;
      client_name: string;
    };
  }>;
};

export type GetTasksByProjectQueryVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
}>;

export type GetTasksByProjectQuery = {
  __typename?: 'query_root';
  project: Array<{
    __typename?: 'project';
    is_billable: boolean;
    title: string;
    tasks: Array<{
      __typename?: 'task';
      due_date_time?: any | null;
      title: string;
      assigned_at: any;
      estimated_time?: any | null;
      start_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      user?: {
        __typename?: 'users';
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      } | null;
    }>;
  }>;
};

export type GetUsersOfOrganizationQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  manager_id?: InputMaybe<Scalars['uuid']>;
}>;

export type GetUsersOfOrganizationQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    role_title?: string | null;
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  }>;
};

export type UpdateTaskMutationVariables = Exact<{
  taskId: Scalars['uuid'];
  taskInput: TaskSetInput;
  newTags?: InputMaybe<Array<TaskTagsInsertInput> | TaskTagsInsertInput>;
  removedTagIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  newCollaborators?: InputMaybe<
    Array<TaskCollaboratorInsertInput> | TaskCollaboratorInsertInput
  >;
  removedCollaboratorsUserIds?: InputMaybe<
    Array<Scalars['uuid']> | Scalars['uuid']
  >;
  filesInput?: InputMaybe<Array<TaskFileInsertInput> | TaskFileInsertInput>;
  projectMembers?: InputMaybe<
    Array<ProjectMembersInsertInput> | ProjectMembersInsertInput
  >;
  newTaskMilestones?: InputMaybe<
    Array<TaskMilestoneInsertInput> | TaskMilestoneInsertInput
  >;
  removedTaskMilestones?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type UpdateTaskMutation = {
  __typename?: 'mutation_root';
  updateTask?: { __typename?: 'UpdateTaskOutput'; success: boolean } | null;
};

export type GetAllTasksQueryVariables = Exact<{
  empId: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllTasksQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    updated_at: any;
  }>;
};

export type GetAllProjectsQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  userId: Scalars['uuid'];
  orderBy?: InputMaybe<Array<Project_Order_By> | Project_Order_By>;
}>;

export type GetAllProjectsQuery = {
  __typename?: 'query_root';
  project: Array<{
    __typename?: 'project';
    id: any;
    title: string;
    slug?: string | null;
    start_date?: any | null;
    end_date?: any | null;
    description?: string | null;
    status?: Enum_Project_Status_Enum | null;
    project_client: {
      __typename?: 'project_client';
      id: any;
      client_name: string;
    };
    tasks: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    owned_by?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    completed_tasks_count: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetTaskExistByIdQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetTaskExistByIdQuery = {
  __typename?: 'query_root';
  task_by_pk?: { __typename?: 'task'; id: any } | null;
};

export type GetAllMyUnreadMessagesCountSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetAllMyUnreadMessagesCountSubscription = {
  __typename?: 'subscription_root';
  chat_message_action_aggregate: {
    __typename?: 'chat_message_action_aggregate';
    aggregate?: {
      __typename?: 'chat_message_action_aggregate_fields';
      count: number;
    } | null;
  };
};

export type InsertTagsMutationVariables = Exact<{
  newTags?: InputMaybe<Array<Tags_Insert_Input> | Tags_Insert_Input>;
}>;

export type InsertTagsMutation = {
  __typename?: 'mutation_root';
  insert_tags?: {
    __typename?: 'tags_mutation_response';
    returning: Array<{ __typename?: 'tags'; id: any }>;
  } | null;
};

export type InsertFileMutationVariables = Exact<{
  object: Storage_Files_Insert_Input;
}>;

export type InsertFileMutation = {
  __typename?: 'mutation_root';
  insert_storage_files_one?: { __typename?: 'storage_files'; id: any } | null;
};

export type GetOrganizationInfoByUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetOrganizationInfoByUserQuery = {
  __typename?: 'query_root';
  organization_user: Array<{
    __typename?: 'organization_user';
    role_title?: string | null;
    system_role?: string | null;
    user?: { __typename?: 'users'; first_name?: string | null } | null;
    organization?: {
      __typename?: 'organization';
      id: any;
      organization_name: string;
      storageFileByLogoFileId?: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
      } | null;
    } | null;
  }>;
};

export type GetOrgRolesByOrgIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
  roleText?: InputMaybe<Scalars['String']>;
}>;

export type GetOrgRolesByOrgIdQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    id: any;
    role_title: string;
    system_role: string;
  }>;
};

export type GetOrgEmployeesFromUsersQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  search?: InputMaybe<Scalars['String']>;
}>;

export type GetOrgEmployeesFromUsersQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    display_name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    id: any;
    account?: {
      __typename?: 'auth_accounts';
      email?: any | null;
      default_role: string;
    } | null;
    calendars: Array<{ __typename?: 'calendar'; id: any }>;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
  }>;
};

export type CreateCallTokenMutationVariables = Exact<{
  agoraId: Scalars['String'];
  channelId: Scalars['String'];
}>;

export type CreateCallTokenMutation = {
  __typename?: 'mutation_root';
  createCallToken?: {
    __typename?: 'CallTokenOutput';
    appId: string;
    token: string;
  } | null;
};

export type LoginBackendMutationVariables = Exact<{
  object: LoginInput;
}>;

export type LoginBackendMutation = {
  __typename?: 'mutation_root';
  login?: {
    __typename?: 'LoginOutput';
    user_id?: any | null;
    jwt_token: string;
    jwt_expires_in: number;
    refresh_token: string;
  } | null;
};

export type GetProfileByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetProfileByUserIdQuery = {
  __typename?: 'query_root';
  profile: Array<{
    __typename?: 'profile';
    id: any;
    is_completed: boolean;
    activity_status?: string | null;
  }>;
};

export type UpdateActivityStateMutationVariables = Exact<{
  userId: Scalars['uuid'];
  activityState: Enum_Activity_State_Enum;
}>;

export type UpdateActivityStateMutation = {
  __typename?: 'mutation_root';
  update_user_state_by_pk?: {
    __typename?: 'user_state';
    activity_state: Enum_Activity_State_Enum;
  } | null;
};

export type UserActivityAlertQueryVariables = Exact<{
  userId: Scalars['uuid'];
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
}>;

export type UserActivityAlertQuery = {
  __typename?: 'query_root';
  user_activity_tracker: Array<{
    __typename?: 'user_activity_tracker';
    user_id: any;
    status: Enum_User_Activity_Tracker_Status_Type_Enum;
    last_status_updated_at: any;
    active_seconds: any;
    extra_seconds?: any | null;
  }>;
};

export type ExtendDayAlertMutationVariables = Exact<{
  object: ExtendDayAlertInput;
}>;

export type ExtendDayAlertMutation = {
  __typename?: 'mutation_root';
  extendDayAlert?: {
    __typename?: 'ExtendDayAlertOutput';
    success: boolean;
  } | null;
};

export type GetManualStateQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetManualStateQuery = {
  __typename?: 'query_root';
  user_state?: {
    __typename?: 'user_state';
    manual_state: Enum_Manual_State_Enum;
  } | null;
};

export type GetUserStartActivityTimeQueryVariables = Exact<{
  userId: Scalars['uuid'];
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
}>;

export type GetUserStartActivityTimeQuery = {
  __typename?: 'query_root';
  user_activity_tracker: Array<{
    __typename?: 'user_activity_tracker';
    created_at: any;
  }>;
};

export type GetUserByIdQueryVariables = Exact<{
  Id: Scalars['uuid'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: any;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      organization_role?: {
        __typename?: 'organization_roles';
        role_title: string;
      } | null;
      avatar_file?: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
      } | null;
    }>;
  } | null;
};

export type GetUserByIdSubSubscriptionVariables = Exact<{
  Id: Scalars['uuid'];
}>;

export type GetUserByIdSubSubscription = {
  __typename?: 'subscription_root';
  user?: {
    __typename?: 'users';
    id: any;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      avatar_file?: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
      } | null;
    }>;
  } | null;
};

export type LogoutMutationVariables = Exact<{
  refreshToken: Scalars['uuid'];
}>;

export type LogoutMutation = {
  __typename?: 'mutation_root';
  logout?: { __typename?: 'LogoutOutput'; success: boolean } | null;
};

export type GetUserDataQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetUserDataQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: any;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
    my_organization?: {
      __typename?: 'organization_user';
      system_role?: string | null;
      role_title?: string | null;
      organization?: {
        __typename?: 'organization';
        id: any;
        organization_name: string;
        logo_file_id?: any | null;
        additional_fields: any;
        owned_by: any;
        storageFileByLogoFileId?: {
          __typename?: 'storage_files';
          file_url: string;
        } | null;
      } | null;
    } | null;
    profile: Array<{
      __typename?: 'profile';
      agora_id?: number | null;
      activity_status?: string | null;
      timezone?: string | null;
      organization_role_id?: any | null;
      avatar_file?: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
      } | null;
    }>;
    department_employees: Array<{
      __typename?: 'department_employee';
      department: { __typename?: 'department'; id: any; name: string };
    }>;
    organization_employees: Array<{
      __typename?: 'organization_employee';
      manager_id?: any | null;
    }>;
    calendars: Array<{
      __typename?: 'calendar';
      calendar_name: string;
      id: any;
      created_by: any;
    }>;
    account?: {
      __typename?: 'auth_accounts';
      email?: any | null;
      default_role: string;
    } | null;
  } | null;
};

export type CreateSprintMutationVariables = Exact<{
  data: CreateSprintInput;
}>;

export type CreateSprintMutation = {
  __typename?: 'mutation_root';
  createSprint?: { __typename?: 'CreateSprintOutput'; id?: any | null } | null;
};

export type GetAllProjectsByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;

export type GetAllProjectsByOrganizationIdQuery = {
  __typename?: 'query_root';
  project: Array<{ __typename?: 'project'; id: any; title: string }>;
};

export type GetSprintsByOrganizationIdQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<Enum_Project_Task_Category_Enum>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  taskOrderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  sprintCategory?: InputMaybe<Enum_Project_Sprint_Category_Enum>;
  organizationId: Scalars['uuid'];
}>;

export type GetSprintsByOrganizationIdQuery = {
  __typename?: 'query_root';
  project_sprints: Array<{
    __typename?: 'project_sprints';
    id: any;
    name: string;
    start_date: any;
    end_date: any;
    category: Enum_Project_Sprint_Category_Enum;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      created_by: any;
      estimated_time?: any | null;
      project?: { __typename?: 'project'; title: string } | null;
      user?: { __typename?: 'users'; id: any } | null;
    }>;
    total_tasks: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    completed_tasks: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetSprintNamesQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  projectId: Scalars['uuid'];
}>;

export type GetSprintNamesQuery = {
  __typename?: 'query_root';
  sprint: Array<{ __typename?: 'project_sprints'; id: any; name: string }>;
};

export type GetTaskByProjectIdOrderBySprintsQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetTaskByProjectIdOrderBySprintsQuery = {
  __typename?: 'query_root';
  task: Array<{ __typename?: 'task'; id: any; title: string }>;
};

export type GetSprintInformationQueryVariables = Exact<{
  sprintId: Scalars['uuid'];
}>;

export type GetSprintInformationQuery = {
  __typename?: 'query_root';
  sprint?: {
    __typename?: 'project_sprints';
    id: any;
    name: string;
    project_id: any;
    duration_in_weeks: any;
    start_date: any;
    end_date: any;
    category: Enum_Project_Sprint_Category_Enum;
  } | null;
};

export type ActivateSprintMutationVariables = Exact<{
  sprintId: Scalars['uuid'];
  startDate?: InputMaybe<Scalars['date']>;
  endDate?: InputMaybe<Scalars['date']>;
}>;

export type ActivateSprintMutation = {
  __typename?: 'mutation_root';
  update_project_sprints_by_pk?: {
    __typename?: 'project_sprints';
    id: any;
  } | null;
};

export type UpdateSprintMutationVariables = Exact<{
  sprintId: Scalars['uuid'];
  data: Project_Sprints_Set_Input;
}>;

export type UpdateSprintMutation = {
  __typename?: 'mutation_root';
  update_project_sprints_by_pk?: {
    __typename?: 'project_sprints';
    id: any;
  } | null;
};

export type GetFiltersBySectionNameQueryVariables = Exact<{
  sectionName: Scalars['String'];
}>;

export type GetFiltersBySectionNameQuery = {
  __typename?: 'query_root';
  filter_definition: Array<{
    __typename?: 'filter_definition';
    column_name: string;
    column_type: string;
    id: any;
    project_id?: any | null;
    section_name: string;
    values?: string | null;
    display_name: string;
  }>;
};

export type CreateProjectMutationVariables = Exact<{
  client: ClientInput;
  project: ProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'mutation_root';
  createProject?: { __typename?: 'CreateProjectOutput'; projectId: any } | null;
};

export type GetTasksByMilestoneIdQueryVariables = Exact<{
  milestoneId: Scalars['uuid'];
}>;

export type GetTasksByMilestoneIdQuery = {
  __typename?: 'query_root';
  project_milestones: Array<{
    __typename?: 'project_milestones';
    task_milestones: Array<{
      __typename?: 'task_milestones';
      task: {
        __typename?: 'task';
        assigned_by?: any | null;
        assigned_to?: any | null;
        category?: Enum_Project_Task_Category_Enum | null;
        created_by: any;
        due_date_time?: any | null;
        description?: string | null;
        end_date_time?: any | null;
        id: any;
        organization_id: any;
        priority?: Enum_Task_Priority_Enum | null;
        project_id?: any | null;
        sprint_id?: any | null;
        start_date_time?: any | null;
        status?: Enum_Task_Status_Enum | null;
        subtask_of?: any | null;
        title: string;
      };
    }>;
  }>;
};

export type CreateMilestoneMutationVariables = Exact<{
  milestone: CreateMilestoneInput;
}>;

export type CreateMilestoneMutation = {
  __typename?: 'mutation_root';
  createMilestone?: {
    __typename?: 'CreateMilestoneOutput';
    id: any;
    name?: string | null;
    date?: any | null;
    status?: string | null;
  } | null;
};

export type UpdateMilestoneMutationVariables = Exact<{
  milestone: UpdateMilestoneInput;
}>;

export type UpdateMilestoneMutation = {
  __typename?: 'mutation_root';
  updateMilestone?: {
    __typename?: 'UpdateMilestoneOutput';
    id: any;
    name?: string | null;
    date?: any | null;
    status?: string | null;
  } | null;
};

export type GetProjectMilestonesByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetProjectMilestonesByProjectIdQuery = {
  __typename?: 'query_root';
  project_milestones: Array<{
    __typename?: 'project_milestones';
    date: any;
    name: string;
    id: any;
    status: Enum_Project_Milestone_Status_Enum;
  }>;
};

export type GetProjectMilestoneByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetProjectMilestoneByIdQuery = {
  __typename?: 'query_root';
  project_milestones_by_pk?: {
    __typename?: 'project_milestones';
    date: any;
    name: string;
    project_id: any;
    status: Enum_Project_Milestone_Status_Enum;
    project: { __typename?: 'project'; created_by?: any | null };
  } | null;
};

export type CreateTaskMutationVariables = Exact<{
  attachedFiles?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  collaborators?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  milestones?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  task: TaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: 'mutation_root';
  createTask?: { __typename?: 'CreateTaskOutput'; taskId: any } | null;
};

export type UpdateTaskDependenciesMutationVariables = Exact<{
  taskId: Scalars['uuid'];
  dependentOnTaskIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  dueDateTime?: InputMaybe<Scalars['String']>;
  removedDependentOnTaskIds?: InputMaybe<
    Array<Scalars['uuid']> | Scalars['uuid']
  >;
  startDateTime?: InputMaybe<Scalars['String']>;
}>;

export type UpdateTaskDependenciesMutation = {
  __typename?: 'mutation_root';
  updateDependentTasks?: {
    __typename?: 'UpdateDependentTaskOutput';
    dependentTaskIds?: Array<any> | null;
    success: boolean;
  } | null;
};

export type GetTaskDependenciesQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetTaskDependenciesQuery = {
  __typename?: 'query_root';
  task_dependencies: Array<{
    __typename?: 'task_dependencies';
    task: { __typename?: 'task'; id: any; title: string };
  }>;
};

export type GetEmployeeCapacityForUserIdQueryVariables = Exact<{
  employeeId: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetEmployeeCapacityForUserIdQuery = {
  __typename?: 'query_root';
  employee_capacity: Array<{
    __typename?: 'employee_capacity';
    date: any;
    display_name: string;
    employee_id: any;
    workload_hours: any;
  }>;
};

export type GetOrganizationWorkHoursQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetOrganizationWorkHoursQuery = {
  __typename?: 'query_root';
  organization_by_pk?: {
    __typename?: 'organization';
    id: any;
    timezone_name: string;
    working_hours: number;
  } | null;
};

export type UpdateTaskRankMutationVariables = Exact<{
  object: UpdateTaskRankInput;
}>;

export type UpdateTaskRankMutation = {
  __typename?: 'mutation_root';
  updateTaskRank: any;
};

export type UpdateTaskRankOutputQueryVariables = Exact<{
  actionId: Scalars['uuid'];
}>;

export type UpdateTaskRankOutputQuery = {
  __typename?: 'query_root';
  updateTaskRank?: {
    __typename?: 'updateTaskRank';
    errors?: any | null;
    output?: { __typename?: 'UpdateTaskRankOutput'; success: boolean } | null;
  } | null;
};

export type UpdateMultipleTasksMutationVariables = Exact<{
  task_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  operation_name: Scalars['String'];
  updateTasks?: InputMaybe<UpdateMultipleTasksInput>;
  tag_ids?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  collaborator_ids?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type UpdateMultipleTasksMutation = {
  __typename?: 'mutation_root';
  updateMultipleTasks?: {
    __typename?: 'UpdateMultipleTasksOutput';
    updated_ids: Array<any | null>;
    message: string;
  } | null;
};

export type CreateChecklistMutationVariables = Exact<{
  checklist: ChecklistInput;
}>;

export type CreateChecklistMutation = {
  __typename?: 'mutation_root';
  createChecklist?: {
    __typename?: 'ChecklistOutput';
    checklistId: any;
    updatedAt?: string | null;
  } | null;
};

export type UpdateChecklistMutationVariables = Exact<{
  checklist: UpdateChecklistInput;
}>;

export type UpdateChecklistMutation = {
  __typename?: 'mutation_root';
  updateChecklist?: {
    __typename?: 'UpdateChecklistOutput';
    id: any;
    updatedAt?: any | null;
  } | null;
};

export type UpdateTaskApproversMutationVariables = Exact<{
  checklist: UpdateTaskApproversInput;
}>;

export type UpdateTaskApproversMutation = {
  __typename?: 'mutation_root';
  updateTaskApprovers?: {
    __typename?: 'UpdateTaskApproversOutput';
    approverIds: Array<any | null>;
  } | null;
};

export type GetChecklistByTaskIdQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetChecklistByTaskIdQuery = {
  __typename?: 'query_root';
  task_checklist: Array<{
    __typename?: 'task_checklist';
    approved_by?: any | null;
    created_at: any;
    id: any;
    is_approved: boolean;
    item_name: string;
    updated_at: any;
  }>;
};

export type DeleteTaskFileMutationVariables = Exact<{
  task_id: Scalars['uuid'];
  storage_file_id: Scalars['uuid'];
}>;

export type DeleteTaskFileMutation = {
  __typename?: 'mutation_root';
  delete_task_file_by_pk?: { __typename?: 'task_file'; task_id: any } | null;
};

export type InsertTaskFilesMutationVariables = Exact<{
  objects: Array<Task_File_Insert_Input> | Task_File_Insert_Input;
}>;

export type InsertTaskFilesMutation = {
  __typename?: 'mutation_root';
  insert_task_file?: {
    __typename?: 'task_file_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateTaskCommentMutationVariables = Exact<{
  object: Task_Comment_Insert_Input;
}>;

export type CreateTaskCommentMutation = {
  __typename?: 'mutation_root';
  insert_task_comment_one?: { __typename?: 'task_comment'; id: any } | null;
};

export type DeleteTaskCommentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteTaskCommentMutation = {
  __typename?: 'mutation_root';
  delete_task_comment?: {
    __typename?: 'task_comment_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'task_comment'; id: any }>;
  } | null;
};

export type CreateTaskLikeMutationVariables = Exact<{
  task_comment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type CreateTaskLikeMutation = {
  __typename?: 'mutation_root';
  insert_task_comment_like?: {
    __typename?: 'task_comment_like_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'task_comment_like';
      task_comment_id: any;
      user_id: any;
      created_at: any;
      update_at: any;
    }>;
  } | null;
};

export type UpdateTaskCommentMutationVariables = Exact<{
  id: Scalars['uuid'];
  comment_text: Scalars['String'];
}>;

export type UpdateTaskCommentMutation = {
  __typename?: 'mutation_root';
  update_task_comment?: {
    __typename?: 'task_comment_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteTaskLikeMutationVariables = Exact<{
  task_comment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;

export type DeleteTaskLikeMutation = {
  __typename?: 'mutation_root';
  delete_task_comment_like?: {
    __typename?: 'task_comment_like_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'task_comment_like';
      task_comment_id: any;
      user_id: any;
    }>;
  } | null;
};

export type DeleteATaskMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteATaskMutation = {
  __typename?: 'mutation_root';
  delete_task_by_pk?: { __typename?: 'task'; id: any } | null;
};

export type DeleteMultipleTasksMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type DeleteMultipleTasksMutation = {
  __typename?: 'mutation_root';
  delete_task?: {
    __typename?: 'task_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteATaskSoftMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteATaskSoftMutation = {
  __typename?: 'mutation_root';
  update_task_by_pk?: { __typename?: 'task'; id: any } | null;
};

export type DeleteMultipleTasksSoftMutationVariables = Exact<{
  id: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type DeleteMultipleTasksSoftMutation = {
  __typename?: 'mutation_root';
  update_task?: {
    __typename?: 'task_mutation_response';
    returning: Array<{ __typename?: 'task'; id: any }>;
  } | null;
};

export type SendTaskReminderMutationVariables = Exact<{
  object: SendTaskReminderInput;
}>;

export type SendTaskReminderMutation = {
  __typename?: 'mutation_root';
  sendTaskReminder?: {
    __typename?: 'SendTaskReminderOutput';
    success?: string | null;
  } | null;
};

export type GetProjectClientsQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type GetProjectClientsQuery = {
  __typename?: 'query_root';
  project_client: Array<{
    __typename?: 'project_client';
    id: any;
    client_name: string;
  }>;
};

export type GetProjectsQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['uuid'];
  orConditions?: InputMaybe<Array<Project_Bool_Exp> | Project_Bool_Exp>;
}>;

export type GetProjectsQuery = {
  __typename?: 'query_root';
  project: Array<{
    __typename?: 'project';
    id: any;
    title: string;
    project_client: {
      __typename?: 'project_client';
      id: any;
      client_name: string;
    };
  }>;
};

export type DeleteSprintMutationVariables = Exact<{
  sprintId: Scalars['uuid'];
  deleteAssociatedTasks: Scalars['Boolean'];
}>;

export type DeleteSprintMutation = {
  __typename?: 'mutation_root';
  deleteSprint?: {
    __typename?: 'DeleteSprintOutput';
    success?: boolean | null;
  } | null;
};

export type DeleteSprintByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteSprintByIdMutation = {
  __typename?: 'mutation_root';
  delete_project_sprints_by_pk?: {
    __typename?: 'project_sprints';
    id: any;
    name: string;
    project_id: any;
    end_date: any;
  } | null;
};

export type GetTasksForExcelExportQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<Enum_Project_Task_Category_Enum>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  organizationId: Scalars['uuid'];
  order_by?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  userId?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
}>;

export type GetTasksForExcelExportQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    created_by: any;
    estimated_time?: any | null;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    rank?: any | null;
    project?: { __typename?: 'project'; title: string } | null;
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    } | null;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetTaskFilesQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetTaskFilesQuery = {
  __typename?: 'query_root';
  task?: {
    __typename?: 'task';
    id: any;
    title: string;
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        metadata: any;
      };
    }>;
  } | null;
};

export type GetTaskRecordingFilesQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetTaskRecordingFilesQuery = {
  __typename?: 'query_root';
  task?: {
    __typename?: 'task';
    id: any;
    title: string;
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        metadata: any;
        type: Enum_Storage_File_Type_Enum;
      };
    }>;
  } | null;
};

export type GetTaskCommentsByTaskIdSubscriptionVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetTaskCommentsByTaskIdSubscription = {
  __typename?: 'subscription_root';
  task_comment: Array<{
    __typename?: 'task_comment';
    id: any;
    updated_at: any;
    comment_text: string;
    activity_type?: Enum_Activity_Type_Enum | null;
    created_at: any;
    created_by: { __typename?: 'users'; id: any; display_name?: string | null };
    task_comment_likes: Array<{
      __typename?: 'task_comment_like';
      user_id: any;
    }>;
    task_comment_likes_aggregate: {
      __typename?: 'task_comment_like_aggregate';
      aggregate?: {
        __typename?: 'task_comment_like_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetProjectByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetProjectByIdQuery = {
  __typename?: 'query_root';
  project?: {
    __typename?: 'project';
    id: any;
    title: string;
    client: { __typename?: 'project_client'; client_name: string };
  } | null;
};

export type GetTaskDetailsQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetTaskDetailsQuery = {
  __typename?: 'query_root';
  task?: {
    __typename?: 'task';
    id: any;
    title: string;
    created_at: any;
    priority?: Enum_Task_Priority_Enum | null;
    category?: Enum_Project_Task_Category_Enum | null;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    description?: string | null;
    estimated_time?: any | null;
    is_recurring: boolean;
    recurred_from?: any | null;
    next_recurred_date?: any | null;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    project?: {
      __typename?: 'project';
      id: any;
      title: string;
      project_members: Array<{
        __typename?: 'project_members';
        user: {
          __typename?: 'users';
          id: any;
          display_name?: string | null;
          profile: Array<{
            __typename?: 'profile';
            activity_status?: string | null;
            avatar_file?: {
              __typename?: 'storage_files';
              file_url: string;
            } | null;
          }>;
        };
      }>;
    } | null;
    project_sprint?: {
      __typename?: 'project_sprints';
      id: any;
      name: string;
    } | null;
    task_tags: Array<{
      __typename?: 'task_tags';
      tag: { __typename?: 'tags'; id: any; name: string };
    }>;
    assigned_to?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        metadata: any;
        type: Enum_Storage_File_Type_Enum;
      };
    }>;
    created_by: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
    task_collaborators: Array<{
      __typename?: 'task_collaborator';
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
    parent_task?: { __typename?: 'task'; id: any; title: string } | null;
    task_milestones: Array<{
      __typename?: 'task_milestones';
      milestone_id: any;
      project_milestone: { __typename?: 'project_milestones'; name: string };
    }>;
    project_section?: { __typename?: 'project_section'; id: any } | null;
  } | null;
};

export type GetTagsQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  tagName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetTagsQuery = {
  __typename?: 'query_root';
  tags: Array<{ __typename?: 'tags'; id: any; name: string }>;
};

export type GetSubTasksQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetSubTasksQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    created_by: any;
    is_draft: boolean;
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    } | null;
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        metadata: any;
        type: Enum_Storage_File_Type_Enum;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
  }>;
};

export type GetSingleSubTaskQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type GetSingleSubTaskQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    due_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    created_by: any;
    is_draft: boolean;
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
    } | null;
    task_files: Array<{
      __typename?: 'task_file';
      storage_file: {
        __typename?: 'storage_files';
        id: any;
        file_url: string;
        metadata: any;
        type: Enum_Storage_File_Type_Enum;
        enum_storage_file_type: {
          __typename?: 'enum_storage_file_type';
          value: string;
        };
      };
    }>;
  }>;
};

export type GetFilterRequiredDataQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  userIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  tagIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetFilterRequiredDataQuery = {
  __typename?: 'query_root';
  project?: { __typename?: 'project'; id: any; title: string } | null;
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
    }>;
  }>;
  tags: Array<{ __typename?: 'tags'; id: any; name: string }>;
};

export type GetProjectDetailsByProjectIdQueryVariables = Exact<{
  projectID: Scalars['uuid'];
}>;

export type GetProjectDetailsByProjectIdQuery = {
  __typename?: 'query_root';
  project?: {
    __typename?: 'project';
    id: any;
    title: string;
    slug?: string | null;
    start_date?: any | null;
    end_date?: any | null;
    description?: string | null;
    is_billable: boolean;
    status?: Enum_Project_Status_Enum | null;
    project_client: {
      __typename?: 'project_client';
      id: any;
      client_name: string;
    };
    project_members: Array<{
      __typename?: 'project_members';
      user: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        profile: Array<{
          __typename?: 'profile';
          activity_status?: string | null;
          avatar_file?: {
            __typename?: 'storage_files';
            file_url: string;
          } | null;
        }>;
      };
    }>;
    owned_by?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
  } | null;
};

export type GetProjectDetailsForDetailsQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetProjectDetailsForDetailsQuery = {
  __typename?: 'query_root';
  project_by_pk?: {
    __typename?: 'project';
    id: any;
    description?: string | null;
  } | null;
};

export type UpdateProjectMembersMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  newMembers?: InputMaybe<
    Array<Project_Members_Insert_Input> | Project_Members_Insert_Input
  >;
  deletedMembers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type UpdateProjectMembersMutation = {
  __typename?: 'mutation_root';
  insert_project_members?: {
    __typename?: 'project_members_mutation_response';
    affected_rows: number;
  } | null;
  delete_project_members?: {
    __typename?: 'project_members_mutation_response';
    affected_rows: number;
  } | null;
};

export type GroupByFilterTasksMutationVariables = Exact<{
  task: GroupByFilterInput;
}>;

export type GroupByFilterTasksMutation = {
  __typename?: 'mutation_root';
  groupByFilterTasks?: {
    __typename?: 'GroupByFilterOutput';
    aggregateCount?: number | null;
    groupByDetails?: Array<{
      __typename?: 'GroupByOutput';
      key?: any | null;
      name?: string | null;
      startIndex?: number | null;
      count?: number | null;
      data?: {
        __typename?: 'ExtraFields';
        startDate?: string | null;
        endDate?: string | null;
        category?: string | null;
        totalTasks?: number | null;
        completedTasks?: number | null;
      } | null;
    }> | null;
    tasks?: Array<{
      __typename?: 'TaskDetailsOutput';
      id?: any | null;
      title?: string | null;
      due_date_time?: any | null;
      status?: string | null;
      priority?: string | null;
      created_at?: any | null;
      estimated_time?: number | null;
      start_date_time?: any | null;
      is_recurring?: boolean | null;
      recurring_frequency?: string | null;
      is_draft?: boolean | null;
      created_by?: {
        __typename?: 'User';
        id?: any | null;
        display_name?: string | null;
      } | null;
      project?: {
        __typename?: 'ProjectOutput';
        id?: any | null;
        title?: string | null;
      } | null;
      user?: { __typename?: 'User'; id?: any | null } | null;
      sub_tasks_aggregate?: {
        __typename?: 'SubTasksAggregate';
        aggregate?: {
          __typename?: 'SubTaskAggregateCount';
          count?: number | null;
        } | null;
      } | null;
      task_files?: Array<{
        __typename?: 'TaskFile';
        storage_file?: {
          __typename?: 'StorageFile';
          id?: any | null;
          file_url?: string | null;
          type?: string | null;
          metadata?: any | null;
          enum_storage_file_type?: {
            __typename?: 'StorageFileType';
            value?: string | null;
          } | null;
        } | null;
      } | null> | null;
      project_sprint?: {
        __typename?: 'Sprint';
        id?: any | null;
        name?: string | null;
      } | null;
      project_section?: {
        __typename?: 'Section';
        id?: any | null;
        name?: string | null;
      } | null;
      task_tags?: Array<{
        __typename?: 'TaskTag';
        tag_id?: any | null;
        tag?: { __typename?: 'Tag'; name?: string | null } | null;
      } | null> | null;
      taskDependenciesByTaskId?: Array<{
        __typename?: 'TaskDependencies';
        dependent_on_task_id?: any | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type GetTaskFilesByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetTaskFilesByProjectIdQuery = {
  __typename?: 'query_root';
  files: Array<{
    __typename?: 'storage_files';
    id: any;
    file_url: string;
    metadata: any;
    created_at: any;
    updated_at: any;
    user?: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    } | null;
    task_files: Array<{
      __typename?: 'task_file';
      task: { __typename?: 'task'; id: any; title: string };
    }>;
  }>;
};

export type DeleteProjectFromAppMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type DeleteProjectFromAppMutation = {
  __typename?: 'mutation_root';
  deleteProject?: { __typename?: 'deleteProjectOutput'; status: string } | null;
};

export type CreateOrUpdateProjectLinkMutationVariables = Exact<{
  data: Project_Links_Insert_Input;
}>;

export type CreateOrUpdateProjectLinkMutation = {
  __typename?: 'mutation_root';
  insert_project_links_one?: { __typename?: 'project_links'; id: any } | null;
};

export type DeleteProjectLinkMutationVariables = Exact<{
  linkId: Scalars['uuid'];
}>;

export type DeleteProjectLinkMutation = {
  __typename?: 'mutation_root';
  delete_project_links_by_pk?: { __typename?: 'project_links'; id: any } | null;
};

export type GetProjectLinksQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetProjectLinksQuery = {
  __typename?: 'query_root';
  project_links: Array<{ __typename?: 'project_links'; id: any; url: string }>;
};

export type CreateEmpBandWidthMutationVariables = Exact<{
  object: EmployeeBandwidthInput;
}>;

export type CreateEmpBandWidthMutation = {
  __typename?: 'mutation_root';
  employeeBandwidthData?: {
    __typename?: 'employeeBandwidthOutput';
    id: any;
  } | null;
};

export type GetProjectMembersByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetProjectMembersByProjectIdQuery = {
  __typename?: 'query_root';
  project_members: Array<{
    __typename?: 'project_members';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        activity_status?: string | null;
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
    };
  }>;
};

export type AddMemberToProjectMutationVariables = Exact<{
  object: AddMemberDetailsInput;
}>;

export type AddMemberToProjectMutation = {
  __typename?: 'mutation_root';
  addMemberToProject?: {
    __typename?: 'AddMemberDetailsOutput';
    id: any;
  } | null;
};

export type UpdateProjectMemberReleaseDateMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  userId: Scalars['uuid'];
  releaseDate?: InputMaybe<Scalars['date']>;
}>;

export type UpdateProjectMemberReleaseDateMutation = {
  __typename?: 'mutation_root';
  update_project_members?: {
    __typename?: 'project_members_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateProjectOwnerMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  ownedBy: Scalars['uuid'];
}>;

export type UpdateProjectOwnerMutation = {
  __typename?: 'mutation_root';
  update_project?: {
    __typename?: 'project_mutation_response';
    returning: Array<{
      __typename?: 'project';
      owned_by?: any | null;
      id: any;
    }>;
  } | null;
};

export type ProjectMembersSubscriptionVariables = Exact<{
  _eq: Scalars['uuid'];
}>;

export type ProjectMembersSubscription = {
  __typename?: 'subscription_root';
  project_members: Array<{
    __typename?: 'project_members';
    project_id: any;
    release_date: any;
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        avatar_file?: { __typename?: 'storage_files'; file_url: string } | null;
      }>;
      account?: { __typename?: 'auth_accounts'; default_role: string } | null;
    };
  }>;
};

export type DeleteProjectMemberMutationVariables = Exact<{
  _eq: Scalars['uuid'];
  _eq1: Scalars['uuid'];
}>;

export type DeleteProjectMemberMutation = {
  __typename?: 'mutation_root';
  delete_project_members?: {
    __typename?: 'project_members_mutation_response';
    affected_rows: number;
  } | null;
};

export type InviteGuestToProjectMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  emailIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type InviteGuestToProjectMutation = {
  __typename?: 'mutation_root';
  inviteGuestToProject?: {
    __typename?: 'InviteGuestToProjectOutput';
    users?: Array<any> | null;
  } | null;
};

export type CreateProjectSectionMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type CreateProjectSectionMutation = {
  __typename?: 'mutation_root';
  insert_project_section?: {
    __typename?: 'project_section_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'project_section';
      name: string;
      created_at: any;
      updated_at: any;
      id: any;
      project_id: any;
    }>;
  } | null;
};

export type GetProjectSectionsQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetProjectSectionsQuery = {
  __typename?: 'query_root';
  project_section: Array<{
    __typename?: 'project_section';
    name: string;
    id: any;
    project_id: any;
  }>;
};

export type GetTasksOfProjectQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  status?: InputMaybe<Array<Enum_Task_Status_Enum> | Enum_Task_Status_Enum>;
  assignedToUsers?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  category?: InputMaybe<Enum_Project_Task_Category_Enum>;
  orderBy?: InputMaybe<Array<Task_Order_By> | Task_Order_By>;
  sprintCategory?: InputMaybe<Enum_Project_Sprint_Category_Enum>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  taskName?: InputMaybe<Scalars['String']>;
}>;

export type GetTasksOfProjectQuery = {
  __typename?: 'query_root';
  task: Array<{
    __typename?: 'task';
    id: any;
    title: string;
    assigned_to?: any | null;
    due_date_time?: any | null;
    start_date_time?: any | null;
    status?: Enum_Task_Status_Enum | null;
    created_by: any;
    estimated_time?: any | null;
    created_at: any;
    is_recurring: boolean;
    recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
    sub_tasks_aggregate: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
  project_sprints: Array<{
    __typename?: 'project_sprints';
    id: any;
    name: string;
    start_date: any;
    end_date: any;
    category: Enum_Project_Sprint_Category_Enum;
    tasks: Array<{
      __typename?: 'task';
      id: any;
      title: string;
      assigned_to?: any | null;
      due_date_time?: any | null;
      status?: Enum_Task_Status_Enum | null;
      created_by: any;
      estimated_time?: any | null;
      is_recurring: boolean;
      recurring_frequency?: Enum_Task_Recurring_Frequency_Enum | null;
      sub_tasks_aggregate: {
        __typename?: 'task_aggregate';
        aggregate?: {
          __typename?: 'task_aggregate_fields';
          count: number;
        } | null;
      };
    }>;
    current_tasks: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    total_tasks: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
    completed_tasks: {
      __typename?: 'task_aggregate';
      aggregate?: {
        __typename?: 'task_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export const LeaveCallByCallIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'leaveCallByCallId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'call_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'call_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaveCallByCallIdMutation,
  LeaveCallByCallIdMutationVariables
>;
export const CallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'callRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_request_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PENDING' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'call_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_by_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'request_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CallRequestSubscription,
  CallRequestSubscriptionVariables
>;
export const RespondToCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'respondToCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'call_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'response_type' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CallRequestResponseType' },
          },
          defaultValue: { kind: 'EnumValue', value: 'ACCEPT' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'respondToCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'call_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'response_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'response_type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'call_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channel_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chat_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RespondToCallMutation,
  RespondToCallMutationVariables
>;
export const LatestChatMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'latestChatMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message_action' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_message' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'message_type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'NORMAL_MESSAGE',
                                      },
                                      {
                                        kind: 'EnumValue',
                                        value: 'DOCUMENT_MESSAGE',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_read' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_shown' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_message' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_room_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_room' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'room_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'chat_room_type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_text' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestChatMessageSubscription,
  LatestChatMessageSubscriptionVariables
>;
export const GetChatRoomUserIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatRoomUserIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatRoomUserIdsQuery,
  GetChatRoomUserIdsQueryVariables
>;
export const UpdateChatMessageStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateChatMessageStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_message_action_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_message_action_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'chat_message_action_pkey',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'is_read' },
                          { kind: 'EnumValue', value: 'is_shown' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChatMessageStatusesMutation,
  UpdateChatMessageStatusesMutationVariables
>;
export const SendReplyMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendReplyMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageText' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'replied_to' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'chatRoomId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message_text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'messageText' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'replied_to' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'replied_to' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message_type' },
                      value: { kind: 'EnumValue', value: 'NORMAL_MESSAGE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendReplyMessageMutation,
  SendReplyMessageMutationVariables
>;
export const LiveCallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'liveCallRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'ACTIVE' },
                                { kind: 'EnumValue', value: 'ACTIVE_PROVIDER' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'live_call_users' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_neq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'userId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'activity_status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LiveCallRequestSubscription,
  LiveCallRequestSubscriptionVariables
>;
export const GetOnlineModeLiveCallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getOnlineModeLiveCallRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'request_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PENDING' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOnlineModeLiveCallRequestSubscription,
  GetOnlineModeLiveCallRequestSubscriptionVariables
>;
export const LeaveMeetCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'leaveMeetCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_call_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveMeetCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meeting_call_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'meeting_call_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaveMeetCallMutation,
  LeaveMeetCallMutationVariables
>;
export const GetCalenderMeetingRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getCalenderMeetingRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_call_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_request_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PENDING' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_meeting_call_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_meeting_call' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_event' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'event_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalenderMeetingRequestSubscription,
  GetCalenderMeetingRequestSubscriptionVariables
>;
export const RespondMeetingCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'respondMeetingCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_call_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'response_type' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CallRequestResponseType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'respondMeetingCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_call_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'meeting_call_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'response_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'response_type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'event_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RespondMeetingCallMutation,
  RespondMeetingCallMutationVariables
>;
export const LeaveMeetingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'leaveMeetingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetingRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveMeetingRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meetingRoomId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'meetingRoomId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaveMeetingRoomMutation,
  LeaveMeetingRoomMutationVariables
>;
export const GetMyMeetingRoomInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyMeetingRoomInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meeting_room_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'INVITED' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_room' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'userByCreatedBy' },
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyMeetingRoomInviteSubscription,
  GetMyMeetingRoomInviteSubscriptionVariables
>;
export const RespondMeetingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'respondMeetingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RespondMeetingRoomInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'respondMeetingRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RespondMeetingRoomMutation,
  RespondMeetingRoomMutationVariables
>;
export const GetMyLatestNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyLatestNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_shown' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resource_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Resource_Calendar_Event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyLatestNotificationsSubscription,
  GetMyLatestNotificationsSubscriptionVariables
>;
export const SetNotificationShownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setNotificationShown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_notifications_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_shown' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetNotificationShownMutation,
  SetNotificationShownMutationVariables
>;
export const SetNotificationReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setNotificationRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'notificationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetNotificationReadMutation,
  SetNotificationReadMutationVariables
>;
export const GetMyActiveTimeFromYesterdayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyActiveTimeFromYesterday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'yesterday' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'yesterday' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_seconds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyActiveTimeFromYesterdayQuery,
  GetMyActiveTimeFromYesterdayQueryVariables
>;
export const StartCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'users' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'media_type' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'StartCallMediaType' },
          },
          defaultValue: { kind: 'EnumValue', value: 'AUDIO_ONLY' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'metadata' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chat_room_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'media_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'media_type' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'users' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'users' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organizationId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'metadata' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'chat_room_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'callId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StartCallMutation, StartCallMutationVariables>;
export const CallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'call' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'call_status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'call_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channel_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chat_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_recording_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_broadcasting_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_by_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'media_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_requests' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'call_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'call_request_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_for_user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'display_name' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'asc_nulls_last',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'agora_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CallSubscription, CallSubscriptionVariables>;
export const StartRecordingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startRecording' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'startRecordingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startRecording' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'call_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartRecordingMutation,
  StartRecordingMutationVariables
>;
export const StopRecordingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'stopRecording' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'stopRecordingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopRecording' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'call_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stopped' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StopRecordingMutation,
  StopRecordingMutationVariables
>;
export const GetOrganizationUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'display_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationUsersQuery,
  GetOrganizationUsersQueryVariables
>;
export const AddUsersToCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addUsersToCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUsersToCallInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUsersToCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUsersToCallMutation,
  AddUsersToCallMutationVariables
>;
export const GetDepartmentsByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDepartmentsByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepartmentsByOrgIdQuery,
  GetDepartmentsByOrgIdQueryVariables
>;
export const GetOurChatRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getOurChatRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'users' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resource_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startChat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'users' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'users' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resource_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resource_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'chatRoomId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOurChatRoomMutation,
  GetOurChatRoomMutationVariables
>;
export const SendMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageText' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'replied_to' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message_type' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'enum_chat_message_type_enum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'chatRoomId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message_text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'messageText' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'replied_to' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'replied_to' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message_type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendMessageMutation, SendMessageMutationVariables>;
export const OurChatMessagesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ourChatMessagesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_first' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message_text' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replied_to' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'replied_to_message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_text' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_message_actions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'is_read' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_message_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_message_actions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_read' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_message_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OurChatMessagesQueryQuery,
  OurChatMessagesQueryQueryVariables
>;
export const OurChatMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ourChatMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_first' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message_text' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replied_to' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_message_actions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_read' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'replied_to_message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_text' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_message_actions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'is_read' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_message_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_message_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OurChatMessageSubscription,
  OurChatMessageSubscriptionVariables
>;
export const OurChatFilesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ourChatFilesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'my_organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_name',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_message_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OurChatFilesQueryQuery,
  OurChatFilesQueryQueryVariables
>;
export const GetDocsByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDocsByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'files' },
            name: { kind: 'Name', value: 'storage_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_files' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'project_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'projectId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'file_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'my_organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_name',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDocsByProjectIdQuery,
  GetDocsByProjectIdQueryVariables
>;
export const GetChatToReplyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatToReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'message_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message_text' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_message_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetChatToReplyQuery, GetChatToReplyQueryVariables>;
export const MyChatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'myChats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'myUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizations_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_users' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'myUserId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: { kind: 'EnumValue', value: 'CLOSED' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'organizations_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_message_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'about_me' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'myUserId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'room_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_messages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'myUserId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'updated_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_message_actions' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'myUserId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'is_read' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyChatsSubscription, MyChatsSubscriptionVariables>;
export const GetChatRoomUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatRoomUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managed_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'display_name' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'about_me' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status_since',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_role',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'my_organization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'manager' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'first_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'room_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatRoomUsersQuery,
  GetChatRoomUsersQueryVariables
>;
export const AddUsersToGroupChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addUsersToGroupChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'users' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUsersToChat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chatRoomId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'chatRoomId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'users' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'users' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUsersToGroupChatMutation,
  AddUsersToGroupChatMutationVariables
>;
export const UpdateChatGroupNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateChatGroupName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'room_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupName' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChatGroupNameMutation,
  UpdateChatGroupNameMutationVariables
>;
export const LeaveGroupChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'leaveGroupChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveChat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chatRoomId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'chatRoomId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaveGroupChatMutation,
  LeaveGroupChatMutationVariables
>;
export const RemoveUserFromChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUserFromChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveChatUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromChat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveUserFromChatMutation,
  RemoveUserFromChatMutationVariables
>;
export const UpdateMessageSeenStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMessageSeenStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'chat_message_action_insert_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_message_action_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'chat_message_action_pkey',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'is_read' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_read' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMessageSeenStatusMutation,
  UpdateMessageSeenStatusMutationVariables
>;
export const ToggleChatNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'toggleChatNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ToggleChatNotificationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleChatNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ToggleChatNotificationMutation,
  ToggleChatNotificationMutationVariables
>;
export const InsertIntoChatFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertIntoChatFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chat_message_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storage_file_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_message_file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_message_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'chat_message_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'storage_file_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'storage_file_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_message_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertIntoChatFilesMutation,
  InsertIntoChatFilesMutationVariables
>;
export const DeleteGroupChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGroupChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'room_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted_at' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGroupChatMutation,
  DeleteGroupChatMutationVariables
>;
export const DeleteChatSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteChatSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_chat_message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChatSessionMutation,
  DeleteChatSessionMutationVariables
>;
export const GetChatRoomTypeByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatRoomTypeById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'chat_room' },
            name: { kind: 'Name', value: 'chat_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatRoomTypeByIdQuery,
  GetChatRoomTypeByIdQueryVariables
>;
export const GetChatGroupCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatGroupCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'chat_room' },
            name: { kind: 'Name', value: 'chat_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'chatRoomId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatGroupCreatorQuery,
  GetChatGroupCreatorQueryVariables
>;
export const GetChatRoomDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatRoomDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'chat_room' },
            name: { kind: 'Name', value: 'chat_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatRoomDetailsQuery,
  GetChatRoomDetailsQueryVariables
>;
export const GetChatInfoForMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatInfoForMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'chat_room' },
            name: { kind: 'Name', value: 'chat_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'chatRoomId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'room_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'calendars' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatInfoForMeetingQuery,
  GetChatInfoForMeetingQueryVariables
>;
export const GetMyTasksForProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyTasksForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_deleted' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyTasksForProjectQuery,
  GetMyTasksForProjectQueryVariables
>;
export const GetLatestMessageByRoomIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLatestMessageByRoomId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_messages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: { kind: 'EnumValue', value: 'desc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_text' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_type' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLatestMessageByRoomIdQuery,
  GetLatestMessageByRoomIdQueryVariables
>;
export const AddReactionToMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addReactionToMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'chat_message_reactions_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_message_reactions_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddReactionToMessageMutation,
  AddReactionToMessageMutationVariables
>;
export const AddChatEmoteReactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addChatEmoteReaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddChatEmoteReactionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addChatEmoteReaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reactionId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddChatEmoteReactionMutation,
  AddChatEmoteReactionMutationVariables
>;
export const GetMessageReactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMessageReactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message_reactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'messageId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMessageReactionsSubscription,
  GetMessageReactionsSubscriptionVariables
>;
export const GetMessageReactionsDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMessageReactionsDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reactionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message_reactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'reactionIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reaction_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMessageReactionsDetailQuery,
  GetMessageReactionsDetailQueryVariables
>;
export const StartMeetingChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startMeetingChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StartMeetingChatInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startMeetingChat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'chatRoomId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartMeetingChatMutation,
  StartMeetingChatMutationVariables
>;
export const UpdateManualStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateManualState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manualState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'enum_manual_state_enum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_state_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manual_state' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'manualState' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'activity_state' },
                      value: { kind: 'EnumValue', value: 'ONLINE' },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'activity_state_last_updated',
                      },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manual_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateManualStateMutation,
  UpdateManualStateMutationVariables
>;
export const GetUserStatusSubDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getUserStatusSub' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserStatusSubSubscription,
  GetUserStatusSubSubscriptionVariables
>;
export const SetWorkingFromByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setWorkingFromByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'setting_value' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_settings_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'setting_type' },
                      value: { kind: 'EnumValue', value: 'WORKING_FROM' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'setting_value' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'setting_value' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'user_settings_created_by_setting_type_key',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: { kind: 'EnumValue', value: 'setting_value' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetWorkingFromByUserIdMutation,
  SetWorkingFromByUserIdMutationVariables
>;
export const GetWorkingFromSettingsByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkingFromSettingsByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'setting_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORKING_FROM' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting_value' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkingFromSettingsByUserIdQuery,
  GetWorkingFromSettingsByUserIdQueryVariables
>;
export const GetAllAwardsByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getAllAwardsByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'award_posts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'award_posts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'award_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'period_description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_role',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'system_role',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllAwardsByDateSubscription,
  GetAllAwardsByDateSubscriptionVariables
>;
export const GetEmployeeBillableHoursByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeeBillableHoursByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'get_employee_billable_hours' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'track_end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'track_start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeBillableHoursByUserIdQuery,
  GetEmployeeBillableHoursByUserIdQueryVariables
>;
export const CumulativeDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'cumulativeData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_billable_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_lte' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_hours' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CumulativeDataQuery, CumulativeDataQueryVariables>;
export const Get_UserByManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_userByManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UserByManagerQuery,
  Get_UserByManagerQueryVariables
>;
export const Get_HolidaysDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_HolidaysData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'org_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_HolidaysDataQuery,
  Get_HolidaysDataQueryVariables
>;
export const Get_Non_BillableDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_Non_BillableData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_billable' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'estimated_time' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Non_BillableDataQuery,
  Get_Non_BillableDataQueryVariables
>;
export const Non_BillableDataInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'non_BillableDataInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'AUTO_APPROVED' },
                                { kind: 'EnumValue', value: 'MANUAL_APPROVED' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_billable' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq1' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_hours' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Non_BillableDataInfoQuery,
  Non_BillableDataInfoQueryVariables
>;
export const BillableDataInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'billableDataInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'AUTO_APPROVED' },
                                { kind: 'EnumValue', value: 'MANUAL_APPROVED' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_billable' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq1' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_hours' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillableDataInfoQuery,
  BillableDataInfoQueryVariables
>;
export const BillableDataCumulativeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'billableDataCumulative' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_billable' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'AUTO_APPROVED' },
                                { kind: 'EnumValue', value: 'MANUAL_APPROVED' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_hours' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillableDataCumulativeQuery,
  BillableDataCumulativeQueryVariables
>;
export const NonBillableDataCumulativeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'nonBillableDataCumulative' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_billable' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'AUTO_APPROVED' },
                                { kind: 'EnumValue', value: 'MANUAL_APPROVED' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_hours' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NonBillableDataCumulativeQuery,
  NonBillableDataCumulativeQueryVariables
>;
export const LeaveDataCumulativeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'leaveDataCumulative' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leaveDates' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'date' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_approved' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaveDates_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'org_user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_in' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'date' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaveDataCumulativeQuery,
  LeaveDataCumulativeQueryVariables
>;
export const OrgHolidayCumulativeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'orgHolidayCumulative' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_holidays_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'date' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrgHolidayCumulativeQuery,
  OrgHolidayCumulativeQueryVariables
>;
export const GetTimeSpentGraphForIndividualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getTimeSpentGraphForIndividual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timeSpentGraphInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeSpentGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'biblableWorkHours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workHours' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nonBillableClientName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workHours' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nonBillableWorkHours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leaveData' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTimeSpentGraphForIndividualMutation,
  GetTimeSpentGraphForIndividualMutationVariables
>;
export const GetEmployeeBillableHoursAsManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeeBillableHoursAsManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'get_employee_billable_hours_as_manager',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'current_manager_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'managerId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'track_end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'track_start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeBillableHoursAsManagerQuery,
  GetEmployeeBillableHoursAsManagerQueryVariables
>;
export const GetEmployeeCapacityByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeeCapacityByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateStartTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateEndTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employee_capacity_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'employee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'estimate_day' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateStartTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'estimate_day' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateEndTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimate_day' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeCapacityByUserIdQuery,
  GetEmployeeCapacityByUserIdQueryVariables
>;
export const GetIndividualEmployeeCapacityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIndividualEmployeeCapacity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_bandwidth_data' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_day' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_day' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_day' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetIndividualEmployeeCapacityQuery,
  GetIndividualEmployeeCapacityQueryVariables
>;
export const GetCalenderIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCalenderID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCalenderIdQuery, GetCalenderIdQueryVariables>;
export const GetMeetingTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMeetingTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_event_material_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_lte' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMeetingTimeQuery, GetMeetingTimeQueryVariables>;
export const GetEmployeeCapacityByManagerIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeeCapacityByManagerId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manager_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateStartTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateEndTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employee_capacity_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'estimate_day' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateStartTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'estimate_day' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateEndTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'manager_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'manager_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimate_day' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'employee_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeCapacityByManagerIdQuery,
  GetEmployeeCapacityByManagerIdQueryVariables
>;
export const GetEmployeeCapacityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeeCapacity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_bandwidth_data' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_day' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_day' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_day' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeCapacityQuery,
  GetEmployeeCapacityQueryVariables
>;
export const GetEmployeeCapacityGraphDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getEmployeeCapacityGraphData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'loadEmployeeCapactiyGraphInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadEmployeeCapactiyGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employeeCapacity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'meetingHours' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'estimatedHours' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_day' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeCapacityGraphDataMutation,
  GetEmployeeCapacityGraphDataMutationVariables
>;
export const GetProjectsByPeopleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectsByPeople' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tasks' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_by' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq' },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_and' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'due_date_time',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_gte',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_gte',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_or' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'due_date_time',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_lte',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_lte',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'assigned_to' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'due_date_time' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'estimated_time' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'start_date_time' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assigned_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created_at' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectsByPeopleQuery,
  GetProjectsByPeopleQueryVariables
>;
export const GetProjectsByIndividualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectsByIndividual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'due_date_time',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_gte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_or' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'due_date_time',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_lte',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_lte',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assigned_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectsByIndividualQuery,
  GetProjectsByIndividualQueryVariables
>;
export const GetAllUserSkillDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getAllUserSkillDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetAllUsersBySkillInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllUserSkillDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileImage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comptPercentage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skillDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skillId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skillName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userRating' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllUserSkillDetailsMutation,
  GetAllUserSkillDetailsMutationVariables
>;
export const GetSkillResourceFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getSkillResourceFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SkillResourceFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSkillResourceFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileImage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allocation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'detail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentProject' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'releaseDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastTaskDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'projectAge' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSkillResourceFilterMutation,
  GetSkillResourceFilterMutationVariables
>;
export const GoalListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'goalList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_byUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'distinct_on' },
                      value: { kind: 'EnumValue', value: 'timePeriod' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalListQuery, GoalListQueryVariables>;
export const SpecificUserDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'specificUserDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SpecificUserDetailsQuery,
  SpecificUserDetailsQueryVariables
>;
export const ListOfSkillsUnderCompetencyInManagerDashbaordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'ListOfSkillsUnderCompetencyInManagerDashbaord',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfSkillsUnderCompetencyInManagerDashbaordQuery,
  ListOfSkillsUnderCompetencyInManagerDashbaordQueryVariables
>;
export const GetTwoXTwoMatrixDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getTwoXTwoMatrix' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TwoXTwoMatrixInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTwoXTwoMatrix' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileImage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'performaceMarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'potentialMarks' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTwoXTwoMatrixMutation,
  GetTwoXTwoMatrixMutationVariables
>;
export const GetLatestTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLatestTimePeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_With_Goals',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'action' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'Stopped',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'action' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'Stopped',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLatestTimePeriodQuery,
  GetLatestTimePeriodQueryVariables
>;
export const GetStressGraphDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getStressGraphData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'loadStressDataForGraphInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadStressDataForGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'week1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amusingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relaxedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'balancedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stressedCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'veryStressedCount',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'week2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amusingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relaxedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'balancedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stressedCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'veryStressedCount',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'week3' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amusingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relaxedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'balancedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stressedCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'veryStressedCount',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'week4' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amusingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relaxedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'balancedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stressedCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'veryStressedCount',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'week5' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amusingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relaxedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'balancedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stressedCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'veryStressedCount',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStressGraphDataMutation,
  GetStressGraphDataMutationVariables
>;
export const GetTaskProductivityGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getTaskProductivityGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'taskproductivityGraphInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskproductivityGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalTask' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timelyCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeDelayed' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskProductivityGraphMutation,
  GetTaskProductivityGraphMutationVariables
>;
export const TaskCountGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'taskCountGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskCountGraphQuery, TaskCountGraphQueryVariables>;
export const TaskTimelyCompletedGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'taskTimelyCompletedGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_lte1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'COMPLETED' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_lte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TaskTimelyCompletedGraphQuery,
  TaskTimelyCompletedGraphQueryVariables
>;
export const TaskTimeDelayedGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'taskTimeDelayedGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_lte1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: { kind: 'EnumValue', value: 'COMPLETED' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_lte1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'due_date_time',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TaskTimeDelayedGraphQuery,
  TaskTimeDelayedGraphQueryVariables
>;
export const GetTaskProductivityGraphIndividualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getTaskProductivityGraphIndividual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'taskproductivityGraphInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskproductivityGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalTask' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timelyCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeDelayed' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'end_date_time' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'due_date_time' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskProductivityGraphIndividualMutation,
  GetTaskProductivityGraphIndividualMutationVariables
>;
export const GetTimeSpentGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getTimeSpentGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timeSpentGraphInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeSpentGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'biblableWorkHours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workHours' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nonBillableWorkHours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leaveData' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTimeSpentGraphMutation,
  GetTimeSpentGraphMutationVariables
>;
export const Get_TableViewDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_TableViewData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_gte1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_lte1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order_by' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'user_activity_tracker_order_by' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'user_activity_trackers_aggregate',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_gte' },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'order_by' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sum' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'active_seconds',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'away_seconds',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_activity_trackers' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gte' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: '_gte1',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: '_lte1',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_TableViewDataQuery,
  Get_TableViewDataQueryVariables
>;
export const Get_TableViewIndividualDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_TableViewIndividualData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_gte1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_lte1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order_by' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'user_activity_tracker_order_by' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'user_activity_trackers_aggregate',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_gte' },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'order_by' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sum' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'active_seconds',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'away_seconds',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_activity_trackers' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gte' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: '_gte1',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: '_lte1',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_TableViewIndividualDataQuery,
  Get_TableViewIndividualDataQueryVariables
>;
export const GetTimeSpentAnalysisDateRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTimeSpentAnalysisDateRange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateStartTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateEndTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateStartDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateEndDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_at' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateStartTimestamp',
                                        },
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateEndTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'away_seconds' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_meeting_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'start_date_time',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateStartTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'start_date_time',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateEndTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'event_duration' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_billable_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'work_date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateStartDate',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'work_date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'dateEndDate',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_hours' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_dates_aggregate',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date_timestamp' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'dateStartTimestamp',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dateEndTimestamp' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'org_user_id',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'is_approved',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'BooleanValue',
                                              value: true,
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'leave_application_status',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'ACCEPTED',
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTimeSpentAnalysisDateRangeQuery,
  GetTimeSpentAnalysisDateRangeQueryVariables
>;
export const Get_ScoresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_Scores' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_records_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'marks' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passing_score' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_ScoresQuery, Get_ScoresQueryVariables>;
export const Individual_ScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'individual_Score' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'scoreInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadindividualScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'totalAttendedAssessment',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pendingAssessment' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Individual_ScoreMutation,
  Individual_ScoreMutationVariables
>;
export const GetUserInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agora_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const UsersAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'usersAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assessments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsersAssessmentTypeQuery,
  UsersAssessmentTypeQueryVariables
>;
export const GraphDataForUpSkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'graphDataForUpSkill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'AssessmentScoreForAssessmentTypeInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'loadAssessmentScoreForAssessmentType',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentTypeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skilledData' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviatedData' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalSkilled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalDeviated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userCount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GraphDataForUpSkillMutation,
  GraphDataForUpSkillMutationVariables
>;
export const EmpDataListOfAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'empDataListOfAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'employeeAssessmentScoreInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadEmployeeAssessmentScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfile' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmpDataListOfAssessmentTypeMutation,
  EmpDataListOfAssessmentTypeMutationVariables
>;
export const IndividualEmpDataOfAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'individualEmpDataOfAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'IndividualAssessmentTypeGraphInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadIndividualAssessmentTypeGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalAssessment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pendingAssessment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attempted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'passed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IndividualEmpDataOfAssessmentTypeMutation,
  IndividualEmpDataOfAssessmentTypeMutationVariables
>;
export const InsertDashboardRemainderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertDashboardRemainder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isRead' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'remainderType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'enum_dashboard_remainder_type_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_dashboard_remainders_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isRead' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'remainder_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'remainderType' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remainder_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertDashboardRemainderMutation,
  InsertDashboardRemainderMutationVariables
>;
export const GetDashboardRemaindersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDashboardRemainders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_remainders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remainder_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDashboardRemaindersQuery,
  GetDashboardRemaindersQueryVariables
>;
export const GetMyDashboardRemaindersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyDashboardRemainders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_remainders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remainder_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyDashboardRemaindersSubscription,
  GetMyDashboardRemaindersSubscriptionVariables
>;
export const CheckReminderExistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkReminderExists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'enum_dashboard_remainder_type_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_remainders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'remainder_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'type' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckReminderExistsQuery,
  CheckReminderExistsQueryVariables
>;
export const ListOfKpiByRoleIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfKpiByRoleId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiWithRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orgId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpi_bank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfKpiByRoleIdQuery,
  ListOfKpiByRoleIdQueryVariables
>;
export const MarkAsReadByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markAsReadByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_dashboard_remainders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkAsReadByIdsMutation,
  MarkAsReadByIdsMutationVariables
>;
export const GetUserTimeCardForManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserTimeCardForManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_at' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'startTimestamp',
                                        },
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'endTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_in' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'userIds',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_seconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra_seconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'away_seconds' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_meeting_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userIds' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'start_date_time',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'startTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'start_date_time',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'endTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'event_duration' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_billable_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userIds' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'work_date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'startDate',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'work_date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'endDate',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_hours' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserTimeCardForManagerQuery,
  GetUserTimeCardForManagerQueryVariables
>;
export const GetEmployeesByManagerIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeesByManagerId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manager_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'manager_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeesByManagerIdQuery,
  GetEmployeesByManagerIdQueryVariables
>;
export const GetUserTimeCardForEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserTimeCardForEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'remoteStartDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'remoteEndDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_at' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'startTimestamp',
                                        },
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'endTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_seconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra_seconds' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotdesk_bookings_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'remoteStartDate',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'remoteEndDate',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_meeting_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'start_date_time',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'startTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'start_date_time',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'endTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'event_duration' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserTimeCardForEmployeeQuery,
  GetUserTimeCardForEmployeeQueryVariables
>;
export const GetUpcomingEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUpcomingEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date_time' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date_time' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_event_material_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'start_date_time',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gt' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'start_date_time',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'start_date_time',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'end_date_time',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'calendar_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_attendee' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'attendee_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'attendee_status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_nin' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'NOT_ATTENDING',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date_time' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'all_day' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calendar_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'joinedMeetingUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'ACTIVE' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_events' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_meeting_calls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'meeting_call_users',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'ACTIVE',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'display_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUpcomingEventsQuery,
  GetUpcomingEventsQueryVariables
>;
export const GetUserStateByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getUserStateById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_state' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manual_state' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserStateByIdSubscription,
  GetUserStateByIdSubscriptionVariables
>;
export const GetProfilePicDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProfilePic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfilePicQuery, GetProfilePicQueryVariables>;
export const GetAllDepartmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllDepartments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllDepartmentsQuery,
  GetAllDepartmentsQueryVariables
>;
export const NewPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'newPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'newPostInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'org_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewPostMutation, NewPostMutationVariables>;
export const GetMyPostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyPosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '1' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orgId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'visibility' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_in' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: '_in' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: '_not' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'visibility',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_is_null',
                                            },
                                            value: {
                                              kind: 'BooleanValue',
                                              value: false,
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disappeared_at' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'holiday_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commentedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'columns' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'liked_by',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'likedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentioned_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'posts_poll' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'poll_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'votes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'poll_choice_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updated_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'voted_by' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'votes_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'activity_status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMyPostsQuery, GetMyPostsQueryVariables>;
export const GetTodayEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTodayEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '1' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'today' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orgId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'visibility' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'today' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'post_type' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'EnumValue',
                                        value: 'WELCOME',
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commentedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'columns' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'liked_by',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'likedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentioned_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTodayEventQuery, GetTodayEventQueryVariables>;
export const GetMoreCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMoreComments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '1' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts_comments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commented_by' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentsLikes_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentsLikes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'liked_by' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentReplies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'reply' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replied_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userWhoReplied' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'likes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'liked_by' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'likes_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMoreCommentsQuery,
  GetMoreCommentsQueryVariables
>;
export const GetPostAggregateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getPostAggregate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPostAggregateSubscription,
  GetPostAggregateSubscriptionVariables
>;
export const AddCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'addCommentInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'post_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddCommentMutation, AddCommentMutationVariables>;
export const LikePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'likePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'likesInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'post_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'like_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LikePostMutation, LikePostMutationVariables>;
export const DeletePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editPostInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'post_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'delete' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePostMutation, DeletePostMutationVariables>;
export const DeleteCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editCommentInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'comment_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'delete' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const GetOrganizationLogoByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLogoByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      directives: [
        { kind: 'Directive', name: { kind: 'Name', value: 'cached' } },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo_file_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storageFileByLogoFileId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLogoByOrganizationIdQuery,
  GetOrganizationLogoByOrganizationIdQueryVariables
>;
export const Get_UsersInfoOf_OrganizationAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_UsersInfoOf_OrganizationAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_nlike' },
                            value: {
                              kind: 'StringValue',
                              value: 'org-admin',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UsersInfoOf_OrganizationAdminQuery,
  Get_UsersInfoOf_OrganizationAdminQueryVariables
>;
export const GetTodayLeavesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTodayLeaves' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_dates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'date' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_approved' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'org_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_leave_data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTodayLeavesQuery, GetTodayLeavesQueryVariables>;
export const InsertStressGraphDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertStressGraphData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'stress_data_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_stress_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertStressGraphDataMutation,
  InsertStressGraphDataMutationVariables
>;
export const CheckStressDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkStressData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stress_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'stress_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckStressDataQuery,
  CheckStressDataQueryVariables
>;
export const GetUserTimeCardForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserTimeCardForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userIds' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_at' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'startTimestamp',
                                        },
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'endTimestamp',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'userIds',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_seconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra_seconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'away_seconds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserTimeCardForUserQuery,
  GetUserTimeCardForUserQueryVariables
>;
export const Get_ListingYear_GraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_listingYear_Graph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListingYear_GraphQuery,
  Get_ListingYear_GraphQueryVariables
>;
export const Get_ListOf_EmployeesByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_listOf_employeesByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_nlike' },
                            value: {
                              kind: 'StringValue',
                              value: 'org-admin',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role_id',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOf_EmployeesByOrgIdQuery,
  Get_ListOf_EmployeesByOrgIdQueryVariables
>;
export const Get_ListOfPeersByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_listOfPeersByUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOfPeersByUserQuery,
  Get_ListOfPeersByUserQueryVariables
>;
export const GetAllTasksForDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getAllTasksForDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'empId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'empId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_deleted' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: { kind: 'EnumValue', value: 'COMPLETED' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllTasksForDashboardSubscription,
  GetAllTasksForDashboardSubscriptionVariables
>;
export const InitiateDashboardRemindersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'initiateDashboardReminders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemainderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDashboardReminders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InitiateDashboardRemindersMutation,
  InitiateDashboardRemindersMutationVariables
>;
export const GetMyTimesheetForGivenWeekDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyTimesheetForGivenWeek' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'work_hours' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gt' },
                                      value: { kind: 'IntValue', value: '0' },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'work_minutes' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gt' },
                                      value: { kind: 'IntValue', value: '0' },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyTimesheetForGivenWeekSubscription,
  GetMyTimesheetForGivenWeekSubscriptionVariables
>;
export const CheckMandatoryAssessmentStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkMandatoryAssessmentStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_role_details' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'roleId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_records' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_records' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckMandatoryAssessmentStatusQuery,
  CheckMandatoryAssessmentStatusQueryVariables
>;
export const NewPollPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'newPollPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'newPollPostInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'org_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newPollPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewPollPostMutation, NewPollPostMutationVariables>;
export const GetPollByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPollByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'posts_poll' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'post_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'postId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'poll_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'votes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'poll_choice_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updated_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'voted_by' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'votes_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPollByPostIdQuery,
  GetPollByPostIdQueryVariables
>;
export const AddNewVoteToPollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewVoteToPoll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pollChoiceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_posts_poll_choices_votes_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'poll_choice_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pollChoiceId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'voted_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewVoteToPollMutation,
  AddNewVoteToPollMutationVariables
>;
export const RemoveVoteFromPollByVoterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeVoteFromPollByVoter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pollChoiceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_poll_choices_votes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'poll_choice_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pollChoiceId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'voted_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveVoteFromPollByVoterMutation,
  RemoveVoteFromPollByVoterMutationVariables
>;
export const SetPollPostInactiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setPollPostInactive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'postId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_active' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_active' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetPollPostInactiveMutation,
  SetPollPostInactiveMutationVariables
>;
export const GetPollQuestionAndTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPollQuestionAndTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'postId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disappeared_at' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPollQuestionAndTimeQuery,
  GetPollQuestionAndTimeQueryVariables
>;
export const GetHolidayDataByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHolidayDataById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHolidayDataByIdQuery,
  GetHolidayDataByIdQueryVariables
>;
export const GetActiveOrgAwardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getActiveOrgAwards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager_permission' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'award_types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActiveOrgAwardsSubscription,
  GetActiveOrgAwardsSubscriptionVariables
>;
export const CheckIsAwardActiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'checkIsAwardActive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckIsAwardActiveSubscription,
  CheckIsAwardActiveSubscriptionVariables
>;
export const CheckIsManagerActiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'checkIsManagerActive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_permission' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager_permission' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckIsManagerActiveSubscription,
  CheckIsManagerActiveSubscriptionVariables
>;
export const AssignAwardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'assignAward' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'assignAwardInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignAward' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssignAwardMutation, AssignAwardMutationVariables>;
export const GetAwardTypeByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAwardTypeById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAwardTypeByIdQuery,
  GetAwardTypeByIdQueryVariables
>;
export const GetPostMentionedUsersByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPostMentionedUsersByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_mentioned_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'postId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPostMentionedUsersByPostIdQuery,
  GetPostMentionedUsersByPostIdQueryVariables
>;
export const GetTokenByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getTokenByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document_token' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'access_token' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token_type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTokenByUserIdSubscription,
  GetTokenByUserIdSubscriptionVariables
>;
export const OnedriveAuthenticationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'onedriveAuthentication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OnedriveAuthenticationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onedriveAuthentication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OnedriveAuthenticationMutation,
  OnedriveAuthenticationMutationVariables
>;
export const OnedriveLogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'onedriveLogout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onedriveLogOut' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OnedriveLogoutMutation,
  OnedriveLogoutMutationVariables
>;
export const GoogledriveAuthenticationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'googledriveAuthentication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoogledriveAuthenticationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'googledriveAuthentication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoogledriveAuthenticationMutation,
  GoogledriveAuthenticationMutationVariables
>;
export const GoogledriveLogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'googledriveLogout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'googledriveLogOut' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoogledriveLogoutMutation,
  GoogledriveLogoutMutationVariables
>;
export const DropboxAuthenticationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'dropboxAuthentication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DropboxAuthenticationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dropboxAuthentication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DropboxAuthenticationMutation,
  DropboxAuthenticationMutationVariables
>;
export const DropboxLogOutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'dropboxLogOut' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dropboxLogOut' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DropboxLogOutMutation,
  DropboxLogOutMutationVariables
>;
export const EmployeeOnBoardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'employeeOnBoarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'additional_fields' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'date_of_birth' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'display_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'last_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatar_file_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_user_gender_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'first_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'last_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'additional_fields' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'additional_fields' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date_of_birth' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date_of_birth' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_completed' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'avatar_file_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'avatar_file_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'gender' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gender' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployeeOnBoardingMutation,
  EmployeeOnBoardingMutationVariables
>;
export const GetOrganizationLocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_locations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'title' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '15' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLocationsQuery,
  GetOrganizationLocationsQueryVariables
>;
export const GetBookedUserNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBookedUserName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotdesk_bookings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_location' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBookedUserNameQuery,
  GetBookedUserNameQueryVariables
>;
export const GetLocationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLocationDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'booking_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_locations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'booked_seats' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'args' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'booking_date' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'booking_date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLocationDetailsQuery,
  GetLocationDetailsQueryVariables
>;
export const BookSeatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bookSeats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'hotdesk_bookings_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_hotdesk_bookings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookSeatsMutation, BookSeatsMutationVariables>;
export const GetUserBookingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserBookings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotdesk_bookings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'StringValue',
                              value: 'now()',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotdesk_bookings_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'StringValue',
                              value: 'now()',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserBookingsQuery,
  GetUserBookingsQueryVariables
>;
export const PermanentDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'permanentDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bookingId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_hotdesk_bookings_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bookingId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PermanentDeleteMutation,
  PermanentDeleteMutationVariables
>;
export const UpdateBookingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBooking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'booking_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'location_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_hotdesk_bookings_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'booking_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'location_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBookingMutation,
  UpdateBookingMutationVariables
>;
export const GetUserLeaveDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'GetUserLeaveData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_year_carry_forward' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'limit_of_days_at_once' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_reason' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_reason' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leaveDates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updated_at' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'org_user_id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'leave_application_id',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserLeaveDataSubscription,
  GetUserLeaveDataSubscriptionVariables
>;
export const LeaveApplicationsByUserIdAndStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'LeaveApplicationsByUserIdAndStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leaveStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_leave_status_type_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'organization_user_leave_application_order_by',
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leaveStatus' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_leave_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_start_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_end_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_reason' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_amount_in_days' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaveDates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaveApplicationsByUserIdAndStatusSubscription,
  LeaveApplicationsByUserIdAndStatusSubscriptionVariables
>;
export const ApprovalRequestsByStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ApprovalRequestsByStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leaveStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_leave_status_type_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'organization_user_leave_application_order_by',
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leaveStatus' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_leave_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_employees',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'manager_id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_start_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_end_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_reason' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_amount_in_days' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaveDates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApprovalRequestsByStatusSubscription,
  ApprovalRequestsByStatusSubscriptionVariables
>;
export const SubmitLeaveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitLeave' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_type_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_start_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_end_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_files_id' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_dates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_reason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitLeave' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_dates' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_dates' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_files_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_files_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_reason' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_reason' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_employee_leave_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitLeaveMutation, SubmitLeaveMutationVariables>;
export const UpdateLeaveStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLeaveStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_leave_status_type_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'is_approved' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_approved' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLeaveStatusMutation,
  UpdateLeaveStatusMutationVariables
>;
export const DeleteLeaveApplicationAndInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteLeaveApplicationAndInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_organization_user_leave_dates',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_organization_user_leave_files',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_applicaton_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_organization_user_leave_application_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLeaveApplicationAndInfoMutation,
  DeleteLeaveApplicationAndInfoMutationVariables
>;
export const GetHolidaysInStartAndEndDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHolidaysInStartAndEndDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'holidays' },
            name: { kind: 'Name', value: 'organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'start_date' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'end_date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHolidaysInStartAndEndDateQuery,
  GetHolidaysInStartAndEndDateQueryVariables
>;
export const GetLiveCallInfoByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLiveCallInfoById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liveCallId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_requests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'request_status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveCallInfoByIdSubscription,
  GetLiveCallInfoByIdSubscriptionVariables
>;
export const GetMyChatGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyChatGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'myUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationsId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_users' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'myUserId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: { kind: 'EnumValue', value: 'CLOSED' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'organizationsId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'GROUP' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'room_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_room' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updated_at' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyChatGroupsSubscription,
  GetMyChatGroupsSubscriptionVariables
>;
export const GetMyChatGroupsWithSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyChatGroupsWithSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'myUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationsId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roomName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_users' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'myUserId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: { kind: 'EnumValue', value: 'CLOSED' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'organizationsId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'GROUP' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'room_name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roomName' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'room_name' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'room_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyChatGroupsWithSearchQuery,
  GetMyChatGroupsWithSearchQueryVariables
>;
export const GetCurrentSelectionOfUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCurrentSelectionOfUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'chat_room_users' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'chat_room_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'chatRoomId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_room_users' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'chat_room' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'chat_room_type',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'ONE_TO_ONE',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'chat_room_users',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'user_id',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'userId',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'chat_room' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'chat_room_users_aggregate',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'max',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'updated_at',
                                                    },
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'desc_nulls_last',
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'chat_room' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updated_at' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCurrentSelectionOfUsersQuery,
  GetCurrentSelectionOfUsersQueryVariables
>;
export const GetUserIdsByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserIdsByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'search' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserIdsByOrganizationIdQuery,
  GetUserIdsByOrganizationIdQueryVariables
>;
export const GetUserInfoForLiveOfficeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getUserInfoForLiveOffice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'my_organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_settings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'setting_type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'WORKING_FROM',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'working_from' },
                        name: { kind: 'Name', value: 'setting_value' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status_since' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'updated_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserInfoForLiveOfficeSubscription,
  GetUserInfoForLiveOfficeSubscriptionVariables
>;
export const GetUserInfoForLiveOfficeQDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserInfoForLiveOfficeQ' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'my_organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_settings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'setting_type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'WORKING_FROM',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'working_from' },
                        name: { kind: 'Name', value: 'setting_value' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status_since' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'updated_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserInfoForLiveOfficeQQuery,
  GetUserInfoForLiveOfficeQQueryVariables
>;
export const GetDepartmentsByOrgIdFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDepartmentsByOrgIdFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentName' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepartmentsByOrgIdFilterQuery,
  GetDepartmentsByOrgIdFilterQueryVariables
>;
export const GetChatRoomInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatRoomInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'roomId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'room_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatRoomInfoQuery,
  GetChatRoomInfoQueryVariables
>;
export const GetMyUnreadMessagesCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyUnreadMessagesCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message_action_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_message' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'chat_room_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'chatRoomId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyUnreadMessagesCountSubscription,
  GetMyUnreadMessagesCountSubscriptionVariables
>;
export const PinChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pinChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_pinned_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_pinned_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PinChatMutation, PinChatMutationVariables>;
export const GetPinnedChatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getPinnedChats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_pinned' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_pinned' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_pinned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'room' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'room_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPinnedChatsSubscription,
  GetPinnedChatsSubscriptionVariables
>;
export const DeletePinnedChatByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePinnedChatById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'Id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_chat_pinned' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'Id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePinnedChatByIdMutation,
  DeletePinnedChatByIdMutationVariables
>;
export const CheckIsPinnedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkIsPinned' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_pinned' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckIsPinnedQuery, CheckIsPinnedQueryVariables>;
export const GetMeetingCallBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMeetingCallBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_call' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channel_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chat_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meet_status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recording_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_fields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_by_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_call_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'display_name' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'asc_nulls_last',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'agora_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'room_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingCallBySlugSubscription,
  GetMeetingCallBySlugSubscriptionVariables
>;
export const JoinMeetCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'joinMeetCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetingCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinMeetCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingCallId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'meetingCallId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarMeetingCallUserId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JoinMeetCallMutation,
  JoinMeetCallMutationVariables
>;
export const RequestCalendarMeetCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestCalendarMeetCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_call_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_ids' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestCalendarMeetCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_call_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'meeting_call_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_ids' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCalendarMeetCallMutation,
  RequestCalendarMeetCallMutationVariables
>;
export const GetRequestedUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getRequestedUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'calendar_meeting_call_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_meeting_call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meetCallId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_request_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: { kind: 'EnumValue', value: 'ACCEPTED' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'user_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'status' },
                  name: { kind: 'Name', value: 'call_request_status' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'user' },
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRequestedUsersSubscription,
  GetRequestedUsersSubscriptionVariables
>;
export const GetCalendarMeetByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getCalendarMeetById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_event_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'all_day' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rrule' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_meeting_calls' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'meet_status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_nin' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'EnumValue', value: 'CANCELLED' },
                                      { kind: 'EnumValue', value: 'ENDED' },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meet_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channel_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meeting_call_users' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'ACTIVE',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalendarMeetByIdSubscription,
  GetCalendarMeetByIdSubscriptionVariables
>;
export const GetMeetingIdByMeetingIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMeetingIdByMeetingId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_event_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingIdByMeetingIdQuery,
  GetMeetingIdByMeetingIdQueryVariables
>;
export const DeleteCalendarEventHandlerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCalendarEventHandler' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'deleteCalendarEventInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'calendar_event_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'mode_type' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'event_delete_date' },
                value: { kind: 'StringValue', value: '', block: false },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCalendarEventHandlerMutation,
  DeleteCalendarEventHandlerMutationVariables
>;
export const UpdateCalendarEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCalendarEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UpdateCalendarEventInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'additional_fields' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'calendar_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'end_date_time' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'event_name' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'rrule' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'all_day' },
                value: { kind: 'BooleanValue', value: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'event_type' },
                value: { kind: 'StringValue', value: 'MEETING', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'start_date_time' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'delete_attendees' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_name' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_type' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_id' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_id' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                  ],
                },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'add_attendees' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_name' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_type' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_id' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_id' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;
export const CreateEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CreateCalendarEventInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'additional_fields' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'calendar_id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'end_date_time' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'event_name' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'event_type' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'start_date_time' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'rrule' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'all_day' },
                value: { kind: 'BooleanValue', value: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'attendees' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_name' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_type' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_id' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_id' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEventMutation, CreateEventMutationVariables>;
export const AddAttendeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addAttendees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'calendar_attendee_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddAttendeesMutation,
  AddAttendeesMutationVariables
>;
export const GetCalendarEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getCalendarEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date_time' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date_time' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'start_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'start_date_time',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'end_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'end_date_time',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_attendee' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'live_training' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CreatedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rrule' } },
                { kind: 'Field', name: { kind: 'Name', value: 'all_day' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_meeting_calls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'meet_status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_nin' },
                                        value: {
                                          kind: 'ListValue',
                                          values: [
                                            {
                                              kind: 'EnumValue',
                                              value: 'CANCELLED',
                                            },
                                            {
                                              kind: 'EnumValue',
                                              value: 'ENDED',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'desc_nulls_last',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'meet_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'channel_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'meeting_call_users',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'ACTIVE',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'display_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalendarEventsSubscription,
  GetCalendarEventsSubscriptionVariables
>;
export const GetCalendarEventIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getCalendarEventIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date_time' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date_time' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'start_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'start_date_time',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'end_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'end_date_time',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_attendee' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'live_training' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalendarEventIdsSubscription,
  GetCalendarEventIdsSubscriptionVariables
>;
export const GetCalendarEventsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCalendarEventsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_attendee' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CreatedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rrule' } },
                { kind: 'Field', name: { kind: 'Name', value: 'all_day' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_meeting_calls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'meet_status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_nin' },
                                        value: {
                                          kind: 'ListValue',
                                          values: [
                                            {
                                              kind: 'EnumValue',
                                              value: 'CANCELLED',
                                            },
                                            {
                                              kind: 'EnumValue',
                                              value: 'ENDED',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'desc_nulls_last',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'meet_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'channel_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'meeting_call_users',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'ACTIVE',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'display_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalendarEventsQueryQuery,
  GetCalendarEventsQueryQueryVariables
>;
export const GetAttendeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAttendees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_event_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_attendee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'email' },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_type' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_event_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAttendeesQuery, GetAttendeesQueryVariables>;
export const GetIndividualAttendeeStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIndividualAttendeeStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'myUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_event_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'myUserId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attendee_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetIndividualAttendeeStatusQuery,
  GetIndividualAttendeeStatusQueryVariables
>;
export const GetAttendeesStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAttendeesStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_event_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTENDING' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_status' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'attendee_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAttendeesStatusQuery,
  GetAttendeesStatusQueryVariables
>;
export const UpdateAttendingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAttendingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attendee_status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_attendee_status_type_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attendeeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_event_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attendeeId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'attendee_status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAttendingStatusMutation,
  UpdateAttendingStatusMutationVariables
>;
export const DeleteMeetingFromCalendarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMeetingFromCalendar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attendeeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_event_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attendeeId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_status' },
                      value: { kind: 'EnumValue', value: 'NOT_ATTENDING' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMeetingFromCalendarMutation,
  DeleteMeetingFromCalendarMutationVariables
>;
export const CreateMeetCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMeetCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMeetCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMeetCallMutation,
  CreateMeetCallMutationVariables
>;
export const GetLeavesForDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLeavesForDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'leave' },
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leaveDates' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date_timestamp' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start_date' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end_date' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ACCEPTED' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_leave_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'dates' },
                  name: { kind: 'Name', value: 'leaveDates' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date_timestamp' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'date_timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLeavesForDateSubscription,
  GetLeavesForDateSubscriptionVariables
>;
export const GetHolidaysInRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getHolidaysInRange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'holidays' },
            name: { kind: 'Name', value: 'organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'start_date' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'end_date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHolidaysInRangeSubscription,
  GetHolidaysInRangeSubscriptionVariables
>;
export const CreateCalendarOneToOneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCalendarOneToOne' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalendarEventOneToOneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCalendarOneToOne' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCalendarOneToOneMutation,
  CreateCalendarOneToOneMutationVariables
>;
export const GetLiveTrainingCalendarEventIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLiveTrainingCalendarEventIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date_time' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date_time' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'start_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'start_date_time',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'end_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'end_date_time',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_attendee' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'live_training' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveTrainingCalendarEventIdsSubscription,
  GetLiveTrainingCalendarEventIdsSubscriptionVariables
>;
export const GetLiveTrainingCalendarEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLiveTrainingCalendarEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_attendee' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'calendar_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'calendar_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CreatedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rrule' } },
                { kind: 'Field', name: { kind: 'Name', value: 'all_day' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_meeting_calls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'meet_status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_nin' },
                                        value: {
                                          kind: 'ListValue',
                                          values: [
                                            {
                                              kind: 'EnumValue',
                                              value: 'CANCELLED',
                                            },
                                            {
                                              kind: 'EnumValue',
                                              value: 'ENDED',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'desc_nulls_last',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'meet_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'channel_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'meeting_call_users',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'ACTIVE',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'display_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveTrainingCalendarEventsQuery,
  GetLiveTrainingCalendarEventsQueryVariables
>;
export const GetMeetingRoomByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMeetingRoomByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meeting_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingRoomByOrganizationIdSubscription,
  GetMeetingRoomByOrganizationIdSubscriptionVariables
>;
export const CreateMeetingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMeetingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMeetingRoomInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMeetingRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'room_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMeetingRoomMutation,
  CreateMeetingRoomMutationVariables
>;
export const GetMyNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userByOwnedBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resource_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Resource_Calendar_Event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_attendees' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'attendee_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'userId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attendee_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attendee_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyNotificationsSubscription,
  GetMyNotificationsSubscriptionVariables
>;
export const GetChatNotificationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getChatNotificationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message_action' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_message' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_message' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'updated_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatNotificationStatusSubscription,
  GetChatNotificationStatusSubscriptionVariables
>;
export const UpdateIsReadNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIsReadNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'is_read' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'notificationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'is_read' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateIsReadNotificationMutation,
  UpdateIsReadNotificationMutationVariables
>;
export const ReadAllNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'readAllNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userByOwnedBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReadAllNotificationsMutation,
  ReadAllNotificationsMutationVariables
>;
export const UpdateIsDeletedNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIsDeletedNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleted_at' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userByOwnedBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deleted_at' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateIsDeletedNotificationMutation,
  UpdateIsDeletedNotificationMutationVariables
>;
export const GetMyEventAttendingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyEventAttendingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eventId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyEventAttendingStatusQuery,
  GetMyEventAttendingStatusQueryVariables
>;
export const GetMyNotificationIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyNotificationIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userByOwnedBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyNotificationIdsQuery,
  GetMyNotificationIdsQueryVariables
>;
export const GetNotificationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getNotificationDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'notificationIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resource_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Resource_Calendar_Event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_attendees' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'attendee_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'userId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attendee_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attendee_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationDetailsQuery,
  GetNotificationDetailsQueryVariables
>;
export const CreateKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createKpi' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'KpiInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateKpiMutation, CreateKpiMutationVariables>;
export const CreateGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoalsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGoalsMutation, CreateGoalsMutationVariables>;
export const UpdateGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGoalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGoalsMutation, UpdateGoalsMutationVariables>;
export const Delete_Goal_DataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_Goal_Data' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteGoalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_Goal_DataMutation,
  Delete_Goal_DataMutationVariables
>;
export const GetTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTimePeriod' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'time_period' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'datePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTimePeriodQuery, GetTimePeriodQueryVariables>;
export const ListOfFrequencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfFrequency' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frequency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfFrequencyQuery,
  ListOfFrequencyQueryVariables
>;
export const GetListOfGoalCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getListOfGoalCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithDepts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'department_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq2' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'time_period' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_in' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetListOfGoalCountQuery,
  GetListOfGoalCountQueryVariables
>;
export const ListOfGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithDepts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'department_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq2' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalWithTimePeriods' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weightage_s' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timePeriodic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_bank' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'kpiWithRoles',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'organization_role',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'role_title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'kpiWithRolesAndDepts',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'department',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithTimePeriods' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListOfGoalsQuery, ListOfGoalsQueryVariables>;
export const GetGoalListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGoalList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dept_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'role_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithDepts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'department_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'dept_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalWithTimePeriods' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'time_period',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'org_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_At' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithTimePeriods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_bank' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'role_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithDepts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'department_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'dept_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalWithTimePeriods' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'time_period',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'org_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGoalListQuery, GetGoalListQueryVariables>;
export const GetRoleListForOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRoleListForOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRoles_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRoleListForOrgAdminQuery,
  GetRoleListForOrgAdminQueryVariables
>;
export const GetDeptListForOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDeptListForOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'kpiWithRolesAndDepts_aggregate',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeptListForOrgAdminQuery,
  GetDeptListForOrgAdminQueryVariables
>;
export const GetListOfKipByGoalIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getListOfKipByGoalId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalWithKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpi_bank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpiWithRoles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'department' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetListOfKipByGoalIdQuery,
  GetListOfKipByGoalIdQueryVariables
>;
export const GetGoalDetailsByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getGoalDetailsById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoalDetailsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDetailById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_Id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weightage_s' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriodic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalsWithRoles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalsWithFreqs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'frequency' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalsWithDepts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'department' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalWithKpis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_ID' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalWithTimePeriods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timePeriod' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalWithRoleWeightage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time_period' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'available_weightage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'weightage' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timePeriod' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoalDetailsByIdMutation,
  GetGoalDetailsByIdMutationVariables
>;
export const DeleteGoalDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGoalData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'goalId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalsWithDepts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalWithTimePeriod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalWithKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goals_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'goalId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGoalDataMutation,
  DeleteGoalDataMutationVariables
>;
export const CreateGoalKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createGoalKpi' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoalKpiInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoalKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGoalKpiMutation,
  CreateGoalKpiMutationVariables
>;
export const UpdateKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateKpi' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateKpiInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateKpiMutation, UpdateKpiMutationVariables>;
export const DeleteKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteKPI' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_kpi' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_kpiWithRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpi_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_kpi' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_kpiWithDept' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpi_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_kpi' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_kpi_bank_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_kpi' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteKpiMutation, DeleteKpiMutationVariables>;
export const ListOfKpiByRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfKpiByRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq3' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_ilike' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: '_ilike' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'kpiWithRoles' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'organization_role',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'role_title',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_ilike',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_ilike',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'kpiWithRolesAndDepts',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'department',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'name',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_ilike',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_ilike',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managerId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goal_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq3' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_ID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfKpiByRoleQuery,
  ListOfKpiByRoleQueryVariables
>;
export const ListOfKpiByDepartmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfKpiByDepartment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq3' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_ilike' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: '_ilike' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'kpiWithRoles' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'organization_role',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'role_title',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_ilike',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_ilike',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'kpiWithRolesAndDepts',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'department',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'name',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_ilike',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_ilike',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managerId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'department_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goal_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq3' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_ID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfKpiByDepartmentQuery,
  ListOfKpiByDepartmentQueryVariables
>;
export const ListOfDepartmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfDepartments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiWithDept_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'department_Id' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpi_bank' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'managerId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'kpiWithRolesAndDepts_aggregate',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'kpi_bank',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'managerId',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_eq',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfDepartmentsQuery,
  ListOfDepartmentsQueryVariables
>;
export const ListOfRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiWithRole_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'role_Id' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpi_bank' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'managerId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'kpiWithRoles_aggregate',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'kpi_bank',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'managerId',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_eq',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListOfRolesQuery, ListOfRolesQueryVariables>;
export const KpiBankDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'kpiBankData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'open_ended_kpi' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KpiBankDataQuery, KpiBankDataQueryVariables>;
export const LoadWeightageForGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loadWeightageForGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Load_Role_Weightage_Input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadWeightageForGoals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'time_period' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'available_weightage',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'weightage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timePeriod' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadWeightageForGoalsMutation,
  LoadWeightageForGoalsMutationVariables
>;
export const Get_DeptByManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_deptByManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'department_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'kpiWithRolesAndDepts_aggregate',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_DeptByManagerQuery,
  Get_DeptByManagerQueryVariables
>;
export const Get_RolesByManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_rolesByManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'profile' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_role',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'role_title',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_ilike',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_ilike',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'role_title' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'kpiWithRoles_aggregate',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'aggregate',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'count',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_RolesByManagerQuery,
  Get_RolesByManagerQueryVariables
>;
export const CheckPerformanceReviewTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkPerformanceReviewTimePeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preformance_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckPerformanceReviewTimePeriodQuery,
  CheckPerformanceReviewTimePeriodQueryVariables
>;
export const FinancialYearInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'financialYearInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'todaysDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_With_Goals',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'performance_review_setting',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_Id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startingDate' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'todaysDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endingDate' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'todaysDate' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinancialYearInfoQuery,
  FinancialYearInfoQueryVariables
>;
export const GetPerformanceReviewDetailInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getPerformanceReviewDetailInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerfReviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPerformanceReviewDetailInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instruction' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organizationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating_detail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scale' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_setting_With_Goals',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'year' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'action' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'performance_review_setting_GoalsLists',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'goal' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'timePeriodic',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'weightage_s',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'goalWithKpis',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'kpi_bank',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'description',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'open_ended_kpi',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'performance_Period_Id',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerformanceReviewDetailInfoMutation,
  GetPerformanceReviewDetailInfoMutationVariables
>;
export const GetPerformanceReviewDetailByRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPerformanceReviewDetailByRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instruction' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_Id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_setting_GoalsLists',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'goal' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'timePeriodic',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_ilike',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_ilike',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'goalsWithRoles',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'role_ID',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_roleId',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'performance_Period_Id',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'timePeriodic',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'weightage_s',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'goalWithKpis',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'order_by',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'kpi_bank',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'created_at',
                                                    },
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'asc',
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'kpi_bank',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'description',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerformanceReviewDetailByRoleQuery,
  GetPerformanceReviewDetailByRoleQueryVariables
>;
export const GetUserRoleIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserRoleID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserRoleIdQuery, GetUserRoleIdQueryVariables>;
export const GetGoalsAssociatedWithEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGoalsAssociatedWithEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'goal_ID' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'goalWithTimePeriods',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'timePeriod' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_in' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalWithKpis' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'kpi_bank' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'kpiWithRoles',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'role_Id',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_eq',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'kpi_bank' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'created_at',
                                        },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'desc',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_bank' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalWithTimePeriods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timePeriod' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalWithKpis_aggregate' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'kpi_bank' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'kpiWithRoles',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'role_Id',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_eq',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoalsAssociatedWithEmployeeQuery,
  GetGoalsAssociatedWithEmployeeQueryVariables
>;
export const CheckActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'StringValue',
                              value: 'Pause',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckActionQuery, CheckActionQueryVariables>;
export const PerformanceReviewActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'performanceReviewAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_orgId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'performance_review_setting_GoalsLists',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'goal' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'goalsWithRoles',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'role_ID',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_roleId',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'StringValue',
                              value: 'Pause',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startingDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_GoalsLists',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timePeriodic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_setting',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'frequency' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PerformanceReviewActionQuery,
  PerformanceReviewActionQueryVariables
>;
export const GetGoalsAssociatedWithEmployeeCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGoalsAssociatedWithEmployeeCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsWithRoles_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriodic' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoalsAssociatedWithEmployeeCountQuery,
  GetGoalsAssociatedWithEmployeeCountQueryVariables
>;
export const SubmitReviewRatingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitReviewRating' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerformanceReviewUsersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReviewUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitReviewRatingMutation,
  SubmitReviewRatingMutationVariables
>;
export const RequestPeerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestPeer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PeerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPeer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestPeerMutation, RequestPeerMutationVariables>;
export const Get_Peers_ReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'get_peers_Review' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performanceReviewByUser_Id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_id',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Peers_ReviewSubscription,
  Get_Peers_ReviewSubscriptionVariables
>;
export const List_Of_EmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_employee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_EmployeeQuery,
  List_Of_EmployeeQueryVariables
>;
export const GetUserManagerIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserManagerId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserManagerIdQuery,
  GetUserManagerIdQueryVariables
>;
export const GetPeersRequestToReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getPeersRequestToReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performanceReviewByUser_Id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_id',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_byUsers',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'peer_Id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: '_is_null',
                                        },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'calendarId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'progressBar' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'calendar_event' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'event_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'start_date_time',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPeersRequestToReviewSubscription,
  GetPeersRequestToReviewSubscriptionVariables
>;
export const CheckPeerRequestCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'checkPeerRequestCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performanceReviewByUser_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckPeerRequestCountSubscription,
  CheckPeerRequestCountSubscriptionVariables
>;
export const GetPerformanceReviewStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getPerformanceReviewStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_Period_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Period_Id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerformanceReviewStatusSubscription,
  GetPerformanceReviewStatusSubscriptionVariables
>;
export const GetUserDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const PerformanceReviewGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'performanceReviewGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerformanceReviewGraphInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalInfo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalMarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'peerMarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weightage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total_peer_marks' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overReviewMarks' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalMark' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PerformanceReviewGraphMutation,
  PerformanceReviewGraphMutationVariables
>;
export const RemoveOrDeclinePeersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeOrDeclinePeers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveOrDeclinePeersMutation,
  RemoveOrDeclinePeersMutationVariables
>;
export const GetPerformanceTimePeriodByYearDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPerformanceTimePeriodByYear' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerformanceTimePeriodByYearQuery,
  GetPerformanceTimePeriodByYearQueryVariables
>;
export const PerformanceReviewMarksPerUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'performanceReviewMarksPerUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerformanceReviewUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewMarksPerUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overAllReview' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'peerReview' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selfReview' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerReview' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalMark' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_user_review' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'self_user_review' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'peer_user_marks' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PerformanceReviewMarksPerUsersMutation,
  PerformanceReviewMarksPerUsersMutationVariables
>;
export const PerformanceReviewNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'performanceReviewNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerformanceReviewNotifyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PerformanceReviewNotificationMutation,
  PerformanceReviewNotificationMutationVariables
>;
export const Get_ListingYearDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_listingYear' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListingYearQuery,
  Get_ListingYearQueryVariables
>;
export const GetPerformanceReviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPerformanceReviews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'peerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_is_null' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'peer_Id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'peerId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'peer_Id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_is_null' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: '_is_null',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_timePeriod_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goal',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser_WithGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'peer_Id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'peerId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'peer_Id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_is_null',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: '_is_null',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'goal_Marks' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_byUser_Kpis',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_bank' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'open_ended_kpi' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_Mark' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerformanceReviewsQuery,
  GetPerformanceReviewsQueryVariables
>;
export const SelfReview_ScoreSheetByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'selfReview_ScoreSheetByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_timePeriod_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goal',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser_WithGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'peer_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'goal_Marks' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_byUser_Kpis',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_bank' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'open_ended_kpi' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_Mark' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelfReview_ScoreSheetByUserIdQuery,
  SelfReview_ScoreSheetByUserIdQueryVariables
>;
export const PeerReview_ScoreSheetByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'peerReview_ScoreSheetByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq4' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq5' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_timePeriod_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goal',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser_WithGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'peer_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goalsWithRoles' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'role_ID' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq4' },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'time_period' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq5' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalsWithRoles' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'role_ID' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq4' },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'time_period' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq5' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_ID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_weightage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time_period' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'goal_Marks' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_byUser_Kpis',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_bank' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'open_ended_kpi' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_Mark' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PeerReview_ScoreSheetByUserIdQuery,
  PeerReview_ScoreSheetByUserIdQueryVariables
>;
export const GetScoreSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getScoreSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetScoreSheetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getScoreSheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewOption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating_scale' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'goal_weightage_marks',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpiDetail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kpi_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kpi_title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'open_ended_kpi',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'userDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'marks',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'comments',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'profileImage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'userName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'role' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetScoreSheetMutation,
  GetScoreSheetMutationVariables
>;
export const PeerCompletedCountWithoutManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'peerCompletedCountWithoutManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_neq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'StringValue',
                              value: 'Completed',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_neq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PeerCompletedCountWithoutManagerQuery,
  PeerCompletedCountWithoutManagerQueryVariables
>;
export const PeerCompletedCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'peerCompletedCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'StringValue',
                              value: 'Completed',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PeerCompletedCountQuery,
  PeerCompletedCountQueryVariables
>;
export const ReviewFeedBackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'reviewFeedBack' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rating' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedBack' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_timePeriod_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'feedBack' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'feedBack' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'rating' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReviewFeedBackMutation,
  ReviewFeedBackMutationVariables
>;
export const Update_OneToOneMarkCompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_OneToOneMarkComplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_timePeriod_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meetingStatus' },
                      value: {
                        kind: 'StringValue',
                        value: 'Completed',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_OneToOneMarkCompleteMutation,
  Update_OneToOneMarkCompleteMutationVariables
>;
export const AddProgressBarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addProgressBar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'progressBar' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_timePeriod_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'progressBar' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'progressBar' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddProgressBarMutation,
  AddProgressBarMutationVariables
>;
export const GetProgressBarValueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getProgressBarValue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_timePeriod_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'progressBar' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProgressBarValueSubscription,
  GetProgressBarValueSubscriptionVariables
>;
export const GetOneToOneMeetingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOneToOneMeetingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_timePeriod_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meetingStatus' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOneToOneMeetingStatusQuery,
  GetOneToOneMeetingStatusQueryVariables
>;
export const GetFeedBackInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getFeedBackInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_timePeriod_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedBack' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeedBackInfoSubscription,
  GetFeedBackInfoSubscriptionVariables
>;
export const OneToOneDiscussionWithDetailInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'oneToOneDiscussionWithDetailInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_timePeriod_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_attendees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attendee_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OneToOneDiscussionWithDetailInfoQuery,
  OneToOneDiscussionWithDetailInfoQueryVariables
>;
export const Check_PeersCompletedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_PeersCompleted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'StringValue',
                              value: 'Completed',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_PeersCompletedQuery,
  Check_PeersCompletedQueryVariables
>;
export const GetRatingCountByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRatingCountByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedBack' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRatingCountByUserIdQuery,
  GetRatingCountByUserIdQueryVariables
>;
export const GetKpiListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getKpiList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetKpiListPerRoleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getKpiList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listOfKpi' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managerId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'open_ended_kpi' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpiWithRoles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_role',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'kpiWithRolesAndDepts',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'department' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goalWithKpis' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kpi_ID' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetKpiListMutation, GetKpiListMutationVariables>;
export const GetRatingTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRatingTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'performance_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rating' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_rating_scale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'performance_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'rating' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRatingTagQuery, GetRatingTagQueryVariables>;
export const CheckSelfWeightageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkSelfWeightage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'year' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'self_review' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckSelfWeightageQuery,
  CheckSelfWeightageQueryVariables
>;
export const GetRoleIdOfUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRoleIdOfUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRoleIdOfUserQuery,
  GetRoleIdOfUserQueryVariables
>;
export const GetMyProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'date_of_birth' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar_file_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMyProfileQuery, GetMyProfileQueryVariables>;
export const UpdateProfileAndUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProfileAndUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'additional_fields' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'date_of_birth' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'last_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'display_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_user_gender_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tz' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'additional_fields' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'additional_fields' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date_of_birth' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date_of_birth' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'gender' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gender' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tz' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'first_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'last_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileAndUserMutation,
  UpdateProfileAndUserMutationVariables
>;
export const UpdateProfilePicDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProfilePic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatar_file_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'avatar_file_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'avatar_file_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfilePicMutation,
  UpdateProfilePicMutationVariables
>;
export const SetNotificaionPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setNotificaionPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'created_by' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'setting_value' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'setting_type' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_user_settings_type_enum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_settings_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'setting_value' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'setting_value' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'setting_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'setting_type' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'user_settings_created_by_setting_type_key',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: { kind: 'EnumValue', value: 'setting_value' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting_value' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetNotificaionPreferencesMutation,
  SetNotificaionPreferencesMutationVariables
>;
export const GetUserNotificationPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserNotificationPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting_value' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserNotificationPreferencesQuery,
  GetUserNotificationPreferencesQueryVariables
>;
export const GetAvatar3DByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getAvatar3DByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_avatar_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FULL_BODY' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatar_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'file_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvatar3DByUserIdSubscription,
  GetAvatar3DByUserIdSubscriptionVariables
>;
export const GetMyApprovedTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyApprovedTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'AUTO_APPROVED',
                                      },
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_APPROVED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByCreatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'AUTO_APPROVED',
                                      },
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_APPROVED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyApprovedTimesheetQuery,
  GetMyApprovedTimesheetQueryVariables
>;
export const GetMyPendingTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyPendingTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'PENDING',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyPendingTimesheetQuery,
  GetMyPendingTimesheetQueryVariables
>;
export const GetMyAllTimesheetByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyAllTimesheetByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_hours' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_minutes' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_minutes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'workHours' },
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'workMinutes',
                                    },
                                    name: {
                                      kind: 'Name',
                                      value: 'work_minutes',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByCreatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_hours' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_minutes' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_minutes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'workHours' },
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'workMinutes',
                                    },
                                    name: {
                                      kind: 'Name',
                                      value: 'work_minutes',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyAllTimesheetByDateQuery,
  GetMyAllTimesheetByDateQueryVariables
>;
export const GetMyTimesheetsByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyTimesheetsByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'draft_minutes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'work_minutes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByManagerBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyTimesheetsByDateQuery,
  GetMyTimesheetsByDateQueryVariables
>;
export const GetMyDeclinedTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyDeclinedTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_REJECTED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyDeclinedTimesheetQuery,
  GetMyDeclinedTimesheetQueryVariables
>;
export const GetMyApprovalApprovedListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyApprovalApprovedList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'AUTO_APPROVED',
                                      },
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_APPROVED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'manager_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyApprovalApprovedListQuery,
  GetMyApprovalApprovedListQueryVariables
>;
export const GetMyAllApprovalListByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyAllApprovalListByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeUserId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedUserId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'manager_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_not' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'task_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: '_is_null',
                                        },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'selectedUserId',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_neq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'excludeUserId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'my_organization',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'organization_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'orgId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'offset' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_minutes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'workHours' },
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'workMinutes',
                                    },
                                    name: {
                                      kind: 'Name',
                                      value: 'work_minutes',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByCreatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'manager_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_not' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'task_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: '_is_null',
                                        },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'selectedUserId',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_neq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'excludeUserId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'my_organization',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'organization_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'orgId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'manager_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'selectedUserId',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_neq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'excludeUserId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'my_organization',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'organization_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'orgId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_minutes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'workHours' },
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'workMinutes',
                                    },
                                    name: {
                                      kind: 'Name',
                                      value: 'work_minutes',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'filtered_total' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'manager_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'selectedUserId',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_neq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'excludeUserId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'my_organization',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'organization_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'orgId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyAllApprovalListByDateQuery,
  GetMyAllApprovalListByDateQueryVariables
>;
export const GetMyAllFilteredApprovalListByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyAllFilteredApprovalListByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeUserId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedUserId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'selectedUserId',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'excludeUserId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'orgId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_unscheduled' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_hours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'draft_minutes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByManagerBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'work_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'work_minutes' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'selectedUserId',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'excludeUserId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'orgId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_hours' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_minutes' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyAllFilteredApprovalListByDateQuery,
  GetMyAllFilteredApprovalListByDateQueryVariables
>;
export const GetMyApprovalRejectedListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyApprovalRejectedList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_REJECTED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'manager_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByManagerBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_work_hours' },
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyApprovalRejectedListQuery,
  GetMyApprovalRejectedListQueryVariables
>;
export const SaveTimeSheetDayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveTimeSheetDay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'TimeSheet' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'saveType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveTimeSheetDay' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'saveType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'saveType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeSheetIds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveTimeSheetDayMutation,
  SaveTimeSheetDayMutationVariables
>;
export const SaveTimeSheetWeekDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveTimeSheetWeek' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'TimeSheetWeekObject' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'saveType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveTimeSheetWeek' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'saveType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'saveType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeSheetIds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveTimeSheetWeekMutation,
  SaveTimeSheetWeekMutationVariables
>;
export const DeleteTaskTimelineByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTaskTimelineByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTaskTimelineByIdsMutation,
  DeleteTaskTimelineByIdsMutationVariables
>;
export const GetMyTaskTimingsForTodayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyTaskTimingsForToday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'today' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'today' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_combined_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_combined_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyTaskTimingsForTodayQuery,
  GetMyTaskTimingsForTodayQueryVariables
>;
export const GetMyTaskTimingsForThisWeekDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyTaskTimingsForThisWeek' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_hours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'work_minutes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'draft_minutes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'work_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_combined_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_combined_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyTaskTimingsForThisWeekQuery,
  GetMyTaskTimingsForThisWeekQueryVariables
>;
export const GetTaskCombinedByTitleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskCombinedByTitle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_combined' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'title' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskCombinedByTitleQuery,
  GetTaskCombinedByTitleQueryVariables
>;
export const GetOrganizationAllEmployeeWithFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationAllEmployeeWithFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'managerId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'search' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'profile' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_role_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'roleId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department_employees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'department' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_sign_date_time' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'filtered_total' },
            name: { kind: 'Name', value: 'organization_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'search' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'profile' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_role_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'roleId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'active' },
            name: { kind: 'Name', value: 'organization_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'account' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'inactive' },
            name: { kind: 'Name', value: 'organization_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'account' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationAllEmployeeWithFilterQuery,
  GetOrganizationAllEmployeeWithFilterQueryVariables
>;
export const GetTimesheetsForCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTimesheetsForCSV' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_time_sheet_status_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedUserId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'managerId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'selectedUserId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'work_minutes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTimesheetsForCsvQuery,
  GetTimesheetsForCsvQueryVariables
>;
export const AddAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'assessment_type_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_assessment_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddAssessmentTypeMutation,
  AddAssessmentTypeMutationVariables
>;
export const LoadAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta_tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadAssessmentTypeQuery,
  LoadAssessmentTypeQueryVariables
>;
export const TrainingsForDevelopmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TrainingsForDevelopmentPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewards' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_feedbacks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'training' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'training_progress_records',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'skill_training_Status',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'feedback' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrainingsForDevelopmentPlanQuery,
  TrainingsForDevelopmentPlanQueryVariables
>;
export const AssessmentsForDevelopmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assessmentsForDevelopmentPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_Skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssessmentsForDevelopmentPlanQuery,
  AssessmentsForDevelopmentPlanQueryVariables
>;
export const CareerDevelopmentDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'careerDevelopmentDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CareerDevelopmentDashboardInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadCareerDevelopmentDashboard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trainingCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'progress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stageName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profileImage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CareerDevelopmentDashboardMutation,
  CareerDevelopmentDashboardMutationVariables
>;
export const CreateCareerDevelopmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCareerDevelopmentPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CareerDevelopmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCareerDevelopmentPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCareerDevelopmentPlanMutation,
  CreateCareerDevelopmentPlanMutationVariables
>;
export const LoadUsersCareerPlanDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loadUsersCareerPlanDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'LoadUserSelectedCareerDetailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadUsersCareerPlanDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trainingCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'progress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stage_details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stage_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'taskDetail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_id',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_id',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'storage_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metadata',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_progress_records',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'docData' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_type',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'question_list',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'skill_detail',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'skill_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stage_status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'external_certification' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'certificate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadUsersCareerPlanDetailsMutation,
  LoadUsersCareerPlanDetailsMutationVariables
>;
export const Delete_Career_Development_PlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_career_development_plan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_development_detail_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_user_career_development_record',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_development_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_detail_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_assign_career_development_plan',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_detail_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_assessment_stage_career' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stage_career_detail' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_development_detail_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'career_development_detail_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_stage_career' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stage_career_detail' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_development_detail_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'career_development_detail_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_stage_career_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_detail_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_career_external_certification',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_detail_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_career_development_role_details',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_plan_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_detail_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_career_development_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_detail_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_Career_Development_PlanMutation,
  Delete_Career_Development_PlanMutationVariables
>;
export const List_Of_Assigned_Users_In_Career_PlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_assigned_users_in_career_plan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assign_career_development_plan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'career_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Assigned_Users_In_Career_PlanQuery,
  List_Of_Assigned_Users_In_Career_PlanQueryVariables
>;
export const List_Of_UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_users' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'my_organization' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<List_Of_UsersQuery, List_Of_UsersQueryVariables>;
export const Assign_User_To_Career_PlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'assign_user_to_career_plan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddAssignUserToCareerPlanInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserToCareerDevelopmentPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Assign_User_To_Career_PlanMutation,
  Assign_User_To_Career_PlanMutationVariables
>;
export const Delete_Assigend_User_In_Career_JourneyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_assigend_user_in_career_journey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_user_career_development_record',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_development_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'career_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_assign_career_development_plan',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'career_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_Assigend_User_In_Career_JourneyMutation,
  Delete_Assigend_User_In_Career_JourneyMutationVariables
>;
export const LoadCareerDevelopmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadCareerDevelopmentPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'career_development_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'career_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cover_image_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'career_external_certifications',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'external_certification_name',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'career_development_role_details',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_development_plan_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'career_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stage_career_details' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_development_detail_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'career_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stage_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'training_stage_careers' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'stage_career_detail',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'career_development_detail_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'career_id',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'training' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'storage_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metadata',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'assessment_stage_careers',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'stage_career_detail',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'career_development_detail_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'career_id',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assessment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'questions_aggregate',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'aggregate',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'count',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_Skills',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'list_Of_Skill',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadCareerDevelopmentPlanQuery,
  LoadCareerDevelopmentPlanQueryVariables
>;
export const UpdateCareerDevelopmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCareerDevelopmentPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCareerDevelopmentPlanInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCareerDevelopmentPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCareerDevelopmentPlanMutation,
  UpdateCareerDevelopmentPlanMutationVariables
>;
export const UploadExternalCertificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadExternalCertification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'user_career_external_certification_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_user_career_external_certification',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadExternalCertificationMutation,
  UploadExternalCertificationMutationVariables
>;
export const CareerViewStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'careerViewStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CareerViewStatsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'careerViewStats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviated_skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill_name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deviated_percentage',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skilled_skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill_name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'skilled_percentage',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_forecasting' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total_emp' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deviated_emp' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'deviated_count',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'percentage' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_list' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'user_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'profile_pic',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skilled_emp' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'skilled_count',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'percentage' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_list' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'user_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'profile_pic',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'training' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_names',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_details',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'training_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'completedBy',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'profile_pic',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'not_CompletedBy',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'profile_pic',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assessment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_names',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_details',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'assessment_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'pass_percentage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'completedBy',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'profile_pic',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'not_CompletedBy',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'profile_pic',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'failed_CompletedBy',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'profile_pic',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_reporting' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile_pic' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deviated_skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'skill_names',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'skill_details',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'skill_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'deviation',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'rating',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'training' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_names',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_details',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'training_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assessment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_names',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_details',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'assessment_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'percentage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ldp_journey' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'career_names',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'career_details',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'career_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'stage_name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'status',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CareerViewStatsMutation,
  CareerViewStatsMutationVariables
>;
export const Update_UserSkillsByManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_UserSkillsByManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rating' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'rating' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'StringValue',
                        value: 'Sent For Manager Approval',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_UserSkillsByManagerMutation,
  Update_UserSkillsByManagerMutationVariables
>;
export const GetUserListForSkillApprovalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getUserListForSkillApproval' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetUSerListForSkillApprovalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserListForSkillApproval' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pendingList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'roleName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profileImage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skillCount' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'roleName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profileImage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skillCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserListForSkillApprovalMutation,
  GetUserListForSkillApprovalMutationVariables
>;
export const GetUsersSkillDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getUsersSkillDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetUserSkillInfoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUsersSkillDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userSkillId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skillName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userRating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'competencyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviseSkill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rating' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trainingCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trainingData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'score' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pass' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUsersSkillDetailMutation,
  GetUsersSkillDetailMutationVariables
>;
export const ApprovalOfSkillsByManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'approvalOfSkillsByManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rating' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'rating' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'StringValue',
                        value: 'Approved',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApprovalOfSkillsByManagerMutation,
  ApprovalOfSkillsByManagerMutationVariables
>;
export const CreateTriningTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTriningType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'training_type_for_training_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTriningTypeMutation,
  CreateTriningTypeMutationVariables
>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetTrainingTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTrainingType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trainingType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'trainingType' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTrainingTypeQuery,
  GetTrainingTypeQueryVariables
>;
export const DeleteTrainingTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTrainingType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTrainingTypeMutation,
  DeleteTrainingTypeMutationVariables
>;
export const EditTrainingTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editTrainingType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditTrainingTypeMutation,
  EditTrainingTypeMutationVariables
>;
export const CompetencyListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'competencyList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompetencyListQuery, CompetencyListQueryVariables>;
export const AddUserSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addUserSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUsersSkillsAndComptInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUserSkillsMutation,
  AddUserSkillsMutationVariables
>;
export const ListOfSkillUnderComptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'listOfSkillUnderCompt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ListofSkillsUnderComptInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skillsUnderComepetency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skillId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userRating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skillName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skillDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rating' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfSkillUnderComptMutation,
  ListOfSkillUnderComptMutationVariables
>;
export const DeleteUserSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUserSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_user_Skills_Assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skills_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_user_Skills_Training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skills_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_user_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteUserSkillsMutation,
  DeleteUserSkillsMutationVariables
>;
export const Update_UserSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_UserSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rating' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'rating' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'StringValue',
                        value: 'Sent For Manager Approval',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_UserSkillsMutation,
  Update_UserSkillsMutationVariables
>;
export const GetUserSkillInDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getUserSkillInDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetUserSkillDashboardInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserSkillInDashboard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'underManagerApproval' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'competencyId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'competencyName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'listOfSkills' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skillId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skillName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userRating' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'skillDetail',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'rating',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessmentDetail',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'assessmentName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'assessmentId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'trainingDetail',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'trainingName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'trainingId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'storage_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'type',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'metadata',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'training_progress_records',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'docData',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'progress',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'type',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'training_feedbacks_aggregate',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'aggregate',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'sum',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'feedback',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'count',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'nodes',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'user',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'display_name',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'profile',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'activity_status',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'avatar_file',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'file_url',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedSkills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'competencyId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'competencyName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'listOfSkills' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skillId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skillName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userRating' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'skillDetail',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'rating',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'projectTitle' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trainingCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trainingAttended' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessmentAttended' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserSkillInDashboardMutation,
  GetUserSkillInDashboardMutationVariables
>;
export const CreateAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AssessmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAssessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAssessmentMutation,
  CreateAssessmentMutationVariables
>;
export const UpdateTrainingInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTrainingInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'updateTrainingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTraining' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTrainingInfoMutation,
  UpdateTrainingInfoMutationVariables
>;
export const FeedBack_CountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'feedBack_Count' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'feedbackInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadfeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FeedBack_CountMutation,
  FeedBack_CountMutationVariables
>;
export const SeeScoreCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'seeScoreCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'scoredCardInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadscorecard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'position' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employeeName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noOfTries' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'passingScore' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SeeScoreCardMutation,
  SeeScoreCardMutationVariables
>;
export const UpdateAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAssessmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAssessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAssessmentMutation,
  UpdateAssessmentMutationVariables
>;
export const Submit_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submit_Assessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'attendAssessmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendAssessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Submit_AssessmentMutation,
  Submit_AssessmentMutationVariables
>;
export const LoadMandatoryAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loadMandatoryAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'loadMandatoryAssessmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadMandatoryAssessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recomendedTraining' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'passingScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadMandatoryAssessmentMutation,
  LoadMandatoryAssessmentMutationVariables
>;
export const CreateNewTrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewTraining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrainingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTraining' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewTrainingMutation,
  CreateNewTrainingMutationVariables
>;
export const CreateTrainingProgressDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTrainingProgressData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrainingProgressInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTrainingProgress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTrainingProgressDataMutation,
  CreateTrainingProgressDataMutationVariables
>;
export const CreateTrainingFeddbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTrainingFeddback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'training_feedback_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_training_feedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTrainingFeddbackMutation,
  CreateTrainingFeddbackMutationVariables
>;
export const TrainingFeedBack_CountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'trainingFeedBack_Count' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_feedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'training_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_feedback_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'feedback' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrainingFeedBack_CountQuery,
  TrainingFeedBack_CountQueryVariables
>;
export const LoadActiveAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadActiveAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta_tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadActiveAssessmentTypeQuery,
  LoadActiveAssessmentTypeQueryVariables
>;
export const GetOrganizationRolesByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationRolesByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleText' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system_role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationRolesByOrganizationIdQuery,
  GetOrganizationRolesByOrganizationIdQueryVariables
>;
export const GetDepartmentByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDepartmentByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentText' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepartmentByOrgIdQuery,
  GetDepartmentByOrgIdQueryVariables
>;
export const Frontend_GetUser_ByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'frontend_getUser_ById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'ip_address' },
                  name: { kind: 'Name', value: 'metadata' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'path' },
                      value: {
                        kind: 'StringValue',
                        value: 'ip_address',
                        block: false,
                      },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_sign_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Frontend_GetUser_ByIdQuery,
  Frontend_GetUser_ByIdQueryVariables
>;
export const Org_AdminRoleIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Org_AdminRoleId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'org-admin',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system_role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Org_AdminRoleIdQuery,
  Org_AdminRoleIdQueryVariables
>;
export const Close_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Close_Assessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_assessment_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Close_AssessmentMutation,
  Close_AssessmentMutationVariables
>;
export const Delete_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Delete_Assessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_AssessmentMutation,
  Delete_AssessmentMutationVariables
>;
export const Load_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'load_assessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommended_training' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active_till' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_role_details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'system_role' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Load_AssessmentQuery,
  Load_AssessmentQueryVariables
>;
export const Get_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Get_Assessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passing_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active_till' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommended_training' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'system_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_title' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessment_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question_options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'correct_answer' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_role_details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'system_role' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_Skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_AssessmentQuery, Get_AssessmentQueryVariables>;
export const GetLatestAssessmentMarksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getLatestAssessmentMarks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'GetLatestAssessmentRecordMarksInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'get_LatestAssessmentMarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'correct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pass' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question_options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'given_answer' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'correct_answer' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLatestAssessmentMarksMutation,
  GetLatestAssessmentMarksMutationVariables
>;
export const CommonTrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'commonTraining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_neq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_nin' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq3' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq4' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_role_details' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_neq' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'training_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_nin' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_nin' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'format' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rewards' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq4' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewards' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_progress_records' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq3' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'skill_training_Status',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_training_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docData' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'progress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_feedbacks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'training' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'training_progress_records',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'skill_training_Status',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'career_training_record',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'feedback' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'activity_status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommonTrainingQuery, CommonTrainingQueryVariables>;
export const Load_TrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'load_training' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq3' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq4' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_role_details' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq2' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'format' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq4' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rewards' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewards' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_progress_records' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq3' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'skill_training_Status',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_training_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docData' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'progress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_feedbacks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'training' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'training_progress_records',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'skill_training_Status',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'career_training_record',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'feedback' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'activity_status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Load_TrainingQuery, Load_TrainingQueryVariables>;
export const Load_TrainingOrg_AdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'load_trainingOrg_Admin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq3' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq4' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in1' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'format' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq4' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rewards' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewards' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_progress_records' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq3' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'skill_training_Status',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_training_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docData' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'progress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_feedbacks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'training' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'training_progress_records',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'skill_training_Status',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'career_training_record',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'feedback' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'activity_status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Load_TrainingOrg_AdminQuery,
  Load_TrainingOrg_AdminQueryVariables
>;
export const TrainingDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'trainingData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storage_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'file_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TrainingDataQuery, TrainingDataQueryVariables>;
export const Delete_TrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_Training' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_TrainingMutation,
  Delete_TrainingMutationVariables
>;
export const Get_QuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Get_Questions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question_options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_QuestionsQuery, Get_QuestionsQueryVariables>;
export const LoadTrainingTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadTrainingTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'meta_tags' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadTrainingTypesQuery,
  LoadTrainingTypesQueryVariables
>;
export const Submit_FeedBackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submit_FeedBack' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'assessment_feedback_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_assessment_feedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Submit_FeedBackMutation,
  Submit_FeedBackMutationVariables
>;
export const Get_FeedBackDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_FeedBackData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_feedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'user_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_FeedBackDataQuery,
  Get_FeedBackDataQueryVariables
>;
export const Load_TrainingForAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'load_TrainingForAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewards' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Load_TrainingForAssessmentQuery,
  Load_TrainingForAssessmentQueryVariables
>;
export const Get_SelectedTrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_SelectedTraining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_SelectedTrainingQuery,
  Get_SelectedTrainingQueryVariables
>;
export const AssessmentRecordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assessmentRecord' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'correct_answer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'given_answer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'question_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssessmentRecordQuery,
  AssessmentRecordQueryVariables
>;
export const Assessment_TypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assessment_Type' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'type' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Assessment_TypeQuery,
  Assessment_TypeQueryVariables
>;
export const Get_TrainingInoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_TrainingIno' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_TrainingInoQuery,
  Get_TrainingInoQueryVariables
>;
export const Get_UsersInfoOf_OrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_UsersInfoOf_Organization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UsersInfoOf_OrganizationQuery,
  Get_UsersInfoOf_OrganizationQueryVariables
>;
export const GetTableOfContentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTableOfContents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_table_of_contents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'end_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showContents' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'start_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'training_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTableOfContentsQuery,
  GetTableOfContentsQueryVariables
>;
export const LoadTrainingProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadTrainingProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_progress_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'training_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'docData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadTrainingProgressQuery,
  LoadTrainingProgressQueryVariables
>;
export const Get_Training_DataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_Training_Data' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rewards' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_table_of_contents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end_time' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'showContents' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_time' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_role_details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training_Skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Training_DataQuery,
  Get_Training_DataQueryVariables
>;
export const ListOfSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list_Of_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListOfSkillsQuery, ListOfSkillsQueryVariables>;
export const ListOfUsersUnderRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfUsersUnderRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'role_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByUserId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'calendars' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfUsersUnderRolesQuery,
  ListOfUsersUnderRolesQueryVariables
>;
export const LiveCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'liveCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'ACTIVE' },
                                { kind: 'EnumValue', value: 'ACTIVE_PROVIDER' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channel_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'live_call_users' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_neq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'userId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LiveCallSubscription,
  LiveCallSubscriptionVariables
>;
export const CreateLiveCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLiveCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startLiveCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'liveCallId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLiveCallMutation,
  CreateLiveCallMutationVariables
>;
export const LeaveLiveCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'leaveLiveCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveLiveCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'liveCallId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'liveCallId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaveLiveCallMutation,
  LeaveLiveCallMutationVariables
>;
export const JoinLiveCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'joinLiveCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinLiveCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'liveCallId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'liveCallId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JoinLiveCallMutation,
  JoinLiveCallMutationVariables
>;
export const GetMyPendingMessagesNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyPendingMessagesNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_users' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notification_status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_messages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'chat_message_actions',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'is_read' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_neq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'user_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_room_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_text' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyPendingMessagesNotificationSubscription,
  GetMyPendingMessagesNotificationSubscriptionVariables
>;
export const GetMyActiveMeetingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMyActiveMeetingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meeting_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_room_users' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'userByCreatedBy' },
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyActiveMeetingRoomSubscription,
  GetMyActiveMeetingRoomSubscriptionVariables
>;
export const GetMeetingRoomInfoByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getMeetingRoomInfoById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'meeting_room' },
            name: { kind: 'Name', value: 'meeting_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByUserId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'user_avatar_files',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'type' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'FULL_BODY',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'agora_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingRoomInfoByIdSubscription,
  GetMeetingRoomInfoByIdSubscriptionVariables
>;
export const InviteMeetingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteMeetingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InviteMeetingRoomInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteMeetingRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InviteMeetingRoomMutation,
  InviteMeetingRoomMutationVariables
>;
export const LatestMeetingRoomUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'latestMeetingRoomUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetingRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meeting_room_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meetingRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestMeetingRoomUserSubscription,
  LatestMeetingRoomUserSubscriptionVariables
>;
export const GetLastGonePersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLastGonePerson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'profile' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'activity_status',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'ListValue',
                                          values: [
                                            {
                                              kind: 'StringValue',
                                              value: 'AWAY',
                                              block: false,
                                            },
                                            {
                                              kind: 'StringValue',
                                              value: 'OFFLINE',
                                              block: false,
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'account' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_state' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'activity_state_last_updated',
                                  },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'desc_nulls_last',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_state' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'activity_state_last_updated',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLastGonePersonSubscription,
  GetLastGonePersonSubscriptionVariables
>;
export const GetLatestOnlinePersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLatestOnlinePerson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'profile' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'activity_status',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'StringValue',
                                          value: 'ONLINE',
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'account' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_state' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'activity_state_last_updated',
                                  },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'desc_nulls_last',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_state' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'activity_state_last_updated',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'previous_activity_status',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLatestOnlinePersonSubscription,
  GetLatestOnlinePersonSubscriptionVariables
>;
export const GetLiveCallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLiveCallRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'request_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PENDING' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'my_organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'manager' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_settings' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'setting_type' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'WORKING_FROM',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'working_from' },
                              name: { kind: 'Name', value: 'setting_value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'activity_status_since',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'live_call_users' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'updated_at' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'desc_nulls_last',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveCallRequestSubscription,
  GetLiveCallRequestSubscriptionVariables
>;
export const GetLatestUnreadMessageToMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLatestUnreadMessageToMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message_action' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_message' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_message' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: {
                              kind: 'EnumValue',
                              value: 'desc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'live_call_users' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'limit' },
                                  value: { kind: 'IntValue', value: '1' },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'order_by' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'updated_at',
                                        },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'desc_nulls_last',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user_settings' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'setting_type',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'WORKING_FROM',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'working_from',
                                    },
                                    name: {
                                      kind: 'Name',
                                      value: 'setting_value',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'date_of_birth',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_role',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'my_organization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'manager' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'first_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLatestUnreadMessageToMeQuery,
  GetLatestUnreadMessageToMeQueryVariables
>;
export const RespondToLiveCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'respondToLiveCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RespondToLiveCallInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'respondToLiveCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RespondToLiveCallMutation,
  RespondToLiveCallMutationVariables
>;
export const ActivateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'activateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ticket' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ticket' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ticket' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resetPassword' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refreshToken' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateAccountMutation,
  ActivateAccountMutationVariables
>;
export const ResendActivateEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendActivateEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendActivateEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendActivateEmailMutation,
  ResendActivateEmailMutationVariables
>;
export const FrontendCreateAnnouncementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'frontendCreateAnnouncement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAnAnnouncement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FrontendCreateAnnouncementMutation,
  FrontendCreateAnnouncementMutationVariables
>;
export const GetPostsByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPostsByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ANNOUNCEMENT' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPostsByOrgIdQuery,
  GetPostsByOrgIdQueryVariables
>;
export const UpdatePostMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePostMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_posts_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePostMessageMutation,
  UpdatePostMessageMutationVariables
>;
export const DeleteAnnouncementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAnnouncement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'announcementId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'delete' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'announcementId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAnnouncementMutation,
  DeleteAnnouncementMutationVariables
>;
export const AddAssessmentTypeOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addAssessmentTypeOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'assessment_type_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_assessment_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddAssessmentTypeOrgAdminMutation,
  AddAssessmentTypeOrgAdminMutationVariables
>;
export const LoadAssessmentTypeOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadAssessmentTypeOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessmentType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessmentType' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta_tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_type_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessmentType' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadAssessmentTypeOrgAdminQuery,
  LoadAssessmentTypeOrgAdminQueryVariables
>;
export const UpdateAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_assessment_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAssessmentTypeMutation,
  UpdateAssessmentTypeMutationVariables
>;
export const DeleteAssessmentTypeOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAssessmentTypeOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_assessment_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAssessmentTypeOrgAdminMutation,
  DeleteAssessmentTypeOrgAdminMutationVariables
>;
export const GetOrgAwardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgAwards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager_permission' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'award_types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrgAwardsQuery, GetOrgAwardsQueryVariables>;
export const UpdateOrganizationAwardsByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationAwardsByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_awards_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_organization_awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationAwardsByOrgIdMutation,
  UpdateOrganizationAwardsByOrgIdMutationVariables
>;
export const InsertOrganizationAwardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertOrganizationAward' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_awards_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_awards_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertOrganizationAwardMutation,
  InsertOrganizationAwardMutationVariables
>;
export const CreateNewAwardTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewAwardType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'createAwardTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAwardType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewAwardTypeMutation,
  CreateNewAwardTypeMutationVariables
>;
export const GetAllAwardsByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllAwardsByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllAwardsByOrgIdQuery,
  GetAllAwardsByOrgIdQueryVariables
>;
export const GetAwardDetailsByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAwardDetailsById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'awardId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'awardId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAwardDetailsByIdQuery,
  GetAwardDetailsByIdQueryVariables
>;
export const UpdateAwardsByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAwardsById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'awardId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'awards_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_awards_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'awardId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAwardsByIdMutation,
  UpdateAwardsByIdMutationVariables
>;
export const UpdateAwardsByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAwardsByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'awards_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAwardsByOrgIdMutation,
  UpdateAwardsByOrgIdMutationVariables
>;
export const DeleteAwardsByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAwardsById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'awardId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'awardId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_awards_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'awardId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAwardsByIdMutation,
  DeleteAwardsByIdMutationVariables
>;
export const AddCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddCompetencyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCompetency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddCompetencyMutation,
  AddCompetencyMutationVariables
>;
export const ListOfCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills_details' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfCompetencyQuery,
  ListOfCompetencyQueryVariables
>;
export const UpdateCompetnecyNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCompetnecyName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_main_skills_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompetnecyNameMutation,
  UpdateCompetnecyNameMutationVariables
>;
export const DeleteCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_sub_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'main_skills_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCompetencyMutation,
  DeleteCompetencyMutationVariables
>;
export const EditCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditCompetencyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editCompetency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditCompetencyMutation,
  EditCompetencyMutationVariables
>;
export const ViewCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'viewCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'main_skills_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills_details' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewCompetencyQuery, ViewCompetencyQueryVariables>;
export const AddSkillInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addSkillInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddSkillDetailsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSkillInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'Object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSkillInfoMutation,
  AddSkillInfoMutationVariables
>;
export const GetListOfSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getListOfSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list_Of_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skills_details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'main_skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list_Of_Skills_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetListOfSkillsQuery,
  GetListOfSkillsQueryVariables
>;
export const EditSkillDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editSkillDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditSkillDetailsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editSkillDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'Object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditSkillDetailsMutation,
  EditSkillDetailsMutationVariables
>;
export const DeleteSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_sub_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_skills_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skills_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_list_Of_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSkillsMutation,
  DeleteSkillsMutationVariables
>;
export const GetSkillDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSkillDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list_Of_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skills_details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSkillDetailsQuery,
  GetSkillDetailsQueryVariables
>;
export const ContactUsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'contactUs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ContactUsInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'StringValue', value: '', block: false },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactUs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactUsMutation, ContactUsMutationVariables>;
export const GetManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'organization_employees',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'organization_id',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'profile' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'organization_role',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'system_role',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_in',
                                                  },
                                                  value: {
                                                    kind: 'ListValue',
                                                    values: [
                                                      {
                                                        kind: 'StringValue',
                                                        value: 'manager-1',
                                                        block: false,
                                                      },
                                                      {
                                                        kind: 'StringValue',
                                                        value: 'manager-2',
                                                        block: false,
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'organization_admin',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'organization_id',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'search' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetManagerQuery, GetManagerQueryVariables>;
export const InsertDepartmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertDepartment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'created_by' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manager_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_department_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'manager_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertDepartmentMutation,
  InsertDepartmentMutationVariables
>;
export const GetDepartmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDepartment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'memberCount' },
                  name: {
                    kind: 'Name',
                    value: 'department_employees_aggregate',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'columns' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'department_id',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDepartmentQuery, GetDepartmentQueryVariables>;
export const GetDepartmentEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDepartmentEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentID' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'search' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organizationId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepartmentEmployeeQuery,
  GetDepartmentEmployeeQueryVariables
>;
export const GetDepartmentEmployeeLengthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDepartmentEmployeeLength' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'user_id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'search' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepartmentEmployeeLengthQuery,
  GetDepartmentEmployeeLengthQueryVariables
>;
export const DeleteDepartmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDepartment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDepartmentMutation,
  DeleteDepartmentMutationVariables
>;
export const EditDepartmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editDepartment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manager_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'manager_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditDepartmentMutation,
  EditDepartmentMutationVariables
>;
export const GetOrganizationEmployeeWithFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationEmployeeWithFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'search' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'profile' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_role_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'roleId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department_employees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'department' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_sign_date_time' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'filtered_total' },
            name: { kind: 'Name', value: 'organization_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'search' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'profile' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_role_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'roleId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'active' },
            name: { kind: 'Name', value: 'organization_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'account' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'inactive' },
            name: { kind: 'Name', value: 'organization_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'account' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationEmployeeWithFilterQuery,
  GetOrganizationEmployeeWithFilterQueryVariables
>;
export const GetDeletedUsersOfOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDeletedUsersOfOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department_employees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'department' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_sign_date_time' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeletedUsersOfOrganizationQuery,
  GetDeletedUsersOfOrganizationQueryVariables
>;
export const SaveEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SaveEmployeeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveEmployee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveEmployeeMutation,
  SaveEmployeeMutationVariables
>;
export const GetDepartmentByOrgIdForAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDepartmentByOrgIdForAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'departmentText' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepartmentByOrgIdForAdminQuery,
  GetDepartmentByOrgIdForAdminQueryVariables
>;
export const GetOrganizationRolesByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationRolesByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleText' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'StringValue',
                              value: 'org-admin',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationRolesByOrgIdQuery,
  GetOrganizationRolesByOrgIdQueryVariables
>;
export const GetManagersByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getManagersByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managerText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'organization_employees',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'orgId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'profile' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'organization_role',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'system_role',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_in',
                                                  },
                                                  value: {
                                                    kind: 'ListValue',
                                                    values: [
                                                      {
                                                        kind: 'StringValue',
                                                        value: 'manager-1',
                                                        block: false,
                                                      },
                                                      {
                                                        kind: 'StringValue',
                                                        value: 'manager-2',
                                                        block: false,
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'organization_admin',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'orgId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'managerText' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'my_organization' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'my_organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_title' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManagersByOrgIdQuery,
  GetManagersByOrgIdQueryVariables
>;
export const Frontend_GetUserByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'frontend_getUserById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'ip_address' },
                  name: { kind: 'Name', value: 'metadata' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'path' },
                      value: {
                        kind: 'StringValue',
                        value: 'ip_address',
                        block: false,
                      },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_sign_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Frontend_GetUserByIdQuery,
  Frontend_GetUserByIdQueryVariables
>;
export const DeleteEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEmployee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEmployeeMutation,
  DeleteEmployeeMutationVariables
>;
export const CreateHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'organization_holidays_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHolidayMutation,
  CreateHolidayMutationVariables
>;
export const GetHolidaysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHolidays' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'holidays' },
            name: { kind: 'Name', value: 'organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHolidaysQuery, GetHolidaysQueryVariables>;
export const UpdateHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_holidays_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'holiday' },
            name: { kind: 'Name', value: 'update_organization_holidays_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateHolidayMutation,
  UpdateHolidayMutationVariables
>;
export const DeleteHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'holiday' },
            name: { kind: 'Name', value: 'delete_organization_holidays_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteHolidayMutation,
  DeleteHolidayMutationVariables
>;
export const GetOrganizationLocationsDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLocationsDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_locations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timezone_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address_line' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_fields' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pin_code' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landmark' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_locations_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLocationsDetailsQuery,
  GetOrganizationLocationsDetailsQueryVariables
>;
export const GetOrganizationLocationsTitleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLocationsTitle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_locations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'title' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLocationsTitleQuery,
  GetOrganizationLocationsTitleQueryVariables
>;
export const UpdateOrganizationLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_locations_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_organization_locations_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationLocationMutation,
  UpdateOrganizationLocationMutationVariables
>;
export const UpdateAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'address_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_address_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;
export const GetLocationBookingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLocationBookings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_locations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total_seats' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'booked_count' },
                  name: { kind: 'Name', value: 'hotdesk_bookings_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotdesk_bookings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'date' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLocationBookingsQuery,
  GetLocationBookingsQueryVariables
>;
export const GetOrganizationLocationByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLocationById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'location' },
            name: { kind: 'Name', value: 'organization_locations_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timezone_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address_line' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_fields' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pin_code' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landmark' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLocationByIdQuery,
  GetOrganizationLocationByIdQueryVariables
>;
export const DeleteOrgLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrgLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrgLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orgId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'locationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locationId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrgLocationMutation,
  DeleteOrgLocationMutationVariables
>;
export const CreateLeaveCycleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLeaveCycle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'starting_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLeaveCycle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'starting_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'starting_date' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_cycle_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLeaveCycleMutation,
  CreateLeaveCycleMutationVariables
>;
export const GetLeaveCycleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLeaveCycle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_leave_cycles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'starting_date' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ending_date' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLeaveCycleSubscription,
  GetLeaveCycleSubscriptionVariables
>;
export const AddNewLeaveTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewLeaveType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddNewLeaveTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addNewLeaveType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_leave_type_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewLeaveTypeMutation,
  AddNewLeaveTypeMutationVariables
>;
export const UpdateLeaveTypeByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLeaveTypeById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditLeaveTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editLeaveType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLeaveTypeByIdMutation,
  UpdateLeaveTypeByIdMutationVariables
>;
export const DeleteOrgActiveLeaveTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOrgActiveLeaveType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditLeaveTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editLeaveType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrgActiveLeaveTypeMutation,
  DeleteOrgActiveLeaveTypeMutationVariables
>;
export const ApplyForLeaveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyForLeave' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_type_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_start_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_end_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_dates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitLeave' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_dates' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_dates' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_employee_leave_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyForLeaveMutation,
  ApplyForLeaveMutationVariables
>;
export const GetOrganizationLeaveCycleByOrgIdAndStartingDateSubDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {
        kind: 'Name',
        value: 'getOrganizationLeaveCycleByOrgIdAndStartingDateSub',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'starting_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_leave_cycles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'starting_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription,
  GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscriptionVariables
>;
export const GetEmployeeLeaveApplicationByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getEmployeeLeaveApplicationById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_start_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_end_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaveDates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_application_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_leave_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'earned_leaves_amount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeLeaveApplicationByIdSubscription,
  GetEmployeeLeaveApplicationByIdSubscriptionVariables
>;
export const GetOrgActiveLeaveTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'GetOrgActiveLeaveTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_active_leave_types_aggregate',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'limit_of_days_at_once' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'annual_eligibility',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrgActiveLeaveTypesSubscription,
  GetOrgActiveLeaveTypesSubscriptionVariables
>;
export const GetLeaveTypeByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'GetLeaveTypeById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'edit_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_active_leave_types_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'edit_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annual_carry_forward_amount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annual_eligibility' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthly_earning_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limit_of_days_at_once' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'will_carry_forward' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'will_be_earned_monthly' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLeaveTypeByIdSubscription,
  GetLeaveTypeByIdSubscriptionVariables
>;
export const GetUserLeavesDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getUserLeavesData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserLeavesDataSubscription,
  GetUserLeavesDataSubscriptionVariables
>;
export const GetLeaveDataOfOrganizationByLeaveIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLeaveDataOfOrganizationByLeaveId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_cycle_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_cycle_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leave_cycle_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'organization_leave_cycle',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ending_date' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'current_term_years',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLeaveDataOfOrganizationByLeaveIdSubscription,
  GetLeaveDataOfOrganizationByLeaveIdSubscriptionVariables
>;
export const GetOrganizationLeaveDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLeaveData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_dates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'org_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organizationId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_reason' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLeaveDataQuery,
  GetOrganizationLeaveDataQueryVariables
>;
export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoginInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jwt_token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jwt_expires_in' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refresh_token' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setup_complete' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const InsertOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertOrganizationMutation,
  InsertOrganizationMutationVariables
>;
export const InsertOrganizationAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertOrganizationAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_admin_insert_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'default_role' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: {
            kind: 'StringValue',
            value: 'org-admin',
            block: false,
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_admin_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_auth_accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'default_role' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'default_role' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertOrganizationAdminMutation,
  InsertOrganizationAdminMutationVariables
>;
export const InsertAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'address_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_address_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertAddressMutation,
  InsertAddressMutationVariables
>;
export const CreateDepartmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDepartments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'department_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_department' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDepartmentsMutation,
  CreateDepartmentsMutationVariables
>;
export const InsertOrganizationLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertOrganizationLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_locations_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_locations_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertOrganizationLocationMutation,
  InsertOrganizationLocationMutationVariables
>;
export const CreateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'createOrganizationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const GetTimePeriodListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTimePeriodList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'time_period' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'datePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTimePeriodListQuery,
  GetTimePeriodListQueryVariables
>;
export const GetPerformanceReviewTableDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPerformanceReviewTableData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instruction' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_Id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_goal_share' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'copy_previews_performance_goals',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'self_review' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager_review' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_review' } },
                { kind: 'Field', name: { kind: 'Name', value: 'view_option' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startingDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preformance_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewScoreOption' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value:
                            'performance_review_setting_GoalsLists_aggregate',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performanceReviewStatuses_aggregate',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'organization_employees_aggregate',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_rating_scales' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerformanceReviewTableDataQuery,
  GetPerformanceReviewTableDataQueryVariables
>;
export const CreatePerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerformanceReviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPerformanceReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePerformanceReviewMutation,
  CreatePerformanceReviewMutationVariables
>;
export const UpdatePerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePerofrmanceReviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePerformanceReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePerformanceReviewMutation,
  UpdatePerformanceReviewMutationVariables
>;
export const FinancialYearValueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'financialYearValue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'todaysDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_With_Goals',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'performance_review_setting',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_Id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startingDate' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'todaysDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endingDate' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'todaysDate' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinancialYearValueQuery,
  FinancialYearValueQueryVariables
>;
export const GetGoalNoPerRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getGoalNoPerRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoalCountPerRolesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGoalNoPerRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoalNoPerRolesMutation,
  GetGoalNoPerRolesMutationVariables
>;
export const PerformanceReviewNotificationForManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'performanceReviewNotificationForManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerformanceReviewNotifyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PerformanceReviewNotificationForManagerMutation,
  PerformanceReviewNotificationForManagerMutationVariables
>;
export const Get_ListingYear_Org_AdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_listingYear_Org_Admin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListingYear_Org_AdminQuery,
  Get_ListingYear_Org_AdminQueryVariables
>;
export const Get_TimePeriod_ListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'get_TimePeriod_List' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TimePeriodListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTimePeriodList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_TimePeriod_ListMutation,
  Get_TimePeriod_ListMutationVariables
>;
export const CheckingPerformanceReviewStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkingPerformanceReviewStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewStatus_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckingPerformanceReviewStatusQuery,
  CheckingPerformanceReviewStatusQueryVariables
>;
export const CheckGoalCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkGoalCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'goalCountPerTimeperiodInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkGoalCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckGoalCountMutation,
  CheckGoalCountMutationVariables
>;
export const LoadPerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loadPerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoadPerformanceReviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadPerformanceReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'period' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timePeriod' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startingDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endingDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'action' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goalDetails' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadPerformanceReviewMutation,
  LoadPerformanceReviewMutationVariables
>;
export const PreviousYearTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'previousYearTimePeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'year' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PreviousYearTimePeriodQuery,
  PreviousYearTimePeriodQueryVariables
>;
export const InsertUserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertUserProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'profile_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_profile_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertUserProfileMutation,
  InsertUserProfileMutationVariables
>;
export const CreateOrganizationOwnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganizationOwner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profile' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProfileInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrganizationOwner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profile' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganizationOwnerMutation,
  CreateOrganizationOwnerMutationVariables
>;
export const InsertOrganizationRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertOrganizationRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleTitle' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'systemRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_roles_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'roleTitle' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'systemRole' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertOrganizationRoleMutation,
  InsertOrganizationRoleMutationVariables
>;
export const GetOrganizationRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleTitle' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system_role' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'totalFiltered' },
            name: { kind: 'Name', value: 'organization_roles_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleTitle' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationRolesQuery,
  GetOrganizationRolesQueryVariables
>;
export const DeletRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrganizationRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletRoleMutation, DeletRoleMutationVariables>;
export const GetOrganizationAdminsByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationAdminsByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_admin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_owner' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_admin_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationAdminsByOrganizationIdQuery,
  GetOrganizationAdminsByOrganizationIdQueryVariables
>;
export const DeleteOrganizationAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrganizationAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteOrgAdminInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrgAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrganizationAdminMutation,
  DeleteOrganizationAdminMutationVariables
>;
export const TransferOrganizationOwnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'transferOrganizationOwnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TransferOwnershipInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferOwnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransferOrganizationOwnershipMutation,
  TransferOrganizationOwnershipMutationVariables
>;
export const SaveOrganizationAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveOrganizationAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SaveOrganizationAdminInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveOrganizationAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveOrganizationAdminMutation,
  SaveOrganizationAdminMutationVariables
>;
export const GetOrganizationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address_line' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pin_code' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'anniversary_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'working_days' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'working_hours' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'organization_employees_aggregate',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holiday_file_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo_file_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_end_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_start_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timezone_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationDetailsQuery,
  GetOrganizationDetailsQueryVariables
>;
export const UpdateAddressDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAddressDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'address_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_address' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'addressID' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAddressDetailsMutation,
  UpdateAddressDetailsMutationVariables
>;
export const UpdateOrganizationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationID' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationDetailsMutation,
  UpdateOrganizationDetailsMutationVariables
>;
export const GetProjectsByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectsByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectTitle' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_billable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectTitle' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectsByOrganizationIdQuery,
  GetProjectsByOrganizationIdQueryVariables
>;
export const GetClientsByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientsByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'clientName' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_client_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'clientName' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientsByOrganizationIdQuery,
  GetClientsByOrganizationIdQueryVariables
>;
export const CreateProjectByAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjectByAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClientInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'project' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectByAdminMutation,
  CreateProjectByAdminMutationVariables
>;
export const CreateClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_client_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_client_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClientMutation,
  CreateClientMutationVariables
>;
export const UpdateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const DeleteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const UpdateClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_client_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project_client_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const DeleteClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_client_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;
export const DeleteClientSoftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteClientSoft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project_client_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClientSoftMutation,
  DeleteClientSoftMutationVariables
>;
export const CreateTriningTypeOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTriningTypeOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'training_type_for_training_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTriningTypeOrgAdminMutation,
  CreateTriningTypeOrgAdminMutationVariables
>;
export const GetTrainingTypeOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTrainingTypeOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trainingType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'trainingType' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'meta_tags' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'training_type_for_training_aggregate',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTrainingTypeOrgAdminQuery,
  GetTrainingTypeOrgAdminQueryVariables
>;
export const DeleteTrainingTypeOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTrainingTypeOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTrainingTypeOrgAdminMutation,
  DeleteTrainingTypeOrgAdminMutationVariables
>;
export const EditTrainingTypeOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editTrainingTypeOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_training_type_for_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditTrainingTypeOrgAdminMutation,
  EditTrainingTypeOrgAdminMutationVariables
>;
export const GetOrganizationByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owned_by' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationByUserIdQuery,
  GetOrganizationByUserIdQueryVariables
>;
export const InsertStorageFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertStorageFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'storage_files_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_storage_files_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertStorageFileMutation,
  InsertStorageFileMutationVariables
>;
export const SuperAdminTableDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'superAdminTableData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address_line' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'organization_employees_aggregate',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_admins' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_owner' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuperAdminTableDataSubscription,
  SuperAdminTableDataSubscriptionVariables
>;
export const CreateAccountRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAccountRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'auth_account_roles_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_auth_account_roles_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'account_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAccountRoleMutation,
  CreateAccountRoleMutationVariables
>;
export const DeleteAccountRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAccountRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_auth_account_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'account_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'account_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'role' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAccountRoleMutation,
  DeleteAccountRoleMutationVariables
>;
export const UpdateAccountByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccountByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'auth_accounts_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_auth_accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccountByUserIdMutation,
  UpdateAccountByUserIdMutationVariables
>;
export const GetAccountByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAccountByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth_accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'new_email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'password_hash' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'default_role' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_anonymous' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'custom_register_data' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'otp_secret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfa_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticket' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ticket_expires_at' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountByUserIdQuery,
  GetAccountByUserIdQueryVariables
>;
export const GetAccountByTicketDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAccountByTicket' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ticket' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth_accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ticket' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ticket' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'new_email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'password_hash' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'default_role' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_anonymous' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'custom_register_data' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'otp_secret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfa_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticket' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ticket_expires_at' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountByTicketQuery,
  GetAccountByTicketQueryVariables
>;
export const CreateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'auth_accounts_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_auth_accounts_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'new_email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'password_hash' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'default_role' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_anonymous' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'custom_register_data' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'otp_secret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfa_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticket' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ticket_expires_at' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export const GetAccountIdByEmailPatternDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAccountIdByEmailPattern' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailPattern' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'citext' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth_accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'emailPattern' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountIdByEmailPatternQuery,
  GetAccountIdByEmailPatternQueryVariables
>;
export const CreateRefreshTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createRefreshToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'auth_refresh_tokens_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_auth_refresh_tokens_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'account_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expires_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refresh_token' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRefreshTokenMutation,
  CreateRefreshTokenMutationVariables
>;
export const DeleteRefreshTokenByAccountIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteRefreshTokenByAccountId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_auth_refresh_tokens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'account_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'accountId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRefreshTokenByAccountIdMutation,
  DeleteRefreshTokenByAccountIdMutationVariables
>;
export const DeleteRefreshTokenByTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteRefreshTokenByToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_auth_refresh_tokens_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refresh_token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refresh_token' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'account_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRefreshTokenByTokenMutation,
  DeleteRefreshTokenByTokenMutationVariables
>;
export const CreateNewAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'assessment_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewAssessmentMutation,
  CreateNewAssessmentMutationVariables
>;
export const UpdateNewAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateNewAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'assessment_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNewAssessmentMutation,
  UpdateNewAssessmentMutationVariables
>;
export const Load_Single_QuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'load_single_question' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Load_Single_QuestionQuery,
  Load_Single_QuestionQueryVariables
>;
export const Delete_Question_OptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_question_options' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_question_options' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'question_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_Question_OptionsMutation,
  Delete_Question_OptionsMutationVariables
>;
export const Insert_QuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insert_questions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'questions_insert_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_questions_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Insert_QuestionsMutation,
  Insert_QuestionsMutationVariables
>;
export const Insert_Question_OptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insert_question_options' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'question_options_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_question_options' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Insert_Question_OptionsMutation,
  Insert_Question_OptionsMutationVariables
>;
export const Update_QuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_question' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'questions_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_questions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_QuestionMutation,
  Update_QuestionMutationVariables
>;
export const LoadAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'type' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_role_details' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'roleId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommended_training' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passing_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active_till' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_records' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'distinct_on' },
                      value: { kind: 'EnumValue', value: 'user_id' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'skill_Assessment_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_Assessment_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_records_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'skill_Assessment_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_Assessment_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'columns' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'assessment_id',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadAssessmentQuery, LoadAssessmentQueryVariables>;
export const AssessmentMarksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assessmentMarks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssessmentMarksQuery,
  AssessmentMarksQueryVariables
>;
export const UserCompletedStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userCompletedStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessmentId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserCompletedStatusQuery,
  UserCompletedStatusQueryVariables
>;
export const UserCreatedDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userCreatedDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserCreatedDateQuery,
  UserCreatedDateQueryVariables
>;
export const GetAttendedRecordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAttendedRecord' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAttendedRecordQuery,
  GetAttendedRecordQueryVariables
>;
export const DeletePreviousRecordOfSkillAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePreviousRecordOfSkillAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePreviousRecordOfSkillAssessmentMutation,
  DeletePreviousRecordOfSkillAssessmentMutationVariables
>;
export const AttendAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'attendAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'assessment_record_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AttendAssessmentMutation,
  AttendAssessmentMutationVariables
>;
export const GetCorrectAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCorrectAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quesId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'question_options' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'question_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'quesId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'correct_answer' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCorrectAnswerQuery,
  GetCorrectAnswerQueryVariables
>;
export const LoadFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_feedback_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avg' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'feedback' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadFeedbackQuery, LoadFeedbackQueryVariables>;
export const LoadScoreCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadScoreCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'user_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'passing_score' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadScoreCardQuery, LoadScoreCardQueryVariables>;
export const LoadNoofTriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadNoofTries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadNoofTriesQuery, LoadNoofTriesQueryVariables>;
export const TotalScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'totalScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avg' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalScoreQuery, TotalScoreQueryVariables>;
export const GetRoleOfUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRoleOfUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profiles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRoleOfUserQuery, GetRoleOfUserQueryVariables>;
export const GetAllAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllAssessmentQuery,
  GetAllAssessmentQueryVariables
>;
export const GetAttendedAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAttendedAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'assessment_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAttendedAssessmentQuery,
  GetAttendedAssessmentQueryVariables
>;
export const GetMarksOfAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMarksOfAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avg' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMarksOfAssessmentQuery,
  GetMarksOfAssessmentQueryVariables
>;
export const GetRoleOfUserBulkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRoleOfUserBulk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profiles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_in' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRoleOfUserBulkQuery,
  GetRoleOfUserBulkQueryVariables
>;
export const GetAllAssessmentBulkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllAssessmentBulk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllAssessmentBulkQuery,
  GetAllAssessmentBulkQueryVariables
>;
export const GetAssesmentAttendedDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssesmentAttendedData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'assessment_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAssesmentAttendedDataQuery,
  GetAssesmentAttendedDataQueryVariables
>;
export const GetMarksOfAssessmentbulkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMarksOfAssessmentbulk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avg' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMarksOfAssessmentbulkQuery,
  GetMarksOfAssessmentbulkQueryVariables
>;
export const Individual_Graph_DataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'individual_graph_data' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_records_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'marks' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Individual_Graph_DataQuery,
  Individual_Graph_DataQueryVariables
>;
export const UpdateoldRecordDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateoldRecordData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateoldRecordDataMutation,
  UpdateoldRecordDataMutationVariables
>;
export const UpdateoldRecordDataOfSkillAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateoldRecordDataOfSkillAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateoldRecordDataOfSkillAssessmentMutation,
  UpdateoldRecordDataOfSkillAssessmentMutationVariables
>;
export const GetRoleOfUserIndividualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRoleOfUserIndividual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profiles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_in' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRoleOfUserIndividualQuery,
  GetRoleOfUserIndividualQueryVariables
>;
export const GetAllAssessmentIndividualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllAssessmentIndividual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllAssessmentIndividualQuery,
  GetAllAssessmentIndividualQueryVariables
>;
export const GetMarksOfAssessmentIndividualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMarksOfAssessmentIndividual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avg' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMarksOfAssessmentIndividualQuery,
  GetMarksOfAssessmentIndividualQueryVariables
>;
export const UserIndiviudalProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userIndiviudalProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserIndiviudalProfileQuery,
  UserIndiviudalProfileQueryVariables
>;
export const InsertAssessmentRoleDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertAssessmentRoleDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'assessment_role_details_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_assessment_role_details' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertAssessmentRoleDetailsMutation,
  InsertAssessmentRoleDetailsMutationVariables
>;
export const DeleteAssessmentRoleDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAssessmentRoleDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_assessment_role_details' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAssessmentRoleDetailsMutation,
  DeleteAssessmentRoleDetailsMutationVariables
>;
export const CreateOneSkillDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOneSkillDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'skills_detail_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_skills_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOneSkillDetailMutation,
  CreateOneSkillDetailMutationVariables
>;
export const Edit_SkillsDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'edit_SkillsDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'skills_detail_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_skills_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skills_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Edit_SkillsDetailsMutation,
  Edit_SkillsDetailsMutationVariables
>;
export const Delete_SkillDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_SkillDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_skills_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skills_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_SkillDetailMutation,
  Delete_SkillDetailMutationVariables
>;
export const CreateOneCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOneCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'main_skills_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOneCompetencyMutation,
  CreateOneCompetencyMutationVariables
>;
export const Edit_CompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'edit_competency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'main_skills_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Edit_CompetencyMutation,
  Edit_CompetencyMutationVariables
>;
export const CreateOneSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOneSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'sub_skills_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_sub_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOneSkillsMutation,
  CreateOneSkillsMutationVariables
>;
export const Delete_SkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_Skills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_sub_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'main_skills_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_SkillsMutation,
  Delete_SkillsMutationVariables
>;
export const CreateSkillInListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSkillInList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'list_Of_Skills_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_list_Of_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSkillInListMutation,
  CreateSkillInListMutationVariables
>;
export const FindSkillsInCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'findSkillsInCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_skills' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'list_Of_Skill' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_ilike' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: '_ilike',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindSkillsInCompetencyQuery,
  FindSkillsInCompetencyQueryVariables
>;
export const Edit_SkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'edit_Skill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_list_Of_Skills_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Edit_SkillMutation, Edit_SkillMutationVariables>;
export const SkillsUnderCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'skillsUnderCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills_details' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SkillsUnderCompetencyQuery,
  SkillsUnderCompetencyQueryVariables
>;
export const UsersComptAndSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'usersComptAndSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'competencyId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsersComptAndSkillsQuery,
  UsersComptAndSkillsQueryVariables
>;
export const CreateUserCompetencyAndSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserCompetencyAndSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'user_Competency_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserCompetencyAndSkillsMutation,
  CreateUserCompetencyAndSkillsMutationVariables
>;
export const EmployeesManagerIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employeesManagerId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployeesManagerIdQuery,
  EmployeesManagerIdQueryVariables
>;
export const CreateOneUserCompetencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOneUserCompetency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'user_Competency_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOneUserCompetencyMutation,
  CreateOneUserCompetencyMutationVariables
>;
export const UserSkillDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userSkillDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Competency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'userId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skill_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills_details' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'main_skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skills_Assessments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'passing_score' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'assessment_records',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'user_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_eq',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'latest_record',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: true,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'total_marks',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skills_Trainings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'training' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserSkillDetailQuery,
  UserSkillDetailQueryVariables
>;
export const UserDetailsWithProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userDetailsWithProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserDetailsWithProjectQuery,
  UserDetailsWithProjectQueryVariables
>;
export const UserSkillDetailStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userSkillDetailStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skills' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'main_skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skills' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'list_Of_Skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'skills_details',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'rating',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'user_Skills_Assessments',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assessment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_Skills_Trainings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'training' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'storage_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metadata',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_progress_records',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'where' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'user_id',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_eq',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'skill_training_Status',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'BooleanValue',
                                                      value: true,
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'docData',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'progress',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_feedbacks_aggregate',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'aggregate',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'sum',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'feedback',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'count',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'user',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'display_name',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'profile',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'activity_status',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'avatar_file',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'file_url',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserSkillDetailStatusQuery,
  UserSkillDetailStatusQueryVariables
>;
export const ListOfUserWithSkillUnderComptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfUserWithSkillUnderCompt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Competencies' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_Skills' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_ilike' },
                                        value: {
                                          kind: 'StringValue',
                                          value: 'Approved',
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_Skills' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_ilike' },
                                        value: {
                                          kind: 'StringValue',
                                          value: 'Approved',
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rating' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill_Id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sub_skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'list_Of_Skill',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfUserWithSkillUnderComptQuery,
  ListOfUserWithSkillUnderComptQueryVariables
>;
export const ListOfSkillsUnderComptInManagerDashbaordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListOfSkillsUnderComptInManagerDashbaord' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfSkillsUnderComptInManagerDashbaordQuery,
  ListOfSkillsUnderComptInManagerDashbaordQueryVariables
>;
export const ListOfEmployeesUnderOrgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOfEmployeesUnderOrg' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_role_id',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListOfEmployeesUnderOrgQuery,
  ListOfEmployeesUnderOrgQueryVariables
>;
export const UserDetailOfProjectAndTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userDetailOfProjectAndTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_members' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_members' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'release_date' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'COMPLETED',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserDetailOfProjectAndTasksQuery,
  UserDetailOfProjectAndTasksQueryVariables
>;
export const GetUserSkillDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserSkillDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'competencyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skills' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'Approved',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'competencyId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skills' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'Approved',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_Id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserSkillDetailQuery,
  GetUserSkillDetailQueryVariables
>;
export const GetUserPerformanceReviewMarksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserPerformanceReviewMarks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUsers',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_byUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_at' },
                            value: { kind: 'EnumValue', value: 'desc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'peer_Id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_byUser_WithGoals',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserPerformanceReviewMarksQuery,
  GetUserPerformanceReviewMarksQueryVariables
>;
export const UserManagerMarksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userManagerMarks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserManagerMarksQuery,
  UserManagerMarksQueryVariables
>;
export const AssessmentCountByRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assessmentCountByRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_role_details_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'passing_score' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssessmentCountByRolesQuery,
  AssessmentCountByRolesQueryVariables
>;
export const TrainingCountByRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'trainingCountByRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_role_details_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'training' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrainingCountByRolesQuery,
  TrainingCountByRolesQueryVariables
>;
export const Get_UserDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_userDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UserDetailsQuery,
  Get_UserDetailsQueryVariables
>;
export const Get_UserSkillApprovedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_userSkillApproved' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skills' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'Approved',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'managerId' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: '_eq' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'userByManagerid',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'my_organization',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'organization',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'owned_by',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: '_eq',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skills_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'Approved',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UserSkillApprovedQuery,
  Get_UserSkillApprovedQueryVariables
>;
export const Get_UserSkillPendingToApproveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_userSkillPendingToApprove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skills' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_nlike' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'Approved',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'managerId' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: '_eq' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'userByManagerid',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'my_organization',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'organization',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'owned_by',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: '_eq',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skills_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_nlike' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'Approved',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UserSkillPendingToApproveQuery,
  Get_UserSkillPendingToApproveQueryVariables
>;
export const UpdateNewAssessmentSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateNewAssessmentSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'assessment_Skills_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_assessment_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNewAssessmentSkillsMutation,
  UpdateNewAssessmentSkillsMutationVariables
>;
export const DeleteAssessmentSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAssessmentSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_assessment_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAssessmentSkillsMutation,
  DeleteAssessmentSkillsMutationVariables
>;
export const UpdateNewTrainingSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateNewTrainingSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'training_Skill_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_training_Skill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNewTrainingSkillsMutation,
  UpdateNewTrainingSkillsMutationVariables
>;
export const DeleteTrainingSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTrainingSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_Skill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTrainingSkillsMutation,
  DeleteTrainingSkillsMutationVariables
>;
export const Get_SkillIdAndNameFrmSubSkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_SkillIdAndNameFrmSubSkill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list_Of_Skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessment_Skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assessment_Id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'training_Skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'training_Id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_SkillIdAndNameFrmSubSkillQuery,
  Get_SkillIdAndNameFrmSubSkillQueryVariables
>;
export const CreateUserSkillAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserSkillAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'user_Skills_Assessment_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_Skills_Assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserSkillAssessmentMutation,
  CreateUserSkillAssessmentMutationVariables
>;
export const Get_UserSkill_IdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_UserSkill_Ids' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skills' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'skill_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skills' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'skill_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UserSkill_IdsQuery,
  Get_UserSkill_IdsQueryVariables
>;
export const CreateUserSkillTrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserSkillTraining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'user_Skills_Training_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_Skills_Training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserSkillTrainingMutation,
  CreateUserSkillTrainingMutationVariables
>;
export const Update_UserSkillAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_UserSkillAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'Status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_Skills_Assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'Status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'Status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_UserSkillAssessmentMutation,
  Update_UserSkillAssessmentMutationVariables
>;
export const Update_UserSkillTrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_UserSkillTraining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_Skills_Training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_UserSkillTrainingMutation,
  Update_UserSkillTrainingMutationVariables
>;
export const CreateSkillsForAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSkillsForAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'assessment_Skills_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_assessment_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSkillsForAssessmentMutation,
  CreateSkillsForAssessmentMutationVariables
>;
export const Get_PassingScoreOfAssessmentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_PassingScoreOfAssessmentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passing_score' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_PassingScoreOfAssessmentIdQuery,
  Get_PassingScoreOfAssessmentIdQueryVariables
>;
export const Get_TrainingDurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_TrainingDuration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_TrainingDurationQuery,
  Get_TrainingDurationQueryVariables
>;
export const Get_AssessmentCountAttendByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_AssessmentCountAttendByUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_records_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'latest_record' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'skill_Assessment_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_Assessment_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'distinct_on' },
                      value: { kind: 'EnumValue', value: 'assessment_id' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_AssessmentCountAttendByUserQuery,
  Get_AssessmentCountAttendByUserQueryVariables
>;
export const Get_UserWithComptIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_UserWithComptId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Competency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'competencyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UserWithComptIdQuery,
  Get_UserWithComptIdQueryVariables
>;
export const Get_UserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_UserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default_role' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_UserRoleQuery, Get_UserRoleQueryVariables>;
export const GetUsersRatingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUsersRating' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Competencies' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'competencyId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'userId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_Skills' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'user_Competency',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'competencyId',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_eq1',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'userId' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_eq',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'StringValue',
                                          value: 'Approved',
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rating' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersRatingQuery, GetUsersRatingQueryVariables>;
export const GetSkillCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSkillCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main_skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSkillCountQuery, GetSkillCountQueryVariables>;
export const Get_LatestAssessmentRecordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_LatestAssessmentRecord' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq3' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq2' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq3' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question_options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'given_answer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'correct_answer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'passing_score' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_LatestAssessmentRecordQuery,
  Get_LatestAssessmentRecordQueryVariables
>;
export const Create_CareerDevelopmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_CareerDevelopmentPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'career_development_detail_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_career_development_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Create_CareerDevelopmentPlanMutation,
  Create_CareerDevelopmentPlanMutationVariables
>;
export const Update_CareerDevelopmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_CareerDevelopmentPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'career_development_detail_set_input',
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_career_development_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_CareerDevelopmentPlanMutation,
  Update_CareerDevelopmentPlanMutationVariables
>;
export const Delete_ExistingRole_CareerDevelopmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_ExistingRole_CareerDevelopment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_career_development_role_details',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_plan_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_ExistingRole_CareerDevelopmentMutation,
  Delete_ExistingRole_CareerDevelopmentMutationVariables
>;
export const Delete_StageCareerDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_StageCareerDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_stage_career' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stage_career_detail' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_development_detail_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_assessment_stage_career' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stage_career_detail' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_development_detail_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_stage_career_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_StageCareerDetailsMutation,
  Delete_StageCareerDetailsMutationVariables
>;
export const Create_Career_Development_Role_DetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_Career_Development_Role_Details' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'career_development_role_details_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_career_development_role_details',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Create_Career_Development_Role_DetailsMutation,
  Create_Career_Development_Role_DetailsMutationVariables
>;
export const Create_Stage_Career_DevelopmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_Stage_Career_development' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'stage_career_detail_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_stage_career_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Create_Stage_Career_DevelopmentMutation,
  Create_Stage_Career_DevelopmentMutationVariables
>;
export const Add_User_To_Career_Dev_PlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'add_user_to_career_dev_plan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'assign_career_development_plan_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_assign_career_development_plan',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Add_User_To_Career_Dev_PlanMutation,
  Add_User_To_Career_Dev_PlanMutationVariables
>;
export const Check_User_AssignedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_user_assigned' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_development_plan_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assign_career_development_plan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_plan_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_User_AssignedQuery,
  Check_User_AssignedQueryVariables
>;
export const Get_Users_Career_Development_DetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_users_career_development_detail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assign_career_development_plan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'career_development_detail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'user_career_development_records',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'userId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'progress' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stage_career_details' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'order_line' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stage_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order_line' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'training_stage_careers',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'order_by' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'order_line',
                                        },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'asc',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_id',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'order_line' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'assessment_stage_careers',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'order_by' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'order_line',
                                        },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'asc',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_id',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'order_line' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'assessment_stage_careers_aggregate',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'training_stage_careers_aggregate',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Users_Career_Development_DetailQuery,
  Get_Users_Career_Development_DetailQueryVariables
>;
export const User_Completed_Career_DevelopmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user_completed_career_development' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_development_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assign_career_development_plan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'Completed',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  User_Completed_Career_DevelopmentQuery,
  User_Completed_Career_DevelopmentQueryVariables
>;
export const Get_Stage_InfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_stage_info' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_development_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stage_career_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'stage_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_Stage_InfoQuery, Get_Stage_InfoQueryVariables>;
export const Get_Career_Development_DetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_career_development_detail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_development_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'career_development_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'assign_career_development_plans',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cover_image_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'career_external_certifications',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_development_detail_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'career_development_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'external_certification_name',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'user_career_development_records',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'progress' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stage_career_details' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_development_detail_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'career_development_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'order_line' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stage_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order_line' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'training_stage_careers' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'order_line' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'training_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order_line' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'assessment_stage_careers',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'order_line' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assessment_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order_line' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'assessment_stage_careers_aggregate',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'training_stage_careers_aggregate',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Career_Development_DetailQuery,
  Get_Career_Development_DetailQueryVariables
>;
export const User_Career_Training_ProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user_career_training_progress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'training_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_progress_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'training_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_training_Status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_training_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'docData' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  User_Career_Training_ProgressQuery,
  User_Career_Training_ProgressQueryVariables
>;
export const User_Career_Assessment_ProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user_career_assessment_progress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessment_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_marks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'passing_score' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  User_Career_Assessment_ProgressQuery,
  User_Career_Assessment_ProgressQueryVariables
>;
export const Update_User_Career_Development_RecordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_user_career_development_record' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_development_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'user_career_development_record_set_input',
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_user_career_development_record',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_development_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'career_development_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_User_Career_Development_RecordMutation,
  Update_User_Career_Development_RecordMutationVariables
>;
export const Update_Old_Record_Data_Of_Career_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'update_old_record_data_of_career_assessment',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessment_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_Old_Record_Data_Of_Career_AssessmentMutation,
  Update_Old_Record_Data_Of_Career_AssessmentMutationVariables
>;
export const Delete_Previous_Record_Of_Career_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'delete_previous_record_of_career_assessment',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessment_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_Previous_Record_Of_Career_AssessmentMutation,
  Delete_Previous_Record_Of_Career_AssessmentMutationVariables
>;
export const Get_List_Of_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_list_of_assessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_Skills' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'list_Of_Skill' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'sub_skills' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'id' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'skillId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_List_Of_AssessmentQuery,
  Get_List_Of_AssessmentQueryVariables
>;
export const Get_List_Of_User_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_list_of_user_assessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills_Assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skill' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'skill_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'skillId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_Id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_List_Of_User_AssessmentQuery,
  Get_List_Of_User_AssessmentQueryVariables
>;
export const Get_List_Of_User_Selected_SkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_list_of_user_selected_skills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Competency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'userId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skill_Id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_List_Of_User_Selected_SkillsQuery,
  Get_List_Of_User_Selected_SkillsQueryVariables
>;
export const Add_User_Skills_AssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'add_user_skills_assessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'user_Skills_Assessment_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_Skills_Assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Add_User_Skills_AssessmentMutation,
  Add_User_Skills_AssessmentMutationVariables
>;
export const Get_List_Of_TrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_list_of_training' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_Skills' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'list_Of_Skill' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'sub_skills' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'id' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'skillId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_List_Of_TrainingQuery,
  Get_List_Of_TrainingQueryVariables
>;
export const Get_List_Of_User_TrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_list_of_user_training' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills_Training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skill' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'skill_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'skillId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'training_Id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_List_Of_User_TrainingQuery,
  Get_List_Of_User_TrainingQueryVariables
>;
export const Add_User_Skills_TrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'add_user_skills_training' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'user_Skills_Training_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_Skills_Training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Add_User_Skills_TrainingMutation,
  Add_User_Skills_TrainingMutationVariables
>;
export const Get_Career_Training_DetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_career_training_details' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'training_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'training_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Career_Training_DetailsQuery,
  Get_Career_Training_DetailsQueryVariables
>;
export const Get_Career_Assessment_DetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_career_assessment_details' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passing_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_Skills' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assessment_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_id',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'list_Of_Skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Career_Assessment_DetailsQuery,
  Get_Career_Assessment_DetailsQueryVariables
>;
export const Create_User_Career_Development_RecordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_user_career_development_record' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'user_career_development_record_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_user_career_development_record',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Create_User_Career_Development_RecordMutation,
  Create_User_Career_Development_RecordMutationVariables
>;
export const Delete_External_CertificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_external_certification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_career_external_certification',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'career_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_External_CertificationMutation,
  Delete_External_CertificationMutationVariables
>;
export const Create_External_CertificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_external_certifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'career_external_certification_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_career_external_certification',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Create_External_CertificationsMutation,
  Create_External_CertificationsMutationVariables
>;
export const Get_Users_With_Completed_External_CertificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'get_users_with_completed_external_certifications',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_career_external_certification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_external_certification_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'career_external_certification_id',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Users_With_Completed_External_CertificationsQuery,
  Get_Users_With_Completed_External_CertificationsQueryVariables
>;
export const List_Of_SkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_skills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list_Of_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<List_Of_SkillsQuery, List_Of_SkillsQueryVariables>;
export const Deviated_SkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'deviated_skills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skill_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'skill_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: { kind: 'IntValue', value: '4' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Competency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_role',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Deviated_SkillsQuery,
  Deviated_SkillsQueryVariables
>;
export const Skilled_SkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'skilled_skills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skill_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'skill_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: { kind: 'IntValue', value: '4' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Competency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'organization_role',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Skilled_SkillsQuery, Skilled_SkillsQueryVariables>;
export const User_CountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user_count' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skill_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'skill_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<User_CountQuery, User_CountQueryVariables>;
export const User_Training_SkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user_training_skill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skill_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'training_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills_Training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skill' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'skill_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'skill_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'training_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'training' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_Competency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  User_Training_SkillQuery,
  User_Training_SkillQueryVariables
>;
export const User_Assessment_SkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user_assessment_skills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skill_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills_Assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skill' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'skill_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'skill_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'Status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_Competency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  User_Assessment_SkillsQuery,
  User_Assessment_SkillsQueryVariables
>;
export const Get_List_Of_Assessment_SkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_list_of_assessment_skill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skill_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_Skills_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'list_Of_Skill' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'sub_skills' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'skill_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_List_Of_Assessment_SkillQuery,
  Get_List_Of_Assessment_SkillQueryVariables
>;
export const Get_List_Of_Training_SkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_list_of_training_skill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skill_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_Skill_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'list_Of_Skill' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'sub_skills' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'skill_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'training' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_List_Of_Training_SkillQuery,
  Get_List_Of_Training_SkillQueryVariables
>;
export const List_Of_Users_In_OrgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_users_in_org' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'my_organization' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Users_In_OrgQuery,
  List_Of_Users_In_OrgQueryVariables
>;
export const Get_Count_Of_Deviated_SkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_count_of_deviated_skill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: { kind: 'IntValue', value: '4' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Competency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'userId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'skill_Id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Count_Of_Deviated_SkillQuery,
  Get_Count_Of_Deviated_SkillQueryVariables
>;
export const List_Of_Skills_For_UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_skills_for_user' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Competency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'userId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'skill_Id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'list_Of_Skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Skills_For_UserQuery,
  List_Of_Skills_For_UserQueryVariables
>;
export const Get_Training_StatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_training_status' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'training_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'training_feedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'training_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Training_StatusQuery,
  Get_Training_StatusQueryVariables
>;
export const Get_Assessment_DetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_assessment_detail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessment_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_Assessment_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total_marks' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Assessment_DetailQuery,
  Get_Assessment_DetailQueryVariables
>;
export const Career_Development_ProgrammDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'career_development_programm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'user_career_development_record_aggregate',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'career_development_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'career_development_detail',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stage_career_details',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stage_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_stage_careers',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'order_by',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'created_at',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'asc',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'training_id',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assessment_stage_careers',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'order_by',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'created_at',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'asc',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'assessment_id',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'assessment_stage_careers_aggregate',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'aggregate',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'count',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'training_stage_careers_aggregate',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'aggregate',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'count',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Career_Development_ProgrammQuery,
  Career_Development_ProgrammQueryVariables
>;
export const Career_Development_Plan_DetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'career_development_plan_detail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'career_development_detail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'career_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stage_career_details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stage_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Career_Development_Plan_DetailQuery,
  Career_Development_Plan_DetailQueryVariables
>;
export const Not_Completed_Assessment_UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'not_completed_assessment_users' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skill_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_Skills_Assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Skill' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'skill_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'skill_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'Status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assessment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_Skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_Competency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Not_Completed_Assessment_UsersQuery,
  Not_Completed_Assessment_UsersQueryVariables
>;
export const Get_OrgAdmin_Role_IdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_orgAdmin_role_id' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'Organization Admin',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_OrgAdmin_Role_IdQuery,
  Get_OrgAdmin_Role_IdQueryVariables
>;
export const Update_Assign_Career_Journey_Of_An_UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_assign_career_journey_of_an_user' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'career_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_assign_career_development_plan',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'career_development_detail_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'career_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_Assign_Career_Journey_Of_An_UserMutation,
  Update_Assign_Career_Journey_Of_An_UserMutationVariables
>;
export const List_Of_Skills_OrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_skills_orgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list_Of_Skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Skills_OrgIdQuery,
  List_Of_Skills_OrgIdQueryVariables
>;
export const List_Of_Users_By_RoleIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_users_by_roleId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profile' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'organization_role_id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'role_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Users_By_RoleIdQuery,
  List_Of_Users_By_RoleIdQueryVariables
>;
export const List_Of_Users_In_Org_CountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_users_in_org_count' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'my_organization' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Users_In_Org_CountQuery,
  List_Of_Users_In_Org_CountQueryVariables
>;
export const Get_OrgAdmin_User_IdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_orgAdmin_user_id' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_OrgAdmin_User_IdQuery,
  Get_OrgAdmin_User_IdQueryVariables
>;
export const GetUserAvatarByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserAvatarByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_avatar_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'player_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'player_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserAvatarByUserIdQuery,
  GetUserAvatarByUserIdQueryVariables
>;
export const GetAvatarFileInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAvatarFileInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatar_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_avatar_files_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'avatar_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'avatar_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatar_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar_image_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvatarFileInfoQuery,
  GetAvatarFileInfoQueryVariables
>;
export const InsertAvatarPlayerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertAvatarPlayer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_avatar_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_avatar_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: { kind: 'EnumValue', value: 'user_avatar_pkey' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'player_id' },
                          { kind: 'EnumValue', value: 'player_url' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'player_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertAvatarPlayerMutation,
  InsertAvatarPlayerMutationVariables
>;
export const InsertAvatarForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertAvatarForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_avatar_files_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_avatar_files_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'user_avatar_files_user_id_type_key',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'avatar_id' },
                          { kind: 'EnumValue', value: 'avatar_image_id' },
                          { kind: 'EnumValue', value: 'avatar_url' },
                          { kind: 'EnumValue', value: 'file_url' },
                          { kind: 'EnumValue', value: 'status' },
                          { kind: 'EnumValue', value: 'created_at' },
                          { kind: 'EnumValue', value: 'updated_at' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatar_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertAvatarForUserMutation,
  InsertAvatarForUserMutationVariables
>;
export const UpdateAvatarForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAvatarForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatar_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_avatar_files_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_avatar_files_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'avatar_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'avatar_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatar_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAvatarForUserMutation,
  UpdateAvatarForUserMutationVariables
>;
export const InsertAwardTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertAwardType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'awards_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_awards_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertAwardTypeMutation,
  InsertAwardTypeMutationVariables
>;
export const GetOrgAwardsByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgAwardsByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager_permission' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'award_types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrgAwardsByOrgIdQuery,
  GetOrgAwardsByOrgIdQueryVariables
>;
export const InsertAwardPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertAwardPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'award_posts_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_award_posts_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'period_description' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertAwardPostMutation,
  InsertAwardPostMutationVariables
>;
export const UpdateAwardPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAwardPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'award_posts_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_award_posts_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAwardPostMutation,
  UpdateAwardPostMutationVariables
>;
export const UpdateAwardsTypeByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAwardsTypeById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'awardId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'awards_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_awards_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'awardId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAwardsTypeByIdMutation,
  UpdateAwardsTypeByIdMutationVariables
>;
export const GetAwardsTypeDetailByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAwardsTypeDetailById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'awardId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'awardId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAwardsTypeDetailByIdQuery,
  GetAwardsTypeDetailByIdQueryVariables
>;
export const GetTodayAnniverseryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTodayAnniversery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'additional_fields' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_has_key' },
                            value: {
                              kind: 'StringValue',
                              value: 'married_anniversary',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pronouns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'about_me' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'date_of_birth' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invited_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'path' },
                      value: {
                        kind: 'StringValue',
                        value: 'married_anniversary',
                        block: false,
                      },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'agora_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_role_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar_file_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_completed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTodayAnniverseryQuery,
  GetTodayAnniverseryQueryVariables
>;
export const BackendGetAllTheUsersDateOfJoiningByConditionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'backendGetAllTheUsersDateOfJoiningByCondition',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'additional_fields' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_has_key' },
                            value: {
                              kind: 'StringValue',
                              value: 'date_of_joining',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pronouns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'about_me' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'date_of_birth' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invited_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address_id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'date_of_joining' },
                  name: { kind: 'Name', value: 'additional_fields' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'path' },
                      value: {
                        kind: 'StringValue',
                        value: 'date_of_joining',
                        block: false,
                      },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'agora_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_role_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar_file_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_completed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owned_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_employees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BackendGetAllTheUsersDateOfJoiningByConditionQuery,
  BackendGetAllTheUsersDateOfJoiningByConditionQueryVariables
>;
export const GetTodayBirthdayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTodayBirthday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date_of_birth' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_regex' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pronouns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'about_me' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'date_of_birth' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invited_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'path' },
                      value: {
                        kind: 'StringValue',
                        value: 'married_anniversary',
                        block: false,
                      },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'agora_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_role_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar_file_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_completed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owned_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_employees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTodayBirthdayQuery,
  GetTodayBirthdayQueryVariables
>;
export const CreateCalendarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCalendar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'calendar_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_calendar_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'default_timezone' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCalendarMutation,
  CreateCalendarMutationVariables
>;
export const CreateCalendarEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCalendarEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'calendar_event_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_calendar_event_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calendar_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'all_day' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCalendarEventMutation,
  CreateCalendarEventMutationVariables
>;
export const CreateAttendeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAttendees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'calendar_attendee_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAttendeesMutation,
  CreateAttendeesMutationVariables
>;
export const DeleteCalendarAttendeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCalendarAttendees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_event_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCalendarAttendeesMutation,
  DeleteCalendarAttendeesMutationVariables
>;
export const DeleteAttendeeByPkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAttendeeByPk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_calendar_attendee_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAttendeeByPkMutation,
  DeleteAttendeeByPkMutationVariables
>;
export const DeleteCalendarEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCalendarEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_event_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_calendar_event_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'calendar_event_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;
export const DeleteCalendarDeleteEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCalendarDeleteEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendar_event_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_calendar_delete_events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCalendarDeleteEventsMutation,
  DeleteCalendarDeleteEventsMutationVariables
>;
export const CreateCalendarMeetingCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCalendarMeetingCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'calendar_meeting_call_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_calendar_meeting_call_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channel_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meet_status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCalendarMeetingCallMutation,
  CreateCalendarMeetingCallMutationVariables
>;
export const GetCalendarMeetingCallsByEventIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCalendarMeetingCallsByEventId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_call' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channel_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meet_status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalendarMeetingCallsByEventIdQuery,
  GetCalendarMeetingCallsByEventIdQueryVariables
>;
export const UpdateCalendarMeetingCallUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCalendarMeetingCallUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'calendar_meeting_call_user_bool_exp',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'calendar_meeting_call_user_set_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_calendar_meeting_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCalendarMeetingCallUserMutation,
  UpdateCalendarMeetingCallUserMutationVariables
>;
export const GetCalenderAttendeesByEventIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCalenderAttendeesByEventId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendarEventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'calendarEventId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attendee_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee_status' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'calendar_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_fields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalenderAttendeesByEventIdQuery,
  GetCalenderAttendeesByEventIdQueryVariables
>;
export const JoinCalendarMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'joinCalendarMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'calendar_meeting_call_user_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_calendar_meeting_call_user_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value:
                          'calendar_meeting_call_user_meeting_call_id_user_id_key',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'status' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_call_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_joined_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JoinCalendarMeetingMutation,
  JoinCalendarMeetingMutationVariables
>;
export const CreateCalendarCallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCalendarCallRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'calendar_meeting_call_request_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_calendar_meeting_call_request',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCalendarCallRequestMutation,
  CreateCalendarCallRequestMutationVariables
>;
export const UpdateCalendarCallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCalendarCallRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'calendar_meeting_call_request_set_input',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetingCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_calendar_meeting_call_request',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_meeting_call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meetingCallId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCalendarCallRequestMutation,
  UpdateCalendarCallRequestMutationVariables
>;
export const GetCalendarMeetingCallsByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCalendarMeetingCallsById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'meet_call' },
            name: { kind: 'Name', value: 'calendar_meeting_call_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channel_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meet_status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalendarMeetingCallsByIdQuery,
  GetCalendarMeetingCallsByIdQueryVariables
>;
export const GetCalenderEventByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCalenderEventById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_event_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rrule' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scheduler_rrule' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_meeting_calls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meetingSchedulars' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schedular_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'meeting_event_id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'all_day' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calendar_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCalenderEventByIdQuery,
  GetCalenderEventByIdQueryVariables
>;
export const GetAttendeesEmployeeCapacityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAttendeesEmployeeCapacity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attendeeIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employee_capacity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'employee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attendeeIds' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workload_hours' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employee_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAttendeesEmployeeCapacityQuery,
  GetAttendeesEmployeeCapacityQueryVariables
>;
export const GetAllRecurringEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllRecurringEvents' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_event' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'scheduler_rrule' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'null',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'rrule' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'null',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rrule' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scheduler_rrule' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendee_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_meeting_calls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meetingSchedulars' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schedular_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'meeting_event_id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'calendar_event_id',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'for_date' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'all_day' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calendar_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllRecurringEventsQuery,
  GetAllRecurringEventsQueryVariables
>;
export const UpdateCalenderEventByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCalenderEventById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'calendar_event_set_input' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_calendar_event' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eventId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rrule' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'all_day' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_fields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event_type' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCalenderEventByIdMutation,
  UpdateCalenderEventByIdMutationVariables
>;
export const CreateCalendarDeleteEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCalendarDeleteEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'calendar_delete_events_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_calendar_delete_events_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'excluded_date' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCalendarDeleteEventMutation,
  CreateCalendarDeleteEventMutationVariables
>;
export const GetMeetingCallDataByMeetingCallIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMeetingCallDataByMeetingCallId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetingCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_meeting_call' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meetingCallId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resource_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recording_link' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channel_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chat_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recording_status' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reacording_started_by' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'meet_status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_call_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'updated_at' },
                            value: { kind: 'EnumValue', value: 'desc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'call_joined_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scheduler_rrule' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rrule' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_attendees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attendee_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attendee_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attendee_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'room_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingCallDataByMeetingCallIdQuery,
  GetMeetingCallDataByMeetingCallIdQueryVariables
>;
export const BackendUpdateCalendarMeetingCallByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'backendUpdateCalendarMeetingCallById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_call_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'calendar_meeting_call_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_calendar_meeting_call' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meeting_call_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BackendUpdateCalendarMeetingCallByIdMutation,
  BackendUpdateCalendarMeetingCallByIdMutationVariables
>;
export const CreateCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'call_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_call_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCallMutation, CreateCallMutationVariables>;
export const CreateCallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCallRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'call_request_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_call_request_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCallRequestMutation,
  CreateCallRequestMutationVariables
>;
export const UpdateCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'call_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_call' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'callId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCallMutation, UpdateCallMutationVariables>;
export const UpdateCallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCallRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_call_request_status_type_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'call_request_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_call_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'callId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_request_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCallRequestMutation,
  UpdateCallRequestMutationVariables
>;
export const GetCallRequestsByCallIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCallRequestsByCallId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'requestStatus' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'enum_call_request_status_type_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'callId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_request_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'requestStatus' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'call_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_request_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'request_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCallRequestsByCallIdQuery,
  GetCallRequestsByCallIdQueryVariables
>;
export const GetCallRequestCountByCallIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCallRequestCountByCallId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call_request_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'callId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCallRequestCountByCallIdQuery,
  GetCallRequestCountByCallIdQueryVariables
>;
export const LeaveCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'leaveCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LeaveCallInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveCallMutation, LeaveCallMutationVariables>;
export const GetCallDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCallDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'callId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_broadcasting_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'call_status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'call_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'media_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chat_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'access_policy' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channel_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_resource_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'call_sid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'call_uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_recording_link' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'ACTIVE' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_users_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'ACTIVE' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_recording_link' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call_recording_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recording_started_by' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCallDetailsQuery, GetCallDetailsQueryVariables>;
export const CreateCallUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCallUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'call_user_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_call_user_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'call_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCallUsersMutation,
  CreateCallUsersMutationVariables
>;
export const CallUserUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'callUserUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'call_user_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'callId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CallUserUpdateMutation,
  CallUserUpdateMutationVariables
>;
export const CallUserUpdateWithoutUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'callUserUpdateWithoutUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'call_user_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'callId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CallUserUpdateWithoutUserIdMutation,
  CallUserUpdateWithoutUserIdMutationVariables
>;
export const GetCallUsersDetailsByCallIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCallUsersDetailsByCallId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_call_user_status_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'callId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'call_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCallUsersDetailsByCallIdQuery,
  GetCallUsersDetailsByCallIdQueryVariables
>;
export const CreateChatMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createChatMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_message_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_message_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateChatMessageMutation,
  CreateChatMessageMutationVariables
>;
export const CreateChatRoomUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createChatRoomUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'chat_room_user_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_room_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'chat_room_user_pkey',
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_room_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateChatRoomUsersMutation,
  CreateChatRoomUsersMutationVariables
>;
export const UpdateChatRoomUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateChatRoomUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_room_user_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'chat_room_user' },
            name: { kind: 'Name', value: 'update_chat_room_user_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'chatRoomId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChatRoomUserMutation,
  UpdateChatRoomUserMutationVariables
>;
export const DeleteChatRoomUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteChatRoomUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_chat_room_user_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chat_room_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'chatRoomId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChatRoomUserMutation,
  DeleteChatRoomUserMutationVariables
>;
export const DeleteChatRoomMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteChatRoomMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_chat_message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chatRoomId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat_room_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChatRoomMessagesMutation,
  DeleteChatRoomMessagesMutationVariables
>;
export const DeleteChatRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteChatRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_chat_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'chatRoomId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChatRoomMutation,
  DeleteChatRoomMutationVariables
>;
export const CreateChatRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createChatRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_room_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_room_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_type' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateChatRoomMutation,
  CreateChatRoomMutationVariables
>;
export const GetOneToOneChatRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOneToOneChatRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstUser' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'secondUser' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'chat_room_users',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'firstUser',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'chat_room_users',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'secondUser',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ONE_TO_ONE' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOneToOneChatRoomQuery,
  GetOneToOneChatRoomQueryVariables
>;
export const GetMeetingChatRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMeetingChatRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetingChatDetails' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'GROUP' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'metadata' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_contains' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'meetingChatDetails',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingChatRoomQuery,
  GetMeetingChatRoomQueryVariables
>;
export const GetGroupChatRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGroupChatRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'combinedUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'chat_room_bool_exp' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'combinedUsers' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'GROUP' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGroupChatRoomQuery,
  GetGroupChatRoomQueryVariables
>;
export const GetChatRoomByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatRoomById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'chat_room' },
            name: { kind: 'Name', value: 'chat_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_message_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notification_settings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_message_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'room_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managed_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatRoomByIdQuery,
  GetChatRoomByIdQueryVariables
>;
export const UpdateChatRoomByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateChatRoomById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_room_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_chat_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_type' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChatRoomByIdMutation,
  UpdateChatRoomByIdMutationVariables
>;
export const GetNewChatRoomManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getNewChatRoomManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chat_room_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chat_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chat_room_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNewChatRoomManagerQuery,
  GetNewChatRoomManagerQueryVariables
>;
export const GetChatRoomIdByMetaDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChatRoomIdByMetaData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'metaData' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'metadata' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_contains' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'metaData' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatRoomIdByMetaDataQuery,
  GetChatRoomIdByMetaDataQueryVariables
>;
export const BackendGetChatRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'backendGetChatRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_room_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_message_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notification_settings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_message_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'room_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managed_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BackendGetChatRoomQuery,
  BackendGetChatRoomQueryVariables
>;
export const BackendDeleteChatRoomByCalendarEventIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'backendDeleteChatRoomByCalendarEventId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_room_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_chat_room' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BackendDeleteChatRoomByCalendarEventIdMutation,
  BackendDeleteChatRoomByCalendarEventIdMutationVariables
>;
export const InsertChatEmoteReactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertChatEmoteReaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'chat_message_reactions_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_chat_message_reactions_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertChatEmoteReactionMutation,
  InsertChatEmoteReactionMutationVariables
>;
export const UpdateEmoteReactionByMsgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmoteReactionByMsgID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'chat_message_reactions_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_chat_message_reactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'messageId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reacted_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmoteReactionByMsgIdMutation,
  UpdateEmoteReactionByMsgIdMutationVariables
>;
export const CheckExistingReactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkExistingReaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message_reactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'messageId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reacted_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reaction_type' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckExistingReactionQuery,
  CheckExistingReactionQueryVariables
>;
export const GetDashboardRemindersByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDashboardRemindersByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_remainders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remainder_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDashboardRemindersByUserIdQuery,
  GetDashboardRemindersByUserIdQueryVariables
>;
export const InsertDashboardReminderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertDashboardReminder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'dashboard_remainders_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_dashboard_remainders_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remainder_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertDashboardReminderMutation,
  InsertDashboardReminderMutationVariables
>;
export const GetAllDashboardRemindersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllDashboardReminders' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_remainders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remainder_type' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllDashboardRemindersQuery,
  GetAllDashboardRemindersQueryVariables
>;
export const CheckExistingRemainderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkExistingRemainder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'remainderTypes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'enum_dashboard_remainder_type_enum',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_remainders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'remainder_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'remainderTypes' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remainder_type' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckExistingRemainderQuery,
  CheckExistingRemainderQueryVariables
>;
export const GetPerformanceReviewStartedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPerformanceReviewStarted' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'StringValue',
                              value: 'Resume',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerformanceReviewStartedQuery,
  GetPerformanceReviewStartedQueryVariables
>;
export const CheckPerformanceReviewStartedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkPerformanceReviewStarted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'StringValue',
                              value: 'Resume',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckPerformanceReviewStartedQuery,
  CheckPerformanceReviewStartedQueryVariables
>;
export const CheckPendingAssessmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkPendingAssessments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_role_details' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'roleId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_records' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckPendingAssessmentsQuery,
  CheckPendingAssessmentsQueryVariables
>;
export const GetAllKpiBanksByRoleIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllKpiBanksByRoleId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'roleId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllKpiBanksByRoleIdQuery,
  GetAllKpiBanksByRoleIdQueryVariables
>;
export const DeleteAllDashboardRemindersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAllDashboardReminders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_dashboard_remainders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAllDashboardRemindersMutation,
  DeleteAllDashboardRemindersMutationVariables
>;
export const InsertDocumentTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertDocumentToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'document_token_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_document_token_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'document_token_pkey',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'access_token' },
                          { kind: 'EnumValue', value: 'refresh_token' },
                          { kind: 'EnumValue', value: 'token_type' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'access_token' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertDocumentTokenMutation,
  InsertDocumentTokenMutationVariables
>;
export const DeleteDocumentTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDocumentToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token_type' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'enum_document_token_type_enum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_document_token' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'token_type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'token_type' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDocumentTokenMutation,
  DeleteDocumentTokenMutationVariables
>;
export const DocumentTokenByPkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'documentTokenByPk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document_token_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'access_token' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refresh_token' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DocumentTokenByPkQuery,
  DocumentTokenByPkQueryVariables
>;
export const LoadTimeSpentIndividualGraphsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadTimeSpentIndividualGraphs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard_billable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadTimeSpentIndividualGraphsQuery,
  LoadTimeSpentIndividualGraphsQueryVariables
>;
export const GetBillableProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBillableProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'org_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'AUTO_APPROVED' },
                                { kind: 'EnumValue', value: 'MANUAL_APPROVED' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_billable' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'project_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_client' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'client_name' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBillableProjectIdQuery,
  GetBillableProjectIdQueryVariables
>;
export const BilableProjectDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'bilableProjectData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_projectID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_userID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'my_organization',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'organization_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'orgId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_userID' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'AUTO_APPROVED',
                                      },
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_APPROVED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'is_billable' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_projectID' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'my_organization',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'organization_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'orgId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_userID' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'AUTO_APPROVED',
                                      },
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_APPROVED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'work_date' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'project_client',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'client_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BilableProjectDataQuery,
  BilableProjectDataQueryVariables
>;
export const NonBilableProjectDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'nonBilableProjectData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_time_sheets' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'my_organization',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'organization_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'orgId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'AUTO_APPROVED',
                                      },
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_APPROVED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'is_billable' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'work_date' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_time_sheets_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'my_organization',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'organization_id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'orgId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'EnumValue',
                                        value: 'AUTO_APPROVED',
                                      },
                                      {
                                        kind: 'EnumValue',
                                        value: 'MANUAL_APPROVED',
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'is_billable' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'work_date' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sum' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'work_hours' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'project_client',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'client_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NonBilableProjectDataQuery,
  NonBilableProjectDataQueryVariables
>;
export const UserNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserNameQuery, UserNameQueryVariables>;
export const LeaveDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'leaveData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leaveDates' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'date' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_approved' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaveDates_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'org_user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'date' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_lte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_lte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveDataQuery, LeaveDataQueryVariables>;
export const OrgHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'orgHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_holidays_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'date' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrgHolidayQuery, OrgHolidayQueryVariables>;
export const LoadUserBasedOnManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadUserBasedOnManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadUserBasedOnManagerQuery,
  LoadUserBasedOnManagerQueryVariables
>;
export const LoadUserBasedOnOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadUserBasedOnOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadUserBasedOnOrganizationQuery,
  LoadUserBasedOnOrganizationQueryVariables
>;
export const TaskCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'taskCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end_date_time' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskCountQuery, TaskCountQueryVariables>;
export const TaskTimelyCompletedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'taskTimelyCompleted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'COMPLETED' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TaskTimelyCompletedQuery,
  TaskTimelyCompletedQueryVariables
>;
export const TaskTimeDelayedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'taskTimeDelayed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'COMPLETED' },
                                { kind: 'EnumValue', value: 'IN_PROGRESS' },
                                { kind: 'EnumValue', value: 'OPEN' },
                                { kind: 'EnumValue', value: 'REOPENED' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_lte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TaskTimeDelayedQuery,
  TaskTimeDelayedQueryVariables
>;
export const GetAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_role_details' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment' },
                      value: { kind: 'ObjectValue', fields: [] },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'assessment_type_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessment_type_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAssessmentTypeQuery,
  GetAssessmentTypeQueryVariables
>;
export const GetAssessmentUnderTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssessmentUnderType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAssessmentUnderTypeQuery,
  GetAssessmentUnderTypeQueryVariables
>;
export const GetAssessmentRecordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssessmentRecord' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assessment_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAssessmentRecordQuery,
  GetAssessmentRecordQueryVariables
>;
export const UserInOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userInOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq2' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserInOrganizationQuery,
  UserInOrganizationQueryVariables
>;
export const GetAssessmentOnTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssessmentOnType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAssessmentOnTypeQuery,
  GetAssessmentOnTypeQueryVariables
>;
export const GetEmprecordDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmprecordData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmprecordDataQuery,
  GetEmprecordDataQueryVariables
>;
export const UserProfileDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userProfileData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserProfileDataQuery,
  UserProfileDataQueryVariables
>;
export const GetAssessmentOnTypeAndRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssessmentOnTypeAndRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_role_details' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAssessmentOnTypeAndRoleQuery,
  GetAssessmentOnTypeAndRoleQueryVariables
>;
export const GetIndividualAssessmentAttendedDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIndividualAssessmentAttendedData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assessment_type_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'EnumValue',
                              value: 'assessment_id',
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'distinct' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetIndividualAssessmentAttendedDataQuery,
  GetIndividualAssessmentAttendedDataQueryVariables
>;
export const ScoredDataOfAssessmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'scoredDataOfAssessment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment_record_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assessment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latest_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passing_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active_till' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScoredDataOfAssessmentQuery,
  ScoredDataOfAssessmentQueryVariables
>;
export const GetOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'system_role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrgAdminQuery, GetOrgAdminQueryVariables>;
export const StressGraphDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'stressGraphData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stress_data_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stress_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'stress_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'feedback' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stress_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stress_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'stress_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'feedback' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StressGraphDataQuery,
  StressGraphDataQueryVariables
>;
export const GetAllAssessmentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllAssessmentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assessment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'assessment_type_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessment_type_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllAssessmentTypeQuery,
  GetAllAssessmentTypeQueryVariables
>;
export const AllUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllUserQuery, AllUserQueryVariables>;
export const LoadTaskDetailsForEmployeeBandwidthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'loadTaskDetailsForEmployeeBandwidth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoadTaskDetailsForEmployeeBandwidthQuery,
  LoadTaskDetailsForEmployeeBandwidthQueryVariables
>;
export const AddEmployeeBandwidthDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addEmployeeBandwidthData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'employee_bandwidth_data_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_employee_bandwidth_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddEmployeeBandwidthDataMutation,
  AddEmployeeBandwidthDataMutationVariables
>;
export const GetEmployeeCapacityUserDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeeCapacityUserData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_bandwidth_data' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_day' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'work_day' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_gte' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_lte' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'work_day' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_hours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeCapacityUserDataQuery,
  GetEmployeeCapacityUserDataQueryVariables
>;
export const GetUserCalenderIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserCalenderID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserCalenderIdQuery,
  GetUserCalenderIdQueryVariables
>;
export const GetMeetingHoursDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMeetingHours' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_event_material_view' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_gte' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_lte' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingHoursQuery,
  GetMeetingHoursQueryVariables
>;
export const GetMeetingHoursForEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMeetingHoursForEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attendeeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDateTime' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDateTime' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar_attendee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attendee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attendeeId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'start_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'startDateTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'end_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'endDateTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end_date_time' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingHoursForEmployeeQuery,
  GetMeetingHoursForEmployeeQueryVariables
>;
export const InsertEmployeeCapacityBulkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertEmployeeCapacityBulk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'employee_capacity_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_employee_capacity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertEmployeeCapacityBulkMutation,
  InsertEmployeeCapacityBulkMutationVariables
>;
export const GetEmployeeLeaveApplicationFromUserIdStartDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'getEmployeeLeaveApplicationFromUserIdStartDate',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_start_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'start_date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeLeaveApplicationFromUserIdStartDateQuery,
  GetEmployeeLeaveApplicationFromUserIdStartDateQueryVariables
>;
export const AddLeaveDatesForOrgUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addLeaveDatesForOrgUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_user_leave_dates_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_organization_user_leave_dates_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddLeaveDatesForOrgUserMutation,
  AddLeaveDatesForOrgUserMutationVariables
>;
export const FindUserLeaveDatesByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'findUserLeaveDatesByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'conditions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_user_leave_dates_bool_exp',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_dates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'conditions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindUserLeaveDatesByUserIdQuery,
  FindUserLeaveDatesByUserIdQueryVariables
>;
export const GetEmployeeLeaveApplicationInfoByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeeLeaveApplicationInfoById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_start_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_end_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_amount_in_days' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_leave_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaveDates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_application_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_leave_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'used_leave_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'earned_leaves_amount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeLeaveApplicationInfoByIdQuery,
  GetEmployeeLeaveApplicationInfoByIdQueryVariables
>;
export const UpdateUserLeaveApplicationByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserLeaveApplicationById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_application_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application_set_input',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_organization_user_leave_application_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_application_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserLeaveApplicationByIdMutation,
  UpdateUserLeaveApplicationByIdMutationVariables
>;
export const AddNewEmployeeLeaveApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewEmployeeLeaveApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_user_leave_application_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_organization_user_leave_application_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewEmployeeLeaveApplicationMutation,
  AddNewEmployeeLeaveApplicationMutationVariables
>;
export const AddEmployeeLeaveApplicationFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addEmployeeLeaveApplicationFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_user_leave_files_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_organization_user_leave_files_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddEmployeeLeaveApplicationFilesMutation,
  AddEmployeeLeaveApplicationFilesMutationVariables
>;
export const GetUserLeaveApplicationByUserIdAndEndDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'getUserLeaveApplicationByUserIdAndEndDate',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_end_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'end_date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserLeaveApplicationByUserIdAndEndDateQuery,
  GetUserLeaveApplicationByUserIdAndEndDateQueryVariables
>;
export const GetPendingLeaveApplicationsByLeaveTypeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPendingLeaveApplicationsByLeaveTypeId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_type_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leave_type_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_approved' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application_status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PENDING' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPendingLeaveApplicationsByLeaveTypeIdQuery,
  GetPendingLeaveApplicationsByLeaveTypeIdQueryVariables
>;
export const UpdateUserLeaveApplicationByUserIdAndYearDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateUserLeaveApplicationByUserIdAndYear',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application_set_input',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'year' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserLeaveApplicationByUserIdAndYearMutation,
  UpdateUserLeaveApplicationByUserIdAndYearMutationVariables
>;
export const InsertNewLeaveTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertNewLeaveType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_active_leave_types_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_organization_active_leave_types_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertNewLeaveTypeMutation,
  InsertNewLeaveTypeMutationVariables
>;
export const GetOrganizationLeaveActiveLeaveTypesByOrgIdAndLeaveTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'getOrganizationLeaveActiveLeaveTypesByOrgIdAndLeaveType',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_type' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_active_leave_types' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leave_type' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLeaveActiveLeaveTypesByOrgIdAndLeaveTypeQuery,
  GetOrganizationLeaveActiveLeaveTypesByOrgIdAndLeaveTypeQueryVariables
>;
export const GetOrganizationLeaveTypesByLeaveIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLeaveTypesByLeaveId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_active_leave_types_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'leave_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'will_carry_forward' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'will_be_earned_monthly' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthly_earning_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annual_eligibility' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annual_carry_forward_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followed_leave_cycle' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLeaveTypesByLeaveIdQuery,
  GetOrganizationLeaveTypesByLeaveIdQueryVariables
>;
export const UpdateOrganizationLeaveTypeByLeaveTypeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationLeaveTypeByLeaveTypeId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_type_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_active_leave_types_set_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_organization_active_leave_types_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'will_carry_forward' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'will_be_earned_monthly' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthly_earning_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annual_eligibility' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annual_carry_forward_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followed_leave_cycle' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationLeaveTypeByLeaveTypeIdMutation,
  UpdateOrganizationLeaveTypeByLeaveTypeIdMutationVariables
>;
export const DeleteLeaveTypeByLeaveTypeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteLeaveTypeByLeaveTypeId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_type_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_organization_user_leave_application',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leave_type_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_active' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_organization_user_leave_data',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leave_type_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_organization_active_leave_types_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLeaveTypeByLeaveTypeIdMutation,
  DeleteLeaveTypeByLeaveTypeIdMutationVariables
>;
export const InsertLeaveCycleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertLeaveCycle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_leave_cycles_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_organization_leave_cycles_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertLeaveCycleMutation,
  InsertLeaveCycleMutationVariables
>;
export const GetOrganizationLeaveCycleByOrgIdAndStartingDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'getOrganizationLeaveCycleByOrgIdAndStartingDate',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'starting_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_leave_cycles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'starting_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLeaveCycleByOrgIdAndStartingDateQuery,
  GetOrganizationLeaveCycleByOrgIdAndStartingDateQueryVariables
>;
export const GetAllTheOrganizationCyclesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllTheOrganizationCycles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_leave_cycles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'starting_date' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ending_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_term_years' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_month_date' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllTheOrganizationCyclesQuery,
  GetAllTheOrganizationCyclesQueryVariables
>;
export const UpdateOrganizationLeaveCyclesByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationLeaveCyclesById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_cycle_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_leave_cycles_set_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_organization_leave_cycles_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_cycle_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'starting_date' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ending_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_term_years' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_month_date' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationLeaveCyclesByIdMutation,
  UpdateOrganizationLeaveCyclesByIdMutationVariables
>;
export const GetOrganizationLeaveCycleByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLeaveCycleByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_leave_cycles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'starting_date' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ending_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_term_years' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_month_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_start_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_end_date' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'organizations_active_leave_types',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLeaveCycleByOrgIdQuery,
  GetOrganizationLeaveCycleByOrgIdQueryVariables
>;
export const GetAllUsersOfAnOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllUsersOfAnOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_admins' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_id' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'userByUserId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'account' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'active' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: true,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userByUserId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'default_role',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_employees' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_id' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'account' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'active' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: true,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'default_role',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllUsersOfAnOrganizationQuery,
  GetAllUsersOfAnOrganizationQueryVariables
>;
export const AddUsersToOrganizationLeaveDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addUsersToOrganizationLeaveData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'organization_user_leave_data_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_organization_user_leave_data',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUsersToOrganizationLeaveDataMutation,
  AddUsersToOrganizationLeaveDataMutationVariables
>;
export const AddUserOrganizationLeaveDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addUserOrganizationLeaveData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_user_leave_data_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_organization_user_leave_data_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_cycle_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUserOrganizationLeaveDataMutation,
  AddUserOrganizationLeaveDataMutationVariables
>;
export const UpdateUserOrganizationLeaveDataByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserOrganizationLeaveDataById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_leave_data_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_user_leave_data_set_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_organization_user_leave_data_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_leave_data_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserOrganizationLeaveDataByIdMutation,
  UpdateUserOrganizationLeaveDataByIdMutationVariables
>;
export const DeleteLeaveApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteLeaveApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_organization_user_leave_dates',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_organization_user_leave_files',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_applicaton_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_organization_user_leave_application_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLeaveApplicationMutation,
  DeleteLeaveApplicationMutationVariables
>;
export const GetLeaveDatesByLeaveIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLeaveDatesByLeaveId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_dates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLeaveDatesByLeaveIdQuery,
  GetLeaveDatesByLeaveIdQueryVariables
>;
export const GetOrganizationLeaveDataByUserIdLeaveTypeIdOrdIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'getOrganizationLeaveDataByUserIdLeaveTypeIdOrdId',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_type_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leave_type_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLeaveDataByUserIdLeaveTypeIdOrdIdQuery,
  GetOrganizationLeaveDataByUserIdLeaveTypeIdOrdIdQueryVariables
>;
export const GetOrganizationUserLeaveDataByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationUserLeaveDataById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationUserLeaveDataByIdQuery,
  GetOrganizationUserLeaveDataByIdQueryVariables
>;
export const GetUserLeaveDataByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserLeaveDataByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'additional_fields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'organization_leave_cycle',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ending_date' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'current_term_years',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserLeaveDataByUserIdQuery,
  GetUserLeaveDataByUserIdQueryVariables
>;
export const GetAllLeaveDataByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllLeaveDataByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'organization_leave_cycle',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ending_date' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'current_term_years',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllLeaveDataByOrganizationIdQuery,
  GetAllLeaveDataByOrganizationIdQueryVariables
>;
export const GetAllLeaveDataOfAllOrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllLeaveDataOfAllOrganizations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'organization_leave_cycle',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ending_date' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'current_term_years',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllLeaveDataOfAllOrganizationsQuery,
  GetAllLeaveDataOfAllOrganizationsQueryVariables
>;
export const GetLeaveDataOfOrganizationByLeaveCycleIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLeaveDataOfOrganizationByLeaveCycleId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_cycle_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_cycle_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leave_cycle_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_year_carry_forward' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'organization_leave_cycle',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ending_date' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'current_term_years',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leaveDates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLeaveDataOfOrganizationByLeaveCycleIdQuery,
  GetLeaveDataOfOrganizationByLeaveCycleIdQueryVariables
>;
export const CreateLiveCallRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLiveCallRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'live_call_request_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_live_call_request_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'request_status' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLiveCallRequestMutation,
  CreateLiveCallRequestMutationVariables
>;
export const GetLiveCallRequestByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLiveCallRequestById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call_request_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveCallRequestByIdQuery,
  GetLiveCallRequestByIdQueryVariables
>;
export const UpdateLiveCallRequestByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLiveCallRequestById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'live_call_request_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_live_call_request_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLiveCallRequestByIdMutation,
  UpdateLiveCallRequestByIdMutationVariables
>;
export const GetLiveCallRequestByLiveCallIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLiveCallRequestByLiveCallId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveCallId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live_call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liveCallId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'request_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveCallRequestByLiveCallIdQuery,
  GetLiveCallRequestByLiveCallIdQueryVariables
>;
export const CreateLiveCallUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLiveCallUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'live_call_user_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_live_call_user_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLiveCallUserMutation,
  CreateLiveCallUserMutationVariables
>;
export const GetLiveCallActiveUsersCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLiveCallActiveUsersCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'callId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call_user_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live_call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'callId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ACTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveCallActiveUsersCountQuery,
  GetLiveCallActiveUsersCountQueryVariables
>;
export const UpdateLiveCallUserByCallIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLiveCallUserByCallId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'live_call_user_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_live_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live_call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liveCallId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLiveCallUserByCallIdMutation,
  UpdateLiveCallUserByCallIdMutationVariables
>;
export const UpdateLiveCallUsersByCallIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLiveCallUsersByCallId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'live_call_user_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_live_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live_call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liveCallId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLiveCallUsersByCallIdMutation,
  UpdateLiveCallUsersByCallIdMutationVariables
>;
export const GetLiveCallUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLiveCallUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveCallId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'live_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live_call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liveCallId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveCallUsersQuery,
  GetLiveCallUsersQueryVariables
>;
export const InsertLiveCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertLiveCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'live_call_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_live_call_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertLiveCallMutation,
  InsertLiveCallMutationVariables
>;
export const GetLiveCallByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLiveCallById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'live_call' },
            name: { kind: 'Name', value: 'live_call_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'live_call_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLiveCallByIdQuery,
  GetLiveCallByIdQueryVariables
>;
export const UpdateLiveCallByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLiveCallById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'live_call_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'live_call' },
            name: { kind: 'Name', value: 'update_live_call_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end_date_time' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLiveCallByIdMutation,
  UpdateLiveCallByIdMutationVariables
>;
export const AddSchedularForAMeetingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addSchedularForAMeetingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newSchedular' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'meeting_call_schedular_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_meeting_call_schedular_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newSchedular' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_event_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedular_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSchedularForAMeetingEventMutation,
  AddSchedularForAMeetingEventMutationVariables
>;
export const DeleteMeetingSchedularsForAMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMeetingSchedularsForAMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetingId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendarEventId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_meeting_call_schedular' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meetingId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'calendarEventId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMeetingSchedularsForAMeetingMutation,
  DeleteMeetingSchedularsForAMeetingMutationVariables
>;
export const DeleteMeetingSchedularsByConditionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMeetingSchedularsByCondition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'meeting_call_schedular_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_meeting_call_schedular' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMeetingSchedularsByConditionMutation,
  DeleteMeetingSchedularsByConditionMutationVariables
>;
export const DeleteMeetingSchedularByCalendarEventIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMeetingSchedularByCalendarEventId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendarEventId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_meeting_call_schedular' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'calendarEventId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMeetingSchedularByCalendarEventIdMutation,
  DeleteMeetingSchedularByCalendarEventIdMutationVariables
>;
export const GetAllTheSchedularForAMeetingByMeetingIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllTheSchedularForAMeetingByMeetingId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meetingId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendarEventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meeting_call_schedular' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meetingId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendar_event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'calendarEventId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar_event_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_event_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedular_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllTheSchedularForAMeetingByMeetingIdQuery,
  GetAllTheSchedularForAMeetingByMeetingIdQueryVariables
>;
export const DeleteMeetingCallByserByUserIdAndMeetingIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'deleteMeetingCallByserByUserIdAndMeetingId',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_call_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_calendar_meeting_call_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_call_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meeting_call_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMeetingCallByserByUserIdAndMeetingIdMutation,
  DeleteMeetingCallByserByUserIdAndMeetingIdMutationVariables
>;
export const DeleteMeetingRoomByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMeetingRoomById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_meeting_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMeetingRoomByIdMutation,
  DeleteMeetingRoomByIdMutationVariables
>;
export const GetMeetingRoomByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMeetingRoomById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'meeting_room' },
            name: { kind: 'Name', value: 'meeting_room_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'createdByUser' },
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_room_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingRoomByIdQuery,
  GetMeetingRoomByIdQueryVariables
>;
export const InsertMeetingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertMeetingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'meeting_room_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_meeting_room_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertMeetingRoomMutation,
  InsertMeetingRoomMutationVariables
>;
export const AddMeetingRoomUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addMeetingRoomUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'meeting_room_users_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_meeting_room_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'meeting_room_users_pkey',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'added_by' },
                          { kind: 'EnumValue', value: 'status' },
                          { kind: 'EnumValue', value: 'updated_at' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMeetingRoomUsersMutation,
  AddMeetingRoomUsersMutationVariables
>;
export const DeleteAMeetingRoomUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAMeetingRoomUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_room_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_meeting_room_users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meeting_room_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'meeting_room_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAMeetingRoomUserMutation,
  DeleteAMeetingRoomUserMutationVariables
>;
export const UpdateMeetingRoomUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMeetingRoomUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'meeting_room_users_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_room_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_meeting_room_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_room_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'meeting_room_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMeetingRoomUsersMutation,
  UpdateMeetingRoomUsersMutationVariables
>;
export const GetMeetingRoomUserByPkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMeetingRoomUserByPk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_room_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'meeting_room_user' },
            name: { kind: 'Name', value: 'meeting_room_users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meeting_room_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'meeting_room_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_room_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'added_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMeetingRoomUserByPkQuery,
  GetMeetingRoomUserByPkQueryVariables
>;
export const CreateNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'notifications_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_notifications_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;
export const DeleteNotificationByResourceIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteNotificationByResourceId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resource_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'resourceId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteNotificationByResourceIdMutation,
  DeleteNotificationByResourceIdMutationVariables
>;
export const GetDepartmentByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDepartmentById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'department' },
            name: { kind: 'Name', value: 'department_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepartmentByIdQuery,
  GetDepartmentByIdQueryVariables
>;
export const CreateDepartmentEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDepartmentEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'department_employee_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_department_employee_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDepartmentEmployeeMutation,
  CreateDepartmentEmployeeMutationVariables
>;
export const CreateDepartmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDepartment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'department_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_department_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables
>;
export const CreateOrganizationEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganizationEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_employee_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_employee_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganizationEmployeeMutation,
  CreateOrganizationEmployeeMutationVariables
>;
export const GetOrgEmployeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgEmployees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'additional_fields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrgEmployeesQuery,
  GetOrgEmployeesQueryVariables
>;
export const GetOrganizationEmployeesPaginatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationEmployeesPaginated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationEmployeesPaginatedQuery,
  GetOrganizationEmployeesPaginatedQueryVariables
>;
export const DeleteEmployeeCapacityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEmployeeCapacity' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_employee_capacity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'ObjectValue', fields: [] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEmployeeCapacityMutation,
  DeleteEmployeeCapacityMutationVariables
>;
export const GetOrganizationEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationEmployeeQuery,
  GetOrganizationEmployeeQueryVariables
>;
export const GetOrgEmployeeByPkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgEmployeeByPk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orgId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'manager_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrgEmployeeByPkQuery,
  GetOrgEmployeeByPkQueryVariables
>;
export const UpdateEmployeeRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmployeeRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_role_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmployeeRoleMutation,
  UpdateEmployeeRoleMutationVariables
>;
export const UpdateEmployeeManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmployeeManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_organization_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'managerId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmployeeManagerMutation,
  UpdateEmployeeManagerMutationVariables
>;
export const UpdateEmployeeDepartmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmployeeDepartment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'departmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_department_employee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'department_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'departmentId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmployeeDepartmentMutation,
  UpdateEmployeeDepartmentMutationVariables
>;
export const DeleteOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_organization_admin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrgAdminMutation,
  DeleteOrgAdminMutationVariables
>;
export const TransferOrgOwnerShipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'transferOrgOwnerShip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ownerState' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_organization_admin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'adminId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_owner' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ownerState' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransferOrgOwnerShipMutation,
  TransferOrgOwnerShipMutationVariables
>;
export const GetAllOrgAdminsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllOrgAdmins' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_admin' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllOrgAdminsQuery,
  GetAllOrgAdminsQueryVariables
>;
export const GetOrganizationAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_admin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_owner' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationAdminQuery,
  GetOrganizationAdminQueryVariables
>;
export const GetOrganizationAdminWhoIsOwnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationAdminWhoIsOwner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_admin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_owner' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_owner' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationAdminWhoIsOwnerQuery,
  GetOrganizationAdminWhoIsOwnerQueryVariables
>;
export const UpdateOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_admin_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_organization_admin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'adminId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrgAdminMutation,
  UpdateOrgAdminMutationVariables
>;
export const CreateOrganizationAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganizationAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_admin_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_admin_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_owner' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganizationAdminMutation,
  CreateOrganizationAdminMutationVariables
>;
export const GetOrganizationLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_locations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timezone_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationLocationQuery,
  GetOrganizationLocationQueryVariables
>;
export const DeleteOrganizationLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrganizationLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_organization_locations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrganizationLocationMutation,
  DeleteOrganizationLocationMutationVariables
>;
export const CreateOrganizationLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganizationLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'organization_locations_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_locations_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timezone_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganizationLocationMutation,
  CreateOrganizationLocationMutationVariables
>;
export const GetOrganizationRoleByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationRoleById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationRoleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'organization_roles' },
            name: { kind: 'Name', value: 'organization_roles_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'organizationRoleId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system_role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationRoleByIdQuery,
  GetOrganizationRoleByIdQueryVariables
>;
export const GetOrganizationRoleForOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationRoleForOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'org-admin',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationRoleForOrgAdminQuery,
  GetOrganizationRoleForOrgAdminQueryVariables
>;
export const GetProfileCountByOrgRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProfileCountByOrgRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProfileCountByOrgRoleQuery,
  GetProfileCountByOrgRoleQueryVariables
>;
export const DeleteRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const CreateOrganizationRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganizationRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_roles_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_roles_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganizationRoleMutation,
  CreateOrganizationRoleMutationVariables
>;
export const InsertOrganizationGetInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertOrganizationGetInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_modified_by' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'address_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertOrganizationGetInfoMutation,
  InsertOrganizationGetInfoMutationVariables
>;
export const UpdateOrgOwnedByDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrgOwnedBy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'prevUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'prevUserId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owned_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newUserId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrgOwnedByMutation,
  UpdateOrgOwnedByMutationVariables
>;
export const GetOrganizationByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timezone_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_fields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationByIdQuery,
  GetOrganizationByIdQueryVariables
>;
export const GetAllOrganizationIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllOrganizationIds' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllOrganizationIdsQuery,
  GetAllOrganizationIdsQueryVariables
>;
export const UpdateOrganizationByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationByIdMutation,
  UpdateOrganizationByIdMutationVariables
>;
export const InsertOrganizationHolidaysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertOrganizationHolidays' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'organization_holidays_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertOrganizationHolidaysMutation,
  InsertOrganizationHolidaysMutationVariables
>;
export const CreateNewKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewKPI' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'kpi_bank_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewKpiMutation,
  CreateNewKpiMutationVariables
>;
export const UpdateNewKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateNewKpi' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'kpi_bank_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNewKpiMutation,
  UpdateNewKpiMutationVariables
>;
export const UpdateKpiRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateKpiRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'kpiWithRole_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_kpiWithRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateKpiRolesMutation,
  UpdateKpiRolesMutationVariables
>;
export const InsertKpiRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertKpiRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'kpiWithRole_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_kpiWithRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertKpiRolesMutation,
  InsertKpiRolesMutationVariables
>;
export const InsertKpiDeptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertKpiDept' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'kpiWithDept_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_kpiWithDept' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertKpiDeptMutation,
  InsertKpiDeptMutationVariables
>;
export const DeleteKpiRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteKpiRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_kpiWithRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteKpiRolesMutation,
  DeleteKpiRolesMutationVariables
>;
export const DeleteKpiDeptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteKpiDept' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_kpiWithDept' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteKpiDeptMutation,
  DeleteKpiDeptMutationVariables
>;
export const Delete_AllKpiRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_AllKpiRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_kpiWithRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpi_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_AllKpiRolesMutation,
  Delete_AllKpiRolesMutationVariables
>;
export const Delete_AllKpidDeptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_AllKpidDept' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_kpiWithDept' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpi_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_AllKpidDeptMutation,
  Delete_AllKpidDeptMutationVariables
>;
export const CreateNewGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goals_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewGoalsMutation,
  CreateNewGoalsMutationVariables
>;
export const CreateNewGoalKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewGoalKpi' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goalWithKpi_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalWithKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewGoalKpiMutation,
  CreateNewGoalKpiMutationVariables
>;
export const AddNewGoalKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewGoalKpi' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goalWithKpi_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalWithKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewGoalKpiMutation,
  AddNewGoalKpiMutationVariables
>;
export const Delete_AllGoalWithKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_allGoalWithKpi' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalWithKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_AllGoalWithKpiMutation,
  Delete_AllGoalWithKpiMutationVariables
>;
export const UpdateNewGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateNewGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'goals_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNewGoalsMutation,
  UpdateNewGoalsMutationVariables
>;
export const DeleteGoalDeptsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGoalDepts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalsWithDepts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGoalDeptsMutation,
  DeleteGoalDeptsMutationVariables
>;
export const DeleteGoalRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGoalRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGoalRolesMutation,
  DeleteGoalRolesMutationVariables
>;
export const DeleteGoalFreqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGoalFreq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalsWithFreqs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGoalFreqMutation,
  DeleteGoalFreqMutationVariables
>;
export const InsertGoalDeptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertGoalDept' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goalsWithDepts_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalsWithDepts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertGoalDeptMutation,
  InsertGoalDeptMutationVariables
>;
export const InsertGoalRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertGoalRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goalsWithRoles_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertGoalRolesMutation,
  InsertGoalRolesMutationVariables
>;
export const InsertGoalFreqsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertGoalFreqs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goalsWithFreqs_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalsWithFreqs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertGoalFreqsMutation,
  InsertGoalFreqsMutationVariables
>;
export const CreateNewPerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewPerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'performance_review_settings_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewPerformanceReviewMutation,
  CreateNewPerformanceReviewMutationVariables
>;
export const GetGoalIdsWithTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGoalIdsWithTimePeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalWithTimePeriods' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoalIdsWithTimePeriodQuery,
  GetGoalIdsWithTimePeriodQueryVariables
>;
export const CreateNewPrWithPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewPRWithPeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'performance_review_setting_With_Goals_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewPrWithPeriodMutation,
  CreateNewPrWithPeriodMutationVariables
>;
export const UpdateNewPerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateNewPerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'performance_review_settings_set_input',
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNewPerformanceReviewMutation,
  UpdateNewPerformanceReviewMutationVariables
>;
export const Delete_PerformanceReviewGoalIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_PerformanceReviewGoalId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preformance_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_PerformanceReviewGoalIdMutation,
  Delete_PerformanceReviewGoalIdMutationVariables
>;
export const CreateNewPrGoalListsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewPRGoalLists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'performance_review_setting_GoalsList_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_performance_review_setting_GoalsList',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewPrGoalListsMutation,
  CreateNewPrGoalListsMutationVariables
>;
export const Delete_PerformanceReviewAllGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_PerformanceReviewAllGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_performance_review_setting_GoalsList',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preformance_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_PerformanceReviewAllGoalsMutation,
  Delete_PerformanceReviewAllGoalsMutationVariables
>;
export const Get_PerformanceReviewStatusByPerformanceIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'get_performanceReviewStatusByPerformanceId',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_PerformanceReviewStatusByPerformanceIdQuery,
  Get_PerformanceReviewStatusByPerformanceIdQueryVariables
>;
export const Get_PerformanceReviewWithTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_performanceReviewWithTimePeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instruction' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preformance_Id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_PerformanceReviewWithTimePeriodQuery,
  Get_PerformanceReviewWithTimePeriodQueryVariables
>;
export const Get_ListOfGoalWithRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_listOfGoalWithRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithRoles' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goal' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'timePeriodic' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_ilike' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: '_ilike',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timePeriodic' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'weightage_s' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goalWithKpis' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'order_by' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'kpi_bank',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'created_at',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'asc',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kpi_bank' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOfGoalWithRolesQuery,
  Get_ListOfGoalWithRolesQueryVariables
>;
export const Get_PerformanceReviewDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_PerformanceReviewDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_year' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_year' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instruction' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_Id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'year' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_year' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_setting_GoalsLists',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'goal' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'goalWithTimePeriods',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'timePeriod',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_ilike',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_ilike',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'goalsWithRoles',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'role_ID',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_roleId',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'performance_Period_Id',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'timePeriodic',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'weightage_s',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'goalsWithRoles',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'where' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'role_ID',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_roleId',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'time_period',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'time_period',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_weightage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'goalWithKpis',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'order_by',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'kpi_bank',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'created_at',
                                                    },
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'asc',
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'where' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'kpi_bank',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'kpiWithRoles',
                                                    },
                                                    value: {
                                                      kind: 'ObjectValue',
                                                      fields: [
                                                        {
                                                          kind: 'ObjectField',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'role_Id',
                                                          },
                                                          value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                              {
                                                                kind: 'ObjectField',
                                                                name: {
                                                                  kind: 'Name',
                                                                  value: '_eq',
                                                                },
                                                                value: {
                                                                  kind: 'Variable',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      '_roleId',
                                                                  },
                                                                },
                                                              },
                                                            ],
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'kpi_bank',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'description',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'open_ended_kpi',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_PerformanceReviewDetailQuery,
  Get_PerformanceReviewDetailQueryVariables
>;
export const Insert_PerformaceReview_With_GoalListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insert_PerformaceReview_With_GoalList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'performance_review_setting_GoalsList_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_performance_review_setting_GoalsList',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Insert_PerformaceReview_With_GoalListMutation,
  Insert_PerformaceReview_With_GoalListMutationVariables
>;
export const Create_SelfReviewByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_SelfReviewByUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'performance_review_byUser_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Create_SelfReviewByUserMutation,
  Create_SelfReviewByUserMutationVariables
>;
export const Insert_UserPerformanceReviewStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insert_UserPerformanceReviewStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'performanceReviewStatus_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_performanceReviewStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Insert_UserPerformanceReviewStatusMutation,
  Insert_UserPerformanceReviewStatusMutationVariables
>;
export const Insert_Peer_DataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insert_Peer_Data' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User_Peer_Review_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Insert_Peer_DataMutation,
  Insert_Peer_DataMutationVariables
>;
export const Update_Peer_DataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_Peer_data' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'User_Peer_Review_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_Peer_DataMutation,
  Update_Peer_DataMutationVariables
>;
export const Get_User_ManagerIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_user_ManagerId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_User_ManagerIdQuery,
  Get_User_ManagerIdQueryVariables
>;
export const Check_Peer_AddedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_Peer_Added' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_Peer_AddedQuery,
  Check_Peer_AddedQueryVariables
>;
export const Get_UsersSelf_ReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_UsersSelf_Review' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'true',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_timePeriod_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_byUser_WithGoals',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Marks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'weightage_s' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goalsWithRoles' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'time_period',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'time_period',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'role_ID',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'role_id',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'role_weightage',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UsersSelf_ReviewQuery,
  Get_UsersSelf_ReviewQueryVariables
>;
export const Get_PeersReview_Of_UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_PeersReview_OF_User' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'Completed',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'reviewStatus' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'true',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_byUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'performance_Id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_timePeriod_Id',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_byUser_WithGoals',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Marks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'weightage_s',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_PeersReview_Of_UserQuery,
  Get_PeersReview_Of_UserQueryVariables
>;
export const Get_UsersDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_UsersDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_UsersDetailQuery,
  Get_UsersDetailQueryVariables
>;
export const Download_PerformanceReviewByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'download_PerformanceReviewByUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_timePeriod_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goal',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser_WithGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'goal_Marks' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_byUser_Kpis',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_bank' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_Mark' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Download_PerformanceReviewByUserQuery,
  Download_PerformanceReviewByUserQueryVariables
>;
export const Get_ListOfRolesPerOrgAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_ListOfRolesPerOrgAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_nlike' },
                            value: {
                              kind: 'StringValue',
                              value: '%org-admin%',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system_role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOfRolesPerOrgAdminQuery,
  Get_ListOfRolesPerOrgAdminQueryVariables
>;
export const Get_GoalCountPerRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_GoalCountPerRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_With_Goals',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instruction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_setting_GoalsLists',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'goalsWithRoles',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'where' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'time_period',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_ilike',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: '_ilike',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'goal_ID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'organization_role',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'role_title',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_GoalCountPerRolesQuery,
  Get_GoalCountPerRolesQueryVariables
>;
export const Check_UserTakenPerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_UserTakenPerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'true',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_UserTakenPerformanceReviewQuery,
  Check_UserTakenPerformanceReviewQueryVariables
>;
export const Check_PeerTakenPerformanceReviewForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_PeerTakenPerformanceReviewForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'true',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_PeerTakenPerformanceReviewForUserQuery,
  Check_PeerTakenPerformanceReviewForUserQueryVariables
>;
export const UserCompletedPerformanceReviewCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userCompletedPerformanceReviewCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewStatus_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_Period_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserCompletedPerformanceReviewCountQuery,
  UserCompletedPerformanceReviewCountQueryVariables
>;
export const Org_TotalEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'org_totalEmployee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_employee_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Org_TotalEmployeeQuery,
  Org_TotalEmployeeQueryVariables
>;
export const UpdateCompletedPeformanceReviewStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCompletedPeformanceReviewStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompletedPeformanceReviewStatusMutation,
  UpdateCompletedPeformanceReviewStatusMutationVariables
>;
export const Get_OrgIdUsingPeformanceReviewSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_OrgIdUsingPeformanceReviewSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_Id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_OrgIdUsingPeformanceReviewSettingQuery,
  Get_OrgIdUsingPeformanceReviewSettingQueryVariables
>;
export const DeleteGoalTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGoalTimePeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalWithTimePeriod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGoalTimePeriodMutation,
  DeleteGoalTimePeriodMutationVariables
>;
export const InsertGoalTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertGoalTimePeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'goalWithTimePeriod_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalWithTimePeriod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertGoalTimePeriodMutation,
  InsertGoalTimePeriodMutationVariables
>;
export const Delete_PerformanceReviewSettingGoalListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_PerformanceReviewSettingGoalList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_performance_review_setting_GoalsList',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_Period_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq2' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_PerformanceReviewSettingGoalListMutation,
  Delete_PerformanceReviewSettingGoalListMutationVariables
>;
export const Get_TimePeriodNameUsingPeriodIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_timePeriodNameUsingPeriodId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_TimePeriodNameUsingPeriodIdQuery,
  Get_TimePeriodNameUsingPeriodIdQueryVariables
>;
export const Get_PerformanceTimePeriodUsingGoalIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_performanceTimePeriodUsingGoalId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_GoalsList',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goal',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_PerformanceTimePeriodUsingGoalIdQuery,
  Get_PerformanceTimePeriodUsingGoalIdQueryVariables
>;
export const Update_PerformanceReviewActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_performanceReviewAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'action' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'action' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_PerformanceReviewActionMutation,
  Update_PerformanceReviewActionMutationVariables
>;
export const Check_SelfReviewTakenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_selfReviewTaken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_SelfReviewTakenQuery,
  Check_SelfReviewTakenQueryVariables
>;
export const Update_CalendarForOneToOneDiscussionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_CalendarForOneToOneDiscussion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_timePeriod_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'calendarId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'calendarId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_CalendarForOneToOneDiscussionMutation,
  Update_CalendarForOneToOneDiscussionMutationVariables
>;
export const Get_ListOfRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_ListOfRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'orgId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalWithTimePeriods' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_At' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithTimePeriods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_bank' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOfRolesQuery,
  Get_ListOfRolesQueryVariables
>;
export const Get_ListOfGoalsByRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_ListOfGoalsByRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalsWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'orgId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_At' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithTimePeriods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'kpi_bank' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'kpiWithRoles' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'role_Id',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_eq',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOfGoalsByRolesQuery,
  Get_ListOfGoalsByRolesQueryVariables
>;
export const Check_PerformanceReviewSettingCreatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_PerformanceReviewSettingCreated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_PerformanceReviewSettingCreatedQuery,
  Check_PerformanceReviewSettingCreatedQueryVariables
>;
export const Check_GoalCreationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_GoalCreation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_Id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_GoalCreationQuery,
  Check_GoalCreationQueryVariables
>;
export const Check_OrgAdminIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_orgAdminId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default_role' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_OrgAdminIdQuery,
  Check_OrgAdminIdQueryVariables
>;
export const Delete_AllDataWithGoalIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_AllDataWithGoalID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'goalId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_performance_review_setting_GoalsList',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_Period_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalsWithDepts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalWithTimePeriod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goalId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goalWithKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goalId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_goals_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'goalId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_AllDataWithGoalIdMutation,
  Delete_AllDataWithGoalIdMutationVariables
>;
export const Check_PerformanceReviewActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_PerformanceReviewAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_GoalsList',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_Period_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goal',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_PerformanceReviewActionQuery,
  Check_PerformanceReviewActionQueryVariables
>;
export const Get_AllDeptByManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_allDeptByManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'department_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_AllDeptByManagerQuery,
  Get_AllDeptByManagerQueryVariables
>;
export const Get_AllRolesByManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_allRolesByManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'role_title' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_role',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_AllRolesByManagerQuery,
  Get_AllRolesByManagerQueryVariables
>;
export const Get_KpiDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_KpiDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_Id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department_Id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_KpiDetailQuery, Get_KpiDetailQueryVariables>;
export const Get_UserDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_UserDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default_role' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'my_organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_UserDetailQuery, Get_UserDetailQueryVariables>;
export const Get_GoalDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_GoalDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'manager_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department_ID' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_ID' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithTimePeriods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_bank' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpiWithRoles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_Id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'kpiWithRolesAndDepts',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_GoalDetailQuery, Get_GoalDetailQueryVariables>;
export const Check_GoalWithPerformanceReviewUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'check_GoalWithPerformanceReviewUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser_WithGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'performance_timePeriod_Id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Check_GoalWithPerformanceReviewUsersQuery,
  Check_GoalWithPerformanceReviewUsersQueryVariables
>;
export const GetManagerIdOfUserForPrDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getManagerIdOfUserForPR' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_byUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'performance_Id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_timePeriod_Id',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_byUser_WithGoals',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Marks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'weightage_s',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManagerIdOfUserForPrQuery,
  GetManagerIdOfUserForPrQueryVariables
>;
export const Get_Peers_ReviewInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_Peers_ReviewInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'peerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'peerId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'Completed',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'reviewStatus' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'true',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_byUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'performance_Id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_timePeriod_Id',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_byUser_WithGoals',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Marks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'weightage_s',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'goalsWithRoles',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'where' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'role_ID',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'role_id',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'time_period',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'time_period',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'role_weightage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Peers_ReviewInfoQuery,
  Get_Peers_ReviewInfoQueryVariables
>;
export const Get_ListOfKpiWithRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_ListOfKpiWithRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq1' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'open_ended_kpi' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'managerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goal_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq2' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_ID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOfKpiWithRolesQuery,
  Get_ListOfKpiWithRolesQueryVariables
>;
export const Get_ListOfKpiWithDeptsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_ListOfKpiWithDepts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'department_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq1' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'open_ended_kpi' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'managerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goal_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq2' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_ID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOfKpiWithDeptsQuery,
  Get_ListOfKpiWithDeptsQueryVariables
>;
export const Get_OrgAdminIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_OrgAdminId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owned_by' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_OrgAdminIdQuery, Get_OrgAdminIdQueryVariables>;
export const Get_ManagerIdOfKpiIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_ManagerIdOFKpiID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ManagerIdOfKpiIdQuery,
  Get_ManagerIdOfKpiIdQueryVariables
>;
export const All_KpiRoleCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'all_kpiRoleCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_role' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  All_KpiRoleCountQuery,
  All_KpiRoleCountQueryVariables
>;
export const All_KpiDeptCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'all_kpiDeptCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'department' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  All_KpiDeptCountQuery,
  All_KpiDeptCountQueryVariables
>;
export const Get_Users_ScoreSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_Users_ScoreSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'true',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_timePeriod_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goal',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByPeerId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_byUser_WithGoals',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Marks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goalsWithRoles' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'role_ID',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'role_id',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'time_period',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'time_period',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'role_weightage',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_byUser_Kpis',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_Id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_Mark' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comment' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_bank' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'open_ended_kpi',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Users_ScoreSheetQuery,
  Get_Users_ScoreSheetQueryVariables
>;
export const Get_Peer_ScoreSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_peer_ScoreSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'true',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq2' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_timePeriod_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goal',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByPeerId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_byUser_WithGoals',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Marks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goalsWithRoles' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'role_ID',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'role_id',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'time_period',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'time_period',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'role_weightage',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_byUser_Kpis',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_Id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_Mark' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comment' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kpi_bank' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'open_ended_kpi',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Peer_ScoreSheetQuery,
  Get_Peer_ScoreSheetQueryVariables
>;
export const Get_PeerListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_PeerList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'Completed',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'reviewStatus' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'StringValue',
                                    value: 'true',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_ilike' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Get_PeerListQuery, Get_PeerListQueryVariables>;
export const ViewOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'viewOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq1' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewScoreOption' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewOptionQuery, ViewOptionQueryVariables>;
export const Get_PerviousfalseScoreSheetOfUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_perviousfalseScoreSheetOfUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'false',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_PerviousfalseScoreSheetOfUserQuery,
  Get_PerviousfalseScoreSheetOfUserQueryVariables
>;
export const Get_PerviousfalseScoreSheetOfPeerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_perviousfalseScoreSheetOfPeer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq2' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'false',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq2' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_PerviousfalseScoreSheetOfPeerQuery,
  Get_PerviousfalseScoreSheetOfPeerQueryVariables
>;
export const Delete_PreviousFalseSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_previousFalseSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_performance_review_byUser_Kpi',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser_WithGoal',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'performance_review_byUser_Id',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_performance_review_byUser_WithGoal',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_byUser_Id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_PreviousFalseSheetMutation,
  Delete_PreviousFalseSheetMutationVariables
>;
export const List_Of_Performance_SettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_performance_setting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startingDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endingDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Performance_SettingQuery,
  List_Of_Performance_SettingQueryVariables
>;
export const Delete_Performance_Rating_ScaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_performance_rating_scale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'performance_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_performance_rating_scale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'performance_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_Performance_Rating_ScaleMutation,
  Delete_Performance_Rating_ScaleMutationVariables
>;
export const Insert_Performance_Rating_ScaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insert_performance_rating_scale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'performance_rating_scale_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_performance_rating_scale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Insert_Performance_Rating_ScaleMutation,
  Insert_Performance_Rating_ScaleMutationVariables
>;
export const Get_Reviewers_WeightageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_reviewers_weightage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'performance_review_setting_With_Goals',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'time_period' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_review_setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'self_review' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_review' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'peer_review' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Reviewers_WeightageQuery,
  Get_Reviewers_WeightageQueryVariables
>;
export const Get_List_Of_PeersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_List_of_peers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'time_period' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_Id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_List_Of_PeersQuery,
  Get_List_Of_PeersQueryVariables
>;
export const Get_Peer_Completed_CountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_peer_completed_count' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'User_Peer_Review_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'time_period' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'Completed',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Peer_Completed_CountQuery,
  Get_Peer_Completed_CountQueryVariables
>;
export const Performance_Review_Of_PeerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'performance_review_of_peer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'peer_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_byUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'time_period' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'true',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'peer_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'peer_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_Id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_timePeriod_Id' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_byUser_WithGoals',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Marks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal_Id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'weightage_s' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goalsWithRoles' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'role_ID',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'role_id',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'time_period',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'time_period',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'role_weightage',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Performance_Review_Of_PeerQuery,
  Performance_Review_Of_PeerQueryVariables
>;
export const List_Of_Roles_In_OrgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_roles_in_org' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Roles_In_OrgQuery,
  List_Of_Roles_In_OrgQueryVariables
>;
export const List_Of_Roles_And_WeigthageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'list_of_roles_and_weigthage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'time_period' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'time_period' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'role_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_title' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_weightage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'goal_ID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  List_Of_Roles_And_WeigthageQuery,
  List_Of_Roles_And_WeigthageQueryVariables
>;
export const Performance_RatingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'performance_rating' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'setting_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_rating_scale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_id',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'setting_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Performance_RatingQuery,
  Performance_RatingQueryVariables
>;
export const Org_ShareDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'org_share' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_With_Goals',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'time_period',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_goal_share' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Org_ShareQuery, Org_ShareQueryVariables>;
export const GetRole_WeightageByRoleIdAndTimePeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRole_WeightageByRoleIdAndTimePeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'role_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'time_period' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'time_period' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_weightage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'time_period' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRole_WeightageByRoleIdAndTimePeriodQuery,
  GetRole_WeightageByRoleIdAndTimePeriodQueryVariables
>;
export const InsertRoleAndWeightageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertRoleAndWeightage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goalsWithRoles_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertRoleAndWeightageMutation,
  InsertRoleAndWeightageMutationVariables
>;
export const Update_Role_WeightageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'update_role_weightage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_weightage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'float8' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_weightage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_weightage' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_Role_WeightageMutation,
  Update_Role_WeightageMutationVariables
>;
export const GoalDetailsByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'goalDetailsByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_Id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weightage_s' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timePeriodic' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithRoles' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'distinct_on' },
                      value: { kind: 'EnumValue', value: 'role_ID' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_weightage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'time_period' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithFreqs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_ID' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithTimePeriods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoalDetailsByIdsQuery,
  GoalDetailsByIdsQueryVariables
>;
export const RoleDetailsInGoalPkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'roleDetailsInGoalPk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'role_ID' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_title' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RoleDetailsInGoalPkQuery,
  RoleDetailsInGoalPkQueryVariables
>;
export const Get_Performance_Rating_Scale_AgvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_performance_rating_scale_agv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_rating_scale_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'performance_review_setting_With_Goals',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'timePeriod' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Performance_Rating_Scale_AgvQuery,
  Get_Performance_Rating_Scale_AgvQueryVariables
>;
export const Copy_Goals_Pervious_YrDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'copy_goals_pervious_yr' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'year' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'time_period',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'performance_review_setting_GoalsLists',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal_Id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Copy_Goals_Pervious_YrQuery,
  Copy_Goals_Pervious_YrQueryVariables
>;
export const Get_Role_Detail_GoalIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_role_detail_goalId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'goal_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'goal_ID' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goal_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'time_period' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'time_period' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role_ID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_weightage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'time_period' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Role_Detail_GoalIdQuery,
  Get_Role_Detail_GoalIdQueryVariables
>;
export const Get_Goal_Details_PkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_goal_details_pk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'goal_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'time_period' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'goal_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weightage_s' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manager_Id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithRoles' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'goal_ID' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'goal_id',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'time_period',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'time_period',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'goal_ID' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'goal_id',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'time_period',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_is_null',
                                            },
                                            value: {
                                              kind: 'BooleanValue',
                                              value: true,
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_ID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_weightage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalsWithDepts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goal_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'goal_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department_ID' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithTimePeriods' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'timePeriod' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'time_period',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goalId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'goal_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goal_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'goal_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_ID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_Goal_Details_PkQuery,
  Get_Goal_Details_PkQueryVariables
>;
export const Create_GoalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_goal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goals_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Create_GoalMutation, Create_GoalMutationVariables>;
export const Create_TimePeriod_For_GoalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_timePeriod_for_goal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goalWithKpi_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalWithKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Create_TimePeriod_For_GoalMutation,
  Create_TimePeriod_For_GoalMutationVariables
>;
export const Create_Role_For_GoalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'create_role_for_goal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'goalsWithRoles_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_goalsWithRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Create_Role_For_GoalMutation,
  Create_Role_For_GoalMutationVariables
>;
export const GetRolesOfUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRolesOfUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRolesOfUserQuery, GetRolesOfUserQueryVariables>;
export const Get_OrgAdmin_RoleidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_orgAdmin_roleid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'org-admin',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_OrgAdmin_RoleidQuery,
  Get_OrgAdmin_RoleidQueryVariables
>;
export const Get_ListOfKpiListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_ListOfKpiList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_id' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dept_id' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'goal_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRoles' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'role_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'department_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'dept_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'org_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'search' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'open_ended_kpi' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'managerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kpiWithRolesAndDepts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalWithKpis' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'goal_ID' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'goal_id' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpi_ID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi_bank_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'my_organization' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'org_id',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_ListOfKpiListQuery,
  Get_ListOfKpiListQueryVariables
>;
export const DeletePostCommentByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePostCommentByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_comments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePostCommentByPostIdMutation,
  DeletePostCommentByPostIdMutationVariables
>;
export const AddNewPostCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewPostComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'new_comment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'posts_comments_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_posts_comments_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'new_comment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commented_by' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewPostCommentMutation,
  AddNewPostCommentMutationVariables
>;
export const EditACommentByCommentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editACommentByCommentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'posts_comments_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_posts_comments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'comment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditACommentByCommentIdMutation,
  EditACommentByCommentIdMutationVariables
>;
export const DeleteCommentLikesByCommentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCommentLikesByCommentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comments_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'commentId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCommentLikesByCommentIdMutation,
  DeleteCommentLikesByCommentIdMutationVariables
>;
export const GetCommentByCommentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCommentByCommentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts_comments_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comment_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commented_by' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentioned_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organizations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentsLikes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'liked_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCommentByCommentIdQuery,
  GetCommentByCommentIdQueryVariables
>;
export const GetCommentLikesByAUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCommentLikesByAUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'post_comments_likes_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_comments_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liked_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCommentLikesByAUserQuery,
  GetCommentLikesByAUserQueryVariables
>;
export const AttachFilesToCommentByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'attachFilesToCommentById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newFiles' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'post_comment_files_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_post_comment_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newFiles' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AttachFilesToCommentByIdMutation,
  AttachFilesToCommentByIdMutationVariables
>;
export const DeleteFilesFromCommentByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFilesFromCommentById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comment_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFilesFromCommentByIdMutation,
  DeleteFilesFromCommentByIdMutationVariables
>;
export const AddMentionUsersToCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addMentionUsersToComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newMentions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'post_comment_mentioned_users_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_post_comment_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newMentions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMentionUsersToCommentMutation,
  AddMentionUsersToCommentMutationVariables
>;
export const DeleteMentionedUsersByCommentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMentionedUsersByCommentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_post_comment_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'commentId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMentionedUsersByCommentIdMutation,
  DeleteMentionedUsersByCommentIdMutationVariables
>;
export const DeleteCommentLikeForAUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCommentLikeForAUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'post_comments_likes_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comments_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCommentLikeForAUserMutation,
  DeleteCommentLikeForAUserMutationVariables
>;
export const DeletePostsCommentByCommentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePostsCommentByCommentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_comments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'comment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comments_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'comment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_post_comment_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'comment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comment_replies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'comment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_replies_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'comment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_post_comment_replies_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'comment_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePostsCommentByCommentIdMutation,
  DeletePostsCommentByCommentIdMutationVariables
>;
export const DeletePostsLikesByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePostsLikesByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'post_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_comments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_mentioned_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comments_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_post_comment_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comment_replies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_replies_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_post_comment_replies_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePostsLikesByPostIdMutation,
  DeletePostsLikesByPostIdMutationVariables
>;
export const AddNewLikeToPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewLikeToPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'new_like' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'post_likes_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_post_likes_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'new_like' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liked_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewLikeToPostMutation,
  AddNewLikeToPostMutationVariables
>;
export const RemoveLikeFromPostByPostIdAndLikerIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeLikeFromPostByPostIdAndLikerId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liker_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'liked_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liker_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveLikeFromPostByPostIdAndLikerIdMutation,
  RemoveLikeFromPostByPostIdAndLikerIdMutationVariables
>;
export const GetLikesByLikerIdAndPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLikesByLikerIdAndPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liker_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'liked_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liker_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liked_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLikesByLikerIdAndPostIdQuery,
  GetLikesByLikerIdAndPostIdQueryVariables
>;
export const AddNewMentionedUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewMentionedUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentioned_users' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'post_mentioned_users_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_post_mentioned_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentioned_users' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewMentionedUsersMutation,
  AddNewMentionedUsersMutationVariables
>;
export const DeletePostMentionedUsersByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePostMentionedUsersByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_mentioned_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'postId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePostMentionedUsersByPostIdMutation,
  DeletePostMentionedUsersByPostIdMutationVariables
>;
export const DeletePostFilesByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePostFilesByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePostFilesByPostIdMutation,
  DeletePostFilesByPostIdMutationVariables
>;
export const AddNewPostFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewPostFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'new_files' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'posts_files_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_posts_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'new_files' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewPostFilesMutation,
  AddNewPostFilesMutationVariables
>;
export const AddANewCommentReplyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addANewCommentReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'post_comment_replies_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_post_comment_replies_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reply' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replied_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddANewCommentReplyMutation,
  AddANewCommentReplyMutationVariables
>;
export const PostCommentsLikesByCommentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'postCommentsLikesByCommentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'post_comments_likes_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_post_comments_likes_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liked_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PostCommentsLikesByCommentIdMutation,
  PostCommentsLikesByCommentIdMutationVariables
>;
export const GetCommentReplyByReplyIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCommentReplyByReplyId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'replyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_comment_replies_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'replyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replied_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reply' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCommentReplyByReplyIdQuery,
  GetCommentReplyByReplyIdQueryVariables
>;
export const AddNewReplyLikeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewReplyLike' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'post_replies_likes_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_post_replies_likes_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reply_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liked_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewReplyLikeMutation,
  AddNewReplyLikeMutationVariables
>;
export const GetReplyLikesByAUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReplyLikesByAUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'post_replies_likes_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_replies_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reply_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liked_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReplyLikesByAUserQuery,
  GetReplyLikesByAUserQueryVariables
>;
export const DeleteReplyLikesForAUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteReplyLikesForAUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'post_replies_likes_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_replies_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReplyLikesForAUserMutation,
  DeleteReplyLikesForAUserMutationVariables
>;
export const AddNewMentionedUserForCommentReplyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewMentionedUserForCommentReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'post_comment_replies_mentioned_users_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_post_comment_replies_mentioned_users_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reply_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewMentionedUserForCommentReplyMutation,
  AddNewMentionedUserForCommentReplyMutationVariables
>;
export const DeleteCommentReplyByReplyIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCommentReplyByReplyId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'replyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comment_replies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'replyId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_replies_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reply_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'replyId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_post_comment_replies_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reply_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'replyId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCommentReplyByReplyIdMutation,
  DeleteCommentReplyByReplyIdMutationVariables
>;
export const DeleteAllTheRepliesOfACommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAllTheRepliesOfAComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'post_comment_replies_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comment_replies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAllTheRepliesOfACommentMutation,
  DeleteAllTheRepliesOfACommentMutationVariables
>;
export const AddANewPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addANewPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPost' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'posts_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_posts_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPost' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'liked_by' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commented_by' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddANewPostMutation, AddANewPostMutationVariables>;
export const EditAPostByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editAPostByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'posts_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_posts_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'liked_by' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commented_by' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditAPostByPostIdMutation,
  EditAPostByPostIdMutationVariables
>;
export const GetPostByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPostByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'post_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'liked_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'likedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commented_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commentedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentioned_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPostByPostIdQuery,
  GetPostByPostIdQueryVariables
>;
export const GetAPostByConditionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAPostByCondition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'condition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'posts_bool_exp' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'condition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'liked_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'likedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commented_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commentedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentioned_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAPostByConditionQuery,
  GetAPostByConditionQueryVariables
>;
export const GetAllThePostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllThePosts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'liked_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'likedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commented_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commentedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commentReplies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'replied_by' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userWhoReplied' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reply' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentioned_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'post_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'post_type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllThePostsQuery, GetAllThePostsQueryVariables>;
export const DeleteAPostByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAPostByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_post_comment_replies_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comment_replies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_replies_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comment_replies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_post_comment_mentioned_users',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comment_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_comments_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_comments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_post_likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'post_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAPostByPostIdMutation,
  DeleteAPostByPostIdMutationVariables
>;
export const CreatePollChoicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPollChoices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'choiceName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_posts_poll_choices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'poll_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'choiceName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePollChoicesMutation,
  CreatePollChoicesMutationVariables
>;
export const AddDisappearDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addDisappearDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'postId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'disappeared_at' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disappeared_at' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDisappearDateMutation,
  AddDisappearDateMutationVariables
>;
export const GetPollDetailsByPostIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPollDetailsByPostId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'postId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disappeared_at' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPollDetailsByPostIdQuery,
  GetPollDetailsByPostIdQueryVariables
>;
export const GetAllOrgHolidaysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllOrgHolidays' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_admins' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'is_owner' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllOrgHolidaysQuery,
  GetAllOrgHolidaysQueryVariables
>;
export const DeletePostByProjectIdAndStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePostByProjectIdAndStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'enum_post_type_enum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'post_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePostByProjectIdAndStatusMutation,
  DeletePostByProjectIdAndStatusMutationVariables
>;
export const GetUserSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'setting_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'EnumValue',
                              value: 'notification_preferences',
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting_type' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting_value' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables
>;
export const CreateProjectClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjectClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_client_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_client_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectClientMutation,
  CreateProjectClientMutationVariables
>;
export const GetProjectClientByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectClientById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectClientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'project_client' },
            name: { kind: 'Name', value: 'project_client_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectClientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectClientByIdQuery,
  GetProjectClientByIdQueryVariables
>;
export const GetProjectMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'project_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendars' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectMembersQuery,
  GetProjectMembersQueryVariables
>;
export const InsertProjectMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertProjectMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newMembers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'project_members_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newMembers' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'project_members_pkey',
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertProjectMembersMutation,
  InsertProjectMembersMutationVariables
>;
export const AddMembersToProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addMembersToProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'project_members_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMembersToProjectsMutation,
  AddMembersToProjectsMutationVariables
>;
export const ProjectOwnedByDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'projectOwnedBy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectOwnedByQuery, ProjectOwnedByQueryVariables>;
export const CheckMemberExistProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkMemberExistProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckMemberExistProjectQuery,
  CheckMemberExistProjectQueryVariables
>;
export const InsertProjectMilestoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertProjectMilestone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'milestone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_milestones_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_milestones_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'milestone' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertProjectMilestoneMutation,
  InsertProjectMilestoneMutationVariables
>;
export const InsertMultipleTasksForMilestoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertMultipleTasksForMilestone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'task_milestones_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_milestones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertMultipleTasksForMilestoneMutation,
  InsertMultipleTasksForMilestoneMutationVariables
>;
export const UpdateProjectMilestoneByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectMilestoneById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'milestoneId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'project_milestones_set_input' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project_milestones_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'milestoneId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectMilestoneByIdMutation,
  UpdateProjectMilestoneByIdMutationVariables
>;
export const DeleteTaskMilestonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTaskMilestones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'milestoneId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_milestones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'milestone_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'milestoneId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'milestone_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTaskMilestonesMutation,
  DeleteTaskMilestonesMutationVariables
>;
export const DeleteProjectMilestoneByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjectMilestoneById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_milestones_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectMilestoneByIdMutation,
  DeleteProjectMilestoneByIdMutationVariables
>;
export const CreateSprintsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSprints' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprints' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'project_sprints_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_sprints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sprints' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSprintsMutation,
  CreateSprintsMutationVariables
>;
export const GetSprintDetailsByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSprintDetailsById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_sprints_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sprintId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owned_by' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'meeting_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSprintDetailsByIdQuery,
  GetSprintDetailsByIdQueryVariables
>;
export const GetTasksBySprintIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasksBySprintId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_sprints_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sprintId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTasksBySprintIdQuery,
  GetTasksBySprintIdQueryVariables
>;
export const DeleteCalendarEventAssociatedToSprintDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCalendarEventAssociatedToSprint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_calendar_event_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'calendar_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCalendarEventAssociatedToSprintMutation,
  DeleteCalendarEventAssociatedToSprintMutationVariables
>;
export const DeleteProjectSprintByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjectSprintById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_sprints_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sprintId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectSprintByIdMutation,
  DeleteProjectSprintByIdMutationVariables
>;
export const InsertProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_billable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertProjectMutation,
  InsertProjectMutationVariables
>;
export const GetProjectInfoByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectInfoById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'project_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_billable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_deleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat_room' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectInfoByIdQuery,
  GetProjectInfoByIdQueryVariables
>;
export const UpdateProjectByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectByIdMutation,
  UpdateProjectByIdMutationVariables
>;
export const GetTasksByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasksByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'project_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTasksByProjectIdQuery,
  GetTasksByProjectIdQueryVariables
>;
export const GetUnsheduledTasksByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUnsheduledTasksByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'project_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUnsheduledTasksByProjectIdQuery,
  GetUnsheduledTasksByProjectIdQueryVariables
>;
export const GetTaskTimeSheetByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskTimeSheetByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'project_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskTimeSheetByProjectIdQuery,
  GetTaskTimeSheetByProjectIdQueryVariables
>;
export const DeleteProjectByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjectById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'project_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'project_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectByIdMutation,
  DeleteProjectByIdMutationVariables
>;
export const InsertUserPresenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertUserPresence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_presence_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_presence_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'user_presence_user_id_key',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'connection_id' },
                          { kind: 'EnumValue', value: 'connection_status' },
                          { kind: 'EnumValue', value: 'metadata' },
                          { kind: 'EnumValue', value: 'type' },
                          { kind: 'EnumValue', value: 'user_id' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertUserPresenceMutation,
  InsertUserPresenceMutationVariables
>;
export const DeleteUserPresenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUserPresence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'connection_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_user_presence_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'connection_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'connection_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteUserPresenceMutation,
  DeleteUserPresenceMutationVariables
>;
export const UpdateUserPresenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserPresence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_presence_bool_exp' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_presence_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_presence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'connection_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserPresenceMutation,
  UpdateUserPresenceMutationVariables
>;
export const GetUserPresenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserPresence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'connection_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_presence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'connection_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'connection_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connection_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connection_status' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserPresenceQuery,
  GetUserPresenceQueryVariables
>;
export const GetStorageFileByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStorageFileById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'storage_file' },
            name: { kind: 'Name', value: 'storage_files_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'file_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStorageFileByIdQuery,
  GetStorageFileByIdQueryVariables
>;
export const UpdateStorageFileByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStorageFileById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storageFileId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'storage_files_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_storage_files_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'storageFileId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'file_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStorageFileByIdMutation,
  UpdateStorageFileByIdMutationVariables
>;
export const GetTaskApproversByTaskIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskApproversByTaskId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_approvers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approver_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskApproversByTaskIdQuery,
  GetTaskApproversByTaskIdQueryVariables
>;
export const Insert_Multiple_Task_ApproversDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insert_multiple_task_approvers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'approvers' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'task_approvers_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_approvers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'approvers' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approver_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Insert_Multiple_Task_ApproversMutation,
  Insert_Multiple_Task_ApproversMutationVariables
>;
export const Delete_Multiple_Task_ApproversDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_multiple_task_approvers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'approverIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_approvers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'approver_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'approverIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approver_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_Multiple_Task_ApproversMutation,
  Delete_Multiple_Task_ApproversMutationVariables
>;
export const InsertChecklistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertChecklist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isApproved' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'approvedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_checklist_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'item_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taskId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_approved' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isApproved' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'approved_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'approvedBy' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                { kind: 'Field', name: { kind: 'Name', value: 'item_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approved_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertChecklistMutation,
  InsertChecklistMutationVariables
>;
export const UpdateChecklistItemNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateChecklistItemName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task_checklist_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'item_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'item_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChecklistItemNameMutation,
  UpdateChecklistItemNameMutationVariables
>;
export const UpdateChecklistApprovedByDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateChecklistApprovedBy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isApproved' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'approvedBy' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task_checklist_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_approved' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isApproved' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'approved_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'approvedBy' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approved_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChecklistApprovedByMutation,
  UpdateChecklistApprovedByMutationVariables
>;
export const GetChecklistByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChecklistById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'checklist' },
            name: { kind: 'Name', value: 'task_checklist_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assigned_to' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created_by' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_approvers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'approver_id' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'approved_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                { kind: 'Field', name: { kind: 'Name', value: 'item_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistByIdQuery,
  GetChecklistByIdQueryVariables
>;
export const DeleteTaskChecklistByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTaskChecklistById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_checklist_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTaskChecklistByIdMutation,
  DeleteTaskChecklistByIdMutationVariables
>;
export const CreateTaskCollaboratorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTaskCollaborator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_collaborator_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_collaborator_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTaskCollaboratorMutation,
  CreateTaskCollaboratorMutationVariables
>;
export const GetTaskCollaboratorByTaskIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskCollaboratorByTaskId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_collaborator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'task_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskCollaboratorByTaskIdQuery,
  GetTaskCollaboratorByTaskIdQueryVariables
>;
export const GetProjectMembersAndTasksByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectMembersAndTasksById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_deleted' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectMembersAndTasksByIdQuery,
  GetProjectMembersAndTasksByIdQueryVariables
>;
export const CreateTaskFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTaskFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_file_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_file_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage_file_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'task_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTaskFileMutation,
  CreateTaskFileMutationVariables
>;
export const SaveTimeSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveTimeSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_time_sheet_insert_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'update_columns' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_time_sheet_update_column' },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              { kind: 'EnumValue', value: 'work_date' },
              { kind: 'EnumValue', value: 'work_hours' },
              { kind: 'EnumValue', value: 'task_id' },
              { kind: 'EnumValue', value: 'status' },
              { kind: 'EnumValue', value: 'draft_hours' },
              { kind: 'EnumValue', value: 'work_minutes' },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_time_sheet_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'task_time_sheet_pkey',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'update_columns' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_hours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'work_minutes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveTimeSheetMutation,
  SaveTimeSheetMutationVariables
>;
export const CreateTaskUnscheduledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTaskUnscheduled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_unscheduled_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_unscheduled_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTaskUnscheduledMutation,
  CreateTaskUnscheduledMutationVariables
>;
export const GetUnScheduledTaskByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUnScheduledTaskById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'unscheduled_task_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_unscheduled_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'unscheduled_task_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUnScheduledTaskByIdQuery,
  GetUnScheduledTaskByIdQueryVariables
>;
export const UpdateTaskUnscheduledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTaskUnscheduled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_unscheduled_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task_unscheduled_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskUnscheduledMutation,
  UpdateTaskUnscheduledMutationVariables
>;
export const InsertTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsertTaskMutation, InsertTaskMutationVariables>;
export const InsertTaskMilestonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertTaskMilestones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'task_milestones_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_milestones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'milestone_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertTaskMilestonesMutation,
  InsertTaskMilestonesMutationVariables
>;
export const GetMilestonesOfTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMilestonesOfTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_milestones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'milestone_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMilestonesOfTaskQuery,
  GetMilestonesOfTaskQueryVariables
>;
export const InsertTaskTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertTaskTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'task_tags_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertTaskTagsMutation,
  InsertTaskTagsMutationVariables
>;
export const RemoveMilestonesOfTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeMilestonesOfTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'milestone_ids' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_milestones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'task_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'milestone_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'milestone_ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'milestone_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveMilestonesOfTaskMutation,
  RemoveMilestonesOfTaskMutationVariables
>;
export const GetTaskRanksForProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskRanksForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rank' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskRanksForProjectQuery,
  GetTaskRanksForProjectQueryVariables
>;
export const UpdateTaskManyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTaskMany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'task_updates' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task_many' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updates' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskManyMutation,
  UpdateTaskManyMutationVariables
>;
export const UpdateEmployeeCapacitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmployeeCapacities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'employee_capacity_updates' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_employee_capacity_many' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updates' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmployeeCapacitiesMutation,
  UpdateEmployeeCapacitiesMutationVariables
>;
export const GetTaskByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'task' },
            name: { kind: 'Name', value: 'task_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_to' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_approvers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approver_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owned_by' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTaskByIdQuery, GetTaskByIdQueryVariables>;
export const GetTasksAssignedToUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasksAssignedToUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: { kind: 'EnumValue', value: 'DRAFT' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'estimated_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTasksAssignedToUserQuery,
  GetTasksAssignedToUserQueryVariables
>;
export const GetPreviousTaskRankDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPreviousTaskRank' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refRank' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'float8' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rank' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rank' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'refRank' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPreviousTaskRankQuery,
  GetPreviousTaskRankQueryVariables
>;
export const GetNextTaskRankDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getNextTaskRank' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refRank' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'float8' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rank' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rank' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'refRank' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNextTaskRankQuery,
  GetNextTaskRankQueryVariables
>;
export const GetFirstRankedTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFirstRankedTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rank' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFirstRankedTaskQuery,
  GetFirstRankedTaskQueryVariables
>;
export const GetMultipleTaskDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMultipleTaskDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_ids' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'task_ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owned_by' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMultipleTaskDetailsQuery,
  GetMultipleTaskDetailsQueryVariables
>;
export const GetPrecalculatedEmployeeCapacityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrecalculatedEmployeeCapacity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'employeeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employee_capacity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'employee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'employeeId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workload_hours' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'employee_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrecalculatedEmployeeCapacityQuery,
  GetPrecalculatedEmployeeCapacityQueryVariables
>;
export const InsertMultipleTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertMultipleTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'task_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertMultipleTasksMutation,
  InsertMultipleTasksMutationVariables
>;
export const UpdateTasksNextRecurredDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTasksNextRecurredDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextRecurredDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'next_recurred_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'nextRecurredDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next_recurred_date' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTasksNextRecurredDateMutation,
  UpdateTasksNextRecurredDateMutationVariables
>;
export const GetAllRecurringTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllRecurringTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringStartDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringEndDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_recurring' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'next_recurred_date' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringStartDate',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringEndDate',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'next_recurred_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sprint_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllRecurringTasksQuery,
  GetAllRecurringTasksQueryVariables
>;
export const GetAllDependentTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllDependentTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentTaskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'get_all_dependent_tasks_new' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parent_task_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parentTaskId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'task_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dependent_on_task_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllDependentTasksQuery,
  GetAllDependentTasksQueryVariables
>;
export const AddDependenciesToTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addDependenciesToTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'task_dependencies_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_dependencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDependenciesToTaskMutation,
  AddDependenciesToTaskMutationVariables
>;
export const RemoveDependenciesToTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeDependenciesToTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dependentOnTaskId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_dependencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dependent_on_task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'dependentOnTaskId',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveDependenciesToTaskMutation,
  RemoveDependenciesToTaskMutationVariables
>;
export const UpdateTaskReturnIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTaskReturnId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskReturnIdMutation,
  UpdateTaskReturnIdMutationVariables
>;
export const GetTaskCollaboratorByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskCollaboratorByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_collaborator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'collaboratorId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskCollaboratorByIdsQuery,
  GetTaskCollaboratorByIdsQueryVariables
>;
export const GetCollaboratorsByTaskIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCollaboratorsByTaskId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_collaborator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCollaboratorsByTaskIdQuery,
  GetCollaboratorsByTaskIdQueryVariables
>;
export const MemberReleaseDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'memberReleaseDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'release_date' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MemberReleaseDateQuery,
  MemberReleaseDateQueryVariables
>;
export const GetProjectMemberByPkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectMemberByPk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_members_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'release_date' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectMemberByPkQuery,
  GetProjectMemberByPkQueryVariables
>;
export const ActivityTaskLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'activityTaskLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment_text' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'created_by' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activity_type' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'enum_activity_type_enum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_comment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'comment_text' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'activity_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'activity_type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivityTaskLogMutation,
  ActivityTaskLogMutationVariables
>;
export const InsertMultipleTaskCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertMultipleTaskCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'add_collaborators' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_collaborator_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_collaborator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'add_collaborators' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'task_collaborators_pkey',
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertMultipleTaskCollaboratorsMutation,
  InsertMultipleTaskCollaboratorsMutationVariables
>;
export const InsertMultipleTaskTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertMultipleTaskTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'add_tags' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_tags_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'add_tags' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: { kind: 'EnumValue', value: 'task_tags_pkey' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertMultipleTaskTagsMutation,
  InsertMultipleTaskTagsMutationVariables
>;
export const UpdateMultipleTasksFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMultipleTasksField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_ids' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateTasks' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'task_ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateTasks' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMultipleTasksFieldMutation,
  UpdateMultipleTasksFieldMutationVariables
>;
export const DeleteTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_ids' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'task_ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTasksMutation, DeleteTasksMutationVariables>;
export const UpdateTaskFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTaskField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newTags' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_tags_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newCollaborators' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_collaborator_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filesInput' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_file_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removedCollaboratorsUserIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removedTagIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectMembers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'project_members_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newTags' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: { kind: 'EnumValue', value: 'task_tags_pkey' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tag_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'removedTagIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_collaborator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newCollaborators' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'task_collaborators_pkey',
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_collaborator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'removedCollaboratorsUserIds',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filesInput' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: { kind: 'EnumValue', value: 'task_files_pkey' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'project_members_pkey',
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectMembers' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskFieldMutation,
  UpdateTaskFieldMutationVariables
>;
export const GetCollaboratorFilteredTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCollaboratorFilteredTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'distinct' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCollaboratorFilteredTaskQuery,
  GetCollaboratorFilteredTaskQueryVariables
>;
export const GetTagsFilteredTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTagsFilteredTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'distinct' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTagsFilteredTaskQuery,
  GetTagsFilteredTaskQueryVariables
>;
export const GetTagsAndCollaboratorsFilteredTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTagsAndCollaboratorsFilteredTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'distinct' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTagsAndCollaboratorsFilteredTaskQuery,
  GetTagsAndCollaboratorsFilteredTaskQueryVariables
>;
export const GetFilteredTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFilteredTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'distinct' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFilteredTaskQuery,
  GetFilteredTaskQueryVariables
>;
export const GroupBySprintDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupBySprint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sprint_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_sprints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'category' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'end_date' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'sort_position' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupBySprintQuery, GroupBySprintQueryVariables>;
export const GroupBySprintWithCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupBySprintWithCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sprint_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_sprints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'category' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'end_date' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'sort_position' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupBySprintWithCollaboratorsQuery,
  GroupBySprintWithCollaboratorsQueryVariables
>;
export const GroupBySprintWithTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupBySprintWithTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sprint_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_sprints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'category' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'end_date' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'sort_position' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupBySprintWithTagsQuery,
  GroupBySprintWithTagsQueryVariables
>;
export const GroupBySprintWithTagsAndCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupBySprintWithTagsAndCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sprint_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_sprints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'category' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'end_date' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'sort_position' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupBySprintWithTagsAndCollaboratorsQuery,
  GroupBySprintWithTagsAndCollaboratorsQueryVariables
>;
export const GroupByPriorityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByPriority' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enum_task_priority' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByPriorityQuery,
  GroupByPriorityQueryVariables
>;
export const GroupByPriorityWithCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByPriorityWithCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enum_task_priority' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByPriorityWithCollaboratorsQuery,
  GroupByPriorityWithCollaboratorsQueryVariables
>;
export const GroupByPriorityWithTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByPriorityWithTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enum_task_priority' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByPriorityWithTagsQuery,
  GroupByPriorityWithTagsQueryVariables
>;
export const GroupByPriorityWithTagsAndCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByPriorityWithTagsAndCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enum_task_priority' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByPriorityWithTagsAndCollaboratorsQuery,
  GroupByPriorityWithTagsAndCollaboratorsQueryVariables
>;
export const GroupByStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enum_task_status' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupByStatusQuery, GroupByStatusQueryVariables>;
export const GroupByStatusWithCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByStatusWithCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enum_task_status' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByStatusWithCollaboratorsQuery,
  GroupByStatusWithCollaboratorsQueryVariables
>;
export const GroupByStatusWithTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByStatusWithTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enum_task_status' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByStatusWithTagsQuery,
  GroupByStatusWithTagsQueryVariables
>;
export const GroupByStatusWithTagsAndCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByStatusWithTagsAndCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enum_task_status' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByStatusWithTagsAndCollaboratorsQuery,
  GroupByStatusWithTagsAndCollaboratorsQueryVariables
>;
export const GroupByProjectWithCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByProjectWithCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByProjectWithCollaboratorsQuery,
  GroupByProjectWithCollaboratorsQueryVariables
>;
export const GroupByProjectWithTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByProjectWithTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByProjectWithTagsQuery,
  GroupByProjectWithTagsQueryVariables
>;
export const GroupByProjectWithCollaboratorsAndTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByProjectWithCollaboratorsAndTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByProjectWithCollaboratorsAndTagsQuery,
  GroupByProjectWithCollaboratorsAndTagsQueryVariables
>;
export const GroupByProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupByProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupByProjectQuery, GroupByProjectQueryVariables>;
export const GroupBySectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupBySection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sectionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'section_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_section' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'section_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sectionId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_section' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupBySectionQuery, GroupBySectionQueryVariables>;
export const GroupBySectionWithTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupBySectionWithTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sectionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'section_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_section' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'section_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sectionId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_section' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupBySectionWithTagsQuery,
  GroupBySectionWithTagsQueryVariables
>;
export const GroupBySectionWithCollaboratorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupBySectionWithCollaborators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sectionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'section_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_section' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'section_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sectionId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_section' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupBySectionWithCollaboratorsQuery,
  GroupBySectionWithCollaboratorsQueryVariables
>;
export const GroupBySectionWithCollaboratorsAndTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupBySectionWithCollaboratorsAndTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sectionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_project_task_category_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateStartTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateEndTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_priority_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringFrequency' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'enum_task_recurring_frequency_enum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDraft' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaboratorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'section_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateStartTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lt' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dueDateEndTime' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'priority' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priority' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recurring_frequency' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'recurringFrequency',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_draft' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isDraft' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_tags' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'tag' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'name' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'tags' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_collaborators' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'collaboratorIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskDependenciesByTaskId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependent_on_task_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_section' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'section_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sectionId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'due_date_time' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateStartTime',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lt' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'dueDateEndTime',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'priority' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'recurring_frequency',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'recurringFrequency',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'isDraft' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'assignedBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_tags' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'tag' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'name' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_in',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tags',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task_collaborators' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'user_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_in' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'collaboratorIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'created_by',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'project' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'project_members',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: '_eq',
                                                        },
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'userId',
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'task_collaborators',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'assigned_to',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_section' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupBySectionWithCollaboratorsAndTagsQuery,
  GroupBySectionWithCollaboratorsAndTagsQueryVariables
>;
export const GetAllTimesheetInRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllTimesheetInRange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startOfWeek' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endOfWeek' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_time_sheets' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_and' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'work_date',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_gte',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'startOfWeek',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'work_date',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_lte',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'endOfWeek',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_or',
                                            },
                                            value: {
                                              kind: 'ListValue',
                                              values: [
                                                {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'work_hours',
                                                      },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: {
                                                              kind: 'Name',
                                                              value: '_is_null',
                                                            },
                                                            value: {
                                                              kind: 'BooleanValue',
                                                              value: false,
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                                {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'work_minutes',
                                                      },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: {
                                                              kind: 'Name',
                                                              value: '_is_null',
                                                            },
                                                            value: {
                                                              kind: 'BooleanValue',
                                                              value: false,
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'work_date' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllTimesheetInRangeQuery,
  GetAllTimesheetInRangeQueryVariables
>;
export const GetMyTimesheetsForGivenWeekDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyTimesheetsForGivenWeek' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_time_sheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'work_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'work_hours' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gt' },
                                      value: { kind: 'IntValue', value: '0' },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'work_minutes' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gt' },
                                      value: { kind: 'IntValue', value: '0' },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'work_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'task_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyTimesheetsForGivenWeekQuery,
  GetMyTimesheetsForGivenWeekQueryVariables
>;
export const CreateTrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTraining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'training_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTrainingMutation,
  CreateTrainingMutationVariables
>;
export const DeleteTrainingProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTrainingProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_progress_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_training_Status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_training_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTrainingProgressMutation,
  DeleteTrainingProgressMutationVariables
>;
export const CreateTrainingProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTrainingProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'training_progress_record_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_training_progress_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTrainingProgressMutation,
  CreateTrainingProgressMutationVariables
>;
export const UpdateTrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTraining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'training_set_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_training' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTrainingMutation,
  UpdateTrainingMutationVariables
>;
export const DeleteTrainingDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTrainingData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_role_details' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_table_of_contents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTrainingDataMutation,
  DeleteTrainingDataMutationVariables
>;
export const UpdateRoleAndContentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRoleAndContents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'training_role_details_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects1' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'training_table_of_contents_insert_input',
                },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_training_role_details' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_training_table_of_contents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects1' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRoleAndContentsMutation,
  UpdateRoleAndContentsMutationVariables
>;
export const DeleteTrainingProgressOfSKillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTrainingProgressOfSKill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_progress_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_training_Status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_training_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTrainingProgressOfSKillMutation,
  DeleteTrainingProgressOfSKillMutationVariables
>;
export const CreateSkillsForTrainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSkillsForTraining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'training_Skill_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_training_Skill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSkillsForTrainingMutation,
  CreateSkillsForTrainingMutationVariables
>;
export const Get_TrainingCountAttendByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_TrainingCountAttendByUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'training_progress_records_aggregate',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'skill_training_Status',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'career_training_record',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'distinct_on' },
                      value: { kind: 'EnumValue', value: 'training_id' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Get_TrainingCountAttendByUserQuery,
  Get_TrainingCountAttendByUserQueryVariables
>;
export const Delete_Training_Progress_Of_CareerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'delete_training_progress_of_career' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'training_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_training_progress_record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'training_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'training_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skill_training_Status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'career_training_record' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'progress' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'StringValue',
                              value: '80',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Delete_Training_Progress_Of_CareerMutation,
  Delete_Training_Progress_Of_CareerMutationVariables
>;
export const AddUserActivityTrackerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addUserActivityTracker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'user_activity_tracker_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_activity_tracker_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUserActivityTrackerMutation,
  AddUserActivityTrackerMutationVariables
>;
export const UpdateUserActivityTrackerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserActivityTracker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_activity_tracker_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_activity_tracker_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserActivityTrackerMutation,
  UpdateUserActivityTrackerMutationVariables
>;
export const GetUserActivityTrackerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserActivityTracker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_at' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'start_date',
                                        },
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'end_date',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: {
                                          kind: 'Name',
                                          value: 'user_id',
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_status_updated_at' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_seconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra_seconds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserActivityTrackerQuery,
  GetUserActivityTrackerQueryVariables
>;
export const GetAllYesterdayUserActivityTrackerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllYesterdayUserActivityTracker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'yesterday' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'account' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: '_is_null',
                                        },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_activity_trackers' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gte' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'yesterday',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'created_at' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active_seconds' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllYesterdayUserActivityTrackerQuery,
  GetAllYesterdayUserActivityTrackerQueryVariables
>;
export const GetAllActiveTimeByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllActiveTimeByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_seconds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllActiveTimeByDateQuery,
  GetAllActiveTimeByDateQueryVariables
>;
export const CreateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'profile_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_profile_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_role_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProfileMutation,
  CreateProfileMutationVariables
>;
export const UpdateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'profile_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const GetUserStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserStatusQuery, GetUserStatusQueryVariables>;
export const CreateUserStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_state_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_state_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_state' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manual_state' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'system_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserStateMutation,
  CreateUserStateMutationVariables
>;
export const UpdateSystemStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSystemState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'systemState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'enum_system_state_enum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'systemStateOwner' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_system_state_owner_enum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_state_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_state' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'systemState' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_state_owner' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'systemStateOwner' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSystemStateMutation,
  UpdateSystemStateMutationVariables
>;
export const UpdateUserStateByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserStateByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'user_state_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_state_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_state' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manual_state' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'system_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserStateByUserIdMutation,
  UpdateUserStateByUserIdMutationVariables
>;
export const GetUserByEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserByEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'citext' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'account' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'email' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expecting_user' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default_role' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetUserWithRoleByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserWithRoleById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'acceptedRoles' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'account' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'default_role' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_nin' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'acceptedRoles',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserWithRoleByIdQuery,
  GetUserWithRoleByIdQueryVariables
>;
export const UpdateUserDefaultRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserDefaultRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'defaultRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_auth_accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'default_role' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'defaultRole' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserDefaultRoleMutation,
  UpdateUserDefaultRoleMutationVariables
>;
export const GetUserInfoByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserInfoById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_employees' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'my_organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendars' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'date_of_birth' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_fields' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'path' },
                            value: {
                              kind: 'StringValue',
                              value: 'married_anniversary',
                              block: false,
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expecting_user' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserInfoByIdQuery,
  GetUserInfoByIdQueryVariables
>;
export const UpdateUserByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'users_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserByIdMutation,
  UpdateUserByIdMutationVariables
>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'users_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_users_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const GetUserNameByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserNameByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserNameByIdsQuery,
  GetUserNameByIdsQueryVariables
>;
export const GetProjectsNameForGraphShdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectsNameForGraphShd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'project_members',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'owned_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectsNameForGraphShdQuery,
  GetProjectsNameForGraphShdQueryVariables
>;
export const GetUsersInfoByOrgAdminShdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUsersInfoByOrgAdminShd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_nlike' },
                            value: {
                              kind: 'StringValue',
                              value: 'org-admin',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUsersInfoByOrgAdminShdQuery,
  GetUsersInfoByOrgAdminShdQueryVariables
>;
export const GetProjectsNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectsName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'project_members',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'owned_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectsNameQuery,
  GetProjectsNameQueryVariables
>;
export const GetTasksByProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasksByProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_lte' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_gte' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_in' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'due_date_time',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gte' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_gte' },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_or' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'due_date_time',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_lte',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_lte',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assigned_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_billable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTasksByProjectQuery,
  GetTasksByProjectQueryVariables
>;
export const GetUsersOfOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUsersOfOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'display_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roles' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manager_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'system_role' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roles' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manager_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'manager_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'display_name' },
                            value: {
                              kind: 'EnumValue',
                              value: 'asc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUsersOfOrganizationQuery,
  GetUsersOfOrganizationQueryVariables
>;
export const UpdateTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskSetInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newTags' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TaskTagsInsertInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removedTagIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newCollaborators' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TaskCollaboratorInsertInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removedCollaboratorsUserIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filesInput' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TaskFileInsertInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectMembers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ProjectMembersInsertInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newTaskMilestones' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TaskMilestoneInsertInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removedTaskMilestones' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskInput' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newTags' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newTags' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newCollaborators' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newCollaborators' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filesInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filesInput' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removedCollaboratorsUserIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removedCollaboratorsUserIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removedTagIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removedTagIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectMembers' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectMembers' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newTaskMilestones' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newTaskMilestones' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removedTaskMilestones' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removedTaskMilestones' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const GetAllTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'empId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'empId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_deleted' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllTasksQuery, GetAllTasksQueryVariables>;
export const GetAllProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'project_order_by' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'project_members',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'owned_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'title' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tasks' },
                  name: { kind: 'Name', value: 'tasks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_neq' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'COMPLETED',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'owned_by' },
                  name: { kind: 'Name', value: 'userByOwnedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'completed_tasks_count' },
                  name: { kind: 'Name', value: 'tasks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'COMPLETED',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllProjectsQuery, GetAllProjectsQueryVariables>;
export const GetTaskExistByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskExistById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskExistByIdQuery,
  GetTaskExistByIdQueryVariables
>;
export const GetAllMyUnreadMessagesCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getAllMyUnreadMessagesCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat_message_action_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_read' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllMyUnreadMessagesCountSubscription,
  GetAllMyUnreadMessagesCountSubscriptionVariables
>;
export const InsertTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newTags' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'tags_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newTags' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: { kind: 'EnumValue', value: 'tags_pkey' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsertTagsMutation, InsertTagsMutationVariables>;
export const InsertFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'storage_files_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_storage_files_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsertFileMutation, InsertFileMutationVariables>;
export const GetOrganizationInfoByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationInfoByUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system_role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_name' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'storageFileByLogoFileId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationInfoByUserQuery,
  GetOrganizationInfoByUserQueryVariables
>;
export const GetOrgRolesByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgRolesByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleText' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system_role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrgRolesByOrgIdQuery,
  GetOrgRolesByOrgIdQueryVariables
>;
export const GetOrgEmployeesFromUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgEmployeesFromUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'my_organization' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organizationId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'search' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default_role' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendars' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrgEmployeesFromUsersQuery,
  GetOrgEmployeesFromUsersQueryVariables
>;
export const CreateCallTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCallToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agoraId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'channelId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCallToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'agoraId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agoraId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channel' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'channelId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'appId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCallTokenMutation,
  CreateCallTokenMutationVariables
>;
export const LoginBackendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loginBackend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoginInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jwt_token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jwt_expires_in' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refresh_token' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginBackendMutation,
  LoginBackendMutationVariables
>;
export const GetProfileByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProfileByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_completed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_status' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProfileByUserIdQuery,
  GetProfileByUserIdQueryVariables
>;
export const UpdateActivityStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateActivityState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'enum_activity_state_enum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_state_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'activity_state' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'activityState' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'activity_state_last_updated',
                      },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateActivityStateMutation,
  UpdateActivityStateMutationVariables
>;
export const UserActivityAlertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userActivityAlert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_status_updated_at' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_seconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra_seconds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserActivityAlertQuery,
  UserActivityAlertQueryVariables
>;
export const ExtendDayAlertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'extendDayAlert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExtendDayAlertInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extendDayAlert' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExtendDayAlertMutation,
  ExtendDayAlertMutationVariables
>;
export const GetManualStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getManualState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user_state' },
            name: { kind: 'Name', value: 'user_state_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manual_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetManualStateQuery, GetManualStateQueryVariables>;
export const GetUserStartActivityTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserStartActivityTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_activity_tracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserStartActivityTimeQuery,
  GetUserStartActivityTimeQueryVariables
>;
export const GetUserByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'Id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'Id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role_title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetUserByIdSubDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getUserByIdSub' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'Id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'Id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserByIdSubSubscription,
  GetUserByIdSubSubscriptionVariables
>;
export const LogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'logout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refreshToken' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'refreshToken' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'refreshToken' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'logoutAllSessions' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const GetUserDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'my_organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organization_name',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'storageFileByLogoFileId',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'additional_fields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owned_by' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'system_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role_title' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agora_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization_role_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendars' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default_role' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserDataQuery, GetUserDataQueryVariables>;
export const CreateSprintDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSprint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSprintInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSprint' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSprintMutation,
  CreateSprintMutationVariables
>;
export const GetAllProjectsByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllProjectsByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllProjectsByOrganizationIdQuery,
  GetAllProjectsByOrganizationIdQueryVariables
>;
export const GetSprintsByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSprintsByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_task_status_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_project_task_category_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskOrderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintCategory' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_project_sprint_category_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_sprints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sprintCategory' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'category' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'end_date' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'sort_position' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organizationId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taskOrderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_tasks' },
                  name: { kind: 'Name', value: 'tasks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organizationId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'completed_tasks' },
                  name: { kind: 'Name', value: 'tasks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'COMPLETED',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organizationId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSprintsByOrganizationIdQuery,
  GetSprintsByOrganizationIdQueryVariables
>;
export const GetSprintNamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSprintNames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sprint' },
            name: { kind: 'Name', value: 'project_sprints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSprintNamesQuery, GetSprintNamesQueryVariables>;
export const GetTaskByProjectIdOrderBySprintsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskByProjectIdOrderBySprints' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_sprint' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'name' },
                            value: {
                              kind: 'EnumValue',
                              value: 'asc_nulls_last',
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskByProjectIdOrderBySprintsQuery,
  GetTaskByProjectIdOrderBySprintsQueryVariables
>;
export const GetSprintInformationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSprintInformation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sprint' },
            name: { kind: 'Name', value: 'project_sprints_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sprintId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration_in_weeks' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSprintInformationQuery,
  GetSprintInformationQueryVariables
>;
export const ActivateSprintDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'activateSprint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project_sprints_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sprintId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'EnumValue', value: 'ACTIVE' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateSprintMutation,
  ActivateSprintMutationVariables
>;
export const UpdateSprintDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSprint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_sprints_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project_sprints_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sprintId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSprintMutation,
  UpdateSprintMutationVariables
>;
export const GetFiltersBySectionNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFiltersBySectionName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sectionName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter_definition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'section_name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sectionName' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'column_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'column_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFiltersBySectionNameQuery,
  GetFiltersBySectionNameQueryVariables
>;
export const CreateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClientInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'project' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const GetTasksByMilestoneIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasksByMilestoneId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'milestoneId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_milestones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'milestoneId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_milestones' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assigned_by' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assigned_to' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created_by' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'due_date_time' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'end_date_time' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priority' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sprint_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'start_date_time' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subtask_of' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTasksByMilestoneIdQuery,
  GetTasksByMilestoneIdQueryVariables
>;
export const CreateMilestoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMilestone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'milestone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMilestoneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMilestone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'milestone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'milestone' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMilestoneMutation,
  CreateMilestoneMutationVariables
>;
export const UpdateMilestoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMilestone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'milestone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMilestoneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMilestone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'milestone' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMilestoneMutation,
  UpdateMilestoneMutationVariables
>;
export const GetProjectMilestonesByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectMilestonesByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_milestones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectMilestonesByProjectIdQuery,
  GetProjectMilestonesByProjectIdQueryVariables
>;
export const GetProjectMilestoneByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectMilestoneById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_milestones_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectMilestoneByIdQuery,
  GetProjectMilestoneByIdQueryVariables
>;
export const CreateTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attachedFiles' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaborators' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'milestones' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'task' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'task' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'task' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachedFiles' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'attachedFiles' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collaborators' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'collaborators' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tags' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tags' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'milestones' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'milestones' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'taskId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTaskDependenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTaskDependencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dependentOnTaskIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDateTime' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removedDependentOnTaskIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDateTime' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDependentTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taskId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dependent_on_task_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dependentOnTaskIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date_time' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dueDateTime' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'removed_dependent_on_task_ids',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'removedDependentOnTaskIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date_time' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDateTime' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dependentTaskIds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskDependenciesMutation,
  UpdateTaskDependenciesMutationVariables
>;
export const GetTaskDependenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskDependencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_dependencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskDependenciesQuery,
  GetTaskDependenciesQueryVariables
>;
export const GetEmployeeCapacityForUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmployeeCapacityForUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'employeeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employee_capacity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'employee_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'employeeId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'employee_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workload_hours' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeCapacityForUserIdQuery,
  GetEmployeeCapacityForUserIdQueryVariables
>;
export const GetOrganizationWorkHoursDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationWorkHours' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timezone_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'working_hours' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationWorkHoursQuery,
  GetOrganizationWorkHoursQueryVariables
>;
export const UpdateTaskRankDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTaskRank' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTaskRankInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTaskRank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskRankMutation,
  UpdateTaskRankMutationVariables
>;
export const UpdateTaskRankOutputDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'updateTaskRankOutput' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'actionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTaskRank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'actionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'output' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'success' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskRankOutputQuery,
  UpdateTaskRankOutputQueryVariables
>;
export const UpdateMultipleTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMultipleTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_ids' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'operation_name' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateTasks' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UpdateMultipleTasksInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tag_ids' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collaborator_ids' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMultipleTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'task_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'task_ids' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'operation_name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'operation_name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateTasks' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateTasks' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tag_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tag_ids' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collaborator_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'collaborator_ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updated_ids' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMultipleTasksMutation,
  UpdateMultipleTasksMutationVariables
>;
export const CreateChecklistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createChecklist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'checklist' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChecklistInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createChecklist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checklist' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'checklist' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'checklistId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateChecklistMutation,
  CreateChecklistMutationVariables
>;
export const UpdateChecklistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateChecklist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'checklist' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateChecklistInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateChecklist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'checklist' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChecklistMutation,
  UpdateChecklistMutationVariables
>;
export const UpdateTaskApproversDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTaskApprovers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'checklist' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTaskApproversInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTaskApprovers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'checklist' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approverIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskApproversMutation,
  UpdateTaskApproversMutationVariables
>;
export const GetChecklistByTaskIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getChecklistByTaskId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_checklist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approved_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                { kind: 'Field', name: { kind: 'Name', value: 'item_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistByTaskIdQuery,
  GetChecklistByTaskIdQueryVariables
>;
export const DeleteTaskFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTaskFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storage_file_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_file_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storage_file_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storage_file_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'task_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'task_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'task_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTaskFileMutation,
  DeleteTaskFileMutationVariables
>;
export const InsertTaskFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'insertTaskFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'task_file_insert_input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertTaskFilesMutation,
  InsertTaskFilesMutationVariables
>;
export const CreateTaskCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTaskComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'task_comment_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_comment_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTaskCommentMutation,
  CreateTaskCommentMutationVariables
>;
export const DeleteTaskCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTaskComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_comment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTaskCommentMutation,
  DeleteTaskCommentMutationVariables
>;
export const CreateTaskLikeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTaskLike' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_comment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_task_comment_like' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_comment_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'task_comment_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_comment_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'update_at' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTaskLikeMutation,
  CreateTaskLikeMutationVariables
>;
export const UpdateTaskCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTaskComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment_text' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task_comment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'activity_type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'COMMENT' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment_text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'comment_text' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTaskCommentMutation,
  UpdateTaskCommentMutationVariables
>;
export const DeleteTaskLikeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTaskLike' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'task_comment_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_comment_like' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_comment_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'task_comment_id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'user_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_comment_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTaskLikeMutation,
  DeleteTaskLikeMutationVariables
>;
export const DeleteATaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteATask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteATaskMutation, DeleteATaskMutationVariables>;
export const DeleteMultipleTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMultipleTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMultipleTasksMutation,
  DeleteMultipleTasksMutationVariables
>;
export const DeleteATaskSoftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteATaskSoft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteATaskSoftMutation,
  DeleteATaskSoftMutationVariables
>;
export const DeleteMultipleTasksSoftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMultipleTasksSoft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted_at' },
                      value: {
                        kind: 'StringValue',
                        value: 'now()',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMultipleTasksSoftMutation,
  DeleteMultipleTasksSoftMutationVariables
>;
export const SendTaskReminderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendTaskReminder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SendTaskReminderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendTaskReminder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendTaskReminderMutation,
  SendTaskReminderMutationVariables
>;
export const GetProjectClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectClients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'name' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectClientsQuery,
  GetProjectClientsQueryVariables
>;
export const GetProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orConditions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'project_bool_exp' },
              },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_or' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'title' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_ilike',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'title',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_client',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'client_name',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_ilike',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'title',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orConditions' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectsQuery, GetProjectsQueryVariables>;
export const DeleteSprintDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSprint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteAssociatedTasks' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSprint' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sprint_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sprintId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleteAssociatedTasks' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deleteAssociatedTasks' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSprintMutation,
  DeleteSprintMutationVariables
>;
export const DeleteSprintByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSprintById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_sprints_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSprintByIdMutation,
  DeleteSprintByIdMutationVariables
>;
export const GetTasksForExcelExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasksForExcelExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_project_task_category_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order_by' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_deleted' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'project' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'project_members',
                                      },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'user_id',
                                            },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: '_eq',
                                                  },
                                                  value: {
                                                    kind: 'Variable',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'userId',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: {
                                  kind: 'Name',
                                  value: 'task_collaborators',
                                },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'user_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: '_eq',
                                            },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'userId',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'assigned_to' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'userId' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order_by' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTasksForExcelExportQuery,
  GetTasksForExcelExportQueryVariables
>;
export const GetTaskFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'task' },
            name: { kind: 'Name', value: 'task_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTaskFilesQuery, GetTaskFilesQueryVariables>;
export const GetTaskRecordingFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskRecordingFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'task' },
            name: { kind: 'Name', value: 'task_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'storage_file' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'type' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'RECORDER',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskRecordingFilesQuery,
  GetTaskRecordingFilesQueryVariables
>;
export const GetTaskCommentsByTaskIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getTaskCommentsByTaskId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task_comment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comment_text' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'created_by' },
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_comment_likes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_comment_likes_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskCommentsByTaskIdSubscription,
  GetTaskCommentsByTaskIdSubscriptionVariables
>;
export const GetProjectByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'project' },
            name: { kind: 'Name', value: 'project_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'client' },
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectByIdQuery, GetProjectByIdQueryVariables>;
export const GetTaskDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'task' },
            name: { kind: 'Name', value: 'task_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'display_name',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'activity_status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar_file',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'file_url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_sprint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'assigned_to' },
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurred_from' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'next_recurred_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'created_by' },
                  name: { kind: 'Name', value: 'userByCreatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_collaborators' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent_task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_milestones' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'milestone_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_milestone' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>;
export const GetTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tagName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organisation_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'tagName' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTagsQuery, GetTagsQueryVariables>;
export const GetSubTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSubTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rank' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_first' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSubTasksQuery, GetSubTasksQueryVariables>;
export const GetSingleSubTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSingleSubTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'enum_storage_file_type',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSingleSubTaskQuery,
  GetSingleSubTaskQueryVariables
>;
export const GetFilterRequiredDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFilterRequiredData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tagIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'project' },
            name: { kind: 'Name', value: 'project_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activity_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar_file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'tagIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFilterRequiredDataQuery,
  GetFilterRequiredDataQueryVariables
>;
export const GetProjectDetailsByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectDetailsByProjectID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'project' },
            name: { kind: 'Name', value: 'project_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectID' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_billable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'activity_status',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avatar_file',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'file_url',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'owned_by' },
                  name: { kind: 'Name', value: 'userByOwnedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectDetailsByProjectIdQuery,
  GetProjectDetailsByProjectIdQueryVariables
>;
export const GetProjectDetailsForDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectDetailsForDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectDetailsForDetailsQuery,
  GetProjectDetailsForDetailsQueryVariables
>;
export const UpdateProjectMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newMembers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'project_members_insert_input' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deletedMembers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newMembers' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'deletedMembers' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectMembersMutation,
  UpdateProjectMembersMutationVariables
>;
export const GroupByFilterTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'groupByFilterTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'task' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GroupByFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupByFilterTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'task' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'task' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupByDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startIndex' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalTasks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedTasks' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priority' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_draft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'enum_storage_file_type',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_sprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_section' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taskDependenciesByTaskId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dependent_on_task_id',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregateCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupByFilterTasksMutation,
  GroupByFilterTasksMutationVariables
>;
export const GetTaskFilesByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskFilesByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'files' },
            name: { kind: 'Name', value: 'storage_files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'task_files' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'task' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'project_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'projectId',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'file_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task_files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTaskFilesByProjectIdQuery,
  GetTaskFilesByProjectIdQueryVariables
>;
export const DeleteProjectFromAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjectFromApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectFromAppMutation,
  DeleteProjectFromAppMutationVariables
>;
export const CreateOrUpdateProjectLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrUpdateProjectLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'project_links_insert_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_links_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: { kind: 'EnumValue', value: 'project_links_pkey' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'url' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrUpdateProjectLinkMutation,
  CreateOrUpdateProjectLinkMutationVariables
>;
export const DeleteProjectLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjectLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'linkId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_links_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'linkId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectLinkMutation,
  DeleteProjectLinkMutationVariables
>;
export const GetProjectLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectLinks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_links' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectLinksQuery,
  GetProjectLinksQueryVariables
>;
export const CreateEmpBandWidthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEmpBandWidth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'employeeBandwidthInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeBandwidthData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEmpBandWidthMutation,
  CreateEmpBandWidthMutationVariables
>;
export const GetProjectMembersByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectMembersByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectMembersByProjectIdQuery,
  GetProjectMembersByProjectIdQueryVariables
>;
export const AddMemberToProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addMemberToProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddMemberDetailsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addMemberToProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMemberToProjectMutation,
  AddMemberToProjectMutationVariables
>;
export const UpdateProjectMemberReleaseDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectMemberReleaseDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'releaseDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'release_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'releaseDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectMemberReleaseDateMutation,
  UpdateProjectMemberReleaseDateMutationVariables
>;
export const UpdateProjectOwnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectOwner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ownedBy' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owned_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ownedBy' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owned_by' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectOwnerMutation,
  UpdateProjectOwnerMutationVariables
>;
export const ProjectMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'projectMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'release_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar_file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file_url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default_role' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectMembersSubscription,
  ProjectMembersSubscriptionVariables
>;
export const DeleteProjectMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjectMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_project_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectMemberMutation,
  DeleteProjectMemberMutationVariables
>;
export const InviteGuestToProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteGuestToProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteGuestToProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emailIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emailIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InviteGuestToProjectMutation,
  InviteGuestToProjectMutationVariables
>;
export const CreateProjectSectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjectSection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_project_section' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectSectionMutation,
  CreateProjectSectionMutationVariables
>;
export const GetProjectSectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectSections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_section' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectSectionsQuery,
  GetProjectSectionsQueryVariables
>;
export const GetTasksOfProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasksOfProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'enum_task_status_enum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignedToUsers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'uuid' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_project_task_category_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'task_order_by' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sprintCategory' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'enum_project_sprint_category_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigned_to' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assignedToUsers' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'category' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subtask_of' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sprint_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_deleted' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'taskName' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigned_to' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'due_date_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start_date_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimated_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_recurring' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurring_frequency' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_sprints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sprintCategory' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'category' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'end_date' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'sort_position' },
                          value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_ilike' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'taskName' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assigned_to' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'due_date_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_by' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_time' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_recurring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring_frequency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sub_tasks_aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'current_tasks' },
                  name: { kind: 'Name', value: 'tasks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'total_tasks' },
                  name: { kind: 'Name', value: 'tasks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'completed_tasks' },
                  name: { kind: 'Name', value: 'tasks_aggregate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'COMPLETED',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'project_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projectId' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'assigned_to' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedToUsers',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'category' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'category' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'created_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subtask_of' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTasksOfProjectQuery,
  GetTasksOfProjectQueryVariables
>;
